import './dueDateWarningBanner.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'semantic-ui-react';
import ReportingGet from 'src/models/reporting';

interface IProps {
  postPone: () => void;
  seeReport: () => void;
  reporting: ReportingGet;
  hidden: boolean;
  triggerSizeCalculation(): void;
}

class DueDateWarningBanner extends React.Component<IProps> {

  public constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.triggerSizeCalculation && this.props.triggerSizeCalculation();
  }

  public render() {
    return (
      <div id="due-date-banner" className={this.props.hidden ? 'due-date-banner-closed' : ''}>
        <div id={'message-validation'}>
          <FormattedMessage
            id="dueDateWarnDesc"
            defaultMessage="The due date of the following reporting has passed:"
          /> {this.props.reporting.name}
        </div>
        <div id={'actions-validation'}>
          <Button
            id={'rejectButton'}
            inverted={true}
            onClick={this.props.postPone}
          >
            <FormattedMessage
              id="dueDateWarnPost"
              defaultMessage="Postpone"
            />
          </Button>
          <Button
            id={'validateButton'}
            onClick={this.props.seeReport}
          >
            <FormattedMessage
              id="dueDateWarnSee"
              defaultMessage="See Reporting"
            />
          </Button>
        </div>
      </div>
    );
  }
}

export default DueDateWarningBanner;
