import './reporting.scss';

import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, Icon, Input, Popup, Segment } from 'semantic-ui-react';
import { RouteComponentProps, withRouter } from 'react-router';

import NotificationActions from 'src/actions/notification-actions';
import PerformancePlanActions from 'src/actions/performancePlan.action';
import Filters, { FilterCategory, FilterType } from 'src/components/common/form/filter/filter';
import BusinessChallenge from 'src/models/businessChallenge';
import PerformancePlan from 'src/models/performancePlan';
import ReportingGet, { ReportingGetFormatted, ReportingPut } from 'src/models/reporting';
import Utils from 'src/utils/utils';
import BusinessChallengeReporting, { BcSnapshot } from 'src/models/businessChallengeReporting';
import ReportList from './reportList/reportList';
import TileBC from './tileBC/tileBC';
import ReportingActions from 'src/actions/reporting.action';
import ReportingAPI from 'src/api/reporting.api';
import AuthStore from 'src/stores/auth.store';
import CloseReportModal from './modal/closeReportModal/closeReportModal';
import DeleteReportModal from './modal/deleteReportModal/deleteReportModal';
import ReportModalContentTitle from './modal/reportModalContentTitle';
import { ToastType } from 'src/components/common/toast/toast';
import CustomScrollBars from '../../../../common/customScrollBars/customScrollBars';
import ConfirmModal from '../../confirmModal/confirmModal';

interface IRouteProps {
  reportingId: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  ppId: number;
  empty: boolean;
  onReportingUpdate: () => void;
  isReportingEditing: boolean;
  setReportingEdition: (editMode: boolean) => void;
}

interface IStates {
  reportingList: ReportingGetFormatted[];
  selectedFilter: FilterType;
  search: BcSnapshot[];
  formValidation: {
    name: boolean;
    date: boolean;
  };
  isLoading: boolean;
  selectedReporting?: ReportingGetFormatted;
  oldSelectedReporting?: ReportingGetFormatted;
  pp: PerformancePlan | undefined;
  closeReportingModalOpen: boolean;
  deleteModalOpen: boolean;
  showReportCancelModal: boolean;
  targetReportingId: number;
}

class ReportingTab extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      selectedFilter: FilterType.NONE,
      search: [],
      isLoading: true,
      reportingList: [],
      pp: undefined,
      closeReportingModalOpen: false,
      deleteModalOpen: false,
      formValidation: {
        name: false,
        date: false,
      },
      showReportCancelModal: false,
      targetReportingId: 0,
    };
    moment.locale(Utils.getCurrentUserLanguageName());
  }

  public componentWillMount() {
    this.setState({ isLoading: true }, () => this.fetchReportings());
  }

  public fetchReportings = () => {
    ReportingAPI.getReportingsFromPerformancePlan(this.props.ppId)
      .then((reportingList: ReportingGet[]) => {
        const ppRep = reportingList.map(rep => new ReportingGetFormatted(rep));
        this.setState({ reportingList: ppRep, isLoading: false }, () => { this.autoComponentRouting(); });
      });
  };

  private autoComponentRouting = () => {
    if (this.state.reportingList) {
      const sortedReportings: ReportingGetFormatted[] = this.state.reportingList.filter(
        report => report.closingDate === undefined,
        );
      if (sortedReportings.length > 0) {
        this.props.history.push(
          `/activities-board/performance-plan/${this.props.ppId}/reporting/${sortedReportings[0].id}`,
          );
      }
    }
  };

  public showReportingCancelModal = (reportingId: number) => {
    this.setState({
      showReportCancelModal: true,
      targetReportingId: reportingId,
    });
  }

  public setSelectedReporting = (reportingId: number) => {
    this.setState({ isLoading: true }, () => {
      ReportingAPI.getReportingById(reportingId)
        .then((selectedReporting: ReportingGet) => {
          this.setState({
            selectedReporting: new ReportingGetFormatted(selectedReporting),
            oldSelectedReporting: new ReportingGetFormatted(selectedReporting),
            search: selectedReporting.bcReportings
              .map(bcRep => bcRep.bcSnapshot),
            isLoading: false,
            formValidation: {
              name: selectedReporting.name !== '',
              date: moment(selectedReporting.dueDate).isValid(),
            },
          });
        });
    });
  };

  public reloadReporting = () => {
    if (!!this.state.selectedReporting) {
      this.setSelectedReporting(this.state.selectedReporting.id);
    }
  };

  public onBcReportingUpdate = (bcReporting: BusinessChallengeReporting) => {
    if (!!this.state.selectedReporting) {
      const selectedReporting = this.state.selectedReporting;
      selectedReporting.bcReportings[selectedReporting.bcReportings.findIndex(rep => rep.id === bcReporting.id)]
        = bcReporting;
      this.setState({ selectedReporting });
    }
  };

  /**
   * On reporting created
   */
  private onReportingCreated = () => {
    this.fetchReportings();
    PerformancePlanActions.emitGetPerformancePlanReportings(this.props.ppId);
    this.props.onReportingUpdate();
  };

  /**
   * Close modal report close
   */
  private handleCloseReportModalClose = () => {
    this.setState({ closeReportingModalOpen: false });
  };

  /**
   * Close modal report agree
   */
  private handleCloseReportModalAgree = () => {
    if (!this.state.selectedReporting) {
      return;
    }
    ReportingAPI.closeReporting(this.state.selectedReporting.id, AuthStore.getConnectedUser().id)
      .then(() => {
        this.setState({ closeReportingModalOpen: false }, () => {
          NotificationActions.toast(
            <FormattedMessage id="closingRep" defaultMessage="Closing Report" />,
            (
              <FormattedMessage
                id="successClosingRep"
                defaultMessage="The Report {repName} has been successfully closed!"
                values={{ repName: this.state.selectedReporting ? this.state.selectedReporting.name : null }}
              />
            ),
            ToastType.SUCCESS,
          );
          this.reloadReporting();
          this.fetchReportings();
          this.props.onReportingUpdate();
        });
      }).catch(() => {
        this.setState({ closeReportingModalOpen: false }, () => {
          NotificationActions.toast(
            <FormattedMessage id="closingRep" defaultMessage="Closing Report" />,
            (
              <FormattedMessage
                id="failureClosingRep"
                defaultMessage="An error occurred while trying to close the Report. Please try again later."
              />
            ),
            ToastType.ERROR,
          );
        });
      });
  };

  /**
   * Delete modal annulation callback
   */
  private handleDeleteModalClose = () => {
    this.setState({ deleteModalOpen: false });
  };

  /**
   * Delete modal validation callback
   */
  private handleDeleteModalAgree = () => {
    this.setState({ deleteModalOpen: false });

    if (!this.state.selectedReporting) {
      return;
    }

    ReportingActions.emitDeleteReporting(this.state.selectedReporting.id)
      .then(() => {
        this.setState({ selectedReporting: undefined }, this.fetchReportings);

        NotificationActions.toast(
          <FormattedMessage id="success" defaultMessage="Success" />,
          (
            <FormattedMessage
              id="reporting.deletedMessage"
              defaultMessage="The Reporting has been successfully deleted !"
            />
          ),
          ToastType.SUCCESS,
        );
        PerformancePlanActions.emitGetPerformancePlanReportings(this.props.ppId);
        this.props.onReportingUpdate();
      })
      .catch(() => {
        Utils.toastError();
      });
  };

  /**
   * Publish plan
   */
  private handleCloseReporting = () => {
    this.setState({ closeReportingModalOpen: true });
  };

  /**
   * Check if all form element are valid
   * @returns {boolean} valid
   */
  private isFormValid = () => {
    return Object.values(this.state.formValidation).every(e => e);
  }

  private cancelEdition = () => {
    this.setState(prevState => ({
      selectedReporting: prevState.oldSelectedReporting && { ...prevState.oldSelectedReporting },
    }));
    this.props.setReportingEdition(false);
  }

  /**
   * Save reporting modification
   */
  private handleSaveEdition = () => {
    if (!this.state.selectedReporting) {
      return;
    }
    const dueDate = this.state.selectedReporting.dueDate;
    if (Utils.isDateFormatLanguageValid(dueDate)) {
      const body: ReportingPut =  Utils.parseDateStringToDate(this.state.selectedReporting, 'dueDate');
      PerformancePlanActions.emitEditPerformancePlanReporting(body)
        .then(this.fetchReportings);
      this.props.setReportingEdition(false);
      this.props.onReportingUpdate();
    } else {
      NotificationActions.toast(
        <FormattedMessage id="error" defaultMessage="Error!" />,
        <FormattedMessage id="invalidDateFormat" defaultMessage="Date format is invalid" />,
        ToastType.ERROR,
      );
    }
  };

  /**
   * Set new name value
   * @param: report name : string
   */
  private handleChangeName = (name: any) => {
    const selectedReporting = this.state.selectedReporting;
    if (!this.state.selectedReporting) {
      return;
    }

    if (selectedReporting) {
      selectedReporting.name = name;
    }
    this.setState(prevState => ({
      selectedReporting,
      formValidation: {
        ...prevState.formValidation,
        name: name !== '',
      },
    }));
  };

  /**
   * Set new due date value
   * @param: report dueDate : Date
   */
  private handleChangeDueDate = (date: any) => {
    const selectedReporting = this.state.selectedReporting;
    if (!selectedReporting) {
      return;
    }
    selectedReporting.dueDate = date;
    this.setState(prevState => ({
      selectedReporting,
      formValidation: {
        ...prevState.formValidation,
        date: Utils.isDateFormatLanguageValid(date),
      },
    }));
  };

  private filterBCs = (filteredArray: BcSnapshot[], selectedFilter: FilterType) => {
    this.setState({
      selectedFilter,
      search: filteredArray,
    });
  };

  private confirmCancel = () => {
    this.setState({
      showReportCancelModal: false,
    });
    this.props.setReportingEdition(false);
    this.props.history.replace(`/activities-board/performance-plan/${this.props.ppId}/reporting/${this.state.targetReportingId}`);
  }

  private noConfirmCancel = () => {
    this.setState({
      showReportCancelModal: false,
    });
  }

  public render() {
    const selectedRep = this.state.selectedReporting;

    if (this.state.isLoading && !this.state.reportingList) {
      return Utils.loader();
    }

    if (!selectedRep) {
      return (
        <CustomScrollBars>
          <div id="reporting-tab-container">
            <div id="reports-list">
              <ReportList
                ppId={this.props.ppId}
                reportings={this.state.reportingList}
                setSelectedReportingId={id => this.setSelectedReporting(id)}
                onReportingCreated={this.onReportingCreated}
                empty={this.props.empty}
                isReportingEditing={this.props.isReportingEditing}
                showReportingCancelModal={this.showReportingCancelModal}
              />
            </div>
            {(this.state.reportingList.length === 0 && !this.state.isLoading)
              ? <div id="report-detail">
                {Utils.empty(<FormattedMessage id="noReports" defaultMessage="No report yet" />, { marginTop: 0 })}
              </div>
              : this.state.isLoading
                ? <div id="report-detail-flex-version">{Utils.loader('inside-flex')}</div>
                : <div id="report-detail" />
            }
          </div>
        </CustomScrollBars>
      );
    }

    // Display publish button if last one has been published or if it is the first reporting
    let closeButton = <></>;

    if (selectedRep && !selectedRep.closingDate) {
      if (!this.state.reportingList
        .filter(rep => !rep.closingDate)
        .every(rep => rep.dueDate.toString() >= selectedRep.dueDate.toString())
      ) {
        closeButton = (
          <FormattedMessage id="rep.closeDisablePrevious" defaultMessage="Previous reporting has to be closed" >
            {msg =>
                <div
                  key="close-rep-button-container"
                  className="closeRepButton"
                  id="modification-button"
                >
                  <Popup
                    inverted={true}
                    content={msg}
                    size="tiny"
                    position="left center"
                    trigger={
                      <Button
                        id="close-rep-button"
                        onClick={this.handleCloseReporting}
                        disabled={true}
                      >
                        <Icon name="upload" />
                        <FormattedMessage id="rep.close" defaultMessage="Close Reporting" />
                      </Button>
                    }
                  />
                </div>
            }
          </FormattedMessage>
        );
      } else {
        closeButton = (
          <FormattedMessage
            id="rep.closeTooltip"
            defaultMessage="It will still be possible to export your report to PDF"
          >
            {msg =>
            <Popup
              inverted={true}
              content={msg}
              size="tiny"
              position="left center"
              trigger={
                <Button
                  id="close-rep-button"
                  className="long"
                  onClick={this.handleCloseReporting}
                  disabled={false}

                >
                  <Icon name="upload" />
                  <FormattedMessage id="rep.close" defaultMessage="Close Reporting" />
                </Button>
              }
            />
          }
          </FormattedMessage>
        );
      }
    }

    const leftColumnContent: JSX.Element[] = [];
    const rightColumnContent: JSX.Element[] = [];
    const activities = Utils.sortArrayByKey(this.state.search, 'code');

    activities.map((businessChallenge: BusinessChallenge, index: number) => {
      const bcReporting = selectedRep.bcReportings
        .find(bcReporting => bcReporting.bcSnapshot.id === businessChallenge.id);
      if (!!bcReporting) {

        const tileBC = (
          <TileBC
            key={bcReporting.id}
            bcReporting={bcReporting}
            reportingId={selectedRep.id}
            isReportingClosed={!!selectedRep.closingDate}
            onBcReportingUpdate={this.onBcReportingUpdate}
          />
        );

        if (index % 2 === 0) {
          leftColumnContent.push(tileBC);
        } else {
          rightColumnContent.push(tileBC);
        }
      }
      return null;
    });
    const cancelEditionTitle = <FormattedMessage id="cancelEdition" defaultMessage="Cancel edition" />;
    const cancelEditionMessage = (
      <FormattedMessage
        id="cancelEditionQuestion"
        defaultMessage="Cancel edition? Any unsaved work will be lost."
      />
    );
    return (
      <div id="reporting-tab-container">
         {this.state.showReportCancelModal
          && <ConfirmModal
            closeNoFunction={this.noConfirmCancel}
            closeYesFunction={this.confirmCancel}
            title={cancelEditionTitle}
            message={cancelEditionMessage}
          />
        }
        {this.state.closeReportingModalOpen &&
        <CloseReportModal
          handleCloseReportModalClose={this.handleCloseReportModalClose}
          handleCloseReportModalAgree={this.handleCloseReportModalAgree}
          closeReportingModalOpen={this.state.closeReportingModalOpen}
        />
        }
        {this.state.deleteModalOpen &&
        <DeleteReportModal
          handleDeleteModalClose={this.handleDeleteModalClose}
          handleDeleteModalAgree={this.handleDeleteModalAgree}
          deleteModalOpen={this.state.deleteModalOpen}
        />
        }
        <div id="reports-list">
          <ReportList
            ppId={this.props.ppId}
            reportings={this.state.reportingList}
            setSelectedReportingId={id => this.setSelectedReporting(id)}
            onReportingCreated={this.onReportingCreated}
            empty={this.props.empty}
            isReportingEditing={this.props.isReportingEditing}
            showReportingCancelModal={this.showReportingCancelModal}
          />
        </div>
          {!this.state.isLoading
            ? <div id="report-detail">
              <Segment>
                <div id="detail-header">
                  <div id="description">
                    <div id="name">
                      {!this.props.isReportingEditing
                        ? (selectedRep && selectedRep.name)
                        : <Input
                          className={`${!this.state.formValidation.name && 'error'}`}
                          onChange={(e, name) => this.handleChangeName(name.value)}
                          defaultValue={selectedRep.name}
                        />
                      }
                    </div>
                    <div id="due-date">
                      {(selectedRep && selectedRep.closingDate)
                      ? <>
                        <FormattedMessage id="closingDate" defaultMessage="Closing date" />:&nbsp;
                        {Utils.displayDate(selectedRep.closingDate)}
                      </>
                      : this.props.isReportingEditing
                        ? <DateInput
                          name={'reportDueDate'}
                          className={`${!this.state.formValidation.date && 'error'}`}
                          popupPosition="bottom right"
                          startMode="month"
                          closable={true}
                          dateFormat={Utils.getDateFormat()}
                          value={Utils.displayDate(selectedRep.dueDate)}
                          onChange={(e: any, date: any) => this.handleChangeDueDate(date.value)}
                        />
                        : <>
                          <FormattedMessage id="dueDate" defaultMessage="Due date" />:&nbsp;
                          {Utils.displayDate(selectedRep.dueDate)}
                        </>
                      }
                    </div>
                    <div id="info">
                      <FormattedMessage
                        id="pp.reporting.explication"
                        defaultMessage="Once you are satisfied with the reporting of your plan,
                        close it to close the period and share it with the iBoost community!"
                      />
                    </div>
                  </div>
                  <div id="buttons">
                    {this.props.isReportingEditing ?
                      <>
                        <Button onClick={this.cancelEdition}>
                          <FormattedMessage id="cancel" defaultMessage="Cancel"/>
                        </Button>
                        <Button id="save-button" disabled={!this.isFormValid()} onClick={this.handleSaveEdition}>
                          <FormattedMessage id="pp.save" defaultMessage="Save"/>
                        </Button>
                      </>
                      : <>
                        {closeButton}
                        {!selectedRep.closingDate
                          && <div id="modification-button">
                            <Icon
                              className="icon"
                              name="pencil alternate"
                              onClick={() => this.props.setReportingEdition(true)}
                            />
                          </div>
                        }
                        <div id="deletion-button">
                          <Icon
                            className="icon"
                            name="trash alternate outline"
                            onClick={() => this.setState({ deleteModalOpen: true })}
                          />
                        </div>
                      </>
                    }
                  </div>

                </div>
                <div id="content-title">
                  <div id="title">
                    <ReportModalContentTitle filterSelected={this.state.selectedFilter}/>
                  </div>
                  <div>
                    <FormattedMessage id="filterBCs" defaultMessage="Filter BCs">
                      {msg =>
                        <Filters
                          dropdownName={msg.toString()}
                          arrayToFilter={!!this.state.selectedReporting
                            ? this.state.selectedReporting.bcReportings
                              .map((bcRep: BusinessChallengeReporting) => bcRep.bcSnapshot)
                            : []
                          }
                          filters={[FilterCategory.STATUS, FilterCategory.TOP5, FilterCategory.PILLAR]}
                          selectedFilter={this.state.selectedFilter}
                          setFilteredArray={this.filterBCs}
                        />
                      }
                    </FormattedMessage>
                  </div>
                </div>
                <div id="bc-list">
                  <div id="left-column" className="column">
                    {leftColumnContent}
                  </div>
                  <div className="column">
                    {rightColumnContent}
                  </div>
                </div>
              </Segment>
            </div>
          : <div id="report-detail-flex-version"> {Utils.loader('inside-flex')} </div>}
      </div>
    );
  }

}

export default withRouter(ReportingTab);
