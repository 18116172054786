import { EventEmitter } from 'events';

import ActionTypes from 'src/constants/actionTypes';
import BusinessChallengeErrors, { defaultBusinessChallengeErrors } from 'src/models/bc-errors';
import BusinessChallenge from 'src/models/businessChallenge';
import Action from '../actions/action';
import Dispatcher from '../dispatcher';
import Keywords from '../models/keywords';
import PerformanceInitiative from '../models/performanceInitiative';

class BusinessChallengeStore extends EventEmitter {

  private businessChallenge: BusinessChallenge;
  private oldBusinessChallenge: BusinessChallenge;
  private performanceInitiatives: PerformanceInitiative[];
  private keywords: Keywords[];
  private functions: Keywords[];
  private errors: BusinessChallengeErrors = { ...defaultBusinessChallengeErrors };

  /* NEW STORE FOR  IBOOST V2 */
  private isOpenNewPiModal: boolean;
  private isOpenImportPiModal: boolean;

  constructor() {
    super();
    Dispatcher.register(this.registerToActions.bind(this));
  }

  /**
   * Get Business Challenge
   * @return BusinessChallenge
   */
  public getBusinessChallenge(): BusinessChallenge {
    return this.businessChallenge;
  }

  /**
   * Get Old Business Challenge
   * @return BusinessChallenge
   */
  public getOldBusinessChallenge(): BusinessChallenge {
    return this.oldBusinessChallenge;
  }

  /**
   * Return boolean to know if new Pi modal is open
   * @returns {boolean}
   */
  public getIsOpenNewPiModal(): boolean {
    return this.isOpenNewPiModal;
  }

  /**
 * Return boolean to know if import Pi modal is open
 * @returns {boolean}
 */
  public getIsOpenImportPiModal(): boolean {
    return this.isOpenImportPiModal;
  }

  /**
   * Invalidate Business Challenge
   * @return void
   */
  public invalidateBusinessChallenge(): void {
    delete this.businessChallenge;
    delete this.oldBusinessChallenge;
  }

  /**
   * Get Keywords
   * @return Keywords[]
   */
  public getKeywords(): Keywords[] {
    return this.keywords;
  }

  /**
   * Get Functions (function has the same struct as keyword)
   * @return Keywords[]
   */
  public getFunctions(): Keywords[] {
    return this.functions;
  }

  /**
   * Get PIs
   * @return PerformanceInitiative[]
   */
  public getPIs(): PerformanceInitiative[] {
    return this.performanceInitiatives;
  }

   /**
   * Get Error
   * @return BusinessChallengeErrors
   */
  public getErrors(): BusinessChallengeErrors {
    return this.errors;
  }

  /**
   * Get Error
   * @return BusinessChallengeErrors[key]
   */
  public getError(key: string): BusinessChallengeErrors {
    return this.errors[key];
  }

   /**
   * Set Error BusinessChallengeErrors
   */
  public setError(error: string) {
    this.errors[error] = true;
    this.emit(ActionTypes.BUSINESS_CHALLENGE_ERRORS.toString());
  }

  /**
   * Clear Error BusinessChallengeErrors
   */
  public clearError(error: string) {
    this.errors[error] = false;
    this.emit(ActionTypes.BUSINESS_CHALLENGE_ERRORS.toString());
  }

  /**
   * Reset all Errors BusinessChallengeErrors
   */
  public resetError() {
    this.errors = { ...defaultBusinessChallengeErrors };
    this.emit(ActionTypes.BUSINESS_CHALLENGE_ERRORS.toString());
  }

  /**
   * Set Keywords
   */
  public setKeywords(keywords: Keywords[]) {
    this.keywords = keywords;
    this.emit(ActionTypes.BUSINESS_CHALLENGE_KEYWORDS.toString());
  }

  /**
   * Set Functions
   */
  public setFunctions(functions: Keywords[]) {
    this.functions = functions;
    this.emit(ActionTypes.BUSINESS_CHALLENGE_FUNCTION.toString());
  }

  /**
   * Set PIs
   */
  public setPIs(performanceInitiatives: PerformanceInitiative[]) {
    this.performanceInitiatives = performanceInitiatives;
    this.emit(ActionTypes.BUSINESS_CHALLENGE_GET_PIS.toString());
  }

  /**
   * Delete Business Challenge
   * @return void
   */
  private deleteBusinessChallenge(): void {
    this.emit(ActionTypes.BUSINESS_CHALLENGE_DELETED.toString());
  }

  public piLinkedToBc(): void {
    this.emit(ActionTypes.PI_LINKED_TO_BC.toString());
  }

  public reloadBcCockpit(): void {
    this.emit(ActionTypes.RELOAD_BC_COCKPIT.toString());
  }

  /**
   * set open new pi modal
   * @param {boolean} open
   * @returns {void}
   */
  private setIsOpenNewPiModal(open: boolean): void {
    this.isOpenNewPiModal = open;
    this.emit(ActionTypes.SET_OPEN_NEW_PI_MODAL.toString());
  }

  /**
   * set open import pi modal
   * @param {boolean} open
   * @returns {void}
   */
  private setIsOpenImportPiModal(open: boolean): void {
    this.isOpenImportPiModal = open;
    this.emit(ActionTypes.SET_OPEN_IMPORT_PI_MODAL.toString());
  }

  /**
   *  Set Business Challenge
   * @param businessChallenge: BusinessChallengeGet
   * @param isAfterSave: boolean (default: false)
   * @return void
   */
  private setBusinessChallenge(businessChallenge: BusinessChallenge, isAfterSave: boolean = false): void {
    this.businessChallenge = businessChallenge;
    if (this.oldBusinessChallenge === undefined
      || businessChallenge.id !== this.oldBusinessChallenge.id
      || isAfterSave) {
      this.oldBusinessChallenge = JSON.parse(JSON.stringify(businessChallenge));
    }
    this.emit(ActionTypes.BUSINESS_CHALLENGE_GET.toString());
  }

  /**
   *  Change Business Challenge
   * @return void
   */
  private changeBusinessChallenge(idAttribute: string, value: any): void {
    const bcTemp = { ...this.businessChallenge };

    if (idAttribute === 'keywords') {
      bcTemp.keywords.id = value;
    } else {
      bcTemp[idAttribute] = value;
    }

    this.businessChallenge = { ...bcTemp };
    this.emit(ActionTypes.BUSINESS_CHALLENGE_CHANGE.toString());
  }

  /**
   * Emit saved Business Challenge
   * @return void
   */
  private savedBusinessChallenge() {
    this.oldBusinessChallenge = this.businessChallenge;
    this.emit(ActionTypes.BUSINESS_CHALLENGE_SAVED.toString());
  }

  /**
   * Rollback changes Business Challenge
   * @return void
   */
  private rollbackBusinessChallenge() {
    this.businessChallenge = JSON.parse(JSON.stringify(this.oldBusinessChallenge));
    this.emit(ActionTypes.BUSINESS_CHALLENGE_CHANGE.toString());
  }

  /**
   * Performs a function according to an action
   * @param action :Action
   * @return void
   */
  private registerToActions(action: Action) {

    switch (action.actionType) {
      case ActionTypes.BUSINESS_CHALLENGE_GET:
        this.setBusinessChallenge(action.businessChallenge, action.isAfterSave);
        break;
      case ActionTypes.BUSINESS_CHALLENGE_CHANGE:
        this.changeBusinessChallenge(action.idAttribute, action.value);
        break;
      case ActionTypes.BUSINESS_CHALLENGE_ROLLBACK:
        this.rollbackBusinessChallenge();
        break;
      case ActionTypes.BUSINESS_CHALLENGE_DELETED:
        this.deleteBusinessChallenge();
        break;
      case ActionTypes.BUSINESS_CHALLENGE_KEYWORDS:
        this.setKeywords(action.keywords);
        break;
      case ActionTypes.BUSINESS_CHALLENGE_FUNCTION:
        this.setFunctions(action.functions);
        break;
      case ActionTypes.BUSINESS_CHALLENGE_GET_PIS:
        this.setPIs(action.businessChallenge.performanceInitiatives);
        break;
      case ActionTypes.BUSINESS_CHALLENGE_SAVED:
        this.savedBusinessChallenge();
        break;
      case ActionTypes.SET_OPEN_NEW_PI_MODAL:
        this.setIsOpenNewPiModal(action.isOpen);
        break;
      case ActionTypes.SET_OPEN_IMPORT_PI_MODAL:
        this.setIsOpenImportPiModal(action.isOpen);
        break;
    }
  }
}

export default new BusinessChallengeStore();
