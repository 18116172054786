import './performanceMeasures.scss';

import moment from 'moment';
import React, { SyntheticEvent } from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Button, Checkbox, Dropdown, Icon, Menu, Popup, Ref, Segment } from 'semantic-ui-react';
import ActionActions from 'src/actions/actions.action';
import ActivitiesActions from 'src/actions/activities.action';
import ActionTypes from 'src/constants/actionTypes';
import ModeTypes from 'src/constants/modeTypes';
import Action, { ActionEdit, ActionGet, ActionPost } from 'src/models/action';
import PerformanceInitiative from 'src/models/performanceInitiative';
import ActionStore from 'src/stores/action.store';
import ActivitiesStore from 'src/stores/activities.store';
import Utils from 'src/utils/utils';
import ConfirmModal from '../../confirmModal/confirmModal';
import PerformanceInitiativeActions from 'src/actions/performanceInitiative.action';
import Filters, { FilterCategory, FilterType } from 'src/components/common/form/filter/filter';
import AuthStore from 'src/stores/auth.store';
import { SortBy, sortOptions } from '../../../../../constants/interfaces/action.interface';
import { RouteComponentProps, withRouter } from 'react-router';
import Scrollbars from 'react-custom-scrollbars';
import { RightsOnPI } from 'src/models/rights';
import CustomScrollBars from '../../../../common/customScrollBars/customScrollBars';
import ActionTile from './actionTile/actionTile';
import UserStore from 'src/stores/user.store';
import Milestone from '../../../../../models/milestone';
import TypeActivity from '../../../../../constants/typeActivity';
import MilestonesList from '../../common/milestonesList/milestonesList';
import MilestoneAction from '../../../../../actions/milestone.action';
import PiTimelineContainer from './piTimelineContainer/piTimelineContainer';
import PerformanceInitiativeAPI from '../../../../../api/performanceInitiative.api';
import ActionUtils from '../../../../../utils/action.utils';
import MilestoneStore from '../../../../../stores/milestone.store';
import { defaultNewMilestone } from '../../../../../constants/milestones';
import NotificationUtils from '../../../../../utils/notification.utils';
import SegmentsStore from '../../../../../stores/segments.store';
import LinkedMilestone, { LinkedAction } from 'src/models/linkedMilestone';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ActionOrderBody, DraggableData } from 'src/constants/order';

const ACTION_HEIGHT = 98;

interface IRouteProps {
  atId: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  data: PerformanceInitiative;
  mode: ModeTypes;
  rightsOnPi: RightsOnPI | undefined;
  activityIdToScrollTo: number | undefined;

  toggleOverviewPanelVisibility(isVisible?: boolean): void;

  setOverviewData(activityId: number | null, type: TypeActivity.MILESTONE | TypeActivity.ACTION,
                  activity?: ActionEdit | Milestone): void;
}

interface IStates {
  loading: boolean;
  activeAction: number | null;
  actions: ActionEdit[];
  oldActions: ActionEdit[];
  mode: ModeTypes;
  atIdToRemove: number;
  filteredActions: ActionEdit[];
  selectedFilter: FilterType;
  showClosedActions: boolean;
  showOnlyMyActions: boolean;
  sortBy: SortBy;
  language: number;
  milestones: Milestone[];
  isOnListView: boolean;
  createActionMilestone: { createMilestones: boolean, createAction: boolean };
  isCancelModalVisible: boolean;
  actionLinkedMilestone: Map<number, LinkedMilestone[]>;
  milestoneLinkedAction: Map<number, LinkedAction[]>;
  isLoading: boolean; // for the Drag n Drop
}

class PerformanceMeasures extends React.Component<IProps & InjectedIntlProps, IStates> {
  private isMount: boolean = false;
  private scrollbarsRef = React.createRef<Scrollbars>();

  //region react lifecycle methods
  public constructor(props: IProps & InjectedIntlProps) {
    super(props);

    this.state = {
      loading: true,
      activeAction: 0,
      actions: [],
      filteredActions: [],
      oldActions: [],
      mode: ActivitiesStore.getMode(),
      atIdToRemove: 0,
      selectedFilter: FilterType.NONE,
      showClosedActions: true,
      showOnlyMyActions: false,
      sortBy: {
        value: sortOptions[0].value,
        order: 'ASC',
      },
      language: 1,
      milestones: [],
      isOnListView: true,
      createActionMilestone: { createMilestones: false, createAction: false },
      isCancelModalVisible: false,
      actionLinkedMilestone: new Map<number, LinkedMilestone[]>(),
      milestoneLinkedAction: new Map<number, LinkedAction[]>(),
      isLoading: false,
    };
    moment.locale(Utils.getCurrentUserLanguageName());
  }

  public componentWillMount() {
    this.isMount = true;
    this.setState({ loading: true });
    ActivitiesStore.addListener(ActionTypes.ACTIVITIES_GET_MODE.toString(), this.getMode);
    ActionStore.addListener(ActionTypes.ACTIONS_GET.toString(), this.getActions);
    ActionStore.addListener(ActionTypes.ACTION_SAVED.toString(), this.getMilestones);
    MilestoneStore.addListener(ActionTypes.GET_MILESTONES.toString(), this.getMilestones);
    MilestoneStore.addListener(ActionTypes.GET_LINK_MILESTONE_TO_ACTION.toString(), this.getLinkedMilestone);
    ActionStore.addListener(ActionTypes.GET_LINK_ACTION_TO_MILESTONE.toString(), this.getLinkedAction);
    ActionActions.getActions(this.props.data.id);
    this.setState({ language: UserStore.getLanguage() });
  }

  public componentWillUnmount() {
    this.isMount = false;
    ActivitiesStore.removeListener(ActionTypes.ACTIVITIES_GET_MODE.toString(), this.getMode);
    ActionStore.removeListener(ActionTypes.ACTIONS_GET.toString(), this.getActions);
    ActionStore.removeListener(ActionTypes.ACTION_SAVED.toString(), this.getMilestones);
    MilestoneStore.removeListener(ActionTypes.GET_MILESTONES.toString(), this.getMilestones);
    MilestoneStore.removeListener(ActionTypes.GET_LINK_MILESTONE_TO_ACTION.toString(), this.getLinkedMilestone);
    ActionStore.removeListener(ActionTypes.GET_LINK_ACTION_TO_MILESTONE.toString(), this.getLinkedAction);
  }

  public componentDidMount() {
    this.translateSortOptions();
    PerformanceInitiativeAPI.getPerformanceInitiativeMilestones(this.props.data.id)
      .then(milestones => this.setState({ milestones }));
  }
  //endregion

  //region class methods

  getLinkedMilestone = () => {
    if (this.isMount) {
      this.setState({ actionLinkedMilestone: MilestoneStore.getMilestoneLinkedAction() });
    }
  }

  getLinkedAction = () => {
    if (this.isMount) {
      this.setState({ milestoneLinkedAction: ActionStore.getActionLinkedMilestone() });
    }
  }

  hasPiActivities = () => {
    return (this.state.actions && this.state.actions.length > 0) ||
      (this.state.milestones && this.state.milestones.length > 0);
  }

  //region Action Part

  private scrollToAction = () => {
    let atId;
    if (this.props.history.location.search.includes('AT_ACTION')) {
      const indexActivity = this.props.history.location.pathname.search('activity');
      atId = +this.props.history.location.pathname.slice(indexActivity + 9);
    }
    if (this.props.history.location.search.includes('?ACTION=')) {
      const indexActivity = this.props.history.location.pathname.search('activity');
      atId = +this.props.history.location.pathname.slice(indexActivity + 9);
    }
    if (this.state.createActionMilestone.createAction) {
      atId = null;
    }
    if (atId || this.state.createActionMilestone.createAction) {
      const index = this.state.createActionMilestone.createAction ?
        0 : this.state.filteredActions.findIndex(at => at.id === parseInt(atId!.toString(), 10));
      const action = this.state.filteredActions[index];
      let minus = 0;
      if (action) {
        // If action is not closed, check if previous actions are closed to minus index
        for (let i = 0; i <= index; i += 1) {
          if (this.state.filteredActions[i].isClosed) {
            minus += 1;
          }
        }
        // Unfold action
        if (action.id) {
          this.setActiveAction(action.id);
        }
        setTimeout(() => {
          if (!!this.scrollbarsRef.current) {
            this.scrollbarsRef.current.scrollTop((index - minus) * ACTION_HEIGHT);
          }
        });
      }
    }
  };

  public getActions = () => {
    if (this.isMount) {
      const actions = ActionStore.getActions() as ActionEdit[];
      actions.forEach((at: ActionEdit) => {
        at.isStatusEditing = false;
      });
      this.setState(
        {
          actions,
          oldActions: JSON.parse(JSON.stringify(actions)),
          loading: false,
        },
        () => {
          this.setFilteredActions();
          this.scrollToAction();
          if (this.state.actions.length > 0 && this.props.rightsOnPi?.canEdit()) {
            this.onMovedMil({
              draggableId: 1,
              source: { droppableId: `${this.state.actions[0].id}`, index: 1 },
              destination: { droppableId: `${this.state.actions[0].id}`, index: 1 },
            });
          }
          setTimeout(
            () => {
              PerformanceInitiativeActions.emitChangePerformanceInitiative('actions', this.state.actions);
            },
            0,
          );
        },
      );
    }
  };

  public getMilestones = () => {
    if (this.isMount) {
      const milestones = MilestoneStore.getMilestones() as Milestone[];
      this.setState(
        {
          milestones,
          loading: false,
        },
        () => {
          setTimeout(
            () => {
              PerformanceInitiativeActions.emitChangePerformanceInitiative('milestones', this.state.milestones);
            },
            0,
          );
        },
      );
    }
  };

  /**
   * Save action in this.state.actions
   * @param {ActionEdit} action
   */
  public saveActionInState = (action: ActionEdit) => {
    let actions = [...this.state.actions];
    const acIndex = actions.findIndex(at => at.id === action.id);

    if (acIndex > -1) {
      actions[acIndex] = action;
    }

    if (action.id === null) {
      if (actions.findIndex(at => at.id === null)) {
        actions = [action, ...actions];
      } else {
        actions[0] = action;
      }
    }
    this.setState({ actions }, this.setFilteredActions);
    this.getLinkedMilestone();
    this.getLinkedAction();
  };

  public displaySave = (action: ActionEdit) => {
    NotificationUtils.sendActivityUpdatedToast(TypeActivity.ACTION);
    const oldActions = [...this.state.oldActions];
    const acIndex = oldActions.findIndex(at => at.id === action.id);
    if (acIndex > -1) {
      oldActions[acIndex] = action;
      this.setState({ oldActions });
    }
    const actions = [...this.state.actions];
    const actionIndex = actions.findIndex(at => at.id === action.id);
    actions[actionIndex] = action;
    this.setState({ actions, selectedFilter: FilterType.NONE }, () => {
      this.setFilteredActions();
      ActionActions.onActionSaved();
    });
  };

  /**
   * Save the action
   * @param {ActionEdit} data
   */
  public save = (data: ActionEdit) => {
    const action = ActionUtils.parseActionDate(data);

    // save in creation
    if (action.id === null) {
      const actions = [...this.state.actions];
      const newAction = { ...actions[0] as ActionPost };

      ActionActions.createAction(this.props.data.id, newAction).then((at) => {
        NotificationUtils.sendActivityCreatedToast(TypeActivity.ACTION);
        actions[0].id = at.id;
        actions[0].isFavourite = at.isFavourite;
        actions.push(actions[0]);
        actions.splice(0, 1);
        actions.sort((a, b) => a.code - b.code);

        const createActionMilestone = { ...this.state.createActionMilestone };
        createActionMilestone.createAction = false;
        this.setState({ createActionMilestone, actions }, this.setFilteredActions);
      });
      return;
    }

    // save in edition
    ActionActions.editAction(
      this.state.oldActions,
      action,
      this.props.data.id,
      this.state.oldActions.find(ac => ac.id === action.id),
    )
      .then(() => this.displaySave(action))
      .catch((err) => {
        if (err === 'duplicated codes') NotificationUtils.sendErrorDuplicateCodesToast(TypeActivity.ACTION);
        else Utils.toastError();
      });
  };

  /**
   * Close or Unclose the action
   * @param action: action to close
   */
  public toggleCloseAction = (action: ActionEdit) => {
    const actionToToggle = { ...action };
    actionToToggle.isClosed = !actionToToggle.isClosed;
    ActionActions.toggleClosedActionStatus(actionToToggle as Action).then(() => this.displaySave(actionToToggle));
  }

  /**
   * Cancel the edit and restore the old action
   * @param action
   */
  public cancel = (action: ActionEdit) => {
    if (action.id === null) {
      const filteredActions = [...this.state.filteredActions];
      const actions = [...this.state.actions];
      filteredActions.splice(0, 1);
      actions.splice(0, 1);

      const createActionMilestone = { ...this.state.createActionMilestone };
      createActionMilestone.createAction = false;

      this.setState({ actions, filteredActions, createActionMilestone });
      return;
    }
    const oldAction = { ...this.state.oldActions.find(ac => ac.id === action.id) } as ActionEdit;
    oldAction.rights = action.rights;
    if (oldAction) {
      this.saveActionInState(oldAction);
    }
  };

  /**
   * Call API to remove action
   */
  public removeAction = (atIdToRemove: number | null) => {
    if (!!atIdToRemove) {
      this.setState({ atIdToRemove });
      ActivitiesActions.emitChangeMode(ModeTypes.MODE_DELETE);
    }
  };

  public isCodeValid = (atId: number | null, code: number): boolean => {
    return ActionUtils.isCodeValid(this.state.actions, atId, code);
  };

  public setActiveAction = (atId: number | null) => {
    this.setState({ activeAction: atId });
  };

  public setLinkedMilestone = (atId: number, milestones: Milestone[]) => {
    const linkedMilestoneList = this.state.actionLinkedMilestone;
    const linkedMilestoneId = milestones.map((mi) => {
      const linkedMilestone = new LinkedMilestone();
      linkedMilestone.pimiId = mi.id!!;
      return linkedMilestone;
    });
    linkedMilestoneList.set(atId, linkedMilestoneId);
    this.setState({ actionLinkedMilestone: linkedMilestoneList });
  }

  public setLinkedAction = (miId: number, actions: Action[]) => {
    const linkedActionList = this.state.milestoneLinkedAction;
    const linkedActionId = actions.map((at) => {
      const linkedAction = new LinkedAction();
      linkedAction.atId = at.id;
      return linkedAction;
    });
    linkedActionList.set(miId, linkedActionId);
    this.setState({ milestoneLinkedAction: linkedActionList });
  }

  private titleActions = (): JSX.Element => {
    switch (this.state.selectedFilter) {
      case FilterType.CONFIDENT:
        return <FormattedMessage id="at.confidentActions" defaultMessage="Confident Actions"/>;
      case FilterType.AT_RISK:
        return <FormattedMessage id="at.atRiskActions" defaultMessage="At Risk Actions"/>;
      case FilterType.CRITICAL:
        return <FormattedMessage id="at.criticalActions" defaultMessage="Critical Actions"/>;
      default:
        return <FormattedMessage id="at.allActions" defaultMessage="All Actions"/>;
    }
  };

  private getMode = () => {
    if (this.isMount) {
      this.setState({ mode: ActivitiesStore.getMode() });
    }
  };

  /**
   * Confirm deletion for delete mode
   */
  private confirmDeletionCancel = (): void => {
    let actionCode;
    ActivitiesActions.emitChangeMode(ModeTypes.MODE_VIEW);
    ActionActions.removeAction(this.state.atIdToRemove)
      .then(() => {
        const actions = [...this.state.actions];
        const oldActions = [...this.state.oldActions];
        const oldActionDeletedIndex = oldActions.findIndex(at => at.id === this.state.atIdToRemove);
        const actionDeletedIndex = actions.findIndex(at => at.id === this.state.atIdToRemove);
        actionCode = actions[actionDeletedIndex].code;
        actions.splice(actionDeletedIndex, 1);
        oldActions.splice(oldActionDeletedIndex, 1);
        this.setState({ actions, oldActions }, () => {
          this.setFilteredActions();
          if (this.state.actions.length > 0) {
            this.onMovedMil({
              draggableId: 1,
              source: { droppableId: `${this.state.actions[0].id}`, index: 1 },
              destination: { droppableId: `${this.state.actions[0].id}`, index: 1 },
            });
          }
        });
      })
      .finally(() => {
        NotificationUtils.sendActivityDeletedToast(TypeActivity.ACTION, actionCode);
      });
  };

  /**
   * No Confirm deletion for delete mode
   */
  private noConfirmDeletionCancel = (): void => {
    ActivitiesActions.emitChangeMode(ModeTypes.MODE_EDITION);
  };

  private filterActions = (filteredArray: ActionEdit[], selectedFilter: FilterType) => {
    this.setState({ selectedFilter }, this.setFilteredActions);
  };

  private setFilteredActions = () => {
    let filteredActions = [...this.state.actions];

    if (this.state.selectedFilter !== FilterType.NONE) {
      filteredActions = filteredActions.filter(
        at => Utils.mapStatusToFilterType(at.status) === this.state.selectedFilter,
      );
    }

    if (!this.state.showClosedActions) {
      filteredActions = filteredActions.filter(at => !at.isClosed);
    }

    if (this.state.showOnlyMyActions) {
      filteredActions = filteredActions
        .filter(at => at.assignedAccounts.some(acc => acc.id === AuthStore.getConnectedUser().id));
    }

    this.setState({ filteredActions });
  };

  private toggleShowClosedActions = () => {
    this.setState({ showClosedActions: !this.state.showClosedActions }, this.setFilteredActions);
  };

  private toggleOnlyMyActions = () => {
    this.setState({ showOnlyMyActions: !this.state.showOnlyMyActions }, this.setFilteredActions);
  };

  private sortBy = (event: SyntheticEvent, data: SortBy) => {
    this.setState(
      {
        sortBy: {
          ...this.state.sortBy,
          value: data.value,
        },
      },
      this.getActionsWithSort);
  };

  private orderBy = () => {
    this.setState(
      prevState => (
        {
          sortBy: {
            ...prevState.sortBy,
            order: prevState.sortBy.order === 'ASC' ? 'DESC' : 'ASC',
          },
        }
      ),
      this.getActionsWithSort);
  };

  private getActionsWithSort = () => {
    ActionStore.setSortBy(this.state.sortBy);
    ActionActions.getActions(this.props.data.id, this.state.sortBy.value, this.state.sortBy.order);
  };

  /**
   * @description Get the translated text for the selected sort option
   * @returns string
   */
  private getSortByText = () => {
    if (sortOptions && sortOptions.length > 0) {
      const opt = sortOptions.filter(s => s.value === this.state.sortBy.value)[0];
      return this.props.intl.formatMessage({ id: opt.id, defaultMessage: opt.text });
    }
  };

  /**
   * @description Translate all sort options
   */
  private translateSortOptions = () => {
    for (const s of sortOptions) {
      s.text = this.props.intl.formatMessage({ id: s.id, defaultMessage: s.text });
    }
  };

  /**
   * @description Translate order by
   */
  translateOrder = () => {
    return this.state.sortBy.order === 'ASC'
      ? this.props.intl.formatMessage({ id: 'ascending', defaultMessage: 'Ascending' })
      : this.props.intl.formatMessage({ id: 'descending', defaultMessage: 'Descending' });
  };

  private createAction = () => {
    this.setActiveAction(null);
    const defaultActionGet: ActionGet = {
      id: null,
      name: '',
      code: 0,
      objectives: '',
      progress: '',
      assignedAccounts: [],
      targetDate: new Date(),
      attachments: [],
      isClosed: false,
      status: 3,
      isHidden: false,
      performanceInitiative: this.props.data,
    } as unknown as ActionGet;

    const defaultActionEdit: ActionEdit = {
      ...defaultActionGet,
      isStatusEditing: false,
      rights: this.props.rightsOnPi,
    } as unknown as ActionEdit;

    const piId = ActivitiesStore.getSelectedActivityId();
    PerformanceInitiativeAPI.getNextAvailableActionCode(piId)
      .then((result) => {
        if (result!! && result.code!!) {
          defaultActionEdit.code = result.code;
        }
      })
      .catch(() => Utils.toastError())
      .finally(() => {
        if (this.state.isOnListView) {
          const filteredActions = [...this.state.filteredActions];
          const actions = [...this.state.actions];

          filteredActions.unshift(defaultActionEdit);
          actions.unshift(defaultActionEdit);

          const createActionMilestone = { ...this.state.createActionMilestone };
          createActionMilestone.createAction = true;

          this.setState({ actions, filteredActions, createActionMilestone }, () => this.scrollToAction());
        } else {
          this.props.setOverviewData(null, TypeActivity.ACTION, defaultActionEdit);
        }
      });

  };

  private toggleToEdit(action: ActionEdit) {
    const oldActions = [...this.state.oldActions];
    oldActions.push(action);
    this.setState({ oldActions });
  }

  // endregion
  //region Milestones
  private toggleCreationMode = () => {
    const createActionMilestone = { ...this.state.createActionMilestone };
    createActionMilestone.createMilestones = !createActionMilestone.createMilestones;
    this.setState({ createActionMilestone });
  };

  private createNewMilestone = () => {
    if (this.state.isOnListView) {
      const createActionMilestone = this.state.createActionMilestone;
      createActionMilestone.createMilestones = true;
      this.setState({ createActionMilestone }, () => {
        MilestoneAction.emitCreateMilestone();
      });
    } else {
      this.props.setOverviewData(null, TypeActivity.MILESTONE, defaultNewMilestone);
    }
  };

  handleViewToggle = (isOnListView: boolean) => {
    if (this.state.isOnListView !== isOnListView && !this.isNavigationBlocked()) {
      this.props.toggleOverviewPanelVisibility(false);
      this.setState({ isOnListView });
    }
  }

  isNavigationBlocked = () => {
    if (SegmentsStore.hasSegmentsOnEdit() && SegmentsStore.hasPiAtMiSegmentOnEdit()) {
      this.setState({ isCancelModalVisible: true });
      return true;
    }
    return false;
  };

  onConfirmCancel = () => {
    this.setState(state => ({ isOnListView: !state.isOnListView }),
                  () => this.props.toggleOverviewPanelVisibility(false),
    );
  }

  onNotConfirmCancel = () => {
    this.setState({ isCancelModalVisible: false });
  }
  //endregion
  // Drag N Drop region

  private onMovedMil = (data: DraggableData) => {
    this.setState({ isLoading: true });
    const actions = this.state.actions;

    if (actions.length > 1) {
      const actionToMove = actions[data.source.index];
      const destination = data.destination ? data.destination.index : actions.length + 1;
      actions.splice(data.source.index, 1);
      actions.splice(destination, 0, actionToMove);
    }

    actions.forEach((m: ActionEdit, index: number) => m.code = index + 1);

    const body: ActionOrderBody[] = [];
    for (const at of actions) {
      body.push({
        id: at.id as number,
        code: at.code,
      });
    }

    const req = PerformanceInitiativeAPI.putPerformanceInitiativeAtsOrder(this.props.data.id, body);
    req
      .then(() => setTimeout(() => this.setState({ isLoading: false }), 500))
      .then(() => this.setFilteredActions())
      .catch(() => Utils.toastError());
  };
  //endregion

  public render() {
    if (this.state.loading) {
      return Utils.loader();
    }
    const nbOfActivities = this.state.milestones.length + this.props.data.actions.length;
    const cancelEditionTitle = <FormattedMessage id="cancelEdition" defaultMessage="Cancel edition" />;
    const cancelEditionMessage = (
      <FormattedMessage
        id="cancelEditionQuestion"
        defaultMessage="Cancel edition? Any unsaved work will be lost."
      />
    );
    return (
      <div id="action-milestones-tab">
        <div id="action-milestones-header">
          <div>
            <span id="all-activities-title">
              <FormattedMessage id="allActivities" defaultMessage="All Activities"/>
              &nbsp;({nbOfActivities})
            </span>
            <Button.Group>
              <Button
                color={!this.state.isOnListView ? 'instagram' : undefined}
                icon={true}
                onClick={() => this.handleViewToggle(false)}
              >
                <Icon name="calendar alternate"/>
              </Button>
              <Button
                color={this.state.isOnListView ? 'instagram' : undefined}
                icon={true}
                onClick={() => this.handleViewToggle(true)}
              >
                <Icon name="list"/>
              </Button>
            </Button.Group>
          </div>
          {this.props.rightsOnPi?.canEdit() &&
            <div>
              <Button
                id="addActionButton"
                color="instagram"
                onClick={this.createAction}
                disabled={this.state.createActionMilestone.createAction}
              >
                <FormattedMessage id="newAction" defaultMessage="New Action"/>
              </Button>
              <Button
                id="add-milestone-button"
                color="instagram"
                onClick={this.createNewMilestone}
                disabled={this.state.createActionMilestone.createMilestones}
              >
                <FormattedMessage id="newMilestone" defaultMessage="New Milestone"/>
              </Button>
            </div>

          }
        </div>
        <div id="action-milestones-body">
          {this.state.isOnListView ?
            <div id="action-milestones-containers">
              <div className="action-container">
                <div id="header">
                  <div id="title">
                    <span className="bold">
                      {this.titleActions()}
                    </span>
                    {Utils.isOnDeleteMode(this.state.mode) &&
                      <ConfirmModal
                        closeNoFunction={this.noConfirmDeletionCancel}
                        closeYesFunction={this.confirmDeletionCancel}
                        title="Confirm deletion"
                        message="Confirm deletion ?"
                      />
                    }
                    <div className="action-list-buttons">
                      <div className="toggle-show-my-actions">
                        <Checkbox
                          toggle={true}
                          checked={this.state.showOnlyMyActions}
                          onChange={this.toggleOnlyMyActions}
                          className="toggle-btn"
                        />
                        <FormattedMessage id="showMyActions" defaultMessage="My Actions Only"/>
                      </div>
                      <div className="toggle-show-closed-actions">
                        <Checkbox
                          toggle={true}
                          checked={this.state.showClosedActions}
                          onChange={this.toggleShowClosedActions}
                          className="toggle-btn"
                        />
                        <FormattedMessage id="showClosedActions" defaultMessage="Show Closed Actions"/>
                      </div>
                      <FormattedMessage id="filterActions" defaultMessage="Filter">
                        {msg =>
                          <Filters
                            dropdownName={msg.toString()}
                            arrayToFilter={this.state.filteredActions}
                            filters={[FilterCategory.STATUS]}
                            selectedFilter={this.state.selectedFilter}
                            setFilteredArray={this.filterActions}
                          />
                        }
                      </FormattedMessage>
                      <Menu id="sortBy">
                        <FormattedMessage id="sortByButtonTooltip" defaultMessage="Sort by">
                          {msg =>
                            <Dropdown
                              text={`${msg} : ${this.getSortByText()}`}
                              options={sortOptions}
                              item={true}
                              onChange={this.sortBy}
                            />
                          }
                        </FormattedMessage>
                      </Menu>
                      <FormattedMessage id="orderByButtonTooltip" defaultMessage="Order by">
                        {msg =>
                          <Popup
                            inverted={true}
                            content={`${msg} ${this.translateOrder()}`}
                            size="tiny"
                            position="top center"
                            trigger={
                              <Button id="btn-order" onClick={this.orderBy}>
                                <Icon
                                  name={this.state.sortBy.order === 'ASC' ? 'sort amount down' : 'sort amount up'}
                                />
                              </Button>}
                          />
                        }
                      </FormattedMessage>
                    </div>
                  </div>
                </div>
                <div id="action-list-area">
                  <CustomScrollBars customRef={this.scrollbarsRef}>
                    <div id="actions-container">
                      {this.state.filteredActions.length > 0 ?
                        <DragDropContext onDragEnd={this.onMovedMil}>
                          <Droppable droppableId="mil-area">
                            {provided => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {this.state.filteredActions
                                  .map((action: ActionEdit, index) => (
                                    <Draggable
                                      key={`mil-drag-${action.id}`}
                                      draggableId={`${action.id}`}
                                      index={index}
                                      isDragDisabled={this.state.isLoading || !this.props.rightsOnPi?.canEdit()}>
                                      {dragProvided => (
                                        <Ref innerRef={dragProvided.innerRef}>
                                          <Segment
                                            {...dragProvided.draggableProps}
                                            {...dragProvided.dragHandleProps}
                                            className="drag-mil"
                                          >
                                            <ActionTile
                                              key={`action-tile-${action.id}`}
                                              action={action}
                                              activeAction={this.state.activeAction}
                                              saveAction={this.saveActionInState}
                                              updateAction={this.save}
                                              cancelAction={this.cancel}
                                              toggleCloseAction={this.toggleCloseAction}
                                              isCodeValid={this.isCodeValid}
                                              removeAction={this.removeAction}
                                              setActiveAction={this.setActiveAction}
                                              toggleToEdit={action => this.toggleToEdit(action)}
                                              canEditPi={this.props.rightsOnPi
                                                ? this.props.rightsOnPi.canEdit() : false}
                                              isForPanelDisplay={false}
                                              milestones={this.state.milestones}
                                            />
                                          </Segment>
                                        </Ref>
                                    )}
                                  </Draggable>),
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                        : Utils.empty(
                          <FormattedMessage
                            id="noAction"
                            defaultMessage="No action was found for this Performance Initiative"
                          />,
                        )
                      }
                    </div>
                  </CustomScrollBars>
                </div>
              </div>
              <MilestonesList
                activityId={this.props.data.id}
                activityType={TypeActivity.PERFORMANCE_INITIATIVE}
                parentPpId={this.props.data.targetPpId}
                canEditOrderMil={this.props.rightsOnPi!.canEdit()}
                isCreate={this.state.createActionMilestone.createMilestones}
                toggleCreationMode={this.toggleCreationMode}
              />
            </div>
            :
            !this.hasPiActivities() ?
              Utils.empty(
                <FormattedMessage
                  id="noActivity"
                  defaultMessage="No activity was found for this Performance Initiative"
                />,
              )
              :
              <PiTimelineContainer
                actions={this.state.actions as Action[]}
                milestones={this.state.milestones}
                setOverviewData={this.props.setOverviewData}
                activityIdToScrollTo={this.props.activityIdToScrollTo}
              />
          }
        </div>
        {this.state.isCancelModalVisible
          && <ConfirmModal
            closeNoFunction={this.onNotConfirmCancel}
            closeYesFunction={this.onConfirmCancel}
            title={cancelEditionTitle}
            message={cancelEditionMessage}
          />
        }
      </div>
    );
  }
}

export default withRouter(injectIntl(PerformanceMeasures));
