import 'react-datepicker/dist/react-datepicker.css';

import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Form, Input } from 'semantic-ui-react';

import ModificationDropdown from 'src/components/common/form/modificationDropdown/modificationDropdown';
import BusinessChallenge from '../../../models/businessChallenge';
import { PerformanceIndicatorPost } from '../../../models/performanceInitiative';
import PerformancePlan from '../../../models/performancePlan';
import Textarea from '../../common/form/textarea/textarea';
import CodeInput from '../../common/form/codeInput/codeInput';
import EditorGenerator from '../../activitiesBoard/containerActivities/ckEditorGenerator';
import Utils from '../../../utils/utils';
import CustomScrollBars from '../../common/customScrollBars/customScrollBars';

interface IProps extends InjectedIntlProps {
  pp: PerformancePlan;
  bc: BusinessChallenge;
  pi: PerformanceIndicatorPost;
  keywords: any[];

  setNavigationDisabled(isNavigationDisabled: boolean): void;

  onUpdate(key: string, value: any): void;
}

interface IStates {
  isNameIncorrect: boolean;
  isCodeOnError: boolean;
  isEditorOnError: boolean;
}

class PiCreationStep1 extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      isNameIncorrect: false,
      isCodeOnError: false,
      isEditorOnError: false,
    };
  }

  private handleFormChange = (idAttribute: string,  value: string | boolean | number) => {
    console.log(idAttribute);
    this.props.onUpdate(idAttribute, value);
    this.validateForm(idAttribute);
  };

  private handleErrorOnEditor = (idElement: string, isFieldOnError: boolean, callback: Function) => {
    this.setState({ isEditorOnError: isFieldOnError }, callback());
  };

  private validateForm = (id: string) => {
    const isCodeOnError = Utils.isCodeOnError(this.props.bc.performanceInitiatives, this.props.pi.code);
    const isNameIncorrect = this.props.pi.name === '' || this.props.pi.name.trim().length === 0;

    let stateToUpdate = {};
    switch (id) {
      case 'code':
        stateToUpdate = { isCodeOnError };
        break;
      case 'name':
        stateToUpdate = { isNameIncorrect };
    }

    this.setState(stateToUpdate, () => {
      this.props.setNavigationDisabled(isCodeOnError || isNameIncorrect || this.state.isEditorOnError);
    });
  };

  public render(): React.ReactNode {
    const subject =
      this.props.intl.formatMessage({ id: 'piKeywordProposition', defaultMessage: 'PI keyword proposition' });

    return (
      <CustomScrollBars autoHeightMax={300} className="content-element-scrollbar">
        <Form id="piCreationStep1">
          <Form.Group widths="equal" className="pi-creation-field equal-width-fields">
            <FormattedMessage id="piNumber" defaultMessage="PI number">
              {msg => (
                <div>
                  <CodeInput
                    defaultValue={this.props.pi.code}
                    isCodeOnError={this.state.isCodeOnError}
                    label={`${msg} *`}
                    emitChange={(event: any, { value }) => this.handleFormChange(event.target.id, value)}
                  />
                  <div className={'little-message error'} hidden={!this.state.isCodeOnError}>
                    <FormattedMessage id="codeAlreadyTaken" defaultMessage="This code is already taken" />
                  </div>
                </div>
              )}
            </FormattedMessage>
            <Form.Field className="pi-creation-field">
              <FormattedMessage id="name" defaultMessage="Name">
                {msg => (
                  <Form.Field
                    id="name"
                    control={Input}
                    label={`${msg} *`}
                    maxLength={90}
                    value={this.props.pi.name}
                    onChange={(e: any, data: any) => this.handleFormChange('name', data.value)}
                    error={this.state.isNameIncorrect}
                  />
                )}
              </FormattedMessage>
              <div className={'little-message error'} hidden={!this.state.isNameIncorrect}>
                <FormattedMessage id="nameCantBeNull" defaultMessage="The name can't be null" />
              </div>
            </Form.Field>
            <FormattedMessage id="piKeywords" defaultMessage="PI keywords">
              {msg => (
                <Form.Field
                  id="keywords"
                  className="pi-creation-field"
                  control={ModificationDropdown}
                  options={this.props.keywords.filter(el => !el.isDeprecated)}
                  dropdownName={'keywordsId'}
                  defaultValue={this.props.pi.keywordsId ? this.props.pi.keywordsId : 2}
                  isValueNullable={true}
                  label={{
                    children: <span>{msg} {Utils.getKeywordTooltip(subject)}</span>,
                    htmlFor: 'keywords',
                  }}
                  placeholder={msg}
                  searchInput={{ id: 'keywords' }}
                  emitChange={(dropdownName: string, value: number) => this.handleFormChange(dropdownName, value)}
                />
              )}
            </FormattedMessage>
          </Form.Group>
          <Form.Field className="pi-creation-field">
            <label>
              <FormattedMessage id="objectives" defaultMessage="Objectives" />
            </label>
            <EditorGenerator
              data={this.props.pi.objectives}
              onChangeAction={this.handleFormChange}
              onErrorAction={this.handleErrorOnEditor}
              idElement={'objectives'}
              smallVersion={true}
            />
          </Form.Field>
          <Form.Field className="pi-creation-field">
            <label>
              <FormattedMessage id="budget" defaultMessage="Budget" />
            </label>
            <Textarea
              value={this.props.pi.budget}
              maxlength={500}
              onChange={(e, data) => this.handleFormChange('budget', data.value)}
              fixedHeight={true}
              height={100}
            />
          </Form.Field>
        </Form>
      </CustomScrollBars>
    );
  }
}

export default injectIntl(PiCreationStep1);
