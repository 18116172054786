export enum RoutesBaseUrl {
  PP = 'performance-plans',
  BC = 'business-challenges',
  PI = 'performance-initiatives',
}

export enum HistoryBaseUrl {
  PP = 'pp-history',
  BC = 'bc-history',
  PI = 'pi-history',
}
