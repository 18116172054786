import { EventEmitter } from 'events';
import ActionTypes from 'src/constants/actionTypes';
import ModeTypes from 'src/constants/modeTypes';
import TypeActivity, { MenuTypeSelected } from 'src/constants/typeActivity';
import PerformancePlan from 'src/models/performancePlan';

import Action from '../actions/action';
import Dispatcher from '../dispatcher';

class ActivitiesStore extends EventEmitter {

  private menuTypeSelected: MenuTypeSelected;
  private activities: PerformancePlan[];
  private selectedActivityId: number;
  private typeActivity: TypeActivity = TypeActivity.PERFORMANCE_PLAN;
  private mode: ModeTypes = ModeTypes.MODE_VIEW;

  constructor() {
    super();
    Dispatcher.register(this.registerToActions.bind(this));
  }

  /**
   * Get id activity
   * @return number
   */
  public getMenuTypeSelected(): number {
    return this.menuTypeSelected;
  }

  /**
   * Get id activity
   * @return number
   */
  public getSelectedActivityId(): number {
    return this.selectedActivityId;
  }

  /**
   * Get type activity
   * @return TypeActivity
   */
  public getTypeActivity(): TypeActivity {
    return this.typeActivity;
  }

  /**
   * Get Activities
   * @return PerformancePlan
   */
  public getActivities(): PerformancePlan[] {
    return this.activities;
  }

  /**
   * Get mode
   * @return ModeTypes
   */
  public getMode(): ModeTypes {
    return this.mode;
  }

  /**
   *  Set Mode
   * @param menuType: MenuTypeSelected
   * @return void
   */
  private setMenuTypeSelected(menuType: MenuTypeSelected): void {
    this.menuTypeSelected = menuType;
    this.emit(ActionTypes.MENU_TYPE_SELECTED.toString());
  }

  /**
   *  Set Mode
   * @param mode: ModeTypes
   * @return void
   */
  private setMode(mode: ModeTypes): void {
    this.mode = mode;
    this.emit(ActionTypes.ACTIVITIES_GET_MODE.toString());
  }

  /**
   *  Set Performance Plan
   * @param performancePlan: PerformancePlan
   * @return void
   */
  private setActivitiesMenu(performancePlan: PerformancePlan[]): void {
    this.activities = performancePlan;
    this.emit(ActionTypes.ACTIVITIES_GET_MENU.toString());
  }

  /**
   * Set Information of Selected Activity
   * @param typeActivity : TypeActivity
   * @param idActivity  : number
   * @return void
   */
  private setActivityInfo(typeActivity: TypeActivity, idActivity: number): void {
    this.typeActivity = typeActivity;
    this.selectedActivityId = idActivity;
    this.emit(ActionTypes.ACTIVITY_SELECTED.toString());
  }

  /**
   * Performs a function according to an action
   * @param action : Action
   * @return void
   */
  private registerToActions(action: Action) {
    switch (action.actionType) {
      case ActionTypes.ACTIVITIES_GET_MENU:
        this.setActivitiesMenu(action.performancePlan);
        break;
      case ActionTypes.ACTIVITY_SELECTED:
        this.setActivityInfo(action.typeActivity, action.idActivity);
        break;
      case ActionTypes.ACTIVITIES_GET_MODE:
        this.setMode(action.mode);
        break;
      case ActionTypes.MENU_TYPE_SELECTED:
        this.setMenuTypeSelected(action.menuType);
        break;
      case ActionTypes.ACTIVITY_PUBLISHED:
        this.emit(ActionTypes.ACTIVITY_PUBLISHED.toString());
        break;
      case ActionTypes.RELOAD_TITLE_ACTIVITY:
        this.emit(ActionTypes.RELOAD_TITLE_ACTIVITY.toString());
        break;
      case ActionTypes.UPDATED_ACTIVITY:
        this.emit(ActionTypes.UPDATED_ACTIVITY.toString());
        break;
    }
  }

}

export default new ActivitiesStore();
