import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import BusinessChallenge from '../../../models/businessChallenge';
import Utils from '../../../utils/utils';
import { Icon } from 'semantic-ui-react';
import CustomScrollBars from '../../common/customScrollBars/customScrollBars';

interface IProps {
  selectedBc: number;
  businessChallenges: BusinessChallenge[];
  isListLoading: boolean;
  setSelectedBc(bcId: number): void;
}

export default class ActivityImportStep2 extends React.Component<IProps> {

  public constructor(props: IProps) {
    super(props);
  }

  private onBcSelection = (selectedBc: number) => {
    this.props.setSelectedBc(selectedBc);
  }

  public render(): React.ReactNode {

    if (this.props.isListLoading) {
      return Utils.loader();
    }

    if (this.props.businessChallenges.length === 0) {
      return Utils.empty(<FormattedMessage id="noBC" defaultMessage="No BC was found for this Performance Plan" />);
    }

    return (
      <div id="activity-import-step2">
        <CustomScrollBars id="bc-container">
          {this.props.businessChallenges.map(bc =>
            <div
              key={`bc${bc.id}`}
              className={`tile ${this.props.selectedBc === bc.id ? 'selected' : ''}`}
              onClick={() => this.onBcSelection(bc.id)}
            >
              {this.props.selectedBc === bc.id && <Icon name="check" />} {Utils.leadingZero(bc.code)} - {bc.name}
            </div>,
          )}
        </CustomScrollBars>
      </div>
    );
  }
}
