import './moreInfo.scss';

import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import BusinessChallengeActions from 'src/actions/businessChallenge.action';
import ActionTypes from 'src/constants/actionTypes';
import ModeTypes from 'src/constants/modeTypes';
import BusinessChallenge from 'src/models/businessChallenge';
import Keywords from 'src/models/keywords';
import BusinessChallengeStore from 'src/stores/businessChallenge.store';
import Utils from '../../../../../utils/utils';
import PerformancePlanAPI from '../../../../../api/performancePlan.api';
import PerformancePlan from '../../../../../models/performancePlan';
import TypeActivity from 'src/constants/typeActivity';
import TeamView from '../../teamView/teamView';
import AttachmentsManagement from '../../attachmentsManagement/attachmentsManagement';
import BusinessChallengeErrors from 'src/models/bc-errors';
import BcPiAbout from '../../common/bcPiAbout/bcPiAbout';
import TeamSegment from '../../common/teamSegment/teamSegment';
import LastUpdateHighlight from '../../common/overviewPanel/panelContent/tabs/lastUpdatedInformations/lastUpdateHighlight';
import { RightsOnBC } from 'src/models/rights';

export interface IBcFormValid {
  isCodeValid: boolean;
  isNameValid: boolean;
  areAttachmentsValid: boolean;
}

interface IRouteProps {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  data: BusinessChallenge;
  mode: ModeTypes;
  rightsOnBc: RightsOnBC | undefined;
  isLinkedBc?: boolean;
}

interface IStates {
  activeBudget: boolean;
  activeKpi: boolean;
  keywords: Keywords[];
  functions: Keywords[]; // function has the same struct as keyword
  data: BusinessChallenge;
  exportLoading: boolean;
  errors: BusinessChallengeErrors;
}

class MoreInfo extends React.Component<IProps, IStates> {

  private isMount = false;

  public constructor(props: any) {
    super(props);
    this.state = {
      data: BusinessChallengeStore.getBusinessChallenge(),
      activeBudget: false,
      activeKpi: false,
      keywords: BusinessChallengeStore.getKeywords(),
      functions: BusinessChallengeStore.getFunctions(),
      exportLoading: false,
      errors: BusinessChallengeStore.getErrors(),
    };
  }

  public componentWillMount() {
    this.isMount = true;
    BusinessChallengeStore.addListener(ActionTypes.BUSINESS_CHALLENGE_GET.toString(), this.getBusinessChallenge);
    BusinessChallengeStore.addListener(ActionTypes.BUSINESS_CHALLENGE_CHANGE.toString(), this.getBusinessChallenge);

    if (!this.state.keywords) {
      BusinessChallengeActions.emitGetKeywords();
    }

    if (!this.state.functions) {
      BusinessChallengeActions.emitGetFunctions();
    }
  }

  public componentDidMount() {
    LastUpdateHighlight.highlightParam();
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>, snapshot?: any): void {
    if (Utils.isOnEditMode(this.props.mode)) {
      if (!this.state.data.performancePlan.businessChallenges) {
        PerformancePlanAPI.getPerformancePlanBcCodes(this.state.data.performancePlan.id)
          .then((pp: PerformancePlan) => {
            const data = { ...this.state.data };
            data.performancePlan.businessChallenges = pp.businessChallenges;
            this.setState({ data });
          });
      }
    }
  }

  public componentWillUnmount() {
    this.isMount = false;
    BusinessChallengeStore.removeListener(ActionTypes.BUSINESS_CHALLENGE_GET.toString(), this.getBusinessChallenge);
    BusinessChallengeStore.removeListener(ActionTypes.BUSINESS_CHALLENGE_CHANGE.toString(), this.getBusinessChallenge);

  }

  private getBusinessChallenge = () => {
    if (this.isMount) {
      const data: BusinessChallenge = BusinessChallengeStore.getBusinessChallenge();
      if (this.state.data.id === data.id) {
        this.setState({ data });
      }
    }
  };

  public canEdit = () : boolean => {
    return this.props.rightsOnBc ? this.props.rightsOnBc.canEdit() : false;
  }

  public canExportWhole = () : boolean => {
    return this.props.rightsOnBc ? this.props.rightsOnBc.canExportWhole() : false;
  }

  public render() {

    if (!this.state.data.performancePlan) {
      return <div />;
    }

    return (
      <div id="more-info-bc">
        <div className="more-info-top">
          <div className="column">
            <div className="element">
            <BcPiAbout
              canEdit={this.canEdit()}
              typeActivity={TypeActivity.BUSINESS_CHALLENGE}
              isLinkedBc={this.props.isLinkedBc}
            />
          </div>
        </div>
        <div className="column">
          <div className="element">
            <TeamSegment
              canEdit={this.canEdit()}
              canExportWhole={this.canExportWhole()}
              activityId={this.state.data.id}
              activityType={TypeActivity.BUSINESS_CHALLENGE}
            />
            </div>
            <div className="element">
              <AttachmentsManagement
                canEdit={this.canEdit()}
                activityId={+this.props.match.params.id}
                activityType={TypeActivity.BUSINESS_CHALLENGE}
              />
            </div>
          </div>
        </div>
        { this.props.rightsOnBc?.canViewCockpit() &&
        <TeamView activityId={this.state.data.id} activityType={TypeActivity.BUSINESS_CHALLENGE} /> }
      </div>
    );
  }
}

export default withRouter(MoreInfo);
