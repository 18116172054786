import './teamSegment.scss';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Segment } from 'semantic-ui-react';

import ExportSheetButton from '../../exportSheetButton/exportSheetButton';
import TypeActivity from '../../../../../constants/typeActivity';
import UserAvatar from '../../../../common/userAvatar/userAvatar';
import Utils from '../../../../../utils/utils';
import User, { AssignedUser, AssignedUsers, UsersAssignation } from '../../../../../models/user';
import UserSelection from '../../../../common/form/userSelection/userSelection';
import PerformancePlan from '../../../../../models/performancePlan';
import BusinessChallenge from '../../../../../models/businessChallenge';
import PerformanceInitiative from '../../../../../models/performanceInitiative';
import ModeTypes from '../../../../../constants/modeTypes';
import MailTeam from './mailTeam/mailTeam';
import LastUpdateHighlight from '../overviewPanel/panelContent/tabs/lastUpdatedInformations/lastUpdateHighlight';
import PerformancePlanAPI from '../../../../../api/performancePlan.api';
import BusinessChallengeAPI from '../../../../../api/businessChallenge.api';
import PerformanceInitiativeAPI from '../../../../../api/performanceInitiative.api';
import { AssignationType } from '../../../../../constants/account';
import SegmentEditButtons from '../segmentEditButtons/segmentEditButtons';
import AuthStore from '../../../../../stores/auth.store';
import NotificationActions from '../../../../../actions/notification-actions';
import { ToastType } from '../../../../common/toast/toast';
import SegmentsStore from '../../../../../stores/segments.store';
import { SegmentType } from '../../../../../models/segmentsMode';
import ActivitiesActions from 'src/actions/activities.action';

type DataType = PerformancePlan | BusinessChallenge | PerformanceInitiative;

interface IProps {
  activityId: number;
  activityType: TypeActivity;
  canEdit: boolean;
  canExportWhole: boolean;
}

interface IStates {
  oldData: DataType | undefined;
  data: DataType | undefined;
  mode: ModeTypes;
  isLoading: boolean;
}

enum ActivityFields {
  OWNER = 'owner',
  ASSIGNED = 'assignedAccounts',
  DEPUTIES = 'deputies',
  INFORMED = 'informedAccounts',
}

class TeamSegment extends Component<IProps, IStates> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      oldData: undefined,
      data: undefined,
      mode: SegmentsStore.getSegmentModeById(this.getSegmentType()),
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getData();
  }

  private getData = () => {
    let request;
    switch (this.props.activityType) {
      case TypeActivity.PERFORMANCE_PLAN:
        request = PerformancePlanAPI.getPerformancePlanAssignations(this.props.activityId);
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        request = BusinessChallengeAPI.getBusinessChallengeAssignations(this.props.activityId);
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        request = PerformanceInitiativeAPI.getPerformanceInitiativeAssignations(this.props.activityId);
        break;
      default:
        break;
    }

    request
      .then(data => this.setState({ data, oldData: data, isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  private changeMode = (mode: ModeTypes) => {
    this.setState({ mode });
  }

  private getSegmentType = (): SegmentType => {
    switch (this.props.activityType) {
      case TypeActivity.PERFORMANCE_PLAN:
        return SegmentType.PP_TEAM;
      case TypeActivity.BUSINESS_CHALLENGE:
        return SegmentType.BC_TEAM;
      case TypeActivity.PERFORMANCE_INITIATIVE:
      default:
        return SegmentType.PI_TEAM;
    }
  }

  //region Edit/Cancel/Save methods
  private onChange = (key: ActivityFields, value: User[]) => {
    const data = { ...this.state.data } as DataType;
    data[key] = key === ActivityFields.OWNER ? value[0] : value;
    this.setState({ data });
  }

  private onCancel = () => {
    const oldData = { ...this.state.oldData } as DataType;
    this.setState({ data: oldData });
  }

  private onSave = () => {
    const oldData = { ...this.state.oldData } as DataType;
    const data = { ...this.state.data } as DataType;
    const promises: Promise<AssignedUser[] | void >[] = [];

    const changes = {
      assignedAccounts: Utils.filterChanges(oldData.assignedAccounts, data.assignedAccounts),
      deputies: Utils.filterChanges(
        (oldData as BusinessChallenge | PerformanceInitiative).deputies || [],
        (data as BusinessChallenge | PerformanceInitiative).deputies || [],
      ),
      informedAccounts: Utils.filterChanges(oldData.informedAccounts, data.informedAccounts),
    };

    let assignationTypes: {
      assignedAccounts: AssignationType,
      deputies?: AssignationType,
      informedAccounts: AssignationType,
    };
    let postRequest;
    let deleteRequest;
    let oldOwnerId;
    let newOwnerId;

    switch (this.props.activityType) {
      case TypeActivity.PERFORMANCE_PLAN:
        assignationTypes = {
          assignedAccounts: AssignationType.PP_DEPUTY,
          informedAccounts: AssignationType.PP_INFORMED,
        };
        postRequest = PerformancePlanAPI.postPerformancePlanAssignees;
        deleteRequest = PerformancePlanAPI.deletePerformancePlanAssignees;

        oldOwnerId = (oldData as PerformancePlan).owner.id;
        newOwnerId = (data as PerformancePlan).owner.id;
        if (oldOwnerId !== newOwnerId) {
          promises.push(PerformancePlanAPI.postPerformancePlanOwner(this.props.activityId, newOwnerId));
        }
        break;

      case TypeActivity.BUSINESS_CHALLENGE:
        assignationTypes = {
          assignedAccounts: AssignationType.BC_LEADER,
          deputies: AssignationType.BC_DEPUTY,
          informedAccounts: AssignationType.BC_INFORMED,
        };
        postRequest = BusinessChallengeAPI.postBusinessChallengeAssignees;
        deleteRequest = BusinessChallengeAPI.deleteBusinessChallengeAssignees;
        break;

      case TypeActivity.PERFORMANCE_INITIATIVE:
        assignationTypes = {
          assignedAccounts: AssignationType.PI_LEADER,
          deputies: AssignationType.PI_DEPUTY,
          informedAccounts: AssignationType.PI_INFORMED,
        };
        postRequest = PerformanceInitiativeAPI.postPerformanceInitiativeAssignees;
        deleteRequest = PerformanceInitiativeAPI.deletePerformanceInitiativeAssignees;
        break;

      default:
        break;
    }

    const changeAssignations = (field: ActivityFields): void => {
      if (field !== ActivityFields.OWNER && assignationTypes[field] !== undefined) {
        if (changes[field].create.length !== 0) {
          promises.push(
            postRequest(this.props.activityId, new UsersAssignation(changes[field].create, assignationTypes[field]!)),
          );
        }
        if (changes[field].remove.length !== 0) {
          promises.push(
            deleteRequest(this.props.activityId, new UsersAssignation(changes[field].remove, assignationTypes[field]!)),
          );
        }
      }
    };

    changeAssignations(ActivityFields.ASSIGNED);
    changeAssignations(ActivityFields.DEPUTIES);
    changeAssignations(ActivityFields.INFORMED);

    return Promise.all(promises)
      .then((results) => {
        const invitedUsers = new AssignedUsers();

        results.forEach((result) => {
          if (result && Array.isArray(result)) invitedUsers.addUsersIfNotExist(result);
        });

        invitedUsers.dispatchAssignedUsers();

        const currentUserId = AuthStore.getConnectedUser().id;
        const hasUserAssignationsChanged = (field: ActivityFields): boolean => {
          const condition = user => user.id === currentUserId;
          return field !== ActivityFields.OWNER
            ? changes[field].create.some(condition) || changes[field].remove.some(condition)
            : false;
        };

        if ((oldOwnerId !== newOwnerId && (currentUserId === oldOwnerId || currentUserId === newOwnerId))
          || hasUserAssignationsChanged(ActivityFields.ASSIGNED)
          || hasUserAssignationsChanged(ActivityFields.DEPUTIES)
          || hasUserAssignationsChanged(ActivityFields.INFORMED)
        ) {
          Utils.updateCurrentUser();
        }

        this.reloadTitle(changes[ActivityFields.ASSIGNED]);

        this.getData();
        ActivitiesActions.emitUpdatedLastUpdate();

        NotificationActions.toast(
          <FormattedMessage id="saved" defaultMessage="Saved!" />,
          (
            <FormattedMessage
              id="assignationsSuccessfullySaved"
              defaultMessage="The assignations were successfully saved"
            />
          ),
          ToastType.SUCCESS,
        );
      })
      .catch(() => {
        Utils.toastError();
      });
  }

  private reloadTitle = (changes: { create: unknown[], edit: unknown[], remove: unknown[] }) => {
    if (changes.create.length > 0
      || changes.edit.length > 0
      || changes.remove.length > 0) {
      ActivitiesActions.emitReloadTitle();
    }
  }
  //endregion

  //region Users' display methods
  /**
   * Generate users' display for view mode
   * @param {DataType} activity
   * @param {ActivityFields} field
   * @param alternativeKey
   * @return {JSX.Element | JSX.Element[]}
   */
  generateUsersForView = (activity: DataType, field: ActivityFields, alternativeKey = false) => {
    let fieldKey;
    switch (field) {
      case ActivityFields.ASSIGNED:
        fieldKey = alternativeKey ? 'bcLeader' : 'assignee';
        break;
      case ActivityFields.DEPUTIES:
        fieldKey = 'deputy';
        break;
      case ActivityFields.INFORMED:
        fieldKey = 'informed';
        break;
    }
    return field === ActivityFields.OWNER
      ? <UserAvatar account={activity[field]} />
      : activity[field] && activity[field].map((account) => {
        return <UserAvatar account={account} key={`${fieldKey}${account.id}`} />;
      });
  }

  /**
   * Generate users' display for edition mode
   * @param {DataType} activity
   * @param {ActivityFields} field
   * @return {JSX.Element}
   */
  generateUsersForEdition = (activity: DataType, field: ActivityFields) => {
    const isFieldOwner = field === ActivityFields.OWNER;
    return (
      <UserSelection
        defaultValue={activity[field] ? isFieldOwner ? [activity[field]] : activity[field] : []}
        onChange={(users: User[]) => this.onChange(field, users)}
        ppReferentSelection={Utils.isActivityPp(this.props.activityType) && isFieldOwner}
      />
    );
  }

  /**
   * Generate users' display according to view mode
   * @param {DataType} activity
   * @param {ActivityFields} field
   * @return {JSX.Element | JSX.Element[]}
   */
  generateUsersDisplay = (activity: DataType, field: ActivityFields) => {
    return Utils.isOnViewOrDeletionMode(this.state.mode)
      ? this.generateUsersForView(activity, field)
      : this.generateUsersForEdition(activity, field);
  }
  //endregion

  //region Roles' display methods
  /**
   * Generate referent area according to activity type
   * @return {JSX.Element | JSX.Element[]}
   */
  generateReferentArea = () => {
    let activity;
    switch (this.props.activityType) {
      case TypeActivity.BUSINESS_CHALLENGE:
        activity = (this.state.data! as BusinessChallenge).performancePlan;
        return (activity && activity) && this.generateUsersForView(activity, ActivityFields.OWNER);
      case TypeActivity.PERFORMANCE_PLAN:
        return this.generateUsersDisplay(this.state.data!, ActivityFields.OWNER);
    }
  }

  /**
   * Generate bc leader area according to activity type
   * @return {JSX.Element | JSX.Element[]}
   */
  generateBcLeaderArea = () => {
    let activity;
    switch (this.props.activityType) {
      case TypeActivity.PERFORMANCE_INITIATIVE:
        activity = this.state.data! as PerformanceInitiative;
        return this.generateUsersForView(activity.businessChallenge, ActivityFields.ASSIGNED, true);
      case TypeActivity.BUSINESS_CHALLENGE:
        return this.generateUsersDisplay(this.state.data!, ActivityFields.ASSIGNED);
    }
  }

  /**
   * Generate deputies area according to activity type
   * @return {JSX.Element | JSX.Element[]}
   */
  generateDeputiesArea = () => {
    if (this.state.data) {
      switch (this.props.activityType) {
        case TypeActivity.BUSINESS_CHALLENGE:
        case TypeActivity.PERFORMANCE_INITIATIVE:
          return this.generateUsersDisplay(this.state.data!, ActivityFields.DEPUTIES);
        case TypeActivity.PERFORMANCE_PLAN:
          return this.generateUsersDisplay(this.state.data!, ActivityFields.ASSIGNED);
      }
    }
  }
  //endregion

  render() {
    const deputies = (
      <div id="deputies">
        <div className="grey-title">
          <FormattedMessage id="bc.info.deputies" defaultMessage="Deputies" />
        </div>
        {this.generateDeputiesArea()}
      </div>
    );

    const segmentType = this.getSegmentType();

    return (
      <Segment id={LastUpdateHighlight.getSegmentId(segmentType)} className="team-segment">
        {this.props.canEdit && <SegmentEditButtons
          mode={this.state.mode}
          segmentType={segmentType}
          changeMode={this.changeMode}
          onSave={this.onSave}
          onCancel={this.onCancel}
        />}

        {this.state.isLoading
          ? Utils.loader()
          : this.state.data
            && <>
              <div className="title-element">
                <FormattedMessage id="team" defaultMessage="Team" />
              </div>

              <div className="team-roles-row">
                {!Utils.isActivityPi(this.props.activityType) &&
                  <div id="referent">
                    <div className="grey-title">
                      <FormattedMessage id="pp.info.referent" defaultMessage="Referent(s)" />
                    </div>
                    {this.generateReferentArea()}
                  </div>
                }

                {!Utils.isActivityPp(this.props.activityType) &&
                  <div id="bc-leaders">
                    <div className="grey-title">
                      <FormattedMessage id="teamBC" defaultMessage="BC Leader(s)" />
                    </div>
                    {this.generateBcLeaderArea()}
                  </div>
                }

                {Utils.isActivityPi(this.props.activityType) &&
                  <div id="pi-leaders">
                    <div className={'grey-title'}>
                      <FormattedMessage
                        id="teamPI"
                        defaultMessage="PI Leader(s)"
                      />
                    </div>
                    {this.generateUsersDisplay(this.state.data!, ActivityFields.ASSIGNED)}
                  </div>
                }

                {Utils.isActivityPp(this.props.activityType) && deputies}
              </div>

              <div className="team-roles-row">
                {!Utils.isActivityPp(this.props.activityType) && deputies}

                <div id="informed">
                  <div className="grey-title">
                    <FormattedMessage id="teamInformed" defaultMessage="Shared with" />
                  </div>
                  {this.generateUsersDisplay(this.state.data!, ActivityFields.INFORMED)}
                </div>
              </div>

              <div className="bottom-buttons">
                {this.props.canExportWhole &&
                  <ExportSheetButton data={this.state.data!} mode={this.state.mode} type={this.props.activityType} />}
                <MailTeam activityId={this.props.activityId} activityType={this.props.activityType} />
              </div>
            </>
        }
      </Segment>
    );
  }
}

export default TeamSegment;
