import * as React from 'react';
import { Divider, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { PerformancePlanGetForBcImport } from '../../../models/performancePlan';
import Utils from '../../../utils/utils';
import CustomScrollBars from '../../common/customScrollBars/customScrollBars';

interface IProps {
  selectedPp: number;
  performancePlans: PerformancePlanGetForBcImport[];
  isListLoading: boolean;
  setSelectedPp(ppId: number, isSimilarPp: boolean): void;
}

export default class ActivityImportStep1 extends React.Component<IProps> {

  public constructor(props: IProps) {
    super(props);
  }

  private getPpTile = (pp: PerformancePlanGetForBcImport): JSX.Element => {
    return (
      <div className="pp-tile-container" key={`pp${pp.id}`}>
        <span
          key={`pp${pp.id}`}
          className={`tile ${this.props.selectedPp === pp.id ? 'selected' : ''}`}
          onClick={() => this.onPpSelection(pp.id, pp.isSimilarPp)}
        >
          {this.props.selectedPp === pp.id && <Icon name="check" />} {pp.name} <span className="year"> {pp.year} </span>
        </span>
      </div>
    );
  }

  private onPpSelection = (selectedPp: number, isSimilarPp: boolean) => {
    this.props.setSelectedPp(selectedPp, isSimilarPp);
  }

  public render(): React.ReactNode {
    const similarPlans = this.props.performancePlans.filter(pp => pp.isSimilarPp);
    const viewedPlans = this.props.performancePlans.filter(pp => !pp.isSimilarPp);

    if (this.props.isListLoading) {
      return Utils.loader();
    }

    if (this.props.performancePlans.length === 0) {
      return Utils.empty(<FormattedMessage id="noPP" defaultMessage="No Performance Plan was found" />);
    }

    return (
      <div id="activity-import-step1">
        {similarPlans.length > 0
          && <>
            <div className="category-title">
              <FormattedMessage
                id="importModal.bc.mostRecentSimilarPp"
                defaultMessage="Most recent similar Performance Plan"
              />:
            </div>
            <div>
              {this.getPpTile(similarPlans[0])}
            </div>
          </>
        }

        {similarPlans.length > 0 && viewedPlans.length > 0 && <Divider />}

        {viewedPlans.length > 0
          && <>
            <div className="category-title">
              <FormattedMessage id="importModal.bc.viewedPps" defaultMessage="Performance Plans you can view" />:
            </div>
            <CustomScrollBars id="viewed-pp-container" className={similarPlans.length === 0 ? 'large' : ''}>
              {viewedPlans.map(pp => this.getPpTile(pp))}
            </CustomScrollBars>
          </>
        }
      </div>
    );
  }
}
