enum AdministrationTab {
  DASHBOARD,
  GBU,
  GEOGRAPHIES,
  KEYWORDS,
  USERS,
  LOAD_TESTS,
  FEEDBACK_BUGS,
  FEEDBACK_SUGGESTIONS,
  FEEDBACK_OTHER,
  FEEDBACK_ARCHIVES,
  USER_GUIDE,
}

export default AdministrationTab;
