import HttpClient from '../utils/httpClient';

export default class TimelineApi {

  /**
    * get informations for timeline
   * @returns Promise<any[]>
   */
  public static getInformationsTimeline(type: number, year: number): Promise<any[]> {
    return HttpClient.get<any[]>(`timeline?type=${type}&year=${year}`);
  }
}
