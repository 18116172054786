import FeedbackAPI from '../api/feedback.api';
import ActionTypes from '../constants/actionTypes';
import Dispatcher from '../dispatcher';
import Feedback from '../models/feedback';

export default class FeedbackActions {

  /**
   * Post feedback from API and emit actions POST_FEEDBACK
   * @param feedback : Feedback
   * @return void
   */
  public static emitPostFeedback(feedback: Feedback): Promise<any> {
    return FeedbackAPI.postFeedback(feedback);
  }

  /**
   * Put statut feedback from API and emit actions PUT_FEEDBACK
   * @param fbId : number
   * @param status : number
   * @return void
   */
  public static emitPutFeedback(fbId: number, status: number): void {
    FeedbackAPI.putFeedback(fbId, status)
      .then(() => {
        Dispatcher.dispatch({
          actionType: ActionTypes.PUT_FEEDBACK,
        });
      });
  }

  /**
   * Get all feedbacks from API and emit actions GET_FEEDBACKS
   * @return void
   */
  public static emitGetFeedbacks(type: number, archived: boolean = false): void {
    FeedbackAPI.getFeedbacks(type, archived)
      .then((feedbacks: Feedback[]) => {
        Dispatcher.dispatch({
          feedbacks,
          actionType: ActionTypes.GET_FEEDBACKS,
        });
      });
  }

  /**
   * Get all feedbacks count from API and emit actions GET_FEEDBACKS_COUNT
   * @return void
   */
  public static emitGetFeedbacksCounts(): void {
    FeedbackAPI.getFeedbacksCounts()
      .then((feedbacksCounts: any) => {
        Dispatcher.dispatch({
          feedbacksCounts,
          actionType: ActionTypes.GET_FEEDBACKS_COUNTS,
        });
      });
  }
}
