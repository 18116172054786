import './customNodeText.scss';

import * as React from 'react';
import growth_logo from '../../../images/growth.png';
import comp_logo from '../../../images/comp.png';
import people_logo from '../../../images/people.png';
import PublicationLabel from '../../common/publicationLabel/publicationLabel';
import Utils from '../../../utils/utils';

class CustomNodeText extends React.Component<any, any> {
  render() {
    const { nodeData } = this.props;
    let img;
    let className;
    if (Utils.isActivityBc(nodeData.selectedActivity.type)) {
      switch (nodeData.selectedActivity.pillar) {
        case 1:
          img = <img src={growth_logo} alt="growth_logo" />;
          className = 'growth';
          break;
        case 2:
          img = <img src={comp_logo} alt="comp_logo" />;
          className = 'comp';
          break;
        case 3:
          img = <img src={people_logo} alt="people_logo" />;
          className = 'people';
          break;
      }
    }
    return (
      <div className="node">
        {img ? img : null}
        <span className={className}>
          {nodeData.name}
          <PublicationLabel publishedDate={nodeData.publishedDate} />
        </span>
      </div>
    );
  }
}

export default CustomNodeText;
