import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from 'semantic-ui-react';
import './exportCenter.scss';
import { ExportStepsOptions } from './interfaces/ExportStepsOptions';

interface IProps{
  exportStepsOptions: ExportStepsOptions;
  toggleMiAt: () => void;
  toggleMetrics: () => void;
}

export default class ExportOptionPptx extends React.Component<IProps> {

  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    return (
      <div>
        <div className="checkbox-cockpit">
          <Checkbox
            toggle={true}
            checked={this.props.exportStepsOptions.includeMiAt}
            onChange={this.props.toggleMiAt}
            className={'checkbox-cockpit-button'}
          />
          <FormattedMessage id="includeMiAt" defaultMessage="Display Milestones & Actions"/>
        </div>
        <div className="checkbox-cockpit">
          <Checkbox
            toggle={true}
            checked={this.props.exportStepsOptions.includeMetrics}
            onChange={this.props.toggleMetrics}
            className={'checkbox-cockpit-button'}
          />
          <FormattedMessage id="includeMetrics" defaultMessage="Display Metrics"/>
        </div>
      </div>
    );
  }
}
