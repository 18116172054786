import * as React from 'react';
import { Icon, Table } from 'semantic-ui-react';

import Metric from 'src/models/metric';
import ImportedLabel from '../../../../../metricsMilestones/shared/importedLabel';
import { MetricMilestone } from '../../../../../../../../constants/typeActivity';
import Utils from '../../../../../../../../utils/utils';
import ModeTypes from 'src/constants/modeTypes';
import BusinessChallengeAPI from '../../../../../../../../api/businessChallenge.api';

interface IProps {
  metric: Metric;
  metricBc: Metric;
  setMetric(id: number | undefined, image: string): void;
}

interface IStates {
  isImageOpened: boolean;
  image:string;
  mode: ModeTypes;
}

class MetricRow extends React.Component<IProps, IStates> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      mode: ModeTypes.MODE_VIEW,
      image: '',
      isImageOpened: false,
    };
  }

  async componentDidMount() {
    if (this.props.metricBc && this.props.metricBc.hasImage) {
      await this.getMetricImage();
    }
  }

  toggleViewChartModal = () => {
    this.props.setMetric(this.props.metric.id, this.state.image);
  };

  private getMetricImage = async () => {
    if (!this.props.metricBc.id) {
      return;
    }
    try {
      let image;
      // Get Pi metric image if it's a linked metric
      image = await BusinessChallengeAPI.getBusinessChallengeMetricImage(this.props.metricBc.id);

      this.setState({
        image: URL.createObjectURL(image),
      });
    } catch (error) {
      Utils.toastError();
    }
  };

  public render() {
    let hasImage;
    const metric = this.props.metric;
    const metricBc = this.props.metricBc;
    hasImage = metricBc !== undefined ? metricBc.hasImage : false;
    const hasId = metric.id !== undefined;

    return (
        <Table.Row key={`metrics${metric.id}`}>
          <Table.Cell>
            {metric.name} {metric.unit && `(${metric.unit})`}
            {metric.isFromLinked &&
                <ImportedLabel
                    ppId={metric.performanceInitiative!!.businessChallenge.performancePlanId}
                    piId={metric.performanceInitiative!!.id}
                    metricMilestone={metric}
                    type={MetricMilestone.METRIC}
                />}
          </Table.Cell>
          <Table.Cell className={'bold-item'}>
            {metric.mostRecentValues.current}
          </Table.Cell>
          <Table.Cell className={'bold-item'}>
            {metric.mostRecentValues.target}
          </Table.Cell>
          <div className="metrics-chart">
            {Utils.isOnViewMode(this.state.mode) &&
                <>
                  { hasImage && hasId ?
                      <Table.Cell className={'bold-item'}>
                        <Icon
                            name="expand arrows alternate"
                            onClick={this.toggleViewChartModal}
                        />
                      </Table.Cell>
                      :
                      <></>
                  }
                  <Table.Cell className={'bold-item'}>
                    {metric.mostRecentValues.hasImage}
                  </Table.Cell>
                </>
            }
          </div>
        </Table.Row>
    );
  }
}

export default MetricRow;
