import PerformancePlanAPI from 'src/api/performancePlan.api';
import ActionTypes from 'src/constants/actionTypes';
import Dispatcher from 'src/dispatcher';
import { PerformancePlanPut } from 'src/models/performancePlan';
import ReportingGet, { ReportingGetFormatted, ReportingPost, ReportingPut } from 'src/models/reporting';

import ActivitiesActions from './activities.action';
import HistoryActions from './history.action';
import ReportingAPI from '../api/reporting.api';
import { PpErrors } from '../constants/errors/performance-plan.error';
import { AssignedUser, AssignedUsers, UsersAssignation } from '../models/user';
import { AssignationType } from '../constants/account';

export default class PerformancePlanActions {

  public static emitValidateDraft(ppId: number): Promise<void> {
    return PerformancePlanAPI.patchPerformancePlanValidateDraft(ppId)
      .then(() => {
        Dispatcher.dispatch({
          actionType: ActionTypes.VALIDATE_DRAFT,
        });
        ActivitiesActions.emitGetActivities();
      });
  }

  public static emitPublish(ppId: number, name: string): Promise<any> {
    return PerformancePlanAPI.postPerformancePlanPublish(ppId, name)
      .then(() => {
        Dispatcher.dispatch({
          actionType: ActionTypes.ACTIVITY_PUBLISHED,
        });
        HistoryActions.emitGetHistoryVersions('performance-plans', ppId);
      });
  }

  /**
   * Create a new performance plan and emit actions PERFORMANCE_PLAN_SAVED
   * @param {any} pp the performance plan to create
   */
  public static async emitCreatePerformancePlan(pp: PerformancePlanPut) {
    const newPp = await PerformancePlanAPI.postPerformancePlan(pp);

    if (!!newPp) {
      const promises: Promise<void | AssignedUser[]>[] = [];

      const assignedUsers = new AssignedUsers();
      if (pp.assignedAccounts && pp.assignedAccounts.length > 0) {
        const deputiesEmails = await PerformancePlanAPI.postPerformancePlanAssignees(
          newPp.id, new UsersAssignation(pp.assignedAccounts, AssignationType.PP_DEPUTY));
        if (deputiesEmails) assignedUsers.addUsersIfNotExist(deputiesEmails);
      }
      if (pp.informedAccounts && pp.informedAccounts.length > 0) {
        const informedEmails = await PerformancePlanAPI.postPerformancePlanAssignees(
          newPp.id, new UsersAssignation(pp.informedAccounts, AssignationType.PP_INFORMED));
        if (informedEmails) assignedUsers.addUsersIfNotExist(informedEmails);
      }

      await Promise.all(promises);

      assignedUsers.dispatchAssignedUsers();
      await ActivitiesActions.emitGetActivities();
      Dispatcher.dispatch({ actionType: ActionTypes.PERFORMANCE_PLAN_SAVED });

      return newPp;
    }

    return Promise.reject(PpErrors.PP_NOT_FOUND);
  }

  /**
   * Delete Performance Plan from API and emit actions PERFORMANCE_PLAN_DELETED
   * @param ppId : number
   * @return void
   */
  public static emitRemovePerformancePlan(ppId: number): void {
    PerformancePlanAPI.deletePerformancePlanById(ppId)
      .then(() => {
        Dispatcher.dispatch({
          actionType: ActionTypes.PERFORMANCE_PLAN_DELETED,
        });
      });
  }

  /**
   * Get all options for a Performance Plan
   * @return void
   */
  public static emitGetPpEntitiesLocations() {
    PerformancePlanAPI.getPpEntitiesLocations()
      .then((options) => {
        Dispatcher.dispatch({
          options,
          actionType: ActionTypes.PERFORMANCE_PLAN_ENTITIES_LOCATIONS_GET,
        });
      });
  }

  /**
   * Get all years available for Performance Plans
   * @return void
   */
  public static emitGetPerformancePlanYears() {
    PerformancePlanAPI.getPerformancePlanYears()
      .then((years) => {
        Dispatcher.dispatch({
          years,
          actionType: ActionTypes.PERFORMANCE_PLAN_YEARS_GET,
        });
      });
  }

  /**
   * Get all Performance Plan Reportings
   * @param ppId : number
   * @return void
   */
  public static emitGetPerformancePlanReportings(ppId: number) {
    ReportingAPI.getReportingsFromPerformancePlan(ppId)
      .then((performancePlanReportings: ReportingGet[]) => {
        const ppReps = performancePlanReportings.map(rep => new ReportingGetFormatted(rep));
        Dispatcher.dispatch({
          performancePlanReportings: ppReps,
          actionType: ActionTypes.PERFORMANCE_PLAN_REPORTINGS_GET,
        });
      });
  }

  /**
   * Change Performance Plan Reporting in Store
   * @return void
   */
  public static emitEditPerformancePlanReporting(reporting: ReportingPut): Promise<any> {
    return ReportingAPI.putReportingBasic(reporting)
      .then(() => {
        Dispatcher.dispatch({
          actionType: ActionTypes.PERFORMANCE_PLAN_REPORTING_EDIT,
        });
      });
  }

  /**
   * Create new report on Performance Plan
   * @return void
   */
  public static emitPerformancePlanNewReport(ppId: number, report: ReportingPost): Promise<any> {
    return ReportingAPI.postReporting(ppId, report)
      .then((reporting) => {
        Dispatcher.dispatch({
          reporting,
          actionType: ActionTypes.PERFORMANCE_PLAN_NEW_REPORTING,
        });
      });
  }

  /**
   * Force the application to fetch PP's BCs
   */
  public static invalidateBcs() {
    Dispatcher.dispatch({
      actionType: ActionTypes.PERFORMANCE_PLAN_INVALIDATE_BCS,
    });
  }

  /**
   * Indicate that the most recent PP history has been archived
   */
  public static onMostRecentHistoryDeletion() {
    Dispatcher.dispatch({
      actionType: ActionTypes.MOST_RECENT_PP_HISTORY_DELETED,
    });
  }
}
