import ActionTypes from 'src/constants/actionTypes';
import Dispatcher from 'src/dispatcher';

export default class RightsActions {

  /**
 * Set pp rights
 * @return {void}
 */
  public static emitSetPpRights(rights): void {
    Dispatcher.dispatch({
      rights,
      actionType: ActionTypes.SET_PP_RIGHTS,
    });
  }

  /*
 * Set bc rights
 * @return {void}
 */
  public static emitSetBcRights(rights): void {
    Dispatcher.dispatch({
      rights,
      actionType: ActionTypes.SET_BC_RIGHTS,
    });
  }

  /*
  * Set pi rights
  * @return {void}
  */
  public static emitSetPiRights(rights): void {
    Dispatcher.dispatch({
      rights,
      actionType: ActionTypes.SET_PI_RIGHTS,
    });
  }
}
