import { LoadTestScenario } from '../../../../models/loadTestsResults';
import { FormattedMessage } from 'react-intl';
import Utils from '../../../../utils/utils';
import * as React from 'react';
import { Accordion, Divider, Icon, Segment, Table } from 'semantic-ui-react';
import { ColorClass, Timing, Status } from './enum/loadTest.enum';

interface IProps {
  scenario: LoadTestScenario;
  index: number;
  activeIndex: number;

  setActiveIndex(i: number): void;
}

// tslint:disable-next-line:variable-name
export const Scenario = (props: IProps) => {
  const { scenario, index, activeIndex, setActiveIndex } = props;
  const isActive = activeIndex === index;

  const displayTime = (value: number) => {
    let color;
    if (value < Timing.LOW) {
      color = ColorClass.LOW;
    } else if (value >= Timing.LOW && value < Timing.HIGH) {
      color = ColorClass.MEDIUM;
    } else if (value >= Timing.HIGH) {
      color = ColorClass.HIGH;
    }

    return <span className={color}>{value}ms</span>;
  };

  const displayNumber = (value: number, status = Status.NONE) => {
    let color = '';
    if (status === Status.OK) {
      color = ColorClass.LOW;
    } else if (status === Status.KO) {
      color = ColorClass.HIGH;
    }

    return <span className={color}>{value}</span>;
  };

  return (
    <Segment>
      <Accordion.Title
        active={isActive}
        index={index}
        onClick={() => setActiveIndex(index)}
      >
        <div className="title-content">
          <div>
            <Icon name="chevron right" className={isActive ? 'active' : ''} />
            <span className="scenario-name">
                {scenario.scenarioName}
              </span>
          </div>

          <div className="right-container">
              <span>
                <FormattedMessage id="loadTestsResults.testDate" defaultMessage="Test date:" />
                &nbsp;
                <strong>{Utils.displayFancyDate(scenario.date, true)}</strong>
              </span>

            <span>
                <FormattedMessage id="loadTestsResults.testDuration" defaultMessage="Test duration:" />
              &nbsp;
              <strong>{scenario.durationTime}ms</strong>
              </span>
          </div>
        </div>

        {isActive && <Divider />}
      </Accordion.Title>
      <Accordion.Content active={isActive}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell rowSpan={2} className="first-col">
                <FormattedMessage id="name" defaultMessage="Name" />
              </Table.HeaderCell>

              <Table.HeaderCell colSpan={3}>
                <FormattedMessage id="loadTestsResults.numberOfRequests" defaultMessage="Number of requests" />
              </Table.HeaderCell>

              <Table.HeaderCell rowSpan={2}>
                <FormattedMessage id="loadTestsResults.minResponseTime" defaultMessage="Min. response time" />
              </Table.HeaderCell>

              <Table.HeaderCell rowSpan={2}>
                <FormattedMessage id="loadTestsResults.maxResponseTime" defaultMessage="Max. response time" />
              </Table.HeaderCell>

              <Table.HeaderCell rowSpan={2}>
                <FormattedMessage id="loadTestsResults.meanResponseTime" defaultMessage="Mean response time" />
              </Table.HeaderCell>
            </Table.Row>

            <Table.Row>
              <Table.HeaderCell>
                <FormattedMessage id="loadTestsResults.ok" defaultMessage="OK" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="loadTestsResults.ko" defaultMessage="KO" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="loadTestsResults.total" defaultMessage="Total" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {scenario.requests.map((request, index: number) =>
               <Table.Row key={`request-row-${request.name}-${index}`}>
                 <Table.Cell className="first-col">
                   {request.name}
                 </Table.Cell>

                 <Table.Cell>
                   {displayNumber(request.stats.numberOfRequests.ok, Status.OK)}
                 </Table.Cell>

                 <Table.Cell>
                   {displayNumber(request.stats.numberOfRequests.ko, Status.KO)}
                 </Table.Cell>

                 <Table.Cell>
                   {displayNumber(request.stats.numberOfRequests.ok +
                                    request.stats.numberOfRequests.ko)}
                 </Table.Cell>

                 <Table.Cell>
                   {displayTime(request.stats.minResponseTime.ok)}
                 </Table.Cell>

                 <Table.Cell>
                   {displayTime(request.stats.maxResponseTime.ok)}
                 </Table.Cell>

                 <Table.Cell>
                   {displayTime(request.stats.meanResponseTime.ok)}
                 </Table.Cell>
               </Table.Row>,
            )}
          </Table.Body>
        </Table>
      </Accordion.Content>
    </Segment>
  );
};
