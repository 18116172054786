import './feedbackContainer.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Select } from 'semantic-ui-react';

import FeedbacksUtils from 'src/utils/feedbacksUtils';
import Textarea from '../../common/form/textarea/textarea';
import Feedback from '../../../models/feedback';

interface IProps {
  feedback: Feedback;

  onChange(idAttribute: string, value: any): void;
}

class FeedbackContainer extends React.Component<IProps> {

  public render() {
    return (
      <div id="feedback-container">
        <Form>
          <Form.Group widths="equal">
            <div id="choice-lists">
              <FormattedMessage
                id="feedback.type"
                defaultMessage="Type*"
              >
                {msg => (
                  <Form.Field
                    id="type"
                    required={true}
                    label={msg}
                    control={Select}
                    options={FeedbacksUtils.typeOptions}
                    placeholder={msg}
                    value={this.props.feedback.type}
                    onChange={(e: any, { value }: any) => this.props.onChange('type', value)}
                  />
                )}
              </FormattedMessage>
              <FormattedMessage
                id="feedback.page"
                defaultMessage="Page"
              >
                {msg => (
                  <Form.Field
                    id="page"
                    label={msg}
                    control={Select}
                    options={FeedbacksUtils.pageOptions}
                    placeholder={msg}
                    value={this.props.feedback.page}
                    onChange={(e: any, { value }: any) => this.props.onChange('page', value)}
                  />
                )}
              </FormattedMessage>

              {this.props.feedback.page
                ? <FormattedMessage
                  id="feedback.tab"
                  defaultMessage="Tab"
                >
                  {msg => (
                    <Form.Field
                      id="tab"
                      label={msg}
                      control={Select}
                      options={this.props.feedback.page ? FeedbacksUtils.getTabList(this.props.feedback.page) : []}
                      placeholder={msg}
                      value={this.props.feedback.tab}
                      onChange={(e: any, { value }: any) => this.props.onChange('tab', value)}
                    />
                  )}
                </FormattedMessage>
                : null
              }

            </div>
            <FormattedMessage
              id="feedback.comment"
              defaultMessage="Comment"
            >
              {(msg =>
                  <Form.Field id="comment" required={true}>
                    <label>{msg}</label>
                    <Textarea
                      value={this.props.feedback.comment}
                      maxlength={500}
                      onChange={(e: any, { value }: any) => this.props.onChange('comment', value)}
                      placeholder={`${msg}...`}
                    />
                  </Form.Field>
              )}
            </FormattedMessage>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default FeedbackContainer;
