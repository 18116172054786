import { LastUpdatedInformation } from 'src/components/activitiesBoard/containerActivities/common/overviewPanel/panelContent/tabs/lastUpdatedInformations/interface/lastUpdatedInformations.interface';
import HttpClient from 'src/utils/httpClient';

export default class LoggingAPI {

  /**
   * ROUTE : /logging/new
   * Send and error to be logged in iboost error log Service
   * @returns Promise<any>
   */
  public static sendErrorToLoggingService(error): Promise<any> {
    return HttpClient.post<any>('logging/new', error);
  }

  /**
   * ROUTE : /logging/pp/:ppId
   * Get the last 10 PP logging updated informations
   * @param ppId number
   * @returns Promise<LastUpdatedInformation[]>
   */
  public static getLastPpUpdatedInformations(ppId: number): Promise<LastUpdatedInformation[]> {
    return HttpClient.get<LastUpdatedInformation[]>(`logging/pp/${ppId}`);
  }

  /**
 * ROUTE : /logging/bc/:bcId
 * Get the last 10 BC logging updated informations
 * @param bcId number
 * @returns Promise<LastUpdatedInformation[]>
 */
  public static getLastBcUpdatedInformations(bcId: number): Promise<LastUpdatedInformation[]> {
    return HttpClient.get<LastUpdatedInformation[]>(`logging/bc/${bcId}`);
  }

  /**
   * ROUTE : /logging/pi/:piId
   * Get the last 10 PI logging updated informations
   * @param piId number
   * @returns Promise<LastUpdatedInformation[]>
   */
  public static getLastPiUpdatedInformations(piId: number): Promise<LastUpdatedInformation[]> {
    return HttpClient.get<LastUpdatedInformation[]>(`logging/pi/${piId}`);
  }
}
