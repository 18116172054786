import jwt from 'jsonwebtoken';

import Constants from '../constants/actionTypes';
import Dispatcher from '../dispatcher';
import HttpClient from '../utils/httpClient';
import AuthStore, { StorageKeys } from '../stores/auth.store';
import { LoginMethod } from '../constants/loginTypes';
import AuthAPI from '../api/auth.api';

export default class AuthActions {

  public static setUserFromJson(json: any) {
    Dispatcher.dispatch({
      user: json.user,
      actionType: Constants.AUTH_LOGIN,
    });
    this.determineLoginMethodFromToken();
  }

  public static determineLoginMethodFromToken() {
    const token = this.getToken();
    if (token) {
      const decoded = jwt.decode(token);

      if (decoded && decoded['using_login_pw']) {
        this.setLoginMethod(LoginMethod.PASSWORD);
      } else {
        this.setLoginMethod(LoginMethod.SSO);
      }
    } else {
      this.setLoginMethod(LoginMethod.UNDEFINED);
    }
  }

  public static getToken() {
    return AuthStore.getItemInStorage(StorageKeys.TOKEN);
  }

  public static getRefreshToken(): string | null {
    return AuthStore.getItemInStorage(StorageKeys.REFRESH_TOKEN);
  }

  public static setLoginMethod(loginMethod: LoginMethod) {
    Dispatcher.dispatch({
      loginMethod,
      actionType: Constants.LOGIN_METHOD,
    });
  }

  public static async loginPassword(email: string, password: string) {
    return HttpClient.noToken()
    .post('auth/login', { email, password, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
      .then((json: any) => {
        this.setTokens(json);
        Dispatcher.dispatch({
          user: json.user,
          token: json.token,
          refreshToken: json.refreshToken,
          actionType: Constants.AUTH_LOGIN,
        });
      });
  }

  public static setTokens(json: any, isSso = false) {
    this.setLoginMethod(isSso ? LoginMethod.SSO : LoginMethod.PASSWORD);
    AuthStore.setToken(StorageKeys.TOKEN, json.token);
    AuthStore.setToken(StorageKeys.REFRESH_TOKEN, json.refreshToken);
  }

  public static async logout() {
    localStorage.clear();
    sessionStorage.clear();
    Dispatcher.dispatch({
      actionType: Constants.AUTH_LOGOUT,
    });
    await AuthAPI.logout();
    this.setLoginMethod(LoginMethod.UNDEFINED);
  }

  public static updateProfile() {
    Dispatcher.dispatch({
      actionType: Constants.PROFILE_UPDATE,
    });
  }
}
