import React, { createRef } from 'react';
import { Segment } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import Scrollbars from 'react-custom-scrollbars';
import OrganizationChart from '@dabeng/react-orgchart';
import TeamViewNode, { DataSource } from './teamViewNode/teamViewNode';
import TypeActivity from '../../../../constants/typeActivity';
import './teamView.scss';
import PerformancePlanAPI from '../../../../api/performancePlan.api';
import BusinessChallengeAPI from '../../../../api/businessChallenge.api';
import PerformancePlan from '../../../../models/performancePlan';
import BusinessChallenge from '../../../../models/businessChallenge';
import Utils from '../../../../utils/utils';
import PerformancePlanStore from '../../../../stores/performancePlan.store';
import BusinessChallengeStore from '../../../../stores/businessChallenge.store';
import PerformanceInitiativeStore from '../../../../stores/performanceInitiative.store';
import ActionTypes from '../../../../constants/actionTypes';
import CustomScrollBars from '../../../common/customScrollBars/customScrollBars';

interface IProps {
  activityId: number;
  activityType: TypeActivity;
}

interface IStates {
  dataSource: DataSource;
  activity: PerformancePlan | BusinessChallenge | null;
  selectedBcs: number[];
}

class TeamView extends React.Component<IProps, IStates> {

  private isMount = false;
  private scrollbarsRef = createRef<Scrollbars>();

  public constructor(props: IProps) {
    super(props);
    this.state = {
      dataSource: { data: undefined, activityType: TypeActivity.PERFORMANCE_PLAN },
      activity: null,
      selectedBcs: [],
    };
  }

  public componentDidMount() {
    this.isMount = true;

    switch (this.props.activityType) {
      case TypeActivity.PERFORMANCE_PLAN:
        PerformancePlanStore.addListener(ActionTypes.PERFORMANCE_PLAN_SAVED.toString(), this.getChartData);
        break;

      case TypeActivity.BUSINESS_CHALLENGE:
        BusinessChallengeStore.addListener(ActionTypes.BUSINESS_CHALLENGE_SAVED.toString(), this.getChartData);
        break;

      case TypeActivity.PERFORMANCE_INITIATIVE:
        PerformanceInitiativeStore.addListener(ActionTypes.PERFORMANCE_INITIATIVE_SAVED.toString(), this.getChartData);
        break;
    }

    this.getChartData();
  }

  public componentWillUnmount() {
    this.isMount = false;
    BusinessChallengeStore.removeListener(ActionTypes.BUSINESS_CHALLENGE_CHANGE.toString(), this.getChartData);
    PerformanceInitiativeStore.removeListener(ActionTypes.PERFORMANCE_INITIATIVE_CHANGE.toString(), this.getChartData);
  }

  private getChartData = () => {
    if (this.isMount) {
      switch (this.props.activityType) {
        case TypeActivity.PERFORMANCE_PLAN:
          PerformancePlanAPI.getPerformancePlanForTeamView(this.props.activityId)
          .then((performancePlan) => {
            this.setState({
              activity: performancePlan,
            },            () => this.buildDataSource());
          });
          break;

        case TypeActivity.BUSINESS_CHALLENGE:
          BusinessChallengeAPI.getBusinessChallengeForTeamView(this.props.activityId)
            .then((businessChallenge) => {
              this.setState({
                activity: businessChallenge,
              },            () => this.buildDataSource());
            });
          break;
      }
    }
  }

  private updateSelectedBcs = (bcId: number) => {
    this.setState(state => ({
      selectedBcs: state.selectedBcs.filter(id => id === bcId).length > 0
        ? state.selectedBcs.filter(id => id !== bcId)
        : [...state.selectedBcs, bcId],
    }),
                  () => {
                    this.buildDataSource();
                  });
  };

  private buildDataSource = () => {
    let dataSrc = {};
    switch (this.props.activityType) {
      case TypeActivity.PERFORMANCE_PLAN:
        const pp = { ...this.state.activity as PerformancePlan };
        const bcs = [...pp.businessChallenges.concat(pp.linkedBcs ? pp.linkedBcs : [])];
        dataSrc =  {
          id: pp.id,
          data: { ...pp },
          activityType: this.props.activityType,
          children: bcs.map((bc) => {
            const pis = [...bc.performanceInitiatives.concat(bc.linkedPis ? bc.linkedPis  : [])];
            if (this.state.selectedBcs && this.state.selectedBcs.filter(bcId => bcId === bc.id).length > 0) {
              return {
                id: bc.id,
                data: { ...bc },
                activityType: TypeActivity.BUSINESS_CHALLENGE,
                loadData: this.updateSelectedBcs,
                children: pis.map((pi) => {
                  return {
                    id: pi.id,
                    data: { ...pi },
                    activityType: TypeActivity.PERFORMANCE_INITIATIVE,
                  };
                }),
              };
            }
            return {
              id: bc.id,
              data: { ...bc },
              loadData: this.updateSelectedBcs,
              activityType: TypeActivity.BUSINESS_CHALLENGE,
            };
          }),
        };
        break;

      case TypeActivity.BUSINESS_CHALLENGE:
        const bc = { ...this.state.activity as BusinessChallenge };
        const pis = [...bc.performanceInitiatives.concat(bc.linkedPis ? bc.linkedPis  : [])];
        dataSrc =  {
          id: bc.id,
          data: { ...bc },
          activityType: this.props.activityType,
          children: pis.map((pi) => {
            return {
              id: pi.id,
              data: { ...pi },
              activityType: TypeActivity.PERFORMANCE_INITIATIVE,
            };
          }),
        };
        break;
    }

    this.setState(
      { dataSource: dataSrc as DataSource },
      () => {
        if (!!this.scrollbarsRef.current) {
          // Auto-scroll the chart to its center
          this.scrollbarsRef.current.scrollLeft(
            (this.scrollbarsRef.current.getScrollWidth() - this.scrollbarsRef.current.getClientWidth()) / 2);
        }
      },
    );
  };

  public render() {
    return (
      <div className="team-view-area">
        <div className="column">
          <div className="element">
            <Segment id="team-view-segment">
              <div className="title-element">
                <FormattedMessage id="tw.teamViewTitle" defaultMessage="Team View" />
                  <div id="chart-legend">
                    <Segment compact={true}>
                      <div className="title-with-badge">
                        <FormattedMessage id="tw.referentOrLeader" defaultMessage="Referent, Leader" />
                        <span className="badge referent-or-leader"/>
                      </div>
                      <div className="title-with-badge">
                        <FormattedMessage id="tw.deputy" defaultMessage="Deputy" />
                        <span className="badge deputy"/>
                      </div>
                      <div className="title-with-badge">
                        <FormattedMessage id="tw.sharedWith" defaultMessage="Shared With" />
                        <span className="badge shared"/>
                      </div>
                    </Segment>
                  </div>
              </div>
              <div className="team-view">
                {this.state.dataSource.data === undefined
                  ? Utils.loader()
                  : <CustomScrollBars className="team-view-scrollbars" customRef={this.scrollbarsRef}>
                    <div id="org-chart-container">
                      <OrganizationChart
                        datasource={this.state.dataSource}
                        NodeTemplate={TeamViewNode}
                        collapsible={false}
                        chartClass="team-view-chart"
                      />
                    </div>
                  </CustomScrollBars>
                }
              </div>
            </Segment>
          </div>
        </div>
      </div>
    );
  }
}
export default TeamView;
