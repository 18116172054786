import './feedbackContent.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Form, Segment, Select } from 'semantic-ui-react';

import FeedbackActions from 'src/actions/feedback.action';
import UserAvatar from 'src/components/common/userAvatar/userAvatar';
import { FeedbackKeysStatus } from 'src/constants/feedbackKeys';
import User from 'src/models/user';
import FeedbacksUtils from 'src/utils/feedbacksUtils';
import Utils from 'src/utils/utils';

interface IProps {
  id: number;
  type: number;
  page?: number;
  tab?: number;
  comment: string;
  status: number;
  author: User;
  createdAt: Date;
}

interface IStates {
  id: number;
  status: number;
}

class FeedbackContent extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      id: this.props.id,
      status: this.props.status,
    };
  }

  public render() {

    const action = this.props.status === FeedbackKeysStatus.FB_STATUS_DONE ?
      (
        <FormattedMessage
          id="feedback.archived"
          defaultMessage="Archived"
        />
      )
      :
      this.props.type === FeedbackKeysStatus.FB_STATUS_TODO ?
        (
          <FormattedMessage
            id="feedback.status"
            defaultMessage="Status"
          >
            {msg => (
              <Form.Field
                required={true}
                id="Status"
                label={msg}
                control={Select}
                options={FeedbacksUtils.statusOptions}
                placeholder={msg}
                value={this.state.status}
                onChange={(e: any, { value }: any) => this.handleStatusChange('status', value)}
              />
            )}
          </FormattedMessage>
        )
        :
        (
          <Button
            onClick={(e: any, { value }: any) => this.handleStatusChange('status', FeedbackKeysStatus.FB_STATUS_DONE)}
          >
            <FormattedMessage
              id="feedback.archive"
              defaultMessage="Archive"
            />
          </Button>
        );

    const type = FeedbacksUtils.typeOptions[this.props.type].text;
    const page = this.props.page ? FeedbacksUtils.pageOptions[this.props.page].text : null;

    const tab = this.props.page
    && this.props.tab
    && FeedbacksUtils.getTabList(this.props.page)
    && FeedbacksUtils.getTabList(this.props.page).length > 0
    && !!FeedbacksUtils.getTabList(this.props.page).find(tab => tab.key === this.props.tab) ?
      FeedbacksUtils.getTabList(this.props.page).find(tab => tab.key === this.props.tab).text : null;

    const twoPoints = page ? ':' : null;
    const dash = tab ? '-' : null;

    const date = Utils.displayDate(this.props.createdAt);

    return (
      <div id="feedback-content">
        <Segment>
          <div id="header">
            <div id="content-title">
              {type} n°{this.props.id} {twoPoints} {page} {dash} {tab}
            </div>
            <div id="date">
              <span>{date}</span>
            </div>
          </div>
          <div id="subtitle">
            <div id="author">
              <UserAvatar account={this.props.author} />
            </div>
            <div id="status">
              {action}
            </div>
          </div>
          <div id="comment">
            <div id="comment-label">
              <FormattedMessage
                id="feedback.comment"
                defaultMessage="Comment"
              />
            </div>
            <pre>
              "{this.props.comment}"
            </pre>
          </div>
        </Segment>
      </div>

    );
  }

  /**
   * Emit action status change and get refresh counts
   * @param key : string
   * @param value : any
   */
  private handleStatusChange = (key: string, value: any) => {
    this.setState({ status: value });
    FeedbackActions.emitPutFeedback(this.state.id, value);
    FeedbackActions.emitGetFeedbacksCounts();
  };
}

export default FeedbackContent;
