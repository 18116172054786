import { Table } from 'semantic-ui-react';
import React from 'react';
import { Months, Quarter, TimelineView } from '../../../../../../../../../constants/timeline';
import TimelineUtils from '../../../../../../../../../utils/timelineUtils';
import { TimelineZone } from '../../../../../../../../../constants/piTimeline';
import Utils from '../../../../../../../../../utils/utils';
import './headerZone.scss';

interface IProps {
  zone: TimelineZone;
  selectedView: TimelineView;
  year: number;
  quarter: Quarter;
  month: Months;
}

const headerZone = (props: IProps) => {
  const shouldShowTodayIcon = TimelineUtils.isOnMonthView(props.selectedView)
    && props.zone.day === new Date().getDate() && props.zone.idMonth === new Date().getMonth()
    && props.year === new Date().getFullYear();
  const zoneName = (props.zone.day !== undefined)
    ? props.zone.day : TimelineUtils.getTitleOfMonth(props.zone.idMonth, true);
  return (
    <Table.HeaderCell
      key={Utils.generateRandomKey()}
      className={`${shouldShowTodayIcon ? 'empty-indicator-cell' : '' }`}
    >
      <span>{zoneName}</span>
    </Table.HeaderCell>
  );
};
export default headerZone;
