import { ExportType } from 'src/actions/export.action';
import { ReportingGetFormatted } from 'src/models/reporting';

export interface ExportStepsOptions {
  selectedExportType: ExportType | undefined;
  selectedReporting: ReportingGetFormatted | undefined;
  includeBcs: boolean;
  includePis: boolean;
  includeAts: boolean;
  bcs: number[];
  pis: number[];
  includeMiAt: boolean;
  includeMetrics: boolean;
}

export class CustomOptions {
  bcs: number[];
  pis: number[];
  includeAts: boolean;
  includeMiAt: boolean;
  includeMetrics: boolean;

  constructor(options: ExportStepsOptions) {
    this.bcs = options.bcs;
    this.pis = options.includePis ? options.pis : [];
    this.includeAts = options.includeAts;
    this.includeMiAt = options.includeMiAt;
    this.includeMetrics = options.includeMetrics;
  }
}
