import { ICON_TYPE, KeyPoints, ShapedIcon } from '../../../../../../../../../../constants/piTimeline';
import { StatusType } from '../../../../../../../../../../constants/statusEnums';
import Utils from '../../../../../../../../../../utils/utils';
import TimelineUtils from '../../../../../../../../../../utils/timelineUtils';
import { Table } from 'semantic-ui-react';
import * as React from 'react';
import { Months, Quarter, TimelineView } from '../../../../../../../../../../constants/timeline';
import IconsDisplay from './iconsDisplay/iconsDisplay';
import './contentCell.scss';

interface IProps {
  selectedView: TimelineView;
  year: number;
  quarter: Quarter;
  month: Months;
  keyPoints: KeyPoints; // the different dates of the action/milestone
  monthOrDay: number; // the current month or current day
  actionStatus?: StatusType; // the action's status
  isStartingDate: boolean; // is it the display for the starting date
  day?: number; // the day of the provided date
  secondDay?: number; // the day of the provided second date (when starting & target are on same month)
  forecastDay?: number; // the day for forecast date
  isLastDay: boolean; // is it the display for the target date (in the case of an action only)
  elementId: number;
  isForecastBetween: boolean; // say if we are between target and forecast (cant check with action status)
  isForecastBeforeTarget: boolean;
  isAt: boolean;
}

const contentCell = (props: IProps) => {

  /**
   * Generates the layout for the icons according to the activity (action or milestone)
   * @return {JSX.Element}
   */
  const generateIconsDisplay = ()
    : JSX.Element => {
    const icons: JSX.Element[] = [];
    let shapedIcons: ShapedIcon[] = [];
    let shouldDisplayCompletion = props.keyPoints.isCompletionSet()
      && props.keyPoints.completionDate!.year === props.year;
    let shouldDisplayTarget = (props.actionStatus === undefined || !props.keyPoints.isStartingSet())
      && (props.keyPoints.isTargetSet() && props.keyPoints.targetDate!.year === props.year);

    let shouldDisplayForecast = props.keyPoints.isForecastSet() && props.keyPoints.forecastDate!.year === props.year;
    let areDatesOnSameArea: boolean;

    if ([TimelineView.YEAR, TimelineView.QUARTER].includes(props.selectedView)) {
      shouldDisplayCompletion = shouldDisplayCompletion && props.keyPoints.completionDate!.month === props.monthOrDay;
      shouldDisplayTarget = shouldDisplayTarget && props.keyPoints.targetDate!.month === props.monthOrDay;
      shouldDisplayForecast = shouldDisplayForecast && props.keyPoints.forecastDate!.month === props.monthOrDay;
    } else {
      shouldDisplayCompletion = shouldDisplayCompletion && props.keyPoints.completionDate!.month === props.month
        && props.keyPoints.completionDate!.day === props.monthOrDay;
      shouldDisplayTarget = shouldDisplayTarget && props.keyPoints.targetDate!.month === props.month
        && props.keyPoints.targetDate!.day === props.monthOrDay;
      shouldDisplayForecast = shouldDisplayForecast && props.keyPoints.forecastDate!.month === props.month
        && props.keyPoints.forecastDate!.day === props.monthOrDay;
    }

    areDatesOnSameArea = TimelineUtils.areDatesOnSameDay(props.keyPoints, props.actionStatus === undefined);

    if (areDatesOnSameArea && (shouldDisplayCompletion && shouldDisplayForecast)) {
      shapedIcons = [new ShapedIcon(props.keyPoints.completionDate!.day, ICON_TYPE.MULTIPLE_DATES)];
    } else {
      if (shouldDisplayTarget && !props.isAt) {
        shapedIcons.push(new ShapedIcon(props.keyPoints.targetDate!.day, ICON_TYPE.TARGET_DATE_MILESTONE));
      }
      if (shouldDisplayForecast) {
        shapedIcons.push(new ShapedIcon(props.keyPoints.forecastDate!.day, ICON_TYPE.FORECAST_DATE));
      }
      if (shouldDisplayCompletion) {
        shapedIcons.push(new ShapedIcon(props.keyPoints.completionDate!.day, !props.isAt
          ? ICON_TYPE.COMPLETION_DATE_MILESTONE : ICON_TYPE.COMPLETION_DATE));
      }
    }

    if (shapedIcons.length > 0) {
      icons.push(
        <IconsDisplay
          key={Utils.generateRandomKey()}
          selectedView={props.selectedView}
          icons={shapedIcons}
          monthId={props.monthOrDay}
          year={props.year}
          elementId={props.elementId}
          isAction={props.actionStatus !== undefined}
        />,
      );
    }
    return (<> {icons} </>);
  };

  const generateDynamicBackground = () => {
    const isInBetween = !props.isStartingDate && !props.isLastDay && props.actionStatus !== undefined;
    const colors = getBgColorsFromStatus();
    let dayP = 0;
    let secondDayP = 0;
    let firstLine = '';
    let secondLine = '';
    let thirdLine = '';
    let fourthLine = '';

    if (props.selectedView !== TimelineView.MONTH) {
      const nbDays = TimelineUtils.getNbDaysForMonth(props.month, props.year);
      const startingColorWidth = TimelineUtils.isOnYearView(props.selectedView) ? 5 : 2;

      if (props.day) dayP = Math.trunc((props.day * 100) / nbDays);
      if (props.secondDay) secondDayP = Math.trunc((props.secondDay * 100) / nbDays);

      const limitWidth = 100 - startingColorWidth;

      if (props.day === 1) {
        dayP = 0;
      } else {
        dayP = dayP > limitWidth ? limitWidth : dayP;
      }

      const firstLineColor = props.isStartingDate && props.day === 1
        ? colors[0]
        : props.isLastDay && !props.isStartingDate
          ? colors[1] : 'rgba(255, 255, 255, 0)';
      const thirdLineColor = (props.isLastDay && !props.isStartingDate) ||
        (props.isStartingDate && props.day === nbDays) ? colors[0] : colors[1];

      // create a colored space between the debut of the cell and the day
      firstLine =  `${firstLineColor} 0%, ${firstLineColor} ${dayP}%,`;
      // create the dark color space beginning or end
      secondLine = `${colors[0]} ${dayP}%, ${colors[0]} ${dayP + startingColorWidth}%`;
      thirdLine = `,${thirdLineColor}  ${dayP  + startingColorWidth}%,`;
      thirdLine = props.isLastDay && !props.isStartingDate
      ? `${thirdLine} rgba(255, 255, 255, 0) ${dayP  + startingColorWidth}%,
        rgba(255, 255, 255, 0) 99%` // blank if last Day only
      : `${thirdLine} ${thirdLineColor} ${secondDayP !== 0 ? secondDayP - startingColorWidth : 99}%`;
      // bar color until the end if starting date only, or until target date
      if (secondDayP !== 0) {
        // create a colored space for the target date (only when there is already one for starting)
        fourthLine = `, ${colors[0]} ${secondDayP - startingColorWidth}%,
          ${colors[0]} ${secondDayP}%, rgba(255, 255, 255, 0) ${secondDayP}%,
          rgba(255, 255, 255, 0) 99%`;
      }
    }

    return props.actionStatus !== undefined
      ? isInBetween || TimelineUtils.isOnMonthView(props.selectedView)
        ? getBgColorFromStatus()
        : (
          `linear-gradient(to right,
          ${firstLine}
          ${secondLine}
          ${thirdLine}
          ${fourthLine})`
        )
      : '';
  };

  const calculateDay = (nbDays: number, targetDay?: number) :
    [number | undefined, number, number] => {
    let dayP: number = 0;
    let secondDayP: number = 0;
    let firstDay : number | undefined;
    let secondDay: number | undefined;

    if (targetDay !== undefined) {
      firstDay = targetDay;
      if (props.forecastDay) {
        secondDay = props.forecastDay;
      }
    } else if (props.forecastDay) {
      firstDay = props.forecastDay;
    }

    dayP = firstDay ? Math.trunc((firstDay * 100) / nbDays) : 0;
    secondDayP = secondDay ? Math.trunc((secondDay * 100) / nbDays) : 0;

    return [secondDay, dayP, secondDayP];
  };

  const getColors = (colors : string) : [string, string] => {
    const firstLineColor : string = props.forecastDay !== undefined && !props.isLastDay
    ? colors : 'rgba(255, 255, 255, 0)';
    const secondLineColor : string = props.forecastDay !== undefined && !props.isLastDay
      ? 'rgba(255, 255, 255, 0)'
      : colors;
    return [firstLineColor, secondLineColor];
  };

  const generateDynamicBackgroundForecast = () => {
    // the main issue with the function is
    // that target can be after or before forecast
    const isInBetween = props.forecastDay === undefined && !props.isLastDay;
    const colors = '#2e86ab';
    let dayP = 0;
    let secondDayP = 0;
    let firstLine = '';
    let secondLine = '';
    let thirdLine = '';

    if (!isInBetween) {
      switch (props.selectedView) {
        case TimelineView.YEAR:
        case TimelineView.QUARTER:
          const nbDays = TimelineUtils.getNbDaysForMonth(props.month, props.year);
          const startingColorWidth = TimelineUtils.isOnYearView(props.selectedView) ? 5 : 2;

          let secondDay: number | undefined;
          let firstLineColor : string;
          let secondLineColor : string;

          const targetDay = props.secondDay || props.day;

          [secondDay, dayP, secondDayP] = calculateDay(nbDays, targetDay);
          // we need to determine if we are at the beginning

          [firstLineColor, secondLineColor] = getColors(colors);

          firstLine =  `${firstLineColor} 0%, ${firstLineColor} ${dayP + startingColorWidth}%`;
          if (targetDay !== nbDays) {
            secondLine = `,${secondLineColor}  ${dayP + startingColorWidth}%,
           ${secondLineColor} ${secondDay !== undefined ? secondDayP + startingColorWidth : 99}%`;
            if (secondDayP !== 0) thirdLine = `, rgba(255, 255, 255, 0) ${secondDayP}%, rgba(255, 255, 255, 0) 99%`;
          }
          break;
      }
    }
    return props.isForecastBetween || props.forecastDay !== undefined || props.isLastDay
      ? isInBetween || TimelineUtils.isOnMonthView(props.selectedView)
        ? colors
        : (
          `linear-gradient(to right,
          ${firstLine}
          ${secondLine}
          ${thirdLine})`
        )
      : '';
  };

  const getBgColorsFromStatus = () => {
    let colors: string[];
    switch (props.actionStatus) {
      case StatusType.CONFIDENT:
        colors = ['#2db84b', '#96dba5'];
        break;
      case StatusType.AT_RISK:
        colors = ['#f5a030', '#facf97'];
        break;
      case StatusType.CRITICAL:
        colors = ['#cf343c', '#e7999d'];
        break;
      default:
        colors = [];
        break;
    }
    return colors;
  };

  const getBgColorFromStatus = () => {
    switch (props.actionStatus) {
      case StatusType.CRITICAL:
        return '#e7999d';
      case StatusType.AT_RISK:
        return '#facf97';
      case StatusType.CONFIDENT:
        return '#96dba5';
      default:
        return '';
    }
  };

  /**
   * Generates the cell content (colored bar as background/no background and icons)
   * @return {JSX.Element}
   */
  const generateCellContent = () => {
    const icons: JSX.Element = generateIconsDisplay();
    // we check is there is an actionStatus so the color is defined for the background
    const bgStyle = props.actionStatus !== undefined ? generateDynamicBackground() : '';
    const bgStyleForecast = props.keyPoints.forecastDate && props.keyPoints.targetDate
      && !props.isForecastBeforeTarget ? generateDynamicBackgroundForecast() : '';
    const barId = `bar-${props.actionStatus !== undefined ? 'at' : 'mi'}-${props.elementId}-${props.monthOrDay}`;
    return (
      <Table.Cell
        width={1}
        className={`no-inline-padding ${TimelineUtils.isOnMonthView(props.selectedView) ? 'smaller-cells' : ''}`}
        key={Utils.generateRandomKey()}
      >
        <div
          id={barId}
          className={TimelineUtils.isOnMonthView(props.selectedView)
            ? 'element-bar-container alt' : 'element-bar-container'}
        >
          <div
            className={TimelineUtils.isOnMonthView(props.selectedView) ? 'element-bar alt' : 'element-bar'}
            style={{ background: bgStyle }}
          >
            {icons}
            {bgStyleForecast !== ''
              ? <div className="element-bar-forecast" style={{ background: bgStyleForecast }} /> : null}
          </div>
        </div>
      </Table.Cell>
    );
  };
  return generateCellContent();
};
export default contentCell;
