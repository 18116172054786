import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import ActivitiesActions from '../../../../actions/activities.action';
import UserActions from '../../../../actions/user.action';
import ActionTypes from '../../../../constants/actionTypes';
import ActivitiesStore from '../../../../stores/activities.store';
import UserStore from '../../../../stores/user.store';
import PerformancePlan from '../../../../models/performancePlan';
import NotificationActions from '../../../../actions/notification-actions';
import { ToastType } from '../../../common/toast/toast';

interface DropdownPpFormat {
  key: number | null;
  value: string;
  text: string | JSX.Element;
}

interface IProps {
  userId: number;
}

interface IStates {
  defaultPpId: number | null;
  activities: PerformancePlan[];
}

const NO_PP_VALUE: string = 'noPp';

class DefaultPpSelection extends React.Component<IProps, IStates> {

  private isMount = false;

  //region REACT LIFECYCLE METHODS
  constructor(props: IProps) {
    super(props);
    this.state = {
      defaultPpId: null,
      activities: [],
    };
  }

  public componentDidMount(): void {
    this.isMount = true;
    UserActions.emitGetDefaultPp();
    UserStore.addListener(ActionTypes.USER_DEFAULT_PP_GET.toString(), this.getDefaultPP);

    if (this.state.activities.length === 0) {
      ActivitiesActions.emitGetActivities().then(() => {
        this.setState({ activities: ActivitiesStore.getActivities() });
      });
    } else {
      this.setState({ activities: ActivitiesStore.getActivities() });
    }
  }

  public componentWillUnmount(): void {
    this.isMount = false;
    UserStore.removeListener(ActionTypes.USER_DEFAULT_PP_GET.toString(), this.getDefaultPP);
  }
  //endregion

  //region LISTENER RELATED METHODS
  public getDefaultPP = () => {
    if (this.isMount) {
      this.setState({ defaultPpId: UserStore.getDefaultPpId() });
    }
  };
  //endregion

  //region OTHER CLASS METHODS
  /**
   * Format PPs to match dropdown options
   */
  private getFormattedOptions(): DropdownPpFormat[] {
    return this.state.activities.map((activity) => {
      return { key: activity.id, value: activity.id.toString(), text: activity.name };
    });
  }

  /**
   * Check if the newly selected PP is different from the default one
   * to prevent useless update request
   * @param newPpId
   */
  private shouldUpdateDefaultPp = (newPpId: string) => {
    if (this.state.defaultPpId === null) {
      return newPpId !== NO_PP_VALUE;
    }
    return this.state.defaultPpId !== +newPpId;
  };

  /**
   * Update the user's default PP
   * @param newPpId
   */
  private changeDefaultPp = (newPpId: string) => {
    if (this.shouldUpdateDefaultPp(newPpId)) {
      const ppId: number | null = newPpId === NO_PP_VALUE ? null : +newPpId;
      UserActions.emitChangeDefaultPp(ppId).then(() => {
        this.setState({ defaultPpId: ppId }, () => {
          NotificationActions.toast(
            <FormattedMessage id="success" defaultMessage="Success !" />,
            <FormattedMessage id="successDefaultPpUpdate" defaultMessage="The default PP displayed has been successfully updated" />,
            ToastType.SUCCESS,
          );
        });
      }).catch(() => {
        NotificationActions.toast(
          <FormattedMessage id="error" defaultMessage="Error" />,
          (
            <FormattedMessage
              id="errorDefaultPpUpdate"
              defaultMessage="An error occurred while trying to update the default PP"
            />
          ),
          ToastType.ERROR,
        );
      });
    }
  }
  //endregion

  public render() {
    const noPlanSelectedText = <FormattedMessage id="defaultPp.noSelection" defaultMessage="None"/>;
    const options: DropdownPpFormat[] = [{ key: null, value: NO_PP_VALUE, text: noPlanSelectedText },
      ...this.getFormattedOptions()];
    const defaultValue = this.state.defaultPpId !==
    null ? this.state.defaultPpId.toString() : NO_PP_VALUE;
    return (
      <div>
        <h5><FormattedMessage id="defaultPp.desc" defaultMessage="Default Performance Plan Selection"/></h5>
        <Dropdown
          fluid={true}
          search={true}
          selection={true}
          value={defaultValue}
          options={options}
          onChange={(e, data) => this.changeDefaultPp(data.value as string)}
        />
      </div>
    );
  }
}

export default DefaultPpSelection;
