import { EventEmitter } from 'events';
import ActionTypes from 'src/constants/actionTypes';

import Action from '../actions/action';
import Dispatcher from '../dispatcher';
import { isNumber } from 'util';

class ExportStore extends EventEmitter {

  private canExport = true;
  private pdfId: string;
  private pdfName: string;

  // Used to allow the progression's pulling process
  private isPdfGenerating: boolean;
  private pdfGeneratingProgression: number;

  constructor() {
    super();
    Dispatcher.register(this.registerToActions.bind(this));
  }

  /**
   * Get canExport
   * @return boolean
   */
  public getCanExport(): boolean {
    return this.canExport;
  }

  public getPdfId(): string {
    return this.pdfId;
  }

  public setPdfName(pdfName: string) {
    this.pdfName = pdfName;
  }

  public getPdfName(): string {
    return this.pdfName;
  }

  public getIsPdfGenerating(): boolean {
    return this.isPdfGenerating;
  }

  public getPdfGeneratingProgression(): number {
    return this.pdfGeneratingProgression;
  }

  /**
   * Set canExport
   * @param canExport: boolean
   */
  private setCanExport(canExport: boolean) {
    this.canExport = canExport;
    this.pdfName = '';
    this.pdfId = '';
    this.isPdfGenerating = false;
    this.emit(ActionTypes.CAN_EXPORT.toString());
  }

  private setPdfId(pdfId: string) {
    this.pdfId = pdfId;
    this.emit(ActionTypes.PDF_ID_RECEIVED.toString());
  }

  private setIsPdfGenerating(isPdfGenerating: boolean) {
    this.isPdfGenerating = isPdfGenerating;
  }

  private setPdfGeneratingProgression(progression: number | null) {
    if (isNumber(progression)) {
      this.pdfGeneratingProgression = progression;
    }
    if (progression === null) {
      this.setIsPdfGenerating(false);
    }
    this.emit(ActionTypes.PDF_PROGRESSION_UPDATE.toString());
  }

  /**
   * Performs a function according to an action
   * @param action: Action
   * @return void
   */
  private registerToActions(action: Action) {
    switch (action.actionType) {
      case ActionTypes.CAN_EXPORT:
        this.setCanExport(action.canExport);
        break;
      case ActionTypes.PDF_ID_RECEIVED:
        this.setIsPdfGenerating(true);
        this.setPdfName(action.pdfName);
        this.setPdfId(action.pdfId);
        break;
      case ActionTypes.PDF_PROGRESSION_UPDATE:
        this.setPdfGeneratingProgression(action.progression);
        break;
      case ActionTypes.PDF_STOP_FETCHING_PROGRESSION:
        this.setIsPdfGenerating(false);
        break;
    }
  }
}

export default new ExportStore();
