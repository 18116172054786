import * as React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import ReportingAPI from 'src/api/reporting.api';
import NotificationActions from 'src/actions/notification-actions';
import BusinessChallengeReporting from 'src/models/businessChallengeReporting';
import Utils from 'src/utils/utils';
import { ToastType } from 'src/components/common/toast/toast';

interface IProps {
  onBcReportingUpdate: (bcReporting: BusinessChallengeReporting) => void;
  setStatus: Function;
  canSave: Function;
  setUpdateModalOpen: Function;
  updateModalOpen: boolean;
  reportingId: number;
  initialBcReporting: BusinessChallengeReporting;
}

interface IStates {
  updateLoading: boolean;
}

class ReportModalConfirmation extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      updateLoading: false,
    };
  }

    /**
   * On BC reporting's update confirmation modal cancel
   */
  private handleUpdateModalClose = () => {
    this.setState({ updateLoading: false });
    this.props.setUpdateModalOpen(false);
  };

  /**
   * On BC reporting's update confirmation modal agree
   */
  private handleUpdateModalAgree = () => {
    this.setState(
      { updateLoading: true },
      () => {
        ReportingAPI.updateBcReporting(this.props.reportingId, this.props.initialBcReporting.id)
          .then((bcReporting) => {
            this.props.onBcReportingUpdate(bcReporting);
            this.props.setStatus(bcReporting);
            this.setState(
              {
                updateLoading: false,
              },
              () => {
                this.props.canSave();
                this.props.setUpdateModalOpen(false);
              },
            );

            NotificationActions.toast(
              <FormattedMessage id="success" defaultMessage="Success" />,
              (
                <FormattedMessage
                  id="pp.reporting.bcReportingUpdate.updatedMessage"
                  defaultMessage="The Business Challenge information has been successfully updated"
                />
              ),
              ToastType.SUCCESS,
            );
          }).catch(() => Utils.toastError());
      },
    );
  };

  public render() {
    return (
        <Modal
          open={this.props.updateModalOpen}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          onClose={this.handleUpdateModalClose}
        >
          <Modal.Header>
            <FormattedMessage id="pp.reporting.bcReportingUpdate.title" defaultMessage="Update the BC information" />
          </Modal.Header>
          <Modal.Content>
            <p>
              <FormattedMessage
                id="pp.reporting.bcReportingUpdate.message"
                defaultMessage="Are you sure you want to update the Business Challenge information?"
              />
              <br />
              <FormattedMessage
                id="pp.reporting.bcReportingUpdate.explanation"
                defaultMessage="This action will refresh the whole BC to its current values,
                including the status (the comment will be kept)."
              />
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleUpdateModalClose}>
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              onClick={this.handleUpdateModalAgree}
              positive={true}
              labelPosition="right"
              icon="checkmark"
              loading={this.state.updateLoading}
              disabled={this.state.updateLoading}
              content={<FormattedMessage id="update" defaultMessage="Update" />}
            />
          </Modal.Actions>
        </Modal>
    );
  }

}

export default ReportModalConfirmation;
