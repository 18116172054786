import * as React from 'react';
import Utils from '../../../../../../../../../../../../utils/utils';
import { ICON_TYPE, ShapedIcon } from '../../../../../../../../../../../../constants/piTimeline';
import TimelineUtils from '../../../../../../../../../../../../utils/timelineUtils';
import { TimelineView } from 'src/constants/timeline';

interface IProps {
  selectedView: TimelineView;
  icon: ShapedIcon;
  monthId: number;
  year: number;
  elementId: number;
  isAction: boolean;
}
interface IState {
  iconLeftMargin: number;
}

export default class IconDisplay extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      iconLeftMargin: 0,
    };
  }

  componentDidMount() {
    this.determineLeftMargin();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    this.determineLeftMargin();
  }

  determineLeftMargin = () => {
    const gap = TimelineUtils.isOnQuarterView(this.props.selectedView)
      ? Utils.isOnMobility() ? 8 : 12 : 0;
    if (!TimelineUtils.isOnMonthView(this.props.selectedView)) {
      const periodElm = document.getElementById(
        `bar-${this.props.isAction ? 'at' : 'mi'}-${this.props.elementId}-${this.props.monthId}`,
      );
      if (periodElm && periodElm.clientWidth !== 0) {
        const nbDaysInMonth = TimelineUtils.getNbDaysForMonth(this.props.monthId, this.props.year);
        const margin = (((this.props.icon.day + 1) * (periodElm.clientWidth - 12)) / nbDaysInMonth) - gap;
        if (margin !== this.state.iconLeftMargin) {
          this.setState({ iconLeftMargin: margin });
        }
      }
    }
  };

  render() {
    const altStyle = TimelineUtils.isOnMonthView(this.props.selectedView);
    let iconType = '';
    switch (this.props.icon.type) {
      case ICON_TYPE.COMPLETION_DATE_MILESTONE:
        iconType = 'compl-date';
        break;
      case ICON_TYPE.TARGET_DATE_MILESTONE:
        iconType = 'target-date';
        break;
      case ICON_TYPE.COMPLETION_DATE:
        iconType = 'alt-color';
        break;
      case ICON_TYPE.FORECAST_DATE:
        iconType = 'circle';
        break;
      case ICON_TYPE.MULTIPLE_DATES:
      default:
        iconType = 'multiple';
        break;
    }
    return (
      <div
        key={Utils.generateRandomKey()}
        className={altStyle ? 'element-icon-container alt' : 'element-icon-container'}
        style={{ marginLeft: altStyle ? 'unset' : `${this.state.iconLeftMargin.toFixed(2)}px` }}
      >
        <div className={`element-icon ${iconType}`}/>
      </div>
    );
  }
}
