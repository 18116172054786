import 'react-datepicker/dist/react-datepicker.css';

import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';

import ModificationDropdown from 'src/components/common/form/modificationDropdown/modificationDropdown';
import { BusinessChallengePost } from 'src/models/businessChallenge';
import PerformancePlan from '../../../models/performancePlan';
import Textarea from '../../common/form/textarea/textarea';
import { pillarList } from '../../../constants/pillarEnums';
import CodeInput from '../../common/form/codeInput/codeInput';
import EditorGenerator from '../../activitiesBoard/containerActivities/ckEditorGenerator';
import Utils from '../../../utils/utils';
import CustomScrollBars from '../../common/customScrollBars/customScrollBars';

interface IProps extends InjectedIntlProps {
  pp: PerformancePlan;
  bc: BusinessChallengePost;
  keywords: any[];
  functions: any[];
  setNavigationDisabled(isNavigationDisabled: boolean): void;
  onUpdate(key: string, value: any): void;
}

interface IStates {
  isNameOnError: boolean;
  isCodeOnError: boolean;
  isEditorOnError: boolean;
}

class BcCreationStep1 extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      isCodeOnError: false,
      isNameOnError: false,
      isEditorOnError: false,
    };
  }

  private handleFormChange = (idAttribute: string,  value: string | boolean | number) => {
    this.props.onUpdate(idAttribute, value);
    this.validateForm(idAttribute);
  };

  private handleErrorOnEditor = (idElement: string, isFieldOnError: boolean, callback: Function) => {
    this.setState({ isEditorOnError: isFieldOnError }, callback());
  };

  private validateForm = (id: string) => {
    const isCodeOnError = Utils.isCodeOnError(this.props.pp.businessChallenges, this.props.bc.code);
    const isNameOnError = this.props.bc.name === '' || this.props.bc.name.trim().length === 0;

    let stateToUpdate = {};
    switch (id) {
      case 'code':
        stateToUpdate = { isCodeOnError };
        break;
      case 'name':
        stateToUpdate = { isNameOnError };
    }

    this.setState(stateToUpdate, () => {
      this.props.setNavigationDisabled(isCodeOnError || isNameOnError || this.state.isEditorOnError);
    });
  };

  public render(): React.ReactNode {
    const subject =
      this.props.intl.formatMessage({ id: 'bcKeywordProposition', defaultMessage: 'BC keyword proposition' });

    return (
      <CustomScrollBars autoHeightMax={300} className="content-element-scrollbar">
        <Form id="bcCreationStep1">
          <Form.Group widths="equal" className="bc-creation-field equal-width-fields">
            <FormattedMessage id="bcNumber" defaultMessage="BC number">
              {msg => (
                <div className="field">
                  <CodeInput
                    defaultValue={this.props.bc.code}
                    isCodeOnError={this.state.isCodeOnError}
                    label={`${msg} *`}
                    emitChange={(event: any, { value }) => this.handleFormChange(event.target.id, value)}
                  />
                  <div className="little-message error" hidden={!this.state.isCodeOnError}>
                    <FormattedMessage id="codeAlreadyTaken" defaultMessage="This code is already taken" />
                  </div>
                </div>
              )}
            </FormattedMessage>
            <FormattedMessage id="name" defaultMessage="Name">
              {msg => (
                <div className="field">
                  <Form.Input
                    label={`${msg} *`}
                    maxLength={90}
                    value={this.props.bc.name}
                    onChange={(e, data) => this.handleFormChange('name', data.value)}
                    error={this.state.isNameOnError}
                  />
                  <div className="little-message error" hidden={!this.state.isNameOnError}>
                    <FormattedMessage id="nameCantBeNull" defaultMessage="The name can't be null" />
                  </div>
                </div>
              )}
            </FormattedMessage>
            <FormattedMessage id="bcKeywords" defaultMessage="BC keywords">
              {msg => (
                <Form.Field
                  id="keywords"
                  control={ModificationDropdown}
                  options={this.props.keywords.filter(el => !el.isDeprecated)}
                  dropdownName="keywordsId"
                  defaultValue={this.props.bc.keywordsId ? this.props.bc.keywordsId : 2}
                  label={{ children: <span>{msg} {Utils.getKeywordTooltip(subject)}</span>, htmlFor: 'keywords' }}
                  placeholder={msg}
                  searchInput={{ id: 'keywords' }}
                  isValueNullable={true}
                  emitChange={(dropdownName: string, value: number) => this.handleFormChange(dropdownName, value)}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="bcFunctions" defaultMessage="Functions">
              {msg => (
                <Form.Field
                  id="functions"
                  control={ModificationDropdown}
                  options={this.props.functions.filter(el => !el.isDeprecated)}
                  dropdownName="functionsId"
                  defaultValue={this.props.bc.functionsId ? this.props.bc.functionsId : 1}
                  label={{ children: `${msg} *`, htmlFor: 'functions' }}
                  placeholder={msg}
                  searchInput={{ id: 'functions' }}
                  isValueNullable={true}
                  emitChange={(dropdownName: string, value: number) => this.handleFormChange(dropdownName, value)}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="pillar" defaultMessage="Pillar">
              {msg => (
                <Form.Field
                  id="pillar"
                  control={ModificationDropdown}
                  options={pillarList}
                  dropdownName="pillar"
                  defaultValue={this.props.bc.pillar ? this.props.bc.pillar : 1}
                  label={{ children: `${msg} *`, htmlFor: 'pillar' }}
                  placeholder={msg}
                  searchInput={{ id: 'pillar' }}
                  emitChange={(dropdownName: string, value: number) => this.handleFormChange(dropdownName, value)}
                />
              )}
            </FormattedMessage>
          </Form.Group>
          <Form.Field className="bc-creation-field">
            <label>
              <FormattedMessage id="objectives" defaultMessage="Objectives" />
            </label>
            <EditorGenerator
              data={this.props.bc.objectives}
              onChangeAction={this.handleFormChange}
              onErrorAction={this.handleErrorOnEditor}
              idElement="objectives"
              smallVersion={true}
            />
          </Form.Field>
          <Form.Field className="bc-creation-field">
            <label>
              <FormattedMessage id="budget" defaultMessage="Budget" />
            </label>
            <Textarea
              value={this.props.bc.budget}
              maxlength={500}
              onChange={(e, data) => this.handleFormChange('budget', data.value)}
              fixedHeight={true}
              height={100}
            />
          </Form.Field>
          <Form.Group widths="1" className="bc-creation-field">
            <FormattedMessage id="bcCreation.top5" defaultMessage="Top 5">
              {msg => (
                <Form.Checkbox
                  label={msg}
                  checked={this.props.bc.top5}
                  onChange={() => this.handleFormChange('top5', !this.props.bc.top5)}
                />
              )}
            </FormattedMessage>
          </Form.Group>
        </Form>
      </CustomScrollBars>
    );
  }
}

export default injectIntl(BcCreationStep1);
