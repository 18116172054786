import './ppHistoryExternal.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Segment } from 'semantic-ui-react';

import iBoostLogo from 'src/images/ambition-boost-logo.png';
import competitiveness from 'src/images/comp.png';
import growth from 'src/images/growth.png';
import people from 'src/images/people.png';
import User from 'src/models/user';
import Utils from 'src/utils/utils';
import TypeActivity from '../../../constants/typeActivity';
import BusinessChallenge from '../../../models/businessChallenge';
import History from '../../../models/history';
import PerformancePlan from '../../../models/performancePlan';
import UserAvatar from '../../common/userAvatar/userAvatar';
import Breadcrumb from '.././breadcrumb/breadcrumb';
import HistoryAPI from '../../../api/history.api';
import HistoryExternalError, { HistoryExternalErrors } from '../containers/historyExternalError';
import PerformanceInitiative from '../../../models/performanceInitiative';
import CustomScrollBars from '../../common/customScrollBars/customScrollBars';
import LinkLabel, { LinkLabelMode } from '../../common/linkLabel/linkLabel';
import { PpErrors } from '../../../constants/errors/performance-plan.error';

interface IRouteProps {
  ppId: string;
  historyId: string;
}

interface IStates {
  pp: PerformancePlan | undefined | null;
  histories: History[] | undefined;
  isExternal: boolean;
  isLoading: boolean;
  historyExternalError: HistoryExternalErrors | undefined;
}

class PpHistoryExternal extends React.Component<RouteComponentProps<IRouteProps>, IStates> {

  public constructor(props: RouteComponentProps<IRouteProps>) {
    super(props);
    this.state = {
      pp: undefined,
      histories: undefined,
      isExternal: this.props.match.url.toString().search('external') !== -1,
      isLoading: true,
      historyExternalError: undefined,
    };
  }

  public componentWillMount() {
    if (this.state.isExternal) {
      this.setExternalView();
    } else {
      this.setHistoryList();
    }

  }

  public componentDidUpdate(prevProps: Readonly<RouteComponentProps<IRouteProps>>, prevState: Readonly<IStates>) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.setState(
        { isLoading: true },
        () => {
          if (this.state.isExternal) {
            this.setExternalView();
          } else {
            if (+prevProps.match.params.ppId !== +this.props.match.params.ppId) {
              this.setHistoryList();
            } else {
              this.isHistoryValid();
            }
          }
        },
      );
    }
  }

  private setHistoryList() {
    HistoryAPI.getHistories('performance-plans', +this.props.match.params.ppId)
      .then((histories) => {
        this.setState(
          { histories },
          () => {
            this.isHistoryValid();
          },
        );
      })
      .catch(err => this.setState({ pp: null, isLoading: false, historyExternalError: err.error }));
  }

  private setExternalView = () => {
    HistoryAPI.getPpExternal(+this.props.match.params.ppId)
      .then((pp: PerformancePlan) => {
        this.setPerformancePlan(pp);
      })
      .catch(err => this.setState({ pp: null, isLoading: false, historyExternalError: err.error }));
  };

  private isHistoryValid = () => {
    if (this.state.histories
      && this.state.histories.length > 0
      && this.state.histories.some(el => el.id === +this.props.match.params.historyId)) {
      this.setHistory();
    } else {
      this.setState({ isLoading: false, historyExternalError: PpErrors.PP_WRONG_HISTORY });
    }
  };

  private setHistory() {
    HistoryAPI.getPpHistoryById(+this.props.match.params.ppId, +this.props.match.params.historyId)
      .then((pp) => {
        this.setPerformancePlan(pp);
      })
      .catch(() => this.setState({ pp: null, isLoading: false }));
  }

  private setPerformancePlan = (pp: PerformancePlan) => {
    this.setState({ pp, isLoading: false });
  };

  /**
   * BC etiquette creation
   * @param {any} bc
   */
  public bcRender(bc: BusinessChallenge): JSX.Element {
    const colorPillar = Utils.getPillarName(bc.pillar, true);
    const piList: JSX.Element[] = [];

    if (bc.performanceInitiatives && bc.performanceInitiatives.length > 0) {
      const originalPis = bc.performanceInitiatives
        .filter(pi => pi.businessChallenge.id === bc.id)
        .map(pi => ({ ...pi, isLinked: false }));

      const linkedPis = bc.performanceInitiatives
        .filter(pi => pi.businessChallenge.id !== bc.id)
        .map(pi => ({ ...pi, isLinked: true }));

      Utils.sortArrayByKey(originalPis, 'code')
        .concat(linkedPis)
        .map((pi: PerformanceInitiative) => {
          piList.push(
            <div key={`PI${pi.id}`}>
              {!this.state.isExternal && Utils.setBadge(pi.status)}
              <span
                className={`pi-title ${this.state.isExternal ? 'link' : ''}`}
                onClick={() => this.onClickPi(pi.id)}
              >
              {pi.isLinked
                ? <LinkLabel
                  pp={pi.businessChallenge.performancePlan}
                  bc={pi.businessChallenge}
                  mode={LinkLabelMode.SMALL}
                  disableLink={true}
                />
                : <span className={this.state.isExternal ? `external-${colorPillar}` : ''}>
                  PI{Utils.leadingZero(pi.code)} -&nbsp;
                </span>}
                {pi.name}
              </span>
            </div>,
          );
          return null;
        });
    }

    return (
      <div className={'element'} key={`BC${bc.id}`}>
        <Segment className={`${colorPillar}-border`}>
          <div className="bc-container">
            <div className="bc-title-container">
              {!this.state.isExternal && Utils.setBadge(bc.status)}
              <span
                className={`bc-title ${this.state.isExternal ? 'link' : ''}`}
                onClick={() => this.onClickBc(bc.id)}
              >
              {bc.isLinked
                ? <LinkLabel pp={bc.performancePlan} disableLink={true} />
                : <span>BC{Utils.leadingZero(bc.code)} -&nbsp;</span>}
              {bc.name}
              </span>
            </div>
            <div className="top5-container">
              {Utils.isTop5(bc)}
            </div>
          </div>
          <div className="bc-users-container">
            <div className="name-user-container">
              {Utils.getUsersAvatars(bc)}
            </div>
          </div>
          <div className="bc-info-container">
            <span className="date">
              <FormattedMessage id="lastPublication" defaultMessage="Last publication" />&nbsp;
              {bc.publishedDate ? Utils.displayFancyDate(bc.publishedDate) : '-'}
            </span>
          </div>
          {piList.length !== 0 &&
          <div className={`pi-container ${this.state.isExternal ? 'external' : ''}`}>
            {piList}
          </div>
          }
        </Segment>
      </div>
    );
  }

  private onClickBc = (bcId: number) => {
    if (this.state.isExternal) {
      this.props.history.push(`/bc-external/${bcId}`);
    }
  };

  private onClickPi = (piId: number) => {
    if (this.state.isExternal) {
      this.props.history.push(`/pi-external/${piId}`);
    }
  };

  private selectHistory = (historyId: number, route: string = 'pp-history') => {
    this.props.history.push(`/${route}/${this.props.match.params.ppId}/${historyId}`);
  };

  private goToExternal = (activityId: number, route: string) => {
    this.props.history.push(`/${route}/${activityId}`);
  };

  public render() {
    const growthColumn: JSX.Element[] = [];
    const competitivenessColumn: JSX.Element[] = [];
    const peopleColumn: JSX.Element[] = [];
    const currentHistoryId = this.props.match.params.historyId
    ? +this.props.match.params.historyId
    : undefined;

    if (this.state.pp) {
      if (this.state.pp.businessChallenges) {
        const originalBcs = this.state.pp.businessChallenges
          .filter(bc => bc.performancePlan.id === this.state.pp!.id)
          .map(bc => ({ ...bc, isLinked: false }));

        const linkedBcs = this.state.pp.businessChallenges
          .filter(bc => bc.performancePlan.id !== this.state.pp!.id)
          .map(bc => ({ ...bc, isLinked: true }));

        Utils.sortArrayByKey(originalBcs, 'code').concat(linkedBcs).forEach((bc: BusinessChallenge) => {
          switch (bc.pillar) {
            case 1:
              growthColumn.push(this.bcRender(bc));
              break;
            case 2:
              competitivenessColumn.push(this.bcRender(bc));
              break;
            case 3:
              peopleColumn.push(this.bcRender(bc));
              break;
          }
        });
      }
      return (
        <div id="history-container">
          <Breadcrumb
            currentHistoryId={currentHistoryId}
            data={this.state.pp}
            typeActivity={TypeActivity.PERFORMANCE_PLAN}
            histories={!!this.state.histories ? this.state.histories : []}
            isExternal={this.state.isExternal}
            handleClick={this.selectHistory}
            goToExternal={this.goToExternal}
          />
          {this.state.isLoading
            ? Utils.loader()
            : <div id="history-content-container">
              <div id="pp-history-container">
                <CustomScrollBars>
                  <div id="content-container">
                    <div id="header">
                      <div id="infos-container">
                        <span id="pp-title">{this.state.pp.name} {this.state.pp.year}</span>
                        <div id="users-container">
                          <div id="referent">
                            <UserAvatar account={this.state.pp.owner}/>
                          </div>
                          <table>
                            <tbody>
                            <tr id="deputies-container">
                              {this.state.pp.assignedAccounts.length > 0 &&
                              <>
                                <td>
                                  {this.state.pp.assignedAccounts.length === 1
                                    ? <FormattedMessage id="deputy" defaultMessage="DEPUTY"/>
                                    : <FormattedMessage id="deputies" defaultMessage="DEPUTIES"/>
                                  }
                                </td>
                                {this.state.pp.assignedAccounts.map((assignee: User) => {
                                  return <td key={`deputie${assignee.id}`}><UserAvatar account={assignee}/></td>;
                                })}
                              </>
                              }
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div id="top-right">
                        <img src={iBoostLogo} alt="Ambition Boost logo"/>
                        <span id="published-date-container">
                        <FormattedMessage id="publicationOf" defaultMessage="Publication of"/>
                          &nbsp;
                          {this.state.pp.publishedDate ? Utils.displayFancyDate(this.state.pp.publishedDate) : '-'}
                      </span>
                      </div>
                    </div>
                    <div id="bc-container">
                      <div id="columns-container">
                        <div className="column">
                          <img src={growth} alt="Growth logo"/>
                          {growthColumn}
                        </div>
                        <div className="column">
                          <img src={competitiveness} alt="Competitiveness logo"/>
                          {competitivenessColumn}
                        </div>
                        <div className="column">
                          <img src={people} alt="People logo"/>
                          {peopleColumn}
                        </div>
                      </div>
                    </div>
                  </div>
                </CustomScrollBars>
              </div>
            </div>
          }
        </div>
      );
    }

    return (
      <HistoryExternalError
        isLoading={this.state.isLoading}
        historyExternalError={this.state.historyExternalError}
      />
    );
  }
}

export default PpHistoryExternal;
