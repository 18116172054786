import './feedbacks.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';

import FeedbackActions from 'src/actions/feedback.action';
import ActionTypes from 'src/constants/actionTypes';
import FeedbackKeysType, { FeedbackKeysStatus, TypeFeedbackFilter } from 'src/constants/feedbackKeys';
import Feedback from 'src/models/feedback';
import User from 'src/models/user';
import FeedbackStore from 'src/stores/feedback.store';
import FeedbacksUtils from 'src/utils/feedbacksUtils';
import FeedbackContent from './feedbackContent/feedbackContent';
import CustomScrollBars from '../../../common/customScrollBars/customScrollBars';

interface IProps {
  type: number;
}

interface IStates {
  feedbacks: Feedback[];
  filteredFeedbacks: Feedback[];
  typeFilter: TypeFeedbackFilter;
  filterSelected: number;
  currentCount: number;
}

class Feedbacks extends React.Component<IProps, IStates> {
  private isMount = false;

  public constructor(props: IProps) {
    super(props);
    this.state = {
      feedbacks: [],
      filteredFeedbacks: [],
      filterSelected: -1,
      typeFilter: TypeFeedbackFilter.TYPE_FB_FILTER_NONE,
      currentCount: 0,
    };
  }

  public componentWillMount() {
    this.isMount = true;
    FeedbackStore.addListener(ActionTypes.GET_FEEDBACKS.toString(), () => { this.getFeedbacks(); });
    FeedbackStore.addListener(ActionTypes.GET_FEEDBACKS_COUNTS.toString(), () => { this.countFeedback(); });
    FeedbackStore.addListener(ActionTypes.PUT_FEEDBACK.toString(), () => { this.updateFeedbacks(); });
  }

  public componentWillUnmount() {
    this.isMount = false;
    FeedbackStore.removeListener(ActionTypes.GET_FEEDBACKS.toString(), () => { this.getFeedbacks(); });
    FeedbackStore.removeListener(ActionTypes.GET_FEEDBACKS_COUNTS.toString(), () => { this.countFeedback(); });
    FeedbackStore.removeListener(ActionTypes.PUT_FEEDBACK.toString(), () => { this.updateFeedbacks(); });
  }

  private getFeedbacks = () => {
    if (this.isMount) {
      this.setState({
        feedbacks: FeedbackStore.getFeedbacks(),
        filteredFeedbacks: FeedbackStore.getFeedbacks(),
        filterSelected: -1,
        typeFilter: TypeFeedbackFilter.TYPE_FB_FILTER_NONE,
      });
    }
  }

  /**
    * Counts the feedbacks message depending on their type (Bug / Suggestions / Other)
    */
  private countFeedback = () => {
    let count;

    switch (this.props.type) {
      case FeedbackKeysType.FB_TYPE_OPTIONS_BUG:
        count = FeedbackStore.getFeedbacksBugsCount();
        break;
      case FeedbackKeysType.FB_TYPE_OPTIONS_SUGGESTIONS:
        count = FeedbackStore.getFeedbacksSuggestionsCount();
        break;
      case FeedbackKeysType.FB_TYPE_OPTIONS_OTHER:
        count = FeedbackStore.getFeedbacksOthersCount();
        break;
      default:
        count = FeedbackStore.getFeedbacksArchivedCount();
        break;
    }
    if (this.isMount) {
      this.setState({
        currentCount: count,
      });
    }
  }

  /**
   * Update status feedback
   */
  private updateFeedbacks() {
    if (this.isMount) {
      FeedbackActions.emitGetFeedbacksCounts();
      FeedbackActions.emitGetFeedbacks(this.props.type, this.props.type === FeedbackKeysType.FB_TYPE_OPTIONS_ARCHIVED);
    }
  }

  /**
   * Filter Feedback depends on the type
   */
  public filterFeedback = (type: TypeFeedbackFilter, filter: number): void => {
    let filteredFeedbacks = this.state.feedbacks;

    if ((this.state.typeFilter === type && this.state.filterSelected === filter) || filter === -1) {
      return this.setState({
        filteredFeedbacks,
        filterSelected: -1,
        currentCount: filteredFeedbacks.length,
      });
    }
    switch (type) {
      case TypeFeedbackFilter.TYPE_FB_FILTER_TYPE:
        filteredFeedbacks = this.state.feedbacks.filter((fb: Feedback) => {
          return fb.type === filter;
        });
        break;
      case TypeFeedbackFilter.TYPE_FB_FILTER_STATUS:
        filteredFeedbacks = this.state.feedbacks.filter((fb: Feedback) => {
          return fb.status === filter;
        });
        break;
      case TypeFeedbackFilter.TYPE_FB_FILTER_PAGE:
        filteredFeedbacks = this.state.feedbacks.filter((fb: Feedback) => {
          return fb.page === filter;
        });
        break;
    }

    this.setState({
      filteredFeedbacks,
      typeFilter: type,
      filterSelected: filter,
      currentCount: filteredFeedbacks.length,
    });
  }

  private getFilterName() {
    switch (this.state.typeFilter) {
      case TypeFeedbackFilter.TYPE_FB_FILTER_TYPE:
        return FeedbacksUtils.typesOptions[this.state.filterSelected].text;
      case TypeFeedbackFilter.TYPE_FB_FILTER_STATUS:
        return FeedbacksUtils.statusOptions[this.state.filterSelected].text;
      case TypeFeedbackFilter.TYPE_FB_FILTER_PAGE:
        return FeedbacksUtils.pageOptions[this.state.filterSelected].text;
      default:
        return null;
    }
  }

  /**
   * Feedback content with all details
   */
  private getFeedbacksList = (fbs: Feedback[]): JSX.Element[] => {
    return fbs.map((feedback, key: number) =>
      (
        <div id="content" key={key}>
          <FeedbackContent
            key={feedback.id}
            id={feedback.id ? feedback.id : -1}
            type={feedback.type}
            page={feedback.page}
            tab={feedback.tab}
            comment={feedback.comment}
            status={feedback.status ? feedback.status : 0}
            author={feedback.author ? feedback.author : new User()}
            createdAt={feedback.createdAt ? feedback.createdAt : new Date()}
          />
        </div>
      ),
    );
  }

  public render() {

    const types = this.props.type === FeedbackKeysType.FB_TYPE_OPTIONS_ARCHIVED ?
      (
        <FormattedMessage
          id="menu.feedbacks.archives"
          defaultMessage="Archives"
        />
      ) : FeedbacksUtils.typesOptions[this.props.type].text;

    const filter = this.state.filterSelected !== -1 ?
      (
        <span>
          : {this.getFilterName()}
        </span>
      ) : null;

    const filterType = this.props.type === FeedbackKeysType.FB_TYPE_OPTIONS_ARCHIVED ?
      (
        <Dropdown.Menu scrolling={true}>
          <Dropdown.Item
            toggle={'true'}
            content={FeedbacksUtils.typesOptions[FeedbackKeysType.FB_TYPE_OPTIONS_BUG].text}
            selected={true}
            key="bug"
            active={this.state.filterSelected === FeedbackKeysType.FB_TYPE_OPTIONS_BUG
              && this.state.typeFilter === TypeFeedbackFilter.TYPE_FB_FILTER_TYPE}
            onClick={() => this.filterFeedback(TypeFeedbackFilter.TYPE_FB_FILTER_TYPE,
                                               FeedbackKeysType.FB_TYPE_OPTIONS_BUG)}
          />
          <Dropdown.Item
            toggle={'true'}
            content={FeedbacksUtils.typesOptions[FeedbackKeysType.FB_TYPE_OPTIONS_SUGGESTIONS].text}
            selected={true}
            key="sugestions"
            active={this.state.filterSelected === FeedbackKeysType.FB_TYPE_OPTIONS_SUGGESTIONS
              && this.state.typeFilter === TypeFeedbackFilter.TYPE_FB_FILTER_TYPE}
            onClick={() => this.filterFeedback(TypeFeedbackFilter.TYPE_FB_FILTER_TYPE,
                                               FeedbackKeysType.FB_TYPE_OPTIONS_SUGGESTIONS)}
          />
          <Dropdown.Item
            toggle={'true'}
            content={FeedbacksUtils.typesOptions[FeedbackKeysType.FB_TYPE_OPTIONS_OTHER].text}
            selected={true}
            key="other"
            active={this.state.filterSelected === FeedbackKeysType.FB_TYPE_OPTIONS_OTHER
              && this.state.typeFilter === TypeFeedbackFilter.TYPE_FB_FILTER_TYPE}
            onClick={() => this.filterFeedback(TypeFeedbackFilter.TYPE_FB_FILTER_TYPE,
                                               FeedbackKeysType.FB_TYPE_OPTIONS_OTHER)}
          />
        </Dropdown.Menu>
      ) : null;

    const filterPage = this.props.type ===
      FeedbackKeysType.FB_TYPE_OPTIONS_BUG ||
      FeedbackKeysType.FB_TYPE_OPTIONS_SUGGESTIONS ||
      FeedbackKeysType.FB_TYPE_OPTIONS_OTHER ?
      (
        <Dropdown.Menu scrolling={true}>
          {FeedbacksUtils.pageOptions.map(
            page =>
              <Dropdown.Item
                toggle={'true'}
                key={page.value}
                content={page.text}
                selected={true}
                active={this.state.filterSelected === page.value
                  && this.state.typeFilter === TypeFeedbackFilter.TYPE_FB_FILTER_PAGE}
                onClick={() => this.filterFeedback(TypeFeedbackFilter.TYPE_FB_FILTER_PAGE,
                                                   page.value)}
              />,
          )}
        </Dropdown.Menu>
      ) : null;

    const filterStatus = this.props.type === FeedbackKeysType.FB_TYPE_OPTIONS_BUG ?
      (
        <Dropdown.Menu scrolling={true}>
          <Dropdown.Item
            toggle={'true'}
            content={FeedbacksUtils.statusOptions[FeedbackKeysStatus.FB_STATUS_TODO].text}
            selected={true}
            key="todo"
            active={this.state.filterSelected === FeedbackKeysStatus.FB_STATUS_TODO
              && this.state.typeFilter === TypeFeedbackFilter.TYPE_FB_FILTER_STATUS}
            onClick={() => this.filterFeedback(TypeFeedbackFilter.TYPE_FB_FILTER_STATUS,
                                               FeedbackKeysStatus.FB_STATUS_TODO)}
          />
          <Dropdown.Item
            toggle={'true'}
            content={FeedbacksUtils.statusOptions[FeedbackKeysStatus.FB_STATUS_IN_PROGRESS].text}
            selected={true}
            key="inProgress"
            active={this.state.filterSelected === FeedbackKeysStatus.FB_STATUS_IN_PROGRESS
              && this.state.typeFilter === TypeFeedbackFilter.TYPE_FB_FILTER_STATUS}
            onClick={() => this.filterFeedback(TypeFeedbackFilter.TYPE_FB_FILTER_STATUS,
                                               FeedbackKeysStatus.FB_STATUS_IN_PROGRESS)}
          />
        </Dropdown.Menu>
      ) : null;

    return (
      <div id="feedback-management">
        <div id="main-title">
          <FormattedMessage
            id="feedback.management"
            defaultMessage="Feedbacks management"
          />
        </div>
        <div id="feedbacks_header">
          <div id="title">
            {types} ({this.state.currentCount}) {filter}
          </div>
          <FormattedMessage
            id="filter"
            defaultMessage="Filter"
          >
            {msg =>
              <Dropdown
                id={'filterFeedback'}
                text={msg.toString()}
                icon="filter"
                floating={true}
                labeled={true}
                button={true}
                basic={true}
                className="icon"
              >
                <Dropdown.Menu direction="left">
                  <Dropdown.Menu scrolling={true}>
                    <Dropdown.Item
                      toggle={'true'}
                      content="None"
                      selected={true}
                      key="none"
                      active={this.state.filterSelected === -1
                      }
                      onClick={() => this.filterFeedback(TypeFeedbackFilter.TYPE_FB_FILTER_NONE, -1)}
                    />
                    {filterType}
                    {filterStatus}
                    {filterPage}
                  </Dropdown.Menu>
                </Dropdown.Menu>
              </Dropdown>
            }
          </FormattedMessage>
        </div >
        <div className={'scrollable-container'}>
          <CustomScrollBars>
            {this.getFeedbacksList(this.state.filteredFeedbacks)}
          </CustomScrollBars>
        </div>
      </div>

    );
  }
}

export default Feedbacks;
