import './UserListModal.scss';

import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

import { FormattedMessage } from 'react-intl';
import CustomScrollBars from '../../../../common/customScrollBars/customScrollBars';

interface IProps {
  title: string;
  showModal: boolean;
  content: JSX.Element[] | JSX.Element;
  showUsersModal(): void;
}

interface IStates {
  showContent: boolean;
  showModal: boolean;
}

class UserListModal extends React.Component<IProps, IStates> {
  render() {
    return (
      <Modal open={this.props.showModal} onClose={this.props.showUsersModal} className="user-list-modal">
        <Modal.Header>{this.props.title}</Modal.Header>
        <Modal.Content>
            <CustomScrollBars className="members-scroll-area">
              <div className="members-list">
                {this.props.content}
              </div>
            </CustomScrollBars>
        </Modal.Content>
        <Modal.Actions>
          <Button id="close-modal" onClick={this.props.showUsersModal}>
            <FormattedMessage id="close" defaultMessage="Close" />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
export default UserListModal;
