import './attachmentRow.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Icon, Input } from 'semantic-ui-react';

import ModeTypes from 'src/constants/modeTypes';
import Attachment from '../../../models/attachment';
import Utils from 'src/utils/utils';

interface IProps {
  mode: ModeTypes;
  attachment: Attachment;
  handleAttachmentChange(value: string, id: number, type: string): void;
  deleteAttachment(id: number): void;
}

const attachmentRow: React.SFC<IProps> = (props) => {
  const MIN_URL_LENGTH = 3;
  const MAX_URL_LENGTH = 512;

  const isErrorMessageHidden = !props.attachment.url ||
    (props.attachment.url.length >= MIN_URL_LENGTH && props.attachment.url.length <= MAX_URL_LENGTH);
  const isEmptyUrlMessageHidden = !(props.attachment.url === '' || (!!props.attachment.title && !props.attachment.url));
  const isEmptyErrorMessageHidden = !((props.attachment.title === '')
    || (!!props.attachment.title ? false : !!props.attachment.url));

  return (Utils.isOnViewOrDeletionMode(props.mode))
    ? (
      <a key={props.attachment.id} target="_blank" rel="noopener noreferrer" href={props.attachment.url}>
        {props.attachment.title}
      </a>
    )
    : (
      <>
        <Form key={props.attachment.id} className="attachment">
          <FormattedMessage id="attachments.title" defaultMessage="Title">
            {msg =>
              <Input
                className={isEmptyErrorMessageHidden ? 'att-input' : 'att-input error'}
                value={props.attachment.title || ''}
                maxLength={50}
                onChange={(e, data) => props.handleAttachmentChange(data.value, props.attachment.id, 'title')}
                placeholder={msg}
              />
            }
          </FormattedMessage>
          <FormattedMessage id="attachments.urlPlaceholder" defaultMessage="URL: http, https, shared drive, ...">
            {msg =>
              <Input
                className={!isErrorMessageHidden || !isEmptyUrlMessageHidden ? 'att-input error' : 'att-input'}
                value={props.attachment.url || ''}
                maxLength={512}
                onChange={(e, data) => props.handleAttachmentChange(data.value, props.attachment.id, 'url')}
                placeholder={msg}
              />
            }
          </FormattedMessage>
          <div className="remove-button">
            <Icon name="trash" onClick={() => props.deleteAttachment(props.attachment.id)} />
          </div>
        </Form>
        <div className="little-message error" hidden={isErrorMessageHidden}>
          <FormattedMessage
            id="attachments.urlWrongPattern"
            defaultMessage="The URL field must contain a value between 3 and 512 characters"
          />
        </div>
        <div className="little-message error" hidden={isEmptyErrorMessageHidden}>
          <FormattedMessage id="attachments.titleFieldNotEmpty" defaultMessage="The title field must not be empty" />
        </div>
        <div className="little-message error" hidden={isEmptyUrlMessageHidden}>
          <FormattedMessage id="attachments.urlFieldNotEmpty" defaultMessage="The URL field must not be empty" />
        </div>
      </>
    );
};

export { attachmentRow as AttachmentRow };
