import { Months, Quarter, TimelineView } from '../constants/timeline';
import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import Utils from './utils';
import { KeyPointInfo, KeyPoints } from '../constants/piTimeline';

export default class TimelineUtils {

  public static isOnMonthView = (view: TimelineView): boolean => {
    return view === TimelineView.MONTH;
  }

  public static isOnQuarterView = (view: TimelineView): boolean => {
    return view === TimelineView.QUARTER;
  }

  public static isOnYearView = (view: TimelineView): boolean => {
    return view === TimelineView.YEAR;
  }

  public static getTitleOfQuarter = (quarterSelected: Quarter): JSX.Element => {
    switch (quarterSelected) {
      case Quarter.Q1:
        return (
          <FormattedMessage
            id="quarter1"
            defaultMessage="Jan - Feb - Mar"
          />);
      case Quarter.Q2:
        return (
          <FormattedMessage
            id="quarter2"
            defaultMessage="Apr - May - Jun"
          />);
      case Quarter.Q3:
        return (
          <FormattedMessage
            id="quarter3"
            defaultMessage="Jul - Aug - Sep"
          />);
      case Quarter.Q4:
        return (
          <FormattedMessage
            id="quarter4"
            defaultMessage="Oct - Nov - Dec"
          />);
    }
  };

  public static getTitleOfMonth = (monthSelected: Months, shortened = false): JSX.Element => {
    switch (monthSelected) {
      case Months.JANUARY:
        return shortened ?  (
            <FormattedMessage
              id="jan"
              defaultMessage="JAN"
            />)
          : (
            <FormattedMessage
              id="january"
              defaultMessage="January"
            />);
      case Months.FEBRUARY:
        return shortened ?  (
            <FormattedMessage
              id="feb"
              defaultMessage="FEB"
            />)
          : (
            <FormattedMessage
              id="february"
              defaultMessage="February"
            />);
      case Months.MARCH:
        return shortened ?  (
            <FormattedMessage
              id="mar"
              defaultMessage="MAR"
            />)
          : (
            <FormattedMessage
              id="march"
              defaultMessage="March"
            />);
      case Months.APRIL:
        return shortened ?  (
            <FormattedMessage
              id="apr"
              defaultMessage="APR"
            />)
          : (
            <FormattedMessage
              id="april"
              defaultMessage="April"
            />);
      case Months.MAY:
        return shortened ?  (
            <FormattedMessage
              id="may.caps"
              defaultMessage="MAY"
            />)
          : (
            <FormattedMessage
              id="may"
              defaultMessage="May"
            />);
      case Months.JUNE:
        return shortened ?  (
            <FormattedMessage
              id="jun"
              defaultMessage="JUN"
            />)
          : (
            <FormattedMessage
              id="june"
              defaultMessage="June"
            />);
      case Months.JULY:
        return shortened ?  (
            <FormattedMessage
              id="jul"
              defaultMessage="JUL"
            />)
          : (
            <FormattedMessage
              id="july"
              defaultMessage="July"
            />);
      case Months.AUGUST:
        return shortened ?  (
            <FormattedMessage
              id="aug"
              defaultMessage="AUG"
            />)
          : (
            <FormattedMessage
              id="august"
              defaultMessage="August"
            />);
      case Months.SEPTEMBER:
        return shortened ?  (
            <FormattedMessage
              id="sep"
              defaultMessage="SEP"
            />)
          : (
            <FormattedMessage
              id="september"
              defaultMessage="September"
            />);
      case Months.OCTOBER:
        return shortened ?  (
            <FormattedMessage
              id="oct"
              defaultMessage="OCT"
            />)
          : (
            <FormattedMessage
              id="october"
              defaultMessage="October"
            />);
      case Months.NOVEMBER:
        return shortened ?  (
            <FormattedMessage
              id="nov"
              defaultMessage="NOV"
            />)
          : (
            <FormattedMessage
              id="november"
              defaultMessage="November"
            />);
      case Months.DECEMBER:
        return shortened ?  (
            <FormattedMessage
              id="dec"
              defaultMessage="DEC"
            />)
          : (
            <FormattedMessage
              id="december"
              defaultMessage="December"
            />);

    }
  };

  public static getCurrentQuarter = (currentMonth: Months): Quarter => {
    const currentQuarter: number = Math.floor((currentMonth + 3) / 3);
    switch (currentQuarter) {
      case 1:
        return Quarter.Q1;
      case 2:
        return Quarter.Q2;
      case 3:
        return Quarter.Q3;
      case 4:
        return Quarter.Q4;
      default:
        return Quarter.Q1;
    }
  };

  public static getFirstMonthOfQuarter = (quarter: Quarter): Months => {
    switch (quarter) {
      case Quarter.Q1:
        return Months.JANUARY;
      case Quarter.Q2:
        return Months.APRIL;
      case Quarter.Q3:
        return Months.JULY;
      case Quarter.Q4:
        return Months.OCTOBER;
      default:
        return new Date().getMonth();
    }
  };

  public static getNbDaysInYear = (year: number) => {
    return (Utils.isLeapYear(year)) ? 366 : 365;
  }

  public static getNbDaysForQuarter = (quarter: Quarter, year: number) => {
    switch (quarter) {
      case Quarter.Q1:
        return TimelineUtils.getNbDaysForMonth(Months.JANUARY, year)
          + TimelineUtils.getNbDaysForMonth(Months.FEBRUARY, year)
          + TimelineUtils.getNbDaysForMonth(Months.MARCH, year);
      case Quarter.Q2:
        return TimelineUtils.getNbDaysForMonth(Months.APRIL, year)
          + TimelineUtils.getNbDaysForMonth(Months.MAY, year)
          + TimelineUtils.getNbDaysForMonth(Months.JUNE, year);
      case Quarter.Q3:
        return TimelineUtils.getNbDaysForMonth(Months.JULY, year)
          + TimelineUtils.getNbDaysForMonth(Months.AUGUST, year)
          + TimelineUtils.getNbDaysForMonth(Months.SEPTEMBER, year);
      case Quarter.Q4:
        return TimelineUtils.getNbDaysForMonth(Months.OCTOBER, year)
          + TimelineUtils.getNbDaysForMonth(Months.NOVEMBER, year)
          + TimelineUtils.getNbDaysForMonth(Months.DECEMBER, year);
      default:
        return 0;

    }
  }

  public static getDayOfQuarter = (year: number) => {
    const currDay = new Date().getDate();
    const currMonth: Months = new Date().getMonth();
    switch (currMonth) {
      case Months.JANUARY:
      case Months.APRIL:
      case Months.JULY:
      case Months.OCTOBER:
        return currDay;
      case Months.FEBRUARY:
      case Months.MAY:
      case Months.AUGUST:
      case Months.NOVEMBER:
        return currDay + TimelineUtils.getNbDaysForMonth(currMonth - 1, year);
      case Months.MARCH:
      case Months.JUNE:
      case Months.SEPTEMBER:
      case Months.DECEMBER:
        return currDay + TimelineUtils.getNbDaysForMonth(currMonth - 1, year)
          + TimelineUtils.getNbDaysForMonth(currMonth - 2, year);
    }
  }

  public static isMonthOnQuarter = (currMonth: number | null, quarter: Quarter) => {
    if (currMonth !== null) {
      let months: Months[] = [];
      switch (quarter) {
        case Quarter.Q1:
          months = [Months.JANUARY, Months.FEBRUARY, Months.MARCH];
          break;
        case Quarter.Q2:
          months = [Months.APRIL, Months.MAY, Months.JUNE];
          break;
        case Quarter.Q3:
          months = [Months.JULY, Months.AUGUST, Months.SEPTEMBER];
          break;
        case Quarter.Q4:
          months = [Months.OCTOBER, Months.NOVEMBER, Months.DECEMBER];
          break;
      }
      return months.includes(currMonth);
    }
    return false;
  }

  public static getNbDaysForMonth = (currMonth: Months, year: number) => {
    let nbDays = 0;
    if (currMonth >= 0 && currMonth < 12) {
      switch (currMonth) {
        case Months.FEBRUARY:
          nbDays = ((Utils.isLeapYear(year)) ? 29 : 28);
          break;
        case Months.JANUARY:
        case Months.MARCH:
        case Months.MAY:
        case Months.JULY:
        case Months.AUGUST:
        case Months.OCTOBER:
        case Months.DECEMBER:
          nbDays = 31;
          break;
        default:
          nbDays = 30;
          break;
      }
    }
    return nbDays;
  }

  public static isDayOnMonthStart = (day: number | null) => {
    return day && day >= 1 && day <= 10;
  };

  public static isDayOnMonthMid = (day: number | null) => {
    return day && day > 10 && day <= 20;
  };

  public static isDayOnMonthEnd = (day: number | null) => {
    return day && day > 20 && day <= 31;
  };

  public static areDatesTheSame = (firstDate: KeyPointInfo, secondDate: KeyPointInfo): boolean => {
    return (firstDate.year === secondDate.year) && (firstDate.month === secondDate.month)
      && (firstDate.day === secondDate.day);
  }

  public static areDatesOnSameDay = (keyPoints: KeyPoints, forMilestone: boolean) => {
    let areDatesOnSameDay = false;
    if (!keyPoints.isTargetSet()) {
      return false;
    }
    if (keyPoints.isStartingSet()) {
      if (keyPoints.isCompletionSet() && keyPoints.isForecastSet()) {
        areDatesOnSameDay = (
          TimelineUtils.areDatesTheSame(keyPoints.completionDate!, keyPoints.targetDate!)
          && TimelineUtils.areDatesTheSame(keyPoints.forecastDate!, keyPoints.targetDate!)
          )
          || (TimelineUtils.areDatesTheSame(keyPoints.completionDate!, keyPoints.forecastDate!));
      } else if (keyPoints.isCompletionSet()) {
        TimelineUtils.areDatesTheSame(keyPoints.completionDate!, keyPoints.targetDate!);
      } else if (keyPoints.isForecastSet()) {
        TimelineUtils.areDatesTheSame(keyPoints.forecastDate!, keyPoints.targetDate!);
      }
    } else {
      areDatesOnSameDay = forMilestone ? keyPoints.isCompletionSet()
        && TimelineUtils.areDatesTheSame(keyPoints.completionDate!, keyPoints.targetDate!)
        : keyPoints.isCompletionSet() && keyPoints.isForecastSet()
        && TimelineUtils.areDatesTheSame(keyPoints.completionDate!, keyPoints.forecastDate!);
    }
    return areDatesOnSameDay;
  }
}
