import './publicationLabel.scss';

import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import Utils from '../../../utils/utils';

interface IProps {
  publishedDate: Date | null;
}

export default class PublicationLabel extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    if (!!this.props.publishedDate) {
      return (
        <div className="publication-label">
          <FormattedMessage id="publicationOf" defaultMessage="Publication of">
            {msg =>
              <Popup
                content={`${msg} ${Utils.displayFancyDate(this.props.publishedDate!)}`}
                inverted={true}
                position="bottom center"
                size="small"
                trigger={
                  <div className="publication-icon-container">
                    <Icon className="publication-icon" name="check circle" />
                  </div>
                }
              />
            }
          </FormattedMessage>
        </div>
      );
    }

    return null;
  }
}
