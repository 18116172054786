import './feedbackModal.scss';
import 'react-datepicker/dist/react-datepicker.css';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, Modal } from 'semantic-ui-react';

import FeedbackActions from 'src/actions/feedback.action';
import Feedback from 'src/models/feedback';
import NotificationActions from '../../actions/notification-actions';
import Utils from '../../utils/utils';
import FeedbackContainer from './feedbackContainer/feedbackContainer';
import { ToastType } from 'src/components/common/toast/toast';

interface IProps {
  onClose: () => void;
  open: boolean;
}

interface IStates {
  feedback: Feedback;
}

class FeedbackModal extends React.Component<IProps, IStates> {

  private defaultFeedBack = {
    type: -1,
    comment: '',
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      feedback: this.defaultFeedBack,
    };
  }

  /**
   * Action once feedback is sent
   */
  private postFeedback = () => {
    FeedbackActions.emitPostFeedback(this.state.feedback)
      .then(() => {
        NotificationActions.toast(
          <FormattedMessage id="feedBackSent" defaultMessage="Sent!" />,
          <FormattedMessage id="feedBackSentMessage" defaultMessage="Your feedback has been correctly sent" />,
          ToastType.SUCCESS,
        );
        this.props.onClose();
      })
      .catch(() => {
        Utils.toastError();
      });
  };

  /**
   * Set type value
   */
  private handleChange = (idAttribute: string, value: any) => {
    const feedback = { ...this.state.feedback };
    feedback[idAttribute] = value;
    this.setState({ feedback });
  };

  public render() {
    return (
      <Modal
        id="feedback-modal-container"
        open={this.props.open}
        closeOnEscape={true}
        closeOnDimmerClick={false}
        onClose={this.props.onClose}
      >
        <Modal.Content id="modal-content">
          <Modal.Header>
            <h1>
              <FormattedMessage
                id="feedback.title"
                defaultMessage="Send a feedback"
              />
            </h1>
          </Modal.Header>
          <FeedbackContainer
            feedback={this.state.feedback}
            onChange={this.handleChange}
          />
        </Modal.Content>
        <Modal.Actions id="actions">
          <Button
            id="button-left"
            color="grey"
            onClick={() => this.setState({ feedback: this.defaultFeedBack })}
            disabled={this.state.feedback === this.defaultFeedBack}
          >
            <FormattedMessage
              id="feedback.clear"
              defaultMessage="Clear"
            />
          </Button>
          <span>
            <Button
              onClick={this.props.onClose}
            >
              <FormattedMessage
                id="cancel"
                defaultMessage="Cancel"
              />
            </Button>
            <Button
              primary={true}
              onClick={this.postFeedback}
              disabled={this.state.feedback.comment === '' || this.state.feedback.type === -1}
            >
              <FormattedMessage
                id="feedback.submit"
                defaultMessage="Submit"
              />
              <Icon id="icon" name="checkmark" />
            </Button>
          </span>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default FeedbackModal;
