import 'react-datepicker/dist/react-datepicker.css';

import * as React from 'react';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';

import BusinessChallengeActions from 'src/actions/businessChallenge.action';
import ActionTypes from 'src/constants/actionTypes';
import { BusinessChallengePost } from 'src/models/businessChallenge';
import BusinessChallengeStore from 'src/stores/businessChallenge.store';
import Utils from 'src/utils/utils';
import NotificationActions from '../../../actions/notification-actions';
import PerformancePlanActions from '../../../actions/performancePlan.action';
import PerformancePlanAPI from '../../../api/performancePlan.api';
import Keywords from '../../../models/keywords';
import PerformancePlan from '../../../models/performancePlan';
import Creation from '../creation';
import BcCreationStep1 from './bcCreationStep1';
import BcCreationStep2 from './bcCreationStep2';
import BcCreationStep3 from './bcCreationStep3';
import { ToastType } from 'src/components/common/toast/toast';

interface IProps extends InjectedIntlProps, RouteComponentProps {
  ppId: number;
  open: boolean;
  close: () => void;
}

interface IStates {
  pp: PerformancePlan | undefined;
  bc: BusinessChallengePost;
  step: number;
  keywords: Keywords[];
  functions: Keywords[]; // functions has the same struct as keyword
  isNavigationDisabled: boolean;
}

class BcCreation extends React.Component<IProps & InjectedIntlProps, IStates> {

  private isMount = false;

  private creationTitle = this.props.intl.formatMessage({
    id: 'createNewBC',
    defaultMessage: 'Create a new Business Challenge',
  });

  private creationSteps = [
    {
      title: this.props.intl.formatMessage({
        id: 'basicInformation',
        defaultMessage: 'Basic information',
      }),
      subtitle: '',
    },
    {
      title: this.props.intl.formatMessage({
        id: 'metricsAndMilestones',
        defaultMessage: 'Metrics & Milestones',
      }),
      subtitle: '',
    },
    {
      title: this.props.intl.formatMessage({
        id: 'bcCreation.assignments',
        defaultMessage: 'BC leader(s) assignment',
      }),
      subtitle: '',
    },
  ];

  private messages = [
    <FormattedMessage key="createNewBC" id="createNewBC" defaultMessage="Create a new Business Challenge" />,
    <FormattedMessage key="basicInformation" id="basicInformation" defaultMessage="Basic information" />,
    <FormattedMessage key="metricsAndMilestones" id="metricsAndMilestones" defaultMessage="Metrics & Milestones" />,
    <FormattedMessage key="assignments" id="assignments" defaultMessage="BC leader(s) assignment" />,
  ];

  private defaultBc: BusinessChallengePost = {
    code: 0,
    name: '',
    keywordsId: 2,
    functionsId: 1,
    pillar: 1,
    objectives: '',
    budget: '',
    top5: false,
    metrics: [],
    milestones: [],
    assignedAccounts: [],
    attachments: [],
    isBudgetHidden: true,
  };

  public constructor(props: IProps & InjectedIntlProps) {
    super(props);
    this.state = {
      pp: undefined,
      bc: { ...this.defaultBc },
      step: 1,
      keywords: BusinessChallengeStore.getKeywords(),
      functions: BusinessChallengeStore.getFunctions(),
      isNavigationDisabled: false,
    };

    if (!this.messages) {
      return;
    }
  }

  public componentDidMount() {
    this.isMount = true;

    BusinessChallengeStore.addListener(ActionTypes.BUSINESS_CHALLENGE_KEYWORDS.toString(), this.getBcKeywords);
    BusinessChallengeStore.addListener(ActionTypes.BUSINESS_CHALLENGE_FUNCTION.toString(), this.getBcFunctions);
    BusinessChallengeActions.emitGetKeywords();
    BusinessChallengeActions.emitGetFunctions();

    this.getPp();
    this.setNavigationDisabled(true);
  }

  public componentWillUnmount() {
    this.isMount = false;

    BusinessChallengeStore.removeListener(ActionTypes.BUSINESS_CHALLENGE_KEYWORDS.toString(), this.getBcKeywords);
    BusinessChallengeStore.removeListener(ActionTypes.BUSINESS_CHALLENGE_FUNCTION.toString(), this.getBcFunctions);
  }

  private getBcKeywords = () => {
    if (this.isMount) {
      this.setState({ keywords: BusinessChallengeStore.getKeywords() });
    }
  };

  private getBcFunctions = () => {
    if (this.isMount) {
      this.setState({ functions: BusinessChallengeStore.getFunctions() });
    }
  };

  private getPp = () => {
    PerformancePlanAPI.getPerformancePlanBcCodes(this.props.ppId)
      .then((pp: PerformancePlan) => {
        const bc = { ...this.state.bc };
        bc.code = this.getDefaultCode(pp);
        this.setState({ pp, bc });
      });
  };

  /**
   * Get the default code of the BC, based on other BCs
   */
  private getDefaultCode = (pp: PerformancePlan): number => {
    let code = 1;
    if (pp.businessChallenges) {
      const codeArray = pp.businessChallenges.map(bc => bc.code);
      while (true) {
        if (!codeArray.find(y => code === y)) { // eslint-disable-line no-loop-func
          break;
        }
        code = code + 1;
      }
    }
    return code;
  };

  /**
   * Submit the new BC to the API and close the modal
   */
  private submit = () => {
    const cleanBc = { ...this.state.bc };
    cleanBc.name = cleanBc.name.trim();
    cleanBc.objectives = cleanBc.objectives.trim();
    cleanBc.budget = cleanBc.budget.trim();
    return BusinessChallengeActions.emitCreateBusinessChallenge(this.props.ppId, cleanBc)
      .then((newBcId: number) => {
        this.close();
        NotificationActions.toast(
          <FormattedMessage id="saved" defaultMessage="Saved!" />,
          (
            <FormattedMessage
              id="successBcCreation"
              defaultMessage="The Business Challenge has been successfully created"
            />
          ),
          ToastType.SUCCESS,
        );
        PerformancePlanActions.invalidateBcs();
        const ppId = this.props.history.location.pathname.split('/')[3];
        this.props.history.push(`/activities-board/${ppId}/business-challenge/${newBcId}/cockpit`);
      })
      .catch((err) => {
        if (err.error && err.error.code === 'CODE_EEXIST') {
          this.getPp();
          NotificationActions.toast(
            <FormattedMessage id="errorOccurred" defaultMessage="Error" />,
            <FormattedMessage id="errorBCCode" defaultMessage="This BC code is already used" />,
            ToastType.ERROR,
          );
        } else {
          Utils.toastError();
        }
      });
  };

  /**
   * Close the modal and reset the state
   */
  private close = () => {
    this.setNavigationDisabled(true);
    this.props.close();
    this.setState({
      bc: { ...this.defaultBc },
      step: 1,
    });
  };

  /**
   * Update the BC when a value is modified in the child components
   * @param key : string
   * @param value : any
   */
  private updateBC = (key: string, value: any) => {
    const bc = this.state.bc;
    bc[key] = value;
    this.setNavigationDisabled(JSON.stringify(bc) === JSON.stringify(this.defaultBc));
    this.setState({ bc });
  };

  private setNavigationDisabled = (isNavigationDisabled: boolean) => {
    this.setState({ isNavigationDisabled });
  };

  public render(): any {
    return (
        <Creation
            open={this.props.open}
            close={this.close}
            title={this.creationTitle}
            steps={this.creationSteps}
            submit={this.submit}
            isNavigationDisabled={this.state.isNavigationDisabled}
        >
          { this.state.pp && this.state.keywords && this.state.functions
              ? <BcCreationStep1
                  pp={this.state.pp}
                  bc={this.state.bc}
                  keywords={this.state.keywords}
                  functions={this.state.functions}
                  onUpdate={this.updateBC}
                  setNavigationDisabled={this.setNavigationDisabled}
              />
              : Utils.loader()
          }
          <BcCreationStep2
              bc={this.state.bc}
              onUpdate={this.updateBC}
              setNavigationDisabled={this.setNavigationDisabled}
          />
          <BcCreationStep3
              bc={this.state.bc}
              onUpdate={this.updateBC}
              setNavigationDisabled={this.setNavigationDisabled}
          />
        </Creation>
    );
  }
}

export default injectIntl(withRouter(BcCreation));
