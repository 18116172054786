import { EventEmitter } from 'events';

import ActionTypes from 'src/constants/actionTypes';
import { RightsOnActivityDTO, RightsOnPP, RightsOnPI, RightsOnBC } from 'src/models/rights';
import Action from '../actions/action';
import Dispatcher from '../dispatcher';

class RightsStore extends EventEmitter {

  private rightsOnPP: RightsOnPP;
  private rightsOnBC: RightsOnBC;
  private rightsOnPI: RightsOnPI;

  constructor() {
    super();
    Dispatcher.register(this.registerToActions.bind(this));
  }

  /**
  * Return PP rights
  * @returns {RightsOnPP}
  */
  public getRightsOnPP(): RightsOnPP {
    return this.rightsOnPP;
  }

  /**
  * Return BC rights
  * @returns {RightsOnBC}
  */
  public getRightsOnBC(): RightsOnBC {
    return this.rightsOnBC;
  }

  /**
  * Set PP rights
  * @param {RightsOnActivityDTO} rights
  * @returns {void}
  */
  private setRightsOnPP(rights: RightsOnActivityDTO): void {
    this.rightsOnPP = new RightsOnPP(rights);
    this.emit(ActionTypes.SET_PP_RIGHTS.toString());
  }

  /*
  * Set BC rights
  * @param {RightsOnActivityDTO} rights
  * @returns {void}
  */
  private setRightsOnBC(rights: RightsOnActivityDTO): void {
    this.rightsOnBC = new RightsOnBC(rights);
    this.emit(ActionTypes.SET_BC_RIGHTS.toString());
  }

  /*
 * Return PI rights
 * @returns {RightsOnPI}
 */
  public getRightsOnPI(): RightsOnPI {
    return this.rightsOnPI;
  }

  /**
  * Set PI rights
  * @param {RightsOnActivityDTO} rights
  * @returns {void}
  */
  private setRightsOnPI(rights: RightsOnActivityDTO): void {
    this.rightsOnPI = new RightsOnPI(rights);
    this.emit(ActionTypes.SET_PI_RIGHTS.toString());
  }

  /**
   * Performs a function according to an action
   * @param {Action} action
   * @return {void}
   */
  private registerToActions(action: Action) {
    switch (action.actionType) {
      case ActionTypes.SET_PP_RIGHTS:
        this.setRightsOnPP(action.rights);
        break;
      case ActionTypes.SET_BC_RIGHTS:
        this.setRightsOnBC(action.rights);
        break;
      case ActionTypes.SET_PI_RIGHTS:
        this.setRightsOnPI(action.rights);
        break;
    }
  }
}

export default new RightsStore();
