import config from '../config';
import AuthActions from '../actions/auth.actions';
import Utils from './utils';
import { AuthError } from '../constants/errors/auth.error';
import AuthAPI, { ILoginRes } from '../api/auth.api';

export default class HttpClient {

  private static fetchFromApi() {
    return fetch;
  }

  private static getOptions(method: string, body?: any) {
    const options = {
      method: `${method}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    options.headers['Authorization'] = `Bearer ${AuthActions.getToken()}`;

    if (body && (method !== 'GET')) {
      options['body'] = body;
    }
    if (body && body.length === 0 && (method === 'POST')) {
      options.headers['Content-Length'] = 0;
    }

    return options;
  }

  public static get<T>(url: string): Promise<T> {
    return this.fetchFromApi()(config.api.url + url, this.getOptions('GET'))
      .then(this.handle)
      .catch((err: any) => {
        return this.handleError<T>(err, () => HttpClient.get<T>(url));
      });
  }

  public static post<T>(url: string, body: any): Promise<T> {
    return this.fetchFromApi()(config.api.url + url, this.getOptions('POST', HttpClient.format(body)))
      .then(this.handle)
      .catch((err: any) => {
        return this.handleError<T>(err, () => HttpClient.post<T>(url, body));
      });
  }

  public static put<T>(url: string, body: any): Promise<T> {
    return this.fetchFromApi()(config.api.url + url, this.getOptions('PUT', HttpClient.format(body)))
      .then(this.handle)
      .catch((err: any) => {
        return this.handleError<T>(err, () => HttpClient.put<T>(url, body));
      });
  }

  public static patch<T>(url: string, body = {}): Promise<T> {
    return this.fetchFromApi()(config.api.url + url, this.getOptions('PATCH', HttpClient.format(body)))
      .then(this.handle)
      .catch((err: any) => {
        return this.handleError<T>(err, () => HttpClient.patch<T>(url, body));
      });
  }

  public static deleteWithId<T>(url: string): Promise<T> {
    return this.fetchFromApi()(config.api.url + url, this.getOptions('DELETE'))
      .then(this.handle)
      .catch((err: any) => {
        return this.handleError<T>(err, () => HttpClient.deleteWithId<T>(url));
      });

  }

  public static delete<T>(url: string, body: any): Promise<T> {
    return this.fetchFromApi()(config.api.url + url, this.getOptions('DELETE', HttpClient.format(body)))
      .then(this.handle)
      .catch((err: any) => {
        return this.handleError<T>(err, () => HttpClient.delete<T>(url, body));
      });
  }

  public static getImage(url: string) {
    const options = {
      method: 'GET',
      headers: {},
    };

    options.headers['Authorization'] = `Bearer ${AuthActions.getToken()}`;

    return this.fetchFromApi()(config.api.url + url, options).then((response: any) => {
      return response.blob();
    });
  }

  public static setImage(url: string, data: FormData) {
    const options = {
      method: 'PUT',
      headers: {},
      body: data,
    };

    options.headers['Authorization'] = `Bearer ${AuthActions.getToken()}`;

    return this.fetchFromApi()(config.api.url + url, options).then((response: any) => {
      return response.blob();
    });
  }

  public static setUserGuide(url: string, data: FormData): Promise<void> {
    const options = {
      method: 'PUT',
      headers: {},
      body: data,
    };

    options.headers['Authorization'] = `Bearer ${AuthActions.getToken()}`;

    return this.fetchFromApi()(config.api.url + url, options)
      .then(this.handle)
      .catch((err: any) => {
        return this.handleError(err, () => HttpClient.setUserGuide(url, data));
      });
  }

  public static getFile(url: string): Promise<string> {
    const options = {
      method: 'GET',
      headers: {},
    };

    options.headers['Authorization'] = `Bearer ${AuthActions.getToken()}`;

    return this.fetchFromApi()(config.api.url + url, options)
      .then((response: any) => {
        if (response.status !== 200) {
          throw response;
        }
        return response.blob().then(res => URL.createObjectURL(res));
      })
      .catch((err: any) => {
        return this.handleError(err, () => HttpClient.getFile(url));
      });
  }

  public static getBase64(url: string): Promise<string> {
    return this.fetchFromApi()(config.api.url + url, this.getOptions('GET'))
      .then((res: Response) => res.text())
      .catch((err: any) => {
        return this.handleError(err, () => HttpClient.getBase64(url));
      });
  }

  public static noToken() {
    return {
      get: (url: string) => {
        return fetch(config.api.url + url, this.getOptions('GET'));
      },
      post: (url: string, body: any) => {
        return fetch(config.api.url + url, this.getOptions('POST', HttpClient.format(body)))
          .then(this.handle);
      },
    };
  }

  private static handle(res: Response): any {
    const reqStatus = res.status;
    return res.text().then((text) => {
      let body;
      try {
        body = JSON.parse(text);
        body.reqStatus = reqStatus;
      } catch {
        body = { reqStatus, error: text };
      }
      if (res.ok) {
        return body;
      }
      throw body;
    });
  }

  private static handleError<T>(err: any, callback: () => any): any {
    if (err.error) {
      if (err.error.code === 'AUTH_01') {
        return callback();
      }

      if (err.error === AuthError.TOKEN_EXPIRED) {
        return AuthAPI.refresh()
          .then((res: ILoginRes) => {
            AuthActions.setTokens(res);
            return callback();
          })
          .catch(async () => {
            await AuthActions.logout();
          });
      }
    }

    if (err.reqStatus === 401) {
      new Utils().toastAuthError();
    }

    throw err;
  }

  /**
   * Format the body
   * @param body
   */
  private static format(body: any) {
    return JSON.stringify(body);
  }

  /**
   * Format the given object's values to URL query params
   * @param params: Object
   * @returns string
   */
  public static formatUrlQueryParams(params: Object) {
    const urlParams = Object.keys(params)
      .map(el => params[el] !== undefined ? `${el}=${params[el]}` : false)
      .filter(el => !!el)
      .join('&');

    return `${urlParams === '' ? '' : `?${urlParams}`}`;
  }
}
