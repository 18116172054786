import './piCreation.scss';
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import * as React from 'react';

import Milestone from 'src/models/milestone';
import Utils from 'src/utils/utils';
import Metric from '../../../models/metric';
import { PerformanceIndicatorPost } from '../../../models/performanceInitiative';
import MetricsTable
  from '../../activitiesBoard/containerActivities/metricsMilestones/metrics/metricsTable/metricsTable';
import ModeTypes from '../../../constants/modeTypes';
import TypeActivity from '../../../constants/typeActivity';
import Milestones from '../../activitiesBoard/containerActivities/metricsMilestones/milestones/milestones';
import { ComparisonType } from '../../../constants/comparisonType';

interface IProps {
  pi: PerformanceIndicatorPost;
  onUpdate: (key: string, value: any) => void;
  setNavigationDisabled(isNavigationDisabled: boolean): void;
}

interface IStates {
  pi: PerformanceIndicatorPost;
  metric: Metric;
  milestone: Milestone;
  isMetricsFormValid: boolean;
  isMilestonesFormValid: boolean;
}

class PiCreationStep2 extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);

    if (!this.props.pi.metrics) {
      this.props.onUpdate('metrics', []);
    }
    if (!this.props.pi.milestones) {
      this.props.onUpdate('milestones', []);
    }

    this.state = {
      pi: this.props.pi,
      metric: {
        name: '',
        isHidden: false,
        code: 0,
        comparisonType: ComparisonType.NONE,
        comparisonTolerance: 0,
        metricValues: [],
        mostRecentValues:  {
          current: 0,
          target: 0,
          hasImage: false,
          updateDate: new Date(),
        },
      },
      milestone: {
        code: 1,
        name: '',
        targetDate: new Date(),
        isHidden: false,
      },
      isMetricsFormValid: true,
      isMilestonesFormValid: true,
    };
    moment.locale(Utils.getCurrentUserLanguageName());
  }

  private checkForm() {
    this.props.setNavigationDisabled(!this.state.isMetricsFormValid || !this.state.isMilestonesFormValid);
  }

  updateSubFormValidation = (isValid: boolean, subForm: string) => {
    if (subForm === 'metrics') {
      this.setState({ isMetricsFormValid: isValid }, this.checkForm);
    } else {
      this.setState({ isMilestonesFormValid: isValid }, this.checkForm);
    }
  }

  public render(): React.ReactNode {
    return (
      <div id="piCreationStep2">
        <div className="metrics-container">
          <MetricsTable
            mode={ModeTypes.MODE_EDITION}
            selectedMetricIndex={0}
            metrics={this.props.pi.metrics}
            type={TypeActivity.BUSINESS_CHALLENGE}
            forActivityCreation={true}
            onDataChange={this.props.onUpdate}
            updateSubFormValidation={this.updateSubFormValidation}
            handleRowClick={() => {}}
          />
        </div>

        <Milestones
          mode={ModeTypes.MODE_EDITION}
          milestones={this.props.pi.milestones}
          forActivityCreation={true}
          onDataChange={this.props.onUpdate}
          updateSubFormValidation={this.updateSubFormValidation}
        />
      </div>
    );
  }
}

export default PiCreationStep2;
