import * as React from 'react';

import Metric, { MetricValueGet, MetricValuePost, MostRecentMetricValueDto } from '../../../../../../models/metric';
import { Button, Form, Icon, Input, Popup, Segment, Table } from 'semantic-ui-react';
import Utils from 'src/utils/utils';
import { FieldType } from '../../metricsContainer';
import { FormattedHTMLMessage } from 'react-intl';
import { ErrType } from 'src/constants/errors/typeError';
import MetricValueEdit from '../metricsTable/metricRow/metricValues/metricValueEdit';
import { ComparisonType } from 'src/constants/comparisonType';
import MetricValuesModal from '../metricsTable/metricRow/metricValues/metricValuesModal';
import ModeTypes from 'src/constants/modeTypes';
import MetricsUtils from 'src/utils/metricsUtils';
import MetricImageUpload from './metricImageUpload';
import TimelineUtils from '../../../../../../utils/timelineUtils';
import { Months, Quarter } from '../../../../../../constants/timeline';

const COMPARISON_TOLERANCE = 'comparisonTolerance';

enum Frequency {
  NONE = 0,
  MONTH = 1,
  QUARTER = 2,
  YEAR = 3,
}

interface IProps {
  index: number;
  metric: Metric;
  form: Metric;
  handleChangeMetric: (name: string, value: string | ComparisonType) => void;
  handleChangeMetricMostRecentValues: (metricValue: MetricValueGet) => void;
  handleChangeMetricValues: (metricValue: MetricValueGet, mvIndex: number | null) => void;
  handleSetMetricValues: (metricValues: MetricValueGet[]) => void;
  forActivityCreation: boolean;
  removeMetricValue: (metricValueIndex: number, index: number) => void;
  isNew: boolean;
  image?: string;
  setImageToUpload: (image: File) => void;
  hasDeletedImage: boolean;
  setDeletedImage: () => void;
}

interface IStates {
  showComparisonPopup: boolean;
  showPreviousValuesModal: boolean;
  metricValueIndex: number | null;
  currentMetricValueIndex: number;
}
export default class MetricForm extends React.Component<IProps, IStates> {

  private isMount = false;
  private comparisonPopupRef = React.createRef<HTMLDivElement>();
  private comparisonOptions = [
    {
      text: <FormattedHTMLMessage id="comparison.noComparison" defaultMessage="No comparison" />,
      value: ComparisonType.NONE,
    },
    {
      text: <FormattedHTMLMessage id="comparison.up" defaultMessage="Increasing" />,
      value: ComparisonType.UP,
    },
    {
      text: <FormattedHTMLMessage id="comparison.down" defaultMessage="Decreasing" />,
      value: ComparisonType.DOWN,
    },
    {
      text: <FormattedHTMLMessage id="comparison.equal" defaultMessage="Equal" />,
      value: ComparisonType.EQUAL,
    },
  ];

  private frequencyOptions = [
    {
      key: 'None',
      text: <FormattedHTMLMessage id="frequency.none" defaultMessage="None" />,
      value: Frequency.NONE,
    },
    {
      key: 'Month',
      text: <FormattedHTMLMessage id="frequency.month" defaultMessage="Month" />,
      value: Frequency.MONTH,
    },
    {
      key: 'Quarter',
      text: <FormattedHTMLMessage id="frequency.quarter" defaultMessage="Quarter" />,
      value: Frequency.QUARTER,
    },
    {
      key: 'Year',
      text: <FormattedHTMLMessage id="frequency.year" defaultMessage="Year" />,
      value: Frequency.YEAR,
    },
  ];

  constructor(props: IProps) {
    super(props);
    this.state = {
      showComparisonPopup: false,
      showPreviousValuesModal: false,
      metricValueIndex: null,
      currentMetricValueIndex: -1,
    };
  }

  componentDidMount() {
    this.isMount = true;
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    this.isMount = false;
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  private handleClickOutside = (event: any) => {
    if (this.isMount) {
      if (this.comparisonPopupRef
        && this.comparisonPopupRef.current
        && !this.comparisonPopupRef.current.contains(event.target)
      ) {
        this.setState({ showComparisonPopup: false });
      }
    }
  };

  private updateMetricValue = (metricValue: MetricValueGet, mIndex: number | null, mvIndex: number | null) => {
    if (metricValue?.metricId === 0) {
      this.props.handleChangeMetricMostRecentValues(metricValue);
    } else {
      this.props.handleChangeMetricValues(metricValue, mvIndex);
    }
  }

  private handleComparisonChange = (event: any) => {
    if (event.target.validity.valid) {
      this.props.handleChangeMetric(COMPARISON_TOLERANCE,
                                    event.target.value === '' ? '' : parseFloat(event.target.value));
    } else if (event.target.value === '') {
      // Input type="number" gives an incorrect value as empty string, but keeps displaying this value
      // Workaround to force input's displayed value to only number OR empty value
      const input = document.getElementById(COMPARISON_TOLERANCE) as HTMLInputElement;
      if (!!input) {
        if (this.props.metric.comparisonTolerance) {
          input.value = this.props.metric.comparisonTolerance.toString().replace(/\D/g, '');
        } else {
          input.value = '';
        }
      }

      this.props.handleChangeMetric(COMPARISON_TOLERANCE,
                                    event.target.value === '' ? '' : parseFloat(event.target.value));
    }
  };

  private toggleShowPreviousValuesModal = () => {
    this.setState(state => ({ showPreviousValuesModal: !state.showPreviousValuesModal }));
  };

  private rendeComparisonPopup = () => {
    return (
      <Popup
        id="metric-comparison-popup"
        content={(
          <div>
            <FormattedHTMLMessage
              id="comparison.desc"
              defaultMessage="Value/target comparison indicates the status of the metric's value depending on its target."
            />
            <br />
            <FormattedHTMLMessage
              id="comparison.fourTypes"
              defaultMessage="There are four types of comparison:"
            />
            <ul>
              <li>
                <span className="uppercase">
                  <FormattedHTMLMessage id="comparison.up" defaultMessage="Increasing" />
                </span>
                &nbsp;-&nbsp;
                <FormattedHTMLMessage
                  id="comparison.upDesc"
                  defaultMessage="The value should be higher than the target"
                />
              </li>
              <li>
                <span className="uppercase">
                  <FormattedHTMLMessage id="comparison.down" defaultMessage="Decreasing" />
                </span>
                &nbsp;-&nbsp;
                <FormattedHTMLMessage id="comparison.downDesc" defaultMessage="The value should be lower than the target" />
              </li>
              <li>
                <span className="uppercase">
                  <FormattedHTMLMessage id="comparison.equal" defaultMessage="Equal" />
                </span>
                &nbsp;-&nbsp;
                <FormattedHTMLMessage id="comparison.equalDesc" defaultMessage="The value should be at the target, +/- tolerance range" />
              </li>
              <li><FormattedHTMLMessage id="comparison.noComparison" defaultMessage="No comparison" /></li>
            </ul>
          </div>
        )}
        position="top center"
        size="small"
        trigger={<Icon className="comparison-info" name="info circle" />}
      />
    );
  }

  private onFrequencyChange = (value: Frequency) => {
    const date = this.props.form.mostRecentValues.updateDate
      ? new Date(this.props.form.mostRecentValues.updateDate) : new Date();
    const metricValues: MetricValuePost[] = [];
    const target = this.props.form.mostRecentValues.target || null;
    const year = TimelineUtils.getCurrentQuarter(date.getMonth()) === Quarter.Q4
      ? (date.getFullYear() + 1) : date.getFullYear();

    switch (value) {
      case Frequency.MONTH:
        let monthDate = new Date(year, Months.JANUARY, 1);
        for (let i = 0; i < 12; i += 1) {
          if (!Utils.areDatesSame(monthDate, date)) {
            metricValues.push({ target, current: null, updateDate: monthDate, hasImage: false });
          }
          monthDate = new Date(year, monthDate.getMonth() + 1, 1);
        }
        break;

      case Frequency.QUARTER:
        let quarterDate = new Date(year, Months.JANUARY, 1);
        for (let i = 0; i < 4; i += 1) {
          if (!Utils.areDatesSame(quarterDate, date)) {
            metricValues.push({ target, current: null, updateDate: quarterDate, hasImage: false });
          }
          quarterDate = new Date(year, i === 2 ? Months.DECEMBER : quarterDate.getMonth() + 3, 1);
        }
        break;

      case Frequency.YEAR:
        let yearDate = new Date(date.getFullYear(), Months.JANUARY, 1);
        for (let i = 0; i < 4; i += 1) {
          if (!Utils.areDatesSame(yearDate, date)) {
            metricValues.push({ target, current: null, updateDate: yearDate, hasImage: false });
          }
          yearDate = new Date(yearDate.getFullYear() + 1, Months.JANUARY, 1);
        }
        break;

      case Frequency.NONE:
      default:
        break;
    }

    this.props.handleSetMetricValues(metricValues.map(mv => mv as MetricValueGet));
  }

  render() {
    const isMetricNameInvalid = Utils.isFieldOnError(this.props.form.name, true, FieldType.STRING);
    const isUnitInvalid = Utils.isFieldOnError(this.props.form.unit, false, FieldType.STRING);
    const mostRecentMetricValueWithCurrent: MostRecentMetricValueDto = MetricsUtils
    .getLastSetCurrentValue(this.props.form);

    return (
      <>
        <Form id="metricForm">
          <div className="row">
            <Form.Field className="name-field">
              <label htmlFor="name">
                <FormattedHTMLMessage id="name" defaultMessage="name" />&nbsp;
                {Utils.isFieldOnError(this.props.form.name, true, FieldType.STRING) &&
                  Utils.getErrorMessageWithPopup(ErrType.ME_NAME)}
              </label>
              <Input
                id="name"
                name="name"
                className="title"
                disabled={this.props.metric.isFromLinked}
                value={(this.props.form.name) ? this.props.form.name : ''}
                onChange={(_, { name, value }) => this.props.handleChangeMetric(name, value)}
                error={isMetricNameInvalid}
              />
            </Form.Field>
            <Form.Field className="unit-field">
              <label htmlFor="unit"><FormattedHTMLMessage id="unit" defaultMessage="Unit" /></label>
              <Input
                id="unit"
                name="unit"
                disabled={this.props.metric.isFromLinked}
                value={Utils.showValueIfExists(this.props.form.unit, false)}
                onChange={(_, { name, value }) =>
                  this.props.handleChangeMetric(name, value)}
                error={isUnitInvalid}
              />
            </Form.Field>
          </div>
          <div className="row">
            <Table basic={true}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <label>
                      <FormattedHTMLMessage id="metrics.updateDate" defaultMessage="Date of Current Value" />
                    </label>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <label>
                      <FormattedHTMLMessage id="current" defaultMessage="Current Value" />
                    </label>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <label>
                      <FormattedHTMLMessage id="target" defaultMessage="Target" />
                    </label>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <MetricValueEdit
                    mostRecentTarget={mostRecentMetricValueWithCurrent.target}
                    updateMetricValue={this.updateMetricValue}
                    metricIsEmpty={this.props.metric.metricValues.length === 0}
                    isImported={this.props.metric.isFromLinked}
                  />
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
          {this.props.isNew &&
            <div className="row">
              <Form.Field className="frequency-field">
                <label htmlFor="name">
                  <FormattedHTMLMessage id="frequency" defaultMessage="Frequency" />:
                </label>
                <Form.Select
                  options={this.frequencyOptions}
                  defaultValue={Frequency.NONE}
                  onChange={(event, data) => this.onFrequencyChange(data.value as Frequency)}
                />
              </Form.Field>
            </div>
          }
          <hr />
          <div className="row button-group">
            <Form.Field className="comparison-field">
              {!this.props.forActivityCreation
                && <Button
                  className="previous-values-button"
                  primary={true}
                  onClick={this.toggleShowPreviousValuesModal}
                >
                  <Icon name="history" />
                  <FormattedHTMLMessage id="metrics.previousValues" defaultMessage="Previous Values" />
                </Button>
              }
              {this.state.showPreviousValuesModal &&
                <MetricValuesModal
                  index={this.props.index}
                  mode={ModeTypes.MODE_EDITION}
                  showModal={this.state.showPreviousValuesModal}
                  metric={this.props.form}
                  metricValueIndex={this.state.metricValueIndex}
                  currentMetricValueIndex={this.state.currentMetricValueIndex}
                  toggleShowModal={this.toggleShowPreviousValuesModal}
                  updateMetricValue={this.updateMetricValue}
                  removeMetricValue={this.props.removeMetricValue}
                />
              }
            </Form.Field>
            <Form.Field className="comparison-field">
              <Button
                className={`comparison-button ${this.props.metric.comparisonTolerance === undefined ? 'error' : ''}`}
                onClick={() => this.setState({ showComparisonPopup: true })}
                disabled={this.props.metric.isFromLinked}
              >
                <span><Icon name="chart line" />
                <FormattedHTMLMessage id="comparison" defaultMessage="Comparison" /></span>
                {this.rendeComparisonPopup()}
              </Button>
            </Form.Field>
            <Form.Field className="image-field">
              <MetricImageUpload
                disabled={this.props.metric.isFromLinked}
                image={this.props.hasDeletedImage ? undefined : this.props.image}
                setImageToUpload={this.props.setImageToUpload}
                setDeletedImage={this.props.setDeletedImage}
              />
            </Form.Field>
            </div>
        </Form>
        {this.state.showComparisonPopup &&
          <div className="popup-container" ref={this.comparisonPopupRef}>
            <Segment>
              {this.comparisonOptions.map(option => (
                <div key={option.value} className="comparison-radio">
                  <label
                    htmlFor={`comparisonType-${option.value}`}
                    key={`comparisonRadio-${option.value}`}
                  >
                    {option.text}
                  </label>
                  <input
                    id={`comparisonType-${option.value}`}
                    type="radio"
                    checked={this.props.form.comparisonType === option.value}
                    onChange={() => this.props.handleChangeMetric('comparisonType', option.value)}
                  />
                </div>
              ))}

              <div className="input-container">
                <span className={this.props.form.comparisonType !== ComparisonType.EQUAL ? 'disabled' : ''}>
                  <FormattedHTMLMessage id="comparison.tolerance" defaultMessage="Tolerance" />:
                </span>
                <Input
                  id="comparisonTolerance"
                  type="number"
                  pattern="[0-9]*"
                  min="0"
                  max="100"
                  disabled={this.props.form.comparisonType !== ComparisonType.EQUAL}
                  value={this.props.form.comparisonTolerance === undefined
                    ? ''
                    : this.props.form.comparisonTolerance
                  }
                  onInput={this.handleComparisonChange}
                  error={this.props.form.comparisonTolerance === undefined}
                />
                <span>%</span>
              </div>
            </Segment>
          </div>
        }
      </>
    );
  }
}
