import BusinessChallenge, {
  BusinessChallengeDetails,
  BusinessChallengeGet,
  BusinessChallengePisFields,
  BusinessChallengePost,
  BusinessChallengePut,
} from 'src/models/businessChallenge';
import Metric, { MetricValueGet } from 'src/models/metric';
import Milestone from 'src/models/milestone';
import Attachment from 'src/models/attachment';
import HttpClient from '../utils/httpClient';
import Keywords from '../models/keywords';
import { ImportBcData } from 'src/models/importActivities';
import PerformanceInitiative from 'src/models/performanceInitiative';
import { TopicsParams } from '../components/explore/containerExplore/topics/topics';
import ILinkRequestRes from '../constants/activityLink';
import { AssignedUser, SendTeamMailAccountDTO, UsersAssignation } from '../models/user';
import { AvailableRoles } from '../constants/typeActivity';
import { MemberOptions } from '../constants/member';
import History from 'src/models/history';
import { RightsOnActivityDTO } from 'src/models/rights';
import { BcPiFields } from '../constants/bcPiFields';
import { BcPiFieldsDTO } from '../models/bcPiFields';
import { CodeOrderBody } from '../constants/order';
import { AllBcMilestones } from '../constants/milestones';
import { ArchiveHistoryResult } from '../constants/interfaces/common.interface';

export default class BusinessChallengeAPI {

  /**
   * ROUTE : /api/business-challenges/by-keyword/:bcKeywordId
   * Get Business Challenges by given keywordId
   * @param bcKeywordId: number
   * @param params: TopicsParams
   * @returns Promise<BusinessChallenge[]>
   */
  public static getBusinessChallengesByKeyword(bcKeywordId: number, params: TopicsParams)
    : Promise<BusinessChallenge[]> {
    return HttpClient.get<BusinessChallenge[]>(
      `business-challenges/by-keyword/${bcKeywordId}${HttpClient.formatUrlQueryParams(params)}`,
    );
  }

  /**
   * ROUTE : /api/business-challenges/keywords
   * Get all business challenge keywords
   * @returns Promise<Keywords[]>
   */
  public static getKeywords(): Promise<Keywords[]> {
    return HttpClient.get<Keywords[]>('business-challenges/keywords');
  }

    /**
   * ROUTE : /api/business-challenges/keywords
   * Get all business challenge keywords
   * @returns Promise<Keywords[]>
   */
  public static getFunctions(): Promise<Keywords[]> {
    return HttpClient.get<Keywords[]>('business-challenges/functions');
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}
   * Delete Business challenge by Id
   * @param bcId : number
   * @returns Promise<BusinessChallenge>
   */
  public static deleteBusinessChallengeById(bcId: number): Promise<BusinessChallenge> {
    return HttpClient.deleteWithId<BusinessChallenge>(`business-challenges/${bcId}`);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}
   * Get a Business Challenge by ID filtered by user rights
   * @param bcId: number
   * @param targetPpId: number
   * @returns Promise<BusinessChallenge>
   */
  public static async getBusinessChallengeById(bcId: number, targetPpId?: number): Promise<BusinessChallenge> {
    const params = targetPpId ? { targetPpId } : {};
    return new BusinessChallenge(await HttpClient
      .get<BusinessChallengeGet>(`business-challenges/${bcId}${HttpClient.formatUrlQueryParams(params)}`));
  }

  /**
* ROUTE : /api/business-challenges/{bcId}/rights
* Get Business Challenge rights
* @param {number} bcId
* @returns {Promise<RightsOnActivityDTO>}
*/
  public static async getBusinessChallengeRightsById(bcId: number): Promise<RightsOnActivityDTO> {
    return await HttpClient.get<RightsOnActivityDTO>(`business-challenges/${bcId}/rights`);
  }

  /**
 * ROUTE : /api/business-challenges/{bcId}/global
 * Get a global Business Challenge by ID
 * @param {number} bcId
 * @param {number} targetPpId
 * @returns {Promise<BusinessChallenge>}
 */
  public static async getBusinessChallengeGlobalById(bcId: number, targetPpId?: number): Promise<BusinessChallenge> {
    const params = targetPpId ? { targetPpId } : {};
    return new BusinessChallenge(await HttpClient
      .get<BusinessChallengeGet>(`business-challenges/${bcId}/global${HttpClient.formatUrlQueryParams(params)}`));
  }

  /**
   * GET : /api/business-challenges/{bcId}/details
   * Get a Business Challenge details by ID
   * @param {number} bcId
   * @returns {Promise<BusinessChallengeDetails>}
  */
  public static async getBusinessChallengeDetailsById(bcId: number): Promise<BusinessChallengeDetails> {
    return HttpClient.get<BusinessChallengeDetails>(`business-challenges/${bcId}/details`);
  }

  /**
 * PUT : /api/business-challenges/{bcId}/details
 * Put a Business Challenge details
 * @param {number} bcId
 * @param {BusinessChallengeDetails} bc
 * @returns {Promise<BusinessChallengeDetails>}
*/
  public static async putBusinessChallengeDetails(bcId: number, bc: BusinessChallengeDetails)
  : Promise<BusinessChallengeDetails> {
    return HttpClient.put<BusinessChallengeDetails>(`business-challenges/${bcId}/details`, bc);
  }

  /**
   * GET : /api/business-challenges/{bcId}/field/{fieldType}
   * Get BC textarea field
   * @param {number} bcId
   * @param {FieldType} fieldType
   * @returns {Promise<BcPiFieldsDTO>}
   */
  public static async getBcField(bcId: number, fieldType: BcPiFields): Promise<BcPiFieldsDTO> {
    return HttpClient.get<BcPiFieldsDTO>(`business-challenges/${bcId}/field/${fieldType}`);
  }

  /**
   * PUT : /api/business-challenges/{bcId}/field/{fieldType}
   * Edit BC textarea field
   * @param {number} bcId
   * @param {FieldType} fieldType
   * @param {string} value
   * @returns {Promise<void>}
   */
  public static async editBcField(bcId: number, fieldType: BcPiFields, value: string): Promise<void> {
    return HttpClient.put<void>(`business-challenges/${bcId}/field/${fieldType}`, { value });
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/pi-codes
   * Get business challenge's PI codes
   * @param bcId : number
   * @returns Promise<BusinessChallenge>
   */
  public static getBusinessChallengePiCodes(bcId: number): Promise<BusinessChallenge> {
    return HttpClient.get<BusinessChallenge>(`business-challenges/${bcId}/pi-codes`);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/pi-cockpit-fields
   * Get business challenge's PI cockpit fields
   * @param bcId : number
   * @param type: string
   * @returns Promise<BusinessChallenge>
   */
  public static getBusinessChallengePiFields(bcId: number, type: BcPiFields): Promise<BusinessChallengePisFields> {
    return HttpClient.get<BusinessChallengePisFields>(`business-challenges/${bcId}/pi-cockpit-fields/${type}`);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/assignations
   * Get business challenge's assignations
   * @param {number} bcId
   * @returns {Promise<BusinessChallenge>}
   */
  public static getBusinessChallengeAssignations(bcId: number): Promise<BusinessChallenge> {
    return HttpClient.get<BusinessChallenge>(`business-challenges/${bcId}/assignations`);
  }

  /**
   * ROUTE : /api/business-challenges/link-pi-list/{bcId}
   * Get Business Challenge's PIs
   * @param bcId : number
   * @returns Promise<BusinessChallenge>
   */
  public static getBusinessChallengePis(bcId: number): Promise<PerformanceInitiative[]> {
    return HttpClient.get<PerformanceInitiative[]>(`business-challenges/link-pi-list/${bcId}`);
  }

  /**
   * ROUTE : /api/performance-plans/${ppId}/business-challenges
   * Create a Business Challenge
   * @param ppId : number
   * @param bc : BusinessChallengePost
   * @returns Promise<BusinessChallenge>
   */
  public static postBusinessChallenge(ppId: number, bc: BusinessChallengePost): Promise<BusinessChallenge> {
    return HttpClient.post<BusinessChallenge>(`performance-plans/${ppId}/business-challenges`, bc);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}
   * Edit a Business Challenge
   * @param bcId : number
   * @param bc : BusinessChallengePut
   * @returns Promise<BusinessChallenge>
   */
  public static putBusinessChallengeById(bcId: number, bc: BusinessChallengePut): Promise<BusinessChallenge> {
    return HttpClient.put<BusinessChallenge>(`business-challenges/${bcId}`, bc);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/publish
   * Publish a Business Challenge
   * @param {number} bcId
   * @param {string} name
   * @returns Promise<void>
   */
  public static postPublishBusinessChallengeById(bcId: number, name: string): Promise<void> {
    return HttpClient.post<void>(`business-challenges/${bcId}/publish`, { name });
  }

  /**
* ROUTE : /api/business-challenges/metrics{metricId}/image
* Get BC metric image
* @param {number} metricId
* @returns {Promise<Blob>}
*/
  public static getBusinessChallengeMetricImage(metricId: number) : Promise<Blob> {
    return HttpClient.getImage(`business-challenges/metrics/${metricId}/image`);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/metrics/{metricId}/image
   * @param {number} bcId
   * @param {number} metricId
   * @param {FormData} formData
   * @returns {Promise<void>}
   */
  public static setBusinessChallengeMetricImage(bcId: number, metricId: number, formData: FormData) {
    return HttpClient.setImage(`business-challenges/${bcId}/metrics/${metricId}/image`, formData);
  }

  /**
* ROUTE : /api/business-challenges/metrics{metricId}/image
* Delete BC metric image
* @param {number} bcId
* @param {number} metricId
* @returns {Promise<void>}
*/
  public static deleteBusinessChallengeMetricImage(bcId: number, metricId: number): Promise<void> {
    return HttpClient.delete<void>(`business-challenges/${bcId}/metrics/${metricId}/image`, {});
  }

  /**
 * ROUTE : /api/business-challenges/{bcId}/metrics
 * Get BC metrics
 * @param {number} bcId
 * @returns {Promise<{metrics: Metric[], linkedBcLinkMetrics: Metric[]}>}
 */
  public static getBusinessChallengeMetrics(bcId: number)
    : Promise<{ metrics: Metric[], linkedBcLinkMetrics: Metric[] }> {
    return HttpClient.get<{ metrics: Metric[], linkedBcLinkMetrics: Metric[] }>(`business-challenges/${bcId}/metrics`);
  }

    /**
 * ROUTE : /api/business-challenges/{bcId}/metrics/order
 * Edit BC metrics orders
 * @param {number} bcId
 * @param {CodeOrderBody[]} metricsOrdered
 * @returns {void}
 */
  public static putBusinessChallengeMetricsOrder(bcId: number, metricsOrdered: CodeOrderBody[]): Promise<void> {
    return HttpClient.put<void>(`business-challenges/${bcId}/metrics/order`, metricsOrdered);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/metrics
   * Create a new metric
   * @param bcId : number
   * @param metrics : Metric[]
   * @returns Promise<Metric[]>
   */
  public static postBusinessChallengeMetrics(bcId: number, metrics: Metric[]): Promise<Metric[]> {
    return HttpClient.post<Metric[]>(`business-challenges/${bcId}/metrics`, metrics);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/metric-values
   * Create a new metric
   * @param bcId : number
   * @param metricValues : MetricValueGet[]
   * @returns Promise<MetricValueGet[]>
   */
  public static postBusinessChallengeMetricValues(bcId: number, metricValues: MetricValueGet[])
      : Promise<MetricValueGet[]> {
    return HttpClient.post<MetricValueGet[]>(`business-challenges/${bcId}/metric-values`, metricValues);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/metrics
   * Delete a metric
   * @param bcId : number
   * @param metrics : number[]
   * @returns Promise<Metric[]>
   */
  public static deleteBusinessChallengeMetrics(bcId: number, metrics: number[]): Promise<Metric[]> {
    return HttpClient.delete<Metric[]>(`business-challenges/${bcId}/metrics`, metrics);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/metric-values
   * Delete one or multiple metric values
   * @param bcId : number
   * @param metricValuesIds: number[]
   * @returns Promise<MetricValueGet[]>
   */
  public static deleteBusinessChallengeMetricValues(bcId: number, metricValuesIds: number[])
      : Promise<MetricValueGet[]> {
    return HttpClient.delete<MetricValueGet[]>(`business-challenges/${bcId}/metric-values`, metricValuesIds);
  }

  /**
   * ROUTE : /api/performance-initiatives/{bcId}/metrics
   * Delete a metric
   * @param bcId : number
   * @param ids : number[]
   * @returns Promise<Metrics[]>
   */
  public static deleteBusinessChallengeAttachments(bcId: number, ids: number[]): Promise<Attachment[]> {
    return HttpClient.delete<Attachment[]>(`business-challenges/${bcId}/attachments`, ids);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/attachments/{metricId}
   * Edit one or multiple attachments in existing bc
   * @param bcId : number
   * @param attachments : Attachment[]
   * @returns Promise<Attachment[]>
   */
  public static putBusinessChallengeAttachmentsById(
    bcId: number, attachments: Attachment[],
  ): Promise<BusinessChallenge> {
    return HttpClient.put<BusinessChallenge>(`business-challenges/${bcId}/attachments/`, attachments);
  }

  /**
   * ROUTE : /api/business-challenges/{piId}/attachments/{metricId}
   * Edit one or multiple attachments in existing bc
   * @param bcId : number
   * @param attachments : Attachment[]
   * @returns Promise<Attachment[]>
   */
  public static postBusinessChallengeAttachments(
    bcId: number, attachments: Attachment[],
  ): Promise<Attachment[]> {
    const attachmentsWithoutTempId = attachments.map((attachment) => {
      const attachmentWithoutTempId = new Attachment();
      attachmentWithoutTempId.title = attachment.title;
      attachmentWithoutTempId.url = attachment.url;
      return attachmentWithoutTempId;
    });
    return HttpClient.post<Attachment[]>(`business-challenges/${bcId}/attachments/`, attachmentsWithoutTempId);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/metrics/edit-metrics
   * Edit a metric in existing bc
   * @param bcId : number
   * @param metrics: Metrics[]
   * @returns Promise<Metrics[]>
   */
  public static putBusinessChallengeMetrics(
    bcId: number, metrics: Metric[],
  ): Promise<Metric[]> {
    return HttpClient.put<Metric[]>(`business-challenges/${bcId}/metrics/edit-metrics`, metrics);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/metrics/metric-values
   * Edit a metric value in existing bc
   * @param bcId : number
   * @param metricValues: MetricValues[]
   * @returns Promise<metricValues[]>
   */
  public static putBusinessChallengeMetricValues(
    bcId: number, metricValues: MetricValueGet[],
  ): Promise<Metric[]> {
    return HttpClient.put<Metric[]>(`business-challenges/${bcId}/metrics/metric-values`, metricValues);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/milestones
   * Get all Milestone of a BC
   * @param bcId : number
   * @returns Promise<Milestone[]>
   */
  public static getBusinessChallengeMilestones(bcId: number): Promise<AllBcMilestones> {
    return HttpClient.get<AllBcMilestones>(`business-challenges/${bcId}/milestones`);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/milestones
   * Create a new milestone
   * @param bcId : number
   * @param milestones : Milestone[]
   * @returns Promise<Milestone[]>
   */
  public static postBusinessChallengeMilestones(bcId: number, milestones: Milestone[]): Promise<Milestone[]> {
    return HttpClient.post<Milestone[]>(`business-challenges/${bcId}/milestones`, milestones);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/milestones/order
   * Edit BC milestones orders
   * @param {number} bcId
   * @param {MilOrderBody[]} metricsOrdered
   * @returns {void}
   */
  public static putBusinessChallengeMilsOrder(bcId: number, milestonesOrdered: CodeOrderBody[]): Promise<void> {
    return HttpClient.put<void>(`business-challenges/${bcId}/milestones/order`, milestonesOrdered);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/milestones
   * Delete a Milestone
   * @param bcId : number
   * @param milestones : number[]
   * @returns Promise<Milestone[]>
   */
  public static deleteBusinessChallengeMilestones(bcId: number, milestones: number[]): Promise<Milestone[]> {
    return HttpClient.delete<Milestone[]>(`business-challenges/${bcId}/milestones`, milestones);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/milestones/edit-milestones
   * Edit a milestone in existing bc
   * @param bcId : number
   * @param milestones : Milestone[]
   * @returns Promise<Milestone[]>
   */
  public static putBusinessChallengeMilestones(bcId: number, milestones: Milestone[])
    : Promise<Milestone[]> {
    return HttpClient.put<Milestone[]>(`business-challenges/${bcId}/milestones/edit-milestones`, milestones);
  }

    /**
   * ROUTE : /api/business-challenges/{bcId}/link-metrics
   * Create new metrics link with BC
   * @param bcId : number
   * @param metrics : number[]
   * @returns Promise<void>
   */
  public static createBusinessChallengeLinkMetrics(bcId: number, metrics: number[]): Promise<void> {
    return HttpClient.post<void>(`business-challenges/${bcId}/link-metrics`, { metrics });
  }

   /**
   * ROUTE : /api/business-challenges/{bcId}/link-milestones
   * Create new milestones link with BC
   * @param bcId : number
   * @param milestones : number[]
   * @returns Promise<void>
   */
  public static createBusinessChallengeLinkMilestones(bcId: number, milestones: { id: number }[])
    : Promise<void> {
    return HttpClient.post<void>(`business-challenges/${bcId}/link-milestones`, { milestones });
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/unlink-metrics
   * Remove metrics link with BC
   * @param bcId : number
   * @param metrics : number[]
   * @returns Promise<void>
   */
  public static removeBusinessChallengeLinkMetrics(bcId: number, metrics: number[]): Promise<void> {
    return HttpClient.post<void>(`business-challenges/${bcId}/unlink-metrics`, { metrics });
  }

   /**
   * ROUTE : /api/business-challenges/{bcId}/unlink-milestones
   * Remove milestones link with BC
   * @param bcId : number
   * @param milestones : number[]
   * @returns Promise<void>
   */
  public static removeBusinessChallengeLinkMilestones(bcId: number, milestones: { id: number }[])
    : Promise<void> {
    return HttpClient.post<void>(`business-challenges/${bcId}/unlink-milestones`, { milestones });
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/assign
   * Assign users to BC
   * @param bcId : number
   * @param users : UsersAssignation
   * @returns Promise<void>
   */
  public static postBusinessChallengeAssignees(bcId: number, users: UsersAssignation): Promise<AssignedUser[]> {
    return HttpClient.post<AssignedUser[]>(`business-challenges/${bcId}/assign`, users);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/unassign
   * Unassign users from BC
   * @param bcId : number
   * @param users : UsersAssignation
   * @returns Promise<void>
   */
  public static deleteBusinessChallengeAssignees(bcId: number, users: UsersAssignation): Promise<void> {
    return HttpClient.delete<void>(`business-challenges/${bcId}/unassign`, users);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/team-email
   * Get BC for the team email modale
   * @param {number} bcId
   * @returns {Promise<BusinessChallenge>}
   */
  public static getBcForTeamEmail(bcId: number): Promise<BusinessChallenge> {
    return HttpClient.get<BusinessChallenge>(`business-challenges/${bcId}/team-email`);
  }

  /**
   * ROUTE : /api/export/{bcId}/cockpit-bc
   * Export the Business Challenge Cockpit
   * @param bcId : number
   * @returns Promise<void>
   */
  public static exportCockpit(bcId: number): Promise<string> {
    return HttpClient.get<string>(`export/${bcId}/cockpit-bc`);
  }

   /**
   * ROUTE : /api/export/{bcId}/history-bc
   * Export the Business Challenge History
   * @param {number} bcId
   * @param {number | undefined} historyId
   * @param {boolean} isPublicView (optional)
   * @returns {Promise<string>}
   */
  public static exportHistory(bcId: number, historyId: number | undefined, isPublicView?: boolean): Promise<string> {
    return HttpClient.get<string>(`export/${bcId}/history-bc${HttpClient.formatUrlQueryParams({ historyId, isPublicView })}`);
  }

  /**
 * ROUTE : /api/business-challenges/{ppId}/history/{historyId}
 * Edit a BC history
 * @param {number} bcId
 * @param {number} historyId
 * @param {string} name
 * @returns {Promise<History>}
 */
  public static editHistoryName(bcId: number, historyId: number, name: string): Promise<History> {
    return HttpClient.put<History>(`business-challenges/${bcId}/history/${historyId}`, { name });
  }

  /**
  * ROUTE : /api/business-challenges/{ppId}/history/{historyId}/archive
  * Archive a BC history
  * @param {number} bcId
  * @param {number} historyId
  * @returns {Promise<ArchiveHistoryResult>}
  */
  public static archiveHistory(bcId: number, historyId: number): Promise<ArchiveHistoryResult> {
    return HttpClient.post<ArchiveHistoryResult>(`business-challenges/${bcId}/history/${historyId}/archive`, {});
  }

  /**
   * ROUTE : /api/export/{bcId}/full-bc
   * Export the whole Business Challenge
   * @param bcId : number
   * @returns Promise<void>
   */
  public static exportFull(bcId: number): Promise<string> {
    return HttpClient.get<string>(`export/${bcId}/full-bc`);
  }

  /**
   * ROUTE : /api/export/{bcId}/full-bc-at-list
   * Export the Business Challenge full actions list
   * @param bcId : number
   * @returns Promise<void>
   */
  public static exportAllActions(bcId: number): Promise<string> {
    return HttpClient.get<string>(`export/${bcId}/full-bc-at-list`);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/import
   * Import a whole Business Challenge
   * @param data : ImportBcData
   * @returns Promise<void>
   */
  public static importBc(data: ImportBcData): Promise<void> {
    return HttpClient.post<void>(`business-challenges/${data.bcId}/import`, data);
  }

  /**
 * ROUTE : /api/business-challenges/{bcId}/bc-import-metrics-milestones
 * Get BC for import metrics / milestones
 * @param {number} bcId
 * @returns {Promise<BusinessChallenge>}
 */
  public static getBcForImportMetricsMilestones(bcId: number): Promise<BusinessChallenge> {
    return HttpClient.get<BusinessChallenge>(`business-challenges/${bcId}/bc-import-metrics-milestones`);
  }

  /**
   * ROUTE : /api/business-challenges/link-bc-list/{ppId}
   * Get BCs by PP id for Activity link
   * @param ppId: number
   * @returns Promise<BusinessChallenge[]>
   */
  public static getBcsForActivityLink(ppId: number): Promise<BusinessChallenge[]> {
    return HttpClient.get<BusinessChallenge[]>(`business-challenges/link-bc-list/${ppId}`);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/link-to-pp/{ppId}
   * Link a BC to a PP
   * @param bcId: number
   * @param ppId: number
   * @returns Promise<void>
   */
  public static linkBcToPp(bcId: number, ppId: number): Promise<void> {
    return HttpClient.patch<void>(`business-challenges/${bcId}/link-to-pp/${ppId}`);
  }

    /**
   * ROUTE : /api/business-challenges/{bcId}/link-to-pp/{ppId}/reject
   * Reject the link of a BC to a PP
   * @param bcId: number
   * @param ppId: number
   * @returns Promise<void>
   */
  public static rejectLinkBcToPp(bcId: number, ppId: number): Promise<void> {
    return HttpClient.patch<void>(`business-challenges/${bcId}/link-to-pp/${ppId}/reject`);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/unlink-from-pp/{ppId}
   * Unlink a BC from a PP
   * @param bcId: number
   * @param ppId: number
   * @returns Promise<void>
   */
  public static unlinkBcFromPp(bcId: number, ppId: number): Promise<void> {
    return HttpClient.deleteWithId<void>(`business-challenges/${bcId}/unlink-from-pp/${ppId}`);
  }

  /**
  * ROUTE : /api/business-challenges/{bcId}/about
  * Get BC about information
  * @param {number} bcId
  * @returns {Promise<BusinessChallenge>}
  */
  public static getBusinessChallengeAbout(bcId: number): Promise<BusinessChallenge> {
    return HttpClient.get<BusinessChallenge>(`business-challenges/${bcId}/about`);
  }

  /**
 * ROUTE : /api/business-challenges/{bcId}/about
 * Put BC about information
 * @param {number} bcId
 * @param {BusinessChallenge} bcAbout
 * @returns {Promise<void>}
 */
  public static putPerformancePlanAbout(bcId: number, bcAbout: BusinessChallenge): Promise<void> {
    return HttpClient.put<void>(`business-challenges/${bcId}/about`, bcAbout);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/attachments
   * Get BC attachments
   * @param {number} bcId
   * @returns {Promise<Attachment[]>}
   */
  public static getBusinessChallengeAttachments(bcId: number): Promise<Attachment[]> {
    return HttpClient.get<Attachment[]>(`business-challenges/${bcId}/attachments`);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/team-view
   * Get a BC information for Team View
   * @param bcId : number
   * @returns Promise<BusinessChallenge>
   */
  public static getBusinessChallengeForTeamView(bcId: number): Promise<BusinessChallenge> {
    return HttpClient.get<BusinessChallenge>(`business-challenges/${bcId}/team-view`);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/request-link
   * Send a Business Challenge notification to link
   * @param bcId : number
   * @param ppId
   * @returns Promise<BusinessChallenge>
   */

  public static requestLinkBcToPp(bcId: number, ppId: number): Promise<ILinkRequestRes> {
    return HttpClient.post<ILinkRequestRes>(`business-challenges/${bcId}/request-link/${ppId}`, {});
  }

  /**
   * ROUTE: /api/business-challenges/{bcId}/team-members
   * @param {number} bcId
   * @param {AvailableRoles[]} roles
   * @return {Promise<SendTeamMailAccountDTO[]>}
   */
  public static retrieveTeamMembers(bcId: number, roles: AvailableRoles[]): Promise<SendTeamMailAccountDTO[]> {
    const options = new MemberOptions(roles);
    return HttpClient
      .get<SendTeamMailAccountDTO[]>(`business-challenges/${bcId}/team-members${HttpClient.formatUrlQueryParams(options)}`);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/toggle-close
   * Toggle close BC
   * @param {number} bcId
   * @param {string} comment (optional)
   * @returns Promise<BusinessChallenge>
   */

  public static toggleCloseBc(bcId: number, comment?: string): Promise<BusinessChallenge> {
    return HttpClient.patch<BusinessChallenge>(`business-challenges/${bcId}/toggle-close`, { comment });
  }
}
