import './containerActivities.scss';

import * as React from 'react';
import { Prompt, Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import * as H from 'history';

import ActionTypes from 'src/constants/actionTypes';
import TypeActivity from 'src/constants/typeActivity';
import PerformancePlan from 'src/models/performancePlan';
import ActivitiesStore from 'src/stores/activities.store';
import Utils from '../../../utils/utils';
import ContentBC, { BcTabs } from './contentBC/contentBC';
import ContentPI, { PiTabs } from './contentPI/contentPI';
import ContentPP from './contentPP/contentPP';
import ActivitiesActions from '../../../actions/activities.action';
import PerformancePlanStore from '../../../stores/performancePlan.store';
import BusinessChallengeStore from '../../../stores/businessChallenge.store';
import PerformanceInitiativeStore from '../../../stores/performanceInitiative.store';
import AssignationEmailModal from './assignationEmailModal/assignationEmailModal';
import SegmentsStore from '../../../stores/segments.store';
import ConfirmModal from './confirmModal/confirmModal';
import NavigationUtils from '../../../utils/navigation.utils';
import SegmentsActions from '../../../actions/segments.action';
interface IStates {
  typeActivity: TypeActivity;
  activities: PerformancePlan[];
  displayCancelModal: boolean;
  nextLocation: string | undefined;
  isOverviewPanelOpen: boolean;
  isOverviewPanelRelative: boolean;
  isDraft: boolean;
}

class ContainerActivities extends React.Component<RouteComponentProps, IStates> {

  private isMount = false;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      typeActivity: ActivitiesStore.getTypeActivity(),
      activities: ActivitiesStore.getActivities(),
      displayCancelModal: false,
      nextLocation: undefined,
      isOverviewPanelOpen: false,
      isOverviewPanelRelative: false,
      isDraft: true,
    };
  }

  public componentWillMount() {
    this.isMount = true;
    ActivitiesStore.addListener(ActionTypes.ACTIVITIES_GET_MENU.toString(), this.getActivities);
    PerformancePlanStore.addListener(ActionTypes.PERFORMANCE_PLAN_DELETED.toString(), this.onDeletion);
    BusinessChallengeStore.addListener(ActionTypes.BUSINESS_CHALLENGE_DELETED.toString(), this.onDeletion);
    PerformanceInitiativeStore.addListener(ActionTypes.PERFORMANCE_INITIATIVE_DELETED.toString(), this.onDeletion);
    SegmentsStore.addListener(ActionTypes.SET_SEGMENT_MODE.toString(), this.onSegmentModeChange);
    this.setPanelView();
  }

  public componentDidUpdate(prevProps: Readonly<RouteComponentProps>, prevState: Readonly<IStates>) {
    if (prevProps !== this.props) this.forceUpdate();
    if (!this.isCockpit(this.props.location) && this.isCockpit(prevProps.location) && this.state.isOverviewPanelOpen) {
      this.setState({ isOverviewPanelOpen: false });
    }
  }

  public componentWillUnmount() {
    this.isMount = false;
    ActivitiesStore.removeListener(ActionTypes.ACTIVITIES_GET_MENU.toString(), this.getActivities);
    PerformancePlanStore.removeListener(ActionTypes.PERFORMANCE_PLAN_DELETED.toString(), this.onDeletion);
    BusinessChallengeStore.removeListener(ActionTypes.BUSINESS_CHALLENGE_DELETED.toString(), this.onDeletion);
    PerformanceInitiativeStore.removeListener(ActionTypes.PERFORMANCE_INITIATIVE_DELETED.toString(), this.onDeletion);
    SegmentsStore.addListener(ActionTypes.SET_SEGMENT_MODE.toString(), this.onSegmentModeChange);
  }
  //region LISTENER RELATED METHODS
  private getActivities = () => {
    if (this.isMount) {
      this.setState({ activities: ActivitiesStore.getActivities() });
    }
  };

  private onDeletion = () => {
    if (this.isMount) {
      ActivitiesActions.emitGetActivities();
    }
  };

  private onSegmentModeChange = () => {
    if (this.isMount) {
      if (Object.entries(SegmentsStore.getSegmentsModes()).some(([_, value]) => Utils.isOnCancelMode(value))) {
        this.setState({ displayCancelModal: true });
      }
    }
  };
  //endregion

  /**
   * Decides if navigation should be blocked (if editing) or not
   */
  private handleBlockedNavigation = (location: H.Location) => {
    if (SegmentsStore.hasSegmentsOnEdit() && !NavigationUtils.isDetailsChangeTabs(location, this.props.location)) {
      this.setState({ displayCancelModal: true, nextLocation: `${location.pathname}${location.search}` });
      return false;
    }

    this.setPanelView(location);
    return true;
  };

  //region CANCEL METHODS
  private resetCancel = () => {
    this.setState({ displayCancelModal: false, nextLocation: undefined });
  }

  private confirmCancel = (): void => {
    if (!!this.state.nextLocation) {
      SegmentsActions.emitResetSegments();
      this.props.history.push(this.state.nextLocation);
    } else {
      SegmentsStore.setCancellingSegmentsOnViewMode();
    }

    this.resetCancel();
  };

  private noConfirmCancel = (): void => {
    SegmentsStore.emitSetCancellingSegmentsToEdition();
    this.resetCancel();
  };
  //endregion

  //region PANEL
  private isCockpit = (location: H.Location): boolean => {
    return location.pathname.indexOf(BcTabs.COCKPIT) > -1 || location.pathname.indexOf(PiTabs.COCKPIT) > -1;
  }

  private togglePanelVisibility = (isVisible?: boolean) => {
    if ((isVisible !== undefined && this.state.isOverviewPanelOpen !== isVisible) || isVisible === undefined) {
      this.setState(prevState => ({
        isOverviewPanelOpen:  isVisible !== undefined ? isVisible : !prevState.isOverviewPanelOpen,
        isOverviewPanelRelative: this.isCockpit(this.props.location),
      }),           () => {
        if (SegmentsStore.hasPiAtMiSegmentOnEdit()) SegmentsActions.emitResetSegments();
      });
    }
  };

  private setPanelView = (location: H.Location = this.props.location) => {
    // Open overview panel by default it it's cockpit view
    const isCockpit = this.isCockpit(location);
    if (isCockpit !== this.state.isOverviewPanelOpen || isCockpit !== this.state.isOverviewPanelRelative) {
      this.setState(prevState => ({
        isOverviewPanelOpen: isCockpit || prevState.isOverviewPanelOpen,
        isOverviewPanelRelative: isCockpit,
      }));
    }
  }

  private setActivityId = (isDraft: boolean) => {

    this.setState({
      isDraft,
    });

  }

  handleDraftChange(isDraft: boolean) {
    this.setState({ isDraft });
  }
  //endregion

  public render() {
    const cancelEditionTitle = <FormattedMessage id="cancelEdition" defaultMessage="Cancel edition" />;
    const cancelEditionMessage = (
      <FormattedMessage
        id="cancelEditionQuestion"
        defaultMessage="Cancel edition? Any unsaved work will be lost."
      />
    );
    return (
      <div id="container-activities">
        {this.state.activities && this.state.activities.length > 0
          ? <Switch>
            <Route path={`${this.props.match.path}/performance-plan/:id`}>
              <ContentPP
                setIsDraft={this.setActivityId}
              />
            </Route>
            <Route path={`${this.props.match.path}/:ppId/business-challenge/:id`}>
              <ContentBC
                isOverviewPanelOpen={this.state.isOverviewPanelOpen}
                isOverviewPanelRelative={this.state.isOverviewPanelRelative}
                toggleOverviewPanelVisibility={this.togglePanelVisibility}
              />
            </Route>
            <Route path={`${this.props.match.path}/:ppId/performance-initiative/:id`}>
              <ContentPI
                isOverviewPanelOpen={this.state.isOverviewPanelOpen}
                isOverviewPanelRelative={this.state.isOverviewPanelRelative}
                toggleOverviewPanelVisibility={this.togglePanelVisibility}
              />
            </Route>
            <Redirect to="/activities-board/taskboard" />
          </Switch>
          : Utils.loader()
        }

        <Prompt message={location => this.handleBlockedNavigation(location)} />
        {this.state.displayCancelModal
          && <ConfirmModal
            closeNoFunction={this.noConfirmCancel}
            closeYesFunction={this.confirmCancel}
            title={cancelEditionTitle}
            message={cancelEditionMessage}
          />
        }
        <AssignationEmailModal ppIsDraft={this.state.isDraft}/>
      </div>
    );
  }
}

export default withRouter(ContainerActivities);
