import './teamViewNode.scss';

import React from 'react';
import { Accordion, Divider, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import TypeActivity, { roleActivity } from '../../../../../constants/typeActivity';
import UserAvatar from '../../../../common/userAvatar/userAvatar';
import PerformancePlan from '../../../../../models/performancePlan';
import BusinessChallenge from '../../../../../models/businessChallenge';
import PerformanceInitiative from '../../../../../models/performanceInitiative';
import Utils from '../../../../../utils/utils';
import UserListModal from '../userListModal/UserListModal';
import User from '../../../../../models/user';

export interface DataSource {
  data: PerformancePlan | BusinessChallenge | PerformanceInitiative | undefined;
  activityType: TypeActivity;
  children?: DataSource[];
  loadData?: Function;
}

interface IProps {
  nodeData: DataSource;
}

interface IStates {
  showContent: boolean;
  showModal: boolean;
}

class TeamViewNode extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      showContent: false,
      showModal: false,
    };
  }

  private showContent = () => {
    this.setState({ showContent: !this.state.showContent });
    if (this.props.nodeData.data && this.props.nodeData.loadData) {
      this.props.nodeData.loadData(this.props.nodeData.data.id);
    }
  };

  private showUsersModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  private generateUsersFromList = (userList: User[], cssKey: string) : JSX.Element[] => {
    const users: JSX.Element[] = [];
    userList.forEach(usr =>
      users.push((
                    <div className="avatar-with-badge" key={`${cssKey}-${usr.id}`}>
                      <UserAvatar account={usr} reducedVersion={true}/>
                      <span className={`badge ${cssKey}`}/>
                    </div>
      )));
    return users;
  }

  private generateDivider = (key: string, roleName: roleActivity, activityType?: TypeActivity) : JSX.Element => {
    let message : null | JSX.Element = <></>;
    if (activityType) {
      if (Utils.isActivityPi(activityType) && roleName === roleActivity.LEADER) {
        message = <FormattedMessage id="tw.piLeader" defaultMessage="PI LEADER"/>;
      } else {
        message = <FormattedMessage id="tw.bcLeader" defaultMessage="BC LEADER"/>;
      }
    } else {
      switch (roleName) {
        case roleActivity.REFERENT:
          message = <FormattedMessage id="tw.referent" defaultMessage="Referent"/>;
          break;

        case roleActivity.DEPUTY:
          message = <FormattedMessage id="tw.deputy" defaultMessage="Deputy" />;
          break;

        case roleActivity.SHARED:
          message = <FormattedMessage id="tw.sharedWith" defaultMessage="Shared With" />;
          break;
      }
    }
    return (<Divider key={key} horizontal={true}>{message}</Divider>);
  }

  public render() {
    const nodeData = this.props.nodeData as DataSource;
    const content: JSX.Element[] = [];
    let dividersCount: number = 0;
    let title: string = '';
    let nodeClass = '';

    switch (nodeData.activityType) {
      case TypeActivity.PERFORMANCE_PLAN:
        const pp = nodeData.data as PerformancePlan;
        title = pp.name;

        if (pp.owner) {
          content.push(this.generateDivider(`referent-pp-${pp.id}`, roleActivity.REFERENT));
          dividersCount += 1;
          content.push(
            <div className="avatar-with-badge" key={`referent-pp-${pp.owner.id}`}>
              <UserAvatar account={pp.owner} reducedVersion={true}/>
              <span className="badge referent-or-leader" />
            </div>);
        }

        if (pp.assignedAccounts && pp.assignedAccounts.length > 0) {
          content.push(this.generateDivider(`assigned-pp-${pp.id}`, roleActivity.DEPUTY));
          dividersCount += 1;
          content.push.apply(content, this.generateUsersFromList(pp.assignedAccounts, 'deputy'));
        }

        if (pp.informedAccounts && pp.informedAccounts.length > 0) {
          content.push(this.generateDivider(`shared-pp-${pp.id}`, roleActivity.SHARED));
          dividersCount += 1;
          content.push.apply(content, this.generateUsersFromList(pp.informedAccounts, 'shared'));
        }

        nodeClass = 'pp-node';
        break;

      case TypeActivity.PERFORMANCE_INITIATIVE:
      case TypeActivity.BUSINESS_CHALLENGE:
        const prefix = Utils.isActivityPi(nodeData.activityType) ? 'PI' : 'BC';
        const bcOrPi = nodeData.data as BusinessChallenge | PerformanceInitiative;

        title = `${prefix}${Utils.leadingZero(bcOrPi.code)}: ${bcOrPi.name}`;
        if (bcOrPi.assignedAccounts && bcOrPi.assignedAccounts.length > 0) {
          content.push(this.generateDivider(`assigned-${prefix + bcOrPi.id}`,
                                            roleActivity.LEADER, nodeData.activityType));
          dividersCount += 1;
          content.push.apply(content, this.generateUsersFromList(bcOrPi.assignedAccounts, `${prefix}-leader`));
        }

        if (bcOrPi.deputies && bcOrPi.deputies.length > 0) {
          content.push(this.generateDivider(`deputy-${prefix + bcOrPi.id}`, roleActivity.DEPUTY));
          dividersCount += 1;
          content.push.apply(content, this.generateUsersFromList(bcOrPi.deputies, 'deputy'));
        }

        if (bcOrPi.informedAccounts && bcOrPi.informedAccounts.length > 0) {
          content.push(this.generateDivider(`shared-${prefix + bcOrPi.id}`, roleActivity.SHARED));
          dividersCount += 1;
          content.push.apply(content, this.generateUsersFromList(bcOrPi.informedAccounts, 'shared'));
        }

        nodeClass = Utils.isActivityPi(nodeData.activityType) ? 'pi-node' : 'bc-node';
        break;
    }

    const iconStyle = this.state.showContent ? 'angle up' : 'angle down';
    let activeContentStyle: string;
    if (content.length <= 4) {
      activeContentStyle = 'sm';
    } else if (content.length <= 8) {
      activeContentStyle = 'md';
    } else if (content.length <= 12) {
      activeContentStyle = 'lg';
    } else {
      activeContentStyle = 'xl';
    }

    const contentToShow = content.slice(0, (16 + dividersCount));

    return (
      <>
        <Accordion
            styled={true}
            className={`team-view-node ${nodeClass} ${this.state.showContent ? activeContentStyle : 'xs'}`}
        >
          <Accordion.Title
            active={true}
            onClick={this.showContent}
            className="team-view-node-title"
          >
            <span>{title}</span><Icon name={iconStyle} className="icon-title" />
          </Accordion.Title>
          <Accordion.Content
            active={this.state.showContent}
            className={this.state.showContent ? activeContentStyle : ''}
          >
            <div className="members">
              {content.length > 0
                ? contentToShow
                : <span className="no-members">
                    <FormattedMessage id="tw.noAssignations" defaultMessage="No Assignation Yet"/>
                  </span>
              }
            </div>
            {(content.length - dividersCount) > 16
            && <span id="see-more-users" onClick={this.showUsersModal}>
                  + {(content.length - dividersCount) - 16}&nbsp;
              {
                (content.length - dividersCount) - 16 === 1
                  ? <FormattedMessage id="moreUser" defaultMessage="more user" />
                  : <FormattedMessage id="moreUsers" defaultMessage="more users" />
              }
                </span>
            }
          </Accordion.Content>
        </Accordion>
        <UserListModal
          content={content}
          showModal={this.state.showModal}
          showUsersModal={this.showUsersModal}
          title={title}
        />
      </>
    );
  }
}
export default TeamViewNode;
