import * as React from 'react';
import PerformancePlanAPI from '../../../../api/performancePlan.api';
import { Label } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import LinkedActivityRow from './linkedActivityRow/linkedActivityRow';
import { BcLinked } from '../../../../models/businessChallenge';
import Utils from '../../../../utils/utils';

interface IProps {
  ppId: number;
}

interface IStates {
  linkedActivities: BcLinked[];
  isLoading: boolean;
}

class LinkedActivities extends React.Component<IProps, IStates> {

  constructor(props) {
    super(props);
    this.state = {
      linkedActivities: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    PerformancePlanAPI.getPpsLinkedActivities(this.props.ppId).then((res) => {
      this.setState({ linkedActivities: res.bcs, isLoading: false });
    });
  }

  render() {
    return (
      <div className="activities-content">
        <div className="grey-title">
          <FormattedMessage
            id="linkedActivities.planActivities"
            defaultMessage="Activities linked to other Performance Plans"
          />
        </div>
        {this.state.isLoading
        ? Utils.loader()
        : this.state.linkedActivities.length === 0
          ?
            <FormattedMessage
              id="linkedActivities.noLink"
              defaultMessage="There is no activity currently linked to another Performance Plan"
            />
          :
            <Label.Group circular={true} id="activities-labels">
              {this.state.linkedActivities.map((linkedActivity, index) =>
                <LinkedActivityRow
                  key={index}
                  activity={linkedActivity}
                  isLastActivity={index === (this.state.linkedActivities.length - 1)}
                />)}
            </Label.Group>
        }
      </div>
    );
  }
}
export default LinkedActivities;
