import React  from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, Label } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import Utils from '../../../utils/utils';

interface IProps {
  pp: any;
  onUpdate: any;
  buildOptions: any;

  setNavigationDisabled(isNavigationDisabled: boolean): void;
}

interface IStates {
  isYearIncorrect: boolean;
  yearLabelMessage: string | JSX.Element;
}

class PpCreationStep2 extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      isYearIncorrect: false,
      yearLabelMessage: '',
    };
  }

  public componentWillMount() {
    this.props.setNavigationDisabled(!Utils.testYear(this.props.pp.year));
  }

  /**
   * Send the modified value to the parent component
   * @param e : any
   * @param value : any
   */
  private handleChange = (e: any, { value }: any) => {
    const isYearIncorrect = !Utils.testYear(value);
    this.setState({
      isYearIncorrect,
      yearLabelMessage:
        isYearIncorrect
          ? (
            <FormattedMessage
              id="pp.creation.notAYear"
              defaultMessage="Please enter a valid year"
            >
              {msg => (<Label pointing={true}>{msg}</Label>)}
            </FormattedMessage>
          )
          : '',
    });

    this.props.setNavigationDisabled(isYearIncorrect);
    this.props.onUpdate(e.target.id, value);
  };

  public render(): React.ReactNode {
    return (
      <Form id="pp-creation-step2">
        <Form.Group widths="equal">
          <Form.Field id="mainAchivements">
            <FormattedMessage
              id="year"
              defaultMessage="Year"
            >
              {msg => (
                <Form.Input
                  id="year"
                  placeholder="Year"
                  pattern="[0-9]*"
                  label={`${msg} *`}
                  value={this.props.pp.year}
                  onChange={(event: any, { value }) => event.target.validity.valid
                    ? this.handleChange(event, { value })
                    : null}
                  error={this.state.isYearIncorrect}
                  autoFocus={true}
                />
              )}
            </FormattedMessage>
            {this.state.yearLabelMessage}
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

export default PpCreationStep2;
