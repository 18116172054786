import './creation.scss';
import 'react-datepicker/dist/react-datepicker.css';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, Modal, Step } from 'semantic-ui-react';

interface IProps {
  title: string;
  steps: ({ title: string, subtitle: string, icon?: string }[]);
  submit: () => Promise<any>;
  open: boolean;
  close: () => void;
  parent?: string;
  isSubmitDisabled?: boolean;
  isNavigationDisabled?: boolean;
}

interface IStates {
  step: any;
  isSubmitDisabled: boolean;
  isNavigationDisabled: boolean;
  isSubmitLoading: boolean;
}

/**
 * Creates a new creation form
 * @param {string} title
 * @param {{ title: string, subtitle: string, icon?: string }[]} steps
 */
export default class Creation extends React.Component<IProps, IStates> {

  private initialState = {
    step: 1,
    isSubmitDisabled: false,
    isNavigationDisabled: false,
    isSubmitLoading: false,
  };

  public constructor(props: IProps) {
    super(props);
    this.state = this.initialState;
  }

  public componentWillReceiveProps(nProps: IProps) {
    this.setState({
      isSubmitDisabled: typeof nProps.isSubmitDisabled !== 'undefined' ? nProps.isSubmitDisabled : false,
      isNavigationDisabled: typeof nProps.isNavigationDisabled !== 'undefined' ? nProps.isNavigationDisabled : false,
    });
  }

  /**
   * Actions triggered on the Previous button click
   */
  public onPreviousClick = () => {
    if (this.state.step > 1) {
      this.setState({
        step: this.state.step - 1,
        isNavigationDisabled: false,
      });
    }
  };

  /**
   * Actions triggered on the Next button click
   */
  public onNextClick = () => {
    if (this.state.step < this.props.steps.length) {
      this.setState({ step: this.state.step + 1 });
    }
  };

  /**
   * Close the modal and reset the state
   */
  public handleClose = () => {
    this.props.close();
    this.clear();
  };

  /**
   * Submit the form
   */
  public submit = () => {
    this.setState(
      { isSubmitLoading: true },
      () => {
        this.props.submit()
          .then(() => {
            this.clear();
            this.setState({ isSubmitLoading: false });
          })
          .catch(() => this.setState({ isSubmitLoading: false }));
      },
    );
  };

  /**
   * Reset the component state
   */
  public clear = () => {
    this.setState({ ...this.initialState });
  };

  /**
   * Render the menu steps sent by the parent component
   * @returns JSX.Element[]
   */
  public renderSteps() {
    const steps: JSX.Element[] = [];
    this.props.steps.forEach((step, i) => {
      steps.push(
        <Step key={i} active={this.state.step === i + 1}>
          {this.state.step.icon ? <Icon name={this.state.step.icon} /> : null}
          <Step.Content>
            <Step.Title>{step.title}</Step.Title>
            <Step.Description>{step.subtitle}</Step.Description>
          </Step.Content>
        </Step>,
      );
    });
    return (
      <Step.Group id="progress">
        {steps}
      </Step.Group>
    );
  }

  public render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        className="creation-modal-container"
      >
        <Modal.Content id="modal-content">
          <Modal.Header>
            <h1>{this.props.title}<span className={'parent-name'}>{this.props.parent}</span></h1>
          </Modal.Header>
          {this.renderSteps()}
          {this.props.children && this.props.children instanceof Array
            ? this.props.children[this.state.step - 1]
            : this.props.children
          }
        </Modal.Content>
        <Modal.Actions>
          <Button id="button-left" onClick={this.handleClose}>
            <FormattedMessage
              id="cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            color="grey"
            disabled={this.state.step === 1}
            onClick={this.onPreviousClick}
          >
            <Icon name="chevron left" />
            <FormattedMessage
              id="previous"
              defaultMessage="Previous"
            />
          </Button>
          {this.state.step !== this.props.steps.length
            ? <Button
              id={'next'}
              primary={true}
              onClick={this.onNextClick}
              disabled={this.state.isNavigationDisabled}
            >
              <FormattedMessage
                id="next"
                defaultMessage="Next"
              /> <Icon name="chevron right" />
            </Button>
            : <Button
              id={'submit'}
              primary={true}
              loading={this.state.isSubmitLoading}
              onClick={this.submit}
              disabled={this.state.isNavigationDisabled || this.state.isSubmitDisabled || this.state.isSubmitLoading}
            >
              <FormattedMessage
                id="submit"
                defaultMessage="Submit"
              /> <Icon name="checkmark" />
            </Button>
          }
        </Modal.Actions>
      </Modal>
    );
  }
}
