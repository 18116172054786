/* eslint-disable */
import './profilePic.scss';

import * as React from 'react';
import { Button, Loader } from 'semantic-ui-react';
import Img from 'react-image';

import AuthActions from '../../../../actions/auth.actions';
import ProfileService from 'src/services/profile.service';
import defaultUserAvatar from 'src/images/default-user.png';

class ProfilePic extends React.Component<any, any> {
  private readonly inputOpenFileRef: any;

  public constructor(props: any) {
    super(props);

    this.inputOpenFileRef = React.createRef();
    this.state = {
      imageSrc: null,
    };
  }

  public componentDidMount() {
    this.refreshAvatar();
  }

  /**
   * Open file selection window
   */
  public uploadFile = () => {
    this.inputOpenFileRef.current.click();
  };

  /**
   * Refresh the user's profile pic
   */
  public refreshAvatar() {
    ProfileService.getProfilePic().then((blob: any) => {
      this.setState({ imageSrc: URL.createObjectURL(blob) });
    });
  }

  /**
   * Change the profile pic
   * @param {any} e
   */
  public handleChange(e: any) {
    const formData = new FormData();
    this.compressImage(e).then((img: any) => {
      formData.append('avatar', img);
      ProfileService.updateProfilePic(formData).then(() => {
        this.refreshAvatar();
        AuthActions.updateProfile();
      });
    });
  }

  private compressImage(e: any) {
    return new Promise((resolve, reject) => {
      const width = 100;
      const height = 100;
      const fileName = e.target.files[0].name;
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      reader.onload = (event) => {
        const img : any = new Image();
        img.src = event.target!['result'];
        img.onload = () => {
          const elem = document.createElement('canvas');
          elem.width = width;
          elem.height = height;
          const ctx = elem.getContext('2d');
          if (!ctx) { return; }
          ctx.drawImage(img, 0, 0, width, height);
          ctx.canvas.toBlob(
            (blob) => {
              if (!blob) { return; }
              const file = new File(
                [blob],
                fileName,
                {
                  type: 'image/jpeg',
                  lastModified: Date.now(),
                });
              resolve(file);
            },
            'image/jpeg', 1);
        },
          reader.onerror = reject;
      };
    });
  }

  public render() {
    return (
      <div id="profile-pic-container">
        <div id="profile-pic">
          <Img
            id="avatar"
            src={[this.state.imageSrc, defaultUserAvatar]}
            loader={<Loader active={true} size="large" />}
          />
          <input
            accept=".gif, .jpg, .tiff, .jpeg, .png"
            id="file-uploader"
            type="file"
            ref={this.inputOpenFileRef}
            onChange={e => this.handleChange(e)}
          />
          <label htmlFor="file">
            <Button circular={true} icon="edit" className="edit-profile-pic" onClick={this.uploadFile} />
          </label>
        </div>
      </div>
    );
  }
}

export default ProfilePic;
