import { EventEmitter } from 'events';

import ActionTypes from 'src/constants/actionTypes';
import PerformancePlan from 'src/models/performancePlan';
import ReportingGet, { ReportingGetFormatted } from 'src/models/reporting';
import Action from '../actions/action';
import Dispatcher from '../dispatcher';
import IPpBusinessLocations from '../models/performancePlanBusinessLocations';

class PerformancePlanStore extends EventEmitter {

  private businessLocations: IPpBusinessLocations;
  private years: string[];
  private performancePlanReportings: ReportingGetFormatted[];

  constructor() {
    super();
    Dispatcher.register(this.registerToActions.bind(this));
  }

  /**
   * Get Options
   * @return IPpBusinessLocations
   */
  public getBusinessLocations(): IPpBusinessLocations {
    return this.businessLocations;
  }

  /**
   * Get years
   * @return string[]
   */
  public getYears(): string[] {
    return this.years;
  }

  /**
   * Get Performance Plan Reportings
   * @return ReportingGet[]
   */
  public getPerformancePlanReportings(): ReportingGetFormatted[] {
    return this.performancePlanReportings;
  }

  /**
   *  Delete Performance Plan
   * @return void
   */
  private deletePerformancePlan(): void {
    this.emit(ActionTypes.PERFORMANCE_PLAN_DELETED.toString());
  }

  /**
   * Set PP Entities/Locations
   * @param entitiesLocations: PerformancePlanOptions
   * @return void
   */
  private setEntitiesLocations(entitiesLocations: IPpBusinessLocations): void {
    this.businessLocations = entitiesLocations;
    this.emit(ActionTypes.PERFORMANCE_PLAN_ENTITIES_LOCATIONS_GET.toString());
  }

  /**
   * Set PP available years
   * @param years: string[]
   * @return void
   */
  private setYears(years: string[]): void {
    this.years = years;
    this.emit(ActionTypes.PERFORMANCE_PLAN_YEARS_GET.toString());
  }

  /**
   *  Set Performance Plan Reporting
   * @param ppReportings: PerformancePlanReporting[]
   * @return void
   */
  private setPerformancePlanReportings(ppReportings: ReportingGetFormatted[]): void {
    this.performancePlanReportings = ppReportings;
    this.emit(ActionTypes.PERFORMANCE_PLAN_REPORTINGS_GET.toString());
  }

  /**
   *  Set new Performance Plan Reporting
   * @param report: PerformancePlanReporting
   * @return void
   */
  private setNewPerformancePlanReporting(report: ReportingGet): void {
    this.emit(ActionTypes.PERFORMANCE_PLAN_NEW_REPORTING.toString());
  }

  /**
   *  Create new Performance Plan
   * @return void
   * @param performancePlan
   */
  private savePerformancePlan(performancePlan: PerformancePlan): void {
    this.emit(ActionTypes.PERFORMANCE_PLAN_SAVED.toString());
  }

  /**
   *  Create new Performance Plan
   * @return void
   */
  private emitGetPerformancePlanReportings(): void {
    this.emit(ActionTypes.PERFORMANCE_PLAN_NEW_REPORTING.toString());
  }

  public bcLinkedToPp(): void {
    this.emit(ActionTypes.BC_LINKED_TO_PP.toString());
  }

  /**
   * Performs a function according to an action
   * @param action :Action
   * @return void
   */
  private registerToActions(action: Action) {
    switch (action.actionType) {
      case ActionTypes.PERFORMANCE_PLAN_ENTITIES_LOCATIONS_GET:
        this.setEntitiesLocations(action.options);
        break;
      case ActionTypes.PERFORMANCE_PLAN_YEARS_GET:
        this.setYears(action.years);
        break;
      case ActionTypes.PERFORMANCE_PLAN_DELETED:
        this.deletePerformancePlan();
        break;
      case ActionTypes.PERFORMANCE_PLAN_SAVED:
        this.savePerformancePlan(action.performancePlan);
        break;
      case ActionTypes.PERFORMANCE_PLAN_REPORTINGS_GET:
        this.setPerformancePlanReportings(action.performancePlanReportings);
        break;
      case ActionTypes.PERFORMANCE_PLAN_NEW_REPORTING:
        this.setNewPerformancePlanReporting(action.report);
        break;
      case ActionTypes.PERFORMANCE_PLAN_REPORTING_EDIT:
        this.emitGetPerformancePlanReportings();
        break;
      case ActionTypes.PERFORMANCE_PLAN_NEW_BC_REPORTING:
        this.emitGetPerformancePlanReportings();
        break;
      case ActionTypes.VALIDATE_DRAFT:
        this.emit(ActionTypes.VALIDATE_DRAFT.toString());
        break;
      case ActionTypes.ACTIVITY_PUBLISHED:
        this.emit(ActionTypes.ACTIVITY_PUBLISHED.toString());
        break;
      case ActionTypes.MOST_RECENT_PP_HISTORY_DELETED:
        this.emit(ActionTypes.MOST_RECENT_PP_HISTORY_DELETED.toString());
        break;
    }
  }
}

export default new PerformancePlanStore();
