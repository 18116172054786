import './notificationTile.scss';

import React from 'react';
import Img from 'react-image';
import { Button, Icon, Loader, Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';

import Utils from '../../../utils/utils';
import defaultUserAvatar from '../../../images/default-user.png';
import UserActions from '../../../actions/user.action';
import Notification, { NotificationType } from 'src/models/notification';
import ActivitiesStore from 'src/stores/activities.store';
import TypeActivity from 'src/constants/typeActivity';
import PerformancePlan from 'src/models/performancePlan';
import BusinessChallenge from 'src/models/businessChallenge';
import PerformanceInitiative from 'src/models/performanceInitiative';
import NotificationActions from 'src/actions/notification-actions';
import { ToastType } from 'src/components/common/toast/toast';
import ActivitiesActions from 'src/actions/activities.action';
import BusinessChallengeAPI from 'src/api/businessChallenge.api';
import PerformanceInitiativeAPI from 'src/api/performanceInitiative.api';
import PerformanceInitiativeError from 'src/constants/errors/performance-initiative.error';
import BusinessChallengeError from 'src/constants/errors/business-challenge.error';
import performancePlanStore from 'src/stores/performancePlan.store';
import businessChallengeStore from 'src/stores/businessChallenge.store';

export interface ActivityNotif {
  name: string;
  type: TypeActivity | undefined;
  id: number;
  performancePlan?: PerformancePlan;
  businessChallenge?: BusinessChallenge;
}

enum NotificationClass {
  INFO = 'info',
  ACTION = 'action',
}

interface IProps extends RouteComponentProps {
  notification: Notification;
  markAsRead: (ids: number[]) => void;
  isInTopBar: boolean;
  onClick?: () => void;
  publicationRequestActions?: (notification: Notification, validate: boolean) => void;
  setNotificationListVisibility?: (isVisible: boolean) => void;
}

interface IStates {
  image: string;
}

interface NavigationOptions {
  actionId: number;
}

class NotificationTile extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);

    this.state = {
      image: '',
    };
  }

  public componentDidMount() {
    if (!!this.props.notification.sender && this.props.notification.sender.hasAvatar) {
      UserActions.getUserAvatar(
        this.props.notification.sender.id,
        image => this.setState({ image }),
      );
    }
  }

  private static getStatusFeedback(status: number): JSX.Element {
    switch (status) {
      default:
      case 0:
        return <FormattedMessage id="feedback.todo" defaultMessage="Todo" />;
      case 1:
        return <FormattedMessage id="feedback.inProgress" defaultMessage="In progress" />;
      case 2:
        return <FormattedMessage id="feedback.done" defaultMessage="Done" />;
    }
  }

  private navigateTo(activityId: number, type: TypeActivity, isAction = false, options?: NavigationOptions) {
    let pp;
    switch (type) {
      case TypeActivity.PERFORMANCE_PLAN:
        this.props.history.push(`/activities-board/performance-plan/${activityId}/cockpit`);
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        pp = ActivitiesStore.getActivities().find((pp: PerformancePlan) => {
          return pp.businessChallenges.some((bc: BusinessChallenge) => {
            return bc.id === activityId;
          });
        });
        if (pp) {
          this.props.history.push(`/activities-board/${pp.id}/business-challenge/${activityId}/cockpit`);
        }
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        pp = ActivitiesStore.getActivities().find((pp: PerformancePlan) => {
          const bc = pp.businessChallenges.find((bc: BusinessChallenge) => {
            return bc.performanceInitiatives.some((pi: PerformanceInitiative) => {
              return pi.id === activityId;
            });
          });
          return !!bc;
        });
        if (pp) {
          if (isAction) {
            this.props.history.push(`/activities-board/${pp.id}/performance-initiative/${activityId}/actions-list${`/${options?.actionId}`}`);
          } else {
            this.props.history.push(`/activities-board/${pp.id}/performance-initiative/${activityId}/cockpit`);
          }
        }
        break;
      default:
        break;
    }

    if (!!this.props.setNotificationListVisibility) this.props.setNotificationListVisibility(false);
  }

  private buildNotification(activity: ActivityNotif, onClick: () => void): JSX.Element | null {

    const notif = this.props.notification;
    const key = `notification${notif.id}`;

    let leaderComment: JSX.Element | null = null;
    if (!!notif.comment) {
      leaderComment = (
        <p>
          <FormattedMessage id="comment" defaultMessage="Comment:" />
          &nbsp;
          <span>"{notif.comment}"</span>
        </p>
      );
    }

    let isSimpleNotification = false;
    let isSimpleNotificationWithComment = false;
    let bcLinkedNotification = false;
    let isWithoutSender = false;
    let message: JSX.Element | null = null;

    switch (notif.type) {
      case NotificationType.PP_EDIT:
        isSimpleNotification = true;
        message = <FormattedMessage id="notifications.madeChangesOn" defaultMessage="made changes on" />;
        break;

      case NotificationType.BC_EDIT:
        isSimpleNotification = true;
        message = <FormattedMessage id="notifications.madeChangesOn" defaultMessage="made changes on" />;
        break;

      case NotificationType.PI_EDIT:
        isSimpleNotification = true;
        message = <FormattedMessage id="notifications.madeChangesOn" defaultMessage="made changes on" />;
        break;

      case NotificationType.BC_LINK:
      case NotificationType.PI_LINK:
        bcLinkedNotification = true;
        const bc = this.props.notification.businessChallenge;
        const pi = this.props.notification.performanceInitiative;

        bc?.isPending || pi?.isPending ?
          message = (
            <FormattedMessage
              id="notifications.linkRequestNeed"
              defaultMessage="needs your validation to link"
            />
          ) :   message = (
            <FormattedMessage
              id="notifications.linkRequestNeeded"
              defaultMessage="needed your validation to link"
            />
          );
        break;

      case NotificationType.BC_LINK_GRANTED:
      case NotificationType.PI_LINK_GRANTED:
        bcLinkedNotification = true;
        message = (
          <FormattedMessage
            id="notifications.linkRequestGranted"
            defaultMessage="has granted the request link of"
          />
        );
        break;

      case NotificationType.BC_LINK_REJECTED:
      case NotificationType.PI_LINK_REJECTED:
        bcLinkedNotification = true;
        message = (
          <FormattedMessage
            id="notifications.linkRequestRejected"
            defaultMessage="has rejected the request link of"
          />
        );
        break;

      case NotificationType.BC_PUBLISH_GRANTED:
      case NotificationType.PI_PUBLISH_GRANTED:
        isSimpleNotificationWithComment = true;
        if (!notif.sender) {
          isWithoutSender = true;
          message = <FormattedMessage id="notifications.automaticallyPublished" defaultMessage="The publication has been automatically published for" />;
        } else {
          message = (
            <FormattedMessage
              id="notifications.hasPublished"
              defaultMessage="has published"
            />
          );
        }
        break;

      case NotificationType.FEEDBACK_STATUS_UPDATED:
        if (!!notif.feedbackStatus) {
          return (
            <p key={key}>
              <FormattedMessage
                id="notifications.feedbackStatusUpdated"
                defaultMessage="Your feedback status has been updated to:"
              />
              &nbsp;
              <b>{NotificationTile.getStatusFeedback(notif.feedbackStatus)}</b>
            </p>
          );
        }
        break;

      case NotificationType.BC_PUBLISH_REJECTED:
      case NotificationType.PI_PUBLISH_REJECTED:
        isSimpleNotificationWithComment = true;
        message = (
          <FormattedMessage
            id="notifications.publishRejected"
            defaultMessage="rejected your publication on"
          />
        );
        break;

      case NotificationType.REPORTING_BC_COMMENT:
        isSimpleNotification = true;
        message = (
          <FormattedMessage
            id="notifications.reportingBcComment"
            defaultMessage="has commented your business challenge"
          />
        );
        break;

      case NotificationType.PP_ASSIGNMENT:
      case NotificationType.BC_ASSIGNMENT:
      case NotificationType.PI_ASSIGNMENT:
        isSimpleNotification = true;
        message = <FormattedMessage id="notifications.assignment" defaultMessage="have assigned you to" />;
        break;

      case NotificationType.ACTION_ASSIGNMENT:
        isSimpleNotification = true;
        message = <FormattedMessage id="notifications.assignment" defaultMessage="have assigned you to" />;
        break;

      case NotificationType.AT_STATUS_CHANGED:
        isWithoutSender = true;
        message = <FormattedMessage id="notifications.actionStatusChanged" defaultMessage="This action's status has been automatically changed" />;
        break;

      case NotificationType.BC_PUBLISH_FORCED:
        isSimpleNotificationWithComment = true;
        message = (
          <FormattedMessage
            id="notifications.bcForcedPublished"
            defaultMessage="has published automatically this BC by publishing the Performance Plan:"
          />
        );
        break;

      case NotificationType.PI_PUBLISH_FORCED:
        isSimpleNotificationWithComment = true;
        message = (
          <FormattedMessage
            id="notifications.piForcedPublished"
            defaultMessage="has published automatically this PI by publishing the Performance Plan:"
          />
        );
        break;

      case NotificationType.PP_DRAFT_REMINDER:
        isWithoutSender = true;
        message = (
          <FormattedMessage
            id="notifications.planDraft"
            defaultMessage="This Performance Plan has been a draft for more than two weeks:"
          />
        );
        break;

      case NotificationType.PI_CLOSURE:
      case NotificationType.BC_CLOSURE:
        isSimpleNotification = true;
        message = notif.isActivityClosed
          ? <FormattedMessage id="notifications.close" defaultMessage="has closed" />
          : <FormattedMessage id="notifications.unclose" defaultMessage="has unclosed" />;
        break;
    }

    if (isWithoutSender) {
      return (
        <p key={key}>
          {message}
          <span className="activity-name" onClick={onClick}>{activity.name}</span>
        </p>
      );
    }

    const sender = <b>{`${notif.sender?.firstName} ${notif.sender?.lastName}`}&nbsp;</b>;

    if (isSimpleNotification) {
      return (
        <p key={key}>
          {sender}
          {message}
          <span className="activity-name" onClick={onClick}>{activity.name} </span>
        </p>
      );
    }

    if (isSimpleNotificationWithComment) {
      return (
        <div key={key}>
          <p>
            {sender}
            {message}
            <span className="activity-name" onClick={onClick}>{activity.name}</span>
          </p>
          {leaderComment}
        </div>
      );
    }

    if (bcLinkedNotification) {
      let endLinkString;
      let onPpClick;

      if (!!activity.performancePlan) {
        endLinkString = activity.performancePlan.name;
        onPpClick = () => !!activity.performancePlan ?
          this.navigateTo(activity.performancePlan.id, TypeActivity.PERFORMANCE_PLAN) : null;
      }

      if (activity.businessChallenge) {
        endLinkString = activity.businessChallenge.name;
        onPpClick = () => !!activity.businessChallenge ?
          this.navigateTo(activity.businessChallenge.id, TypeActivity.BUSINESS_CHALLENGE) : null;
      }

      return (
        <p key={key}>
          {sender}
          {message}
          <span className="activity-name" onClick={onClick}>{activity.name} </span>
          <FormattedMessage id="to" defaultMessage="to" />
          <span className="activity-name" onClick={onPpClick}>{endLinkString}</span>
        </p>
      );
    }

    return null;
  }

  private rejectLink = (notification: Notification) => {
    let promise: Promise<void> | undefined;
    let message;

    if (notification.type === NotificationType.BC_LINK
      && !!notification.performancePlan && !!notification.businessChallenge) {
      promise = BusinessChallengeAPI
        .rejectLinkBcToPp(notification.businessChallenge.id, notification.performancePlan.id);
      message = (
        <FormattedMessage
          id="link.rejected.bcLinkedToPp"
          defaultMessage="This Business Challenge link has been rejected"
        />
      );
    } else if (notification.type === NotificationType.PI_LINK
      && !!notification.businessChallenge && !!notification.performanceInitiative) {
      promise = PerformanceInitiativeAPI
        .rejectLinkPiToBc(notification.performanceInitiative.id, notification.businessChallenge.id);
      message = (
        <FormattedMessage
          id="link.rejected.piLinkedToBc"
          defaultMessage="This Performance Initiative link has been rejected"
        />
      );
    } else {
      promise = Promise.reject();
    }

    if (promise !== undefined) {
      promise
        .then(async () => {
          NotificationActions.toast(
            <FormattedMessage id="link.rejected.activityLinked" defaultMessage="Activity link rejected !" />,
            message,
            ToastType.SUCCESS,
          );
          await NotificationActions.getNotifications();
        })
        .catch((err) => {
          if (notification.type === NotificationType.BC_LINK) {
            BusinessChallengeError.toastBcError(err?.error);
          } else {
            PerformanceInitiativeError.toastPiError(err?.error);
          }
        });
    }
  }

  private validateLink = (notification: Notification) => {
    let promise: Promise<void> | undefined;
    let message;

    if (notification.type === NotificationType.BC_LINK
      && !!notification.performancePlan && !!notification.businessChallenge) {
      promise = BusinessChallengeAPI.linkBcToPp(notification.businessChallenge.id, notification.performancePlan.id);
      message = (
        <FormattedMessage
          id="link.bcLinkedToPp"
          defaultMessage="This Business Challenge has been successfully linked to the Plan"
        />
      );
    } else if (notification.type === NotificationType.PI_LINK
      && !!notification.businessChallenge && !!notification.performanceInitiative) {
      promise = PerformanceInitiativeAPI
        .linkPiToBc(notification.performanceInitiative.id, notification.businessChallenge.id);
      message = (
        <FormattedMessage
          id="link.piLinkedToBc"
          defaultMessage="This Performance Initiative has been successfully linked to the BC"
        />
      );
    } else {
      promise = Promise.reject();
    }

    if (promise !== undefined) {
      promise
        .then(async () => {
          NotificationActions.toast(
            <FormattedMessage id="link.activityLinked" defaultMessage="Activity linked!" />,
            message,
            ToastType.SUCCESS,
          );
          await ActivitiesActions.emitGetActivities();
          await NotificationActions.getNotifications();
          if (notification.type === NotificationType.BC_LINK) {
            performancePlanStore.bcLinkedToPp();
          } else {
            businessChallengeStore.piLinkedToBc();
          }
        })
        .catch((err) => {
          if (notification.type === NotificationType.BC_LINK) {
            BusinessChallengeError.toastBcError(err?.error);
          } else {
            PerformanceInitiativeError.toastPiError(err?.error);
          }
        });
    }
  }

  public render() {
    let onClick: () => void = () => { };
    let isAwaitingValidation = false;
    let activity: ActivityNotif = { id: 0, name: '', type: undefined };

    const pp = this.props.notification.performancePlan;
    const bc = this.props.notification.businessChallenge;
    const pi = this.props.notification.performanceInitiative;

    if (!!pp) {
      onClick = () => this.navigateTo(pp.id, TypeActivity.PERFORMANCE_PLAN);
      activity = { id: pp.id, name: pp.name, type: TypeActivity.PERFORMANCE_PLAN };
    }

    if (!!bc) {
      onClick = () => this.navigateTo(bc.id, TypeActivity.BUSINESS_CHALLENGE);
      isAwaitingValidation = !!bc.isPending;
      activity = {
        id: bc.id,
        name: `BC${Utils.leadingZero(bc.code)} - ${bc.name}`,
        type: TypeActivity.BUSINESS_CHALLENGE,
        performancePlan: pp,
      };
    }

    if (!!pi) {
      onClick = () => this.navigateTo(pi.id, TypeActivity.PERFORMANCE_INITIATIVE);
      isAwaitingValidation = pi.isPending;
      activity = {
        id: pi.id,
        name: `PI${Utils.leadingZero(pi.code)} - ${pi.name}`,
        type: TypeActivity.PERFORMANCE_INITIATIVE,
        performancePlan: pp,
        businessChallenge: bc,
      };
    }

    const at = this.props.notification.action;
    if (!!at) {
      onClick = () => this.navigateTo(at.performanceInitiative!.id, TypeActivity.PERFORMANCE_INITIATIVE,
                                      true, { actionId: at.id });
      activity = {
        id: at.id,
        name: `Action: ${at.name}`,
        type: TypeActivity.ACTION,
        performancePlan: at.performanceInitiative!.businessChallenge.performancePlan,
      };
    }

    const notificationClass = NotificationClass.INFO;

    return (
      <div
        className={`notification-tile
          ${notificationClass}
          ${this.props.notification.isSeen ? 'seen' : ''}
        `}
      >
        <div className={`notification-content ${this.props.isInTopBar ? 'in-topbar' : ''}`}>
          <div className="notification-body">
            {!!this.props.notification.sender
            && <Img
              className="notification-avatar"
              src={[this.state.image, defaultUserAvatar]}
              loader={<Loader active={true} size="large" />}
            />
            }
            <div className={!!this.props.notification.sender ? '' : 'notification-without-sender'}>
              {this.buildNotification(activity, onClick)}
              <div className="notification-date">
                {Utils.displayDate(this.props.notification.createdAt, true)}
                {!!activity.performancePlan
                  && <span className="in-pp">
                      , Plan:&nbsp;
                    <span
                      className="pp-name"
                      onClick={() => this.navigateTo(activity.performancePlan!.id, TypeActivity.PERFORMANCE_PLAN)}
                    >
                      {activity.performancePlan.name}
                    </span>
                  </span>
                }
              </div>
            </div>
          </div>
          {this.props.publicationRequestActions
          && (this.props.notification.type === NotificationType.BC_LINK)
          && <div className="notification-actions">
            {isAwaitingValidation
              ? <div>
                <Button
                  basic={true}
                  onClick={() => this.props.publicationRequestActions!(this.props.notification, false)}
                >
                  <FormattedMessage id="reject" defaultMessage="Reject" />
                </Button>
                <Button
                  primary={true}
                  onClick={() => this.props.publicationRequestActions!(this.props.notification, true)}
                >
                  <FormattedMessage id="validate" defaultMessage="Validate" />
                </Button>
              </div>
              : <div className="done-message">
                <FormattedMessage id="done" defaultMessage="Done" />
              </div>
            }
          </div>
          }
          {(this.props.notification.type === NotificationType.BC_LINK
            || this.props.notification.type === NotificationType.PI_LINK) && isAwaitingValidation
            && <div className="notification-actions">
              <Button
                basic={true}
                onClick={() => this.rejectLink(this.props.notification)}
              >
                <FormattedMessage id="reject" defaultMessage="Reject" />
              </Button>
              <Button
                primary={true}
                onClick={() => this.validateLink(this.props.notification)}
              >
                <FormattedMessage id="validate" defaultMessage="Validate" />
              </Button>
            </div>
          }
        </div>
        <div className="icon-container">
          {!this.props.notification.isSeen
            ? <FormattedMessage id="notifications.markAsRead" defaultMessage="Mark as read">
              {msg =>
                <Popup
                  inverted={true}
                  content={msg}
                  size="tiny"
                  position="left center"
                  trigger={
                    <div
                      className="unread"
                      onClick={() => this.props.markAsRead([this.props.notification.id])}
                    >
                      <Icon name="envelope" />
                    </div>
                  }
                />
              }
            </FormattedMessage>
            : <div className="read">
              <Icon name="envelope open" />
            </div>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(NotificationTile);
