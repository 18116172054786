import './dashboard.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Popup, Segment } from 'semantic-ui-react';
import { Line, Pie } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import moment from 'moment';

import StatsStore from 'src/stores/stats.store';
import UserStore from 'src/stores/user.store';
import ActionTypes from 'src/constants/actionTypes';
import UserActions from 'src/actions/user.action';
import Utils from 'src/utils/utils';
import Role from 'src/models/role';
import StatsActions from 'src/actions/stats.action';
import UsersCounts from 'src/models/usersCounts';
import ActivitiesCounts from 'src/models/activitiesCounts';
import ConnectionsCount from 'src/models/connectionsCount';
import CustomScrollBars from '../../../common/customScrollBars/customScrollBars';
import StatsAPI from '../../../../api/stats.api';
import MonthlyReport from '../../../../models/monthlyReport';
import ExportAllBcsPisButton from './exportAllBcsPis/exportAllBcsPis';

enum ReportDirection {
  PREVIOUS,
  NEXT,
}

interface IProps {
}

interface IStates {
  usersCounts: UsersCounts;
  activitiesCounts: ActivitiesCounts;
  connectionsCount: ConnectionsCount[];
  connectionsChartData: any;
  usersChartData: any;
  connectionsSinceLaunching: number;
  selectedReportMonth: Date;
  isMinDate: boolean;
  isMaxDate: boolean;
  monthlyReport: MonthlyReport | undefined;
  isMonthlyReportLoading: boolean;
}

class Dashboard extends React.Component<IProps, IStates> {
  private connectionsChartDataLabels =
    ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  private connectionsChartOptions: ChartOptions = {
    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 0,
        hitRadius: 0,
        hoverRadius: 0,
      },
    },
    scales: {
      yAxes: [{
        ticks: {
          min: 0,
        },
      }],
    },
    animation: {
      duration: 0,
    },
  };

  private usersChartOptions: ChartOptions = {
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    legend: {
      display: false,
    },
    animation: {
      duration: 0,
    },
  };

  private timeout: NodeJS.Timeout;

  public constructor(props: IProps) {
    super(props);

    const reportMonth = new Date();
    reportMonth.setDate(1);
    reportMonth.setHours(0);

    this.state = {
      selectedReportMonth: reportMonth,
      usersCounts: StatsStore.getUsersCounts(),
      activitiesCounts: StatsStore.getActivitiesCounts(),
      connectionsCount: StatsStore.getConnectionsCount(),
      connectionsChartData: {
        labels: this.connectionsChartDataLabels,
        datasets: [
          {
            data: [],
            fill: 'start',
            borderColor: ['rgba(0,0,0,0)'],
            borderWidth: 0,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            datalabels: { display: false },
          },
        ],
      },
      usersChartData: {
        labels: [''],
        datasets: [
          {
            data: [],
            backgroundColor: ['#FC80AB', '#F7D474', '#BDD441', '#A58ABE', '#96DEFF', '#9EA1A2'],
            datalabels: { display: false },
          },
        ],
      },
      connectionsSinceLaunching: 0,
      isMinDate: this.isMinDate(reportMonth),
      isMaxDate: this.isMaxDate(reportMonth),
      monthlyReport: undefined,
      isMonthlyReportLoading: false,
    };
  }

  public componentWillMount() {
    StatsStore.addListener(ActionTypes.STATS_USERS_COUNTS.toString(), () => {
      this.setUserPieChart();
    });

    StatsStore.addListener(ActionTypes.STATS_ACTIVITIES_COUNTS.toString(), () => {
      this.setState({ activitiesCounts: StatsStore.getActivitiesCounts() });
    });

    StatsStore.addListener(ActionTypes.STATS_CONNECTIONS_COUNT.toString(), () => {
      this.setConnectionsChart();
    });

    UserStore.addListener(ActionTypes.USERS_ROLES_GET.toString(), () => {
      this.getRoles();
    });

    StatsActions.emitGetUsersCounts();
    StatsActions.emitGetActivitiesCounts();
    StatsActions.emitGetConnectionsCount();
    UserActions.emitGetRoles();
    this.getMonthlyReport();
  }

  public componentWillUnmount() {
    StatsStore.removeListener(ActionTypes.STATS_USERS_COUNTS.toString(), () => {
      this.setUserPieChart();
    });

    StatsStore.removeListener(ActionTypes.STATS_ACTIVITIES_COUNTS.toString(), () => {
      this.setState({ activitiesCounts: StatsStore.getActivitiesCounts() });
    });

    StatsStore.removeListener(ActionTypes.STATS_CONNECTIONS_COUNT.toString(), () => {
      this.setConnectionsChart();
    });

    UserStore.removeListener(ActionTypes.USERS_ROLES_GET.toString(), () => {
      this.getRoles();
    });
  }

  private getMonthlyReport = () => {
    this.setState(
      { isMonthlyReportLoading: true },
      () => {
        // Wait 800ms between each month selection, to prevent too many requests at once
        clearTimeout(this.timeout);
        this.timeout = setTimeout(
          () => {
            const month = this.state.selectedReportMonth.getMonth() + 1;
            const year = this.state.selectedReportMonth.getFullYear();

            StatsAPI.getMonthlyReport({ month, year })
              .then((monthlyReport) => {
                this.setState({ monthlyReport, isMonthlyReportLoading: false });
              })
              .catch(() => {
                Utils.toastError();
                this.setState({ isMonthlyReportLoading: false });
              });
          },
          800,
        );
      },
    );
  }

  /**
   * Get Connections and fill connection chart data
   */
  private setConnectionsChart = () => {
    let connectionsSinceLaunching = 0;
    StatsStore.getConnectionsCount().forEach(el => connectionsSinceLaunching += el.count);

    const connectionsCount = StatsStore.getConnectionsCount().filter(
      el => new Date(el.date).getFullYear() === new Date().getFullYear(),
    );

    const data: number[] = [];
    this.connectionsChartDataLabels.forEach((month: string, index: number) => {
      const countCurrentMonth = connectionsCount.filter(el => new Date(el.date).getMonth() === index);

      let totalCount = 0;
      countCurrentMonth.forEach((dayCount: ConnectionsCount) => totalCount += dayCount.count);

      data.push(totalCount);
    });

    const connectionsChartData = { ...this.state.connectionsChartData };

    this.setState({
      connectionsCount,
      connectionsSinceLaunching,
      connectionsChartData: (canvas: any) => {
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 270);
        gradient.addColorStop(0, '#B95FDB');
        gradient.addColorStop(1, 'rgba(226, 174, 157, 0.5)');

        connectionsChartData.datasets[0].backgroundColor = gradient;
        connectionsChartData.datasets[0].data = data;
        return connectionsChartData;
      },
    });
  };

  /**
   * Get Users and fill users pie chart data
   */
  private setUserPieChart = () => {
    const usersChartData = { ...this.state.usersChartData };
    const usersCounts = StatsStore.getUsersCounts();

    usersChartData.datasets[0].data = [
      usersCounts.referents,
      usersCounts.bcLeaders,
      usersCounts.piLeaders,
      usersCounts.actionOwners,
      usersCounts.basicAccounts,
      usersCounts.informedAccounts,
    ];
    this.setState({ usersCounts, usersChartData });
  };

  /**
   * Get Roles and fill users pie chart legend
   */
  private getRoles = () => {
    const usersChartData = { ...this.state.usersChartData };
    const labels: string[] = [];
    Utils.sortArrayByKey(UserStore.getRoles(), 'id').forEach((role: Role) => {
      labels.push(role.name);
    });

    usersChartData.labels = labels;
    this.setState({ usersChartData });
  };

  private static getStatElement(title: JSX.Element, content: number | JSX.Element) {
    return (
      <div className="element">
        <div className="element-title">
          {title}
        </div>
        <div className="element-content">
          {content}
        </div>
      </div>
    );
  }

  private changeReportMonth = (reportDirection: ReportDirection) => {
    const selectedReportMonth = this.state.selectedReportMonth;

    const isMinDate = this.isMinDate(selectedReportMonth);
    const isMaxDate = this.isMaxDate(selectedReportMonth);

    let changed = false;
    if (reportDirection === ReportDirection.PREVIOUS && !isMinDate) {
      selectedReportMonth.setMonth(selectedReportMonth.getMonth() - 1);
      changed = true;
    }
    if (reportDirection === ReportDirection.NEXT && !isMaxDate) {
      selectedReportMonth.setMonth(selectedReportMonth.getMonth() + 1);
      changed = true;
    }

    this.setState(
      {
        selectedReportMonth,
        isMinDate: this.isMinDate(selectedReportMonth),
        isMaxDate: this.isMaxDate(selectedReportMonth),
      },
      () => changed && this.getMonthlyReport(),
    );
  }

  private isMinDate = (reportMonth: Date) => {
    return reportMonth.getMonth() <= 0 && reportMonth.getFullYear() <= 2018;
  }

  private isMaxDate = (reportMonth: Date) => {
    const todayDate = new Date();
    return reportMonth.getFullYear() === todayDate.getFullYear()
      ? reportMonth.getMonth() >= todayDate.getMonth()
      : reportMonth.getMonth() >= 11 && reportMonth.getFullYear() >= todayDate.getFullYear();
  }

  public render() {
    return (
      <CustomScrollBars>
        <div id="dashboard">
          <div id="main-title">
            <FormattedMessage id="managementData" defaultMessage="Management Data" />
          </div>
          <div id="content-container">
            <Segment id="charts-container">
              <div id="connections-container">
                <div className="title-container">
                  <span className="segment-title">
                    <FormattedMessage id="iBoost" defaultMessage="iBoost" /> {new Date().getFullYear()}
                  </span>
                  <span id="connections-count">
                    <span id="nb-connections">{this.state.connectionsSinceLaunching}</span>
                    <span id="desc-connections">
                      <FormattedMessage id="connectionsSinceLaunching" defaultMessage="Connections since Launching" />
                    </span>
                  </span>
                </div>
                <div id="connections-chart-container">
                  <FormattedMessage id="connections" defaultMessage="Connections" />
                  <Line data={this.state.connectionsChartData} options={this.connectionsChartOptions} height={75} />
                </div>
              </div>
              <div id="users-container">
                <div className="title-container">
                  <div>
                    <span className="segment-title">
                      <FormattedMessage id="users" defaultMessage="Users">
                        {msg => `${msg.toString().toUpperCase()} (${
                          this.state.usersCounts ? this.state.usersCounts.totalUsers : null
                          })`}
                      </FormattedMessage>
                    </span>
                  </div>
                  <div className="sub-title">
                    <FormattedMessage
                      id="actorsConnectedAtLeastOnce"
                      defaultMessage="iBoost actors who connected at least once"
                    />
                  </div>
                </div>
                <div id="users-chart-container">
                  <div id="users-chart">
                    <Pie data={this.state.usersChartData} options={this.usersChartOptions} width={1} height={1} />
                  </div>
                  <div id="legend">
                    {this.state.usersChartData.labels.map((label: string, index: number) => {
                      const style = { backgroundColor: this.state.usersChartData.datasets[0].backgroundColor[index] };
                      return (
                        <div key={`legend${index}`}>
                          <span className="legend-badge" style={style} />
                          {label} ({this.state.usersChartData.datasets[0].data[index]})
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div id="nb-not-created-container">
                  {this.state.usersCounts
                    && <div>
                      {this.state.usersCounts.coreTeamAccounts > 0
                      && <div>
                        <FormattedMessage id="including" defaultMessage="Including" />
                        &nbsp;
                        <span className="nb-not-created">
                          {this.state.usersCounts.coreTeamAccounts}
                        </span>
                        &nbsp;
                        <FormattedMessage id="coreTeamMembers" defaultMessage="Core Team members." />
                      </div>
                      }
                      {this.state.usersCounts.newAccounts > 0
                        ? <div>
                          <span className="nb-not-created">
                            {this.state.usersCounts.newAccounts}
                          </span>
                          &nbsp;
                          <FormattedMessage
                            id="assigneesAccountNotCreated"
                            defaultMessage="assignees have not created their account yet."
                          />
                        </div>
                        : <FormattedMessage
                          id="allAccountsCreated"
                          defaultMessage="All assignees created their account."
                        />
                      }
                    </div>
                  }
                </div>
              </div>
            </Segment>

            <div id="stats-container">
              <Segment id="activities-container">
                <div className="title-container">
                  <span className="segment-title">
                    <FormattedMessage id="activities" defaultMessage="Activities" /> {new Date().getFullYear()}
                  </span>
                  <ExportAllBcsPisButton />
                </div>
                {this.state.activitiesCounts
                  ? <>
                    <div className="column">
                      {Dashboard.getStatElement(
                        <FormattedMessage id="performancePlans" defaultMessage="Performance Plans" />,
                        this.state.activitiesCounts.performancePlans,
                      )}

                      {Dashboard.getStatElement(
                        <FormattedMessage id="businessChallenges" defaultMessage="Business Challenges" />,
                        this.state.activitiesCounts.businessChallenges,
                      )}

                      {Dashboard.getStatElement(
                        <FormattedMessage id="performanceInitiatives" defaultMessage="Performance Initiatives" />,
                        this.state.activitiesCounts.performanceInitiatives,
                      )}

                      {Dashboard.getStatElement(
                        <FormattedMessage id="actions" defaultMessage="Actions" />,
                        this.state.activitiesCounts.actions,
                      )}
                    </div>
                  </>
                  : Utils.loader('loader')
                }
              </Segment>

              <Segment id="monthly-report-container">
                <div className="title-container">
                <span className="segment-title">
                  <FormattedMessage id="monthlyReport" defaultMessage="Monthly Report" />
                  <span id="month-selection">
                    <Icon
                      name="chevron left"
                      disabled={this.state.isMinDate}
                      onClick={() => this.changeReportMonth(ReportDirection.PREVIOUS)}
                    />

                    <span>
                      {moment(this.state.selectedReportMonth).format('MMMM')}
                      &nbsp;
                      {this.state.selectedReportMonth.getFullYear()}
                    </span>

                    <Icon
                      name="chevron right"
                      disabled={this.state.isMaxDate}
                      onClick={() => this.changeReportMonth(ReportDirection.NEXT)}
                    />

                    {this.state.isMonthlyReportLoading && Utils.loader('month-selection-loader', 'tiny')}
                  </span>
                </span>
                </div>
                {this.state.monthlyReport
                  ? <div className="column-container">
                    <div className="column">
                      {Dashboard.getStatElement(
                        <FormattedMessage id="monthlyReport.maxSimultaneousConnections" defaultMessage="Max simultaneous connections" />,
                        <>
                          {this.state.monthlyReport.maxSimultaneousConnections.count}
                          <Popup
                            size="small"
                            position="right center"
                            content={(
                              <>
                                <FormattedMessage
                                  id="monthlyReport.maxSimultaneousConnections.date"
                                  defaultMessage="At"
                                />: {Utils.displayDate(this.state.monthlyReport.maxSimultaneousConnections.start)}
                                <br />
                                <FormattedMessage
                                  id="monthlyReport.maxSimultaneousConnections.hours"
                                  defaultMessage="From {startHour} to {endHour}"
                                  values={{
                                    startHour: Utils.displayHours(
                                      this.state.monthlyReport.maxSimultaneousConnections.start),
                                    endHour: Utils.displayHours(
                                      this.state.monthlyReport.maxSimultaneousConnections.end),
                                  }}
                                />
                              </>
                            )}
                            trigger={<Icon name="info circle" />}
                          />
                        </>,
                      )}

                      {Dashboard.getStatElement(
                        <FormattedMessage id="monthlyReport.uniqueUsersConnected" defaultMessage="Unique users connected" />,
                        this.state.monthlyReport.uniqueUsersConnected,
                      )}

                      {Dashboard.getStatElement(
                        <FormattedMessage id="monthlyReport.ssoConnections" defaultMessage="SSO connections" />,
                        this.state.monthlyReport.ssoConnections,
                      )}

                      {Dashboard.getStatElement(
                        <FormattedMessage id="monthlyReport.passwordConnections" defaultMessage="Mail/password connections" />,
                        this.state.monthlyReport.passwordConnections,
                      )}
                    </div>

                    <div className="column">
                      {Dashboard.getStatElement(
                        <FormattedMessage id="monthlyReport.newUsers" defaultMessage="New Users" />,
                        this.state.monthlyReport.newUsers,
                      )}

                      {Dashboard.getStatElement(
                        <FormattedMessage id="monthlyReport.invitations" defaultMessage="Invited users" />,
                        this.state.monthlyReport.invitations,
                      )}

                      {Dashboard.getStatElement(
                        <FormattedMessage
                          id="monthlyReport.acceptedInvitations"
                          defaultMessage="Accepted invitations"
                        />,
                        this.state.monthlyReport.acceptedInvitations,
                      )}

                      {Dashboard.getStatElement(
                        <FormattedMessage id="monthlyReport.pendingInvitations" defaultMessage="Pending invitations" />,
                        this.state.monthlyReport.pendingInvitations,
                      )}
                    </div>

                    <div className="column">
                      {Dashboard.getStatElement(
                        <FormattedMessage id="monthlyReport.newPp" defaultMessage="New Performance Plans" />,
                        this.state.monthlyReport.newPp,
                      )}

                      {Dashboard.getStatElement(
                        <FormattedMessage id="monthlyReport.newBc" defaultMessage="New Business Challenges" />,
                        this.state.monthlyReport.newBc,
                      )}

                      {Dashboard.getStatElement(
                        <FormattedMessage id="monthlyReport.newPi" defaultMessage="New Performance Initiatives" />,
                        this.state.monthlyReport.newPi,
                      )}

                      {Dashboard.getStatElement(
                        <FormattedMessage id="monthlyReport.newAt" defaultMessage="New Actions" />,
                        this.state.monthlyReport.newAt,
                      )}
                    </div>

                    <div className="column">
                      {Dashboard.getStatElement(
                        <FormattedMessage id="monthlyReport.totalExports" defaultMessage="Total PDF exports" />,
                        this.state.monthlyReport.totalExports,
                      )}

                      {Dashboard.getStatElement(
                        <FormattedMessage id="monthlyReport.succeededExports" defaultMessage="Succeeded PDF exports" />,
                        this.state.monthlyReport.succeededExports,
                      )}

                      {Dashboard.getStatElement(
                        <FormattedMessage id="monthlyReport.failedExports" defaultMessage="Failed PDF exports" />,
                        this.state.monthlyReport.failedExports,
                      )}
                    </div>

                    <div className="column">
                      {Dashboard.getStatElement(
                        <FormattedMessage id="monthlyReport.totalIncidents" defaultMessage="Total incidents" />,
                        this.state.monthlyReport.totalIncidents,
                      )}

                      {Dashboard.getStatElement(
                        <FormattedMessage id="monthlyReport.clientIncidents" defaultMessage="Client incidents" />,
                        this.state.monthlyReport.clientIncidents,
                      )}

                      {Dashboard.getStatElement(
                        <FormattedMessage id="monthlyReport.apiIncidents" defaultMessage="Server incidents" />,
                        this.state.monthlyReport.apiIncidents,
                      )}
                    </div>
                  </div>
                  : Utils.loader('loader')
                }
              </Segment>
            </div>
          </div>
        </div>
      </CustomScrollBars>
    );
  }
}

export default Dashboard;
