import './lastUpdatedInformations.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Icon, Label } from 'semantic-ui-react';
import { RouteComponentProps, withRouter } from 'react-router';

import Utils from 'src/utils/utils';
import TypeActivity from 'src/constants/typeActivity';
import LoggingAPI from 'src/api/logging.api';
import { LastUpdatedInformation } from './interface/lastUpdatedInformations.interface';
import ActivitiesStore from 'src/stores/activities.store';
import { BcTabs } from '../../../../../contentBC/contentBC';
import LastUpdateHighlight from './lastUpdateHighlight';
import PerformancePlan from 'src/models/performancePlan';
import { SegmentType } from 'src/models/segmentsMode';
import ActionTypes from 'src/constants/actionTypes';

interface IRouteProps {
  ppId: string;
  id: string;
}
interface IProps extends RouteComponentProps<IRouteProps> {
  typeActivity?: TypeActivity | null;
  activityId: number;
  handleTabChange: (index: number) => void;
  contentPanelHeight: number;
}

interface IStates {
  lastUpdatedInformations: LastUpdatedInformation[];
  loadingLastUpdate: boolean;
}

class LastUpdatedInformations extends React.Component<IProps, IStates> {
  private isMount = false;

  public constructor(props: IProps) {
    super(props);
    this.state = {
      lastUpdatedInformations: [],
      loadingLastUpdate: false,
    };
  }

  componentDidMount() {
    this.isMount = true;
    this.getLastUpdatedInformations();
    ActivitiesStore.addListener(ActionTypes.UPDATED_ACTIVITY.toString(), this.getLastUpdatedInformations);
  }

  componentWillUnmount() {
    this.isMount = false;
    ActivitiesStore.removeListener(ActionTypes.UPDATED_ACTIVITY.toString(), this.getLastUpdatedInformations);
  }

  componentDidUpdate(prevProps: Readonly<IProps & ReactIntl.InjectedIntlProps>, snapshot?: any) {
    if (prevProps.activityId !== this.props.activityId) {
      this.getLastUpdatedInformations();
    }
  }

  getLastUpdatedInformations = () => {
    if (this.isMount) {
      this.setLoading(true);
      let promise;
      switch (this.props.typeActivity) {
        case TypeActivity.PERFORMANCE_PLAN:
          promise = LoggingAPI.getLastPpUpdatedInformations(this.props.activityId);
          break;
        case TypeActivity.BUSINESS_CHALLENGE:
          promise = LoggingAPI.getLastBcUpdatedInformations(this.props.activityId);
          break;
        case TypeActivity.PERFORMANCE_INITIATIVE:
          promise = LoggingAPI.getLastPiUpdatedInformations(this.props.activityId);
          break;
        default:
          promise = LoggingAPI.getLastPpUpdatedInformations(this.props.activityId);
          break;
      }
      promise.then((logs) => {
        this.setState({
          lastUpdatedInformations: logs,
          loadingLastUpdate: false,
        });
      }).catch(() => {
        this.setLoading(false);
      });
    }
  }

  private setLoading = (isLoading) => {
    this.setState({
      loadingLastUpdate: isLoading,
    });
  }

  private redirectTo = (logging: LastUpdatedInformation) => {
    let url = '.';
    const openedMenu = window.location.href;
    if (Utils.isActivityPp(ActivitiesStore.getTypeActivity())) {
      if (logging.ppId && logging.bcId) {
        url = `/activities-board/${this.props.match.params.id}/business-challenge/${logging.bcId}`;
      }
      if (logging.ppId && logging.piId) {
        url = `/activities-board/${this.props.match.params.id}/performance-initiative/${logging.piId}`;
      }
    } else {
      if (logging.piId) {
        url = `/activities-board/${logging.ppId[0]}/performance-initiative/${logging.piId}`;
      } else if (logging.bcId) {
        url = `/activities-board/${this.props.match.params.ppId}/business-challenge/${logging.bcId}`;
      }
    }

    const cockpit = [
      SegmentType.BC_STATUS, SegmentType.BC_MAIN_ACHIEVEMENTS, SegmentType.BC_MAIN_ISSUES,
      SegmentType.BC_DECISIONS_TO_BE_MADE, SegmentType.BC_NEXT_STEPS, SegmentType.BC_CREATED,
      SegmentType.PI_STATUS, SegmentType.PI_MAIN_ACHIEVEMENTS, SegmentType.PI_MAIN_ISSUES,
      SegmentType.PI_DECISIONS_TO_BE_MADE, SegmentType.PI_NEXT_STEPS, SegmentType.PI_CREATED,
    ];

    const metricsMilestones = [
      SegmentType.BC_METRICS, SegmentType.PI_METRICS, SegmentType.PI_METRICS_DELETION, SegmentType.BC_METRICS_DELETION,
    ];

    const actionsMilestones = [
      SegmentType.BC_MILESTONES, SegmentType.PI_MILESTONES, SegmentType.AT_ACTION, SegmentType.AT_ACTION_DELETION,
    ];

    const moreInfo = [
      SegmentType.PP_ABOUT, SegmentType.PP_TEAM, SegmentType.PP_ATTACHMENTS,
      SegmentType.BC_ABOUT, SegmentType.BC_TEAM, SegmentType.BC_ATTACHMENTS,
      SegmentType.PI_ABOUT, SegmentType.PI_TEAM, SegmentType.PI_ATTACHMENTS,
    ];

    const allMenus = { cockpit, metricsMilestones, actionsMilestones, moreInfo };

    // Check if the cockpit is opened
    if (openedMenu.includes('cockpit')) {
      url += LastUpdatedInformations.checkActivityMenu(allMenus, logging);
    }

    if (openedMenu.includes('more-info')) {
      url += LastUpdatedInformations.checkActivityMenu(allMenus, logging);
    }

    if (openedMenu.includes('metrics-view')) {
      url += LastUpdatedInformations.checkActivityMenu(allMenus, logging);
    }

    if (openedMenu.includes('activity')) {
      url += LastUpdatedInformations.checkActivityMenu(allMenus, logging);
    }

    // Don't change page on status because we just need to change overview panel tabs
    if ((SegmentType[logging.updatedType] === SegmentType.BC_STATUS
      || SegmentType[logging.updatedType] === SegmentType.PI_STATUS) && this.props.typeActivity !== undefined
      && !Utils.isActivityPp(this.props.typeActivity as TypeActivity)) {
      url = '';
      this.props.handleTabChange(0);
    }

    // if we want to display the highlight we add highlight in the url
    url += `?highlight=${logging.updatedType}`;

    if (SegmentType[logging.updatedType] === SegmentType.BC_METRICS
      || SegmentType[logging.updatedType] === SegmentType.PI_METRICS) {
      url += `&metricId=${logging.metricId}`;
    }

    this.props.history.push(url);
  }

  private static checkActivityMenu(
    allMenus: {
      cockpit: SegmentType[];
      metricsMilestones: SegmentType[];
      actionsMilestones: SegmentType[];
      moreInfo: SegmentType[];
    },
    logging: LastUpdatedInformation,
  ): string {
    let menuPath;
    if (allMenus.metricsMilestones.includes(SegmentType[logging.updatedType])) {
      menuPath = `/${BcTabs.METRICS_VIEW}`;
    }

    if (allMenus.actionsMilestones.includes(SegmentType[logging.updatedType])) {
      menuPath = `/${BcTabs.ACTIVITY}`;

      if (logging.atId) menuPath = `/${BcTabs.ACTIVITY}/${logging.atId}`;

      if (SegmentType[logging.updatedType] === SegmentType.BC_MILESTONES) {
        menuPath = `/${BcTabs.ACTIVITY}/${logging.updatedData.id}`;
      }
    }

    if (allMenus.cockpit.includes(SegmentType[logging.updatedType])) {
      menuPath = `/${BcTabs.COCKPIT}`;
    }

    if (allMenus.moreInfo.includes(SegmentType[logging.updatedType])) {
      menuPath = `/${BcTabs.MORE_INFO}`;
    }

    return menuPath;
  }

  /**
   * Display child activity if it's PP
   * @param {LastUpdatedInformation} logging
   * @returns {JSX.Element} Semantic Label | void
   */
  private displayChildLogging = (logging: LastUpdatedInformation) : JSX.Element | void => {
    const actType = ActivitiesStore.getTypeActivity();

    if ((Utils.isActivityPp(actType) && ((logging.ppId && logging.bcId) || (logging.ppId && logging.piId)))
      || (Utils.isActivityBc(actType) && logging.bcId && logging.piId)) {

      let code;
      if (SegmentType[logging.updatedType] === SegmentType.PI_METRICS) {
        code = logging.updatedData.performanceInitiative?.code ?? logging.updatedData.code;
      }

      if (SegmentType[logging.updatedType] === SegmentType.BC_METRICS
        && Utils.isActivityPp(actType) && !!logging.updatedData.businessChallenge) {
        code = logging.updatedData.businessChallenge.code;
      }

      if (SegmentType[logging.updatedType] === SegmentType.PI_MILESTONES
        || SegmentType[logging.updatedType] === SegmentType.PI_MILESTONES_DELETION) {
        code = logging.updatedData.piCode;
      }

      if (!code) {
        code = logging.updatedData.code;
      }

      return (
        <Label className="activity-badge" circular={true} color={'grey'}>
          {logging.isLinkedActivity && <Icon name="linkify" />}
          {Utils.formatActivityCode(
             code,
             (Utils.isActivityPp(actType) && (logging.bcId && !logging.piId)) ?
              TypeActivity.BUSINESS_CHALLENGE : TypeActivity.PERFORMANCE_INITIATIVE)}
        </Label>
      );
    }
  }

  private isClickable(logging: LastUpdatedInformation, pp: PerformancePlan): boolean {
    const bcPiSegments = [
      SegmentType.BC_STATUS, SegmentType.BC_MAIN_ACHIEVEMENTS, SegmentType.BC_MAIN_ISSUES,
      SegmentType.BC_DECISIONS_TO_BE_MADE, SegmentType.BC_NEXT_STEPS, SegmentType.BC_CREATED,
      SegmentType.BC_METRICS, SegmentType.BC_MILESTONES,
      SegmentType.BC_ABOUT, SegmentType.BC_TEAM, SegmentType.BC_ATTACHMENTS,

      SegmentType.PI_STATUS, SegmentType.PI_MAIN_ACHIEVEMENTS, SegmentType.PI_MAIN_ISSUES,
      SegmentType.PI_DECISIONS_TO_BE_MADE, SegmentType.PI_NEXT_STEPS, SegmentType.PI_CREATED,
      SegmentType.PI_METRICS, SegmentType.PI_MILESTONES,
      SegmentType.PI_ABOUT, SegmentType.PI_TEAM, SegmentType.PI_ATTACHMENTS,
    ];

    if (SegmentType[logging.updatedType] === SegmentType.BC_DELETED
      || SegmentType[logging.updatedType] === SegmentType.PI_DELETED
      || SegmentType[logging.updatedType] === SegmentType.BC_MILESTONES_DELETION
      || SegmentType[logging.updatedType] === SegmentType.PI_MILESTONES_DELETION
      || SegmentType[logging.updatedType] === SegmentType.AT_ACTION_DELETION
      || SegmentType[logging.updatedType] === SegmentType.PI_METRICS_DELETION
      || SegmentType[logging.updatedType] === SegmentType.BC_METRICS_DELETION) {
      return false;
    }

    if (this.props.typeActivity && Utils.isActivityPp(this.props.typeActivity)
    && bcPiSegments.includes(SegmentType[logging.updatedType])) {
      if (pp) {
        if (logging.piId) {
          return pp.businessChallenges.some(bc => bc.performanceInitiatives.some(pi => pi.id === logging.piId));
        }
        if (logging.bcId) return pp.businessChallenges.some(bc => bc.id === logging.bcId);
        if (logging.ppId) return true;
      }
      return false;
    }

    return true;
  }

  private idSegmentToHighlight = (logging: LastUpdatedInformation) => {
    switch (SegmentType[logging.updatedType]) {
      case SegmentType.AT_ACTION:
        return logging.atId;

      case SegmentType.BC_METRICS:
      case SegmentType.PI_METRICS :
        return logging.metricId;

      case SegmentType.BC_MILESTONES:
      case SegmentType.PI_MILESTONES:
        return logging.updatedData.id;
    }
  }

  public render() {
    const pps = ActivitiesStore.getActivities();
    const actType = ActivitiesStore.getTypeActivity();
    return (
      <ul
        id="last-updated-informations"
        className="last-updated-informations"
        style={{ height: `${this.props.contentPanelHeight}px` }}
      >
        {this.state.lastUpdatedInformations.map((logging, index) => {
          const isClickable = this.isClickable(logging, pps
            .filter(pp => pp.id === (logging.updatedData?.performancePlan
              ? logging.updatedData?.performancePlan.id
              : logging.updatedData?.performancePlanId || (logging.updatedData?.businessChallenge
                ? (logging.updatedData?.businessChallenge.performancePlan
                    ? logging.updatedData?.businessChallenge.performancePlan.id
                    : logging.updatedData?.businessChallenge.performancePlanId)
                : null)),
            )?.[0]);
          return (
            <li
              key={`last-update-card-${index}`}
              className={isClickable ? 'clickable' : ''}
              onClick={() => isClickable && this.redirectTo(logging)}
              onMouseEnter={() => !(Utils.isActivityBc(actType) && logging.bcId && logging.piId)
                ? LastUpdateHighlight.highlightArea(SegmentType[logging.updatedType],
                                                    this.idSegmentToHighlight(logging)) : null
              }
              onMouseLeave={() =>
                LastUpdateHighlight.unHighlightArea(SegmentType[logging.updatedType],
                                                    this.idSegmentToHighlight(logging))}
            >
              <span className="title">
                {!!logging && !!logging.updatedType ?
                  <>
                    {this.displayChildLogging(logging)}
                    {Utils.displayUpdatedInformationsType(logging.updatedType)}
                    {(SegmentType[logging.updatedType] === SegmentType.AT_ACTION
                    || SegmentType[logging.updatedType] === SegmentType.AT_ACTION_DELETION) && ` - A${logging.updatedData?.code}`}
                    {(SegmentType[logging.updatedType] === SegmentType.PI_MILESTONES
                      || SegmentType[logging.updatedType] === SegmentType.BC_MILESTONES
                      || SegmentType[logging.updatedType] === SegmentType.BC_MILESTONES_DELETION
                      || SegmentType[logging.updatedType] === SegmentType.PI_MILESTONES_DELETION) &&
                    ` - ${Utils.formatActivityCode(logging.updatedData?.code, TypeActivity.MILESTONE)}`
                    }
                  </> : <FormattedMessage id={SegmentType[logging.updatedType]} defaultMessage={logging.updatedType} />
                }
                {!isClickable && SegmentType[logging.updatedType] !== SegmentType.BC_DELETED
                && SegmentType[logging.updatedType] !== SegmentType.PI_DELETED
                  && <span className="italic">
                  <FormattedMessage id="deletedActivity" defaultMessage="deleted activity" />
                </span>
                }
              </span>
              <div className="element">
                <FormattedMessage id="by" defaultMessage="By" />
                <p>
                  {logging.user
                    ? `${logging.user.firstName} ${logging.user.lastName}`
                    : <em><FormattedMessage id="deletedUser" defaultMessage="Deleted user"/></em>
                  }
                </p>
              </div>
              <div className="element">
                <FormattedMessage id="at" defaultMessage="At" />
                <p>{Utils.displayDate(moment(logging.updatedAt).toDate(), true)}</p>
              </div>
            </li>
          );
        })}
        {this.state.lastUpdatedInformations.length <= 0 && !this.state.loadingLastUpdate &&
          <li className="no-update">
            <FormattedMessage id="noLastUpdate" defaultMessage="No last update" />
          </li>
        }
        {this.state.loadingLastUpdate && Utils.loader()}
      </ul>
    );
  }
}

export default withRouter(LastUpdatedInformations);
