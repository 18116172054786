
import HttpClient from '../utils/httpClient';
import TypeActivity from 'src/constants/typeActivity';
import { WorkBook } from 'xlsx';
import { ExportChoices } from '../constants/exportColumns';

interface IGetProgression {
  progression: number;
  isPdfAvailable: boolean;
}

export default class ExportAPI {

  public static getPdfGenerationProgress(pdfId: string): Promise<IGetProgression> {
    return HttpClient.get(`export/progression/${pdfId}`);
  }

  public static getGeneratedPdf(pdfId: string): Promise<string> {
    return HttpClient.getBase64(`export/pdf/${pdfId}`);
  }

  public static getCsvExport(activityId: number, type: TypeActivity): Promise<WorkBook> {
    let activityType = '';
    switch (type) {
      case TypeActivity.PERFORMANCE_PLAN:
        activityType = 'pp';
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        activityType = 'bc';
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        activityType = 'pi';
        break;
    }

    return HttpClient.get(`export/${activityId}/sheet-${activityType}`);
  }

  public static getPpActivitiesCsvExport(activityId: number): Promise<string> {
    return HttpClient.getFile(`export/${activityId}/all-pp-activities`);
  }

  public static getActionListCsvExport(activityId: number, type: TypeActivity): Promise<string> {
    let activityType = '';
    switch (type) {
      case TypeActivity.PERFORMANCE_PLAN:
        activityType = 'pp';
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        activityType = 'bc';
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        activityType = 'pi';
        break;
    }
    return HttpClient.getFile(`export/${activityId}/action-list-sheet-${activityType}`);
  }

  public static getAllBcsPisExportSheet(exportChoices: ExportChoices): Promise<string> {
    return HttpClient.getFile(`export/all-bcs-and-pis-sheet${HttpClient.formatUrlQueryParams(exportChoices)}`);
  }
}
