import Action from '../models/action';
import User from '../models/user';
import TypeActivity from './typeActivity';
import Milestone from '../models/milestone';
import { Months } from './timeline';
import TimelineUtils from '../utils/timelineUtils';

export interface KeyPointInfo {
  day: number;
  month: number;
  year: number;
  fullDate: Date;
}

export enum ICON_TYPE {
  COMPLETION_DATE = 1,
  FORECAST_DATE = 2,
  MULTIPLE_DATES = 3,
  TARGET_DATE_MILESTONE = 4,
  COMPLETION_DATE_MILESTONE = 5,
}

export class ShapedIcon {
  day: number;
  type: ICON_TYPE;

  constructor(day: number, type: ICON_TYPE) {
    this.day = day;
    this.type = type;
  }
}

export class KeyPoints {
  public startingDate?: KeyPointInfo;
  public targetDate?: KeyPointInfo;
  public forecastDate?: KeyPointInfo;
  public completionDate?: KeyPointInfo;

  constructor(element: AtTimelineElement | MiTimelineElement) {
    if ('startingDate' in element && element['startingDate'] !== null) {
      this.startingDate = {
        day: new Date((element as AtTimelineElement).startingDate!).getDate(),
        month: new Date((element as AtTimelineElement).startingDate!).getMonth(),
        year: new Date((element as AtTimelineElement).startingDate!).getFullYear(),
        fullDate: (element as AtTimelineElement).startingDate!,
      };
    }
    if ('forecastDate' in element && element['forecastDate'] !== null) {
      this.forecastDate = {
        day: new Date((element as AtTimelineElement).forecastDate!).getDate(),
        month: new Date((element as AtTimelineElement).forecastDate!).getMonth(),
        year: new Date((element as AtTimelineElement).forecastDate!).getFullYear(),
        fullDate: (element as AtTimelineElement).forecastDate!,
      };
    }
    if (element.completionDate) {
      this.completionDate = {
        day: new Date(element.completionDate).getDate(),
        month: new Date(element.completionDate).getMonth(),
        year: new Date(element.completionDate).getFullYear(),
        fullDate: element.completionDate,
      };
    }
    if (element.targetDate) {
      this.targetDate = {
        day: new Date(element.targetDate).getDate(),
        month: new Date(element.targetDate).getMonth(),
        year: new Date(element.targetDate).getFullYear(),
        fullDate: element.targetDate,
      };
    }
  }

  public isStartingSet = () => {
    return this.startingDate !== undefined;
  }

  public isCompletionSet = () => {
    return this.completionDate !== undefined;
  }

  public isForecastSet = () => {
    return this.forecastDate !== undefined;
  }

  public isTargetSet = () => {
    return this.targetDate !== undefined;
  }
}

export class AtTimelineElement {

  public id: number;
  public name: string;
  public code: number;
  public status: number;
  public targetDate: Date | null;
  public completionDate: Date | null;
  public startingDate: Date | null;
  public forecastDate: Date | null;
  public assignedAccounts: User[];
  public isClosed: boolean;
  public isFavourite: boolean;
  public piMilestoneId: number;
  public piMilestoneCode: number;
  public type = TypeActivity.ACTION;

  constructor(at: Action) {
    this.id = at.id;
    this.name = at.name;
    this.code = at.code;
    this.status = at.status;
    this.targetDate = at.targetDate;
    this.completionDate = at.completionDate;
    this.startingDate = at.startingDate;
    this.forecastDate = at.forecastDate;
    this.assignedAccounts = at.assignedAccounts;
    if ('isClosed' in at) {
      this.isClosed = at.isClosed;
    }
    if ('isFavourite' in at) {
      this.isFavourite = at.isFavourite;
    }
    this.piMilestoneId = at.piMilestoneId;
    this.piMilestoneCode = at.piMilestoneCode;
  }
}

export class MiTimelineElement {
  public id: number;
  public name: string;
  public code: number;
  public targetDate: Date | null;
  public completionDate?: Date;
  public type = TypeActivity.MILESTONE;
  public associatedActions: AtTimelineElement[];

  constructor(mi: Milestone, actions: Action[]) {
    this.id = mi.id!;
    this.name = mi.name;
    this.code = mi.code;
    this.targetDate = mi.targetDate;
    this.completionDate = mi.completionDate;
    this.associatedActions = actions.map(at => new AtTimelineElement(at));
  }
}

export type TimelineElement = (AtTimelineElement | MiTimelineElement);

export class TimelineZone {
  public nbDays: number;
  public idMonth: number;
  public percentageSize: number;
  public day?: number;

  constructor(month: Months, year: number, totalDays?: number, nbDaysOverride?: number, day?: number) {
    this.nbDays = (nbDaysOverride !== undefined) ? nbDaysOverride : TimelineUtils.getNbDaysForMonth(month, year);
    this.idMonth = month;
    const tDays = totalDays !== undefined ? totalDays : this.nbDays;
    this.percentageSize = (this.nbDays / tDays) * 100;
    if (day !== undefined) this.day = day;
  }
}
