import React from 'react';
import { FormattedMessage } from 'react-intl';
import Utils from '../../../../../utils/utils';
import ModeTypes from '../../../../../constants/modeTypes';
import { DateInput } from 'semantic-ui-calendar-react';
import { ActionDateField, CommonDateField } from '../../../../../constants/fields';
import { Icon, Popup } from 'semantic-ui-react';

interface IProps {
  mode: ModeTypes;
  dateField: CommonDateField | ActionDateField;
  date: Date | undefined | null;
  minDate?: Date;
  handleDateChange?({ name, value }: any, event: any): void;
  isFieldValid?: boolean;
  viewOnly?: boolean;
  isMilestone?: boolean;
}

class DateField extends React.Component<IProps> {

  private getToolTip(subject: string) {
    let message : string;
    const messageCompletion : string = `If the completion date is before today\'s date,
      the action will be closed on the next visit when saved. You will be able to re-open it after
      and the action closing will be manual.`;
    switch (subject) {
      case 'targetDate':
        message = 'The Target Date must be after the Starting Date';
        break;
      case 'completionDate':
        message = 'The Completion Date must be after the Starting Date';
        break;
      case 'forecastDate':
      default:
        message = 'The Forecast Date must be after the Starting Date';
        break;
    }
    return (
      <div>
        <Popup
          size="small"
          position="top center"
          hoverable={true}
          content={<FormattedMessage id="at.targetDate" defaultMessage={message} />}
          trigger={<Icon name="info circle" />}
        />
        {subject === 'completionDate' &&
          <Popup
            size="small"
            position="top center"
            hoverable={true}
            content={<FormattedMessage id="at.closedAt" defaultMessage={messageCompletion} />}
            trigger={<Icon name="info circle" />}
          />
        }
      </div>
    );
  }
  private getDateTitle = () => {
    switch (this.props.dateField) {
      case CommonDateField.TARGET_DATE:
        return (
          <div className="dateHolder">
            <FormattedMessage id="targetDate" defaultMessage="Target date"/>
            {!Utils.isOnViewMode(this.props.mode) && !this.props.isMilestone && this.getToolTip('targetDate')}
          </div>
        );
      case CommonDateField.COMPLETION_DATE:
        return (
          <div className="dateHolder">
            <FormattedMessage id="dateOfCompletion" defaultMessage="Completion date"/>
            {!Utils.isOnViewMode(this.props.mode) && !this.props.isMilestone && this.getToolTip('completionDate')}
          </div>
        );
      case ActionDateField.FORECAST_DATE:
        return (
          <div className="dateHolder">
            <FormattedMessage id="forecastDate" defaultMessage="Forecast date"/>
            {!Utils.isOnViewMode(this.props.mode) && this.getToolTip('forecastDate')}
          </div>
        );
      case ActionDateField.STARTING_DATE:
        return (
          <FormattedMessage id="startingDate" defaultMessage="Starting date"/>
        );
    }
  }

  private generateDateInput = (): JSX.Element => {
    return (
      <DateInput
        className={`date-input ${!this.props.isFieldValid && 'error'}`}
        name={this.props.dateField}
        placeholder="Date"
        startMode="month"
        popupPosition="bottom left"
        iconPosition="left"
        dateFormat={Utils.getDateFormat()}
        value={this.props.date ? Utils.displayDate(this.props.date) : ''}
        onChange={(event: any, { name, value }: any) => { this.props.handleDateChange!({ name, value }, event); }}
        minDate={this.props.minDate ? Utils.displayDate(this.props.minDate) : ''}
      />
    );
  };

  private displayDate = () => {
    return (<span>{Utils.showDateOrNone(this.props.date)}</span>);
  }

  render() {
    if (this.props.viewOnly && Utils.isOnViewMode(this.props.mode)) {
      return (
        <div className="date-container">
            {this.getDateTitle()}:&nbsp;
            {this.displayDate()}
        </div>
      );
    }
    if (!this.props.viewOnly) {
      return (
        <div className="date-element">
        <span className="grey-title date-title">
          {this.getDateTitle()}
        </span>
          {Utils.isOnViewMode(this.props.mode) ? this.displayDate() : this.generateDateInput()}
        </div>
      );
    }
    return null;
  }
}
export default DateField;
