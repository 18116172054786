import './piHistoryExternal.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Icon, Label } from 'semantic-ui-react';

import Utils from 'src/utils/utils';
import TypeActivity from '../../../constants/typeActivity';
import History from '../../../models/history';
import PerformanceInitiative from '../../../models/performanceInitiative';
import Breadcrumb from '../breadcrumb/breadcrumb';
import ObjectivesContainer from '../containers/objectives';
import TextFieldsContainer from '../containers/textfields';
import HistoryAPI from '../../../api/history.api';
import HistoryExternalError, { HistoryExternalErrors } from '../containers/historyExternalError';
import Action from '../../../models/action';
import CustomScrollBars from '../../common/customScrollBars/customScrollBars';
import { PiErrors } from '../../../constants/errors/performance-initiative.error';

interface IRouteProps {
  piId: string;
  historyId: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
}

interface IStates {
  pi: PerformanceInitiative | undefined | null;
  headerColor: string;
  histories: History[] | undefined;
  isExternal: boolean;
  isPublicView: boolean;
  isLoading: boolean;
  historyExternalError: HistoryExternalErrors | undefined;
}

class PiHistoryExternal extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);

    const isExternal = this.props.match.url.toString().search('external') !== -1;
    this.state = {
      isExternal,
      pi: undefined,
      headerColor: '',
      histories: undefined,
      isPublicView: !isExternal,
      isLoading: true,
      historyExternalError: undefined,
    };
  }

  public componentWillMount() {
    if (this.state.isExternal) {
      this.setExternalView();
    } else {
      this.setHistoryList();
    }
  }

  public componentDidUpdate(prevProps: Readonly<RouteComponentProps<IRouteProps>>, prevState: Readonly<IStates>) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.setState(
        { isLoading: true },
        () => {
          if (this.state.isExternal) {
            this.setExternalView();
          } else {
            if (+prevProps.match.params.piId !== +this.props.match.params.piId) {
              this.setHistoryList();
            } else {
              this.isHistoryValid();
            }
          }
        },
      );
    }
  }

  private setHistoryList() {
    HistoryAPI.getHistories('performance-initiatives', +this.props.match.params.piId)
      .then((histories) => {
        this.setState(
          { histories },
          () => {
            this.isHistoryValid();
          },
        );
      })
      .catch(err => this.setState({ pi: null, isLoading: false, historyExternalError: err.error }));
  }

  private setExternalView = () => {
    HistoryAPI.getPiExternal(+this.props.match.params.piId)
      .then((pi) => {
        this.setPerformanceInitiative(pi);
      })
      .catch(err => this.setState({ pi: null, isLoading: false, historyExternalError: err.error }));
  };

  private isHistoryValid = () => {
    if (this.state.histories
      && this.state.histories.length > 0
      && this.state.histories.some(el => el.id === +this.props.match.params.historyId)) {
      this.setHistory();
    } else {
      this.setState({ isLoading: false, historyExternalError: PiErrors.PI_WRONG_HISTORY });
    }
  };

  private setHistory() {
    HistoryAPI.getPiHistoryById(+this.props.match.params.piId, +this.props.match.params.historyId)
      .then((pi) => {
        this.setPerformanceInitiative(pi);
      })
      .catch(() => this.setState({ pi: null, isLoading: false }));
  }

  private setPerformanceInitiative = (pi: PerformanceInitiative) => {
    this.setState({ pi, headerColor: Utils.getPillarName(pi.businessChallenge.pillar, true), isLoading: false });
  };

  private getActionsList(actions: Action[]) {
    return actions.map((action: Action) => (
      <div key={Utils.formatActivityCode(action.id, TypeActivity.ACTION)} className="action">
        <div>
          {!this.state.isExternal && Utils.setBadge(action.isClosed ? 0 : action.status)}
          <span className="action-title">
            {Utils.formatActivityCode(action.code, TypeActivity.ACTION)} - {action.name}
            {action.isClosed && <Label><FormattedMessage id="actionClosed" defaultMessage="CLOSED" /></Label>}
            {action.isFavourite && <Icon name="star" className="favourite-icon"/>}
          </span>
          {Utils.getAssigneeList(action)}
        </div>
        <div className="action-progress">
          {!!action.targetDate &&
            <>
              <FormattedMessage id="targetDate" defaultMessage="Target date"/>:&nbsp;
              {Utils.displayFancyDate(action.targetDate)}
              <br />
            </>
          }

          {action.isClosed && !!action.completionDate &&
            <>
              <FormattedMessage id="dateOfCompletion" defaultMessage="Completion date"/>:&nbsp;
              {Utils.displayFancyDate(action.completionDate)}
              <br />
            </>
          }

          {(!!action.progress && action.progress !== '')
            ? <>
              <FormattedMessage id="at.progress" defaultMessage="Progress status" />:&nbsp;
              {Utils.getFormattedTextFromHTMLString(action.progress)}
            </>
            : <FormattedMessage id="at.noProgress" defaultMessage="No progress status" />
          }
        </div>
      </div>
    ));
  }

  private getBudget() {
    return this.state.pi && this.state.pi.budget
      && (this.state.isExternal || this.state.isPublicView ? !this.state.pi.isBudgetHidden : true)
      && (
        <div id="budget-container">
          <div className={`title ${this.state.headerColor}-title`}>
            <FormattedMessage id="budget" defaultMessage="Budget" />
          </div>
          <pre id="budget-content">
          {this.state.pi ? this.state.pi.budget : null}
        </pre>
        </div>
      );
  }

  private getStatus() {
    return (this.state.isExternal
      ? <FormattedMessage id="status" defaultMessage="Status" />
      : (
        <div>
          <span id="element-status">
            {this.state.pi && Utils.setBadge(this.state.pi.status)}
          </span>
          <FormattedMessage id="status" defaultMessage="Status" />&nbsp;-&nbsp;
          {this.state.pi && Utils.getStatusName(this.state.pi.status)}
        </div>
      ));
  }

  private selectHistory = (historyId: number) => {
    this.props.history.push(`/pi-history/${this.props.match.params.piId}/${historyId}`);
  };

  private togglePublicView = (isPublicView: boolean) => {
    this.setState({ isPublicView });
  };

  private goToExternal = (activityId: number, route: string) => {
    this.props.history.push(`/${route}/${activityId}`);
  };

  public render() {
    if (this.state.pi) {
      const currentHistoryId = this.props.match.params.historyId
        ? +this.props.match.params.historyId
        : undefined;

      const actions = Utils.sortArrayByKey(this.state.pi.actions, 'code')
        .filter(at => this.state.isPublicView ? !at.isHidden : true);

      return (
        <div id="history-container">
          <Breadcrumb
            data={this.state.pi}
            typeActivity={TypeActivity.PERFORMANCE_INITIATIVE}
            histories={!!this.state.histories ? this.state.histories : []}
            isExternal={this.state.isExternal}
            currentHistoryId={currentHistoryId}
            isPublicView={this.state.isExternal ? undefined : this.state.isPublicView}
            handleClick={this.selectHistory}
            togglePublicView={this.togglePublicView}
            goToExternal={this.goToExternal}
          />
          {this.state.isLoading
            ? Utils.loader()
            : <div id="history-content-container">
              <div id="pi-element-history-container">
                <CustomScrollBars id="pi-history-scrollbars">
                  <div id="content-container">
                    <div id="element-history-header">
                      <div id="icon">
                        {Utils.getPillar(this.state.pi.businessChallenge.pillar)}
                      </div>
                      <div id="header-content">
                        <div id="header-top">
                          <div id="top-center">
                            <div id="plan-title">
                              {this.state.pi.businessChallenge.performancePlan.name}
                            </div>
                            <div id="bc-title">
                              {Utils.formatActivityName(this.state.pi.businessChallenge.code,
                                                        this.state.pi.businessChallenge.name,
                                                        TypeActivity.BUSINESS_CHALLENGE)}
                            </div>
                            <div id="pi-title">
                              {Utils.formatActivityName(this.state.pi.code, this.state.pi.name,
                                                        TypeActivity.PERFORMANCE_INITIATIVE)}
                              {this.state.pi.isClosed &&
                                <div id="pi-closed-tag" className="ui label closed-label margin-left">CLOSED</div>
                              }
                            </div>
                          </div>
                          <div id="top-right">
                            <div id="publication">
                            <span className="date">
                              <FormattedMessage id="publicationOf" defaultMessage="Publication of">
                                {msg => `${msg} ${Utils.showDateIfExists(this.state.pi!!.publishedDate)}`}
                              </FormattedMessage>
                            </span>
                            </div>
                            <div id="leader">
                              {this.state.pi.assignedAccounts ? Utils.getUsersAvatars(this.state.pi) : null}
                            </div>
                            {this.state.pi.deputies && this.state.pi.deputies.length > 0
                              && <div id="deputy-title-pi">
                                {this.state.pi.deputies.length === 1
                                  ? <FormattedMessage id="deputy" defaultMessage="DEPUTY" />
                                  : <FormattedMessage id="deputies" defaultMessage="DEPUTIES" />
                                }
                              </div>
                            }
                            <div id="leader">
                              {this.state.pi.deputies ? Utils.getUsersAvatarsFromList(this.state.pi.deputies) : null}
                            </div>
                            <div id="keyword-content">
                              {this.state.pi.keywords && this.state.pi.keywords.id !== 1 && this.state.pi.keywords.name}
                            </div>
                          </div>
                        </div>
                        <div className={this.state.headerColor} />
                        <div className={`description-title ${this.state.headerColor}-title`}>
                          <FormattedMessage id="description" defaultMessage="Description" />
                        </div>
                        <div id="description-content">
                        {Utils.getFormattedTextFromHTMLString(this.state.pi.objectives)}
                      </div>
                      </div>
                    </div>
                    <div className={this.state.headerColor} />
                    <div id="content">
                      <div id="objectives-container">
                        <div className={`title ${this.state.headerColor}-title`}>
                          <FormattedMessage id="objectives" defaultMessage="Objectives" />
                        </div>
                        <ObjectivesContainer
                          element={this.state.pi}
                          isPublicView={this.state.isPublicView}
                          activityType={TypeActivity.PERFORMANCE_INITIATIVE}
                        />
                      </div>
                      <div id="status-container">
                        {!this.state.isPublicView
                          && <div className={`title ${this.state.headerColor}-title`}>
                            {this.getStatus()}
                          </div>
                        }
                        <TextFieldsContainer element={this.state.pi} isPublicView={this.state.isPublicView} />
                      </div>
                      <div id="actions-container">
                        <div className={`title ${this.state.headerColor}-title`}>
                          <FormattedMessage id="actions" defaultMessage="Actions" /> ({actions.length})
                        </div>
                        <div id="actions-list">
                          {this.getActionsList(actions)}
                        </div>
                      </div>
                      {this.getBudget()}
                    </div>
                  </div>
                </CustomScrollBars>
              </div>
            </div>
          }
        </div>
      );
    }

    return (
      <HistoryExternalError
        isLoading={this.state.isLoading}
        historyExternalError={this.state.historyExternalError}
      />
    );
  }
}

export default PiHistoryExternal;
