import { EventEmitter } from 'events';
import ActionTypes from 'src/constants/actionTypes';
import FluxAction from 'src/actions/action';
import Dispatcher from '../dispatcher';
import { GUIDE_STATUS, GuideIds, GUIDES, IGuideType, VIEWS } from '../constants/guideConstants';

class GuideStore extends EventEmitter {

  private currentGuide: IGuideType;
  private guideStatus: GUIDE_STATUS = GUIDE_STATUS.PENDING;
  private currentView: VIEWS | undefined;

  constructor() {
    super();
    Dispatcher.register(this.registerToActions.bind(this));
  }

  public getCurrentGuide(): IGuideType {
    return this.currentGuide;
  }

  private setCurrentGuide(guideId: GuideIds) {
    const guide = GUIDES.find(g => g.id === guideId);
    if (guide) {
      this.currentGuide = guide;
      this.emit(ActionTypes.CURRENT_GUIDE_SET.toString());
    }
  }

  public getGuideStatus(): GUIDE_STATUS {
    return this.guideStatus;
  }

  private setGuideStatus(status: GUIDE_STATUS) {
    this.guideStatus = status;
    this.emit(ActionTypes.GUIDE_STATUS_UPDATED.toString(), status);
  }

  public getCurrentView(): VIEWS | undefined {
    return this.currentView;
  }

  public shouldRunGuide(view: VIEWS): boolean {
    return this.currentView === undefined || this.currentView !== view;
  }

  private setCurrentView(view: VIEWS) {
    this.currentView = view;
    this.emit(ActionTypes.CURRENT_VIEW_UPDATED.toString(), view);
  }

  public resetCurrentView() {
    this.currentView = undefined;
  }

   /**
   * Performs a function according to an action
   * @param action: Action
   * @return void
   */
  private registerToActions(action: FluxAction) {
    switch (action.actionType) {
      case ActionTypes.CURRENT_GUIDE_SET:
        this.setCurrentGuide(action.guideId);
        break;
      case ActionTypes.GUIDE_STATUS_UPDATED:
        this.setGuideStatus(action.status);
        break;
      case ActionTypes.CURRENT_VIEW_UPDATED:
        this.setCurrentView(action.view);
        break;
    }
  }
}

export default new GuideStore();
