import './userSelection.scss';

import * as React from 'react';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { Dropdown, DropdownItemProps, Icon, Popup } from 'semantic-ui-react';
import removeAccent from 'remove-accents';

import User from 'src/models/user';
import Utils from 'src/utils/utils';
import UserAPI from '../../../../api/user.api';

interface IProps extends InjectedIntlProps {
  defaultValue: User[];
  onChange: (users: User[]) => void;
  ppReferentSelection?: boolean;
}

interface IState {
  allUsers: User[];
  searchQuery: string;
  isSearchLoading: boolean;
  isDropdownOpen: boolean;
}

class UserSelection extends React.Component<IProps, IState> {
  private timeout: NodeJS.Timeout | null = null;
  constructor(props: IProps) {
    super(props);
    this.state = {
      allUsers: [],
      searchQuery: '',
      isSearchLoading: false,
      isDropdownOpen: false,
    };
  }

  public componentDidMount(): void {
    this.setState({ allUsers: Utils.sortArrayByKey(this.props.defaultValue, 'lastName') });
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({ allUsers: Utils.sortArrayByKey(this.props.defaultValue, 'lastName') });
    }
  }

  private static getUniqueUsers(users: User[]) {
    const knownAccounts: string[] = [];
    return users.filter((user) => {
      if (knownAccounts.find(email => user.email === email)) {
        return false;
      }
      knownAccounts.push(user.email);
      return true;
    });
  }

  private getUsers = (search: string) => {
    this.setState(
      { isSearchLoading: true },
      () => {
        const params = {
          search,
          isPpReferentSelection: this.isPpReferentSelection(),
        };

        UserAPI.getUsersLimited(params).then((users: User[]) => {
          this.setState({
            allUsers: UserSelection.getUniqueUsers([...this.props.defaultValue, ...users]),
            isSearchLoading: false,
          });
        });
      },
    );
  };

  private generateFormattedUserList() {
    return this.state.allUsers.map((user) => {
      const firstName = user.firstName ? user.firstName : '----';
      const lastName = user.lastName ? user.lastName.toUpperCase() : '----';

      return {
        key: user.email,
        value: user.email,
        content: user.isInvited && (!this.state.searchQuery.includes(' '))
          ? <div className="sso-user">{user.email}</div>
          : <div>{firstName} {lastName}</div>,
        text: user.isInvited && (!this.state.searchQuery.includes(' '))
          ? `${user.email}`
          : `${firstName} ${lastName}`,
      };
    });
  }

  public onChange(value: string[]) {
    this.setState({ searchQuery: '' });
    this.props.onChange(
      UserSelection.getUniqueUsers(this.state.allUsers.filter(user => value.find(email => user.email === email))),
    );
  }

  public onChangeUnique(value: string) {
    this.setState({ searchQuery: '' });
    this.props.onChange(
      UserSelection.getUniqueUsers(this.state.allUsers.filter(user => value === user.email)),
    );
  }

  public handleSearchChange(e: any, data: any) {
    if (data.searchQuery.length >= 2) {
      if (!!this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }

      this.setState(
        { isSearchLoading: true },
        () => {
          this.timeout = setTimeout(
            () => {
              this.getUsers(data.searchQuery);
            },
            800,
          );
        });
    }

    this.setState({ searchQuery: data.searchQuery });
  }

  private setOnChangeMethod(value: any) {
    if (this.isPpReferentSelection()) {
      this.onChangeUnique(value);
    } else {
      this.onChange(value);
    }
  }

  private setDefaultValue() {
    if (!!this.props.defaultValue) {
      if (this.isPpReferentSelection()) {
        return this.props.defaultValue[0].email;
      }
      return this.props.defaultValue.map(acc => acc.email);
    }

    return [];
  }

  // Override Semantic's default search method
  private search = (options: DropdownItemProps[], value: string) => {
    const formattedValue = removeAccent(value).toLowerCase();
    return options.filter(el => removeAccent(el.text as string).toLowerCase().indexOf(formattedValue) !== -1);
  };

  private isPpReferentSelection = () => {
    return !!this.props.ppReferentSelection && this.props.ppReferentSelection;
  }

  private setDropdownOpen = (isOpened) => {
    this.setState({ isDropdownOpen: isOpened });
  }

  public render() {
    const searchUserMessage =
      this.props.intl.formatMessage({ id: 'searchUserMessage', defaultMessage: 'Search users...' });
    const noUserFoundMessage = this.props.intl.formatMessage({ id: 'noUserFound', defaultMessage: 'No user found' });

    return (
      <div className="user-modification-container">
        <Dropdown
          className="user-dropdown"
          placeholder={searchUserMessage}
          fluid={true}
          selection={true}
          search={this.search}
          noResultsMessage={noUserFoundMessage}
          loading={this.state.isSearchLoading}
          searchQuery={this.state.searchQuery}
          onSearchChange={(e, data) => this.handleSearchChange(e, data)}
          multiple={!this.isPpReferentSelection()}
          onChange={(e: any, { value }: any) => this.setOnChangeMethod(value)}
          value={this.setDefaultValue()}
          options={this.generateFormattedUserList()}
          onOpen={() => this.setDropdownOpen(true)}
          onClose={() => this.setDropdownOpen(false)}
        />
        <div className={`search-info-container ${this.state.isDropdownOpen && 'dropdown-open'}`}>
          <Popup
            size="tiny"
            inverted={true}
            position="top center"
            trigger={<Icon name="info circle" />}
            content={<FormattedMessage id="userSearchPrecise" defaultMessage="Entering email addresses will return more precise results" />}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(UserSelection);
