import './activityLink.scss';

import * as React from 'react';
import { Button, Icon, Modal, Step } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import TypeActivity from '../../constants/typeActivity';
import ActivityLinkStep1 from './activityLinkStep1';
import ActivityFilterSelection from './activityFilterSelection';
import ActivityPpSelection from './activityPpSelection';
import ActivityBcSelection from './activityBcSelection';
import PerformancePlanAPI from '../../api/performancePlan.api';
import PerformancePlan from '../../models/performancePlan';
import BusinessChallenge from '../../models/businessChallenge';
import BusinessChallengeAPI from '../../api/businessChallenge.api';
import ActivityLinkPpFilters from '../../models/activityLink';
import { IBusinessLocationsValues } from '../common/form/ppGbuGeo/ppGbuGeo';
import NotificationActions from '../../actions/notification-actions';
import { ToastType } from '../common/toast/toast';
import PerformanceInitiative from '../../models/performanceInitiative';
import BusinessChallengeError from '../../constants/errors/business-challenge.error';
import PerformanceInitiativeError from '../../constants/errors/performance-initiative.error';
import ActivitiesActions from '../../actions/activities.action';
import BusinessChallengeActions from '../../actions/businessChallenge.action';
import PerformanceInitiativeActions from '../../actions/performanceInitiative.action';
import ILinkRequestRes from '../../constants/activityLink';
import Utils from '../../utils/utils';

interface IProps {
  data: BusinessChallenge | PerformanceInitiative;
  parentType: TypeActivity;
  close(): void;
}

interface IStates {
  steps: { title: JSX.Element, desc: JSX.Element }[];
  currentStep: number;
  selectedGeoGbuValues: IBusinessLocationsValues;
  selectedYear: string;
  performancePlans?: PerformancePlan[];
  selectedPp: number;
  businessChallenges?: BusinessChallenge[];
  selectedBc: number;
  isSubmitLoading: boolean;
  isNextDisabled: boolean;
}

export default class ActivityLink extends React.Component<IProps, IStates> {
  private readonly NONE_VALUE = 1;

  public constructor(props: IProps) {
    super(props);

    const steps = [
      {
        title: <FormattedMessage id="link.introductionStep.title" defaultMessage="Explanation" />,
        desc: <FormattedMessage id="link.introductionStep.description" defaultMessage="Link feature explanation" />,
      },
      {
        title: <FormattedMessage id="filters" defaultMessage="Filters" />,
        desc: <FormattedMessage id="link.ppFiltersDesc" defaultMessage="Performance Plan Filters" />,
      },
      {
        title: <FormattedMessage id="performancePlans" defaultMessage="Performance Plans" />,
        desc: <FormattedMessage id="link.ppSelectionDesc" defaultMessage="Performance Plan Selection" />,
      },
    ];

    if (Utils.isActivityBc(this.props.parentType)) {
      steps.push({
        title: <FormattedMessage id="businessChallenges" defaultMessage="Business Challenges" />,
        desc: <FormattedMessage id="link.bcSelectionDesc" defaultMessage="Business Challenge Selection" />,
      });
    }

    let selectedYear;
    switch (this.props.parentType) {
      case TypeActivity.PERFORMANCE_PLAN:
        selectedYear = (this.props.data as BusinessChallenge).performancePlan.year;
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        selectedYear = (this.props.data as PerformanceInitiative).businessChallenge.performancePlan.year;
        break;
    }

    this.state = {
      selectedYear,
      steps,
      currentStep: 0,
      selectedGeoGbuValues: {
        gbuId: this.NONE_VALUE,
        businessLineId: this.NONE_VALUE,
        segmentId: this.NONE_VALUE,
        geographyId: this.NONE_VALUE,
        countryId: this.NONE_VALUE,
        subcountryId: this.NONE_VALUE,
      },
      selectedPp: 0,
      selectedBc: 0,
      isSubmitLoading: false,
      isNextDisabled: false,
    };
  }

  /**
   * Actions triggered on the Previous button click
   */
  public onPreviousClick = () => {
    if (this.state.currentStep > 0) {
      this.setState(state => ({ currentStep: state.currentStep - 1 }));
    }
  };

  /**
   * Actions triggered on the Next button click
   */
  public onNextClick = () => {
    if (this.state.currentStep < this.state.steps.length) {
      const previousStep = this.state.currentStep;
      this.setState(
        state => ({ currentStep: state.currentStep + 1 }),
        () => {
          if (previousStep === 1 && this.state.currentStep === 2) {
            this.getPpList();
          } else if (previousStep === 2 && this.state.currentStep === 3) {
            this.getBcList();
          }
        },
      );
    }
  };

  /**
   * Close the modal and reset the state
   */
  public handleClose = () => {
    this.props.close();
  };

  /**
   * Submit the form
   */
  public submit = () => {
    this.setState(
      { isSubmitLoading: true },
      () => {
        let promise: Promise<ILinkRequestRes> | undefined;
        let message;

        if (Utils.isActivityPp(this.props.parentType) && this.state.selectedPp !== 0) {
          promise = BusinessChallengeActions.emitRequestLink(this.props.data.id, this.state.selectedPp);
          message = (
            <FormattedMessage
              id="link.bcLinkedToPp"
              defaultMessage="This Business Challenge has been successfully linked to the Plan"
            />
          );
        } else if (Utils.isActivityBc(this.props.parentType) && this.state.selectedBc !== 0) {
          promise = PerformanceInitiativeActions.emitRequestLink(this.props.data.id, this.state.selectedBc);
          message = (
            <FormattedMessage
              id="link.piLinkedToBc"
              defaultMessage="This Performance Initiative has been successfully linked to the BC"
            />
          );
        } else {
          promise = Promise.reject();
        }

        if (promise !== undefined) {
          promise
            .then(async (res: ILinkRequestRes) => {
              let title = <FormattedMessage id="link.activityLinked" defaultMessage="Activity linked!" />;

              if (!res.isLinked) {
                title = <FormattedMessage id="linkRequest" defaultMessage="Link request" />;
                message =
                  <FormattedMessage id="linkRequestMessage" defaultMessage="Your link request has been sent!" />;
              }

              NotificationActions.toast(title, message, ToastType.SUCCESS);
              await ActivitiesActions.emitGetActivities();
              this.props.close();
            })
            .catch((err) => {
              switch (this.props.parentType) {
                case TypeActivity.PERFORMANCE_PLAN:
                  BusinessChallengeError.toastBcError(err.error);
                  break;
                case TypeActivity.BUSINESS_CHALLENGE:
                  PerformanceInitiativeError.toastPiError(err.error);
                  break;
              }
              this.setState({ isSubmitLoading: false });
            });
        }
      },
    );
  };

  //region Step 2 Methods
  private step2Update = (selectedGeoGbuValues?: IBusinessLocationsValues, selectedYear?: string) => {
    const state = { ...this.state };
    if (!!selectedGeoGbuValues) state.selectedGeoGbuValues = selectedGeoGbuValues;
    this.setState(state);
  };
  //endregion

  //region Step 3 Methods
  private getPpList = () => {
    this.setState(
      { isNextDisabled: true },
      () => {
        const ppId = Utils.isActivityPp(this.props.parentType)
          ? Utils.getPlanIdFromActivity(this.props.data, TypeActivity.BUSINESS_CHALLENGE)
          : Utils.getPlanIdFromActivity(this.props.data, TypeActivity.PERFORMANCE_INITIATIVE);

        PerformancePlanAPI
          .getPpsForActivityLink(
            ppId, new ActivityLinkPpFilters(this.state.selectedYear, this.state.selectedGeoGbuValues))
          .then((performancePlans) => {
            this.setState({
              performancePlans,
              selectedPp: performancePlans.length > 0 ? performancePlans[0].id : 0,
              isNextDisabled: false,
            });
          });
      },
    );
  };

  private selectPp = (selectedPp: number) => {
    this.setState({ selectedPp });
  };
  //endregion

  //region Step 4 Methods
  private getBcList = () => {
    this.setState(
      { isNextDisabled: true },
      () => {
        BusinessChallengeAPI
          .getBcsForActivityLink(this.state.selectedPp)
          .then((businessChallenges) => {
            this.setState({
              businessChallenges,
              selectedBc: businessChallenges.length > 0 ? businessChallenges[0].id : 0,
              isNextDisabled: false,
            });
          });
      },
    );
  };

  private selectBc = (selectedBc: number) => {
    this.setState({ selectedBc });
  };
  //endregion

  public render() {
    const nextButtonMessage = this.state.currentStep === 1
      ? <FormattedMessage id="search" defaultMessage="Search"/>
      : <FormattedMessage id="next" defaultMessage="Next"/>;

    return (
      <Modal
        open={true}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        className="link-modal-container"
      >
        <Modal.Content id="link-modal-content">
          <Modal.Header>
            <h1>
              {Utils.isActivityPp(this.props.parentType)
                ? <FormattedMessage id="link.bcToPp" defaultMessage="Link this Business Challenge to a Plan" />
                : <FormattedMessage id="link.piToBc" defaultMessage="Link this Performance Initiative to a BC" />
              }
            </h1>
          </Modal.Header>
          <div id="link-modal-content-container">
            <Step.Group id="progress" vertical={true}>
              {this.state.steps.map((step, index) =>
                <Step key={index} active={this.state.currentStep === index}>
                  <Step.Content>
                    <Step.Title>{step.title}</Step.Title>
                    <Step.Description>{step.desc}</Step.Description>
                  </Step.Content>
                </Step>,
              )}
            </Step.Group>
            <div id="link-modal-child">
              {this.state.currentStep === 0 && <ActivityLinkStep1 />}

              {this.state.currentStep === 1 &&
                <ActivityFilterSelection
                  selectedGeoGbuValues={this.state.selectedGeoGbuValues}
                  onChange={this.step2Update}
                />
              }

              {this.state.currentStep === 2 &&
                <ActivityPpSelection
                  performancePlans={this.state.performancePlans}
                  selectedPp={this.state.selectedPp}
                  selectPp={this.selectPp}
                />
              }

              {this.state.currentStep === 3 &&
                <ActivityBcSelection
                  businessChallenges={this.state.businessChallenges}
                  selectedBc={this.state.selectedBc}
                  selectBc={this.selectBc}
                />
              }
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button id="button-left" onClick={this.handleClose}>
            <FormattedMessage id="cancel" defaultMessage="Cancel" />
          </Button>

          <div>
            <Button
              className="previous"
              color="grey"
              disabled={this.state.currentStep === 0 || this.state.isSubmitLoading}
              onClick={this.onPreviousClick}
            >
              <Icon name="chevron left" />
              <FormattedMessage id="previous" defaultMessage="Previous" />
            </Button>
            {this.state.currentStep !== this.state.steps.length - 1
              ? <Button
                className="next"
                primary={true}
                onClick={this.onNextClick}
                disabled={this.state.isNextDisabled}
              >
                {nextButtonMessage}
                <Icon name="chevron right" />
              </Button>
              : <Button
                className="submit"
                primary={true}
                loading={this.state.isSubmitLoading}
                onClick={this.submit}
                disabled={this.state.isSubmitLoading}
              >
                <FormattedMessage id="confirm" defaultMessage="Confirm" />
                <Icon name="checkmark" />
              </Button>
            }
          </div>
        </Modal.Actions>
      </Modal>
    );
  }

}
