import { EventEmitter } from 'events';

import Dispatcher from '../dispatcher';
import ActionTypes from 'src/constants/actionTypes';
import Action from '../actions/action';

class ErrorStore extends EventEmitter {

  private error?: any;

  constructor() {
    super();
    Dispatcher.register(this.registerToActions.bind(this));
  }

  /**
   * Get Error
   * @return Error
   */
  public getError(): Error {
    return this.error;
  }
  /**
   *  Set Error
   * @param Error: Error
   * @return void
   */
  private setError(error: Error): void {
    this.error = error;
    this.emit(ActionTypes.ERROR_CHANGE.toString());
  }

  /**
   *  Delete Error
   * @return void
   */
  private deleteError(): void {
    this.error = undefined;
    this.emit(ActionTypes.ERROR_DELETED.toString());
  }
/**
   * Performs a function according to an action
   * @param action :Action
   * @return void
   */
  private registerToActions(action: Action) {
    switch (action.actionType) {
      case ActionTypes.ERROR_SET:
        this.setError(action.error);
        break;
      case ActionTypes.ERROR_REMOVE:
        this.deleteError();
        break;
    }
  }
}

export default new ErrorStore();
