import './containerExplore.scss';

import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';

import Organisation from './organisation/organisation';
import Community from './community/community';
import Topics from './topics/topics';

export enum ExploreTabs {
  ORGANISATION = 'organisation',
  COMMUNITY = 'community',
  TOPICS = 'topics',
}

class ContainerExplore extends React.Component<RouteComponentProps> {

  public constructor(props: RouteComponentProps) {
    super(props);
  }

  public render() {
    return (
      <div id="container-explore">
        <Switch>
          <Route
            path={`${this.props.match.path}/${ExploreTabs.ORGANISATION}`}
            component={Organisation}
          />
          <Route
            path={`${this.props.match.path}/${ExploreTabs.COMMUNITY}`}
            component={Community}
          />
          <Route
            path={`${this.props.match.path}/${ExploreTabs.TOPICS}`}
            component={Topics}
          />
          <Redirect to={`/explore/${ExploreTabs.ORGANISATION}`} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(ContainerExplore);
