import './userGuide.scss';

import * as React from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import NotificationActions from '../../../../actions/notification-actions';
import { ToastType } from '../../../common/toast/toast';
import UserGuideApi from '../../../../api/userGuide.api';
import { UserGuideError } from '../../../../constants/errors/user-guide.error';

interface IProps {
  onModalClose(): void;
}

interface IStates {
  fileName: string | JSX.Element;
  isFileSelected: boolean;
  isSubmitLoading: boolean;
}

class UserGuide extends React.Component<IProps, IStates> {
  private readonly fileInputRef: React.RefObject<HTMLInputElement>;

  public constructor(props: IProps) {
    super(props);
    this.state = {
      fileName: <FormattedMessage id="userGuide.noFileSelected" defaultMessage="Choose File"/>,
      isFileSelected: false,
      isSubmitLoading: false,
    };
    this.fileInputRef = React.createRef();
  }

  openFileInput() {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  }

  /**
   * Update the user guide
   */
  public onFormSubmit() {
    if (this.fileInputRef.current && this.fileInputRef.current.files) {
      if (this.fileInputRef.current.files[0]) {
        this.setState({ isSubmitLoading: true });
        const pdfDocument = new FormData();
        pdfDocument.append('userGuide', this.fileInputRef.current.files[0]);
        UserGuideApi.updateUserGuide(pdfDocument)
          .then(() => {
            NotificationActions.toast(
              <FormattedMessage id="saved" defaultMessage="Saved!" />,
              (
                <FormattedMessage
                  id="userGuide.savedSuccessfully"
                  defaultMessage="The user guide has been successfully saved"
                />
              ),
              ToastType.SUCCESS,
            );
            this.close();
            this.setState({ isSubmitLoading: false });
          })
          .catch((err) => {
            let message = (
              <FormattedMessage
                id="errorOccurredMessage"
                defaultMessage="An error occurred, please try again later"
              />
            );

            if (err.error) {
              switch (err.error) {
                case UserGuideError.FILE_TOO_LARGE:
                  message =
                    <FormattedMessage id="userGuide.tooLarge" defaultMessage="The file is too large (max: 8MB)" />;
                  break;
                case UserGuideError.NOT_A_PDF:
                  message = <FormattedMessage id="userGuide.notAPdf" defaultMessage="The file is not a valid PDF" />;
                  break;
                default:
                  break;
              }
            }

            NotificationActions.toast(
              <FormattedMessage id="errorOccurred" defaultMessage="Error" />,
              message,
              ToastType.ERROR,
            );

            this.close();
            this.setState({ isSubmitLoading: false });
          });
      }
    }
  }

  onFileChange = () => {
    if (this.fileInputRef.current && this.fileInputRef.current.files) {
      if (this.fileInputRef.current.files.length > 0) {
        this.setState({
          fileName: this.fileInputRef.current.files[0].name,
          isFileSelected: true,
        });
      } else {
        this.setState({
          fileName: <FormattedMessage id="userGuide.noFileSelected" defaultMessage="Choose File"/>,
          isFileSelected: false,
        });
      }
    }
  }

  /**
   * Close the modal and reset the state
   */
  private close = () => {
    this.props.onModalClose();
  };

  public render() {
    return (
      <Modal
        id="user-guide-modal"
        open={true}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={this.close}
        className="creation-modal-container"
      >
        <Modal.Content id="modal-content">
          <Modal.Header>
            <div className="title">
              <FormattedMessage id="userGuide.importNewGuide" defaultMessage="Import user guide"/>
            </div>
          </Modal.Header>

          <Button
            content={this.state.fileName}
            labelPosition="left"
            icon="file"
            onClick={() => this.openFileInput()}
          />
          <input
            ref={this.fileInputRef}
            type="file"
            accept=".pdf"
            hidden={true}
            onChange={this.onFileChange}
          />

        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.close}>
            <FormattedMessage id="cancel" defaultMessage="Cancel"/>
          </Button>
          <Button
            onClick={() => this.onFormSubmit()}
            disabled={!this.state.isFileSelected}
            loading={this.state.isSubmitLoading}
          >
            <FormattedMessage id="userGuide.importGuide" defaultMessage="Import Guide"/>&nbsp;
            <Icon name="checkmark"/>
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default UserGuide;
