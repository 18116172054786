import { Icon } from 'semantic-ui-react';
import React from 'react';

interface IProps {
  selectedYear: number;
  setSelectedYear: (year: number) => void;
}

const yearSelector = (props: IProps) => {

  const updateSelectedYear = (increase = true) => {
    const updatedYear = increase ? props.selectedYear + 1 : props.selectedYear - 1;
    props.setSelectedYear(updatedYear);
  };

  return (
    <div className="selector" key="yearSelector">
      <div className="pointer">
        <Icon
          name="angle left"
          size="large"
          onClick={() => updateSelectedYear(false)}
        />
      </div>
      <div id="yearSelected">{props.selectedYear}</div>
      <div className="pointer">
        <Icon name="angle right" size="large" onClick={updateSelectedYear}/>
      </div>
    </div>
  );
};
export default yearSelector;
