import * as React from 'react';

import Metric, { MetricValueGet, MostRecentMetricValueDto } from '../../../../../../models/metric';
import Chart from '../chartContainer/chartContainer';
import ModeTypes from '../../../../../../constants/modeTypes';
import TypeActivity, { MetricMilestone } from '../../../../../../constants/typeActivity';
import { Icon, Popup, Segment } from 'semantic-ui-react';
import ImportedLabel from '../../shared/importedLabel';
import ToggleHideElement from '../../../toggleHideElement/toggleHideElement';
import SegmentEditButtons from '../../../common/segmentEditButtons/segmentEditButtons';
import BusinessChallenge from 'src/models/businessChallenge';
import { SegmentType } from 'src/models/segmentsMode';
import Utils from 'src/utils/utils';
import MetricForm from '../form/metricForm';
import { ComparisonType } from 'src/constants/comparisonType';
import BusinessChallengeAPI from 'src/api/businessChallenge.api';
import NotificationActions from 'src/actions/notification-actions';
import { FormattedHTMLMessage } from 'react-intl';
import { ToastType } from 'src/components/common/toast/toast';
import PerformanceInitiativeAPI from 'src/api/performanceInitiative.api';
import LastUpdateHighlight
  from '../../../common/overviewPanel/panelContent/tabs/lastUpdatedInformations/lastUpdateHighlight';
import { FieldType } from '../../metricsContainer';
import MetricsUtils from 'src/utils/metricsUtils';
import ActivitiesStore from '../../../../../../stores/activities.store';
import { MAX_DATE_LENGTH } from 'src/constants/date';
import Img from 'react-image';
import ImageModal from '../chartContainer/chart/imageModal';

interface IProps {
  index: number;
  metric: Metric;
  data: BusinessChallenge | undefined;
  typeActivity: TypeActivity;
  forActivityCreation: boolean;
  setDeleteModal: (isOpen: boolean, metric: Metric, index: number) => void;
  activityId: number;
  onUpdate: (metric: Metric) => void;
  reloadMetrics: () => void;
  isNew?: boolean;
  deleteNewMetric: (index: number) => void;
  canEdit: boolean;
  scrollToMetric: (metricId: number) => void;
  metricsCount: number;
}

interface IStates {
  mode: ModeTypes;
  form: Metric;
  isFormValid: boolean;
  defaultMostRecentValues: MostRecentMetricValueDto | null;
  image: string;
  newImage: File | null;
  hasDeletedImage: boolean;
  hasAnEllipsis: boolean;
  isImageOpened: boolean;
}
export default class MetricSegment extends React.Component<IProps, IStates> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      mode: ModeTypes.MODE_VIEW,
      form: {
        id: 0,
        name: '',
        unit: '',
        code: 0,
        comparisonType: ComparisonType.NONE,
        comparisonTolerance: 0,
        isHidden: false,
        mostRecentValues: {
          current: null,
          target: null,
          hasImage: false,
          updateDate: new Date(),
        },
        metricValues: [],
        isFromLinked: false,
      },
      isFormValid: false,
      defaultMostRecentValues: null,
      image: '',
      newImage: null,
      hasDeletedImage: false,
      hasAnEllipsis: false,
      isImageOpened: false,
    };
  }

  async componentDidMount() {
    const metricIdFromUrl = new URLSearchParams(window.location.search).get('metricId');
    if (metricIdFromUrl && parseInt(metricIdFromUrl, 10) === this.props.metric.id) {
      LastUpdateHighlight.highlightParam(this.props.metric.id);
    }
    if (this.props.metric.hasImage) {
      await this.getMetricImage();
    }
    const hasAnEllipsis = !!this.isEllipsisActive();
    if (this.state.hasAnEllipsis !== hasAnEllipsis) {
      this.setState({ hasAnEllipsis });
    }
  }

  async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>, snapshot?: any) {
    if (prevProps.metric.hasImage !== this.props.metric.hasImage) {
      if (this.props.metric.hasImage) {
        await this.getMetricImage();
      }
    }
  }

  static getDerivedStateFromProps(nextProps: IProps, prevState: IStates) {
    if (nextProps.metric.id !== prevState.form.id) {
      const defaultMostRecentValues = {
        updateDate: new Date(),
        current: null,
        target: nextProps.metric.mostRecentValues?.target ?? null,
      };
      return {
        defaultMostRecentValues,
        mode: nextProps.isNew ? ModeTypes.MODE_EDITION : prevState.mode,
        form: {
          id: nextProps.metric.id,
          name: nextProps.metric.name,
          unit: nextProps.metric.unit,
          comparisonType: nextProps.metric.comparisonType,
          comparisonTolerance: nextProps.metric.comparisonTolerance,
          isHidden: nextProps.metric.isHidden ?? false,
          mostRecentValues: defaultMostRecentValues,
          metricValues: nextProps.metric.metricValues,
          isFromLinked: nextProps.metric.isFromLinked,
        },
      };
    }
    return null;
  }

  private resetForm = () => {
    if (this.props.metric.isNew) {
      this.props.deleteNewMetric(this.props.index);
      return;
    }
    this.setState({
      form: {
        id: this.props.metric.id,
        name: this.props.metric.name,
        unit: this.props.metric.unit,
        code: this.props.metric.code,
        comparisonType: this.props.metric.comparisonType,
        comparisonTolerance: this.props.metric.comparisonTolerance,
        isHidden: this.props.metric.isHidden,
        mostRecentValues: {
          updateDate: new Date(),
          current: null,
          target: this.props.metric.mostRecentValues.target,
          hasImage: this.props.metric.mostRecentValues.hasImage,
        },
        metricValues: this.props.metric.metricValues,
        isFromLinked: this.props.metric.isFromLinked,
      },
    });
  }

  private getMetricImage = async (metricId?: number) => {
    if (!this.props.metric.id && !metricId) {
      return;
    }

    try {
      let image;
      // Get Pi metric image if it's a linked metric
      const type = Utils.isActivityBc(this.props.typeActivity) && this.props.metric.isFromLinked ?
      TypeActivity.PERFORMANCE_INITIATIVE : this.props.typeActivity;
      switch (type) {
        case TypeActivity.BUSINESS_CHALLENGE:
          image = await BusinessChallengeAPI
          .getBusinessChallengeMetricImage(metricId ?? this.props.metric.id as number);
          break;
        case TypeActivity.PERFORMANCE_INITIATIVE:
          image = await PerformanceInitiativeAPI
          .getPerformanceInitiativeMetricImage(metricId ?? this.props.metric.id as number);
          break;
      }
      this.setState({
        image: URL.createObjectURL(image),
      });
    } catch (error) {
      Utils.toastError();
    }
  };

  private changeMode = (mode: ModeTypes) => {
    this.setState(prevState => ({
      mode: mode ?? prevState.mode,
    }));
  }

  private getSegmentType = (): SegmentType | string => {
    switch (this.props.typeActivity) {
      case TypeActivity.BUSINESS_CHALLENGE:
        return `${SegmentType.BC_METRICS}-${this.props.metric.id}`;
      case TypeActivity.PERFORMANCE_INITIATIVE:
      default:
        return `${SegmentType.PI_METRICS}-${this.props.metric.id}`;
    }
  }

  private handleChangeMetric = (name: string, value: string | ComparisonType | boolean) => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));
  }

  private isFormValid = () => {
    const isMetricNameInvalid = Utils.isFieldOnError(this.state.form.name, true, FieldType.STRING);
    const mostRecentMetricValueWithCurrent = MetricsUtils.getLastSetCurrentValue(this.state.form);
    const isMetricValuesDateValid = !Utils.isDateFormatLanguageValid(this.state.form.mostRecentValues.updateDate);
    return !!mostRecentMetricValueWithCurrent.target && !isMetricNameInvalid && !isMetricValuesDateValid;
  }

  private handleChangeMetricMostRecentValues = (metricValue: MetricValueGet) => {
    const oldMetricValues = [...this.state.form.metricValues].map((m) => {
      m.target = metricValue.target;
      return m;
    });
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        mostRecentValues: metricValue,
        metricValues: oldMetricValues,
      },
    }));
  }

  private handleChangeMetricValues = (metricValue: MetricValueGet, mvIndex: number | null) => {
    const metricValuesCopy = [...this.state.form.metricValues];
    if (mvIndex !== null) {
      metricValuesCopy[mvIndex] = { ...metricValue };
    } else {
      metricValue.metricId = this.props.metric.id;
      metricValuesCopy.push(metricValue);
    }
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        metricValues: metricValuesCopy,
      },
    }));
  }

  private handleSetMetricValues = (metricValues: MetricValueGet[]) => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        metricValues,
      },
    }));
  }

  private toggleHiddenMetric = () => {
    this.handleChangeMetric('isHidden', !this.state.form.isHidden);
  }

  private removeMetricValue = (valueIndex: number) => {
    const metricValuesCopy: MetricValueGet[] = [...this.state.form.metricValues];
    metricValuesCopy.splice(valueIndex, 1);
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        metricValues: metricValuesCopy,
      },
    }));
  }

  private createMetric = async () => {
    if (this.props.metric.isFromLinked || !MetricsUtils.hasMetricChanged(this.props.metric, this.state.form)) {
      return;
    }
    try {
      let metricId;
      // remove metric values on creation request only to prevent duplicates
      const metricWithoutMetricValues = { ...this.state.form };
      metricWithoutMetricValues.metricValues = [];

      switch (this.props.typeActivity) {
        case TypeActivity.BUSINESS_CHALLENGE:
          const bcMetrics = await BusinessChallengeAPI
            .postBusinessChallengeMetrics(this.props.activityId, [metricWithoutMetricValues]);
          metricId = bcMetrics[0].id;
          break;
        case TypeActivity.PERFORMANCE_INITIATIVE:
          const piMetrics = await PerformanceInitiativeAPI
            .postPerformanceInitiativeMetrics(this.props.activityId, [metricWithoutMetricValues]);
          metricId = piMetrics[0].id;
          break;
      }
      await this.checkValuesChanges(metricId);
      this.props.onUpdate(this.state.form);
      NotificationActions.toast(
        <FormattedHTMLMessage id="saved" defaultMessage="Saved!" />,
        (
          <FormattedHTMLMessage
            id="metrics.toast.updatedSuccess"
            defaultMessage="The metric has been updated successfully"
          />
        ),
        ToastType.SUCCESS,
      );
      if (await this.uploadImage(metricId)) {
        await this.getMetricImage(metricId);
        this.props.reloadMetrics();
      }
      this.props.scrollToMetric(metricId);
    } catch (error) {
      Utils.toastError();
    }
  }

  private updateMetric = async () => {
    await this.checkValuesChanges();
    let imageUploadedOrDeleted;
    if (this.state.hasDeletedImage) {
      await this.deleteImage();
      imageUploadedOrDeleted = true;
    } else {
      imageUploadedOrDeleted = await this.uploadImage();
    }
    if (this.props.metric.isFromLinked || !MetricsUtils.hasMetricChanged(this.props.metric, this.state.form)) {
      if (imageUploadedOrDeleted) {
        this.props.reloadMetrics();
        await this.getMetricImage();
        this.successCallback();
      }
      return;
    }
    try {
      switch (this.props.typeActivity) {
        case TypeActivity.BUSINESS_CHALLENGE:
          await BusinessChallengeAPI.putBusinessChallengeMetrics(this.props.activityId, [this.state.form]);
          break;
        case TypeActivity.PERFORMANCE_INITIATIVE:
          await PerformanceInitiativeAPI.putPerformanceInitiativeMetrics(this.props.activityId, [this.state.form]);
          break;
      }
      this.successCallback();
      if (imageUploadedOrDeleted) {
        this.props.reloadMetrics();
        await this.getMetricImage();
      }
    } catch (error) {
      Utils.toastError();
    }
  }

  private successCallback = () => {
    this.props.onUpdate(this.state.form);
    NotificationActions.toast(
      <FormattedHTMLMessage id="saved" defaultMessage="Saved!" />,
      (
        <FormattedHTMLMessage
          id="metrics.toast.updatedSuccess"
          defaultMessage="The metric has been updated successfully"
        />
      ),
      ToastType.SUCCESS,
    );
  }

  private checkValuesChanges = async (metricId?: number) => {
    let concatenatedValues = [...this.state.form.metricValues];
    if (this.state.defaultMostRecentValues &&
      !MetricsUtils.isSameMostRecentValues(this.state.defaultMostRecentValues, this.state.form.mostRecentValues)) {
      const newMostRecentValue = { ...this.state.form.mostRecentValues as MetricValueGet };
      newMostRecentValue.metricId = metricId ?? this.props.metric.id;
      concatenatedValues = this.state.form.metricValues.concat(newMostRecentValue);
    }

    if (metricId) {
      for (const metricValue of this.state.form.metricValues) {
        if (!metricValue.metricId) {
          metricValue.metricId = metricId;
        }
      }
    }

    for (let i = 0; i < concatenatedValues.length; i += 1) {
      const metricValue = concatenatedValues[i];
      if (metricValue.updateDate.toString().length <= MAX_DATE_LENGTH) {
        concatenatedValues[i] = Utils.parseDateStringToDate(metricValue, 'updateDate');
      }
    }

    const metricsValuesChanges = Utils.filterChanges(this.props.metric.metricValues, concatenatedValues);
    switch (this.props.typeActivity) {
      case TypeActivity.BUSINESS_CHALLENGE:
        if (metricsValuesChanges.create.length !== 0) {
          const newMetricValue = await BusinessChallengeAPI
            .postBusinessChallengeMetricValues(this.props.activityId, metricsValuesChanges.create);
          this.reloadMetrics(newMetricValue);
        }
        if (metricsValuesChanges.edit.length !== 0) {
          await BusinessChallengeAPI.putBusinessChallengeMetricValues(this.props.activityId, metricsValuesChanges.edit);
          this.props.reloadMetrics();
        }
        if (metricsValuesChanges.remove.length !== 0) {
          await BusinessChallengeAPI.deleteBusinessChallengeMetricValues(
            this.props.activityId, metricsValuesChanges.remove.map(metric => metric.id));
          this.props.reloadMetrics();
        }
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        if (metricsValuesChanges.create.length !== 0) {
          const newMetricValue = await PerformanceInitiativeAPI
            .postPerformanceInitiativeMetricValues(this.props.activityId, metricsValuesChanges.create);
          this.reloadMetrics(newMetricValue);
        }
        if (metricsValuesChanges.edit.length !== 0) {
          await PerformanceInitiativeAPI
            .putPerformanceInitiativeMetricValues(this.props.activityId, metricsValuesChanges.edit);
          this.props.reloadMetrics();
        }
        if (metricsValuesChanges.remove.length !== 0) {
          await PerformanceInitiativeAPI.deletePerformanceInitiativeMetricValues(
            this.props.activityId, metricsValuesChanges.remove.map(metric => metric.id));
          this.props.reloadMetrics();
        }
        break;
    }
  }

  private reloadMetrics = (newMetricValue?: MetricValueGet[]) => {
    let newMetricValues = [...this.state.form.metricValues];
    if (newMetricValue && newMetricValue.length > 0 &&
      !MetricsUtils.isSameMostRecentValues(this.props.metric.mostRecentValues, this.state.form.mostRecentValues)) {
      newMetricValues = [...newMetricValues, newMetricValue[0]];
    }
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        metricValues: newMetricValues,
        mostRecentValues: {
          updateDate: new Date(),
          current: null,
          target: this.props.metric.mostRecentValues.target,
          hasImage: this.props.metric.mostRecentValues.hasImage,
        },
      },
    }));
    this.props.reloadMetrics();
  }

  private uploadImage = (metricId?: number) => {
    return new Promise(async (resolve, reject) => {
      if (!this.state.newImage) {
        resolve(null);
      }

      try {
        const formData = new FormData();
        if (this.state.newImage) {
          formData.append('image', this.state.newImage);
        }
        let promise;
        switch (this.props.typeActivity) {
          case TypeActivity.BUSINESS_CHALLENGE:
            promise = BusinessChallengeAPI.setBusinessChallengeMetricImage;
            break;
          case TypeActivity.PERFORMANCE_INITIATIVE:
            promise = PerformanceInitiativeAPI.setPerformanceInitiativeMetricImage;
            break;
        }
        await promise(this.props.activityId, metricId ?? this.props.metric.id, formData);
        resolve(true);
      } catch (error) {
        Utils.toastError();
      }
    });
  }

  private deleteImage = async () => {
    try {
      let promise;
      switch (this.props.typeActivity) {
        case TypeActivity.BUSINESS_CHALLENGE:
          promise = BusinessChallengeAPI.deleteBusinessChallengeMetricImage;
          break;
        case TypeActivity.PERFORMANCE_INITIATIVE:
          promise = PerformanceInitiativeAPI.deletePerformanceInitiativeMetricImage;
          break;
      }
      await promise(this.props.activityId, this.props.metric.id);
    } catch (error) {
      Utils.toastError();
    }
  }

  private setImageToUpload = (newImage: File) => {
    this.setState({
      newImage,
      hasDeletedImage: false,
    });
  }

  private setDeletedImage = () => {
    this.setState({
      hasDeletedImage: true,
    });
  }

  // indicates if the metric title has an ellipsis
  private isEllipsisActive = () : boolean | null => {
    const metricTitle = document.getElementById(`me-title${this.props.metric.id}`);
    return (metricTitle && (metricTitle.offsetWidth < metricTitle.scrollWidth));
  }

  toggleViewChartModal = () => {
    this.setState(state => ({ isImageOpened: !state.isImageOpened }));
  };

  private getTitle = () => {
    const titleContent = (
      <h3
        id={`me-title${this.props.metric.id}`}
        className={`
        ${Utils.isOnEditMode(this.state.mode) ? 'editing' : ''}
        ${this.state.hasAnEllipsis ? 'has-ellipsis' : ''}
        `}
      >
        {this.props.metric.isNew &&
          <em><FormattedHTMLMessage id="metric.newMetric" defaultMessage="New metric" /></em>
        }
        <>{this.props.metric.name} {this.props.metric.unit && `(${this.props.metric.unit})`}</>
      </h3>
    );
    return this.state.hasAnEllipsis ?
      (
        <Popup
          inverted={false}
          content={
            <h3 className="metric-title-tooltip">
              {this.props.metric.isNew &&
                <em><FormattedHTMLMessage id="metric.newMetric" defaultMessage="New metric" /></em>
              }
              <>{this.props.metric.name} {this.props.metric.unit && `(${this.props.metric.unit})`}</>
            </h3>
          }
          size="tiny"
          position="top center"
          hoverable={true}
          trigger={titleContent}
        />
      )
      : titleContent;
  }

  render() {
    const segmentType = Utils.isActivityPi(ActivitiesStore.getTypeActivity())
      ? SegmentType.PI_METRICS : SegmentType.BC_METRICS;

    // tslint:disable-next-line:jsx-wrap-multiline
    const element = <Segment
        className={`metric-segment ${this.props.metricsCount === 1 ? 'alone' : ''}`}
        id={LastUpdateHighlight.getSegmentId(segmentType, this.props.metric.id)}
    >
      {this.props.canEdit &&
          <SegmentEditButtons
              mode={this.state.mode}
              segmentType={this.getSegmentType()}
              popupPosition={'right center'}
              deletable={!this.props.metric.isNew}
              onSave={this.props.metric.isNew ? this.createMetric : this.updateMetric}
              onDelete={() => this.props.setDeleteModal(true, this.props.metric, this.props.index)}
              onCancel={this.resetForm}
              changeMode={this.changeMode}
              isSaveDisabled={!this.isFormValid()}
          />
      }
      <div className="metrics-title">
        {this.getTitle()}
        {(Utils.isOnViewMode(this.state.mode) || !this.props.metric.isFromLinked) &&
            <ToggleHideElement
                isElementHidden={this.state.form.isHidden}
                viewOnly={Utils.isOnViewMode(this.state.mode)}
                updateElementState={this.toggleHiddenMetric}
            />
        }
        {this.props.metric.isFromLinked && this.props.data &&
            <ImportedLabel
                ppId={this.props.data.performancePlanId}
                piId={this.props.metric.performanceInitiative?.id}
                metricMilestone={this.props.metric}
                type={MetricMilestone.METRIC}
            />
        }
      </div>
      {Utils.isOnEditMode(this.state.mode) &&
          <MetricForm
              index={this.props.index}
              metric={this.props.metric}
              form={this.state.form}
              handleChangeMetric={this.handleChangeMetric}
              handleChangeMetricMostRecentValues={this.handleChangeMetricMostRecentValues}
              handleChangeMetricValues={this.handleChangeMetricValues}
              forActivityCreation={this.props.forActivityCreation}
              removeMetricValue={this.removeMetricValue}
              image={this.state.image}
              setImageToUpload={this.setImageToUpload}
              hasDeletedImage={this.state.hasDeletedImage}
              setDeletedImage={this.setDeletedImage}
              handleSetMetricValues={this.handleSetMetricValues}
              isNew={!!this.props.isNew}
          />}
      <div className="metrics-chart">
        {Utils.isOnViewMode(this.state.mode) &&
            <>
              {this.props.metric.hasImage &&
                  <Icon
                      name="expand arrows alternate"
                      onClick={this.toggleViewChartModal}
                  />
              }
            </>
        }
      </div>
      <div className="metrics-body">
        {Utils.isOnViewMode(this.state.mode) &&
            <>
              {this.props.metric.hasImage
                  ?
                  <Img
                      className="metrics-image"
                      draggable="false"
                      src={[this.state.image]}
                  />
                  :
                  <Chart
                      mode={this.state.mode}
                      metrics={this.props.metric}
                      metricsCount={this.props.metricsCount}
                  />
              }
              {this.state.isImageOpened &&
                  <ImageModal
                      metric={this.props.metric}
                      onModalClose={this.toggleViewChartModal}
                      metricsCount={this.props.metricsCount}
                      image={this.state.image}
                  />
              }
            </>
        }
      </div>
    </Segment>;
    return element;
  }
}
