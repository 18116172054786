import './topics.scss';

import * as React from 'react';
import {
  Dropdown,
  DropdownDivider,
  DropdownHeader,
  DropdownItem,
  DropdownItemProps,
  Form,
  Segment,
} from 'semantic-ui-react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import Scrollbars from 'react-custom-scrollbars';
import { RouteComponentProps } from 'react-router';

import PerformancePlanStore from 'src/stores/performancePlan.store';
import ActionTypes from 'src/constants/actionTypes';
import PerformancePlanActions from 'src/actions/performancePlan.action';
import BubbleGraph from './bubble-graph';
import Utils from 'src/utils/utils';
import DatavizAPI from '../../../../api/dataviz.api';
import BusinessChallengeAPI from '../../../../api/businessChallenge.api';
import BusinessChallenge from '../../../../models/businessChallenge';
import PerformanceInitiative from '../../../../models/performanceInitiative';
import PerformanceInitiativeAPI from '../../../../api/performanceInitiative.api';
import TypeActivity from '../../../../constants/typeActivity';
import User from '../../../../models/user';
import ActivitiesActions from '../../../../actions/activities.action';
import ActivitiesStore from '../../../../stores/activities.store';
import PublicationLabel from '../../../common/publicationLabel/publicationLabel';
import { PiTabs } from '../../../activitiesBoard/containerActivities/contentPI/contentPI';
import CustomScrollBars from '../../../common/customScrollBars/customScrollBars';

interface IStates {
  yearOptions: DropdownItemProps[] | undefined;
  gbuOptions: DropdownItemProps[];
  countryOptions: DropdownItemProps[];
  geographyOptions: DropdownItemProps[];
  subCountryOptions: DropdownItemProps[];
  params: TopicsParams;
  locationTextValue: string | undefined;
  bcList: BusinessChallenge[] | undefined;
  piList: PerformanceInitiative[] | undefined;
  selectedKeywordName: string;
  isDataEmpty: boolean;
}

export interface TopicsParams {
  year?: number;
  gbuId?: number;
  geographyId?: number;
  countryId?: number;
  subcountryId?: number;
}

export interface BcKeyword {
  id: number;
  name: string;
  shortname: string;
  type: string;
  children: PiKeyword[];
}

export interface PiKeyword {
  id: number;
  name: string;
  shortname: string;
  type: string;
  value: number;
}

enum NodeType {
  BCK = 'bck',
  PIK = 'pik',
}

enum LocationItemType {
  GEOGRAPHY,
  COUNTRY,
  SUBCOUNTRY,
}

class Topics extends React.Component<RouteComponentProps & InjectedIntlProps, IStates> {
  private isMount = false;
  private allOption = { key: 0, text: <FormattedMessage id="all" defaultMessage="All" />, value: 0 };
  private noPiKeyword = this.props.intl.formatMessage({
    id: 'noPIKeywords',
    defaultMessage: 'No PI keywords',
  });
  private scrollbarsRef = React.createRef<Scrollbars>();

  public constructor(props: RouteComponentProps & InjectedIntlProps) {
    super(props);

    this.state = {
      yearOptions: Topics.formatYears(PerformancePlanStore.getYears()),
      gbuOptions: [],
      countryOptions: [],
      geographyOptions: [],
      subCountryOptions: [],
      params: {},
      locationTextValue: undefined,
      bcList: undefined,
      piList: undefined,
      selectedKeywordName: '',
      isDataEmpty: false,
    };

    const message = <FormattedMessage id="noPIKeywords" defaultMessage="No PI keywords" />;
    if (!message) {
      return;
    }
  }

  //region LIFECYCLE METHODS
  public componentDidMount(): void {
    this.isMount = true;
    PerformancePlanStore.addListener(ActionTypes.PERFORMANCE_PLAN_ENTITIES_LOCATIONS_GET.toString(), this.setOptions);
    PerformancePlanStore.addListener(ActionTypes.PERFORMANCE_PLAN_YEARS_GET.toString(), this.getYears);

    PerformancePlanActions.emitGetPpEntitiesLocations();
    if (this.state.yearOptions === undefined) PerformancePlanActions.emitGetPerformancePlanYears();

    this.updateChart();
  }

  public componentWillUnmount(): void {
    this.isMount = false;
    PerformancePlanStore.removeListener(
      ActionTypes.PERFORMANCE_PLAN_ENTITIES_LOCATIONS_GET.toString(), this.setOptions);
    PerformancePlanStore.removeListener(ActionTypes.PERFORMANCE_PLAN_YEARS_GET.toString(), this.getYears);
  }

  private setOptions = () => {
    if (this.isMount) {
      this.setState({
        gbuOptions: [this.allOption, ...Utils.sortWithNoneValue(PerformancePlanStore.getBusinessLocations().gbus)],
        geographyOptions: [...Utils.sortWithNoneValue(PerformancePlanStore.getBusinessLocations().geographies)],
        countryOptions: [...Utils.sortWithNoneValue(PerformancePlanStore.getBusinessLocations().countries)],
        subCountryOptions: [...Utils.sortWithNoneValue(PerformancePlanStore.getBusinessLocations().subcountries)],
      });
    }
  };

  private getYears = () => {
    if (this.isMount) {
      this.setState({ yearOptions: Topics.formatYears(PerformancePlanStore.getYears()) });
    }
  };

  private static formatYears(years: string[] | undefined) {
    return !!years ? years.map(year => ({ key: year, text: year, value: year })) : undefined;
  }
  //endregion

  //region CHART METHODS
  private updateChart = () => {
    this.resetActivityList();

    DatavizAPI.getKeywords(this.state.params).then((rawData) => {
      const data: any = { name: 'keywords', children: [] };
      rawData.children.forEach((bcKeyword: BcKeyword) => {
        if (bcKeyword.id) {
          const bcKeywordElement: any = {
            id: bcKeyword.id,
            name: bcKeyword.name,
            shortname: bcKeyword.shortname,
            type: bcKeyword.type,
            children: [],
          };
          if (bcKeyword.children.every((child: any) => !child.id)) {
            bcKeywordElement.children = [{
              name: this.noPiKeyword,
              value: !!bcKeyword.children[0] ? bcKeyword.children[0].value : 1,
            }];
          } else {
            bcKeyword.children.forEach((piKeyword: any) => {
              if (piKeyword.id) {
                piKeyword['children'] = [{
                  name: piKeyword.name,
                  shortname: piKeyword.shortname,
                  value: piKeyword.value,
                }];
                bcKeywordElement.children.push(piKeyword);
              }
            });
          }

          data.children.push(bcKeywordElement);
        }
      });

      BubbleGraph(data, 'bubble-graph', this.onNodeClick);

      this.setState({ isDataEmpty: data.children.length === 0 });
    });
  };

  private onNodeClick = (element: any) => {
    if (element === null) {
      this.resetActivityList();
    } else {
      switch (element.data.type.toString()) {
        case NodeType.BCK:
          BusinessChallengeAPI.getBusinessChallengesByKeyword(element.data.id, this.state.params)
            .then((bcList: BusinessChallenge[]) => {
              let filteredBcList: BusinessChallenge[] = [...bcList];
              if (!!this.state.params.gbuId) {
                filteredBcList = bcList.filter(
                  (bc: BusinessChallenge) => bc.performancePlan.gbu.id === this.state.params.gbuId,
                );
              }
              if (!!this.state.params.geographyId || !!this.state.params.countryId || this.state.params.subcountryId) {
                filteredBcList = bcList.filter(
                  (bc: BusinessChallenge) =>
                    (bc.performancePlan.geography.id === this.state.params.geographyId) ||
                    (bc.performancePlan.country.id === this.state.params.countryId) ||
                    (bc.performancePlan.subcountry.id === this.state.params.subcountryId),
                );
              }
              this.setState({ bcList: filteredBcList, piList: undefined, selectedKeywordName: element.data.name });
            });
          break;
        case NodeType.PIK:
          PerformanceInitiativeAPI.getPerformanceInitiativesByKeyword(
            element.parent.data.id,
            element.data.id,
            this.state.params,
          ).then((piList: PerformanceInitiative[]) => {
            let filteredPiList: PerformanceInitiative[] = [...piList];
            if (!!this.state.params.gbuId) {
              filteredPiList = piList.filter(
                (pi: PerformanceInitiative) =>
                  pi.businessChallenge.performancePlan.gbu.id === this.state.params.gbuId,
              );
            }
            if (!!this.state.params.geographyId || !!this.state.params.countryId || this.state.params.subcountryId) {
              filteredPiList = piList.filter(
                (pi: PerformanceInitiative) =>
                  (pi.businessChallenge.performancePlan.geography.id === this.state.params.geographyId) ||
                  (pi.businessChallenge.performancePlan.country.id === this.state.params.countryId) ||
                  (pi.businessChallenge.performancePlan.subcountry.id === this.state.params.subcountryId),
              );
            }
            this.setState({ piList: filteredPiList, bcList: undefined, selectedKeywordName: element.data.name });
          });
          break;
      }
    }

    if (!!this.scrollbarsRef.current) {
      this.scrollbarsRef.current.scrollToTop();
    }
  };

  private onTitleClick = (type: TypeActivity, element: BusinessChallenge | PerformanceInitiative) => {
    if (ActivitiesStore.getActivities()) {
      this.redirectToElement(type, element);
    } else {
      ActivitiesActions.emitGetActivities().then(() => {
        this.redirectToElement(type, element);
      });
    }
  };
  //endregion

  //region ACTIVITY LIST METHODS
  private resetActivityList = () => {
    this.setState({ bcList: undefined, piList: undefined, selectedKeywordName: '' });
  };

  private redirectToElement = (type: TypeActivity, element: BusinessChallenge | PerformanceInitiative) => {
    switch (type) {
      case TypeActivity.BUSINESS_CHALLENGE:
        if (Utils.isExternalView(element.id, type)) {
          this.props.history.push(`/bc-external/${element.id}`);
        } else {
          this.props.history.push(
            `/activities-board/${element['performancePlan'].id}/business-challenge/${element.id}`,
          );
        }
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        if (Utils.isExternalView(element.id, type)) {
          this.props.history.push(`/pi-external/${element.id}`);
        } else {
          this.props.history.push(
            `/activities-board/${
              element['businessChallenge']['performancePlan'].id}/performance-initiative/${element.id}/${PiTabs.COCKPIT}`,
          );
        }
        break;
    }
  };

  private displayUserName = (account: User) => {
    let name = '';
    let styling = '';
    if (account.firstName && account.lastName) {
      name = `${account.firstName} ${account.lastName.toUpperCase()}`;
    } else {
      name = `${account.email}`;
      styling = 'italic-text';
    }
    return (<span key={`account${account.id}`} className={styling}>{name}</span>);
  }

  private renderElement = (element: BusinessChallenge | PerformanceInitiative, type: TypeActivity) => {
    const pp = Utils.isActivityBc(type)
      ? (element as BusinessChallenge).performancePlan
      : (element as PerformanceInitiative).businessChallenge.performancePlan;

    let location;
    if (!!pp.subcountry && pp.subcountry.id !== 1) {
      location = pp.subcountry.name;
    } else if (!!pp.country && pp.country.id !== 1) {
      location = pp.country.name;
    } else {
      location = pp.geography.name;
    }

    return (
      <div className="element" key={`element${element.id}`}>
        <div className="title" onClick={() => this.onTitleClick(type, element)}>
          {Utils.isActivityBc(type) ? 'BC' : 'PI'}
          {Utils.leadingZero(element.code)}: {element.name}
          <PublicationLabel publishedDate={element.publishedDate} />
        </div>
        <div className="assignees">
          {element.assignedAccounts && element.assignedAccounts.map((account: User) =>
            this.displayUserName(account),
          )}
        </div>
        <div className="plan-info">
          <span>
            <FormattedMessage id="location" defaultMessage="Location" />: {location} /&nbsp;
            <FormattedMessage id="gbu" defaultMessage="GBU" />: {pp.gbu.name} / {pp.year}
          </span>
        </div>
      </div>
    );
  };
  //endregion

  //region FORM METHODS
  private changeSelection = (value: any, key: string) => {
    const params = { ...this.state.params };
    params[key] = value;
    this.setState({ params }, () => this.updateChart());
  };

  private handleLocationItemClick = (itemType: LocationItemType, item: any) => {
    const params = { ...this.state.params };
    delete params.geographyId;
    delete params.countryId;
    delete params.subcountryId;

    switch (itemType) {
      case LocationItemType.SUBCOUNTRY:
        params.subcountryId = item.value;
        break;
      case LocationItemType.COUNTRY:
        params.countryId = item.value;
        break;
      case LocationItemType.GEOGRAPHY:
      default:
        params.geographyId = item.value;
        break;
    }
    this.setState({ params, locationTextValue: item.text }, this.updateChart);
  };

  private resetLocation = () => {
    const params = { ...this.state.params };
    delete params.geographyId;
    delete params.countryId;
    delete params.subcountryId;

    this.setState({ params, locationTextValue: undefined }, this.updateChart);
  };

  private generateDropdownItem(item: any, itemType: LocationItemType, currentId: number | undefined) {
    return (
      <DropdownItem
        onClick={() => this.handleLocationItemClick(itemType, item)}
        id={item.key}
        value={item.value}
        key={Utils.generateRandomKey()}
        selected={currentId === item.value}
      >
        {item.text}
      </DropdownItem>
    );
  }
  //endregion

  public render() {
    if (this.state.yearOptions === undefined) {
      return Utils.loader();
    }

    const geographies = this.state.geographyOptions.map(item =>
      this.generateDropdownItem(item, LocationItemType.GEOGRAPHY, this.state.params.geographyId));

    const countries = this.state.countryOptions.map(item =>
      this.generateDropdownItem(item, LocationItemType.COUNTRY, this.state.params.countryId));

    const subCountries = this.state.subCountryOptions.map(item =>
      this.generateDropdownItem(item, LocationItemType.SUBCOUNTRY, this.state.params.subcountryId));

    return (
      <div id="container-keywords-organisation">
        <Segment>
          <div id="filter-chart-container">
            <div id="filter-container">
              <h2><FormattedMessage id="topics" defaultMessage="Ambition Boost Topics" /></h2>
              <Form.Group id="filters" widths="equal">
                <FormattedMessage id="all" defaultMessage="All">{select =>
                  <div id="dropdown-filter">
                    <FormattedMessage id="year" defaultMessage="Year">{msg =>
                      <Form.Select
                        id="year"
                        fluid={true}
                        className="select-filter"
                        placeholder={select as string}
                        label={msg}
                        options={[
                          this.allOption,
                          ...this.state.yearOptions!,
                        ]}
                        value={!!this.state.params.year ? this.state.params.year : undefined}
                        onChange={(event, data) => this.changeSelection(data.value, 'year')}
                      />}
                    </FormattedMessage>
                    <FormattedMessage id="GBU" defaultMessage="GBU">{msg =>
                      <Form.Select
                        id="gbu"
                        fluid={true}
                        className="select-filter"
                        placeholder={select as string}
                        label={msg}
                        options={this.state.gbuOptions!}
                        value={!!this.state.params.gbuId ? this.state.params.gbuId : undefined}
                        onChange={(event, data) => this.changeSelection(data.value, 'gbuId')}
                      />}
                    </FormattedMessage>
                    <div className="dropdown-label-group">
                      <Form.Field>
                        <FormattedMessage id="location" defaultMessage="Location" />
                      </Form.Field>
                      <FormattedMessage id="all" defaultMessage="All">{select =>
                        <Dropdown
                          id="country-dropdown"
                          text={!!this.state.locationTextValue ? this.state.locationTextValue : (select as string)}
                          scrolling={true}
                          className="select-filter custom-dropdown"
                        >
                          <Dropdown.Menu>
                            <DropdownItem key="locationNone" onClick={this.resetLocation}>
                              <FormattedMessage id="all" defaultMessage="All">{msg => msg}</FormattedMessage>
                            </DropdownItem>
                            <DropdownHeader>
                              <FormattedMessage id="geography" defaultMessage="GEOGRAPHY" />
                            </DropdownHeader>
                            {geographies}
                            <DropdownDivider />
                            <DropdownHeader>
                              <FormattedMessage id="countries" defaultMessage="COUNTRY" />
                            </DropdownHeader>
                            {countries}
                            <DropdownDivider />
                            <DropdownHeader>
                              <FormattedMessage id="subcountries" defaultMessage="SUBCOUNTRY" />
                            </DropdownHeader>
                            {subCountries}
                          </Dropdown.Menu>
                        </Dropdown>}
                      </FormattedMessage>
                    </div>
                  </div>
                }</FormattedMessage>
              </Form.Group>
            </div>
            <div id="chart-container">
              <div id="bubble-graph" />
              {
                this.state.isDataEmpty
                && <FormattedMessage id="noActivityFound" defaultMessage="No activity was found for this filter" />
              }
            </div>
          </div>
          <div id="selected-topic-container">
            <h2>
              {this.state.bcList
                ? <span>
                  <FormattedMessage id="SelectedBcKeywords" defaultMessage="Selected BC keywords" />:&nbsp;
                  {this.state.selectedKeywordName}
                </span>
                : this.state.piList
                  ? <span>
                    <FormattedMessage id="SelectedPiKeywords" defaultMessage="Selected PI keywords" />:&nbsp;
                    {this.state.selectedKeywordName}
                  </span>
                  : <FormattedMessage id="selectedTopic" defaultMessage="Selected Topic" />
              }
            </h2>
            <div id="bc-pi-list">
              <CustomScrollBars customRef={this.scrollbarsRef}>
                {this.state.bcList === undefined && this.state.piList === undefined
                  ? null
                  : this.state.bcList
                    ? this.state.bcList.map((element: BusinessChallenge) => {
                      return this.renderElement(element, TypeActivity.BUSINESS_CHALLENGE);
                    })
                    : this.state.piList
                      ? this.state.piList.map((element: PerformanceInitiative) => {
                        return this.renderElement(element, TypeActivity.PERFORMANCE_INITIATIVE);
                      })
                      : Utils.loader()
                }
              </CustomScrollBars>
            </div>
          </div>
        </Segment>
      </div>
    );
  }
}

export default injectIntl(Topics);
