import './bcFunctions.scss';

import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';

import ModeTypes from 'src/constants/modeTypes';
import BusinessChallengeErrors from 'src/models/bc-errors';
import BusinessChallenge from 'src/models/businessChallenge';
import Keywords from 'src/models/keywords';

interface IProps extends InjectedIntlProps {
  data: BusinessChallenge;
  getElementDropdown: (
    functionsId: number, name: string, options: any, mode: ModeTypes, isValueNullable: boolean, isError: boolean,
  ) => JSX.Element;
  mode: ModeTypes;
  functions: Keywords[];
  dropdownEdit?: (name: string, value: any) => void;
  errors?: BusinessChallengeErrors;
}

class BcFunctions extends React.Component<IProps> {
  render() {
    const title = <FormattedMessage id="bcFunctions" defaultMessage="Functions" />;

    return (
      <>
        <div className="grey-title bc-function">
          <span>{title}</span>
        </div>
        <div>
          {this.props.functions
            ? this.props.getElementDropdown(
              this.props.data.functions ? this.props.data.functions.id : 1,
              'functions',
              this.props.functions,
              this.props.mode,
              true,
              false,
            )
            : <FormattedMessage id="none" defaultMessage="None" />
          }
        </div>
      </>
    );
  }
}

export default injectIntl(BcFunctions);
