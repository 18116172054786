import './accountConflict.scss';

import * as React from 'react';
import { Button, Icon, Modal, Popup } from 'semantic-ui-react';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';

import UserAPI from 'src/api/user.api';
import { IConflictResponse, IUserConflict } from 'src/constants/accountConflict';
import User from 'src/models/user';
import AuthStore from 'src/stores/auth.store';
import EmailList from './emailList';
import Utils from 'src/utils/utils';
import NotificationActions from 'src/actions/notification-actions';
import { ToastType } from 'src/components/common/toast/toast';
import AuthActions from 'src/actions/auth.actions';

interface IProps extends InjectedIntlProps {
  user: User;
  handleModalClose(): void;
  reloadUserList(): void;
}

interface IStates {
  conflictResponse: IConflictResponse | undefined;
  leftList: IUserConflict[];
  rightList: IUserConflict[];
  mainAccountId: number;
  isMergeLoading: boolean;
  isUnsetConflictLoading: boolean;
}

class AccountConflict extends React.Component<IProps, IStates> {

  public constructor(props: any) {
    super(props);
    this.state = {
      conflictResponse: undefined,
      leftList: [],
      rightList: [],
      mainAccountId: 0,
      isMergeLoading: false,
      isUnsetConflictLoading: false,
    };
  }

  public componentDidMount(): void {
    UserAPI.getAccountConflict(this.props.user.id).catch((conflict) => {
      this.setState(
        {
          conflictResponse: conflict,
          leftList: Utils.sortArrayByKey(conflict.users, 'email'),
        },
        () => this.moveItem(this.props.user.id),
      );
    });
  }

  /**
   * Close the modal and reset the state
   */
  private handleClose = () => {
    this.setState({ conflictResponse: undefined, isMergeLoading: false });
    this.props.handleModalClose();
  };

  private unsetConflict = () => {
    this.setState(
      { isUnsetConflictLoading: true },
      () => {
        UserAPI.unsetConflict(this.props.user.id)
          .then(() => {
            NotificationActions.toast(
              <FormattedMessage id="success" defaultMessage="Success" />,
              (
                <FormattedMessage
                  id="accountConflict.unsetConflictSuccessful"
                  defaultMessage="The conflicts on this account were successfully unset!"
                />
              ),
              ToastType.SUCCESS,
            );

            this.handleClose();
            this.props.reloadUserList();
          })
          .catch(() => Utils.toastError());
      },
    );
  };

  public moveItem = (userId: number) => {
    let leftList = [...this.state.leftList];
    let rightList = [...this.state.rightList];
    let mainAccountId = this.state.mainAccountId;

    // Check in left list if the user is in
    // If so, remove him from left list and add him to the right list
    const leftListItem = leftList.find(el => el.id === userId);
    if (!!leftListItem) {
      leftList.splice(leftList.findIndex(el => el.id === userId), 1);
      rightList.push(leftListItem);

      // If there is only one item in the list, set it as main account
      if (rightList.length === 1) {
        mainAccountId = rightList[0].id;
      }
    } else {
      // Else, remove him from right list and add him to the left list
      const rightListItem = rightList.find(el => el.id === userId);
      if (!!rightListItem) {
        rightList.splice(rightList.findIndex(el => el.id === userId), 1);
        leftList.push(rightListItem);

        // If the moved item was set as main account, set another main account
        if (mainAccountId === userId) {
          mainAccountId = rightList.length > 0 ? rightList[0].id : 0;
        }
      }
    }

    leftList = Utils.sortArrayByKey(leftList, 'email');
    rightList = Utils.sortArrayByKey(rightList, 'email');

    this.setState({ leftList, rightList, mainAccountId });
  };

  private merge = () => {
    this.setState(
      { isMergeLoading: true },
      () => {
        const ids = this.state.rightList.map(el => el.id);

        UserAPI.mergeConflict({ ids })
          .then(async () => {
            NotificationActions.toast(
              <FormattedMessage id="success" defaultMessage="Success" />,
              (
                <FormattedMessage
                  id="accountConflict.mergeSuccessful"
                  defaultMessage="These accounts were successfully merged!"
                />
              ),
              ToastType.SUCCESS,
            );

            this.handleClose();

            // Logout the connected user if his account has been merged (and his session is invalid)
            if (ids.some(id => id === AuthStore.getConnectedUser().id)
              && Math.min(...ids) !== AuthStore.getConnectedUser().id) {
              await AuthActions.logout();
            } else {
              this.props.reloadUserList();
            }
          })
          .catch(() => Utils.toastError());
      },
    );
  };

  public render() {
    if (!!this.state.conflictResponse && this.state.conflictResponse.users.length > 0) {
      const oneAccount = this.props.intl.formatMessage(
        {
          id: 'accountConflict.oneAccount',
          defaultMessage: 'Another account is already registered',
        });

      const multipleAccounts = this.props.intl.formatMessage(
        {
          id: 'accountConflict.multipleAccounts',
          defaultMessage: 'Multiple other accounts are already registered',
        });

      const alreadyRegisteredNames = this.props.intl.formatMessage(
        {
          id: 'accountConflict.alreadyRegisteredNames',
          defaultMessage: 'in iBoost with the same first name and last name',
        });

      const asThisAccount = this.props.intl.formatMessage(
        {
          id: 'accountConflict.asThisAccount',
          defaultMessage: 'as this account',
        });

      let message = '';

      if (this.state.conflictResponse.users.length === 2) {
        message += oneAccount;
      } else {
        message += multipleAccounts;
      }

      message += ` ${alreadyRegisteredNames} ${asThisAccount}.`;

      return (
        <Modal id="conflict-modal" open={true} onClose={this.handleClose}>
          <Modal.Content id="modal-content">
            <Modal.Header>
              <h1>
                <FormattedMessage id="accountConflict.title" defaultMessage="There are conflicts on this account" />:
                &nbsp;
                <span className="email">{this.props.user.email}</span>
              </h1>
            </Modal.Header>
            <div>
              {message}
            </div>
            <div id="accounts-container">
              <span>
                <FormattedMessage
                  id="accountConflict.selectAccountsToMerge"
                  defaultMessage="Please select the accounts you wish to merge"
                />:
              </span>
              <div className="lists-container">
                <EmailList
                  users={this.state.leftList}
                  moveItem={this.moveItem}
                />
                <EmailList
                  users={this.state.rightList}
                  isRightList={true}
                  moveItem={this.moveItem}
                />
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <div>
              <Button onClick={this.handleClose}>
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <FormattedMessage
                id="accountConflict.unsetConflictsTooltip"
                defaultMessage="Set this account as not conflicting"
              >
                {msg =>
                  <Popup
                    inverted={true}
                    content={msg}
                    size="tiny"
                    position="left center"
                    trigger={
                      <Button id="unset-conflict" className="long" onClick={this.unsetConflict}>
                        <FormattedMessage id="accountConflict.unsetConflicts" defaultMessage="Unset conflicts" />
                      </Button>}
                  />
                }
              </FormattedMessage>
            </div>

            <Button
              id="merge"
              primary={true}
              loading={this.state.isMergeLoading}
              onClick={this.merge}
              disabled={this.state.isMergeLoading
                || this.state.rightList.length < 2
                || this.state.mainAccountId === 0}
            >
              <FormattedMessage id="merge" defaultMessage="Merge"/>
              <Icon name="checkmark" />
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }

    return null;
  }
}

export default injectIntl(AccountConflict);
