import './attachmentsManagement.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Popup, Segment } from 'semantic-ui-react';

import Utils from '../../../../utils/utils';
import Attachment from '../../../../models/attachment';
import ModeTypes from '../../../../constants/modeTypes';
import { AttachmentRow } from '../../../common/attachments/attachmentRow';
import TypeActivity from '../../../../constants/typeActivity';
import PerformancePlanAPI from '../../../../api/performancePlan.api';
import BusinessChallengeAPI from '../../../../api/businessChallenge.api';
import PerformanceInitiativeAPI from '../../../../api/performanceInitiative.api';
import ActionAPI from '../../../../api/action.api';
import SegmentEditButtons from '../common/segmentEditButtons/segmentEditButtons';
import { SegmentType } from '../../../../models/segmentsMode';
import NotificationActions from '../../../../actions/notification-actions';
import { ToastType } from '../../../common/toast/toast';
import LastUpdateHighlight from '../common/overviewPanel/panelContent/tabs/lastUpdatedInformations/lastUpdateHighlight';
import SegmentsStore from '../../../../stores/segments.store';
import ActivitiesActions from 'src/actions/activities.action';
import ActionStore from 'src/stores/action.store';
import ActionTypes from 'src/constants/actionTypes';

interface IProps {
  activityId: number | null;
  activityType: TypeActivity;
  canEdit: boolean;

  shouldResetAttachments?: boolean;
  mode?: ModeTypes;
  setAreAttachmentsValid?(areAttachmentsValid: boolean): void;
  setAttachmentsResetDone?(): void;
}

interface IStates {
  attachments: Attachment[] | undefined;
  oldAttachments: Attachment[] | undefined;
  areAttachmentsValid: boolean;
  mode: ModeTypes;
  isLoading: boolean;
  blockAttachments: boolean;
}

class AttachmentsManagement extends React.Component<IProps, IStates> {

  private isMount = false;

  constructor(props: IProps) {
    super(props);

    this.state = {
      attachments: undefined,
      oldAttachments: undefined,
      areAttachmentsValid: true,
      mode: SegmentsStore.getSegmentModeById(this.getSegmentType()),
      isLoading: true,
      blockAttachments: false,
    };
  }

  componentDidMount() {
    this.isMount = true;
    this.getData();
    ActionStore.addListener(ActionTypes.ACTION_SAVED.toString(), this.onSave);
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>, snapshot?: any) {
    if (this.props.shouldResetAttachments) {
      this.onCancel(this.props.setAttachmentsResetDone);
    }
    if ((!prevProps.activityId && this.props.activityId)
      || (prevProps.activityId !== this.props.activityId)) {
      this.getData();
    }
  }

  componentWillUnmount() {
    this.isMount = false;
    ActionStore.removeListener(ActionTypes.ACTION_SAVED.toString(), this.onSave);
  }

  private getData = () => {
    if (this.props.activityId!!) {
      let request: Promise<Attachment[]>;
      switch (this.props.activityType) {
        case TypeActivity.PERFORMANCE_PLAN:
          request = PerformancePlanAPI.getPerformancePlanAttachments(this.props.activityId!!);
          break;
        case TypeActivity.BUSINESS_CHALLENGE:
          request = BusinessChallengeAPI.getBusinessChallengeAttachments(this.props.activityId!!);
          break;
        case TypeActivity.PERFORMANCE_INITIATIVE:
          request = PerformanceInitiativeAPI.getPerformanceInitiativeAttachments(this.props.activityId!!);
          break;
        case TypeActivity.ACTION:
        default:
          request = ActionAPI.getActionAttachments(this.props.activityId!!);
          break;
      }

      request
        .then((attachments) => {
          this.setState({
            attachments: [...attachments],
            oldAttachments: [...attachments],
            isLoading: false,
          });
        })
        .catch(() => this.setState({ isLoading: false }));
    } else {
      this.setState({ blockAttachments: true });
    }

  }

  private getSegmentType = (): SegmentType => {
    switch (this.props.activityType) {
      case TypeActivity.PERFORMANCE_PLAN:
        return SegmentType.PP_ATTACHMENTS;
      case TypeActivity.BUSINESS_CHALLENGE:
        return SegmentType.BC_ATTACHMENTS;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        return SegmentType.PI_ATTACHMENTS;
      case TypeActivity.ACTION:
      default:
        return SegmentType.AT_ATTACHMENTS;
    }
  }

  private changeMode = (mode: ModeTypes) => {
    this.setState({ mode });
  }

  //region Cancel/Save methods
  private onCancel = (callback?: Function) => {
    const oldAttachments = this.state.oldAttachments ? [...this.state.oldAttachments as Attachment[]] : [];
    this.setState(
      {
        attachments: oldAttachments,
        areAttachmentsValid: Utils.testAttachments(oldAttachments),
      },
      () => {
        this.props.setAreAttachmentsValid && this.props.setAreAttachmentsValid(this.state.areAttachmentsValid);
        callback && callback();
      });
  }

  private onSave = async () => {
    if (!this.isMount) {
      return;
    }
    if (this.state.oldAttachments && this.state.attachments) {
      const oldAttachments = [...this.state.oldAttachments];
      const attachments = [...this.state.attachments];
      const promises: Promise<void>[] = [];
      const changes = Utils.filterChanges(oldAttachments, attachments);

      let postRequest;
      let putRequest;
      let deleteRequest;

      switch (this.props.activityType) {
        case TypeActivity.PERFORMANCE_PLAN:
          postRequest = PerformancePlanAPI.postPerformancePlanAttachments;
          putRequest = PerformancePlanAPI.putPerformancePlanAttachmentsById;
          deleteRequest = PerformancePlanAPI.deletePerformancePlanAttachments;
          break;

        case TypeActivity.BUSINESS_CHALLENGE:
          postRequest = BusinessChallengeAPI.postBusinessChallengeAttachments;
          putRequest = BusinessChallengeAPI.putBusinessChallengeAttachmentsById;
          deleteRequest = BusinessChallengeAPI.deleteBusinessChallengeAttachments;
          break;

        case TypeActivity.PERFORMANCE_INITIATIVE:
          postRequest = PerformanceInitiativeAPI.postPerformanceInitiativeAttachments;
          putRequest = PerformanceInitiativeAPI.putPerformanceInitiativeAttachments;
          deleteRequest = PerformanceInitiativeAPI.deletePerformanceInitiativeAttachments;
          break;

        case TypeActivity.ACTION:
          postRequest = ActionAPI.postActionAttachments;
          putRequest = ActionAPI.putActionAttachments;
          deleteRequest = ActionAPI.deleteActionAttachments;
          break;
      }

      if (changes.create.length !== 0) {
        promises.push(postRequest(this.props.activityId, changes.create));
      }
      if (changes.edit.length !== 0) {
        promises.push(putRequest(this.props.activityId, changes.edit));
      }
      if (changes.remove.length !== 0) {
        promises.push(deleteRequest(this.props.activityId, changes.remove.map(attachment => attachment.id)));
      }

      Promise.all(promises)
        .then(() => {
          this.getData();
          if (this.props.mode === undefined) {
            NotificationActions.toast(
              <FormattedMessage id="saved" defaultMessage="Saved!" />,
              (
                <FormattedMessage
                  id="attachments.successfullySaved"
                  defaultMessage="The attachments were successfully saved"
                />
              ),
              ToastType.SUCCESS,
            );
          }
          ActivitiesActions.emitUpdatedLastUpdate();
        })
        .catch(() => Utils.toastError());
    }
  }
  //endregion

  /**
   * Handle attachment form changes
   * @param value: value typed
   * @param id: current attachment id
   * @param type: URL or title of the attachment
   */
  public handleAttachmentChange = (value: string, id: number, type: string) => {
    const attachments = JSON.parse(JSON.stringify(this.state.attachments));
    if (attachments) {
      const attIndex = attachments.findIndex(att => att.id === id);

      if (attIndex !== -1) {
        if (type === 'url') {
          attachments[attIndex].url = value;
        } else {
          attachments[attIndex].title = value;
        }
      }

      this.setState({ attachments, areAttachmentsValid: Utils.testAttachments(attachments) }, () => {
        this.props.setAreAttachmentsValid && this.props.setAreAttachmentsValid(this.state.areAttachmentsValid);
      });
    }
  };

  /**
   * Handle attachment form deletion
   * @param id: id of the attachment to delete
   */
  private deleteAttachment = (id: number) => {
    if (this.state.attachments) {
      const currentAttachments = [...this.state.attachments];
      const attachments = currentAttachments.filter(a => a.id !== id);
      this.setState({ attachments, areAttachmentsValid: Utils.testAttachments(attachments) }, () => {
        this.props.setAreAttachmentsValid && this.props.setAreAttachmentsValid(this.state.areAttachmentsValid);
      });
    }
  };

  /**
   * Handle attachment addition
   */
  private addAttachment = () => {
    if (this.state.attachments) {
      const attachments = [...this.state.attachments];
      const nbOfAttachments = attachments.length;
      const lastAttachment = attachments[nbOfAttachments - 1];
      if (lastAttachment && (!lastAttachment.title || !lastAttachment.url)) {
        return;
      }
      const newAttachment = new Attachment();
      newAttachment.id = new Date().getTime();
      attachments.push(newAttachment);
      this.setState({ attachments, areAttachmentsValid: false }, () => {
        this.props.setAreAttachmentsValid && this.props.setAreAttachmentsValid(this.state.areAttachmentsValid);
      });
    }
  };

  public render() {
    const segmentType = this.getSegmentType();
    const title = <FormattedMessage id="attachments" defaultMessage="Attachments" />;
    const isSegmentTypeAts = segmentType === SegmentType.AT_ACTION;

    if (this.state.blockAttachments) {
      return (
        <Segment id={LastUpdateHighlight.getSegmentId(segmentType)}>
          <div className={`title-element blocked ${isSegmentTypeAts ? 'grey-title' : ''}`}>
            {Utils.getTitleWithErrIcon(title, !this.state.areAttachmentsValid, true)}
            {Utils.isOnEditMode(this.props.mode ?? this.state.mode) &&
              <FormattedMessage
                id="attachments.blocked"
                defaultMessage="Attachments cannot be edited on action creation"
              >
                {msg =>
                  <Popup
                    inverted={true}
                    content={msg}
                    size="tiny"
                    trigger={<Icon name="add" className="attachment-add" />}
                  />
                }
              </FormattedMessage>
            }
          </div>
        </Segment>
      );
    }

    if (!this.state.attachments) return null;

    return (
      <Segment id={LastUpdateHighlight.getSegmentId(segmentType)}>
        {this.props.canEdit && !this.props.mode &&
          <SegmentEditButtons
            mode={this.props.mode ?? this.state.mode}
            segmentType={segmentType}
            isSaveDisabled={!this.state.areAttachmentsValid}
            changeMode={this.changeMode}
            onSave={this.onSave}
            onCancel={this.onCancel}
          />
        }
        <div className={`title-element ${isSegmentTypeAts ? 'grey-title' : ''}`}>
          {Utils.getTitleWithErrIcon(title, !this.state.areAttachmentsValid, true)}
          {Utils.isOnEditMode(this.props.mode ?? this.state.mode) &&
            <FormattedMessage id="attachments.add" defaultMessage="Add an attachment">
              {msg =>
                <Popup
                  inverted={true}
                  content={msg}
                  size="tiny"
                  trigger={<Icon name="add" className="attachment-add" onClick={this.addAttachment} />}
                />
              }
            </FormattedMessage>
          }
        </div>
        <div className="attachments-container">
          {this.state.attachments.length === 0
            ? <FormattedMessage id="attachments.noAttachment" defaultMessage="No attachment" />
            : this.state.attachments.map(attachment =>
              <AttachmentRow
                key={attachment.id}
                mode={this.props.mode ?? this.state.mode}
                attachment={attachment}
                handleAttachmentChange={this.handleAttachmentChange}
                deleteAttachment={this.deleteAttachment}
              />,
            )
          }
        </div>
      </Segment>
    );
  }
}

export default AttachmentsManagement;
