import React from 'react';
import DateField from './dateField/dateField';
import ModeTypes from '../../../../constants/modeTypes';
import { ActionEdit } from '../../../../models/action';
import { CommonDateField, ActionDateField } from '../../../../constants/fields';
import { IAtFormValid } from '../../../../constants/formValid';

interface IProps {
  mode: ModeTypes;
  action: ActionEdit;
  handleDateChange?({ name, value }: any, event: any): void;
  areDatesValid: IAtFormValid;
  alternateDisplayOnEdit?: boolean;
}

class ActionDates extends React.Component<IProps> {
  render() {
    const startingDate = (
      <DateField
        mode={this.props.mode}
        dateField={ActionDateField.STARTING_DATE}
        date={this.props.action.startingDate}
        handleDateChange={this.props.handleDateChange}
        isFieldValid={this.props.areDatesValid.isStartingDateValid}
      />
    );
    const forecastDate = (
      <DateField
        mode={this.props.mode}
        dateField={ActionDateField.FORECAST_DATE}
        date={this.props.action.forecastDate}
        handleDateChange={this.props.handleDateChange}
        isFieldValid={this.props.areDatesValid.isForecastDateValid}
        minDate={this.props.action.startingDate}
      />
    );
    const targetDate = (
      <DateField
        mode={this.props.mode}
        dateField={CommonDateField.TARGET_DATE}
        date={this.props.action.targetDate}
        handleDateChange={this.props.handleDateChange}
        isFieldValid={this.props.areDatesValid.isTargetDateValid}
        minDate={this.props.action.startingDate}
      />
    );
    const completionDate = (
      <DateField
        mode={this.props.mode}
        dateField={CommonDateField.COMPLETION_DATE}
        date={this.props.action.completionDate}
        handleDateChange={this.props.handleDateChange}
        isFieldValid={this.props.areDatesValid.isCompletionDateValid}
        minDate={this.props.action.startingDate}
      />
    );
    return (
      <div className="content-container action-content-container">
        {this.props.alternateDisplayOnEdit
        ?
          <>
            <div className="flex">
              {startingDate}
              {targetDate}
            </div>
            <div className="flex">
              {forecastDate}
              {completionDate}
            </div>
          </>
        :
        <>
          {startingDate}
          {targetDate}
          {forecastDate}
          {completionDate}
        </>
        }
      </div>
    );
  }
}
export default ActionDates;
