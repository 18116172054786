import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'semantic-ui-react';

import Utils from '../../../utils/utils';
import PerformanceInitiative from 'src/models/performanceInitiative';
import CustomScrollBars from '../../common/customScrollBars/customScrollBars';

interface IProps {
  selectedPi: number;
  performanceInitiatives: PerformanceInitiative[];
  isListLoading: boolean;
  setSelectedPi(piId: number): void;
}

export default class ActivityImportStep3 extends React.Component<IProps> {

  public constructor(props: IProps) {
    super(props);
  }

  private onPiSelection = (selectedPi: number) => {
    this.props.setSelectedPi(selectedPi);
  }

  public render(): React.ReactNode {

    if (this.props.isListLoading) {
      return Utils.loader();
    }

    if (this.props.performanceInitiatives.length === 0) {
      return Utils.empty(<FormattedMessage id="noPI" defaultMessage="No PI was found for this Business Challenge" />);
    }

    return (
      <div id="activity-import-step2">
        <CustomScrollBars id="bc-container">
          {this.props.performanceInitiatives.map(pi =>
            <div
              key={`bc${pi.id}`}
              className={`tile ${this.props.selectedPi === pi.id ? 'selected' : ''}`}
              onClick={() => this.onPiSelection(pi.id)}
            >
              {this.props.selectedPi === pi.id && <Icon name="check" />} {Utils.leadingZero(pi.code)} - {pi.name}
            </div>,
          )}
        </CustomScrollBars>
      </div>
    );
  }
}
