import '../metricsMilestones.scss';

import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import Utils from '../../../../../utils/utils';
import CustomScrollBars from 'src/components/common/customScrollBars/customScrollBars';
import Action from 'src/models/action';

interface IProps {
  actions: Action[] | undefined;
  selectedAt: number;
  selectAt(selectedAtId: number): void;
}

export default class ActionSelection extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    if (this.props.actions === undefined) {
      return Utils.loader();
    }

    if (this.props.actions.length === 0) {
      return Utils.empty(
      <FormattedMessage
        id="noAction"
        defaultMessage="No action was found for this Performance Initiative"
      />);
    }

    return (
      <div id="at-selection" className="step-container">
        <CustomScrollBars>
            {this.props.actions.map(at =>
              <div key={`at${at.id}`} className="tile-container">
                <div
                  className={`tile ${this.props.selectedAt === at.id ? 'selected' : ''}`}
                  onClick={() => this.props.selectAt(at.id)}
                >
                  {this.props.selectedAt === at.id && <Icon name="check" />} {Utils.leadingZero(at.code)} - {at.name}
                </div>
              </div>,
            )}
        </CustomScrollBars>
      </div>
    );
  }
}
