import '../metricsMilestones.scss';

import * as React from 'react';
import { Button, Icon, Modal, Step } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import PiSelection from './piSelection';
import PerformanceInitiative from 'src/models/performanceInitiative';
import PerformanceInitiativeAPI from '../../../../../api/performanceInitiative.api';
import Metric from 'src/models/metric';
import MetricMilestoneSelection from './metricMilestoneSelection';
import Utils from '../../../../../utils/utils';
import Milestone from '../../../../../models/milestone';
import BusinessChallengeAPI from '../../../../../api/businessChallenge.api';
import BusinessChallenge from 'src/models/businessChallenge';
import TypeActivity from '../../../../../constants/typeActivity';
import MilestoneAction from '../../../../../actions/milestone.action';
import ModeTypes from '../../../../../constants/modeTypes';
import NotificationActions from '../../../../../actions/notification-actions';
import { ToastType } from '../../../../common/toast/toast';
import { LinkMetricMilestoneError } from '../../../../../constants/errors/metricMilestone.error';

export enum importType {
  milestone,
  metric,
}

interface IProps {
  bcId?: number;
  importType: importType;
  closeModal: () => void;
  reloadMetrics?: () => void;
}

interface IStates {
  bc: BusinessChallenge | undefined;
  steps: { title: JSX.Element, desc: JSX.Element }[];
  currentStep: number;
  isSubmitLoading: boolean;
  performanceInitiatives: PerformanceInitiative[] | undefined;
  selectedPi: number;
  milestones: Milestone[];
  linkedMilestones: Milestone[];
  selectedMilestone: Milestone | undefined;
  metrics: Metric[];
  selectedMetric: Metric | undefined;
  loadingMetricMilestone: boolean;
}

export class ImportMetricsMilestonesModal extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);

    const steps = [
      {
        title: <FormattedMessage id="performanceInitiatives" defaultMessage="Performance initiatives" />,
        desc: <FormattedMessage id="link.piSelectionDesc" defaultMessage="Performance initiatives selection" />,
      },
    ];

    if (this.props.importType === importType.milestone) {
      steps.push({
        title: <FormattedMessage id="milestones" defaultMessage="Milestones" />,
        desc: <FormattedMessage id="link.milestoneSelectionDesc" defaultMessage="Milestone Selection" />,
      });
    } else {
      steps.push({
        title: <FormattedMessage id="metrics" defaultMessage="Metrics" />,
        desc: <FormattedMessage id="link.metricSelectionDesc" defaultMessage="Metric Selection" />,
      });
    }

    this.state = {
      steps,
      bc: undefined,
      currentStep: 0,
      isSubmitLoading: false,
      performanceInitiatives: [],
      selectedPi: 0,
      milestones: [],
      linkedMilestones: [],
      metrics: [],
      selectedMetric: undefined,
      selectedMilestone: undefined,
      loadingMetricMilestone: false,
    };
  }

  async componentDidMount() {
    await this.getData();
  }

  private getData = async () => {
    if (!this.props.bcId) {
      return;
    }
    const bc = await BusinessChallengeAPI.getBcForImportMetricsMilestones(this.props.bcId);
    let pis = bc.performanceInitiatives;
    const linkedPis = bc.linkedPis;
    if (linkedPis) {
      pis = pis.concat(linkedPis);
    }

    pis.sort((a, b) => a.code > b.code ? 1 : b.code > a.code ? -1 : 0);

    this.setState({
      bc,
      performanceInitiatives: pis,
      selectedPi: pis.length > 0 ? pis[0].id : 0,
    });
    await this.getMilestones();
  }

  private async getMilestones() {
    if (!!this.props.bcId) {
      await BusinessChallengeAPI.getBusinessChallengeMilestones(this.props.bcId)
        .then((milestones) => {
          milestones.bcMilestones.forEach((mi) => {
            mi.activityMilestone = TypeActivity.BUSINESS_CHALLENGE;
          });
          milestones.piLinkedMilestones.forEach((mi) => {
            mi.activityMilestone = TypeActivity.PERFORMANCE_INITIATIVE;
          });
          this.setState({ linkedMilestones: milestones.piLinkedMilestones });
        });
    }
  }

  private handleClose = () => {
    MilestoneAction.emitChangeMilestoneMode(ModeTypes.MODE_VIEW);
    this.props.closeModal();
  }

  private onPreviousClick = () => {
    if (this.state.currentStep > 0) {
      this.setState({ currentStep: this.state.currentStep - 1 });
    }

    if (this.state.currentStep === 1) {
      this.setState({
        milestones: [],
        metrics: [],
      });
    }
  }

  private onNextClick = () => {
    if (this.state.currentStep < this.state.steps.length) {
      const previousStep = this.state.currentStep;
      this.setState(
        { currentStep: this.state.currentStep + 1 },
        async () => {
          if (previousStep === 0 && this.state.currentStep === 1) {
            this.setState({
              loadingMetricMilestone: true,
            });
            // Get Milestone / Metric
            if (this.props.importType === importType.milestone) {
              PerformanceInitiativeAPI.getPerformanceInitiativeMilestones(this.state.selectedPi)
                .then((milestones) => {
                  this.setState({
                    milestones,
                    selectedMilestone: milestones.length > 0 ? milestones[0] : undefined,
                    loadingMetricMilestone: false,
                  });
                })
                .catch(() => Utils.toastError());
            } else {
              PerformanceInitiativeAPI.getPerformanceInitiativeMetrics(this.state.selectedPi)
                .then((res) => {
                  this.setState({
                    metrics: res.metrics,
                    selectedMetric: res.metrics.length > 0 ? res.metrics[0] : undefined,
                    loadingMetricMilestone: false,
                  });
                })
                .catch(() => Utils.toastError());
            }
          }
        },
      );
    }
  }

  private selectPi = (piId: number) => {
    this.setState({ selectedPi: piId });
  }

  private selectMetricMilestone = (element) => {
    if (this.props.importType === importType.milestone) {
      this.setState({ selectedMilestone: element });
    } else {
      this.setState({ selectedMetric: element });
    }
  }

  private submit = async () => {
    if (this.props.importType === importType.metric && !!this.state.selectedMetric
      && this.state.selectedMetric.id && this.state.bc) {
      BusinessChallengeAPI.createBusinessChallengeLinkMetrics(this.state.bc.id, [this.state.selectedMetric.id])
        .then(() => {
          this.handleClose();
          this.props.reloadMetrics?.();
        })
        .catch((err) => {
          let message: JSX.Element | null = null;
          if (err.errors && err.errors[0].error === LinkMetricMilestoneError.CANNOT_LINK_METRIC_TO_BC) {
            message = (
              <FormattedMessage
                key="metric.cannotLink"
                id="imported.metric.error.cannotLink"
                defaultMessage="Metric doesn't exist or is not from the same BC"
              />
            );
          } else if (err.errors && err.errors[0].error === LinkMetricMilestoneError.LINK_ALREADY_EXIST) {
            message = (
              <FormattedMessage
                key="metric.linkAlreadyExist"
                id="imported.metricMilestone.error.linkAlreadyExist"
                defaultMessage="Link already exists"
              />
            );
          }
          if (message) {
            NotificationActions.toast(
              <FormattedMessage id="error" defaultMessage="Error!"/>,
              message,
              ToastType.ERROR,
            );
          } else {
            Utils.toastError();
          }
        });
    } else if (this.props.importType === importType.milestone && !!this.state.selectedMilestone
      && !!this.props.bcId && !!this.state.selectedMilestone.id
      && !this.state.linkedMilestones.some(mi => mi.id === this.state.selectedMilestone?.id)) {
      await BusinessChallengeAPI.createBusinessChallengeLinkMilestones(
        this.props.bcId, [{ id: this.state.selectedMilestone.id }])
        .then(() => this.handleClose())
        .then(() => {
          if (this.state.selectedMilestone && this.state.selectedMilestone.id) {
            MilestoneAction.emitLinkMilestoneToBc(this.state.selectedMilestone.id, this.state.selectedPi);
          }
        });
    }
  }

  private filterLinkedMilestones() {
    const allMilestones = [...this.state.milestones];
    const alreadyLinkedMilestone = [...this.state.linkedMilestones];

    alreadyLinkedMilestone.forEach((miLinked) => {
      const index = allMilestones.findIndex(mi => mi.id === miLinked.id);

      if (index > -1) {
        allMilestones.splice(index, 1);
      }
    });
    return allMilestones;
  }

  public render() {
    this.filterLinkedMilestones();
    const title = this.props.importType === importType.milestone
      ? <FormattedMessage id="importModal.milestone.title" defaultMessage="Import a milestone"/>
      : <FormattedMessage id="importModal.metric.title" defaultMessage="Import a metric"/>;

    const nextButtonMessage = this.state.currentStep === 1
      ? <FormattedMessage id="search" defaultMessage="Search"/>
      : <FormattedMessage id="next" defaultMessage="Next"/>;
    return (
      <>
        <Modal
          className="import-milestone-modal"
          open={true}
          onClose={this.handleClose}
          closeOnDimmerClick={false}
        >
          <Modal.Content id="import-milestone-modal-content">
            <Modal.Header>
              <h1>{title}</h1>
            </Modal.Header>
            <div className="modal-container">
              <Step.Group id="progress" vertical={true}>
                {this.state.steps.map((step, index) =>
                  <Step key={index} active={this.state.currentStep === index}>
                    <Step.Content>
                      <Step.Title>{step.title}</Step.Title>
                      <Step.Description>{step.desc}</Step.Description>
                    </Step.Content>
                  </Step>,
                )}
              </Step.Group>
              <div className="selection-container">
                {this.state.currentStep === 0 &&
                  <PiSelection
                    performanceInitiatives={this.state.performanceInitiatives}
                    selectedPi={this.state.selectedPi}
                    selectPi={this.selectPi}
                  />
                }
                {this.state.currentStep === 1 &&
                  <MetricMilestoneSelection
                    loadingMetricMilestone={this.state.loadingMetricMilestone}
                    importType={this.props.importType}
                    milestones={this.filterLinkedMilestones()}
                    metrics={this.state.metrics}
                    selectedMilestone={this.state.selectedMilestone}
                    selectedMetric={this.state.selectedMetric}
                    selectMetricMilestone={this.selectMetricMilestone}
                  />
                }
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button id="button-left" onClick={this.handleClose}>
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <div>
              <Button
                className="previous"
                color="grey"
                disabled={this.state.currentStep === 0 || this.state.isSubmitLoading}
                onClick={this.onPreviousClick}
              >
                <Icon name="chevron left" />
                <FormattedMessage id="previous" defaultMessage="Previous" />
              </Button>
              {this.state.currentStep !== this.state.steps.length - 1
                ? <Button
                  className="next"
                  primary={true}
                  onClick={this.onNextClick}
                >
                  {nextButtonMessage}
                  <Icon name="chevron right" />
                </Button>
                : <Button
                  className="submit"
                  primary={true}
                  loading={this.state.isSubmitLoading}
                  onClick={this.submit}
                  disabled={this.state.isSubmitLoading}
                >
                  <FormattedMessage id="confirm" defaultMessage="Confirm" />
                  <Icon name="checkmark" />
                </Button>
              }
            </div>
          </Modal.Actions>
        </ Modal>
      </>
    );
  }
}
