import * as React from 'react';
import { Button, Icon, Segment } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { IUserConflict } from 'src/constants/accountConflict';
import CustomScrollBars from '../../../../../common/customScrollBars/customScrollBars';

interface IProps {
  users: IUserConflict[];
  isRightList?: boolean;

  moveItem(userId: number): void;
}

class EmailList extends React.Component<IProps> {

  public constructor(props: any) {
    super(props);
    this.state = {
    };
  }

  public render() {
    const isRightList = this.props.isRightList !== undefined && this.props.isRightList;
    return (
      <Segment>
        <div id="title">
          {isRightList
            ? <FormattedMessage id="accountConflict.toMergeColumn" defaultMessage="Accounts to merge" />
            : <FormattedMessage id="accountConflict.conflictColumn" defaultMessage="Conflicting accounts" />
          }
        </div>
        <CustomScrollBars id="scrollbar">
          {this.props.users.map(user =>
            <div className="row" key={user.id}>
              <Button
               onClick={() => this.props.moveItem(user.id)}
               icon={true}
               labelPosition={isRightList ? 'left' : 'right'}
              >
               {user.email}
                {isRightList
                  ? <Icon name="arrow left" />
                  : <Icon name="arrow right" />
                }
              </Button>
            </div>,
          )}
        </CustomScrollBars>
      </Segment>
    );
  }
}

export default EmailList;
