import './actionsManagement.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Utils from '../../../../utils/utils';
import ModeTypes from '../../../../constants/modeTypes';
import TypeActivity from '../../../../constants/typeActivity';
import MilestoneStore from '../../../../stores/milestone.store';
import ActionTypes from '../../../../constants/actionTypes';
import { Dropdown, Icon, Segment } from 'semantic-ui-react';
import LastUpdateHighlight from '../common/overviewPanel/panelContent/tabs/lastUpdatedInformations/lastUpdateHighlight';
import { SegmentType } from '../../../../models/segmentsMode';
import SegmentsStore from '../../../../stores/segments.store';
import SegmentEditButtons from '../common/segmentEditButtons/segmentEditButtons';
import NotificationActions from '../../../../actions/notification-actions';
import { ToastType } from '../../../common/toast/toast';
import ActivitiesActions from '../../../../actions/activities.action';
import { LinkedAction } from '../../../../models/linkedMilestone';
import Action from '../../../../models/action';
import ActionStore from '../../../../stores/action.store';
import ActionActions from 'src/actions/actions.action';
import moment from 'moment';
import { CommonDateField } from 'src/constants/fields';

interface IProps {
  activityId: number | null;
  activityType: TypeActivity;
  milestoneId: number;
  canEdit: boolean;
  mode?: ModeTypes;
  shouldResetActions?: boolean;
  setActionsResetDone?(): void;
  completionDate: Date | undefined;
  setCompletionDate(mode: ModeTypes, noLinkedAction: boolean): void;
  handleDateChange?({ name, value }: any, save: boolean): void;
}

interface IStates {
  actions: Action[];
  availableAction: Action[];
  linkedAction: Action[];
  oldAction: Action[];
  isLoading: boolean;
  mode: ModeTypes;
  milestoneLinkedAction: Map<number, LinkedAction[]>;
}

class ActionsManagement extends React.Component<IProps, IStates> {

  private isMount = false;

  constructor(props: IProps) {
    super(props);

    this.state = {
      actions: ActionStore.getActions(),
      availableAction: [],
      linkedAction: [],
      oldAction: [],
      isLoading: true,
      mode: SegmentsStore.getSegmentModeById(SegmentType.AT_MILESTONES),
      milestoneLinkedAction: ActionStore.getActionLinkedMilestone(),
    };
  }

  componentDidMount() {
    this.isMount = true;
    MilestoneStore.addListener(ActionTypes.GET_MILESTONES.toString(), this.onSave);
    ActionStore.addListener(ActionTypes.GET_LINK_ACTION_TO_MILESTONE.toString(), this.getLinkedAction);
    ActionStore.addListener(ActionTypes.ACTION_SAVED.toString(), this.getActions);
    this.getData(true);
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>, snapshot?: any) {
    if (this.props.shouldResetActions) {
      this.onCancel(this.props.setActionsResetDone);
    }
    if ((!prevProps.activityId && this.props.activityId)
      || (this.props.milestoneId !== prevProps.milestoneId)) {
      this.getData();
    }
  }

  componentWillUnmount() {
    this.isMount = false;
    MilestoneStore.removeListener(ActionTypes.GET_MILESTONES.toString(), this.onSave);
    ActionStore.removeListener(ActionTypes.GET_LINK_ACTION_TO_MILESTONE.toString(), this.getLinkedAction);
    ActionStore.removeListener(ActionTypes.ACTION_SAVED.toString(), this.getActions);
  }

  getLinkedAction = () => {
    if (this.isMount) {
      this.setState({ milestoneLinkedAction: ActionStore.getActionLinkedMilestone() });
      this.getData();
    }
  }

  getActions  = () => {
    if (this.isMount) {
      this.setState({ actions: this.sortPossibleActions(ActionStore.getActions()) }, () => {
        this.getData(true);
      });
    }
  }

  private checkStateAction(actions: Action[], save: boolean = false, deletedLastAction : boolean = false) {
    let allActionCompleted: boolean = true;
    let lastCompletionDate: Date | undefined = undefined;
    let value : string = '';
    for (const action of actions) {
      if (action.completionDate === null) {
        allActionCompleted = false;
        break;
      } else {
        if (!lastCompletionDate || moment(action.completionDate).isAfter(lastCompletionDate)) {
          lastCompletionDate = action.completionDate;
        }
      }
    }
    const name = CommonDateField.COMPLETION_DATE;

    if (allActionCompleted && actions.length > 0) {
      const originalDate = new Date(lastCompletionDate!.toString());
      const newDate = new Date(originalDate.setDate(originalDate.getDate() + 1));
      const dataTab = newDate.toISOString().split('T')[0].split('-'); // year-month-day
      value = `${dataTab[2]}/${dataTab[1]}/${dataTab[0]}`;
      this.props.setCompletionDate(ModeTypes.MODE_VIEW, false);
      this.props.handleDateChange!({ name, value }, save);
    } else if (actions.length > 0) {
      this.props.setCompletionDate(ModeTypes.MODE_VIEW, false);
      this.props.handleDateChange!({ name, value }, save);
    } else {
      this.props.setCompletionDate(this.props.mode!, true);
      if (deletedLastAction) this.props.handleDateChange!({ name, value }, save);
    }
  }

  private sortPossibleActions(actions : Action[]) {
    const possibleAction : Action[] = [];
    for (const action of actions) {
      let actionUsed : boolean = false;
      this.state.milestoneLinkedAction.forEach((value: LinkedAction[], key: number) => {
        if (!actionUsed && key !== this.props.milestoneId && value.filter(at => at.atId === action.id).length === 1) {
          actionUsed = true;
        }
      });
      if (!actionUsed)  possibleAction.push(action);
    }
    return possibleAction;
  }

  private getData = (save: boolean = false) => {
    if (this.props.activityId!! && this.state.milestoneLinkedAction !== undefined) {
      const possibleActions = this.sortPossibleActions(this.state.actions);
      let availableAction : Action[] = possibleActions;
      let linkedAction : Action[] = [];
      if (this.state.milestoneLinkedAction.get(this.props.milestoneId)) {
        availableAction = possibleActions.filter((at: Action) => {
          return this.state.milestoneLinkedAction!!.get(this.props.milestoneId!!)!!
            .find(i => i.atId === at.id) === undefined;
        });
        linkedAction = possibleActions.filter((at: Action) => {
          return this.state.milestoneLinkedAction!!.get(this.props.milestoneId!!)!!
            .find(i => i.atId === at.id) !== undefined;
        });
      }
      this.checkStateAction(linkedAction, save);
      this.setState({
        availableAction,
        linkedAction,
        oldAction: linkedAction,
        isLoading: false,
      });
    }
  }

  //region Cancel/Save methods
  private onCancel = (callback?: Function) => {
    const oldAction = [...this.state.oldAction!!];
    const availableAction = this.state.availableAction!!.filter((at: Action) => !oldAction.includes(at));
    this.setState(
      {
        availableAction,
        linkedAction: oldAction,
      },
      () => {
        callback && callback();
      });
  }

  private onSave = async () => {
    if (!this.isMount) {
      return;
    }
    if (this.state.oldAction && this.state.linkedAction) {
      const promises: Promise<void>[] = [];
      const changes = Utils.filterChanges(this.state.oldAction, this.state.linkedAction);
      if (changes.create.length !== 0) {
        changes.create.forEach((action: Action) => {
          promises.push(ActionActions.updateLinkedActions(action.id, this.props.milestoneId, true));
        });
      }
      if (changes.remove.length !== 0) {
        changes.remove.forEach((action: Action) => {
          promises.push(ActionActions.deleteLinkedActions(action.id, this.props.milestoneId, true));
        });
      }

      Promise.all(promises)
        .then(() => {
          this.getData();
          if (this.props.mode === undefined) {
            NotificationActions.toast(
              <FormattedMessage id="saved" defaultMessage="Saved!" />,
              (
                <FormattedMessage
                  id="actions.successfullySaved"
                  defaultMessage="The actions were successfully saved"
                />
              ),
              ToastType.SUCCESS,
            );
          }
          ActivitiesActions.emitUpdatedLastUpdate();
        })
        .catch(() => Utils.toastError());
    }
  }
  //endregion

  /**
   * Handle action deletion
   * @param id: id of the action to delete
   */
  private deleteAction = (id: number) => {
    if (this.state.linkedAction && this.state.availableAction) {
      const currentAction = [...this.state.linkedAction];
      let availableAction: Action[];
      for (const action of this.state.linkedAction) {
        if (action.id === id) {
          availableAction = [...this.state.availableAction];
          availableAction.push(action);
        }
      }
      const linkedAction = currentAction.filter(a => a.id !== id);
      this.checkStateAction(linkedAction, false, linkedAction.length === 0);
      this.setState({ linkedAction, availableAction: availableAction!! });
    }
  };

  /**
   * Handle milestone addition
   */
  private addAction = (id: number) => {
    if (this.state.linkedAction && this.state.availableAction) {
      const currentAction = [...this.state.availableAction];
      let linkedAction: Action[];
      linkedAction = [];
      this.state.availableAction.forEach((action) => {
        if (action.id === id) {
          linkedAction = [...this.state.linkedAction!!];
          linkedAction.push(action);
        }
      });
      const availableAction = currentAction.filter(a => a.id !== id);
      this.checkStateAction(linkedAction);
      this.setState({ linkedAction, availableAction });
    }
  };

  private changeMode = (mode: ModeTypes) => {
    this.setState({ mode });
  }

  private generatedActionListDropdown = () => {
    return this.state.availableAction!!.map((action) => {
      return {
        key: action.id!!,
        value: action.id!!,
        content: action.name,
      };
    });

  }

  public render() {
    const segmentType = SegmentType.PI_MI_ACTIONS;
    const maxActions = 5;
    const title = `Actions (${this.state.linkedAction!!.length})`;

    return (
      <Segment id={LastUpdateHighlight.getSegmentId(segmentType)}>
        <div className="linked-action">
          <span className="grey-title date-title">
            <FormattedMessage id="linkedActions" defaultMessage={title} />
          </span>

          <div>
              {this.props.canEdit && !this.props.mode &&
              <SegmentEditButtons
                mode={this.props.mode ?? this.state.mode}
                segmentType={segmentType}
                changeMode={this.changeMode}
                onSave={this.onSave}
                onCancel={this.onCancel}
              />
            }
            {Utils.isOnEditMode(this.props.mode ?? this.state.mode) &&
              <Dropdown
              className="action-drop"
              placeholder={'Link an action'}
              selection={true}
              loading={this.state.isLoading}
              onChange={(e: any, { value }: any) => this.addAction(value)}
              options={this.generatedActionListDropdown()}
            />
            }
            <ul className="actions-list">
                {this.state.linkedAction!!.map((action, index) => (
                  (Utils.isOnEditMode(this.props.mode ?? this.state.mode) || index < maxActions) &&
                  <li key={index} >
                    {action.name}
                    {Utils.isOnEditMode(this.props.mode ?? this.state.mode) &&
                      <Icon name="times" className="action-del" onClick={() => this.deleteAction(action.id!!)}/>
                    }
                  </li>
                ))}
                {this.state.linkedAction!!.length === 0 &&
                  <span className="grey-title date-title">
                    <FormattedMessage id="linkedActions" defaultMessage="No linked Actions" />
                  </span>
                }

            </ul>
          </div>
        </div>
      </Segment>
    );
  }
}

export default ActionsManagement;
