import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Checkbox, Icon, Segment } from 'semantic-ui-react';

import ModeTypes from 'src/constants/modeTypes';
import Utils from '../../../../../../utils/utils';
import BusinessChallenge from 'src/models/businessChallenge';
import BcPiKeywords from '../../../bcPiKeywords/bcPiKeywords';
import TypeActivity from 'src/constants/typeActivity';
import CodeInput from 'src/components/common/form/codeInput/codeInput';
import Keywords from 'src/models/keywords';
import { pillarList } from 'src/constants/pillarEnums';
import SegmentEditButtons from '../../../common/segmentEditButtons/segmentEditButtons';
import LastUpdateHighlight from '../../../common/overviewPanel/panelContent/tabs/lastUpdatedInformations/lastUpdateHighlight';
import { SegmentType } from 'src/models/segmentsMode';
import BcFunctions from '../../../bcFuntions/bcFunctions';

export interface IBcFormValid {
  isCodeValid: boolean;
  isNameValid: boolean;
  isKeywordsValid: boolean;
}

interface IRouteProps {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  isLinkedBc?: boolean;
  data: BusinessChallenge | undefined;
  mode: ModeTypes;
  isFormValid: IBcFormValid;
  keywords: Keywords[];
  functions: Keywords[];
  onSave: () => Promise<void>;
  onCancel: () => void;
  changeMode: (mode: ModeTypes) => void;
  handleChange: (event: any, { value }: any) => void;
  getElementDropdown: (defaultValue: number, dropdownName: string, options: any, mode: ModeTypes,
                       isValueNullable?: boolean, isError?: boolean) => JSX.Element;
  dropdownEdit: (name: string, value: any) => void;
  getElement: (data: string, idElement: string) => JSX.Element[];
  onTop5Changes?: () => void;
  canEdit: boolean;
}

interface IStates {
}

class About extends React.Component<IProps, IStates> {

  public render() {
    return (
      <>
        {this.props.data && <Segment id={LastUpdateHighlight.getSegmentId(SegmentType.BC_ABOUT)}>
        {this.props.canEdit && <SegmentEditButtons
          segmentType={SegmentType.BC_ABOUT}
          mode={this.props.mode}
          onSave={this.props.onSave}
          onCancel={this.props.onCancel}
          changeMode={this.props.changeMode}
        />}
          <div className="title-element">
            <FormattedMessage id="aboutBC" defaultMessage="About the Business Challenge" />
          </div>
          <div className="content-element">
            <div className="column">
              <div className="info-element">
                <div className="grey-title">
                  <FormattedMessage id="bcNumber" defaultMessage="BC number" />
                </div>
                {Utils.isOnViewOrDeletionMode(this.props.mode)
                  ? <div>
                    {Utils.leadingZero(this.props.data.code)}
                  </div>
                  : <div>
                    <CodeInput
                      defaultValue={this.props.data.code}
                      isCodeOnError={!this.props.isFormValid.isCodeValid}
                      emitChange={this.props.handleChange}
                    />
                    <div
                      className="little-message error"
                      hidden={this.props.isFormValid.isCodeValid || +this.props.data.code === 0}
                    >
                      <FormattedMessage id="codeAlreadyTaken" defaultMessage="This code is already taken" />
                    </div>
                  </div>
                }
              </div>
              <div className="info-element">
                <div className="grey-title">
                  {this.props.isLinkedBc
                    ? <FormattedMessage id="aboutPlanLinked" defaultMessage="Plan of origin" />
                    : <FormattedMessage id="aboutPlan" defaultMessage="Plan" />
                  }
                </div>
                <div>
                  {this.props.data.performancePlan.name &&
                    <>{this.props.data.performancePlan.name} ({this.props.data.performancePlan.year})</>}
                </div>
              </div>
              <div className="info-element">
                <BcPiKeywords
                  data={this.props.data}
                  getElementDropdown={this.props.getElementDropdown}
                  mode={this.props.mode}
                  keywords={this.props.keywords}
                  typeActivity={TypeActivity.BUSINESS_CHALLENGE}
                  dropdownEdit={this.props.dropdownEdit}
                />
              </div>
              <div className="info-element">
                <BcFunctions
                  data={this.props.data}
                  getElementDropdown={this.props.getElementDropdown}
                  mode={this.props.mode}
                  functions={this.props.functions}
                  dropdownEdit={this.props.dropdownEdit}
                />
              </div>
            </div>
            <div className="column">

              <div className="info-element">
                <div className="grey-title">
                  <FormattedMessage id="pillar" defaultMessage="Pillar" />
                </div>
                <div>
                  {pillarList &&
                    this.props.getElementDropdown(this.props.data.pillar, 'pillar', pillarList,
                                                  this.props.mode)}
                </div>
              </div>
              <div className="info-element">
                <div className="grey-title">
                  <FormattedMessage id="aboutTitle" defaultMessage="Business challenge" />
                </div>
                <div>
                  {this.props.getElement(this.props.data.name, 'name')}
                </div>
              </div>
              <div className="info-element">
                <div className="grey-title">
                  <FormattedMessage id="isTop5" defaultMessage="Top 5" />
                </div>
                <div>
                  {Utils.isOnViewOrDeletionMode(this.props.mode)
                    ? (this.props.data.top5 ? <Icon name="check" /> : <Icon name="delete" />)
                    : <Checkbox
                      checked={this.props.data.top5}
                      onChange={this.props.onTop5Changes}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </Segment>}
      </>
    );
  }
}

export default withRouter(About);
