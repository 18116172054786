import '../metricsMilestones.scss';

import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import Utils from '../../../../../utils/utils';
import PerformanceInitiative from 'src/models/performanceInitiative';
import CustomScrollBars from 'src/components/common/customScrollBars/customScrollBars';

interface IProps {
  performanceInitiatives: PerformanceInitiative[] | undefined;
  selectedPi: number;
  selectPi(selectedPiId: number): void;
}

export default class PiSelection extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    if (this.props.performanceInitiatives === undefined) {
      return Utils.loader();
    }

    if (this.props.performanceInitiatives.length === 0) {
      return Utils.empty(<FormattedMessage id="noPI" defaultMessage="No PI was found for this Business Challenge" />);
    }

    return (
      <div id="pi-selection" className="step-container">
        <CustomScrollBars>
            {this.props.performanceInitiatives.map(pi =>
              <div key={`pi${pi.id}`} className="tile-container">
                <div
                  className={`tile ${this.props.selectedPi === pi.id ? 'selected' : ''}`}
                  onClick={() => this.props.selectPi(pi.id)}
                >
                  {this.props.selectedPi === pi.id && <Icon name="check" />} {Utils.leadingZero(pi.code)} - {pi.name}
                </div>
              </div>,
            )}
        </CustomScrollBars>
      </div>
    );
  }
}
