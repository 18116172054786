enum ActionTypes {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  LOGIN_METHOD,
  MENU_TYPE_SELECTED,
  ACTIONS_GET,
  ACTION_DELETED,
  ACTION_SAVED,
  ACTIVITIES_GET_MENU,
  ACTIVITIES_GET_MODE,
  ACTIVITY_SELECTED,
  PERFORMANCE_PLAN_SAVED,
  PERFORMANCE_PLAN_ENTITIES_LOCATIONS_GET,
  PERFORMANCE_PLAN_YEARS_GET,
  PERFORMANCE_PLAN_DELETED,
  PERFORMANCE_PLAN_REPORTINGS_GET,
  PERFORMANCE_PLAN_NEW_REPORTING,
  PERFORMANCE_PLAN_REPORTING_EDIT,
  PERFORMANCE_PLAN_NEW_BC_REPORTING,
  PERFORMANCE_PLAN_INVALIDATE_BCS,
  MOST_RECENT_PP_HISTORY_DELETED,
  BUSINESS_CHALLENGE_GET,
  BUSINESS_CHALLENGE_CHANGE,
  BUSINESS_CHALLENGE_ROLLBACK,
  BUSINESS_CHALLENGE_SAVED,
  BUSINESS_CHALLENGE_DELETED,
  BUSINESS_CHALLENGE_KEYWORDS,
  BUSINESS_CHALLENGE_FUNCTION,
  BUSINESS_CHALLENGE_GET_PIS,
  BUSINESS_CHALLENGE_ERRORS,
  PERFORMANCE_INITIATIVE_GET,
  PERFORMANCE_INITIATIVE_CHANGE,
  PERFORMANCE_INITIATIVE_ROLLBACK,
  PERFORMANCE_INITIATIVE_SAVED,
  PERFORMANCE_INITIATIVE_DELETED,
  PERFORMANCE_INITIATIVE_KEYWORDS_GET,
  PERFORMANCE_INITIATIVE_ERRORS,
  NAV_TB_SELECT_MENU,
  NOTIFICATIONS_GET,
  NOTIFICATIONS_GET_NUMBER,
  PROFILE_UPDATE,
  PROFILE_SAVE,
  SAVE_DISABLED,
  REFRESH_ACTIVITIES,
  STATS_USERS_COUNTS,
  STATS_ACTIVITIES_COUNTS,
  STATS_CONNECTIONS_COUNT,
  TOAST_NOTIFICATION,
  TIMELINE_GET,
  PUT_FEEDBACK,
  GET_FEEDBACKS,
  GET_FEEDBACKS_COUNTS,
  ADMINISTRATION_TAB_GET,
  USERS_ROLES_GET,
  CHANGE_LANGUAGE,
  GET_HISTORIES,
  GET_PP_HISTORY,
  GET_BC_HISTORY,
  GET_PI_HISTORY,
  GET_EXPLORE_ACTIVITIES,
  GET_SELECTED_ACTIVITY_TREEVIEW,
  CAN_EXPORT,
  PDF_STOP_FETCHING_PROGRESSION,
  PDF_ID_RECEIVED,
  PDF_PROGRESSION_UPDATE,
  ERROR_SET,
  ERROR_CHANGE,
  ERROR_REMOVE,
  ERROR_DELETED,
  USER_DEFAULT_PP_GET,
  USER_DEFAULT_PP_PUT,
  BC_LINKED_TO_PP,
  PI_LINKED_TO_BC,
  RELOAD_BC_COCKPIT,
  SORT_BY_GET,
  ACTIONS_FAVOURITE_STATUS_CHANGED,
  REFRESH_MILESTONES,
  INVITED_USERS,
  SET_PP_RIGHTS,
  SET_BC_RIGHTS,
  SET_PI_RIGHTS,
  SET_OPEN_NEW_PI_MODAL,
  SET_OPEN_IMPORT_PI_MODAL,
  SET_OPEN_NEW_AT_MODAL,
  SET_SEGMENT_MODE,
  RESET_SEGMENTS_MODES,
  SET_CREATED_ACTION,
  VALIDATE_DRAFT,
  ACTIVITY_PUBLISHED,
  RELOAD_TITLE_ACTIVITY,
  UPDATED_ACTIVITY,
  CURRENT_GUIDE_SET,
  GUIDE_STATUS_UPDATED,
  CURRENT_GUIDE_LOCK_UPDATED,
  CURRENT_VIEW_UPDATED,
  COMPLETED_GUIDES_GET,
  MILESTONE_CREATION,
  CHANGE_MILESTONE_MODE,
  LINK_MILESTONE_TO_BC,
  GET_MILESTONES,
  USER_SELECTED_YEARS_GET,
  USER_SELECTED_YEARS_PUT,
  GET_LINK_MILESTONE_TO_ACTION,
  SET_LINK_MILESTONE_TO_ACTION,
  DELETE_LINK_MILESTONE_TO_ACTION,
  GET_LINK_ACTION_TO_MILESTONE,
  SET_LINK_ACTION_TO_MILESTONE,
  DELETE_LINK_ACTION_TO_MILESTONE,
}

export default ActionTypes;
