import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Form, Input } from 'semantic-ui-react';

import ModeTypes from 'src/constants/modeTypes';
import Utils from '../../../../../utils/utils';
import TypeActivity from 'src/constants/typeActivity';
import ModificationDropdown from 'src/components/common/form/modificationDropdown/modificationDropdown';
import Keywords from 'src/models/keywords';
import NotificationActions from 'src/actions/notification-actions';
import { ToastType } from 'src/components/common/toast/toast';
import PerformanceInitiative from 'src/models/performanceInitiative';
import PerformanceInitiativeAPI from 'src/api/performanceInitiative.api';
import ActivitiesActions from 'src/actions/activities.action';
import BusinessChallenge from 'src/models/businessChallenge';
import BusinessChallengeAPI from 'src/api/businessChallenge.api';
import { default as BcAbout } from '../../contentBC/moreInfo/about/about';
import { default as PiAbout } from '../../contentPI/moreInfo/about/about';
import LastUpdateHighlight from '../overviewPanel/panelContent/tabs/lastUpdatedInformations/lastUpdateHighlight';

export interface IFormValid {
  isCodeValid: boolean;
  isNameValid: boolean;
  isKeywordsValid: boolean;
}

interface IRouteProps {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  typeActivity: TypeActivity;
  isLinkedPi?: boolean;
  isLinkedBc?: boolean;
  canEdit: boolean;
}

interface IStates {
  data: PerformanceInitiative | BusinessChallenge | undefined;
  oldData: PerformanceInitiative | BusinessChallenge | undefined;
  mode: ModeTypes;
  isFormValid: IFormValid;
  keywords: Keywords[];
  functions: Keywords[]; // function has the same struct as keyword
}

class BcPiAbout extends React.Component<IProps, IStates> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      data: undefined,
      oldData: undefined,
      mode: ModeTypes.MODE_VIEW,
      isFormValid: {
        isCodeValid: true,
        isNameValid: true,
        isKeywordsValid: true,
      },
      keywords: [],
      functions: [],
    };
    this.getElement = this.getElement.bind(this);
  }

  public componentDidMount() {
    LastUpdateHighlight.highlightParam();
    this.getAboutData();
    this.getKeywords();
    this.getFunctions();
  }

  private getAboutData = async () => {
    try {
      const data = Utils.isActivityBc(this.props.typeActivity)
        ? await this.getBcAbout()
        : await this.getPiAbout();
      this.setState({
        data,
        oldData: data,
      });
    } catch (error) {
      Utils.toastError();
    }
  }

  private getPiAbout = () => {
    return PerformanceInitiativeAPI.getPerformanceInitiativeAbout(+this.props.match.params.id);
  }

  private getBcAbout = () => {
    return BusinessChallengeAPI.getBusinessChallengeAbout(+this.props.match.params.id);
  }

  private getKeywords = async () => {
    try {
      const keywords = Utils.isActivityBc(this.props.typeActivity)
        ? await this.getBcKeywords()
        : await this.getPiKeywords();
      this.setState({
        keywords,
      });
    } catch (error) {
      Utils.toastError();
    }
  }

  private getFunctions = async () => {
    try {
      const functions = Utils.isActivityBc(this.props.typeActivity)
        ? await this.getBcFunctions()
        : [];
      this.setState({
        functions,
      });
    } catch (error) {
      Utils.toastError();
    }
  }

  private getPiKeywords = () => {
    return PerformanceInitiativeAPI.getKeywords();
  }

  private getBcKeywords = () => {
    return BusinessChallengeAPI.getKeywords();
  }

  private getBcFunctions = () => {
    return BusinessChallengeAPI.getFunctions();
  }

  public isPiCodeValid = (code: number): boolean => {
    if (!this.state.data) {
      return false;
    }
    const pi = this.state.data as PerformanceInitiative;
    return !pi.businessChallenge.performanceInitiatives.some(otherPi =>
      (otherPi.code === code && otherPi.id !== pi.id) || code === 0);
  };

  public isBcCodeValid = (code: number): boolean => {
    if (!this.state.data) {
      return false;
    }
    const bc = this.state.data as BusinessChallenge;
    return !bc.performancePlan.businessChallenges.some(otherBc =>
      (otherBc.code === code && otherBc.id !== bc.id) || code === 0);
  };

  /**
 * Handle form changes
 * @param event
 * @param value
 * @returns {void}
 */
  public handleChange = (event: any, { value }: any) => {
    const isFormValid = { ...this.state.isFormValid };
    switch (event.target.id) {
      case 'code':
        isFormValid.isCodeValid = Utils.isActivityBc(this.props.typeActivity)
          ? this.isBcCodeValid(+value)
          : this.isPiCodeValid(+value);
        break;
      case 'name':
        isFormValid.isNameValid = value !== '';
        break;
    }

    let newState = {
      ...this.state,
      isFormValid,
    };

    if (event.target?.id) {
      newState = {
        ...newState,
        data: {
          ...newState.data,
          [event.target.id]: event.target.id === 'code' ? +value : value,
        } as PerformanceInitiative | BusinessChallenge,
      };
    }
    this.setState(newState);
  };

  /**
   * Manage edition in any dropdown
   * @param {string} name
   * @param value
   * @returns {void}
   */
  private dropdownEdit = (name: string, value: any) => {
    let isKeywordsValid;
    const newState = { ...this.state };
    if (name === 'keywords') {
      if (value === 1) {
        isKeywordsValid = false;
      } else {
        isKeywordsValid = true;
      }
    }
    this.setState({
      ...newState,
      isFormValid: {
        ...newState.isFormValid,
        isKeywordsValid,
      },
      data: {
        ...newState.data,
        [name]: name === 'keywords' || name === 'functions' ? {
          id: value,
        } : value,
      } as PerformanceInitiative | BusinessChallenge,
    });
  }

  /**
   *  Get dropdown element depends on the mode (saved or editing)
   * @param {number} defaultValue
   * @param {string} dropdownName
   * @param options
   * @param {ModeTypes} mode
   * @param {boolean} isValueNullable
   * @param {boolean} isError
   * @returns {JSX.Element}
   */
  public getElementDropdown(defaultValue: number, dropdownName: string, options: any, mode: ModeTypes,
                            isValueNullable: boolean = false, isError: boolean = false): JSX.Element {
    let domElement: JSX.Element = <div>---</div>;

    if (Utils.isOnViewOrDeletionMode(mode)) {
      const currentObj = options.find((myObj: any) => myObj.id === defaultValue);
      if (currentObj) {
        domElement = (
          <div key={dropdownName} className="content-element">
            {currentObj.name}
          </div>
        );
      }
    } else {
      domElement = (
        <ModificationDropdown
          defaultValue={defaultValue}
          dropdownName={dropdownName}
          options={options}
          isValueNullable={isValueNullable}
          isError={isError}
          emitChange={(name, value) => this.dropdownEdit(name, value)}
        />
      );

    }
    return domElement;
  }

  /**
 * BC element displayed, depends on the mode
 * @param {string} data
 * @param {string} idElement
 * @return {JSX.Element[]} the BC element to display
 */
  public getElement(data: string, idElement: string): JSX.Element[] {
    const domElement: JSX.Element[] = [];

    if (Utils.isOnViewOrDeletionMode(this.state.mode)) {
      domElement.push(
        <pre key={idElement} className="content-element">
          {data}
        </pre>,
      );
    } else {
      const MAX_LENGTH = 90;

      if (idElement === 'name') {
        domElement.push(
          <Form key={idElement}>
            <Input
              id={idElement}
              className={`content-element ${this.state.isFormValid.isNameValid ? '' : 'error'}`}
              value={data}
              maxLength={MAX_LENGTH}
              onChange={(e, data) => this.handleChange(e, data)}
            />
          </Form>,
        );
      }
    }
    return domElement;
  }

  private onTop5Changes = () => {
    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        top5: !(prevState.data as BusinessChallenge).top5,
      } as BusinessChallenge,
    }));
  }

  private onSave = async () => {
    if (!this.state.data) {
      return;
    }

    try {
      let editedAbout = {
        ...this.state.data,
        keywordsId: this.state.data.keywords.id,
        code: +this.state.data.code,
      };
      if (Utils.isActivityBc(this.props.typeActivity)) {
        const bc : BusinessChallenge = this.state.data as BusinessChallenge;
        editedAbout = { ...editedAbout, functionsId: bc.functions.id };
      }
      Utils.isActivityBc(this.props.typeActivity)
        ? await this.saveBcAbout(editedAbout)
        : await this.savePiAbout(editedAbout);
      this.successSavedToast();
      const editedData = { ...this.state.data };
      this.reloadTitle(this.state.oldData as BusinessChallenge | PerformanceInitiative, editedData);
      this.setState({
        mode: ModeTypes.MODE_VIEW,
        oldData: editedData,
      });
      await ActivitiesActions.emitGetActivities();
      ActivitiesActions.emitUpdatedLastUpdate();
    } catch (error) {
      Utils.toastError();
    }
  }

  private reloadTitle = (oldData: BusinessChallenge | PerformanceInitiative,
                         editedData: BusinessChallenge | PerformanceInitiative) => {
    if ((oldData.name !== editedData.name || oldData.code !== editedData.code)
      || (Utils.isActivityBc(this.props.typeActivity) &&
      ((oldData as BusinessChallenge).top5 !== (editedData as BusinessChallenge).top5
      || (oldData as BusinessChallenge).pillar !== (editedData as BusinessChallenge).pillar))) {
      ActivitiesActions.emitReloadTitle();
    }
  }

  private savePiAbout = (editedAbout) => {
    return PerformanceInitiativeAPI.putPerformanceInitiativeAbout(+this.props.match.params.id, editedAbout);
  }

  private saveBcAbout = (editedAbout) => {
    return BusinessChallengeAPI.putPerformancePlanAbout(+this.props.match.params.id, editedAbout);
  }

  private successSavedToast = () => {
    const message =  Utils.isActivityBc(this.props.typeActivity) ? (
      <FormattedMessage
        id="successBcAboutUpdated"
        defaultMessage="The about segment of this Business Challenge has been successfully updated"
      />
    ) : (
      <FormattedMessage
        id="successPIAboutUpdated"
        defaultMessage="The aobut segment of fthis Performance Initiative has been successfully updated"
      />
    );
    NotificationActions.toast(
      <FormattedMessage id="saved" defaultMessage="Saved!" />,
      message,
      ToastType.SUCCESS,
    );
  }

  private onCancel = () => {
    const oldData = { ...this.state.oldData } as BusinessChallenge | PerformanceInitiative;
    this.setState({ data: oldData });
  }

  private changeMode = (mode: ModeTypes) => {
    this.setState({ mode });
  }

  public render() {
    return (
      <>
        {
          Utils.isActivityBc(this.props.typeActivity)
            ? <BcAbout
              isLinkedBc={this.props.isLinkedBc}
              data={this.state.data as BusinessChallenge}
              mode={this.state.mode}
              isFormValid={this.state.isFormValid}
              keywords={this.state.keywords}
              functions={this.state.functions}
              onSave={this.onSave}
              onCancel={this.onCancel}
              changeMode={this.changeMode}
              handleChange={this.handleChange}
              getElementDropdown={this.getElementDropdown}
              dropdownEdit={this.dropdownEdit}
              getElement={this.getElement}
              onTop5Changes={this.onTop5Changes}
              canEdit={this.props.canEdit}
            />
            : <PiAbout
              isLinkedPi={this.props.isLinkedPi}
              data={this.state.data as PerformanceInitiative}
              mode={this.state.mode}
              isFormValid={this.state.isFormValid}
              keywords={this.state.keywords}
              onSave={this.onSave}
              onCancel={this.onCancel}
              changeMode={this.changeMode}
              handleChange={this.handleChange}
              getElementDropdown={this.getElementDropdown}
              dropdownEdit={this.dropdownEdit}
              getElement={this.getElement}
              canEdit={this.props.canEdit}
            />
        }
      </>
    );
  }
}

export default withRouter(BcPiAbout);
