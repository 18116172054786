import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'semantic-ui-react';

interface IProps {
  title: string | JSX.Element;
  message: string | JSX.Element;
  closeNoFunction: () => void;
  closeYesFunction: () => void;
}

interface IStates {
  open: boolean;
}

class ConfirmModal extends React.Component<IProps, IStates> {

  constructor(props: IProps) {
    super(props);
    this.state = { open: true };
  }

  private close = (): void => this.setState({ open: false });

  private confirm = (): void => {
    this.props.closeYesFunction();
    this.close();
  };
  private cancel = (): void => {
    this.props.closeNoFunction();
    this.close();
  };

  render() {
    return (
      <div>
        <Modal
          id="confirm-modal"
          open={this.state.open}
          closeOnEscape={true}
          closeOnDimmerClick={false}
          onClose={this.cancel}
        >
          <Modal.Header>{this.props.title}</Modal.Header>
          <Modal.Content>
            <pre>{this.props.message}</pre>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.cancel}
            >
              <FormattedMessage
                id="cancel"
                defaultMessage="Cancel"
              />
            </Button>
            <Button
              onClick={this.confirm}
              positive={true}
            >
              <FormattedMessage
                id="confirm"
                defaultMessage="Confirm"
              />
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default ConfirmModal;
