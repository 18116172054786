import './metricsMilestonesTab.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from 'semantic-ui-react';

import Metric from 'src/models/metric';
import Milestone from 'src/models/milestone';
import ImportedLabel from '../../../../metricsMilestones/shared/importedLabel';
import { MetricMilestone } from '../../../../../../../constants/typeActivity';
import Utils from '../../../../../../../utils/utils';
import ModeTypes from 'src/constants/modeTypes';
import ImageModal from '../../../../metricsMilestones/metrics/chartContainer/chart/imageModal';
import MetricRow from './metricRow/metricRow';

interface IProps {
  metrics: Metric[];
  linkedMetrics: Metric[];
  milestones: Milestone[];
  linkedMilestones: Milestone[];
  metricsBc : Metric[];
}

interface IStates {
  isImageOpened: boolean;
  mode: ModeTypes;
}

class MetricsMilestonesTab extends React.Component<IProps, IStates> {
  private metric;
  private image;
  public constructor(props: IProps) {
    super(props);
    this.state = {
      mode: ModeTypes.MODE_VIEW,
      isImageOpened: false,
    };
  }

  generateMetricRow = (metric: Metric, metricsBc: Metric[]) => {
    let metricBc;
    metricBc = metricsBc.find(bcMetric => bcMetric.id === metric.id);

    return (

      <MetricRow
          metric={metric}
          metricBc={metricBc}
          setMetric={this.setMetric}
      />
    );
  };

  generateMilestoneRow = (milestone: Milestone, isLinked = false) => {
    return (
      <Table.Row key={`milestone${milestone.id}`}>
        <Table.Cell>
          {milestone.name}
          {isLinked &&
          <ImportedLabel
              ppId={milestone.performanceInitiative!!.businessChallenge.performancePlanId}
              piId={milestone.performanceInitiative!!.id}
              metricMilestone={milestone}
              type={MetricMilestone.MILESTONE}
          />}
        </Table.Cell>
        <Table.Cell className={'bold-item'}>
          {!!milestone.targetDate
            ? Utils.displayDate(milestone.targetDate)
            : <FormattedMessage id="none" defaultMessage="None"/>
          }
        </Table.Cell>
        <Table.Cell className={'bold-item'}>
          {!!milestone.completionDate
            ? Utils.displayDate(milestone.completionDate)
            : <FormattedMessage id="none" defaultMessage="None"/>
          }
        </Table.Cell>
      </Table.Row>
    );
  };

  generateMetricRows = () => {
    let metrics = [...this.props.metrics];
    for (const linkedMetric of this.props.linkedMetrics) {
      metrics.push({ ...linkedMetric, isFromLinked: true });
    }
    metrics = Utils.sortArrayByKey(metrics, 'code');
    return (
      <>
        {metrics.map((metric: Metric) => this.generateMetricRow(metric, this.props.metricsBc))}
      </>
    );
  };

  generateMilestoneRows = () => {
    return (
      <>
        {this.props.milestones.map(milestones => this.generateMilestoneRow(milestones))}
        {this.props.linkedMilestones &&
        this.props.linkedMilestones.map(milestones => this.generateMilestoneRow(milestones, true))}
      </>
    );
  };

  toggleViewChartModal = () => {
    this.setState(state => ({ isImageOpened: !state.isImageOpened }));
  };

  setMetric = (id: number | undefined, image: string) => {
    if (this.props.metricsBc.find(metric => metric.id === id)) {
      this.image = image;
      this.metric = this.props.metricsBc.find(metric => metric.id === id);
      this.toggleViewChartModal();
    }
  };

  public render() {
    return (
      <div id="metrics-milestones-table">
        <Table basic="very" collapsing={true}>
          <Table.Body>
            <Table.Row className="header-row">
              <Table.HeaderCell className="table-title" width={11}>
                <FormattedMessage
                  id="metrics"
                  defaultMessage="Metrics"
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="table-title" width={3}>
                <FormattedMessage
                  id="current"
                  defaultMessage="Current"
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="table-title" width={3}>
                <FormattedMessage
                  id="target"
                  defaultMessage="Target"
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="table-title" width={3}>
                <FormattedMessage
                  id="hasImage"
                  defaultMessage="Image"
                />
              </Table.HeaderCell>
            </Table.Row>
            {this.props.metrics.length === 0 && this.props.linkedMetrics.length === 0
              ? <Table.Row>
                <Table.Cell colSpan={3} className="no-element">
                  <FormattedMessage id="noMetrics" defaultMessage="No metrics available"/>
                </Table.Cell>
              </Table.Row>
              :
              <>
                {this.generateMetricRows()}
              </>
            }
          </Table.Body>
        </Table>

        <Table id="milestones-table" basic="very" collapsing={true}>
          <Table.Body>
            <Table.Row className="header-row">
              <Table.HeaderCell className="table-title" width={9}>
                <FormattedMessage
                  id="milestones"
                  defaultMessage="Milestones"
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="table-title" width={3}>
                <FormattedMessage
                  id="targetDate"
                  defaultMessage="Target date"
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="table-title" width={3}>
                <FormattedMessage
                  id="dateOfCompletion"
                  defaultMessage="Completion Date"
                />
              </Table.HeaderCell>
            </Table.Row>
            {this.props.milestones.length === 0 && this.props.linkedMilestones?.length === 0
              ? <Table.Row>
                <Table.Cell colSpan={3} className="no-element">
                  <FormattedMessage id="noMilestones" defaultMessage="No milestones available"/>
                </Table.Cell>
              </Table.Row>
              :
              <>
                {this.generateMilestoneRows()}
              </>
            }
          </Table.Body>
        </Table>
        {this.state.isImageOpened &&
            <ImageModal
                metric={this.metric}
                onModalClose={this.toggleViewChartModal}
                metricsCount={0}
                image={this.image}
            />
        }
      </div>
    );
  }
}

export default MetricsMilestonesTab;
