import HttpClient from '../utils/httpClient';
import User from '../models/user';
import AuthActions from '../actions/auth.actions';
import config from '../config';

export interface ILoginRes {
  token: string;
  refreshToken: string;
  user: User;
}

export default class AuthAPI {

  /**
   * POST : /api/auth/login
   * Login the user with email/password
   * @param email: string
   * @param password: string
   * @returns Promise<any>
   */
  public static loginPassword(email: string, password: string): Promise<ILoginRes> {
    return HttpClient.noToken()
    .post('auth/login', { email, password, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone });
  }

  /**
   * POST : /api/auth/check
   * Check the user's authentication
   * @param {string} timezone
   * @returns Promise<any>
   */
  public static postCheck(timezone?: string): Promise<any> {
    return HttpClient.post('auth/check', { timezone });
  }

  /**
   * Get login URL
   * @returns string
   */
  public static getSsoLoginUrl(): string {
    return `${config.api.url}sso`;
  }

  /**
   * GET : /api/sso/logout
   * Logout from SSO
   * @returns Promise<any>
   */
  public static logout(): Promise<any> {
    return HttpClient.get('sso/logout');
  }

  /**
   * POST : /api/auth/refresh
   * Refresh access token
   * @returns Promise<any>
   */
  public static refresh(): Promise<ILoginRes> {
    return HttpClient.noToken().post('auth/refresh', { refreshToken: AuthActions.getRefreshToken() });
  }
}
