import { EventEmitter } from 'events';

import Action from '../actions/action';
import ActionTypes from '../constants/actionTypes';
import Dispatcher from '../dispatcher';
import Feedback from '../models/feedback';

class FeedbackStore extends EventEmitter {
  private feedbacksList: Feedback[];
  private feedbackBugsCount: number;
  private feedbackSuggestionsCount: number;
  private feedbackOtherCount: number;
  private feedbackArchivedCount: number;

  constructor() {
    super();
    Dispatcher.register(this.registerToActions.bind(this));
  }

  /**
   * Get all feedbacks
   */
  public getFeedbacks(): Feedback[] {
    return this.feedbacksList;
  }

  /**
   * Get feedbacks bugs counts
   */
  public getFeedbacksBugsCount(): number {
    return this.feedbackBugsCount;
  }

  /**
   * Get feedbacks suggestions counts
   */
  public getFeedbacksSuggestionsCount(): number {
    return this.feedbackSuggestionsCount;
  }

  /**
   * Get feedbacks other counts
   */
  public getFeedbacksOthersCount(): number {
    return this.feedbackOtherCount;
  }

  /**
   * Get feedbacks archived counts
   */
  public getFeedbacksArchivedCount(): number {
    return this.feedbackArchivedCount;
  }

  /**
   * Set feedbacks and emit action
   * @param feedbacks Feedback[] - list of all feedbacks
   */
  private addFeedbacksToList(feedbacks: Feedback[]) {
    this.feedbacksList = feedbacks;
    this.emit(ActionTypes.GET_FEEDBACKS.toString());
  }

  /**
   * Set feedbacks counts and emit action
   * @param feedbacksCounts
   */
  private addFeedbacksCounts(feedbacksCounts: any) {
    this.feedbackBugsCount = feedbacksCounts.bugs;
    this.feedbackSuggestionsCount = feedbacksCounts.suggestions;
    this.feedbackOtherCount = feedbacksCounts.other;
    this.feedbackArchivedCount = feedbacksCounts.archived;
    this.emit(ActionTypes.GET_FEEDBACKS_COUNTS.toString());
  }

  /**
   * Emit action
   */
  private updateFeedbacks() {
    this.emit(ActionTypes.PUT_FEEDBACK.toString());
  }

  /**
   * Performs a function according to an action
   * @param action : Action
   * @return void
   */
  private registerToActions(action: Action) {
    switch (action.actionType) {
      case ActionTypes.GET_FEEDBACKS:
        this.addFeedbacksToList(action.feedbacks);
        break;
      case ActionTypes.GET_FEEDBACKS_COUNTS:
        this.addFeedbacksCounts(action.feedbacksCounts);
        break;
      case ActionTypes.PUT_FEEDBACK:
        this.updateFeedbacks();
        break;
    }
  }
}

export default new FeedbackStore();
