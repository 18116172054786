import './checkpoint.scss';

import * as React from 'react';
import { Container, Header, Loader, Message, Icon } from 'semantic-ui-react';
import ReactSVG from 'react-svg';
import logo from '../../images/logo.svg';
import AuthActions from 'src/actions/auth.actions';
import { RouteComponentProps, withRouter } from 'react-router';
import AuthAPI from '../../api/auth.api';
import { LoginMethod } from '../../constants/loginTypes';
import LoginForm from './loginForm';
import LoginError from './loginError';
import { Online, Offline } from '../connectionManager/connectionManager';
import { NavLink } from 'react-router-dom';

export enum StatusCode {
  OK = 200,
  UNAUTHORIZED = 401,
  INTERNAL_SERVER_ERROR = 500,
  NOT_FOUND = 404,
  UNPROCESSABLE_ENTITY = 422,
}

interface IProps extends RouteComponentProps {
  login(): void;
}

interface IStates {
  error: boolean;
  errorCode: StatusCode;
  pending: boolean;
  loginMethod: LoginMethod;
}

class Checkpoint extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      error: false,
      errorCode: StatusCode.OK,
      pending: true,
      loginMethod: LoginMethod.SSO,
    };
  }

  public componentDidMount() {
    const queryString = window.location.search;
    const token = new URLSearchParams(queryString).get('token');
    const refreshToken = new URLSearchParams(queryString).get('refreshToken');
    if (token && refreshToken) {
      AuthActions.setTokens({ token, refreshToken }, true);
    }
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    AuthAPI.postCheck(timezone)
      .then((json: any) => {
        this.setState({ pending: false });
        AuthActions.setUserFromJson(json);
        this.props.login();
      })
      .catch((err) => {
        if (err.reqStatus === StatusCode.UNAUTHORIZED
          || err.reqStatus === StatusCode.NOT_FOUND
          || err.reqStatus === StatusCode.UNPROCESSABLE_ENTITY
          || err.reqStatus === StatusCode.INTERNAL_SERVER_ERROR
        ) {
          this.setState({ error: true, pending: false, errorCode: err.reqStatus });
        }
      });
  }

  public render() {
    const domElement: JSX.Element[] = [];

    if (this.state.pending) {
      domElement.push(<Loader key="loader" active={true} inline={true} size="large" content="Connecting to iBoost" />);
    } else if (this.state.error) {
      if (this.state.errorCode !== StatusCode.UNAUTHORIZED) {
        domElement.push(
          <Message key="msg" error={true} header="Error" content="An error occurred while connecting to iBoost" />,
          <LoginError key="error" />,
        );
      } else {
        domElement.push(
          <LoginForm
            key="login-form"
            loginMethod={this.state.loginMethod}
            setLoginMethod={loginMethod => this.setState({ loginMethod })}
          />,
        );
      }
    }

    return (
          <div id="login">
            <div id="container-login">
              <div id="container-login-form">
                <Header as="h2" icon={true} textAlign="center" id="login-title">
                  <ReactSVG id="logoAB-login" src={logo} />
                  <Header.Content>
                    <h1>Welcome</h1>
                  </Header.Content>
                </Header>
                <Online>
                  <Container id="container" textAlign="center">
                    {domElement}
                  </Container>
                </Online>
                <Offline>
                    <h4 className="offline">
                    It seems that you are offline, please enable your network connectivity.
                    </h4>
                    <Icon className="offlineIcon" name="plug" />
                </Offline>
                <div className="user-guide-link">
                  <NavLink to="/user-guide">
                    See user guide
                  </NavLink>
                </div>
              </div>
              <div id="copyright">
                <p>THALES © - Made with ♥ at Vélizy.</p>
              </div>
            </div>
          </div>
    );
  }
}

export default withRouter(Checkpoint);
