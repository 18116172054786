import './textfields.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactSVG from 'react-svg';

import achievements from 'src/images/achievements.svg';
import decisions from 'src/images/decisions.svg';
import issues from 'src/images/issues.svg';
import next_steps from 'src/images/next_steps.svg';
import BusinessChallenge from 'src/models/businessChallenge';
import PerformanceInitiative from 'src/models/performanceInitiative';
import Utils from '../../../utils/utils';

interface IProps {
  element: BusinessChallenge | PerformanceInitiative;
  isPublicView: boolean;
}

class TextFieldsContainer extends React.Component<IProps> {

  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    return (
      <div id="status-container">
        <div className="line">
          <div className="column">
            <div id="main-achievements" className="box">
              <ReactSVG className="icon" src={achievements} />
              <div className="subtitle">
                <FormattedMessage id="mainAchievements" defaultMessage="Main achievements" />
              </div>
              <div className="four-content">
                {Utils.getFormattedTextFromHTMLString(this.props.element.achievements)}
              </div>
            </div>
          </div>
          <div className="column">
            <div id="main-issues" className="box">
              <ReactSVG className="icon" src={issues} />
              <div className="subtitle">
                <FormattedMessage id="mainIssuesAndRisks" defaultMessage="Main issues & risks" />
              </div>
              <div className="four-content">
                {Utils.getFormattedTextFromHTMLString(this.props.element.issuesAndRisks)}
              </div>
            </div>
          </div>
        </div>
        <div className="line">
          <div className="column">
            <div id="decisions" className="box">
              <ReactSVG className="icon" src={decisions} />
              <div className="subtitle">
                <FormattedMessage id="decisionsToBeMade" defaultMessage="Decisions to be made" />
              </div>
              <div className="four-content">
                {Utils.getFormattedTextFromHTMLString(this.props.element.decisionsToBeMade)}
              </div>
            </div>
          </div>
          <div className="column">
            <div id="next-steps" className="box">
              <ReactSVG className="icon" src={next_steps} />
              <div className="subtitle">
                <FormattedMessage id="nextSteps" defaultMessage="Next Steps" />
              </div>
              <div className="four-content">
                {Utils.getFormattedTextFromHTMLString(this.props.element.nextSteps)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TextFieldsContainer;
