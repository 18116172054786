import ActionTypes from '../constants/actionTypes';
import Dispatcher from '../dispatcher';
import ModeTypes from 'src/constants/modeTypes';
import { SegmentType } from '../models/segmentsMode';

export default class SegmentsActions {

  /**
   * Set mode for a segment
   * @param {string} id
   * @param {ModeTypes} modeType
   * @return {void}
   */
  public static emitSegmentMode(id: SegmentType | string, modeType: ModeTypes): void {
    Dispatcher.dispatch({
      id,
      modeType,
      actionType: ActionTypes.SET_SEGMENT_MODE,
    });
  }

  /**
   * Reset all segments modes
   * @return {void}
   */
  public static emitResetSegments(): void {
    Dispatcher.dispatch({
      actionType: ActionTypes.RESET_SEGMENTS_MODES,
    });
  }
}
