import PerformancePlan, {
  PerformancePlanGet,
  PerformancePlanGetForBcImport,
  PerformancePlanPut, PpGetLinkedActivities,
} from 'src/models/performancePlan';
import HttpClient from 'src/utils/httpClient';
import Attachment from 'src/models/attachment';
import BusinessChallenge from '../models/businessChallenge';
import IPpBusinessLocations from '../models/performancePlanBusinessLocations';
import ActivityLinkPpFilters from '../models/activityLink';
import { AssignedUser, SendTeamMailAccountDTO, UsersAssignation } from '../models/user';
import { PpApiType } from 'src/constants/performance-plan.enum';
import { AvailableRoles } from '../constants/typeActivity';
import { MemberOptions } from '../constants/member';
import { CustomOptions } from 'src/components/activitiesBoard/containerActivities/titleContainer/exportCenter/interfaces/ExportStepsOptions';
import { IBusinessLocationsValues } from 'src/components/common/form/ppGbuGeo/ppGbuGeo';
import { RightsOnActivityDTO } from 'src/models/rights';
import { ArchiveHistoryResult } from '../constants/interfaces/common.interface';

export default class PerformancePlanAPI {

  /**
   * ROUTE : /api/performance-plans
   * Get all Performance Plans by geography
   * @param element : node or link or ActivityLinkPpFilters
   * @param type : string
   * @param year : string
   * @returns Promise<PerformancePlan[]>
   */
  public static getPerformancePlansByGeography(element: any | ActivityLinkPpFilters,
                                               type: string, year?: string): Promise<PerformancePlan[]> {
    let queryParams;
    if (type === PpApiType.EXPLORE_ORGANISATION) {
      let gbu: string;
      let bl: string;
      let country: string;
      let subcountry: string;
      let geography: string;

      queryParams = {
        year,
        type: PpApiType.EXPLORE_ORGANISATION,
      };

      if (element.source) { // if link
        gbu = element.source.type === 'gbu' ? element.source.id :
          element.target.type === 'gbu' ? element.target.id : null;
        bl = element.source.type === 'bl' ? element.source.id :
          element.target.type === 'bl' ? element.target.id : null;
        country = element.target.type === 'country' ? element.target.id :
          element.target.type === 'country' ? element.target.id : null;
        subcountry = element.target.type === 'subcountry' ? element.target.id :
          element.target.type === 'subcountry' ? element.target.id : null;
        geography = element.target.type === 'geography' ? element.target.id :
          element.target.type === 'geography' ? element.target.id : null;
      } else {
        gbu = element.type === 'gbu' ? element.id : null;
        bl = element.type === 'bl' ? element.id : null;
        country = element.type === 'country' ? element.id : null;
        subcountry = element.type === 'subcountry' ? element.id : null;
        geography = element.type === 'geography' ? element.id : null;
      }

      if (gbu !== null) {
        queryParams.gbuId = gbu;
      }
      if (bl !== null) {
        queryParams.businessLineId = bl;
      }
      if (country !== null) {
        queryParams.countryId = country;
      }
      if (geography !== null) {
        queryParams.geographyId = geography;
      }
      if (subcountry !== null) {
        queryParams.subcountryId = subcountry;
      }

    } else {
      queryParams = {
        ...element,
        type: PpApiType.MENTION_ACTIVITY,
      };
    }

    return HttpClient.get<PerformancePlan[]>(`performance-plans${HttpClient.formatUrlQueryParams(queryParams)}`);
  }

  /**
 * ROUTE : /api/performance-plans/{ppId}/rights
 * Get Performance Plan rights
 * @param {number} ppId
 * @returns {Promise<RightsOnActivityDTO>}
 */
  public static getPerformancePlanRightsById(ppId: number): Promise<RightsOnActivityDTO> {
    return HttpClient.get<RightsOnActivityDTO>(`performance-plans/${ppId}/rights`);
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/global
   * Get a global Performance Plan
   * @param {number} ppId
   * @returns {Promise<PerformancePlan>}
   */
  public static getPerformancePlanGlobalById(ppId: number): Promise<PerformancePlan> {
    return HttpClient.get<PerformancePlan>(`performance-plans/${ppId}/global`);
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/validate-draft
   * Validate Performance Plan draft
   * @param ppId : number
   * @returns Promise<PerformancePlan>
   */
  public static patchPerformancePlanValidateDraft(ppId: number): Promise<PerformancePlan> {
    return HttpClient.patch<PerformancePlan>(`performance-plans/${ppId}/validate-draft`);
  }

  /**
 * ROUTE : /api/performance-plans/{ppId}/is-draft
 * Get Pp is draft
 * @param {number} ppId
 * @returns Promise<PerformancePlan>
 */
  public static getPpIsDraft(ppId: number): Promise<PerformancePlan> {
    return HttpClient.get<PerformancePlan>(`performance-plans/${ppId}/is-draft`);
  }

  /**
* ROUTE : /api/performance-plans/{ppId}/dates
* Get Pp dates
* @param {number} ppId
* @returns Promise<PerformancePlan>
*/
  public static getPpDates(ppId: number): Promise<PerformancePlan> {
    return HttpClient.get<PerformancePlan>(`performance-plans/${ppId}/dates`);
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/publish
   * Publish a Performance Plan
   * @param {number} ppId
   * @param {string} name
   * @returns Promise<PerformancePlan>
   */
  public static postPerformancePlanPublish(ppId: number, name: string): Promise<PerformancePlan> {
    return HttpClient.post<PerformancePlan>(`performance-plans/${ppId}/publish`, { name });
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/cockpit
   * Get performance plan by Id
   * @param ppId : number
   * @returns Promise<PerformancePlan>
   */
  public static async getPerformancePlanCockpitById(ppId: number): Promise<PerformancePlan> {
    const pp = await HttpClient.get<PerformancePlanGet>(`performance-plans/${ppId}/cockpit`);
    return new PerformancePlan(pp);
  }

  /**
   * ROUTE : /api/performance-plans/years
   * Get performances plan years
   * @returns Promise<string[]>
   */
  public static async getPerformancePlanYears(): Promise<string[]> {
    return HttpClient.get<string[]>('performance-plans/years');
  }

  /**
   * ROUTE : /api/performance-plans/entities-locations
   * Get all entities (GBU, Business Line, Segment) and locations (Geography, Country, Subcountry) options
   */
  public static getPpEntitiesLocations(): Promise<IPpBusinessLocations> {
    return HttpClient.get<IPpBusinessLocations>('performance-plans/business-locations');
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/bcs
   * Get performance plan's BCs
   * @param ppId : number
   * @returns Promise<PerformancePlan>
   */
  public static getPerformancePlanBcs(ppId: number): Promise<BusinessChallenge[]> {
    return HttpClient.get<BusinessChallenge[]>(`performance-plans/${ppId}/bcs`);
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/bc-codes
   * Get performance plan's BC codes
   * @param ppId : number
   * @returns Promise<PerformancePlan>
   */
  public static getPerformancePlanBcCodes(ppId: number): Promise<PerformancePlan> {
    return HttpClient.get<PerformancePlan>(`performance-plans/${ppId}/bc-codes`);
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/bc-codes
   * Get performance plan by Id
   * @param ppId : number
   * @returns Promise<PerformancePlan>
   */
  public static getPerformancePlanTreeView(ppId: number): Promise<PerformancePlan> {
    return HttpClient.get<PerformancePlan>(`performance-plans/${ppId}/tree-view`);
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/team-email
   * Get PP for the team email modale
   * @param {number} ppId
   * @returns {Promise<PerformancePlan>}
   */
  public static getPpForTeamEmail(ppId: number): Promise<PerformancePlan> {
    return HttpClient.get<PerformancePlan>(`performance-plans/${ppId}/team-email`);
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}
   * Delete performance plan by Id
   * @param ppId : number
   * @returns Promise<PerformancePlan>
   */
  public static deletePerformancePlanById(ppId: number): Promise<PerformancePlan> {
    return HttpClient.deleteWithId<PerformancePlan>(`performance-plans/${ppId}`);
  }

  /**
   * ROUTE : /api/performance-plans
   * Create a new performance plan
   * @param performancePlan : PerformancePlan
   * @returns Promise<PerformancePlan>
   */
  public static postPerformancePlan(performancePlan: PerformancePlanPut) {
    return HttpClient.post<PerformancePlan>('performance-plans', performancePlan);
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/assignations
   * Get performance plan's assignations
   * @param {number} ppId
   * @returns {Promise<PerformancePlan>}
   */
  public static getPerformancePlanAssignations(ppId: number): Promise<PerformancePlan> {
    return HttpClient.get<PerformancePlan>(`performance-plans/${ppId}/assignations`);
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/assign-owner
   * Assign owner to PP
   * @param {number} ppId
   * @param {number} ownerId
   * @returns {Promise<void>}
   */
  public static postPerformancePlanOwner(ppId: number, ownerId: number): Promise<void> {
    return HttpClient.post<void>(`performance-plans/${ppId}/assign-owner`, { ownerId });
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/assign
   * Assign users to PP
   * @param ppId : number
   * @param users : UsersAssignation
   * @returns Promise<AssignedUser[]>
   */
  public static postPerformancePlanAssignees(ppId: number, users: UsersAssignation)
    : Promise<AssignedUser[]> {
    return HttpClient.post<AssignedUser[]>(`performance-plans/${ppId}/assign`, { ...users, isAllowed: true });
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/unassign
   * Unassign users from PP
   * @param ppId : number
   * @param users : UsersAssignation
   * @returns Promise<AssignedUser[]>
   */
  public static deletePerformancePlanAssignees(ppId: number, users: UsersAssignation)
    : Promise<void> {
    return HttpClient.delete<void>(`performance-plans/${ppId}/unassign`, { ...users, isAllowed: true });
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/metrics
   * Delete a metric
   * @param ppId : number
   * @param ids : number[]
   * @returns Promise<Metrics[]>
   */
  public static deletePerformancePlanAttachments(ppId: number, ids: number[]): Promise<Attachment[]> {
    return HttpClient.delete<Attachment[]>(`performance-plans/${ppId}/attachments`, ids);
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/attachments/{metricId}
   * Edit one or multiple attachments in existing bc
   * @param ppId : number
   * @param attachments : Attachment[]
   * @returns Promise<PerformancePlanPut>
   */
  public static putPerformancePlanAttachmentsById(
    ppId: number, attachments: Attachment[],
  ): Promise<PerformancePlanPut> {
    return HttpClient.put<PerformancePlanPut>(`performance-plans/${ppId}/attachments/`, attachments);
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/attachments/{metricId}
   * Edit one or multiple attachments in existing bc
   * @param ppId : number
   * @param attachments : Attachment[]
   * @returns Promise<Attachment[]>
   */
  public static postPerformancePlanAttachments(
    ppId: number, attachments: Attachment[],
  ): Promise<Attachment[]> {
    const attachmentsWithoutTempId = attachments.map((attachment) => {
      const attachmentWithoutTempId = new Attachment();
      attachmentWithoutTempId.title = attachment.title;
      attachmentWithoutTempId.url = attachment.url;
      return attachmentWithoutTempId;
    });
    return HttpClient.post<Attachment[]>(`performance-plans/${ppId}/attachments/`, attachmentsWithoutTempId);
  }

  /**
   * ROUTE : /api/export/{ppId}/cockpit-pp
   * Export the Performance Plan Cockpit
   * @param ppId : number
   * @returns Promise<string>
   */
  public static exportCockpit(ppId: number): Promise<string> {
    return HttpClient.get<string>(`export/${ppId}/cockpit-pp`);
  }

  /**
   * ROUTE : /api/export/{ppId}/full-pp-at-list
   * Export the Performance Plan full actions list
   * @param ppId : number
   * @returns Promise<void>
   */
  public static exportAllActions(ppId: number): Promise<string> {
    return HttpClient.get<string>(`export/${ppId}/full-pp-at-list`);
  }

   /**
   * ROUTE : /api/export/{ppId}/history-pp
   * Export the Performance Plan History
   * @param ppId : number
   * @param historyId : number | undefined
   * @returns Promise<string>
   */
  public static exportHistory(ppId: number, historyId: number | undefined, isPublicView?: boolean): Promise<string> {
    return HttpClient.get<string>(`export/${ppId}/history-pp${HttpClient.formatUrlQueryParams({
      historyId,
      isPublicView,
    })}`);
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/history/{historyId}
   * Edit a PP history
   * @param {number} ppId
   * @param {number} historyId
   * @param {string} name
   * @returns {Promise<History>}
   */
  public static editHistoryName(ppId: number, historyId: number, name: string): Promise<History> {
    return HttpClient.put<History>(`performance-plans/${ppId}/history/${historyId}`, { name });
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/history/{historyId}/archive
   * Archive a PP history
   * @param {number} ppId
   * @param {number} historyId
   * @returns {Promise<ArchiveHistoryResult>}
   */
  public static archiveHistory(ppId: number, historyId: number): Promise<ArchiveHistoryResult> {
    return HttpClient.post<ArchiveHistoryResult>(`performance-plans/${ppId}/history/${historyId}/archive`, {});
  }

  /**
   * ROUTE : /api/export/{ppId}/full-pp
   * Export the whole Performance Plan
   * @param ppId : number
   * @returns Promise<string>
   */
  public static exportFull(ppId: number): Promise<string> {
    return HttpClient.get<string>(`export/${ppId}/full-pp`);
  }

  /**
   * ROUTE : /api/export/{ppId}/custom
   * Export custom Performance Plan
   * @param ppId : number
   * @param customOptions : CustomOptions (optional)
   * @returns Promise<string>
   */
  public static exportCustom(ppId: number, customOptions?: CustomOptions): Promise<string> {
    return HttpClient.post<string>(`export/${ppId}/custom`, { customOptions });
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/about
   * Get PP about information
   * @param {number} ppId
   * @returns {Promise<PerformancePlan>}
   */
  public static getPerformancePlanAbout(ppId: number): Promise<PerformancePlan> {
    return HttpClient.get<PerformancePlan>(`performance-plans/${ppId}/about`);
  }

  /**
 * ROUTE : /api/performance-plans/{ppId}/about
 * Put PP about information
 * @param {number} ppId
 * @param {IBusinessLocationsValues} ppAbout
 * @returns {Promise<PerformancePlan>}
 */
  public static putPerformancePlanAbout(ppId: number, ppAbout: IBusinessLocationsValues): Promise<void> {
    return HttpClient.put<void>(`performance-plans/${ppId}/about`, ppAbout);
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/attachments
   * Get PP attachments
   * @param {number} ppId
   * @returns {Promise<Attachment[]>}
   */
  public static getPerformancePlanAttachments(ppId: number): Promise<Attachment[]> {
    return HttpClient.get<Attachment[]>(`performance-plans/${ppId}/attachments`);
  }

  /**
   * ROUTE : /api/performance-plans/{ppId}/team-view
   * Get PP Information for Team View
   * @param ppId : number
   * @returns Promise<PerformancePlan>
   */
  public static getPerformancePlanForTeamView(ppId: number): Promise<PerformancePlan> {
    return HttpClient.get<PerformancePlan>(`performance-plans/${ppId}/team-view`);
  }

  /**
   * ROUTE : /api/performance-plans/bc-import/{piId}
   * Get PPs for BC import
   * @param ppId : number
   * @returns Promise<PerformancePlan[]>
   */
  public static getPerformancePlansForBcImport(ppId: number): Promise<PerformancePlanGetForBcImport[]> {
    return HttpClient.get<PerformancePlanGetForBcImport[]>(`performance-plans/bc-import/${ppId}`);
  }

  /**
   * ROUTE : /api/performance-plans/link-pp-list
   * Get PPs for Activity link
   * @param ppId: number
   * @param filters: IActivityLinkPpFilters
   * @returns Promise<PerformancePlan[]>
   */
  public static getPpsForActivityLink(ppId: number, filters: ActivityLinkPpFilters): Promise<PerformancePlan[]> {
    return HttpClient
      .get<PerformancePlan[]>(`performance-plans/link-pp-list/${ppId}${HttpClient.formatUrlQueryParams(filters)}`);
  }

  /**
   * ROUTE: /api/performance-plans/{ppId}/linked-activities
   * @param ppId: number
   * @return Promise<PpGetLinkedActivities>
   */
  public static getPpsLinkedActivities(ppId: number): Promise<PpGetLinkedActivities> {
    return HttpClient.get<PpGetLinkedActivities>(`performance-plans/${ppId}/linked-activities`);
  }

  /**
   * ROUTE: /api/performance-plans/{ppId}/team-members
   * @param {number} ppId
   * @param {AvailableRoles[]} roles
   * @param {number[]} bcs
   * @return {Promise<SendTeamMailAccountDTO[]>}
   */
  public static retrieveTeamMembers(ppId: number, roles: AvailableRoles[], bcs: number[])
    : Promise<SendTeamMailAccountDTO[]> {
    const memberOptions = new MemberOptions(roles, bcs);
    return HttpClient.get<SendTeamMailAccountDTO[]>(`performance-plans/${ppId}/team-members${HttpClient.formatUrlQueryParams(memberOptions)}`);
  }
}
