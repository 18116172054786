import Dispatcher from '../dispatcher';
import Constants from '../constants/actionTypes';
import Notification from 'src/models/notification';
import NotificationApi from '../api/notification.api';
import { ToastType } from '../components/common/toast/toast';

export default class NotificationActions {

  /**
   * Get notifications from the backend
   */
  public static getNotifications(): Promise<void> {
    return NotificationApi.getNotifications().then((notifications: Notification[]) => {
      Dispatcher.dispatch({
        notifications,
        actionType: Constants.NOTIFICATIONS_GET,
      });
    });
  }

   /**
   * Get notifications number from the backend
   */
  public static getNumberOfNotifications(): Promise<void> {
    return NotificationApi.getNotificationsNumber().then((notificationNmbr: any) => {
      const notificationNumber = notificationNmbr.error;
      Dispatcher.dispatch({
        notificationNumber,
        actionType: Constants.NOTIFICATIONS_GET_NUMBER,
      });
    });
  }

  /**
   * Display a toast notification
   * @param title
   * @param message
   * @param type
   * @param timeout
   */
  public static toast(title: JSX.Element, message: JSX.Element, type: ToastType, timeout?: number) {
    Dispatcher.dispatch({
      title,
      message,
      type,
      timeout,
      actionType: Constants.TOAST_NOTIFICATION,
    });
  }
}
