import React from 'react';
import Utils from '../../../../utils/utils';
import UserSelection from '../../form/userSelection/userSelection';
import User from '../../../../models/user';
import UserAvatar from '../../userAvatar/userAvatar';
import ModeTypes from '../../../../constants/modeTypes';
import './actionAssignation.scss';

interface IProps {
  mode: ModeTypes;
  assignedAccounts: User[];
  actionId: number | null;
  isActionActive: boolean;
  withTitle?: boolean;
  handleChange(field: string, users: User[]): void;
}

const MAX_ASSIGNATION_VISIBLE = 3;

class ActionAssignation extends React.Component<IProps> {

  private generateUsers = (users: User[]) => {
    return users.map((user: User) => <UserAvatar account={user} key={`assignees${user.id}`}/>);
  }

  render() {
    const shouldShowLessAssignations = this.props.assignedAccounts.length > MAX_ASSIGNATION_VISIBLE
      && !this.props.isActionActive;
    const limitedNbOfAssignations = this.props.assignedAccounts.slice(0, MAX_ASSIGNATION_VISIBLE);

    return (
      <>
        <div className="assigned-list">
          {Utils.isOnEditMode(this.props.mode)
            ? <UserSelection
              defaultValue={this.props.assignedAccounts || []}
              onChange={(users: User[]) => this.props.handleChange('assignedAccounts', users)}
            />
            : shouldShowLessAssignations
              ? <>
                {this.generateUsers(limitedNbOfAssignations)}
                {Utils.getMoreUsersLabel(this.props.assignedAccounts.length, MAX_ASSIGNATION_VISIBLE)}
              </>
              : this.generateUsers(this.props.assignedAccounts)
          }
        </div>
      </>
    );
  }
}

export default ActionAssignation;
