import UserAPI from 'src/api/user.api';
import ActionTypes from 'src/constants/actionTypes';
import Dispatcher from 'src/dispatcher';
import User, { UserPost } from 'src/models/user';
import Role from '../models/role';
import UserStore from '../stores/user.store';
import { GuideIds } from '../constants/guideConstants';

export default class UserActions {

  /**
   * Get all users roles from API and emit actions USERS_ROLES_GET
   * @return void
   */
  public static emitGetRoles(): void {
    UserAPI.getRoles()
      .then((roles: Role[]) => {
        Dispatcher.dispatch({
          roles,
          actionType: ActionTypes.USERS_ROLES_GET,
        });
      });
  }

  /**
   * Get user's completed guides from API and emit actions COMPLETED_GUIDES_GET
   * @return void
   */
  public static emitGetCompletedGuides(): void {
    UserAPI.getCompletedGuides()
      .then((completedGuides: GuideIds[]) => {
        Dispatcher.dispatch({
          completedGuides,
          actionType: ActionTypes.COMPLETED_GUIDES_GET,
        });
      });
  }

  /**
   * Post user's completed guide from API and emit actions COMPLETED_GUIDES_GET
   * @return void
   */
  public static emitAddCompletedGuides(guideId: GuideIds): void {
    UserAPI.postCompletedGuide(guideId)
      .then((completedGuides: GuideIds[]) => {
        Dispatcher.dispatch({
          completedGuides,
          actionType: ActionTypes.COMPLETED_GUIDES_GET,
        });
      });
  }

  public static emitGetDefaultPp(): Promise<void> {
    return UserAPI.getUserDefaultPpId()
      .then((user: User) => {
        Dispatcher.dispatch({
          defaultPpId: user.defaultPpId,
          actionType: ActionTypes.USER_DEFAULT_PP_GET,
        });
      });
  }

  public static emitChangeDefaultPp(defaultPpId: number | null): Promise<void> {
    return UserAPI.putUserDefaultPpId(defaultPpId)
      .then((ppId: number) => {
        Dispatcher.dispatch({
          defaultPpId: ppId,
          actionType: ActionTypes.USER_DEFAULT_PP_PUT,
        });
      });
  }

  /**
   * Save User Profile
   * @param user : UserPost
   * @param oldUser : User
   * @return void
   */
  public static emitSaveUserProfile(user: UserPost, oldUser: User): Promise<any> {
    return UserAPI.putUserProfile(user).then((user) => {
      const lang = user.language;
      if (lang !== oldUser.language) {
        UserStore.setLanguage(lang);
      }

      Dispatcher.dispatch({
        user,
        actionType: ActionTypes.PROFILE_SAVE,
      });
    });
  }

  /**
   * Get User's avatar
   * @param userId : number
   * @param callback: (image: string) => void
   * @return string (base64 image)
   */
  public static getUserAvatar(userId: number, callback: (image: string) => void): void {
    // Check store if user's avatar isn't already downloaded and saved
    let image = UserStore.getUserAvatar(userId);

    // Else, get the image from API
    if (image === undefined) {
      const index = UserStore.isFetchingAvatar.findIndex(el => el.userId === userId);
      let promise: Promise<Blob>;

      // If the request is not already registered, send it, else use it
      if (index === -1) {
        promise = UserAPI.getAvatar(userId);
        UserStore.isFetchingAvatar.push({ userId, promise });
      } else {
        promise = UserStore.isFetchingAvatar[index].promise;
      }

      // Once the promise is done, set the image correctly & remove the registration of the pending request
      promise.then((imgBlob) => {
        image = URL.createObjectURL(imgBlob);
        UserStore.setUserAvatar(userId, image);

        callback(image);

        UserStore.isFetchingAvatar.splice(index, 1);
      });
    } else {
      callback(image);
    }
  }

  public static emitGetSelectedYears(userId: number): Promise<void> {
    const minYear = 2019;
    const maxYear = new Date().getFullYear() + 1;
    const possibleYears = Array.from({ length: maxYear - (minYear - 1) }, (_, i) => (maxYear - i).toString());
    return UserAPI.getSelectedYears(userId)
      .then((year) => {
        const selectedYears: Record<string, boolean> = {};
        if (!year.selectedYears) {
          possibleYears.forEach(item => selectedYears[item] = +item >= maxYear - 2 ? true : false);
        } else {
          possibleYears.forEach(item => selectedYears[item] = year.selectedYears.includes(item) ? true : false);
        }
        Dispatcher.dispatch({
          selectedYears,
          possibleYears,
          actionType: ActionTypes.USER_SELECTED_YEARS_GET,
        });
      });
  }

  public static emitChangeSelectedYears(
    userId: number,
    years: string,
    selectedYears: Record<string, boolean>): Promise<void> {
    return UserAPI.putSelectedYears(userId, years)
      .then(() => {
        Dispatcher.dispatch({
          selectedYears,
          actionType: ActionTypes.USER_SELECTED_YEARS_PUT,
        });
      });
  }
}
