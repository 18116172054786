import Metric, { MostRecentMetricValueDto } from 'src/models/metric';
import Utils from './utils';

export default class MetricsUtils {
  public static isSameMostRecentValues = (oldMostRecentValues: MostRecentMetricValueDto,
                                          newMostRecentValues: MostRecentMetricValueDto) => {
    return !(newMostRecentValues.target !== oldMostRecentValues.target
      || newMostRecentValues.current !== null
      || (Utils.displayDate(newMostRecentValues.updateDate) !== Utils.displayDate(new Date())));
  }

  public static hasMetricChanged = (oldMetric: Metric, newMetric: Metric) => {
    return (oldMetric.comparisonTolerance !== newMetric.comparisonTolerance
      || oldMetric.comparisonType !== newMetric.comparisonType
      || oldMetric.isHidden !== newMetric.isHidden
      || oldMetric.name !== newMetric.name
      || oldMetric.unit !== newMetric.unit);
  }

  public static getLastSetCurrentValue(metric: Metric): MostRecentMetricValueDto {
    let mostRecentMetricValueWithCurrentSet = metric.mostRecentValues;

    // If the Current value is null and at least one value is set, fetch the last not null Current value
    if (!mostRecentMetricValueWithCurrentSet.current && metric.metricValues.some(mv => !!mv.current)) {
      metric.metricValues.forEach((mv) => {
        if (mv.current) mostRecentMetricValueWithCurrentSet = mv;
      });
    }

    return mostRecentMetricValueWithCurrentSet;
  }
}
