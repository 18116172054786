import './moreInfo.scss';

import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import PerformanceInitiativeActions from 'src/actions/performanceInitiative.action';
import ActionTypes from 'src/constants/actionTypes';
import ModeTypes from 'src/constants/modeTypes';
import PerformanceInitiative from 'src/models/performanceInitiative';
import BusinessChallengeStore from 'src/stores/businessChallenge.store';
import BusinessChallengeActions from '../../../../../actions/businessChallenge.action';
import Keywords from '../../../../../models/keywords';
import PerformanceInitiativeStore from '../../../../../stores/performanceInitiative.store';
import Utils from '../../../../../utils/utils';
import TypeActivity from 'src/constants/typeActivity';
import AttachmentsManagement from '../../attachmentsManagement/attachmentsManagement';
import CustomScrollBars from '../../../../common/customScrollBars/customScrollBars';
import BcPiAbout from '../../common/bcPiAbout/bcPiAbout';
import TeamSegment from '../../common/teamSegment/teamSegment';
import LastUpdateHighlight from '../../common/overviewPanel/panelContent/tabs/lastUpdatedInformations/lastUpdateHighlight';
import PerformanceInitiativeErrors from 'src/models/pi-errors';
import { RightsOnPI } from 'src/models/rights';

export interface IPiFormValid {
  isCodeValid: boolean;
  isNameValid: boolean;
  areAttachmentsValid: boolean;
}

interface IRouteProps {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  mode: ModeTypes;
  rightsOnPi: RightsOnPI | undefined;
  isLinkedPi?: boolean;
}

interface IStates {
  data: PerformanceInitiative;
  keywords: Keywords[];
  siblingPis: PerformanceInitiative[];
  errors: PerformanceInitiativeErrors;
}

class MoreInfo extends React.Component<IProps, IStates> {

  private isMount = false;

  public constructor(props: IProps) {
    super(props);
    this.state = {
      keywords: [],
      siblingPis: BusinessChallengeStore.getPIs(),
      data: PerformanceInitiativeStore.getPerformanceInitiative(),
      errors: PerformanceInitiativeStore.getErrors(),
    };
  }

  public componentWillMount() {
    this.isMount = true;

    PerformanceInitiativeStore
      .addListener(ActionTypes.PERFORMANCE_INITIATIVE_GET.toString(), this.getPerformanceInitiative);
    PerformanceInitiativeStore
      .addListener(ActionTypes.PERFORMANCE_INITIATIVE_CHANGE.toString(), this.changePerformanceInitiative);

    PerformanceInitiativeActions.emitGetKeywords();
  }

  public componentDidMount() {
    LastUpdateHighlight.highlightParam();
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>): void {
    if (prevProps.mode === ModeTypes.MODE_VIEW && this.props.mode === ModeTypes.MODE_EDITION) {
      BusinessChallengeActions.emitGetBusinessChallengePisById(this.state.data.businessChallenge.id);
    }
  }

  public componentWillUnmount() {
    this.isMount = false;
    PerformanceInitiativeStore
      .removeListener(ActionTypes.PERFORMANCE_INITIATIVE_GET.toString(), this.getPerformanceInitiative);
    PerformanceInitiativeStore
      .removeListener(ActionTypes.PERFORMANCE_INITIATIVE_CHANGE.toString(), this.changePerformanceInitiative);
  }

  private getPerformanceInitiative = () => {
    if (this.isMount) {
      const data: PerformanceInitiative = PerformanceInitiativeStore.getPerformanceInitiative();
      this.setState({ data });
    }
  };

  private changePerformanceInitiative = () => {
    if (this.isMount) {
      const data: PerformanceInitiative = PerformanceInitiativeStore.getPerformanceInitiative();
      this.setState({ data });
    }
  };

  public canEdit = () : boolean => {
    return this.props.rightsOnPi ? this.props.rightsOnPi.canEdit() : false;
  }

  public canExportWhole = () : boolean => {
    return this.props.rightsOnPi ? this.props.rightsOnPi.canExportWhole() : false;
  }

  public render() {
    if (this.state.data.businessChallenge) {
      return (
        <div className="scrollable-container">
          <CustomScrollBars id="scroll-wrapper">
            <div id="more-info-pi">
              <div className="more-info-top">
                <div className="column">
                  <div className="element">
                    <BcPiAbout
                      canEdit={this.canEdit()}
                      typeActivity={TypeActivity.PERFORMANCE_INITIATIVE}
                      isLinkedPi={this.props.isLinkedPi}
                    />
                  </div>
                </div>
                <div className="column">
                  <div className="element">
                    <TeamSegment
                      canEdit={this.canEdit()}
                      canExportWhole={this.canExportWhole()}
                      activityId={this.state.data.id}
                      activityType={TypeActivity.PERFORMANCE_INITIATIVE}
                    />
                  </div>
                  <div className="element">
                    <AttachmentsManagement
                      canEdit={this.canEdit()}
                      activityId={+this.props.match.params.id}
                      activityType={TypeActivity.PERFORMANCE_INITIATIVE}
                    />
                  </div>
                </div>
              </div>
            </div>
          </CustomScrollBars>
        </div>
      );
    }
    return Utils.loader();
  }
}

export default withRouter(MoreInfo);
