import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FilterType } from 'src/components/common/form/filter/filter';

interface IProps {
  filterSelected: FilterType;
}

class ReportModalContentTitle extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
  }
  public render() {
    switch (this.props.filterSelected) {
      case FilterType.TOP5:
        return <FormattedMessage id="pp.top5filter" defaultMessage="Top 5 Business Challenges"/>;
      case FilterType.CRITICAL:
        return <FormattedMessage id="pp.criticalBc" defaultMessage="Critical Business Challenges" />;
      case FilterType.AT_RISK:
        return <FormattedMessage id="pp.atRiskBc" defaultMessage="At Risk Business Challenges"/>;
      case FilterType.CONFIDENT:
        return <FormattedMessage id="pp.ConfidentBc" defaultMessage="Confident Business Challenges" />;
      case FilterType.GROWTH:
        return <FormattedMessage id="pp.GrowthBc" defaultMessage="Growth Business Challenges" />;
      case FilterType.PEOPLE:
        return <FormattedMessage id="pp.peopleBc" defaultMessage="People Business Challenges" />;
      case FilterType.COMPETITIVENESS:
        return <FormattedMessage id="pp.competitivenessBc" defaultMessage="Competitiveness Business Challenges" />;
      case FilterType.NONE:
      default:
        return <FormattedMessage id="pp.allBcs" defaultMessage="All Business Challenges" />;
    }
  }
}

export default ReportModalContentTitle;
