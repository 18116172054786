import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import BusinessChallenge from 'src/models/businessChallenge';
import PerformancePlan from 'src/models/performancePlan';
import PerformanceInitiative from 'src/models/performanceInitiative';
import ModeTypes from 'src/constants/modeTypes';
import CustomDropdown from '../customDropdown/customDropdown';
import TypeActivity from 'src/constants/typeActivity';
import Utils from 'src/utils/utils';
import ActivitiesStore from 'src/stores/activities.store';
import { MenuItems } from '../titleContainer';
import ActionTypes from 'src/constants/actionTypes';
import ExportStore from 'src/stores/export.store';
import ExportService from 'src/services/export.service';
import ActivitiesActions from 'src/actions/activities.action';
import BusinessChallengeAPI from 'src/api/businessChallenge.api';
import PerformanceInitiativeAPI from 'src/api/performanceInitiative.api';
import ActivityLink from '../../../../activityLink/activityLink';
import TreeView from '../../../../treeView/treeView';
import NotificationActions from '../../../../../actions/notification-actions';
import { ToastType } from '../../../../common/toast/toast';
import { PpTabs } from '../../contentPP/contentPP';
import { RightsOnBC, RightsOnPI, RightsOnPP } from 'src/models/rights';
import ModalButton from '../../modalButton/modalButton';

interface IRouteProps {
  ppId: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  data: PerformancePlan | BusinessChallenge | PerformanceInitiative;
  mode: ModeTypes;
  type: TypeActivity;
  rightsOnPp: RightsOnPP | undefined;
  rightsOnBc: RightsOnBC | undefined;
  rightsOnPi: RightsOnPI | undefined;
}

interface IStates {
  moreActionsVisible: boolean;
  seeInPlanVisible: boolean;
  isLinkToActivityModalOpened: boolean;
  isCloseValidationModalOpen: boolean;
}

class MoreActions extends React.Component<IProps, IStates> {

  private closeValidationChild = React.createRef<ModalButton>();
  private node: HTMLDivElement | null;

  public constructor(props: IProps) {
    super(props);

    this.state = {
      moreActionsVisible: false,
      seeInPlanVisible: false,
      isLinkToActivityModalOpened: false,
      isCloseValidationModalOpen: false,
    };
  }

  public componentWillMount() {
    ExportStore.addListener(ActionTypes.PDF_ID_RECEIVED.toString(), this.onPdfIdReceived);
  }

  private getPerformancePlanId(): number {
    switch (ActivitiesStore.getTypeActivity()) {
      case TypeActivity.PERFORMANCE_PLAN:
        return this.props.data.id;
      case TypeActivity.BUSINESS_CHALLENGE:
        return (this.props.data as BusinessChallenge).performancePlan.id;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        return (this.props.data as PerformanceInitiative).businessChallenge.performancePlanId;
      default:
        return 0;
    }
  }

  private handleClickMoreActionsButton = () => {
    if (!this.state.moreActionsVisible) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      moreActionsVisible: !prevState.moreActionsVisible,
    }));
  };

  private handleOutsideClick = (e: any) => {
    if (this.node !== null && this.node.contains(e.target)) {
      return;
    }

    this.handleClickMoreActionsButton();
  };

  private onPdfIdReceived = () => {
    ExportService.startPullingPdfProgression();
  };

  private openLinkModal = (isLinkToActivityModalOpened: boolean) => {
    this.setState({ isLinkToActivityModalOpened });
  }

  private openValidationModal = (isOpen: boolean) => {
    this.setState({
      isCloseValidationModalOpen: isOpen,
    });
  }

  private unlinkActivity = () => {
    let promise;
    if (Utils.isActivityBc(this.props.type)) {
      promise = BusinessChallengeAPI.unlinkBcFromPp(this.props.data.id, +this.props.match.params.ppId);
    } else if (Utils.isActivityPi(this.props.type)) {
      promise = PerformanceInitiativeAPI.unlinkPiFromPp(this.props.data.id, +this.props.match.params.ppId);
    }

    if (promise !== undefined) {
      promise.then(async () => {
        await ActivitiesActions.emitGetActivities();

        let message;
        if (Utils.isActivityBc(this.props.type)) {
          message = (
            <FormattedMessage
              id="link.bcUnlinkedFromPp"
              defaultMessage="This Business Challenge has been successfully unlinked from this Plan"
            />
          );
        } else {
          message = (
            <FormattedMessage
              id="link.piUnlinkedFromBc"
              defaultMessage="This Performance Initiative has been successfully unlinked from this BC"
            />
          );
        }

        NotificationActions.toast(
          <FormattedMessage id="link.activityUnlinked" defaultMessage="Activity unlinked!" />,
          message,
          ToastType.SUCCESS,
        );

        this.props.history
          .push(`/activities-board/performance-plan/${+this.props.match.params.ppId}/${PpTabs.COCKPIT}`);
      });
    }
  }

  private removeElement = () => {
    ActivitiesActions.emitChangeMode(ModeTypes.MODE_DELETE);
  };

  public getCanEditRights = () => {
    switch (this.props.type) {
      case TypeActivity.BUSINESS_CHALLENGE:
        return this.props.rightsOnBc?.canEdit();
      case TypeActivity.PERFORMANCE_INITIATIVE:
        return this.props.rightsOnPi?.canEdit();
      case TypeActivity.PERFORMANCE_PLAN:
        return this.props.rightsOnPp?.canEdit();
    }
  }

  public getCanDeleteRights = () => {
    switch (this.props.type) {
      case TypeActivity.BUSINESS_CHALLENGE:
        return this.props.rightsOnBc?.canDelete();
      case TypeActivity.PERFORMANCE_INITIATIVE:
        return this.props.rightsOnPi?.canDelete();
      case TypeActivity.PERFORMANCE_PLAN:
        return this.props.rightsOnPp?.canDelete();
    }
  }

  public render() {
    const dropdownMenuItems: MenuItems[] = [];

    // Close / Unclose activity
    if (!Utils.isActivityPp()) {
      if (!(this.props.data as BusinessChallenge | PerformanceInitiative).isClosed) {
        let closeText;
        if (Utils.isActivityBc()) {
          closeText = <FormattedMessage id="close.closeBC" defaultMessage="Close BC"/>;
        } else {
          closeText = <FormattedMessage id="close.closePI" defaultMessage="Close PI"/>;
        }
        dropdownMenuItems.push({
          title: closeText,
          handleClick: () => this.openValidationModal(true),
        });
      } else {
        let uncloseText;
        if (Utils.isActivityBc()) {
          uncloseText = <FormattedMessage id="close.uncloseBC" defaultMessage="Unclose BC"/>;
        } else {
          uncloseText = <FormattedMessage id="close.unclosePI" defaultMessage="Unclose PI"/>;
        }
        dropdownMenuItems.push({
          title: uncloseText,
          handleClick: () => this.closeValidationChild.current?.handleDraftValidationModalAgree(),
        });
      }
    }

    // Tree View
    if (this.props.rightsOnPp?.canViewPpCockpit()) {
      if (Utils.isActivityPp()) {
        dropdownMenuItems.push(
          {
            title: <FormattedMessage id="treeView" defaultMessage="Tree View"/>,
            handleClick: () => this.setState({ seeInPlanVisible: true }),
          },
        );
      } else {
        dropdownMenuItems.push(
          {
            title: <FormattedMessage id="seeInPlan" defaultMessage="See in plan"/>,
            handleClick: () => this.setState({ seeInPlanVisible: true }),
          },
        );
      }
    }

    // Link / Unlink
    const parentType = Utils.isActivityBc(this.props.type)
      ? TypeActivity.PERFORMANCE_PLAN
      : Utils.isActivityPi(this.props.type)
        ? TypeActivity.BUSINESS_CHALLENGE
        : undefined;

    if (
      this.getCanEditRights()
      && (
        Utils.isActivityBc(this.props.type) || Utils.isActivityPi(this.props.type)
      )
      && !(this.props.data as BusinessChallenge | PerformanceInitiative).isPpDraft
    ) {
      if ((this.props.data as BusinessChallenge | PerformanceInitiative).isLinked) {
        dropdownMenuItems.push({
          title: (
            <>
              <FormattedMessage id="link.unlink" defaultMessage="Unlink from the" />&nbsp;
              {Utils.isActivityBc(this.props.type) ? 'PP' : 'BC'}
            </>
          ),
          handleClick: this.unlinkActivity,
        });
      } else if (!(this.props.data as PerformanceInitiative).isBcLinked) {
        dropdownMenuItems.push({
          title: (
            <>
              <FormattedMessage id="link.to" defaultMessage="Link to a" />&nbsp;
              {Utils.isActivityBc(this.props.type) ? 'PP' : 'BC'}
            </>
          ),
          handleClick: () => this.openLinkModal(true),
        });
      }
    }

    // Delete
    if (this.getCanDeleteRights()) {
      dropdownMenuItems.push({
        title: (
          <>
            <FormattedMessage id="remove" defaultMessage="Remove" />&nbsp;
            {Utils.getTypeName(this.props.type)}
          </>
        ),
        handleClick: this.removeElement,
      });
    }

    if (this.props.mode === ModeTypes.MODE_VIEW) {
      return (
        <>
          <div
            ref={(node) => {
              this.node = node;
            }}
            key="more-actions-container"
          >
            <Button
              key="more-actions-dropdown"
              basic={true}
              icon="ellipsis vertical"
              id="more-actions"
              onClick={this.handleClickMoreActionsButton}
            />
          </div>

          <CustomDropdown
            key={'CustomDropdown-menu'}
            menuItems={dropdownMenuItems}
            display={this.state.moreActionsVisible}
          />

          {!Utils.isActivityPp() && <ModalButton
            ref={this.closeValidationChild}
            openValidationModal={this.openValidationModal}
            isCloseValidationModalOpen={this.state.isCloseValidationModalOpen}
            data={this.props.data as BusinessChallenge | PerformanceInitiative}
          />}

          {this.state.seeInPlanVisible &&
            <TreeView
              open={this.state.seeInPlanVisible}
              close={() => this.setState({ seeInPlanVisible: false })}
              currentActivityId={this.props.data.id}
              currentActivityType={ActivitiesStore.getTypeActivity()}
              ppId={this.getPerformancePlanId()}
            />
          }

          {this.state.isLinkToActivityModalOpened && parentType !== undefined &&
            <ActivityLink
              data={(this.props.data as BusinessChallenge | PerformanceInitiative)}
              parentType={parentType}
              close={() => this.openLinkModal(false)}
            />
          }
        </>
      );
    }

    return null;
  }
}

export default withRouter(MoreActions);
