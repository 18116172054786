import 'react-datepicker/dist/react-datepicker.css';

import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import UserSelection from '../../common/form/userSelection/userSelection';
import User from '../../../models/user';
import { BusinessChallengePost } from 'src/models/businessChallenge';

interface IProps {
  bc: BusinessChallengePost;
  onUpdate: (key: string, value: any) => void;

  setNavigationDisabled(isNavigationDisabled: boolean): void;
}

interface IStates {
  assignedUsers: User[];
}

class BcCreationStep3 extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = { assignedUsers: [] };
  }

  /**
   * Update the users in the parent component
   * @param users : User[]
   */
  private onSelectedUsersChange = (users: User[]) => {
    this.props.onUpdate('assignedAccounts', users);
  };

  public render(): React.ReactNode {
    return (
      <Form>
        <Form.Group widths="equal">
          <Form.Field>
            <FormattedMessage
              id="bcCreation.assignUsers"
              defaultMessage="Assign BC leader(s)"
            >
              {msg => (
                <div>
                  <h3>{msg}</h3>
                  <UserSelection
                    defaultValue={this.props.bc.assignedAccounts ? this.props.bc.assignedAccounts : []}
                    onChange={this.onSelectedUsersChange}
                  />
                </div>
              )}
            </FormattedMessage>
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

export default BcCreationStep3;
