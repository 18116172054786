import ModeTypes from '../constants/modeTypes';
import Utils from '../utils/utils';

export class BcPiFieldsData {
  achievements: string | undefined;
  issuesAndRisks: string | undefined;
  decisionsToBeMade: string | undefined;
  nextSteps: string | undefined;

  constructor(
    achievements?: BcPiFieldsDTO,
    issuesAndRisks?: BcPiFieldsDTO,
    decisionsToBeMade?: BcPiFieldsDTO,
    nextSteps?: BcPiFieldsDTO,
  ) {
    this.achievements = achievements?.content;
    this.issuesAndRisks = issuesAndRisks?.content;
    this.decisionsToBeMade = decisionsToBeMade?.content;
    this.nextSteps = nextSteps?.content;
  }
}

export class BcPiFieldsMode {
  achievements: ModeTypes;
  issuesAndRisks: ModeTypes;
  decisionsToBeMade: ModeTypes;
  nextSteps: ModeTypes;

  constructor() {
    this.achievements = ModeTypes.MODE_VIEW;
    this.issuesAndRisks = ModeTypes.MODE_VIEW;
    this.decisionsToBeMade = ModeTypes.MODE_VIEW;
    this.nextSteps = ModeTypes.MODE_VIEW;
  }

  hasAnyEditing = () => {
    return Utils.isOnEditMode(this.achievements) || Utils.isOnEditMode(this.issuesAndRisks) ||
      Utils.isOnEditMode(this.decisionsToBeMade) || Utils.isOnEditMode(this.nextSteps);
  }
}

export class EditorErrors {
  achievements: boolean;
  issuesAndRisks: boolean;
  decisionsToBeMade: boolean;
  nextSteps: boolean;
  objectives: boolean;
  budget: boolean;
  successFactors: boolean;

  constructor() {
    this.achievements = false;
    this.issuesAndRisks = false;
    this.decisionsToBeMade = false;
    this.nextSteps = false;
    this.objectives = false;
    this.budget = false;
    this.successFactors = false;
  }
}

export class ShowPiFields {
  achievements: boolean;
  issuesAndRisks: boolean;
  decisionsToBeMade: boolean;
  nextSteps: boolean;

  constructor() {
    this.achievements = false;
    this.issuesAndRisks = false;
    this.decisionsToBeMade = false;
    this.nextSteps = false;
  }
}

export interface BcPiFieldsDTO {
  content: string;
}
