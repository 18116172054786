import './milestoneTile.scss';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import Milestone from '../../../../../models/milestone';
import ModeTypes from '../../../../../constants/modeTypes';
import Utils from '../../../../../utils/utils';
import TypeActivity, { MetricMilestone } from '../../../../../constants/typeActivity';
import ToggleHideElement from '../../toggleHideElement/toggleHideElement';
import { Form, Input, Popup, Segment } from 'semantic-ui-react';
import ImportedLabel from '../../metricsMilestones/shared/importedLabel';
import { defaultNewMilestone } from '../../../../../constants/milestones';
import ConfirmModal from '../../confirmModal/confirmModal';
import LastUpdateHighlight from '../overviewPanel/panelContent/tabs/lastUpdatedInformations/lastUpdateHighlight';
import { SegmentType } from '../../../../../models/segmentsMode';
import SegmentEditButtons from '../segmentEditButtons/segmentEditButtons';
import MilestoneStore from '../../../../../stores/milestone.store';
import ActionTypes from '../../../../../constants/actionTypes';
import { MAX_DATE_LENGTH } from 'src/constants/date';
import { CommonDateField, MeMiAtCommonField } from '../../../../../constants/fields';
import DateField from '../../../../common/activityElements/actionDates/dateField/dateField';
import ActivityTitle from '../../../../common/activityElements/activityTitle/activityTitle';
import { IMiFormValid } from '../../../../../constants/formValid';
import moment from 'moment';
import ActionsManagement from 'src/components/activitiesBoard/containerActivities/linkedActions/actionsManagement';
import Action from 'src/models/action';
import ActionStore from 'src/stores/action.store';

interface IRouteProps {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  milestone: Milestone;
  activityType: TypeActivity;
  activityId?: number;
  parentPpId?: number;
  isAlternateDisplayForPanel?: boolean;
  isForBcView?: boolean;
  shouldResetAction?: boolean;

  saveMilestonesInState(milestone: Milestone);
  saveDateUpdate?(data: Milestone);
  onEdit(milestone: Milestone): void;
  onCancel(id: number | null);
  onDelete(milestone: Milestone);
  toggleToEdit?(milestone);
  setActionResetDone?(): void;
}

interface IStates {
  isFormValid: IMiFormValid;
  isFormCheck: boolean;
  newMilestone: Milestone;
  mode: ModeTypes;
  modeCompletionDate: ModeTypes; // mode for the completionDate in PI
  noLinkedAction: boolean;
  // this boolean is used to block the edition of completion in PI when it's determined by the linked action
  actions: Action[];
}

class MilestoneTile extends React.Component<IProps, IStates> {

  private isMount: boolean;

  public constructor(props: IProps & InjectedIntlProps) {
    super(props);

    this.state = {
      isFormValid: {
        isTargetDateValid: true,
        isCompletionDateValid: true,
        isNameValid: this.props.milestone.id !== null,
      },
      isFormCheck: false,
      newMilestone: defaultNewMilestone,
      mode: this.props.milestone.id === null ? ModeTypes.MODE_EDITION : ModeTypes.MODE_VIEW,
      modeCompletionDate: this.props.milestone.id === null ? ModeTypes.MODE_EDITION : ModeTypes.MODE_VIEW,
      noLinkedAction: true,
      actions: ActionStore.getActions(),
    };
  }

  public componentDidMount() {
    this.isMount = true;
    const allUrl = this.props.history.location.pathname;
    const indexOfMilestoneId = allUrl.search(`${this.props.milestone.id}`);
    if (indexOfMilestoneId > -1) {
      LastUpdateHighlight.highlightParam(this.props.milestone.id);
    }
    MilestoneStore.addListener(ActionTypes.CHANGE_MILESTONE_MODE.toString(), this.changeMode);
  }

  public componentWillUnmount() {
    this.isMount = false;
    MilestoneStore.removeListener(ActionTypes.CHANGE_MILESTONE_MODE.toString(), this.changeMode);
  }

  private noConfirmDeletionCancel = () => {
    this.changeMode(ModeTypes.MODE_EDITION);
  };

  private confirmDeletionCancel = () => {
    this.props.onDelete(this.props.milestone);
  };

  public handleChange = (key: string, value: any) => {
    const milestone = { ...this.props.milestone } || { ...this.state.newMilestone };
    const maxNameLength = 255;
    if (key === 'isHidden') {
      milestone[key] = value;
    } else {
      milestone[key] = value.value;
      if (key === 'name') {
        const isFormValid = { ...this.state.isFormValid };
        isFormValid.isNameValid = value.value !== '' && value.value.length <= maxNameLength;
        this.setState({ isFormValid });
      }
    }
    this.props.saveMilestonesInState(milestone);
  };

  public handleDateChange = ({ name, value }: any, save: boolean = false) => {
    const milestone = { ...this.props.milestone } || { ...this.state.newMilestone };
    let hasCompletionChanged : boolean = false;
    if (value.length > MAX_DATE_LENGTH) {
      return;
    }

    const isFormValid = { ...this.state.isFormValid };
    switch (name) {
      case CommonDateField.TARGET_DATE:
        isFormValid.isTargetDateValid = value !== '' && Utils.isDateFormatLanguageValid(value);
        break;
      case CommonDateField.COMPLETION_DATE:
        if (value !== '') {
          isFormValid.isCompletionDateValid = Utils.isDateFormatLanguageValid(value);
          hasCompletionChanged = true;
        }
        break;
    }

    milestone[name] = value !== '' ? moment(value, Utils.getDateFormat()).toDate() : null;
    this.props.saveMilestonesInState(milestone);
    this.setState({ isFormValid });
    if (save && this.props.saveDateUpdate && hasCompletionChanged) {
      this.props.saveDateUpdate(milestone);
    }
  };

  public generatePopupElementForLinkedMilestone(element: JSX.Element, isMilestoneLinked) {
    if (isMilestoneLinked) {
      const popupMessage = (
        <>
          <FormattedMessage
            id="imported.edit.notAllowed"
            defaultMessage="You can only edit at original PI level"
          />
          &nbsp;
          <b>
            PI{(!!this.props.milestone.piCode && this.props.milestone.piCode < 10) && '0'}{this.props.milestone.piCode}
          </b>
        </>
      );

      return (
        <Popup
          content={popupMessage}
          trigger={element}
          inverted={true}
          size="tiny"
          position={'top center'}
        />
      );
    }

    return element;
  }

  private getSegmentType(): SegmentType | string {
    switch (this.props.activityType) {
      case TypeActivity.BUSINESS_CHALLENGE:
        return `${SegmentType.BC_MILESTONES}-${this.props.milestone.id}`;
      case TypeActivity.PERFORMANCE_INITIATIVE:
      default:
        return `${SegmentType.PI_MILESTONES}-${this.props.milestone.id}`;
    }
  }

  public getNameFieldEdition = () => {
    const milestone = { ...this.props.milestone } || { ...this.state.newMilestone };
    const isBc = Utils.isActivityBc(this.props.activityType);
    const isMilestoneLinked = !!milestone.piCode && isBc;

    return (
    <Form className={isBc ? 'bc-display-name-input milestone-input' : 'milestone-input'}>
      <div className="grey-title"><FormattedMessage id="name" defaultMessage="Name"/></div>
      {this.generatePopupElementForLinkedMilestone(
        <div>
          <Input
            id="name"
            name="name"
            className={`${!this.state.isFormValid.isNameValid && 'error'} ${isMilestoneLinked && 'forbidden-cursor'}`}
            placeholder="Name"
            value={milestone.name || ''}
            disabled={isMilestoneLinked}
            onChange={((event, data) => this.handleChange('name', data))}
          />
        </div>,
        isMilestoneLinked,
      )}
    </Form>
    );
  }

  public setCompletionDate = (mode: ModeTypes, noLinkedAction: boolean) => {
    this.setState({ noLinkedAction, modeCompletionDate: mode });
  }

  private changeMode = (mode: ModeTypes) => {
    if (this.isMount) {
      this.setState(prevState => ({
        mode: mode ?? prevState.mode,
      }));
      if (this.state.noLinkedAction) {
        this.setState(prevState => ({
          modeCompletionDate: mode ?? prevState.mode,
        }));
      }
    }
  }

  render() {
    const isBc = Utils.isActivityBc(this.props.activityType);
    const isOnViewMode = Utils.isOnViewMode(this.state.mode);
    const isOnDeleteMode = Utils.isOnDeleteMode(this.state.mode);
    const milestone = { ...this.props.milestone } || { ...this.state.newMilestone };
    const isMilestoneLinked = !!milestone.piCode && isBc;
    let segmentId;

    if (isBc) {
      segmentId = LastUpdateHighlight.getSegmentId(SegmentType.BC_MILESTONES, milestone.id);
    } else {
      segmentId = LastUpdateHighlight.getSegmentId(SegmentType.PI_MILESTONES, milestone.id);
    }

    const isSaveDisabled = !this.state.isFormValid.isNameValid || !this.state.isFormValid.isTargetDateValid
      || !this.state.isFormValid.isCompletionDateValid;

    if (this.props.isAlternateDisplayForPanel) {
      return (
        <Segment id={segmentId} className="milestone-tile-panel">
          {Utils.isOnDeleteMode(this.state.mode) &&
            <ConfirmModal
              closeNoFunction={this.noConfirmDeletionCancel}
              closeYesFunction={this.confirmDeletionCancel}
              title="Confirm deletion"
              message="Confirm deletion ?"
            />
          }
          <div id="milestone-header">
            <div id="milestone-buttons">
              <div id="toggle-hide-milestone">
                <ToggleHideElement
                  isElementHidden={milestone.isHidden}
                  updateElementState={() => this.handleChange(MeMiAtCommonField.IS_HIDDEN,
                                                              !this.props.milestone.isHidden)}
                  viewOnly={isOnViewMode}
                />
              </div>
              <SegmentEditButtons
                mode={this.state.mode}
                segmentType={this.getSegmentType()}
                changeMode={this.changeMode}
                onCancel={this.props.onCancel}
                onSave={() => this.props.onEdit(this.props.milestone)}
                deletable={milestone.id!!}
                isSaveDisabled={isSaveDisabled}
              />
            </div>
            <div id="milestone-title">
              <div id="label-title">
                <ActivityTitle
                  activity={milestone}
                  type={TypeActivity.MILESTONE}
                  mode={this.state.mode}
                  isFormValid={this.state.isFormValid}
                  handleChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div id="milestone-body">
            {!isOnViewMode && this.getNameFieldEdition()}
            <div className="dates-area">
              <div id="dates" className="grey-title">
                <FormattedMessage id="action.dates" defaultMessage="Dates"/>
              </div>
              <div className={`milestone-dates ${!Utils.isOnViewMode(this.state.mode) ? 'editing' : ''}`}>
                <DateField
                  mode={this.state.mode}
                  dateField={CommonDateField.TARGET_DATE}
                  date={this.props.milestone.targetDate}
                  isFieldValid={this.state.isFormValid.isTargetDateValid}
                  handleDateChange={this.handleDateChange}
                  isMilestone={true}
                />
                <DateField
                  mode={this.state.modeCompletionDate}
                  dateField={CommonDateField.COMPLETION_DATE}
                  date={this.props.milestone.completionDate}
                  isFieldValid={this.state.isFormValid.isCompletionDateValid}
                  handleDateChange={this.handleDateChange}
                  isMilestone={true}
                />
              </div>
                {!isBc &&
                  <ActionsManagement
                    mode={this.state.mode}
                    canEdit={!isOnViewMode}
                    activityId={this.props.activityId!!}
                    milestoneId={this.props.milestone.id!!}
                    activityType={TypeActivity.MILESTONE}
                    shouldResetActions={this.props.shouldResetAction}
                    setActionsResetDone={this.props.setActionResetDone}
                    completionDate={this.props.milestone.completionDate}
                    setCompletionDate={this.setCompletionDate}
                    handleDateChange={this.handleDateChange}
                  />
                }
            </div>
          </div>
        </Segment>
      );
    }

    const milestoneButtons = (
      <div id="milestone-buttons">
        <SegmentEditButtons
          mode={this.state.mode}
          segmentType={this.getSegmentType()}
          changeMode={this.changeMode}
          onCancel={() => milestone.id !== undefined && this.props.onCancel(milestone.id)}
          onSave={() => this.props.onEdit(this.props.milestone)}
          onEdit={() => this.props.toggleToEdit!(this.props.milestone)}
          deletable={milestone.id !== null}
          isSaveDisabled={isSaveDisabled}
        />
      </div>
    );

    const milestoneBody = (
      <div id="milestone-body" className={this.props.isForBcView ? 'bc-display' : ''}>
        {(Utils.isActivityPi(this.props.activityType) && isOnViewMode) &&
          <span id="milestone-name">{milestone.name}</span>
        }
        {!isOnViewMode && this.getNameFieldEdition()}
        <div className={`milestone-date-container ${!isOnViewMode ? 'editing' : ''}`}>
          <DateField
            mode={this.state.mode}
            dateField={CommonDateField.TARGET_DATE}
            date={this.props.milestone.targetDate}
            isFieldValid={this.state.isFormValid.isTargetDateValid}
            handleDateChange={this.handleDateChange}
            isMilestone={true}
          />
          <DateField
            mode={this.state.modeCompletionDate}
            dateField={CommonDateField.COMPLETION_DATE}
            date={this.props.milestone.completionDate}
            isFieldValid={this.state.isFormValid.isCompletionDateValid}
            handleDateChange={this.handleDateChange}
            isMilestone={true}
          />
        </div>
        {!isBc &&
          <ActionsManagement
            mode={this.state.mode}
            canEdit={!isOnViewMode}
            activityId={this.props.activityId!!}
            milestoneId={this.props.milestone.id!!}
            activityType={TypeActivity.MILESTONE}
            shouldResetActions={this.props.shouldResetAction}
            setActionsResetDone={this.props.setActionResetDone}
            completionDate={this.props.milestone.completionDate}
            setCompletionDate={this.setCompletionDate}
            handleDateChange={this.handleDateChange}
          /> }
      </div>
    );

    return (
      <Segment id={segmentId} className={`milestone-tile ${!milestone.id ? 'editing-new' : ''}`}>
        {isOnDeleteMode &&
          <ConfirmModal
            closeNoFunction={this.noConfirmDeletionCancel}
            closeYesFunction={this.confirmDeletionCancel}
            title="Confirm deletion"
            message="Confirm deletion ?"
          />
        }
        <div id="milestone-header">
          {!milestone.id && milestoneButtons}
          <div id="milestone-title">
            <div id="label-title">
              <ActivityTitle
                activity={milestone}
                type={TypeActivity.MILESTONE}
                mode={this.state.mode}
                isFormValid={this.state.isFormValid}
                handleChange={this.handleChange}
                hideNameOnView={!this.props.isForBcView}
              />
            </div>
            {(isOnViewMode && isMilestoneLinked) &&
              <ImportedLabel
                metricMilestone={milestone}
                type={MetricMilestone.MILESTONE}
                ppId={this.props.parentPpId!!}
                piId={milestone.piId}
              />
            }
            <div id="toggle-hide-milestone">
              {this.generatePopupElementForLinkedMilestone(
                <div>
                  <ToggleHideElement
                    isElementHidden={milestone.isHidden}
                    updateElementState={() => this.handleChange(MeMiAtCommonField.IS_HIDDEN, !milestone.isHidden)}
                    isDisabled={isMilestoneLinked}
                    viewOnly={isMilestoneLinked || isOnViewMode}
                  />
                </div>,
                isMilestoneLinked,
              )}
            </div>
          </div>
          {milestone.id!! && milestoneButtons}
          {(this.props.isForBcView && Utils.isOnViewMode(this.state.mode)) && milestoneBody}
        </div>
        {!(this.props.isForBcView && Utils.isOnViewMode(this.state.mode)) && milestoneBody}
      </Segment>
    );
  }
}

export default withRouter(MilestoneTile);
