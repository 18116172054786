import { EventEmitter } from 'events';
import jwt from 'jsonwebtoken';

import Action from '../actions/action';
import AuthActions from '../constants/actionTypes';
import Dispatcher from '../dispatcher';
import User from '../models/user';
import { LoginMethod } from '../constants/loginTypes';

export enum StorageKeys {
  CONNECTED_USER = 'connectedUser',
  TOKEN = 'token',
  REFRESH_TOKEN = 'refreshToken',
  REMEMBER_ME = 'rememberMe',
}

class AuthStore extends EventEmitter {

  private loginMethod = LoginMethod.UNDEFINED;

  constructor() {
    super();
    Dispatcher.register(this.registerToActions.bind(this));
  }

  public getConnectedUser(): User {
    return JSON.parse(this.getItemInStorage(StorageKeys.CONNECTED_USER) as string);
  }

  public getToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const token = String(AuthStore.getStorage().getItem(StorageKeys.TOKEN));
        const decoded = jwt.decode(token);
        resolve(decoded);
      } catch (err) {
        reject(err);
      }
    });
  }

  public setRememberMe(rememberMe: boolean) {
    localStorage.setItem(StorageKeys.REMEMBER_ME, `${rememberMe}`);
  }

  public addLoginListener(callback: () => void) {
    this.on(AuthActions.AUTH_LOGIN.toString(), callback);
  }

  public removeLoginListener(callback: () => void) {
    this.removeListener(AuthActions.AUTH_LOGIN.toString(), callback);
  }

  public addLogoutListener(callback: () => void) {
    this.on(AuthActions.AUTH_LOGOUT.toString(), callback);
  }

  public removeLogoutListener(callback: () => void) {
    this.removeListener(AuthActions.AUTH_LOGOUT.toString(), callback);
  }

  public login(user: User) {
    this.setConnectedUser(user);
    this.emit(AuthActions.AUTH_LOGIN.toString());
  }

  public setConnectedUser(user: User) {
    AuthStore.getStorage().setItem(StorageKeys.CONNECTED_USER, JSON.stringify(user));
  }

  public setToken(key: StorageKeys, token: string) {
    AuthStore.getStorage().setItem(key, token);
  }

  public logout() {
    this.emit(AuthActions.AUTH_LOGOUT.toString());
  }

  public isConnected(): boolean {
    return !!this.getConnectedUser();
  }

  public updateProfile() {
    this.emit(AuthActions.PROFILE_UPDATE.toString());
  }

  public addUpdateProfileListener(callback: () => void) {
    this.on(AuthActions.PROFILE_UPDATE.toString(), callback);
  }

  public removeUpdateProfileListener(callback: () => void) {
    this.removeListener(AuthActions.PROFILE_UPDATE.toString(), callback);
  }

  private static getStorage(): Storage {
    let ret = sessionStorage;
    if (localStorage.getItem(StorageKeys.REMEMBER_ME) === 'true') {
      ret = localStorage;
    }
    return ret;
  }

  private updateLoginMethod(loginMethod: LoginMethod) {
    this.loginMethod = loginMethod;
  }

  public getItemInStorage(item: string) {
    return AuthStore.getStorage().getItem(item);
  }

  public getLoginMethod() {
    return this.loginMethod;
  }

  private registerToActions(action: Action) {
    switch (action.actionType) {
      case AuthActions.AUTH_LOGIN:
        this.login(action.user);
        break;
      case AuthActions.AUTH_LOGOUT:
        this.logout();
        break;
      case AuthActions.PROFILE_UPDATE:
        this.updateProfile();
        break;

      case AuthActions.LOGIN_METHOD:
        this.updateLoginMethod(action.loginMethod);
        break;
    }
  }
}

export default new AuthStore();
