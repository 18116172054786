import './cockpit.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Form, Segment } from 'semantic-ui-react';

import Utils from '../../../../../utils/utils';
import EditorGenerator from '../../ckEditorGenerator';
import CustomScrollBars from '../../../../common/customScrollBars/customScrollBars';
import LastUpdateHighlight from '../../common/overviewPanel/panelContent/tabs/lastUpdatedInformations/lastUpdateHighlight';
import { SegmentType } from '../../../../../models/segmentsMode';
import { BcPiFieldsData, BcPiFieldsDTO, BcPiFieldsMode, EditorErrors } from '../../../../../models/bcPiFields';
import { BcPiFields } from '../../../../../constants/bcPiFields';
import NotificationActions from '../../../../../actions/notification-actions';
import { ToastType } from '../../../../common/toast/toast';
import SegmentEditButtons from '../../common/segmentEditButtons/segmentEditButtons';
import ModeTypes from '../../../../../constants/modeTypes';
import PerformanceInitiativeAPI from '../../../../../api/performanceInitiative.api';
import ActivitiesActions from 'src/actions/activities.action';
import GuideStore from '../../../../../stores/guide.store';
import { VIEWS } from '../../../../../constants/guideConstants';
import GuideActions from '../../../../../actions/guide.action';
import { PiTabs } from '../contentPI';

interface IRouteProps {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  canEdit: boolean;
  setTabErrorStatus(isOnErr: boolean, tab: PiTabs): void;
}

interface IStates {

  data: BcPiFieldsData;
  oldData: BcPiFieldsData;
  mode: BcPiFieldsMode;
  isOnError: EditorErrors;
  isLoading: boolean;
}

class Cockpit extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);

    this.state = {
      data: new BcPiFieldsData(),
      oldData: new BcPiFieldsData(),
      mode: new BcPiFieldsMode(),
      isOnError: new EditorErrors(),
      isLoading: true,
    };
  }

  public componentDidMount() {
    LastUpdateHighlight.highlightParam();
    this.getData();
    this.setCurrentView();
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>, snapshot?: any) {
    this.setCurrentView();
  }

  private setCurrentView = () => {
    if (this.state.mode.hasAnyEditing() && GuideStore.shouldRunGuide(VIEWS.COCKPIT_VIEW_ON_EDIT)) {
      GuideActions.emitUpdateCurrentView(VIEWS.COCKPIT_VIEW_ON_EDIT);
    }
  }

  private getData = (field?: BcPiFields) => {
    const promises: Promise<BcPiFieldsDTO>[] = [];

    const piId = +this.props.match.params.id;

    if (field) {
      PerformanceInitiativeAPI.getPiField(piId, field)
        .then((result) => {
          const data = { ...this.state.data };
          data[field] = result.content;
          this.setState({ data, oldData: { ...data }, isLoading: false });
        })
        .catch(() => this.setState({ isLoading: false }));
    } else {
      promises.push(PerformanceInitiativeAPI.getPiField(piId, BcPiFields.MAIN_ACHIEVEMENTS));
      promises.push(PerformanceInitiativeAPI.getPiField(piId, BcPiFields.ISSUES_AND_RISKS));
      promises.push(PerformanceInitiativeAPI.getPiField(piId, BcPiFields.DECISIONS_TO_BE_MADE));
      promises.push(PerformanceInitiativeAPI.getPiField(piId, BcPiFields.NEXT_STEPS));

      Promise.all(promises)
        .then((results) => {
          const data = new BcPiFieldsData(results[0], results[1], results[2], results[3]);
          this.setState({ data, oldData: { ...data }, isLoading: false });
        })
        .catch(() => this.setState({ isLoading: false }));
    }
  }

  //region Edit/Cancel/Save methods
  private onChange = (field: BcPiFields, value: string) => {
    const data = { ...this.state.data };
    data[field] = value;

    this.setState({ data });
  }

  private onCancel = (field: BcPiFields) => {
    const data = { ...this.state.data };
    const oldData = { ...this.state.oldData };
    data[field] = oldData[field];
    const isOnError = { ...this.state.isOnError };
    isOnError[field] = false;
    this.props.setTabErrorStatus(Object.values(isOnError).includes(true), PiTabs.COCKPIT);
    this.setState({ data, isOnError });
  }

  private onSave = (field: BcPiFields) => {
    const valueToSave = this.state.data[field];
    if (valueToSave !== undefined) {
      return PerformanceInitiativeAPI.editPiField(+this.props.match.params.id, field, valueToSave)
        .then(() => {
          this.getData(field);

          NotificationActions.toast(
            <FormattedMessage id="saved" defaultMessage="Saved!" />,
            (
              <FormattedMessage
                id="assignationsSuccessfullySaved"
                defaultMessage="The assignations were successfully saved"
              />
            ),
            ToastType.SUCCESS,
          );
          ActivitiesActions.emitUpdatedLastUpdate();
        })
        .catch(() => {
          Utils.toastError();
        });
    }
    return Promise.resolve();
  }
  //endregion

  private changeMode(mode: ModeTypes, field: BcPiFields) {
    const state = { ...this.state };
    state.mode[field] = mode;
    this.setState(state);
  }

  private handleErrorOnEditor = (field: BcPiFields, isFieldOnError: boolean, callback: Function) => {
    const state = { ...this.state };
    state.isOnError[field] = isFieldOnError;
    this.props.setTabErrorStatus(Object.values(state.isOnError).includes(true), PiTabs.COCKPIT);
    this.setState(state, () => callback());
  };

  private isFieldOnErr = (field: BcPiFields) => {
    return this.state.isOnError[field] && Utils.isOnEditMode(this.state.mode[field]);
  }

  /**
   * PI element displayed, depends on the mode
   * @param {BcPiFields} field
   * @return {JSX.Element} the PI element to display
   */
  public getElement(field: BcPiFields): JSX.Element {
    const value = this.state.data[field];
    let content = <></>;
    let title;
    let segmentType;

    switch (field) {
      case BcPiFields.MAIN_ACHIEVEMENTS:
        title = <FormattedMessage id="mainAchievements" defaultMessage="Main achievements" />;
        segmentType = SegmentType.PI_MAIN_ACHIEVEMENTS;
        break;
      case BcPiFields.ISSUES_AND_RISKS:
        title = <FormattedMessage id="mainIssuesAndRisks" defaultMessage="Main issues and risks" />;
        segmentType = SegmentType.PI_MAIN_ISSUES;
        break;
      case BcPiFields.DECISIONS_TO_BE_MADE:
        title = <FormattedMessage id="decisionsToBeMade" defaultMessage="Decisions to be made" />;
        segmentType = SegmentType.PI_DECISIONS_TO_BE_MADE;
        break;
      case BcPiFields.NEXT_STEPS:
        title = <FormattedMessage id="nextSteps" defaultMessage="Next steps" />;
        segmentType = SegmentType.PI_NEXT_STEPS;
        break;
    }

    if (value !== undefined) {
      if (Utils.isOnViewOrDeletionMode(this.state.mode[field])) {
        content = (
          <CustomScrollBars className="content-element-scrollbar">
            <div key={field} className="formatted-text">
              {Utils.replaceActivityMentionToJsx(value, this)}
            </div>
          </CustomScrollBars>
        );
      } else {
        content = (
          <Form key={field}>
            <EditorGenerator
              data={value}
              onChangeAction={this.onChange}
              onErrorAction={this.handleErrorOnEditor}
              idElement={field}
            />
          </Form>
        );
      }
    }

    return (
      <div className="column">
        <Segment id={LastUpdateHighlight.getSegmentId(segmentType)}>
          <div className="title-element">
            {Utils.getTitleWithErrIcon(title, this.isFieldOnErr(field))}
            {this.props.canEdit && <SegmentEditButtons
              segmentType={segmentType}
              mode={this.state.mode[field]}
              isSaveDisabled={this.state.isOnError[field]}
              onSave={() => this.onSave(field)}
              onCancel={() => this.onCancel(field)}
              changeMode={mode => this.changeMode(mode, field)}
            />}
          </div>
          <div className="content-element">
            {content}
          </div>
        </Segment>
      </div>
    );
  }

  public render() {
    const firstLineEditCondition = Utils.isOnEditOrCancelMode(this.state.mode.achievements)
      || Utils.isOnEditOrCancelMode(this.state.mode.issuesAndRisks);

    const secondLineEditCondition = Utils.isOnEditOrCancelMode(this.state.mode.decisionsToBeMade)
      || Utils.isOnEditOrCancelMode(this.state.mode.nextSteps);

    return (
      <div className={'scrollable-container'}>
        <CustomScrollBars id="scroll-wrapper">
          <div id="global-state-pi">
            <div className={`line text-container ${firstLineEditCondition ? 'editing' : ''}`}>
              {this.getElement(BcPiFields.MAIN_ACHIEVEMENTS)}
              {this.getElement(BcPiFields.ISSUES_AND_RISKS)}
            </div>
            <div className={`line text-container ${secondLineEditCondition ? 'editing' : ''}`}>
              {this.getElement(BcPiFields.DECISIONS_TO_BE_MADE)}
              {this.getElement(BcPiFields.NEXT_STEPS)}
            </div>
          </div>
        </CustomScrollBars>
      </div>
    );
  }
}

export default withRouter(Cockpit);
