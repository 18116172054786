enum TypeActivity {
  PERFORMANCE_PLAN,
  BUSINESS_CHALLENGE,
  PERFORMANCE_INITIATIVE,
  ACTION,
  MILESTONE,
}

export default TypeActivity;

export enum MetricMilestone {
  METRIC = 4,
  MILESTONE = 5,
}

export enum MenuTypeSelected {
  MENU_ACTIVITY,
  MENU_TASKBOARD,
}

export enum roleActivity {
  REFERENT,
  LEADER,
  DEPUTY,
  SHARED,
}

export enum AvailableRoles {
  REFERENT,
  PP_INFORMED,

  BC_LEADERS,
  BC_INFORMED,

  PI_LEADERS,
  PI_INFORMED,

  AT_OWNERS,
}
