import * as React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import PerformancePlanActions from 'src/actions/performancePlan.action';
import NotificationActions from 'src/actions/notification-actions';
import Utils from 'src/utils/utils';
import PerformancePlan from 'src/models/performancePlan';
import { ToastType } from 'src/components/common/toast/toast';
import ConfirmModal from '../../confirmModal/confirmModal';
import ActivitiesActions from '../../../../../actions/activities.action';
import AssignationEmailModal from '../../assignationEmailModal/assignationEmailModal';
import { AssignedUser } from '../../../../../models/user';
import PerformancePlanAPI from '../../../../../api/performancePlan.api';
import { AssignationType } from '../../../../../constants/account';

interface IProps {
  data: PerformancePlan;
  assignedUsers: AssignedUser[];
}

interface IStates {
  draftValidationModalOpen: boolean;
}

class DraftValidationButton extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      draftValidationModalOpen: false,
    };
  }

  private handleDraftValidationModalClose = () => {
    this.setState({ draftValidationModalOpen: false });
  };

  private handleDraftValidationModalAgree = () => {
    this.setState({ draftValidationModalOpen: false });
    this.getPPAssignedUsers();
    PerformancePlanActions.emitValidateDraft(this.props.data.id)
      .then(() => {
        NotificationActions.toast(
          <FormattedMessage id="draft.draftValidated" defaultMessage="Draft validated!" />,
          <FormattedMessage id="draft.draftValidatedMessage" defaultMessage="This Plan draft has been validated!" />,
          ToastType.SUCCESS,
        );
        AssignationEmailModal.popUpEmailValidationButtonDraft(this.props.assignedUsers);
        ActivitiesActions.emitReloadTitle();
      })
      .catch(() => Utils.toastError());
  };

  private getPPAssignedUsers = async () => {
    const ppUsersAssigned = await PerformancePlanAPI.getPerformancePlanAssignations(+this.props.data.id);
    ppUsersAssigned.informedAccounts.forEach((user) => {
      const assigned: AssignedUser =
        { activityId: this.props.data.id, assignationType: AssignationType.PP_INFORMED,
          email: user.email, id: user.id };
      this.props.assignedUsers.push(assigned);
    });

  }
  public render() {
    const message = (
      <>
        <FormattedMessage id="draft.validateDraftQuestion" defaultMessage="Are you sure you want to validate this draft?" />
        &nbsp;
        <FormattedMessage id="actionCantBeReverted" defaultMessage="This action cannot be reverted." />
        <br /><br />
        <FormattedMessage
          id="draft.planDraftValidationExplanation"
          defaultMessage="When a Plan is no longer a draft, the team members assigned to BCs, PIs and actions underneath will be able to see this Plan, and receive notifications related to its activities."
        />
      </>
    );

    return (
      <>
        <Button
          key="validate-draft-button"
          basic={true}
          onClick={() => this.setState({ draftValidationModalOpen: true })}
        >
          <Icon name="checkmark" />
          <FormattedMessage id="draft.validateDraft" defaultMessage="Validate draft"/>&nbsp;
        </Button>
        {this.state.draftValidationModalOpen &&
          <ConfirmModal
            closeNoFunction={this.handleDraftValidationModalClose}
            closeYesFunction={this.handleDraftValidationModalAgree}
            title={<FormattedMessage id="draft.draftValidation" defaultMessage="Draft validation"/>}
            message={message}
          />
        }
      </>
    );
  }
}

export default DraftValidationButton;
