import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Segment } from 'semantic-ui-react';

import ModeTypes from 'src/constants/modeTypes';
import Utils from '../../../../../../utils/utils';
import BcPiKeywords from '../../../bcPiKeywords/bcPiKeywords';
import TypeActivity from 'src/constants/typeActivity';
import CodeInput from 'src/components/common/form/codeInput/codeInput';
import Keywords from 'src/models/keywords';
import PerformanceInitiative from 'src/models/performanceInitiative';
import SegmentEditButtons from '../../../common/segmentEditButtons/segmentEditButtons';
import LastUpdateHighlight from '../../../common/overviewPanel/panelContent/tabs/lastUpdatedInformations/lastUpdateHighlight';
import { SegmentType } from 'src/models/segmentsMode';

export interface IPiFormValid {
  isCodeValid: boolean;
  isNameValid: boolean;
  isKeywordsValid: boolean;
}

interface IRouteProps {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  isLinkedPi?: boolean;
  data: PerformanceInitiative | undefined;
  mode: ModeTypes;
  isFormValid: IPiFormValid;
  keywords: Keywords[];
  onSave: () => Promise<void>;
  onCancel: () => void;
  changeMode: (mode: ModeTypes) => void;
  handleChange: (event: any, { value }: any) => void;
  getElementDropdown: (defaultValue: number, dropdownName: string, options: any, mode: ModeTypes,
                       isValueNullable?: boolean, isError?: boolean) => JSX.Element;
  dropdownEdit: (name: string, value: any) => void;
  getElement: (data: string, idElement: string) => JSX.Element[];
  canEdit: boolean;
}

interface IStates {
}

class About extends React.Component<IProps, IStates> {

  public render() {
    return (
      <>
        {this.props.data && <Segment id={LastUpdateHighlight.getSegmentId(SegmentType.PI_ABOUT)}>
          {this.props.canEdit && <SegmentEditButtons
            segmentType={SegmentType.PI_ABOUT}
            mode={this.props.mode}
            onSave={this.props.onSave}
            onCancel={this.props.onCancel}
            changeMode={this.props.changeMode}
          />}
          <div className="title-element">
            <FormattedMessage id="aboutPI" defaultMessage="About the Performance Initiative" />
          </div>
          <div className="content-element">
            <div className="column">
              <div className="info-element">
                <div className="grey-title">
                  <FormattedMessage id="piNumber" defaultMessage="PI number" />
                </div>
                {Utils.isOnViewOrDeletionMode(this.props.mode)
                  ? <div>{Utils.leadingZero(this.props.data.code)}</div>
                  : <div>
                    <CodeInput
                      defaultValue={this.props.data.code}
                      isCodeOnError={!this.props.isFormValid.isCodeValid}
                      emitChange={this.props.handleChange}
                    />
                    <div className="little-message error" hidden={this.props.isFormValid.isCodeValid}>
                      <FormattedMessage id="codeAlreadyTaken" defaultMessage="This code is already taken" />
                    </div>
                  </div>
                }
              </div>
              <div className="info-element">
                <div className="info-element">
                  <div className="grey-title">
                    <FormattedMessage id="pillar" defaultMessage="Pillar" />
                  </div>
                  <div>
                    {Utils.getPillarName(this.props.data.businessChallenge.pillar)}
                  </div>
                </div>
                <div className="info-element">
                  <div className="grey-title">
                    <FormattedMessage id="bcKeywords" defaultMessage="BC keywords" />
                  </div>
                  <div>
                    {this.props.data.businessChallenge.keywords
                      ? this.props.data.businessChallenge.keywords.name
                      : <FormattedMessage id="none" defaultMessage="None" />
                    }
                  </div>
                </div>
                <div className="info-element">
                  <div className="grey-title">
                    <FormattedMessage id="bcFunctions" defaultMessage="Functions" />
                  </div>
                  <div>
                    {this.props.data.businessChallenge.functions
                      ? this.props.data.businessChallenge.functions.name
                      : <FormattedMessage id="none" defaultMessage="None" />
                    }
                  </div>
                </div>
                <div className="info-element">
                  <div className="grey-title">
                    {this.props.isLinkedPi
                      ? <FormattedMessage id="aboutBCLinked" defaultMessage="Business challenge of origin" />
                      : <FormattedMessage id="aboutBC" defaultMessage="Business challenge" />
                    }
                  </div>
                  <div>
                    {this.props.isLinkedPi &&
                      <>
                        <span>
                          {this.props.data.businessChallenge.performancePlan.name}
                          &nbsp;
                          ({this.props.data.businessChallenge.performancePlan.year})
                        </span>
                        <br />
                      </>
                    }
                    {Utils.formatActivityName(
                      this.props.data.businessChallenge.code,
                      this.props.data.businessChallenge.name,
                      TypeActivity.BUSINESS_CHALLENGE,
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="info-element">
                <BcPiKeywords
                  data={this.props.data}
                  getElementDropdown={this.props.getElementDropdown}
                  mode={this.props.mode}
                  keywords={this.props.keywords}
                  typeActivity={TypeActivity.PERFORMANCE_INITIATIVE}
                  dropdownEdit={this.props.dropdownEdit}
                />
              </div>
              <div className="info-element">
                <div className="grey-title">
                  <FormattedMessage id="aboutTitle" defaultMessage="Title" />
                </div>
                <div>
                  {this.props.getElement(this.props.data.name, 'name')}
                </div>
              </div>
            </div>
          </div>
        </Segment>}
      </>
    );
  }
}

export default withRouter(About);
