import * as React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';

import { Pillars } from 'src/constants/pillarEnums';
import RoleNames from '../../../../constants/roleNames';

export enum FilterCategory {
  NONE = 'none',
  STATUS = 'status',
  TOP5 = 'top5',
  PILLAR = 'pillar',
  ROLE = 'roleId',
}

export enum FilterType {
  NONE = 0,

  REFERENT = RoleNames.REFERENT,
  BC_LEADER = RoleNames.BC_LEADER,
  PI_LEADER = RoleNames.PI_LEADER,
  ACTION_OWNER = RoleNames.ACTION_OWNER,
  INFORMED = RoleNames.INFORMED,
  BASIC_USER= RoleNames.BASIC_USER,

  TOP5 = 8,

  CRITICAL = 9,
  AT_RISK = 10,
  CONFIDENT = 11,

  GROWTH = 12,
  COMPETITIVENESS = 13,
  PEOPLE = 14,
}

export function getFilterValue(filter: FilterType) {
  const filterValues: { type: FilterType, value: any }[] = [
    { type: FilterType.NONE, value: 0 },

    { type: FilterType.TOP5, value: true },

    { type: FilterType.CRITICAL, value: 1 },
    { type: FilterType.AT_RISK, value: 2 },
    { type: FilterType.CONFIDENT, value: 3 },

    { type: FilterType.GROWTH, value: Pillars.GROWTH },
    { type: FilterType.COMPETITIVENESS, value: Pillars.COMPETITIVENESS },
    { type: FilterType.PEOPLE, value: Pillars.PEOPLE },

    { type: FilterType.REFERENT, value: RoleNames.REFERENT },
    { type: FilterType.BC_LEADER, value: RoleNames.BC_LEADER },
    { type: FilterType.PI_LEADER, value: RoleNames.PI_LEADER },
    { type: FilterType.ACTION_OWNER, value: RoleNames.ACTION_OWNER },
    { type: FilterType.BASIC_USER, value: RoleNames.BASIC_USER },
    { type: FilterType.INFORMED, value: RoleNames.INFORMED },
  ];

  const filterValue = filterValues.find(el => el.type === filter);

  if (!!filterValue) return filterValue.value;
  return undefined;
}

interface FilterDropdownValues {
  name: string;
  type: FilterType;
  category: FilterCategory;
}

interface IProps extends InjectedIntlProps {
  dropdownName: string;
  filters: FilterCategory[];
  selectedFilter: FilterType;
  arrayToFilter: any[];

  setFilteredArray(filteredArray: any[], selectedFilter: FilterType): void;
}

class Filter extends React.Component<IProps> {

  public constructor(props: IProps) {
    super(props);
  }

  private setSelectedFilters = (): (FilterDropdownValues)[] => {
    const selectedFilters: (FilterDropdownValues)[] = [];

    selectedFilters.push({
      name: this.props.intl.formatMessage({ id: 'none', defaultMessage: 'None' }),
      type: FilterType.NONE,
      category: FilterCategory.NONE,
    });

    this.props.filters.forEach((filter) => {
      switch (filter) {
        case FilterCategory.STATUS:
          selectedFilters.push(
            {
              name: this.props.intl.formatMessage({ id: 'critical', defaultMessage: 'Critical' }),
              type: FilterType.CRITICAL,
              category: filter,
            },
            {
              name: this.props.intl.formatMessage({ id: 'atRisk', defaultMessage: 'At Risk' }),
              type: FilterType.AT_RISK,
              category: filter,
            },
            {
              name: this.props.intl.formatMessage({ id: 'confident', defaultMessage: 'Confident' }),
              type: FilterType.CONFIDENT,
              category: filter,
            },
          );
          break;

        case FilterCategory.TOP5:
          selectedFilters.push(
            {
              name: 'TOP 5',
              type: FilterType.TOP5,
              category: filter,
            },
          );
          break;

        case FilterCategory.PILLAR:
          selectedFilters.push(
            {
              name: this.props.intl.formatMessage({ id: 'growth', defaultMessage: 'Growth' }),
              type: FilterType.GROWTH,
              category: filter,
            },
            {
              name: this.props.intl.formatMessage({ id: 'competitiveness', defaultMessage: 'Competitiveness' }),
              type: FilterType.COMPETITIVENESS,
              category: filter,
            },
            {
              name: this.props.intl.formatMessage({ id: 'people', defaultMessage: 'People' }),
              type: FilterType.PEOPLE,
              category: filter,
            },
          );
          break;

        case FilterCategory.ROLE:
          selectedFilters.push(
            {
              name: this.props.intl.formatMessage({ id: 'referent', defaultMessage: 'Referent' }),
              type: FilterType.REFERENT,
              category: filter,
            },
            {
              name: 'BC Leader',
              type: FilterType.BC_LEADER,
              category: filter,
            },
            {
              name: 'PI Leader',
              type: FilterType.PI_LEADER,
              category: filter,
            },
            {
              name: 'Action Leader',
              type: FilterType.ACTION_OWNER,
              category: filter,
            },
            {
              name: this.props.intl.formatMessage({ id: 'basicUser', defaultMessage: 'Basic User' }),
              type: FilterType.BASIC_USER,
              category: filter,
            },
            {
              name: this.props.intl.formatMessage({ id: 'informed', defaultMessage: 'Informed' }),
              type: FilterType.INFORMED,
              category: filter,
            },
          );
          break;
      }
    });

    return selectedFilters;
  };

  private filterArray = (filterCategory: FilterCategory, filterType: FilterType) => {
    let filteredArray;

    if (filterCategory === FilterCategory.NONE) {
      filteredArray = this.props.arrayToFilter;
    } else {
      filteredArray = this.props.arrayToFilter.filter(el => el[filterCategory] === getFilterValue(filterType));
    }

    this.props.setFilteredArray(filteredArray, filterType);
  };

  public render() {
    const selectedFilters = this.setSelectedFilters();
    let previousCategory = FilterCategory.NONE;

    return (
      <Dropdown
        id={'filterButton'}
        text={this.props.dropdownName}
        icon="filter"
        labeled={true}
        button={true}
        basic={true}
        className="icon"
      >
        <Dropdown.Menu>
          <Dropdown.Menu scrolling={true}>
            {selectedFilters.map((filter) => {
              let divider;
              if (previousCategory !== filter.category) {
                divider = <Dropdown.Divider />;
                previousCategory = filter.category;
              }

              return (
                <React.Fragment key={filter.name}>
                  {divider}
                  <Dropdown.Item
                    toggle={'true'}
                    content={filter.name}
                    selected={this.props.selectedFilter === filter.type}
                    active={this.props.selectedFilter === filter.type}
                    onClick={() => this.filterArray(
                      !!filter.category ? filter.category : FilterCategory.NONE,
                      !!filter.type ? filter.type : FilterType.NONE,
                    )}
                  />
                </React.Fragment>
              );
            })}
          </Dropdown.Menu>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default injectIntl(Filter);
