import './notificationList.scss';

import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';

import Notification from 'src/models/notification';
import NotificationTile from './notificationTile/notificationTile';
import NotificationActions from '../../actions/notification-actions';
import NotificationStore from 'src/stores/notification.store';
import NotificationApi from '../../api/notification.api';
import Utils from '../../utils/utils';
import CustomScrollBars from '../common/customScrollBars/customScrollBars';

enum NotificationGroup {
  TODAY,
  LAST_WEEK,
  EARLIER,
}

interface IProps extends RouteComponentProps{
  isInTopBar?: boolean;
  setNotificationListVisibility?: (isVisible: boolean) => void;
  setNotificationModalVisibility?: (isVisible: boolean) => void;
}

interface IStates {
  notifications: Notification[];
  comment: string;
  markedAsRead: number[];
  isLoading: boolean;
  notificationId?: number;
}

class NotificationList extends React.Component<IProps, IStates> {

  private isMount = false;

  constructor(props: IProps) {
    super(props);
    this.state = {
      notifications: NotificationStore.getNotifications(),
      comment: '',
      markedAsRead: [],
      isLoading: true,
    };
  }

  public componentDidMount(): void {
    this.isMount = true;
    NotificationStore.addNotificationsChangeListener(this.setNotifications);
    this.getNotifications();
    NotificationActions.getNumberOfNotifications();
  }

  public componentWillUnmount(): void {
    this.isMount = false;
    NotificationStore.removeNotificationsChangeListener(this.setNotifications);
  }

  private getNotifications = () => {
    NotificationActions.getNotifications()
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  };

  private setNotifications = () => {
    if (this.isMount) {
      this.setState({ notifications: NotificationStore.getNotifications() });
    }
  };

  public onChangeComment = (e: any, data: any) => {
    this.setState({ comment: data.value });
  };

  private markAsRead = (ids: number[]) => {
    NotificationApi.putMarkNotificationAsRead(ids).then(() => {
      this.getNotifications();
      NotificationActions.getNumberOfNotifications();
    });
  };

  private static buildGroupHeader(group: NotificationGroup, isOnlyOneGroup: boolean): JSX.Element | null {
    let message: JSX.Element | null = null;
    if (!isOnlyOneGroup) {
      switch (group) {
        case NotificationGroup.TODAY:
          message = <FormattedMessage id="notifications.today" defaultMessage="Today" />;
          break;

        case NotificationGroup.LAST_WEEK:
          message = <FormattedMessage id="notifications.lastWeek" defaultMessage="Last Week" />;
          break;

        case NotificationGroup.EARLIER:
          message = <FormattedMessage id="notifications.earlier" defaultMessage="Earlier" />;
          break;
      }

      return <span className="notification-group-title">{message}</span>;
    }

    return null;
  }

  private buildNotificationTile(notification: Notification, isInTopBar: boolean) {
    return (
      <NotificationTile
        key={`notification-tile ${notification.id}`}
        notification={notification}
        markAsRead={this.markAsRead}
        isInTopBar={isInTopBar}
        setNotificationListVisibility={this.props.setNotificationListVisibility}
      />
    );
  }

  private seeInTaskboard = () => {
    this.props.history.push('/activities-board/taskboard');
    if (!!this.props.setNotificationListVisibility) this.props.setNotificationListVisibility(false);
  };

  public render() {
    if (this.state.isLoading) {
      return (
        <div id="notification-empty-list">
          {Utils.loader()}
        </div>
      );
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const lastWeek = new Date();
    lastWeek.setDate(today.getDate() - 7);
    lastWeek.setHours(0, 0, 0, 0);

    let todayNotifications: Notification[] = [];
    let lastWeekNotifications: Notification[] = [];
    let earlierNotifications: Notification[] = [];

    if (this.state.notifications) {
      todayNotifications = this.state.notifications.filter(el => new Date(el.createdAt) >= today);

      lastWeekNotifications = this.state.notifications
        .filter(el => new Date(el.createdAt) >= lastWeek && new Date(el.createdAt) < today);

      earlierNotifications = this.state.notifications.filter(el => new Date(el.createdAt) < lastWeek);
    }

    const isOnlyOneGroup = (todayNotifications.length === 0 && lastWeekNotifications.length === 0)
      || (lastWeekNotifications.length === 0 && earlierNotifications.length === 0)
      || (todayNotifications.length === 0 && earlierNotifications.length === 0);

    const isInTopBar = this.props.isInTopBar !== undefined && this.props.isInTopBar;

    if (!!this.state.notifications && this.state.notifications.length > 0) {
      return (
        <div id="notification-list" className={isInTopBar ? 'top-bar' : ''}>
          <>
            <div id="notification-header">
              <span className="count">
                <FormattedMessage id="notifications" defaultMessage="Notifications"/>
                {` (${NotificationStore.getNumberOfNotifications()})`}
              </span>
              {this.state.notifications.some(el => !el.isSeen)
                && <span className="action" onClick={() => this.markAsRead(this.state.notifications.map(el => el.id))}>
                  <FormattedMessage id="notifications.markAllAsRead" defaultMessage="Mark all as read"/>
                </span>
              }
            </div>

            <CustomScrollBars id="notification-list-scrollbars">
              {todayNotifications.length > 0
                && <>
                  {NotificationList.buildGroupHeader(NotificationGroup.TODAY, isOnlyOneGroup)}
                  <div className="notification-group">
                    {todayNotifications.map(el => this.buildNotificationTile(el, isInTopBar))}
                  </div>
                </>
              }

              {lastWeekNotifications.length > 0
                && <>
                  {NotificationList.buildGroupHeader(NotificationGroup.LAST_WEEK, isOnlyOneGroup)}
                  <div className="notification-group">
                    {lastWeekNotifications.map(el => this.buildNotificationTile(el, isInTopBar))}
                  </div>
                </>
              }

              {earlierNotifications.length > 0
                && <>
                  {NotificationList.buildGroupHeader(NotificationGroup.EARLIER, isOnlyOneGroup)}
                  <div className="notification-group">
                    {earlierNotifications.map(el => this.buildNotificationTile(el, isInTopBar))}
                  </div>
                </>
              }
            </CustomScrollBars>

            {isInTopBar
              && <div id="notification-footer">
                <span className="action" onClick={this.seeInTaskboard}>
                  <FormattedMessage id="notifications.seeInTaskboard" defaultMessage="See all in Taskboard"/>
                </span>
              </div>
            }
          </>
        </div>
      );
    }

    return (
      <div id="notification-empty-list">
        <FormattedMessage id="notifications.noNotification" defaultMessage="No new notification"/>
      </div>
    );
  }
}

export default withRouter(NotificationList);
