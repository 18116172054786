import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FeedbackKeysType, {
  FeedbackKeysCreation,
  FeedbackKeysPage,
  FeedbackKeysStatus,
  FeedbackKeysTab,
} from 'src/constants/feedbackKeys';

export default class FeedbacksUtils {

  public static typeOptions = [
    {
      key: FeedbackKeysType.FB_TYPE_OPTIONS_BUG,
      text: FeedbacksUtils.getFormattedType(FeedbackKeysType.FB_TYPE_OPTIONS_BUG),
      value: FeedbackKeysType.FB_TYPE_OPTIONS_BUG,
    },
    {
      key: FeedbackKeysType.FB_TYPE_OPTIONS_SUGGESTIONS,
      text: FeedbacksUtils.getFormattedType(FeedbackKeysType.FB_TYPE_OPTIONS_SUGGESTIONS),
      value: FeedbackKeysType.FB_TYPE_OPTIONS_SUGGESTIONS,
    },
    {
      key: FeedbackKeysType.FB_TYPE_OPTIONS_OTHER,
      text: FeedbacksUtils.getFormattedType(FeedbackKeysType.FB_TYPE_OPTIONS_OTHER),
      value: FeedbackKeysType.FB_TYPE_OPTIONS_OTHER,
    },
  ];

  private static getFormattedType(type: number) { // Code dupliqué feedbackContent ? | private méthode dans utils ?

    switch (type) {
      case FeedbackKeysType.FB_TYPE_OPTIONS_BUG:
        return (
          <FormattedMessage
            id="feedback.bug"
            defaultMessage="Bug"
          />);
      case FeedbackKeysType.FB_TYPE_OPTIONS_SUGGESTIONS:
        return (
          <FormattedMessage
            id="feedback.suggestion"
            defaultMessage="Suggestion"
          />);
      case FeedbackKeysType.FB_TYPE_OPTIONS_OTHER:
        return (
          <FormattedMessage
            id="feedback.other"
            defaultMessage="Other comment"
          />);
      default:
        return (<div />);
    }
  }

  public static typesOptions = [
    {
      key: FeedbackKeysType.FB_TYPE_OPTIONS_BUG,
      text: FeedbacksUtils.getFormattedTypes(FeedbackKeysType.FB_TYPE_OPTIONS_BUG),
      value: FeedbackKeysType.FB_TYPE_OPTIONS_BUG,
    },
    {
      key: FeedbackKeysType.FB_TYPE_OPTIONS_SUGGESTIONS,
      text: FeedbacksUtils.getFormattedTypes(FeedbackKeysType.FB_TYPE_OPTIONS_SUGGESTIONS),
      value: FeedbackKeysType.FB_TYPE_OPTIONS_SUGGESTIONS,
    },
    {
      key: FeedbackKeysType.FB_TYPE_OPTIONS_OTHER,
      text: FeedbacksUtils.getFormattedTypes(FeedbackKeysType.FB_TYPE_OPTIONS_OTHER),
      value: FeedbackKeysType.FB_TYPE_OPTIONS_OTHER,
    },
  ];

  private static getFormattedTypes(type: number) { // Code dupliqué feedbackContent ? | private méthode dans utils ?

    switch (type) {
      case FeedbackKeysType.FB_TYPE_OPTIONS_BUG:
        return (
          <FormattedMessage
            id="menu.feedbacks.bugs"
            defaultMessage="Bugs"
          />);
      case FeedbackKeysType.FB_TYPE_OPTIONS_SUGGESTIONS:
        return (
          <FormattedMessage
            id="menu.feedbacks.suggestions"
            defaultMessage="Suggestions"
          />);
      case FeedbackKeysType.FB_TYPE_OPTIONS_OTHER:
        return (
          <FormattedMessage
            id="menu.feedbacks.otherComments"
            defaultMessage="Other comments"
          />);
      default:
        return (<div />);
    }
  }

  public static pageOptions = [
    {
      key: FeedbackKeysPage.FB_PAGE_OPTIONS_LOGIN,
      text: FeedbacksUtils.getFormattedPage(FeedbackKeysPage.FB_PAGE_OPTIONS_LOGIN),
      value: FeedbackKeysPage.FB_PAGE_OPTIONS_LOGIN,
    },
    {
      key: FeedbackKeysPage.FB_PAGE_OPTIONS_PROFILE,
      text: FeedbacksUtils.getFormattedPage(FeedbackKeysPage.FB_PAGE_OPTIONS_PROFILE),
      value: FeedbackKeysPage.FB_PAGE_OPTIONS_PROFILE,
    },
    {
      key: FeedbackKeysPage.FB_PAGE_OPTIONS_MENU,
      text: FeedbacksUtils.getFormattedPage(FeedbackKeysPage.FB_PAGE_OPTIONS_MENU),
      value: FeedbackKeysPage.FB_PAGE_OPTIONS_MENU,
    },
    {
      key: FeedbackKeysPage.FB_PAGE_OPTIONS_TASKBOARD,
      text: FeedbacksUtils.getFormattedPage(FeedbackKeysPage.FB_PAGE_OPTIONS_TASKBOARD),
      value: FeedbackKeysPage.FB_PAGE_OPTIONS_TASKBOARD,
    },
    {
      key: FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_PLAN,
      text: FeedbacksUtils.getFormattedPage(FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_PLAN),
      value: FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_PLAN,
    },
    {
      key: FeedbackKeysPage.FB_PAGE_OPTIONS_BUSINESS_CHALLENGE,
      text: FeedbacksUtils.getFormattedPage(FeedbackKeysPage.FB_PAGE_OPTIONS_BUSINESS_CHALLENGE),
      value: FeedbackKeysPage.FB_PAGE_OPTIONS_BUSINESS_CHALLENGE,
    },
    {
      key: FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_INITIATIVE,
      text: FeedbacksUtils.getFormattedPage(FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_INITIATIVE),
      value: FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_INITIATIVE,
    },
    {
      key: FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_PLAN_CREATION,
      text: FeedbacksUtils.getFormattedPage(FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_PLAN_CREATION),
      value: FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_PLAN_CREATION,
    },
    {
      key: FeedbackKeysPage.FB_PAGE_OPTIONS_BUSINESS_CHALLENGE_CREATION,
      text: FeedbacksUtils.getFormattedPage(FeedbackKeysPage.FB_PAGE_OPTIONS_BUSINESS_CHALLENGE_CREATION),
      value: FeedbackKeysPage.FB_PAGE_OPTIONS_BUSINESS_CHALLENGE_CREATION,
    },
    {
      key: FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_INITIATIVE_CREATION,
      text: FeedbacksUtils.getFormattedPage(FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_INITIATIVE_CREATION),
      value: FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_INITIATIVE_CREATION,
    },
    {
      key: FeedbackKeysPage.FB_PAGE_OPTIONS_OTHER,
      text: FeedbacksUtils.getFormattedPage(FeedbackKeysPage.FB_PAGE_OPTIONS_OTHER),
      value: FeedbackKeysPage.FB_PAGE_OPTIONS_OTHER,
    },
  ];

  private static getFormattedPage(page: number) {
    let jsxMessage: JSX.Element;
    switch (page) {
      case FeedbackKeysPage.FB_PAGE_OPTIONS_LOGIN:
        jsxMessage = (
          <FormattedMessage
            id="feedback.page.login"
            defaultMessage="Login"
          />);
        break;
      case FeedbackKeysPage.FB_PAGE_OPTIONS_PROFILE:
        jsxMessage = (
          <FormattedMessage
            id="feedback.page.profile"
            defaultMessage="Suggestion"
          />);
        break;
      case FeedbackKeysPage.FB_PAGE_OPTIONS_MENU:
        jsxMessage = (
          <FormattedMessage
            id="feedback.page.menu"
            defaultMessage="Menu"
          />);
        break;
      case FeedbackKeysPage.FB_PAGE_OPTIONS_TASKBOARD:
        jsxMessage = (
          <FormattedMessage
            id="feedback.page.taskboard"
            defaultMessage="Taskboard"
          />);
        break;
      case FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_PLAN:
        jsxMessage = (
          <FormattedMessage
            id="feedback.page.pp"
            defaultMessage="Performance plan"
          />);
        break;
      case FeedbackKeysPage.FB_PAGE_OPTIONS_BUSINESS_CHALLENGE:
        jsxMessage = (
          <FormattedMessage
            id="feedback.page.bc"
            defaultMessage="Business Challenge"
          />);
        break;
      case FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_INITIATIVE:
        jsxMessage = (
          <FormattedMessage
            id="feedback.page.pi"
            defaultMessage="Performance initiative"
          />);
        break;
      case FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_PLAN_CREATION:
        jsxMessage = (
          <FormattedMessage
            id="feedback.page.ppCreation"
            defaultMessage="Performance Plan Creation"
          />);
        break;
      case FeedbackKeysPage.FB_PAGE_OPTIONS_BUSINESS_CHALLENGE_CREATION:
        jsxMessage = (
          <FormattedMessage
            id="feedback.page.bcCreation"
            defaultMessage="Business Challenge Creation"
          />);
        break;
      case FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_INITIATIVE_CREATION:
        jsxMessage = (
          <FormattedMessage
            id="feedback.page.piCreation"
            defaultMessage="Performance Initiative Creation"
          />);
        break;
      case FeedbackKeysPage.FB_PAGE_OPTIONS_OTHER:
        jsxMessage = (
          <FormattedMessage
            id="feedback.other"
            defaultMessage="Other comment"
          />
        );
        break;
      default:
        jsxMessage = (
          <div />);
        break;
    }
    return jsxMessage;
  }

  public static tabOptions = [
    {
      key: FeedbackKeysTab.FB_TAB_OPTIONS_COCKPIT,
      text: FeedbacksUtils.getFormattedTab(FeedbackKeysTab.FB_TAB_OPTIONS_COCKPIT),
      value: FeedbackKeysTab.FB_TAB_OPTIONS_COCKPIT,
    },
    {
      key: FeedbackKeysTab.FB_TAB_OPTIONS_MORE_INFO,
      text: FeedbacksUtils.getFormattedTab(FeedbackKeysTab.FB_TAB_OPTIONS_MORE_INFO),
      value: FeedbackKeysTab.FB_TAB_OPTIONS_MORE_INFO,
    },
  ];

  private static getFormattedTab(tab: number) {
    let jsxMessage: JSX.Element;
    switch (tab) {
      case FeedbackKeysTab.FB_TAB_OPTIONS_COCKPIT:
        jsxMessage = (
          <FormattedMessage
            id="pp.cockpit"
            defaultMessage="Cockpit"
          />);
        break;
      case FeedbackKeysTab.FB_TAB_OPTIONS_MORE_INFO:
        jsxMessage = (
          <FormattedMessage
            id="moreInformation"
            defaultMessage="More information"
          />);
        break;
      default:
        jsxMessage = (
          <div />);
        break;
    }
    return jsxMessage;
  }

  public static tabPPOptions = [
    {
      key: FeedbackKeysTab.FB_TAB_PP_OPTIONS_REPORTING,
      text: (<FormattedMessage id="pp.reporting" defaultMessage="Reporting" />),
      value: FeedbackKeysTab.FB_TAB_PP_OPTIONS_REPORTING,
    },
  ];

  public static tabBCOptions = [
    {
      key: FeedbackKeysTab.FB_TAB_BC_PI_OPTIONS_PERFORMANCE_INDICATOR,
      text: FeedbacksUtils.getFormattedBCTab(FeedbackKeysTab.FB_TAB_BC_PI_OPTIONS_PERFORMANCE_INDICATOR),
      value: FeedbackKeysTab.FB_TAB_BC_PI_OPTIONS_PERFORMANCE_INDICATOR,
    },
    {
      key: FeedbackKeysTab.FB_TAB_BC_OPTIONS_PERFORMANCE_INITIATIVES,
      text: FeedbacksUtils.getFormattedBCTab(FeedbackKeysTab.FB_TAB_BC_OPTIONS_PERFORMANCE_INITIATIVES),
      value: FeedbackKeysTab.FB_TAB_BC_OPTIONS_PERFORMANCE_INITIATIVES,
    },
  ];

  private static getFormattedBCTab(tab: number) {
    let jsxMessage: JSX.Element;
    switch (tab) {
      case FeedbackKeysTab.FB_TAB_BC_PI_OPTIONS_PERFORMANCE_INDICATOR:
        jsxMessage = (
          <FormattedMessage
            id="bc.performanceIndicators"
            defaultMessage="Performance Indicators"
          />);
        break;
      case FeedbackKeysTab.FB_TAB_BC_OPTIONS_PERFORMANCE_INITIATIVES:
        jsxMessage = (
          <FormattedMessage
            id="bc.pi"
            defaultMessage="Performance Initiatives"
          />);
        break;
      default:
        jsxMessage = (
          <div />);
        break;
    }
    return jsxMessage;
  }

  public static tabPIOptions = [
    {
      key: FeedbackKeysTab.FB_TAB_BC_PI_OPTIONS_PERFORMANCE_INDICATOR,
      text: FeedbacksUtils.getFormattedPITab(FeedbackKeysTab.FB_TAB_BC_PI_OPTIONS_PERFORMANCE_INDICATOR),
      value: FeedbackKeysTab.FB_TAB_BC_PI_OPTIONS_PERFORMANCE_INDICATOR,
    },
    {
      key: FeedbackKeysTab.FB_TAB_PI_OPTIONS_ACTION_LIST,
      text: FeedbacksUtils.getFormattedPITab(FeedbackKeysTab.FB_TAB_PI_OPTIONS_ACTION_LIST),
      value: FeedbackKeysTab.FB_TAB_PI_OPTIONS_ACTION_LIST,
    },
  ];

  private static getFormattedPITab(tab: number) {
    let jsxMessage: JSX.Element;
    switch (tab) {
      case FeedbackKeysTab.FB_TAB_BC_PI_OPTIONS_PERFORMANCE_INDICATOR:
        jsxMessage = (
          <FormattedMessage
            id="bc.performanceIndicators"
            defaultMessage="Performance Indicators"
          />);
        break;
      case FeedbackKeysTab.FB_TAB_PI_OPTIONS_ACTION_LIST:
        jsxMessage = (
          <FormattedMessage
            id="pi.actionsList"
            defaultMessage="Actions List"
          />);
        break;
      default:
        jsxMessage = (
          <div />);
        break;
    }
    return jsxMessage;
  }

  public static ppCreationOptions = [
    {
      key: FeedbackKeysCreation.FB_PP_CREATION_OPTIONS_BUSINESS,
      text: FeedbacksUtils.getFormattedPPCreation(FeedbackKeysCreation.FB_PP_CREATION_OPTIONS_BUSINESS),
      value: FeedbackKeysCreation.FB_PP_CREATION_OPTIONS_BUSINESS,
    },
    {
      key: FeedbackKeysCreation.FB_PP_CREATION_OPTIONS_OTHER_INFO,
      text: FeedbacksUtils.getFormattedPPCreation(FeedbackKeysCreation.FB_PP_CREATION_OPTIONS_OTHER_INFO),
      value: FeedbackKeysCreation.FB_PP_CREATION_OPTIONS_OTHER_INFO,
    },
    {
      key: FeedbackKeysCreation.FB_PP_CREATION_OPTIONS_DEPUTIES,
      text: FeedbacksUtils.getFormattedPPCreation(FeedbackKeysCreation.FB_PP_CREATION_OPTIONS_DEPUTIES),
      value: FeedbackKeysCreation.FB_PP_CREATION_OPTIONS_DEPUTIES,
    },
    {
      key: FeedbackKeysCreation.FB_PP_CREATION_OPTIONS_CONFIRM_NAME,
      text: FeedbacksUtils.getFormattedPPCreation(FeedbackKeysCreation.FB_PP_CREATION_OPTIONS_CONFIRM_NAME),
      value: FeedbackKeysCreation.FB_PP_CREATION_OPTIONS_CONFIRM_NAME,
    },
  ];

  private static getFormattedPPCreation(tab: number) {
    let jsxMessage: JSX.Element;
    switch (tab) {
      case FeedbackKeysCreation.FB_PP_CREATION_OPTIONS_BUSINESS:
        jsxMessage = (
          <FormattedMessage
            id="ppCreation.businessAndLocation"
            defaultMessage="Business & Location"
          />);
        break;
      case FeedbackKeysCreation.FB_PP_CREATION_OPTIONS_OTHER_INFO:
        jsxMessage = (
          <FormattedMessage
            id="ppCreation.otherInformation"
            defaultMessage="Other information"
          />);
        break;
      case FeedbackKeysCreation.FB_PP_CREATION_OPTIONS_DEPUTIES:
        jsxMessage = (
          <FormattedMessage
            id="pp.creation.deputies"
            defaultMessage="Deputies"
          />);
        break;
      case FeedbackKeysCreation.FB_PP_CREATION_OPTIONS_CONFIRM_NAME:
        jsxMessage = (
          <FormattedMessage
            id="ppCreation.confirmName"
            defaultMessage="Confirm Name"
          />);
        break;
      default:
        jsxMessage = (
          <div />);
        break;
    }
    return jsxMessage;
  }

  public static bcCreationOptions = [
    {
      key: FeedbackKeysCreation.FB_BC_PI_CREATION_OPTIONS_BASIC_INFO,
      text: FeedbacksUtils.getFormattedBCCreation(FeedbackKeysCreation.FB_BC_PI_CREATION_OPTIONS_BASIC_INFO),
      value: FeedbackKeysCreation.FB_BC_PI_CREATION_OPTIONS_BASIC_INFO,
    },
    {
      key: FeedbackKeysCreation.FB_BC_CREATION_OPTIONS_GLOBAL_STATE,
      text: FeedbacksUtils.getFormattedBCCreation(FeedbackKeysCreation.FB_BC_CREATION_OPTIONS_GLOBAL_STATE),
      value: FeedbackKeysCreation.FB_BC_CREATION_OPTIONS_GLOBAL_STATE,
    },
    {
      key: FeedbackKeysCreation.FB_BC_CREATION_OPTIONS_BC_LEADER,
      text: FeedbacksUtils.getFormattedBCCreation(FeedbackKeysCreation.FB_BC_CREATION_OPTIONS_BC_LEADER),
      value: FeedbackKeysCreation.FB_BC_CREATION_OPTIONS_BC_LEADER,
    },
  ];

  private static getFormattedBCCreation(tab: number) {
    let jsxMessage: JSX.Element;
    switch (tab) {
      case FeedbackKeysCreation.FB_BC_PI_CREATION_OPTIONS_BASIC_INFO:
        jsxMessage = (
          <FormattedMessage
            id="bcCreation.basicInformation"
            defaultMessage="Basic information"
          />);
        break;
      case FeedbackKeysCreation.FB_BC_CREATION_OPTIONS_GLOBAL_STATE:
        jsxMessage = (
          <FormattedMessage
            id="bcCreation.globalState"
            defaultMessage="Global state"
          />);
        break;
      case FeedbackKeysCreation.FB_BC_CREATION_OPTIONS_BC_LEADER:
        jsxMessage = (
          <FormattedMessage
            id="bcCreation.assignments"
            defaultMessage="BC leader(s) assignment"
          />);
        break;
      default:
        jsxMessage = (
          <div />);
        break;
    }
    return jsxMessage;
  }

  public static piCreationOptions = [
    {
      key: FeedbackKeysCreation.FB_BC_PI_CREATION_OPTIONS_BASIC_INFO,
      text: FeedbacksUtils.getFormattedPICreation(FeedbackKeysCreation.FB_BC_PI_CREATION_OPTIONS_BASIC_INFO),
      value: FeedbackKeysCreation.FB_BC_PI_CREATION_OPTIONS_BASIC_INFO,
    },
    {
      key: FeedbackKeysCreation.FB_PI_CREATION_OPTIONS_METRICS,
      text: FeedbacksUtils.getFormattedPICreation(FeedbackKeysCreation.FB_PI_CREATION_OPTIONS_METRICS),
      value: FeedbackKeysCreation.FB_PI_CREATION_OPTIONS_METRICS,
    },
    {
      key: FeedbackKeysCreation.FB_PI_CREATION_OPTIONS_PI_LEADER,
      text: FeedbacksUtils.getFormattedPICreation(FeedbackKeysCreation.FB_PI_CREATION_OPTIONS_PI_LEADER),
      value: FeedbackKeysCreation.FB_PI_CREATION_OPTIONS_PI_LEADER,
    },
  ];

  private static getFormattedPICreation(tab: number) {
    let jsxMessage: JSX.Element;
    switch (tab) {
      case FeedbackKeysCreation.FB_BC_PI_CREATION_OPTIONS_BASIC_INFO:
        jsxMessage = (
          <FormattedMessage
            id="bcCreation.basicInformation"
            defaultMessage="Basic information"
          />);
        break;
      case FeedbackKeysCreation.FB_PI_CREATION_OPTIONS_METRICS:
        jsxMessage = (
          <FormattedMessage
            id="piCreation.progressView"
            defaultMessage="Metrics & Milestones"
          />);
        break;
      case FeedbackKeysCreation.FB_PI_CREATION_OPTIONS_PI_LEADER:
        jsxMessage = (
          <FormattedMessage
            id="piCreation.assignments"
            defaultMessage="PI leader(s) assignment"
          />);
        break;
      default:
        jsxMessage = (
          <div />);
        break;
    }
    return jsxMessage;
  }

  public static statusOptions = [
    {
      key: FeedbackKeysStatus.FB_STATUS_TODO,
      text: FeedbacksUtils.getFormattedStatus(FeedbackKeysStatus.FB_STATUS_TODO),
      value: FeedbackKeysStatus.FB_STATUS_TODO,
    },
    {
      key: FeedbackKeysStatus.FB_STATUS_IN_PROGRESS,
      text: FeedbacksUtils.getFormattedStatus(FeedbackKeysStatus.FB_STATUS_IN_PROGRESS),
      value: FeedbackKeysStatus.FB_STATUS_IN_PROGRESS,
    },
    {
      key: FeedbackKeysStatus.FB_STATUS_DONE,
      text: FeedbacksUtils.getFormattedStatus(FeedbackKeysStatus.FB_STATUS_DONE),
      value: FeedbackKeysStatus.FB_STATUS_DONE,
    },
  ];

  private static getFormattedStatus(status: number) {
    switch (status) {
      case FeedbackKeysStatus.FB_STATUS_TODO:
        return (
          <FormattedMessage
            id="feedback.todo"
            defaultMessage="Todo"
          />);
      case FeedbackKeysStatus.FB_STATUS_IN_PROGRESS:
        return (
          <FormattedMessage
            id="feedback.inProgress"
            defaultMessage="In progress"
          />);
      case FeedbackKeysStatus.FB_STATUS_DONE:
        return (
          <FormattedMessage
            id="feedback.done"
            defaultMessage="Done"
          />);
      default:
        return (<div />);
    }
  }

  /**
   * Get tab list depends on chosen page
   * @param value : number
   */
  public static getTabList = (page: number) => {
    if (page) {
      let tabOp: any[] = [];
      switch (page) {
        case FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_PLAN:
          tabOp = tabOp.concat(FeedbacksUtils.tabOptions, FeedbacksUtils.tabPPOptions);
          break;
        case FeedbackKeysPage.FB_PAGE_OPTIONS_BUSINESS_CHALLENGE:
          tabOp = tabOp.concat(FeedbacksUtils.tabOptions, FeedbacksUtils.tabBCOptions);
          break;
        case FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_INITIATIVE:
          tabOp = tabOp.concat(FeedbacksUtils.tabOptions, FeedbacksUtils.tabPIOptions);
          break;
        case FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_PLAN_CREATION:
          tabOp = FeedbacksUtils.ppCreationOptions;
          break;
        case FeedbackKeysPage.FB_PAGE_OPTIONS_BUSINESS_CHALLENGE_CREATION:
          tabOp = FeedbacksUtils.bcCreationOptions;
          break;
        case FeedbackKeysPage.FB_PAGE_OPTIONS_PERFORMANCE_INITIATIVE_CREATION:
          tabOp = FeedbacksUtils.piCreationOptions;
          break;
      }
      return tabOp;
    }
    return [];
  };

}
