import Utils from '../../../../utils/utils';
import TypeActivity from '../../../../constants/typeActivity';
import { Form, Input, Label } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import { ActionEdit } from '../../../../models/action';
import ModeTypes from '../../../../constants/modeTypes';
import { CommonField } from '../../../../constants/fields';
import User from '../../../../models/user';
import Milestone from '../../../../models/milestone';
import { IAtFormValid, IMiFormValid } from '../../../../constants/formValid';

interface IProps {
  activity: ActionEdit | Milestone;
  type: TypeActivity;
  mode: ModeTypes;
  isFormValid: IAtFormValid | IMiFormValid;
  hideNameOnView?: boolean;
  handleChange(key: string, value: any | User[] | number | boolean, event): void;
}

class ActivityTitle extends React.Component<IProps, any> {

  render() {
    const isAt = Utils.isActivityAt(this.props.type);
    const { activity, type } = this.props;
    return (
      Utils.isOnViewMode(this.props.mode)
        ? (
          <span className="title-and-closed-label">
            <span className="text-title">
              {this.props.hideNameOnView
                ? Utils.formatActivityCode(activity.code, type)
                : Utils.formatActivityName(activity.code, activity.name, type)
              }
            </span>
            {(isAt && (activity as ActionEdit).isClosed) &&
              <Label><FormattedMessage id="actionClosed" defaultMessage="CLOSED"/></Label>
            }
          </span>
        )
        : (
          <>
            {isAt ?
              <Form>
                {Utils.formatActivityCode(activity.code, type)}
                &nbsp;-&nbsp;
                <Input
                  className={`name-input ${this.props.isFormValid.isNameValid ? '' : ' error'}`}
                  name="name"
                  placeholder="Name"
                  value={activity.name || ''}
                  onChange={(event, data) => this.props.handleChange(CommonField.NAME, data.value, event)}
                />
              </Form>
              :
              <span>
                {activity.code
                  ? Utils.formatActivityCode(activity.code, type)
                  : <FormattedMessage id="newMilestone" defaultMessage="New Milestone"/>
                }
              </span>
            }
          </>
        )
    );
  }
}

export default ActivityTitle;
