import './checkpoint.scss';

import * as React from 'react';
import { Button, Checkbox, Form, Icon, Input, Message, Popup } from 'semantic-ui-react';

import AuthActions from 'src/actions/auth.actions';
import AuthStore from 'src/stores/auth.store';
import { LoginMethod } from 'src/constants/loginTypes';
import HttpClient from '../../utils/httpClient';
import AuthAPI from '../../api/auth.api';

interface IProps {
  loginMethod: LoginMethod;
  setLoginMethod(loginMethod: LoginMethod): void;
}

interface IStates {
  email: string;
  password: string;
  rememberMe: boolean;
  loginUnauthorized: boolean;
  loginUnauthorizedErrorMessage: string;
  onLogin: boolean;
  onSSOLogin: boolean;
  forgottenPassword: boolean;
  firstConnection: boolean;
  resetPasswordSuccess: boolean | undefined;
  resetPasswordMessage: string;
}

class LoginForm extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      email: '',
      password: '',
      rememberMe: false,
      loginUnauthorized: false,
      loginUnauthorizedErrorMessage: '',
      onLogin: false,
      onSSOLogin: false,
      forgottenPassword: false,
      firstConnection: false,
      resetPasswordSuccess: undefined,
      resetPasswordMessage: '',
    };
  }

  /**
   * handleInputChange, if auth is OK, navigate to home route
   * otherwise loginUnauthorized stays true and redirect to /login
   */
  private handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    if (this.state.forgottenPassword && this.state.resetPasswordSuccess !== undefined) {
      this.resetForgottenPassword();
    }

    this.setState({
      email: target.name === 'email' ? value : this.state.email,
      password: target.name === 'password' ? value : this.state.password,
    });
  };

  /**
   * LoginForm function, if auth is OK, navigate to home route
   * otherwise loginUnauthorized stays true and redirect to /login
   */
  private login = () => {
    this.setState({ onLogin: true });
    AuthStore.setRememberMe(this.state.rememberMe);
    AuthActions.loginPassword(this.state.email, this.state.password)
      .catch((ex: any) => {
        this.setState({ loginUnauthorized: true });
        let errorMsg = 'The server has encountered an error. Please try again later or contact the administrator.';
        if (ex.reqStatus && (ex.reqStatus === 401 || ex.reqStatus === 400 || ex.reqStatus === 404)) {
          errorMsg = ex.error.toString();
        }
        this.setState({ loginUnauthorizedErrorMessage: errorMsg, onLogin: false });
      });
  };

  /**
   * LoginForm function, if auth is OK, navigate to home route
   * otherwise loginUnauthorized stays true and redirect to /login
   */
  private resetPassword = () => {
    this.setState({ onLogin: true, resetPasswordSuccess: undefined });
    const email = this.state.email;

    HttpClient.noToken().post('users/reset-password', { email })
      .then(() => {
        this.setState({
          resetPasswordSuccess: true,
          resetPasswordMessage:
            `An email ${this.state.firstConnection ? '' : 'with your new password'} has been sent to: ${email}.
            If you don't receive a mail in the next minutes, please check if the email you provided is correct and retry.`,
          onLogin: false,
        });
      })
      .catch((err: { reqStatus: number, error: string }) => {
        this.setState({ resetPasswordSuccess: false });
        let errorMsg = 'The server has encountered an error. Please try again later or contact the administrator.';

        if (err.reqStatus === 400 || err.reqStatus === 404) {
          errorMsg = err.error;
        }
        this.setState({ resetPasswordMessage: errorMsg, onLogin: false });
      });
  };

  /**
   * loginError, if the provided values are incorrect
   * @returns JSX.Element[] | null
   */
  private loginError(isOnResetPassword = false): JSX.Element | null {
    const msg = isOnResetPassword
      ? this.state.resetPasswordMessage
      : this.state.loginUnauthorized
        ? this.state.loginUnauthorizedErrorMessage
        : null;

    if (msg) {
      return (
        <Message negative={true} className="error-message">
          <Message.Header>{msg}</Message.Header>
        </Message>
      );
    }

    return null;
  }

  /**
   * resetPasswordInfo, after a password reset request
   * @returns JSX.Element[]
   */
  private resetPasswordInfo(): JSX.Element {
    return (
      <Message info={true}>
        <Message.Header>
          {this.state.firstConnection ? 'Successfully sent.' : 'Successfully reset your password.'}
        </Message.Header>
        <p>{this.state.resetPasswordMessage}</p>
      </Message>
    );
  }

  private handleLoginClick = () => {
    this.setState({ onSSOLogin: true });
    window.location.assign(AuthAPI.getSsoLoginUrl());
  };

  private isEmailValid = (): boolean => {
    const re = /\S+@\S+\.\S+/;
    return re.test(String(this.state.email).toLowerCase());
  }

  private resetForgottenPassword = (closeForm = false) => {
    this.setState({
      forgottenPassword: closeForm ? false : this.state.forgottenPassword,
      resetPasswordSuccess: undefined,
      resetPasswordMessage: '',
    });
  };

  public render() {
    const isEmailValid = this.isEmailValid();
    const sendNewPasswordButton = (
      <div className="login-button">
        <Button
          color="blue"
          content={this.state.firstConnection ? 'Send password creation by mail' : 'Send a new password by mail'}
          disabled={this.state.onLogin || !isEmailValid}
          icon="mail"
          labelPosition="right"
          loading={this.state.onLogin}
          onClick={this.resetPassword}
        />
      </div>
    );

    return (
      <>
        {this.props.loginMethod === LoginMethod.SSO
          ? <>
            <p className="login-title">
              Choose your favorite authentication method to get access to your iBoost account.
            </p>
            <Button
              color="blue"
              content="Log in with eDir / Thales SSO"
              icon="user"
              labelPosition="right"
              loading={this.state.onSSOLogin}
              onClick={this.handleLoginClick}
            />
            <br />
            <Button
              className="alt-login"
              color="blue"
              content="Log in with email / password"
              icon="user"
              labelPosition="right"
              onClick={() => this.props.setLoginMethod(LoginMethod.PASSWORD)}
            />
          </>
          : this.state.forgottenPassword
            ? <>
              <Form>
                <Form.Field id="login-mail">
                  <div>
                    <Input
                      name="email"
                      iconPosition="left"
                      placeholder="Mail"
                      value={this.state.email}
                      autoFocus={true}
                      onChange={this.handleInputChange}
                    >
                      <Icon name="at" />
                      <input />
                    </Input>
                  </div>
                </Form.Field>
                <div id="container-button-login">
                  {isEmailValid
                    ? sendNewPasswordButton
                    : <Popup
                      content="This email address has not a valid format"
                      inverted={true}
                      position="right center"
                      size="small"
                      trigger={sendNewPasswordButton}
                    />
                  }
                </div>
              </Form>
              {this.state.resetPasswordSuccess !== undefined
                ? this.state.resetPasswordSuccess
                  ? this.resetPasswordInfo() : this.loginError(true)
                : null}
              <div id="container-button-login">
                <Button
                  className="login-button"
                  color="blue"
                  content="Return to login form"
                  icon="arrow left"
                  labelPosition="left"
                  onClick={() => this.resetForgottenPassword(true)}
                />
              </div>
            </>
            : <>
              <Form>
                <Form.Field id="login-mail">
                  <div>
                    <Input
                      name="email"
                      iconPosition="left"
                      placeholder="Mail"
                      value={this.state.email}
                      autoFocus={true}
                      onChange={this.handleInputChange}
                    >
                      <Icon name="at" />
                      <input />
                    </Input>
                  </div>
                </Form.Field>

                <Form.Field id="login-password">
                  <div>
                    <Input
                      name="password"
                      type="password"
                      iconPosition="left"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.handleInputChange}
                    >
                      <Icon name="lock" />
                      <input />
                    </Input>
                  </div>
                </Form.Field>

                <Form.Field>
                  <Checkbox
                    id="rememberme"
                    label="Remember me"
                    onChange={() => this.setState(state => ({ rememberMe: !state.rememberMe }))}
                  />
                </Form.Field>
                <div id="container-button-login">
                  <Button
                    className="login-button"
                    color="blue"
                    content="Log in"
                    icon="check"
                    labelPosition="right"
                    loading={this.state.onLogin}
                    onClick={this.login}
                  />
                </div>
                <div className="link-container">
                <span
                  className="link"
                  onClick={() => this.setState({ forgottenPassword: true, firstConnection: false })}>
                  Forgot your password?
                </span>
                <span
                  className="link"
                  onClick={() => this.setState({ forgottenPassword: true, firstConnection: true })}
                >
                  First mail/password connection ?
                </span>
                </div>
              </Form>
              {this.state.loginUnauthorized && this.loginError()}
              <Button
                className="alt-login"
                color="blue"
                content="Or log in with your eDir / Thales SSO account"
                icon="user"
                labelPosition="right"
                loading={this.state.onSSOLogin}
                onClick={this.handleLoginClick}
              />
            </>
        }
      </>
    );
  }
}

export default LoginForm;
