export default class UserUtils {
  private static readonly EMAIL_DOMAINS = [
    'thalesgroup.com',
    'fr.thalesgroup.com',
    'thalesaleniaspace.com',
    'uk.thalesgroup.com',
    'thalesgroup.com.au',
    'us.thalesgroup.com',
    'nl.thalesgroup.com',
    'asia.thalesgroup.com',
    'mythalesgroup.io',
    'ca.thalesgroup.com',
    'trixell-thalesgroup.com',
    'thales-services.com',
    'thales-esecurity.com',
    'be.thalesgroup.com',
    'thalesesec.com',
    'thalesesec.net',
    'thalesdigital.io',
    'thalesesecurity.com',
    'thalesraytheon-fr.com',
    'thaleslatam.com',
    'thalesatmus.com',
    'thales-mts.com',
    'thalesinflight.com',
    'mythalesgroup.com',
    'thales.co.om',
    'thalesgroup.ae',
    'thalesgroup.co.nz',
    'external.thalesaleniaspace.com',
    'us.thalesaleniaspace.com',
    'thales-esecurity.net',
    'external.thalesgroup.com',
    'tda.thalesgroup.com',
    'thalesgroup.io',
    'thales.com.au',
    'thalesavionicsspa.191.it',
    'mythles-ksa.com',
    'omnisys.com.br',
    'yaltes.com',
    'edisoft.pt',
    'ercom.fr',
    'suneris.com',
    'gemalto.com',
  ];

  public static validateEmailAddress(value: string) {
    const emails = this.EMAIL_DOMAINS.join('|@');
    return new RegExp(`^[a-zA-Z0-9-_'.\`]{1,60}(@${emails})$`).test(value);
  }

  public static validateTgi(value: string) {
    return value === ''
      ? true
      : new RegExp('^[tT]\\d{7}$').test(value);
  }
}
