import React, { Component } from 'react';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import {
  GUIDE_LOCK_TYPE,
  GUIDE_STATUS,
  GuideIds,
  GUIDES,
  IGuideStep,
  IGuideType,
  VIEWS,
} from '../../constants/guideConstants';
import GuideStore from '../../stores/guide.store';
import ActionTypes from '../../constants/actionTypes';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import GuideActions from '../../actions/guide.action';
import UserActions from '../../actions/user.action';
import UserStore from '../../stores/user.store';

interface IStates {
  steps: any[];
  stepIndex: number;
  currentGuide: IGuideType;
  currentView: VIEWS | undefined;
  isReady: boolean;
}

/**
 * High-order component adding the guide logic
 * @param {JSX.Element} WrappedComponent - the component to wrap with the guide
 */
// tslint:disable-next-line:variable-name
export const guideWrapper = (WrappedComponent: React.ComponentType<any>) => {

  class GuideWrapper extends Component<any & InjectedIntlProps, IStates> {

    isMount = false;

    constructor(props) {
      super(props);

      this.state = {
        steps: [],
        stepIndex: 0,
        currentGuide: { id: GuideIds.NONE, fileName: '' },
        currentView: GuideStore.getCurrentView(),
        isReady: false,
      };
    }

    componentDidMount() {
      this.isMount = true;
      GuideStore.addListener(ActionTypes.CURRENT_VIEW_UPDATED.toString(), this.updateCurrentView);
      GuideStore.addListener(ActionTypes.CURRENT_GUIDE_SET.toString(), this.setCurrentGuide);
    }

    componentWillUnmount() {
      this.isMount = false;
      GuideStore.removeListener(ActionTypes.CURRENT_VIEW_UPDATED.toString(), this.updateCurrentView);
      GuideStore.removeListener(ActionTypes.CURRENT_GUIDE_SET.toString(), this.setCurrentGuide);
      GuideStore.resetCurrentView();
    }

    updateCurrentView = () => {
      if (this.isMount) {
        this.setState({ currentView: GuideStore.getCurrentView() }, this.tryDisplayGuide);
      }
    }

    setCurrentGuide = () => {
      if (this.isMount) {
        this.setState({ currentGuide: GuideStore.getCurrentGuide() }, this.loadGuideData);
      }
    }

    /**
     * Check if displaying a guide is needed then display it or not.
     */
    tryDisplayGuide = () => {
      const completedGuides = UserStore.getCompletedGuides();
      let currentGuide: IGuideType | undefined = undefined;

      const getGuide = (guideId: GuideIds): IGuideType | undefined =>
        completedGuides.includes(guideId) ? undefined : GUIDES.find(g => g.id === guideId);

      switch (this.state.currentView) {
        case VIEWS.COCKPIT_VIEW_ON_EDIT:
        case VIEWS.ACTION_LIST_VIEW_ON_EDIT:
          currentGuide = getGuide(GuideIds.MENTION_ACTIVITIES);
          break;
        default:
          return;
      }

      if (currentGuide) this.setState({ currentGuide }, this.loadGuideData);
    };

    /**
     * Load the data file for the given guide, then start the guide process
     */
    loadGuideData = () => {
      if (!this.state.currentGuide) {
        return;
      }
      const { fileName: guideName } = { ...this.state.currentGuide };
      const path = `${guideName}.guide.tsx`;
      // Retrieve guide data based on guide name
      import((`../../constants/guides/${path}`)).then(async (data) => {
        const steps = data.default.map((step, index) => {
          // Add extra config for each step
          if (step.title && step.title.id) step.title = this.props.intl.formatMessage(step.title);
          if (step.content && step.content.id) step.content = this.props.intl.formatMessage(step.content);
          return {
            ...step,
            disableBeacon: true,
            hideCloseButton: true,
            hideBackButton: index > 0 && [GUIDE_LOCK_TYPE.HARD, GUIDE_LOCK_TYPE.SOFT]
              .includes(data.default[index - 1]?.lock),
          };
        });
        this.setState({
          steps,
          stepIndex: 0,
        },            this.startGuide);
      }).catch(err => console.log(err));
    };

    /**
     * Start the guide process
     */
    startGuide = () => {
      this.setState({ isReady: true });
    };

    /**
     * End the guide and update status
     */
    endGuide = async (status = GUIDE_STATUS.FINISHED) => {
      const completedGuides = UserStore.getCompletedGuides();
      if (this.state.currentGuide && !completedGuides.includes(this.state.currentGuide.id)) {
        UserActions.emitAddCompletedGuides(this.state.currentGuide.id);
      }

      this.setState({
        isReady: false,
        steps: [],
        stepIndex: 0,
      },            () => GuideActions.emitUpdateGuideStatus(status));
    };

    /**
     * Click event handler on soft-locked spotlight
     */
    handleSpotlightClick = () => {
      const currStep: IGuideStep = this.state.steps[this.state.stepIndex];
      const currElement = document.getElementById(currStep?.target.substring(1));
      currElement && currElement.click();
      this.setState(state => ({ stepIndex: state.stepIndex + 1 }));
    };

    /**
     * Callback function from Joyride after each event.
     * @param {joyride internal state} data
     */
    handleJoyrideCallback = async (data) => {
      const { action, index, status, type } = data;

      const spotLight = document.getElementsByClassName('react-joyride__spotlight');
      const currentStep: IGuideStep = { ...this.state.steps[this.state.stepIndex] };

      if (spotLight.length === 1 && currentStep.lock === GUIDE_LOCK_TYPE.SOFT) {
        spotLight[0].addEventListener('click', this.handleSpotlightClick);
      }

      if ([EVENTS.STEP_AFTER].includes(type) && [ACTIONS.PREV, ACTIONS.NEXT].includes(action)) {
        // close the guide if this was the last step
        if (action === ACTIONS.NEXT && (index + 1) === (this.state.steps.length)) {
          await this.endGuide(status);
        }
        // Update state to advance the tour
        this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
        // Guide has been finished and or skipped
      } else if ([GUIDE_STATUS.FINISHED, GUIDE_STATUS.SKIPPED].includes(status)) {
        await this.endGuide(status);
      }
    };

    shouldHideBtn = (lock?: GUIDE_LOCK_TYPE) => {
      return lock !== undefined && (lock === GUIDE_LOCK_TYPE.SOFT || lock === GUIDE_LOCK_TYPE.HARD);
    }

    render = () => {
      if (this.state.steps.length > 0 && this.state.stepIndex <= this.state.steps.length - 1) {

        // current step
        const { steps, stepIndex } = { ...this.state };
        const currentStep: IGuideStep = steps[stepIndex];

        // styling
        const sharedStyle = {
          backgroundColor: '#517aa4',
          color: '#FFF',
          borderRadius: 4,
        };
        const btnStyle = this.shouldHideBtn(currentStep.lock) ? { display: 'none', ...sharedStyle } : sharedStyle;
        const overlayStyleOverride = {
          cursor: (currentStep?.lock || currentStep?.allowClick) ? 'pointer' : 'not-allowed',
        };

        // translations
        const btnLabels = {
          back: this.props.intl.formatMessage({ id: 'previous', defaultMessage: 'Previous' }),
          last: this.props.intl.formatMessage({ id: 'finish', defaultMessage: 'Finish' }),
          next: this.props.intl.formatMessage({ id: 'next', defaultMessage: 'Next' }),
          skip: this.props.intl.formatMessage({ id: 'skip', defaultMessage: 'Skip the tutorial' }),
        };

        return (
          <>
            <WrappedComponent {...this.props} step={currentStep}/>

            {this.state.steps.length > 0 && this.state.isReady &&
            <Joyride
              styles={{
                buttonNext: btnStyle,
                buttonBack: btnStyle,
                options: { zIndex: 2000 },
                overlay: overlayStyleOverride,
                buttonSkip: sharedStyle,
                tooltipTitle: { color: '#517aa4' },
              }}
              continuous={true}
              showSkipButton={true}
              disableCloseOnEsc={true}
              disableOverlayClose={true}
              disableScrolling={true}
              run={this.state.isReady}
              steps={this.state.steps}
              callback={this.handleJoyrideCallback}
              stepIndex={this.state.stepIndex}
              spotlightClicks={currentStep.hide || currentStep.allowClick || currentStep.lock === GUIDE_LOCK_TYPE.HARD}
              spotlightPadding={(currentStep?.strictSpotlight) ? 2 : 6}
              locale={btnLabels}
            />
            }
          </>
        );
      }
      return (
        <WrappedComponent {...this.props} />
      );
    }
  }
  return injectIntl(GuideWrapper);
};
