import './bcPiKeywords.scss';

import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';

import ModeTypes from 'src/constants/modeTypes';
import TypeActivity from 'src/constants/typeActivity';
import BusinessChallengeErrors from 'src/models/bc-errors';
import BusinessChallenge from 'src/models/businessChallenge';
import Keywords from 'src/models/keywords';
import PerformanceInitiative from 'src/models/performanceInitiative';
import PerformanceInitiativeErrors from 'src/models/pi-errors';
import Utils from 'src/utils/utils';

interface IProps extends InjectedIntlProps {
  data: BusinessChallenge | PerformanceInitiative;
  getElementDropdown: (
    keywordsId: number, name: string, options: any, mode: ModeTypes, isValueNullable: boolean, isError: boolean,
  ) => JSX.Element;
  mode: ModeTypes;
  keywords: Keywords[];
  typeActivity: TypeActivity;
  dropdownEdit?: (name: string, value: any) => void;
  errors?: BusinessChallengeErrors | PerformanceInitiativeErrors;
}

class BcPiKeywords extends React.Component<IProps> {
  render() {
    let title = <FormattedMessage id="piKeywords" defaultMessage="PI keywords" />;
    let subject =
      this.props.intl.formatMessage({ id: 'piKeywordProposition', defaultMessage: 'PI keyword proposition' });

    if (Utils.isActivityBc(this.props.typeActivity)) {
      title = <FormattedMessage id="bcKeywords" defaultMessage="BC keywords" />;
      subject =
        this.props.intl.formatMessage({ id: 'bcKeywordProposition', defaultMessage: 'BC keyword proposition' });
    }

    return (
      <>
        <div className="grey-title bc-pi-keyword">
          <span>{title}</span>
          {Utils.isKeywordNone(this.props.data.keywords.id) && Utils.isOnEditMode(this.props.mode) &&
            Utils.getKeywordTooltip(subject, false)}
        </div>
        <div>
          {this.props.keywords
            ? this.props.getElementDropdown(
              this.props.data.keywords ? this.props.data.keywords.id : 1,
              'keywords',
              this.props.keywords,
              this.props.mode,
              true,
              false,
            )
            : <FormattedMessage id="none" defaultMessage="None" />
          }
        </div>
      </>
    );
  }
}

export default injectIntl(BcPiKeywords);
