import './exportCenter.scss';

import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Icon, Modal, Popup } from 'semantic-ui-react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';

import PerformancePlan from '../../../../../models/performancePlan';
import BusinessChallenge from '../../../../../models/businessChallenge';
import PerformanceInitiative from '../../../../../models/performanceInitiative';
import ModeTypes from '../../../../../constants/modeTypes';
import TypeActivity from '../../../../../constants/typeActivity';
import { ReportingGetFormatted } from '../../../../../models/reporting';
import PerformancePlanStore from '../../../../../stores/performancePlan.store';
import ExportActions, { CsvExportType, ExportType } from '../../../../../actions/export.action';
import Utils from '../../../../../utils/utils';
import ActionTypes from '../../../../../constants/actionTypes';
import PerformancePlanActions from '../../../../../actions/performancePlan.action';
import PerformanceInitiativeStore from '../../../../../stores/performanceInitiative.store';
import ExportStore from '../../../../../stores/export.store';
import { SortBy, sortOptions } from '../../../../../constants/interfaces/action.interface';
import actionStore from 'src/stores/action.store';
import performanceInitiativeStore from 'src/stores/performanceInitiative.store';
import ExportAPI from '../../../../../api/export.api';
import NotificationActions from '../../../../../actions/notification-actions';
import { ToastType } from '../../../../common/toast/toast';
import { ExportSheetErrors } from '../../../../../constants/errors/export.error';
import { CustomOptions, ExportStepsOptions } from './interfaces/ExportStepsOptions';
import ExportThirdStep from './exportThirdStep';
import ExportSecondStep from './exportSecondStep';
import CustomScrollBars from 'src/components/common/customScrollBars/customScrollBars';
import { RightsOnBC, RightsOnPI, RightsOnPP } from 'src/models/rights';
import PerformancePlanAPI from 'src/api/performancePlan.api';
import ExportOptionPptx from './exportOptionPptx';

interface IRouteProps {
  id: string;
}
interface IProps extends InjectedIntlProps, RouteComponentProps<IRouteProps> {
  data: PerformancePlan | BusinessChallenge | PerformanceInitiative;
  mode: ModeTypes;
  type: TypeActivity;
  rightsOnPp: RightsOnPP | undefined;
  rightsOnBc: RightsOnBC | undefined;
  rightsOnPi: RightsOnPI | undefined;
}

interface IStates {
  reportings: ReportingGetFormatted[];
  canExport: boolean;
  sortBy: SortBy;
  openConfirmModal: boolean;
  openExportModal: boolean;
  exportXLSLoading: boolean;
  steps: number;
  exportStepsOptions: ExportStepsOptions;
  performancePlan: PerformancePlan | undefined;
}

class ExportCenter extends React.Component<IProps & RouteComponentProps, IStates> {
  private isMount = false;

  public constructor(props: IProps & RouteComponentProps) {
    super(props);
    this.state = {
      openExportModal: false,
      reportings: PerformancePlanStore.getPerformancePlanReportings() || [],
      canExport: ExportStore.getCanExport(),
      sortBy: {
        value: '',
        order: '',
      },
      openConfirmModal: false,
      exportXLSLoading: false,
      steps: 0,
      exportStepsOptions :  {
        selectedExportType: undefined,
        selectedReporting: undefined,
        includeBcs: true,
        includePis: true,
        includeAts: false,
        bcs: [],
        pis: [],
        includeMiAt: true,
        includeMetrics: false,
      },
      performancePlan: undefined,
    };
  }

  public componentDidMount() {
    this.isMount = true;

    if (Utils.isActivityPp(this.props.type)) {
      PerformancePlanStore.addListener(ActionTypes.PERFORMANCE_PLAN_REPORTINGS_GET.toString(), this.setReportings);

      if (this.props.rightsOnPp?.canViewReporting()) {
        PerformancePlanActions.emitGetPerformancePlanReportings(this.props.data.id);
      }
    }
  }

  public componentWillMount() {
    actionStore.addListener(ActionTypes.SORT_BY_GET.toString(), this.setSortBy);
    performanceInitiativeStore
      .addListener(ActionTypes.PERFORMANCE_INITIATIVE_CHANGE.toString(), this.resetSort);
  }

  public componentWillUnmount() {
    this.isMount = false;

    if (Utils.isActivityPp(this.props.type)) {
      PerformancePlanStore.removeListener(ActionTypes.PERFORMANCE_PLAN_REPORTINGS_GET.toString(), this.setReportings);
    }
    actionStore.removeListener(ActionTypes.SORT_BY_GET.toString(), this.setSortBy);
  }

  private setSortBy = () => {
    this.setState({
      sortBy: actionStore.getSortBy(),
    });
  }

  private resetSort = () => {
    actionStore.resetSortBy();
  }

  private setReportings = () => {
    if (this.isMount) {
      this.setState({ reportings: PerformancePlanStore.getPerformancePlanReportings() });
    }
  };

  private handleClick =
    (exportType: ExportType, activityId: number, selectedReporting?: number, onlyTop5?: boolean,
     customOptions?: CustomOptions) => {
      ExportActions.emitExport(exportType, activityId, selectedReporting, onlyTop5,
                               undefined, undefined, customOptions);
      this.handleOpenModal(false);
    };

  /**
   * Export actions list as SHEET
   */
  private exportSheet = (csvType: CsvExportType) => {
    this.setState({ exportXLSLoading: true });
    const promise = csvType === CsvExportType.AT_LIST
      ? ExportAPI.getActionListCsvExport(this.props.data.id, this.props.type)
      : ExportAPI.getPpActivitiesCsvExport(this.props.data.id);
    promise.then((url: string) => {
      const link = document.createElement('a');
      let fileName = '';
      if (csvType === CsvExportType.AT_LIST) {
        switch (this.props.type) {
          case TypeActivity.BUSINESS_CHALLENGE:
          case TypeActivity.PERFORMANCE_INITIATIVE:
            const data = this.props.data as PerformanceInitiative;
            const name = Utils.formatActivityName(data.code, data.name, this.props.type, undefined, true, true);
            fileName = `action_list_of_${name}`;
            break;
          case TypeActivity.PERFORMANCE_PLAN:
            fileName = `action_list_of_${this.props.data.name}_(${(this.props.data as PerformancePlan).year})`;
            break;
        }
      } else {
        fileName = `activities_of_${this.props.data.name}_(${(this.props.data as PerformancePlan).year})`;
      }
      link.href = url;
      link.download = `export_${fileName}_${Utils.displayDate(new Date(), false, '-')}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.handleOpenModal(false);
      NotificationActions.toast(
          <FormattedMessage id="export" defaultMessage="Success" />,
          <FormattedMessage id="exportDone" defaultMessage="Sheet successfully exported"/>,
          ToastType.SUCCESS,
        );
    })
      .catch((err) => {
        if (err.error === ExportSheetErrors.NO_ASSIGNATIONS) {
          NotificationActions.toast(
            <FormattedMessage id="exportUnable" defaultMessage="Export to Sheet unavailable" />,
            (
              <FormattedMessage
                id="exportNoAssignation"
                defaultMessage="You can't export this activity because there is no assignation on it."
              />
            ),
            ToastType.WARNING,
          );
        } else {
          Utils.toastError();
        }
      });
    this.setState({ exportXLSLoading: false });
  }

  private handleOpenModal = (openExportModal?: boolean) => {
    this.setState(
      prevState => ({
        openExportModal: openExportModal === undefined ? !prevState.openExportModal : openExportModal,
        steps: !openExportModal ? 0 : prevState.steps,
      }),
      () => {
        if (Utils.isActivityPp(this.props.type)) {
          PerformancePlanStore.addListener(ActionTypes.PERFORMANCE_PLAN_REPORTINGS_GET.toString(), this.setReportings);
          if (this.getCanViewReporting()) {
            PerformancePlanActions.emitGetPerformancePlanReportings(this.props.data.id);
          }
        }
      });
  };

  private defaultExport = () => {
    ExportActions.emitExport(ExportType.PI_AT_LIST, this.props.data.id, undefined, undefined, undefined);
    this.closeConfirmModalExport();
  }

  private confirmExport = () => {
    ExportActions.emitExport(ExportType.PI_AT_LIST, this.props.data.id, undefined, undefined, this.state.sortBy);
    this.closeConfirmModalExport();
  }

  private getSortByText = () => {
    if (sortOptions && sortOptions.length > 0) {
      return sortOptions.filter(s => s.value === this.state.sortBy.value)[0].text;
    }
  }

  private closeConfirmModalExport = () => {
    this.setState({ openConfirmModal: false });
  }

  private closeExportCenterModalExport = () => {
    this.setState({
      openExportModal: false,
      steps: 0,
      exportStepsOptions :  {
        selectedExportType: undefined,
        selectedReporting: undefined,
        includeBcs: true,
        includePis: true,
        includeAts: false,
        bcs: [],
        pis: [],
        includeMiAt: true,
        includeMetrics: false,
      },
      performancePlan: undefined,
    });
  }

  /**
   * @description Translate order by
   */
  translateOrder = () => {
    return this.state.sortBy.order === 'ASC' ? this.props.intl.formatMessage({ id: 'ascending', defaultMessage: 'Ascending' }) :
    this.props.intl.formatMessage({ id: 'descending', defaultMessage: 'Descending' });
  }

  private confirmModalExport = () => {
    return this.state.openConfirmModal && (
    <Modal
      onClose={() => this.closeConfirmModalExport()}
      open={this.state.openConfirmModal}
    >
    <Modal.Header><FormattedMessage id="ConfirmSort" defaultMessage="Confirm the sort" /></Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <p className="confirmSortText">
        <FormattedMessage
          id="ConfirmSortDownload"
          defaultMessage="Confirm the actions list export with the following sort:"
        />
        <b> {this.getSortByText()} {this.translateOrder()}</b>
        </p>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
    <Button onClick={() => this.closeConfirmModalExport()}>
    <FormattedMessage id="cancel" defaultMessage="cancel" />
      </Button>
      <Button onClick={() => this.defaultExport()}>
        <FormattedMessage id="defaultSort" defaultMessage="Use default sort" />
      </Button>
      <Button color="green" onClick={() => this.confirmExport()}>
      <FormattedMessage id="confirm" defaultMessage="confirm" />
      </Button>
    </Modal.Actions>
    </Modal>);
  }

  private exportCockpit = () => {
    switch (this.props.type) {
      case TypeActivity.PERFORMANCE_PLAN:
        this.handleClick(ExportType.PP_COCKPIT, this.props.data.id);
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        this.handleClick(ExportType.BC_COCKPIT, this.props.data.id, undefined,
                         undefined, this.state.exportStepsOptions);
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        this.handleClick(ExportType.PI_COCKPIT, this.props.data.id, undefined,
                         undefined, this.state.exportStepsOptions);
        break;
    }
  }

  private exportWhole = () => {
    switch (this.props.type) {
      case TypeActivity.PERFORMANCE_PLAN:
        this.nextStep(ExportType.PP_FULL);
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        this.handleClick(ExportType.BC_FULL, this.props.data.id);
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        this.handleClick(ExportType.PI_FULL, this.props.data.id);
        break;
    }
  }

  private renderActionList = () => {
    let displayActionList = false;
    switch (this.props.type) {
      case TypeActivity.PERFORMANCE_PLAN:
        if ((this.props.data as PerformancePlan).hasActions) {
          displayActionList = true;
        }
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        if ((this.props.data as BusinessChallenge).hasActions) {
          displayActionList = true;
        }
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        if (PerformanceInitiativeStore.getPerformanceInitiative().actions.length > 0) {
          displayActionList = true;
        }
        break;
    }

    return displayActionList && (
    <Button onClick={() => this.nextStep(ExportType.ACTION)}>
      <FormattedMessage id="pi.actionsList" defaultMessage="Actions List" /> <Icon name="angle right"/>
    </Button>
    );
  }

  private includeBcs = () => {
    this.setState(prevState => ({
      exportStepsOptions: {
        ...prevState.exportStepsOptions,
        includeBcs: !prevState.exportStepsOptions.includeBcs,
        includePis: prevState.exportStepsOptions.includeBcs && prevState.exportStepsOptions.includePis ?
        false : prevState.exportStepsOptions.includePis,
        includeAts: prevState.exportStepsOptions.includeBcs && prevState.exportStepsOptions.includeAts ?
        false : prevState.exportStepsOptions.includeAts,
      },
    }));
  }

  private includePis = () => {
    this.setState(prevState => ({
      exportStepsOptions: {
        ...prevState.exportStepsOptions,
        includePis: !prevState.exportStepsOptions.includePis,
        includeAts: prevState.exportStepsOptions.includePis && prevState.exportStepsOptions.includeAts ?
        false : prevState.exportStepsOptions.includeAts,
      },
    }));
  }

  private includeAts = () => {
    this.setState(prevState => ({
      exportStepsOptions: {
        ...prevState.exportStepsOptions,
        includeAts: !prevState.exportStepsOptions.includeAts,
      },
    }));
  }

  private toggleMiAt = () => {
    this.setState({ exportStepsOptions: {
      ...this.state.exportStepsOptions,
      includeMiAt: !this.state.exportStepsOptions.includeMiAt,
    }});
  }

  private toggleMetrics = () => {
    this.setState({ exportStepsOptions: {
      ...this.state.exportStepsOptions,
      includeMetrics: !this.state.exportStepsOptions.includeMetrics,
    }});
  }

  /**
   * Toggle add/remove 1 activity id in state bcs/pis
   * @param {TypeActivity} type
   * @param {number} id
   * @param {boolean} isPresent
   * @param {number} parentId
   */
  private toggleActivity = (type: TypeActivity, id: number, isPresent: boolean, parentId?: number) => {
    switch (type) {
      case TypeActivity.BUSINESS_CHALLENGE:
        const filteredBcs = !isPresent ? [...this.state.exportStepsOptions.bcs, id]
        : this.state.exportStepsOptions.bcs.filter(bc => bc !== id);
        let filteredPis: number[] = [];

        // Remove all pis when unselect a bc
        if (isPresent) {
          const pp = { ...this.state.performancePlan as PerformancePlan };
          if (pp) {
            let bc = pp.businessChallenges.find(bc => bc.id === id);
            if (!bc && pp.linkedBcs) {
              bc = pp.linkedBcs.find(bc => bc.id === id);
            }
            if (bc) {
              if (bc.linkedPis) {
                const linkedPis = bc.linkedPis.map(pi => pi.id);
                filteredPis = filteredPis.filter(pi => !linkedPis.includes(pi));
              }
            }
          }
        }

        this.setState(prevState => ({
          exportStepsOptions: {
            ...prevState.exportStepsOptions,
            bcs: filteredBcs,
            pis: isPresent ? filteredPis : prevState.exportStepsOptions.pis,
          },
        }));
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        const newPis = [...this.state.exportStepsOptions.pis, id];
        const bcs = this.state.exportStepsOptions.bcs;

        // Add bc parent when select a pi
        if (!isPresent && parentId) {
          if (!this.state.exportStepsOptions.bcs.includes(parentId)) {
            bcs.push(parentId);
          }
        }

        this.setState(prevState => ({
          exportStepsOptions: {
            ...prevState.exportStepsOptions,
            bcs,
            pis: !isPresent ? newPis : prevState.exportStepsOptions.pis.filter(pi => pi !== id),
          },
        }));
        break;
    }
  }

  /**
   * Toggle add/remove all activities id in state bcs/pis
   * @param {boolean} selectAll
   */
  private toggleActivities = (selectAll: boolean) => {
    const pp = { ...this.state.performancePlan as PerformancePlan };
    if (selectAll && pp) {
      let selectedBcs: number[] = [];
      let selectedPis: number[] = [];

      selectedBcs = pp.businessChallenges.map(bc => bc.id);
      if (pp.linkedBcs) {
        selectedBcs = selectedBcs.concat(pp.linkedBcs.map(bc => bc.id));

        // Add linked pi from linked bc
        pp.linkedBcs.forEach((bc) => {
          selectedPis = selectedPis.concat(bc.performanceInitiatives.map(pi => pi.id));
          if (bc.linkedPis) {
            selectedPis = selectedPis.concat(bc.linkedPis.map(pi => pi.id));
          }
        });
      }

      pp.businessChallenges.forEach((bc) => {
        selectedPis = selectedPis.concat(bc.performanceInitiatives.map(pi => pi.id));
        if (bc.linkedPis) {
          selectedPis = selectedPis.concat(bc.linkedPis.map(pi => pi.id));
        }
      });
      this.setState(prevState => ({
        exportStepsOptions: {
          ...prevState.exportStepsOptions,
          bcs: selectedBcs,
          pis: selectedPis,
        },
      }));
    } else {
      this.setState(prevState => ({
        exportStepsOptions: {
          ...prevState.exportStepsOptions,
          bcs: [],
          pis: [],
        },
      }));
    }
  }

  private nextStep = (type?: ExportType, reporting?: ReportingGetFormatted) => {
    this.setState(
      prevState => (
        {
          steps: prevState.steps + 1,
          exportStepsOptions: {
            ...prevState.exportStepsOptions,
            selectedExportType: type !== undefined ? type : prevState.exportStepsOptions.selectedExportType,
            selectedReporting: reporting ? reporting : prevState.exportStepsOptions.selectedReporting,
          },
        }),
      () => {
        if (this.state.steps === 2 && (this.state.exportStepsOptions.selectedExportType === ExportType.CUSTOM
        || this.state.exportStepsOptions.selectedExportType === ExportType.REPORTING)) {
          this.getPerformancePlan();
        }
      });
  }

  private previousStep = () => {
    this.setState(prevState => ({
      steps: prevState.steps - 1,
    }));
  }

  private getPerformancePlan = async () => {
    const pp = await PerformancePlanAPI.getPerformancePlanCockpitById(+this.props.match.params.id);
    this.setState(
      {
        performancePlan: pp,
      },
      () => {
        this.toggleActivities(true);
      });
  }

  private generateExport = () => {
    this.handleClick(
      ExportType.CUSTOM,
      this.props.data.id,
      undefined,
      undefined,
      new CustomOptions(this.state.exportStepsOptions),
    );
  }

  public getCanViewReporting = () => {
    switch (this.props.type) {
      case TypeActivity.PERFORMANCE_PLAN:
        return this.props.rightsOnPp?.canViewReporting();
      default:
        return false;
    }
  }

  public getCanEditRights = () => {
    switch (this.props.type) {
      case TypeActivity.BUSINESS_CHALLENGE:
        return this.props.rightsOnBc?.canEdit();
      case TypeActivity.PERFORMANCE_INITIATIVE:
        return this.props.rightsOnPi?.canEdit();
      case TypeActivity.PERFORMANCE_PLAN:
        return this.props.rightsOnPp?.canEdit();
    }
  }

  public getCanExportCockpitRights = () => {
    switch (this.props.type) {
      case TypeActivity.BUSINESS_CHALLENGE:
        return this.props.rightsOnBc?.canExportCockpit();
      case TypeActivity.PERFORMANCE_INITIATIVE:
        return this.props.rightsOnPi?.canExportCockpit();
      case TypeActivity.PERFORMANCE_PLAN:
        return this.props.rightsOnPp?.canExportCockpit();
    }
  }

  private exportCenterModal = () => {
    const canViewCockpit = this.getCanExportCockpitRights();
    return this.state.openExportModal && (
    <Modal
      id="export-center-modal"
      onClose={() => this.closeExportCenterModalExport()}
      open={this.state.openExportModal}
    >
    <Modal.Header>
      <FormattedMessage id="exportA" defaultMessage="Export a" /> {` ${Utils.getTypeName(this.props.type)}`}
    </Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <CustomScrollBars>
        {this.state.steps === 0 && <div className="options-container">
            {
              canViewCockpit &&
              <>
                <div className={`${!Utils.isActivityPp(this.props.type) ? 'export-cockpit' : 'export-cockpit-pp'}`}>
                  <Button onClick={() => this.exportCockpit()}>
                    <FormattedMessage id="cockpit-pptx" defaultMessage="Cockpit (PowerPoint)" />
                  </Button>
                  {!Utils.isActivityPp(this.props.type) &&
                    <ExportOptionPptx
                      exportStepsOptions={this.state.exportStepsOptions}
                      toggleMetrics={this.toggleMetrics}
                      toggleMiAt={this.toggleMiAt}
                    />
                  }
                </div>
                <Button onClick={() => this.exportWhole()}>
                  {Utils.isActivityPp(this.props.type) &&
                  <>
                    <FormattedMessage id="wholePlan" defaultMessage="Whole Plan" />
                    <Icon name="angle right"/>
                  </>
                  }
                  {Utils.isActivityBc(this.props.type) &&
                  <FormattedMessage id="wholeBC" defaultMessage="Whole BC" />}
                  {Utils.isActivityPi(this.props.type) &&
                  <FormattedMessage id="wholePI" defaultMessage="Whole PI" />}
                </Button>
              </>
            }
          {Utils.isActivityPp(this.props.type) && this.state.reportings.length > 0 &&
          this.props.rightsOnPp?.canExportReporting() &&
          <Button onClick={() => this.nextStep(ExportType.REPORTING)}>
            <FormattedMessage id="reportings" defaultMessage="Reportings"/> <Icon name="angle right"/>
          </Button>}
            {
              canViewCockpit &&
              <>
                {this.renderActionList()}
                {Utils.isActivityPp(this.props.type) &&
                <Button onClick={() => this.nextStep(ExportType.CUSTOM)}>
                  <FormattedMessage id="custom" defaultMessage="Custom"/> <Icon name="angle right"/>
                </Button>}
              </>
            }
        </div>}
        {this.state.steps === 1 &&
        <ExportSecondStep
          reportings={this.state.reportings}
          data={this.props.data}
          exportStepsOptions={this.state.exportStepsOptions}
          type={this.props.type}
          handleClick={this.handleClick}
          nextStep={this.nextStep}
          includeBcs={this.includeBcs}
          includePis={this.includePis}
          includeAts={this.includeAts}
          exportSheet={this.exportSheet}
        />}
        {this.state.steps === 2 &&
        <ExportThirdStep
          data={this.props.data}
          exportStepsOptions={this.state.exportStepsOptions}
          handleClick={this.handleClick}
          toggleActivity={this.toggleActivity}
          toggleActivities={this.toggleActivities}
          performancePlan={this.state.performancePlan}
        />}
        </CustomScrollBars>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
    {this.state.steps > 0 &&  <Button onClick={() => this.previousStep()}>
      <FormattedMessage id="previous" defaultMessage="Previous" />
      </Button>}
    <Button onClick={() => this.closeExportCenterModalExport()}>
    <FormattedMessage id="cancel" defaultMessage="cancel" />
      </Button>
    {this.state.steps > 0 && this.state.steps < 2 &&
    this.state.exportStepsOptions.selectedExportType === ExportType.CUSTOM &&
    <Button
      className="next-button"
      primary={true}
      onClick={() => this.nextStep()}
      disabled={!this.state.exportStepsOptions.includeBcs}
    >
      <FormattedMessage id="next" defaultMessage="Next" /> <Icon name="chevron right" />
    </Button>}
    {this.state.steps >= 2 && this.state.exportStepsOptions.selectedExportType === ExportType.CUSTOM &&
    <Button
      primary={true}
      onClick={() => this.generateExport()}
      disabled={this.state.exportStepsOptions.bcs.length === 0 && this.state.exportStepsOptions.pis.length === 0}
    >
      <FormattedMessage id="generate" defaultMessage="generate" />
    </Button>}
    </Modal.Actions>
    </Modal>);
  }

  public render() {
    if (this.getCanExportCockpitRights() && this.props.mode === ModeTypes.MODE_VIEW) {
      const button = (
        <Button
          className="btn-export"
          key="export-dropdown-button"
          basic={true}
          id="export-dropdown-button"
          disabled={!ExportStore.getCanExport()}
          onClick={() => this.handleOpenModal()}
        >
          <Icon name="download"/>
          <FormattedMessage id="export" defaultMessage="Export"/>
        </Button>
      );
      return (
        <div
          key="export-dropdown-container"
        >
          {ExportStore.getCanExport()
            ? button
            : <FormattedMessage id="exportAlreadyInProgress" defaultMessage="An export is already in progress">
              {msg =>
                <Popup
                  inverted={true}
                  content={msg}
                  size="tiny"
                  position="bottom center"
                  trigger={<span>{button}</span>}
                />
              }
            </FormattedMessage>
          }
          {this.exportCenterModal()}
          {this.confirmModalExport()}
        </div>
      );
    }

    return null;
  }
}

export default withRouter(injectIntl(ExportCenter));
