import HttpClient from '../utils/httpClient';
import UsersCounts from '../models/usersCounts';
import ActivitiesCounts from '../models/activitiesCounts';
import ConnectionsCount from '../models/connectionsCount';
import MonthlyReport from '../models/monthlyReport';

export default class StatsAPI {

  /**
   * GET : /api/admin/statistics/users
   * Get the users counts
   * @returns Promise<UsersCounts>
   */
  public static getUsersCount(): Promise<UsersCounts> {
    return HttpClient.get<UsersCounts>('admin/statistics/users');
  }

  /**
   * GET : /api/admin/statistics/activities
   * Get the activities counts
   * @returns Promise<ActivitiesCounts>
   */
  public static getActivitiesCount(): Promise<ActivitiesCounts> {
    return HttpClient.get<ActivitiesCounts>('admin/statistics/activities');
  }

  /**
   * GET : /api/admin/statistics/connections
   * Get the connections counts with date
   * @returns Promise<ConnectionsCount[]>
   */
  public static getConnectionsCount(): Promise<ConnectionsCount[]> {
    return HttpClient.get<ConnectionsCount[]>('admin/statistics/connections');
  }

  /**
   * GET : /api/admin/statistics/monthly-report
   * Get the monthly report for specified month
   * @returns Promise<any>
   */
  public static getMonthlyReport(params: { month: number, year: number }): Promise<MonthlyReport> {
    return HttpClient.get<MonthlyReport>(`admin/statistics/monthly-report${HttpClient.formatUrlQueryParams(params)}`);
  }
}
