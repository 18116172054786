import Pillar from '../models/pillar';

export enum Pillars {
  GROWTH = 1,
  COMPETITIVENESS = 2,
  PEOPLE = 3,
}

export enum PillarNames {
  GROWTH = 'Growth',
  COMPETITIVENESS = 'Competitiveness',
  PEOPLE = 'People',
}

export const pillarList = [
  new Pillar(Pillars.GROWTH, PillarNames.GROWTH),
  new Pillar(Pillars.COMPETITIVENESS, PillarNames.COMPETITIVENESS),
  new Pillar(Pillars.PEOPLE, PillarNames.PEOPLE),
];
