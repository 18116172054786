export interface SortBy {
  value: string;
  order: 'ASC' | 'DESC' | string;
}

export const sortOptions = [
  {
    id: 'code',
    text: 'Code',
    value: 'CODE',
  },
  {
    id: 'startingDate',
    text: 'Starting date',
    value: 'STARTING_DATE',
  },
  {
    id: 'targetDate',
    text: 'Target date',
    value: 'TARGET_DATE',
  },
  {
    id: 'forecastDate',
    text: 'Forecast date',
    value: 'FORECAST_DATE',
  },
  {
    id: 'dateOfCompletion',
    text: 'Completion date',
    value: 'COMPLETION_DATE',
  },
];
