import React from 'react';
import TypeActivity from '../../../../../../constants/typeActivity';
import { Tab } from 'semantic-ui-react';
import LastUpdatedInformations from './tabs/lastUpdatedInformations/lastUpdatedInformations';
import PublicationHistory from './tabs/publication/publicationHistory';
import BusinessChallenge from '../../../../../../models/businessChallenge';
import PerformanceInitiative from '../../../../../../models/performanceInitiative';
import Utils from '../../../../../../utils/utils';
import BcPiDetails from './tabs/bcPiDetails/bcPiDetails';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import Action from '../../../../../../models/action';
import Milestone from '../../../../../../models/milestone';
import ActionMilestone from './tabs/actionMilestone/actionMilestone';

interface IProps {
  typeActivity: TypeActivity | undefined;
  activity: BusinessChallenge | PerformanceInitiative | Action | Milestone | undefined;
  routeActivityId: string;
  contentPanelHeight: number;
  handleTabChange(index: number): void;
  activeIndex: number;
  canEditPi: boolean;
  setPanelErrStatus(isOnErr: boolean): void;
  resetPanelActivity(activityIdToScrollTo?: number): void;
}
interface IStates {}

class PanelContent extends React.Component<IProps & InjectedIntlProps, IStates> {

  generatePane = (messageId: string) => {
    let message;
    let tabContent;
    switch (messageId) {
      case 'overviewPanel.lastUpdate':
        message = this.props.intl.formatMessage(
          {
            id: 'overviewPanel.lastUpdate',
            defaultMessage: 'Last update',
          });
        tabContent = (
          <LastUpdatedInformations
            typeActivity={this.props.typeActivity}
            activityId={this.props.activity?.id ?? +this.props.routeActivityId}
            handleTabChange={this.props.handleTabChange}
            contentPanelHeight={this.props.contentPanelHeight}
          />
        );
        break;
      case 'overviewPanel.publication':
        message = this.props.intl.formatMessage(
          {
            id: 'overviewPanel.publication',
            defaultMessage: 'Publication',
          });
        tabContent = (
          <PublicationHistory
            typeActivity={this.props.typeActivity}
            activity={this.props.activity as (BusinessChallenge | PerformanceInitiative)}
            contentPanelHeight={this.props.contentPanelHeight}
          />
        );
        break;
      case 'overviewPanel.details':
        message = this.props.intl.formatMessage(
          {
            id: 'overviewPanel.details',
            defaultMessage: 'Details',
          });
        tabContent = (
          <BcPiDetails
            typeActivity={this.props.typeActivity}
            activity={this.props.activity  as (BusinessChallenge | PerformanceInitiative)}
            contentPanelHeight={this.props.contentPanelHeight}
            setPanelErrStatus={this.props.setPanelErrStatus}
          />
        );
    }

    return (
      {
        menuItem: message,
        render: () => (
            <Tab.Pane attached={false}>
              {tabContent}
            </Tab.Pane>
        ),
      }
    );
  }

  getPanelContentAsTabs = () => {
    const panes = [
      this.generatePane('overviewPanel.lastUpdate'),
      this.generatePane('overviewPanel.publication'),
    ];

    if (!Utils.isActivityPp(this.props.typeActivity) || (this.props.activity && this.props.typeActivity)) {
      panes.unshift(this.generatePane('overviewPanel.details'));
    }
    return (
      <Tab
        id="overview-details-container"
        onTabChange={(event, data) => this.props.handleTabChange(data.activeIndex as number)}
        activeIndex={this.props.activeIndex}
        className={`${(Utils.isActivityBc(this.props.typeActivity) || Utils.isActivityPi(this.props.typeActivity)) && this.props.activeIndex === 0 ? 'expand' :''}`}
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
    );
  }

  render() {
    if (!Utils.isActivityActionOrMilestone(this.props.typeActivity)) {
      return this.getPanelContentAsTabs();
    }
    if (Utils.isActivityActionOrMilestone(this.props.typeActivity)) {
      return (
        <ActionMilestone
          activity={this.props.activity as Action | Milestone}
          activityType={this.props.typeActivity!!}
          canEditPi={this.props.canEditPi}
          contentPanelHeight={this.props.contentPanelHeight}
          piId={+this.props.routeActivityId}
          resetPanelActivity={this.props.resetPanelActivity}
        />
      );
    }
    return Utils.loader();
  }
}
export default injectIntl(PanelContent);
