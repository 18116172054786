import './publicationRow.scss';

import React from 'react';
import { Form, Input, Label } from 'semantic-ui-react';
import BusinessChallengeAPI from 'src/api/businessChallenge.api';
import PerformanceInitiativeAPI from 'src/api/performanceInitiative.api';
import PerformancePlanAPI from 'src/api/performancePlan.api';
import ModeTypes from 'src/constants/modeTypes';
import TypeActivity from 'src/constants/typeActivity';
import History from 'src/models/history';
import Utils from 'src/utils/utils';
import SegmentEditButtons from '../../../../../segmentEditButtons/segmentEditButtons';
import NotificationActions from 'src/actions/notification-actions';
import { FormattedMessage } from 'react-intl';
import { ToastType } from 'src/components/common/toast/toast';
import { RightsOnBC, RightsOnPI, RightsOnPP } from 'src/models/rights';
import { SegmentType } from '../../../../../../../../../models/segmentsMode';
import SegmentsStore from 'src/stores/segments.store';
import ConfirmModal from '../../../../../../confirmModal/confirmModal';
import PerformanceInitiativeActions from '../../../../../../../../../actions/performanceInitiative.action';
import BusinessChallengeActions from '../../../../../../../../../actions/businessChallenge.action';
import PerformancePlanActions from '../../../../../../../../../actions/performancePlan.action';
import { ArchiveHistoryResult } from '../../../../../../../../../constants/interfaces/common.interface';

interface IProps {
  publication: History;
  handleHistoryNavigation: (publication: History) => void;
  typeActivity: TypeActivity | undefined;
  activityId: number;
  isSameActivityRoute: () => boolean;
  editedHistory: (history: History) => void;
  archiveHistory: (history: History) => void;
  rightsOnBc: RightsOnBC | undefined;
  rightsOnPi: RightsOnPI | undefined;
  rightsOnPp: RightsOnPP | undefined;
}

interface IStates {
  mode: ModeTypes;
  name: string;
  isArchiveModalOpen: boolean;
}

class PublicationRow extends React.Component<IProps, IStates> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      mode: SegmentsStore.getSegmentModeById(this.getSegmentType()) || ModeTypes.MODE_VIEW,
      name: '',
      isArchiveModalOpen: false,
    };
  }

  private getSegmentType = (): SegmentType | string => {
    switch (this.props.typeActivity) {
      case TypeActivity.PERFORMANCE_PLAN:
        return `${SegmentType.PP_PUBLICATIONS}-${this.props.publication.id}`;
      case TypeActivity.BUSINESS_CHALLENGE:
        return `${SegmentType.BC_PUBLICATIONS}-${this.props.publication.id}`;
      case TypeActivity.PERFORMANCE_INITIATIVE:
      default:
        return `${SegmentType.PI_PUBLICATIONS}-${this.props.publication.id}`;
    }
  }

  private onEdit = () => {
    this.setState({
      name: this.props.publication.name,
    });
  }

  private onCancel = () => {
    this.setState({
      name: this.props.publication.name,
    });
  }

  private setArchiveModal = (isOpen: boolean, cancelDeletion?: boolean) => {
    this.setState({
      isArchiveModalOpen: isOpen,
    });
    if (!isOpen) {
      SegmentsStore.emitSetDeletingSegmentsToEdition(cancelDeletion);
    }
  }

  private confirmArchive = async () => {
    let promise;
    const [activityId, publicationId] = [this.props.activityId, this.props.publication.id];
    switch (this.props.typeActivity) {
      case TypeActivity.PERFORMANCE_PLAN:
        promise = PerformancePlanAPI.archiveHistory(activityId, publicationId);
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        promise = BusinessChallengeAPI.archiveHistory(activityId, publicationId);
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        promise = PerformanceInitiativeAPI.archiveHistory(activityId, publicationId);
        break;
    }
    try {
      const result: ArchiveHistoryResult = await promise;
      if (result.hasBeenUpdated) {
        switch (this.props.typeActivity) {
          case TypeActivity.PERFORMANCE_INITIATIVE:
            PerformanceInitiativeActions.emitChangePerformanceInitiative('publishedDate', result.publicationDate);
            break;
          case TypeActivity.BUSINESS_CHALLENGE:
            BusinessChallengeActions.emitChangeBusinessChallenge('publishedDate', result.publicationDate);
            break;
          case TypeActivity.PERFORMANCE_PLAN:
            PerformancePlanActions.onMostRecentHistoryDeletion();
            break;
        }
      }
      this.showNotificationToast(true, ModeTypes.MODE_DELETE);
      this.setArchiveModal(false);
      this.props.archiveHistory(this.props.publication);
    } catch (error) {
      this.showNotificationToast(false);
    }
  }

  private onSave = async () => {
    let promise;
    if (this.state.name === this.props.publication.name) {
      return;
    }
    const [activityId, publicationId] = [this.props.activityId, this.props.publication.id];
    switch (this.props.typeActivity) {
      case TypeActivity.PERFORMANCE_PLAN:
        promise = PerformancePlanAPI.editHistoryName(activityId, publicationId, this.state.name);
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        promise = BusinessChallengeAPI.editHistoryName(activityId, publicationId, this.state.name);
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        promise = PerformanceInitiativeAPI.editHistoryName(activityId, publicationId, this.state.name);
        break;
    }
    try {
      const historyRow = await promise;
      this.props.editedHistory(historyRow);
      this.showNotificationToast(true, ModeTypes.MODE_EDITION);
    } catch (error) {
      this.showNotificationToast(false);
    }
  }

  private showNotificationToast = (success: boolean, modeTypes?: ModeTypes) => {
    if (!success) {
      Utils.toastError();
    }

    let message;
    switch (modeTypes) {
      case ModeTypes.MODE_EDITION:
        message = (
          <FormattedMessage
            id="successPublicationUpdated"
            defaultMessage="This publication has been successfully updated"
          />);
        break;
      case ModeTypes.MODE_DELETE:
        message = (
        <FormattedMessage
          id="successPublicationArchived"
          defaultMessage="This publication has been successfully deleted"
        />);
        break;
    }
    NotificationActions.toast(
      <FormattedMessage id="saved" defaultMessage="Saved!" />,
      message,
      ToastType.SUCCESS,
    );
  }

  private changeMode = (mode: ModeTypes) => {
    this.setState({ mode: !mode ? ModeTypes.MODE_VIEW : mode });
  }

  private onNameChange = (name: string) => {
    this.setState({ name });
  }

  private getCanEdit = () => {
    switch (this.props.typeActivity) {
      case TypeActivity.PERFORMANCE_PLAN:
        return this.props.rightsOnPp?.canEdit();
      case TypeActivity.BUSINESS_CHALLENGE:
        return this.props.rightsOnBc?.canEdit();
      case TypeActivity.PERFORMANCE_INITIATIVE:
        return this.props.rightsOnPi?.canEdit();
    }
  }

  private renderPublication = () => {
    return (
      <>
        <span className="span-date" onClick={() => this.props.handleHistoryNavigation(this.props.publication)}>
        {this.props.publication.name ? `${this.props.publication.name} - ` : ''}
          <em>{Utils.displayFancyDate(this.props.publication.publishedDate)}</em>
        </span>
        {Utils.isBoundToParent(this.props.publication, this.props.typeActivity) && this.getActivityLabel()}
      </>
    );
  }

  private getActivityLabel = () => {
    return (
          <Label className="linked-publications-history">
            {Utils.getTypeName(Utils.parentType(this.props.typeActivity), false, true)}
          </Label>
    );
  }

  private renderForm = () => {
    return (
      <FormattedMessage id="publication.name" defaultMessage="Publication name">
        {msg =>
          <Form>
            <Input
              size="small"
              value={this.state.name ?? ''}
              onChange={(e, { value }) => this.onNameChange(value)}
              placeholder={msg}
              maxLength={60}
            />
            <div>
              <em>{Utils.displayFancyDate(this.props.publication.publishedDate)}</em>
            </div>
          </Form>}
      </FormattedMessage>
    );
  }

  public render() {
    const title = <FormattedMessage id="publication.deleteModal.title" defaultMessage="Delete the Publication" />;
    const message = <FormattedMessage id="publication.deleteConfirmation" defaultMessage="Are you sure you want to delete this publication?" />;

    return (
      <div className="publication-row">
        {Utils.isOnViewMode(this.state.mode) ? this.renderPublication() : this.renderForm()}
        {this.props.isSameActivityRoute() && this.getCanEdit()
          && <SegmentEditButtons
            mode={this.state.mode}
            segmentType={this.getSegmentType()}
            popupPosition={'right center'}
            hideButtons={true}
            deletable={true}
            onSave={this.onSave}
            onEdit={this.onEdit}
            onCancel={this.onCancel}
            onDelete={() => this.setArchiveModal(true)}
            changeMode={this.changeMode}
            publicationHeight={true}
          />
        }
        {Utils.isOnDeleteMode(this.state.mode) &&
          <ConfirmModal
            title={title}
            message={message}
            closeNoFunction={() => this.setArchiveModal(false, true)}
            closeYesFunction={this.confirmArchive}
          />
        }
      </div>
    );
  }
}

export default PublicationRow;
