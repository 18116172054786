import React, { Component } from 'react';
import { Icon, Input, Popup, Table } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { FormattedMessage } from 'react-intl';

import Utils from '../../../../../utils/utils';
import ToggleHideElement from '../../toggleHideElement/toggleHideElement';
import Milestone from '../../../../../models/milestone';
import ModeTypes from '../../../../../constants/modeTypes';
import { FieldType } from '../metricsContainer';
import ImportedLabel from '../shared/importedLabel';
import TypeActivity, { MetricMilestone } from 'src/constants/typeActivity';
import businessChallengeStore from 'src/stores/businessChallenge.store';
import performanceInitiativeStore from 'src/stores/performanceInitiative.store';

interface IProps {
  mode: ModeTypes;
  milestone: Milestone;
  index: number;

  updateMilestone(index: number, { name, value }: any): void;
  removeMilestone(index: number): void;
}

export default class MilestoneRow extends Component<IProps> {

  public constructor(props: IProps) {
    super(props);
  }

  private updateMilestone(name: string, value: any) {
    this.props.updateMilestone(this.props.index, { name, value });
  }

  private getPiIdByCode = (piCode: number | undefined) => {
    if (piCode) {
      return businessChallengeStore.getBusinessChallenge().performanceInitiatives.find(pi => pi.code === piCode)?.id;
    }
  }

  render() {
    const milestone = this.props.milestone;
    const bc = businessChallengeStore.getBusinessChallenge();
    const pi = performanceInitiativeStore.getPerformanceInitiative();
    const milestoneOriginMsg = [
      milestone.piCode ? Utils.formatActivityCode(milestone.piCode, TypeActivity.PERFORMANCE_INITIATIVE) : null,
    ];

    const isLinked = !!milestone.piCode;

    if (Utils.isOnViewOrDeletionMode(this.props.mode)) {
      return (
        <Table.Row>
          <Table.Cell className="title-table">
            <div>
              {milestone.name}
              {(isLinked) &&
              <ImportedLabel
                ppId={!!bc ? bc.performancePlanId : pi.businessChallenge.performancePlanId}
                piId={this.getPiIdByCode(milestone.piCode)}
                metricMilestone={milestone}
                type={MetricMilestone.MILESTONE}
              />}
            </div>
          </Table.Cell>
          <Table.Cell>
            {milestone.targetDate
              ? Utils.displayDate(milestone.targetDate)
              : <FormattedMessage id="noValue" defaultMessage="None" />
            }
          </Table.Cell>
          <Table.Cell>
            {milestone.completionDate
              ? Utils.displayDate(milestone.completionDate)
              : <FormattedMessage id="noValue" defaultMessage="None" />
            }
          </Table.Cell>
          <Table.Cell className="hide">
            <ToggleHideElement isElementHidden={milestone.isHidden} viewOnly={true} />
          </Table.Cell>
        </Table.Row>
      );
    }

    const isEditionDisabled = Utils.isCurrentActivityTypeBC() && (!!milestone.piCode);
    const isMilestoneNameInvalid = Utils.isFieldOnError(milestone.name, true, FieldType.STRING);
    const isMilestoneTargetDateInvalid = milestone.targetDate === null || Utils.isFieldOnError(milestone.targetDate,
                                                                                               true);

    const row = (
      <Table.Row key={`${milestone.id}-${this.props.index}`} className={isEditionDisabled ? 'disabled-row' : ''}>
        <Table.Cell>
          <Input
            name="name"
            value={milestone.name}
            disabled={isEditionDisabled}
            onChange={(event, { name, value }) => this.updateMilestone(name, value)}
            error={isMilestoneNameInvalid}
          />
        </Table.Cell>
        <Table.Cell>
          <DateInput
            className={isMilestoneTargetDateInvalid ? 'error' : ''}
            name="targetDate"
            disabled={isEditionDisabled}
            format={Utils.getDateFormat()}
            placeholder="Date"
            popupPosition="top center"
            value={milestone.targetDate ? Utils.displayDate(milestone.targetDate) : ''}
            startMode="month"
            iconPosition="left"
            onChange={(event, { name, value }) => this.updateMilestone(name, value)}
          />
        </Table.Cell>
        <Table.Cell>
          <DateInput
            name="completionDate"
            disabled={isEditionDisabled}
            placeholder="Date"
            startMode="month"
            popupPosition="top center"
            format={Utils.getDateFormat()}
            value={milestone.completionDate ? Utils.displayDate(milestone.completionDate) : ''}
            iconPosition="left"
            onChange={(event, { name, value }) => this.updateMilestone(name, value)}
          />
        </Table.Cell>
        <Table.Cell className="actions-container">
          <div className="action-icons">
            <ToggleHideElement
              isElementHidden={milestone.isHidden}
              updateElementState={() => this.updateMilestone('isHidden', !milestone.isHidden)}
            />
            <Icon name="trash" onClick={() => this.props.removeMilestone(this.props.index)} />
          </div>
        </Table.Cell>
      </Table.Row>
    );

    return (isEditionDisabled
      ? (
        <Popup
          key={`popup-edition-disabled-${milestone.id}`}
          inverted={true}
          content={(
            <>
              <FormattedMessage
                id="imported.edit.notAllowed"
                defaultMessage="You can only edit at original PI level"
              />
              &nbsp;
              <b className="original-pi">
                {milestoneOriginMsg}
              </b>
            </>
          )}
          size="tiny"
          position="left center"
          trigger={row}
        />
      )
      : row
    );
  }
}
