import * as React from 'react';
import { Input, Table } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';

import Utils from '../../../../../../../../utils/utils';
import { MetricValueGet, MetricValuePost, MostRecentMetricValueDto } from '../../../../../../../../models/metric';
import { FieldType } from '../../../../metricsContainer';
import moment from 'moment';

type IProps = {
  metricIndex: number | null;
  metricValueIndex: number | null;
  mostRecentTarget: number | null;
  metricValue?: MetricValueGet | MostRecentMetricValueDto;
  metricIsEmpty: boolean;
  updateMetricValue(metricValue: MetricValueGet, mIndex: number | null, mvIndex: number | null, newMv: boolean);
  isImported: boolean | undefined;
};

interface IStates {
  newMetricValue: MetricValuePost;
}

export default class MetricValueEdit extends React.Component<IProps, IStates> {

  static defaultProps = {
    metricValueIndex: null,
    metricIndex: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      newMetricValue: {
        metricId: 0,
        current: null,
        target: this.props.mostRecentTarget || null,
        updateDate: new Date(),
        hasImage: false,
      },
    };
  }

  public handleChangeMetricValue = ({ name, value }: { name: string, value: string | number | Date | null }) => {
    let filteredValue = value;
    if ((name === 'current' && (value === ''))) {
      filteredValue = null;
    } else if (name === 'updateDate') {
      filteredValue = value !== '' ?  moment(value, Utils.getDateFormat()).toDate() : null;
    }

    const updatedMv = this.props.metricValue ? { ...this.props.metricValue } : { ...this.state.newMetricValue };
    // check if this is a new metric value
    const newMv = !this.props.metricValueIndex;

    updatedMv[name] = filteredValue;
    this.setState({ newMetricValue: updatedMv });

    if (this.props.metricValue && (this.props.metricValue as MetricValueGet).id) {
      updatedMv.id = (this.props.metricValue as MetricValueGet).id;
    }

    this.props.updateMetricValue(
      updatedMv as MetricValueGet, this.props.metricIndex, this.props.metricValueIndex, newMv);
  }

  render() {
    const isCurrentInvalid = this.props.metricValue
      ? Utils.isFieldOnError(this.props.metricValue.current, false, FieldType.NUMBER)
      : false;
    const isTargetInvalid = this.props.metricValue
      ? Utils.isFieldOnError(this.props.metricValue.target, true, FieldType.NUMBER)
      : (!this.state.newMetricValue.target && this.props.metricIsEmpty);
    const isUpdateDateInvalid = !Utils.isDateFormatLanguageValid(this.state.newMetricValue.updateDate);

    return (
      <>
        <Table.Cell className="bold-item" width={3}>
          <DateInput
            name="updateDate"
            placeholder="Date"
            popupPosition="top center"
            dateFormat={Utils.getDateFormat()}
            disabled={this.props.isImported}
            value={(this.props.metricValue?.updateDate)
              ? Utils.displayDate(this.props.metricValue.updateDate)
              : Utils.displayDate(this.state.newMetricValue.updateDate)}
            startMode="month"
            iconPosition="left"
            onChange={(event: any, { name, value }: any) => this.handleChangeMetricValue({ name, value })}
            error={isUpdateDateInvalid}
          />
        </Table.Cell>
        <Table.Cell className="bold-item" width={2}>
          <Input
            name="current"
            disabled={this.props.isImported}
            value={(this.props.metricValue && (this.props.metricValue.current
              || this.props.metricValue.current === 0))
              ? this.props.metricValue.current
              : (this.state.newMetricValue.current) || ''}
            pattern="[\-+]?[0-9]*\.?[0-9]?"
            onChange={(event: any, { name, value }: any) => event.target.validity.valid
              ? this.handleChangeMetricValue({ name, value })
              : null}
            error={isCurrentInvalid}
          />
        </Table.Cell>
        <Table.Cell className="bold-item" width={2}>
          <Input
            name="target"
            disabled={this.props.isImported}
            value={(this.props.metricValue && (this.props.metricValue.target
              || this.props.metricValue.target === 0))
              ? this.props.metricValue.target
              : (this.state.newMetricValue.target) || ''}
            pattern="[\-+]?[0-9]*\.?[0-9]?"
            onChange={(event: any, { name, value }: any) => event.target.validity.valid
              ? this.handleChangeMetricValue({ name, value })
              : null}
            error={isTargetInvalid}
          />
        </Table.Cell>
      </>
    );
  }
}
