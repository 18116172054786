import Action, { ActionGet } from 'src/models/action';
import Attachment from 'src/models/attachment';

import HttpClient from '../utils/httpClient';
import { AssignedUser, UsersAssignation } from '../models/user';
import LinkedMilestone, { LinkedAction } from 'src/models/linkedMilestone';

export default class ActionAPI {

  /**
   * GET : /api/performance-initiatives/{piId}/actions
   * Get the actions of a PI
   * @param piId : number
   * @param sortBy: string (optional)
   * @param orderBy: string (optional)
   * @returns Promise<Action>
   */
  public static async getActions(piId: number, sortBy?: string, orderBy?: string): Promise<Action[]> {
    const actions = await HttpClient.get<ActionGet[]>(`performance-initiatives/${piId}/actions${HttpClient.formatUrlQueryParams({ sortBy, orderBy })}`);
    return actions.map(at => new Action(at));
  }

  /**
   * GET : /api/actions/{atId}
   * Get an action by its id
   * @param atId : number
   * @returns Promise<Action>
   */
  public static async getActionById(atId: number): Promise<Action> {
    const action = await HttpClient.get<ActionGet>(`actions/${atId}`);
    return new Action(action);
  }

  /**
   * POST : /api/performance-initiatives/{piId}/actions
   * Create an action
   * @param piId : number
   * @param action
   * @returns Promise<Action>
   */
  public static postAction(piId: number, action: Action): Promise<Action> {
    return HttpClient.post<Action>(`performance-initiatives/${piId}/actions`, action);
  }

  /**
   * POST : /api/actions/{acId}
   * Create an action
   * @param action
   * @returns Promise<Action>
   */
  public static putAction(action: Action): Promise<Action> {
    return HttpClient.put<Action>(`actions/${action.id}`, action);
  }

  /**
   * PATCH : /api/actions/{acId}/close
   * Update Closed Status of an Action
   * @param atId
   * @param closedStatus
   * @returns Promise<Action>
   */
  public static toggleClosedActionStatus(atId: number, closedStatus: boolean): Promise<Action> {
    return HttpClient.patch<Action>(`actions/${atId}/close`, { isClosed: closedStatus });
  }

  /**
   * PATCH : /api/actions/{acId}/favourite
   * Update Favourite Status of an Action
   * @param atId
   * @param favouriteStatus
   * @returns Promise<Action>
   */
  public static toggleFavouriteActionStatus(atId: number, favouriteStatus: boolean): Promise<Action> {
    return HttpClient.patch<Action>(`actions/${atId}/favourite`, { isFavourite: favouriteStatus });
  }

  /**
   * DELETE : /api/actions/{atId}
   * Delete Action by Id
   * @param atId : number
   * @returns Promise<Action>
   */
  public static deleteActionById(atId: number): Promise<Action> {
    return HttpClient.deleteWithId<Action>(`actions/${atId}`);
  }

  /**
   * POST : /api/actions/{atId}/assign
   * Assign users to AT
   * @param atId : number
   * @param users : UsersAssignation
   * @returns Promise<void>
   */
  public static postActionAssignees(atId: number, users: UsersAssignation): Promise<AssignedUser[]> {
    return HttpClient.post<AssignedUser[]>(`actions/${atId}/assign`, users);
  }

  /**
   * DELETE : /api/actions/{atId}/unassign
   * Unassign users from AT
   * @param atId : number
   * @param users : UsersAssignation
   * @returns Promise<void>
   */
  public static deleteActionAssignees(atId: number, users: UsersAssignation): Promise<void> {
    return HttpClient.delete<void>(`actions/${atId}/unassign`, users);
  }

  /**
   * ROUTE : /api/actions/{atId}/attachments
   * Get action attachments
   * @param {number} atId
   * @returns {Promise<Attachment[]>}
   */
  public static getActionAttachments(atId: number): Promise<Attachment[]> {
    return HttpClient.get<Attachment[]>(`actions/${atId}/attachments`);
  }

  /**
   * POST : /api/performance-initiatives/{atId}/actions
   * Create action attachments
   * @param atId : number
   * @param attachments
   * @returns Promise<Action>
   */
  public static postActionAttachments(atId: number, attachments: Attachment[]): Promise<Attachment[]> {
    const attachmentsWithoutTempId = attachments.map((attachment) => {
      const attachmentWithoutTempId = new Attachment();
      attachmentWithoutTempId.title = attachment.title;
      attachmentWithoutTempId.url = attachment.url;
      return attachmentWithoutTempId;
    });
    return HttpClient.post<Attachment[]>(`actions/${atId}/attachments`, attachmentsWithoutTempId);
  }

  /**
   * POST : /api/performance-initiatives/{atId}/actions
   * Create an action
   * @param atId : number
   * @param attachments
   * @returns Promise<Action>
   */
  public static putActionAttachments(atId: number, attachments: Attachment[]): Promise<Attachment[]> {
    return HttpClient.put<Attachment[]>(`actions/${atId}/attachments`, attachments);
  }

  /**
   * POST : /api/performance-initiatives/{atId}/actions
   * Create an action
   * @param atId : number
   * @param attachmentIds
   * @returns Promise<Action>
   */
  public static deleteActionAttachments(atId: number, attachmentIds: number[]): Promise<Attachment[]> {
    return HttpClient.delete<Attachment[]>(`actions/${atId}/attachments`, attachmentIds);
  }

  /**
   * ROUTE : /api/actions/{atId}/linked-milestone
   * Get action linked milestones
   * @param {number} atId
   * @returns {Promise<LinkedMilestone[]>}
   */
  public static getActionMilestones(atId: number): Promise<LinkedMilestone[]> {
    return HttpClient.get<LinkedMilestone[]>(`actions/${atId}/linked-milestone`);
  }

  /**
   * PUT : /api/actions/{atId}/linked-milestone/{miId}
   * Link a milestone
   * @param atId : number
   * @param miId : number
   * @returns Promise<Action>
   */
  public static putActionMilestones(atId: number, miId: number): Promise<LinkedMilestone> {
    const milestone = new LinkedMilestone();
    milestone.atId = atId;
    milestone.pimiId = miId;
    return HttpClient.put<LinkedMilestone>(`actions/${atId}/linked-milestone/${miId}`, milestone);
  }

  /**
   * DELETE : /api/actions/{atId}/linked-milestone/{miId}
   * Delete a linked milestone
   * @param atId : number
   * @param miId : number
   * @returns Promise<Action>
   */
  public static deleteActionMilestones(atId: number, miId: number): Promise<LinkedMilestone> {
    const milestone = new LinkedMilestone();
    milestone.atId = atId;
    milestone.pimiId = miId;
    return HttpClient.delete<LinkedMilestone>(`actions/${atId}/linked-milestone/${miId}`, milestone);
  }

  /**
   * ROUTE : /api/actions/{miId}/linked-action
   * Get action linked milestones
   * @param {number} miId
   * @returns {Promise<LinkedAction[]>}
   */
  public static getMilestoneActions(miId: number): Promise<LinkedAction[]> {
    return HttpClient.get<LinkedAction[]>(`actions/${miId}/linked-action`);
  }

  /**
   * PUT : /api/actions/{miId}/linked-action/{atId}
   * Link a milestone
   * @param atId : number
   * @param miId : number
   * @returns Promise<Action>
   */
  public static putMilestoneActions(atId: number, miId: number): Promise<LinkedAction> {
    const action = new LinkedAction();
    action.atId = atId;
    action.pimiId = miId;
    return HttpClient.put<LinkedAction>(`actions/${miId}/linked-action/${atId}`, action);
  }

  /**
   * DELETE : /api/actions/{atId}/linked-milestone/{miId}
   * Delete a linked milestone
   * @param atId : number
   * @param miId : number
   * @returns Promise<Action>
   */
  public static deleteMilestoneActions(atId: number, miId: number): Promise<LinkedAction> {
    const action = new LinkedAction();
    action.atId = atId;
    action.pimiId = miId;
    return HttpClient.delete<LinkedAction>(`actions/${miId}/linked-action/${atId}`, action);
  }

}
