import './main.scss';

import * as React from 'react';

import ActivitiesBoard from '../activitiesBoard/activitiesBoard';
import { withRouter } from 'react-router';

class Main extends React.Component<any, any> {

  public render() {

    return (
      <div>
        <section id="container-main">
          <div id="container-main-taskboard">
            <ActivitiesBoard />
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(Main);
