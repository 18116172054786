import './topBar.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { Container, Input, Menu, Popup } from 'semantic-ui-react';

import logo from '../../images/logo.svg';
import InfoContainer from './infoContainer/infoContainer';

interface IStates {
  searchValue: string;
}

class TopBar extends React.Component<RouteComponentProps, IStates> {

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      searchValue: '',
    };
  }

  private handleSearch = () => {
    this.props.history.push(`/search?q=${this.state.searchValue}`);
  };

  private handleInputChange = (e: any) => {
    this.setState({ searchValue: e.target.value });
  };

  public render() {
    return (
      <Menu fixed="top" inverted={true} id="topBar">
        <Container>
          <div>
            <NavLink to="/activities-board/taskboard" id="back-to-taskboard">
              <FormattedMessage
                id="topBar.home.tooltip"
                defaultMessage="Home"
              >
                {msg =>
                  <Popup
                    inverted={true}
                    content={msg}
                    size="tiny"
                    position="bottom center"
                    trigger={<ReactSVG id="ABHome" src={logo} />}
                  />
                }
              </FormattedMessage>
            </NavLink>
            <FormattedMessage id="topBar.workplace" defaultMessage="My workspace">
              {msg =>
                <Popup
                  inverted={true}
                  content={msg}
                  size="tiny"
                  position="bottom center"
                  trigger={
                    <NavLink
                      className={`topBar-navlink noselect-text
                        ${this.props.location.pathname.indexOf('activities-board') > -1 ? 'selected' : ''}`}
                      to="/activities-board/taskboard"
                    >
                      {msg}
                    </NavLink>
                  }
                />
              }
            </FormattedMessage>
            <FormattedMessage id="topBar.explore" defaultMessage="Explore">
              {msg =>
                <Popup
                  inverted={true}
                  content={msg}
                  size="tiny"
                  position="bottom center"
                  trigger={
                    <NavLink
                      className={`topBar-navlink noselect-text
                        ${this.props.location.pathname.indexOf('explore') > -1 ? 'selected' : ''}`}
                      to="/explore"
                    >
                      {msg}
                    </NavLink>
                  }
                />
              }
            </FormattedMessage>
          </div>
          <div>
            <div id="searchContainer" onKeyPress={event => event.key === 'Enter' ? this.handleSearch() : null}>
              <FormattedMessage id="topBar.searchBar" defaultMessage="Search...">
                {msg => (
                  <Input
                    action={{ icon: 'search', onClick: () => this.handleSearch() }}
                    placeholder={msg}
                    onChange={this.handleInputChange}
                  />
                )}
              </FormattedMessage>
            </div>
            <div>
              <InfoContainer />
            </div>
          </div>
        </Container>
      </Menu>
    );
  }
}

export default withRouter(TopBar);
