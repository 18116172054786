import './error.scss';

import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import ErrorImage from '../../images/error.png';
import { Button } from 'semantic-ui-react';

class Error extends React.Component<RouteComponentProps> {

  public constructor(props: RouteComponentProps) {
    super(props);
  }

  public render() {
    return (
      <div id="error-page">
        <img src={ErrorImage} alt="Error" className="error-img"/>
        <div className="error-block">
          <div className="error-head">
            <h2>
              <FormattedMessage
                id="errorTitle"
                defaultMessage="Oops ! The application encountered an unexpected error."
              />
            </h2>
            <p>
              <FormattedMessage
                id="errorMsg"
                defaultMessage="The application automatically sent an error report, you have nothing else to do. You can now reload the app."
              />
            </p>
            <Button
              className="error-btn"
              onClick={() => {
                window.localStorage.removeItem('error');
                this.props.history.push('/');
              }}
            >
              <FormattedMessage id="errorReload" defaultMessage="Reload the app" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Error);
