import './users.scss';

import * as React from 'react';
import Scrollbars, { positionValues } from 'react-custom-scrollbars';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { Button, Checkbox, Icon, Input, Modal, Segment, Table, TableHeaderCell } from 'semantic-ui-react';

import UserActions from 'src/actions/user.action';
import ActionTypes from 'src/constants/actionTypes';
import User from 'src/models/user';
import UserStore from 'src/stores/user.store';
import Utils from 'src/utils/utils';
import Role from 'src/models/role';
import UserRow from './userRow/userRow';
import UserCreation from './userCreation/userCreation';
import UserAPI, { GetUsersResult } from '../../../../api/user.api';
import Filter, { FilterCategory, FilterType } from '../../../common/form/filter/filter';
import CustomScrollBars from '../../../common/customScrollBars/customScrollBars';

enum FilterMethod {
  ROLE,
  SEARCH,
  CONFLICT,
}

enum Column {
  NAME = 'lastName',
  TGI = 'tgi',
  ROLE = 'roleId',
  CORE_TEAM = 'isCoreTeam',
  EMAIL = 'email',
  LAST_CONNECTION = 'lastConnection',
}

export enum Direction {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

export interface UserListFilters {
  conflicting: boolean;
  role: FilterType;
  search: string;
  sortColumn: Column;
  sortDirection: Direction;
  offset: number;
}

interface IStates {
  users: User[];
  isUserListLoading: boolean;
  nbUsers: number;
  roles: Role[];
  filters: UserListFilters;
  isUserCreationModalOpened: boolean;
  newPassword: { opened: boolean, password: string };
  newPasswordCopied: boolean;
  cursor: number;
  isLoadingNextUsers: boolean;
}

class Users extends React.Component<InjectedIntlProps, IStates> {

  private isMount = false;
  private ITEMS_PER_PAGE = 50;
  private scrollbarsRef = React.createRef<Scrollbars>();
  private timeout: NodeJS.Timeout;

  private DEFAULT_FILTERS: UserListFilters = {
    conflicting: false,
    role: FilterType.NONE,
    search: '',
    sortColumn: Column.NAME,
    sortDirection: Direction.ASCENDING,
    offset: 0,
  };

  public constructor(props: InjectedIntlProps) {
    super(props);

    this.state = {
      users: [],
      isUserListLoading: true,
      nbUsers: 0,
      roles: UserStore.getRoles(),
      filters: this.DEFAULT_FILTERS,
      isUserCreationModalOpened: false,
      newPassword: { opened: false, password: '' },
      newPasswordCopied: false,
      cursor: 0,
      isLoadingNextUsers: false,
    };
  }

  public componentWillMount() {
    this.isMount = true;
    UserStore.addListener(ActionTypes.USERS_ROLES_GET.toString(), this.getRoles);

    this.emitGetUsers().then(() => this.setState({ isUserListLoading: false }));
    UserActions.emitGetRoles();
  }

  public componentWillUnmount() {
    this.isMount = false;
    UserStore.removeListener(ActionTypes.USERS_ROLES_GET.toString(), this.getRoles);
  }

  private emitGetUsers = () => {
    return this.isMount
      ? UserAPI.getUsers(this.state.filters)
        .then((result: GetUsersResult) => {
          this.setState({
            users: [...this.state.users, ...result.users],
            nbUsers: result.count,
          });
        })
        .then(() => Promise.resolve())
      : Promise.resolve();
  };

  private getRoles = () => {
    if (this.isMount) {
      const referent = this.props.intl.formatMessage({
        id: 'referent',
        defaultMessage: 'Referent',
      });

      const informed = this.props.intl.formatMessage({
        id: 'informed',
        defaultMessage: 'Informed',
      });

      const basicUser = this.props.intl.formatMessage({
        id: 'basicUser',
        defaultMessage: 'Basic User',
      });

      const roles = UserStore.getRoles() ? UserStore.getRoles() : [];

      if (roles.length > 0) {
        roles[0].name = referent;
        roles[4].name = informed;
        roles[5].name = basicUser;
      }

      this.setState({ roles });
    }
  };

  public passwordReset = (password: string) => {
    this.setState({ newPassword: { password, opened: true } });
  };

  private filterUsersByRole = (filteredUsers: User[], selectedFilter: FilterType) => {
    this.filterUsers(FilterMethod.ROLE, selectedFilter);
  };

  private filterUsers = (
    filterMethod?: FilterMethod, selectedFilter?: FilterType, searchValue?: string, checked?: boolean) => {
    const filters = { ...this.state.filters };

    // Manage SEARCH filter
    if (filterMethod === FilterMethod.SEARCH && searchValue !== undefined) {
      filters.search = searchValue.toLowerCase();
    }

    // Manage ROLE filter
    if (filterMethod === FilterMethod.ROLE && selectedFilter !== undefined) {
      filters.role = selectedFilter;
    }

    // Manage CONFLICT filter
    if (filterMethod === FilterMethod.CONFLICT && checked !== undefined) {
      filters.conflicting = checked;
    }

    filters.offset = 0;

    if (!!this.scrollbarsRef.current) {
      this.scrollbarsRef.current.scrollToTop();
    }

    this.reloadUserList(filters);
  };

  /**
   * Sort the array depending on the column clicked
   * @param sortColumn : string
   */
  public handleSort = (sortColumn: Column) => {
    // If clicking on the same column, revert direction, else sort descending
    const sortDirection = sortColumn === this.state.filters.sortColumn
      ? this.state.filters.sortDirection === Direction.DESCENDING ? Direction.ASCENDING : Direction.DESCENDING
      : Direction.DESCENDING;

    const filters = { ...this.state.filters };
    filters.sortColumn = sortColumn;
    filters.sortDirection = sortDirection;

    this.reloadUserList(filters);
  };

  /**
   * Copy newly generated password to clipboard
   */
  private copyToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = this.state.newPassword.password;
    el.setAttribute('readonly', '');
    el.style.cssText = 'position:absolute;left:-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.setState(
      { newPasswordCopied: true },
      () => {
        setTimeout(
          () => this.setState({ newPasswordCopied: false }),
          2000,
        );
      },
    );
  };

  private loadMore = (values: positionValues) => {
    if (!this.state.isLoadingNextUsers && values.top > 0.9 && this.state.users.length < this.state.nbUsers) {
      const filters = { ...this.state.filters };
      const cursor = this.state.cursor + 1;
      filters.offset = this.ITEMS_PER_PAGE * cursor;

      this.setState(
        { filters, cursor, isLoadingNextUsers: true },
        () => this.emitGetUsers().then(() => this.setState({ isLoadingNextUsers: false })),
      );
    }
  };

  private reloadUserList = (filters?: UserListFilters) => {
    this.setState(
      {
        filters: !!filters ? filters : this.state.filters,
        users: [],
        cursor: 0,
        isUserListLoading: true,
      },
      () => this.emitGetUsers().then(() => this.setState({ isUserListLoading: false })),
    );
  }

  // Wait 500ms between each character typing, to prevent one request by character changed
  private handleSearchChange(value: any) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(
      () => {
        this.filterUsers(FilterMethod.SEARCH, undefined, value);
      },
      500,
    );
  }

  public render() {
    if (this.state.roles) {
      return (
        <div id="users-management">
          <div id="main-title">
            <FormattedMessage id="usersManagement" defaultMessage="Users Management" />
          </div>
          <Segment id="users-container">
            <div id="title-container">
              <div className="title">
                <FormattedMessage id="users" defaultMessage="Users" />&nbsp;
                <span>
                  ({this.state.nbUsers})
                </span>
              </div>
              <div id="title-container-right">
                <FormattedMessage id="displayOnlyConflict" defaultMessage="Display only conflicting accounts">
                  {msg => <Checkbox
                    label={msg}
                    checked={this.state.filters.conflicting}
                    onChange={(e, { checked }) =>
                      this.filterUsers(FilterMethod.CONFLICT, undefined, undefined, checked)}
                  />}
                </FormattedMessage>
                <FormattedMessage id="filter" defaultMessage="Filter">
                  {msg =>
                    <Filter
                      dropdownName={msg.toString()}
                      arrayToFilter={this.state.users}
                      filters={[FilterCategory.ROLE]}
                      selectedFilter={this.state.filters.role}
                      setFilteredArray={this.filterUsersByRole}
                    />
                  }
                </FormattedMessage>
                <Input
                  placeholder="Search"
                  icon="search"
                  onChange={(e, { value }) => this.handleSearchChange(value)}
                />

                <div id="divider" />

                {this.state.isUserCreationModalOpened
                  && <UserCreation
                    roles={this.state.roles}
                    passwordReset={this.passwordReset}
                    onModalClose={() => this.setState({ isUserCreationModalOpened: false })}
                    reloadUserList={this.reloadUserList}
                  />
                }
                <Button
                  id="add"
                  icon={true}
                  onClick={() => this.setState({ isUserCreationModalOpened: true })}
                >
                  <Icon name="add circle" />&nbsp;
                  <FormattedMessage id="newUser" defaultMessage="New User" />
                </Button>
              </div>
            </div>
            <div className="scrollable-container">
              <CustomScrollBars onScrollFrame={this.loadMore} customRef={this.scrollbarsRef}>
                {this.state.isUserListLoading
                  ? Utils.loader()
                  : this.state.users.length > 0
                    ? <Table sortable={true}>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell
                            className="name-column"
                            sorted={this.state.filters.sortColumn === Column.NAME
                              ? this.state.filters.sortDirection : undefined}
                            onClick={() => this.handleSort(Column.NAME)}
                          >
                            <FormattedMessage id="name" defaultMessage="Name" />
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            className="tgi-column"
                            sorted={this.state.filters.sortColumn === Column.TGI
                              ? this.state.filters.sortDirection : undefined}
                            onClick={() => this.handleSort(Column.TGI)}
                          >
                            <FormattedMessage id="tgi" defaultMessage="TGI" />
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            className="role-column"
                            sorted={this.state.filters.sortColumn === Column.ROLE
                              ? this.state.filters.sortDirection : undefined}
                            onClick={() => this.handleSort(Column.ROLE)}
                          >
                            <FormattedMessage id="rights" defaultMessage="Rights" />
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            className="core-team-column"
                            sorted={this.state.filters.sortColumn === Column.CORE_TEAM
                              ? this.state.filters.sortDirection : undefined}
                            onClick={() => this.handleSort(Column.CORE_TEAM)}
                          >
                            <FormattedMessage id="isCoreTeam" defaultMessage="Core Team" />
                          </Table.HeaderCell>
                          <TableHeaderCell
                            className="email-column"
                            sorted={this.state.filters.sortColumn === Column.EMAIL
                              ? this.state.filters.sortDirection : undefined}
                            onClick={() => this.handleSort(Column.EMAIL)}
                          >
                            <FormattedMessage id="email" defaultMessage="Mail" />
                          </TableHeaderCell>
                          <Table.HeaderCell
                            className="last-connection-column"
                            sorted={this.state.filters.sortColumn === Column.LAST_CONNECTION
                              ? this.state.filters.sortDirection : undefined}
                            onClick={() => this.handleSort(Column.LAST_CONNECTION)}
                          >
                            <FormattedMessage id="lastConnection" defaultMessage="Last connection" />
                          </Table.HeaderCell>
                          <Table.HeaderCell className="actions-column"/>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.state.users.map((user, index: number) =>
                          <UserRow
                            key={`user${user.id}`}
                            user={user}
                            index={index}
                            nbUsers={this.state.users.length}
                            roles={this.state.roles}
                            passwordReset={this.passwordReset}
                            reloadUserList={this.reloadUserList}
                          />,
                        )}
                      </Table.Body>
                    </Table>
                    : Utils.empty(
                      <FormattedMessage
                        id="noUsersFoundForThisFilter"
                        defaultMessage="No user was found for this filter"
                      />,
                    )
                }
              </CustomScrollBars>
            </div>
          </Segment>

          <Modal
            open={this.state.newPassword.opened}
            closeOnEscape={true}
            closeOnDimmerClick={false}
          >
            <Modal.Header>
              <FormattedMessage id="resetPasswordTitle" defaultMessage="This user's password has been set" />
            </Modal.Header>
            <Modal.Content>
              <div id="newPasswordMessage">
                <FormattedMessage id="resetPasswordMessage" defaultMessage="This user's password is: " />
                <div id="newPassword">
                  <span>{this.state.newPassword.password}</span>
                  <Icon name="copy outline" onClick={this.copyToClipboard} />
                </div>
                <div id="copyLabel" className={this.state.newPasswordCopied ? 'visible' : ''}>
                  <FormattedMessage id="passwordCopied" defaultMessage="Password copied!" />
                </div>
              </div>
              <div>
                <FormattedMessage
                  id="resetPasswordNotice"
                  defaultMessage={'You can copy this password and send it by mail to the concerned user.' +
                  ' Please note that you won\'t be able to see this password again.'}
                />
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={() => this.setState({ newPassword: { opened: false, password: '' } })}
                icon="checkmark"
                positive={true}
              />
            </Modal.Actions>
          </Modal>
        </div>
      );
    }

    return Utils.loader();
  }
}

export default injectIntl(Users);
