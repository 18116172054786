import BusinessChallengeReporting from './businessChallengeReporting';
import PerformancePlan from './performancePlan';
import User from './user';

export default class ReportingGet {

  public id: number;
  public name: string;
  public dueDate: string;
  public closingDate: string;
  public performancePlan: PerformancePlan;
  public bcReportings: BusinessChallengeReporting[];
  public issuerId: number;
  public ppSnapshot: PpSnapshot;
}

export class ReportingGetFormatted {

  public id: number;
  public name: string;
  public dueDate: Date;
  public closingDate: Date;
  public performancePlan: PerformancePlan;
  public bcReportings: BusinessChallengeReporting[];
  public issuerId: number;
  public ppSnapshot: PpSnapshot;

  constructor(body: any) {
    this.dueDate = new Date(body.dueDate);
    if (body.closingDate) this.closingDate = new Date(body.closingDate);
    this.id = body.id;
    this.name = body.name;
    this.performancePlan = body.performancePlan;
    this.bcReportings = body.bcReportings;
    this.issuerId = body.issuerId;
    this.ppSnapshot = body.ppSnapshot;
  }
}

export class ReportingPut {

  public id: number;
  public name: string;
  public dueDate: Date;
}

export class ReportingPost {

  public name: string;
  public dueDate: Date;
}

export class PpSnapshot {

  id: number;
  name: string;
  year: string;
  owner: User;
  assignedAccounts: User[];
}
