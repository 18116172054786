import User from './user';
import Attachment from './attachment';
import { RightsOnActivityDTO, RightsOnAT } from './rights';
import PerformanceInitiative from './performanceInitiative';
import ModeTypes from '../constants/modeTypes';

export default class Action {

  public id: number;
  public name: string;
  public code: number;
  public status: number;
  public targetDate: Date | null;
  public completionDate: Date | null;
  public startingDate: Date | null;
  public forecastDate: Date | null;
  public objectives: string;
  public progress: string;
  public isHidden: boolean;
  public updatedAt: Date;
  public assignedAccounts: User[];
  public attachments: Attachment[];
  public rights: RightsOnAT;
  public isClosed: boolean;
  public performanceInitiative?: PerformanceInitiative;
  public isFavourite: boolean;
  public piMilestoneId: number;
  public piMilestoneCode: number;

  constructor(action: ActionGet) {
    this.id = action.id;
    this.name = action.name;
    this.code = action.code;
    this.status = action.status;
    this.targetDate = action.targetDate;
    this.completionDate = action.completionDate;
    this.startingDate = action.startingDate;
    this.forecastDate = action.forecastDate;
    this.objectives = action.objectives;
    this.progress = action.progress;
    if ('isHidden' in action) {
      this.isHidden = action.isHidden;
    }
    this.updatedAt = action.updatedAt;
    this.assignedAccounts = action.assignedAccounts;
    this.attachments = action.attachments;
    this.rights = new RightsOnAT(action.rightsOnAt);
    if ('isClosed' in action) {
      this.isClosed = action.isClosed;
    }
    if ('isFavourite' in action) {
      this.isFavourite = action.isFavourite;
    }
    this.piMilestoneId = action.piMilestoneId;
    this.piMilestoneCode = action.piMilestoneCode;
  }
}

export class ActionGet {

  public id: number;
  public name: string;
  public code: number;
  public status: number;
  public targetDate: Date | null;
  public completionDate: Date | null;
  public startingDate: Date | null;
  public forecastDate: Date | null;
  public objectives: string;
  public progress: string;
  public isHidden: boolean;
  public updatedAt: Date;
  public assignedAccounts: User[];
  public attachments: Attachment[];
  public rightsOnAt: RightsOnActivityDTO;
  public isClosed: boolean;
  public isFavourite: boolean;
  public piMilestoneId: number;
  public piMilestoneCode: number;
}

export class ActionEdit {

  public id: number | null;
  public name: string;
  public code: number;
  public status: number;
  public targetDate: Date;
  public completionDate: Date;
  public startingDate: Date;
  public forecastDate: Date;
  public objectives: string;
  public progress: string;
  public isHidden: boolean;
  public updatedAt: Date;
  public assignedAccounts: User[];
  public attachments: Attachment[];
  public rights: RightsOnAT;
  public isClosed: boolean;
  public performanceInitiative?: PerformanceInitiative;
  public isFavourite: boolean;
  public mode: ModeTypes;
  public isStatusEditing: boolean;
  public piMilestoneId: number;
  public piMilestoneCode: number;
}

export class ActionPost {

  public name: string;
  public targetDate: Date;
  public objectives: string;
  public progress: string;
  public assignedAccounts: User[];
  public attachments: Attachment[];
  public isClosed: boolean;
  public status: number;
}

export class KeyAction {
  public id: number;
  public piCode: number;
  public piName: string;
  public name: string;
  public code: number;
  public isHidden: boolean;
  public targetDate: Date;
  public completionDate: Date;
}
