import User, { AssignationEmailDTO, UserPost } from 'src/models/user';

import Role from '../models/role';
import SelectedYears from '../models/selectedYears';
import HttpClient from '../utils/httpClient';
import { IConflictResponse, IMergeConflict } from '../constants/accountConflict';
import { UserListFilters } from '../components/administrationBoard/containerAdministration/users/users';
import { GuideIds } from '../constants/guideConstants';

export interface GetUsersResult {
  count: number;
  users: User[];
}

export default class UserAPI {

  /**
   * ROUTE : /api/users
   * Get all users
   * @returns Promise<User[]>
   */
  public static getUsers(filters: UserListFilters): Promise<GetUsersResult> {
    const params: string = Object.keys(filters).map(key => `${key}=${filters[key]}`).join('&');
    return HttpClient.get<GetUsersResult>(`admin/users?${params}`);
  }

  /**
   * ROUTE : /api/users/limited
   * Get all users limited
   * @returns Promise<User[]>
   */
  public static getUsersLimited(params: { search: string, isPpReferentSelection: boolean }): Promise<User[]> {
    return HttpClient.get<User[]>(`users/basic${HttpClient.formatUrlQueryParams(params)}`);
  }

  /**
   * ROUTE : /api/admin/users/{userId}/reset-password
   * Reset a user password
   * @param userId : User Id
   * @returns Promise<number>
   */
  public static resetPassword(userId: number): Promise<{ password: string }> {
    return HttpClient.get<{ password: string }>(`admin/users/${userId}/reset-password`);
  }

  /**
   * ROUTE : /api/users/roles
   * Get user's roles
   * @returns Promise<Role[]>
   */
  public static getRoles(): Promise<Role[]> {
    return HttpClient.get<Role[]>('users/roles');
  }

  /**
   * ROUTE : /api/completed-guides
   * Get user's completed guides
   * @returns Promise<GuideIds[]>
   */
  public static getCompletedGuides(): Promise<GuideIds[]> {
    return HttpClient.get<GuideIds[]>('users/me/completed-guides');
  }

  /**
   * ROUTE : /api/completed-guides
   * Post user's completed guide
   * @param {GuideIds} guideId
   * @returns Promise<GuideIds[]>
   */
  public static postCompletedGuide(guideId: GuideIds): Promise<GuideIds[]> {
    return HttpClient.post<GuideIds[]>('users/me/completed-guides', { guideId });
  }

  /**
   * ROUTE : /api/users/{userId}/avatar
   * Get user avatar by id
   * @returns Promise<User[]>
   */
  public static getAvatar(userId: number): Promise<Blob> {
    return HttpClient.getImage(`users/${userId}/avatar`);
  }

  /**
   * ROUTE : /api/users/{userId}
   * Edit a User
   * @param userId : number
   * @param user : User
   * @returns Promise<User>
   */
  public static putUserById(userId: number, user: User): Promise<User> {
    return HttpClient.put<User>(`users/${userId}`, user);
  }

  /**
   * ROUTE : /api/users/me
   * Change current User's Profile
   * @param user : UserPost
   * @returns Promise<User>
   */
  public static putUserProfile(user: UserPost): Promise<User> {
    return HttpClient.put<User>('users/me', user);
  }

  /**
   * ROUTE : /api/users
   * Create a User
   * @param user : User
   * @returns Promise<User>
   */
  public static postUser(user: UserPost): Promise<{ password: string }> {
    return HttpClient.post<{ password: string }>('users', user);
  }

  /**
   * ROUTE : /api/users/send-invite-mail
   * Send invite mail
   * @param inviteMail: InviteMailDTO
   * @returns Promise<User>
   */
  public static sendInviteMail(inviteMail: AssignationEmailDTO): Promise<void> {
    return HttpClient.post<void>('users/send-invite-mail', inviteMail);
  }

  /**
   * ROUTE : /api/admin/users/{userId}/reset-password
   * Check if current user is admin
   * @returns Promise<number>
   */
  public static isAdmin(): Promise<any> {
    return HttpClient.get<any>('admin/users/is-admin');
  }

  /**
   * ROUTE : /api/users/:userId/conflict
   * Get account conflicts (TGI or name)
   * @param userId : number
   * @returns Promise<IConflictResponse>
   */
  public static getAccountConflict(userId: number): Promise<IConflictResponse> {
    return HttpClient.get<IConflictResponse>(`admin/users/${userId}/conflict`);
  }

  /**
   * ROUTE : /api/admin/users/merge-conflict
   * Merge conflicting user accounts
   * @param ids : IMergeConflict
   * @returns Promise<any>
   */
  public static mergeConflict(ids: IMergeConflict): Promise<any> {
    return HttpClient.post<any>('admin/users/merge-conflict', ids);
  }

  /**
   * ROUTE : /api/admin/users/:userId/unset-conflict
   * Unset conflicting state on an account
   * @param userId : number
   * @returns Promise<any>
   */
  public static unsetConflict(userId: number): Promise<any> {
    return HttpClient.patch<any>(`admin/users/${userId}/unset-conflict`);
  }

  /**
   * ROUTE : /api/admin/users/:userId/delete-account
   * Remove account from database
   * @param userId : number
   * @returns Promise<any>
   */
  public static deleteUser(userId: number): Promise<any> {
    return HttpClient.delete<any>(`admin/users/${userId}/remove-account`, {});
  }

  public static putUserDefaultPpId(defaultPpId: number | null): Promise<number> {
    return HttpClient.put<number>('users/me/defaultPpId', { defaultPpId });
  }

  public static getUserDefaultPpId(): Promise<User> {
    return HttpClient.get<User>('users/me/defaultPpId');
  }

  /**
   * ROUTE : /api/users/:userId/selectedYears
   * Remove account from database
   * @param userId : number
   * @returns Promise<any>
   */
  public static putSelectedYears(userId: number, selectedYears: string): Promise<string> {
    return HttpClient.put<string>(`users/${userId}/selectedYears`, { selectedYears });
  }

  public static getSelectedYears(userId: number): Promise<SelectedYears> {
    return HttpClient.get<SelectedYears>(`users/${userId}/selectedYears`);
  }

  /**
   * ROUTE : /api/users/:userId/stop-modal
   * Stop the modal from appearing at connexion
   * @param userId
   */
  public static stopVersionModal(userId: number) {
    return HttpClient.put(`users/${userId}/stop-modal`, {});
  }
}
