import BusinessChallenge, { BcLinked } from './businessChallenge';
import BusinessLine from './businessLine';
import Country from './country';
import Gbu from './gbu';
import Geography from './geography';
import Segment from './segment';
import SubCountry from './subcountry';
import User from './user';
import Attachment from './attachment';
import { RightsOnActivityDTO } from './rights';

export default class PerformancePlan {

  public id: number;
  public assignedAccounts: User[];
  public businessChallenges: BusinessChallenge[];
  public linkedBcs?: BusinessChallenge[];
  public businessLine: BusinessLine;
  public country: Country;
  public countryId: number;
  public gbu: Gbu;
  public gbuId: number;
  public geography: Geography;
  public name: string;
  public attachments: Attachment[];
  public owner: User;
  public ownerId: number;
  public segment: Segment;
  public subcountry: SubCountry;
  public lastUpdate: Date;
  public publishedDate: Date;
  public year: string;
  public isDraft: boolean;
  public hasActions: boolean;
  public informedAccounts: User[];
  public isAssignee: boolean;

  constructor(pp: PerformancePlanGet) {
    this.id = pp.id;
    this.assignedAccounts = pp.assignedAccounts;
    this.businessChallenges = pp.businessChallenges;
    this.businessLine = pp.businessLine;
    this.country = pp.country;
    this.countryId = pp.countryId;
    this.gbu = pp.gbu;
    this.gbuId = pp.gbuId;
    this.geography = pp.geography;
    this.name = pp.name;
    this.attachments = pp.attachments;
    this.owner = pp.owner;
    this.ownerId = pp.ownerId;
    this.segment = pp.segment;
    this.subcountry = pp.subcountry;
    this.lastUpdate = pp.lastUpdate;
    this.publishedDate = pp.publishedDate;
    this.year = pp.year;
    this.isDraft = pp.isDraft;
    this.hasActions = pp.hasActions;
    this.informedAccounts = pp.informedAccounts;
    this.linkedBcs =  pp.linkedBcs ? pp.linkedBcs : undefined;
  }
}

export class PerformancePlanGet {

  public id: number;
  public assignedAccounts: User[];
  public businessChallenges: BusinessChallenge[];
  public linkedBcs?: BusinessChallenge[];
  public businessLine: BusinessLine;
  public country: Country;
  public countryId: number;
  public gbu: Gbu;
  public gbuId: number;
  public geography: Geography;
  public name: string;
  public attachments: Attachment[];
  public owner: User;
  public ownerId: number;
  public segment: Segment;
  public subcountry: SubCountry;
  public lastUpdate: Date;
  public publishedDate: Date;
  public year: string;
  public isDraft: boolean;
  public hasActions: boolean;
  public informedAccounts: User[];
  public rightsOnPP: RightsOnActivityDTO;
}

export class PerformancePlanPut {

  public businessLineId: number;
  public countryId: number;
  public gbuId: number;
  public geographyId: number;
  public name: string;
  public attachments: Attachment[];
  public segmentId: number;
  public subcountryId: number;
  public year: string;
  public owner?: User;
  public ownerId?: number;
  public assignedAccounts?: User[];
  public informedAccounts?: User[];
  public isDraft: boolean;
}

export class PerformancePlanGetForBcImport {
  public id: number;
  public name: string;
  public year: string;
  public isSimilarPp: boolean;
  public businessChallenges: BusinessChallenge[];
}

export class PpLinked {
  public id: number;
  public name: string;
  public year: string;
}

export class PpGetLinkedActivities {
  public id: number;
  public bcs: BcLinked[];
}
