export class RightsOnActivityDTO {
  view: IView;
  export: IExport;
  edit: IEdit;
  delete: IDelete;
  publish: IPublish;
}

interface IView {
  canView: boolean;
  tabs: { [k: string]: boolean };
}

interface IExport {
  [k: string]: boolean;
}

interface IEdit {
  canEdit: boolean;
}

interface IDelete {
  canDelete: boolean;
}

interface IPublish {
  canPublish: boolean;
}

abstract class AbstractRightsOn {
  protected view: IView = {
    canView: false, // can view basic info or nothing at all
    tabs: {},
  };
  protected export: IExport = {};
  protected edit: IEdit = {
    canEdit: false,
  };
  protected delete: IDelete = {
    canDelete: false,
  };
  protected publish: IPublish = {
    canPublish: false,
  };

  // GETTERS
  public canView(): boolean { return this.view.canView; }
  public canViewPpCockpit(): boolean { return this.view.tabs.viewPpCockpit; }
  public canEdit(): boolean { return this.edit.canEdit; }
  public canDelete(): boolean { return this.delete.canDelete; }
  public canPublish(): boolean { return this.publish.canPublish; }
  public canExportCockpit(): boolean { return this.export.cockpit; }
  public canExportWhole(): boolean { return this.export.whole; }
}

export class RightsOnPP extends AbstractRightsOn {

  public constructor(rightsOnPp?: RightsOnActivityDTO) {
    super();

    if (!!rightsOnPp) {
      this.view = rightsOnPp.view;
      this.export = rightsOnPp.export;
      this.edit = rightsOnPp.edit;
      this.delete = rightsOnPp.delete;
      this.publish = rightsOnPp.publish;
    } else {
      this.view.tabs = {
        cockpit: false,
        reporting: false,
      };
      this.export.cockpit = false;
      this.export.reporting = false;
      this.publish.canPublish = false;
    }
  }

  // GETTERS
  public canViewCockpit(): boolean {
    return super.canView() && this.view.tabs!.cockpit;
  }

  public canViewReporting(): boolean {
    return super.canView() && this.view.tabs!.reporting;
  }

  public canExportReporting(): boolean {
    return this.export.reporting;
  }
}

export class RightsOnBC extends AbstractRightsOn {

  public constructor(rightsOnBc?: RightsOnActivityDTO) {
    super();

    if (!!rightsOnBc) {
      this.view = rightsOnBc.view;
      this.export = rightsOnBc.export;
      this.edit = rightsOnBc.edit;
      this.delete = rightsOnBc.delete;
      this.publish = rightsOnBc.publish;
    } else {
      this.view.tabs = {
        cockpit: false,
      };
      this.export.cockpit = false;
      this.publish.canPublish = false;
    }
  }

  // GETTERS
  public canViewCockpit(): boolean {
    return super.canView() && this.view.tabs!.cockpit;
  }
}

export class RightsOnPI extends AbstractRightsOn {

  public constructor(rightsOnPi?: RightsOnActivityDTO) {
    super();

    if (!!rightsOnPi) {
      this.view = rightsOnPi.view;
      this.export = rightsOnPi.export;
      this.edit = rightsOnPi.edit;
      this.delete = rightsOnPi.delete;
      this.publish = rightsOnPi.publish;
    } else {
      this.export.cockpit = false;
      this.publish.canPublish = false;
    }
  }
}

export class RightsOnAT extends AbstractRightsOn {

  public constructor(rightsOnAt?: RightsOnActivityDTO) {
    super();

    if (!!rightsOnAt) {
      this.view = rightsOnAt.view;
      this.edit = rightsOnAt.edit;
      this.delete = rightsOnAt.delete;
    } else {
      delete this.export;
      delete this.publish;
    }
  }
}
