import ModeTypes from 'src/constants/modeTypes';

export interface SegmentsMode {
  [key: string]: ModeTypes;
}

export enum SegmentType {
  PP_PUBLICATIONS = 'ppPublications',
  PP_ABOUT = 'aboutPP',
  PP_TEAM = 'ppTeam',
  PP_ATTACHMENTS = 'PPAttachments',

  BC_DETAILS = 'bcDetails',
  BC_PUBLICATIONS = 'bcPublications',
  BC_STATUS = 'bcStatus',
  BC_MAIN_ACHIEVEMENTS = 'mainAchievements',
  BC_MAIN_ISSUES = 'mainIssuesAndRisks',
  BC_DECISIONS_TO_BE_MADE = 'decisionsToBeMade',
  BC_NEXT_STEPS = 'nextSteps',
  BC_METRICS = 'bcMetrics',
  BC_METRICS_DELETION = 'bcMetricsDeletion',
  BC_MILESTONES = 'bcMilestones',
  BC_MILESTONES_DELETION = 'bcMilestonesDeletion',
  BC_ABOUT = 'aboutBC',
  BC_TEAM = 'bcTeam',
  BC_ATTACHMENTS = 'bcAttachments',
  BC_CREATED = 'bcCreated',
  BC_DELETED = 'bcDeleted',

  PI_DETAILS = 'piDetails',
  PI_PUBLICATIONS = 'piPublications',
  PI_STATUS = 'piStatus',
  PI_MAIN_ACHIEVEMENTS = 'mainAchievements',
  PI_MAIN_ISSUES = 'mainIssuesAndRisks',
  PI_DECISIONS_TO_BE_MADE = 'decisionsToBeMade',
  PI_NEXT_STEPS = 'nextSteps',
  PI_METRICS = 'piMetrics',
  PI_METRICS_DELETION = 'piMetricsDeletion',
  PI_MILESTONES = 'piMilestones',
  PI_MILESTONES_DELETION = 'piMilestonesDeletion',
  PI_ABOUT = 'aboutPI',
  PI_TEAM = 'piTeam',
  PI_ATTACHMENTS = 'piAttachments',
  PI_CREATED = 'piCreated',
  PI_DELETED = 'piDeleted',
  PI_MI_ACTIONS = 'milestoneAt',

  AT_ACTION = 'actions',
  AT_ACTION_DELETION = 'actionsDeletion',
  AT_ATTACHMENTS = 'atAttachments',
  AT_MILESTONES= 'atMilestones',
}
