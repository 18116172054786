import HttpClient from '../utils/httpClient';
import UserStore from '../stores/user.store';
import AuthStore from '../stores/auth.store';

export default class ProfileService {

  /**
   * Update the password of the current user
   * @return {User} the connected user
   */
  public static updatePassword(password: string) {
    return HttpClient.put('users/me', { password });
  }

  /**
   * Get the connected user's profile picture
   */
  public static getProfilePic() {
    return HttpClient.getImage('users/me/avatar')
      .then((imgBlob: any) => {
        UserStore.setUserAvatar(AuthStore.getConnectedUser().id, URL.createObjectURL(imgBlob));
        return imgBlob;
      });
  }

  /**
   * Update the connected user's profile picture
   */
  public static updateProfilePic(formData: FormData) {
    return HttpClient.setImage('users/me/avatar', formData);
  }
}
