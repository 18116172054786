import { CustomOptions } from 'src/components/activitiesBoard/containerActivities/titleContainer/exportCenter/interfaces/ExportStepsOptions';
import HttpClient from 'src/utils/httpClient';

export default class ExportPptxAPI {

  public static exportCockpitBc(bcId: number, customOptions: CustomOptions): Promise<string> {
    const includeMiAt : boolean = customOptions.includeMiAt;
    const includeMetrics : boolean = customOptions.includeMetrics;
    return HttpClient.getBase64(`export/${bcId}/cockpit-bc-pptx${HttpClient.formatUrlQueryParams({
      includeMiAt,
      includeMetrics,
    })}`);
  }

  public static exportCockpitPi(piId: number, customOptions: CustomOptions): Promise<string> {
    const includeMiAt : boolean = customOptions.includeMiAt;
    const includeMetrics : boolean = customOptions.includeMetrics;
    return HttpClient.getBase64(`export/${piId}/cockpit-pi-pptx${HttpClient.formatUrlQueryParams({
      includeMiAt,
      includeMetrics,
    })}`);
  }

  public static exportCockpitPp(ppId: number): Promise<string> {
    return HttpClient.getBase64(`export/${ppId}/cockpit-pp-pptx`);
  }

  public static exportFullPp(ppId: number, customOptions: CustomOptions): Promise<string> {
    const includeMiAt : boolean = customOptions.includeMiAt;
    const includeMetrics : boolean = customOptions.includeMetrics;
    return HttpClient.getBase64(`export/${ppId}/full-pp-pptx${HttpClient.formatUrlQueryParams({
      includeMiAt,
      includeMetrics,
    })}`);
  }

}
