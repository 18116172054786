enum TypeElementTimeline {
  REPORTING, // PP OR BC
  REPORTING_BEFORE, // PI
  MILESTONE,
  ACTION,
}
export default TypeElementTimeline;

export enum TimelineView {
  YEAR,
  QUARTER,
  MONTH,
}

export enum Months {
  JANUARY = 0,
  FEBRUARY = 1,
  MARCH = 2,
  APRIL= 3,
  MAY = 4,
  JUNE = 5,
  JULY = 6,
  AUGUST = 7,
  SEPTEMBER = 8,
  OCTOBER = 9,
  NOVEMBER = 10,
  DECEMBER = 11,
}

export enum Quarter {
  Q1, Q2, Q3, Q4,
}
