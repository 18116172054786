import './App.scss';

import * as React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';

import ChangePassword from './components/changePassword/changePassword';
import PrivateRoute from './components/privateRoute/privateRoute';
import AuthStore from './stores/auth.store';
import LoggingAPI from './api/logging.api';

interface Node {
  id: string;
  className: string;
  tagName: string;
  parentNode?: Node;
}

class App extends React.Component<RouteComponentProps> {
  private isSendingError = false;

  private getSourceNode(node: Node): Node {
    const newNode = {
      id: node.id,
      className: node.className,
      tagName: node.tagName.toLowerCase(),
    };

    if (!!node.parentNode && node.parentNode.id !== '' && node.id === '') {
      return {
        ...newNode,
        parentNode: this.getSourceNode(node.parentNode),
      };
    }

    return newNode;
  }

  public componentDidMount() {
    window.addEventListener('error', (event: ErrorEvent) => {

      if (!this.isSendingError) {
        this.isSendingError = true;
        const { message, error } = event;
        const explicitOriginalTarget = event['explicitOriginalTarget'];

        LoggingAPI.sendErrorToLoggingService({
          message,
          error,
          type: 'error',
          scope: 'client',
          time: new Date(),
          context: {
            sourceNode: explicitOriginalTarget ? this.getSourceNode(explicitOriginalTarget) : undefined,
            location: window.location.href,
            userId: AuthStore.getConnectedUser().id,
            platform: window.navigator.platform,
            userAgent: window.navigator.userAgent,
            language: window.navigator.language,
            connectivity: window.navigator.onLine,
          },
        }).then(() => {
          this.props.history.push('/error');
          this.isSendingError = false;
        });
      }
    });
  }

  public render() {
    if (!!AuthStore.getConnectedUser() && AuthStore.getConnectedUser().isFirstConnection) {
      return <ChangePassword />;
    }

    return (
      <Switch>
        <Route path={'/'} component={PrivateRoute} />
      </Switch>
    );
  }
}

export default withRouter(App);
