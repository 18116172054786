import Utils from '../../../../../../../../utils/utils';
import { Icon, Popup, Table } from 'semantic-ui-react';
import * as React from 'react';
import { AtTimelineElement, MiTimelineElement, TimelineElement } from '../../../../../../../../constants/piTimeline';
import RowPopupContent from './rowPopupContent/rowPopupContent';
import { Months, Quarter, TimelineView } from '../../../../../../../../constants/timeline';
import ContentRowElements from './contentRowElements/contentRowElements';
import './contentRowWrapper.scss';
import TypeActivity from '../../../../../../../../constants/typeActivity';

interface IProps {
  selectedView: TimelineView;
  year: number;
  quarter: Quarter;
  month: Months;
  isChild: boolean;
  timelineElement: TimelineElement;
  setOverviewData(activityId: number, type: TypeActivity.MILESTONE | TypeActivity.ACTION): void;
  colorElement?: string;
}

// tslint:disable-next-line:variable-name
const ContentRowWrapper = (props: IProps) => {
  const mi = props.timelineElement as MiTimelineElement;
  const at = props.timelineElement as AtTimelineElement;
  const isAt = Utils.isActivityAt(props.timelineElement.type);
  const activityName = Utils.formatActivityName(props.timelineElement.code, props.timelineElement.name,
                                                props.timelineElement.type,
                                                (props.timelineElement as AtTimelineElement).piMilestoneCode);

  const onPiTimelineRowClick = () => {
    props.setOverviewData(props.timelineElement.id, isAt ? TypeActivity.ACTION : TypeActivity.MILESTONE);
  };

  return (
    <React.Fragment key={Utils.generateRandomKey()}>
      <Popup
        basic={true}
        id="pi-timeline-popup-content"
        content={<RowPopupContent timelineElement={props.timelineElement} />}
        size="huge"
        position="top center"
        trigger={
          <Table.Row
            className={isAt ? 'at' : 'mi'}
            key={Utils.generateRandomKey()}
            onClick={onPiTimelineRowClick}
          >
            <Table.Cell width={1} className={`activity-name-cell ${props.colorElement ? props.colorElement : ''}`}>
              <div className={`activity-name ${isAt && at.isClosed ? 'closed' : ''}`}>
                {props.isChild && <div><Icon name="angle right"/></div>}
                <div className="name">{activityName}</div>
                {isAt && Utils.getFavouriteIcon(at)}
                {isAt && Utils.getClosedTag(at)}
              </div>
            </Table.Cell>
            {<ContentRowElements
              timelineElement={props.timelineElement}
              selectedView={props.selectedView}
              year={props.year}
              quarter={props.quarter}
              month={props.month}
              isAt={isAt}
            />}
          </Table.Row>}
      />
      {mi.associatedActions && mi.associatedActions.map(at => (
        <ContentRowWrapper
          key={Utils.generateRandomKey()}
          selectedView={props.selectedView}
          year={props.year}
          quarter={props.quarter}
          month={props.month}
          timelineElement={at}
          isChild={true}
          setOverviewData={props.setOverviewData}
        />
      ))}
    </React.Fragment>
  );
};
export default ContentRowWrapper;
