import React from 'react';
import './timelineViewButtons.scss';
import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { TimelineView } from '../../../../../../../../constants/timeline';
import TimelineUtils from '../../../../../../../../utils/timelineUtils';

interface IProps {
  selectedView: TimelineView;
  setTimelineView: (view: TimelineView) => void;
}

const timelineViewButtons = (props: IProps) => {
  const monthBtnStyle = TimelineUtils.isOnMonthView(props.selectedView) ? 'selected' : 'unselected';
  const quarterBtnStyle = TimelineUtils.isOnQuarterView(props.selectedView) ? 'selected' : 'unselected';
  const yearBtnStyle = TimelineUtils.isOnYearView(props.selectedView) ? 'selected' : 'unselected';

  const handleChangeTimelineView = (view: TimelineView) => {
    if (props.selectedView !== view) {
      props.setTimelineView(view);
    }
  };

  return (
    <div id="timeSelect">
      <Button
        className={monthBtnStyle}
        onClick={() => handleChangeTimelineView(TimelineView.MONTH)}
      >
        <FormattedMessage id="month" defaultMessage="Month"/>
      </Button>
      <Button
        className={quarterBtnStyle}
        onClick={() => handleChangeTimelineView(TimelineView.QUARTER)}
      >
        <FormattedMessage id="quarter" defaultMessage="Quarter"/>
      </Button>
      <Button
        className={yearBtnStyle}
        onClick={() => handleChangeTimelineView(TimelineView.YEAR)}
      >
        <FormattedMessage id="year" defaultMessage="YEAR"/>
      </Button>
    </div>
  );
};
export default timelineViewButtons;
