import './segmentEditButtons.scss';

import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, Popup } from 'semantic-ui-react';

import ModeTypes from '../../../../../constants/modeTypes';
import Utils from '../../../../../utils/utils';
import SegmentsActions from 'src/actions/segments.action';
import SegmentsStore from 'src/stores/segments.store';
import ActionTypes from '../../../../../constants/actionTypes';
import { SegmentType } from '../../../../../models/segmentsMode';

interface IProps extends RouteComponentProps {
  segmentType: SegmentType | string;
  mode: ModeTypes;
  deletable?: boolean;
  isSaveDisabled?: boolean;
  popupPosition?: 'left center' | 'top left' | 'top right' | 'bottom right' | 'bottom left' | 'right center' | 'top center' | 'bottom center' | undefined;
  hideButtons?: boolean;
  alwaysShow?: boolean;
  publicationHeight?: boolean;
  alternateStyling?: boolean;

  changeMode(mode: ModeTypes): void;
  onSave(): Promise<void>;
  onCancel(): void;
  onEdit?(): void;
  onDelete?(): void;
}

export enum ActionType {
  EDIT,
  SAVE,
  CANCEL,
  DELETE,
}

interface IState {
  hasSegmentOnEdit: boolean;
}

class SegmentEditButtons extends React.Component<IProps, IState> {
  private isMount = false;

  constructor(props) {
    super(props);
    this.state = {
      hasSegmentOnEdit: SegmentsStore.hasSegmentsOnEdit(),
    };
  }

  componentDidMount() {
    this.isMount = true;
    SegmentsStore.addListener(ActionTypes.SET_SEGMENT_MODE.toString(), this.onSegmentModeChange);
    SegmentsStore.addListener(ActionTypes.RESET_SEGMENTS_MODES.toString(), this.onSegmentModeChange);
  }

  componentWillUnmount() {
    this.isMount = false;
    SegmentsStore.removeListener(ActionTypes.SET_SEGMENT_MODE.toString(), this.onSegmentModeChange);
    SegmentsStore.removeListener(ActionTypes.RESET_SEGMENTS_MODES.toString(), this.onSegmentModeChange);
  }

  private onSegmentModeChange = () => {
    if (this.isMount) {
      const mode = SegmentsStore.getSegmentModeById(this.props.segmentType);
      mode !== this.props.mode && this.props.changeMode(mode);

      if (Utils.isOnCancelMode(this.props.mode) && Utils.isOnViewMode(mode)) {
        this.props.onCancel();
      }

      if (this.state.hasSegmentOnEdit !== SegmentsStore.hasSegmentsOnEdit()) {
        this.setState({ hasSegmentOnEdit: SegmentsStore.hasSegmentsOnEdit() });
      }
    }
  }

  private setMode = (mode: ModeTypes) => {
    setTimeout(
      () => {
        this.props.changeMode(mode);
        SegmentsActions.emitSegmentMode(this.props.segmentType, mode);
      },
      0,
    );
  }

  private onSave = () => {
    this.props.onSave().then(() => this.setMode(ModeTypes.MODE_VIEW));
  }

  private onEdit = () => {
    if (!this.state.hasSegmentOnEdit) {
      this.setMode(ModeTypes.MODE_EDITION);
      this.props.onEdit && this.props.onEdit();
    }
  }

  private onCancel = () => {
    this.setMode(ModeTypes.MODE_CANCEL);
  }

  private onDelete = () => {
    this.setMode(ModeTypes.MODE_DELETE);
    this.props.onDelete && this.props.onDelete();
  }

  private getPopup = (actionType: ActionType): JSX.Element => {
    let content;
    let iconName;
    let action;

    switch (actionType) {
      case ActionType.EDIT:
        const sectionOnEdition = this.state.hasSegmentOnEdit && !!SegmentsStore.getSegmentOnEdit()
          ? Utils.getEditingFieldSectionLabel(SegmentsStore.getSegmentOnEdit()!)
          : null;
        content = this.state.hasSegmentOnEdit
          ? (
            <div className="one-edition-only">
              <FormattedMessage id="oneEditionAtATime" defaultMessage="You can only edit one field at a time" />
              {sectionOnEdition &&
                <div className="editing-section">
                  <FormattedMessage
                    id="fieldStillBeingEdited"
                    defaultMessage="One field is still being edited in the following section: "
                  />
                  {sectionOnEdition}
                </div>
              }
            </div>

          )
          : <FormattedMessage id="editSegment" defaultMessage="Edit the segment" />;
        iconName = 'pencil';
        action = this.onEdit;
        break;
      case ActionType.SAVE:
        content = <FormattedMessage id="save" defaultMessage="Save" />;
        iconName = 'check';
        action = this.onSave;
        break;
      case ActionType.CANCEL:
        content = <FormattedMessage id="cancelEdition" defaultMessage="Cancel edition" />;
        iconName = 'delete';
        action = this.onCancel;
        break;
      case ActionType.DELETE:
        content = <FormattedMessage id="delete" defaultMessage="Delete" />;
        iconName = 'trash';
        action = this.onDelete;
        break;
    }

    const isBtnDisabled = (actionType === ActionType.SAVE && this.props.isSaveDisabled) ||
      (this.state.hasSegmentOnEdit && Utils.isActionTypeEdit(actionType));

    return (
      <Popup
        content={content}
        trigger={
          <Button
            circular={true}
            icon={true}
            disabled={actionType === ActionType.SAVE && this.props.isSaveDisabled}
            onClick={action}
            className={isBtnDisabled ? 'btn-disabled' : ''}
          >
            <Icon
              name={iconName}
              className={`${this.state.hasSegmentOnEdit && Utils.isActionTypeEdit(actionType) ? 'icon-disabled' : ''}`}
            />
          </Button>
        }
        inverted={!(this.state.hasSegmentOnEdit && Utils.isActionTypeEdit(actionType))}
        size="tiny"
        position={this.props.popupPosition ?? 'left center'}
      />
    );
  }

  public render() {
    return (
      <div
        className={`edit-button
          ${Utils.isOnEditMode(this.props.mode) ? 'editing' : ''}
          ${this.props.alternateStyling ? 'alternate' : ''}
          ${this.props.hideButtons ? 'hidden' : ''}
          ${this.props.alwaysShow ? 'always-show' : ''}
          ${this.props.publicationHeight && Utils.isOnEditMode(this.props.mode) ? 'to-top-right' : ''}
          ${!this.props.deletable ? 'not-deletable' : ''}`}
      >
        {Utils.isOnEditMode(this.props.mode)
          ? <div className="cancel-save-container">
            {this.props.deletable && this.getPopup(ActionType.DELETE)}
            {this.getPopup(ActionType.CANCEL)}
            {this.getPopup(ActionType.SAVE)}
          </div>
          : this.getPopup(ActionType.EDIT)
        }
      </div>
    );
  }
}

export default withRouter(SegmentEditButtons);
