import React from 'react';
import { FormattedMessage } from 'react-intl';
import Utils from '../../../../utils/utils';
import CustomScrollBars from '../../customScrollBars/customScrollBars';
import { Form } from 'semantic-ui-react';
import EditorGenerator from '../../../activitiesBoard/containerActivities/ckEditorGenerator';
import User from '../../../../models/user';
import ModeTypes from '../../../../constants/modeTypes';
import { ActionEdit } from '../../../../models/action';
import { ActionTextField } from '../../../../constants/fields';
import './actionFields.scss';

interface IProps {
  isEditorOnError: boolean[];
  mode: ModeTypes;
  action: ActionEdit;
  onlySpecificField?: ActionTextField;

  setIsEditorOnError(isOnError: boolean[], callback: Function): void;
  handleChange(key: string, value: string | User[] | number | boolean): void;
}

enum AT_FIELD_ERR_POSITION {
  OBJECTIVES = 0,
  PROGRESS = 1,
}

class ActionFields extends React.Component<IProps, any> {

  private handleErrorOnEditor = (idElement: ActionTextField, isFieldOnError: boolean, callback: Function) => {
    const isOnError = [...this.props.isEditorOnError];
    switch (idElement) {
      case ActionTextField.OBJECTIVES:
        isOnError[AT_FIELD_ERR_POSITION.OBJECTIVES] = isFieldOnError;
        break;
      case ActionTextField.PROGRESS:
        isOnError[AT_FIELD_ERR_POSITION.PROGRESS] = isFieldOnError;
        break;
    }
    this.props.setIsEditorOnError(isOnError, callback);
  };

  /**
   * Element displayed, depends on the mode
   * @param {string} data
   * @param {ActionTextField} idElement
   * @return {Element[]} the PI element to display
   */
  public getElement = (data: string, idElement: ActionTextField): JSX.Element => {
    let title;
    let element;
    const errPosition = idElement === ActionTextField.OBJECTIVES
      ? AT_FIELD_ERR_POSITION.OBJECTIVES : AT_FIELD_ERR_POSITION.PROGRESS;
    switch (idElement) {
      case ActionTextField.OBJECTIVES:
        title = <FormattedMessage id="at.objectives" defaultMessage="Objectives"/>;
        break;
      case ActionTextField.PROGRESS:
        title = <FormattedMessage id="at.progress" defaultMessage="Progress Status"/>;
        break;
    }

    const isTextEmpty = !data || (data && data.length === 0);

    if (Utils.isOnViewMode(this.props.mode)) {
      element = (
        <div
          key={idElement}
          className={`formatted-text
          ${isTextEmpty ? 'empty-text' : ''}
          `}
        >
          <CustomScrollBars className="content-element-scrollbar">
            {Utils.replaceActivityMentionToJsx(data, this)}
          </CustomScrollBars>
        </div>
      );
    } else {
      element = (
        <Form key={idElement}>
          <EditorGenerator
            data={data}
            onChangeAction={this.props.handleChange}
            onErrorAction={this.handleErrorOnEditor}
            idElement={idElement}
          />
        </Form>
      );
    }
    return (
      <div className={`info-element ${idElement}`}>
        <div className="grey-title">
          {Utils.getTitleWithErrIcon(title, this.props.isEditorOnError[errPosition])}
        </div>
        <div className="textarea-zone">
          {element}
        </div>
      </div>
    );
  }

  render() {
    if (this.props.onlySpecificField) {
      const field = this.props.onlySpecificField === ActionTextField.OBJECTIVES
        ? this.props.action.objectives : this.props.action.progress;
      return(
        <div className="content-container action-content-container">
          {this.getElement(field, this.props.onlySpecificField)}
        </div>
      );
    }
    return(
      <div className="content-container action-content-container">
        {this.getElement(this.props.action.objectives, ActionTextField.OBJECTIVES)}
        {this.getElement(this.props.action.progress, ActionTextField.PROGRESS)}
      </div>
    );
  }
}
export default ActionFields;
