import React,  { createRef } from 'react';
import { Months, Quarter, TimelineView } from 'src/constants/timeline';
import Utils from 'src/utils/utils';
import { Table } from 'semantic-ui-react';
import TimelineUtils from '../../../../../../../utils/timelineUtils';
import { TimelineElement } from '../../../../../../../constants/piTimeline';
import TodayIndicator from './todayIndicator/todayIndicator';
import TimelineContentHeader from './contentHeader/timelineContentHeader';
import ContentRowWrapper from './contentRowWrapper/contentRowWrapper';
import './timelineContent.scss';
import CustomScrollBars from '../../../../../../common/customScrollBars/customScrollBars';
import TypeActivity from '../../../../../../../constants/typeActivity';
import Scrollbars from 'react-custom-scrollbars';
import ActionTypes from 'src/constants/actionTypes';
import ActionStore from 'src/stores/action.store';
import { LinkedAction } from 'src/models/linkedMilestone';

interface IProps {
  selectedView: TimelineView;
  year: number;
  quarter: Quarter;
  month: Months;
  timelineElements: TimelineElement[];
  setOverviewData(activityId: number, type: TypeActivity.MILESTONE | TypeActivity.ACTION): void;
  activityIdToScrollTo?: number;
}

interface IStates{
  milestoneLinkedAction: Map<number, LinkedAction[]>;
  orderedTimelineElements: TimelineElement[];
  colorElements: Map<TimelineElement, string>;
}

enum ColorEnum {
  firstColor = 'firstColor',
  secondColor = 'secondColor',
}
class TimelineContent extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      milestoneLinkedAction: ActionStore.getActionLinkedMilestone(),
      orderedTimelineElements: [],
      colorElements: new Map<TimelineElement, string>(),
    };
  }

  componentDidMount() {
    ActionStore.addListener(ActionTypes.GET_LINK_ACTION_TO_MILESTONE.toString(), this.getLinkedAction);
    this.getLinkedAction();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.activityIdToScrollTo !== this.props.activityIdToScrollTo
      || (this.props.timelineElements.length !== prevProps.timelineElements.length
        && this.props.activityIdToScrollTo)) {
      this.scrollToActivity();
    }
    if (prevProps.timelineElements !== this.props.timelineElements) {
      this.getLinkedAction();
    }
  }

  private scrollbarsRef = createRef<Scrollbars>();

  componentWillUnmount() {
    ActionStore.removeListener(ActionTypes.GET_LINK_ACTION_TO_MILESTONE.toString(), this.getLinkedAction);
  }

  getLinkedAction = () => {
    // we get the dictionnary btw the milestones and their actions
    const milestoneLinkedAction: Map<number, LinkedAction[]> = ActionStore.getActionLinkedMilestone();
    this.setState({ milestoneLinkedAction });

    const milestoneTimeline = this.props.timelineElements
      .filter((el: TimelineElement) => el.type === TypeActivity.MILESTONE);
    const orderedTimelineElements: TimelineElement[] = [];
    let timelineElements: TimelineElement[] = this.props.timelineElements;

    let color: ColorEnum = ColorEnum.firstColor;
    // first we create the groups between the milestones and their linked actions
    for (const milestone of milestoneTimeline) {
      if (milestoneLinkedAction.get(milestone.id!)!.length > 0) {
        const colorElements: Map<TimelineElement, string> = this.state.colorElements;
        for (const action of milestoneLinkedAction.get(milestone.id!)!) {
          const actionElement: TimelineElement[] = timelineElements
            .filter(el => el.type === TypeActivity.ACTION && el.id === action.atId);
          // the length can only be 1 but it's to make sure
          if (actionElement.length === 1) {
            orderedTimelineElements.push(actionElement[0]);
            colorElements.set(actionElement[0], color);
            timelineElements = timelineElements.filter(el => el !== actionElement[0]);
          }
        }
        orderedTimelineElements.push(milestone);
        colorElements.set(milestone, color);
        color = color === ColorEnum.firstColor ? ColorEnum.secondColor : ColorEnum.firstColor;
        timelineElements = timelineElements.filter(el => el !== milestone);
      }
    }

    // then we push the rest: action first then milestone (defined in the container)
    for (const el of timelineElements) {
      orderedTimelineElements.push(el);
    }

    this.setState({ orderedTimelineElements });
  }

  showTable = (hideHeader = false, hideBody = false) => {
    return (
      <Table
        id={hideBody ? 'timeline-table-body-less' : 'timeline-table-header-less'}
        celled={true}
        structured={true}
        singleLine={true}
        selectable={true}
      >
        <TimelineContentHeader
          isHidden={hideHeader}
          selectedView={this.props.selectedView}
          year={this.props.year}
          quarter={this.props.quarter}
          month={this.props.month}
        />
        <Table.Body style={{ visibility: hideBody ? 'collapse' : 'visible' }}>
          {this.state.orderedTimelineElements.map(el => (
            <ContentRowWrapper
              key={Utils.generateRandomKey()}
              selectedView={this.props.selectedView}
              year={this.props.year}
              quarter={this.props.quarter}
              month={this.props.month}
              timelineElement={el}
              isChild={false}
              setOverviewData={this.props.setOverviewData}
              colorElement={this.state.colorElements.get(el)}
            />
          ))}
        </Table.Body>
      </Table>
    );
  }

  private scrollToActivity = () => {
    const timelineEl = document.getElementsByTagName('tr');
    const timelineElementHeight = timelineEl && timelineEl[0]
      ? document.getElementsByTagName('tr')[0].clientHeight : 26.6;
    const index = this.props.timelineElements.findIndex(el => el.id === this.props.activityIdToScrollTo);
    const action = this.props.timelineElements[index];
    if (action) {
      setTimeout(() => {
        if (!!this.scrollbarsRef.current) {
          this.scrollbarsRef.current.scrollTop(index * timelineElementHeight);
        }
      });
    }
  };

  public render() {
    return (
      <div id="pi-timeline-component">
        {!TimelineUtils.isOnMonthView(this.props.selectedView) &&
        <TodayIndicator
          selectedView={this.props.selectedView}
          year={this.props.year}
          quarter={this.props.quarter}
          month={this.props.month}
        />
        }
        {this.showTable(false, true)}
          <CustomScrollBars className="scrollable-table" customRef={this.scrollbarsRef}>
            {this.showTable(true, false)}
          </CustomScrollBars>
     </div>
    );
  }
}

export default TimelineContent;
