export enum ExportColumns {
  PLAN = 'Plan Name',
  YEAR = 'Plan Year',
  REFERENT = 'PP Referent',
  CODE = 'BC & PI Codes',
  NAME = 'BC & PI Names',
  KEYWORDS = 'BC & PI Keywords',
  LEADERS = 'BC & PI Leaders',
  GEO = 'Geography',
  CO = 'Country',
  SUB_CO = 'Subcountry',
  GBU = 'GBU',
}

export enum ExportChoicesAttributes {
  CODE = 'code',
  NAME = 'name',
  PP_NAME = 'ppName',
  PP_YEAR = 'ppYear',
  KEYWORD = 'keyword',
  LEADERS = 'leaders',
  GEO = 'geo',
  COUNTRY = 'country',
  SUBCOUNTRY = 'subcountry',
  GBU = 'gbu',
  PP_REFERENT = 'ppReferent',
}

export class ExportChoices {
  code: boolean;
  name: boolean;
  ppName: boolean;
  ppYear: boolean;
  keyword: boolean;
  leaders: boolean;
  geo: boolean;
  country: boolean;
  subcountry: boolean;
  gbu: boolean;
  ppReferent: boolean;

  constructor() {
    this.code = true;
    this.name = true;
    this.ppName = true;
    this.ppYear = true;
    this.keyword = true;
    this.leaders = true;
    this.geo = true;
    this.country = true;
    this.subcountry = true;
    this.gbu = true;
    this.ppReferent = true;
  }
}
