import ActionTypes from '../constants/actionTypes';
import Dispatcher from '../dispatcher';
import AdministrationTab from 'src/constants/administrationTab';

export default class AdministrationActions {

  /**
   * EMIT ADMINISTRATION_TAB_GET
   * @param administrationTab: AdministrationTab
   */
  public static emitTabSelect(administrationTab: AdministrationTab) {
    Dispatcher.dispatch({
      administrationTab,
      actionType: ActionTypes.ADMINISTRATION_TAB_GET,
    });
  }
}
