import { Icon } from 'semantic-ui-react';
import React,  { Component } from 'react';

import Chart from './chart/chart';
import ChartModal from './chart/chartModal';
import Utils from '../../../../../../utils/utils';
import ModeTypes from '../../../../../../constants/modeTypes';
import Metric from '../../../../../../models/metric';

interface IProps {
  mode: ModeTypes;
  metrics: Metric;
  metricsCount: number;
}

interface IStates {
  isChartModalOpened: boolean;
}

export default class ChartContainer extends Component<IProps, IStates> {

  constructor(props) {
    super(props);
    this.state = {
      isChartModalOpened: false,
    };
  }

  toggleViewChartModal = () => {
    this.setState(state => ({ isChartModalOpened: !state.isChartModalOpened }));
  };

  render() {
    return (
      <>
        {Utils.isOnViewOrDeletionMode(this.props.mode)
        && <div className="metrics-chart">
          {this.props.metrics
          && <Icon
            name="expand arrows alternate"
            onClick={this.toggleViewChartModal}
          />
          }
          <Chart
            metric={this.props.metrics}
            metricsCount={this.props.metricsCount}
          />
          {this.state.isChartModalOpened &&
            <ChartModal
              metric={this.props.metrics}
              onModalClose={this.toggleViewChartModal}
              metricsCount={this.props.metricsCount}
              image={''}
            />
          }
        </div>
        }
      </>
    );
  }
}
