import ActionAPI from 'src/api/action.api';
import ActionTypes from 'src/constants/actionTypes';
import Dispatcher from 'src/dispatcher';
import Action, { ActionEdit, ActionPost } from 'src/models/action';

import Utils from 'src/utils/utils';
import AuthStore from '../stores/auth.store';
import { AssignedUsers, UsersAssignation } from '../models/user';
import { AssignationType } from '../constants/account';
import MilestoneAction from './milestone.action';
import Milestone from 'src/models/milestone';
import { LinkedAction } from 'src/models/linkedMilestone';

export default class ActionActions {

  /**
   * Get the actions of a PI
   * @param piId: number
   * @param sortBy?: string (optional)
   * @param orderBy?: string (optional)
   */
  public static getActions(piId: number, sortBy?: string, orderBy?: string) {
    ActionAPI.getActions(piId, sortBy, orderBy).then((actions) => {
      Dispatcher.dispatch({
        actions,
        actionType: ActionTypes.ACTIONS_GET,
      });
      // when we gets the action in performanceMeasure we also want the linked milestone
      MilestoneAction.getLinkedMilestone(actions);
    });
  }

  /**
   * Emit action saved
   * @returns {void}
   */
  public static onActionSaved() {
    Dispatcher.dispatch({
      actionType: ActionTypes.ACTION_SAVED,
    });
  }

  /**
   * Create an action
   * @return void
   * @param piId
   * @param action
   */
  public static createAction(piId: number, action: ActionPost): Promise<Action> {
    const body: any = action;
    return ActionAPI.postAction(piId, body)
      .then(async (newAt) => {
        const assignedUsers = new AssignedUsers();
        if (action.assignedAccounts && action.assignedAccounts.length > 0) {
          const ownersEmails = await ActionAPI.postActionAssignees(
            newAt.id, new UsersAssignation(action.assignedAccounts, AssignationType.AT_OWNER));
          if (ownersEmails) assignedUsers.addUsersIfNotExist(ownersEmails);
        }

        assignedUsers.dispatchAssignedUsers();
        return newAt;
      });
  }

  public static toggleClosedActionStatus(action: Action) : Promise<Action> {
    return ActionAPI.toggleClosedActionStatus(action.id, action.isClosed);
  }

  public static toggleFavouriteActionStatus(action: Action) {
    return ActionAPI.toggleFavouriteActionStatus(action.id, action.isFavourite);
  }

  /**
   * Edit an action
   * @param actionList: Action[]
   * @param action: Action
   * @param piId
   * @param oldAction: Action
   */
  public static async editAction(actionList: ActionEdit[] | Action[],
                                 action: ActionEdit | Action, piId: number,
                                 oldAction?: ActionEdit | Action): Promise<Action> {
    const body: any = action;
    const promises: Promise<any>[] = [];
    const atId = oldAction!.id;

    //region OWNERS
    const invitedUsers = new AssignedUsers();
    const assignedAccounts = Utils.filterChanges(oldAction!.assignedAccounts, action.assignedAccounts);
    if (assignedAccounts.create.length !== 0 && atId) {
      const ownersEmails = await ActionAPI.postActionAssignees(
        atId, new UsersAssignation(assignedAccounts.create, AssignationType.AT_OWNER));
      if (ownersEmails) invitedUsers.addUsersIfNotExist(ownersEmails);
    }
    if (assignedAccounts.remove.length !== 0 && atId) {
      promises.push(ActionAPI.deleteActionAssignees(
        atId, new UsersAssignation(assignedAccounts.remove, AssignationType.AT_OWNER)));
    }
    //endregion

    return Promise.all(promises).then(() => {
      if (action.status) {
        body['status'] = action.status;
      }
      if ('isClosed' in action) {
        body['isClosed'] = action.isClosed;
      }

      invitedUsers.dispatchAssignedUsers();
      if (atId) {
        return ActionAPI.putAction(body).then((action) => {
          if (
            assignedAccounts.create.some(user => user.id === AuthStore.getConnectedUser().id)
            || assignedAccounts.remove.some(user => user.id === AuthStore.getConnectedUser().id)
          ) {
            Utils.updateCurrentUser();
          }
          return action;
        });
      }

      if ((actionList as Action[]).filter(at => at.id !== action.id).some(at => at.code === action.code)) {
        return Promise.reject('duplicated codes');
      }
      return ActionAPI.postAction(piId, action as Action).then((action) => {
        return action;
      });

    });
  }

  /**
   * Delete Action from API and emit actions ACTION_DELETED
   * @param atId : number
   * @return void
   */
  public static removeAction(atId: number): Promise<void> {
    return ActionAPI.deleteActionById(atId)
      .then(() => {
        Dispatcher.dispatch({
          actionType: ActionTypes.ACTION_DELETED,
        });
      });
  }

  /**
   * Add created Action from API and emit actions SET_CREATED_ACTION
   * @param {Action} action
   * @return {void}
   */
  public static setCreatedAction(action: Action): void {
    Dispatcher.dispatch({
      action,
      actionType: ActionTypes.SET_CREATED_ACTION,
    });
  }

  public static getLinkedActions(milestones: Milestone[]) {
    const actionsLinkedMilestone = new Map<number, LinkedAction[]>();

    const promises = milestones.map((milestone)  => {
      return ActionAPI.getMilestoneActions(milestone.id!!)
        .then((actionId) => {
          actionsLinkedMilestone.set(milestone.id!!, actionId);
        });
    });

    Promise.all(promises)
      .then(() => {
        Dispatcher.dispatch({
          actionsLinkedMilestone,
          actionType: ActionTypes.GET_LINK_ACTION_TO_MILESTONE,
        });
      });
  }

  public static deleteLinkedActions(atId: number, miId: number, firstCall: boolean) {
    return ActionAPI.deleteMilestoneActions(atId, miId).then(() => {
      if (firstCall) MilestoneAction.deleteLinkedMilestone(atId, miId, false);
      Dispatcher.dispatch({
        atId,
        miId,
        actionType: ActionTypes.DELETE_LINK_ACTION_TO_MILESTONE,
      });
    });
  }

  public static updateLinkedActions(atId: number, miId: number, firstCall: boolean) {
    return ActionAPI.putMilestoneActions(atId, miId).then(() => {
      if (firstCall) MilestoneAction.updateLinkedMilestone(atId, miId, false);
      Dispatcher.dispatch({
        atId,
        miId,
        actionType: ActionTypes.SET_LINK_ACTION_TO_MILESTONE,
      });
    });
  }

}
