import './titleContainer.scss';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import UserAvatar from 'src/components/common/userAvatar/userAvatar';
import ActionTypes from 'src/constants/actionTypes';
import ModeTypes from 'src/constants/modeTypes';
import TypeActivity from 'src/constants/typeActivity';
import PerformancePlan from 'src/models/performancePlan';
import BusinessChallenge from 'src/models/businessChallenge';
import PerformanceInitiative from 'src/models/performanceInitiative';
import ActivitiesStore from 'src/stores/activities.store';
import AuthStore from 'src/stores/auth.store';
import Utils from 'src/utils/utils';
import { AssignedUser } from '../../../../models/user';
import User from 'src/models/user';
import PublicationButton from './publicationButton/publicationButton';
import MoreActions from './moreActions/moreActions';
import DraftValidationButton from './draftValidationButton/draftValidationButton';
import DraftLabel from '../../../common/draftLabel/draftLabel';
import LinkLabel from '../../../common/linkLabel/linkLabel';
import ExportCenter from './exportCenter/exportCenter';
import PerformancePlanAPI from 'src/api/performancePlan.api';
import BusinessChallengeAPI from 'src/api/businessChallenge.api';
import PerformanceInitiativeAPI from 'src/api/performanceInitiative.api';
import { RightsOnBC, RightsOnPI, RightsOnPP } from 'src/models/rights';
import RightsStore from 'src/stores/rights.store';
import { AssignationType } from '../../../../constants/account';

export interface MenuItems {
  title: JSX.Element;
  subMenu?: MenuItems[];
  active?: boolean;

  handleClick?(): any;
}

interface IRouteProps {
  ppId: string;
  id: string;
}
interface IProps extends RouteComponentProps<IRouteProps> {
  type: TypeActivity;
  emitSave?: (
    data: BusinessChallenge | PerformanceInitiative,
    oldData: BusinessChallenge | PerformanceInitiative,
  ) => Promise<any>;
  triggerSizeCalculation(): void;
}

interface IStates {
  data: PerformancePlan | BusinessChallenge | PerformanceInitiative | null;
  oldData: PerformancePlan | BusinessChallenge | PerformanceInitiative | null;
  assignedList: AssignedUser[];
  user: User;
  accountPanel: JSX.Element[];
  mode: ModeTypes;
  rightsOnPp: RightsOnPP | undefined;
  rightsOnBc: RightsOnBC | undefined;
  rightsOnPi: RightsOnPI | undefined;
}

class TitleContainer extends React.Component<IProps & RouteComponentProps, IStates> {

  private isMount: boolean = false;

  public constructor(props: IProps & RouteComponentProps) {
    super(props);
    this.state = {
      data: null,
      oldData: null,
      assignedList: [],
      user: AuthStore.getConnectedUser(),
      accountPanel: [],
      mode: ActivitiesStore.getMode(),
      rightsOnPp: RightsStore.getRightsOnPP(),
      rightsOnBc: RightsStore.getRightsOnBC(),
      rightsOnPi: RightsStore.getRightsOnPI(),
    };
  }

  public componentDidMount() {
    this.isMount = true;
    ActivitiesStore.addListener(ActionTypes.ACTIVITIES_GET_MODE.toString(), this.getMode);
    ActivitiesStore.addListener(ActionTypes.ACTIVITY_PUBLISHED.toString(), this.getData);
    ActivitiesStore.addListener(ActionTypes.RELOAD_TITLE_ACTIVITY.toString(), this.getData);
    RightsStore.addListener(ActionTypes.SET_PP_RIGHTS.toString(), this.setRightsOnPp);
    RightsStore.addListener(ActionTypes.SET_BC_RIGHTS.toString(), this.setRightsOnBc);
    RightsStore.addListener(ActionTypes.SET_PI_RIGHTS.toString(), this.setRightsOnPI);
    this.getData().then(() => {
      this.props.triggerSizeCalculation();
    });
  }

  componentDidUpdate(prevProps: Readonly<IProps & RouteComponentProps>, prevState: Readonly<IStates>, snapshot?: any) {
    if (prevProps.match.params.id !== this.props.match.params.id && Utils.isActivityPp(this.props.type)) {
      this.getData().then(() => {
        this.props.triggerSizeCalculation();
      });
    }
  }

  public componentWillUnmount() {
    this.isMount = false;
    ActivitiesStore.removeListener(ActionTypes.ACTIVITIES_GET_MODE.toString(), this.getMode);
    ActivitiesStore.removeListener(ActionTypes.ACTIVITY_PUBLISHED.toString(), this.getData);
    ActivitiesStore.removeListener(ActionTypes.RELOAD_TITLE_ACTIVITY.toString(), this.getData);
    RightsStore.removeListener(ActionTypes.SET_PP_RIGHTS.toString(), this.setRightsOnPp);
    RightsStore.removeListener(ActionTypes.SET_BC_RIGHTS.toString(), this.setRightsOnBc);
    RightsStore.removeListener(ActionTypes.SET_PI_RIGHTS.toString(), this.setRightsOnPI);
  }

  private setRightsOnPp = () => {
    this.setState({
      rightsOnPp: RightsStore.getRightsOnPP(),
    });
  }

  private setRightsOnBc = () => {
    this.setState({
      rightsOnBc: RightsStore.getRightsOnBC(),
    });
  }

  private setRightsOnPI = () => {
    this.setState({
      rightsOnPi: RightsStore.getRightsOnPI(),
    });
  }

  private getData = async () => {
    let data;
    let assignedUsersList;

    switch (this.props.type) {
      case TypeActivity.PERFORMANCE_PLAN:
        data = await PerformancePlanAPI.getPerformancePlanGlobalById(+this.props.match.params.id);
        assignedUsersList = await this.getListUsersAssigned(+this.props.match.params.id);
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        data = await BusinessChallengeAPI
        .getBusinessChallengeGlobalById(+this.props.match.params.id, +this.props.match.params.ppId);
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        data = await PerformanceInitiativeAPI
        .getPerformanceInitiativeGlobalById(+this.props.match.params.id, +this.props.match.params.ppId);
        break;
    }
    this.setState({
      data,
      oldData:  { ...data },
      assignedList: assignedUsersList,
    });
  }
  private getListUsersAssigned = async (ppId: number) => {
    const listUsersAssigned: AssignedUser[] = [];

    const listIdBc = await PerformancePlanAPI.getPerformancePlanBcCodes(ppId);
    for (const bc of listIdBc.businessChallenges) {
      const bcUsersAssigned = await BusinessChallengeAPI.getBusinessChallengeAssignations(bc.id);
      if (bcUsersAssigned.informedAccounts.length > 0) {
        bcUsersAssigned.informedAccounts.forEach((user) => {
          const assigned: AssignedUser =
              { activityId: bc.id, assignationType: AssignationType.BC_INFORMED, email: user.email, id: user.id };
          listUsersAssigned.push(assigned);
        });
      }
      const listIdPi = await BusinessChallengeAPI.getBusinessChallengePiCodes(bc.id);
      for (const pi of listIdPi.performanceInitiatives) {
        const piUsersAssigned = await PerformanceInitiativeAPI.getPerformanceInitiativeAssignations(pi.id);
        if (piUsersAssigned.informedAccounts.length > 0) {
          piUsersAssigned.informedAccounts.forEach((user) => {
            const assigned: AssignedUser =
                { activityId: pi.id, assignationType: AssignationType.PI_INFORMED, email: user.email, id: user.id };
            listUsersAssigned.push(assigned);
          });
        }
      }
    }
    console.log(listUsersAssigned);
    return listUsersAssigned.filter((value, index, usersArray) => usersArray.indexOf(value) === index);
  }
  private getMode = () => {
    if (this.isMount) {
      this.setState({ mode: ActivitiesStore.getMode() });
    }
  };

  public fetchAssignees(): JSX.Element[] {
    const accountPanel: JSX.Element[] = [];
    if (Utils.isActivityPp(this.props.type) && (this.state.data as PerformancePlan).owner) {
      accountPanel.push(
        <UserAvatar
          account={(this.state.data as PerformancePlan).owner}
          key={(this.state.data as PerformancePlan).owner.id}
        />,
      );
    } else {

      if (this.state.data?.assignedAccounts) {
        this.state.data.assignedAccounts.forEach((acc) => {
          accountPanel.push(<UserAvatar account={acc} key={acc.id} />);
        });
      }
    }

    return accountPanel;
  }

  public getCanEditRights = () => {
    switch (this.props.type) {
      case TypeActivity.BUSINESS_CHALLENGE:
        return this.state.rightsOnBc?.canEdit();
      case TypeActivity.PERFORMANCE_INITIATIVE:
        return this.state.rightsOnPi?.canEdit();
      case TypeActivity.PERFORMANCE_PLAN:
        return this.state.rightsOnPp?.canEdit();
    }
  }

  public render() {
    if (!this.state.data) {
      return <div className="loader-container">{Utils.loader()}</div>;
    }
    const isPpDraft = 'isDraft' in this.state.data
        ? (this.state.data as PerformancePlan).isDraft
        : (this.state.data as BusinessChallenge | PerformanceInitiative).isPpDraft;
    const isClosed = (this.state.data as PerformanceInitiative | BusinessChallenge).isClosed;

    return (
      <div id="taskBoard-toolbar">
        <div id="title-container-tb">
          <span className="activity-title">
            {Utils.getTypeName(this.props.type)}
              {(this.state.data as BusinessChallenge | PerformanceInitiative).code
                && Utils.leadingZero((this.state.data as BusinessChallenge | PerformanceInitiative).code)}
                &nbsp;- {this.state.data?.name}
              {Utils.isActivityPp(this.props.type)
                && <DraftLabel isDraft={(this.state.data as PerformancePlan).isDraft} />}
          </span>

          {this.state.data && (this.state.data as BusinessChallenge | PerformanceInitiative).isLinked
            && <LinkLabel
              pp={Utils.isActivityBc(this.props.type)
                ? (this.state.data as BusinessChallenge).performancePlan
                : (this.state.data as PerformanceInitiative).businessChallenge.performancePlan
              }
              bc={Utils.isActivityPi(this.props.type)
                ? (this.state.data as PerformanceInitiative).businessChallenge
                : undefined
              }
            />
          }

          {Utils.isActivityBc(this.props.type) && this.state.data
            && Utils.isTop5((this.state.data as BusinessChallenge))}

          {Utils.isActivityBc(this.props.type) && (this.state.data as BusinessChallenge).pillar
            && Utils.getPillar((this.state.data as BusinessChallenge).pillar)}

          <div id={'buttons-container'}>
            {this.getCanEditRights()
              && <>
                {Utils.isOnViewMode(this.state.mode) && (this.state.data as PerformancePlan).isDraft &&
                  <DraftValidationButton
                      data={this.state.data as PerformancePlan}
                      assignedUsers={this.state.assignedList}
                  />
                }
                {!isPpDraft && !isClosed &&
                  <PublicationButton
                    data={this.state.data}
                    mode={this.state.mode}
                  />
                }
              </>
            }
            {this.state.data && <>
              <ExportCenter
                data={this.state.data}
                mode={this.state.mode}
                type={this.props.type}
                rightsOnPp={this.state.rightsOnPp}
                rightsOnBc={this.state.rightsOnBc}
                rightsOnPi={this.state.rightsOnPi}
              />
              <MoreActions
                data={this.state.data}
                mode={this.state.mode}
                type={this.props.type}
                rightsOnPp={this.state.rightsOnPp}
                rightsOnBc={this.state.rightsOnBc}
                rightsOnPi={this.state.rightsOnPi}
              />
            </>}
          </div>
        </div>
        <div id="name-owner-container">
          {this.fetchAssignees()}
        </div>
      </div>
    );
  }
}

export default withRouter(TitleContainer);
