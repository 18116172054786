import './draftLabel.scss';

import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

interface IProps {
  isDraft: boolean | null;
}

export default class DraftLabel extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    if (!!this.props.isDraft) {
      return (
        <div className="pp-draft-label-container">
          <FormattedMessage id="draft.draftPlan" defaultMessage="This Plan is a draft">
            {msg =>
              <Popup
                content={msg}
                inverted={true}
                position="right center"
                size="small"
                trigger={
                  <div className="pp-draft-icon-container">
                    <Icon name="sticky note outline" />
                  </div>
                }
              />
            }
          </FormattedMessage>
        </div>
      );
    }

    return null;
  }
}
