import './userRow.scss';

import * as React from 'react';
import { Button, Checkbox, Icon, Input, Modal, Popup, Table } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import User from 'src/models/user';
import ModeTypes from 'src/constants/modeTypes';
import Role from 'src/models/role';
import Utils from 'src/utils/utils';
import ModificationDropdown from 'src/components/common/form/modificationDropdown/modificationDropdown';
import NotificationActions from 'src/actions/notification-actions';
import AuthStore from 'src/stores/auth.store';
import UserAPI from 'src/api/user.api';
import { ToastType } from 'src/components/common/toast/toast';
import AccountConflict from './accountConflict/accountConflict';
import AssignedList from './assignedList/assignedList';
import UserUtils from '../../../../../utils/userUtils';
import RoleNames from '../../../../../constants/roleNames';

interface IProps {
  user: User;
  index: number;
  nbUsers: number;
  roles: Role[];

  passwordReset(password: string): void;
  reloadUserList(): void;
}

interface IStates {
  mode: ModeTypes;
  currentUser: User;
  isConflictModalOpened: boolean;
  deletionModalOpened: boolean;
  errorDeletion: boolean;
  deletionLoader: boolean;
  errorDeletionContent: any;
  isFirstNameValid: boolean;
  isLastNameValid: boolean;
  isTgiValid: boolean;
  isEmailValid: boolean;
}

class UserRow extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      mode: ModeTypes.MODE_VIEW,
      currentUser: this.props.user,
      isConflictModalOpened: false,
      deletionModalOpened: false,
      errorDeletion: false,
      deletionLoader: false,
      errorDeletionContent: {},
      isFirstNameValid: true,
      isLastNameValid: true,
      isTgiValid: true,
      isEmailValid: true,
    };
  }

  public handleEditing() {
    this.setState({ mode: ModeTypes.MODE_EDITION });
  }

  public handleChange = (idAttribute: string, value: any): void => {
    const state = { ...this.state };

    switch (idAttribute) {
      case 'lastName':
        state.currentUser.lastName = value;
        state.isLastNameValid = value.trim().length !== 0;
        break;
      case 'firstName':
        state.currentUser.firstName = value;
        state.isFirstNameValid = value.trim().length !== 0;
        break;
      case 'tgi':
        state.currentUser.tgi = value;
        state.isTgiValid = UserUtils.validateTgi(value);
        break;
      case 'role':
        state.currentUser.roleId = value;
        break;
      case 'email':
        state.currentUser.email = value;
        state.isEmailValid = UserUtils.validateEmailAddress(value);
        break;
      default:
        state.currentUser[idAttribute] = value;
        break;
    }

    this.setState(state);
  };

  public isFormValid = () => {
    return this.state.isFirstNameValid && this.state.isLastNameValid
      && this.state.isTgiValid && this.state.isEmailValid;
  };

  public getInvalidMessage = () => {
    if (!this.state.isFirstNameValid) {
      return <FormattedMessage id="userCreation.invalidFirstName" defaultMessage="Invalid first name" />;
    }

    if (!this.state.isLastNameValid) {
      return <FormattedMessage id="userCreation.invalidLastName" defaultMessage="Invalid last name" />;
    }

    if (!this.state.isTgiValid) {
      return <FormattedMessage id="userCreation.invalidTgi" defaultMessage="Invalid TGI" />;
    }

    if (!this.state.isEmailValid) {
      return <FormattedMessage id="userCreation.invalidEmail" defaultMessage="Invalid email" />;
    }

    return null;
  };

  public resetPassword() {
    UserAPI.resetPassword(this.state.currentUser.id)
      .then(res => this.props.passwordReset(res.password))
      .catch(() => Utils.toastError());
  }

  public onSave = (): void => {
    const currentUser = { ...this.state.currentUser };
    if (!this.state.isTgiValid || !this.state.isEmailValid || !currentUser) {
      return;
    }
    // Force automatic highest role computing if not referent
    currentUser.roleId = currentUser.roleId === RoleNames.REFERENT ? RoleNames.REFERENT : -1;

    UserAPI.putUserById(currentUser.id, currentUser)
      .then(() => {
        NotificationActions.toast(
          <FormattedMessage id="saved" defaultMessage="Saved!" />,
          <FormattedMessage id="successUserSaved" defaultMessage="User has been successfully saved" />,
          ToastType.SUCCESS,
        );

        if (currentUser.id === AuthStore.getConnectedUser().id) {
          AuthStore.setConnectedUser(currentUser);
          AuthStore.updateProfile();

          if (!currentUser.isCoreTeam) {
            window.location.href = '/';
          }
        }

        this.props.reloadUserList();
      })
      .catch(() => Utils.toastError());

    this.setState({ mode: ModeTypes.MODE_VIEW });
  };

  public onCancel(): void {
    this.setState({ currentUser: this.state.currentUser, mode: ModeTypes.MODE_VIEW });
  }

  /**
   * User element displayed, depends on the mode
   * @param {string} data
   * @param {string} idElement
   * @param {boolean} isInvited (default: false)
   * @return {Element[]} the PI element to display
   */
  public getElement(data: string | undefined, idElement: string, isInvited = false): JSX.Element {
    let domElement: JSX.Element;

    if (this.state.mode === ModeTypes.MODE_VIEW) {
      domElement = (
        <span key={idElement} className="content-element">
          {data
            ? idElement === 'lastName' || idElement === 'tgi' ? data.toUpperCase() : data
            : '---'
          }
        </span>
      );

      if (isInvited) {
        domElement = (
          <Popup
            size="tiny"
            position="top center"
            inverted={true}
            trigger={domElement}
            content={<FormattedMessage id="userInvited" defaultMessage="This user has been invited, but did not create his account yet" />}
          />
        );
      }
    } else {
      domElement = (
        <Input
          id={idElement}
          className="content-element"
          defaultValue={data}
          onChange={(e, onChangeData) => this.handleChange(idElement, onChangeData.value)}
        />
      );
    }
    return domElement;
  }

  /**
   * Get dropdown element depends on the mode (saved or editing)
   */
  public getElementDropdown(): JSX.Element {
    let domElement: JSX.Element = <div>---</div>;

    if (this.state.mode === ModeTypes.MODE_VIEW) {
      const currentObj = this.props.roles.find((myObj: any) => myObj.id === this.state.currentUser.roleId);
      if (currentObj) {
        domElement = (
          <div key={`role${this.state.currentUser.id}`} className="content-element">
            {currentObj.name}
          </div>
        );
      }
    } else {
      const options = [
        this.props.roles[0],
        {
          id: -1,
          name: 'Auto',
        },
      ];
      domElement = (
        <ModificationDropdown
          defaultValue={this.state.currentUser.roleId === RoleNames.REFERENT ? RoleNames.REFERENT : -1}
          dropdownName="role"
          options={options}
          emitChange={this.handleChange}
        />
      );
    }
    return domElement;
  }

  private onConflictClick = () => {
    this.setState({ isConflictModalOpened: true });
  };

  public onConflictModalClose = () => {
    this.setState({ isConflictModalOpened: false });
  };

  public openRequestDeletion = () => {
    this.setState({ deletionModalOpened: true });
  }

  public requestDeletion = () => {
    this.setState({ deletionLoader: true });
    UserAPI.deleteUser(this.state.currentUser.id)
    .then(() => {
      NotificationActions.toast(
        <FormattedMessage id="success" defaultMessage="Success" />,
        (
          <FormattedMessage
            id="account.removeSuccessful"
            defaultMessage="This account was successfully removed!"
          />
        ),
        ToastType.SUCCESS,
      );
      this.props.reloadUserList();
      this.setState({ deletionModalOpened: false, deletionLoader: false  });
    })
    .catch((err) => {
      if (err.reqStatus === 412) {
        this.setState({
          deletionModalOpened: false,
          errorDeletion: true,
          deletionLoader: false,
          errorDeletionContent: err,
        });
      } else {
        Utils.toastError();
      }
    });
  }

  public render() {
    return (
      <Table.Row
        className={`user-row${this.props.index % 2 === 0 ? ' even' : ' odd'}
          ${this.state.mode === ModeTypes.MODE_EDITION ? ' editing' : ''}
          ${this.props.nbUsers === this.props.index + 1 ? ' last' : ''}
        `}
      >
        <Table.Cell className="name-column">
          {this.getElement(this.state.currentUser.lastName, 'lastName')}
          &nbsp;
          {this.getElement(this.state.currentUser.firstName, 'firstName')}
        </Table.Cell>
        <Table.Cell className="tgi-column">
          {this.getElement(this.state.currentUser.tgi, 'tgi')}
        </Table.Cell>
        <Table.Cell className="role-column">
          {this.getElementDropdown()}
        </Table.Cell>
        <Table.Cell className="core-team-column">
          {this.state.mode === ModeTypes.MODE_VIEW
            ? <div>{this.state.currentUser.isCoreTeam ? <Icon name="check" /> : <Icon name="delete" />}</div>
            : <Checkbox
              checked={this.state.currentUser.isCoreTeam}
              onChange={() => this.handleChange('isCoreTeam', !this.state.currentUser.isCoreTeam)}
            />
          }
        </Table.Cell>
        <Table.Cell className={`email-column ${this.props.user.isInvited ? 'invited' : ''}`}>
          {this.getElement(this.state.currentUser.email.toLowerCase(), 'email', this.props.user.isInvited)}
        </Table.Cell>
        <Table.Cell className="last-connection-column">
          {this.state.currentUser.lastConnection
            ? Utils.displayFancyDate(this.state.currentUser.lastConnection, true)
            : '---'}
        </Table.Cell>
        <Table.Cell className="actions-column">
          {this.state.mode === ModeTypes.MODE_VIEW
            && <>
              <span className="icon-container">
                {this.state.currentUser.isConflict
                  && <Popup
                    inverted={true}
                    content={<FormattedMessage id="accountConflict.manageConflict" defaultMessage="Manage conflicts"/>}
                    size="tiny"
                    position="left center"
                    trigger={<div><Icon name="exclamation triangle" onClick={this.onConflictClick}/></div>}
                  />
                }
              </span>
              <span className="icon-container">
                <Popup
                  content={<FormattedMessage id="remove" defaultMessage="Remove"/>}
                  inverted={true}
                  position="left center"
                  size="tiny"
                  trigger={<div><Icon name="trash alternate" onClick={this.openRequestDeletion}/></div>}
                />
              </span>
            </>
          }
          <div className={`edition-icons-container ${this.state.mode === ModeTypes.MODE_EDITION ? 'editing' :  ''}`}>
            {this.state.mode === ModeTypes.MODE_VIEW
              ? <Popup
                content={<FormattedMessage id="edit" defaultMessage="Edit" />}
                inverted={true}
                position="left center"
                size="tiny"
                trigger={<div><Icon name="pencil" onClick={() => this.handleEditing()} /></div>}
              />
              : <>
                <Popup
                  content={<FormattedMessage id="resetPassword" defaultMessage="Reset password" />}
                  inverted={true}
                  position="left center"
                  size="tiny"
                  trigger={<div><Icon name="undo" onClick={() => this.resetPassword()} /></div>}
                />

                <Popup
                  content={this.isFormValid()
                    ? <FormattedMessage id="save" defaultMessage="Save" />
                    : this.getInvalidMessage()
                  }
                  inverted={true}
                  position="left center"
                  size="tiny"
                  trigger={
                    <div>
                      <Icon
                        className={!this.isFormValid() ? 'disabled' : ''}
                        name="check"
                        onClick={() => this.onSave()}
                      />
                    </div>
                  }
                />

                <Popup
                  content={<FormattedMessage id="cancel" defaultMessage="Cancel" />}
                  inverted={true}
                  position="left center"
                  size="tiny"
                  trigger={<div><Icon name="delete" onClick={() => this.onCancel()} /></div>}
                />
              </>
            }
          </div>
        </Table.Cell>

        {this.state.isConflictModalOpened
          && <AccountConflict
            user={this.state.currentUser}
            handleModalClose={this.onConflictModalClose}
            reloadUserList={this.props.reloadUserList}
          />
        }

        <Modal size="mini" open={this.state.deletionModalOpened} className="confirmationDeletion">
          <Modal.Header>
            <FormattedMessage
              id="account.removeTitle"
              defaultMessage="Remove the account of"
            /> {this.state.currentUser.lastName} {this.state.currentUser.firstName}
          </Modal.Header>
          <Modal.Content>
            <FormattedMessage
              id="account.areYouSure"
              defaultMessage="Are you sure you want to remove this user ? The action cannot be reverted."
            />
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.setState({ deletionModalOpened: false })}>
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              onClick={this.requestDeletion}
              content={<FormattedMessage id="confirmDeletion" defaultMessage="Confirm deletion" />}
              icon="checkmark"
              labelPosition="right"
              className="confirmationDeletionAction"
              loading={this.state.deletionLoader}
            />
          </Modal.Actions>
        </Modal>
        <Modal size="mini" open={this.state.errorDeletion} className="impossibleRemoveModale">
          <Modal.Header>
            <FormattedMessage id="account.removeImpossible" defaultMessage="Remove this account is not possible" />
          </Modal.Header>
          <Modal.Content>
            <FormattedMessage
              id="account.stillAssigned"
              defaultMessage="This account is still assigned to the following content :"
            />
            <br/>
            <br/>
            <AssignedList
              deletionCallbackData={this.state.errorDeletionContent}
              role={this.state.currentUser.roleId}
            />
            <br/>
            <br/>
            <FormattedMessage
              id="account.pleaseUnassign"
              defaultMessage="Please remove this account from the following assignments and try again"
            />
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.setState({ errorDeletion: false })}>
              <FormattedMessage id="close" defaultMessage="Close" />
            </Button>
          </Modal.Actions>
        </Modal>
      </Table.Row>
    );
  }
}

export default UserRow;
