import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Utils from '../../../utils/utils';
import { PpErrors } from '../../../constants/errors/performance-plan.error';
import { BcErrors } from '../../../constants/errors/business-challenge.error';
import { PiErrors } from '../../../constants/errors/performance-initiative.error';

export type HistoryExternalErrors = PpErrors | BcErrors | PiErrors;

interface IProps {
  isLoading: boolean;
  historyExternalError?: HistoryExternalErrors;
}

export default class HistoryExternalError extends React.Component<IProps> {
  public render(): JSX.Element {
    if (this.props.isLoading) {
      return Utils.loader();
    }

    let message;

    switch (this.props.historyExternalError) {
      case PpErrors.PP_NOT_FOUND:
        message = <FormattedMessage id="ppHistoryNotFound" defaultMessage="This Performance Plan does not exist" />;
        break;
      case BcErrors.BC_NOT_FOUND:
        message = <FormattedMessage id="bcHistoryNotFound" defaultMessage="This Business Challenge does not exist" />;
        break;
      case PiErrors.PI_NOT_FOUND:
        message =
          <FormattedMessage id="piHistoryNotFound" defaultMessage="This Performance Initiative does not exist" />;
        break;

      case PpErrors.PP_NEVER_BEEN_PUBLISHED:
        message =
          <FormattedMessage id="noExternalViewPp" defaultMessage="This Performance Plan has not been published yet" />;
        break;
      case BcErrors.BC_NEVER_BEEN_PUBLISHED:
        message = <FormattedMessage id="noExternalViewBc" defaultMessage="This Business Challenge has not been published yet" />;
        break;
      case PiErrors.PI_NEVER_BEEN_PUBLISHED:
        message = <FormattedMessage id="noExternalViewPi" defaultMessage="This Performance Initiative has not been published yet" />;
        break;

      case PpErrors.PP_WRONG_HISTORY:
        message = <FormattedMessage id="ppHistoryInvalid" defaultMessage="This history does not belong to this Performance Plan" />;
        break;
      case BcErrors.BC_WRONG_HISTORY:
        message = <FormattedMessage id="bcHistoryInvalid" defaultMessage="This history does not belong to this Business Challenge" />;
        break;
      case PiErrors.PI_WRONG_HISTORY:
        message = <FormattedMessage id="piHistoryInvalid" defaultMessage="This history does not belong to this Performance Initiative" />;
        break;
      default:
        message =
          <FormattedMessage id="errorOccurredMessage" defaultMessage="An error occurred, please try again later" />;
        break;
    }

    return <div id="no-available">{message}</div>;
  }
}
