import './exportCenter.scss';

import * as React from 'react';
import Utils from 'src/utils/utils';
import { CsvExportType, ExportType } from 'src/actions/export.action';
import { Button, Icon } from 'semantic-ui-react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { CustomOptions, ExportStepsOptions } from './interfaces/ExportStepsOptions';
import PerformancePlan from 'src/models/performancePlan';
import BusinessChallenge from 'src/models/businessChallenge';
import PerformanceInitiative from 'src/models/performanceInitiative';
import { ReportingGetFormatted } from 'src/models/reporting';
import TypeActivity from 'src/constants/typeActivity';
import ExportOptionPptx from './exportOptionPptx';

interface IProps extends InjectedIntlProps {
  reportings: ReportingGetFormatted[];
  data: PerformancePlan | BusinessChallenge | PerformanceInitiative;
  exportStepsOptions: ExportStepsOptions;
  type: TypeActivity;
  handleClick: (exportType: ExportType, activityId: number, selectedReporting?: number,
                onlyTop5?: boolean, customOptions?: CustomOptions) => void;
  nextStep: (type?: ExportType, reporting?: ReportingGetFormatted) => void;
  includeBcs: () => void;
  includePis: () => void;
  includeAts: () => void;
  exportSheet: (csvType: CsvExportType) => void;
}

interface IStates {
  exportStepsOptions: ExportStepsOptions;
}

class ExportSecondStep extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    // The state will be use for the includeMiAt and includeMetrics options on the toggle
    this.state = {
      exportStepsOptions : this.props.exportStepsOptions,
    };
  }

  private toggleMetrics = () => {
    this.setState({ exportStepsOptions: {
      ...this.state.exportStepsOptions,
      includeMetrics: !this.state.exportStepsOptions.includeMetrics,
    }});
  }

  private toggleMiAt = () => {
    this.setState({ exportStepsOptions: {
      ...this.state.exportStepsOptions,
      includeMiAt: !this.state.exportStepsOptions.includeMiAt,
    }});
  }

  public render() {
    switch (this.props.exportStepsOptions.selectedExportType) {
      case ExportType.PP_FULL:
        const ppOptions = [
          {
            key: 'allActivitiesPptx',
            title: <FormattedMessage id="at.allActionsPPTX" defaultMessage="PPTX Format" />,
            handleClick: () => this.props.handleClick(ExportType.PP_FULL_PPTX, this.props.data.id, undefined,
                                                      undefined, this.state.exportStepsOptions),
          },
          {
            key: 'allActivitiesPdf',
            title: <FormattedMessage id="at.allActionsPDF" defaultMessage="PDF Format" />,
            handleClick: () => this.props.handleClick(ExportType.PP_FULL, this.props.data.id),
          },
          {
            key: 'allActivitiesXls',
            title: <FormattedMessage id="at.allActionsXLS" defaultMessage="XLS Format" />,
            handleClick: () => this.props.exportSheet(CsvExportType.ACTIVITIES_LIST),
          },
        ];
        return (
          <div className="options-container">
          {
            ppOptions.map((option) => {
              return (
                <div key={option.key} className={`${option.key === 'allActivitiesPptx' ? 'export-cockpit' : 'export-cockpit-pp'}`}>
                  <Button onClick={option.handleClick}>
                    {option.title}
                  </Button>
                  { option.key === 'allActivitiesPptx' &&
                    <ExportOptionPptx
                      exportStepsOptions={this.state.exportStepsOptions}
                      toggleMetrics={this.toggleMetrics}
                      toggleMiAt={this.toggleMiAt}
                    />
                  }
              </div>
              );
            })
          }
        </div>
        );
      case ExportType.ACTION:
        let exportType;
        switch (this.props.type) {
          case TypeActivity.PERFORMANCE_PLAN:
            exportType = ExportType.PP_FULL_AT_LIST;
            break;
          case TypeActivity.BUSINESS_CHALLENGE:
            exportType = ExportType.BC_FULL_AT_LIST;
            break;
          case TypeActivity.PERFORMANCE_INITIATIVE:
            exportType = ExportType.PI_AT_LIST;
            break;
        }
        const actions = [
          {
            key: 'allActionsPDF',
            title: <FormattedMessage id="at.allActionsPDF" defaultMessage="PDF Format" />,
            handleClick: () => this.props.handleClick(exportType, this.props.data.id),
          },
          {
            key: 'allActionsXLS',
            title: <FormattedMessage id="at.allActionsXLS" defaultMessage="XLS Format" />,
            handleClick: () => this.props.exportSheet(CsvExportType.AT_LIST),
          },
        ];
        return (
          <div className="options-container">
          {
            actions.map((action) => {
              return (
                <Button key={action.key} onClick={action.handleClick}>
                  {action.title}
                </Button>
              );
            })
          }
        </div>
        );
      case ExportType.REPORTING:
        const reportings = [
          {
            key: 'allBcs',
            title: <FormattedMessage id="allBcs" defaultMessage="All BCs" />,
            handleClick: () => this.props.handleClick(ExportType.REPORTING, this.props.data.id),
            isNavigation: false,
          },
          {
            key: 'onlyTop5',
            title: <FormattedMessage id="onlyTop5" defaultMessage="Only Top5 BCs" />,
            handleClick: () => this.props.handleClick(ExportType.REPORTING, this.props.data.id, undefined, true),
            isNavigation: false,
          },
        ];
        this.props.reportings.forEach((reporting: ReportingGetFormatted) => {
          reportings.push(
            {
              key: `reporting-list${reporting.id}`,
              title: (
                <span>
                  {reporting.name}
                  <span className="reporting-date"> - {Utils.displayDate(reporting.dueDate)}</span>
                </span>
              ),
              handleClick: () => this.props.nextStep(undefined, reporting),
              isNavigation: true,
            },
          );
        });
        return (
          <div className="options-container">
            {
              reportings.map((report) => {
                return (
                  <Button key={report.key} onClick={report.handleClick}>
                    {report.title} {report.isNavigation && <Icon name="angle right" />}
                  </Button>
                );
              })
            }
          </div>
        );
      case ExportType.CUSTOM:
        const options = [
          {
            key: 'bcs',
            title: <FormattedMessage id="include.bcs" defaultMessage="Include Business challenges" />,
            handleClick: () => this.props.includeBcs(),
            selected: this.props.exportStepsOptions.includeBcs,
            disabled: false,
          },
          {
            key: 'pis',
            title: <FormattedMessage id="include.pis" defaultMessage="Include Performance initiatives" />,
            handleClick: () => this.props.includePis(),
            selected: this.props.exportStepsOptions.includePis,
            disabled: !this.props.exportStepsOptions.includeBcs,
          },
          {
            key: 'ats',
            title: <FormattedMessage id="include.ats" defaultMessage="Include actions" />,
            handleClick: () => this.props.includeAts(),
            selected: this.props.exportStepsOptions.includeAts,
            disabled: !this.props.exportStepsOptions.includePis,
          },
        ];
        return (
          <div className="options-container">
            {
              options.map((opt) => {
                return (
                  <Button key={opt.key} primary={opt.selected} onClick={opt.handleClick} disabled={opt.disabled}>
                    {opt.title} {opt.selected && <Icon name="check" />}
                  </Button>
                );
              })
            }
          </div>
        );
    }
  }

}

export default injectIntl(ExportSecondStep);
