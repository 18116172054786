import './activitiesBoard.scss';

import * as React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import ActivitiesActions from 'src/actions/activities.action';
import ActionTypes from 'src/constants/actionTypes';
import { MenuTypeSelected } from 'src/constants/typeActivity';
import ActivitiesStore from 'src/stores/activities.store';

import TaskBoard from '../taskBoard/taskBoard';
import ContainerActivities from './containerActivities/containerActivities';
import MenuActivities from './menuActivities/menuActivities';

interface IProps extends RouteComponentProps<any> {
}

interface IStates {
  menuTypeSelected: MenuTypeSelected;
}

class ActivitiesBoard extends React.Component<IProps, IStates> {

  private isMount = false;

  constructor(props: IProps) {
    super(props);
    this.state = {
      menuTypeSelected: MenuTypeSelected.MENU_ACTIVITY,
    };
  }

  public componentWillMount() {
    this.isMount = true;
    ActivitiesStore.addListener(ActionTypes.MENU_TYPE_SELECTED.toString(), this.getMenuTypeSelected);
    ActivitiesActions.emitGetActivities();
  }

  public componentWillUnmount() {
    this.isMount = false;
    ActivitiesStore.removeListener(ActionTypes.MENU_TYPE_SELECTED.toString(), this.getMenuTypeSelected);
  }

  private getMenuTypeSelected = () => {
    if (this.isMount) {
      this.setState({
        menuTypeSelected: ActivitiesStore.getMenuTypeSelected(),
      });
    }
  };

  public render() {
    return (
      <div id={'activitiesBoard'}>
        <MenuActivities />
        <Switch>
          <Route
            exact={true}
            path={'/activities-board/taskboard'}
            render={() => <TaskBoard />}
          />
          <Route
            path={'/activities-board'}
            render={() => <ContainerActivities />}
          />
          <Redirect to="/activities-board/taskboard" />
        </Switch>
      </div>
    );
  }
}

export default withRouter(ActivitiesBoard);
