import './treeView.scss';

import * as React from 'react';
import Tree from 'react-d3-tree';
import { Button, Modal } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import User from '../../models/user';
import ambitionboost from '../../images/ambition-boost-logo.png';
import Utils from '../../utils/utils';
import UserAvatar from '../common/userAvatar/userAvatar';
import PerformancePlanAPI from '../../api/performancePlan.api';
import PerformancePlan from '../../models/performancePlan';
import BusinessChallenge from '../../models/businessChallenge';
import PerformanceInitiative from '../../models/performanceInitiative';
import Action from '../../models/action';
import TypeActivity from '../../constants/typeActivity';
import AuthStore from '../../stores/auth.store';
import Dispatcher from '../../dispatcher';
import ActionTypes from '../../constants/actionTypes';
import RightColumn from './rightColumn/rightColumn';
import { SelectedActivity } from 'src/stores/treeview.store';
import CustomNodeText from './customNodeText/customNodeText';

interface IProps {
  open: boolean;
  ppId: number;
  currentActivityId: number;
  currentActivityType: TypeActivity;

  close(): void;
}

interface IStates {
  pp: PerformancePlan | undefined;
  selectedActivity: SelectedActivity | undefined;
  translate: { x: number, y: number };
}

enum Colors {
  assigned = '#2987CD',
  defaultStroke = '#CDCDCD',
  defaultFill = '#FFF',
  hasSubElements = '#CDCDCD',
  currentActivity = '#302B66',
}

class TreeView extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);

    this.state = {
      pp: undefined,
      selectedActivity: undefined,
      translate: { x: 1, y: 1 },
    };
  }

  public componentWillMount(): void {
    PerformancePlanAPI.getPerformancePlanTreeView(this.props.ppId)
    .then((pp: PerformancePlan) => {
      this.setState({ pp });
    });
  }

  private treeContainer = (element: any) => {
    if (element) {
      const dimensions = element.getBoundingClientRect();

      this.setState({
        translate: {
          x: dimensions.width / 2,
          y: dimensions.height / 4,
        },
      });
    }
  };

  private static truncateString(str: string): string {
    const TEXT_LENGTH = 25;
    return `${str.substring(0, TEXT_LENGTH).trim()}${str.length > TEXT_LENGTH ? '...' : ''}`;
  }

  private setTreeData(): any {
    if (this.state.pp) {
      return {
        name: `${this.state.pp.name} ${this.state.pp.year}`,
        publishedDate: this.state.pp.publishedDate,
        nodeSvgShape: {
          shapeProps: this.nodeStyle(
            this.state.pp,
            this.state.pp.businessChallenges.length,
            this.state.pp.id,
            TypeActivity.PERFORMANCE_PLAN,
          ),
        },
        selectedActivity: {
          name: `${this.state.pp.name} ${this.state.pp.year}`,
          type: TypeActivity.PERFORMANCE_PLAN,
          id: this.state.pp.id,
          assignedAccounts: [this.state.pp.owner],
          publishedDate: this.state.pp.publishedDate,
        },
        children: Utils.sortArrayByKey(this.state.pp.businessChallenges, 'code').map((bc: BusinessChallenge) => {
          return {
            name: `BC${Utils.leadingZero(bc.code)} - ${TreeView.truncateString(bc.name)}`,
            publishedDate: bc.publishedDate,
            nodeSvgShape: {
              shapeProps: this.nodeStyle(
                bc,
                bc.performanceInitiatives.length,
                bc.id,
                TypeActivity.BUSINESS_CHALLENGE,
              ),
              nodeSvgShape: {
                shapeProps: {
                  r: 5,
                  fill: 'green',
                },
              },
            },
            _collapsed:
              !(
                (TypeActivity.PERFORMANCE_INITIATIVE === this.props.currentActivityType
                  && bc.performanceInitiatives.some(pi => pi.id === this.props.currentActivityId))
                || (bc.id === this.props.currentActivityId
                  && TypeActivity.BUSINESS_CHALLENGE === this.props.currentActivityType)
              ),
            selectedActivity: {
              name: `BC${Utils.leadingZero(bc.code)} - ${bc.name}`,
              type: TypeActivity.BUSINESS_CHALLENGE,
              id: bc.id,
              assignedAccounts: bc.assignedAccounts,
              desc: bc.objectives,
              pillar: bc.pillar,
              publishedDate: bc.publishedDate,
            },
            children: Utils.sortArrayByKey(bc.performanceInitiatives, 'code').map((pi: PerformanceInitiative) => {
              return {
                name: `PI${Utils.leadingZero(pi.code)} - ${TreeView.truncateString(pi.name)}`,
                publishedDate: pi.publishedDate,
                nodeSvgShape: {
                  shapeProps: this.nodeStyle(
                    pi,
                    pi.actions.length,
                    pi.id,
                    TypeActivity.PERFORMANCE_INITIATIVE,
                  ),
                },
                _collapsed: !(pi.id === this.props.currentActivityId
                  && TypeActivity.PERFORMANCE_INITIATIVE === this.props.currentActivityType),
                selectedActivity: {
                  name: `PI${Utils.leadingZero(pi.code)} - ${pi.name}`,
                  type: TypeActivity.PERFORMANCE_INITIATIVE,
                  id: pi.id,
                  assignedAccounts: pi.assignedAccounts,
                  desc: pi.objectives,
                  publishedDate: pi.publishedDate,
                },
                children: pi.actions.map((at: Action) => {
                  return {
                    name: `A${Utils.leadingZero(at.code)}`,
                    nodeSvgShape: {
                      shapeProps: this.nodeStyle(
                        at,
                        0,
                        at.id,
                        TypeActivity.ACTION,
                      ),
                    },
                    selectedActivity: {
                      name: `A${Utils.leadingZero(at.code)} - ${at.name}`,
                      type: TypeActivity.ACTION,
                      id: at.id,
                      assignedAccounts: at.assignedAccounts,
                    },
                  };
                }),
              };
            }),
          };
        }),
      };
    }

    return { name: '' };
  }

  private nodeStyle(
    activity: PerformancePlan | BusinessChallenge | PerformanceInitiative | Action,
    subActivityLength: number,
    activityId: number,
    activityType: TypeActivity,
  ): { r: number, stroke: Colors, fill: Colors } {
    const styles = {
      r: 7,
      stroke: Colors.defaultStroke,
      fill: Colors.defaultFill,
    };

    if (subActivityLength > 0) {
      styles.r = 10;
      styles.fill = Colors.hasSubElements;
    }

    const currentUserId = AuthStore.getConnectedUser().id;
    let isUserInActivity = false;
    switch (activityType) {
      case TypeActivity.PERFORMANCE_PLAN:
        if (currentUserId === (activity as PerformancePlan).owner.id) {
          isUserInActivity = true;
        }
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        if ((activity as BusinessChallenge).assignedAccounts.some(account => account.id === currentUserId)) {
          isUserInActivity = true;
        }
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        if ((activity as PerformanceInitiative).assignedAccounts.some(account => account.id === currentUserId)) {
          isUserInActivity = true;
        }
        break;
      case TypeActivity.ACTION:
        if ((activity as Action).assignedAccounts.some(account => account.id === currentUserId)) {
          isUserInActivity = true;
        }
        break;
    }

    if (isUserInActivity) {
      styles.stroke = Colors.assigned;
    }

    if (this.props.currentActivityId === activityId && this.props.currentActivityType === activityType) {
      styles.fill = Colors.currentActivity;
    }

    return styles;
  }

  private handleNodeClick = (event: any) => {
    Dispatcher.dispatch({
      selectedActivity: event.selectedActivity,
      actionType: ActionTypes.GET_SELECTED_ACTIVITY_TREEVIEW,
    });
  };

  public render() {
    const treeviewStyles = {
      links: {
        stroke: '#CBCBCB',
        strokeWidth: 1,
      },
    };

    return (
      <Modal
        open={this.props.open}
        onClose={this.props.close}
        id="treeview-modal-container"
      >
        <Modal.Content id="modal-content">
          <div id="header">
            <div id="infos-container">
              {this.state.pp
                ? <span id="pp-title">{this.state.pp.name} {this.state.pp.year}</span>
                : Utils.loader()
              }

              {this.state.pp
                ? <div id="users-container">
                  <div id="referent">
                    <UserAvatar account={this.state.pp.owner} />
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td id="deputies-container">
                          <FormattedMessage id="deputies" defaultMessage="DEPUTIES" />
                        </td>
                        <td id="deputies">
                          {this.state.pp.assignedAccounts.map((assignee: User) => {
                            return <UserAvatar key={`deputy${assignee.id}`} account={assignee} />;
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                : Utils.loader()
              }
            </div>
            <div id="top-right">
              <img src={ambitionboost} alt="Ambition Boost logo" />
              <span id="published-date-container">
                <FormattedMessage id="publicationOf" defaultMessage="Publication of" />
                &nbsp;
                {this.state.pp && this.state.pp.publishedDate
                  ? Utils.displayFancyDate(this.state.pp.publishedDate)
                  : '-'
                }
              </span>
            </div>
          </div>
          {this.state.pp
            ? <div id="content-container">
              <div id="tree-container" ref={this.treeContainer}>
                <Tree
                  data={this.setTreeData()}
                  orientation={'vertical'}
                  translate={this.state.translate}
                  scaleExtent={{ min: 0.4, max: 1.8 }}
                  separation={{ siblings: 2, nonSiblings: 2 }}
                  styles={treeviewStyles}
                  zoom={1}
                  onClick={this.handleNodeClick}
                  allowForeignObjects={true}
                  nodeLabelComponent={{
                    render: <CustomNodeText handleNodeClick={this.handleNodeClick} />,
                    foreignObjectWrapper: {
                      textAnchor: 'middle',
                      x: -150,
                      y: -32,
                      width: '300px',
                      height: '50px',
                    },
                  }}
                />
              </div>
              <RightColumn />
            </div>
            : Utils.loader()
          }
        </Modal.Content>
        <Modal.Actions>
          <Button id="button-close" basic={true} onClick={this.props.close}>
            <FormattedMessage
              id="close"
              defaultMessage="Close"
            />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default TreeView;
