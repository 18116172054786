import './chart.scss';

import * as React from 'react';
import { ChartData, ChartDataSets, ChartOptions, ChartPoint, GridLineOptions } from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import { InjectedIntlProps, injectIntl } from 'react-intl';

import Metric, { MetricValueGet } from '../../../../../../../models/metric';
import { ComparisonType } from '../../../../../../../constants/comparisonType';
import { Align } from 'chartjs-plugin-datalabels/types/options';
import UserStore from '../../../../../../../stores/user.store';
import Languages from '../../../../../../../constants/languages';

enum LineType {
  CURRENT,
  TARGET,
  COMPARISON_TOLERANCE,
}

interface IProps extends InjectedIntlProps {
  metric: Metric | undefined;
  isCalledFromModal?: boolean;
  metricsCount: number;
}

interface IStates {
  chartData: ChartData;
}

class Chart extends React.Component<IProps, IStates> {

  private readonly chartStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    pointBorderColor: 'rgba(54, 162, 235, 1)',
    pointBackgroundColor: '#FFFFFF',
    hoverBackgroundColor: '#FFCE56',
    gridLinesColor: '#E5E5E5',
    lineTension: 0,
    fontSizeLarge: 15,

    currentBorderColor: 'rgba(54, 162, 235, 1)',
    currentPointRadius: 3,
    currentBorderWidth: 2,

    targetBorderColor: 'rgba(200, 200, 200, 1)',
    targetPointRadius: 0,
    targetBorderWidth: 4,
    targetBorderDash: [6, 6, 6, 6, 6, 6, 6, 6],

    toleranceBorderColor: 'rgb(235, 148, 54)',
    toleranceBackgroundColor: 'rgba(235, 148, 54, 0.1)',
    tolerancePointRadius: 0,
    toleranceBorderWidth: 0.5,

    labelColor: 'rgba(0,0,0,.87)',
    labelFontSize: 14,
  };

  public constructor(props: IProps) {
    super(props);

    this.state = {
      chartData: {
        datasets: [
          {
            fill: true,
            backgroundColor: this.chartStyle.backgroundColor,
            pointBackgroundColor: this.chartStyle.pointBackgroundColor,
            pointRadius: this.chartStyle.currentPointRadius,
            pointBorderColor: this.chartStyle.pointBorderColor,
            data: [],
            hoverBackgroundColor: [this.chartStyle.hoverBackgroundColor],
            borderColor: [this.chartStyle.currentBorderColor],
            borderWidth: this.chartStyle.currentBorderWidth,
            lineTension: this.chartStyle.lineTension,
            datalabels: {
              color: this.chartStyle.labelColor,
              font: {
                size: this.chartStyle.fontSizeLarge,
              },
              formatter: value => value.y,
              align: (context: Context) => {
                const index = context.dataIndex;
                const data = context.dataset.data;

                let align: Align = 'top';

                if (data && data.length > 0) {
                  const currentData = data[index] as ChartPoint;
                  const currentValue = parseFloat(currentData.y as string);

                  const isFirstValue = index === 0;
                  const isLastValue = index === data.length - 1;

                  let previousValue;
                  if (!isFirstValue) {
                    const previousData: ChartPoint = data[index - 1] as ChartPoint;
                    if (previousData) {
                      previousValue = parseFloat(previousData.y as string);
                    }
                  }

                  let nextValue;
                  if (!isLastValue) {
                    const nextData: ChartPoint = data[index + 1] as ChartPoint;
                    if (nextData) {
                      nextValue = parseFloat(nextData.y as string);
                    }
                  }

                  if (isFirstValue && nextValue !== undefined) {
                    align = currentValue >= nextValue ? -45 : 45;
                  }

                  if (isLastValue && previousValue !== undefined) {
                    align = currentValue >= previousValue ? 225 : 135;
                  }

                  if (previousValue !== undefined && nextValue !== undefined) {
                    if (currentValue >= previousValue && currentValue >= nextValue) {
                      align = 'top';
                    }

                    if (currentValue < previousValue && currentValue < nextValue) {
                      align = 'bottom';
                    }

                    if (currentValue >= previousValue && currentValue < nextValue) {
                      align = 'top';
                    }

                    if (currentValue < previousValue && currentValue >= nextValue) {
                      align = 'top';
                    }
                  }
                }

                return align;
              },
            },
          },
          {
            fill: true,
            backgroundColor: this.chartStyle.backgroundColor,
            pointBackgroundColor: this.chartStyle.pointBackgroundColor,
            pointRadius: this.chartStyle.targetPointRadius,
            data: [],
            borderColor: [this.chartStyle.targetBorderColor],
            borderWidth: this.chartStyle.targetBorderWidth,
            lineTension: this.chartStyle.lineTension,
            borderDash: this.chartStyle.targetBorderDash,
            datalabels: {
              display: false,
            },
          },
        ],
      },
    };
  }

  public componentDidMount() {
    this.setChartData();
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>, snapshot?: any) {
    if (prevProps.metric !== this.props.metric) {
      this.setChartData();
    }
  }

  private getDateStep(): number {
    const MIN_STEP = 1;
    if (!!this.props.metric && this.props.metric.metricValues.length > 1) {
      const dates: number[] = this.props.metric.metricValues.map(mv => new Date(mv.updateDate.toString()).getTime());
      const min = Math.min(...dates);
      const max = Math.max(...dates);
      const period = (max - min) / (1000 * 3600 * 24); // Convert ms to days
      const step = Math.floor(period / 7);
      if (step > MIN_STEP) {
        return step;
      }
    }

    return MIN_STEP;
  }

  private getComparisonTolerances(): { lower: number, upper: number }[] | null {
    if (!!this.props.metric && this.props.metric!.comparisonTolerance !== undefined) {
      const tolerances: { lower: number, upper: number }[] = [];
      this.props.metric.metricValues.forEach((mv) => {
        if (mv.target) {
          const target = parseFloat(mv.target.toString());
          tolerances.push({
            lower: target - (target * this.props.metric!.comparisonTolerance! / 100),
            upper: target + (target * this.props.metric!.comparisonTolerance! / 100),
          });
        }
      });
      return tolerances;
    }

    return null;
  }

  private getMinMaxMetricValue(): { min?: number; max?: number } {
    const comparisonTolerances = this.getComparisonTolerances();
    const allData: number[] = comparisonTolerances
      ? [...comparisonTolerances.map(el => el.lower), ...comparisonTolerances.map(el => el.upper)]
      : [];

    const percentage = 40 / 100;
    if (!!this.props.metric) {
      this.props.metric.metricValues.forEach((metric) => {
        if (!!metric.current || metric.current === 0) {
          allData.push(+metric.current);
        }
        if (!!metric.target || metric.target === 0) {
          allData.push(+metric.target);
        }
      });

      const min = Math.min(...allData);
      const max = Math.max(...allData);
      let margin = (max - min) * percentage;
      if (Math.round(margin) !== 0) {
        margin = Math.round(margin);
      }

      return {
        max: max + margin,
        min: min - margin,
      };
    }

    return {};
  }

  private setPointsColors(): string | string[] {
    if (this.props.metric?.comparisonType !== ComparisonType.NONE) {
      const colors: string[] = [];
      this.props.metric?.metricValues.forEach((mv: MetricValueGet) => {
        const current = mv.current && parseFloat(mv.current.toString());
        const target = parseFloat(mv.target.toString());

        const okColor = '#0ea432';
        const notOkColor = '#9f3a38';

        switch (this.props.metric?.comparisonType) {
          case ComparisonType.UP:
            colors.push(current >= target ? okColor : notOkColor);
            break;
          case ComparisonType.DOWN:
            colors.push(current <= target ? okColor : notOkColor);
            break;
          case ComparisonType.EQUAL:
            const tolerance = target * this.props.metric?.comparisonTolerance / 100;
            colors.push((current >= target - tolerance) && (current <= target + tolerance) ? okColor : notOkColor);
            break;
        }
      });

      return colors;
    }

    return this.chartStyle.pointBorderColor;
  }

  /**
   * Set the chart data for the target line, the current line and the colors of the current values
   */
  public setChartData() {
    const chartData = { ...this.state.chartData };

    const targetData: ChartPoint[] = [];
    const currentData: ChartPoint[] = [];

    if (!!this.props.metric && this.props.metric.metricValues) {
      this.props.metric.metricValues
        .sort((val1, val2) => new Date(val1.updateDate).getTime() - new Date(val2.updateDate).getTime());

      this.props.metric.metricValues
        .forEach((entry) => {
          currentData.push({ y: entry.current!!, x: new Date(entry.updateDate) });
          targetData.push({ y: entry.target!!, x: new Date(entry.updateDate) });
        });
    }

    if (chartData.datasets !== undefined) {
      chartData.datasets[1].data = targetData;
      chartData.datasets[0].data = currentData;
      chartData.datasets[0].pointBorderColor = this.setPointsColors();
    }

    // Comparison tolerance lines
    if (
      this.props.metric?.comparisonType === ComparisonType.EQUAL
      && this.props.metric?.comparisonTolerance
      && this.props.metric.metricValues.length > 0
    ) {
      const comparisonTolerances = this.getComparisonTolerances();
      if (this.props.metric.mostRecentValues.target && comparisonTolerances) {
        const dataset: ChartDataSets = {
          backgroundColor: this.chartStyle.backgroundColor,
          pointBackgroundColor: this.chartStyle.pointBackgroundColor,
          pointRadius: this.chartStyle.tolerancePointRadius,
          borderColor: [this.chartStyle.toleranceBorderColor],
          borderWidth: this.chartStyle.toleranceBorderWidth,
          lineTension: this.chartStyle.lineTension,
          fill: true,
          datalabels: {
            display: false,
          },
        };

        const lowerData: ChartPoint[] = [];
        const upperData: ChartPoint[] = [];

        comparisonTolerances.forEach((tr, index) => {
          const x = new Date(this.props.metric!.metricValues![index].updateDate);
          lowerData.push({ x, y: tr.lower });
          upperData.push({ x, y: tr.upper });
        });

        const lowerLine: ChartDataSets = {
          ...dataset,
          fill: '+1',
          backgroundColor: this.chartStyle.toleranceBackgroundColor,
          data: lowerData,
        };

        const upperLine: ChartDataSets = {
          ...dataset,
          data: upperData,
        };

        if (chartData.datasets![2] && chartData.datasets![3]) {
          chartData.datasets![2] = lowerLine;
          chartData.datasets![3] = upperLine;
        } else {
          chartData.datasets!.push(lowerLine);
          chartData.datasets!.push(upperLine);
        }
      }
    } else {
      chartData.datasets!.splice(2, 2);
    }

    this.setState({ chartData });
  }

  private setLegend(lineType: LineType): JSX.Element | null {
    let boxClass;
    let name;

    switch (lineType) {
      case LineType.CURRENT:
        boxClass = 'current';
        name = this.props.intl.formatMessage({ id: 'current', defaultMessage: 'Current' });
        break;
      case LineType.TARGET:
        boxClass = 'target';
        name = this.props.intl.formatMessage({ id: 'target', defaultMessage: 'Target' });
        break;
      case LineType.COMPARISON_TOLERANCE:
        boxClass = 'comparison-tolerance';
        name = `${this.props.intl.formatMessage({
          id: 'comparison.range',
          defaultMessage: 'Tolerance range',
        })} (±${this.props.metric?.comparisonTolerance}%)`;
        break;
      default:
        break;
    }

    if (boxClass && name) {
      return (
        <div key={`legend-${lineType}`}>
          <div className={`legend-box ${boxClass}`}/>
          <span className="legend-name">
          {name}
        </span>
        </div>
      );
    }

    return null;
  }

  public render() {
    const gridLines: GridLineOptions = {
      color: this.chartStyle.gridLinesColor,
      zeroLineColor: this.chartStyle.gridLinesColor,
    };

    let ticks = {};

    if (!!this.props.isCalledFromModal) {
      ticks = { fontSize: this.chartStyle.fontSizeLarge };
    }

    const options: ChartOptions = {
      scales: {
        xAxes: [{
          gridLines,
          ticks,
          type: 'time',
          time: {
            unit: 'year',
            unitStepSize: this.getDateStep(),
            displayFormats: {
              year: UserStore.getLanguage() === Languages.EN ? 'MMM DD YYYY' : 'DD MMM YYYY',
            },
          },
          distribution: 'series',
        }],
        yAxes: [{
          gridLines,
          ticks: {
            ...ticks,
            ...this.getMinMaxMetricValue(),
          },
        }],
      },
      legend: {
        display: false,
      },
      maintainAspectRatio: false,
    };

    if (!!this.state.chartData.datasets && !!this.state.chartData.datasets[1].data) {
      return (
        <div className="chart-container">
          <div className="legend-container">
            {this.state.chartData.datasets.map((el, index) => this.setLegend(index))}
          </div>
          <div className={`chart-wrapper ${this.props.metricsCount > 1 ? 'not-alone' : ''}`}>
            <Line
              data={this.state.chartData}
              options={options}
              plugins={[ChartDataLabels]}
              datasetKeyProvider={() => btoa(Math.random().toString()).substring(0, 12)}
            />
          </div>
        </div>
      );
    }

    return null;
  }
}

export default injectIntl(Chart);
