import ActivitiesActions from 'src/actions/activities.action';
import BusinessChallengeAPI from 'src/api/businessChallenge.api';
import TypeActivity from 'src/constants/typeActivity';
import BusinessChallenge, { BusinessChallengePost, BusinessChallengePut } from 'src/models/businessChallenge';
import Utils from 'src/utils/utils';

import ActionTypes from '../constants/actionTypes';
import Dispatcher from '../dispatcher';
import HistoryActions from './history.action';
import BusinessChallengeStore from 'src/stores/businessChallenge.store';
import { MetricValueGet } from '../models/metric';
import ILinkRequestRes from '../constants/activityLink';
import { AssignedUsers, UsersAssignation } from '../models/user';
import { AssignationType } from '../constants/account';

export default class BusinessChallengeActions {

  public bc: BusinessChallenge;

  private static async businessChallengeGet(bcId: number, targetPpId?: number, isAfterSave = false): Promise<void> {
    Dispatcher.dispatch({
      isAfterSave,
      businessChallenge: await BusinessChallengeAPI.getBusinessChallengeById(bcId, targetPpId),
      actionType: ActionTypes.BUSINESS_CHALLENGE_GET,
    });
  }

  /**
   * Get Business Challenge from API and emit actions BUSINESS_CHALLENGE_GET
   * @param bcId: number
   * @param targetPpId: number (optional)
   * @return void
   */
  public static emitGetBusinessChallengeById(bcId: number, targetPpId: number): Promise<void> {
    return this.businessChallengeGet(bcId, targetPpId)
      .then(() => {
        Dispatcher.dispatch({
          idActivity: bcId,
          typeActivity: TypeActivity.BUSINESS_CHALLENGE,
          actionType: ActionTypes.ACTIVITY_SELECTED,
        });
      })
      .catch((error) => {
        Dispatcher.dispatch({
          error,
          actionType: ActionTypes.ERROR_SET,
        });
      });
  }

  /**
 * Open new pi modal
 * @param {boolean} isOpen
 * @return {void}
 */
  public static emitOpenNewPiModal(isOpen: boolean): void {
    Dispatcher.dispatch({
      isOpen,
      actionType: ActionTypes.SET_OPEN_NEW_PI_MODAL,
    });
  }

  /**
* Open import pi modal
* @param {boolean} isOpen
* @return {void}
*/
  public static emitOpenImportPiModal(isOpen: boolean): void {
    Dispatcher.dispatch({
      isOpen,
      actionType: ActionTypes.SET_OPEN_IMPORT_PI_MODAL,
    });
  }

  /**
   * Get Business Challenge from API and emit actions BUSINESS_CHALLENGE_GET
   * @param bcId: number
   * @param targetPpId: number
   * @return void
   */
  public static emitGetBusinessChallengeByIdAfterSaved(bcId: number, targetPpId: number): void {
    this.businessChallengeGet(bcId, targetPpId, true)
      .then(() => {
        Dispatcher.dispatch({
          idActivity: bcId,
          typeActivity: TypeActivity.BUSINESS_CHALLENGE,
          actionType: ActionTypes.ACTIVITY_SELECTED,
        });
      });
  }

  /**
   * Grant publication for bc id
   * @param bcId: number
   * @return void
   */
  public static emitGrantPublication(bcId: number, name: string): Promise<any> {
    const bc = BusinessChallengeStore.getBusinessChallenge();
    return BusinessChallengeAPI.postPublishBusinessChallengeById(bcId, name)
      .then(() => HistoryActions.emitGetHistoryVersions('business-challenges', bcId))
      .then(() => this.businessChallengeGet(bcId, bc ? bc.targetPpId : undefined))
      .then(() => {
        Dispatcher.dispatch({
          actionType: ActionTypes.ACTIVITY_PUBLISHED,
        });
      });
  }

  /**
   * Get Business Challenge from API and emit actions BUSINESS_CHALLENGE_GET
   * @param bcId : number
   * @return void
   */
  public static emitGetBusinessChallengePisById(bcId: number): void {
    BusinessChallengeAPI.getBusinessChallengePiCodes(bcId)
      .then((businessChallenge: BusinessChallenge) => {
        Dispatcher.dispatch({
          businessChallenge,
          actionType: ActionTypes.BUSINESS_CHALLENGE_GET_PIS,
        });
      });
  }

  /**
   * Delete Business Challenge from API and emit actions BUSINESS_CHALLENGE_DELETED
   * @param bcId : number
   * @return void
   */
  public static emitRemoveBusinessChallenge(bcId: number): Promise<any> {
    return BusinessChallengeAPI.deleteBusinessChallengeById(bcId)
      .then(() => {
        Dispatcher.dispatch({
          actionType: ActionTypes.BUSINESS_CHALLENGE_DELETED,
        });
      });
  }

  /**
   * Create Business Challenge and emit actions BUSINESS_CHALLENGE_SAVED
   * @return void
   * @param ppId
   * @param bc
   */
  public static emitCreateBusinessChallenge(ppId: number, bc: BusinessChallengePost): Promise<number> {
    if (bc.code) {
      bc.code = parseInt(bc.code.toString(), 10);
    }

    return BusinessChallengeAPI.postBusinessChallenge(ppId, bc)
      .then(async (newBc: BusinessChallenge) => {
        const newBcId = newBc.id;
        const promises: Promise<any>[] = [];

        if (bc.metrics && bc.metrics.length !== 0) {
          promises.push(BusinessChallengeAPI.postBusinessChallengeMetrics(newBcId, bc.metrics));
        }
        if (bc.milestones && bc.milestones.length !== 0) {
          promises.push(BusinessChallengeAPI.postBusinessChallengeMilestones(newBcId, bc.milestones));
        }

        const assignedUsers = new AssignedUsers();
        if (bc.assignedAccounts && bc.assignedAccounts.length > 0) {
          const leadersEmails = await BusinessChallengeAPI.postBusinessChallengeAssignees(
            newBcId, new UsersAssignation(bc.assignedAccounts, AssignationType.BC_LEADER));
          if (leadersEmails) assignedUsers.addUsersIfNotExist(leadersEmails);
        }
        if (bc.informedAccounts && bc.informedAccounts.length > 0) {
          const informedEmails = await BusinessChallengeAPI.postBusinessChallengeAssignees(
            newBcId, new UsersAssignation(bc.informedAccounts, AssignationType.BC_DEPUTY));
          if (informedEmails) assignedUsers.addUsersIfNotExist(informedEmails);
        }

        return Promise.all(promises).then(async () => {
          assignedUsers.dispatchAssignedUsers();
          await ActivitiesActions.emitGetActivities();
          return newBcId;
        });
      });
  }

  /**
   * Close a bc from API and emit actions BUSINESS_CHALLENGE_GET
   * @param bc
   * @param comment
   */
  public static emitToggleCloseBusinessChallenge(bc: BusinessChallenge, comment?: string) {
    const bcId = bc.id;
    return BusinessChallengeAPI.toggleCloseBc(bcId, comment).then(async (bc) => {
      await ActivitiesActions.emitGetActivities();
      this.emitGetBusinessChallengeById(bcId, bc.targetPpId)
        .catch(() => window.location.href = '/');
    });
  }

  /**
   * Get Business Challenge from API and emit actions BUSINESS_CHALLENGE_GET
   * @param bc: BusinessChallenge
   * @param oldBc: BusinessChallenge
   * @returns Promise<void>
   */
  public static async emitSaveBusinessChallenge(bc: BusinessChallenge, oldBc: BusinessChallenge): Promise<void> {
    const bcId = oldBc.id;
    const promises: Promise<any>[] = [];

    // Compare BC with its previous version, to prevent an unnecessary update request
    const bcToCompare = { ...bc };
    const oldBcToCompare = { ...oldBc };
    delete bcToCompare.assignedAccounts;
    delete bcToCompare.deputies;
    delete bcToCompare.informedAccounts;
    delete bcToCompare.performancePlan.businessChallenges;
    delete oldBcToCompare.assignedAccounts;
    delete oldBcToCompare.deputies;
    delete oldBcToCompare.informedAccounts;

    if (JSON.stringify(bcToCompare) !== JSON.stringify(oldBcToCompare)) {

      const newBc = bc as BusinessChallengePut;
      if (bc.status) {
        newBc.status = bc.status;
      }
      if (bc.keywords) {
        newBc.keywordsId = bc.keywords.id;
      }
      if (bc.pillar) {
        newBc.pillar = bc.pillar;
      }
      if (typeof bc.code !== 'number') {
        newBc.code = parseInt(bc.code, 10);
      }

      promises.push(BusinessChallengeAPI.putBusinessChallengeById(bcId, newBc));
    }

    //region METRICS
    bc.metrics = bc.metrics.filter(me => !me.isFromLinked);
    const metricsFull = Utils.filterChanges(oldBc.metrics, bc.metrics);

    const oldMetricsWithoutValuesToEdit = oldBc.metrics
      .filter(oldMe => !metricsFull.remove.some(metric => oldMe.id === metric.id))
      .map((metric) => {
        const me = { ...metric };
        delete me.metricValues;
        return me;
      });

    const metricsWithoutValuesToEdit = bc.metrics
      .filter(me => me.id && !metricsFull.remove.some(metric => me.id === metric.id))
      .map((metric) => {
        const me = { ...metric };
        delete me.metricValues;
        return me;
      });

    const oldMetricValues : MetricValueGet[] = [];
    oldBc.metrics.forEach((me) => {
      if (!metricsFull.remove.some(metric => me.id === metric.id)) {
        oldMetricValues.push(...me.metricValues);
      }
    });
    const bcMetricValues : MetricValueGet[] = [];
    bc.metrics.forEach((me) => {
      if (me.id && !metricsFull.remove.some(metric => me.id === metric.id)) {
        bcMetricValues.push(...me.metricValues);
      }
    });

    const editedMetrics = Utils.filterChanges(oldMetricsWithoutValuesToEdit, metricsWithoutValuesToEdit).edit;
    const filteredMetricValues = Utils.filterChanges(oldMetricValues, bcMetricValues);

    if (metricsFull.create.length !== 0) {
      promises.push(BusinessChallengeAPI.postBusinessChallengeMetrics(bcId, metricsFull.create));
    }
    if (editedMetrics.length !== 0) {
      promises.push(BusinessChallengeAPI.putBusinessChallengeMetrics(bcId, editedMetrics));
    }
    if (metricsFull.remove.length !== 0) {
      promises.push(
        BusinessChallengeAPI.deleteBusinessChallengeMetrics(bcId, metricsFull.remove.map(metric => metric.id)));
    }

    if (filteredMetricValues.create.length !== 0) {
      promises.push(BusinessChallengeAPI.postBusinessChallengeMetricValues(bcId, filteredMetricValues.create));
    }
    if (filteredMetricValues.edit.length !== 0) {
      promises.push(BusinessChallengeAPI.putBusinessChallengeMetricValues(bcId, filteredMetricValues.edit));
    }

    if (filteredMetricValues.remove.length !== 0) {
      promises.push(BusinessChallengeAPI.deleteBusinessChallengeMetricValues(
        bcId, filteredMetricValues.remove.map(metricValue => metricValue.id)));
    }

    const linkMetrics = Utils.filterChanges(oldBc.linkedBcLinkMetrics, bc.linkedBcLinkMetrics);
    if (linkMetrics.create.length !== 0) {
      promises.push(BusinessChallengeAPI.createBusinessChallengeLinkMetrics(bcId, linkMetrics.create.map(m => m.id)));
    }
    if (linkMetrics.remove.length !== 0) {
      promises.push(BusinessChallengeAPI.removeBusinessChallengeLinkMetrics(bcId, linkMetrics.remove.map(m => m.id)));
    }
    //endregion

    //region MILESTONES
    const oldOriginalMilestones = oldBc.milestones.filter(mi => !mi.piCode);
    const originalMilestones = bc.milestones.filter(mi => !mi.piCode);

    const oldLinkedMilestones = oldBc.milestones.filter(mi => !!mi.piCode);
    const linkedMilestones = bc.milestones.filter(mi => !!mi.piCode);

    const filteredOriginalMilestones = Utils.filterChanges(oldOriginalMilestones, originalMilestones);
    if (filteredOriginalMilestones.create.length !== 0) {
      promises.push(BusinessChallengeAPI.postBusinessChallengeMilestones(bcId, filteredOriginalMilestones.create));
    }
    if (filteredOriginalMilestones.edit.length !== 0) {
      promises.push(BusinessChallengeAPI.putBusinessChallengeMilestones(bcId, filteredOriginalMilestones.edit));
    }
    if (filteredOriginalMilestones.remove.length !== 0) {
      promises.push(BusinessChallengeAPI
        .deleteBusinessChallengeMilestones(bcId, filteredOriginalMilestones.remove.map(mi => mi.id)));
    }

    const filteredLinkedMilestones = Utils.filterChanges(oldLinkedMilestones, linkedMilestones);

    //region create milestones links
    let milestonesToLink: { id: number }[] = [];
    if (filteredLinkedMilestones.create.length !== 0) {
      milestonesToLink =
        milestonesToLink.concat(filteredLinkedMilestones.create.map(m => ({ id: m.id })));
    }

    if (milestonesToLink.length > 0) {
      promises.push(BusinessChallengeAPI.createBusinessChallengeLinkMilestones(bcId, milestonesToLink));
    }
    //endregion

    //region remove milestones links
    let milestonesToUnLink: { id: number }[] = [];
    if (filteredLinkedMilestones.remove.length !== 0) {
      milestonesToUnLink =
        milestonesToUnLink.concat(filteredLinkedMilestones.remove.map(m => ({ id: m.id })));
    }

    if (milestonesToUnLink.length > 0) {
      promises.push(BusinessChallengeAPI.removeBusinessChallengeLinkMilestones(bcId, milestonesToUnLink));
    }
    //endregion
    //endregion

    return Promise.all(promises).then(async () => {
      Dispatcher.dispatch({ actionType: ActionTypes.BUSINESS_CHALLENGE_SAVED });

      await ActivitiesActions.emitGetActivities();
      this.emitGetBusinessChallengeById(bcId, bc.targetPpId)
        .catch(() => window.location.href = '/');
    });
  }

  /**
   * Change Business Challenge in Store
   * @return void
   */
  public static emitChangeBusinessChallenge(idAttribute: string, value: any, text?: any): void {
    Dispatcher.dispatch({
      idAttribute,
      value,
      text,
      actionType: ActionTypes.BUSINESS_CHALLENGE_CHANGE,
    });
  }

  /**
   * Rollback Business Challenge in Store to previous data (after cancelling edition)
   * @return void
   */
  public static emitRollbackBusinessChallenge(): void {
    Dispatcher.dispatch({
      actionType: ActionTypes.BUSINESS_CHALLENGE_ROLLBACK,
    });
  }

  /**
   * Get keywords for business challenges
   */
  public static emitGetKeywords() {
    BusinessChallengeAPI.getKeywords()
      .then((keywords) => {
        Dispatcher.dispatch({
          keywords,
          actionType: ActionTypes.BUSINESS_CHALLENGE_KEYWORDS,
        });
      });
  }

  /**
   * Get functions for business challenges
   */
  public static emitGetFunctions() {
    BusinessChallengeAPI.getFunctions()
      .then((functions) => {
        Dispatcher.dispatch({
          functions,
          actionType: ActionTypes.BUSINESS_CHALLENGE_FUNCTION,
        });
      });
  }

  /**
   * Put request link for bc id
   * @param bcId : number
   * @param ppId
   * @return void
   */
  public static emitRequestLink(bcId: number, ppId: number): Promise<ILinkRequestRes> {
    return BusinessChallengeAPI.requestLinkBcToPp(bcId, ppId);
  }

  /**
   * Emit error to store
   * @param error string
   */
  public static emitError(error: string): void {
    BusinessChallengeStore.setError(error);
  }

    /**
   * clear error value to store
   * @param error string
   */
  public static clearError(error: string): void {
    BusinessChallengeStore.clearError(error);
  }

  /**
   * Reset errors value store
   */
  public static resetErrors(): void {
    BusinessChallengeStore.resetError();
  }
}
