import './bcPiDetails.scss';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Accordion, Dropdown, Form, Icon, Segment } from 'semantic-ui-react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import Utils from 'src/utils/utils';
import BusinessChallenge, { BusinessChallengeDetails } from 'src/models/businessChallenge';
import ModeTypes from 'src/constants/modeTypes';
import PerformanceInitiative, { PerformanceInitiativeDetails } from 'src/models/performanceInitiative';
import LinkLabel, { LinkLabelMode } from 'src/components/common/linkLabel/linkLabel';
import PublicationLabel from 'src/components/common/publicationLabel/publicationLabel';
import { PiTabs } from '../../../../../contentPI/contentPI';
import TypeActivity from 'src/constants/typeActivity';
import BusinessChallengeAPI from 'src/api/businessChallenge.api';
import PerformanceInitiativeAPI from 'src/api/performanceInitiative.api';
import Colors from 'src/utils/colors';
import Action from 'src/models/action';
import { Pie } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import CustomScrollBars from 'src/components/common/customScrollBars/customScrollBars';
import EditorGenerator from '../../../../../ckEditorGenerator';
import SegmentEditButtons from '../../../../segmentEditButtons/segmentEditButtons';
import { RightsOnBC, RightsOnPI } from 'src/models/rights';
import BusinessChallengeActions from 'src/actions/businessChallenge.action';
import ActionTypes from 'src/constants/actionTypes';
import SegmentsStore from 'src/stores/segments.store';
import NotificationActions from 'src/actions/notification-actions';
import { ToastType } from 'src/components/common/toast/toast';
import RightsStore from 'src/stores/rights.store';
import ActionStore from 'src/stores/action.store';
import { SegmentType } from '../../../../../../../../models/segmentsMode';
import ActivitiesActions from 'src/actions/activities.action';
import LastUpdateHighlight from '../lastUpdatedInformations/lastUpdateHighlight';
import { EditorErrors } from '../../../../../../../../models/bcPiFields';
import GuideStore from '../../../../../../../../stores/guide.store';
import GuideActions from '../../../../../../../../actions/guide.action';
import { VIEWS } from '../../../../../../../../constants/guideConstants';
import { OverviewPanelFields } from '../../../../../../../../constants/bcPiFields';
import { ErrType } from '../../../../../../../../constants/errors/typeError';
import ToggleHideElement from '../../../../../toggleHideElement/toggleHideElement';

enum TabsIndex {
  STATUS = 0,
  OBJECTIVES = 1,
  BUDGET = 2,
  SUCCESS_FACTORS = 3,
  ACTIONS_PI = 4,
}

interface IRouteProps {
  id: string;
  ppId: string;
}

interface IState {
  data: BusinessChallengeDetails | PerformanceInitiativeDetails | undefined;
  oldData: BusinessChallengeDetails | PerformanceInitiativeDetails | undefined;
  mode: ModeTypes;
  type: TypeActivity;
  activeIndex: number[];
  isLoadingData: boolean;
  isEditorOnError: EditorErrors;
  rightsOnBc: RightsOnBC;
  rightsOnPi: RightsOnPI;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  typeActivity: TypeActivity | undefined;
  activity: PerformanceInitiative | BusinessChallenge | undefined;
  contentPanelHeight: number;
  setPanelErrStatus(isOnErr: boolean): void;
}

class BcPiDetails extends React.Component<IProps & InjectedIntlProps, IState> {

  private critical = this.props.intl.formatMessage(
    {
      id: 'critical',
      defaultMessage: 'Critical',
    });

  private atRisk = this.props.intl.formatMessage(
    {
      id: 'atRisk',
      defaultMessage: 'At Risk',
    });

  private confident = this.props.intl.formatMessage(
    {
      id: 'confident',
      defaultMessage: 'Confident',
    });

  private tagOptions = [
    {
      text: this.critical,
      value: 1,
      label: { color: 'red', circular: true },
    }, {
      text: this.atRisk,
      value: 2,
      label: { color: 'orange', circular: true },
    }, {
      text: this.confident,
      value: 3,
      label: { color: 'green', circular: true },
    },
  ];

  private criticalActions = this.props.intl.formatMessage(
    {
      id: 'at.criticalActions',
      defaultMessage: 'Critical Actions',
    });

  private atRiskActions = this.props.intl.formatMessage(
    {
      id: 'at.atRiskActions',
      defaultMessage: 'At Risk Actions',
    });

  private confidentActions = this.props.intl.formatMessage(
    {
      id: 'at.confidentActions',
      defaultMessage: 'Confident Actions',
    });

  private closedActions = this.props.intl.formatMessage(
    {
      id: 'at.closedActions',
      defaultMessage: 'Closed Actions',
    });

  private noActions = this.props.intl.formatMessage(
    {
      id: 'at.noActions',
      defaultMessage: 'No Actions',
    });

  private pieChartOptions: ChartOptions = {
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    legend: {
      display: false,
    },
    animation: {
      duration: 0,
    },
  };

  private defaultPieChartData = {
    labels: [this.noActions],
    datasets: [
      {
        data: [1],
        backgroundColor: [Colors.grey],
        datalabels: { display: false },
      },
    ],
  };

  constructor(props: IProps & InjectedIntlProps) {
    super(props);

    const activityType = this.props.typeActivity ?? Utils.getActivityByRoute(this.props.history.location.pathname);

    this.state = {
      data: undefined,
      oldData: undefined,
      mode: SegmentsStore.getSegmentModeById(this.getSegmentType(activityType)),
      type: activityType,
      activeIndex: [TabsIndex.STATUS, TabsIndex.OBJECTIVES, TabsIndex.ACTIONS_PI],
      isLoadingData: true,
      isEditorOnError: new EditorErrors(),
      rightsOnBc: RightsStore.getRightsOnBC(),
      rightsOnPi: RightsStore.getRightsOnPI(),
    };
  }

  componentDidMount() {
    this.getData();
    LastUpdateHighlight.highlightParam();
    SegmentsStore.addListener(ActionTypes.RESET_SEGMENTS_MODES.toString(), this.resetSegment);
    RightsStore.addListener(ActionTypes.SET_BC_RIGHTS.toString(), this.setRightsOnBc);
    RightsStore.addListener(ActionTypes.SET_PI_RIGHTS.toString(), this.setRightsOnPI);
    ActionStore.addListener(ActionTypes.SET_CREATED_ACTION.toString(), this.addAction);
    this.setCurrentView();
  }

  componentDidUpdate(prevProps: Readonly<IProps & ReactIntl.InjectedIntlProps>, prevState: Readonly<IState>,
                     snapshot?: any) {
    this.setCurrentView();
    if (prevProps.activity !== this.props.activity) {
      this.getData();
    }
  }

  componentWillUnmount() {
    SegmentsStore.removeListener(ActionTypes.RESET_SEGMENTS_MODES.toString(), this.resetSegment);
    RightsStore.removeListener(ActionTypes.SET_BC_RIGHTS.toString(), this.setRightsOnBc);
    RightsStore.removeListener(ActionTypes.SET_PI_RIGHTS.toString(), this.setRightsOnPI);
    ActionStore.removeListener(ActionTypes.SET_CREATED_ACTION.toString(), this.addAction);
  }

  private setRightsOnBc = () => {
    this.setState({
      rightsOnBc: RightsStore.getRightsOnBC(),
    });
  }

  private setRightsOnPI = () => {
    this.setState({
      rightsOnPi: RightsStore.getRightsOnPI(),
    });
  }

  private setCurrentView = () => {
    if (Utils.isOnEditMode(this.state.mode) && GuideStore.shouldRunGuide(VIEWS.COCKPIT_VIEW_ON_EDIT)) {
      GuideActions.emitUpdateCurrentView(VIEWS.COCKPIT_VIEW_ON_EDIT);
    }
  }

  private getSegmentType = (activityType: TypeActivity = this.state.type): SegmentType => {
    switch (activityType) {
      case TypeActivity.BUSINESS_CHALLENGE:
        return SegmentType.BC_DETAILS;
      case TypeActivity.PERFORMANCE_INITIATIVE:
      default:
        return SegmentType.PI_DETAILS;
    }
  }

  private addAction = () => {
    const newData = { ...this.state.data };
    const newAction = ActionStore.getCreatedAction();
    (newData as PerformanceInitiativeDetails).actions.push(newAction);
    this.setState({
      data: newData as PerformanceInitiativeDetails,
    });
  }

  private resetSegment = () => {
    this.setState({
      mode: SegmentsStore.getSegmentModeById(this.getSegmentType()),
    });
  }

  private getData = async () => {
    const id = this.props.activity ? this.props.activity.id : parseInt(this.props.match.params.id, 10);
    switch (this.state.type) {
      case TypeActivity.BUSINESS_CHALLENGE:
        const bc = await BusinessChallengeAPI.getBusinessChallengeDetailsById(id);
        this.setState({
          data: bc,
          oldData: bc,
          isLoadingData: false,
        });
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        const pi = await PerformanceInitiativeAPI
          .getPerformanceInitiativeDetailsById(id);
        this.setState({
          data: pi,
          oldData: pi,
          isLoadingData: false,
        });
        break;
    }
  }

  private changeMode = (mode: ModeTypes) => {
    this.setState({ mode });
  }

  public getElement(data: string, idElement: string): JSX.Element | undefined {
    if (Utils.isOnEditOrCancelMode(this.state.mode)) {
      return (
        <Form key={idElement}>
          <EditorGenerator
            data={data}
            onChangeAction={this.changeFormValue}
            onErrorAction={this.handleErrorOnEditor}
            idElement={idElement}
          />
        </Form>
      );
    }

    if (Utils.isOnViewOrDeletionMode(this.state.mode)) {
      return (
        <>
          <pre className="layout-text" key={idElement}>{Utils.replaceActivityMentionToJsx(data, this)}</pre>
          <CustomScrollBars className="content-element-scrollbar">
            <div key={idElement} className="formatted-text">
              {Utils.replaceActivityMentionToJsx(data, this)}
            </div>
          </CustomScrollBars>
        </>
      );
    }
  }

  private handleErrorOnEditor = (idElement: OverviewPanelFields, isFieldOnError: boolean, callback: Function) => {
    const isEditorOnError = { ...this.state.isEditorOnError };
    isEditorOnError[idElement] = isFieldOnError;
    this.props.setPanelErrStatus(Object.values(isEditorOnError).includes(true));
    this.setState({ isEditorOnError }, () => {
      callback();
    });
  };

  public changeFormValue = (idElement: string, value: string): void => {
    this.setState((state) => {
      return {
        ...state,
        data: {
          ...(state.data as BusinessChallengeDetails | PerformanceInitiativeDetails),
          [idElement]: value,
        },
      };
    });
  }

  private onSave = async () => {
    if (JSON.stringify(this.state.data) === JSON.stringify(this.state.oldData)) return;
    const id = this.props.activity ? this.props.activity.id : parseInt(this.props.match.params.id, 10);
    let data;
    let oldData;
    try {
      switch (this.state.type) {
        case TypeActivity.BUSINESS_CHALLENGE:
          data = oldData = await BusinessChallengeAPI
          .putBusinessChallengeDetails(id, this.state.data as BusinessChallengeDetails);
          this.showNotificationToast(TypeActivity.BUSINESS_CHALLENGE, true);
          break;
        case TypeActivity.PERFORMANCE_INITIATIVE:
          data = oldData = await PerformanceInitiativeAPI
          .putPerformanceInitiativeDetails(id, this.state.data as PerformanceInitiativeDetails);
          this.showNotificationToast(TypeActivity.PERFORMANCE_INITIATIVE, true);
          break;
      }
      this.updateActivities();
      this.setState({
        data,
        oldData,
        mode: ModeTypes.MODE_VIEW,
      });
    } catch (error) {
      this.showNotificationToast(null, false);
    }
  }

  /**
   * Check if status has change to update activities in menu
   */
  private updateActivities = () => {
    if (this.state.data?.status !== this.state.oldData?.status) {
      ActivitiesActions.emitGetActivities();
    }
  }

  private showNotificationToast = (type: TypeActivity | null, success: boolean) => {
    if (!success) {
      const title = <FormattedMessage id="errorOccurred" defaultMessage="Error"/>;
      const message: JSX.Element = (
        <FormattedMessage id="errorOccurredMessage" defaultMessage="An error occurred, please try again later"/>
      );
      return NotificationActions.toast(title, message, ToastType.ERROR);
    }
    let message;
    switch (type) {
      case TypeActivity.BUSINESS_CHALLENGE:
        message = (
          <FormattedMessage
            id="successBCSave"
            defaultMessage="The Business Challenge has been successfully saved"
          />
        );
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        message = (
          <FormattedMessage
            id="successPISave"
            defaultMessage="The Performance Initiative has been successfully saved"
          />
        );
        break;
    }

    NotificationActions.toast(
      <FormattedMessage id="saved" defaultMessage="Saved!"/>,
      message,
      ToastType.SUCCESS,
    );
  }

  private onCancel = () => {
    const oldData = { ...this.state.oldData } as BusinessChallengeDetails | PerformanceInitiativeDetails;
    this.setState({ data: oldData, isEditorOnError: new EditorErrors() }, () => this.props.setPanelErrStatus(false));
  }

  private onEdit = () => {
    this.setState({ activeIndex: [
      TabsIndex.STATUS,
      TabsIndex.OBJECTIVES, TabsIndex.BUDGET,
      TabsIndex.SUCCESS_FACTORS, TabsIndex.ACTIONS_PI,
    ] });
  }

  private handleBudgetHiddenChange = () => {
    let activeIndex = this.state.activeIndex;
    const index = activeIndex.indexOf(TabsIndex.BUDGET);
    if (index > -1) {
      activeIndex.splice(index, 1);
    } else {
      activeIndex = [...activeIndex, TabsIndex.BUDGET];
    }

    this.setState(prevState => ({
      ...prevState,
      activeIndex,
      data: {
        ...prevState.data,
        isBudgetHidden: !prevState.data?.isBudgetHidden,
      } as BusinessChallengeDetails | PerformanceInitiativeDetails,
    }));
  };

  /**
   * Status edit
   * @param {any} event
   * @param {any} data
   */
  public changeStatus = (event: any, data: any) => {
    this.setState((state) => {
      return {
        ...state,
        data: {
          ...(state.data as BusinessChallengeDetails | PerformanceInitiativeDetails),
          status: data.value,
        },
      };
    });
  };

  private renderStatus = () => {
    return this.state.data && (
      <div>
        <div
          className="status-badge"
          style={{ backgroundColor: Utils.getColorStatus(this.state.data.status, this.state.data.isClosed) }}
        >
          {Utils.isOnViewMode(this.state.mode) && <span>
            {Utils.getStatusName(this.state.data.status, this.state.data.isClosed)}
          </span>}
          {Utils.isOnEditMode(this.state.mode) &&
            <>
              {!this.state.data.isClosed &&
                <Dropdown trigger={Utils.getStatusName(this.state.data.status, this.state.data.isClosed)}>
                  <Dropdown.Menu>
                    <Dropdown.Menu scrolling={true}>
                      {this.tagOptions.map(option =>
                        <Dropdown.Item key={option.value} {...option} onClick={this.changeStatus} />)}
                    </Dropdown.Menu>
                  </Dropdown.Menu>
                </Dropdown>
              }
            </>
          }
        </div>
      </div>
    );
  }

  private renderPis = () => {
    if (!this.state.data) {
      return;
    }
    let pis = (this.state.data as BusinessChallengeDetails).performanceInitiatives;
    const linkedPis = (this.state.data as BusinessChallengeDetails).linkedPis;
    if (linkedPis) {
      pis = pis.concat(linkedPis);
    }
    return (
      <div>
        {this.state.rightsOnBc && this.state.rightsOnBc.canEdit()
          && this.isSameActivityRoute() && <FormattedMessage id="pISyntheticViewBc.newPi" defaultMessage="New PI">
            {msg =>
              <Dropdown
                text={msg.toString()}
                icon="add circle"
                floating={true}
                labeled={true}
                button={true}
                direction="left"
                className="icon"
                id="bc-button-group"
                disabled={Utils.isOnEditMode(this.state.mode)}
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => BusinessChallengeActions.emitOpenNewPiModal(true)}
                  >
                    <Icon name="add" />
                    <FormattedMessage id="pISyntheticViewBc.addPi" defaultMessage="Create a PI" />
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => BusinessChallengeActions.emitOpenImportPiModal(true)}
                  >
                    <Icon name="history" />
                    <FormattedMessage id="pISyntheticViewBc.importPi" defaultMessage="Import a PI" />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            }
          </FormattedMessage>
        }
        <ul className="pis-list">
          {pis.map((pi: PerformanceInitiative) => {
            return (
              <div key={`pi${pi.id}`} className="pi">
                {Utils.setBadge(pi.status, pi.isClosed)}
                <span className="pi-name">
                  <span
                    onClick={() =>
                      this.props.history.push(`/activities-board/${this.props.match.params.ppId}/performance-initiative/${pi.id}/${PiTabs.COCKPIT}`)}
                  >
                    {pi.isLinked
                      ? <LinkLabel
                        mode={LinkLabelMode.SMALL}
                        pp={pi.businessChallenge.performancePlan}
                        bc={pi.businessChallenge}
                      />
                      : <span>PI{Utils.leadingZero(pi.code)} - </span>
                    }
                    {pi.name}
                  </span>
                  <PublicationLabel publishedDate={pi.publishedDate} />
                </span>
              </div>
            );
          })}
        </ul>
      </div>
    );
  }

  private renderActions = () => {
    const defaultPieChartData = JSON.parse(JSON.stringify(this.defaultPieChartData));
    let formattedPieChartData = defaultPieChartData;
    if (this.state.data && !!(this.state.data as PerformanceInitiativeDetails).actions
      && (this.state.data as PerformanceInitiativeDetails).actions.length > 0) {
      const pieChartData = { ...defaultPieChartData };
      let nbCritical = 0;
      let nbAtRisk = 0;
      let nbConfident = 0;
      let nbClosed = 0;

      (this.state.data as PerformanceInitiativeDetails).actions.forEach((action: Action) => {
        if (!action.isClosed) {
          switch (action.status) {
            case 1:
              nbCritical += 1;
              break;
            case 2:
              nbAtRisk += 1;
              break;
            case 3:
              nbConfident += 1;
              break;
          }
        } else {
          nbClosed += 1;
        }
      });

      pieChartData.labels = [this.criticalActions, this.atRiskActions, this.confidentActions, this.closedActions];
      pieChartData.datasets[0].data = [nbCritical, nbAtRisk, nbConfident, nbClosed];
      pieChartData.datasets[0].backgroundColor = [Colors.critical, Colors.atRisk, Colors.confident, Colors.closed];
      formattedPieChartData = pieChartData;
    }

    return (
      <div>
        <div id="actions-overview-element">
          <div id="action-element">
            <Pie data={formattedPieChartData} options={this.pieChartOptions} height={100} width={100} />
          </div>
          <div id="legend">
            {formattedPieChartData.labels.map((label: string, index: number) => {
              const style = {
                backgroundColor: formattedPieChartData.datasets[0].backgroundColor[index],
              };
              return (
                <div key={`legend${index}`}>
                  <span className="legend-badge" style={style} />
                  {formattedPieChartData.labels.length > 1
                    ? `${formattedPieChartData.datasets[0].data[index]} ${label}`
                    : label
                  }
                </div>
              );
            })}

            <span hidden={true}>
              <FormattedMessage id="at.criticalActions" defaultMessage="Critical Actions" />
              <FormattedMessage id="at.atRiskActions" defaultMessage="At Risk Actions" />
              <FormattedMessage id="at.confidentActions" defaultMessage="Confident Actions" />
              <FormattedMessage id="at.noActions" defaultMessage="No Actions" />
            </span>
          </div>
        </div>
      </div>
    );
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    this.setState(prevState => ({
      activeIndex: !prevState.activeIndex.includes(index) ?
        [...prevState.activeIndex, index] : prevState.activeIndex.filter(idx => idx !== index),
    }));
  }

  private isSameActivityRoute = () => {
    if (!this.state.data) return false;
    return this.state.data.id === +this.props.match.params.id;
  }

  private getCanEdit = () => {
    switch (this.props.typeActivity) {
      case TypeActivity.BUSINESS_CHALLENGE:
        return this.state.rightsOnBc.canEdit();
      case TypeActivity.PERFORMANCE_INITIATIVE:
        return this.state.rightsOnPi.canEdit();
    }
  }

  private isFieldOnErr = (field: OverviewPanelFields) => {
    return this.state.isEditorOnError[field] && Utils.isOnEditMode(this.state.mode);
  }

  private getFieldTitle = (field: OverviewPanelFields) => {
    let title;
    const errIcon = this.isFieldOnErr(field) && Utils.getErrorMessageWithPopup(ErrType.TEXT_FIELDS);

    switch (field) {
      case OverviewPanelFields.STATUS:
        title = <FormattedMessage id="status" defaultMessage="Status" />;
        break;
      case OverviewPanelFields.OBJECTIVES:
        title = <FormattedMessage id="objectives" defaultMessage="Objectives" />;
        break;
      case OverviewPanelFields.SUCCESS_FACTORS:
        title = <FormattedMessage id="successFactorsRisks" defaultMessage="Success factors and risks" />;
        break;
      case OverviewPanelFields.BUDGET:
        title = <FormattedMessage id="budget" defaultMessage="Budget" />;
        break;
    }
    return (
      <div className={`title-message ${this.isFieldOnErr(field) ? 'error' : ''}`}>
        <Icon name="dropdown" />
        {title}
        {errIcon}
      </div>
    );
  }

  public render() {
    return (
      <div className="bc-pi-details" style={{ height: `${this.props.contentPanelHeight}px` }}>
        {this.isSameActivityRoute() && this.getCanEdit()
            && <SegmentEditButtons
              mode={this.state.mode}
              segmentType={this.getSegmentType()}
              onSave={this.onSave}
              onEdit={this.onEdit}
              onCancel={this.onCancel}
              changeMode={this.changeMode}
              alternateStyling={true}
              alwaysShow={true}
              isSaveDisabled={Object.values(this.state.isEditorOnError).includes(true)}
            />
          }
        <CustomScrollBars>
          {this.state.isLoadingData && Utils.loader()}
          {<Segment
              id={LastUpdateHighlight.getSegmentId(Utils.isActivityBc(this.state.type) ?
              SegmentType.BC_STATUS : SegmentType.PI_STATUS)}
          >
            <Accordion id="details-list">
              <Accordion.Title
                active={this.state.activeIndex.includes(TabsIndex.STATUS)}
                index={TabsIndex.STATUS}
                onClick={this.handleClick}
              >
                {this.getFieldTitle(OverviewPanelFields.STATUS)}
              </Accordion.Title>
              <Accordion.Content active={this.state.activeIndex.includes(TabsIndex.STATUS)}>
                {this.renderStatus()}
              </Accordion.Content>

              <Accordion.Title
                active={this.state.activeIndex.includes(TabsIndex.OBJECTIVES)}
                index={TabsIndex.OBJECTIVES}
                onClick={this.handleClick}
              >
                {this.getFieldTitle(OverviewPanelFields.OBJECTIVES)}
              </Accordion.Title>
              <Accordion.Content active={this.state.activeIndex.includes(TabsIndex.OBJECTIVES)}>
                <div className="content-element">
                  {this.state.data && this.getElement(this.state.data.objectives, OverviewPanelFields.OBJECTIVES)}
                </div>
              </Accordion.Content>

              <Accordion.Title
                active={this.state.activeIndex.includes(TabsIndex.BUDGET)}
                index={TabsIndex.BUDGET}
                onClick={this.handleClick}
              >
                {this.getFieldTitle(OverviewPanelFields.BUDGET)}
                {Utils.isOnViewMode(this.state.mode) && this.state.data &&
                  <ToggleHideElement
                    isElementHidden={this.state.data.isBudgetHidden}
                    viewOnly={true}
                  />
                }
                {Utils.isOnEditMode(this.state.mode) && this.state.data &&
                  <ToggleHideElement
                    isElementHidden={this.state.data.isBudgetHidden}
                    updateElementState={this.handleBudgetHiddenChange}
                  />
                }
              </Accordion.Title>
              <Accordion.Content active={this.state.activeIndex.includes(TabsIndex.BUDGET)}>
                <div className="content-element">
                  {this.state.data && this.getElement(this.state.data.budget, OverviewPanelFields.BUDGET)}
                </div>
              </Accordion.Content>

              <Accordion.Title
                active={this.state.activeIndex.includes(TabsIndex.SUCCESS_FACTORS)}
                index={TabsIndex.SUCCESS_FACTORS}
                onClick={this.handleClick}
              >
                {this.getFieldTitle(OverviewPanelFields.SUCCESS_FACTORS)}
              </Accordion.Title>
              <Accordion.Content active={this.state.activeIndex.includes(TabsIndex.SUCCESS_FACTORS)}>
                <div className="content-element">
                  {this.state.data
                    && this.getElement(this.state.data.successFactorsRisks, OverviewPanelFields.SUCCESS_FACTORS)}
                </div>
              </Accordion.Content>

              <Accordion.Title
                active={this.state.activeIndex.includes(TabsIndex.ACTIONS_PI)}
                index={TabsIndex.ACTIONS_PI}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                {Utils.isActivityBc(this.state.type)
                  && <FormattedMessage id="performanceInitiatives" defaultMessage="Performance Initiatives" />}
                {Utils.isActivityPi(this.state.type) && <FormattedMessage id="actions" defaultMessage="Actions" />}
              </Accordion.Title>
              <Accordion.Content active={this.state.activeIndex.includes(TabsIndex.ACTIONS_PI)}>
                {Utils.isActivityBc(this.state.type) && this.renderPis()}
                {Utils.isActivityPi(this.state.type) && this.renderActions()}
              </Accordion.Content>
            </Accordion>
          </Segment>}
        </CustomScrollBars>
      </div>
    );
  }

}

export default withRouter(injectIntl(BcPiDetails));
