import './bcHistoryExternal.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';

import Utils from 'src/utils/utils';
import TypeActivity from '../../../constants/typeActivity';
import BusinessChallenge from '../../../models/businessChallenge';
import History from '../../../models/history';
import Breadcrumb from '../breadcrumb/breadcrumb';
import ObjectivesContainer from '../containers/objectives';
import TextFieldsContainer from '../containers/textfields';
import HistoryAPI from '../../../api/history.api';
import HistoryExternalError, { HistoryExternalErrors } from '../containers/historyExternalError';
import PerformanceInitiative from '../../../models/performanceInitiative';
import CustomScrollBars from '../../common/customScrollBars/customScrollBars';
import LinkLabel, { LinkLabelMode } from '../../common/linkLabel/linkLabel';
import { BcErrors } from '../../../constants/errors/business-challenge.error';

interface IRouteProps {
  bcId: string;
  historyId: string;
}

interface IStates {
  bc: BusinessChallenge | undefined | null;
  headerColor: string;
  histories: History[] | undefined;
  isExternal: boolean;
  isPublicView: boolean;
  isLoading: boolean;
  historyExternalError: HistoryExternalErrors | undefined;
}

class BcHistoryExternal extends React.Component<RouteComponentProps<IRouteProps>, IStates> {

  public constructor(props: RouteComponentProps<IRouteProps>) {
    super(props);

    const isExternal = this.props.match.url.toString().search('external') !== -1;
    this.state = {
      isExternal,
      bc: undefined,
      headerColor: '',
      histories: undefined,
      isPublicView: !isExternal,
      isLoading: true,
      historyExternalError: undefined,
    };
  }

  public componentWillMount() {
    if (this.state.isExternal) {
      this.setExternalView();
    } else {
      this.setHistoryList();
    }
  }

  public componentDidUpdate(prevProps: Readonly<RouteComponentProps<IRouteProps>>, prevState: Readonly<IStates>) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.setState(
        { isLoading: true },
        () => {
          if (this.state.isExternal) {
            this.setExternalView();
          } else {
            if (+prevProps.match.params.bcId !== +this.props.match.params.bcId) {
              this.setHistoryList();
            } else {
              this.isHistoryValid();
            }
          }
        },
      );
    }
  }

  private setHistoryList() {
    HistoryAPI.getHistories('business-challenges', +this.props.match.params.bcId)
      .then((histories) => {
        this.setState(
          { histories },
          () => {
            this.isHistoryValid();
          },
        );
      })
      .catch(err => this.setState({ bc: null, isLoading: false, historyExternalError: err.error }));
  }

  private setExternalView = () => {
    HistoryAPI.getBcExternal(+this.props.match.params.bcId)
      .then((bc: BusinessChallenge) => {
        this.setBusinessChallenge(bc);
      })
      .catch(err => this.setState({ bc: null, isLoading: false, historyExternalError: err.error }));
  };

  private isHistoryValid = () => {
    if (this.state.histories
      && this.state.histories.length > 0
      && this.state.histories.some(el => el.id === +this.props.match.params.historyId)) {
      this.setHistory();
    } else {
      this.setState({ isLoading: false, historyExternalError: BcErrors.BC_WRONG_HISTORY });
    }
  };

  private setHistory() {
    HistoryAPI.getBcHistoryById(+this.props.match.params.bcId, +this.props.match.params.historyId)
      .then((bc) => {
        this.setBusinessChallenge(bc);
      })
      .catch(() => this.setState({ bc: null, isLoading: false }));
  }

  private setBusinessChallenge = (bc: BusinessChallenge) => {
    this.setState({ bc, headerColor: Utils.getPillarName(bc.pillar, true), isLoading: false });
  };

  private getPiList() {
    const piList: JSX.Element[] = [];
    if (this.state.bc && this.state.bc.performanceInitiatives.length > 0) {
      const originalPis = this.state.bc.performanceInitiatives
        .filter(pi => pi.businessChallenge.id === this.state.bc!.id)
        .map(pi => ({ ...pi, isLinked: false }));

      const linkedPis = this.state.bc.performanceInitiatives
        .filter(pi => pi.businessChallenge.id !== this.state.bc!.id)
        .map(pi => ({ ...pi, isLinked: true }));

      Utils.sortArrayByKey(originalPis, 'code').concat(linkedPis)
        .map((pi: PerformanceInitiative) => {
          piList.push(
            <div key={`PI${pi.id}`}>
              {!this.state.isExternal && Utils.setBadge(pi.status, pi.isClosed)}
              <span
                className={`sub-element-title ${this.state.isExternal ? 'link' : ''}`}
                onClick={() => this.onClickPi(pi.id)}
              >
                {pi.isLinked
                  ? <LinkLabel
                    pp={pi.businessChallenge.performancePlan}
                    bc={pi.businessChallenge}
                    mode={LinkLabelMode.SMALL}
                    disableLink={true}
                  />
                  : <span>PI{Utils.leadingZero(pi.code)} -&nbsp;</span>}
                {pi.name}
              </span>

              {Utils.getAssigneeList(pi)}
            </div>,
          );
          return null;
        });
    }
    return piList;
  }

  private onClickPi = (piId: number) => {
    if (this.state.isExternal) {
      this.props.history.push(`/pi-external/${piId}`);
    }
    return null;
  };

  private getBudget() {
    return this.state.bc && this.state.bc.budget
      && (this.state.isPublicView ? !this.state.bc.isBudgetHidden : true)
      && (
        <div id="budget-container">
          <div className={`title ${this.state.headerColor}-title`}>
            <FormattedMessage id="budget" defaultMessage="Budget" />
          </div>
          <pre id="budget-content">
          {this.state.bc && this.state.bc.budget}
        </pre>
        </div>
      );
  }

  private getStatus() {
    return (this.state.isExternal
      ? <FormattedMessage id="status" defaultMessage="Status" />
      : (
        <div>
          <span id="element-status">
            {this.state.bc && Utils.setBadge(this.state.bc.status)}
          </span>
          <FormattedMessage id="status" defaultMessage="Status"/>&nbsp;-&nbsp;
          {this.state.bc && Utils.getStatusName(this.state.bc.status)}
        </div>
      )
    );
  }

  private selectHistory = (historyId: number) => {
    this.props.history.push(`/bc-history/${this.props.match.params.bcId}/${historyId}`);
  };

  private togglePublicView = (isPublicView: boolean) => {
    this.setState({ isPublicView });
  };

  private goToExternal = (activityId: number, route: string) => {
    this.props.history.push(`/${route}/${activityId}`);
  };

  public render() {
    if (this.state.bc) {
      const currentHistoryId = this.props.match.params.historyId
        ? +this.props.match.params.historyId
        : undefined;

      return (
        <div id="history-container">
          <Breadcrumb
            data={this.state.bc}
            typeActivity={TypeActivity.BUSINESS_CHALLENGE}
            histories={!!this.state.histories ? this.state.histories : []}
            isExternal={this.state.isExternal}
            currentHistoryId={currentHistoryId}
            isPublicView={this.state.isExternal ? undefined : this.state.isPublicView}
            handleClick={this.selectHistory}
            togglePublicView={this.togglePublicView}
            goToExternal={this.goToExternal}
          />
          {this.state.isLoading
            ? Utils.loader()
            : <div id="history-content-container">
              <div id="bc-element-history-container">
                <CustomScrollBars>
                  <div id="content-container">
                    <div id="element-history-header" className={this.state.headerColor}>
                      <div id="icon">
                        {Utils.getNegativePillar(this.state.bc.pillar)}
                      </div>
                      <div id="header-content">
                        <div id="header-top">
                          <div id="top-center">
                            <div id="plan-title">
                              {this.state.bc.performancePlan.name}
                            </div>
                            <div id="bc-title">
                              BC
                              {Utils.leadingZero(this.state.bc.code)} - {this.state.bc.name}
                              {this.state.bc.isClosed &&
                                <div id="pi-closed-tag" className="ui label closed-label margin-left">CLOSED</div>
                              }
                            </div>
                          </div>
                          <div id="top-right">
                            <div id="publication">
                            <span className="date">
                              <FormattedMessage id="publicationOf" defaultMessage="Publication of">
                                {msg => `${msg} ${this.state.bc
                                  ? this.state.bc.publishedDate
                                    ? Utils.displayDate(this.state.bc.publishedDate)
                                    : '-'
                                  : null
                                }`}
                              </FormattedMessage>
                            </span>
                            </div>
                            <div id="leader">
                              {this.state.bc.assignedAccounts ? Utils.getUsersAvatars(this.state.bc) : null}
                            </div>
                            {this.state.bc.deputies && this.state.bc.deputies.length > 0
                              && <div id="deputy-title-bc">
                                {this.state.bc.deputies.length === 1
                                  ? <FormattedMessage id="deputy" defaultMessage="DEPUTY" />
                                  : <FormattedMessage id="deputies" defaultMessage="DEPUTIES" />
                                }
                              </div>
                            }
                            <div id="leader">
                              {this.state.bc.deputies ? Utils.getUsersAvatarsFromList(this.state.bc.deputies) : null}
                            </div>
                            <div id="keyword-content">
                              {this.state.bc.keywords && this.state.bc.keywords.id !== 1 && this.state.bc.keywords.name}
                            </div>
                          </div>
                        </div>
                        <div id="divider"/>
                        <div id="description-title">
                          <FormattedMessage id="description" defaultMessage="Description" />
                        </div>
                        <div id="description-content">
                          {Utils.getFormattedTextFromHTMLString(this.state.bc.objectives)}
                        </div>
                      </div>
                    </div>
                    <div id="content">
                      <div id="objectives-container">
                        <div className={`title ${this.state.headerColor}-title`}>
                          <FormattedMessage id="objectives" defaultMessage="Objectives" />
                        </div>
                        <ObjectivesContainer
                          element={this.state.bc}
                          isPublicView={this.state.isPublicView}
                          activityType={TypeActivity.BUSINESS_CHALLENGE}
                        />
                      </div>
                      <div id="status-container">
                        {!this.state.isPublicView
                          && <div className={`title ${this.state.headerColor}-title`}>
                            {this.getStatus()}
                          </div>
                        }
                        <TextFieldsContainer element={this.state.bc} isPublicView={this.state.isPublicView} />
                      </div>
                      <div id="sub-element-list-container">
                        <div className={`title ${this.state.headerColor}-title`}>
                          <FormattedMessage id="performanceInitiatives" defaultMessage="Performance initiatives">
                            {msg => `${msg} (${this.state.bc ? this.state.bc.performanceInitiatives.length : null})`}
                          </FormattedMessage>
                        </div>
                        <div id="sub-element-list-content">
                          {this.getPiList()}
                        </div>
                      </div>
                      {this.getBudget()}
                    </div>
                  </div>
                </CustomScrollBars>
              </div>
            </div>
          }
        </div>
      );
    }

    return (
      <HistoryExternalError
        isLoading={this.state.isLoading}
        historyExternalError={this.state.historyExternalError}
      />
    );
  }
}

export default withRouter(BcHistoryExternal);
