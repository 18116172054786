import HttpClient from 'src/utils/httpClient';
import ReportingGet, { ReportingPost, ReportingPut } from '../models/reporting';
import BusinessChallengeReporting,
{ BusinessChallengeReportingPost, BusinessChallengeReportingPut } from '../models/businessChallengeReporting';

export default class ReportingAPI {

  /**
   * ROUTE : /api/reporting/{ppId}/reportings
   * Get Reportings from Performance Plan
   * @param ppId: number
   * @returns Promise<ReportingGet[]>
   */
  public static getReportingsFromPerformancePlan(ppId: number) {
    return HttpClient.get<ReportingGet[]>(`reporting/${ppId}/reportings`);
  }

    /**
   * ROUTE : /api/reporting/{ppId}/reportingsDue
   * Get Reportings Due date passed from Performance Plan
   * @param ppId: number
   * @returns Promise<ReportingGet[]>
   */
  public static getReportingsDueFromPerformancePlan(ppId: number): Promise<ReportingGet> {
    return HttpClient.get<ReportingGet>(`reporting/${ppId}/reportingsDue`);
  }
  /**
   * ROUTE : /api/reporting/{reportingId}/bc-reporting?bcId=1
   * Update a report
   * @returns Promise<PerformancePlanReporting>
   * @param reportId
   * @param bcId
   * @param bcReporting
   */
  public static putPerformancePlanBCReporting(
    reportId: number,
    bcId: number,
    bcReporting: BusinessChallengeReportingPut,
  ): Promise<BusinessChallengeReporting> {
    return HttpClient.put<BusinessChallengeReporting>(
      `reporting/${reportId}/bc-reporting?bcId=${bcId}`,
      bcReporting);
  }

  /**
   * ROUTE : /api/reporting/{reportingId}/update/{bcReportingId}
   * Update a BC reporting
   * @returns Promise<BusinessChallengeReporting>
   * @param reportingId: number
   * @param bcReportingId: number
   */
  public static updateBcReporting(reportingId: number, bcReportingId: number): Promise<BusinessChallengeReporting> {
    return HttpClient.get<BusinessChallengeReporting>(`reporting/${reportingId}/update/${bcReportingId}`);
  }

  /**
   * ROUTE : /api/reporting/{reportingId}
   * Delete a reporting
   * @param reportingId: number
   * @returns Promise<void>
   */
  public static deleteReporting(reportingId: number)
    : Promise<void> {
    return HttpClient.delete<void>(`reporting/${reportingId}`, {});
  }

  /**
   * ROUTE : /api/reporting/{reportingId}
   * Get a reporting by id
   */
  public static getReportingById(reportingId: number) {
    return HttpClient.get<ReportingGet>(`reporting/${reportingId}`);
  }

  /**
   * ROUTE : /api/reporting/close/{reportingId}?userId=
   * Close a reporting
   */
  public static closeReporting(reportingId: number, userId: number) {
    return HttpClient.get<void>(`reporting/close/${reportingId}?userId=${userId}`);
  }
  /**
   * ROUTE : /api/reporting/postpone/{reportingId}?userId=
   * Postpone a reporting
   */
  public static postponeReporting(reportingId: number) {
    return HttpClient.get<void>(`reporting/postpone/${reportingId}`);
  }

  /**
   * ROUTE : /api/reporting/{ppId}
   * Create a new report
   * @param ppId : number
   * @param report
   * @returns Promise<ReportingGet>
   */
  public static postReporting(ppId: number, report: ReportingPost) : Promise<ReportingGet> {
    return HttpClient.post<ReportingGet>(`reporting/${ppId}`, report);
  }

  /**
   * ROUTE : /api/reporting/{ppId}
   * Update a Reporting's basic info (name and due date)
   * @returns Promise<ReportingGet>
   * @param report
   */
  public static putReportingBasic(report: ReportingPut) : Promise<ReportingPut> {
    return HttpClient.put<ReportingPut>(
      `reporting/${report.id}/basic`,
      { name: report.name, dueDate: report.dueDate });
  }

  /**
   * ROUTE : /api/reporting/{reportingId}/bc-reporting?bcId=
   * Update a BcReporting
   * @returns Promise<ReportingGet>
   * @param reportId
   * @param bcId
   * @param bcReporting
   */
  public static putBCReporting(reportId: number, bcId: number, bcReporting: BusinessChallengeReportingPost)
    : Promise<BusinessChallengeReporting> {
    return HttpClient.put<BusinessChallengeReporting>(
      `reporting/${reportId}/bc-reporting?bcId=${bcId}`,
      bcReporting);
  }

  /**
   * ROUTE : /api/export/{ppId}/reporting
   * Export the Performance Plan reporting(s)
   * @param ppId : number
   * @param selectedReporting : number
   * @param onlyTop5 : boolean
   * @returns Promise<string>
   */
  public static exportReporting(ppId: number, selectedReporting?: number, onlyTop5?: boolean): Promise<string> {
    let url = `export/${ppId}/reporting?`;
    let isFirstParam = true;

    if (!!selectedReporting) {
      url += `selectedReporting=${selectedReporting}`;

      isFirstParam = false;
    }

    if (!!onlyTop5 && onlyTop5) {
      url += `${!isFirstParam ? '&' : ''}onlyTop5=${onlyTop5}`;
    }

    return HttpClient.get<string>(url);
  }
}
