import { EventEmitter } from 'events';

import ActionTypes from 'src/constants/actionTypes';
import ModeTypes from 'src/constants/modeTypes';
import { SegmentsMode, SegmentType } from 'src/models/segmentsMode';
import Action from '../actions/action';
import Dispatcher from '../dispatcher';
import Utils from '../utils/utils';
import SegmentsActions from '../actions/segments.action';

class SegmentsStore extends EventEmitter {

  private segmentsModes: SegmentsMode;

  constructor() {
    super();
    Dispatcher.register(this.registerToActions.bind(this));
    this.resetSegmentsModes();
  }

  /**
   * Get segments modes
   * @returns {SegmentsMode}
   */
  public getSegmentsModes(): SegmentsMode {
    return this.segmentsModes;
  }

  /**
   * Get segment mode by segment id
   * @param {SegmentType} id
   * @returns {ModeTypes}
   */
  public getSegmentModeById(id: SegmentType | string): ModeTypes {
    return this.segmentsModes[id];
  }

  /**
   * Check if at least one segment is on edit mode
   * @returns {boolean}
   */
  public hasSegmentsOnEdit(): boolean {
    return Object.entries(this.segmentsModes).some(([_, value]) => Utils.isOnEditMode(value));
  }

  /**
   * Get the label of the segment being edited
   * @returns {SegmentType | string | null}
   */
  public getSegmentOnEdit(): SegmentType | string | null {
    if (this.hasSegmentsOnEdit()) {
      return Object.entries(this.segmentsModes).find(([_, value]) => Utils.isOnEditMode(value))![0];
    }
    return null;
  }

  /**
   * Check if at least one publication segment is on edit mode
   * @returns {boolean}
   */
  public hasPublicationsSegmentOnEdit(): boolean {
    return Object.entries(this.segmentsModes).some(([key, value]) =>
    (key.includes(SegmentType.PP_PUBLICATIONS) || key.includes(SegmentType.BC_PUBLICATIONS)
    || key.includes(SegmentType.PI_PUBLICATIONS))
    && Utils.isOnEditMode(value));
  }

  /**
   * Check if at least one action or milestone (PI) segment is on edit mode
   * @returns {boolean}
   */
  public hasPiAtMiSegmentOnEdit(): boolean {
    return Object.entries(this.segmentsModes).some(([key, value]) =>
       (key.includes(SegmentType.AT_ACTION) || key.includes(SegmentType.PI_MILESTONES)) && Utils.isOnEditMode(value));
  }

  /**
   * Set segment mode
   * @param {SegmentType} id
   * @param {ModeTypes} modeType
   * @returns {void}
   */
  private setSegmentsModes(id: SegmentType, modeType: ModeTypes): void {
    this.segmentsModes[id] = modeType;
    this.emit(ActionTypes.SET_SEGMENT_MODE.toString());
  }

  /**
   * Set segment mode
   * @returns {void}
   */
  public setCancellingSegmentsOnViewMode(): void {
    for (const [key, value] of Object.entries(this.segmentsModes)) {
      if (value === ModeTypes.MODE_CANCEL) {
        SegmentsActions.emitSegmentMode(key as SegmentType | string, ModeTypes.MODE_VIEW);
      }
    }
  }

  /**
   * Reset segments modes with default values
   * @returns {void}
   */
  private resetSegmentsModes(): void {
    this.segmentsModes = {};
    Object.entries(SegmentType).forEach(([key, value]) => this.segmentsModes[value] = ModeTypes.MODE_VIEW);
    this.emit(ActionTypes.RESET_SEGMENTS_MODES.toString());
  }

  /**
   * Set all cancelling segments to edition
   * @return {void}
   */
  public emitSetCancellingSegmentsToEdition(): void {
    Object.entries(this.segmentsModes).forEach(([key, value]) => {
      if (this.segmentsModes[key] && Utils.isOnCancelMode(this.segmentsModes[key])) {
        this.segmentsModes[key] = ModeTypes.MODE_EDITION;
      }
    });

    this.emit(ActionTypes.SET_SEGMENT_MODE.toString());
  }

  /**
  * Set all deleting segments to edition
  * @return {void}
  */
  public emitSetDeletingSegmentsToEdition(isDeletingValidation = false): void {
    Object.entries(this.segmentsModes).forEach(([key, value]) => {
      if (this.segmentsModes[key] && Utils.isOnDeleteMode(this.segmentsModes[key])) {
        this.segmentsModes[key] = isDeletingValidation ? ModeTypes.MODE_EDITION : ModeTypes.MODE_VIEW ;
      }
    });
    this.emit(ActionTypes.SET_SEGMENT_MODE.toString());
  }

  /**
   * Performs a function according to an action
   * @param {Action} action
   * @return {void}
   */
  private registerToActions(action: Action) {
    switch (action.actionType) {
      case ActionTypes.SET_SEGMENT_MODE:
        this.setSegmentsModes(action.id, action.modeType);
        break;
      case ActionTypes.RESET_SEGMENTS_MODES:
        this.resetSegmentsModes();
        break;
    }
  }
}

export default new SegmentsStore();
