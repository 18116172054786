import './rightColumn.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';

import TreeViewStore, { SelectedActivity } from 'src/stores/treeview.store';
import ActionTypes from '../../../constants/actionTypes';
import TypeActivity from '../../../constants/typeActivity';
import User from '../../../models/user';
import PublicationLabel from '../../common/publicationLabel/publicationLabel';
import Utils from '../../../utils/utils';
import CustomScrollBars from '../../common/customScrollBars/customScrollBars';

interface IProps extends RouteComponentProps {
}

interface IStates {
  selectedActivity: SelectedActivity | undefined;
  translate: { x: number, y: number };
}

class RightColumn extends React.Component<IProps, IStates> {

  private isMount = false;

  public constructor(props: IProps) {
    super(props);

    this.state = {
      selectedActivity: undefined,
      translate: { x: 1, y: 1 },
    };
  }

  public componentWillMount(): void {
    this.isMount = true;
    TreeViewStore.addListener(ActionTypes.GET_SELECTED_ACTIVITY_TREEVIEW.toString(), this.setSelectedActivity);
  }

  public componentWillUnmount(): void {
    this.isMount = false;
    TreeViewStore.removeListener(ActionTypes.GET_SELECTED_ACTIVITY_TREEVIEW.toString(), this.setSelectedActivity);
  }

  private setSelectedActivity = () => {
    if (this.isMount) {
      this.setState({ selectedActivity: TreeViewStore.getSelectedActivity() });
    }
  };

  private handleActivityClick = () => {
    if (this.state.selectedActivity) {
      let route;
      switch (this.state.selectedActivity.type) {
        case TypeActivity.PERFORMANCE_PLAN:
          route = 'pp-external';
          break;
        case TypeActivity.BUSINESS_CHALLENGE:
          route = 'bc-external';
          break;
        case TypeActivity.PERFORMANCE_INITIATIVE:
          route = 'pi-external';
          break;
      }
      if (route) {
        this.props.history.push(`/${route}/${this.state.selectedActivity.id}`);
      }
    }
  };

  public render() {
    return (
      <div id="right-column">
        <div id="selected-element-container">
          <span id="selected-element">
            <FormattedMessage id="selectedElement" defaultMessage="Selected element" />
          </span>
          {this.state.selectedActivity
            ? <div>
              <div
                id="element-title"
                className={this.state.selectedActivity.type !== TypeActivity.ACTION ? 'link' : ''}
                onClick={this.handleActivityClick}
              >
                {this.state.selectedActivity.name}
                <PublicationLabel publishedDate={this.state.selectedActivity.publishedDate} />
              </div>
              <div id="accounts">
                {this.state.selectedActivity.assignedAccounts.map((account: User) => {
                  return (
                    <div key={`selected-assignees${account.id}`}>
                      {account.firstName} {account.lastName.toUpperCase()}
                    </div>
                  );
                })}
              </div>
              <div id="description-element">
                <FormattedMessage id="aboutDescription" defaultMessage="Description" />
              </div>
              <div id="description">
                <CustomScrollBars>
                  {Utils.isActivityBc(this.state.selectedActivity.type)
                    || Utils.isActivityPi(this.state.selectedActivity.type)
                    ? <div className="formatted-text">
                        {Utils.getFormattedTextFromHTMLString(this.state.selectedActivity.objectives)}
                      </div>
                    : null
                  }
                </CustomScrollBars>
              </div>
            </div>
            : null
          }
        </div>
        <div id="legend-container">
          <div className="legend">
            <span id="legend-my-activities" className="legend-badge" />
            <FormattedMessage id="myActivities" defaultMessage="Activities assigned to me" />
          </div>
          <div className="legend">
            <span id="legend-current-activity" className="legend-badge" />
            <FormattedMessage id="currentActivity" defaultMessage="Current activity" />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(RightColumn);
