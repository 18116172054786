import './contentBC.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { Icon, Menu } from 'semantic-ui-react';

import ActivitiesActions from 'src/actions/activities.action';
import BusinessChallengeActions from 'src/actions/businessChallenge.action';
import TitleTaskboardActions from 'src/actions/titleTaskboard.actions';
import ActionTypes from 'src/constants/actionTypes';
import ModeTypes from 'src/constants/modeTypes';
import TypeActivity from 'src/constants/typeActivity';
import BusinessChallenge from 'src/models/businessChallenge';
import ActivitiesStore from 'src/stores/activities.store';
import ErrorStore from 'src/stores/error.store';
import BusinessChallengeStore from 'src/stores/businessChallenge.store';
import Utils from 'src/utils/utils';
import ConfirmModal from '../confirmModal/confirmModal';
import MetricsContainer from '../metricsMilestones/metricsContainer';
import TitleContainer from '../titleContainer/titleContainer';
import Cockpit from './cockpit/cockpit';
import MoreInfo from './moreInfo/moreInfo';
import DatesContainer from '../datesContainer/datesContainer';
import EditingDraftBanner from '../contentPP/editingDraftBanner/editingDraftBanner';
import CustomScrollBars from '../../../common/customScrollBars/customScrollBars';
import { PpTabs } from '../contentPP/contentPP';
import BusinessChallengeErrors from 'src/models/bc-errors';
import ClosedBanner from '../closedBanner/closedBanner';
import OverviewPanel from '../common/overviewPanel/overviewPanel';
import PiCreation from 'src/components/creation/piCreation/piCreation';
import PiImport from 'src/components/creation/activityImport/piImport';
import { RightsOnBC } from 'src/models/rights';
import RightsActions from 'src/actions/rights.action';
import BusinessChallengeAPI from 'src/api/businessChallenge.api';
import RightsStore from 'src/stores/rights.store';
import { guideWrapper } from '../../../guide/guideWrapper';
import PerformanceMeasures from './performanceMeasures/performanceMeasures';

export enum BcTabs {
  COCKPIT = 'cockpit',
  METRICS_VIEW = 'metrics-view',
  ACTIVITY = 'activity',
  MORE_INFO = 'more-info',
}

interface IRouteProps {
  ppId: string;
  id: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  isOverviewPanelOpen: boolean;
  isOverviewPanelRelative: boolean;
  toggleOverviewPanelVisibility: () => void;
  handleDraftChange(isDraft): boolean;
}

interface IStates {
  data: BusinessChallenge;
  oldData: BusinessChallenge;
  rightsOnBc: RightsOnBC | undefined;
  mode: ModeTypes;
  error?: Error;
  isFormValid: boolean[];
  isCockpitOnError: boolean;
  errors: BusinessChallengeErrors;
  isNewPiModalOpen: boolean;
  isImportPiModalOpen: boolean;
  contentHeight: number;
}

class ContentBC extends React.Component<IProps, IStates> {

  private isMount = false;

  //region REACT LIFECYCLE METHODS
  constructor(props: IProps) {
    super(props);
    this.state = {
      data: BusinessChallengeStore.getBusinessChallenge(),
      oldData: BusinessChallengeStore.getOldBusinessChallenge(),
      rightsOnBc: undefined,
      error: ErrorStore.getError(),
      mode: ActivitiesStore.getMode(),
      isFormValid: [true, true],
      isCockpitOnError: false,
      errors: BusinessChallengeStore.getErrors(),
      isNewPiModalOpen: BusinessChallengeStore.getIsOpenNewPiModal(),
      isImportPiModalOpen: BusinessChallengeStore.getIsOpenImportPiModal(),
      contentHeight: 0,
    };
  }

  public componentDidMount() {
    this.isMount = true;
    BusinessChallengeStore.addListener(ActionTypes.BUSINESS_CHALLENGE_GET.toString(), this.getBusinessChallenge);
    BusinessChallengeStore.addListener(ActionTypes.BUSINESS_CHALLENGE_CHANGE.toString(), this.getBusinessChallenge);
    BusinessChallengeStore.addListener(ActionTypes.BUSINESS_CHALLENGE_SAVED.toString(), this.getBcAfterSave);
    BusinessChallengeStore.addListener(ActionTypes.RELOAD_BC_COCKPIT.toString(), this.emitGetBusinessChallengeById);
    BusinessChallengeStore.addListener(ActionTypes.BUSINESS_CHALLENGE_ERRORS.toString(), this.setErrors);
    BusinessChallengeStore.addListener(ActionTypes.SET_OPEN_NEW_PI_MODAL.toString(), this.getOpenNewPiModal);
    BusinessChallengeStore.addListener(ActionTypes.SET_OPEN_IMPORT_PI_MODAL.toString(), this.getOpenImportPiModal);
    RightsStore.addListener(ActionTypes.SET_BC_RIGHTS.toString(), this.setRightsOnBc);
    ActivitiesStore.addListener(ActionTypes.ACTIVITIES_GET_MODE.toString(), this.getMode);
    ErrorStore.addListener(ActionTypes.ERROR_CHANGE.toString(), this.getError);
    ErrorStore.addListener(ActionTypes.ERROR_DELETED.toString(), this.getError);
    this.emitGetBusinessChallengeById();
    this.getBcRights();
    ActivitiesActions.emitGetActivitySelect(TypeActivity.BUSINESS_CHALLENGE);
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      BusinessChallengeActions.emitGetBusinessChallengeById(+nextProps.match.params.id, +nextProps.match.params.ppId);
      this.getBcRights(+nextProps.match.params.id);
    }
  }

  private emitGetBusinessChallengeById = () => {
    BusinessChallengeActions.emitGetBusinessChallengeById(+this.props.match.params.id, +this.props.match.params.ppId);
  }

  public componentWillUnmount() {
    this.isMount = false;
    BusinessChallengeStore.removeListener(ActionTypes.BUSINESS_CHALLENGE_GET.toString(), this.getBusinessChallenge);
    BusinessChallengeStore.removeListener(ActionTypes.BUSINESS_CHALLENGE_CHANGE.toString(), this.getBusinessChallenge);
    BusinessChallengeStore.removeListener(ActionTypes.BUSINESS_CHALLENGE_SAVED.toString(), this.getBcAfterSave);
    BusinessChallengeStore.removeListener(ActionTypes.RELOAD_BC_COCKPIT.toString(), this.emitGetBusinessChallengeById);
    BusinessChallengeStore.removeListener(ActionTypes.BUSINESS_CHALLENGE_ERRORS.toString(), this.setErrors);
    BusinessChallengeStore.removeListener(ActionTypes.SET_OPEN_NEW_PI_MODAL.toString(), this.getOpenNewPiModal);
    BusinessChallengeStore.removeListener(ActionTypes.SET_OPEN_IMPORT_PI_MODAL.toString(), this.getOpenImportPiModal);
    RightsStore.removeListener(ActionTypes.SET_BC_RIGHTS.toString(), this.setRightsOnBc);
    ActivitiesStore.removeListener(ActionTypes.ACTIVITIES_GET_MODE.toString(), this.getMode);
    ErrorStore.removeListener(ActionTypes.ERROR_SET.toString(), this.getError);
    ErrorStore.removeListener(ActionTypes.ERROR_DELETED.toString(), this.getError);
  }
  //endregion

  private getBcRights = async (bcId?: number) => {
    const rights = await BusinessChallengeAPI.getBusinessChallengeRightsById(bcId ?? +this.props.match.params.id);
    RightsActions.emitSetBcRights(rights);
  }

  //region LISTENER RELATED METHODS
  private setRightsOnBc = () => {
    this.setState({
      rightsOnBc: RightsStore.getRightsOnBC(),
    });
  }

  private getError = () => {
    this.setState({ error: ErrorStore.getError() });
  };

  private setErrors = () => {
    if (this.isMount) {
      this.setState({
        errors: BusinessChallengeStore.getErrors(),
      });
    }
  }

  private triggerErrors = () => {
    if (Utils.isKeywordNone(this.state.data.keywords.id)) {
      return BusinessChallengeActions.emitError('keywords');
    }
    if (this.state.errors.keywords) {
      return BusinessChallengeActions.clearError('keywords');
    }
  }

  private getBusinessChallenge = () => {
    if (this.isMount) {
      this.setState(
        {
          data: BusinessChallengeStore.getBusinessChallenge(),
          oldData: BusinessChallengeStore.getOldBusinessChallenge(),
        },
        () => {
          const currentRoute = window.location.pathname.split('/');
          if (+currentRoute[currentRoute.length - 1] === this.state.data.id) {
            if (this.state.rightsOnBc?.canViewCockpit()) {
              this.navigateToTab(BcTabs.COCKPIT);
            } else {
              this.navigateToTab(BcTabs.MORE_INFO);
            }
          }
        },
      );
    }
  };

  private getBcAfterSave = () => {
    if (this.isMount) {
      BusinessChallengeActions
        .emitGetBusinessChallengeByIdAfterSaved(+this.props.match.params.id, +this.props.match.params.ppId);
    }
  };

  private getMode = () => {
    if (this.isMount) {
      this.setState({ mode: ActivitiesStore.getMode() });
    }
    if (Utils.isOnEditMode(ActivitiesStore.getMode())) {
      this.triggerErrors();
    }
  };
  //endregion

  //region CLASS METHODS
  private isFormValidated = (isValid: boolean, form: string) => {
    const isFormValid = [...this.state.isFormValid];

    switch (form) {
      case 'metricsMilestones':
        isFormValid[0] = isValid;
        break;
      case 'moreInfo':
        isFormValid[1] = isValid;
        break;
      default:
        break;
    }
    this.setState(
      { isFormValid },
      () => TitleTaskboardActions.setIsSaveDisabled(!this.state.isFormValid.every(el => el)),
    );
  };

  private navigateToTab = (tab: BcTabs) => {
    this.props.history.push(`${this.props.match.url}/${tab}`);
  };

  /**
   * Confirm deletion action
   */
  private confirmDeletion = (): void => {
    ActivitiesActions.emitChangeMode(ModeTypes.MODE_VIEW);
    BusinessChallengeActions.emitRemoveBusinessChallenge(this.state.data.id)
      .then(() => {
        this.props.history.push(`/activities-board/performance-plan/${this.state.data.performancePlan.id}/${PpTabs.COCKPIT}`);
      });
  };

  /**
   * UnConfirmed deletion action
   */
  private noConfirmDeletionCancel = (): void => {
    ActivitiesActions.emitChangeMode(ModeTypes.MODE_VIEW);
  };
  //endregion

  private getOpenNewPiModal = () => {
    this.setState({
      isNewPiModalOpen: BusinessChallengeStore.getIsOpenNewPiModal(),
    });
  }

  private toggleNewPiModal = () => {
    BusinessChallengeActions.emitOpenNewPiModal(!this.state.isNewPiModalOpen);
  }

  private getOpenImportPiModal = () => {
    this.setState({
      isImportPiModalOpen: BusinessChallengeStore.getIsOpenImportPiModal(),
    });
  }

  private toggleImportPiModal = () => {
    BusinessChallengeActions.emitOpenImportPiModal(!this.state.isImportPiModalOpen);
  }

  private getCanEdit = () => {
    return this.state.rightsOnBc ? this.state.rightsOnBc.canEdit() : false;
  }

  private setTabErrorStatus = (onError: boolean, tab: BcTabs) => {
    switch (tab) {
      case BcTabs.COCKPIT:
        this.setState({ isCockpitOnError: onError });
        break;
    }
  };

  public calculateContentSize = () => {
    const containerHeight = Utils.calculateContentSize();
    if (this.state.contentHeight !== containerHeight) {
      this.setState({ contentHeight: containerHeight });
    }
  }

  public render() {
    if (this.state.error !== undefined) {
      return Utils.empty(<FormattedMessage id="noBC" defaultMessage="No BC was found for this Performance Plan" />);
    }

    if (!this.state.data || this.state.data.id !== +this.props.match.params.id) {
      return Utils.loader();
    }

    const errIcon = <Icon className="menu-icon-error error" name="warning circle" />;
    const confirmDeletionTitle = <FormattedMessage id="confirmDeletion" defaultMessage="Confirm deletion" />;
    const confirmDeletionMessage = <FormattedMessage id="confirmDeletionQuestion" defaultMessage="Confirm deletion?" />;

    return (
      <div id="business-challenge-container">
        {this.state.data.isPpDraft &&
          <EditingDraftBanner
            activityType={TypeActivity.BUSINESS_CHALLENGE}
            triggerSizeCalculation={this.calculateContentSize}
          />}
        {this.state.data.isClosed &&
          <ClosedBanner
            activityType={TypeActivity.BUSINESS_CHALLENGE}
            message={this.state.data.closeComment}
            date={this.state.data.closeDate}
            triggerSizeCalculation={this.calculateContentSize}
          />
        }

        {(Utils.isOnDeleteMode(this.state.mode))
          && <ConfirmModal
            closeNoFunction={this.noConfirmDeletionCancel}
            closeYesFunction={this.confirmDeletion}
            title={confirmDeletionTitle}
            message={confirmDeletionMessage}
          />}
        <TitleContainer
          type={TypeActivity.BUSINESS_CHALLENGE}
          triggerSizeCalculation={this.calculateContentSize}
          emitSave={() => BusinessChallengeActions.emitSaveBusinessChallenge(this.state.data, this.state.oldData)}
        />
        <Menu id="main-tabs-menu" className={'tab-menu-container'} pointing={true} secondary={true}>
          {this.state.rightsOnBc?.canViewCockpit()
            && <>
              <Menu.Item
                active={this.props.location.pathname.indexOf(BcTabs.COCKPIT) > -1}
                className={this.state.isCockpitOnError ? 'error' : ''}
                onClick={() => this.navigateToTab(BcTabs.COCKPIT)}
              >
                <FormattedMessage id="bc.cockpit" defaultMessage="Cockpit" />
                {this.state.isCockpitOnError && errIcon}
              </Menu.Item>

              <Menu.Item
                active={this.props.location.pathname.indexOf(BcTabs.METRICS_VIEW) > -1}
                onClick={() => this.navigateToTab(BcTabs.METRICS_VIEW)}
              >
                <FormattedMessage id="metricView" defaultMessage="Metrics view" />
              </Menu.Item>

            <Menu.Item
              active={this.props.location.pathname.indexOf(BcTabs.ACTIVITY) > -1}
              onClick={() => this.navigateToTab(BcTabs.ACTIVITY)}
            >
              <FormattedMessage id="bc.activity" defaultMessage="Activity" />
            </Menu.Item>
            </>
          }

          <Menu.Item
            active={this.props.location.pathname.indexOf(BcTabs.MORE_INFO) > -1}
            onClick={() => this.navigateToTab(BcTabs.MORE_INFO)}
          >
            <FormattedMessage id="moreInformation" defaultMessage="More information" />
          </Menu.Item>

          <DatesContainer
            dataActivity={this.state.data}
            isOverviewPanelOpen={this.props.isOverviewPanelOpen}
            togglePanelVisibility={this.props.toggleOverviewPanelVisibility}
          />
        </Menu>
        <div
          id="content-container"
          className={`scrollable-container content-container
          ${(this.state.rightsOnBc?.canPublish() ? 'has-publication' : '')}`}
          style={{ height: `${this.state.contentHeight}px` }}
        >
          <CustomScrollBars>
            <Switch>
              <Route
                path={`${this.props.match.path}/${BcTabs.COCKPIT}`}
                render={() => <Cockpit
                  setTabErrorStatus={this.setTabErrorStatus}
                  canEdit={this.getCanEdit()}
                />}
              />
              <Route
                path={`${this.props.match.path}/${BcTabs.METRICS_VIEW}`}
                render={() => <MetricsContainer
                  bc={this.state.data}
                  type={TypeActivity.BUSINESS_CHALLENGE}
                  onDataChange={BusinessChallengeActions.emitChangeBusinessChallenge}
                  mode={this.state.mode}
                  isFormValidated={this.isFormValidated}
                  activityId={+this.props.match.params.id}
                />}
              />
              <Route
                path={`${this.props.match.path}/${BcTabs.ACTIVITY}`}
                render={() => <PerformanceMeasures
                  data={this.state.data}
                  canEditMil={this.state.rightsOnBc!.canEdit()}
                />}
              />
              <Route
                path={`${this.props.match.path}/${BcTabs.MORE_INFO}`}
                render={() => <MoreInfo
                  data={this.state.data}
                  mode={this.state.mode}
                  rightsOnBc={this.state.rightsOnBc}
                  isLinkedBc={this.state.data.performancePlan.id !== +this.props.match.params.ppId}
                  isFormValidated={this.isFormValidated}
                />}
              />
            </Switch>
          </CustomScrollBars>
          <OverviewPanel
              isOverviewPanelOpen={this.props.isOverviewPanelOpen}
              activity={this.state.data}
              typeActivity={TypeActivity.BUSINESS_CHALLENGE}
              togglePanelVisibility={this.props.toggleOverviewPanelVisibility}
              isRelativeView={this.props.isOverviewPanelRelative}
              containerHeight={this.state.contentHeight}
          />
          {this.state.isNewPiModalOpen
            && <PiCreation
              close={() => this.toggleNewPiModal()}
              open={this.state.isNewPiModalOpen}
              pp={this.state.data.performancePlan}
              bc={this.state.data}
            />
          }
          {this.state.isImportPiModalOpen
          && <PiImport
            close={() => this.toggleImportPiModal()}
            ppId={parseInt(this.props.match.params.ppId, 10)}
            bcId={parseInt(this.props.match.params.id, 10)}
          />
        }
        </div>
      </div>
    );
  }
}

export default guideWrapper(withRouter(ContentBC));
