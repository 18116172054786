import './menuActivities.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Dropdown, Image, Loader, Icon } from 'semantic-ui-react';

import ActivitiesActions from 'src/actions/activities.action';
import PpCreation from 'src/components/creation/ppCreation/ppCreation';
import ActionTypes from 'src/constants/actionTypes';
import ModeTypes from 'src/constants/modeTypes';
import RoleNames from 'src/constants/roleNames';
import TypeActivity, { MenuTypeSelected } from 'src/constants/typeActivity';
import thales_logo from 'src/images/thales_logo.svg';
import PerformancePlan from 'src/models/performancePlan';
import ActivitiesStore from 'src/stores/activities.store';
import AuthStore from 'src/stores/auth.store';
import PerformancePlanStore from 'src/stores/performancePlan.store';
import ConfirmModal from '../containerActivities/confirmModal/confirmModal';
import MenuBc from './menuBc';
import UserActions from '../../../actions/user.action';
import UserStore from '../../../stores/user.store';
import CustomScrollBars from '../../common/customScrollBars/customScrollBars';
import BusinessChallenge from 'src/models/businessChallenge';
import PpYearsFilter from 'src/components/profile/components/ppYearsFilter/ppYearsFilter';
import NewVersionModal from 'src/components/notificationVersion/newVersionModal';
import User from 'src/models/user';

enum assigneeFilter {
  ALL,
  ASSIGNED,
}

interface IStates {
  user: User;
  activities: PerformancePlan[] | undefined;
  showNewPpModal: boolean;
  menuSelected: MenuTypeSelected;
  isFirst: boolean;
  isOpenConfirmModal: boolean;
  isOpenConfirmModalChangePp: boolean;
  mode: ModeTypes;
  defaultPpId: number | null | undefined;
  selectedYears: Record<string, boolean>;
  isAssigneeFiltered: boolean;
  dropdownOpen: boolean;
  isNewVersionModalOpened: boolean;
}

export class MenuActivities extends React.Component<RouteComponentProps, IStates> {

  private isMount = false;
  private isFirstMount = false;

  //region REACT LIFECYCLE METHODS
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      user: AuthStore.getConnectedUser(),
      activities: undefined,
      menuSelected: MenuTypeSelected.MENU_ACTIVITY,
      showNewPpModal: false,
      isFirst: true,
      isOpenConfirmModal: false,
      isOpenConfirmModalChangePp: false,
      mode: ModeTypes.MODE_VIEW,
      defaultPpId: undefined,
      selectedYears: {},
      isAssigneeFiltered: false,
      dropdownOpen: false,
      isNewVersionModalOpened: false,
    };
  }

  public componentDidMount(): void {
    this.isMount = true;
    UserActions.emitGetDefaultPp()
      .then(() => {
        UserActions.emitGetSelectedYears(this.state.user.id)
        .then(() => {
          this.setState({
            defaultPpId: UserStore.getDefaultPpId(),
            activities: ActivitiesStore.getActivities(),
            mode: ActivitiesStore.getMode(),
            selectedYears: UserStore.getSelectedYears(),
            isNewVersionModalOpened: this.state.user.isNewVersionModalOpened,
          });
        });
      });
    ActivitiesStore.addListener(ActionTypes.ACTIVITIES_GET_MENU.toString(), this.getMenu);
    ActivitiesStore.addListener(ActionTypes.MENU_TYPE_SELECTED.toString(), this.getMenuSelected);
    ActivitiesStore.addListener(ActionTypes.ACTIVITIES_GET_MODE.toString(), this.getMode);
    PerformancePlanStore.addListener(ActionTypes.PERFORMANCE_PLAN_DELETED.toString(), this.deletedPP);
    ActivitiesStore.addListener(ActionTypes.UPDATED_ACTIVITY.toString(), this.updatePPYear);
    UserStore.addListener(ActionTypes.USER_DEFAULT_PP_PUT.toString(), this.getDefaultPpId);
    UserStore.addListener(ActionTypes.USER_SELECTED_YEARS_PUT.toString(), this.getSelectedYears);
    UserStore.addListener(ActionTypes.USER_SELECTED_YEARS_GET.toString(), this.getSelectedYears);
    ActivitiesStore.setMaxListeners(25);
  }

  public componentWillUnmount() {
    this.isMount = false;
    ActivitiesStore.removeListener(ActionTypes.ACTIVITIES_GET_MENU.toString(), this.getMenu);
    ActivitiesStore.removeListener(ActionTypes.MENU_TYPE_SELECTED.toString(), this.getMenuSelected);
    ActivitiesStore.removeListener(ActionTypes.ACTIVITIES_GET_MODE.toString(), this.getMode);
    UserStore.removeListener(ActionTypes.USER_DEFAULT_PP_PUT.toString(), this.getDefaultPpId);
    UserStore.removeListener(ActionTypes.USER_SELECTED_YEARS_PUT.toString(), this.getSelectedYears);
    UserStore.removeListener(ActionTypes.USER_SELECTED_YEARS_GET.toString(), this.getSelectedYears);
    PerformancePlanStore.removeListener(ActionTypes.PERFORMANCE_PLAN_DELETED.toString(), this.deletedPP);
    ActivitiesStore.addListener(ActionTypes.UPDATED_ACTIVITY.toString(), this.updatePPYear);
  }
  //endregion

  //region LISTENER RELATED METHODS
  private getMenu = () => {
    if (this.isMount) {
      this.setState({ activities: ActivitiesStore.getActivities() }, () => {
        if (!this.isFirstMount) {
          if (this.state.activities!!.length > 0 && this.props.location.pathname === '/activities-board') {
            this.setSelectedPp();
          }
          this.isFirstMount = true;
        }
      },
      );
    }
  };

  private getMode = () => {
    if (this.isMount) {
      this.setState({ mode: ActivitiesStore.getMode() });
    }
  };

  private getMenuSelected = () => {
    if (this.isMount) {
      this.setState({
        menuSelected: ActivitiesStore.getMenuTypeSelected(),
      });
    }
  };

  private deletedPP = () => {
    if (this.isMount) {
      ActivitiesActions.emitGetActivities().then(
        () => {
          this.setState({ activities: ActivitiesStore.getActivities() }, () => { this.setSelectedPp(); });
        },
      );
    }
  };

  private updatePPYear = () => {
    if (this.isMount) {
      ActivitiesActions.emitGetActivities().then(
        () => {
          this.setState({ activities: ActivitiesStore.getActivities() }, () => { this.setSelectedPp(true); });
        },
      );
    }
  };

  private getDefaultPpId = () => {
    if (this.isMount) {
      this.setState({ defaultPpId: UserStore.getDefaultPpId() });
    }
  };

  private getSelectedYears = () => {
    if (this.isMount) {
      this.setState({ selectedYears: UserStore.getSelectedYears() });
    }
  };

  /**
   * @description Get array of Bcs and filtering only Assigned activity
   * @param bcs businessChallenge[]
   * @returns businessChallenge[]
   */
  private filterAssigneeActivity = (bcs: BusinessChallenge[]): BusinessChallenge[] => {
    const filteredBcs: BusinessChallenge[] = [];
    for (const bc of bcs) {
      if (bc.isAssignee) {
        filteredBcs.push({
          ...bc,
        });
        continue;
      }
      for (const pi of bc.performanceInitiatives) {
        if (pi.isAssignee || pi.isActionAssignee) {
          const index = filteredBcs.findIndex(b => b.id === bc.id);
          // If bc isn't already in filtered array then add it
          if (index < 0) {
            filteredBcs.push({
              ...bc,
              performanceInitiatives: [pi],
            });
          } else {
            // If bc already in filtered array, add only the pi
            filteredBcs[index].performanceInitiatives.push(pi);
          }
        }
      }
    }
    return filteredBcs;
  };
  //endregion

  //region OTHER CLASS METHODS
  /**
   * Build Menu for BC/PI
   * @return JSX.Element[]
   */
  private buildMenu = (): JSX.Element[] => {
    const idElement = this.props.location.pathname.indexOf('performance-plan') > -1
      ? +this.props.location.pathname.split('/')[3]
      : +this.props.location.pathname.split('/')[2];

    const element = this.state.activities!!.find(el => el.id === idElement);
    const defaultElement = this.state.activities!!.find(el => el.id === this.state.defaultPpId);
    const selectedElement = !!element
      ? element
      : this.state.defaultPpId
        ? !!defaultElement
          ? defaultElement
          : this.state.activities!![0]
        : this.state.activities!![0];

    let bcs = selectedElement.businessChallenges;
    if (selectedElement.linkedBcs) {
      bcs = bcs.concat(selectedElement.linkedBcs);
    }

    if (this.state.isAssigneeFiltered) {
      bcs = this.filterAssigneeActivity(bcs);
    }

    return bcs.map(bc =>
    (
    <MenuBc
      key={`bc${bc.id}`}
      data={bc}
      assigneeFiltered={bc.isAssignee && this.state.isAssigneeFiltered}
      ppId={selectedElement.id}
    />
      ));
  };

  /**
   * Get PP dropdown item
   * @return JSX.Element
   */
  private getPpDropdownItem = (pp: PerformancePlan, selectedPpId: number): JSX.Element => {
    return (
      <Dropdown.Item
        id={pp.id}
        key={`pp-dropdown-item${pp.id}`}
        className={pp.isDraft ? 'pp-draft' : ''}
        value={pp.id}
        content={pp.name}
        description={pp.year}
        selected={selectedPpId === pp.id}
        onClick={() => this.handlePpChange(pp.id)}
      />
    );
  };

  private handlePpChange = (ppId: number) => {
    let currentPpId;
    // Fetch the current PP id from the URL
    if (this.props.location.pathname.indexOf('performance-plan') > -1) {
      currentPpId = +this.props.location.pathname.split('/')[3];
    } else if (this.props.location.pathname.indexOf('business-challenge') > -1
      || this.props.location.pathname.indexOf('performance-initiative') > -1) {
      currentPpId = +this.props.location.pathname.split('/')[2];
    }
    // If the current PP id could not be retrieved or it is different from the next PP id, then navigate to the next PP.
    if (!currentPpId || currentPpId !== ppId) {
      this.props.history.push(`/activities-board/performance-plan/${ppId}`);
    }
  };

  private getDefaultPpOrFirstPp = () : PerformancePlan => {
    if (this.state.defaultPpId) {
      const pp = this.state.activities!!.find((myElement: any) => myElement.id === this.state.defaultPpId);
      if (pp) {
        return pp;
      }
    }
    return this.getCorrectYearPp();
  }

  private getCorrectYearPp = () : PerformancePlan => {
    for (const pp of this.state.activities!!) {
      if (this.state.selectedYears[pp.year]) {
        return pp;
      }
    }
    return this.state.activities!![0];
  }
  public getSelectedPp(): PerformancePlan {
    const urlSplit = this.props.location.pathname.split('/');

    if (urlSplit['length'] < 4) {
      return this.getDefaultPpOrFirstPp();
    }

    const idElement = (this.props.location.pathname.indexOf('performance-plan') > -1)
      ? +urlSplit[3] : +urlSplit[2];

    const element = this.state.activities!!.find((myElement: any) =>
      myElement.id === idElement,
    );
    if (!element) {
      return this.getDefaultPpOrFirstPp();
    }
    return element;
  }

  private setSelectedPp(isUpdatingYear: boolean = false): void {
    let path = '/activities-board/taskboard';
    if (this.state.activities!!['length'] > 0) {
      const selectedPp = this.getSelectedPp();
      path = `/activities-board/performance-plan/${selectedPp.id}`;
    }
    // we don't want to redirect if the setSelectingPp is from a year change
    // we want to stay on the about part
    if (!isUpdatingYear) {
      this.props.history.push(path);
    }
  }

  private onNewPpClick = (): void => {
    this.setState({ showNewPpModal: true });
  };

  private onNewPpClose = (): void => {
    this.setState({ showNewPpModal: false });
  };

  private onClick = (): void => {
    if (this.props.location.pathname.indexOf('performance-plan') === -1) {
      this.setSelectedPp();
    }
  };

  private confirmCancel = (): void => {
    ActivitiesActions.emitChangeMode(ModeTypes.MODE_VIEW);
    this.setSelectedPp();
  };

  private noConfirmCancel = (): void => {
    this.setState({
      isOpenConfirmModal: false,
    });
  };

  private confirmCancelChange = (): void => {
    ActivitiesActions.emitChangeMode(ModeTypes.MODE_VIEW);
    ActivitiesActions.emitGetActivitySelect(TypeActivity.PERFORMANCE_PLAN);
    this.setSelectedPp();
  };

  private noConfirmCancelChange = (): void => {
    this.setState({
      isOpenConfirmModalChangePp: false,
    });
  };

  private switchAssigneeMode = (type: assigneeFilter): void => {
    this.setState({
      isAssigneeFiltered: type === assigneeFilter.ALL ? false : true,
    });
    this.buildMenu();
  }

  // the dropdown should stay open when we click on the checkbox
  private openDropdown = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    if (target.outerHTML.includes('checkbox-year')) {
      this.setState({
        dropdownOpen: true,
      });
    } else {
      this.setState({
        dropdownOpen: !this.state.dropdownOpen,
      });
    }
  }

  // use to close the dropdown when clicking outside
  private onBlurDropdown = () => {
    this.setState({
      dropdownOpen: false,
    });
  }
  //endregion

  private onCloseNewVersionModal = () => {
    const newUser : User = this.state.user;
    // we close the modal for the session
    newUser.isNewVersionModalOpened = false;
    AuthStore.setConnectedUser(newUser);
    this.setState({ isNewVersionModalOpened: false, user: AuthStore.getConnectedUser() });
  }

  public render() {
    if (!AuthStore.getConnectedUser()) {
      return null;
    }

    const currentUser = AuthStore.getConnectedUser();
    const newPPContent: JSX.Element[] = [];
    const cancelQuestionMessage = (
      <FormattedMessage
        id="cancelEditionQuestion"
        defaultMessage="Cancel edition? Any unsaved work will be lost."
      />
    );
    const cancelMessage = <FormattedMessage id="cancelEdition" defaultMessage="Cancel edition" />;

    if (currentUser.role.id === RoleNames.REFERENT || currentUser.isCoreTeam) {
      newPPContent.push(
          <Button
              key={'add-plan-button'}
              id="add-plan-button"
              onClick={this.onNewPpClick}
              disabled={this.state.mode === ModeTypes.MODE_EDITION}
          >
            <span className={'material-icons'}>add_circle_outline</span>
            <FormattedMessage id="menu.newPlan" defaultMessage="NEW PLAN" />
          </Button>,
      );

      if (this.state.showNewPpModal) {
        newPPContent.push(
            <PpCreation
                key={'pp-creation'}
                close={this.onNewPpClose}
                open={this.state.showNewPpModal}
            />,
        );
      }
    }
    const menuTop = (
      <>
        {this.state.isOpenConfirmModal
        && <ConfirmModal
            closeNoFunction={this.noConfirmCancel}
            closeYesFunction={this.confirmCancel}
            title={cancelMessage}
            message={cancelQuestionMessage}
        />
        }
        {this.state.isOpenConfirmModalChangePp
        && <ConfirmModal
            closeNoFunction={this.noConfirmCancelChange}
            closeYesFunction={this.confirmCancelChange}
            title={cancelMessage}
            message={cancelQuestionMessage}
        />
        }
        <div>
          <Button
              id="my-taskboard-button"
              onClick={() => {
                this.props.history.push('/activities-board/taskboard');
              }}
              className={`${this.props.location.pathname.indexOf('taskboard') !== -1 ?
                  'selectedMenu' : 'unselectedMenu'}`}
          >
            <span id={'icon-taskboard'} className={'material-icons'}>dashboard</span>
            <FormattedMessage id="menu.myTaskboard" defaultMessage="MY TASK BOARD"/>
          </Button>
        </div>
      </>
    );
    const menuBottom = (
        <div id="copyright">
          {newPPContent}
          <Image id={'logoFooter'} centered={true} size="small" src={thales_logo}/>
          <div>Made with ♥ by DIGIT.</div>
        </div>
    );

    if (!this.state.activities || this.state.defaultPpId === undefined) {
      return (
        <div id={'menu-activities'} className={'noselect-text'}>
          {menuTop}
          <Loader active={true} size={'large'}/>
          {menuBottom}
        </div>
      );
    }

    const selectedPp = this.getSelectedPp();

    let draftPps: JSX.Element[] = [];
    let notDraftPps: JSX.Element[] = [];

    if (!!this.state.activities && this.state.activities.length > 0) {
      draftPps = this.state.activities.filter(pp => pp.isDraft)
        .filter(pp => this.state.selectedYears[pp.year] || pp.id === this.state.defaultPpId)
        .map(pp => this.getPpDropdownItem(pp, selectedPp.id));
      notDraftPps = this.state.activities.filter(pp => !pp.isDraft)
        .filter(pp => this.state.selectedYears[pp.year] || pp.id === this.state.defaultPpId)
        .map(pp => this.getPpDropdownItem(pp, selectedPp.id));
    }

    return (
      <div id={'menu-activities'} className={'noselect-text'}>
        {menuTop}
        <div className="feedback-container">
          {this.state.isNewVersionModalOpened &&
            <NewVersionModal
              open={this.state.isNewVersionModalOpened}
              onClose={this.onCloseNewVersionModal}
              userId={this.state.user.id}
            />}
        </div>
        { this.state.activities.length !== 0
            ? <div>
              <div className={'performancePlanSelection'}>
                <div
                  className={`pp-selection-button  ${this.props.location.pathname.indexOf('performance-plan') > -1 ?
                    'selectedMenu' : 'unselectedMenu'}`}
                  onClick={() => this.onClick()}
                >
                  <div className="text-div">
                    <div id="selected-plan">
                      <FormattedMessage id="menu.selectedPlan" defaultMessage="Selected Plan" />
                    </div>
                    <div id="menu-title-pp" className="title-main-menu">
                      {selectedPp.name}
                    </div>
                    <div id="menu-year-pp">{selectedPp.year}</div>
                  </div>
                  {selectedPp.isAssignee &&
                  <div className="icon-div">
                    <Icon className="icon" name="id badge"/>
                  </div>}
                </div>
                <Dropdown
                  trigger={<Button className="pp-dropdown" icon="caret down"/>}
                  icon={null}
                  pointing="top right"
                  open={this.state.dropdownOpen}
                  onClick={this.openDropdown}
                  onBlur={this.onBlurDropdown}
                >
                  <Dropdown.Menu>
                    <div id="years-dropdown">
                      <PpYearsFilter userId={this.state.user.id}/>
                    </div>
                    <Dropdown.Divider />
                    {draftPps}
                    {draftPps.length > 0 && notDraftPps.length > 0 && <Dropdown.Divider />}
                    {notDraftPps}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="assignee-toggle">
                <Button
                 className={this.state.isAssigneeFiltered ? '' : 'selected'}
                 size="tiny"
                 attached="left"
                 onClick={() => this.switchAssigneeMode(assigneeFilter.ALL)}
                 compact={true}
                >
                <FormattedMessage id="common.all" defaultMessage="All" />
                </Button>
                <Button
                 className={!this.state.isAssigneeFiltered ? '' : 'selected'}
                 size="tiny"
                 attached="right"
                 onClick={() => this.switchAssigneeMode(assigneeFilter.ASSIGNED)}
                 compact={true}
                >
                  <FormattedMessage id="menu.assigned" defaultMessage="Assigned" />
                </Button>
              </div>
              <div className={'scrollable-container'}>
                <CustomScrollBars
                  style={{
                    height: `calc(100vh - ${
                      (currentUser.role.id === RoleNames.REFERENT || currentUser.isCoreTeam) ? '350px' : '290px'})`,
                  }}
                >
                  <div id={'activities'}>
                    {this.buildMenu()}
                  </div>
                </CustomScrollBars>
              </div>
            </div>
            : <div>
              <span className={'message'}>
                <FormattedMessage id="menu.noActivity" defaultMessage="You are not assigned to any activity." />
              </span>
            </div> }
        {menuBottom}
      </div>
    );
  }
}

export default withRouter(MenuActivities);
