import './menuActivities.scss';

import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Icon, Popup } from 'semantic-ui-react';

import ActionTypes from 'src/constants/actionTypes';
import { MenuTypeSelected } from 'src/constants/typeActivity';
import BusinessChallenge from 'src/models/businessChallenge';
import ActivitiesStore from 'src/stores/activities.store';
import Utils from 'src/utils/utils';
import Colors from '../../../utils/colors';
import MenuPi from './menuPi';
import LinkLabel, { LinkLabelMode } from '../../common/linkLabel/linkLabel';
import { FormattedMessage } from 'react-intl';

interface IProps extends RouteComponentProps {
  data: BusinessChallenge;
  ppId: number;
  assigneeFiltered: boolean;
}

interface IStates {
  isVisible: boolean;
  isSelected: boolean;
  menuSelected: MenuTypeSelected;
  nextBcId: number;
}

export class MenuBc extends React.Component<IProps, IStates> {
  private isMount = false;

  constructor(props: any) {
    super(props);
    this.state = {
      isVisible: true,
      isSelected: ActivitiesStore.getSelectedActivityId() === this.props.data.id
        && Utils.isActivityBc(),
      nextBcId: -1,
      menuSelected: MenuTypeSelected.MENU_ACTIVITY,
    };
  }

  componentDidUpdate(previousProps) {
    if (previousProps.assigneeFiltered !== this.props.assigneeFiltered) {
      this.setState({ isVisible: !this.props.assigneeFiltered });
    }
  }

  public componentWillMount() {
    this.isMount = true;
    ActivitiesStore.addListener(ActionTypes.ACTIVITY_SELECTED.toString(), this.getIdActivitySelected);
    ActivitiesStore.addListener(ActionTypes.MENU_TYPE_SELECTED.toString(), this.getMenuSelected);
  }

  public componentWillUnmount() {
    this.isMount = false;
    ActivitiesStore.removeListener(ActionTypes.ACTIVITY_SELECTED.toString(), this.getIdActivitySelected);
    ActivitiesStore.removeListener(ActionTypes.MENU_TYPE_SELECTED.toString(), this.getMenuSelected);
  }

  private getMenuSelected = () => {
    if (this.isMount) {
      this.setState({ menuSelected: ActivitiesStore.getMenuTypeSelected() });
    }
  };

  private getIdActivitySelected = () => {
    if (this.isMount) {
      this.setState({
        isSelected: ActivitiesStore.getSelectedActivityId() === this.props.data.id && Utils.isActivityBc(),
      });
    }
  };

  public onClick = (target: any) => {
    if (target.id !== 'icon-menu-bc') {
      const isCurrentActivityBc = this.props.location.pathname.indexOf('business-challenge') > -1;
      const currentBcId = isCurrentActivityBc ? +this.props.location.pathname.split('/')[4] : null;

      if (!isCurrentActivityBc || (!!currentBcId && currentBcId !== this.props.data.id)) {
        this.props.history.push(`/activities-board/${this.props.ppId}/business-challenge/${this.props.data.id}`);
      }
    }
  };

  public setBCBadge(): React.CSSProperties {
    if (!this.props.data.status || this.props.data.isClosed) return { borderLeft: `4px solid ${Colors.grey}` };

    switch (this.props.data.status) {
      case 1:
        return { borderLeft: `4px solid ${Colors.critical}` };
      case 2:
        return { borderLeft: `4px solid ${Colors.atRisk}` };
      case 3:
        return { borderLeft: `4px solid ${Colors.confident}` };
      default:
        return { borderLeft: `4px solid ${Colors.grey}` };
    }
  }

  public render() {
    let pis = this.props.data.performanceInitiatives;
    if (this.props.data.linkedPis) {
      pis = pis.concat(this.props.data.linkedPis);
    }

    let className = 'activities-menu-item bc-menu-item';
    if (this.props.location.pathname.indexOf(`business-challenge/${this.props.data.id}`) > -1) {
      className = `${className} selectedMenu`;
    } else {
      className = `${className} unselectedMenu`;
    }

    if (this.props.data.isClosed) className = `${className} closed`;

    return (
      <div id="menu-bc-render">
        <div
          style={this.setBCBadge()}
          className={className}
          onClick={e => this.onClick(e.target)}
        >
          {this.props.data.performanceInitiatives.length !== 0
            && <Icon
              id="icon-menu-bc"
              key={this.props.data.id}
              className={(this.state.isVisible) ? 'icon-up' : 'icon-down'}
              name="angle up"
              onClick={() => this.setState({ isVisible: !this.state.isVisible })}
            />
          }
          <div className="menu-title-bc">
            {this.props.data.isLinked
              ? <LinkLabel mode={LinkLabelMode.ICON_ONLY} />
              : <span>BC{Utils.leadingZero(this.props.data.code)}</span>}
          </div>
          <div className="menu-name-bc">
            {this.props.data.name}
          </div>
          {this.props.data.isAssignee &&
            <Popup
              inverted={true}
              content={<FormattedMessage id="menu.bcAssigned" defaultMessage="Assigned on the BC" />}
              size="tiny"
              trigger={
                <div className="icon-div">
                  <Icon name="id badge"/>
                </div>
              }
            />
          }
        </div>
        <div className={this.state.isVisible ? '' : 'hidden-AB'}>
          {pis.map(pi => <MenuPi key={`pi${pi.id}`} data={pi} ppId={this.props.ppId} />)}
        </div>
      </div>
    );
  }
}

export default withRouter(MenuBc);
