import React, { Component } from 'react';
import { Button, Icon, Popup, Table } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import Utils from '../../../../../../utils/utils';
import ModeTypes from '../../../../../../constants/modeTypes';
import Metric, { MetricValueGet } from '../../../../../../models/metric';
import MetricRow from './metricRow/metricRow';
import TypeActivity from '../../../../../../constants/typeActivity';
import { FieldType, FieldValues } from '../../metricsContainer';
import { ErrType } from '../../../../../../constants/errors/typeError';
import businessChallengeStore from 'src/stores/businessChallenge.store';
import { ComparisonType } from '../../../../../../constants/comparisonType';
import { DEFAULT_METRIC } from '../../shared/constants/defaultMetric';

interface IProps {
  mode: ModeTypes;
  selectedMetricIndex: number;
  metrics: Metric[];
  type: TypeActivity;
  forActivityCreation: boolean;
  onDataChange(idAttribute: string, value: any, text?: any): void;
  handleRowClick(index: number): void;
  updateSubFormValidation(isValid: boolean, subForm: string): void;
}

interface IStates {
  importMilestoneModalOpen: boolean;
}
export default class MetricsTable extends Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      importMilestoneModalOpen: false,
    };
  }

  //region REACT LIFECYCLE METHODS
  componentDidMount() {
    setTimeout(
      () => this.props.updateSubFormValidation(this.isMetricsFormValid(this.props.metrics), 'metrics'),
      0,
    );
  }
  //endregion

  //region METRICS MANAGEMENT
  getMetricsTable = () : JSX.Element[] => {
    const domElement: JSX.Element[] = [];
    if (this.props.metrics !== undefined) {
      this.props.metrics.forEach((metric: Metric, index: number) => {
        domElement.push(
          <MetricRow
            key={`metric${metric.id}-${index}`}
            type={this.props.type}
            metric={metric}
            index={index}
            handleRowClick={this.props.handleRowClick}
            handleChangeMetric={this.handleChangeMetric}
            removeMetric={this.removeMetric}
            mode={this.props.mode}
            selectedMetricIndex={this.props.selectedMetricIndex}
            forActivityCreation={this.props.forActivityCreation}
            updateMetricValue={this.updateMetricValue}
            removeMetricValue={this.removeMetricValue}
          />);
      });
    }
    if (Utils.isOnEditMode(this.props.mode)) {
      domElement.push(
        <Table.Row key="newMetric" className="add-button">
          <Table.Cell colSpan={8}>
            <Button className="add-row-button" onClick={this.addMetric}>
              <Button.Content>
                <Icon name="add" />
                <FormattedMessage id="createNewMetric" defaultMessage="Add a new metric" />
              </Button.Content>
            </Button>
            {!this.props.forActivityCreation && Utils.isCurrentActivityTypeBC() &&
              <Button className="add-row-button" onClick={() => this.openImportModal()}>
                <Button.Content>
                  <Icon name="download" />
                  <FormattedMessage id="importModal.metric.title" defaultMessage="Import a metric" />
                </Button.Content>
              </Button>
            }
          </Table.Cell>
        </Table.Row>,
      );
    }
    return domElement;
  }

  openImportModal = () => {
    this.setState({
      importMilestoneModalOpen: true,
    });
  }

  closeImportModal = () => {
    this.setState({
      importMilestoneModalOpen: false,
    });
  }

  addMetric = () => {
    let metrics : Metric[] = [];
    if (!!this.props.metrics) {
      metrics = [...this.props.metrics];
    }
    metrics.push({ ...DEFAULT_METRIC });
    this.props.onDataChange('metrics', metrics);
    this.props.updateSubFormValidation(false, 'metrics');
  };

  handleChangeMetric = (index: number, { name, value }: FieldValues) => {
    let metrics = [...this.props.metrics];
    if (!metrics) {
      metrics = [];
    }

    let filteredValue = value;
    if (name === 'unit' && (value === '' || value === '-')) {
      filteredValue = null;
    }

    if (name === 'comparisonType' && value !== ComparisonType.EQUAL) {
      metrics[index].comparisonTolerance = 0;
    }

    metrics[index][name] = filteredValue;
    this.props.onDataChange('metrics', metrics);

    if (name === 'name' || name === 'comparisonTolerance') {
      this.props.updateSubFormValidation(this.isMetricsFormValid(metrics), 'metrics');
    }
  };

  removeMetric = (index: number) => {
    const currentMetrics = [...this.props.metrics];
    const removed = currentMetrics.splice(index, 1);
    this.removeLinkdMetrics(removed[0]);
    this.props.onDataChange('metrics', currentMetrics);
    this.props.updateSubFormValidation(this.isMetricsFormValid(currentMetrics), 'metrics');
  }

  removeLinkdMetrics = (metric) => {
    if (metric.isFromLinked) {
      const bc = businessChallengeStore.getBusinessChallenge();
      if (bc && bc.linkedBcLinkMetrics) {
        const linkedBcLinkMetrics = bc.linkedBcLinkMetrics.filter(bclm => bclm.id !== metric.id);
        this.props.onDataChange('linkedBcLinkMetrics', linkedBcLinkMetrics);
      }
    }
  }
  //endregion

  //region METRIC VALUES MANAGEMENT
  updateMetricValue = (updateMv: MetricValueGet, mIndex: number | null, mvIndex: number | null,
                       callback?: () => void) => {
    let metrics = [...this.props.metrics];
    if (!metrics) {
      metrics = [];
    }

    // if the metric value only needs to be updated
    if (mvIndex !== null && mIndex !== null) {
      metrics[mIndex].metricValues[mvIndex] = updateMv;

      // if the metric value needs to be added to the metric
    } else if (mvIndex === null && mIndex !== null) {
      updateMv.metricId = metrics[mIndex].id;
      metrics[mIndex].metricValues.push(updateMv);
      callback && callback();
    }

    this.props.onDataChange('metrics', metrics);
    this.props.updateSubFormValidation(this.isMetricsFormValid(metrics), 'metrics');
  }

  public removeMetricValue = (mvIndex: number, mIndex: number) => {
    const currentMetrics = [...this.props.metrics];
    currentMetrics[mIndex].metricValues.splice(mvIndex, 1);
    this.props.onDataChange('metrics', currentMetrics);
    this.props.updateSubFormValidation(this.isMetricsFormValid(currentMetrics), 'metrics');
  }
  //endregion

  //region METRICS VALIDATION
  // Check if every metric has a non-empty name
  areMetricNamesValid = (metrics: Metric[]) => {
    return metrics.every(metric => !Utils.isValueNullOrEmpty(metric.name)
      && !Utils.isFieldOnError(metric.name, true, FieldType.STRING));
  }

  // Check if every metric has at least one value
  areMetricNotEmpty = (metrics: Metric[]) => {
    return metrics.every(metric => metric.metricValues.length !== 0);
  }

  // Check if every metric has a comparison tolerance
  areMetricComparisonToleranceValid = (metrics: Metric[]) => {
    return metrics.every(metric => metric.comparisonTolerance !== undefined);
  }

  // Check if every metric has each of its metric values' current are correct
  areMetricValuesCurrentValid = (metrics: Metric[]) => {
    return metrics.every(metric => !metric.metricValues.some(mv =>
      Utils.isFieldOnError(mv.current, false, FieldType.NUMBER),
    ));
  }

  // Check if every metric has each of its metric values' targets are correct
  areMetricValuesTargetValid = (metrics: Metric[]) => {
    return metrics.every((metric) => {
      if (metric.metricValues.length > 0) {
        return !metric.metricValues.some(mv => Utils.isFieldOnError(mv.target, true, FieldType.NUMBER));
      }

      return !Utils.isFieldOnError(metric.mostRecentValues.target, true, FieldType.NUMBER);
    });
  }

  // Check if every metric has each of its metric values' update date are correct
  areMetricValuesDateValid = (metrics: Metric[]) => {
    return metrics.every(metric => !metric.metricValues.some((mv) => {
      return Utils.isValueNullOrEmpty(mv.updateDate) || !Utils.isDateFormatLanguageValid(mv.updateDate);
    }));
  }

  isMetricsFormValid = (metrics: Metric[]) => {
    return this.areMetricNamesValid(metrics)
      && this.areMetricNotEmpty(metrics)
      && this.areMetricComparisonToleranceValid(metrics)
      && this.areMetricValuesCurrentValid(metrics)
      && this.areMetricValuesTargetValid(metrics)
      && this.areMetricValuesDateValid(metrics);
  };
  //endregion

  render() {
    return (
      <div className="metrics-table">
        <Table>
          <Table.Body>
            <Table.Row className="header-row">
              <Table.HeaderCell className="table-title">
                <FormattedMessage id="metric" defaultMessage="KPI" />
                <span className="error-container">
                  {(!this.areMetricNamesValid(this.props.metrics) && Utils.isOnEditMode(this.props.mode))
                    && Utils.getErrorMessageWithPopup(ErrType.ME_NAME)}
                </span>
              </Table.HeaderCell>
              {Utils.isOnEditMode(this.props.mode) &&
                <Table.HeaderCell>
                  <FormattedMessage id="metrics.updateDate" defaultMessage="Date of Current Value" />
                  <span className="error-container">
                    {!this.areMetricValuesDateValid(this.props.metrics)
                      && Utils.isOnEditMode(this.props.mode)
                      && Utils.getErrorMessageWithPopup(ErrType.MEV_DATE)}
                  </span>
                </Table.HeaderCell>
              }
              <Table.HeaderCell>
                <FormattedMessage id="current" defaultMessage="Current Value" />
                <span className="error-container">
                  {!this.areMetricValuesCurrentValid(this.props.metrics)
                    && Utils.isOnEditMode(this.props.mode)
                    && Utils.getErrorMessageWithPopup(ErrType.MEV_CURRENT)}
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="target" defaultMessage="Target" />
                <span className="error-container">
                  {!this.areMetricValuesTargetValid(this.props.metrics)
                    && Utils.isOnEditMode(this.props.mode)
                    && Utils.getErrorMessageWithPopup(ErrType.MEV_TARGET)}
                </span>
              </Table.HeaderCell>
              {Utils.isOnEditMode(this.props.mode) &&
                <Table.HeaderCell>
                  <FormattedMessage id="unit" defaultMessage="Unit" />
                </Table.HeaderCell>
              }
              <Table.HeaderCell
                className={`comparison-type-header ${Utils.isOnEditMode(this.props.mode) ? 'edit' : ''}`}
              >
                <Popup
                  id="metric-comparison-popup"
                  content={(
                    <div>
                      <FormattedMessage
                        id="comparison.desc"
                        defaultMessage="Value/target comparison indicates the status of the metric's value depending on its target."
                      />
                      <br />
                      <FormattedMessage
                        id="comparison.fourTypes"
                        defaultMessage="There are four types of comparison:"
                      />
                      <ul>
                        <li>
                          <span className="uppercase">
                            <FormattedMessage id="comparison.up" defaultMessage="Increasing" />
                          </span>
                          &nbsp;-&nbsp;
                          <FormattedMessage id="comparison.upDesc" defaultMessage="The value should be higher than the target" />
                        </li>
                        <li>
                          <span className="uppercase">
                            <FormattedMessage id="comparison.down" defaultMessage="Decreasing" />
                          </span>
                          &nbsp;-&nbsp;
                          <FormattedMessage id="comparison.downDesc" defaultMessage="The value should be lower than the target" />
                        </li>
                        <li>
                          <span className="uppercase">
                            <FormattedMessage id="comparison.equal" defaultMessage="Equal" />
                          </span>
                          &nbsp;-&nbsp;
                          <FormattedMessage id="comparison.equalDesc" defaultMessage="The value should be at the target, +/- tolerance range" />
                        </li>
                        <li><FormattedMessage id="comparison.noComparison" defaultMessage="No comparison" /></li>
                      </ul>
                    </div>
                  )}
                  position="top center"
                  size="small"
                  trigger={<Icon className="comparison-info" name="info circle" />}
                />
                <span className="error-container">
                  {(!this.areMetricComparisonToleranceValid(this.props.metrics) && Utils.isOnEditMode(this.props.mode))
                  && Utils.getErrorMessageWithPopup(ErrType.ME_COMPARISON_TOLERANCE)}
                </span>
              </Table.HeaderCell>
            </Table.Row>
            {this.getMetricsTable()}
          </Table.Body>
        </Table>
      </div>
    );
  }
}
