import './activityImport.scss';

import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, Modal, Step } from 'semantic-ui-react';
import BusinessChallenge from 'src/models/businessChallenge';
import PerformanceInitiative from 'src/models/performanceInitiative';
import NotificationActions from 'src/actions/notification-actions';
import PerformancePlanActions from 'src/actions/performancePlan.action';
import { ToastType } from '../../common/toast/toast';
import ActivitiesActions from 'src/actions/activities.action';
import Utils from 'src/utils/utils';
import TypeActivity from '../../../constants/typeActivity';

interface IProps extends RouteComponentProps {
  title: string;
  steps: ({ title: string, subtitle: string, icon?: string }[]);
  submit(): Promise<BusinessChallenge | PerformanceInitiative>;
  close: () => void;
  isSubmitDisabled?: boolean;
  isNavigationDisabled?: boolean;
  onStepChange(stepNumber: number, isNextStep: boolean): void;
  typeActivity : TypeActivity;
  redirectToNewActivity(activityId: number): void;
}

interface IStates {
  step: any;
  isSubmitLoading: boolean;
}

/**
 * Creates a new import activity form
 * @param {string} title
 * @param {{ title: string, subtitle: string, icon?: string }[]} steps
 */
class ActivityImport extends React.Component<IProps, IStates> {

  private initialState: IStates = {
    step: 1,
    isSubmitLoading: false,
  };

  public constructor(props: IProps) {
    super(props);
    this.state = this.initialState;
  }

  /**
   * Actions triggered on the Previous button click
   */
  public onPreviousClick = () => {
    if (this.state.step > 1) {
      this.setState(
        { step: this.state.step - 1 },
        () => this.props.onStepChange(this.state.step, false),
      );
    }
  };

  /**
   * Actions triggered on the Next button click
   */
  public onNextClick = () => {
    if (this.state.step < this.props.steps.length) {
      this.setState(
        { step: this.state.step + 1 },
        () => this.props.onStepChange(this.state.step, true),
      );
    }
  };

  /**
   * Close the modal and reset the state
   */
  public handleClose = () => {
    this.props.close();
    this.clear();
  };

  /**
   * Submit the form
   */
  public submit = () => {
    this.setState({ isSubmitLoading: true });
    return this.props.submit()
        .then((el) => {
          PerformancePlanActions.invalidateBcs();
          ActivitiesActions.emitGetActivities().then(() => {
            this.props.close();
            this.setState({ isSubmitLoading: false });

            NotificationActions.toast(
              <FormattedMessage id="saved" defaultMessage="Saved!" />,
              Utils.isActivityBc(this.props.typeActivity)
                ? (
                  <FormattedMessage
                    id="successBcCreation"
                    defaultMessage="The Business Challenge has been successfully created"
                  />
                )
                : (
                  <FormattedMessage
                    id="successPiCreation"
                    defaultMessage="The Performance Initiative has been successfully created"
                  />
                ),
              ToastType.SUCCESS,
            );

            this.props.redirectToNewActivity(el.id);
          });
        })
        .catch(() => {
          Utils.toastError();
        });
  };

  /**
   * Reset the component state
   */
  public clear = () => {
    this.setState({ ...this.initialState });
  };

  /**
   * Render the menu steps sent by the parent component
   * @returns JSX.Element[]
   */
  public renderSteps() {
    const steps: JSX.Element[] = [];
    this.props.steps.forEach((step, i) => {
      steps.push(
        <Step key={i} active={this.state.step === i + 1}>
          {this.state.step.icon ? <Icon name={this.state.step.icon} /> : null}
          <Step.Content>
            <Step.Title>{step.title}</Step.Title>
            <Step.Description>{step.subtitle}</Step.Description>
          </Step.Content>
        </Step>,
      );
    });
    return (
      <Step.Group id="progress" vertical={true}>
        {steps}
      </Step.Group>
    );
  }

  public render() {
    return (
      <Modal
        open={true}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        className="import-modal-container"
      >
        <Modal.Content id="import-modal-content">
          <Modal.Header>
            <h1>{this.props.title}</h1>
          </Modal.Header>
          <div id="import-modal-content-container">
            {this.renderSteps()}
            <div id="import-modal-child">
              {this.props.children && this.props.children instanceof Array
                ? this.props.children[this.state.step - 1]
                : this.props.children
              }
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button id="button-left" onClick={this.handleClose}>
            <FormattedMessage id="cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            color="grey"
            disabled={this.state.step === 1}
            onClick={this.onPreviousClick}
          >
            <Icon name="chevron left" />
            <FormattedMessage id="previous" defaultMessage="Previous" />
          </Button>
          {this.state.step !== this.props.steps.length
            ? <Button
              id={'next'}
              primary={true}
              onClick={this.onNextClick}
              disabled={this.props.isNavigationDisabled}
            >
              <FormattedMessage id="next" defaultMessage="Next"/> <Icon name="chevron right" />
            </Button>
            : <Button
              id={'submit'}
              primary={true}
              loading={this.state.isSubmitLoading}
              onClick={this.submit}
              disabled={this.props.isNavigationDisabled || this.props.isSubmitDisabled || this.state.isSubmitLoading}
            >
              <FormattedMessage id="submit" defaultMessage="Submit" />
              <Icon name="checkmark" />
            </Button>
          }
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withRouter(ActivityImport);
