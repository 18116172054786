import './assignationEmailModal.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Checkbox, Input, Modal } from 'semantic-ui-react';

import ActionTypes from 'src/constants/actionTypes';
import Utils from '../../../../utils/utils';
import UserStore from '../../../../stores/user.store';
import UserAPI from '../../../../api/user.api';
import { AssignationForEmailDTO, AssignationEmailDTO, AssignedUser } from '../../../../models/user';
import NotificationActions from '../../../../actions/notification-actions';
import { ToastType } from '../../../common/toast/toast';
import CustomScrollBars from '../../../common/customScrollBars/customScrollBars';

interface IProps {
  ppIsDraft: boolean;
}

interface IStates {
  selectedUsers: number[];
  isInvitedUserModalOpened: boolean;
  customMessage: string;
  isSubmitLoading: boolean;
}

export default class AssignationEmailModal extends React.Component<IProps, IStates> {

  private isMount: boolean = false;
  private MESSAGE_MAX_LENGTH = 100;
  static isDraft: boolean = false;
  static userAssigned: AssignedUser[] | null ;

  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedUsers: [],
      isInvitedUserModalOpened: UserStore.getAssignedUsers().length > 0 && !this.props.ppIsDraft,
      customMessage: '',
      isSubmitLoading: false,
    };
  }

  public static popUpEmailValidationButtonDraft(listAssigned: AssignedUser[] | null): void {
    AssignationEmailModal.isDraft = true;
    AssignationEmailModal.userAssigned = listAssigned;
  }

  public componentWillMount() {
    this.isMount = true;
    UserStore.addListener(ActionTypes.INVITED_USERS.toString(), this.openInvitedUsersModal);
  }

  public componentWillUnmount() {
    this.isMount = false;
    UserStore.removeListener(ActionTypes.INVITED_USERS.toString(), this.openInvitedUsersModal);
  }

  private openInvitedUsersModal = () => {
    if (this.isMount) {
      const users = UserStore.getAssignedUsers();
      this.setState({
        selectedUsers: users.map(user => user.id),
        isInvitedUserModalOpened: users.length > 0 && !this.props.ppIsDraft,
      });
    }
  };

  private onMessageChange = (value: string) => {
    if (value.length <= this.MESSAGE_MAX_LENGTH) this.setState({ customMessage: value });
  }

  private closeModal = () => {
    AssignationEmailModal.isDraft = false;
    this.setState({ customMessage: '', isInvitedUserModalOpened: false });
    UserStore.resetAssignedUsers();
  }

  private sendMail = () => {
    AssignationEmailModal.isDraft = false;
    this.setState(
      { isSubmitLoading: true },
      () => {
        const users = UserStore.getAssignedUsers();
        const assignations: AssignationForEmailDTO[] = users
          .filter(user => this.state.selectedUsers.some(el => el === user.id))
          .map(user => new AssignationForEmailDTO(user));
        UserAPI.sendInviteMail(new AssignationEmailDTO(assignations, this.state.customMessage))
          .then(() => {
            const title = <FormattedMessage id="userInvited.toastTitle" defaultMessage="Invitation sent!" />;
            const desc = users.length === 1
              ? (
                <FormattedMessage
                  id="userInvited.toastMessage.singleUser"
                  defaultMessage="The invitation has been sent to the user successfully"
                />
              )
              : (
                <FormattedMessage
                  id="userInvited.toastMessage.severalUser"
                  defaultMessage="The invitation has been sent to the users successfully"
                />
              );

            NotificationActions.toast(title, desc, ToastType.SUCCESS);
            this.setState({ isSubmitLoading: false }, this.closeModal);
          })
          .catch(() => {
            Utils.toastError();
            this.setState({ isSubmitLoading: false }, this.closeModal);
          });
      },
    );
  }

  private toggleUserSelection = (userId: number, index: number) => {
    const selectedUsers = [...this.state.selectedUsers];
    index === -1 ? selectedUsers.push(userId) : selectedUsers.splice(index, 1);
    this.setState({ selectedUsers });
  };

  private getUserCheckbox(user: AssignedUser) {
    const index = this.state.selectedUsers.findIndex(el => el === user.id);
    return (
      <div key={`user-selection-${user.id}`} className="checkbox-container">
        <Checkbox
          label={user.email}
          checked={index > -1}
          onChange={() => this.toggleUserSelection(user.id, index)}
        />
      </div>
    );
  }

  public render() {
    const users = AssignationEmailModal.isDraft && AssignationEmailModal.userAssigned !== null ?
        AssignationEmailModal.userAssigned : UserStore.getAssignedUsers();
    users.sort((a, b) => a.email > b.email ? 1 : -1);
    let desc;
    let concernedUser;

    if (users.length === 1) {
      desc = (
        <FormattedMessage
          id="userInvited.desc.singleUser"
          defaultMessage="You have just assigned a user, and an invitation email can be sent to him/her."
        />
      );
      concernedUser = <FormattedMessage id="userInvited.concernedUsers" defaultMessage="Concerned users:" />;
    } else {
      desc = (
        <FormattedMessage
          id="userInvited.desc.severalUsers"
          defaultMessage="You have just assigned users, and an invitation email can be sent to them."
        />
      );
      concernedUser = <FormattedMessage id="userInvited.concernedUsers" defaultMessage="Concerned user:" />;
    }

    return (
      <Modal
        id="invited-users-modal"
        open={AssignationEmailModal.isDraft ? true : this.state.isInvitedUserModalOpened}
        closeOnDimmerClick={false}
        closeOnEscape={false}
      >
        <Modal.Header>
          <FormattedMessage id="userInvited.modalTitle" defaultMessage="Sending invitation email" />
        </Modal.Header>
        <Modal.Content id="invited-users-content">
          <div className="desc-container">
            {desc}
          </div>

          <FormattedMessage id="userInvited.question" defaultMessage="Would you like to add a message to this email?" />

          <div className="user-list-container">
            <span className="label">
              {concernedUser}
            </span>

            <CustomScrollBars id="user-list-scrollbar" autoHeight={true}>
              <div id="user-list">
                {users.map(user => this.getUserCheckbox(user))}
              </div>
            </CustomScrollBars>
          </div>

          <span className="message">
            <FormattedMessage id="userInvited.messageLabel" defaultMessage="Message" />
            ({this.state.customMessage.length}/{this.MESSAGE_MAX_LENGTH})
          </span>
          <br />
          <Input
            value={this.state.customMessage}
            onChange={(e, { value }) => this.onMessageChange(value)}
          />
        </Modal.Content>
        <Modal.Actions id="invited-users-actions">
          <Button onClick={this.closeModal}>
            <FormattedMessage id="userInvited.closeModal" defaultMessage="Close and do not send invitation" />
          </Button>
          <Button
            onClick={this.sendMail}
            primary={true}
            loading={this.state.isSubmitLoading}
            disabled={this.state.isSubmitLoading || this.state.selectedUsers.length === 0}
          >
            <FormattedMessage id="userInvited.sendInvitation" defaultMessage="Send invitation" />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
