import * as React from 'react';
import Scrollbars, { ScrollbarProps } from 'react-custom-scrollbars';

interface IProps extends ScrollbarProps {
  customRef?: string | ((instance: Scrollbars | null) => void) | React.RefObject<Scrollbars> | null | undefined;
}

export default class CustomScrollBars extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    return (
      <Scrollbars
        id={this.props.id}
        className={`custom-scrollbars ${this.props.className}`}
        style={this.props.style}
        ref={this.props.customRef}

        renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
        renderTrackVertical={props => <div {...props} className="track-vertical"/>}
        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
        renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
        renderView={props => <div {...props} className="view"/>}

        // Add here ScrollbarProps
        autoHeight={this.props.autoHeight}
        autoHeightMax={this.props.autoHeightMax}
        onScrollFrame={this.props.onScrollFrame}
      >
        {this.props.children}
      </Scrollbars>
    );
  }
}
