import './milestonesList.scss';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import TypeActivity from '../../../../../constants/typeActivity';
import Utils from '../../../../../utils/utils';
import Milestone from '../../../../../models/milestone';
import PerformanceInitiativeAPI from '../../../../../api/performanceInitiative.api';
import NotificationActions from '../../../../../actions/notification-actions';
import { ToastType } from '../../../../common/toast/toast';
import { defaultNewMilestone } from '../../../../../constants/milestones';
import moment from 'moment';
import BusinessChallengeAPI from '../../../../../api/businessChallenge.api';
import Scrollbars from 'react-custom-scrollbars';
import ActionTypes from '../../../../../constants/actionTypes';
import MilestoneStore from '../../../../../stores/milestone.store';
import MilestoneAction from '../../../../../actions/milestone.action';
import { CommonDateField } from '../../../../../constants/fields';
import MilestoneTile from '../milestoneTile/milestoneTile';
import NotificationUtils from '../../../../../utils/notification.utils';
import Dispatcher from '../../../../../dispatcher';
import ActionActions from 'src/actions/actions.action';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Ref, Segment } from 'semantic-ui-react';
import { CodeOrderBody, DraggableData } from 'src/constants/order';
import CustomScrollBars from 'src/components/common/customScrollBars/customScrollBars';

interface IRouteProps {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  activityId: number;
  activityType: TypeActivity;
  parentPpId: number;
  isCreate: boolean;
  canEditOrderMil: boolean;
  toggleCreationMode(): void;
}

interface IStates {
  milestones: Milestone[];
  newMilestone: Milestone;
  oldMilestones: Milestone[];
  milestoneFormValid: { isTargetDateValid: boolean, isNameValid: boolean };
  shouldResetActions: boolean;
  isLoading : boolean;
}

class MilestonesList extends React.Component<IProps, IStates> {
  private isMount: boolean = false;
  private scrollbarsRef = React.createRef<Scrollbars>();

  public constructor(props: IProps & InjectedIntlProps) {
    super(props);

    this.state = {
      milestones: [],
      newMilestone: defaultNewMilestone,
      oldMilestones: [],
      milestoneFormValid: { isTargetDateValid: true, isNameValid: false },
      shouldResetActions: false,
      isLoading: false,
    };
  }

  public componentDidMount() {
    this.isMount = true;
    MilestoneStore.addListener(ActionTypes.MILESTONE_CREATION.toString(), this.createMilestone);
    MilestoneStore.addListener(ActionTypes.LINK_MILESTONE_TO_BC.toString(),
                               this.addLinkedPiMilestoneToMilestoneState);
    this.getMilestones();
  }

  public componentWillUnmount() {
    this.isMount = false;
    MilestoneStore.removeListener(ActionTypes.MILESTONE_CREATION.toString(), this.createMilestone);
    MilestoneStore.removeListener(ActionTypes.LINK_MILESTONE_TO_BC.toString(),
                                  this.addLinkedPiMilestoneToMilestoneState);
  }

  //region functions
  private getMilestones() {
    if (this.isMount) {
      if (Utils.isActivityPi(this.props.activityType)) {
        PerformanceInitiativeAPI.getPerformanceInitiativeMilestones(this.props.activityId)
          .then((milestones) => {
            milestones.forEach((mi) => {
              mi.activityMilestone = TypeActivity.PERFORMANCE_INITIATIVE;
            });
            ActionActions.getLinkedActions(milestones);
            this.setState({ milestones }, () => this.scrollToMilestone());
            Dispatcher.dispatch({
              milestones,
              actionType: ActionTypes.GET_MILESTONES,
            });
          });
      } else {
        BusinessChallengeAPI.getBusinessChallengeMilestones(this.props.activityId)
          .then((milestones) => {
            milestones.bcMilestones.forEach((mi) => {
              mi.activityMilestone = TypeActivity.BUSINESS_CHALLENGE;
            });
            milestones.piLinkedMilestones.forEach((mi) => {
              mi.activityMilestone = TypeActivity.PERFORMANCE_INITIATIVE;
            });
            const allBcMilestones = [...milestones.bcMilestones, ...milestones.piLinkedMilestones]
              .sort((a, b) => a.code - b.code);
            this.setState({ milestones: allBcMilestones }, () => this.scrollToMilestone());
            // force a refresh of the drag and drop index
            if (allBcMilestones.length > 0 && this.props.canEditOrderMil) {
              this.onMovedMil({
                draggableId: 1,
                source: { droppableId: `${this.state.milestones[0].id}`, index: 1 },
                destination: { droppableId: `${this.state.milestones[0].id}`, index: 1 },
              });
            }
          });
      }
    }
  }

  public scrollToMilestone = () => {
    let milestoneId;
    if (this.props.history.location.search.includes('BC_MILESTONES')) {
      const indexActivity = this.props.history.location.pathname.search('activity');
      milestoneId = +this.props.history.location.pathname.slice(indexActivity + 9);
    }

    if (this.props.isCreate) {
      milestoneId = null;
    }

    if (milestoneId || milestoneId === null) {
      const index = this.props.isCreate ?
        0 : this.state.milestones.findIndex(at => at.id === parseInt(milestoneId, 10));
      const milestones = this.state.milestones[index];
      if (milestones) {
        setTimeout(() => {
          if (!!this.scrollbarsRef.current) {
            this.scrollbarsRef.current.scrollTop(index * 120);
          }
        });
      }
    }
  }

  private addLinkedPiMilestoneToMilestoneState = () => {
    const milestoneToLinkId = MilestoneStore.getMilestoneToLinkId();
    const milestonePiId = MilestoneStore.getMilestonePerformanceInitiativeId();
    PerformanceInitiativeAPI.getPerformanceInitiativeMilestoneById(milestonePiId, milestoneToLinkId)
      .then((mi) => {
        mi.activityMilestone = TypeActivity.PERFORMANCE_INITIATIVE;
        mi.piId = milestonePiId;
        mi.code = this.state.milestones.length + 1;
        const milestones = [...this.state.milestones, mi].sort((a, b) => a.code - b.code);
        this.setState({ milestones });
      });
  }

  public saveMilestonesInState = (milestone: Milestone) => {
    const milestones = [...this.state.milestones];
    const mIndex = milestones.findIndex(m => m.id === milestone.id);

    if (mIndex > -1) {
      milestones[mIndex] = milestone;
      this.setState({ milestones });
    }
  };

  private save = async (data: Milestone) => {
    let milestone = data;
    if (milestone.targetDate) {
      milestone = Utils.parseDateStringToDate(milestone, CommonDateField.TARGET_DATE);
    }
    if (milestone.completionDate) {
      milestone = Utils.parseDateStringToDate(milestone, CommonDateField.COMPLETION_DATE);
    }

    if (milestone.id === null) {
      const allMilestones = [...this.state.milestones];
      allMilestones.shift();

      milestone.code = allMilestones.length + 1;

      let request;
      switch (this.props.activityType) {
        case TypeActivity.BUSINESS_CHALLENGE:
          request = BusinessChallengeAPI.postBusinessChallengeMilestones(this.props.activityId, [milestone]);
          break;
        case TypeActivity.PERFORMANCE_INITIATIVE:
          request = PerformanceInitiativeAPI.createMilestones(this.props.activityId, [milestone]);
          break;
        default:
          break;
      }

      request.then((milestonePostReturn: { milestoneId: number }) => {
        milestone.id = milestonePostReturn.milestoneId;
        const newMilestoneArray = [...allMilestones, milestone]
              .sort((a, b) => a.code - b.code);
        this.props.toggleCreationMode();
        this.setState({ milestones: newMilestoneArray }, () => {
          NotificationUtils.sendActivityCreatedToast(TypeActivity.MILESTONE);
          if (Utils.isActivityPi(this.props.activityType)) {
            MilestoneAction.setMilestones(this.state.milestones);
          }
        });
      })
          .catch(() => {
            NotificationActions.toast(
              <FormattedMessage id="error" defaultMessage="Error" />,
              (
                <FormattedMessage
                  id="errorOccurredMessage"
                  defaultMessage="An error occurred, please try again later"
                />
              ),
              ToastType.ERROR,
            );
          });
      return;
    }

    let request;
    switch (this.props.activityType) {
      case TypeActivity.BUSINESS_CHALLENGE:
        request =
            BusinessChallengeAPI.putBusinessChallengeMilestones(this.props.activityId, [milestone]);
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        request =
            PerformanceInitiativeAPI.putPerformanceInitiativeMilestones(this.props.activityId, [milestone]);
        break;
      default:
        break;
    }
    request.then(() => {
      NotificationUtils.sendActivityUpdatedToast(TypeActivity.MILESTONE);
      if (Utils.isActivityPi(this.props.activityType)) {
        MilestoneAction.setMilestones(this.state.milestones);
      }
    }).catch(() => Utils.toastError());
  }

  private onCancel = (id: number | null) => {
    const milestones = [...this.state.milestones];
    if (!!id) {
      const oldMilestones = [...this.state.oldMilestones];
      const milestoneInEdition = milestones.findIndex(milestone => milestone.id === id);
      const oldMilestoneToCancel = oldMilestones.findIndex(milestone => milestone.id === id);

      if (milestoneInEdition !== -1) {
        milestones[milestoneInEdition] = oldMilestones[oldMilestoneToCancel];
        oldMilestones.splice(oldMilestoneToCancel, 1);
        this.setState({ milestones, oldMilestones, shouldResetActions: true });
      }
    } else if (id === null) {
      milestones.shift();
      this.setState({ milestones, shouldResetActions: true }, () => this.props.toggleCreationMode());
    }
  }

  private async onDelete(milestone: Milestone) {
    if (!!milestone.id) {
      let request;
      switch (this.props.activityType) {
        case TypeActivity.BUSINESS_CHALLENGE:
          if (Utils.isActivityPi(milestone.activityMilestone)) {
            request =
              BusinessChallengeAPI.removeBusinessChallengeLinkMilestones(this.props.activityId, [{ id: milestone.id }]);
          } else {
            request =
              BusinessChallengeAPI.deleteBusinessChallengeMilestones(this.props.activityId, [milestone.id]);
          }
          break;
        case TypeActivity.PERFORMANCE_INITIATIVE:
          request = PerformanceInitiativeAPI.deleteMilestones(this.props.activityId, [milestone.id]);
          break;
        default:
          break;
      }
      request.then(() => {
        const newMilestonesList = this.state.milestones.filter(mi => mi.id !== milestone.id);
        this.setState({ milestones: newMilestonesList }, () => {
          NotificationUtils.sendActivityDeletedToast(TypeActivity.MILESTONE, milestone.code);
          if (Utils.isActivityPi(this.props.activityType)) {
            MilestoneAction.setMilestones(this.state.milestones);
          }
        });
        // if there is still any milestone
        if (newMilestonesList.length > 0) {
          // force a refresh of the drag and drop index
          this.onMovedMil({
            draggableId: 1,
            source: { droppableId: `${this.state.milestones[0].id}`, index: 1 },
            destination: { droppableId: `${this.state.milestones[0].id}`, index: 1 },
          });
        }
      }).catch(() => Utils.toastError());
    }
  }

  private setActionResetDone = () => {
    this.setState({ shouldResetActions: false });
  }

  private toggleToEdit(milestone: Milestone) {
    const oldMilestones = this.state.oldMilestones;
    oldMilestones.push(milestone);
    this.setState({ oldMilestones });
  }

  public handleChange = (key: string, value: any, milestone: Milestone) => {
    milestone[key] = value.value;
    const milestoneFormValid = { ...this.state.milestoneFormValid };
    this.saveMilestonesInState(milestone);

    if (milestone.name.length === 0 && milestoneFormValid.isNameValid) {
      milestoneFormValid.isNameValid = false;
    } else if (milestone.name.length > 0 && !milestoneFormValid.isNameValid) {
      milestoneFormValid.isNameValid = true;
    }

    this.setState({ milestoneFormValid });
  };

  public handleDateChange = (milestone: Milestone, { name, value }: any) => {
    const milestoneFormValid = { ...this.state.milestoneFormValid };

    milestone[name] = value !== '' ? moment(value, Utils.getDateFormat()).toDate() : null;

    if (name === 'targetDate' && milestone.targetDate) {
      milestoneFormValid.isTargetDateValid =
        !isNaN(new Date(milestone.targetDate).getTime()) && new Date(milestone.targetDate).getFullYear() > 2000;
    }

    this.saveMilestonesInState(milestone);
    this.setState({ milestoneFormValid });
  };

  public changeIsHidden(index: number | null): void {
    if (index === null) {
      const newMilestone = { ...this.state.newMilestone };
      newMilestone.isHidden = !newMilestone.isHidden;
      this.setState({ newMilestone });
      return;
    }

    const milestones = [...this.state.milestones];

    milestones[index].isHidden = !milestones[index].isHidden;
    this.setState({ milestones });
  }

  // this function is caleld when an actin completion date impacting a milestone is changed
  public saveDateUpdate = (data:Milestone) => {
    let milestone = data;
    if (milestone.targetDate) {
      milestone = Utils.parseDateStringToDate(milestone, CommonDateField.TARGET_DATE);
    }
    if (milestone.completionDate) {
      milestone = Utils.parseDateStringToDate(milestone, CommonDateField.COMPLETION_DATE);
    }
    let request;
    request = PerformanceInitiativeAPI.putPerformanceInitiativeMilestones(this.props.activityId, [milestone]);
    request.then(() => {
      MilestoneAction.setMilestones(this.state.milestones);
    }).catch(() => Utils.toastError());
  }

  private createMilestone = () => {
    const milestones = [...this.state.milestones];

    milestones.unshift(defaultNewMilestone);

    this.setState({ milestones }, () => this.scrollToMilestone());
  }

  //endregion
  // Drag N Drop region

  private onMovedMil = (data: DraggableData) => {
    this.setState({ isLoading: true });
    const milestones = this.state.milestones;
    const body: CodeOrderBody[] = [];
    const milestoneToMove = milestones[data.source.index];
    const destination = data.destination ? data.destination.index : milestones.length + 1;
    milestones.splice(data.source.index, 1);
    milestones.splice(destination, 0, milestoneToMove);
    milestones.forEach((m, index) => m.code = index + 1);
    for (const mil of milestones) {
      body.push({
        id: mil.id as number,
        code: mil.code,
        isLinked: mil.piId ? true : false,
      });
    }
    this.setState({ milestones });
    let req;
    switch (this.props.activityType) {
      case TypeActivity.BUSINESS_CHALLENGE:
        req = BusinessChallengeAPI.putBusinessChallengeMilsOrder(this.props.activityId, body);
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
      default:
        req = PerformanceInitiativeAPI.putPerformanceInitiativeMilsOrder(this.props.activityId, body);
        break;
    }
    req
      .then(() => setTimeout(() => this.setState({ isLoading: false }), 500))
      .then(() => MilestoneAction.setMilestones(this.state.milestones))
      .catch(() => Utils.toastError());
  };
  // endregion

  render() {
    const isBc = Utils.isActivityBc(this.props.activityType);
    const milestones = [...this.state.milestones];

    return(
      <div id="milestones-list-container" className={isBc ? 'bc-display' : ''}>
        {!isBc &&
          <div id="milestones-list-title">
            <FormattedMessage id="milestones" defaultMessage="Milestones" />
          </div>
        }
        <div id="milestones-list-body">
          <CustomScrollBars id="milestones-list" className="content-element-scrollbar" customRef={this.scrollbarsRef}>
            {milestones.length > 0 ?
              <DragDropContext onDragEnd={this.onMovedMil}>
                <Droppable droppableId="mil-area">
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {milestones.map((milestone, index) => (
                        <Draggable
                          key={`mil-drag-${milestone.id}`}
                          draggableId={`${milestone.id}`}
                          index={index}
                          isDragDisabled={this.state.isLoading || !this.props.canEditOrderMil}>
                          {dragProvided => (
                              <Ref innerRef={dragProvided.innerRef}>
                                <Segment
                                  {...dragProvided.draggableProps}
                                  {...dragProvided.dragHandleProps}
                                  className="drag-mil"
                                >
                                  <MilestoneTile
                                    key={`milestone-tile-${milestone.id}`}
                                    milestone={milestone}
                                    activityType={this.props.activityType}
                                    activityId={this.props.activityId}
                                    parentPpId={this.props.parentPpId}
                                    saveMilestonesInState={this.saveMilestonesInState}
                                    onEdit={this.save}
                                    onCancel={this.onCancel}
                                    onDelete={() => this.onDelete(milestone)}
                                    toggleToEdit={milestone => this.toggleToEdit(milestone)}
                                    isForBcView={isBc}
                                    shouldResetAction={this.state.shouldResetActions}
                                    setActionResetDone={this.setActionResetDone}
                                    saveDateUpdate={this.saveDateUpdate}
                                  />
                                </Segment>
                              </Ref>
                          )}
                        </Draggable>),
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            : Utils.empty(<FormattedMessage
                id="noMilestones"
                defaultMessage="No milestone was found for this activity"
              />)
            }
          </CustomScrollBars>
        </div>
      </div>
    );
  }
}
export default withRouter(MilestonesList);
