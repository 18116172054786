import './reportModal.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Form, Icon, Menu, Modal, Popup } from 'semantic-ui-react';

import Textarea from 'src/components/common/form/textarea/textarea';
import BusinessChallengeReporting, { BusinessChallengeReportingPut } from 'src/models/businessChallengeReporting';
import Utils from 'src/utils/utils';
import ReportModalContent from '../modal/reportModalContent';
import ReportingActions from 'src/actions/reporting.action';
import NotificationActions from 'src/actions/notification-actions';
import MetricsMilestonesTab from './metricsMilestonesTab/metricsMilestonesTab';
import ReportModalConfirmation from './reportModalConfirmation/reportModalConfirmation';
import { ToastType } from 'src/components/common/toast/toast';
import CustomScrollBars from '../../../../../common/customScrollBars/customScrollBars';
import ActivitiesActions from 'src/actions/activities.action';
import Metric from 'src/models/metric';

interface IProps {
  onClose: () => void;
  open: boolean;
  initialBcReporting: BusinessChallengeReporting;
  reportingId: number;
  isReportingClosed: boolean;
  onBcReportingUpdate: (bcReporting: BusinessChallengeReporting) => void;
  metrics: Metric[];
}

interface IStates {
  comment: string | null;
  status: number;
  activeCode: number;
  tab: string;
  isStatusEditing: boolean;
  saveDisabled: boolean;
  updateModalOpen: boolean;
}

class ReportModal extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      comment: !!this.props.initialBcReporting.comment ? this.props.initialBcReporting.comment : null,
      status: !!this.props.initialBcReporting.bcSnapshot.status ? this.props.initialBcReporting.bcSnapshot.status : 3,
      activeCode: 0,
      tab: '',
      isStatusEditing: false,
      saveDisabled: true,
      updateModalOpen: false,
    };
  }

  public setStatus = (bcReporting: BusinessChallengeReporting) => {
    this.setState({
      status: bcReporting.bcSnapshot.status,
    });
  }
  public setUpdateModalOpen = (bool: boolean) => {
    this.setState({
      updateModalOpen: bool,
    });
  }
  /**
   * Check if the save button must be disabled & set its state accordingly
   */
  public canSave = () => {
    this.setState({ saveDisabled:
      (this.props.initialBcReporting.comment === this.state.comment
        || (this.props.initialBcReporting.comment === undefined && this.state.comment === null)
      ) && this.props.initialBcReporting.bcSnapshot.status === this.state.status,
    });
  };

  /**
   * Close modal
   */
  private handleClose = () => {
    this.props.onClose();
  };

  /**
   * Save referent's comment
   */
  private handleSave = () => {
    ReportingActions.emitUpdateReferentBCReporting(
      this.props.reportingId,
      this.props.initialBcReporting.bcSnapshot.id,
      new BusinessChallengeReportingPut(
        this.props.initialBcReporting.bcSnapshot.id, this.state.comment, this.state.status),
    ).then((bcReporting: BusinessChallengeReporting) => {
      this.props.onBcReportingUpdate(bcReporting);
      if (this.state.status !== this.props.initialBcReporting.bcSnapshot.status) {
        ActivitiesActions.emitGetActivities();
      }
      NotificationActions.toast(
        (
          <FormattedMessage
            id="saved"
            defaultMessage="Saved!"
          />
        ),
        (
          <FormattedMessage
            id="pp.reporting.bcReportingSave"
            defaultMessage="The BC reporting has been successfully saved"
          />
        ),
        ToastType.SUCCESS,
      );
    }).catch(() => Utils.toastError());

    this.props.onClose();
  };

  /**
   * Update the state with param element
   * @param codeMenu: number;
   * @param tabName
   */
  private handleItemClick = (codeMenu: number, tabName: string) => {
    this.setState({ activeCode: codeMenu, tab: tabName });
  };

  /**
   * Set status value state
   * @param status : number
   */
  private handleStatusChange = (status: number) => {
    this.setState({ status }, this.canSave);
    this.toggleStatusDropdown();
  };

  /**
   * Set text (comment) value state
   * @param key : string
   * @param value : any
   */
  private handleTextChange = (key: string, value: any) => {
    this.setState({ comment: value === '' ? null : value }, this.canSave);
  };

  /**
   * Toggle the displaying of the status modification dropdown
   */
  private toggleStatusDropdown = () => {
    if (!this.props.isReportingClosed) {
      this.setState({ isStatusEditing: !this.state.isStatusEditing });
    }
  };

  public render() {
    let bcComment = '';
    switch (this.state.activeCode) {
      case 1:
        bcComment = this.props.initialBcReporting.bcSnapshot.achievements;
        break;
      case 2:
        bcComment = this.props.initialBcReporting.bcSnapshot.issuesAndRisks;
        break;
      case 3:
        bcComment = this.props.initialBcReporting.bcSnapshot.decisionsToBeMade;
        break;
      case 4:
        bcComment = this.props.initialBcReporting.bcSnapshot.nextSteps;
        break;
      default:
        break;
    }

    return (
      <>
        <Modal
          open={this.props.open}
          closeOnEscape={true}
          closeOnDimmerClick={false}
          onClose={this.props.onClose}
        >
          <Modal.Content id="report-modal-content">
            <Modal.Header>
              <div className="bc-container">
                <div className="bc-title-container">
                  <div
                    className={`status
                      ${!this.props.isReportingClosed ? 'status-container' : ''}
                      ${Utils.getColorFromStatus(this.state.status)}
                      ${this.state.isStatusEditing ? 'open' : ''}`
                    }
                    onClick={() => this.toggleStatusDropdown()}
                  >
                    {this.state.isStatusEditing
                        && <div className={'status-dropdown'}>
                        {this.state.status !== 1 &&
                        <div className={'status red'} onClick={() => this.handleStatusChange(1)} />}
                        {this.state.status !== 2 &&
                        <div className={'status orange'} onClick={() => this.handleStatusChange(2)} />}
                        {this.state.status !== 3 &&
                        <div className={'status green'} onClick={() => this.handleStatusChange(3)} />}
                      </div>
                    }
                  </div>
                  <span className="bc-title">
                    BC{Utils.leadingZero(this.props.initialBcReporting.bcSnapshot.code)} -
                    {this.props.initialBcReporting.bcSnapshot.name}
                  </span>
                </div>
                <div>
                  {Utils.isTop5(this.props.initialBcReporting.bcSnapshot)}
                  {Utils.getPillar(this.props.initialBcReporting.bcSnapshot.pillar)}
                </div>
              </div>
              <div className="bc-info-container">
                <div className="name-user-container">
                  {Utils.getUsersAvatars(this.props.initialBcReporting.bcSnapshot)}
                </div>
                <span className="date">
                  <FormattedMessage id="lastUpdate" defaultMessage="Last update">
                    {msg => `${msg}: ${this.props.initialBcReporting.lastSnapshotUpdate ?
                      Utils.displayDate(this.props.initialBcReporting.lastSnapshotUpdate)
                      : '-'}`}
                  </FormattedMessage>
                </span>
              </div>
            </Modal.Header>
            <Menu className={'tab-menu-container'} secondary={true}>
              <FormattedMessage id="metricsAndMilestones" defaultMessage="Metrics & Milestones">
                {msg => (
                  <Menu.Item
                    active={this.state.activeCode === 0}
                    onClick={() => this.handleItemClick(0, msg.toString())}
                  >
                    {msg}
                  </Menu.Item>
                )}
              </FormattedMessage>

              <FormattedMessage id="achievement" defaultMessage="Achievements">
                {msg => (
                  <Menu.Item
                    active={this.state.activeCode === 1}
                    onClick={() => this.handleItemClick(1, msg.toString())}
                  >
                    {msg}
                  </Menu.Item>
                )}
              </FormattedMessage>

              <FormattedMessage id="issues" defaultMessage="Issues & Risks">
                {msg => (
                  <Menu.Item
                    active={this.state.activeCode === 2}
                    onClick={() => this.handleItemClick(2, msg.toString())}
                  >
                    {msg}
                  </Menu.Item>
                )}
              </FormattedMessage>

              <FormattedMessage id="decisions" defaultMessage="Decisions" >
                {msg => (
                  <Menu.Item
                    active={this.state.activeCode === 3}
                    onClick={() => this.handleItemClick(3, msg.toString())}
                  >
                    {msg}
                  </Menu.Item>
                )}
              </FormattedMessage>

              <FormattedMessage id="nextSteps" defaultMessage="Next Steps">
                {msg => (
                  <Menu.Item
                    active={this.state.activeCode === 4}
                    onClick={() => this.handleItemClick(4, msg.toString())}
                  >
                    {msg}
                  </Menu.Item>
                )}
              </FormattedMessage>
            </Menu>
            <div className={'scrollable-container'}>
              <CustomScrollBars>
                <ReportModalContent
                  tabId={this.state.activeCode}
                  tab={this.state.tab}
                  bcComment={this.state.activeCode === 0
                    ? <MetricsMilestonesTab
                      metrics={this.props.initialBcReporting.bcSnapshot.metrics}
                      linkedMetrics={this.props.initialBcReporting.bcSnapshot.linkedMetrics}
                      milestones={this.props.initialBcReporting.bcSnapshot.milestones}
                      linkedMilestones={this.props.initialBcReporting.bcSnapshot.linkedMilestones}
                      metricsBc={this.props.metrics}
                    />

                    : bcComment
                  }
                  pi={this.props.initialBcReporting.bcSnapshot.performanceInitiatives}
                />
              </CustomScrollBars>
            </div>
          </Modal.Content>
          <Modal.Actions id="actions">
            <Form id="report-modal-form">
              <div id="referent-comment-title">
                <FormattedMessage id="referentComment" defaultMessage="Referent's comment" />:
              </div>
              {this.props.isReportingClosed
                ? <div id="referent-comment-text">{this.state.comment}</div>
                : <div id="referent-comment-text-edit">
                  <FormattedMessage id="enterCommentHere" defaultMessage="Enter your comment here" >
                    {msg => <Textarea
                      value={!!this.state.comment ? this.state.comment : ''}
                      onChange={(e: any, { value }: any) => this.handleTextChange('comment', value)}
                      maxlength={500}
                      placeholder={msg.toString()}
                      maxLinesNumber={5}
                      fixedHeight={true}
                      height={90}
                    />
                    }
                  </FormattedMessage>
                </div>
              }
            </Form>

            <div id="buttons-container">
              {this.props.isReportingClosed
                ? <>
                  <div />
                  <Button
                    color="grey"
                    onClick={this.handleClose}
                  >
                    <FormattedMessage id="close" defaultMessage="Close" />
                  </Button>
                </>
                : <>
                  <FormattedMessage
                    id="pp.reporting.bcReportingUpdateTooltip"
                    defaultMessage="Update data displayed here with latest data"
                  >
                    {msg =>
                      <Popup
                        inverted={true}
                        content={msg}
                        size="tiny"
                        position="left center"
                        trigger={
                          <Button
                            className="long"
                            primary={true}
                            onClick={() => this.setState({ updateModalOpen: true })}
                          >
                            <FormattedMessage id="pp.reporting.bcReportingUpdate" defaultMessage="Update"/>
                            <Icon name="sync alternate"/>
                          </Button>
                        }
                      />
                    }
                  </FormattedMessage>

                  <div>
                    <Button
                      onClick={this.handleClose}
                    >
                      <FormattedMessage id="cancel" defaultMessage="Cancel" />
                    </Button>

                    <Button
                      primary={true}
                      onClick={this.handleSave}
                      disabled={this.state.saveDisabled}
                    >
                      <FormattedMessage id="save" defaultMessage="Save" />
                      <Icon name="checkmark" />
                    </Button>
                  </div>
                </>
              }
            </div>
          </Modal.Actions>
        </Modal>
        <ReportModalConfirmation
          onBcReportingUpdate={this.props.onBcReportingUpdate}
          setStatus={this.setStatus}
          reportingId={this.props.reportingId}
          initialBcReporting={this.props.initialBcReporting}
          canSave={this.canSave}
          updateModalOpen={this.state.updateModalOpen}
          setUpdateModalOpen={this.setUpdateModalOpen}
        />
      </>
    );
  }
}

export default ReportModal;
