import * as d3 from 'd3';

const pack = (data: any) => d3.pack()
  .size([width, height])
  .padding(3)(d3.hierarchy(data)
    .sum((d: any) => d.value)
    .sort((a: any, b: any) => b.value - a.value));

const width = 500;
const height = 500;

export default function bubbleGraph(data: any, id: string, callback: (d: any) => void) {

  const root = pack(data);
  let focus = root;
  let view: any;

  document.getElementById(id)!.innerHTML = '';

  const svg = d3.select(`#${id}`)
    .append('svg')
    .attr('viewBox', `-${width / 2} -${height / 2} ${width} ${height}`)
    .style('display', 'block')
    .style('margin', '0')
    .style('width', '100%')
    .style('height', 'calc(100vh - 250px)')
    .style('background', 'white')
    .style('cursor', 'pointer')
    .on('click', () => {
      callback(null);
      zoom(root);
    });

  const node = svg.append('g').selectAll('circle')
    .data(root.descendants().slice(1))
    .enter().append('circle')
    .attr('fill', (d: any) => d.children || d.depth === 2 ? '#71c5d9' : 'white')
    .attr('pointer-events', (d: any) => !d.children ? 'none' : null)
    .on('mouseover', function () {
      // @ts-ignore
      d3.select(this).attr('stroke', '#000');
    })
    .on('mouseout', function () {
      // @ts-ignore
      d3.select(this).attr('stroke', null);
    })
    .on('click', (d: any) => {
      if (focus === d) {
        callback(null);
      } else {
        callback(d);
      }

      return focus !== d && (zoom(d), d3.event.stopPropagation());
    })
    .attr('id', root.descendants().slice(1));

  const label = svg.append('g')
    .style('Lato', '12px sans-serif')
    .attr('pointer-events', 'none')
    .attr('text-anchor', 'middle')
    .selectAll('text')
    .data(root.descendants())
    .enter();

  const labelLine1 = label.append('text')
    .style('fill-opacity', (d: any) => d.parent === root ? 0.8 : 0)
    .style('display', (d: any) => d.parent === root ? 'inline' : 'none')
    .attr('dy', '0em')
    .text((d: any) => {
      const shortname: string[] = d.data.shortname ? d.data.shortname.split(' ') : d.data.name.split(' ');
      const linebreakLocation = Math.floor(shortname.length / 2);
      let shortnameString: string = d.data.shortname || d.data.name;
      if (linebreakLocation >= 1) {
        shortnameString = shortname[0];
        for (let i = 1; i < linebreakLocation; i += 1) {
          shortnameString += ` ${shortname[i]}`;
        }
      }
      return shortnameString;
    });

  const labelLine2 = label.append('text')
    .style('fill-opacity', (d: any) => d.parent === root ? 0.8 : 0)
    .style('display', (d: any) => d.parent === root ? 'inline' : 'none')
    .attr('dy', '1em')
    .text((d: any) => {
      const shortname: string[] = d.data.shortname ? d.data.shortname.split(' ') : d.data.name.split(' ');
      const linebreakLocation = Math.floor(shortname.length / 2);
      let shortnameString = shortname[linebreakLocation];
      if (linebreakLocation >= 1) {
        for (let i = linebreakLocation + 1; i < shortname.length; i += 1) {
          shortnameString += ` ${shortname[i]}`;
        }
        return shortnameString;
      }
      return '';
    });

  zoomTo([root.x, root.y, root.r * 2]);

  function zoomTo(v: any) {
    const k = width / v[2];

    view = v;

    labelLine1.attr('transform', (d: any) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
    labelLine2.attr('transform', (d: any) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
    node.attr('transform', (d: any) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
    node.attr('r', (d: any) => d.r * k);
  }

  function filterLabelLines(line: any, transition: any) {
    line
      .filter(function (d: any) {
        // @ts-ignore
        return d.parent === focus || this.style.display === 'inline';
      })
      .transition(transition)
      .style('fill-opacity', (d: any) => d.parent === focus ? 0.8 : 0)
      .on('start', function (d: any) {
        if (d.parent === focus) {
          // @ts-ignore
          this.style.display = 'inline';
        }
      })
      .on('end', function (d: any) {
        if (d.parent !== focus) {
          // @ts-ignore
          this.style.display = 'none';
        }
      });
  }

  function zoom(d: number) {
    focus = d;

    const transition = svg.transition()
      .duration(d3.event.altKey ? 7500 : 750)
      .tween('zoom', () => {
        const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
        return (t: any) => zoomTo(i(t));
      });

    filterLabelLines(labelLine1, transition);
    filterLabelLines(labelLine2, transition);
  }

  return svg.node();
}
