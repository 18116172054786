import './mentionActivityTextModal.scss';
import * as React from 'react';
import { Button, Checkbox, Icon, Modal, Step } from 'semantic-ui-react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { IBusinessLocationsValues } from 'src/components/common/form/ppGbuGeo/ppGbuGeo';
import ActivityFilterSelection from 'src/components/activityLink/activityFilterSelection';
import ActivityPpSelection from 'src/components/activityLink/activityPpSelection';
import ActivityBcSelection from 'src/components/activityLink/activityBcSelection';
import PerformancePlan from 'src/models/performancePlan';
import BusinessChallenge from 'src/models/businessChallenge';
import PiSelection from './metricsMilestones/modal/piSelection';
import PerformanceInitiative from 'src/models/performanceInitiative';
import PerformancePlanAPI from 'src/api/performancePlan.api';
import ActivityLinkPpFilters from 'src/models/activityLink';
import BusinessChallengeAPI from 'src/api/businessChallenge.api';
import Utils from 'src/utils/utils';
import TypeActivity from 'src/constants/typeActivity';
import { PpApiType } from 'src/constants/performance-plan.enum';
import { RouteComponentProps, withRouter } from 'react-router';
import ActionSelection from './metricsMilestones/modal/actionSelection';
import Action from 'src/models/action';
import PerformanceInitiativeAPI from 'src/api/performanceInitiative.api';

interface IProps extends InjectedIntlProps, RouteComponentProps {
  closeModal: () => void;
  submit: (selected, type, pp?, pi?) => void;
}

interface IStates {
  steps: { title: JSX.Element, desc: JSX.Element, key: MentionSteps }[];
  currentStep: number;
  isSubmitLoading: boolean;
  selectedGeoGbuValues: IBusinessLocationsValues;
  performancePlans: PerformancePlan[];
  selectedPp: number;
  businessChallenges: BusinessChallenge[];
  selectedBc: number;
  performanceInitiatives: PerformanceInitiative[];
  selectedPi: number;
  actions: Action[];
  selectedAt: number;
  selectedYear: string;
  isNextDisabled: boolean;
  shouldSelectFromPlan: boolean;
}

enum MentionSteps {
  SEARCH,
  PP_LIST,
  BC_LIST,
  PI_LIST,
  AT_LIST,
}

class MentionActivityTextModal extends React.Component<IProps, IStates> {

  private NONE_VALUE = 1;

  public constructor(props: IProps) {
    super(props);

    const steps = [
      {
        key: MentionSteps.SEARCH,
        title: <FormattedMessage id="filters" defaultMessage="Filters" />,
        desc: <FormattedMessage id="link.ppFiltersDesc" defaultMessage="Performance Plan Filters" />,
      },
      {
        key: MentionSteps.PP_LIST,
        title: <FormattedMessage id="performancePlans" defaultMessage="Performance Plans" />,
        desc: <FormattedMessage id="link.ppSelectionDesc" defaultMessage="Performance Plan Selection" />,
      },
      {
        key: MentionSteps.BC_LIST,
        title: <FormattedMessage id="businessChallenges" defaultMessage="Business Challenges" />,
        desc: <FormattedMessage id="link.bcSelectionDesc" defaultMessage="Business Challenge Selection" />,
      },
      {
        key: MentionSteps.PI_LIST,
        title: <FormattedMessage id="performanceInitiatives" defaultMessage="Performance initiatives" />,
        desc: <FormattedMessage id="link.piSelectionDesc" defaultMessage="Performance initiatives selection" />,
      },
      {
        key: MentionSteps.AT_LIST,
        title: <FormattedMessage id="actions" defaultMessage="actions" />,
        desc: <FormattedMessage id="link.atSelectionDesc" defaultMessage="Actions selection" />,
      },
    ];

    this.state = {
      steps,
      currentStep: MentionSteps.SEARCH,
      isSubmitLoading: false,
      selectedGeoGbuValues: {
        gbuId: this.NONE_VALUE,
        businessLineId: this.NONE_VALUE,
        segmentId: this.NONE_VALUE,
        geographyId: this.NONE_VALUE,
        countryId: this.NONE_VALUE,
        subcountryId: this.NONE_VALUE,
      },
      selectedPp: 0,
      selectedBc: 0,
      selectedPi: 0,
      selectedAt: 0,
      performancePlans: [],
      businessChallenges: [],
      performanceInitiatives: [],
      actions: [],
      selectedYear: 'all',
      isNextDisabled: false,
      shouldSelectFromPlan: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>, snapshot?: any) {
    if ((prevState.currentStep !== this.state.currentStep)
      || (prevState.shouldSelectFromPlan !== this.state.shouldSelectFromPlan)) {
      switch (this.state.currentStep) {
        case MentionSteps.PP_LIST:
          this.getPpList();
          break;
        case MentionSteps.BC_LIST:
          this.getBcList();
          break;
        case MentionSteps.PI_LIST:
          this.getPiList();
          break;
        case MentionSteps.AT_LIST:
          this.getAtList();
          break;
      }
    }
  }

  private onPreviousClick = () => {
    if (this.state.currentStep > MentionSteps.SEARCH) {
      this.setState(state => ({ currentStep: state.currentStep - 1 }));
    }
  }

  private onNextClick = () => {
    if (this.state.currentStep < this.state.steps.length) {
      this.setState({ currentStep: this.state.currentStep + 1 });
    }
  }

  private getPpList = () => {
    this.setState({ isNextDisabled: true }, () => {
      PerformancePlanAPI.getPerformancePlansByGeography(
        new ActivityLinkPpFilters(this.state.selectedYear, this.state.selectedGeoGbuValues), PpApiType.MENTION_ACTIVITY)
          .then((performancePlans) => {
            this.setState({
              performancePlans,
              selectedPp: performancePlans.length > 0 ? performancePlans[0].id : 0,
              isNextDisabled: false,
            });
          });
    },
    );
  };

  private getBcList = () => {
    const currentPpId = this.state.shouldSelectFromPlan
      ? Number(this.props.location.pathname.split('/')[2]) : this.state.selectedPp;
    this.setState({ isNextDisabled: true }, () => {
      BusinessChallengeAPI.getBcsForActivityLink(currentPpId)
          .then((businessChallenges) => {
            this.setState({
              businessChallenges,
              selectedBc: businessChallenges.length > 0 ? businessChallenges[0].id : 0,
              isNextDisabled: false,
            });
          });
    },
    );
  };

  private getPiList = () => {
    this.setState({ isNextDisabled: true }, () => {
      BusinessChallengeAPI.getBusinessChallengePis(this.state.selectedBc)
          .then((performanceInitiatives) => {
            this.setState({
              performanceInitiatives,
              selectedPi: performanceInitiatives.length > 0 ? performanceInitiatives[0].id : 0,
              isNextDisabled: false,
            });
          });
    },
    );
  };

  private getAtList = () => {
    this.setState(
      { isNextDisabled: true },
      () => {
        PerformanceInitiativeAPI
          .getPerformanceInitiativeActionsMentions(this.state.selectedPi)
          .then((actions) => {
            this.setState({
              actions,
              selectedAt: actions.length > 0 ? actions[0].id : 0,
              isNextDisabled: false,
            });
          });
      },
    );
  }

  private setFilters = (selectedGeoGbuValues?: IBusinessLocationsValues, selectedYear?: string) => {
    const state = { ...this.state };
    if (!!selectedGeoGbuValues) state.selectedGeoGbuValues = selectedGeoGbuValues;
    if (!!selectedYear) state.selectedYear = selectedYear;
    this.setState(state);
  };

  private selectPp = (selectedPp: number) => {
    this.setState({ selectedPp });
  };

  private selectBc = (selectedBc: number) => {
    this.setState({ selectedBc });
  };

  private selectPi = (selectedPi: number) => {
    this.setState({ selectedPi });
  }

  private selectAt = (atId: number) => {
    this.setState({ selectedAt: atId });
  }

  private submit = () => {
    let selected;
    let type;
    switch (this.state.currentStep) {
      case MentionSteps.PP_LIST:
        selected = this.state.performancePlans.find(pp => pp.id === this.state.selectedPp);
        type = TypeActivity.PERFORMANCE_PLAN;
        break;
      case MentionSteps.BC_LIST:
        selected = this.state.businessChallenges.find(bc => bc.id === this.state.selectedBc);
        type = TypeActivity.BUSINESS_CHALLENGE;
        break;
      case MentionSteps.PI_LIST:
        selected = this.state.performanceInitiatives.find(pi => pi.id === this.state.selectedPi);
        type = TypeActivity.PERFORMANCE_INITIATIVE;
        break;
      case 4:
        selected = this.state.actions.find(at => at.id === this.state.selectedAt);
        type = TypeActivity.ACTION;
        break;
    }
    this.props.submit(selected, type, this.state.selectedPp, this.state.selectedPi);
  }

  private displaySteps = () => {
    return this.state.steps.map((step, index) => {
      const stepDisplay = (
        <Step className="mention-step" key={index} active={this.state.currentStep === index}>
          <Step.Content>
            <Step.Title>{step.title}</Step.Title>
            <Step.Description>{step.desc}</Step.Description>
          </Step.Content>
        </Step>
      );
      if (this.state.shouldSelectFromPlan) {
        if (step.key !== MentionSteps.SEARCH && step.key !== MentionSteps.PP_LIST) return stepDisplay;
      } else {
        return stepDisplay;
      }
      return null;
    });
  }

  private toggleFromThisPlan = () => {
    this.setState(state => ({
      shouldSelectFromPlan: !state.shouldSelectFromPlan,
      currentStep: !state.shouldSelectFromPlan ? MentionSteps.BC_LIST : MentionSteps.SEARCH,
      selectedPp: Number(this.props.location.pathname.split('/')[2]),
    }));
  }

  public render() {
    const isStepSearch = this.state.currentStep === MentionSteps.SEARCH;
    const isStepBc = this.state.currentStep === MentionSteps.BC_LIST;
    const nextButtonMessage = isStepSearch
      ? <FormattedMessage id="search" defaultMessage="Search"/>
      : <FormattedMessage id="next" defaultMessage="Next"/>;
    return (
      <>
        <Modal
          className="mention-activity-modal"
          open={true}
          onClose={this.props.closeModal}
          closeOnDimmerClick={false}
        >
          <Modal.Content id="mention-activity-modal-content">
            <Modal.Header id="mention-activity-modal-header">
              <h1><FormattedMessage id="selectActivity" defaultMessage="select an activity"/></h1>
              <Checkbox
                toggle={true}
                onClick={this.toggleFromThisPlan}
                label={this.props.intl.formatMessage({
                  id: 'mentionActivity.fromThisPlan',
                  defaultMessage: 'From This Plan',
                })}
              />
            </Modal.Header>
            <div className="modal-container">
              <Step.Group id="progress" vertical={true}>
                {this.displaySteps()}
              </Step.Group>
              <div className="selection-container">
              {this.state.isNextDisabled ? Utils.loader() : (
                <>
                 { isStepSearch &&
                <ActivityFilterSelection
                  selectedYear={this.state.selectedYear}
                  selectedGeoGbuValues={this.state.selectedGeoGbuValues}
                  onChange={this.setFilters}
                />
              }
              {this.state.currentStep === MentionSteps.PP_LIST &&
                <ActivityPpSelection
                  performancePlans={this.state.performancePlans}
                  selectedPp={this.state.selectedPp}
                  selectPp={this.selectPp}
                />
              }

              {isStepBc &&
                <ActivityBcSelection
                  businessChallenges={this.state.businessChallenges}
                  selectedBc={this.state.selectedBc}
                  selectBc={this.selectBc}
                />
              }

              {this.state.currentStep === MentionSteps.PI_LIST &&
              <PiSelection
                performanceInitiatives={this.state.performanceInitiatives}
                selectedPi={this.state.selectedPi}
                selectPi={this.selectPi}
              />}

              {this.state.currentStep === 4 &&
              <ActionSelection
                actions={this.state.actions}
                selectedAt={this.state.selectedAt}
                selectAt={this.selectAt}
              />}
                </>
              )}
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button id="button-left" onClick={this.props.closeModal}>
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <div>
              {this.state.currentStep > MentionSteps.SEARCH && <Button
                className="previous"
                color="grey"
                disabled={(this.state.shouldSelectFromPlan ? isStepBc : isStepSearch) || this.state.isSubmitLoading}
                onClick={this.onPreviousClick}
              >
                <Icon name="chevron left" />
                <FormattedMessage id="previous" defaultMessage="Previous" />
              </Button>}
                <Button
                  id="next-btn"
                  className="next"
                  primary={true}
                  onClick={this.onNextClick}
                  disabled={this.state.isNextDisabled || this.state.currentStep === MentionSteps.AT_LIST}
                >
                  {nextButtonMessage}
                  <Icon name="chevron right" />
                </Button>
            </div>
            {this.state.currentStep > MentionSteps.SEARCH &&
            <Button
                className="submit"
                primary={true}
                loading={this.state.isSubmitLoading}
                onClick={this.submit}
                disabled={this.state.isSubmitLoading}
            >
                <FormattedMessage id="select" defaultMessage="Select" />
                <Icon name="checkmark" />
            </Button>}
          </Modal.Actions>
        </ Modal>
      </>
    );
  }
}
export default withRouter(injectIntl(MentionActivityTextModal));
