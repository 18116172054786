import './metricsMilestones.scss';
import 'react-circular-progressbar/dist/styles.css';

import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import ModeTypes from 'src/constants/modeTypes';
import TypeActivity from 'src/constants/typeActivity';
import BusinessChallenge from 'src/models/businessChallenge';
import ActivitiesStore from 'src/stores/activities.store';
import Utils from 'src/utils/utils';
import Metrics from './metrics/metrics';
import CustomScrollBars from '../../../common/customScrollBars/customScrollBars';
import LastUpdateHighlight from '../common/overviewPanel/panelContent/tabs/lastUpdatedInformations/lastUpdateHighlight';
import { SegmentType } from '../../../../models/segmentsMode';
import Metric from 'src/models/metric';
import PerformanceInitiativeAPI from 'src/api/performanceInitiative.api';
import BusinessChallengeAPI from 'src/api/businessChallenge.api';
import { DEFAULT_METRIC } from './shared/constants/defaultMetric';
import { FormattedMessage } from 'react-intl';

interface IRouteProps {
  id: string;
  metricId: string;
}

export enum FieldType {
  STRING,
  NUMBER,
}

interface IProps extends RouteComponentProps<IRouteProps> {
  bc?: BusinessChallenge;
  type: TypeActivity;
  onDataChange: (idAttribute: string, value: any, text?: any) => void;
  mode: ModeTypes;
  isFormValidated(isValid: boolean, form: string): void;
  activityId: number;
}

interface IStates {
  metrics: Metric[];
  mode: ModeTypes;
  isMetricsFormValid: boolean;
  isMilestonesFormValid: boolean;
}

export interface FieldValues {
  name: string;
  value: number | Date | string | boolean | null | undefined;
}

class MetricsContainer extends React.Component<IProps, IStates> {

  //region REACT LIFECYCLE METHODS
  public constructor(props: IProps) {
    super(props);
    this.state = {
      metrics: [],
      mode: ActivitiesStore.getMode(),
      isMetricsFormValid: true,
      isMilestonesFormValid: true,
    };
    moment.locale(Utils.getCurrentUserLanguageName());
  }

  public componentDidMount() {
    this.getMetrics();
  }
  //endregion

  private getMetrics = async (callback?: Function) => {
    switch (this.props.type) {
      case TypeActivity.BUSINESS_CHALLENGE:
        const bcMetrics = await BusinessChallengeAPI.getBusinessChallengeMetrics(this.props.activityId);
        this.setState({
          metrics: this.mergeLinkedMetrics(bcMetrics),
        },            () => callback && callback());
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        const piMetrics = await PerformanceInitiativeAPI.getPerformanceInitiativeMetrics(this.props.activityId);
        this.setState({
          metrics: piMetrics.metrics,
        },            () => callback && callback());
        break;
    }
  }

  private createMetric = () => {
    this.setState(prevState => ({
      metrics: [{ ...DEFAULT_METRIC }, ...prevState.metrics],
    }));
  }

  private deleteNewMetric = (index: number) => {
    const metricsCopy: Metric[] = [...this.state.metrics];
    metricsCopy.splice(index, 1);
    this.setState({
      metrics: metricsCopy,
    });
  }

  //region LISTENER RELATED METHODS
  public mergeLinkedMetrics = (data) => {
    const metrics: Metric[] = data.metrics;
    if (data.linkedBcLinkMetrics) {
      for (const metric of data.linkedBcLinkMetrics) {
        if (!data.metrics.find(m => m.id === metric.id)) {
          metrics.push({ ...metric, isFromLinked: true });
        }
      }
    }
    return Utils.sortArrayByKey(metrics, 'code');
  }
  //endregion

  //region GLOBAL FORM VALIDATION
  private checkForm = () => {
    this.props.isFormValidated(this.state.isMetricsFormValid && this.state.isMilestonesFormValid, 'metricsMilestones');
  }

  updateSubFormValidation = (isValid: boolean, subForm: string) => {
    if (subForm === 'metrics') {
      this.setState({ isMetricsFormValid: isValid }, this.checkForm);
    } else {
      this.setState({ isMilestonesFormValid: isValid }, this.checkForm);
    }
  }
  //endregion

  private deleteMetric = (metricId: number) => {
    this.setState({
      metrics: this.state.metrics.filter(metric => metric.id !== metricId),
    });
  }

  private updateMetrics = (metric: Metric) => {
    const metricsCopy = [...this.state.metrics];

    for (let i = 0; i < metricsCopy.length; i += 1) {
      if (metricsCopy[i].id === metric.id) {
        metricsCopy[i] = metric;
      }
    }

    this.setState({
      metrics: metricsCopy,
    });
  }

  public render() {

    if (!this.state.metrics) {
      return Utils.loader();
    }

    const metricSegmentId = Utils.isActivityBc(this.props.type)
      ? LastUpdateHighlight.getSegmentId(SegmentType.BC_METRICS)
      : LastUpdateHighlight.getSegmentId(SegmentType.PI_METRICS);

    const data = (
      <div id="metric-view">
        <div id={metricSegmentId} className="metrics-segment">
          <Metrics
            metrics={this.state.metrics}
            mode={this.state.mode}
            onDataChange={this.props.onDataChange}
            type={this.props.type}
            forActivityCreation={false}
            updateSubFormValidation={this.updateSubFormValidation}
            selectedUrlMetric={this.props.match.params.metricId}
            data={Utils.isActivityBc(this.props.type) ? this.props.bc : undefined}
            activityId={this.props.activityId}
            deleteMetric={this.deleteMetric}
            updateMetrics={this.updateMetrics}
            reloadMetrics={this.getMetrics}
            createMetric={this.createMetric}
            deleteNewMetric={this.deleteNewMetric}
          />
        </div>
      </div>
    );

    if (Utils.isActivityBc(this.props.type)) {
      return (data);
    }

    return (
      <div className="scrollable-container">
        <CustomScrollBars id="scroll-wrapper">
          {data}
          {this.state.metrics.length <= 0 && Utils.empty(<FormattedMessage id="noMetrics" defaultMessage="No metrics available" />)}
        </CustomScrollBars>
      </div>
    );
  }
}

export default withRouter(MetricsContainer);
