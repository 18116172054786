import * as React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, Input, Label } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { PerformancePlanPut } from '../../../models/performancePlan';

interface IProps {
  pp: PerformancePlanPut;
  onUpdate: any;

  setNavigationDisabled(isNavigationDisabled: boolean): void;
}

interface IStates {
  isNameIncorrect: boolean;
  nameLabelMessage: string | JSX.Element;
}

class PpCreationStep4 extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);

    this.state = {
      isNameIncorrect: false,
      nameLabelMessage: '',
    };
  }

  public componentWillMount() {
    this.props.setNavigationDisabled(!PpCreationStep4.checkName(this.props.pp.name));
  }

  /**
   * Send the modified value to the parent component
   * @param e : any
   * @param value : any
   */
  private handleChange = (e: any, { value }: any) => {
    this.refreshLabel(e.target.id, value);
  };

  /**
   * Refresh the pointing label depending on the validity of the name
   * @param idElement : string
   * @param value : string
   */
  private refreshLabel(idElement: string, value: string) {
    this.setNameLabel('');

    if (PpCreationStep4.checkName(value)) {
      this.props.onUpdate(idElement, value);
      this.setState({ isNameIncorrect: false });
    } else {
      this.setState({ isNameIncorrect: true });
      if (value.length < 3) {
        this.setNameLabel('tooShort');
      } else if (value.length > 20) {
        this.setNameLabel('tooLong');
      }
    }

    const isNameIncorrect = !PpCreationStep4.checkName(value);
    if (isNameIncorrect) {
      if (value.length === 0) {
        this.setNameLabel('blank');
      } else if (value.length < 3) {
        this.setNameLabel('tooShort');
      } else if (value.length > 20) {
        this.setNameLabel('tooLong');
      }
    }

    this.setState({ isNameIncorrect });

    this.props.setNavigationDisabled(isNameIncorrect);
    this.props.onUpdate(idElement, value);
  }

  /**
   * Check if the name is valid
   * @param name : string
   */
  private static checkName(name: string) {
    return new RegExp('^.{3,20}$').test(name);
  }

  /**
   * Set the error message
   * @param msg : string
   */
  public setNameLabel(msg: string) {
    let jsxMessage: JSX.Element;

    switch (msg) {
      case 'blank':
        jsxMessage = (
          <FormattedMessage id="nameCantBeNull" defaultMessage="The name can't be null">
            {msg => (<Label pointing={true}>{msg}</Label>)}
          </FormattedMessage>
        );
        break;

      case 'tooShort':
        jsxMessage = (
          <FormattedMessage id="profile.tooShort" defaultMessage="Too short">
            {msg => (<Label pointing={true}>{msg}</Label>)}
          </FormattedMessage>
        );
        break;

      case 'tooLong':
        jsxMessage = (
          <FormattedMessage id="profile.tooLong" defaultMessage="Too long">
            {msg => (<Label pointing={true}>{msg}</Label>)}
          </FormattedMessage>
        );
        break;

      default:
        jsxMessage = <div />;
        break;
    }

    this.setState({ nameLabelMessage: jsxMessage });
  }

  public render(): React.ReactNode {
    return (
      <Form id="pp-creation-step4">
        <Form.Group widths="equal">
          <Form.Field>
            <FormattedMessage id="name" defaultMessage="Name">
              {msg => (
                <Form.Field
                  id="name"
                  control={Input}
                  label={`${msg} *`}
                  value={this.props.pp.name}
                  onChange={this.handleChange}
                  error={this.state.isNameIncorrect}
                  autoFocus={true}
                />
              )}
            </FormattedMessage>
            {this.state.nameLabelMessage}
            <div className="legend">
              <FormattedMessage
                id="examplePpName"
                defaultMessage="Example: For the AVS CBU in the UK, the name of the plan is AVS_UK"
              />
            </div>
          </Form.Field>
        </Form.Group>
        <Form.Group widths="1" id="pp-is-draft-container">
          <Form.Field>
            <FormattedMessage id="pp.creation.isDraft" defaultMessage="This Plan is a draft">
              {msg => (
                <Form.Checkbox
                  label={msg}
                  checked={this.props.pp.isDraft}
                  onChange={(e, { checked }) => this.props.onUpdate('isDraft', checked)}
                />
              )}
            </FormattedMessage>
            <div className="legend">
              <FormattedMessage
                id="pp.creation.planDraftExplanation"
                defaultMessage="When a Plan is a draft, the team members assigned to BCs, PIs and actions underneath won't see this Plan, nor receive notifications related to its activities."
              />
              &nbsp;
              <FormattedMessage
                id="pp.creation.validateDraftLater"
                defaultMessage="The Plan's referent and deputies will be able to validate the draft later."
              />
            </div>
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

export default PpCreationStep4;
