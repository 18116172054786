enum FeedbackKeysType {
  FB_TYPE_OPTIONS_BUG,
  FB_TYPE_OPTIONS_SUGGESTIONS,
  FB_TYPE_OPTIONS_OTHER,
  FB_TYPE_OPTIONS_ARCHIVED,
}
export default FeedbackKeysType;

export enum TypeFeedbackFilter {
  TYPE_FB_FILTER_NONE,
  TYPE_FB_FILTER_TYPE,
  TYPE_FB_FILTER_STATUS,
  TYPE_FB_FILTER_PAGE,
}

export enum FeedbackKeysStatus {
  FB_STATUS_TODO,
  FB_STATUS_IN_PROGRESS,
  FB_STATUS_DONE,
}

export enum FeedbackKeysPage {
  FB_PAGE_OPTIONS_LOGIN,
  FB_PAGE_OPTIONS_PROFILE,
  FB_PAGE_OPTIONS_MENU,
  FB_PAGE_OPTIONS_TASKBOARD,
  FB_PAGE_OPTIONS_PERFORMANCE_PLAN,
  FB_PAGE_OPTIONS_BUSINESS_CHALLENGE,
  FB_PAGE_OPTIONS_PERFORMANCE_INITIATIVE,
  FB_PAGE_OPTIONS_PERFORMANCE_PLAN_CREATION,
  FB_PAGE_OPTIONS_BUSINESS_CHALLENGE_CREATION,
  FB_PAGE_OPTIONS_PERFORMANCE_INITIATIVE_CREATION,
  FB_PAGE_OPTIONS_OTHER,
}

export enum FeedbackKeysTab {
  FB_TAB_OPTIONS_COCKPIT,
  FB_TAB_OPTIONS_MORE_INFO,

  FB_TAB_PP_OPTIONS_REPORTING,

  FB_TAB_BC_PI_OPTIONS_PERFORMANCE_INDICATOR,
  FB_TAB_BC_OPTIONS_PERFORMANCE_INITIATIVES,
  FB_TAB_PI_OPTIONS_ACTION_LIST,
}

export enum FeedbackKeysCreation {
  FB_PP_CREATION_OPTIONS_BUSINESS,
  FB_PP_CREATION_OPTIONS_OTHER_INFO,
  FB_PP_CREATION_OPTIONS_DEPUTIES,
  FB_PP_CREATION_OPTIONS_CONFIRM_NAME,

  FB_BC_PI_CREATION_OPTIONS_BASIC_INFO,

  FB_BC_CREATION_OPTIONS_GLOBAL_STATE,
  FB_BC_CREATION_OPTIONS_BC_LEADER,

  FB_PI_CREATION_OPTIONS_METRICS,
  FB_PI_CREATION_OPTIONS_PI_LEADER,
}
