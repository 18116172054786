import './userAvatar.scss';

import * as React from 'react';
import Img from 'react-image';
import { Icon, Loader, Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import User from '../../../models/user';
import defaultUserAvatar from '../../../images/default-user.png';
import UserActions from '../../../actions/user.action';
import Utils from '../../../utils/utils';

interface IProps {
  account: User;
  reducedVersion?: boolean;
}

interface IStates {
  image: string;
  firstName: null | string;
}

const MAX_EMAIL_LENGTH = 28;

class UserAvatar extends React.Component<IProps, IStates> {

  public static defaultProps = {
    reducedVersion: false,
  };

  public constructor(props: IProps) {
    super(props);
    this.state = { image: '', firstName: null };
  }

  public componentDidMount() {
    if (!!this.props.account && this.props.account.hasAvatar) {
      UserActions.getUserAvatar(
        this.props.account.id,
        image => this.setState({ image }),
      );
    }
    if (this.props.reducedVersion && !!this.props.account.firstName && !!this.props.account.lastName) {
      const chars = this.props.account.firstName.length + this.props.account.lastName.length;
      if (chars >= 20) {
        this.setState({
          firstName: `${(this.props.account.firstName).charAt(0)}.`});
      }
    }
  }

  public render() {
    if (!this.props.account) {
      return <div />;
    }

    return (
      <div className="name-user" key={this.props.account.id}>
        {this.props.account.isInvited
          ? <Popup
            inverted={true}
            size="tiny"
            position="top center"
            content={
              <>
                <FormattedMessage id="userInvited" defaultMessage="This user has been invited, but did not create his account yet" />
                {(this.props.account.email.length > MAX_EMAIL_LENGTH && Utils.isOnMobility())
                && <span> ({this.props.account.email})</span>}
              </>
            }
            trigger={(
              <div className="invited-container">
                <Icon name="globe" className="invited-icon" />
                <div className="name">
                  <span className="email">
                    {this.props.account.email}
                  </span>
                </div>
              </div>
            )}
          />
          : <>
            <Img
              className="avatar"
              src={[this.state.image, defaultUserAvatar]}
              loader={<Loader active={true} size="large" />}
            />
            <div className="name">
              <span className="firstName">
                {this.state.firstName || this.props.account.firstName}
              </span>
              &nbsp;
              <span className="lastName">{this.props.account.lastName}</span>
            </div>
          </>
        }
      </div>
    );
  }
}

export default UserAvatar;
