import ActionTypes from 'src/constants/actionTypes';
import Dispatcher from 'src/dispatcher';
import { GUIDE_STATUS, GuideIds, VIEWS } from '../constants/guideConstants';

export default class GuideActions {

  public static emitUpdateGuideStatus(status: GUIDE_STATUS): void {
    Dispatcher.dispatch({
      status,
      actionType: ActionTypes.GUIDE_STATUS_UPDATED,
    });
  }

  public static emitRunGuide(guideId: GuideIds): void {
    Dispatcher.dispatch({
      guideId,
      actionType: ActionTypes.CURRENT_GUIDE_SET,
    });
  }

  public static emitUpdateCurrentView(view: VIEWS): void {
    Dispatcher.dispatch({
      view,
      actionType: ActionTypes.CURRENT_VIEW_UPDATED,
    });
  }
}
