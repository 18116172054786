import 'react-datepicker/dist/react-datepicker.css';

import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import User from '../../../models/user';
import UserSelection from '../../common/form/userSelection/userSelection';
import { PerformancePlanPut } from '../../../models/performancePlan';

interface IProps {
  pp: PerformancePlanPut;
  onUpdate: (key: string, value: any) => void;
}

interface IStates {
}

class PpCreationStep3 extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
  }

  /**
   * Update the referent user in the parent component
   * @param user : User
   */
  private onReferentUserChange = (user: User[]) => {
    this.props.onUpdate('owner', user[0]);
  };

  /**
   * Update the assigned users in the parent component
   * @param users : User[]
   */
  private onAssignedUsersChange = (users: User[]) => {
    this.props.onUpdate('assignedAccounts', users);
  };

  /**
   * Update the informed users in the parent component
   * @param users : User[]
   */
  private onInformedUsersChange = (users: User[]) => {
    this.props.onUpdate('informedAccounts', users);
  };

  public render(): React.ReactNode {
    return (
      <Form id="pp-creation-step3">
        <Form.Group widths="equal">
          <Form.Field>
            <FormattedMessage
              id="pp.creation.referent"
              defaultMessage="Referent"
            >
              {msg => (
                <div>
                  <h3>{msg}</h3>
                  <UserSelection
                    defaultValue={this.props.pp.owner ? [this.props.pp.owner] : []}
                    onChange={this.onReferentUserChange}
                    ppReferentSelection={true}
                  />
                </div>
              )}
            </FormattedMessage>

            <FormattedMessage
              id="pp.creation.deputies"
              defaultMessage="Deputies"
            >
              {msg => (
                <div>
                  <h3>{msg}</h3>
                  <UserSelection
                    defaultValue={this.props.pp.assignedAccounts ? this.props.pp.assignedAccounts : []}
                    onChange={this.onAssignedUsersChange}
                  />
                </div>
              )}
            </FormattedMessage>

            <FormattedMessage
              id="pp.creation.sharedWith"
              defaultMessage="Shared with"
            >
              {msg => (
                <div>
                  <h3>{msg}</h3>
                  <UserSelection
                    defaultValue={this.props.pp.informedAccounts ? this.props.pp.informedAccounts : []}
                    onChange={this.onInformedUsersChange}
                  />
                </div>
              )}
            </FormattedMessage>
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

export default PpCreationStep3;
