import { IBusinessLocationsValues } from '../components/common/form/ppGbuGeo/ppGbuGeo';

export default class ActivityLinkPpFilters {
  year?: string;
  gbuId?: number;
  businessLineId?: number;
  segmentId?: number;
  geographyId?: number;
  countryId?: number;
  subcountryId?: number;

  constructor(year: string, geoGbuValues: IBusinessLocationsValues) {
    if (year !== 'all') this.year = year;
    if (geoGbuValues.gbuId !== 1) this.gbuId = geoGbuValues.gbuId;
    if (geoGbuValues.businessLineId !== 1) this.businessLineId = geoGbuValues.businessLineId;
    if (geoGbuValues.segmentId !== 1) this.segmentId = geoGbuValues.segmentId;
    if (geoGbuValues.geographyId !== 1) this.geographyId = geoGbuValues.geographyId;
    if (geoGbuValues.countryId !== 1) this.countryId = geoGbuValues.countryId;
    if (geoGbuValues.subcountryId !== 1) this.subcountryId = geoGbuValues.subcountryId;
  }
}

export class LinkedBc {
  id: number;
  isPending: boolean;

  constructor(body: any) {
    if (body.id) this.id = body.id;
    if (body.isPending) this.isPending = body.isPending;

  }
}
