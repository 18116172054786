import * as H from 'history';
import SegmentsStore from '../stores/segments.store';
import { SegmentType } from '../models/segmentsMode';
import Utils from './utils';

export default class NavigationUtils {

  public static isDetailsChangeTabs = (location: H.Location, propsLocation: H.Location): boolean => {
    const BC_DETAILS_MODE = SegmentsStore.getSegmentModeById(SegmentType.BC_DETAILS);
    const PI_DETAILS_MODE = SegmentsStore.getSegmentModeById(SegmentType.PI_DETAILS);

    if (Utils.isOnEditMode(BC_DETAILS_MODE) || Utils.isOnEditMode(PI_DETAILS_MODE)
      || SegmentsStore.hasPublicationsSegmentOnEdit()) {
      // Compare origin url with target url to check if we change activity or change tabs
      const extractedOriginUrl = propsLocation.pathname.match(/\/activities-board\/[0-9]*\/[a-z|-]*\/[0-9]*\//);
      const targetExtractedUrl = location.pathname.match(/\/activities-board\/[0-9]*\/[a-z|-]*\/[0-9]*\//);
      return (!!extractedOriginUrl && !!targetExtractedUrl && extractedOriginUrl[0] === targetExtractedUrl[0]);
    }
    return false;
  }
}
