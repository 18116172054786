import './exploreBoard.scss';

import * as React from 'react';

import ContainerExplore from './containerExplore/containerExplore';
import MenuExplore from './menuExplore/menuExplore';

interface IProps { }

interface IStates { }

class ExploreBoard extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    return (
      <div id="explore-container">
        <MenuExplore />
        <ContainerExplore />
      </div >
    );
  }
}

export default ExploreBoard;
