import React from 'react';
import Metric from '../../../../../models/metric';
import { Modal, ModalContent, ModalHeader, Ref, Segment } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import ImportedLabel from '../../metricsMilestones/shared/importedLabel';
import TypeActivity, { MetricMilestone } from '../../../../../constants/typeActivity';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CodeOrderBody, DraggableData } from '../../../../../constants/order';
import BusinessChallengeAPI from '../../../../../api/businessChallenge.api';
import PerformanceInitiativeAPI from '../../../../../api/performanceInitiative.api';
import Utils from '../../../../../utils/utils';
import './orderActivitiesModal.scss';
import CustomScrollBars from '../../../../common/customScrollBars/customScrollBars';

interface IProps {
  activities: Metric[];
  isOpen: boolean;
  performancePlanId: number | null;
  parentType: TypeActivity.BUSINESS_CHALLENGE | TypeActivity.PERFORMANCE_INITIATIVE;
  parentId: number;

  onClose(): void;

  onReorder(callback: Function): void;
}

interface IStates {
  isLoading: boolean;
}

class OrderActivitiesModal extends React.Component<IProps, IStates> {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<any>, nextContext: any): boolean {
    return JSON.parse(JSON.stringify(nextProps.activities)) !== JSON.parse(JSON.stringify(this.props.activities));
  }

  onMovedMetric = async (data: DraggableData) => {
    this.setState({ isLoading: true });
    const metrics = JSON.parse(JSON.stringify(this.props.activities));
    const body: CodeOrderBody[] = [];
    const metricToMove = metrics[data.source.index];
    const destination = data.destination ? data.destination.index : metrics.length + 1;
    metrics.splice(data.source.index, 1);
    metrics.splice(destination, 0, metricToMove);
    metrics.forEach((m, index) => m.code = index + 1);
    for (let i = 0; i < metrics.length; i += 1) {
      body.push({
        id: metrics[i].id as number,
        code: metrics[i].code,
        isLinked: metrics[i].isFromLinked,
      });
    }
    let req;
    switch (this.props.parentType) {
      case TypeActivity.BUSINESS_CHALLENGE:
        req = BusinessChallengeAPI.putBusinessChallengeMetricsOrder(this.props.parentId, body);
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
      default:
        req = PerformanceInitiativeAPI.putPerformanceInitiativeMetricsOrder(this.props.parentId, body);
        break;
    }
    req
      .then(() => this.props.onReorder(() => setTimeout(() => this.setState({ isLoading: false }), 500)))
      .catch(() => Utils.toastError());
  };

  render() {
    return (
      <Modal id="order-metrics-modal" open={this.props.isOpen} onClose={this.props.onClose} style={{ width: '450px' }}>
        <ModalHeader>
          <FormattedMessage id="orderMetrics" defaultMessage="Order metrics"/>
        </ModalHeader>
        <ModalContent>
          <FormattedMessage
            id="dndToOrderMetrics"
            defaultMessage="Drag and drop the following metric tiles to order them:"
          />
          {this.state.isLoading
            ? <div id="order-loader">{Utils.loader('loader-modal')}</div>
            : <CustomScrollBars id="metrics-scroll-area">
                <DragDropContext onDragEnd={this.onMovedMetric}>
                  <Droppable droppableId="metrics-area">
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {this.props.activities.map((at, index) => (
                          <Draggable key={at.name} draggableId={`${at.id}`} index={index}>
                            {provided => (
                              <Ref innerRef={provided.innerRef}>
                                <Segment
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className="metrics-title" style={{ display: 'flex' }}>
                                    <h3>
                                      <>{at.name} {at.unit && `(${at.unit})`}</>
                                    </h3>
                                    {at.isFromLinked && this.props.performancePlanId!! &&
                                      <ImportedLabel
                                        ppId={this.props.performancePlanId!}
                                        piId={at.performanceInitiative?.id}
                                        metricMilestone={at}
                                        type={MetricMilestone.METRIC}
                                      />}
                                  </div>
                                </Segment>
                              </Ref>
                            )}
                          </Draggable>),
                        )}
                        {provided.placeholder}
                      </div>
                    )}
              </Droppable>
                </DragDropContext>
            </CustomScrollBars>
          }
        </ModalContent>
      </Modal>
    );
  }
}

export default OrderActivitiesModal;
