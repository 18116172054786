/* Copyright (C) Thales Services, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Thales Services, 2020
 */
import Action from './action';
import BusinessChallenge, { BcLinkedWithSource } from './businessChallenge';
import Keywords from './keywords';
import Metrics from './metric';
import Milestone from './milestone';
import User from './user';
import Attachment from './attachment';
import { RightsOnActivityDTO } from './rights';

export default class PerformanceInitiative {

  public id: number;
  public name: string;
  public objectives: string;
  public code: number;
  public keywords: Keywords;
  public keywordsId?: number;
  public dueDate: Date;
  public achievements: string;
  public issuesAndRisks: string;
  public nextSteps: string;
  public decisionsToBeMade: string;
  public metrics: Metrics[];
  public isAwaitingValidation: boolean;
  public assignedAccounts: User[];
  public deputies: User[];
  public informedAccounts: User[];
  public milestones: Milestone[];
  public businessChallenge: BusinessChallenge;
  public businessChallengeId?: number;
  public budget: string;
  public status: number;
  public isBudgetHidden: boolean;
  public actions: Action[];
  public publishedDate: Date;
  public lastUpdate: Date;
  public lastModifierId: number;
  public lastModifier: User;
  public attachments: Attachment[];
  public isPpDraft: boolean;
  public isActionAssignee: boolean;
  public isAssignee: boolean;
  public isLinked: boolean;
  public isBcLinked: boolean;
  public targetPpId: number;
  public isPending: boolean;
  public isClosed: boolean;
  public closeComment: string;
  public closeDate: Date;

  constructor(pi: PerformanceInitiativeGet) {
    this.id = pi.id;
    this.name = pi.name;
    this.objectives = pi.objectives;
    this.code = pi.code;
    this.keywords = pi.keywords;
    this.keywordsId = pi.keywordsId;
    this.dueDate = pi.dueDate;
    this.achievements = pi.achievements;
    this.issuesAndRisks = pi.issuesAndRisks;
    this.nextSteps = pi.nextSteps;
    this.decisionsToBeMade = pi.decisionsToBeMade;
    this.metrics = pi.metrics;
    this.assignedAccounts = pi.assignedAccounts;
    this.deputies = pi.deputies;
    this.informedAccounts = pi.informedAccounts;
    this.milestones = pi.milestones;
    this.businessChallenge = pi.businessChallenge;
    this.businessChallengeId = pi.businessChallengeId;
    this.budget = pi.budget;
    this.status = pi.status;
    this.isBudgetHidden = pi.isBudgetHidden;
    this.actions = pi.actions;
    this.publishedDate = pi.publishedDate;
    this.lastUpdate = pi.lastUpdate;
    this.lastModifier = pi.lastModifier;
    this.attachments = pi.attachments;
    this.isPpDraft = pi.isPpDraft;
    this.isLinked = pi.isLinked;
    this.isBcLinked = pi.isBcLinked;
    this.targetPpId = pi.targetPpId;
    this.isClosed = pi.isClosed;
    this.closeComment = pi.closeComment;
    this.closeDate = pi.closeDate;
  }
}

export class PerformanceInitiativeGet {

  public id: number;
  public name: string;
  public objectives: string;
  public code: number;
  public keywords: Keywords;
  public keywordsId?: number;
  public dueDate: Date;
  public achievements: string;
  public issuesAndRisks: string;
  public nextSteps: string;
  public decisionsToBeMade: string;
  public metrics: Metrics[];
  public assignedAccounts: User[];
  public deputies: User[];
  public informedAccounts: User[];
  public milestones: Milestone[];
  public businessChallenge: BusinessChallenge;
  public businessChallengeId?: number;
  public budget: string;
  public status: number;
  public isBudgetHidden: boolean;
  public actions: Action[];
  public publishedDate: Date;
  public lastUpdate: Date;
  public lastModifier : User;
  public lastModifierId : number;
  public attachments: Attachment[];
  public rightsOnPI: RightsOnActivityDTO;
  public isPpDraft: boolean;
  public isLinked: boolean;
  public isBcLinked: boolean;
  public targetPpId: number;
  public isClosed: boolean;
  public closeComment: string;
  public closeDate: Date;
}

export class PerformanceInitiativeDetails {
  public id: number;
  public name: string;
  public status: number;
  public objectives: string;
  public successFactorsRisks: string;
  public code: number;
  public isClosed: boolean;
  public actions: Action[];
  public budget: string;
  public isBudgetHidden: boolean;
}

export class PerformanceIndicatorPost {

  public name: string;
  public objectives: string;
  public code: number;
  public keywordsId: number;
  public status?: number;
  public dueDate?: Date;
  public achievements?: string;
  public issuesAndRisks?: string;
  public nextSteps?: string;
  public decisionsToBeMade?: string;
  public metrics: Metrics[];
  public assignedAccounts?: User[];
  public deputies?: User[];
  public informedAccounts?: User[];
  public milestones: Milestone[];
  public businessChallenge?: BusinessChallenge;
  public budget: string;
  public isBudgetHidden?: boolean;
  public actions?: Action[];
  public attachments: Attachment[];
}

export class PerformanceIndicatorPut {

  public id?: number;
  public name?: string;
  public objectives?: string;
  public code?: number;
  public keywordsId?: number;
  public status?: number;
  public dueDate?: Date;
  public achievements?: string;
  public issuesAndRisks?: string;
  public nextSteps?: string;
  public decisionsToBeMade?: string;
  public metrics?: Metrics[];
  public assignedAccounts?: User[];
  public deputies?: User[];
  public informedAccounts?: User[];
  public milestones?: Milestone[];
  public businessChallengeId?: number;
  public budget?: string;
  public scope?: string;
  public isBudgetHidden?: boolean;
  public actions?: Action[];
  public attachments: Attachment[];
}

export class PiLinked {
  public id: number;
  public code: number;
  public linkedBcs: BcLinkedWithSource[];
}

export class PerformanceInitiativesCockpitFields {
  public id: number;
  public name: string;
  public code: number;
  public achievements?: string;
  public issuesAndRisks?: string;
  public nextSteps?: string;
  public decisionsToBeMade?: string;
}
