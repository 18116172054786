import './cockpit.scss';
import 'react-circular-progressbar/dist/styles.css';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Divider, Dropdown, Form, Icon, Segment } from 'semantic-ui-react';

import BusinessChallengeActions from 'src/actions/businessChallenge.action';
import ModeTypes from 'src/constants/modeTypes';
import { BusinessChallengePisFields } from 'src/models/businessChallenge';
import { PerformanceInitiativesCockpitFields } from '../../../../../models/performanceInitiative';
import Utils from '../../../../../utils/utils';
import EditorGenerator from '../../ckEditorGenerator';
import CustomScrollBars from '../../../../common/customScrollBars/customScrollBars';
import LastUpdateHighlight from '../../common/overviewPanel/panelContent/tabs/lastUpdatedInformations/lastUpdateHighlight';
import SegmentEditButtons from '../../common/segmentEditButtons/segmentEditButtons';
import BusinessChallengeAPI from '../../../../../api/businessChallenge.api';
import { BcPiFields } from '../../../../../constants/bcPiFields';
import { SegmentType } from '../../../../../models/segmentsMode';
import {
  BcPiFieldsData,
  BcPiFieldsDTO,
  BcPiFieldsMode,
  EditorErrors,
  ShowPiFields,
} from '../../../../../models/bcPiFields';
import NotificationActions from '../../../../../actions/notification-actions';
import { ToastType } from '../../../../common/toast/toast';
import ActivitiesActions from 'src/actions/activities.action';
import GuideStore from '../../../../../stores/guide.store';
import GuideActions from '../../../../../actions/guide.action';
import { VIEWS } from '../../../../../constants/guideConstants';
import { BcTabs } from '../contentBC';

interface IRouteProps {
  id: string;
  ppId: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  canEdit: boolean;
  setTabErrorStatus(isOnErr: boolean, tab: BcTabs): void;
}

interface IStates {
  data: BcPiFieldsData;
  oldData: BcPiFieldsData;
  mode: BcPiFieldsMode;
  isOnError: EditorErrors;
  showPiFields: ShowPiFields;

  bcPisFields: BusinessChallengePisFields | undefined;
  isLoading: boolean;
}

class Cockpit extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);

    this.state = {
      data: new BcPiFieldsData(),
      oldData: new BcPiFieldsData(),
      mode: new BcPiFieldsMode(),
      isOnError: new EditorErrors(),
      showPiFields: new ShowPiFields(),

      bcPisFields: undefined,
      isLoading: true,
    };
  }

  public componentDidMount() {
    LastUpdateHighlight.highlightParam();
    this.getData();
    this.setCurrentView();
  }

  public componentDidUpdate(prevProps: Readonly<IProps & ReactIntl.InjectedIntlProps>, prevState: Readonly<IStates>,
                            snapshot?: any) {
    this.setCurrentView();
  }

  private getData = (field?: BcPiFields) => {
    const promises: Promise<BcPiFieldsDTO>[] = [];

    const bcId = +this.props.match.params.id;

    if (field) {
      BusinessChallengeAPI.getBcField(bcId, field)
        .then((result) => {
          const data = { ...this.state.data };
          data[field] = result.content;
          this.setState({ data, oldData: { ...data }, isLoading: false });
        })
        .catch(() => this.setState({ isLoading: false }));
    } else {
      promises.push(BusinessChallengeAPI.getBcField(bcId, BcPiFields.MAIN_ACHIEVEMENTS));
      promises.push(BusinessChallengeAPI.getBcField(bcId, BcPiFields.ISSUES_AND_RISKS));
      promises.push(BusinessChallengeAPI.getBcField(bcId, BcPiFields.DECISIONS_TO_BE_MADE));
      promises.push(BusinessChallengeAPI.getBcField(bcId, BcPiFields.NEXT_STEPS));

      Promise.all(promises)
        .then((results) => {
          const data = new BcPiFieldsData(results[0], results[1], results[2], results[3]);
          this.setState({ data, oldData: { ...data }, isLoading: false });
        })
        .catch(() => this.setState({ isLoading: false }));
    }
  }

  //region Edit/Cancel/Save methods
  private onChange = (field: BcPiFields, value: string) => {
    const data = { ...this.state.data };
    data[field] = value;

    this.setState({ data });
  }

  private onCancel = (field: BcPiFields) => {
    const data = { ...this.state.data };
    const oldData = { ...this.state.oldData };
    data[field] = oldData[field];
    const isOnError = { ...this.state.isOnError };
    isOnError[field] = false;
    this.props.setTabErrorStatus(Object.values(isOnError).includes(true), BcTabs.COCKPIT);
    this.setState({ data, isOnError });
  }

  private onSave = (field: BcPiFields) => {
    const valueToSave = this.state.data[field];
    if (valueToSave !== undefined) {
      return BusinessChallengeAPI.editBcField(+this.props.match.params.id, field, valueToSave)
        .then(() => {
          this.getData(field);

          NotificationActions.toast(
            <FormattedMessage id="saved" defaultMessage="Saved!" />,
            (
              <FormattedMessage
                id="assignationsSuccessfullySaved"
                defaultMessage="The assignations were successfully saved"
              />
            ),
            ToastType.SUCCESS,
          );
          ActivitiesActions.emitUpdatedLastUpdate();
        })
        .catch(() => {
          Utils.toastError();
        });
    }
    return Promise.resolve();
  }
  //endregion

  private changeMode(mode: ModeTypes, field: BcPiFields) {
    const state = { ...this.state };
    state.mode[field] = mode;
    state.showPiFields[field] = false;
    this.setState(state);
  }

  private setCurrentView = () => {
    if (this.hasFieldOnEditMode() && GuideStore.shouldRunGuide(VIEWS.COCKPIT_VIEW_ON_EDIT)) {
      GuideActions.emitUpdateCurrentView(VIEWS.COCKPIT_VIEW_ON_EDIT);
    }
  }

  private handleErrorOnEditor = (field: BcPiFields, isFieldOnError: boolean, callback: Function) => {
    const state = { ...this.state };
    state.isOnError[field] = isFieldOnError;
    this.props.setTabErrorStatus(Object.values(state.isOnError).includes(true), BcTabs.COCKPIT);
    this.setState(state, () => callback());
  };

  private isFieldOnErr = (field: BcPiFields) => {
    return this.state.isOnError[field] && Utils.isOnEditMode(this.state.mode[field]);
  }

  private handleClickPiButton = (field?: BcPiFields) => {
    const showPiFields = new ShowPiFields();

    if (field !== undefined) {
      if (this.isNotShowingPiFields(field)) {
        BusinessChallengeAPI.getBusinessChallengePiFields(+this.props.match.params.id, field)
          .then(bcPisFields => this.setState({ bcPisFields }));
      }

      showPiFields[field] = !this.state.showPiFields[field];
    }

    this.setState({ showPiFields });
  }

  /**
   * BC element displayed, depends on the mode
   * @param {BcPiFields} field
   * @return {Element} the BC element to display
   */
  public getElement(field: BcPiFields): JSX.Element {
    const value = this.state.data[field]!;
    const checkFieldAndModeCondition = this.hasFieldOnEditMode(field) || this.hasFieldOnCancelMode(field);

    if (checkFieldAndModeCondition) {
      return (
        <Form key={field}>
          <EditorGenerator
            data={value}
            onChangeAction={this.onChange}
            onErrorAction={this.handleErrorOnEditor}
            idElement={field}
            onDisplayPiData={() => this.handleClickPiButton(field)}
            toggleButton={this.state.showPiFields[field]}
          />
        </Form>
      );
    }

    if (Utils.isOnViewOrDeletionMode(this.state.mode[field])) {
      return (
        <>
          <CustomScrollBars className="content-element-scrollbar">
            <div key={field} className="formatted-text">
              {Utils.replaceActivityMentionToJsx(value, this)}
            </div>
          </CustomScrollBars>
        </>
      );
    }

    return (
      <Form key={field}>
        <EditorGenerator
          data={value}
          onChangeAction={this.onChange}
          onErrorAction={this.handleErrorOnEditor}
          idElement={field}
          onDisplayPiData={this.handleClickPiButton}
        />
      </Form>
    );
  }

  private isNotShowingPiFields = (field: BcPiFields): boolean => {
    return Object.keys(this.state.showPiFields).some(key => !this.state.showPiFields[key] && field === key);
  }

  private hasFieldOnEditMode = (field?: BcPiFields): boolean => {
    return Object.keys(this.state.mode).some((key) => {
      const condition = Utils.isOnEditMode(this.state.mode[key]);
      if (field) return condition && field === key;
      return condition;
    });
  }

  private hasFieldOnCancelMode = (field?: BcPiFields): boolean => {
    return Object.keys(this.state.mode).some((key) => {
      const condition = Utils.isOnCancelMode(this.state.mode[key]);
      if (field) return condition && field === key;
      return condition;
    });
  }

  public controlledRefPopup = (field: BcPiFields) => {
    const open = this.state.showPiFields[field];
    const mode = this.state.mode[field];
    let position;
    let title;
    let segmentType;

    switch (field) {
      case BcPiFields.MAIN_ACHIEVEMENTS:
        position = 'top right';
        title = <FormattedMessage id="mainAchievements" defaultMessage="Main achievements" />;
        segmentType = SegmentType.BC_MAIN_ACHIEVEMENTS;
        break;
      case BcPiFields.ISSUES_AND_RISKS:
        position = 'top left';
        title = <FormattedMessage id="mainIssuesAndRisks" defaultMessage="Main issues and risks" />;
        segmentType = SegmentType.BC_MAIN_ISSUES;
        break;
      case BcPiFields.DECISIONS_TO_BE_MADE:
        position = 'top right';
        title = <FormattedMessage id="decisionsToBeMade" defaultMessage="Decisions to be made" />;
        segmentType = SegmentType.BC_DECISIONS_TO_BE_MADE;
        break;
      case BcPiFields.NEXT_STEPS:
        position = 'top left';
        title = <FormattedMessage id="nextSteps" defaultMessage="Next steps" />;
        segmentType = SegmentType.BC_NEXT_STEPS;
        break;
    }

    const segment = (
      <Segment id={LastUpdateHighlight.getSegmentId(segmentType)} className={open ? 'pi-popup-opened' : ''}>
        <div className="title-element">
          {Utils.getTitleWithErrIcon(title, this.isFieldOnErr(field))}
          {this.props.canEdit && <SegmentEditButtons
            segmentType={segmentType}
            mode={mode}
            isSaveDisabled={this.state.isOnError[field]}
            onSave={() => this.onSave(field)}
            onCancel={() => this.onCancel(field)}
            changeMode={mode => this.changeMode(mode, field)}
          />}
        </div>
        <div className="content-element">
          {this.getElement(field)}
        </div>
      </Segment>
    );

    let popup;

    if (this.hasFieldOnEditMode(field) && open) {
      let performanceInitiatives: PerformanceInitiativesCockpitFields[] = [];
      const linkedPiIds = this.state.bcPisFields?.linkedPis.map(pi => pi.id);

      if (!!this.state.bcPisFields?.performanceInitiatives) {
        performanceInitiatives = [...performanceInitiatives, ...this.state.bcPisFields?.performanceInitiatives];
        performanceInitiatives.sort((a, b) => (parseInt(String(a.code), 10) < parseInt(String(b.code), 10) ? -1 : 1));
      }
      if (!!this.state.bcPisFields?.linkedPis) {
        performanceInitiatives = [...performanceInitiatives, ...this.state.bcPisFields?.linkedPis];
      }

      const filteredPerformanceInitiatives = performanceInitiatives.filter(pi => !!pi[field] && pi[field] !== '');

      let popupContent;
      if (!!this.state.bcPisFields && filteredPerformanceInitiatives.length > 0) {
        popupContent = filteredPerformanceInitiatives.map((pi, index) => (
          <>
            {index > 0 && <Divider />}
            <div key={`${field}-pi-${pi.id}`} className="pi-content">
              <h5>
                PI{pi.code} - {pi.name}
                {linkedPiIds?.includes(pi.id) && <Icon name="linkify" className="link-icon"/>}
              </h5>
              {Utils.getFormattedTextFromHTMLString(pi[field])}
            </div>
          </>
        ));
      }

      popup = (
        <div id="popup-pi-container" className={position}>
          <div className="close-button" onClick={() => this.handleClickPiButton()}>
            <Icon name="delete" />
          </div>
          {popupContent && popupContent.length > 0
            ? <>
              <div className="placeholder">
                {popupContent}
              </div>

              <CustomScrollBars>
                {popupContent}
              </CustomScrollBars>
            </>
            : <div key="empty-pi-field" className="popup-empty">
              <FormattedMessage
                id={`emptyPis${field.charAt(0).toUpperCase() + field.slice(1)}`}
                defaultMessage={`All PI ${title} are empty.`}
              />
            </div>
          }
        </div>
      );
    }

    return (
      <>
        {segment}
        {popup}
      </>
    );
  }

  public render() {

    const firstLineEditCondition = Utils.isOnEditOrCancelMode(this.state.mode.achievements)
      || Utils.isOnEditOrCancelMode(this.state.mode.issuesAndRisks);

    const secondLineEditCondition = Utils.isOnEditOrCancelMode(this.state.mode.decisionsToBeMade)
      || Utils.isOnEditOrCancelMode(this.state.mode.nextSteps);

    const isPopupDisplayed = Object.keys(this.state.showPiFields).some(key => this.state.showPiFields[key]);

    return (
        <div id="global-state-bc">
          <div className="new-tab-container">
            {this.props.canEdit && <FormattedMessage id="pISyntheticViewBc.newPi" defaultMessage="New PI">
              {msg =>
                <Dropdown
                  text={msg.toString()}
                  icon="add circle"
                  floating={true}
                  labeled={true}
                  button={true}
                  direction="left"
                  className="icon"
                  id="bc-button-group"
                  disabled={this.hasFieldOnEditMode()}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => BusinessChallengeActions.emitOpenNewPiModal(true)}
                    >
                      <Icon name="add" />
                      <FormattedMessage id="pISyntheticViewBc.addPi" defaultMessage="Create a PI" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => BusinessChallengeActions.emitOpenImportPiModal(true)}
                    >
                      <Icon name="history" />
                      <FormattedMessage id="pISyntheticViewBc.importPi" defaultMessage="Import a PI" />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              }
            </FormattedMessage>
            }
          </div>

          <div className={`line text-container ${firstLineEditCondition ? 'editing' : ''}`}>
            <div className="column">
              {this.controlledRefPopup(BcPiFields.MAIN_ACHIEVEMENTS)}
            </div>
            <div className="column">
              {this.controlledRefPopup(BcPiFields.ISSUES_AND_RISKS)}
            </div>
          </div>
          <div className={`line text-container ${secondLineEditCondition ? 'editing' : ''}`}>
            <div className="column">
              {this.controlledRefPopup(BcPiFields.DECISIONS_TO_BE_MADE)}
            </div>
            <div className="column">
              {this.controlledRefPopup(BcPiFields.NEXT_STEPS)}
            </div>
          </div>

          {isPopupDisplayed && <div id="background" />}
        </div>
    );
  }
}

export default withRouter(Cockpit);
