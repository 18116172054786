import './activityLink.scss';

import * as React from 'react';
import { Divider, Dropdown } from 'semantic-ui-react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';

import PpGbuGeoEdition, { IBusinessLocationsValues, IRenderBusinessLocations } from '../common/form/ppGbuGeo/ppGbuGeo';
import CustomScrollBars from '../common/customScrollBars/customScrollBars';
import performancePlanStore from 'src/stores/performancePlan.store';
import PerformancePlanActions from 'src/actions/performancePlan.action';
import ActionTypes from 'src/constants/actionTypes';

interface IProps extends InjectedIntlProps {
  selectedGeoGbuValues: IBusinessLocationsValues;
  selectedYear?: string;
  onChange(selectedGeoGbuValues?: IBusinessLocationsValues, selectedYear?: string): void;
}

interface IStates {
  years: string[];
}

class ActivityFilterSelection extends React.Component<IProps, IStates> {

  private isMount = false;

  public constructor(props: IProps) {
    super(props);

    this.state = {
      years: [],
    };
  }

  componentDidMount() {
    this.isMount = true;
    this.getYears();
    performancePlanStore.addListener(ActionTypes.PERFORMANCE_PLAN_YEARS_GET.toString(), this.getYears);
  }

  componentWillUnmount() {
    this.isMount = false;
    performancePlanStore.removeListener(ActionTypes.PERFORMANCE_PLAN_YEARS_GET.toString(), this.getYears);
  }

  private getYears = () => {
    if (this.isMount) {
      const years = performancePlanStore.getYears();
      if (years === undefined) {
        PerformancePlanActions.emitGetPerformancePlanYears();
      } else {
        this.setYears(years);
      }
    }
  }

  private setYears = (years: string[]) => {
    this.setState({ years });
  }

  private onChange = (selectedGeoGbuValues?: IBusinessLocationsValues, selectedYear?: string) => {
    this.props.onChange(selectedGeoGbuValues, selectedYear);
  };

  public renderGeoGbu(elements: IRenderBusinessLocations): JSX.Element {
    return (
      <>
        <div className="category-title">
          <FormattedMessage id="business" defaultMessage="Business" />
        </div>
        {elements.gbu}
        {elements.businessLine}
        {elements.segment}

        <Divider />

        <div className="category-title">
          <FormattedMessage id="location" defaultMessage="Location" />
        </div>
        {elements.geography}
        {elements.country}
        {elements.subcountry}
      </>
    );
  }

  private renderYearSelection = () => {
    return (
      <>
        <div className="category-title">
          <FormattedMessage id="year" defaultMessage="Year" />
        </div>
        <div className="year-element">
          <Dropdown
            fluid={true}
            selection={true}
            options={[
              {
                key: 'all',
                text: this.props.intl.formatMessage({ id: 'all', defaultMessage: 'ALl' }),
                value: 'all',
              },
              ...this.state.years.map(year => ({ key: year, text: year, value: year })),
            ]}
            onChange={(e, { value }) => this.onChange(undefined, value as string)}
            value={this.props.selectedYear}
          />
        </div>
        <Divider />
      </>
    );
  }

  public render() {
    return (
      <div id="activity-link-step2" className="step-container">
        <CustomScrollBars>
          {this.props.selectedYear && this.state.years && this.renderYearSelection()}
          <PpGbuGeoEdition
            selectedValues={this.props.selectedGeoGbuValues}
            onChange={this.onChange}
            renderGeoGbu={this.renderGeoGbu}
          />
        </CustomScrollBars>
      </div>
    );
  }
}

export default injectIntl(ActivityFilterSelection);
