import './elementUpcoming.scss';

import * as React from 'react';
import { Popup } from 'semantic-ui-react';
import { RouteComponentProps, withRouter } from 'react-router';

import TypeActivity from '../../../../constants/typeActivity';
import { PpTabs } from '../../../activitiesBoard/containerActivities/contentPP/contentPP';
import { BcTabs } from '../../../activitiesBoard/containerActivities/contentBC/contentBC';
import { PiTabs } from '../../../activitiesBoard/containerActivities/contentPI/contentPI';
import { TimelineObject } from '../../../../constants/timeline.types';
import TypeElementTimeline from 'src/constants/timeline';
import Utils from 'src/utils/utils';

interface IProps extends RouteComponentProps{
  element: TimelineObject;
}

interface FormattedData {
  name: string;
  desc: string;
  shortened: boolean;
}

class ElementUpcoming extends React.Component<IProps> {

  public constructor(props: IProps) {
    super(props);
  }

  private getBadgeStatus = (): JSX.Element => {
    switch (this.props.element.elementType) {
      case TypeElementTimeline.MILESTONE:
        return (<div id={'milestonesBadge'} className={'badge'} />);
      case TypeElementTimeline.ACTION:
        return (<div id={'actionsBadge'} className={'badge'} />);
      default:
      case TypeElementTimeline.REPORTING:
        return (<div id={'reportingsBadge'} className={'badge'} />);
    }
  }

  private displayNameAndDesc = (): FormattedData => {
    const maxInnerWidth = 1400;
    const maxInnerHeight = 900;
    const maxNameLength = 30;
    const maxDescLength = 25;
    const reducedNameLength = 27;
    const reducedDescLength = 22;

    if (window.innerWidth >= maxInnerWidth && window.innerHeight >= maxInnerHeight) {
      return {
        name: `${this.props.element.name}`,
        desc: ` - ${this.props.element.desc}`,
        shortened: false,
      };
    }
    if (this.props.element.name.length > maxNameLength && this.props.element.desc.length > maxDescLength) {
      return {
        name: `${this.props.element.name.substring(0, reducedNameLength)}...`,
        desc: ` - ${this.props.element.desc.substring(0, reducedDescLength)}...`,
        shortened: true,
      };
    }
    if (this.props.element.name.length > maxNameLength) {
      return {
        name: `${this.props.element.name.substring(0, reducedNameLength)}...`,
        desc: ` - ${this.props.element.desc}`,
        shortened: true,
      };
    }
    if (this.props.element.desc.length > maxDescLength) {
      return {
        name: `${this.props.element.name}`,
        desc: ` - ${this.props.element.desc.substring(0, reducedDescLength)}...`,
        shortened: true,
      };
    }
    return {
      name: `${this.props.element.name}`,
      desc: ` - ${this.props.element.desc}`,
      shortened: false,
    };
  }

  private redirect = () => {
    if (this.props.element.elementType !== TypeElementTimeline.ACTION) {
      switch (this.props.element.activityType) {
        case TypeActivity.PERFORMANCE_PLAN:
          this.props.history.push(`/activities-board/performance-plan/${this.props.element.activityId}/${PpTabs.COCKPIT}`);
          break;
        case TypeActivity.BUSINESS_CHALLENGE:
          this.props.history.push(`/activities-board/${this.props.element.ppId}/business-challenge/${this.props.element.activityId}/${BcTabs.COCKPIT}`);
          break;
        case TypeActivity.PERFORMANCE_INITIATIVE:
          this.props.history.push(`/activities-board/${this.props.element.ppId}/performance-initiative/${this.props.element.activityId}/${PiTabs.COCKPIT}`);
          break;
      }
    } else {
      this.props.history.push(`/activities-board/${this.props.element.ppId}/performance-initiative/${this.props.element.activityId}/${PiTabs.ACTIVITY}`);
    }
  }

  public render() {
    const date = new Date(this.props.element.date);
    const formattedNameAndDesc: FormattedData  = this.displayNameAndDesc();
    const element = (
        <div id={'elementTimeline'}>
          {this.getBadgeStatus()}
          <div className={'date'} >{`${Utils.displayDate(date)} :`}</div>
          <div className={'name'} onClick={this.redirect}>{formattedNameAndDesc.name}</div>
          <div className={'desc'} >{formattedNameAndDesc.desc}</div>
        </div>
    );

    if (formattedNameAndDesc.shortened) {
      return (
          <Popup
              inverted={true}
              content={`${Utils.displayDate(date)} : ${this.props.element.name} - ${this.props.element.desc}`}
              size="tiny"
              position="top center"
              trigger={element}
          />
      );
    }
    return (element);
  }
}

export default withRouter(ElementUpcoming);
