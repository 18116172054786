import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';

import PerformancePlanStore from '../../../../stores/performancePlan.store';
import PerformancePlanActions from '../../../../actions/performancePlan.action';
import ActionTypes from '../../../../constants/actionTypes';
import IPpBusinessLocations from '../../../../models/performancePlanBusinessLocations';
import Utils from '../../../../utils/utils';
import BusinessLine from '../../../../models/businessLine';
import Segment from '../../../../models/segment';
import Country from '../../../../models/country';
import SubCountry from '../../../../models/subcountry';
import Gbu from '../../../../models/gbu';
import Geography from '../../../../models/geography';

export enum BusinessLocationsKeys {
  GBU = 'gbus',
  BUSINESS_LINE = 'businessLines',
  SEGMENT = 'segments',
  GEOGRAPHY = 'geographies',
  COUNTRY = 'countries',
  SUBCOUNTRY = 'subcountries',
}

export enum BusinessLocationsAttr {
  GBU = 'gbuId',
  BUSINESS_LINE = 'businessLineId',
  SEGMENT = 'segmentId',
  GEOGRAPHY = 'geographyId',
  COUNTRY = 'countryId',
  SUBCOUNTRY = 'subcountryId',
}

export interface IBusinessLocationsValues {
  gbuId: number;
  businessLineId: number;
  segmentId: number;
  geographyId: number;
  countryId: number;
  subcountryId: number;
  year?: string;
}

export interface IRenderBusinessLocations {
  gbu: JSX.Element;
  businessLine: JSX.Element;
  segment: JSX.Element;
  geography: JSX.Element;
  country: JSX.Element;
  subcountry: JSX.Element;
}

interface IProps extends InjectedIntlProps {
  selectedValues: IBusinessLocationsValues;
  onChange(values: IBusinessLocationsValues): void;
  renderGeoGbu(elements: IRenderBusinessLocations): JSX.Element;
}

interface IStates {
  options: IPpBusinessLocations;
}

class PpGbuGeoEdition extends React.Component<IProps, IStates> {
  private isMount = false;
  private readonly NONE_VALUE = 1;

  public constructor(props: IProps) {
    super(props);

    this.state = {
      options: PerformancePlanStore.getBusinessLocations(),
    };
  }

  public componentDidMount() {
    this.isMount = true;
    PerformancePlanStore.addListener(ActionTypes.PERFORMANCE_PLAN_ENTITIES_LOCATIONS_GET.toString(), this.setOptions);

    if (PerformancePlanStore.getBusinessLocations() === undefined) {
      PerformancePlanActions.emitGetPpEntitiesLocations();
    } else {
      this.setOptions();
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>) {
    if (prevProps.selectedValues !== this.props.selectedValues) {
      this.setOptions();
    }
  }

  public componentWillUnmount() {
    this.isMount = false;
    PerformancePlanStore.removeListener(
      ActionTypes.PERFORMANCE_PLAN_ENTITIES_LOCATIONS_GET.toString(), this.setOptions);
  }

  /**
   * Set option lists with correct sub-values
   */
  private setOptions = () => {
    if (this.isMount) {
      const options = { ...PerformancePlanStore.getBusinessLocations() };
      if (options) {
        options.businessLines = this.filterOptions(options.businessLines, BusinessLocationsAttr.GBU) as BusinessLine[];
        options.segments = this.filterOptions(options.segments, BusinessLocationsAttr.BUSINESS_LINE) as Segment[];
        options.countries = this.filterOptions(options.countries, BusinessLocationsAttr.GEOGRAPHY) as Country[];
        options.subcountries = this.filterOptions(options.subcountries, BusinessLocationsAttr.COUNTRY) as SubCountry[];

        this.setState({ options });
      }
    }
  };

  /**
   * Filter options to keep only sub-values of the selected parent value, and the None value
   * @param options: (BusinessLine | Segment | Country | SubCountry)[]
   * @param parent: DropdownOptions
   * @returns {(BusinessLine | Segment | Country | SubCountry)[]}
   */
  private filterOptions(options: (BusinessLine | Segment | Country | SubCountry)[], parent: BusinessLocationsAttr) {
    return options.filter(el => el[parent] === this.props.selectedValues[parent] || el.id === this.NONE_VALUE);
  }

  /**
   * Update selected values (and its sub-values) then send it to the parent component
   * @param idAttribute: string
   * @param value: number
   */
  private onChange = (idAttribute: string, value: number) => {
    const values = { ...this.props.selectedValues };
    values[idAttribute] = value;

    switch (idAttribute) {
      case 'gbuId':
        values.businessLineId = this.NONE_VALUE;
      // fall through
      case 'blId':
        values.segmentId = this.NONE_VALUE;
        break;
      case 'geographyId':
        values.countryId = this.NONE_VALUE;
      // fall through
      case 'countryId':
        values.subcountryId = this.NONE_VALUE;
        break;
      default:
        break;
    }

    this.props.onChange(values);
  };

  /**
   * Sort provided options list (keep None value first) and format it to DropdownItemProps object
   * @param options: (Gbu | BusinessLine | Segment | Geography | Country | SubCountry)[]
   * @returns {DropdownItemProps[]}
   */
  private generateOptionList(options: (Gbu | BusinessLine | Segment | Geography | Country | SubCountry)[])
    : DropdownItemProps[] {

    options.sort((a, b) => {
      if (a.id === this.NONE_VALUE) return -1;
      if (b.id === this.NONE_VALUE) return 1;

      if (a.name === b.name) return 0;
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    const formattedOptions = options.map(el => ({ key: el.id, text: el.name, value: el.id }));

    formattedOptions[0].text = this.props.intl.formatMessage({ id: 'noValue', defaultMessage: 'None' });
    return formattedOptions;
  }

  /**
   * Create the field with provided label and options
   * @param title: JSX.Element
   * @param dropdownOption: DropdownOptions
   * @returns {JSX.Element}
   */
  private renderDropdown(title: JSX.Element, dropdownOption: BusinessLocationsAttr): JSX.Element {
    let options: (Gbu | BusinessLine | Segment | Geography | Country | SubCountry)[] = [];

    if (this.state.options) {
      switch (dropdownOption) {
        case BusinessLocationsAttr.GBU:
          options = this.state.options.gbus;
          break;
        case BusinessLocationsAttr.BUSINESS_LINE:
          options = this.state.options.businessLines;
          break;
        case BusinessLocationsAttr.SEGMENT:
          options = this.state.options.segments;
          break;
        case BusinessLocationsAttr.GEOGRAPHY:
          options = this.state.options.geographies;
          break;
        case BusinessLocationsAttr.COUNTRY:
          options = this.state.options.countries;
          break;
        case BusinessLocationsAttr.SUBCOUNTRY:
          options = this.state.options.subcountries;
          break;
      }
    }

    return (
      <div className="geo-gbu-element">
        <div className="title">
          {title}
        </div>
        <Dropdown
          fluid={true}
          selection={true}
          upward={options === this.state.options.subcountries || options === this.state.options.countries}
          options={this.generateOptionList(options)}
          onChange={(event, data) => this.onChange(dropdownOption, data.value as number)}
          value={this.props.selectedValues[dropdownOption]}
        />
      </div>
    );
  }

  public render() {
    if (this.state.options === undefined) {
      return Utils.loader();
    }

    const elements: IRenderBusinessLocations = {
      gbu: this.renderDropdown(
        <FormattedMessage id="pp.info.gbu" defaultMessage="GBU" />,
        BusinessLocationsAttr.GBU,
      ),
      businessLine: this.renderDropdown(
        <FormattedMessage id="pp.info.bl" defaultMessage="Business Line" />,
        BusinessLocationsAttr.BUSINESS_LINE,
      ),
      segment: this.renderDropdown(
        <FormattedMessage id="pp.info.segment" defaultMessage="Segment" />,
        BusinessLocationsAttr.SEGMENT,
      ),
      geography: this.renderDropdown(
        <FormattedMessage id="pp.info.geo" defaultMessage="Geography" />,
        BusinessLocationsAttr.GEOGRAPHY,
      ),
      country: this.renderDropdown(
        <FormattedMessage id="country" defaultMessage="Country" />,
        BusinessLocationsAttr.COUNTRY,
      ),
      subcountry: this.renderDropdown(
        <FormattedMessage id="pp.info.subCountry" defaultMessage="Subcountry" />,
        BusinessLocationsAttr.SUBCOUNTRY,
      ),
    };

    return this.props.renderGeoGbu(elements);
  }
}

export default injectIntl(PpGbuGeoEdition);
