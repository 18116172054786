import './breadcrumb.scss';

import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { Button, Checkbox, Icon } from 'semantic-ui-react';

import Utils from 'src/utils/utils';
import TypeActivity from '../../../constants/typeActivity';
import BusinessChallenge from '../../../models/businessChallenge';
import History, { OrderedHistories } from '../../../models/history';
import PerformanceInitiative from '../../../models/performanceInitiative';
import PerformancePlan from '../../../models/performancePlan';
import TreeView from 'src/components/treeView/treeView';
import CustomDropdown from
'src/components/activitiesBoard/containerActivities/titleContainer/customDropdown/customDropdown';
import { MenuItems } from
'src/components/activitiesBoard/containerActivities/titleContainer/titleContainer';
import exportStore from 'src/stores/export.store';
import ActionTypes from 'src/constants/actionTypes';
import ExportService from 'src/services/export.service';
import ExportActions, { ExportType } from '../../../actions/export.action';

interface IProps extends RouteComponentProps {
  data: PerformancePlan | BusinessChallenge | PerformanceInitiative;
  typeActivity: TypeActivity;
  histories: History[];
  isExternal: boolean;
  currentHistoryId?: number;
  isPublicView?: boolean;

  handleClick(historyId: number): void;
  togglePublicView?(isVisible: boolean): void;
  goToExternal(activityId: number, route: string): void;
}

interface IStates {
  showTreeView: boolean;
  orderedHistories: OrderedHistories[];
  moreActionsVisible: boolean;
}

class Breadcrumb extends React.Component<IProps & InjectedIntlProps, IStates> {

  public constructor(props: IProps & InjectedIntlProps) {
    super(props);
    this.state = {
      showTreeView: false,
      orderedHistories: [],
      moreActionsVisible: false,
    };
  }

  public componentWillMount(): void {
    this.setHistoryDropdown(this.props.histories);
    exportStore.addListener(ActionTypes.PDF_ID_RECEIVED.toString(), this.onPdfIdReceived);
  }

  private handleClickMoreActionsButton = () => {
    if (!this.state.moreActionsVisible) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      moreActionsVisible: !prevState.moreActionsVisible,
    }));
  };

  private handleOutsideClick = () => {
    this.handleClickMoreActionsButton();
  };

  private exportHistoryActivity = () => {
    let exportType;
    switch (this.props.typeActivity) {
      case TypeActivity.PERFORMANCE_PLAN:
        exportType = ExportType.PP_HISTORY;
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        exportType = ExportType.BC_HISTORY;
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        exportType = ExportType.PI_HISTORY;
        break;
    }
    ExportActions.emitExport(
      exportType,
      this.props.data.id,
      undefined,
      undefined,
      undefined,
      this.props.currentHistoryId,
      undefined,
      this.props.isPublicView,
    );
  };

  private onPdfIdReceived = () => {
    ExportService.startPullingPdfProgression();
  };

  private setHistoryDropdown = (histories: History[]) => {
    let betterHistories = histories.map((el) => {
      return { ...el, year : new Date(el.publishedDate).getFullYear() };
    });
    // Get only last per day
    betterHistories = betterHistories.filter((v : History, i : any, a : any) => a.findIndex(
      (t : History) => (Utils.displayDate(t.publishedDate) === Utils.displayDate(v.publishedDate)),
    ) === i);
    const orderedHistories = Utils.groupBy(betterHistories, 'year');
    orderedHistories.reverse();
    this.setState({ orderedHistories });
  }

  public render() {
    const separator = <Icon className="separator" name="chevron right" />;
    let breadcrumbActivity: JSX.Element[] = [];
    let ppId: number = 0;

    switch (this.props.typeActivity) {
      case TypeActivity.PERFORMANCE_PLAN:
        ppId = this.props.data.id;
        breadcrumbActivity = [
          (
            <div className="activity-name" key="current-activity">
              {this.props.data.name}
              {this.props.isExternal ? null : separator}
            </div>
          ),
        ];
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        ppId = (this.props.data as BusinessChallenge).performancePlan.id;
        breadcrumbActivity = [
          (
            <div className="activity-name" key="pp-activity">
              <span
                className={this.props.isExternal ? 'pointer' : ''}
                onClick={
                  this.props.isExternal
                    ? () => this.props.goToExternal(
                    ppId,
                    'pp-external',
                    )
                    : () => {
                    }
                }
              >
                {(this.props.data as BusinessChallenge).performancePlan.name}
              </span>
              {separator}
            </div>
          ),
          (
            <div className="activity-name" key="current-activity">
              {`BC${Utils.leadingZero((this.props.data as BusinessChallenge).code)}`}
              {this.props.isExternal ? null : separator}
            </div>
          ),
        ];
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        ppId = (this.props.data as PerformanceInitiative).businessChallenge.performancePlan.id;
        breadcrumbActivity = [
          (
            <div className="activity-name" key="pp-activity">
              <span
                className={this.props.isExternal ? 'pointer' : ''}
                onClick={
                  this.props.isExternal
                    ? () => this.props.goToExternal(
                    ppId,
                    'pp-external',
                    )
                    : () => {
                    }
                }
              >
                {(this.props.data as PerformanceInitiative).businessChallenge.performancePlan.name}
              </span>
              {separator}
            </div>
          ),
          (
            <div className="activity-name" key="bc-activity">
              <span
                className={this.props.isExternal ? 'pointer' : ''}
                onClick={
                  this.props.isExternal
                    ? () => this.props.goToExternal(
                    (this.props.data as PerformanceInitiative).businessChallenge.id,
                    'bc-external',
                    )
                    : () => {
                    }
                }
              >
                {`BC${Utils.leadingZero((this.props.data as PerformanceInitiative).businessChallenge.code)}`}
              </span>
              {separator}
            </div>
          ),
          (
            <div className="activity-name" key="current-activity">
              {`PI${Utils.leadingZero((this.props.data as PerformanceInitiative).code)}`}
              {this.props.isExternal ? null : separator}
            </div>
          ),
        ];
        break;
    }

    const treeViewButton = (
      <Button onClick={() => this.setState({ showTreeView: true })}>
        <FormattedMessage id="treeView" defaultMessage="Tree View" />
      </Button>
    );

    const dropdownMenuItems: MenuItems[] | undefined = [];

    if (this.state.orderedHistories && this.state.orderedHistories.length > 0) {
      const route = this.props.match.path.split('/:')[0];
      if (dropdownMenuItems) {
        dropdownMenuItems.push(
          {
            title: <FormattedMessage id="publishedAt" defaultMessage="Last Publication" />,
            handleClick: () => this.props.history.push(
              `/${route.substr(1)}/${this.props.data.id}/${this.state.orderedHistories[0][1][0].id}`,
            ),
          },
        );
      }

      this.state.orderedHistories.forEach((el : any) => {
        if (dropdownMenuItems) {
          const subList: any = [];
          el[1].forEach((el : History) => {
            subList.push({
              title: <span key={`history-list${el.id}`}> {Utils.displayFancyDate(el.publishedDate)}</span>,
              handleClick: () => this.props.history.push(`/${route.substr(1)}/${this.props.data.id}/${el.id}`),
            });
          });
          dropdownMenuItems.push(
            {
              title: <span key={`history-list${el[0].id}`}>{el[0]}</span>,
              subMenu: subList,
            },
          );
        }
      });
    } else {
      if (dropdownMenuItems) {
        dropdownMenuItems.push(
          {
            title: <FormattedMessage id="noHistory" defaultMessage="No history yet" />,
          },
        );
      }
    }
    const currentHistory : any = this.props.histories.find((el: History) => {
      return el.id === this.props.currentHistoryId;
    });

    return (
      <div id="breadcrumb">
        <div id="left">
          {breadcrumbActivity}
          {!this.props.isExternal
            && <div className={'history-menu-container activity-name'}>
              <Button
                onClick={this.handleClickMoreActionsButton}
                className="history-button"
              >
                <span>
                  <FormattedMessage id="publicationOf" defaultMessage="Publication of" />&nbsp;
                  {Utils.displayFancyDate(currentHistory.publishedDate)}
                  <Icon name="chevron down" />
                </span>
              </Button>
              <CustomDropdown
                key={'CustomDropdown-menu'}
                display={this.state.moreActionsVisible}
                menuItems={dropdownMenuItems}
                isSubMenuOnRight={true}
              />
            </div>
          }
        </div>
        <div id="right">
          {this.props.isPublicView !== undefined
            && <div className="public-view-toggle">
              <Checkbox
                toggle={true}
                checked={this.props.isPublicView}
                label={this.props.intl.formatMessage({ id: 'publicView', defaultMessage: 'Public View' })}
                onChange={() => this.props.togglePublicView && this.props.togglePublicView(!this.props.isPublicView)}
              />
            </div>
          }
          <Button onClick={this.exportHistoryActivity} disabled={!exportStore.getCanExport()}>
            <Icon name="download"/>
            <FormattedMessage id="export" defaultMessage="Export"/>
          </Button>
          {!this.props.isExternal ? treeViewButton : null}
        </div>
        {this.state.showTreeView
          ? <TreeView
            open={this.state.showTreeView}
            close={() => this.setState({ showTreeView: false })}
            currentActivityId={this.props.data.id}
            currentActivityType={this.props.typeActivity}
            ppId={ppId}
          />
          : null
        }
      </ div>
    );
  }
}

export default withRouter(injectIntl(Breadcrumb));
