import './milestonesManagement.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Utils from '../../../../utils/utils';
import ModeTypes from '../../../../constants/modeTypes';
import TypeActivity from '../../../../constants/typeActivity';
import ActionStore from 'src/stores/action.store';
import MilestoneStore from 'src/stores/milestone.store';
import ActionTypes from 'src/constants/actionTypes';
import Milestone from 'src/models/milestone';
import { Dropdown, Icon, Segment } from 'semantic-ui-react';
import LastUpdateHighlight from '../common/overviewPanel/panelContent/tabs/lastUpdatedInformations/lastUpdateHighlight';
import { SegmentType } from 'src/models/segmentsMode';
import SegmentsStore from '../../../../stores/segments.store';
import SegmentEditButtons from '../common/segmentEditButtons/segmentEditButtons';
import NotificationActions from 'src/actions/notification-actions';
import { ToastType } from 'src/components/common/toast/toast';
import ActivitiesActions from 'src/actions/activities.action';
import MilestoneAction from 'src/actions/milestone.action';
import LinkedMilestone from 'src/models/linkedMilestone';

interface IProps {
  activityId: number | null;
  activityType: TypeActivity;
  actionId: number;
  canEdit: boolean;
  milestones: Milestone[] | null;
  mode?: ModeTypes;
  shouldResetMilestones?: boolean;
  setMilestonesResetDone?(): void;
}

interface IStates {
  availableMilestone: Milestone[] | undefined;
  linkedMilestone: Milestone[] | undefined;
  oldMilestone: Milestone[] | undefined;
  isLoading: boolean;
  mode: ModeTypes;
  listDisabled: boolean;
  actionLinkedMilestone: Map<number, LinkedMilestone[]>;
}

class MilestonesManagement extends React.Component<IProps, IStates> {

  private isMount = false;

  constructor(props: IProps) {
    super(props);

    this.state = {
      availableMilestone: [],
      linkedMilestone: [],
      oldMilestone: [],
      isLoading: true,
      mode: SegmentsStore.getSegmentModeById(SegmentType.AT_MILESTONES),
      listDisabled : false,
      actionLinkedMilestone: MilestoneStore.getMilestoneLinkedAction(),
    };
  }

  componentDidMount() {
    this.isMount = true;
    ActionStore.addListener(ActionTypes.ACTION_SAVED.toString(), this.onSave);
    MilestoneStore.addListener(ActionTypes.GET_LINK_MILESTONE_TO_ACTION.toString(), this.getLinkedMilestone);
    this.getData();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>, snapshot?: any) {
    if (this.props.shouldResetMilestones) {
      this.onCancel(this.props.setMilestonesResetDone);
    }
    if ((!prevProps.activityId && this.props.activityId)
      || (prevProps.milestones?.length !== this.props.milestones?.length)
      || (this.props.actionId !== prevProps.actionId)) {
      this.getData();
    }
  }

  componentWillUnmount() {
    this.isMount = false;
    ActionStore.removeListener(ActionTypes.ACTION_SAVED.toString(), this.onSave);
    MilestoneStore.removeListener(ActionTypes.GET_LINK_MILESTONE_TO_ACTION.toString(), this.getLinkedMilestone);
  }

  getLinkedMilestone = () => {
    if (this.isMount) {
      this.setState({ actionLinkedMilestone: MilestoneStore.getMilestoneLinkedAction() });
      this.getData();
    }
  }

  private getData = () => {
    if (this.props.activityId!!) {
      const availableMilestone = this.props.milestones!!.filter((mi: Milestone) => {
        return this.state.actionLinkedMilestone.get(this.props.actionId!!)!!
          .find(i => i.pimiId === mi.id) === undefined;
      });
      const linkedMilestone = this.props.milestones!!.filter((mi: Milestone) => {
        return this.state.actionLinkedMilestone.get(this.props.actionId!!)!!
          .find(i => i.pimiId === mi.id) !== undefined;
      });
      const isDisabled = linkedMilestone.length > 0;
      this.setState({
        availableMilestone,
        linkedMilestone,
        oldMilestone: linkedMilestone,
        isLoading: false,
        listDisabled: isDisabled,
      });
    }
  }

  //region Cancel/Save methods
  private onCancel = (callback?: Function) => {
    const oldMilestone = [...this.state.oldMilestone!!];
    const availableMilestone = this.state.availableMilestone!!.filter((mi: Milestone) => !oldMilestone.includes(mi));
    this.setState(
      {
        availableMilestone,
        linkedMilestone: oldMilestone,
      },
      () => {
        callback && callback();
      });
  }

  private onSave = async () => {
    if (!this.isMount) {
      return;
    }
    if (this.state.oldMilestone && this.state.linkedMilestone) {
      const promises: Promise<void>[] = [];
      const changes = Utils.filterChanges(this.state.oldMilestone, this.state.linkedMilestone);

      if (changes.create.length !== 0) {
        changes.create.forEach((milestone: Milestone) => {
          promises.push(MilestoneAction.updateLinkedMilestone(this.props.actionId, milestone.id!!, true));
        });
      }
      if (changes.remove.length !== 0) {
        changes.remove.forEach((milestone: Milestone) => {
          promises.push(MilestoneAction.deleteLinkedMilestone(this.props.actionId, milestone.id!!, true));
        });
      }

      Promise.all(promises)
        .then(() => {
          this.getData();
          if (this.props.mode === undefined) {
            NotificationActions.toast(
              <FormattedMessage id="saved" defaultMessage="Saved!" />,
              (
                <FormattedMessage
                  id="milestones.successfullySaved"
                  defaultMessage="The milestones were successfully saved"
                />
              ),
              ToastType.SUCCESS,
            );
          }
          ActivitiesActions.emitUpdatedLastUpdate();
        })
        .catch(() => Utils.toastError());
    }
  }
  //endregion

  /**
   * Handle milestone deletion
   * @param id: id of the milestone to delete
   */
  private deleteMilestone = (id: number) => {
    if (this.state.linkedMilestone && this.state.availableMilestone) {
      const currentMilestone = [...this.state.linkedMilestone];
      let availableMilestone: Milestone[];
      for (const milestone of this.state.linkedMilestone) {
        if (milestone.id === id) {
          availableMilestone = [...this.state.availableMilestone];
          availableMilestone.push(milestone);
        }
      }
      const linkedMilestone = currentMilestone.filter(a => a.id !== id);
      const isDisabled = linkedMilestone.length > 0;
      this.setState({ linkedMilestone, availableMilestone: availableMilestone!!, listDisabled: isDisabled });
    }
  };

  /**
   * Handle milestone addition
   */
  private addMilestone = (id: number) => {
    if (this.state.linkedMilestone && this.state.availableMilestone) {
      const currentMilestone = [...this.state.availableMilestone];
      let linkedMilestone: Milestone[];
      linkedMilestone = [];
      this.state.availableMilestone.forEach((milestone) => {
        if (milestone.id === id) {
          linkedMilestone = [...this.state.linkedMilestone!!];
          linkedMilestone.push(milestone);
        }
      });
      const availableMilestone = currentMilestone.filter(a => a.id !== id);
      const isDisabled = linkedMilestone.length > 0;
      this.setState({ linkedMilestone, availableMilestone, listDisabled: isDisabled });
    }
  };

  private changeMode = (mode: ModeTypes) => {
    this.setState({ mode });
  }

  private generatedMilestoneListDropdown = () => {
    return this.state.availableMilestone!!.map((milestone) => {

      return {
        key: milestone.id!!,
        value: milestone.id!!,
        content: milestone.name,
      };
    });

  }

  public render() {
    const segmentType = SegmentType.AT_MILESTONES;
    const title = <FormattedMessage id="linkedMilestones" defaultMessage="Milestones" />;

    return (
      <Segment id={LastUpdateHighlight.getSegmentId(segmentType)}>
        <div className={'linked-milestone'}>
          <div className={'title-element title-message'}>{title}</div>
          <div>
              {this.props.canEdit && !this.props.mode &&
              <SegmentEditButtons
                mode={this.props.mode ?? this.state.mode}
                segmentType={segmentType}
                changeMode={this.changeMode}
                onSave={this.onSave}
                onCancel={this.onCancel}
              />
            }
            {Utils.isOnEditMode(this.props.mode ?? this.state.mode) &&
              <Dropdown
              className="milestone-drop"
              placeholder={'Link a milestone'}
              selection={true}
              loading={this.state.isLoading}
              onChange={(e: any, { value }: any) => this.addMilestone(value)}
              options={this.generatedMilestoneListDropdown()}
              disabled={this.state.listDisabled}
            />
            }
            <ul>
                {this.state.linkedMilestone!!.map((milestone, index) => (
                  <li key={index} >
                    {milestone.name}
                    {Utils.isOnEditMode(this.props.mode ?? this.state.mode) &&
                      <Icon name="times" className="milestone-del"
                        onClick={() => this.deleteMilestone(milestone.id!!)}
                      />
                    }
                  </li>
                ))}

            </ul>
          </div>
        </div>
      </Segment>
    );
  }
}

export default MilestonesManagement;
