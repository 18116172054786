import './userCreation.scss';

import * as React from 'react';
import { Button, Form, Icon, Input, Label, Modal, Select } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import Utils from 'src/utils/utils';
import { UserPost } from 'src/models/user';
import Role from 'src/models/role';
import { ToastType } from 'src/components/common/toast/toast';
import { AccountErrors } from '../../../../../constants/errors/account.error';
import NotificationActions from '../../../../../actions/notification-actions';
import UserUtils from '../../../../../utils/userUtils';
import UserAPI from '../../../../../api/user.api';
import RoleNames from '../../../../../constants/roleNames';

enum ErrorLabel {
  INVALID_TGI,
  INVALID_EMAIL,
  EMAIL_ALREADY_EXISTS,
  TGI_ALREADY_EXISTS,
}

interface IProps {
  roles: Role[];

  passwordReset(password: string): void;
  onModalClose(): void;
  reloadUserList(): void;
}

interface IStates {
  newUser: UserPost;
  addUserEnabled: boolean;
  isTgiConflict: boolean;
  isEmailConflict: boolean;
  tgiValid: boolean | null;
  emailValid: boolean | null;
  userCreating: boolean;
}

export default class UserCreation extends React.Component<IProps, IStates> {

  private defaultNewUser: UserPost = {
    firstName: '',
    lastName: '',
    tgi: '',
    email: '',
    roleId: RoleNames.BASIC_USER,
    isCoreTeam: false,
  };

  private defaultState: IStates = {
    newUser: { ...this.defaultNewUser },
    addUserEnabled: false,
    isTgiConflict: false,
    isEmailConflict: false,
    tgiValid: null,
    emailValid: null,
    userCreating: false,
  };

  public constructor(props: IProps) {
    super(props);
    this.state = this.defaultState;
  }

  /**
   * Close the modal and reset the state
   */
  private close = () => {
    this.resetUser();
    this.props.onModalClose();
  };

  /**
   * Reset new user to its default values
   */
  private resetUser = () => {
    this.setState(this.defaultState);
  };

  /**
   * Handle User creation
   */
  public addUser = () => {
    this.setState(
      { userCreating: true },
      () => {
        UserAPI.postUser(this.state.newUser)
          .then((res) => {
            this.props.passwordReset(res.password);
            this.props.reloadUserList();
            this.close();
          })
          .catch((err) => {
            const stateToUpdate = {
              userCreating: false,
              isEmailConflict: false,
              isTgiConflict: false,
              addUserEnabled: true,
            };

            if (!!err.errors && err.errors.length > 0) {
              let title;
              let message;

              if (err.errors.length === 1) {
                if (err.errors[0] === AccountErrors.EMAIL_ALREADY_USED) {
                  title = (
                    <FormattedMessage id="userCreation.conflictingEmail" defaultMessage="Conflicting email address" />
                  );
                  message = (
                    <FormattedMessage
                      id="userCreation.emailAlreadyExists"
                      defaultMessage="This email address already exists"
                    />
                  );
                  stateToUpdate.isEmailConflict = true;
                } else if (err.errors[0] === AccountErrors.TGI_ALREADY_USED) {
                  title = <FormattedMessage id="userCreation.conflictingTgi" defaultMessage="Conflicting TGI" />;
                  message = (
                    <FormattedMessage id="userCreation.tgiAlreadyExists" defaultMessage="This TGI already exists" />
                  );
                  stateToUpdate.isTgiConflict = true;
                }
              } else {
                title = <FormattedMessage id="userCreation.conflictingFields" defaultMessage="Conflicting fields" />;
                message = (
                  <FormattedMessage
                    id="userCreation.multipleFieldsAlreadyExists"
                    defaultMessage="Multiple fields are conflicting with other users"
                  />
                );
                stateToUpdate.isEmailConflict = true;
                stateToUpdate.isTgiConflict = true;
              }

              stateToUpdate.addUserEnabled = false;
              NotificationActions.toast(title, message, ToastType.ERROR);
            } else {
              Utils.toastError();
            }

            this.setState(stateToUpdate);
          });
      },
    );
  };

  /**
   * Update the new user fields
   * @param {string} idElement
   * @param {any} value
   */
  public handleChange = (idElement: string, value: any) => {
    const newUser = { ...this.state.newUser };
    let isTgiConflict = this.state.isTgiConflict;
    let isEmailConflict = this.state.isEmailConflict;
    let tgiValid = this.state.tgiValid;
    let emailValid = this.state.emailValid;

    switch (idElement) {
      case 'role':
        newUser.roleId = value;
        break;

      case 'tgi':
        tgiValid = UserUtils.validateTgi(value);
        isTgiConflict = false;
        newUser[idElement] = value;
        break;

      case 'email':
        emailValid = UserUtils.validateEmailAddress(value);
        isEmailConflict = false;
        newUser[idElement] = value;
        break;

      default:
        newUser[idElement] = value;
        break;
    }

    let addUserEnabled: boolean;

    addUserEnabled = newUser.firstName !== ''
      && newUser.lastName !== ''
      && newUser.email !== ''
      && (!!emailValid && emailValid)
      && !isEmailConflict
      && !isTgiConflict;

    if (newUser.tgi && newUser.tgi !== '') {
      addUserEnabled = addUserEnabled && (!!tgiValid && tgiValid);
    }

    this.setState({
      isEmailConflict,
      isTgiConflict,
      tgiValid,
      emailValid,
      newUser,
      addUserEnabled,
    });
  };

  /**
   * Format the options for the dropdowns
   * @param values : any[]
   * @returns JSON objects
   */
  public buildOptions = (values: any[]) => {
    const options: any = [];

    if (!values.find((myObj: any) => myObj.id === 1)) {
      options.push({ key: 1, text: 'None', value: 1 });
    }

    values.forEach((value: any) => {
      options.push({ text: value.name, value: value.id });
    });

    return options;
  };

  public static getErrorLabel(label: ErrorLabel): null | JSX.Element {
    let message: null | JSX.Element = null;

    switch (label) {
      case ErrorLabel.INVALID_EMAIL:
        message = <FormattedMessage id="userCreation.invalidEmail" defaultMessage="Invalid email" />;
        break;
      case ErrorLabel.INVALID_TGI:
        message = <FormattedMessage id="userCreation.invalidTgi" defaultMessage="Invalid TGI" />;
        break;
      case ErrorLabel.EMAIL_ALREADY_EXISTS:
        message = (
          <FormattedMessage id="userCreation.emailAlreadyExists" defaultMessage="This email address already exists" />
        );
        break;
      case ErrorLabel.TGI_ALREADY_EXISTS:
        message = <FormattedMessage id="userCreation.tgiAlreadyExists" defaultMessage="This TGI already exists" />;
        break;
    }

    return <Label className="error-label" pointing={true}>{message}</Label>;
  }

  public render() {
    return (
      <Modal
        id="user-creation-modal"
        open={true}
        onClose={this.close}
        className="creation-modal-container"
      >
        <Modal.Content id="modal-content">
          <Modal.Header>
            <div className="title">
              <FormattedMessage id="userCreation.newUser" defaultMessage="New user" />
            </div>
          </Modal.Header>
          <Form>
            <FormattedMessage id="userCreation.firstName" defaultMessage="First Name">
              {msg => (
                <Form.Field
                  id="firstName"
                  control={Input}
                  label={{ children: `${msg} *`, htmlFor: 'firstName' }}
                  placeholder={`${msg}`}
                  value={this.state.newUser.firstName}
                  onChange={(e: any, { value }: any) => this.handleChange('firstName', value)}
                />
              )}
            </FormattedMessage>

            <FormattedMessage id="userCreation.lastName" defaultMessage="Last Name">
              {msg => (
                <Form.Field
                  id="lastName"
                  control={Input}
                  label={{ children: `${msg} *`, htmlFor: 'lastName' }}
                  placeholder={msg}
                  value={this.state.newUser.lastName}
                  onChange={(e: any, { value }: any) => this.handleChange('lastName', value)}
                />
              )}
            </FormattedMessage>

            <FormattedMessage id="tgi" defaultMessage="TGI">
              {msg => (
                <div className="unique-field-container">
                  <Form.Field
                    id="tgi"
                    control={Input}
                    label={{ children: msg, htmlFor: 'tgi' }}
                    placeholder={msg}
                    value={this.state.newUser.tgi}
                    onChange={(e: any, { value }: any) => this.handleChange('tgi', value)}
                  />
                  {this.state.tgiValid === null || this.state.tgiValid
                    ? this.state.isTgiConflict && UserCreation.getErrorLabel(ErrorLabel.TGI_ALREADY_EXISTS)
                    : UserCreation.getErrorLabel(ErrorLabel.INVALID_TGI)
                  }
                </div>
              )}
            </FormattedMessage>

            <FormattedMessage id="email" defaultMessage="Mail">
              {msg => (
                <div className="unique-field-container">
                  <Form.Field
                    id="email"
                    control={Input}
                    label={{ children: `${msg} *`, htmlFor: 'email' }}
                    placeholder={msg}
                    value={this.state.newUser.email}
                    onChange={(e: any, { value }: any) => this.handleChange('email', value)}
                  />
                  {this.state.emailValid === null || this.state.emailValid
                    ? this.state.isEmailConflict && UserCreation.getErrorLabel(ErrorLabel.EMAIL_ALREADY_EXISTS)
                    : UserCreation.getErrorLabel(ErrorLabel.INVALID_EMAIL)
                  }
                </div>
              )}
            </FormattedMessage>

            {this.props.roles
              && <FormattedMessage id="rights" defaultMessage="Rights">
                {msg => (
                  <Form.Field
                    id="role"
                    control={Select}
                    options={this.buildOptions(this.props.roles)}
                    label={{ children: `${msg} *`, htmlFor: 'role' }}
                    placeholder={msg}
                    value={this.state.newUser.roleId}
                    onChange={(e: any, { value }: any) => this.handleChange('role', value)}
                  />
                )}
              </FormattedMessage>
            }
          </Form>
        </Modal.Content>
        <Modal.Actions>
        <Button id="clear" basic={true} onClick={this.resetUser}>
              <Icon name="refresh" />
              <FormattedMessage id="clear" defaultMessage="Clear" />
            </Button>
        <div>
        <Button onClick={this.close}>
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
          <Button
            id="add"
            icon={true}
            onClick={this.addUser}
            disabled={!this.state.addUserEnabled}
            loading={this.state.userCreating}
          >
            <FormattedMessage id="userCreation.createUser" defaultMessage="Create User" />&nbsp;
            <Icon name="checkmark" />
          </Button>
        </div>
        </Modal.Actions>
      </Modal>
    );
  }
}
