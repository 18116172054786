import ActivitiesActions from 'src/actions/activities.action';
import ActionTypes from 'src/constants/actionTypes';
import TypeActivity from 'src/constants/typeActivity';
import Dispatcher from 'src/dispatcher';
import PerformanceInitiative, {
  PerformanceIndicatorPost,
  PerformanceIndicatorPut,
} from 'src/models/performanceInitiative';
import Utils from 'src/utils/utils';

import PerformanceInitiativeAPI from '../api/performanceInitiative.api';
import HistoryActions from './history.action';
import AuthStore from '../stores/auth.store';
import { MetricValueGet } from '../models/metric';
import PerformanceInitiativeStore from '../stores/performanceInitiative.store';
import ILinkRequestRes from '../constants/activityLink';
import { AssignedUsers, UsersAssignation } from '../models/user';
import { AssignationType } from '../constants/account';

export default class PerformanceInitiativeActions {

  private static async performanceInitiativeGet(piId: number, targetPpId?: number, isAfterSave = false) {
    Dispatcher.dispatch({
      isAfterSave,
      performanceInitiative: await PerformanceInitiativeAPI.getPerformanceInitiativeById(piId, targetPpId),
      actionType: ActionTypes.PERFORMANCE_INITIATIVE_GET,
    });
  }

  /**
   * Get Performance initiative from API and emit actions PERFORMANCE_INITIATIVE_GET
   * @param piId: number
   * @param targetPpId: number
   * @return void
   */
  public static emitGetPerformanceInitiativeById(piId: number, targetPpId: number): Promise<any> {
    return this.performanceInitiativeGet(piId, targetPpId)
      .then(() => {
        Dispatcher.dispatch({
          actionType: ActionTypes.ERROR_REMOVE,
        });
        Dispatcher.dispatch({
          idActivity: piId,
          typeActivity: TypeActivity.PERFORMANCE_INITIATIVE,
          actionType: ActionTypes.ACTIVITY_SELECTED,
        });
      }).catch((error) => {
        Dispatcher.dispatch({
          error,
          actionType: ActionTypes.ERROR_SET,
        });
      });
  }

  /**
   * Grant publication for the given PI id and emit actions PERFORMANCE_INITIATIVE_GET
   * @param piId : number
   * @return void
   */
  public static emitGrantPublication(piId: number, name: string): Promise<any> {
    const pi = PerformanceInitiativeStore.getPerformanceInitiative();
    return PerformanceInitiativeAPI.postPublishPerformanceInitiativeById(piId, name)
      .then(() => HistoryActions.emitGetHistoryVersions('performance-initiatives', piId))
      .then(() => this.performanceInitiativeGet(piId, pi ? pi.targetPpId : undefined))
      .then(() => {
        Dispatcher.dispatch({
          actionType: ActionTypes.ACTIVITY_PUBLISHED,
        });
      });
  }

  /**
   * Delete Performance Initiative from API and emit actions PERFORMANCE_INITIATIVE_DELETED
   * @param piId : number
   * @return void
   */
  public static emitRemovePerformanceInitiative(piId: number): Promise<any> {
    return PerformanceInitiativeAPI.deletePerformanceInitiativeById(piId)
      .then(() => {
        Dispatcher.dispatch({
          actionType: ActionTypes.PERFORMANCE_INITIATIVE_DELETED,
        });
      });
  }

  /**
   * Create Performance Initiative from API and emit actions BUSINESS_CHALLENGE_GET
   * @param bcId : number
   * @param pi : PerformanceIndicatorPost
   * @return Promise<number>
   */
  public static emitCreatePerformanceInitiative(bcId: number, pi: PerformanceIndicatorPost): Promise<number> {
    return PerformanceInitiativeAPI.postPerformanceInitiative(bcId, pi)
      .then(async (newPI: PerformanceInitiative) => {
        const newPiId = newPI.id;
        const promises: Promise<any>[] = [];

        if (pi.metrics && pi.metrics.length !== 0) {
          promises.push(PerformanceInitiativeAPI.postPerformanceInitiativeMetrics(newPiId, pi.metrics));
        }
        if (pi.milestones && pi.milestones.length !== 0) {
          promises.push(PerformanceInitiativeAPI.createMilestones(newPiId, pi.milestones));
        }

        const assignedUsers = new AssignedUsers();
        if (pi.assignedAccounts && pi.assignedAccounts.length > 0) {
          const leadersEmails = await PerformanceInitiativeAPI.postPerformanceInitiativeAssignees(
            newPiId, new UsersAssignation(pi.assignedAccounts, AssignationType.PI_LEADER));
          if (leadersEmails) assignedUsers.addUsersIfNotExist(leadersEmails);
        }
        if (pi.informedAccounts && pi.informedAccounts.length > 0) {
          const informedEmails = await PerformanceInitiativeAPI.postPerformanceInitiativeAssignees(
            newPiId, new UsersAssignation(pi.informedAccounts, AssignationType.PI_INFORMED));
          if (informedEmails) assignedUsers.addUsersIfNotExist(informedEmails);
        }

        return Promise.all(promises).then(async () => {
          assignedUsers.dispatchAssignedUsers();
          await ActivitiesActions.emitGetActivities();
          return newPiId;
        });
      });
  }

  /**
   * Change Performance Initiative in Store
   * @return void
   */
  public static emitChangePerformanceInitiative(idAttribute: string, value: any, text?: any): void {
    Dispatcher.dispatch({
      idAttribute,
      value,
      text,
      actionType: ActionTypes.PERFORMANCE_INITIATIVE_CHANGE,
    });
  }

  /**
   * Rollback Performance Initiative in Store to previous data (after cancelling edition)
   * @return void
   */
  public static emitRollbackPerformanceInitiative(): void {
    Dispatcher.dispatch({
      actionType: ActionTypes.PERFORMANCE_INITIATIVE_ROLLBACK,
    });
  }

  /**
   * Close a pi and send action PERFORMANCE_INITIATIVE_SAVED
   * @param pi
   * @param comment
   */
  public static emitToggleClosePerformanceInitiative(pi: PerformanceInitiative, comment?: string): Promise<void> {
    const piId = pi.id;
    return PerformanceInitiativeAPI.toggleClosePi(piId, comment).then(async (pi) => {
      await ActivitiesActions.emitGetActivities();
      this.emitGetPerformanceInitiativeById(piId, pi.targetPpId)
        .catch(() => window.location.href = '/');
    });
  }

  /**
   * Save a pi and send action PERFORMANCE_INITIATIVE_SAVED
   * @return void
   * @param pi
   * @param oldPi
   */
  public static async emitSavePerformanceInitiative(pi: PerformanceInitiative, oldPi: PerformanceInitiative)
    : Promise<void> {
    const piId = oldPi.id;
    const promises: Promise<any>[] = [];

    // Compare PI with its previous version, to prevent an unnecessary update request
    const piToCompare = { ...pi };
    const oldPiToCompare = { ...oldPi };
    delete piToCompare.assignedAccounts;
    delete piToCompare.deputies;
    delete piToCompare.informedAccounts;
    delete oldPiToCompare.assignedAccounts;
    delete oldPiToCompare.deputies;
    delete oldPiToCompare.informedAccounts;

    if (JSON.stringify(piToCompare) !== JSON.stringify(oldPiToCompare)) {

      const newPi = pi as PerformanceIndicatorPut;
      if (pi.status) {
        newPi.status = pi.status;
      }
      if (pi.keywords) {
        newPi.keywordsId = pi.keywords.id;
      }
      if (typeof pi.code !== 'number') {
        newPi.code = parseInt(pi.code, 10);
      }

      promises.push(PerformanceInitiativeAPI.putPerformanceInitiative(piId, newPi));
    }

    //region METRICS
    const metricsFull = Utils.filterChanges(oldPi.metrics, pi.metrics);

    const oldMetricsWithoutValuesToEdit = oldPi.metrics
      .filter(oldMe => !metricsFull.remove.some(metric => oldMe.id === metric.id))
      .map((metric) => {
        const me = { ...metric };
        delete me.metricValues;
        return me;
      });

    const metricsWithoutValuesToEdit = pi.metrics
      .filter(me => me.id && !metricsFull.remove.some(metric => me.id === metric.id))
      .map((metric) => {
        const me = { ...metric };
        delete me.metricValues;
        return me;
      });

    const oldMetricValues : MetricValueGet[] = [];
    oldPi.metrics.forEach((me) => {
      if (!metricsFull.remove.some(metric => me.id === metric.id)) {
        oldMetricValues.push(...me.metricValues);
      }
    });
    const piMetricValues : MetricValueGet[] = [];
    pi.metrics.forEach((me) => {
      if (me.id && !metricsFull.remove.some(metric => me.id === metric.id)) {
        piMetricValues.push(...me.metricValues);
      }
    });

    const editedMetrics = Utils.filterChanges(oldMetricsWithoutValuesToEdit, metricsWithoutValuesToEdit).edit;
    const filteredMetricValues = Utils.filterChanges(oldMetricValues, piMetricValues);
    //endregion

    //region milestones comparison
    if (metricsFull.create.length !== 0) {
      promises.push(PerformanceInitiativeAPI.postPerformanceInitiativeMetrics(piId, metricsFull.create));
    }
    if (editedMetrics.length !== 0) {
      promises.push(PerformanceInitiativeAPI.putPerformanceInitiativeMetrics(piId, editedMetrics));
    }
    if (metricsFull.remove.length !== 0) {
      promises.push(
        PerformanceInitiativeAPI.deletePerformanceInitiativeMetrics(
          piId, metricsFull.remove.map(metric => metric.id),
        ),
      );
    }

    if (filteredMetricValues.create.length !== 0) {
      promises.push(PerformanceInitiativeAPI.postPerformanceInitiativeMetricValues(piId, filteredMetricValues.create));
    }
    if (filteredMetricValues.edit.length !== 0) {
      promises.push(PerformanceInitiativeAPI.putPerformanceInitiativeMetricValues(piId, filteredMetricValues.edit));
    }

    if (filteredMetricValues.remove.length !== 0) {
      promises.push(
          PerformanceInitiativeAPI.deletePerformanceInitiativeMetricValues(
              piId, filteredMetricValues.remove.map(metricValue => metricValue.id)));
    }
    //endregion

    //region MILESTONES
    const milestones = Utils.filterChanges(oldPi.milestones, pi.milestones);
    if (milestones.create.length !== 0) {
      promises.push(PerformanceInitiativeAPI.createMilestones(piId, milestones.create));
    }
    if (milestones.edit.length !== 0) {
      promises.push(PerformanceInitiativeAPI.putPerformanceInitiativeMilestones(piId, milestones.edit));
    }
    if (milestones.remove.length !== 0) {
      promises.push(PerformanceInitiativeAPI
        .deleteMilestones(piId, milestones.remove.map(dl => dl.id)));
    }
    //endregion

    //region LEADERS / DEPUTIES / INFORMED
    const invitedUsers = new AssignedUsers();
    const assignedAccounts = Utils.filterChanges(oldPi.assignedAccounts, pi.assignedAccounts);
    if (assignedAccounts.create.length !== 0) {
      const leadersEmails = await PerformanceInitiativeAPI.postPerformanceInitiativeAssignees(
        piId, new UsersAssignation(assignedAccounts.create, AssignationType.PI_LEADER));
      if (leadersEmails) invitedUsers.addUsersIfNotExist(leadersEmails);
    }
    if (assignedAccounts.remove.length !== 0) {
      promises.push(PerformanceInitiativeAPI.deletePerformanceInitiativeAssignees(
        piId, new UsersAssignation(assignedAccounts.remove, AssignationType.PI_LEADER)));
    }

    const deputies = Utils.filterChanges(oldPi.deputies, pi.deputies);
    if (deputies.create.length !== 0) {
      const deputiesEmails = await PerformanceInitiativeAPI.postPerformanceInitiativeAssignees(
        piId, new UsersAssignation(deputies.create, AssignationType.PI_DEPUTY));
      if (deputiesEmails) invitedUsers.addUsersIfNotExist(deputiesEmails);
    }
    if (deputies.remove.length !== 0) {
      promises.push(PerformanceInitiativeAPI.deletePerformanceInitiativeAssignees(
        piId, new UsersAssignation(deputies.remove, AssignationType.PI_DEPUTY)));
    }

    const informedAccounts = Utils.filterChanges(oldPi.informedAccounts, pi.informedAccounts);
    if (informedAccounts.create.length !== 0) {
      const informedEmails = await PerformanceInitiativeAPI.postPerformanceInitiativeAssignees(
        piId, new UsersAssignation(informedAccounts.create, AssignationType.PI_INFORMED));
      if (informedEmails) invitedUsers.addUsersIfNotExist(informedEmails);
    }
    if (informedAccounts.remove.length !== 0) {
      promises.push(PerformanceInitiativeAPI.deletePerformanceInitiativeAssignees(
        piId, new UsersAssignation(informedAccounts.remove, AssignationType.PI_INFORMED)));
    }
    //endregion

    return Promise.all(promises).then(async () => {
      Dispatcher.dispatch({ actionType: ActionTypes.PERFORMANCE_INITIATIVE_SAVED });
      invitedUsers.dispatchAssignedUsers();

      if (
        assignedAccounts.create.some(user => user.id === AuthStore.getConnectedUser().id)
        || assignedAccounts.remove.some(user => user.id === AuthStore.getConnectedUser().id)
        || deputies.create.some(user => user.id === AuthStore.getConnectedUser().id)
        || deputies.remove.some(user => user.id === AuthStore.getConnectedUser().id)
        || informedAccounts.create.some(user => user.id === AuthStore.getConnectedUser().id)
        || informedAccounts.remove.some(user => user.id === AuthStore.getConnectedUser().id)
      ) {
        Utils.updateCurrentUser();
      }

      await ActivitiesActions.emitGetActivities();
      this.emitGetPerformanceInitiativeById(piId, pi.targetPpId)
        .catch(() => window.location.href = '/');
    });
  }

  /**
   * Get keywords for performance initiative
   */
  public static emitGetKeywords() {
    PerformanceInitiativeAPI.getKeywords()
      .then((keywords) => {
        Dispatcher.dispatch({
          keywords,
          actionType: ActionTypes.PERFORMANCE_INITIATIVE_KEYWORDS_GET,
        });
      });
  }

  /**
   * Put request link for pi id
   * @param piId
   * @param bcId : number
   * @return void
   */
  public static emitRequestLink(piId: number, bcId: number): Promise<ILinkRequestRes> {
    return PerformanceInitiativeAPI.requestLinkPiToBc(piId, bcId);
  }

  /**
   * Emit error to store
   * @param error string
   */
  public static emitError(error: string): void {
    PerformanceInitiativeStore.setError(error);
  }

  /**
   * clear error value to store
   * @param error string
   */
  public static clearError(error: string): void {
    PerformanceInitiativeStore.clearError(error);
  }

  /**
   * Reset errors value store
   */
  public static resetErrors(): void {
    PerformanceInitiativeStore.resetError();
  }

  /**
   * Get the milestones of a PI
   * @param piId
   */
  public static getMilestones(piId: number) {
    PerformanceInitiativeAPI.getPerformanceInitiativeMilestones(piId, true).then((milestones) => {
      Dispatcher.dispatch({
        milestones,
        actionType: ActionTypes.ACTIONS_FAVOURITE_STATUS_CHANGED,
      });
    });
  }
}
