import React, { Component } from 'react';
import TimelineUtils from '../../../../../../../../utils/timelineUtils';
import { Months, Quarter, TimelineView } from '../../../../../../../../constants/timeline';
import moment from 'moment';
import './todayIndicator.scss';

interface IProps {
  year: number;
  month: Months;
  quarter: Quarter;
  selectedView: TimelineView;
}

interface IState {
  indicatorPosition: number;
  indicatorBarLength: number;
  showIndicator: boolean;
}

export default class TodayIndicator extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      indicatorPosition: 0,
      indicatorBarLength: 0,
      showIndicator: false,
    };
  }

  componentDidMount() {
    this.handleTodayIconDisplay();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    this.handleTodayIconDisplay();
  }

  private handleTodayIconDisplay = () => {
    this.setShowTodayIcon();
    const calculatedIndicPosition = this.getTodayPosition();
    const calculatedIndicBarLength = this.getTodayBarLength();
    const hasTodayPositionChanged = this.state.indicatorPosition !== calculatedIndicPosition;
    const hasTodayBarLengthChanged = this.state.indicatorBarLength !== calculatedIndicBarLength;
    if (hasTodayPositionChanged && hasTodayBarLengthChanged) {
      this.setState({ indicatorPosition: calculatedIndicPosition, indicatorBarLength: calculatedIndicBarLength });
    } else if (hasTodayPositionChanged) {
      this.setState({ indicatorPosition: calculatedIndicPosition });
    } else if (hasTodayBarLengthChanged) {
      this.setState({ indicatorBarLength: calculatedIndicBarLength });
    }
  };

  private isYearCurrent = () => {
    const currYear = new Date().getFullYear();
    return currYear === this.props.year;
  };

  private isMonthCurrent = () => {
    const currMonth = new Date().getMonth();
    return currMonth === this.props.month;
  };

  private isMonthCurrentQuarter = () => {
    const currMonth = new Date().getMonth();
    return TimelineUtils.isMonthOnQuarter(currMonth, this.props.quarter);
  };

  private setShowTodayIcon = () => {
    let shouldShow = false;

    switch (this.props.selectedView) {
      case TimelineView.YEAR:
        shouldShow = this.isYearCurrent();
        break;
      case TimelineView.QUARTER:
        shouldShow = this.isYearCurrent() && this.isMonthCurrentQuarter();
        break;
      case TimelineView.MONTH:
        shouldShow = this.isYearCurrent() && this.isMonthCurrent();
        break;

    }
    if (this.state.showIndicator !== shouldShow) {
      this.setState({ showIndicator: shouldShow });
    }
  };

  private getTodayPosition = () => {
    const tableElement = window.document.getElementById('timeline-table-header-less');
    const titleColumnElement = window.document.getElementsByClassName('activity-name-cell');
    let currDay = new Date().getDate();
    let nbDaysInPeriod = 0;

    switch (this.props.selectedView) {
      case TimelineView.YEAR:
        if (this.isYearCurrent()) {
          currDay = moment().dayOfYear();
          nbDaysInPeriod = TimelineUtils.getNbDaysInYear(this.props.year);
        }
        break;
      case TimelineView.QUARTER:
        if (this.isMonthCurrentQuarter()) {
          currDay = TimelineUtils.getDayOfQuarter(this.props.year);
          nbDaysInPeriod = TimelineUtils.getNbDaysForQuarter(this.props.quarter, this.props.year);
        }
        break;
    }
    if (nbDaysInPeriod !== 0 && currDay) {
      const dayPercentage = Number(((currDay * 100 / nbDaysInPeriod) / 100).toFixed(2));
      const titleColumnElementWidth = (titleColumnElement && titleColumnElement[0])
        ? titleColumnElement[0].clientWidth : 0;
      return tableElement && titleColumnElement
        ? (titleColumnElementWidth + 10) + (((tableElement.clientWidth + 8)
        - (titleColumnElementWidth + 10))) * dayPercentage
        : 0;
    }
    return 0;
  };

  private getTodayBarLength = () => {
    const tableElm = window.document.getElementsByClassName('scrollable-table');
    const headerElm = window.document.getElementsByTagName('thead');
    return tableElm && headerElm && tableElm[0] ? tableElm[0].clientHeight : 0;
  };

  render() {
    if (this.state.showIndicator) {
      return (
        <div id="today-indicator" style={{ left: `${this.state.indicatorPosition}px` }}>
          <div id="day">{new Date().getDate()}</div>
          <div id="bar" style={{ height: `${this.state.indicatorBarLength}px` }}/>
        </div>
      );
    }
    return null;
  }
}
