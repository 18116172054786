import HttpClient from '../utils/httpClient';

export default class UserGuideApi {

  /**
   * Update the user guide
   * @returns Promise<void>
   */
  public static updateUserGuide(formData: FormData): Promise<void> {
    return HttpClient.setUserGuide('user-guide/update', formData);
  }

  /**
   * Get the user guide
   * @returns Promise<string>
   */
  public static getUserGuide(): Promise<string> {
    return HttpClient.getFile('user-guide');
  }
}
