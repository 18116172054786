import React from 'react';
import { FormattedMessage } from 'react-intl';

import './timelineLegend.scss';

const timelineLegend = () => {
  return (
    <div id="pi-timeline-legend">
      <div id="at-full-timeline">
        <div className="at-timeline">
          <div className="element-bar-container">
            <div className="element-bar green is-start beginning">
              <FormattedMessage
                id="pi.timeline.confident"
                defaultMessage="Confident"
              />
            </div>
            <div className="element-bar orange is-start beginning">
              <FormattedMessage
                id="pi.timeline.atRisk"
                defaultMessage="At Risk"
              />
            </div>
            <div className="element-bar red is-start beginning">
              <FormattedMessage
                id="pi.timeline.critical"
                defaultMessage="Critical"
              />
            </div>
          </div>
          <FormattedMessage
            id="pi.timeline.atTimeline"
            defaultMessage="Action's timeline (from starting date to target date)"
          />
        </div>
        <div className="at-timeline">
          <div className="element-bar-container">
            <div className="element-bar blue" />
          </div>
          <FormattedMessage
            id="pi.timeline.atTimelineForecast"
            defaultMessage="Action's timeline (from target date to forecast date)"
          />
        </div>
      </div>
      <div className="element-icon-container-timeline">
        <div className="icon-legend">
          <div className="element-icon alt-color"/>
          <FormattedMessage
            id="pi.timeline.dateOfCompletion"
            defaultMessage="Action's Completion date"
          />
        </div>
        <div className="icon-legend">
          <div className="element-icon circle"/>
          <FormattedMessage
            id="pi.timeline.forecastDate"
            defaultMessage="Action's Forecast date"
          />
        </div>
      </div>
      <div className="element-icon-container-timeline">
        <div className="icon-legend">
          <div className="element-icon target-date"/>
          <FormattedMessage
            id="pi.timeline.targetMilestone"
            defaultMessage="Milestone's Target date"
          />
        </div>
        <div className="icon-legend">
          <div className="element-icon compl-date"/>
          <FormattedMessage
            id="pi.timeline.forecastMilestone"
            defaultMessage="Milestone's Completion Date"
          />
        </div>
      </div>
    </div>
  );
};
export default timelineLegend;
