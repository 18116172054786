import * as React from 'react';
import './profile.scss';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Icon, Input, Label } from 'semantic-ui-react';

import Utils from 'src/utils/utils';
import ActionTypes from 'src/constants/actionTypes';
import fr_flag from 'src/images/fr.png';
import gb_flag from 'src/images/gb.png';
import { ToastType } from 'src/components/common/toast/toast';
import ProfilePic from './components/profilePic/profilePic';
import UserInfos from './components/userInfos/userInfos';
import DefaultPpSelection from './components/defaultPpSelection/defaultPpSelection';
import NotificationActions from 'src/actions/notification-actions';
import UserActions from 'src/actions/user.action';
import AuthStore from 'src/stores/auth.store';
import UserStore from 'src/stores/user.store';
import PpYearsFilter from './components/ppYearsFilter/ppYearsFilter';

interface IProps extends RouteComponentProps {
}

interface IStates {
  user: any;
  buttonFrClass: string;
  buttonEnClass: string;
  newPasswordIncorrect: boolean;
  newPasswordLabelMessage: JSX.Element | string;
  newPasswordVerifyIncorrect: boolean;
  passwordButtonDisabled: boolean;
  password: string;
  newPassword: string;
  newPasswordVerify: string;
}

class Profile extends React.Component<IProps, IStates> {
  buttonClass = 'flag-button';

  constructor(props: IProps) {
    super(props);

    this.state = {
      user: AuthStore.getConnectedUser(),
      buttonFrClass: this.buttonClass + (Utils.getCurrentUserLanguageName() === 'FR' ? ' selected' : ''),
      buttonEnClass: this.buttonClass + (Utils.getCurrentUserLanguageName() === 'EN' ? ' selected' : ''),
      newPasswordIncorrect: false,
      newPasswordLabelMessage: '',
      newPasswordVerifyIncorrect: false,
      passwordButtonDisabled: true,
      password: '',
      newPassword: '',
      newPasswordVerify: '',
    };
  }

  public componentWillMount(): void {
    UserStore.addListener(
      ActionTypes.PROFILE_SAVE.toString(),
      () => this.setState({ user: AuthStore.getConnectedUser() }),
    );
  }

  public componentWillUnmount(): void {
    UserStore.removeListener(
      ActionTypes.PROFILE_SAVE.toString(),
      () => this.setState({ user: AuthStore.getConnectedUser() }),
    );
  }

  private static toastSuccessUser() {
    NotificationActions.toast(
      <FormattedMessage id="success" defaultMessage="Success" />,
      <FormattedMessage id="successProfileSave" defaultMessage="Your profile has been successfully saved" />,
      ToastType.SUCCESS,
    );
  }

  /**
   * Change language when a flag is clicked
   * @param {string} lang the language to switch to
   */
  public onClickFlag(lang: string) {
    if (lang !== this.state.user.language) {
      const userCopy = JSON.parse(JSON.stringify(this.state.user));
      userCopy.language = lang === 'EN' ? 1 : 2;
      this.setState({ user: userCopy });

      UserActions.emitSaveUserProfile(userCopy, this.state.user)
        .then(() => {
          Profile.toastSuccessUser();
        })
        .catch(() => {
          Utils.toastError();
        });
    }

    this.setState({
      buttonFrClass: this.buttonClass + (lang === 'FR' ? ' selected' : ''),
      buttonEnClass: this.buttonClass + (lang === 'EN' ? ' selected' : ''),
    });
  }

  public onPasswordInputChange = (event: any) => {
    this.setState(
      {
        password: event.target.value,
      },
      () => {
        this.updateButtonStatus();
      });
  };

  private refreshMatchingStatus() {
    const arePasswordsEqual = Profile.areInputsEqual(this.state.newPassword, this.state.newPasswordVerify);
    this.setState({ newPasswordVerifyIncorrect: !arePasswordsEqual }, () => { this.updateButtonStatus(); });
  }

  private static areInputsEqual(inputValue1: string, inputValue2: string) {
    return inputValue1 === inputValue2;
  }

  private static testRegExp(regExp: string, value: string): boolean {
    return new RegExp(regExp).test(value);
  }

  private updateButtonStatus() {
    const disabled = (this.state.password.length === 0
      || this.state.newPassword.length === 0
      || this.state.newPasswordVerify.length === 0
      || this.state.newPasswordIncorrect
      || this.state.newPasswordVerifyIncorrect);

    this.setState({ passwordButtonDisabled: disabled });
  }

  /**
   * Validate and register the new password
   * @param {any} event
   */
  public onNewPasswordInputChange = (event: any) => {
    this.setState(
      {
        newPasswordIncorrect: false,
        passwordButtonDisabled: true,
        newPassword: event.target.value,
      },
      () => {
        if (this.state.newPasswordVerify.length > 0) {
          this.refreshMatchingStatus();
        }
        const password = this.state.newPassword;
        const size = Profile.testRegExp('^.{8,20}$', password);
        const lowercase = Profile.testRegExp('([a-z]+)', password);
        const uppercase = Profile.testRegExp('([A-Z]+)', password);
        const number = Profile.testRegExp('([0-9]+)', password);
        const specialChar = Profile.testRegExp('([ !"#$%&\'()*+,\\-./:;<=>?@[\\\\\\]^_`{|}~]+)', password);

        if (!size) {
          if (password.length === 0) {
            this.setState({ newPasswordIncorrect: false });
          } else if (password.length < 8) {
            this.setNewPasswordLabel('tooShort');
          } else if (password.length > 20) {
            this.setNewPasswordLabel('tooLong');
          }
          return;
        }

        if (!lowercase) {
          this.setNewPasswordLabel('min1letter');
          return;
        }

        if (!uppercase) {
          this.setNewPasswordLabel('min1uppercaseLetter');
          return;
        }

        if (!number) {
          this.setNewPasswordLabel('min1digit');
          return;
        }

        if (!specialChar) {
          this.setNewPasswordLabel('min1specialChar');
          return;
        }

        this.updateButtonStatus();

      });
  };

  public onNewPasswordVerifyInputChange = (event: any) => {
    this.setState({ newPasswordVerify: event.target.value }, () => {
      this.refreshMatchingStatus();
    });
  };

  /**
   * Set the formatted error message depending on the message code
   * @param {string} msg message code
   */
  public setNewPasswordLabel(msg: string) {
    let jsxMessage: JSX.Element;

    switch (msg) {
      case 'min1uppercaseLetter':
        jsxMessage = (
          <FormattedMessage
            id="profile.password.min1uppercaseLetter"
            defaultMessage="There must be at least one uppercase letter"
          >
            {msg => (this.setLabelText(msg))}
          </FormattedMessage>);
        break;

      case 'min1digit':
        jsxMessage = (
          <FormattedMessage
            id="profile.password.min1digit"
            defaultMessage="There must be at least one digit"
          >
            {msg => (this.setLabelText(msg))}
          </FormattedMessage>);
        break;

      case 'min1letter':
        jsxMessage = (
          <FormattedMessage
            id="profile.password.min1letter"
            defaultMessage="There must be at least one lowercase letter"
          >
            {msg => (this.setLabelText(msg))}
          </FormattedMessage>);
        break;

      case 'min1specialChar':
        jsxMessage = (
          <FormattedMessage
            id="profile.password.min1specialChar"
            defaultMessage="There must be at least one special character"
          >
            {msg => (this.setLabelText(msg))}
          </FormattedMessage>);
        break;

      case 'tooShort':
        jsxMessage = (
          <FormattedMessage
            id="profile.tooShort"
            defaultMessage="Too short"
          >
            {msg => (this.setLabelText(msg))}
          </FormattedMessage>);
        break;

      case 'tooLong':
        jsxMessage = (
          <FormattedMessage
            id="profile.tooLong"
            defaultMessage="Too long"
          >
            {msg => (this.setLabelText(msg))}
          </FormattedMessage>);
        break;

      default:
        jsxMessage = <div />;
        break;
    }

    this.setState({
      newPasswordIncorrect: true,
      newPasswordLabelMessage: jsxMessage,
    });
  }

  /**
   * Set the error message label
   * @param {string | JSX.Element} msg error message
   * @return {JSX.Element} the label
   */
  public setLabelText(msg: string | JSX.Element): JSX.Element {
    return <Label className="password-label" pointing={true} color="red"> {msg} </Label>;
  }

  /**
   * Upadte the password on click on the 'update' button
   */
  public onPasswordChangeClick() {
    const userCopy = JSON.parse(JSON.stringify(this.state.user));
    userCopy.password = this.state.password;
    userCopy.newPassword = this.state.newPassword;

    UserActions.emitSaveUserProfile(userCopy, this.state.user)
      .then(() => {
        Profile.toastSuccessUser();
        this.setState({
          newPasswordIncorrect: false,
          newPasswordVerifyIncorrect: false,
          passwordButtonDisabled: true,
          password: '',
          newPassword: '',
          newPasswordVerify: '',
        });
      })
      .catch((err) => {
        if (err.reqStatus === 401) {
          NotificationActions.toast(
            <FormattedMessage id="error" defaultMessage="Error!" />,
            <FormattedMessage id="profile.wrongPassword" defaultMessage="Incorrect password, please try again" />,
            ToastType.ERROR,
          );
        } else if (err.reqStatus === 400) {
          NotificationActions.toast(
            <FormattedMessage id="error" defaultMessage="Error!" />,
            (
              <FormattedMessage
                id="profile.new.password.notSecure"
                defaultMessage="The new password is not secure enough. Please, change it"
              />
            ),
            ToastType.ERROR,
          );
        } else {
          Utils.toastError();
        }
      });

  }

  public render() {
    return (
      <div id="profile-main-container">
        <div id="top">
          <div id="back-to-taskboard" onClick={this.props.history.goBack}>
            <Icon name="arrow left" />
            <FormattedMessage
              id="back"
              defaultMessage="Back"
            />
          </div>
        </div>
        <div id="profile-container">
          <div id="profile">
            {this.state.user
              ? <div>
                <div id="user-infos-container">
                  <ProfilePic />
                  <UserInfos user={this.state.user} />
                </div>

                <div id="profile-settings-container">

                  <h3>
                    <FormattedMessage
                      id="settings"
                      defaultMessage="Settings subkeywords"
                    />
                  </h3>

                  <div className="keywords">
                    <div className="subkeywords">

                      <div id="password-container">
                        <div className="password-subcontainer">
                          <h5>
                            <FormattedMessage
                              id="profile.current.password"
                              defaultMessage="Current password"
                            />
                          </h5>
                          <Input
                            className="password-input"
                            onChange={this.onPasswordInputChange}
                            type="password"
                            value={this.state.password}
                          />
                        </div>

                        <div
                          className={[this.state.newPasswordIncorrect ? 'incorrect' : '',
                            'password-subcontainer'].join(' ')}
                        >
                          <h5>
                            <FormattedMessage
                              id="profile.new.password"
                              defaultMessage="New password"
                            />
                          </h5>
                          <Input
                            id="new-password-input"
                            className="password-input"
                            onChange={this.onNewPasswordInputChange}
                            type="password"
                            value={this.state.newPassword}
                          />
                          {this.state.newPasswordLabelMessage}

                        </div>

                        <div
                          className={[this.state.newPasswordVerifyIncorrect ? 'incorrect' : '',
                            'password-subcontainer'].join(' ')}
                        >
                          <h5>
                            <FormattedMessage
                              id="profile.verify.new.password"
                              defaultMessage="Verify the new password"
                            />
                          </h5>
                          <Input
                            id="new-password-verify-input"
                            className="password-input"
                            onChange={this.onNewPasswordVerifyInputChange}
                            type="password"
                            value={this.state.newPasswordVerify}
                          />
                          {this.state.newPasswordVerifyIncorrect ?
                            <Label className="password-label" pointing={true} color="red">
                              <FormattedMessage
                                id="profile.new.password.matching.error"
                                defaultMessage="This field does not match with the new password"
                              />
                            </Label>
                            : ''
                          }
                        </div>

                        <Button
                          color="green"
                          id={'button_change_password'}
                          disabled={this.state.passwordButtonDisabled}
                          onClick={() => this.onPasswordChangeClick()}
                        >
                          <FormattedMessage
                            id="profile.update"
                            defaultMessage="Update"
                          />
                        </Button>
                      </div>
                    </div>
                    <div className="lang_default_pp_years">
                      <div className="lang_default_pp">
                        <div className="subkeywords">
                          <h5>
                            <FormattedMessage
                              id="profile.changeLanguage"
                              defaultMessage="Language"
                            />
                          </h5>
                          <Button
                            className={this.state.buttonFrClass}
                            onClick={() => this.onClickFlag('FR')}
                          >
                            <img src={String(fr_flag)} alt="country-flag-fr"/>
                          </Button>

                          <Button
                            className={this.state.buttonEnClass}
                            onClick={() => this.onClickFlag('EN')}
                          >
                            <img src={String(gb_flag)} alt="country-flag-fr" />
                          </Button>
                        </div>
                        <DefaultPpSelection userId={this.state.user.id}/>
                      </div>
                      <PpYearsFilter userId={this.state.user.id}/>
                    </div>
                  </div>
                </div>
              </div>
              : Utils.loader('loader-user-infos')
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Profile);
