import './editingDraftBanner.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import TypeActivity from '../../../../../constants/typeActivity';
import Utils from '../../../../../utils/utils';

interface IProps {
  activityType: TypeActivity;
  triggerSizeCalculation(): void;
}

class EditingDraftBanner extends React.Component<IProps> {

  public constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.triggerSizeCalculation();
  }

  public render() {
    let msg: JSX.Element;
    if (Utils.isActivityPp(this.props.activityType)) {
      msg = (
          <FormattedMessage
              id="editingDraftBanner.pp"
              defaultMessage="You are currently viewing/editing a Plan in Draft Mode"
          />
      );
    } else {
      msg = (
          <FormattedMessage
              id="editingDraftBanner.activity"
              defaultMessage="You are currently viewing/editing an activity belonging to a Plan in Draft Mode"
          />
      );
    }
    return (
      <div id="draft-edition-banner">
          <div id={'message-validation'}>
              {msg}
          </div>
      </div>
    );
  }
}

export default EditingDraftBanner;
