import './metricValuesModal.scss';

import React, { createRef } from 'react';
import { Button, Icon, Modal, Table } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import Metric, { MetricValueGet, MetricValuePost } from '../../../../../../../../models/metric';
import ModeTypes from '../../../../../../../../constants/modeTypes';
import MetricValueEdit from './metricValueEdit';
import Utils from '../../../../../../../../utils/utils';
import CustomScrollBars from '../../../../../../../common/customScrollBars/customScrollBars';
import { ErrType } from '../../../../../../../../constants/errors/typeError';
import Scrollbars from 'react-custom-scrollbars';

interface IProps {
  showModal: boolean;
  metric: Metric;
  mode: ModeTypes;
  index: number;
  toggleShowModal: Function;
  metricValueIndex: number | null;
  currentMetricValueIndex: number;
  updateMetricValue(metricValue: MetricValueGet, mIndex: number | null, mvIndex: number | null);
  removeMetricValue(mvIndex: number, mIndex: number);
}

interface IStates {
  isValuesDatesValid: boolean;
}

export default class MetricValuesModal extends React.Component<IProps, IStates> {

  private scrollbarsRef = createRef<Scrollbars>();
  private isMetricValueAdded = false;

  constructor(props: IProps) {
    super(props);
    this.state = {
      isValuesDatesValid: true,
    };
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (!!this.scrollbarsRef.current && this.isMetricValueAdded) {
      this.scrollbarsRef.current.scrollToBottom();
      this.isMetricValueAdded = false;
    }
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.isMetricValueDateValid();
    }
  }

  addMetricValue = () => {
    const target = this.props.metric.mostRecentValues.target || null;
    const newMv: MetricValuePost = { target, current: null, updateDate: new Date(), hasImage: false };
    this.props.updateMetricValue((newMv as MetricValueGet), this.props.index, null);
    this.isMetricValueAdded = true;
  }

  //region METRIC VALUES VALIDATION
  isFormOnError = (): boolean => {
    return !this.areMetricValuesDateValid() || !this.areMetricValuesTargetValid() || !this.state.isValuesDatesValid;
  };

  // Check if the metric has each of its metric values' targets are correct (except for most recent target value)
  areMetricValuesTargetValid = () => {
    return this.props.metric.metricValues.every((mv, index) =>
      this.props.currentMetricValueIndex !== index ? !Utils.isValueNullOrEmpty(mv.target) : true,
    );
  }

  // Check if the metric has each of its metric values' update date are correct (except for most recent update date)
  areMetricValuesDateValid = () => {
    return this.props.metric.metricValues.every((mv, index) =>
      this.props.currentMetricValueIndex !== index ? !Utils.isValueNullOrEmpty(mv.updateDate) : true,
    );
  }

  // Check if the metric has at least one value
  isMetricNotEmpty = () => {
    return this.props.metric.metricValues.length !== 0;
  }

  private isMetricValueDateValid = () => {
    let valid = true;
    for (const metricValue of this.props.metric.metricValues) {
      if (!Utils.isDateFormatLanguageValid(metricValue.updateDate)) {
        valid = false;
      }
    }
    this.setState({
      isValuesDatesValid: valid,
    });
  }
  //endregion

  close = () => {
    this.props.toggleShowModal();
  }

  render() {
    return (
      <Modal
        className="metric-values-modal"
        open={this.props.showModal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={() => this.props.toggleShowModal()}
      >
        <Modal.Header>
          <FormattedMessage id="metrics.previousMetricValues" defaultMessage="Previous values of this metric" />
        </Modal.Header>
        <Modal.Content>
          {this.isMetricNotEmpty() && !(
            this.props.metric.metricValues.length === 1
            && this.props.currentMetricValueIndex === this.props.metricValueIndex
          )
            ? <CustomScrollBars customRef={this.scrollbarsRef}>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      <FormattedMessage id="metrics.updateDate" defaultMessage="Date of Current Value" />
                      <span className="error-container">
                      {!this.areMetricValuesDateValid() && Utils.getErrorMessageWithPopup(ErrType.MEV_DATE)}
                    </span>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <FormattedMessage id="current" defaultMessage="Current" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <FormattedMessage id="target" defaultMessage="Target" />
                      <span className="error-container">
                      {(!this.areMetricValuesTargetValid() || !this.isMetricNotEmpty())
                        && Utils.getErrorMessageWithPopup(ErrType.MEV_TARGET)}
                    </span>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.metric && this.props.metric.metricValues.map((metricValue, index) =>
                    (this.props.currentMetricValueIndex !== index) &&
                    <Table.Row key={`metricValue${metricValue.id ?? index}`}>
                      <MetricValueEdit
                        metricIsEmpty={this.props.metric.metricValues.length === 0}
                        metricIndex={this.props.index}
                        metricValueIndex={index}
                        mostRecentTarget={metricValue.target}
                        updateMetricValue={this.props.updateMetricValue}
                        metricValue={metricValue}
                        isImported={this.props.metric.isFromLinked}
                      />
                      <Table.Cell className="remove-button" width={1}>
                        {!this.props.metric.isFromLinked
                        && <Icon name="trash" onClick={() => this.props.removeMetricValue(index, this.props.index)} />}
                      </Table.Cell>
                    </Table.Row>,
                  )}
                </Table.Body>
              </Table>
            </CustomScrollBars>
            : Utils.empty(
              <FormattedMessage
                id="noMetricValuesForThisMetric"
                defaultMessage="This metric doesn't have any previous values yet"
              />,
              {}, undefined, true,
            )}
        </Modal.Content>
        <Modal.Actions className={this.props.metric.isFromLinked ? 'align-end' : ''}>
          {!this.props.metric.isFromLinked && <Button className="add-button" onClick={() => this.addMetricValue()}>
            <Button.Content>
              <Icon name="add"/>
              <FormattedMessage id="metrics.createNewMetricValue" defaultMessage="Add a new metric value" />
            </Button.Content>
          </Button>}
          <Button
            className="confirm"
            primary={true}
            onClick={this.close}
            disabled={this.isFormOnError()}
          >
            <FormattedMessage id="confirm" defaultMessage="Confirm" />
            <Icon name="checkmark" />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
