import ReportingAPI from '../api/reporting.api';
import BusinessChallengeReporting, { BusinessChallengeReportingPut } from '../models/businessChallengeReporting';
import Dispatcher from '../dispatcher';
import ActionTypes from '../constants/actionTypes';

export default class ReportingActions {

  /**
   * Update new report BC on Performance Plan
   * @return void
   */
  public static emitUpdateReferentBCReporting(
    reportId: number, bcId: number, bcReporting: BusinessChallengeReportingPut): Promise<BusinessChallengeReporting> {
    return ReportingAPI.putPerformancePlanBCReporting(reportId, bcId, bcReporting)
      .then((bcReporting) => {
        Dispatcher.dispatch({
          bcReporting,
          actionType: ActionTypes.PERFORMANCE_PLAN_NEW_BC_REPORTING,
        });

        return bcReporting;
      });
  }

  /**
   * Delete a reporting
   * @return void
   */
  public static emitDeleteReporting(reportingId: number): Promise<void> {
    return ReportingAPI.deleteReporting(reportingId);
  }
}
