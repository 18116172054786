import { EventEmitter } from 'events';
import Dispatcher from '../dispatcher';
import FluxAction from '../actions/action';
import ActionTypes from '../constants/actionTypes';
import ModeTypes from '../constants/modeTypes';
import Milestone from '../models/milestone';
import LinkedMilestone from 'src/models/linkedMilestone';

class MilestoneStore extends EventEmitter {

  private milestoneMode: ModeTypes;
  private milestoneToLinkId: number;
  private milestonePiId: number;
  private milestones: Milestone[];
  private milestoneLinkedAction: Map<number, LinkedMilestone[]>;

  constructor() {
    super();
    Dispatcher.register(this.registerToMilestone.bind(this));
  }

  /**
   * Return ModeType to know the milestone mode
   * @returns {ModeTypes}
   */
  public getMilestoneMode(): ModeTypes {
    return this.milestoneMode;
  }

  /**
   * Return ModeType to know the milestone mode
   * @returns {ModeTypes}
   */
  public getMilestoneToLinkId(): number {
    return this.milestoneToLinkId;
  }

  public getMilestones(): Milestone[] {
    return this.milestones;
  }

  /**
   * Return ModeType to know the milestone mode
   * @returns {ModeTypes}
   */
  public getMilestonePerformanceInitiativeId(): number {
    return this.milestonePiId;
  }

  private createMilestone() {
    this.emit(ActionTypes.MILESTONE_CREATION.toString());
  }

  private setMilestones(milestones: Milestone[]) {
    this.milestones = milestones;
    this.emit(ActionTypes.GET_MILESTONES.toString());
  }

  private changeMilestoneMode(mode: ModeTypes) {
    this.milestoneMode = mode;
    this.emit(ActionTypes.CHANGE_MILESTONE_MODE.toString());
  }

  private linkMilestoneToBc(miId: number, piId: number) {
    this.milestoneToLinkId = miId;
    this.milestonePiId = piId;
    this.emit(ActionTypes.LINK_MILESTONE_TO_BC.toString());
  }

  public getMilestoneLinkedAction(): Map<number, LinkedMilestone[]> {
    return this.milestoneLinkedAction;
  }

  private setMilestoneLinkedAction(actionLinkedMilestone: Map<number, LinkedMilestone[]>) {
    this.milestoneLinkedAction = actionLinkedMilestone;
    this.emit(ActionTypes.GET_LINK_MILESTONE_TO_ACTION.toString());
  }

  /*
  * update milestone take an action id and a milestone id
  * it changes the value of milestoneLinkedAction by adding the corresponding LinkedMilestone for the map entry
  * Then it emits so the listener can update their value
  */
  public updateMilestoneLinkedAction(atId: number, miId: number) {
    let milestones: LinkedMilestone[];
    if (this.milestoneLinkedAction === undefined) {
      this.milestoneLinkedAction = new Map<number, LinkedMilestone[]>();
    }
    milestones = this.milestoneLinkedAction.get(atId) !== undefined ? this.milestoneLinkedAction.get(atId)!! : [];
    const linkedMilestone = new LinkedMilestone();
    linkedMilestone.pimiId = miId;
    linkedMilestone.atId = atId;
    milestones.push(linkedMilestone);
    this.milestoneLinkedAction.set(atId, milestones);
    this.emit(ActionTypes.GET_LINK_MILESTONE_TO_ACTION.toString(), this.milestoneLinkedAction);
  }

  /*
  * delete milestone take an action id and a milestone id
  * it deletes the corresponding LinkedMilestone for the map entry
  * Then it emits so the listener can update their value
  */
  public deleteMilestoneLinkedAction(atId: number, miId: number) {
    let milestones: LinkedMilestone[];
    milestones = this.milestoneLinkedAction.get(atId)!!.filter(mi => mi.pimiId !== miId);
    this.milestoneLinkedAction.set(atId, milestones);
    this.emit(ActionTypes.GET_LINK_MILESTONE_TO_ACTION.toString(), this.milestoneLinkedAction);
  }

  /**
   * Performs a function according to a milestone
   * @param action :Milestone
   * @return void
   */
  private registerToMilestone(action: FluxAction) {
    switch (action.actionType) {
      case ActionTypes.MILESTONE_CREATION:
        this.createMilestone();
        break;
      case ActionTypes.CHANGE_MILESTONE_MODE:
        this.changeMilestoneMode(action.mode);
        break;
      case ActionTypes.LINK_MILESTONE_TO_BC:
        this.linkMilestoneToBc(action.milestoneId, action.piId);
        break;
      case ActionTypes.GET_LINK_MILESTONE_TO_ACTION:
        this.setMilestoneLinkedAction(action.actionLinkedMilestone);
        break;
      case ActionTypes.DELETE_LINK_MILESTONE_TO_ACTION:
        this.deleteMilestoneLinkedAction(action.atId, action.miId);
        break;
      case ActionTypes.SET_LINK_MILESTONE_TO_ACTION:
        this.updateMilestoneLinkedAction(action.atId, action.miId);
        break;
      case ActionTypes.GET_MILESTONES:
        this.setMilestones(action.milestones);
    }
  }
}

export default new MilestoneStore();
