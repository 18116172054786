import './searchResult.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Divider, Form, Segment } from 'semantic-ui-react';

import ActivitiesActions from 'src/actions/activities.action';
import TypeActivity from 'src/constants/typeActivity';
import ActivitiesStore from 'src/stores/activities.store';
import SearchApi, { IFilters, ISearchResult } from '../../api/search.api';
import Utils from '../../utils/utils';
import PublicationLabel from '../common/publicationLabel/publicationLabel';
import DraftLabel from '../common/draftLabel/draftLabel';
import CustomScrollBars from '../common/customScrollBars/customScrollBars';
import { PpTabs } from '../activitiesBoard/containerActivities/contentPP/contentPP';
import { BcTabs } from '../activitiesBoard/containerActivities/contentBC/contentBC';
import { PiTabs } from '../activitiesBoard/containerActivities/contentPI/contentPI';
import NotificationActions from 'src/actions/notification-actions';
import { ToastType } from '../common/toast/toast';

interface IState {
  searchResult: ISearchResult;
  filters: IFilters;
  selectedFilters: {
    year: string | undefined,
    bcKeyword: number | undefined,
    bcFunction: number | undefined,
    piKeyword: number | undefined,
    gbu: number | undefined,
    country: number | undefined,
  };
  isLoading: boolean;
}

class SearchResult extends React.Component<RouteComponentProps, IState> {

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      searchResult: {
        performancePlans: [],
        businessChallenges: [],
        performanceInitiatives: [],
      },
      isLoading: true,
      filters: {
        years: [],
        bcKeywords: [],
        bcFunctions: [],
        piKeywords: [],
        gbus: [],
        geographies: [],
        countries: [],
        subCountries: [],
      },
      selectedFilters: {
        year: undefined,
        bcKeyword: undefined,
        bcFunction: undefined,
        piKeyword: undefined,
        gbu: undefined,
        country: undefined,
      },
    };
  }

  public componentDidMount(): void {
    this.submitSearch();
    this.fetchFilters();
  }

  public componentDidUpdate(prevProps: RouteComponentProps) {
    if (this.props.location !== prevProps.location) {
      this.submitSearch();
    }
  }

  /**
   * Fetch filters from the api
   */
  public fetchFilters = () => {
    SearchApi.getFilters().then(filters => this.setState({ filters }));
  };

  /**
   * Set a filter
   * @param key
   * @param value
   */
  public setFilter(key: string, value: any) {
    const selectedFilters = { ...this.state.selectedFilters };
    selectedFilters[key] = value;
    this.setState({ selectedFilters });
  }

  /**
   * Return the results after filters have been applied
   */
  public getFilteredSearchResult = (): ISearchResult => {
    const searchResult = this.state.searchResult;
    const filters = this.state.selectedFilters;
    const filteredPPs = searchResult.performancePlans
      .filter(pp =>
        (!filters.year || filters.year === pp.year)
        && (!filters.gbu || filters.gbu === pp.gbu.id)
        && (!filters.bcKeyword || pp.businessChallenges.some(bc => bc.keywordsId === filters.bcKeyword)));

    const filteredBCs = searchResult.businessChallenges
      .filter(bc =>
        (!filters.bcKeyword || filters.bcKeyword === bc.keywordsId)
        && (!filters.bcFunction || filters.bcFunction === bc.functionsId)
        && (!filters.year || filters.year === bc.performancePlan.year)
        && (!filters.gbu || filters.gbu === bc.performancePlan.gbu.id));

    const filteredPIs = searchResult.performanceInitiatives
      .filter(pi => (!filters.piKeyword || filters.piKeyword === pi.keywordsId)
        && (!filters.bcKeyword || filters.bcKeyword === pi.businessChallenge.keywordsId)
        && (!filters.bcFunction || filters.bcFunction === pi.businessChallenge.functionsId)
        && (!filters.year || filters.year === pi.businessChallenge.performancePlan.year)
        && (!filters.gbu || filters.gbu === pi.businessChallenge.performancePlan.gbu.id));

    return ({
      performancePlans: filteredPPs,
      businessChallenges: filteredBCs,
      performanceInitiatives: filteredPIs,
    });
  };

  /**
   * Submit the search query to the api
   */
  public submitSearch = () => {
    this.setState({ isLoading: true });
    let query = new URLSearchParams(this.props.location.search).get('q');
    if (!query) {
      query = '';
    }
    // the number 3 was the previous limit for the query hence the toast
    if (query.length < 3) {
      const messageLongSearch : string = 'The search might take some time as your query is under 3 characters.';
      NotificationActions.toast(
        <FormattedMessage id="searchInfo" defaultMessage="Information" />,
        <FormattedMessage id="searchWarning" defaultMessage={messageLongSearch}/>,
        ToastType.INFO,
      );
    }
    const messageTimer = 'The search has taken too much time. Please try another time.';
    // it consider that the request is lost after one minute
    let hasFound : boolean = true;
    const timer = setTimeout(() => {
      NotificationActions.toast(
        <FormattedMessage id="searchErr" defaultMessage="Error" />,
        <FormattedMessage id="searchError" defaultMessage={messageTimer}/>,
        ToastType.ERROR,
      );
      this.setState({ isLoading: false });
      hasFound = false;
    },
                             60000);

    SearchApi.search(query).then((searchResult) => {
      if (hasFound) {
        this.setState({ searchResult });
        // when it succeeds the timer is discarded
        clearTimeout(timer);
      }
      this.setState({ isLoading: false });
    });
    ActivitiesActions.emitGetActivities();
  };

  /**
   * Navigate the the pp view
   * @param id
   */
  public onPPClick = (id: number) => {
    if (ActivitiesStore.getActivities() === undefined) {
      ActivitiesActions.emitGetActivities();
    }
    if (Utils.isExternalView(id, TypeActivity.PERFORMANCE_PLAN)) {
      this.props.history.push(`pp-external/${id}`);
    } else {
      this.props.history.push(`/activities-board/performance-plan/${id}/${PpTabs.COCKPIT}`);
    }
  };

  /**
   * Navigate the the bc view
   * @param id
   * @param ppId
   */
  public onBCClick = (id: number, ppId: number) => {
    if (ActivitiesStore.getActivities() === undefined) {
      ActivitiesActions.emitGetActivities();
    }
    if (Utils.isExternalView(id, TypeActivity.BUSINESS_CHALLENGE)) {
      this.props.history.push(`bc-external/${id}`);
    } else {
      this.props.history.push(`/activities-board/${ppId}/business-challenge/${id}/${BcTabs.COCKPIT}`);
    }
  };

  /**
   * Navigate the the pi view
   * @param id
   * @param ppId
   */
  public onPIClick = (id: number, ppId: number) => {
    if (Utils.isExternalView(id, TypeActivity.PERFORMANCE_INITIATIVE)) {
      this.props.history.push(`pi-external/${id}`);
    } else {
      this.props.history.push(`/activities-board/${ppId}/performance-initiative/${id}/${PiTabs.COCKPIT}`);
    }
  };

  public render(): React.ReactNode {
    const searchResult = this.getFilteredSearchResult();
    return (
      <div id={'search-result'}>
        <div id={'filters-container'}>
          <h1><FormattedMessage id={'searchFilters'} defaultMessage={'SEARCH FILTERS'} /></h1>
          <h2><FormattedMessage id={'restrictResultsBy'} defaultMessage={'Restrict results by'} /> ...</h2>
          <Form.Group id={'filters'} widths={'equal'}>
            <FormattedMessage id={'select'} defaultMessage={'Select'}>{(select: string | any) =>
              <FormattedMessage id={'noFilter'} defaultMessage={'No filter'}>{(noFilter: string | any) =>
                <div>
                  <FormattedMessage id="year" defaultMessage="Year">{msg =>
                    <Form.Select
                      id="year"
                      fluid={true}
                      label={msg}
                      placeholder={select}
                      options={[
                        { key: 'nullYear', text: `<${noFilter}>`, value: undefined },
                        ...this.state.filters.years.map(year => ({ key: year, text: year, value: year })),
                      ]}
                      value={this.state.selectedFilters.year}
                      onChange={(e, { id, value }) => this.setFilter(id, value)}
                    />}
                  </FormattedMessage>
                  < FormattedMessage id="bcKeyword" defaultMessage="BC Keyword">{msg =>
                    <Form.Select
                      id="bcKeyword"
                      fluid={true}
                      placeholder={select}
                      label={msg}
                      options={[
                        { key: 'nullBCKeyword', text: `<${noFilter}>`, value: undefined },
                        ...this.state.filters.bcKeywords.map(bck => ({ key: bck.id, text: bck.name, value: bck.id })),
                      ]}
                      value={this.state.selectedFilters.bcKeyword ? this.state.selectedFilters.bcKeyword : undefined}
                      onChange={(e, { id, value }) => this.setFilter(id, value)}
                    />}
                  </FormattedMessage>
                  < FormattedMessage id="bcFunction" defaultMessage="Function">{msg =>
                    <Form.Select
                      id="bcFunction"
                      fluid={true}
                      placeholder={select}
                      label={msg}
                      options={[
                        { key: 'nullBCFunction', text: `<${noFilter}>`, value: undefined },
                        ...this.state.filters.bcFunctions.map(bcf => ({ key: bcf.id, text: bcf.name, value: bcf.id })),
                      ]}
                      value={this.state.selectedFilters.bcFunction ? this.state.selectedFilters.bcFunction : undefined}
                      onChange={(e, { id, value }) => this.setFilter(id, value)}
                    />}
                  </FormattedMessage>
                  <FormattedMessage id="piKeyword" defaultMessage="PI Keyword">{msg =>
                    <Form.Select
                      id="piKeyword"
                      fluid={true}
                      placeholder={select}
                      label={msg}
                      options={[
                        { key: 'nullPIKeyword', text: `<${noFilter}>`, value: undefined },
                        ...this.state.filters.piKeywords.map(pik => ({ key: pik.id, text: pik.name, value: pik.id })),
                      ]}
                      value={this.state.selectedFilters.piKeyword ? this.state.selectedFilters.piKeyword : undefined}
                      onChange={(e, { id, value }) => this.setFilter(id, value)}
                    />}
                  </FormattedMessage>
                  <FormattedMessage id="GBU" defaultMessage="GBU">{msg =>
                    <Form.Select
                      id="gbu"
                      fluid={true}
                      placeholder={select}
                      label={msg}
                      options={[
                        { key: 'nullGBU', text: `<${noFilter}>`, value: undefined },
                        ...Utils.sortWithNoneValue(this.state.filters.gbus),
                      ]}
                      value={this.state.selectedFilters.gbu ? this.state.selectedFilters.gbu : undefined}
                      onChange={(e, { id, value }) => this.setFilter(id, value)}
                    />}
                  </FormattedMessage>
                </div>
              }</FormattedMessage>
            }</FormattedMessage>
          </Form.Group>
        </div>
        {this.state.isLoading ? Utils.loader() :
          <div id="result-container">
            <Segment id="performance-plans">
              <FormattedMessage id="plans" defaultMessage="Plans">
                {msg =>
                  <h4>{`${msg} (${searchResult.performancePlans.length})`}</h4>
                }
              </FormattedMessage>
              <Divider />
              <CustomScrollBars className="container-scroll">
                {searchResult.performancePlans.map(pp =>
                  <div className="tile" key={pp.id}>
                    <h2 onClick={() => this.onPPClick(pp.id)}>
                      {pp.name}
                      <PublicationLabel publishedDate={pp.publishedDate} />
                      <DraftLabel isDraft={pp.isDraft} />
                    </h2>
                    <h4>{`${pp.owner.firstName} ${pp.owner.lastName}`}</h4>
                    <h4>
                      {`${pp.country.name},
                       GBU ${pp.gbu.name},
                        ${pp.year}`}
                    </h4>
                  </div>,
                )}
              </CustomScrollBars>
            </Segment>

            <Segment id="business-challenges">
              <FormattedMessage id="businessChallenges" defaultMessage="Business Challenges">
                {msg =>
                  <h4>{`${msg} (${searchResult.businessChallenges.length})`}</h4>
                }
              </FormattedMessage>
              <Divider />
              <CustomScrollBars className="container-scroll">
                {searchResult.businessChallenges.map(bc =>
                  <div className="tile" key={bc.id}>
                    <h2
                      onClick={() => bc.performancePlan ? this.onBCClick(bc.id, bc.performancePlan.id) : null}
                    >
                      {`BC${Utils.leadingZero(bc.code)}: ${bc.name}`}
                      <PublicationLabel publishedDate={bc.publishedDate} />
                    </h2>
                    {bc.assignedAccounts.map(acc =>
                      <h4 key={acc.id}>{`${acc.firstName} ${acc.lastName}`}</h4>,
                    )}
                    <h4>
                      {bc.performancePlan ? `${bc.performancePlan.country.name},
                       GBU ${bc.performancePlan.gbu.name},
                        ${bc.performancePlan.year}` : null}
                    </h4>
                  </div>,
                )}
              </CustomScrollBars>
            </Segment>

            <Segment id="performance-initiatives">
              <FormattedMessage id="performanceInitiatives" defaultMessage="Performance Initiatives">
                {msg =>
                  <h4>{`${msg} (${searchResult.performanceInitiatives.length})`}</h4>
                }
              </FormattedMessage>
              <Divider />
              <CustomScrollBars className="container-scroll">
                {searchResult.performanceInitiatives.map(pi =>
                  <div className="tile" key={pi.id}>
                    <h2
                      onClick={() => pi.businessChallenge.performancePlan ?
                        this.onPIClick(pi.id, pi.businessChallenge.performancePlan.id) : null}
                    >
                      {`PI${Utils.leadingZero(pi.code)}: ${pi.name}`}
                      <PublicationLabel publishedDate={pi.publishedDate} />
                    </h2>
                    {pi.assignedAccounts.map(acc =>
                      <h4 key={acc.id}>{`${acc.firstName} ${acc.lastName}`}</h4>,
                    )}
                    <h4>
                      {pi.businessChallenge.performancePlan ? `${pi.businessChallenge.performancePlan.country.name}
                , GBU ${pi.businessChallenge.performancePlan.gbu.name}, ${pi.businessChallenge.performancePlan.year}`
                        : null}
                    </h4>
                  </div>,
                )}
              </CustomScrollBars>
            </Segment>
          </div>
        }
      </div>
    );
  }
}

export default withRouter(SearchResult);
