import HttpClient from 'src/utils/httpClient';

export default class ExploreAPI {

  /**
   * ROUTE : /api/dataviz/activities
   * Get all activities
   * @returns Promise<any>
   */
  public static getActivities(year: string): Promise<any> {
    return HttpClient.get<any>(`dataviz/activities${year !== 'All' ? `?year=${year}` : ''}`);
  }
}
