import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import NotificationActions from '../../actions/notification-actions';
import { ToastType } from '../../components/common/toast/toast';
import { CommonErrors } from './common.error';

export enum PiErrors {
  PI_NOT_FOUND = 'Performance Initiative not found',
  PI_ALREADY_AWAITING_FOR_VALIDATION = 'This Performance Initiative is already awaiting for validation',
  PI_ALREADY_WAITING_LINK_VALIDATION = 'This Performance Initiative link has already been requested',
  PI_CANNOT_LINK_TO_SAME_PP = 'Could not link a Performance Initiative into a Plan it is already linked in',
  PI_CANNOT_LINK_TO_OWN_PP = 'Could not link a Performance Initiative into its original Plan',
  PI_CANNOT_LINK_TO_DRAFT = 'Could not link a Performance Initiative into a draft Plan',
  PI_CANNOT_LINK_WHEN_DRAFT = 'Could not link a Performance Initiative when its Plan is a draft',
  PI_CANNOT_LINK_DIFFERENT_YEAR = 'Could not link a Performance Initiative when its Plan\'s year is different from the target Plan\'s year',
  PI_NEVER_BEEN_PUBLISHED = 'Performance Initiative has never been published',
  PI_WRONG_HISTORY = 'This history does not belong to this Performance Initiative',
  PUBLICATION_CONDITIONS_NOT_MET = 'There is no need to publish, because it was already published today and has not been edited since',
}

export default class PerformanceInitiativeError {

  public static toastPiError(error: string) {
    let message: JSX.Element;
    let toastType = ToastType.ERROR;
    let title = <FormattedMessage id="errorOccurred" defaultMessage="Error"/>;

    switch (error) {
      case PiErrors.PI_ALREADY_AWAITING_FOR_VALIDATION:
        message = (
          <FormattedMessage
            id="link.piAlreadyWaitingValidation"
            defaultMessage="This Performance Initiative is already awaiting for validation"
          />
        );
        break;
      case PiErrors.PI_CANNOT_LINK_TO_SAME_PP:
        message = (
          <FormattedMessage
            id="link.piLinkOnSamePpError"
            defaultMessage="Could not link a Performance Initiative into a Plan it is already linked in"
          />
        );
        break;
      case PiErrors.PI_CANNOT_LINK_TO_OWN_PP:
        message = (
          <FormattedMessage
            id="link.piOriginalPlanError"
            defaultMessage="Could not link a Performance Initiative into its original Plan"
          />
        );
        break;
      case PiErrors.PI_CANNOT_LINK_TO_DRAFT:
        message = (
          <FormattedMessage
            id="link.piInDraftPlanError"
            defaultMessage="Could not link a Performance Initiative into a draft Plan"
          />
        );
        break;
      case PiErrors.PI_CANNOT_LINK_WHEN_DRAFT:
        message = (
          <FormattedMessage
            id="link.piInDraftPlanError"
            defaultMessage="Could not link a Performance Initiative into a draft Plan"
          />
        );
        break;
      case PiErrors.PI_ALREADY_WAITING_LINK_VALIDATION:
        message = (
          <FormattedMessage
            id="link.piAlreadyWaitingValidation"
            defaultMessage="This Performance Initiative link has already been requested"
          />
        );
        break;
      case PiErrors.PI_CANNOT_LINK_DIFFERENT_YEAR:
        message = (
          <FormattedMessage
            id="link.piDifferentPerformancePlanYear"
            defaultMessage="Could not link a Performance Initiative when its Plan's year is different from the target Plan's year"
          />
        );
        break;
      case CommonErrors.PUBLICATION_CONDITIONS_NOT_MET:
        title = <FormattedMessage id="informationPublicationPlanError" defaultMessage="Info"/>;
        message = (
          <FormattedMessage
            id="notPublishedMessage"
            defaultMessage="The activity could not be published because it was already published today and has not been modified since (neither it nor the activities it contains)"
          />
        );
        toastType = ToastType.INFO;
        break;
      default:
        message = (
          <FormattedMessage
            id="errorOccurredMessage"
            defaultMessage="An error occurred, please try again later"
          />
        );
        break;
    }

    NotificationActions.toast(title, message, toastType);
  }
}
