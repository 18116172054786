import './activityLink.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';

class ActivityLinkStep1 extends React.Component<{}> {

  public render() {
    return (
      <div id="activity-link-step1" className="step-container">
        <div>
          <FormattedMessage
            id="link.introductionStep.message1"
            defaultMessage="This feature allows co-edition of a common PI or BC between Performance Plans. The PI or BC is shared equally between the two plans (from the origin Plan and the target plan to which it is shared), and under the same editing rules that apply to a plan's BC/PI structure. Both sides are able to edit it and see modifications."
          />
        </div>

        <div>
          <FormattedMessage
            id="link.introductionStep.message2"
            defaultMessage={'To create this linked PI or BC, the leader should "push" the item from their Plan to the target Plan - this can be done by the PI leader, the BC leader or the Referent. In the target Plan a notification will be sent to the corresponding leader to accept or reject this linked item.'}
          />
        </div>
      </div>
    );
  }
}

export default ActivityLinkStep1;
