import ActionTypes from 'src/constants/actionTypes';
import Dispatcher from 'src/dispatcher';
import StatsAPI from '../api/stats.api';

export default class StatsActions {

  /**
   * Get the counts of the users (by role)
   */
  public static emitGetUsersCounts() {
    StatsAPI.getUsersCount().then((usersCounts) => {
      Dispatcher.dispatch({
        usersCounts,
        actionType: ActionTypes.STATS_USERS_COUNTS,
      });
    });
  }

  /**
   * Get the counts of the activities
   */
  public static emitGetActivitiesCounts() {
    StatsAPI.getActivitiesCount().then((activitiesCounts) => {
      Dispatcher.dispatch({
        activitiesCounts,
        actionType: ActionTypes.STATS_ACTIVITIES_COUNTS,
      });
    });
  }

  /**
   * Get the counts of the activities
   */
  public static emitGetConnectionsCount() {
    StatsAPI.getConnectionsCount().then((connectionsCount) => {
      Dispatcher.dispatch({
        connectionsCount,
        actionType: ActionTypes.STATS_CONNECTIONS_COUNT,
      });
    });
  }
}
