import { ComparisonType } from 'src/constants/comparisonType';

export const DEFAULT_METRIC = {
  name: '',
  unit: '',
  isHidden: false,
  code: 0,
  comparisonType: ComparisonType.NONE,
  comparisonTolerance: 0,
  metricValues: [],
  mostRecentValues: {
    current: null,
    target: null,
    hasImage: false,
    updateDate: new Date(),
  },
  isNew: true,
};
