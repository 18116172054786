import { EventEmitter } from 'events';
import ActionTypes from 'src/constants/actionTypes';
import FluxAction from 'src/actions/action';
import Dispatcher from '../dispatcher';
import UsersCounts from '../models/usersCounts';
import ActivitiesCounts from '../models/activitiesCounts';
import ConnectionsCount from '../models/connectionsCount';

class StatsStore extends EventEmitter {

  private usersCounts: UsersCounts;
  private activitiesCounts: ActivitiesCounts;
  private connectionsCount: ConnectionsCount[];

  constructor() {
    super();
    Dispatcher.register(this.registerToActions.bind(this));
  }

  /**
   * Return the counts of users
   */
  public getUsersCounts(): UsersCounts {
    return this.usersCounts;
  }

  /**
   * Set userCounts
   * @param {UsersCounts} usersCounts
   */
  private setUsersCounts(usersCounts: UsersCounts) {
    this.usersCounts = usersCounts;
    this.emit(ActionTypes.STATS_USERS_COUNTS.toString(), usersCounts);
  }

  /**
   * Return the counts of activities
   */
  public getActivitiesCounts(): ActivitiesCounts {
    return this.activitiesCounts;
  }

  /**
   * Set activitiesCounts
   * @param {ActivitiesCounts} activitiesCounts
   */
  private setActivitiesCounts(activitiesCounts: ActivitiesCounts) {
    this.activitiesCounts = activitiesCounts;
    this.emit(ActionTypes.STATS_ACTIVITIES_COUNTS.toString(), activitiesCounts);
  }

  /**
   * Return the count of connections (with date)
   */
  public getConnectionsCount(): ConnectionsCount[] {
    return this.connectionsCount;
  }

  /**
   * Set connectionsCount
   * @param {ConnectionsCount[]} connectionsCount
   */
  private setConnectionsCount(connectionsCount: ConnectionsCount[]) {
    this.connectionsCount = connectionsCount;
    this.emit(ActionTypes.STATS_CONNECTIONS_COUNT.toString(), connectionsCount);
  }

  /**
   * Performs a function according to an action
   * @param action :Action
   * @return void
   */
  private registerToActions(action: FluxAction) {
    switch (action.actionType) {
      case ActionTypes.STATS_USERS_COUNTS:
        this.setUsersCounts(action.usersCounts);
        this.getUsersCounts();
        break;
      case ActionTypes.STATS_ACTIVITIES_COUNTS:
        this.setActivitiesCounts(action.activitiesCounts);
        this.getActivitiesCounts();
        break;
      case ActionTypes.STATS_CONNECTIONS_COUNT:
        this.setConnectionsCount(action.connectionsCount);
        this.getConnectionsCount();
        break;
    }
  }
}

export default new StatsStore();
