import HttpClient from '../utils/httpClient';
import PerformancePlan from '../models/performancePlan';
import BusinessChallenge from '../models/businessChallenge';
import PerformanceInitiative from '../models/performanceInitiative';
import Keywords from '../models/keywords';
import Gbu from '../models/gbu';
import Country from '../models/country';
import Geography from '../models/geography';
import Subcountry from '../models/subcountry';

export interface ISearchResult {
  performancePlans: PerformancePlan[];
  businessChallenges: BusinessChallenge[];
  performanceInitiatives: PerformanceInitiative[];
}

export interface IFilters {
  years: string[];
  bcKeywords: Keywords[];
  bcFunctions: Keywords[]; // function has the same struct as keyword HERE
  piKeywords: Keywords[];
  gbus: Gbu[];
  geographies: Geography[];
  countries: Country[];
  subCountries: Subcountry[];
}

export default class SearchApi {

  /**
   * ROUTE : /api/search?q=string
   * Search any object by name
   * @returns Promise<any[]>
   */
  public static search(query: string): Promise<ISearchResult> {
    return HttpClient.get<ISearchResult>(`search?q=${query}`);
  }

  /**
   * ROUTE : /api/search/filters
   * Get all filters available in the search
   * @returns Promise<any[]>
   */
  public static getFilters(): Promise<IFilters> {
    return HttpClient.get<IFilters>('search/filters');
  }
}
