import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from 'semantic-ui-react';

interface IProps {
  handleCloseReportModalClose: any;
  handleCloseReportModalAgree: any;
  closeReportingModalOpen: boolean;
}

class CloseReportModal extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
  }
  public render() {
    return (
      <Modal
        open={this.props.closeReportingModalOpen}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={this.props.handleCloseReportModalClose}
      >
        <Modal.Header>
          <FormattedMessage id="rep.closingModal.title" defaultMessage="Close the Reporting" />
        </Modal.Header>
        <Modal.Content>
          <p>
            <FormattedMessage
              id="rep.closingModal.mainDesc"
              defaultMessage="Are you sure you want to close this Reporting ?"
            />
            <br />
            <br />
            <FormattedMessage
              id="rep.closingModal.secondDesc"
              defaultMessage="Doing so will make this reporting uneditable
but it will still possible to export it to PDF."
            />
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.handleCloseReportModalClose}>
            <FormattedMessage id="cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            onClick={this.props.handleCloseReportModalAgree}
            positive={true}
          >
            <FormattedMessage id="rep.closingModal.close" defaultMessage="Close" />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default CloseReportModal;
