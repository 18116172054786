import { ActionEdit } from '../models/action';
import Utils from './utils';
import { ActionDateField, CommonDateField } from '../constants/fields';

export default class ActionUtils {
  public static parseActionDate = (data: ActionEdit) => {
    let action = data;
    if (action.targetDate) {
      action = Utils.parseDateStringToDate(action, CommonDateField.TARGET_DATE);
    }
    if (action.completionDate) {
      action = Utils.parseDateStringToDate(action, CommonDateField.COMPLETION_DATE);
    }
    if (action.startingDate) {
      action = Utils.parseDateStringToDate(action, ActionDateField.STARTING_DATE);
    }
    if (action.forecastDate) {
      action = Utils.parseDateStringToDate(action, ActionDateField.FORECAST_DATE);
    }
    return action;
  }

  public static isCodeValid = (actions: ActionEdit[], atId: number | null, code: number): boolean => {
    return !actions.filter(at => at.id !== atId).some(at => at.code === code);
  }
}
