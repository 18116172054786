import { EventEmitter } from 'events';
import ActionTypes from 'src/constants/actionTypes';
import Action from '../actions/action';
import Dispatcher from '../dispatcher';
import Role from '../models/role';
import AuthStore from '../stores/auth.store';
import { AssignedUser } from '../models/user';
import { GuideIds } from '../constants/guideConstants';

interface UserAvatars {
  userId: number;
  image: string;
}

class UserStore extends EventEmitter {

  private roles: Role[];
  private language: number;
  private avatars: UserAvatars[] = [];
  private defaultPpId: number | null;
  private selectedYears: Record<string, boolean> = {};
  private possibleYears: string[] = [];
  private assignedUsers: AssignedUser[] = [];
  private completedGuides: GuideIds[] = [];
  public isFetchingAvatar: { userId: number, promise: Promise<Blob> }[] = [];

  constructor() {
    super();
    Dispatcher.register(this.registerToActions.bind(this));
  }

  /**
   * Get Users roles
   * @return {Role[]}
   */
  public getRoles(): Role[] {
    return this.roles;
  }

  /**
   * Get User's language
   * @return {number}
   */
  public getLanguage(): number {
    return this.language;
  }

  /**
   * Get User's completed guides
   * @return {GuideIds[]}
   */
  public getCompletedGuides(): GuideIds[] {
    return this.completedGuides;
  }

  /**
   * Set Users roles
   * @param {Role[]} roles
   * @return void
   */
  public setRoles(roles: Role[]): void {
    this.roles = roles;
    this.emit(ActionTypes.USERS_ROLES_GET.toString());
  }

  /**
   * Get User's default pp id
   * @return {number}
   */
  public getDefaultPpId(): number | null {
    return this.defaultPpId;
  }

  /**
   * Set User's default pp id
   * @param {number} ppId
   * @return void
   */
  public setDefaultPpId(ppId: number): void {
    this.defaultPpId = ppId;
    this.emit(ActionTypes.USER_DEFAULT_PP_GET.toString());
  }

  /**
   * Set User's language
   * @param {number} language
   * @return void
   */
  public setLanguage(language: number): void {
    this.language = language;
    this.emit(ActionTypes.CHANGE_LANGUAGE.toString());
  }

  /**
   * Set User's completed guides
   * @param {GuideIds[]} completedGuides
   * @return void
   */
  public setCompletedGuides(completedGuides: GuideIds[]): void {
    this.completedGuides = completedGuides;
  }

  /**
   * Set assigned users list
   * @param {AssignedUser[]} invitedUsers
   * @return void
   */
  private setAssignedUsers(invitedUsers: AssignedUser[]): void {
    this.assignedUsers = invitedUsers;
    this.emit(ActionTypes.INVITED_USERS.toString());
  }

  /**
   * Reset assigned users list
   * @return {AssignedUser[]}
   */
  public resetAssignedUsers(): void {
    this.assignedUsers = [];
  }

  /**
   * Get assigned users list
   * @return {AssignedUser[]}
   */
  public getAssignedUsers(): AssignedUser[] {
    return this.assignedUsers;
  }

  public getUserAvatar(userId: number): string | void {
    const userAvatar = this.avatars.find(el => el.userId === userId);
    if (!!userAvatar) {
      return userAvatar.image;
    }
  }

  public setUserAvatar(userId: number, image: string) {
    const userAvatarIndex = this.avatars.findIndex(el => el.userId === userId);
    const newAvatar: UserAvatars = { userId, image };
    if (userAvatarIndex !== -1) {
      this.avatars[userAvatarIndex] = newAvatar;
    } else {
      this.avatars.push(newAvatar);
    }
  }

  /**
   * Get User's selected years
   * @return {string}
   */
  public getSelectedYears(): Record<string, boolean> {
    return this.selectedYears;
  }

  /**
   * Get User's selected years
   * @return {string}
   */
  public getPossibleYears(): string[] {
    return this.possibleYears;
  }

  /**
   * Set User's default pp id
   * @param {string} selectedYears
   * @return void
   */
  public setSelectedYears(selectedYears: Record<string, boolean>, possibleYears?: string[]): void {
    this.selectedYears = selectedYears;
    if (possibleYears) {
      this.possibleYears = possibleYears!!;
    }
    this.emit(ActionTypes.USER_SELECTED_YEARS_GET.toString());
  }
  /**
   * Performs a function according to an action
   * @param action :Action
   * @return void
   */
  private registerToActions(action: Action) {
    switch (action.actionType) {
      case ActionTypes.USERS_ROLES_GET:
        this.setRoles(action.roles);
        break;
      case ActionTypes.CHANGE_LANGUAGE:
        this.setLanguage(action.language);
        break;
      case ActionTypes.COMPLETED_GUIDES_GET:
        this.setCompletedGuides(action.completedGuides);
        break;
      case ActionTypes.PROFILE_SAVE:
        AuthStore.setConnectedUser(action.user);
        this.emit(ActionTypes.PROFILE_SAVE.toString());
        break;
      case ActionTypes.USER_DEFAULT_PP_GET:
        this.setDefaultPpId(action.defaultPpId);
        break;
      case ActionTypes.INVITED_USERS:
        this.setAssignedUsers(action.invitedUsers);
        break;
      case ActionTypes.USER_SELECTED_YEARS_GET:
        this.setSelectedYears(action.selectedYears, action.possibleYears);
        break;
      case ActionTypes.USER_SELECTED_YEARS_PUT:
        this.setSelectedYears(action.selectedYears);
        break;
    }
  }
}

export default new UserStore();
