import ActivitiesAPI from 'src/api/activites.api';
import ModeTypes from 'src/constants/modeTypes';
import TypeActivity from 'src/constants/typeActivity';
import PerformancePlan from 'src/models/performancePlan';
import ActionTypes from '../constants/actionTypes';
import Dispatcher from '../dispatcher';

export default class ActivitiesActions {

  /**
   * Get Business Challenge from API and emit actions BUSINESS_CHALLENGE_GET
   * @return void
   */
  public static emitGetActivities(): Promise<void> {
    return ActivitiesAPI.getActivities()
      .then((performancePlan: PerformancePlan) => {
        Dispatcher.dispatch({
          performancePlan,
          actionType: ActionTypes.ACTIVITIES_GET_MENU,
        });
      });
  }

  /**
   * EMIT ACTIVITY_SELECTED
   * @param typeActivity: TypeActivity
   * @return void
   *
   */
  public static emitGetActivitySelect(typeActivity: TypeActivity): void {
    setTimeout(
      () => {
        Dispatcher.dispatch({
          typeActivity,
          actionType: ActionTypes.ACTIVITY_SELECTED,
        });
      },
      0,
    );
  }

  /**
   * EMIT ACTIVITIES_GET_MODE
   * @param mode: ModeTypes
   * @return void
   *
   */
  public static emitChangeMode(mode: ModeTypes): void {
    Dispatcher.dispatch({
      mode,
      actionType: ActionTypes.ACTIVITIES_GET_MODE,
    });
  }

  /**
 * Emit when title needs to be reload
 * @return {void}
 *
 */
  public static emitReloadTitle(): void {
    Dispatcher.dispatch({
      actionType: ActionTypes.RELOAD_TITLE_ACTIVITY,
    });
  }

  /**
   * Emit an updated segment data to update lastUpdateInformations
   * @returns {void}
   */
  public static emitUpdatedLastUpdate(): void {
    Dispatcher.dispatch({
      actionType: ActionTypes.UPDATED_ACTIVITY,
    });
  }
}
