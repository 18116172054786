import { EventEmitter } from 'events';
import ActionTypes from 'src/constants/actionTypes';
import TypeElementTimeline from 'src/constants/timeline';
import Utils from 'src/utils/utils';

import Action from '../actions/action';
import Dispatcher from '../dispatcher';
import { At, BcWithMilestones, PiWithMilestones, Pp, RepObjectOnTimeline, TimelineInfo } from '../constants/timeline.types';
import TypeActivity from '../constants/typeActivity';

class TimelineStore extends EventEmitter {

  private reporting: RepObjectOnTimeline[] = [];
  private actions: At[] = [];
  private pp: Pp[] = [];
  private bc: BcWithMilestones[] = [];
  private pi: PiWithMilestones[] = [];
  private showReporting: boolean = false;
  private showBeforeReporting: boolean = false;

  constructor() {
    super();
    Dispatcher.register(this.registerToActions.bind(this));
  }

  private setInformationTimeline(info: TimelineInfo) {

    // reportings to display on timeline
    const reportingsTimeline: RepObjectOnTimeline[] = [];

    // if pps related to user (referent or deputy of plan only)
    if (info.performancePlans.length > 0) {

      info.performancePlans.forEach((pp) => {

        // get all the reportings related to that pp
        const reports = info.reportings.filter((element) => {
          return element.performancePlan.id === pp.id;
        });

        // add a PP REPORT entry for each reporting matching user's PPs
        reports.forEach((report) => {
          reportingsTimeline.push({
            id: report.id,
            name: `${report.name} -  ${report.performancePlan.name}`,
            type: TypeElementTimeline.REPORTING,
            dueDate: report.dueDate,
            performancePlanId: report.performancePlan.id,
            activityType: TypeActivity.PERFORMANCE_PLAN,
            activityId: report.performancePlan.id,
          });
        });
      });
    }

    // if bcs related to user
    if (info.businessChallenges.length > 0) {

      info.businessChallenges.forEach((bc) => {

        // get all the reportings related to that bc
        const reports = info.reportings.filter((repGet) => {
          return repGet.performancePlan.id === bc.performancePlan.id;
        });

        reports.forEach((repGet) => {

          const ppReport = reportingsTimeline.find((repObj) => {
            return repObj.dueDate === repGet.dueDate && repObj.type === TypeElementTimeline.REPORTING
                && Utils.isActivityPp(repObj.activityType);
          });

          // if the PP REPORT entry does not exist, create a new BC REPORT entry
          if (!ppReport) {
            reportingsTimeline.push({
              id: repGet.id,
              name: `${repGet.name} -  ${bc.performancePlan.name} BC${Utils.leadingZero(bc.code)}`,
              type: TypeElementTimeline.REPORTING,
              dueDate: repGet.dueDate,
              performancePlanId: repGet.performancePlan.id,
              activityType: TypeActivity.BUSINESS_CHALLENGE,
              activityId: bc.id,
            });
          }
        });
      });
    }

    // if pis related to user
    if (info.performanceInitiatives.length > 0) {

      info.performanceInitiatives.forEach((pi) => {

        // get all the reportings related to that pi
        const reports = info.reportings.filter((element) => {
          return element.performancePlan.id === pi.businessChallenge.performancePlan.id;
        });

        reports.forEach((report) => {

          const ppReport = reportingsTimeline.find((myR) => {
            return (myR.dueDate === report.dueDate
                && myR.type === TypeElementTimeline.REPORTING
                && Utils.isActivityPp(myR.activityType));
          });
          const bcReport = reportingsTimeline.find((myR) => {
            return (myR.dueDate === report.dueDate
                && myR.type === TypeElementTimeline.REPORTING
                 && Utils.isActivityBc(myR.activityType)
                && myR.activityId === pi.businessChallenge.id);
          });

          // If a PP REPORT entry does not already exists
          if (!ppReport) {
            // if a BC REPORT entry already exists transform it into a PI REPORT entry
            if (bcReport) {
              const index = reportingsTimeline.indexOf(bcReport);
              bcReport.name = `${bcReport.name} PI${Utils.leadingZero(pi.code)}`;
              bcReport.activityType = TypeActivity.PERFORMANCE_INITIATIVE;
              bcReport.type = TypeElementTimeline.REPORTING_BEFORE;
              bcReport.activityId = pi.id;
              reportingsTimeline[index] = bcReport;
              // If not, create a new PI REPORT entry
            } else {
              reportingsTimeline.push({
                id: report.id,
                name: `${report.name} - ${pi.businessChallenge.performancePlan.name}
                BC${Utils.leadingZero(pi.businessChallenge.code)} PI${Utils.leadingZero(pi.code)}`,
                type: TypeElementTimeline.REPORTING_BEFORE,
                dueDate: report.dueDate,
                performancePlanId: report.performancePlan.id,
                activityType: TypeActivity.PERFORMANCE_INITIATIVE,
                activityId: pi.id,
              });
            }
          }
        });
      });
    }

    this.reporting = [...reportingsTimeline];
    this.actions = [...info.actions];
    this.bc = [...info.businessChallenges];
    this.pi = [...info.performanceInitiatives];
    this.pp = [...info.performancePlans];
    this.emit(ActionTypes.TIMELINE_GET.toString());
  }

  public getActions() {
    return this.actions;
  }

  public getPP() {
    return this.pp;
  }

  public getReporting() {
    return this.reporting;
  }

  public getShowReporting() {
    return this.showReporting;
  }

  public getShowBeforeReporting() {
    return this.showBeforeReporting;
  }

  public getBc() {
    return this.bc;
  }

  public getPi() {
    return this.pi;
  }

  /**
   * Performs a function according to an action
   * @param action :Action
   * @return void
   */
  private registerToActions(action: Action) {
    switch (action.actionType) {
      case ActionTypes.TIMELINE_GET:
        this.setInformationTimeline(action.informationTimeline);
        break;
    }
  }

}

export default new TimelineStore();
