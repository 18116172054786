import NotificationActions from '../actions/notification-actions';
import { FormattedMessage } from 'react-intl';
import { ToastType } from '../components/common/toast/toast';
import React from 'react';
import TypeActivity from '../constants/typeActivity';
import Utils from './utils';

export default class NotificationUtils {

  //region SUCCESS
  public static sendActivityCreatedToast(type: TypeActivity) {
    let msg;
    switch (type) {
      case TypeActivity.ACTION:
        msg = <FormattedMessage id="successActionCreated" defaultMessage="The action has been successfully created"/>;
        break;
      case TypeActivity.MILESTONE:
        msg = <FormattedMessage id="successMilestoneCreated" defaultMessage="The milestone was successfully saved"/>;
        break;
    }
    NotificationActions.toast(
      <FormattedMessage id="activityCreated" defaultMessage="Activity created!"/>,
      msg, ToastType.SUCCESS,
    );
  }

  public static sendActivityUpdatedToast(type: TypeActivity) {
    let msg;
    switch (type) {
      case TypeActivity.MILESTONE:
        msg = (
          <FormattedMessage id="milestones.successUpdate" defaultMessage="The milestone was successfully updated" />
        );
        break;
      case TypeActivity.ACTION:
        msg = <FormattedMessage id="successActionSaved" defaultMessage="The action has been successfully updated"/>;
        break;
    }
    NotificationActions.toast(
      <FormattedMessage id="activityUpdated" defaultMessage="Activity Updated!" />,
      msg, ToastType.SUCCESS,
    );
  }

  public static sendActivityDeletedToast(type: TypeActivity, code: number) {
    let msg;
    switch (type) {
      case TypeActivity.MILESTONE:
        msg = (
          <FormattedMessage
            id="successMilestoneDeleted"
            defaultMessage="The milestone {milestoneCode} was successfully deleted"
            values={{
              milestoneCode: Utils.formatActivityCode(code, TypeActivity.MILESTONE),
            }}
          />
        );
        break;
      case TypeActivity.ACTION:
        msg = (
          <FormattedMessage
            id="actionDeleted"
            defaultMessage="The action {actionCode} was successfully deleted"
            values={{
              actionCode: Utils.formatActivityCode(code, TypeActivity.ACTION),
            }}
          />
        );
        break;
    }
    NotificationActions.toast(
      <FormattedMessage id="activityDeleted" defaultMessage="Activity Deleted!" />,
      msg, ToastType.SUCCESS,
    );
  }
  //endregion

  //region ERRORS
  public static sendErrorDuplicateCodesToast(type: TypeActivity) {
    let msg;
    switch (type) {
      case TypeActivity.ACTION:
        msg = (
          <FormattedMessage
            id="cannotSaveActionCode"
            defaultMessage={'Cannot save Action\'s code, another Action already uses this code. '
              + 'Please change or save this other Action before'}
          />
        );
        break;
    }
    NotificationActions.toast(
      <FormattedMessage id="codeAlreadyTaken" defaultMessage="This code is already taken"/>,
      msg, ToastType.ERROR, 6000,
    );
  }

  public static sendErrorDateToast() {
    const msg = (
      <FormattedMessage
        id="incorrectDateAction"
        defaultMessage={'The Starting Date must be before the Target, the Completion and the Forecast Date'}
      />
    );
    NotificationActions.toast(
      <FormattedMessage id="incorrectDate" defaultMessage="Incorrect Date(s)"/>,
      msg, ToastType.ERROR, 6000,
    );
  }
  //endregion
}
