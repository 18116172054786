import './moreInfo.scss';

import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import PerformancePlanActions from 'src/actions/performancePlan.action';
import ActionTypes from 'src/constants/actionTypes';
import ModeTypes from 'src/constants/modeTypes';
import PerformancePlanStore from 'src/stores/performancePlan.store';
import TypeActivity from 'src/constants/typeActivity';
import TeamView from '../../teamView/teamView';
import AttachmentsManagement from '../../attachmentsManagement/attachmentsManagement';
import IPpBusinessLocations from '../../../../../models/performancePlanBusinessLocations';
import About from './about/about';
import TeamSegment from '../../common/teamSegment/teamSegment';
import LastUpdateHighlight from '../../common/overviewPanel/panelContent/tabs/lastUpdatedInformations/lastUpdateHighlight';
import { RightsOnPP } from 'src/models/rights';

export interface IPpFormValid {
  isYearValid: boolean;
}

interface IRouteProps {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  mode: ModeTypes;
  rightsOnPP: RightsOnPP | undefined;
}

interface IStates {
  geoGbuOptions: IPpBusinessLocations;
  isFormValid: IPpFormValid;
}

class MoreInfo extends React.Component<IProps, IStates> {
  private isMount = false;

  constructor(props: IProps) {
    super(props);

    this.state = {
      geoGbuOptions: PerformancePlanStore.getBusinessLocations(),
      isFormValid: { isYearValid: true },
    };
  }

  //region REACT LIFE-CYCLE
  public componentDidMount() {
    this.isMount = true;
    LastUpdateHighlight.highlightParam();
    PerformancePlanActions.emitGetPpEntitiesLocations();
    PerformancePlanStore.addListener(ActionTypes.PERFORMANCE_PLAN_ENTITIES_LOCATIONS_GET.toString(), this.setOptions);
  }

  public componentWillUnmount() {
    this.isMount = false;
    PerformancePlanStore.removeListener(
      ActionTypes.PERFORMANCE_PLAN_ENTITIES_LOCATIONS_GET.toString(), this.setOptions);
  }

  public setOptions = () => {
    if (this.isMount) {
      this.setState({ geoGbuOptions: PerformancePlanStore.getBusinessLocations() });
    }
  };
  //endregion

  public canEdit = () : boolean => {
    return this.props.rightsOnPP ? this.props.rightsOnPP.canEdit() : false;
  }

  public canExportWhole = () : boolean => {
    return this.props.rightsOnPP ? this.props.rightsOnPP.canExportWhole() : false;
  }

  public render() {
    return (
      <div id="more-info-pp">
        <div className="more-info-top">
          <div className="column">
            <div className="element">
              <About canEdit={this.canEdit()}/>
            </div>
          </div>
          <div className="column">
            <div className="element">
              <TeamSegment
                canEdit={this.canEdit()}
                canExportWhole={this.canExportWhole()}
                activityId={+this.props.match.params.id}
                activityType={TypeActivity.PERFORMANCE_PLAN}
              />
            </div>
            <div className="element">
              <AttachmentsManagement
                canEdit={this.canEdit()}
                activityId={+this.props.match.params.id}
                activityType={TypeActivity.PERFORMANCE_PLAN}
              />
            </div>
          </div>
        </div>
        { this.props.rightsOnPP?.canViewCockpit() &&
        <TeamView activityId={+this.props.match.params.id} activityType={TypeActivity.PERFORMANCE_PLAN} />}
      </div>
    );
  }
}

export default withRouter(MoreInfo);
