import './objectives.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from 'semantic-ui-react';

import BusinessChallenge from 'src/models/businessChallenge';
import PerformanceInitiative from 'src/models/performanceInitiative';
import Utils from 'src/utils/utils';
import Metric from '../../../models/metric';
import Milestone from '../../../models/milestone';
import TypeActivity, { MetricMilestone } from '../../../constants/typeActivity';
import ImportedLabel from '../../activitiesBoard/containerActivities/metricsMilestones/shared/importedLabel';
import { KeyAction } from '../../../models/action';

interface IProps {
  element: BusinessChallenge | PerformanceInitiative;
  isPublicView: boolean;
  activityType: TypeActivity;
}

class ObjectivesContainer extends React.Component<IProps> {

  public constructor(props: IProps) {
    super(props);
  }

  private getMetrics() {
    const metrics: JSX.Element[] = [];
    if (this.props.element.metrics) {
      this.props.element.metrics
        .filter(me => this.props.isPublicView ? !me.isHidden : true)
        .sort((a, b) => a.code < b.code ? -1 : 1)
        .forEach((metric: Metric) => {
          metrics.push(
            <Table.Row key={`metric${metric.id}`}>
              <Table.Cell className="metric-row-name" textAlign="left">
                {metric.name}
                {metric.performanceInitiative &&
                <ImportedLabel
                  ppId={(this.props.element as BusinessChallenge).performancePlan.id}
                  piId={metric.performanceInitiative.id}
                  metricMilestone={metric}
                  type={MetricMilestone.METRIC}
                />}
              </Table.Cell>
              <Table.Cell textAlign="right" className={'bold-item'}>
                {metric.mostRecentValues.current}
              </Table.Cell>
              <Table.Cell textAlign="right" className={'bold-item'}>
                {metric.mostRecentValues.target}
              </Table.Cell>
            </Table.Row>,
          );
        });
    }
    return metrics;
  }

  private getMetricsTable(metrics: JSX.Element[]) {
    return (
      <div id="bc-metrics-table">
        <Table basic="very" collapsing={true}>
          <Table.Header>
            <Table.Row className="header-row">
              <Table.HeaderCell className="table-title" width={7} textAlign="left">
                <FormattedMessage
                  id="metrics"
                  defaultMessage="Metrics"
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="table-title value" width={3} textAlign="right">
                <FormattedMessage
                  id="current"
                  defaultMessage="Current"
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="table-title value" width={4} textAlign="right">
                <FormattedMessage
                  id="target"
                  defaultMessage="Target"
                />
              </Table.HeaderCell>
            </Table.Row>
            {metrics}
          </Table.Header>
        </Table>
      </div>
    );
  }

  private getMilestones() {
    const milestones: JSX.Element[] = [];
    if (this.props.element.milestones) {
      this.props.element.milestones
        .filter(mi => this.props.isPublicView ? !mi.isHidden : true)
        .forEach((milestone: Milestone) => {
          milestones.push(
            <Table.Row key={`milestone${milestone.id}`}>
              <Table.Cell className="metric-row-name" textAlign="left">
                <>
                  {milestone.name}
                  {(!!milestone.piCode) &&
                  <ImportedLabel
                    ppId={milestone.piCode ?
                      (this.props.element as BusinessChallenge).performancePlan.id :
                      (this.props.element as PerformanceInitiative).businessChallenge.performancePlan.id
                    }
                    piId={this.getPiIdByCode(milestone.piCode)}
                    metricMilestone={milestone}
                    type={MetricMilestone.MILESTONE}
                  />}
                </>
              </Table.Cell>
              <Table.Cell textAlign="right" className={'bold-item'}>
                {Utils.showDateOrNone(milestone.targetDate)}
              </Table.Cell>
              <Table.Cell textAlign="right" className={'bold-item'}>
                {Utils.showDateIfExists(milestone.completionDate, false)}
              </Table.Cell>
            </Table.Row>,
          );
        });
    }
    return milestones;
  }

  private getKeyActions() {
    const keyActions: JSX.Element[] = [];
    const actions = (this.props.element as BusinessChallenge).keyActions
      .filter(at => this.props.isPublicView ? !at.isHidden : true);
    if (actions && actions.length > 0) {
      actions.forEach((at: KeyAction) => {
        keyActions.push(
          <Table.Row key={`action${at.id}`}>
            <Table.Cell className="metric-row-name" textAlign="left">
              <div>
                <b className="action-label">
                    <>{Utils.formatActivityCode(at.piCode, TypeActivity.PERFORMANCE_INITIATIVE)} . </>
                  {Utils.formatActivityCode(at.code, TypeActivity.ACTION)}
                </b> - {at.name}
              </div>
            </Table.Cell>
            <Table.Cell textAlign="right" className={'bold-item'}>
              {Utils.displayDate(at.targetDate)}
            </Table.Cell>
            <Table.Cell textAlign="right" className={'bold-item'}>
              {Utils.showDateIfExists(at.completionDate, false)}
            </Table.Cell>
          </Table.Row>,
        );
      });
    }
    return keyActions;
  }

  private getPiIdByCode = (piCode: number | undefined) => {
    if (piCode) {
      return (this.props.element as BusinessChallenge).performanceInitiatives.find(pi => pi.code === piCode)?.id;
    }
  }

  private getMilestonesOrKeyActionsTable(data: JSX.Element[], allTablesVisible: boolean, forActions = false) {
    const id = forActions ? 'bc-key-actions-table' : 'bc-milestones-table';
    const title = forActions ? <FormattedMessage id="keyActions" defaultMessage="Key Actions"/>
      : <FormattedMessage id="milestones" defaultMessage="Milestones" />;
    return (
      <div id={id} className={forActions && allTablesVisible ?  'no-right-padding' : ''}>
        <Table basic="very" collapsing={true}>
          <Table.Header>
            <Table.Row className="header-row">
              <Table.HeaderCell className="table-title" width={7} textAlign="left">
                {title}
              </Table.HeaderCell>
              <Table.HeaderCell className="table-title value" width={4} textAlign="right">
                <FormattedMessage
                  id="targetDate"
                  defaultMessage="Target date"
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="table-title value" width={4} textAlign="right">
                <FormattedMessage
                  id="dateOfComp"
                  defaultMessage="Comp. Date"
                />
              </Table.HeaderCell>
            </Table.Row>
            {data}
          </Table.Header>
        </Table>
      </div>
    );
  }

  public shouldShowTable = (data: JSX.Element[], onBcOnly = false) => {
    return (onBcOnly ? Utils.isActivityBc(this.props.activityType) : true) && data && data.length > 0;
  }

  public render() {
    const keyActions = Utils.isActivityBc(this.props.activityType) ? this.getKeyActions() : [];
    const milestones = this.getMilestones();
    const metrics = this.getMetrics();

    const allTablesVisible = this.shouldShowTable(keyActions, true) && this.shouldShowTable(metrics)
      && this.shouldShowTable(milestones);

    return (
      <div id="objectives-container">
        <div id="objectives-content">
          {this.shouldShowTable(metrics) && this.getMetricsTable(metrics)}
          {this.shouldShowTable(milestones) && this.getMilestonesOrKeyActionsTable(milestones, allTablesVisible)}
          {this.shouldShowTable(keyActions, true)
            && this.getMilestonesOrKeyActionsTable(keyActions, allTablesVisible, true)
          }
        </div>
      </div>
    );
  }
}

export default ObjectivesContainer;
