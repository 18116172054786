import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';

// tslint:disable-next-line: variable-name
export function withPanelAnimation<T>(BaseComponent: React.ComponentType<T>) {
  const componentWithAnimation = (props) => {
    return (
      <CSSTransitionGroup
        transitionName="overview"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {props.isOverviewPanelOpen ?
        <BaseComponent {...props} />
        : <></>}
      </CSSTransitionGroup>
    );
  };
  return componentWithAnimation;
}
