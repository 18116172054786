import './importedLabel.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Icon, Label, Popup } from 'semantic-ui-react';
import TypeActivity, { MetricMilestone } from 'src/constants/typeActivity';
import Metric from 'src/models/metric';
import Milestone from 'src/models/milestone';
import Utils from 'src/utils/utils';
import { PiTabs } from '../../contentPI/contentPI';

interface IRouteProps {
  ppId?: string;
  id?: string;
  bcId?: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  metricMilestone: Metric | Milestone;
  type: MetricMilestone;
  ppId: number;
  piId?: number;
}

class ImportedLabel extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
  }

  private redirectToOriginal = () => {
    this.props.history.push(
      `/activities-board/${this.props.ppId}/performance-initiative/${this.props.piId}/${PiTabs.ACTIVITY}/${this.props.metricMilestone.id}`);
  }

  public render() {
    let code;
    switch (this.props.type) {
      case MetricMilestone.METRIC:
        const pi = (this.props.metricMilestone as Metric).performanceInitiative;
        if (pi) {
          code = pi.code;
        }
        break;
      case MetricMilestone.MILESTONE:
        const milestone = (this.props.metricMilestone as Milestone);
        code = milestone.performanceInitiative && milestone.performanceInitiative.code
          ? milestone.performanceInitiative.code : milestone.piCode;
        break;
    }
    const popupMessage = this.props.type === MetricMilestone.METRIC ?
    <FormattedMessage id="imported.metric" defaultMessage="This metric is imported from " /> :
    <FormattedMessage id="imported.milestone" defaultMessage="This milestone is imported from " />;
    return (
      <Popup
        inverted={true}
        content={(
          <>
            {popupMessage}
            <b>{Utils.formatActivityCode(code, TypeActivity.PERFORMANCE_INITIATIVE)}</b>
          </>
        )}
        size="tiny"
        position="right center"
        trigger={
          <Label
            className="imported-pi-label"
            circular={true}
            key={'grey'}
            onClick={() => this.redirectToOriginal()}
          >
            <Icon name="linkify" />
            {Utils.formatActivityCode(code, TypeActivity.PERFORMANCE_INITIATIVE)}
          </Label>
        }
      />
    );
  }
}

export default withRouter(ImportedLabel);
