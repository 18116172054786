import '../metricsMilestones.scss';

import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import Utils from '../../../../../utils/utils';
import CustomScrollBars from 'src/components/common/customScrollBars/customScrollBars';
import Milestone from 'src/models/milestone';
import Metric from 'src/models/metric';
import { importType } from './importMetricsMilestonesModal';

interface IProps {
  loadingMetricMilestone: boolean;
  importType: importType;
  milestones: Milestone[];
  metrics: Metric[];
  selectedMilestone: Milestone | undefined;
  selectedMetric: Metric | undefined;
  selectMetricMilestone(element: Metric | Milestone): void;
}

export default class MetricMilestoneSelection extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    let selection;
    let selected;
    if (this.props.importType === importType.milestone) {
      selection = 'milestones';
      selected = 'selectedMilestone';
    } else {
      selection = 'metrics';
      selected = 'selectedMetric';
    }

    if (this.props.loadingMetricMilestone) {
      return Utils.loader();
    }

    if (this.props[selection].length === 0) {
      const message = this.props.importType === importType.milestone
      ? <FormattedMessage id="noMilestones" defaultMessage="No milestones available" />
      : <FormattedMessage id="noMetrics" defaultMessage="No metrics available" />;
      return Utils.empty(message);
    }

    return (
      <div id="pi-selection" className="step-container">
        <CustomScrollBars>
            {this.props[selection].map(s =>
              <div key={`pi${s.id}`} className="tile-container">
                <div
                  className={`tile ${this.props[selected].id === s.id ? 'selected' : ''}`}
                  onClick={() => this.props.selectMetricMilestone(s)}
                >
                  {this.props[selected].id === s.id && <Icon name="check" />} {s.name}
                </div>
              </div>,
            )}
        </CustomScrollBars>
      </div>
    );
  }
}
