import PerformancePlan from 'src/models/performancePlan';
import HttpClient from 'src/utils/httpClient';

export default class ActivitiesAPI {

  /**
 * ROUTE : /api/activities
 * Get Activities
 * @returns Promise<PerformancePlan>
 */
  public static getActivities(): Promise<PerformancePlan> {
    return HttpClient.get<PerformancePlan>('activities');
  }
}
