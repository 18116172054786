import { AvailableRoles } from './typeActivity';

export class MemberOptions {
  ppReferent: boolean;
  ppInformed: boolean;

  bcLeaders: boolean;
  bcInformed: boolean;

  piLeaders: boolean;
  piInformed: boolean;

  actionOwners: boolean;

  specificBcs: number[];

  constructor(roles: AvailableRoles[], bcs?: number[]) {
    this.ppReferent = roles.some(ro => ro === AvailableRoles.REFERENT);
    this.ppInformed = roles.some(ro => ro === AvailableRoles.PP_INFORMED);

    this.bcLeaders = roles.some(ro => ro === AvailableRoles.BC_LEADERS);
    this.bcInformed = roles.some(ro => ro === AvailableRoles.BC_INFORMED);

    this.piLeaders = roles.some(ro => ro === AvailableRoles.PI_LEADERS);
    this.piInformed = roles.some(ro => ro === AvailableRoles.PI_INFORMED);

    this.actionOwners = roles.some(ro => ro === AvailableRoles.AT_OWNERS);

    if (bcs && bcs.length > 0) this.specificBcs = bcs;
  }
}
