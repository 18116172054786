import { EventEmitter } from 'events';
import ActionTypes from 'src/constants/actionTypes';
import {
  SortBy,
  sortOptions,
} from 'src/constants/interfaces/action.interface';
import Action from 'src/models/action';
import FluxAction from 'src/actions/action';
import Dispatcher from '../dispatcher';
import { LinkedAction } from 'src/models/linkedMilestone';

class ActionStore extends EventEmitter {

  private actions: Action[];
  private sortBy : SortBy;
  private actionsLinkedMilestone: Map<number, LinkedAction[]>;

  /**
   * IBOOST V2 STORE
  */
  private createdAction: Action;

  constructor() {
    super();
    Dispatcher.register(this.registerToActions.bind(this));
  }

  /**
   * Return the action array
   */
  public getActions(): Action[] {
    return this.actions;
  }

  /**
   * Listen to changes on the action array
   * @param callback
   */
  public addActionsListener(callback: () => void) {
    this.on(ActionTypes.ACTIONS_GET.toString(), callback);
  }

  private setActions(actions: Action[]) {
    this.actions = actions;
    this.emit(ActionTypes.ACTIONS_GET.toString(), actions);
  }

  /**
   *  Delete Action
   * @return void
   */
  private deleteAction(): void {
    this.emit(ActionTypes.ACTION_DELETED.toString());
  }

  public getActionLinkedMilestone(): Map<number, LinkedAction[]> {
    return this.actionsLinkedMilestone;
  }

  private setActionLinkedMilestone(actionsLinkedMilestone: Map<number, LinkedAction[]>) {
    this.actionsLinkedMilestone = actionsLinkedMilestone;
    this.emit(ActionTypes.GET_LINK_ACTION_TO_MILESTONE.toString());
  }

  /*
  * update action take an action id and a milestone id
  * it changes the value of actionsLinkedMilestone by adding the corresponding LinkedAction for the map entry
  * Then it emits so the listener can update their value
  */
  public updateActionLinkedMilestone(atId: number, miId: number) {
    let actions: LinkedAction[];
    if (this.actionsLinkedMilestone === undefined) {
      this.actionsLinkedMilestone = new Map<number, LinkedAction[]>();
    }
    actions = this.actionsLinkedMilestone.get(miId) !== undefined ? this.actionsLinkedMilestone.get(miId)!! : [];
    const linkedAction = new LinkedAction();
    linkedAction.pimiId = miId;
    linkedAction.atId = atId;
    actions.push(linkedAction);
    this.actionsLinkedMilestone.set(miId, actions);
    this.emit(ActionTypes.GET_LINK_ACTION_TO_MILESTONE.toString(), this.actionsLinkedMilestone);
  }

  /*
  * delete action take an action id and a milestone id
  * it deletes the corresponding LinkedAction for the map entry
  * Then it emits so the listener can update their value
  */
  public deleteActionLinkedMilestone(atId: number, miId: number) {
    let actions: LinkedAction[];
    actions = this.actionsLinkedMilestone.get(miId)!!.filter(at => at.atId !== atId);
    this.actionsLinkedMilestone.set(miId, actions);
    this.emit(ActionTypes.GET_LINK_ACTION_TO_MILESTONE.toString(), this.actionsLinkedMilestone);
  }

  /**
   * Performs a function according to an action
   * @param action :Action
   * @return void
   */
  private registerToActions(action: FluxAction) {
    switch (action.actionType) {
      case ActionTypes.ACTION_DELETED:
        this.deleteAction();
        break;
      case ActionTypes.ACTIONS_GET:
        this.setActions(action.actions);
        break;
      case ActionTypes.SET_CREATED_ACTION:
        this.setCreatedAction(action.action);
        break;
      case ActionTypes.ACTION_SAVED:
        this.emit(ActionTypes.ACTION_SAVED.toString());
        break;
      case ActionTypes.GET_LINK_ACTION_TO_MILESTONE:
        this.setActionLinkedMilestone(action.actionsLinkedMilestone);
        break;
      case ActionTypes.DELETE_LINK_ACTION_TO_MILESTONE:
        this.deleteActionLinkedMilestone(action.atId, action.miId);
        break;
      case ActionTypes.SET_LINK_ACTION_TO_MILESTONE:
        this.updateActionLinkedMilestone(action.atId, action.miId);
        break;
    }
  }

  public getSortBy(): SortBy {
    return this.sortBy;
  }

  public setSortBy(sortBy: SortBy) {
    this.sortBy = sortBy;
    this.emit(ActionTypes.SORT_BY_GET.toString(), sortBy);
  }

  public resetSortBy() {
    this.sortBy = {
      value: sortOptions[0].value,
      order: 'ASC',
    };
  }

  /**
   * IBOOST V2 STORE
  */
  public getCreatedAction(): Action {
    return this.createdAction;
  }

  private setCreatedAction(action: Action): void {
    this.createdAction = action;
    this.emit(ActionTypes.SET_CREATED_ACTION.toString());
  }
}

export default new ActionStore();
