import './ppCreation.scss';
import 'react-datepicker/dist/react-datepicker.css';

import * as React from 'react';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';

import PerformancePlanActions from 'src/actions/performancePlan.action';
import ActionTypes from 'src/constants/actionTypes';
import AuthStore from 'src/stores/auth.store';
import PerformancePlanStore from 'src/stores/performancePlan.store';
import ActivitiesActions from '../../../actions/activities.action';
import NotificationActions from '../../../actions/notification-actions';
import PerformancePlan, { PerformancePlanPut } from '../../../models/performancePlan';
import Utils from '../../../utils/utils';
import Creation from '../creation';
import PpCreationStep1 from './ppCreationStep1';
import PpCreationStep2 from './ppCreationStep2';
import PpCreationStep3 from './ppCreationStep3';
import PpCreationStep4 from './ppCreationStep4';
import { ToastType } from 'src/components/common/toast/toast';
import { PpErrors } from '../../../constants/errors/performance-plan.error';
import IPpBusinessLocations from '../../../models/performancePlanBusinessLocations';
import { IBusinessLocationsValues } from '../../common/form/ppGbuGeo/ppGbuGeo';

interface IProps extends InjectedIntlProps, RouteComponentProps {
  open: boolean;
  close: () => void;
}

interface IStates {
  options: IPpBusinessLocations;
  pp: PerformancePlanPut;
  isSubmitDisabled: boolean;
  isNavigationDisabled: boolean;
}

class PpCreation extends React.Component<IProps, IStates> {

  private isMount = false;
  private readonly child: React.RefObject<Creation>;

  private creationTitle = this.props.intl.formatMessage(
    {
      id: 'ppCreation.createNewPlan',
      defaultMessage: 'Create a new Performance Plan',
    });

  private creationSteps = [
    {
      title: this.props.intl.formatMessage(
        {
          id: 'ppCreation.businessAndLocation',
          defaultMessage: 'Business & Location',
        }),
      subtitle: '',
    },
    {
      title: this.props.intl.formatMessage(
        {
          id: 'ppCreation.otherInformation',
          defaultMessage: 'Other information',
        }),
      subtitle: '',
    },
    {
      title: this.props.intl.formatMessage(
        {
          id: 'ppCreation.deputiesAndShared',
          defaultMessage: 'Deputies and Shared with',
        }),
      subtitle: '',
    },
    {
      title: this.props.intl.formatMessage(
        {
          id: 'ppCreation.confirmName',
          defaultMessage: 'Confirm Name',
        }),
      subtitle: '',
    },
  ];

  private readonly NONE_VALUE = 1;
  private defaultStatePP = {
    name: '',
    isDraft: false,
    gbuId: this.NONE_VALUE,
    businessLineId: this.NONE_VALUE,
    segmentId: this.NONE_VALUE,
    geographyId: this.NONE_VALUE,
    countryId: this.NONE_VALUE,
    subcountryId: this.NONE_VALUE,
    year: '',
    owner: AuthStore.getConnectedUser(),
    assignedAccounts: [],
    informedAccounts: [],
    attachments: [],
  };

  public constructor(props: IProps) {
    super(props);

    this.state = {
      options: PerformancePlanStore.getBusinessLocations(),
      pp: { ...this.defaultStatePP },
      isSubmitDisabled: false,
      isNavigationDisabled: false,
    };

    this.child = React.createRef();
  }

  public componentWillMount() {
    this.isMount = true;
    PerformancePlanStore.addListener(ActionTypes.PERFORMANCE_PLAN_ENTITIES_LOCATIONS_GET.toString(), this.setOptions);
    PerformancePlanStore.addListener(ActionTypes.PERFORMANCE_PLAN_SAVED.toString(), this.afterSubmit);
    if (!this.state.options) {
      PerformancePlanActions.emitGetPpEntitiesLocations();
    }
  }

  public componentWillUnmount() {
    this.isMount = false;
    PerformancePlanStore.removeListener(
      ActionTypes.PERFORMANCE_PLAN_ENTITIES_LOCATIONS_GET.toString(), this.setOptions);
    PerformancePlanStore.removeListener(ActionTypes.PERFORMANCE_PLAN_SAVED.toString(), this.afterSubmit);
  }

  /**
   * Set whether the navigation is disabled or not
   */
  private setNavigationDisabled = (isNavigationDisabled: boolean) => {
    this.setState({ isNavigationDisabled });
  };

  /**
   * Refresh the state when receiving new options
   */
  private setOptions = () => {
    if (this.isMount) {
      this.setState({ options: PerformancePlanStore.getBusinessLocations() });
    }
  };

  /**
   * Format the options for the dropdowns
   * @param values : any[]
   * @returns JSON objects
   */
  private buildOptions = (values: any[]) => {
    const options: any = [];
    const valuesSorted = Utils.sortArrayByKey(values, 'name').filter((myObj: any) => myObj.name !== 'None');

    if (!valuesSorted.find((myObj: any) => myObj.id === 1)) {
      options.push({ key: 1, text: 'None', value: 1 });
    }

    valuesSorted.map((value: any) => {
      options.push({ text: value.name, value: value.id });
      return null;
    });

    return options;
  };

  /**
   * Actions to do to create the PP
   */
  private submit = () => {
    return PerformancePlanActions.emitCreatePerformancePlan(this.state.pp)
      .then((newPp: PerformancePlan) => {
        this.close();
        NotificationActions.toast(
          <FormattedMessage id="saved" defaultMessage="Saved!" />,
          (
            <FormattedMessage
              id="successPPCreation"
              defaultMessage="The Performance Plan has been successfully created"
            />
          ),
          ToastType.SUCCESS,
        );
        this.props.history.push(`/activities-board/performance-plan/${newPp.id}/cockpit`);
      })
      .catch((err) => {
        if (err.error === PpErrors.PP_WITH_SAME_NAME_YEAR_ALREADY_EXISTS) {
          NotificationActions.toast(
            <FormattedMessage id="pp.duplicatePlan" defaultMessage="Plan duplication error!" />,
            (
              <FormattedMessage
                id="pp.nameYearAlreadyExists"
                defaultMessage="A Performance Plan with the same name/year combination already exists"
              />
            ),
            ToastType.ERROR,
          );
        } else {
          Utils.toastError();
        }

        return Promise.reject();
      });
  };

  /**
   * Close the modal and reset the state
   */
  private close = () => {
    this.props.close();
    this.setState({ pp: { ...this.defaultStatePP } });
  };

  /**
   * Update the PP when a value is modified in the child components
   * @param key : string
   * @param value : any
   */
  private updatePP = (key: string, value: any) => {
    const pp = this.state.pp;
    if (key === 'owner') {
      const currentUser = AuthStore.getConnectedUser();
      if (pp.assignedAccounts !== undefined) {
        if (value.id === currentUser.id) {
          pp.assignedAccounts.splice(pp.assignedAccounts.map(acc => acc.id).indexOf(value.id), 1);
        } else {
          if (pp.assignedAccounts.map(acc => acc.id).indexOf(currentUser.id) === -1) {
            pp.assignedAccounts.push(currentUser);
          }
        }
      }
    }

    pp[key] = value;
    this.setState({ pp });
  };

  private onUpdateGbuGeo = (geoGbuValues: IBusinessLocationsValues) => {
    const pp = this.state.pp;
    pp.gbuId = geoGbuValues.gbuId;
    pp.businessLineId = geoGbuValues.businessLineId;
    pp.segmentId = geoGbuValues.segmentId;
    pp.geographyId = geoGbuValues.geographyId;
    pp.countryId = geoGbuValues.countryId;
    pp.subcountryId = geoGbuValues.subcountryId;
    this.setState({ pp });
    this.refreshPPName();
  };

  /**
   * Auto-generate the PP name depending on the Business/Locations selected
   */
  private refreshPPName() {
    let prefix;
    let suffix;

    if (this.state.pp.gbuId !== this.NONE_VALUE) {
      prefix = this.findObjInOptions(this.state.options.gbus, this.state.pp.gbuId).name;

      if (this.state.pp.businessLineId !== this.NONE_VALUE) {
        const blObj = this.findObjInOptions(this.state.options.businessLines, this.state.pp.businessLineId);
        if (blObj) {
          prefix = blObj.name;

          if (this.state.pp.segmentId !== this.NONE_VALUE) {
            const segmentObj = this.findObjInOptions(this.state.options.segments, this.state.pp.segmentId);
            if (segmentObj) {
              prefix = segmentObj.tag;
            }
          }
        }
      }
    }

    if (this.state.pp.geographyId !== this.NONE_VALUE) {
      suffix = this.findObjInOptions(this.state.options.geographies, this.state.pp.geographyId).name;

      if (this.state.pp.countryId !== this.NONE_VALUE) {
        const countryObj = this.findObjInOptions(this.state.options.countries, this.state.pp.countryId);
        if (countryObj) {
          suffix = countryObj.tag;
        }

        if (this.state.pp.subcountryId !== this.NONE_VALUE) {
          const subCountryObj = this.findObjInOptions(this.state.options.subcountries, this.state.pp.subcountryId);
          if (subCountryObj) {
            suffix = subCountryObj.tag;
          }
        }
      }
    }

    const pp = this.state.pp;
    pp.name = [prefix, suffix].join(!!prefix && !!suffix ? '_' : '');
    this.setState({ pp });
  }

  /**
   * Find an object amongst an array of objects depending on its id
   * @param options : any
   * @param key : any
   */
  private findObjInOptions(options: any, key: any): any {
    return options.find((myObj: any) => myObj.id === key);
  }

  public render() {
    if (this.state.options) {
      return (
        <Creation
          ref={this.child}
          open={this.props.open}
          close={this.close}
          title={this.creationTitle}
          steps={this.creationSteps}
          submit={this.submit}
          isNavigationDisabled={this.state.isNavigationDisabled}
        >
          <PpCreationStep1
            pp={this.state.pp}
            onUpdate={this.onUpdateGbuGeo}
            setNavigationDisabled={this.setNavigationDisabled}
          />

          <PpCreationStep2
            pp={this.state.pp}
            onUpdate={this.updatePP}
            buildOptions={this.buildOptions}
            setNavigationDisabled={this.setNavigationDisabled}
          />

          <PpCreationStep3
            pp={this.state.pp}
            onUpdate={this.updatePP}
          />

          <PpCreationStep4
            pp={this.state.pp}
            onUpdate={this.updatePP}
            setNavigationDisabled={this.setNavigationDisabled}
          />
          <span hidden={true}>
            <FormattedMessage
              id="ppCreation.deputiesAndShared"
              defaultMessage="Deputies and Shared with"
            />
          </span>
        </Creation>
      );
    }

    return Utils.loader();
  }

  /**
   * Actions to do when the PP is created
   */
  private afterSubmit = () => {
    if (this.isMount) {
      if (this.child.current) {
        this.child.current.handleClose();
        ActivitiesActions.emitGetActivities();
      }
    }
  };
}

export default injectIntl(withRouter(PpCreation));
