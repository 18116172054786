import User from 'src/models/user';
import HttpClient from 'src/utils/httpClient';
import { TopicsParams } from '../components/explore/containerExplore/topics/topics';

export default class DatavizAPI {

  /**
   * ROUTE : /api/dataviz/keywords<?gbuId={gbuId}>
   * Get Dataviz Keywords by GBU
   * @param params: TopicsParams
   * @returns Promise<any>
   */
  public static getKeywords(params: TopicsParams): Promise<any> {
    return HttpClient.get(`dataviz/keywords${HttpClient.formatUrlQueryParams(params)}`);
  }

   /**
   * ROUTE : /api/users
   * Get all users
   * @returns Promise<User[]>
   */
  public static getUsersWithFilters(queryParams: {
    gbuId?: number,
    countryId?: number,
    geographyId?: number,
    subcountryId?: number,
    bcKeywordId?: number,
    bcFunctionId?:number,
    piKeywordId?: number,
    roleId?: number,
    year?: string,
    limit: number,
    offset: number,
  }): Promise<User[]> {
    return HttpClient.get<User[]>(`dataviz/community${HttpClient.formatUrlQueryParams(queryParams)}`);
  }
}
