export default interface TypeError {
  reqStatus: number;
  error: string;
}

export enum ErrType {
  ME_NAME,
  ME_COMPARISON_TOLERANCE,
  MEV_DATE,
  MEV_CURRENT,
  MEV_TARGET,

  MI_NAME,
  MI_TARGET_DATE,

  TEXT_FIELDS,
  ATTACHMENTS,
}
