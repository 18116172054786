import './frame.scss';

import * as React from 'react';
import { addLocaleData, IntlProvider, FormattedMessage } from 'react-intl';
import * as en from 'react-intl/locale-data/en';
import * as fr from 'react-intl/locale-data/fr';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { Segment, Progress, Icon } from 'semantic-ui-react';

import AdministrationBoard from 'src/components/administrationBoard/administrationBoard';
import SearchResult from '../../components/searchResult/searchResult';
import ActionTypes from '../../constants/actionTypes';
import AuthStore from '../../stores/auth.store';
import UserStore from '../../stores/user.store';
import ExportStore from 'src/stores/export.store';
import ExploreBoard from '../explore/exploreBoard';
import BcHistoryExternal from '../historyExternal/bcHistoryExternal/bcHistoryExternal';
import PiHistoryExternal from '../historyExternal/piHistoryExternal/piHistoryExternal';
import PpHistoryExternal from '../historyExternal/ppHistoryExternal/ppHistoryExternal';
import Info from '../info/info';
import Main from '../main/main';
import Error from '../error/error';
import Profile from '../profile/profile';
import Toast from '../common/toast/toast';
import TopBar from '../topBar/topBar';
import UserAPI from '../../api/user.api';
import Utils from '../../utils/utils';
import UserGuide from '../userGuide/userGuide';
import UserActions from '../../actions/user.action';

const messagesEn = require('../../translations/locales/en.json');
const messagesFr = require('../../translations/locales/fr.json');
addLocaleData([...en, ...fr]);

interface IStates {
  locale: string;
  isAdmin: boolean;
  isAdminPending: boolean;
  canExport: boolean;
  pdfProgression: number;
}

class Frame extends React.Component<any, IStates> {

  i18nConfig = {
    locale: 'EN',
    messages: messagesEn,
  };

  private isMount = false;

  public constructor(props: any) {
    super(props);
    this.state = {
      locale: 'EN',
      isAdmin: false,
      isAdminPending: true,
      canExport: ExportStore.getCanExport(),
      pdfProgression: 0,
    };
    this.onChangeLanguage = this.onChangeLanguage.bind(this);
  }

  public componentWillMount() {
    this.isMount = true;
    UserStore.addListener(ActionTypes.CHANGE_LANGUAGE.toString(), this.onChangeLanguage);
    ExportStore.addListener(ActionTypes.CAN_EXPORT.toString(), this.setCanExport);
    ExportStore.addListener(ActionTypes.PDF_PROGRESSION_UPDATE.toString(), this.setPdfProgression);

    const user = AuthStore.getConnectedUser();
    if (!!user) {
      UserStore.setLanguage(user.language);
    }
    AuthStore.addLogoutListener(() => {
      this.props.history.push('/');
    });

    UserAPI.isAdmin()
      .then(() => this.setState({ isAdmin: true, isAdminPending: false }))
      .catch(() => this.setState({ isAdmin: false, isAdminPending: false }));

    UserActions.emitGetCompletedGuides();
  }

  private setPdfProgression = () => {
    if (this.isMount) {
      this.setState({ pdfProgression: ExportStore.getPdfGeneratingProgression() });
    }
  };

  public componentWillUnmount(): void {
    this.isMount = false;
    UserStore.removeListener(ActionTypes.CHANGE_LANGUAGE.toString(), this.onChangeLanguage);
    ExportStore.removeListener(ActionTypes.CAN_EXPORT.toString(), this.setCanExport);
    ExportStore.removeListener(ActionTypes.PDF_PROGRESSION_UPDATE.toString(), this.setPdfProgression);
  }

  private setCanExport = () => {
    if (this.isMount) {
      this.setState({ canExport: ExportStore.getCanExport(), pdfProgression: 0 });
    }
  };

  /**
   * Change language function
   */
  private readonly onChangeLanguage = (): void => {
    const lang = Utils.getCurrentUserLanguageName();
    this.i18nConfig.messages = lang === 'FR' ? messagesFr : messagesEn;
    this.setState({ locale: lang });
    this.i18nConfig.locale = lang;
  };

  /**
   * workaround to manage news feed bug
   */
  private emptyComponent = () => {
    return null;
  };

  public render() {
    const path = this.props.match.path;

    return (
        <IntlProvider locale={this.i18nConfig.locale} messages={this.i18nConfig.messages}>
          <div>
            <TopBar />
            <Toast />
            <Segment id="pdf-notification" className={this.state.canExport ? '' : 'visible'}>
              <Icon circular={true} inverted={true} name="info" />
              <div id="message">
                <FormattedMessage
                  id="exportingPDFMessage"
                  defaultMessage="Export generation in progress..."
                />
              </div>
              { this.state.pdfProgression !== 0 &&
              <Progress
                id="progress-bar"
                percent={this.state.pdfProgression}
                progress={true}
                indicating={true}
                size="small"
              />}
            </Segment>

            <Switch>
              <Route
                exact={true}
                path={`${path}profile`}
                render={() => <Profile />}
              />
              <Route
                path={`${path}pp-history/:ppId/:historyId`}
                component={PpHistoryExternal}
              />
              <Route
                path={`${path}bc-history/:bcId/:historyId`}
                component={BcHistoryExternal}
              />
              <Route
                path={`${path}pi-history/:piId/:historyId`}
                component={PiHistoryExternal}
              />
              <Route
                path={`${path}pp-external/:ppId`}
                component={PpHistoryExternal}
              />
              <Route
                path={`${path}bc-external/:bcId`}
                component={BcHistoryExternal}
              />
              <Route
                path={`${path}pi-external/:piId`}
                component={PiHistoryExternal}
              />
              <Route
                path="empty"
                component={this.emptyComponent}
              />
              <Route
                exact={true}
                path={`${path}error`}
                render={() => <Error/>}
              />
              <Route
                exact={true}
                path={`${path}info`}
                render={() => <Info />}
              />
              {this.state.isAdmin
                && <Route
                  exact={true}
                  path={`${path}admin`}
                  render={() => <AdministrationBoard />}
                />
              }
              <Route
                path={`${path}explore`}
                render={() => <ExploreBoard />}
              />
              <Route
                exact={true}
                path={`${path}search`}
                render={() => <SearchResult />}
              />
              <Route
                exact={true}
                path={`${path}user-guide`}
                render={() => <UserGuide isVisitor={false} />}
              />
              <Route
                path={`${path}`}
                component={Main}
              />
            </Switch>
          </div>
        </IntlProvider>
    );
  }
}

export default withRouter(Frame);
