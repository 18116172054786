/* Copyright (C) Thales Services, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Thales Services, 2020
 */

// This one is the action linked to a milestone
// so the pimiId is mandatory
export default class LinkedMilestone {
  public pimiId: number;
  public atId?: number;
}

// This one is the action linked to a milestone
// so the at_id is mandatory
export class LinkedAction {
  public pimiId?: number;
  public atId: number;
}
