import { AtTimelineElement, TimelineElement } from '../../../../../../../../../constants/piTimeline';
import Utils from '../../../../../../../../../utils/utils';
import UserAvatar from '../../../../../../../../common/userAvatar/userAvatar';
import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import './rowPopupContent.scss';

interface IProps {
  timelineElement: TimelineElement;
}

const rowPopupContent = (props: IProps) => {
  const at = props.timelineElement as AtTimelineElement;
  const isAt = Utils.isActivityAt(props.timelineElement.type);
  const account = at.assignedAccounts && at.assignedAccounts.length
    ? Utils.sortArrayByKey(at.assignedAccounts, 'lastName')[0] : null;
  const activityName = Utils.formatActivityName(props.timelineElement.code, props.timelineElement.name,
                                                props.timelineElement.type,
                                                (props.timelineElement as AtTimelineElement).piMilestoneCode);
  const separator = ': ';
  return (
    <div id="pi-timeline-popup-content">
      <div className="activity-title">{isAt && Utils.setBadge(at.status, at.isClosed)}{activityName}</div>
      { isAt &&
      <div className="activity-assignees">
        {account && <UserAvatar key={account.id} account={account} />}
      </div>
      }
      <div className="activity-content">
        {isAt &&
        <div>
          <FormattedMessage id="startingDate" defaultMessage="Starting date"/>
          {separator}{Utils.showDateOrNone(at.startingDate)}
        </div>
        }
        <div>
          <FormattedMessage id="targetDate" defaultMessage="Target date"/>
          {separator}{Utils.showDateOrNone(props.timelineElement.targetDate)}
        </div>
        <div>
          <FormattedMessage id="dateOfCompletion" defaultMessage="Completion date"/>
          {separator}{Utils.showDateOrNone(props.timelineElement.completionDate)}
        </div>
        {isAt &&
        <div>
          <FormattedMessage id="forecastDate" defaultMessage="Forecast date"/>
          {separator}{Utils.showDateOrNone(at.forecastDate)}
        </div>
        }
      </div>
    </div>
  );
};
export default rowPopupContent;
