import './codeInput.scss';

import * as React from 'react';
import { Form } from 'semantic-ui-react';

interface IProps {
  defaultValue: number | undefined;
  isCodeOnError: boolean;
  label?: string;
  emitChange: (idAttribute: string, { value }: any) => void;
}

class CodeInput extends React.Component<IProps> {

  private handleChange = (event: any) => {
    if (event.target.validity.valid) {
      this.props.emitChange(event, { value: event.target.value });
    } else if (event.target.value === '') {
      // Input type="number" gives an incorrect value as empty string, but keeps displaying this value
      // Workaround to force input's displayed value to only number OR empty value
      const input = document.getElementById('code') as HTMLInputElement;
      if (!!input) {
        if (this.props.defaultValue) {
          input.value = this.props.defaultValue.toString().replace(/\D/g, '');
        } else {
          input.value = '';
        }
      }
      this.props.emitChange(event, { value: event.target.value });
    }
  };

  private isCodeOnError = ():boolean => {
    return (this.props.defaultValue === undefined
      || this.props.isCodeOnError
      || this.props.defaultValue!.toString() === ''
    );
  };

  public render() {
    return (
      <Form.Input
        id="code"
        type="number"
        pattern="[0-9]*"
        min="1"
        label={this.props.label}
        error={this.isCodeOnError()}
        value={this.props.defaultValue}
        onInput={this.handleChange}
      />
    );
  }
}

export default CodeInput;
