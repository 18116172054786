import * as React from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';

import Utils from 'src/utils/utils';

interface IProps {
  defaultValue: number;
  dropdownName: string;
  options: any;
  isOptional?: boolean;
  isValueNullable?: boolean;
  isError?:boolean;
  emitChange: (idAttribute: string, value: any, text?: any) => void;
}

interface IStates { }

class ModificationDropdown extends React.Component<IProps, IStates> {

  private generateOptionList(): DropdownItemProps[] {
    const options: DropdownItemProps[] = [];

    // If none value is not accepted
    if (!this.props.isValueNullable) {
      const isOptional = (!!this.props.isOptional && this.props.isOptional) ||
      this.props.options.some(myObj => myObj.name === 'None');

      if (isOptional) {
        options.push({ key: 1, text: 'None', value: 1 });
      }
    } else {
      // If none value is accepted set disabled
      const noneValue = this.props.options.find(myObj => myObj.name === 'None');
      if (noneValue) {
        options.push({
          key: 1,
          text: 'None',
          value: 1,
          disabled: noneValue.isDeprecated,
        });
      }
    }

    const optionsFiltered = Utils.sortArrayByKey(this.props.options, 'name').filter(myObj => myObj.name !== 'None');
    optionsFiltered.forEach((option) => {
      options.push({
        key: option.id,
        text: option.name,
        value: option.id,
        disabled: option.isDeprecated !== undefined && option.isDeprecated,
      });
    });

    return options;
  }

  public render() {
    if (this.props.options && this.props.options.length > 0) {
      const options = this.generateOptionList();
      return (
        <Dropdown
          fluid={true}
          selection={true}
          options={options}
          onChange={(event, data) => this.props.emitChange(this.props.dropdownName, data.value)}
          error={this.props.isError}
          defaultValue={this.props.defaultValue}
        />
      );
    }

    return <div>None</div>;
  }
}

export default ModificationDropdown;
