import './contentPP.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { Menu, Icon } from 'semantic-ui-react';
import TypeError from '../../../../constants/errors/typeError';
import ActivitiesActions from 'src/actions/activities.action';
import PerformancePlanActions from 'src/actions/performancePlan.action';
import ConfirmModal from 'src/components/activitiesBoard/containerActivities/confirmModal/confirmModal';
import TitleContainer from 'src/components/activitiesBoard/containerActivities/titleContainer/titleContainer';
import ActionTypes from 'src/constants/actionTypes';
import ModeTypes from 'src/constants/modeTypes';
import TypeActivity from 'src/constants/typeActivity';
import ActivitiesStore from 'src/stores/activities.store';
import ErrorStore from 'src/stores/error.store';
import Utils from 'src/utils/utils';
import DatesContainer from '../datesContainer/datesContainer';
import Cockpit from './cockpit/cockpit';
import MoreInfo from './moreInfo/moreInfo';
import ReportingTab from './reporting/reporting';
import DueDateWarningBanner from './dueDateWarningBanner/dueDateWarningBanner';
import ReportingAPI from 'src/api/reporting.api';
import ReportingGet from 'src/models/reporting';
import NotificationActions from 'src/actions/notification-actions';
import { ToastType } from 'src/components/common/toast/toast';
import EditingDraftBanner from './editingDraftBanner/editingDraftBanner';
import CustomScrollBars from '../../../common/customScrollBars/customScrollBars';
import OverviewPanel from '../common/overviewPanel/overviewPanel';
import PerformanceInitiative from 'src/models/performanceInitiative';
import BusinessChallenge from 'src/models/businessChallenge';
import PerformancePlanAPI from 'src/api/performancePlan.api';
import RightsActions from 'src/actions/rights.action';
import { RightsOnActivityDTO, RightsOnPP } from 'src/models/rights';
import RightsStore from 'src/stores/rights.store';
import PerformancePlanStore from 'src/stores/performancePlan.store';

export enum PpTabs {
  COCKPIT = 'cockpit',
  REPORTING = 'reporting',
  MORE_INFO = 'more-info',
}

interface IRouteProps {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  setIsDraft(isDraft: boolean): void;
}

interface IStates {
  rightsOnPP: RightsOnPP | undefined;
  mode: ModeTypes;
  error?: Error | TypeError;
  hasPPChanged: boolean;
  hiddenBanner: boolean;
  reportDueDateExpired?: ReportingGet | null;
  isReportingEditing: boolean;
  overviewPanel: {
    isOpen: boolean;
    type: TypeActivity | undefined;
    activity: PerformanceInitiative | BusinessChallenge | undefined;
  };
  isDraft: boolean;
  lastUpdate: Date | undefined;
  publishedDate: Date | undefined;
  contentHeight: number;
}

class ContentPP extends React.Component<IProps, IStates> {

  private isMount = false;

  //region REACT LIFECYCLE METHODS
  public constructor(props: IProps) {
    super(props);

    this.state = {
      rightsOnPP: undefined,
      error: ErrorStore.getError(),
      mode: ActivitiesStore.getMode(),
      hiddenBanner: true,
      hasPPChanged: false,
      reportDueDateExpired: undefined,
      isReportingEditing: false,
      overviewPanel: {
        isOpen: false,
        type: TypeActivity.PERFORMANCE_PLAN,
        activity: undefined,
      },
      isDraft: false,
      lastUpdate: undefined,
      publishedDate: undefined,
      contentHeight: 0,
    };
  }

  public componentDidMount() {
    this.isMount = true;
    ActivitiesStore.addListener(ActionTypes.ACTIVITIES_GET_MODE.toString(), this.getMode);
    PerformancePlanStore.addListener(ActionTypes.VALIDATE_DRAFT.toString(), this.getPpIsDraft);
    RightsStore.addListener(ActionTypes.SET_PP_RIGHTS.toString(), this.setRightsOnPp);
    ErrorStore.addListener(ActionTypes.ERROR_CHANGE.toString(), this.getError);
    ErrorStore.addListener(ActionTypes.ERROR_DELETED.toString(), this.getError);
    ActivitiesActions.emitGetActivitySelect(TypeActivity.PERFORMANCE_PLAN);
    this.getAssociatedPpData();
    this.calculateContentSize();
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>, snapshot?: any): void {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setState(
        { hasPPChanged: true },
        () => {
          this.getAssociatedPpData();
        },
      );
    }
    this.calculateContentSize();
  }

  public componentWillUnmount() {
    this.isMount = false;
    RightsStore.removeListener(ActionTypes.SET_PP_RIGHTS.toString(), this.setRightsOnPp);
    ActivitiesStore.removeListener(ActionTypes.ACTIVITIES_GET_MODE.toString(), this.getMode);
    PerformancePlanStore.removeListener(ActionTypes.VALIDATE_DRAFT.toString(), this.getPpIsDraft);
    ErrorStore.removeListener(ActionTypes.ERROR_SET.toString(), this.getError);
    ErrorStore.removeListener(ActionTypes.ERROR_DELETED.toString(), this.getError);
  }
  private setIsDraftPp = () => {
    this.props.setIsDraft(this.state.isDraft);
  }
  private getAssociatedPpData = () => {
    this.getPpRights();
    this.getPpIsDraft();
    this.getPpDates();
  }

  private getPpIsDraft = async () => {
    const pp = await PerformancePlanAPI.getPpIsDraft(+this.props.match.params.id);
    this.setState({
      isDraft: pp.isDraft,
    });
    this.setIsDraftPp();
  }

  private getPpDates = async () => {
    const pp = await PerformancePlanAPI.getPpDates(+this.props.match.params.id);
    this.setState({
      publishedDate: pp.publishedDate,
      lastUpdate: pp.lastUpdate,
    });
  }

  private getPpRights = async () => {
    const rights = await PerformancePlanAPI.getPerformancePlanRightsById(+this.props.match.params.id);
    RightsActions.emitSetPpRights(rights);
    this.navigateByRights(rights);
  }

  private setRightsOnPp = () => {
    this.setState({
      rightsOnPP: RightsStore.getRightsOnPP(),
    });
  }

  private navigateByRights = (rights: RightsOnActivityDTO) => {
    const rightsOnPp = new RightsOnPP(rights);
    if (rightsOnPp.canViewCockpit()) {
      this.navigateToTab(PpTabs.COCKPIT);
    } else {
      this.navigateToTab(PpTabs.MORE_INFO);
    }

    if (rightsOnPp.canViewReporting()) {
      PerformancePlanActions.emitGetPerformancePlanReportings(+this.props.match.params.id);
      this.getReportingDue();
    }
  }

  /**
   * Set activity to send it to overview panel
   * @param {PerformanceInitiative | BusinessChallenge} activity
   * @param {TypeActivity} type
   */
  private setOverviewPanelActivity = (activity: PerformanceInitiative | BusinessChallenge, type: TypeActivity) => {
    this.setState({
      overviewPanel: {
        activity,
        type,
        isOpen: true,
      },
    });
  }
  //endregion

  //region LISTENER RELATED METHODS
  private getMode = () => {
    if (this.isMount) {
      this.setState({ mode: ActivitiesStore.getMode() });
    }
  };

  private getError = () => {
    this.setState({ error: ErrorStore.getError() });
  };

  private getReportingDue = () => {
    // Collect reports that due date is expired & not postpone
    ReportingAPI.getReportingsDueFromPerformancePlan(+this.props.match.params.id)
        .then((reporting: ReportingGet) => {
          // Put it in the state
          this.setState({
            reportDueDateExpired: reporting,
            hiddenBanner: !reporting,
          });
        })
        .catch(() => {
          this.setState({
            reportDueDateExpired: undefined,
            hiddenBanner: true,
          });
        });
  };
  //endregion

  //region CLASS METHODS
  private navigateToTab = (tab: PpTabs) => {
    if (this.state.isReportingEditing) {
      this.setState({ mode: ModeTypes.MODE_CANCEL });
      return;
    }
    this.props.history.push(`${this.props.match.url}/${tab}`);
  };

  /**
   * Confirm deletion action
   */
  private confirmDeletionCancel = (): void => {
    ActivitiesActions.emitChangeMode(ModeTypes.MODE_VIEW);
    PerformancePlanActions.emitRemovePerformancePlan(+this.props.match.params.id);
  };

  /**
   * UnConfirmed deletion action
   */
  private noConfirmDeletionCancel = (): void => {
    ActivitiesActions.emitChangeMode(ModeTypes.MODE_VIEW);
  };

  public postpone = () => {
    if (this.state.reportDueDateExpired) {
      ReportingAPI.postponeReporting(this.state.reportDueDateExpired.id).then(() => {
        NotificationActions.toast(
          <FormattedMessage id="success" defaultMessage="Success" />,
          <FormattedMessage id="reporting.postponeMessage" defaultMessage="The Reporting has been postponed"/>,
          ToastType.SUCCESS,
        );
        this.setState(
          { hiddenBanner: true },
          () => {
            // Add timeout to give time to banner's display transition to operate
            setTimeout(this.getReportingDue, 300);
          },
        );
      });
    }
  };

  public seeReport = () => {
    if (this.state.reportDueDateExpired) {
      const reportingPath = `/activities-board/performance-plan/${+this.props.match.params.id}/${PpTabs.REPORTING}`;
      this.props.history.push(`${reportingPath}/${this.state.reportDueDateExpired.id}`);
    }
  };

  public setReportingEdition = (isEditMode: boolean) => {
    this.setState({
      isReportingEditing: isEditMode,
    });
  }

  public calculateContentSize = () => {
    const containerHeight = Utils.calculateContentSize();
    if (this.state.contentHeight !== containerHeight) {
      this.setState({ contentHeight: containerHeight });
    }
  }
  //endregion

  //region PANEL
  private togglePanelVisibility = () => {
    this.setState(prevState => ({
      overviewPanel: {
        ...prevState.overviewPanel,
        isOpen: !prevState.overviewPanel.isOpen,
        activity: !prevState.overviewPanel.isOpen ? undefined : prevState.overviewPanel.activity,
        type: TypeActivity.PERFORMANCE_PLAN,
      },
    }));
  };
  //endregion

  public render() {
    if (this.state.error !== undefined) {
      if ((this.state.error as TypeError).reqStatus === 403) {
        return Utils.empty(
        <FormattedMessage id="noRights" defaultMessage="You do not have the rights to see this Performance Plan" />,
        {},
        <Icon className={'empty'} name={'lock'} />);
      }
      return Utils.empty(<FormattedMessage id="noPP" defaultMessage="No Performance Plan was found" />);
    }

    const confirmDeletionTitle = <FormattedMessage id="confirmDeletion" defaultMessage="Confirm deletion" />;
    const confirmDeletionMessage = <FormattedMessage id="confirmDeletionQuestion" defaultMessage="Confirm deletion?" />;

    return (
      <div id="performance-plan-container">
        <OverviewPanel
          isOverviewPanelOpen={this.state.overviewPanel.isOpen}
          activity={this.state.overviewPanel.activity ?? { id: this.props.match.params.id }}
          typeActivity={this.state.overviewPanel.type}
          togglePanelVisibility={this.togglePanelVisibility}
          isRelativeView={false}
          containerHeight={this.state.contentHeight}
        />

        {this.state.isDraft &&
        <EditingDraftBanner
          activityType={TypeActivity.PERFORMANCE_PLAN}
          triggerSizeCalculation={this.calculateContentSize}
        />
        }

        {this.state.reportDueDateExpired && !Utils.isOnEditMode(this.state.mode)
          && <DueDateWarningBanner
            postPone={this.postpone}
            hidden={this.state.hiddenBanner}
            seeReport={this.seeReport}
            reporting={this.state.reportDueDateExpired}
            triggerSizeCalculation={this.calculateContentSize}
          />
        }

        {Utils.isOnDeleteMode(this.state.mode)
          && <ConfirmModal
            closeNoFunction={this.noConfirmDeletionCancel}
            closeYesFunction={this.confirmDeletionCancel}
            title={confirmDeletionTitle}
            message={confirmDeletionMessage}
          />
        }

        <TitleContainer
          type={TypeActivity.PERFORMANCE_PLAN}
          triggerSizeCalculation={this.calculateContentSize}
        />
        <Menu id="main-tabs-menu" className="tab-menu-container" pointing={true} secondary={true}>
          {this.state.rightsOnPP?.canViewCockpit()
            && <Menu.Item
              active={this.props.location.pathname.indexOf(PpTabs.COCKPIT) > -1}
              onClick={() => this.navigateToTab(PpTabs.COCKPIT)}
            >
              <FormattedMessage id="pp.cockpit" defaultMessage="Cockpit" />
            </Menu.Item>
          }

          {this.state.rightsOnPP?.canViewReporting()
            && <Menu.Item
              active={this.props.location.pathname.indexOf(PpTabs.REPORTING) > -1}
              onClick={() => this.navigateToTab(PpTabs.REPORTING)}
            >
            <FormattedMessage id="pp.reporting" defaultMessage="Reporting" />
            </Menu.Item>
          }

          <Menu.Item
            active={this.props.location.pathname.indexOf(PpTabs.MORE_INFO) > -1}
            onClick={() => this.navigateToTab(PpTabs.MORE_INFO)}
          >
            <FormattedMessage id="moreInformation" defaultMessage="More information" />
          </Menu.Item>

          <DatesContainer
            isOverviewPanelOpen={this.state.overviewPanel.isOpen}
            togglePanelVisibility={this.togglePanelVisibility}
            ppPublishedDate={this.state.publishedDate}
          />
        </Menu>
        <div
          id="content-container"
          className="scrollable-container content-container"
          style={{ height: `${this.state.contentHeight}px` }}
        >
          <CustomScrollBars>
            <Switch>
              {this.state.rightsOnPP?.canViewCockpit()
                && <Route
                  path={`${this.props.match.path}/${PpTabs.COCKPIT}`}
                  render={() => <Cockpit
                    mode={this.state.mode}
                    rightsOnPP={this.state.rightsOnPP}
                    setOverviewPanelActivity={this.setOverviewPanelActivity}
                  />}
                />
              }
              {this.state.rightsOnPP?.canViewReporting()
                && <Route
                  path={`${this.props.match.path}/${PpTabs.REPORTING}/:reportingId?`}
                  render={() =>
                    <ReportingTab
                      ppId={+this.props.match.params.id}
                      empty={true}
                      onReportingUpdate={this.getReportingDue}
                      isReportingEditing={this.state.isReportingEditing}
                      setReportingEdition={this.setReportingEdition}
                    />}
                />
              }
              <Route
                path={`${this.props.match.path}/${PpTabs.MORE_INFO}`}
                render={() => <MoreInfo mode={this.state.mode} rightsOnPP={this.state.rightsOnPP} />}
              />
            </Switch>
          </CustomScrollBars>
        </div>
      </div>
    );
  }
}

export default withRouter(ContentPP);
