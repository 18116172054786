import Milestone from '../models/milestone';

export const defaultNewMilestone = {
  id: null,
  name: '',
  targetDate: new Date(),
  completionDate: undefined,
  isHidden: false,
} as Milestone;

export interface AllBcMilestones {
  bcMilestones: Milestone[];
  piLinkedMilestones: Milestone[];
}
