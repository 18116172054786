import './textarea.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { TextArea } from 'semantic-ui-react';

import CustomScrollBars from '../../customScrollBars/customScrollBars';

interface IProps {
  className?: string;
  value: string;
  maxlength: number;
  placeholder?: string;
  maxLinesNumber?: number;
  fixedHeight?: boolean;
  height?: number;

  onChange(e: any, data: any): void;
}

export default class Textarea extends React.Component<IProps> {

  private keyPressed: number | undefined;
  private readonly MAX_LINES_NB: number = !!this.props.maxLinesNumber ? this.props.maxLinesNumber : 18;

  public constructor(props: IProps) {
    super(props);
  }

  private onChange = (e: any, data: any) => {
    if (data.value.split('\n').length <= this.MAX_LINES_NB ||
      this.keyPressed === 8 ||
      this.keyPressed === 46) {
      this.props.onChange(e, data);
      this.keyPressed = undefined;
    }
  };

  private onKeyDown = (e: any) => {
    this.keyPressed = e.keyCode;
  };

  public render() {
    const linesNumber = this.props.value.split('\n').length;

    const style = {
      height: !!this.props.fixedHeight
        ? this.props.height ? this.props.height : 200
        : '100%',
    };

    return (
      <div className="textarea-container">
        <CustomScrollBars className="textarea-scrollbar" style={style}>
          <TextArea
            autoHeight={true}
            className={this.props.className ? this.props.className : ''}
            placeholder={this.props.placeholder ? this.props.placeholder : ''}
            value={this.props.value}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            maxLength={this.props.maxlength}
          />
        </CustomScrollBars>

        <div className="counters-container">
          {linesNumber > 1
            && <span className={`counters${linesNumber >= this.MAX_LINES_NB ? ' length-at-max' : ''}`}>
              {`${linesNumber}/${this.MAX_LINES_NB}`}&nbsp;
            <FormattedMessage id="lines" defaultMessage="lines" />
            </span>
          }

          {this.props.value
            && <span className={`counters${this.props.value.length >= this.props.maxlength ? ' length-at-max' : ''}`}>
              {`${this.props.value.length}/${this.props.maxlength}`}&nbsp;
              <FormattedMessage id="characters" defaultMessage="characters" />
            </span>
          }
        </div>
      </div>
    );
  }
}
