import ActivitiesActions from 'src/actions/activities.action';
import ModeTypes from 'src/constants/modeTypes';
import ActivityStore from 'src/stores/activities.store';
import BusinessChallengeStore from 'src/stores/businessChallenge.store';
import PerformanceInitiativeStore from 'src/stores/performanceInitiative.store';
import AuthActions from '../actions/auth.actions';
import HttpClient from '../utils/httpClient';

export default class AuthService {

  /**
   * Change the user password
   * @param {string} password
   * @returns {any}
   */
  public static changePassword(password: string) {
    return HttpClient.post('auth/change-password', { password });
  }

  public static async logout() {
    // Flush Activities in stores
    BusinessChallengeStore.invalidateBusinessChallenge();
    PerformanceInitiativeStore.invalidatePerformanceInitiative();

    if (ActivityStore.getMode() === ModeTypes.MODE_EDITION) {
      ActivitiesActions.emitChangeMode(ModeTypes.MODE_VIEW);
    }

    await AuthActions.logout();
  }
}
