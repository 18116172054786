import HttpClient from 'src/utils/httpClient';
import PerformancePlan from '../models/performancePlan';
import History from 'src/models/history';
import BusinessChallenge from '../models/businessChallenge';
import PerformanceInitiative from '../models/performanceInitiative';

export default class HistoryAPI {

  /**
   * ROUTE : /api/{activityRoute}/{id}/history
   * Get all history versions of a PP/BC/PI
   * @returns Promise<History>
   */
  public static getHistories(activityRoute: string, id: number): Promise<History[]> {
    return HttpClient.get<History[]>(`${activityRoute}/${id}/history-list`);
  }

  // Performance Plan
  /**
   * ROUTE : /api/performance-plans/{id}/history/{historyId}
   * Get the history of a PP by its id and the history id
   * @returns Promise<PerformancePlan>
   */
  public static getPpHistoryById(id: number, historyId: number): Promise<PerformancePlan> {
    return HttpClient.get<PerformancePlan>(`performance-plans/${id}/history/${historyId}`);
  }

  /**
   * ROUTE : /api/performance-plans/{id}/external
   * Get the external view of a PP by its id
   * @returns Promise<PerformancePlan>
   */
  public static getPpExternal(id: number): Promise<PerformancePlan> {
    return HttpClient.get<PerformancePlan>(`performance-plans/${id}/external`);
  }

  // Business Challenge
  /**
   * ROUTE : /api/business-challenges/{id}/history/{historyId}
   * Get the history of a BC by its id and the history id
   * @returns Promise<BusinessChallenge>
   */
  public static getBcHistoryById(id: number, historyId?: number): Promise<BusinessChallenge> {
    return HttpClient.get<BusinessChallenge>(`business-challenges/${id}/history/${historyId}`);
  }

  /**
   * ROUTE : /api/business-challenges/{id}/external
   * Get the external view of a BC by its id
   * @returns Promise<BusinessChallenge>
   */
  public static getBcExternal(id: number): Promise<BusinessChallenge> {
    return HttpClient.get<BusinessChallenge>(`business-challenges/${id}/external`);
  }

  // Performance Initiative
  /**
   * ROUTE : /api/performance-initiatives/{id}/history/{historyId}
   * Get the history of a PI by its id and the history id
   * @returns Promise<PerformanceInitiative>
   */
  public static getPiHistoryById(id: number, historyId?: number): Promise<PerformanceInitiative> {
    return HttpClient.get<PerformanceInitiative>(`performance-initiatives/${id}/history/${historyId}`);
  }

  /**
   * ROUTE : /api/performance-initiatives/{id}/external
   * Get the external view of a PI by its id
   * @returns Promise<BusinessChallenge>
   */
  public static getPiExternal(id: number): Promise<PerformanceInitiative> {
    return HttpClient.get<PerformanceInitiative>(`performance-initiatives/${id}/external`);
  }
}
