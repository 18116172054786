import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';

import PerformancePlanAPI from '../../../api/performancePlan.api';
import BusinessChallenge from '../../../models/businessChallenge';
import BusinessChallengeAPI from 'src/api/businessChallenge.api';
import ActivityImport from './activityImport';
import ActivityImportStep1 from './activityImportStep1';
import ActivityImportStep2 from './activityImportStep2';
import ActivityImportStepFinal from './activityImportStepFinal';
import { PerformancePlanGetForBcImport } from '../../../models/performancePlan';
import { ImportBcParams } from '../../../models/importActivities';
import { BcTabs } from '../../activitiesBoard/containerActivities/contentBC/contentBC';
import TypeActivity from '../../../constants/typeActivity';

interface IProps extends RouteComponentProps {
  close: () => void;
  ppId: number;
}

interface IStates {
  isNavigationDisabled: boolean;
  isListLoading: boolean;

  // Step 1 states
  selectedPp: number;
  isSelectedSimilarPp: boolean | undefined;
  performancePlans: PerformancePlanGetForBcImport[];

  // Step 2 states
  selectedBc: number;
  businessChallenges: BusinessChallenge[];

  // Step 3 states
  importParams: ImportBcParams;
}

class BcImport extends React.Component<IProps & InjectedIntlProps, IStates> {

  private modalTitle = this.props.intl.formatMessage({
    id: 'importModal.bc.title',
    defaultMessage: 'Import a Business Challenge',
  });

  private modalSteps = [
    {
      title: this.props.intl.formatMessage({
        id: 'importModal.steps.pp',
        defaultMessage: 'Performance Plan',
      }),
      subtitle: this.props.intl.formatMessage({
        id: 'importModal.steps.selectPP',
        defaultMessage: 'Performance Plan selection',
      }),
    },
    {
      title: this.props.intl.formatMessage({
        id: 'importModal.steps.bc',
        defaultMessage: 'Business Challenge',
      }),
      subtitle: this.props.intl.formatMessage({
        id: 'importModal.steps.selectBC',
        defaultMessage: 'Business Challenge selection',
      }),
    },
    {
      title: this.props.intl.formatMessage({
        id: 'importModal.steps.importSettings',
        defaultMessage: 'Import settings',
      }),
      subtitle: this.props.intl.formatMessage({
        id: 'importModal.steps.customizeImport',
        defaultMessage: 'Customize the BC import',
      }),
    },
  ];

  public constructor(props: IProps & InjectedIntlProps) {
    super(props);
    this.state = {
      isNavigationDisabled: false,
      isListLoading: true,

      selectedPp: 0,
      isSelectedSimilarPp: undefined,
      performancePlans: [],

      selectedBc: 0,
      businessChallenges: [],

      importParams: {
        keepPI: false,
        keepAt: false,
        keepStakeholdersAssignations: false,
        keepMetrics: false,
        keepMilestones: false,
        keepTextField: false,
        resetStatus: false,
      },
    };
  }

  public componentWillMount() {
    this.setNavigationDisabled(true);
  }

  public componentDidMount() {
    this.getPerformancePlans();
  }

  private setNavigationDisabled = (isNavigationDisabled: boolean) => {
    this.setState({ isNavigationDisabled });
  };

  private closeModal = () => {
    this.setNavigationDisabled(true);
    this.props.close();
  };

  private onStepChange = (stepNumber: number, isNextStep: boolean) => {
    // If coming back to the previous step, reset the selected activity
    if (!isNextStep && stepNumber === 1) {
      this.setDefaultSelectedBc();
    }

    this.isNavigationDisabled(stepNumber);
  };

  private submit = () => {
    let ret;
    if (this.state.importParams) {
      ret = BusinessChallengeAPI.importBc({
        params: this.state.importParams,
        bcId : this.state.selectedBc,
        ppId: this.props.ppId,
        isSimilar: this.state.isSelectedSimilarPp,
      });
    } else {
      ret = Promise.reject();
    }
    return ret;
  };

  private redirectToNewBc = (bcId: number) => {
    this.props.history.push(`/activities-board/${this.props.ppId}/business-challenge/${bcId}/${BcTabs.COCKPIT}`);
  };

  private isNavigationDisabled = (stepNumber: number) => {
    switch (stepNumber) {
      case 1:
        this.setNavigationDisabled(this.state.performancePlans.length === 0);
        break;
      case 2:
        this.setNavigationDisabled(this.state.businessChallenges.length === 0);
        break;

    }
  };

  private setDefaultSelectedBc = () => {
    const pp = this.state.performancePlans.find(pp => pp.id === this.state.selectedPp);
    if (pp === undefined || !pp.businessChallenges.some(bc => bc.id === this.state.selectedBc)) {
      this.setSelectedBc(this.state.businessChallenges.length > 0 ? this.state.businessChallenges[0].id : 0);
    }
  };

  /** STEP 1 METHODS **/
  private getPerformancePlans = () => {
    this.setState(
      { isListLoading: true },
      () => {
        PerformancePlanAPI.getPerformancePlansForBcImport(this.props.ppId).then((performancePlans) => {
          this.setState(
            { performancePlans },
            () => {
              const pp = performancePlans.length > 0 ? performancePlans[0] : null;

              this.setSelectedPp(
                !!pp ? pp.id : 0,
                !!pp ? pp.isSimilarPp : undefined,
                () => this.setState({ isListLoading: false }, () => this.isNavigationDisabled(1)),
              );
            },
          );
        });
      },
    );
  }

  private setSelectedPp = (selectedPp: number, isSelectedSimilarPp: boolean | undefined, callback?: () => void) => {
    const pp = this.state.performancePlans.find(pp => pp.id === selectedPp);
    const businessChallenges = !!pp ? pp.businessChallenges : [];

    this.setState(
      { selectedPp, isSelectedSimilarPp, businessChallenges },
      () => {
        this.setDefaultSelectedBc();
        callback && callback();
      });

    if (isSelectedSimilarPp) {
      this.setState({
        importParams: {
          keepPI: true,
          keepAt: true,
          keepStakeholdersAssignations: true,
          keepMetrics: true,
          keepMilestones: true,
          keepTextField: true,
          resetStatus: false,
        },
      });
    } else {
      this.setState({
        importParams: {
          keepPI: true,
          keepAt: true,
          keepStakeholdersAssignations: false,
          keepMetrics: true,
          keepMilestones: true,
          keepTextField: false,
          resetStatus: false,
        },
      });
    }
  }

  /** STEP 2 METHODS **/
  private setSelectedBc = (selectedBc: number) => {
    this.setState({ selectedBc });
  }

  /** FINAL STEP METHODS **/
  private setChoices = (choices : ImportBcParams) => {
    this.setState({ importParams: choices });
  }

  public render(): JSX.Element {
    return (
      <ActivityImport
        close={this.closeModal}
        title={this.modalTitle}
        steps={this.modalSteps}
        submit={this.submit}
        isNavigationDisabled={this.state.isNavigationDisabled}
        onStepChange={this.onStepChange}
        typeActivity={TypeActivity.BUSINESS_CHALLENGE}
        redirectToNewActivity={this.redirectToNewBc}
      >
        <ActivityImportStep1
          selectedPp={this.state.selectedPp}
          performancePlans={this.state.performancePlans}
          isListLoading={this.state.isListLoading}
          setSelectedPp={this.setSelectedPp}
        />
        <ActivityImportStep2
          selectedBc={this.state.selectedBc}
          businessChallenges={this.state.businessChallenges}
          isListLoading={this.state.isListLoading}
          setSelectedBc={this.setSelectedBc}
        />
        <ActivityImportStepFinal
          isSelectedSimilarPp={!!this.state.isSelectedSimilarPp}
          importParams={this.state.importParams}
          setChoices={this.setChoices}
          typeActivity={TypeActivity.BUSINESS_CHALLENGE}
        />
      </ActivityImport>
    );
  }

}
export default injectIntl(withRouter(BcImport));
