export enum FIELD_IDS {
  ACHIEVEMENTS = 'achievements',
  ISSUES_AND_RISKS = 'issuesAndRisks',
  DECISIONS = 'decisionsToBeMade',
  NEXT_STEPS = 'nextSteps',
}

export enum FIELD_POSITION {
  ACHIEVEMENTS = 0,
  ISSUES_AND_RISKS = 1,
  DECISIONS = 2,
  NEXT_STEPS = 3,
}

export enum CommonField {
  NAME = 'name',
  CODE = 'code',
}

export enum MeMiAtCommonField {
  IS_HIDDEN = 'isHidden',
}

export enum CommonDateField {
  TARGET_DATE =  'targetDate',
  COMPLETION_DATE =  'completionDate',
}

export enum ActionDateField {
  STARTING_DATE = 'startingDate',
  FORECAST_DATE =  'forecastDate',
}
export enum ActionTextField {
  PROGRESS = 'progress',
  OBJECTIVES = 'objectives',
}
