enum RoleNames {
  REFERENT = 1,
  BC_LEADER = 2,
  PI_LEADER = 3,
  ACTION_OWNER = 4,
  INFORMED = 5,
  BASIC_USER = 6,
}

export default RoleNames;
