import { ActionDateField, CommonDateField } from '../../../../../../../constants/fields';
import { FormattedMessage } from 'react-intl';
import { Divider, Icon } from 'semantic-ui-react';
import AttachmentsManagement from '../../../../attachmentsManagement/attachmentsManagement';
import TypeActivity from '../../../../../../../constants/typeActivity';
import * as React from 'react';
import ModeTypes from '../../../../../../../constants/modeTypes';
import { ActionEdit } from '../../../../../../../models/action';
import User from '../../../../../../../models/user';
import './actionTileListDisplay.scss';
import Utils from '../../../../../../../utils/utils';
import ActionStatus from '../../../../../../common/activityElements/actionStatus/actionStatus';
import FavouriteAndHideToggle from '../../../../../../common/activityElements/favouriteToggle/favouriteAndHideToggle';
import ActionEditionButtons from '../../../../../../common/activityElements/actionEditionButtons/actionEditionButtons';
import ActivityTitle from '../../../../../../common/activityElements/activityTitle/activityTitle';
import ActionAssignation from '../../../../../../common/activityElements/actionAssignation/actionAssignation';
import DateField from '../../../../../../common/activityElements/actionDates/dateField/dateField';
import ActionDates from '../../../../../../common/activityElements/actionDates/actionDates';
import ActionFields from '../../../../../../common/activityElements/actionFields/actionFields';
import { IAtFormValid } from '../../../../../../../constants/formValid';
import MilestonesManagement from 'src/components/activitiesBoard/containerActivities/linkedMilestone/milestonesManagement';
import Milestone from 'src/models/milestone';

interface IProps {
  isActionActive: boolean;
  mode: ModeTypes;
  action: ActionEdit;
  isFormValid: IAtFormValid;
  canEditPi: boolean;
  segmentEditionButtonsWidth: number;
  isSaveOrCloseDisabled: boolean;
  activityId: string;
  isEditorOnError: boolean[];
  shouldResetAttachments: boolean;
  shouldResetMilestones: boolean;
  milestones: Milestone[] | null;

  setStatus(status: number, action: ActionEdit): void;
  handleChange(key: string, value: string | User[] | number | boolean): void;
  handleChangeEvent(key: string, value: string | User[] | number | boolean, event): void;
  toggleIsFavourite(): void;
  toggleCloseAction(action: ActionEdit): void;
  changeMode(mode: ModeTypes): void;
  cancelAction(action: ActionEdit): void;
  toggleToEdit(action: ActionEdit): void;
  updateAction(): Promise<void>;
  closeAction(): void;
  removeAction(atId: number | null): void;
  setActiveAction(atId: number | null): void;
  handleDateChange({ name, value }: any, event: any): void;
  handleErrorOnEditor(isOnError: boolean[], callback: Function): void;
  setAreAttachmentsValid(areAttachmentsValid: boolean): void;
  setAttachmentsResetDone(): void;
  setMilestonesResetDone(): void;
}

const actionTileListDisplay = (props: IProps) => {

  const isAnyViewMoreElementOnErr = () => {
    const { isFormValid } = props;
    return Utils.isOnEditMode(props.mode) && !(isFormValid.isTargetDateValid && isFormValid.isForecastDateValid
      && isFormValid.isStartingDateValid && isFormValid.isCompletionDateValid
      && (!props.isEditorOnError.some(v => v)));
  };

  return (
    <>
      <div
        className={`header-container-action
          ${Utils.isOnEditMode(props.mode) ? 'editing' : ''}
        `}
      >
        <div className="title-container">
          <ActionStatus
            mode={props.mode}
            setStatus={props.setStatus}
            action={props.action}
            isHorizontal={false}
          />
          <span className="title">
              <ActivityTitle
                mode={props.mode}
                handleChange={props.handleChangeEvent}
                isFormValid={props.isFormValid}
                activity={props.action}
                type={TypeActivity.ACTION}
              />
              <FavouriteAndHideToggle
                action={props.action}
                toggleIsFavourite={props.toggleIsFavourite}
                handleChange={props.handleChange}
                mode={props.mode}
                canEditPi={props.canEditPi}
              />
            </span>
        </div>
        <div className="header-right-sub-container">
          <ActionEditionButtons
            mode={props.mode}
            rights={props.action.rights}
            isClosed={props.action.isClosed}
            atId={props.action.id}
            isFormValid={props.isFormValid}
            segmentEditionButtonsWidth={props.segmentEditionButtonsWidth}
            isSaveOrCloseDisabled={props.isSaveOrCloseDisabled}
            toggleCloseAction={() => props.toggleCloseAction(props.action)}
            changeMode={props.changeMode}
            cancelAction={() => props.cancelAction(props.action)}
            toggleToEdit={() => props.toggleToEdit(props.action)}
            updateAction={props.updateAction}
            removeAction={() => props.removeAction(props.action.id)}
            closeAction={props.closeAction}
          />
        </div>
      </div>
      <div className="desc-container">
        <div className="info-element assigned-view-more-row">
          <ActionAssignation
            mode={props.mode}
            actionId={props.action.id}
            assignedAccounts={props.action.assignedAccounts}
            isActionActive={props.isActionActive}
            handleChange={props.handleChange}
          />
          <DateField
            mode={props.mode}
            dateField={props.action.forecastDate ? ActionDateField.FORECAST_DATE : CommonDateField.TARGET_DATE}
            date={props.action.forecastDate ? props.action.forecastDate : props.action.targetDate}
            viewOnly={true}
          />
          {props.isActionActive
            ? <div className="view-more-container" onClick={() => props.setActiveAction(0)}>
                <FormattedMessage id="pp.reporting.viewLess" defaultMessage="View Less"/>
                <Icon name="angle up"/>
              </div>
            : <div
              className={`view-more-container ${isAnyViewMoreElementOnErr() ? 'error' : ''}`}
              onClick={() => props.setActiveAction(props.action.id)}
            >
              {isAnyViewMoreElementOnErr() && Utils.getErrorMessageWithPopup()}
              <FormattedMessage id="pp.reporting.viewMore" defaultMessage="View More"/>
              <Icon name="angle down"/>
            </div>
          }
        </div>
      </div>

      {props.isActionActive &&
        <div className={props.isActionActive ? '' : 'hidden'}>
          <Divider/>
          <ActionDates
            mode={props.mode}
            action={props.action}
            areDatesValid={props.isFormValid}
            handleDateChange={props.handleDateChange}
          />
          <ActionFields
            action={props.action}
            mode={props.mode}
            isEditorOnError={props.isEditorOnError}
            setIsEditorOnError={props.handleErrorOnEditor}
            handleChange={props.handleChange}
          />
          {props.action.id !== null &&
            <div>
              <Divider/>
              <div className="content-container action-content-container">
                <AttachmentsManagement
                  mode={props.mode}
                  canEdit={props.canEditPi}
                  activityId={+props.action.id}
                  activityType={TypeActivity.ACTION}
                  setAreAttachmentsValid={props.setAreAttachmentsValid}
                  shouldResetAttachments={props.shouldResetAttachments}
                  setAttachmentsResetDone={props.setAttachmentsResetDone}
                />
                <MilestonesManagement
                  mode={props.mode}
                  canEdit={props.canEditPi}
                  activityId={+props.activityId}
                  actionId={props.action.id}
                  activityType={TypeActivity.ACTION}
                  milestones={props.milestones}
                  shouldResetMilestones={props.shouldResetMilestones}
                  setMilestonesResetDone={props.setMilestonesResetDone}
                />
              </div>
            </div>
          }
        </div>
      }
    </>
  );
};

export default actionTileListDisplay;
