import RoleNames from '../constants/roleNames';
import Role from './role';
import { AssignationType } from '../constants/account';
import Dispatcher from '../dispatcher';
import ActionTypes from '../constants/actionTypes';

export default class User {

  public id: number;
  public firstName: string;
  public lastName: string;
  public tgi?: string;
  public email: string;
  public location: string;
  public entity: string;
  public phone: string;
  public isCoreTeam: boolean;
  public role: Role;
  public roleId: RoleNames;
  public language: number;
  public isFirstConnection: boolean;
  public isConflict: boolean;
  public lastConnection: Date;
  public gbu: any;
  public country: any;
  public hasAvatar: boolean;
  public defaultPpId: number | null;
  public isInvited: boolean;
  public isNewVersionModalOpened: boolean;
}

export class UserPost {

  public firstName: string;
  public lastName: string;
  public tgi?: string;
  public email: string;
  public location?: string;
  public entity?: string;
  public phone?: string;
  public isCoreTeam: boolean;
  public roleId: RoleNames;
  public language?: number;
  public isFirstConnection?: boolean;
  public defaultPpId?: number | null;
}

export class UsersAssignation {
  public ids: number[] = [];
  public emails: string[] = [];
  public assignationType: AssignationType;

  constructor(users: User[], assignationType: AssignationType) {
    users.forEach((user) => {
      if (user.isInvited) {
        this.emails.push(user.email);
      } else {
        this.ids.push(user.id);
      }
    });

    this.assignationType = assignationType;
  }
}

export interface AssignedUser {
  id: number;
  assignationType: AssignationType;
  activityId: number;
  email: string;
}

export class AssignedUsers {
  public users: AssignedUser[];

  constructor() {
    this.users = [];
  }

  addUsersIfNotExist(userList: AssignedUser[]) {
    userList.forEach(user => !this.users.some(el => el.id === user.id) && this.users.push(user));
  }

  dispatchAssignedUsers() {
    if (this.users.length > 0) {
      Dispatcher.dispatch({ invitedUsers: this.users, actionType: ActionTypes.INVITED_USERS });
    }
  }
}

export class AssignationForEmailDTO {
  public id: number;
  public activityId: number;
  public assignationType: AssignationType;

  constructor(user: AssignedUser) {
    this.id = user.id;
    this.assignationType = user.assignationType;
    this.activityId = user.activityId;
  }
}

export class AssignationEmailDTO {
  public assignations: AssignationForEmailDTO[];
  public message: string;

  constructor(assignations: AssignationForEmailDTO[], message = '') {
    this.assignations = assignations;
    this.message = message;
  }
}

export class SendTeamMailAccountDTO {
  public account: User;
  public isAssigned: boolean;
}
