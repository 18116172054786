import './bcCreation.scss';
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import * as React from 'react';

import { BusinessChallengePost } from 'src/models/businessChallenge';
import Milestone from 'src/models/milestone';
import Utils from 'src/utils/utils';
import Metric from '../../../models/metric';
import ModeTypes from '../../../constants/modeTypes';
import TypeActivity from '../../../constants/typeActivity';
import MetricsTable
  from '../../activitiesBoard/containerActivities/metricsMilestones/metrics/metricsTable/metricsTable';
import Milestones from '../../activitiesBoard/containerActivities/metricsMilestones/milestones/milestones';
import { ComparisonType } from '../../../constants/comparisonType';

interface IProps {
  bc: BusinessChallengePost;
  onUpdate(key: string, value: any): void;
  setNavigationDisabled(isNavigationDisabled: boolean): void;
}

interface IStates {
  bc: BusinessChallengePost;
  metric: Metric;
  milestone: Milestone;
  isMetricsFormValid: boolean;
  isMilestonesFormValid: boolean;
}

class BcCreationStep2 extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);

    if (!this.props.bc.metrics) {
      this.props.onUpdate('metrics', []);
    }
    if (!this.props.bc.milestones) {
      this.props.onUpdate('milestones', []);
    }

    this.state = {
      bc: this.props.bc,
      metric: {
        name: '',
        isHidden: false,
        code: 0,
        comparisonType: ComparisonType.NONE,
        comparisonTolerance: 0,
        metricValues: [],
        mostRecentValues: {
          current: 0,
          target: 0,
          hasImage: false,
          updateDate: new Date(),
        },
      },
      milestone: {
        name: '',
        code: 0,
        isHidden: false,
        targetDate: new Date(),
        mode: ModeTypes.MODE_VIEW,
      } as Milestone,
      isMetricsFormValid: true,
      isMilestonesFormValid: true,
    };
    moment.locale(Utils.getCurrentUserLanguageName());
    this.checkForm();
  }

  private checkForm() {
    this.props.setNavigationDisabled(!this.state.isMetricsFormValid || !this.state.isMilestonesFormValid);
  }

  updateSubFormValidation = (isValid: boolean, subForm: string) => {
    if (subForm === 'metrics') {
      this.setState({ isMetricsFormValid: isValid }, this.checkForm);
    } else {
      this.setState({ isMilestonesFormValid: isValid }, this.checkForm);
    }
  }

  public render(): React.ReactNode {
    return (
      <div id="bcCreationStep2">
        <div className="metrics-container">
          <MetricsTable
            mode={ModeTypes.MODE_EDITION}
            selectedMetricIndex={0}
            metrics={this.props.bc.metrics}
            type={TypeActivity.BUSINESS_CHALLENGE}
            forActivityCreation={true}
            onDataChange={this.props.onUpdate}
            updateSubFormValidation={this.updateSubFormValidation}
            handleRowClick={() => {}}
          />
        </div>

        <Milestones
          mode={ModeTypes.MODE_EDITION}
          milestones={this.props.bc.milestones}
          forActivityCreation={true}
          onDataChange={this.props.onUpdate}
          updateSubFormValidation={this.updateSubFormValidation}
        />
      </div>
    );
  }
}

export default BcCreationStep2;
