import './menuExplore.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import ReactSVG from 'react-svg';
import { Icon } from 'semantic-ui-react';
import earth from 'src/images/earth.svg';
import users from 'src/images/users.svg';
import { ExploreTabs } from '../containerExplore/containerExplore';

interface IProps extends RouteComponentProps {

}

interface IStates {

}

class MenuExplore extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
  }

  private clickOnTab = (tab: ExploreTabs) => {
    this.props.history.push(`/explore/${tab}`);
  }

  private isTabActive = (tab: ExploreTabs) => {
    return this.props.location.pathname.indexOf(tab) > -1;
  }

  public render() {
    return (
      <div id="menu-explore">
        <div id="menu-title">
          <FormattedMessage id="menu.exploreTitle" defaultMessage="iBoost EXPLORE" />
        </div>

        <div
          className={`menu-button ${this.isTabActive(ExploreTabs.ORGANISATION) ? 'active' : null}`}
          onClick={() => this.clickOnTab(ExploreTabs.ORGANISATION)}
        >
          <ReactSVG src={earth} />
          <FormattedMessage id="menu.activities" defaultMessage="Organisation" />
        </div>

        <div
          className={`menu-button ${this.isTabActive(ExploreTabs.COMMUNITY) ? 'active' : null}`}
          onClick={() => this.clickOnTab(ExploreTabs.COMMUNITY)}
        >
          <ReactSVG src={users} />
          <FormattedMessage id="menu.people" defaultMessage="iBoost Community" />
        </div>

        <div
          className={`menu-button ${this.isTabActive(ExploreTabs.TOPICS) ? 'active' : null}`}
          onClick={() => this.clickOnTab(ExploreTabs.TOPICS)}
        >
          <Icon id={'svg-icon'} name="bars" />
          <FormattedMessage id="menu.keywords" defaultMessage="Topics" />
        </div>
      </div>
    );
  }
}

export default withRouter(MenuExplore);
