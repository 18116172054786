import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from 'semantic-ui-react';

interface IProps {
  handleDeleteModalClose: any;
  handleDeleteModalAgree: any;
  deleteModalOpen: boolean;
}

class DeleteReportModal extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
  }
  public render() {
    return (
      <Modal
        open={this.props.deleteModalOpen}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={this.props.handleDeleteModalClose}
      >
        <Modal.Header>
          <FormattedMessage id="reporting.deleteModal.title" defaultMessage="Delete Reporting" />
        </Modal.Header>
        <Modal.Content>
          <p>
            <FormattedMessage
              id="reporting.deleteModal.deleteConfirmation"
              defaultMessage="Are you sure you want to delete this Reporting?"
            />
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.handleDeleteModalClose}>
            <FormattedMessage
              id="cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            onClick={this.props.handleDeleteModalAgree}
            positive={true}
          >
             <FormattedMessage id="reporting.deleteModal.delete" defaultMessage="Delete"/>
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default DeleteReportModal;
