import './cockpit.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dropdown, Icon, Segment } from 'semantic-ui-react';

import BcCreation from 'src/components/creation/bcCreation/bcCreation';
import ActionTypes from 'src/constants/actionTypes';
import ModeTypes from 'src/constants/modeTypes';
import PerformancePlan from 'src/models/performancePlan';
import User from 'src/models/user';
import AuthStore from 'src/stores/auth.store';
import BusinessChallengeStore from 'src/stores/businessChallenge.store';
import PerformanceInitiativeStore from 'src/stores/performanceInitiative.store';
import PerformancePlanStore from 'src/stores/performancePlan.store';
import Utils from 'src/utils/utils';
import BusinessChallenge from 'src/models/businessChallenge';
import Filters, { FilterCategory, FilterType } from 'src/components/common/form/filter/filter';
import PublicationLabel from '../../../../common/publicationLabel/publicationLabel';
import BcImport from '../../../../creation/activityImport/bcImport';
import LinkLabel, { LinkLabelMode } from '../../../../common/linkLabel/linkLabel';
import PerformanceInitiative from '../../../../../models/performanceInitiative';
import TypeActivity from 'src/constants/typeActivity';
import PerformancePlanAPI from '../../../../../api/performancePlan.api';
import { RightsOnPP } from 'src/models/rights';

interface IRouteProps {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  mode: ModeTypes;
  rightsOnPP: RightsOnPP | undefined;
  setOverviewPanelActivity: (activity: PerformanceInitiative | BusinessChallenge, type: TypeActivity) => void;
}

interface IStates {
  pp: PerformancePlan | undefined;
  user: User;
  search: BusinessChallenge[];
  showNewBcModal: boolean;
  showImportBcModal: boolean;
  selectedFilter: FilterType;
}

class Cockpit extends React.Component<IProps, IStates> {
  private isMount = false;

  public constructor(props: IProps) {
    super(props);
    const user = AuthStore.getConnectedUser();
    if (!!user) {
      this.state = {
        user,
        pp: undefined,
        search: [],
        showNewBcModal: false,
        showImportBcModal: false,
        selectedFilter: FilterType.NONE,
      };
    }
  }

  public componentDidMount() {
    this.isMount = true;
    BusinessChallengeStore.addListener(ActionTypes.BUSINESS_CHALLENGE_DELETED.toString(), this.reloadCockpit);
    PerformanceInitiativeStore.addListener(ActionTypes.PERFORMANCE_INITIATIVE_DELETED.toString(), this.reloadCockpit);
    PerformancePlanStore.addListener(ActionTypes.BC_LINKED_TO_PP.toString(), this.reloadCockpit);
    this.getPpCockpit();
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>, snapshot?: any): void {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.getPpCockpit();
    }
  }

  private getPpCockpit = () => {
    PerformancePlanAPI.getPerformancePlanCockpitById(+this.props.match.params.id)
      .then(pp => this.setState({ pp, search: this.mergeBcLinked(pp) }));
  }

  public componentWillReceiveProps(nProps: IProps) {
    if (this.state.pp !== undefined) {
      this.setState({
        search: this.mergeBcLinked(this.state.pp),
        selectedFilter: FilterType.NONE,
      });
    }
  }

  public componentWillUnmount() {
    this.isMount = false;
    BusinessChallengeStore.removeListener(ActionTypes.BUSINESS_CHALLENGE_DELETED.toString(), this.reloadCockpit);
    PerformanceInitiativeStore
      .removeListener(ActionTypes.PERFORMANCE_INITIATIVE_DELETED.toString(), this.reloadCockpit);
    PerformancePlanStore.removeListener(ActionTypes.BC_LINKED_TO_PP.toString(), this.reloadCockpit);
  }

  private reloadCockpit = () => {
    if (this.isMount) {
      this.getPpCockpit();
    }
  }

  public mergeBcLinked(pp: PerformancePlan): BusinessChallenge[] {
    const sortedOriginalBcs = pp.businessChallenges;
    return pp.linkedBcs && sortedOriginalBcs
      ? sortedOriginalBcs.concat(pp.linkedBcs.map(el => ({ ...el, isLinked: true })))
      : sortedOriginalBcs;
  }

  /**
   * Handle new BC creation modal
   */
  public onNewBcClick = () => {
    this.setState({ showNewBcModal: true });
  };

  /**
   * Handle BC creation modal closure
   */
  public onNewBcClose = () => {
    this.setState({ showNewBcModal: false });
  };

  /**
   * Handle import BC modal
   */
  public onImportBcClick = () => {
    this.setState({ showImportBcModal: true });
  };

  /**
   * Handle import BC modal closure
   */
  public onImportBcClose = () => {
    this.setState({ showImportBcModal: false });
  };

  /**
   * Bc Title by Filter Selected
   */
  public titleRender = (): JSX.Element => {
    switch (this.state.selectedFilter) {
      case FilterType.TOP5:
        return <FormattedMessage id="pp.top5filter" defaultMessage="Business Challenges - Top 5" />;
      case FilterType.CRITICAL:
        return <FormattedMessage id="pp.criticalBc" defaultMessage="Business Challenges - Critical" />;
      case FilterType.AT_RISK:
        return <FormattedMessage id="pp.atRiskBc" defaultMessage="Business Challenges - At Risk" />;
      case FilterType.CONFIDENT:
        return <FormattedMessage id="pp.ConfidentBc" defaultMessage="Business Challenges - Confident" />;
      case FilterType.GROWTH:
        return <FormattedMessage id="pp.GrowthBc" defaultMessage="Business Challenges - Growth" />;
      case FilterType.PEOPLE:
        return <FormattedMessage id="pp.peopleBc" defaultMessage="Business Challenges - People" />;
      case FilterType.COMPETITIVENESS:
        return <FormattedMessage id="pp.competitivenessBc" defaultMessage="Business Challenges - Competitiveness" />;
      case FilterType.NONE:
      default:
        return <FormattedMessage id="pp.allBcs" defaultMessage="All Business Challenges" />;
    }
  };

  /**
   * BC etiquette creation
   * @param {any} bc
   */
  public bcRender(bc: BusinessChallenge): JSX.Element {
    const piList: JSX.Element[] = [];

    const pis = bc.linkedPis
      ? bc.performanceInitiatives.concat(bc.linkedPis.map(el => ({ ...el, isLinked: true })))
      : bc.performanceInitiatives;

    pis.forEach((pi: PerformanceInitiative) => {
      piList.push(
        <div key={`PI${pi.id}`} className="pi">
          {Utils.setBadge(pi.status, pi.isClosed)}
          <span
            className="pi-title"
            onClick={() => this.props.setOverviewPanelActivity(pi, TypeActivity.PERFORMANCE_INITIATIVE)}
          >
            {pi.isLinked
              ? <LinkLabel pp={pi.businessChallenge.performancePlan} mode={LinkLabelMode.SMALL} />
              : <span>PI{Utils.leadingZero(pi.code)} -&nbsp;</span>}
            {pi.name}
          </span>
          <PublicationLabel publishedDate={pi.publishedDate} />
        </div>,
      );
      return null;
    });

    return (
      <div className={'element'} key={`BC${bc.id}`}>
        <Segment>
          <div className="bc-container">
            <div className="bc-title-container">
              {Utils.setBadge(bc.status, bc.isClosed)}
              <span
                className="bc-title"
                onClick={() => this.props.setOverviewPanelActivity(bc, TypeActivity.BUSINESS_CHALLENGE)}
              >
              {bc.isLinked
                ? <LinkLabel pp={bc.performancePlan} />
                : <span>BC{Utils.leadingZero(bc.code)} -&nbsp;</span>}
              {bc.name}
              </span>
              <PublicationLabel publishedDate={bc.publishedDate} />
            </div>
            <div className="top5-pillar-container">
              {Utils.isTop5(bc)}
              {Utils.getPillar(bc.pillar)}
            </div>
          </div>
          <div className="bc-info-container">
            <div className="name-user-container">
              {Utils.getUsersAvatars(bc)}
            </div>
            <span className="date">
              <FormattedMessage id="lastPublication" defaultMessage="Last publication" />&nbsp;
              {bc.publishedDate ? Utils.displayDate(bc.publishedDate) : '-'}
            </span>
          </div>
          <div className="pi-container">
            {piList}
          </div>
        </Segment>
      </div>
    );
  }

  private filterBCs = (filteredArray: BusinessChallenge[], selectedFilter: FilterType) => {
    this.setState({
      selectedFilter,
      search: filteredArray,
    });
  };

  public render() {
    if (!this.state.pp) return Utils.loader();

    const leftColumnContent: JSX.Element[] = [];
    const rightColumnContent: JSX.Element[] = [];
    const arrToFilter = this.mergeBcLinked(this.state.pp);

    let bcs: BusinessChallenge[] = [];
    if (this.state.search !== undefined) {
      bcs = this.state.search;
    }

    bcs.forEach((bc: BusinessChallenge, index: number) => {
      if (index % 2 === 0) {
        leftColumnContent.push(this.bcRender(bc));
      } else {
        rightColumnContent.push(this.bcRender(bc));
      }
      return null;
    });

    let newActionButton;
    if (this.props.rightsOnPP?.canEdit()) {
      newActionButton = (
        <div className="pi-synthetic-view-ctrls btn-with-margin">
          {this.state.showNewBcModal
            && <BcCreation
              close={this.onNewBcClose}
              open={this.state.showNewBcModal}
              ppId={this.state.pp.id}
            />
          }

          {this.state.showImportBcModal && <BcImport close={this.onImportBcClose} ppId={this.state.pp.id} />}
          <FormattedMessage
            id="pp.newBc"
            defaultMessage="New BC"
          >
          {msg =>
            <Dropdown
                text={msg.toString()}
                icon="add circle"
                floating={true}
                labeled={true}
                button={true}
                direction="left"
                className="icon"
                id="bc-button-group"
                disabled={this.props.mode === ModeTypes.MODE_EDITION}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={this.onNewBcClick}
                >
                  <Icon name="add" />
                  <FormattedMessage id="pp.newBc" defaultMessage="New BC" />
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={this.onImportBcClick}
                >
                  <Icon name="history" />
                  <FormattedMessage id="pp.importBc" defaultMessage="Import existing BC" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          }
          </FormattedMessage>
        </div>
      );
    }

    return (
      <div id="cockpit-tab-container">
        {!this.state.search
          ? Utils.loader()
          : <>
            <div id="cockpit-title-container">
                <div>
                    <h2>
                      {this.titleRender()}
                    </h2>
                </div>
                <div>
                    <div className="pi-synthetic-view-ctrls">
                        <FormattedMessage id="filterBCs" defaultMessage="Filter BCs">
                          {msg =>
                            <Filters
                              dropdownName={msg.toString()}
                              arrayToFilter={arrToFilter}
                              filters={[FilterCategory.STATUS, FilterCategory.TOP5, FilterCategory.PILLAR]}
                              selectedFilter={this.state.selectedFilter}
                              setFilteredArray={this.filterBCs}
                            />
                          }
                        </FormattedMessage>
                        {newActionButton}
                    </div>
                </div>
            </div>

            <div id="columns-container">
              <div className="column">
                {leftColumnContent}
              </div>
              <div className="column">
                {rightColumnContent}
              </div>
            </div>
          </>
        }
      </div>
    );
  }
}

export default withRouter(Cockpit);
