export enum BcPiFields {
  MAIN_ACHIEVEMENTS = 'achievements',
  ISSUES_AND_RISKS = 'issuesAndRisks',
  DECISIONS_TO_BE_MADE = 'decisionsToBeMade',
  NEXT_STEPS = 'nextSteps',
}

export enum OverviewPanelFields {
  OBJECTIVES = 'objectives',
  BUDGET = 'budget',
  SUCCESS_FACTORS = 'successFactorsRisks',
  STATUS = 'status',
}
