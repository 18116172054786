import React, { Component } from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import Utils from '../../../../../utils/utils';
import Milestone from '../../../../../models/milestone';
import ModeTypes from '../../../../../constants/modeTypes';
import { ErrType } from '../../../../../constants/errors/typeError';
import { FieldType } from '../metricsContainer';
import { ImportMetricsMilestonesModal, importType } from '../modal/importMetricsMilestonesModal';
import MilestoneRow from './milestoneRow';

interface IProps {
  mode: ModeTypes;
  milestones: Milestone[];
  forActivityCreation: boolean;
  onDataChange(idAttribute: string, value: any, text?: any): void;
  updateSubFormValidation(isValid: boolean, subForm: string): void;
}

interface IStates {
  importMilestoneModalOpen: boolean;
}

export default class Milestones extends Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      importMilestoneModalOpen: false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.props.updateSubFormValidation(this.isMilestonesFormValid(), 'milestones'), 0);
  }

  //region MILESTONES MANAGEMENT
  getMilestonesTable = () : JSX.Element[] => {
    const domElement: JSX.Element[] = [];
    if (this.props.milestones !== undefined) {
      this.props.milestones.forEach((milestone: Milestone, index: number) => {
        domElement.push(
          <MilestoneRow
            key={`milestone${milestone.id}-${index}`}
            mode={this.props.mode}
            milestone={milestone}
            index={index}
            updateMilestone={this.updateMilestone}
            removeMilestone={this.removeMilestone}
          />,
        );
      });
    }

    if (Utils.isOnEditMode(this.props.mode)) {
      domElement.push(
        <Table.Row key="newMilestone">
          <Table.Cell className="add-button">
            <Button className="add-row-button" onClick={() => this.addMilestone()}>
              <Button.Content>
                <Icon name="add" />
                <FormattedMessage id="createNewMilestone" defaultMessage="Add a new milestone" />
              </Button.Content>
            </Button>
            {!this.props.forActivityCreation && Utils.isCurrentActivityTypeBC() &&
            <Button className="add-row-button" onClick={() => this.openImportModal()}>
              <Button.Content>
                <Icon name="download" />
                <FormattedMessage id="importModal.milestone.title" defaultMessage="Import milestone" />
              </Button.Content>
            </Button>}
          </Table.Cell>
        </Table.Row>,
      );
    }
    return domElement;
  }

  // Check if every milestone has a non-empty name
  areMilestoneNamesValid = (milestones = this.props.milestones) => {
    return milestones.every(milestone => !Utils.isValueNullOrEmpty(milestone.name)
      && !Utils.isFieldOnError(milestone.name, true, FieldType.STRING));
  }

  // Check if every milestone has a valid target date
  areMilestoneTargetDatesValid = (milestones = this.props.milestones) => {
    return milestones.every(milestone => !Utils.isValueNullOrEmpty(milestone.targetDate));
  }

  isMilestonesFormValid = (milestones = this.props.milestones) => {
    return this.areMilestoneNamesValid(milestones) && this.areMilestoneTargetDatesValid(milestones);
  }

  openImportModal = () => {
    this.setState({ importMilestoneModalOpen: true });
  }

  closeImportModal = () => {
    this.setState({ importMilestoneModalOpen: false });
  }

  addMilestone = () => {
    let milestones : Milestone[] = [];
    let codeMil : number = 1;
    if (!!this.props.milestones) {
      milestones = [...this.props.milestones];
      codeMil = milestones.length + 1;
    }

    milestones.push(
      {
        name: '',
        code: codeMil,
        targetDate: new Date(),
        isHidden: false,
      },
    );
    this.props.onDataChange('milestones', milestones);
    this.props.updateSubFormValidation(false, 'milestones');
  };

  updateMilestone = (index: number, { name, value }: any) => {
    const milestones = [...this.props.milestones];
    milestones[index][name] =
      (name === 'targetDate' || name === 'completionDate')
        ? value !== ''
          ? moment(value, Utils.getDateFormat()).toDate()
          : null
        : value;
    this.props.onDataChange('milestones', milestones);

    if (name === 'name' || name === 'targetDate') {
      this.props.updateSubFormValidation(this.isMilestonesFormValid(), 'milestones');
    }
  };

  removeMilestone = (index: number) => {
    const currentMilestones = [...this.props.milestones];
    currentMilestones.splice(index, 1);
    this.props.onDataChange('milestones', currentMilestones);
    this.props.updateSubFormValidation(this.isMilestonesFormValid(currentMilestones), 'milestones');
  }
  //endregion

  render() {
    return (
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.HeaderCell className="table-title" width={9}>
              <FormattedMessage id="milestones" defaultMessage="Milestones" />
              <span className="error-container">
                {(!this.areMilestoneNamesValid() && Utils.isOnEditMode(this.props.mode))
                  && Utils.getErrorMessageWithPopup(ErrType.MI_NAME)}
              </span>
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              <FormattedMessage id="targetDate" defaultMessage="Target date" />
              <span className="error-container">
                {(!this.areMilestoneTargetDatesValid() && Utils.isOnEditMode(this.props.mode))
                  && Utils.getErrorMessageWithPopup(ErrType.MI_TARGET_DATE)}
              </span>
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              <FormattedMessage id="dateOfCompletion" defaultMessage="Completion Date" />
            </Table.HeaderCell>
            <Table.HeaderCell width={3} />
          </Table.Row>
          {this.getMilestonesTable()}
          {this.state.importMilestoneModalOpen &&
            <ImportMetricsMilestonesModal importType={importType.milestone} closeModal={this.closeImportModal}/>}
        </Table.Body>
      </Table>
    );
  }
}
