import Feedback from 'src/models/feedback';
import HttpClient from 'src/utils/httpClient';

export default class FeedbackAPI {

  /**
   * ROUTE : /api/feedbacks
   * Get all feedbacks
   * @returns Promise<Feedback[]>
   */
  public static getFeedbacks(type: number, archived: boolean = false): Promise<Feedback[]> {
    const query = type === -1 ? HttpClient.get<Feedback[]>(`feedbacks?archived=${archived}`) :
      HttpClient.get<Feedback[]>(`feedbacks?type=${type}&archived=${archived}`);
    return query;
  }

  /**
   * ROUTE : /api/feedbacks/counts
   * Get all feedbacks counts
   * @returns Promise<any>
   */
  public static getFeedbacksCounts(): Promise<any> {
    return HttpClient.get<any>('feedbacks/counts');
  }

  /**
 * ROUTE : /api/feedbacks
 * post feedback
 * @returns Promise<Feedback>
 */
  public static postFeedback(feedback: Feedback): Promise<Feedback> {
    return HttpClient.post<Feedback>('feedbacks', feedback);
  }

  /**
  * ROUTE : /api/feedbacks
  * put feedback
  * @returns Promise<Feedback>
  */
  public static putFeedback(fbId: number, statusNumber: number): Promise<any> {
    return HttpClient.put<number>(`feedbacks/${fbId}`, { status: statusNumber });
  }

}
