import './overviewPanel.scss';

import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Icon } from 'semantic-ui-react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import TypeActivity from 'src/constants/typeActivity';
import PerformanceInitiative from 'src/models/performanceInitiative';
import BusinessChallenge from 'src/models/businessChallenge';
import Utils from 'src/utils/utils';
import { withPanelAnimation } from './hoc/withPanelAnimation';
import PublicationLabel from '../../../../common/publicationLabel/publicationLabel';
import { BcTabs } from '../../contentBC/contentBC';
import Action from '../../../../../models/action';

import Milestone from '../../../../../models/milestone';
import PanelContent from './panelContent/panelContent';
import MilestoneAction from '../../../../../actions/milestone.action';
import MilestoneStore from '../../../../../stores/milestone.store';

interface IRouteProps {
  id: string;
  ppId: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  isRelativeView: boolean;
  typeActivity?: TypeActivity | undefined;
  activity?: PerformanceInitiative | BusinessChallenge | Action | Milestone | undefined;
  togglePanelVisibility(activityIdToScrollTo?: number): void;
  containerHeight: number;
  canEditPi: boolean;

  setOverviewData(id: number, type: TypeActivity): void;
}

interface IStates {
  nextLocation: string | undefined;
  activeIndex: number;
  contentPanelHeight: number;
  isOnErr: boolean;
}

class OverviewPanel extends React.Component<IProps & InjectedIntlProps, IStates> {

  public constructor(props: IProps & InjectedIntlProps) {
    super(props);
    this.state = {
      nextLocation: undefined,
      activeIndex: 0,
      contentPanelHeight: 0,
      isOnErr: false,
    };
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>, snapshot?: any): void {
    if (prevProps.containerHeight !== this.props.containerHeight) {
      this.calculateOverviewPanel();
    }
  }

  componentDidMount() {
    this.calculateOverviewPanel();
    if (Utils.isActivityMilestone(this.props.typeActivity) && MilestoneStore.getMilestones()
      && MilestoneStore.getMilestones().length === 0) {
      MilestoneAction.getPiMilestones(+this.props.match.params.id);
    }
  }

  private calculateOverviewPanel = () => {
    const titleElement = window.document.getElementsByClassName('header-container');
    const secondaryMenu = window.document.getElementsByClassName('secondary menu');

    if (titleElement && titleElement[0] && secondaryMenu && secondaryMenu[0]) {
      const titleElementHeight = titleElement[0].clientHeight;
      let secondaryMenuHeight: number;

      const secondaryMenuBottomLine = 2;

      secondaryMenuHeight = secondaryMenu[0].clientHeight + secondaryMenuBottomLine;

      const menuTotalHeight = this.props.containerHeight - titleElementHeight - secondaryMenuHeight;

      if (menuTotalHeight && this.state.contentPanelHeight !== menuTotalHeight) {
        this.setState({ contentPanelHeight: menuTotalHeight });
      }
    }
  };

  private navigateToActivity = () => {
    if (!this.props.activity || (this.props.activity.id === +this.props.match.params.id)) return;
    const isBc = Utils.isActivityBc(this.props.typeActivity);
    this.props.history.push(`/activities-board/${this.props.match.params.ppId || this.props.match.params.id}/${isBc ? 'business-challenge' : 'performance-initiative'}/${this.props.activity.id}/${BcTabs.COCKPIT}`);
  };

  private handleTabChange = (index: number) => {
    this.setState({ activeIndex: index });
  };

  private renderHeader = () => {
    const closeButton = (
      <Button icon={true} className="close-btn" onClick={() => this.props.togglePanelVisibility()}>
        <Icon name="close"/>
      </Button>
    );
    if (this.props.activity && this.props.typeActivity) {

      if (Utils.isActivityPp()) {
        const activity = this.props.activity as (BusinessChallenge | PerformanceInitiative);

        const pp = activity.isLinked && Utils.isActivityBc(this.props.typeActivity) ?
          (activity as BusinessChallenge).performancePlan
          : (activity as PerformanceInitiative).businessChallenge?.performancePlan;

        return (
          <div className="header-container pi-header">
            {Utils.isActivityBc(this.props.typeActivity) && <div>
              {Utils.isTop5(activity as BusinessChallenge)}
              {Utils.getPillar((activity as BusinessChallenge).pillar)}
            </div>}
            <div className="title-container">
              <h3 className="title" onClick={this.navigateToActivity}>
                {Utils.formatLinkedActivityName(activity.code, activity.name, this.props.typeActivity,
                                                activity.isLinked, pp)}
              </h3>
              <PublicationLabel publishedDate={activity.publishedDate}/>
            </div>
            {closeButton}
          </div>
        );
      }
    }

    let formattedMessage : JSX.Element = <FormattedMessage id="overviewPanel.title" defaultMessage="Overview"/>;

    if (Utils.isActivityAction(this.props.typeActivity)) {
      formattedMessage = <FormattedMessage id="at.allActions" defaultMessage="Actions"/>;
    } else if (Utils.isActivityMilestone(this.props.typeActivity)) {
      formattedMessage = <FormattedMessage id="milestones" defaultMessage="Milestones"/>;
    }

    return (
      <div className="header-container">
        <h3 className={this.state.isOnErr ? 'error' : ''}>
          {formattedMessage}
          {this.state.isOnErr && <Icon className="menu-icon-error error" name="warning circle"/>}
        </h3>
        {closeButton}
      </div>
    );
  };

  public render() {
    return (
      <div
        id="overview-panel"
        className={`panel
         ${this.props.isRelativeView && 'relative'}
         ${Utils.isActivityAction(this.props.typeActivity) && 'panel-at'}
         `}
        style={{ top: `${Utils.getHeightToSubtract(true)}px`, height: `${this.props.containerHeight}px` }}
      >
        {this.renderHeader()}
        <PanelContent
          typeActivity={this.props.typeActivity}
          routeActivityId={this.props.match.params.id}
          activity={this.props.activity}
          contentPanelHeight={this.state.contentPanelHeight === 0
            ? this.props.containerHeight : this.state.contentPanelHeight}
          handleTabChange={this.handleTabChange}
          activeIndex={this.state.activeIndex}
          canEditPi={this.props.canEditPi}
          setPanelErrStatus={isOnErr => this.setState({ isOnErr })}
          resetPanelActivity={this.props.togglePanelVisibility}
        />
      </div>
    );
  }
}
export default withPanelAnimation(withRouter(injectIntl(OverviewPanel)));
