import './loadTestsResults.scss';

import * as React from 'react';
import { Accordion, Dropdown, DropdownItem, DropdownMenu } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import CustomScrollBars from '../../../common/customScrollBars/customScrollBars';
import LoadTestsResultsAPI from '../../../../api/loadTestsResults.api';
import { LoadTestScenario } from '../../../../models/loadTestsResults';
import Utils from '../../../../utils/utils';
import { Scenario } from './scenario';

interface IProps {}

interface IStates {
  scenarios: LoadTestScenario[] | undefined;
  activeIndex: number;
  isLoading: boolean;
  selectedDate: Date | undefined;
  availableDates: Date[];
}

class LoadTestsResults extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);

    this.state = {
      scenarios: undefined,
      activeIndex: -1,
      isLoading: true,
      selectedDate: undefined,
      availableDates: [],
    };
  }

  public componentDidMount() {
    LoadTestsResultsAPI.getLoadTestsDates()
      .then((availableDates) => {
        LoadTestsResultsAPI.getLoadTestsResults(new Date(availableDates[0]))
          .then((scenarios) => {
            this.setState({
              scenarios,
              availableDates: availableDates.map(d => new Date(d)),
              isLoading: false,
              selectedDate: new Date(availableDates[0]),
            });
          })
          .catch(() => {
            this.setState({ isLoading: false });
          });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>, snapshot?: any) {
    if (prevState.selectedDate && prevState.selectedDate.getTime() !== this.state.selectedDate?.getTime()) {
      LoadTestsResultsAPI.getLoadTestsResults(this.state.selectedDate!!)
        .then((scenarios) => {
          this.setState({
            scenarios,
            isLoading: false,
          });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  }

  private setActiveIndex = (index: number) => {
    this.setState(state => ({ activeIndex: state.activeIndex === index ? -1 : index }));
  }

  private setSelectedDate = (date: Date) => {
    this.setState({ selectedDate: new Date(date) });
  }

  public render() {
    if (this.state.isLoading) return Utils.loader();

    return (
      <CustomScrollBars>
        <div id="load-tests-results">
          <div id="main-title">
            <FormattedMessage id="loadTestsResults" defaultMessage="Load tests results" />
            {this.state.scenarios && this.state.scenarios.length > 0 &&
              <>
                &nbsp;-&nbsp;
                <FormattedMessage id="loadTestsResults.resultsOf" defaultMessage="Results of" />
                &nbsp;&nbsp;
                <Dropdown text={Utils.displayFancyDate(this.state.selectedDate!!, true)}>
                  <DropdownMenu id="dropdown-menu">
                    {this.state.availableDates.map(date =>
                       <DropdownItem
                         key={date.getTime()}
                         label={Utils.displayFancyDate(date, true)}
                         onClick={() => this.setSelectedDate(date)}
                         selected={this.state.selectedDate?.getTime() === date.getTime()}
                       />,
                    )}
                  </DropdownMenu>
                </Dropdown>
              </>
            }
          </div>

          {this.state.scenarios
            ? <Accordion>
              {this.state.scenarios.map((scenario, index) =>
                <Scenario
                  key={scenario.scenarioName}
                  scenario={scenario}
                  index={index}
                  activeIndex={this.state.activeIndex}
                  setActiveIndex={this.setActiveIndex}
                />,
              )}
            </Accordion>
            : Utils.empty(<FormattedMessage id="loadTestsResults.noResults" defaultMessage="No results were found" />)
          }
        </div>
      </CustomScrollBars>
    );
  }
}

export default LoadTestsResults;
