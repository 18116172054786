import Dispatcher from '../dispatcher';
import ActionTypes from '../constants/actionTypes';
import ModeTypes from '../constants/modeTypes';
import Milestone from '../models/milestone';
import PerformanceInitiativeAPI from '../api/performanceInitiative.api';
import ActionAPI from 'src/api/action.api';
import LinkedMilestone from 'src/models/linkedMilestone';
import Action from 'src/models/action';
import ActionActions from './actions.action';

export default class MilestoneAction {

  public static getPiMilestones(piId: number) {
    PerformanceInitiativeAPI.getPerformanceInitiativeMilestones(piId, true).then((milestones) => {
      Dispatcher.dispatch({
        milestones,
        actionType: ActionTypes.GET_MILESTONES,
      });
    });
  }

  public static setMilestones(milestones: Milestone[]) {
    Dispatcher.dispatch({
      milestones,
      actionType: ActionTypes.GET_MILESTONES,
    });
  }

  /**
   * Create new milestone segment
   * @return {void}
   */
  public static emitCreateMilestone(): void {
    Dispatcher.dispatch({
      actionType: ActionTypes.MILESTONE_CREATION,
    });
  }

  /**
   * Create new milestone segment
   * @return {void}
   */
  public static emitChangeMilestoneMode(mode: ModeTypes): void {
    Dispatcher.dispatch({
      mode,
      actionType: ActionTypes.CHANGE_MILESTONE_MODE,
    });
  }

  /**
   * Link pi milestone to BC
   * @return {void}
   */
  public static emitLinkMilestoneToBc(milestoneId: number, piId: number): void {
    Dispatcher.dispatch({
      milestoneId,
      piId,
      actionType: ActionTypes.LINK_MILESTONE_TO_BC,
    });
  }

  public static getLinkedMilestone(actions: Action[]) {
    const actionLinkedMilestone = new Map<number, LinkedMilestone[]>();
    const promises = actions.map((action) => {
      return ActionAPI.getActionMilestones(action.id)
        .then((milestonesId) => {
          actionLinkedMilestone.set(action.id, milestonesId);
        });
    });

    Promise.all(promises)
      .then(() => {
        Dispatcher.dispatch({
          actionLinkedMilestone,
          actionType: ActionTypes.GET_LINK_MILESTONE_TO_ACTION,
        });
      });
  }

  public static deleteLinkedMilestone(atId: number, miId: number, firstCall: boolean) {
    return ActionAPI.deleteActionMilestones(atId, miId).then(() => {
      if (firstCall) ActionActions.deleteLinkedActions(atId, miId, false);
      Dispatcher.dispatch({
        atId,
        miId,
        actionType: ActionTypes.DELETE_LINK_MILESTONE_TO_ACTION,
      });
    });
  }

  public static updateLinkedMilestone(atId: number, miId: number, firstCall: boolean) {
    return ActionAPI.putActionMilestones(atId, miId).then(() => {
      if (firstCall) ActionActions.updateLinkedActions(atId, miId, false);
      Dispatcher.dispatch({
        atId,
        miId,
        actionType: ActionTypes.SET_LINK_MILESTONE_TO_ACTION,
      });
    });
  }

}
