import './taskBoard.scss';

import * as React from 'react';
import { Segment } from 'semantic-ui-react';
import NotificationList from '../notificationList/notificationList';
import NotificationActions from '../../actions/notification-actions';
import TimelineContainer from './timelineContainer';

class TaskBoard extends React.Component<{}, {}> {

  //region REACT LIFECYCLE METHODS
  public constructor(props: {}) {
    super(props);
  }

  public componentDidMount(): void {
    NotificationActions.getNumberOfNotifications();
    NotificationActions.getNotifications();
  }
  //endregion

  public render() {
    return (
      <div id="task-board">
        <div id="timeline">
          <Segment>
            <TimelineContainer />
          </Segment>
        </div>
        <div id="notification">
          <Segment>
            <NotificationList />
          </Segment>
        </div>
      </div>
    );
  }
}

export default TaskBoard;
