import 'react-datepicker/dist/react-datepicker.css';

import * as React from 'react';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';

import PerformanceInitiativeActions from 'src/actions/performanceInitiative.action';
import { PerformanceIndicatorPost } from 'src/models/performanceInitiative';
import PerformanceInitiativeStore from 'src/stores/performanceInitiative.store';
import Utils from 'src/utils/utils';
import NotificationActions from '../../../actions/notification-actions';
import PerformancePlanActions from '../../../actions/performancePlan.action';
import BusinessChallengeAPI from '../../../api/businessChallenge.api';
import ActionTypes from '../../../constants/actionTypes';
import BusinessChallenge from '../../../models/businessChallenge';
import Keywords from '../../../models/keywords';
import PerformancePlan from '../../../models/performancePlan';
import Creation from '../creation';
import PiCreationStep1 from './piCreationStep1';
import PiCreationStep2 from './piCreationStep2';
import PiCreationStep3 from './piCreationStep3';
import { ToastType } from 'src/components/common/toast/toast';

interface IProps extends InjectedIntlProps, RouteComponentProps {
  pp: PerformancePlan;
  bc: BusinessChallenge;
  open: boolean;
  close: () => void;
}

interface IStates {
  bc: BusinessChallenge | undefined;
  pi: PerformanceIndicatorPost;
  step: number;
  keywords: Keywords[];
  isNavigationDisabled: boolean;
  isSubmitDisabled: boolean;
  isEditorOnError: boolean;
}

class PiCreation extends React.Component<IProps, IStates> {

  private isMount = false;

  private creationTitle = this.props.intl.formatMessage({
    id: 'createNewPi',
    defaultMessage: 'Create a new Performance Initiative',
  });

  private creationSteps = [
    {
      title: this.props.intl.formatMessage(
        {
          id: 'basicInformation',
          defaultMessage: 'Basic Information',
        }),
      subtitle: '',
    },
    {
      title: this.props.intl.formatMessage({
        id: 'metricsAndMilestones',
        defaultMessage: 'Metrics & Milestones',
      }),
      subtitle: '',
    },
    {
      title: this.props.intl.formatMessage(
        {
          id: 'piCreation.assignments',
          defaultMessage: 'PI leader(s) assignment',
        }),
      subtitle: '',
    },
  ];

  private messages = [
    <FormattedMessage key="createNewPi" id="createNewPi" defaultMessage="Create a new Performance Initiative" />,
    <FormattedMessage key="basicInformation" id="basicInformation" defaultMessage="Basic information" />,
    <FormattedMessage key="metricsAndMilestones" id="metricsAndMilestones" defaultMessage="Metrics & Milestones" />,
    <FormattedMessage key="assignments" id="piCreation.assignments" defaultMessage="PI leader(s) assignment" />,
  ];

  private defaultPi = {
    code: 0,
    name: '',
    objectives: '',
    budget: '',
    keywordsId: 2,
    metrics: [],
    milestones: [],
    attachments: [],
    isBudgetHidden: true,
  };

  public constructor(props: IProps) {
    super(props);
    this.state = {
      bc: undefined,
      pi: { ...this.defaultPi },
      step: 1,
      keywords: PerformanceInitiativeStore.getKeywords(),
      isNavigationDisabled: true,
      isSubmitDisabled: false,
      isEditorOnError: false,
    };

    if (!this.messages) {
      return;
    }
  }

  public componentDidMount() {
    this.isMount = true;

    PerformanceInitiativeStore.addListener(
      ActionTypes.PERFORMANCE_INITIATIVE_KEYWORDS_GET.toString(), this.getPiKeywords);
    PerformanceInitiativeActions.emitGetKeywords();

    this.getBc();
    this.setNavigationDisabled(true);
  }

  public componentWillUnmount() {
    this.isMount = false;

    PerformanceInitiativeStore.removeListener(
      ActionTypes.PERFORMANCE_INITIATIVE_KEYWORDS_GET.toString(), this.getPiKeywords);
  }

  private getPiKeywords = () => {
    if (this.isMount) {
      this.setState({ keywords: PerformanceInitiativeStore.getKeywords() });
    }
  };

  private getBc = () => {
    BusinessChallengeAPI.getBusinessChallengePiCodes(this.props.bc.id)
      .then((bc: BusinessChallenge) => {
        const pi = { ...this.state.pi };
        pi.code = this.getDefaultCode(bc);
        this.setState({ bc, pi });
      });
  };

  /**
   * Get the default code of the PI, based on other PIs
   */
  private getDefaultCode = (bc: BusinessChallenge): number => {
    let code = 1;
    if (bc.performanceInitiatives) {
      const codeArray = bc.performanceInitiatives.map(pi => pi.code);
      while (true) {
        if (!codeArray.find(y => code === y)) { // eslint-disable-line no-loop-func
          break;
        }
        code = code + 1;
      }
    }
    return code;
  };

  /**
   * Set whether the navigation is disabled or not
   */
  private setNavigationDisabled = (isNavigationDisabled: boolean) => {
    this.setState({ isNavigationDisabled });
  };

  /**
   * Submit the new BC to the API and close the modal
   */
  private submit = () => {
    const cleanPi = { ...this.state.pi };
    cleanPi.name = cleanPi.name.trim();
    cleanPi.objectives = cleanPi.objectives.trim();
    cleanPi.budget = cleanPi.budget.trim();
    return PerformanceInitiativeActions.emitCreatePerformanceInitiative(this.props.bc.id, cleanPi)
      .then((newPiId: number) => {
        this.close();
        NotificationActions.toast(
          <FormattedMessage id="saved" defaultMessage="Saved!" />,
          (
            <FormattedMessage
              id="successPiCreation"
              defaultMessage="The Performance Initiative has been successfully created"
            />
          ),
          ToastType.SUCCESS,
        );

        PerformancePlanActions.invalidateBcs();
        const ppId = this.props.history.location.pathname.split('/')[2];
        this.props.history.push(`/activities-board/${ppId}/performance-initiative/${newPiId}/cockpit`);
      })
      .catch((err) => {
        if (err && err.code === 'CODE_EEXIST') {
          this.getBc();
          NotificationActions.toast(
            <FormattedMessage id="errorOccurred" defaultMessage="Error" />,
            <FormattedMessage id="errorPICode" defaultMessage="This PI code is already used" />,
            ToastType.ERROR,
          );
        } else {
          Utils.toastError();
        }
      });
  };

  /**
   * Close the modal and reset the state
   */
  private close = () => {
    this.props.close();
    this.setState({
      pi: { ...this.defaultPi },
      step: 1,
      isSubmitDisabled: true,
    });
  };

  /**
   * Update the PI when a value is modified in the child components
   * @param key : string
   * @param value : any
   */
  private updatePI = (key: string, value: any) => {
    const pi = this.state.pi;
    pi[key] = value;

    this.setState(
      {
        pi,
        isSubmitDisabled: (JSON.stringify(pi) === JSON.stringify(this.defaultPi)) || (pi.name === ''),
      });
  };

  public render(): any {
    return (
      <Creation
        open={this.props.open}
        close={this.close}
        title={this.creationTitle}
        parent={`BC${Utils.leadingZero(this.props.bc.code)}`}
        steps={this.creationSteps}
        submit={this.submit}
        isNavigationDisabled={this.state.isNavigationDisabled}
      >
        {this.state.bc && this.state.keywords
          ? <PiCreationStep1
            pp={this.props.pp}
            bc={this.state.bc}
            pi={this.state.pi}
            keywords={this.state.keywords}
            onUpdate={this.updatePI}
            setNavigationDisabled={this.setNavigationDisabled}
          />
          : Utils.loader()
        }

        <PiCreationStep2
          pi={this.state.pi}
          onUpdate={this.updatePI}
          setNavigationDisabled={this.setNavigationDisabled}
        />
        <PiCreationStep3
          pi={this.state.pi}
          onUpdate={this.updatePI}
          setNavigationDisabled={this.setNavigationDisabled}
        />
      </Creation>);
  }

}

export default injectIntl(withRouter(PiCreation));
