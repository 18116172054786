import * as React from 'react';
import { Checkbox, Divider, Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { ImportBcParams, ImportPiParams } from '../../../models/importActivities';
import TypeActivity from '../../../constants/typeActivity';
import CustomScrollBars from '../../common/customScrollBars/customScrollBars';
import Utils from '../../../utils/utils';

interface IProps {
  isSelectedSimilarPp: boolean;
  importParams: ImportBcParams | ImportPiParams;
  typeActivity: TypeActivity;
  setChoices(importParams:  ImportBcParams | ImportPiParams): void;
}

export default class ActivityImportStepFinal extends React.Component<IProps> {

  public constructor(props: IProps) {
    super(props);
  }

  private onChangeCheckbox(data) {
    const stateObj = this.props.importParams;
    if (stateObj !== undefined) {
      stateObj[data.id] = data.checked;
      this.props.setChoices(stateObj);
    }
  }

  private renderChoices() {
    const keepAssignationsMessage = this.props.importParams.keepStakeholdersAssignations
      ? null
      : (
        <Message>
          <FormattedMessage
            id="pp.importAssignWarn"
            defaultMessage="You will have to assign stakeholders manually after the import"
          />
        </Message>
      );
    const keepActionsMsg = this.props.importParams.keepAt && !this.props.isSelectedSimilarPp
      ? (
        <Message>
          <FormattedMessage
            id="pp.importAtWarn"
            defaultMessage="Only actions that are neither hidden nor closed are imported"
          />
        </Message>
      ) : null;
    const keepMetricsMsg = ((this.props.importParams.keepMetrics || this.props.importParams.keepMilestones)
    && !this.props.isSelectedSimilarPp)
      ? (
        <Message>
          <FormattedMessage
            id="pp.importMetricsWarn"
            defaultMessage="Only metrics/milestones that are not hidden will be imported"
          />
        </Message>
      ) : null;

    return Utils.isActivityPi(this.props.typeActivity)
      ? (
      <div>
        <div className="category-title">
          <FormattedMessage id="importModal.copy.assignations" defaultMessage="Stakeholders assignations" />:
        </div>
        <div className="import-checkbox">
          <Checkbox
            id="keepStakeholdersAssignations"
            onClick={(evt, data) => this.onChangeCheckbox(data)}
            disabled={!this.props.isSelectedSimilarPp}
            defaultChecked={this.props.importParams.keepStakeholdersAssignations}
            label={{ children:
                <FormattedMessage
                  id="importModal.copy.keepAssignations"
                  defaultMessage="Keep stakeholders assignations"
                />,
            }}
          />
        </div>
        {keepAssignationsMessage}

        <div className="category-title">
          <Divider />
          <FormattedMessage id="importModal.copy.piInfos" defaultMessage="Performance Initiatives" />:
        </div>
        <div className="import-checkbox">
          <Checkbox
            id="keepAt"
            onClick={(evt, data) => this.onChangeCheckbox(data)}
            defaultChecked={(this.props.importParams as ImportPiParams).keepAt}
            label={{ children: <FormattedMessage id="importModal.copy.importAt" defaultMessage="Import Actions" /> }}
          />
        </div>
        {keepActionsMsg}

        <div className="category-title">
          <Divider />
          <FormattedMessage id="importModal.copy.metricView" defaultMessage="Metrics view" />:
        </div>
        <div className="import-checkbox">
          <Checkbox
            id="keepMetrics"
            onClick={(evt, data) => this.onChangeCheckbox(data)}
            defaultChecked={(this.props.importParams as ImportPiParams).keepMetrics}
            label={{ children: <FormattedMessage id="importModal.copy.keepMetrics" defaultMessage="Keep metrics" /> }}
          />
        </div>
        <div className="import-checkbox">
          <Checkbox
            id="keepMilestones"
            onClick={(evt, data) => this.onChangeCheckbox(data)}
            defaultChecked={this.props.importParams.keepMilestones}
            label={{ children:
              <FormattedMessage
                id="importModal.copy.keepMilestones"
                defaultMessage="Keep milestones"
              />,
            }}
          />
        </div>
        {keepMetricsMsg}

        <div className="category-title">
          <Divider />
          <FormattedMessage id="importModal.copy.cockpit" defaultMessage="Cockpit view" />:
        </div>
        <div className="import-checkbox">
          <Checkbox
            id="keepTextField"
            onClick={(evt, data) => this.onChangeCheckbox(data)}
            disabled={!this.props.isSelectedSimilarPp}
            defaultChecked={this.props.importParams.keepTextField}
            label={{ children:
              <FormattedMessage
                id="importModal.copy.keepAchievements"
                defaultMessage="Keep main achievements, issues & risks, decisions to be made and next steps"
              />,
            }}
          />
        </div>
        <div className="import-checkbox">
          <Checkbox
            id="resetStatus"
            onClick={(evt, data) => this.onChangeCheckbox(data)}
            disabled={!this.props.isSelectedSimilarPp}
            defaultChecked={this.props.importParams.resetStatus}
            label={{ children:
              <FormattedMessage
                id="importModal.copy.restoreStatus"
                defaultMessage="Restore all status to Confident"
              />,
            }}
          />
        </div>
      </div>
    )
    : (
      <div>
        <div className="category-title">
          <FormattedMessage id="importModal.copy.toto" defaultMessage="Stakeholders assignations" />:
        </div>
        <div className="import-checkbox">
          <Checkbox
            id="keepStakeholdersAssignations"
            onClick={(evt, data) => this.onChangeCheckbox(data)}
            disabled={!this.props.isSelectedSimilarPp}
            defaultChecked={this.props.importParams.keepStakeholdersAssignations}
            label={{ children:
                <FormattedMessage
                  id="importModal.copy.keepAssignations"
                  defaultMessage="Keep stakeholders assignations"
                />,
            }}
          />
        </div>
        {keepAssignationsMessage}

        <div className="category-title">
          <Divider />
          <FormattedMessage id="importModal.copy.piInfos" defaultMessage="Performance Initiatives" />:
        </div>
        <div className="import-checkbox">
          <Checkbox
            id="keepPI"
            onClick={(evt, data) => {
              const params = (this.props.importParams as ImportBcParams);
              if (params.keepAt && params.keepPI) {
                params.keepAt = false;
              }
              if (data.checked !== undefined) {
                params.keepPI = data.checked;
              }
              this.props.setChoices(params);
            }}
            defaultChecked={(this.props.importParams as ImportBcParams).keepPI}
            label={{ children: <FormattedMessage id="importModal.copy.importPI" defaultMessage="Import PIs" /> }}
          />
        </div>
        <div className="import-checkbox">
          <Checkbox
            id="keepAt"
            onClick={(evt, data) => this.onChangeCheckbox(data)}
            defaultChecked={(this.props.importParams as ImportBcParams).keepAt}
            disabled={!(this.props.importParams as ImportBcParams).keepPI}
            checked={(this.props.importParams as ImportBcParams).keepAt}
            label={{ children: <FormattedMessage id="importModal.copy.importAt" defaultMessage="Import actions" /> }}
          />
        </div>
        {keepActionsMsg}

        <div className="category-title">
          <Divider />
          <FormattedMessage id="importModal.copy.metricView" defaultMessage="Metrics view" />:
        </div>
        <div className="import-checkbox">
          <Checkbox
            id="keepMetrics"
            onClick={(evt, data) => this.onChangeCheckbox(data)}
            defaultChecked={this.props.importParams.keepMetrics}
            label={{ children: <FormattedMessage id="importModal.copy.keepMetrics" defaultMessage="Keep metrics" /> }}
          />
        </div>
        <div className="import-checkbox">
          <Checkbox
            id="keepMilestones"
            onClick={(evt, data) => this.onChangeCheckbox(data)}
            defaultChecked={this.props.importParams.keepMilestones}
            label={{ children:
              <FormattedMessage
                id="importModal.copy.keepMilestones"
                defaultMessage="Keep milestones"
              />,
            }}
          />
        </div>
        {keepMetricsMsg}

        <div className="category-title">
          <Divider />
          <FormattedMessage id="importModal.copy.cockpit" defaultMessage="Cockpit view" />:
        </div>
        <div className="import-checkbox">
          <Checkbox
            id="keepTextField"
            onClick={(evt, data) => this.onChangeCheckbox(data)}
            disabled={!this.props.isSelectedSimilarPp}
            defaultChecked={this.props.importParams.keepTextField}
            label={{ children:
              <FormattedMessage
                id="importModal.copy.keepAchievements"
                defaultMessage="Keep main achievements, issues & risks, decisions to be made and next steps"
              />,
            }}
          />
        </div>
        <div className="import-checkbox">
          <Checkbox
            id="resetStatus"
            onClick={(evt, data) => this.onChangeCheckbox(data)}
            defaultChecked={this.props.importParams.resetStatus}
            label={{ children:
              <FormattedMessage
                id="importModal.copy.restoreStatus"
                defaultMessage="Restore all status to Confident"
              />,
            }}
          />
        </div>
      </div>
    );
  }

  public render(): React.ReactNode {
    return (
      <div id="activity-import-step-final">
        <CustomScrollBars>
          <div id="choice-container">
            {this.renderChoices()}
          </div>
        </CustomScrollBars>
      </div>
    );
  }
}
