import './infoContainer.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Button, Dropdown, Icon, Loader } from 'semantic-ui-react';
import Img from 'react-image';

import FeedbackModal from 'src/components/feedback/feedbackModal';
import NotificationStore from 'src/stores/notification.store';
import NotificationActions from 'src/actions/notification-actions';
import ProfileService from 'src/services/profile.service';
import AuthStore from 'src/stores/auth.store';
import NotificationList from 'src/components/notificationList/notificationList';
import AuthService from 'src/services/auth.service';
import ActionTypes from 'src/constants/actionTypes';
import defaultUserAvatar from 'src/images/default-user.png';
import UserActions from 'src/actions/user.action';
import User from 'src/models/user';

interface IState {
  user: User;
  isFeedbackModalOpened: boolean;
  isNotificationModalOpened: boolean;
  isNotificationListVisible: boolean;
  nbNotifications?: number;
  image: string;
}

class InfoContainer extends React.Component<RouteComponentProps, IState> {

  private isMount = false;
  private notificationBellRef = React.createRef<HTMLDivElement>();
  private notificationListRef = React.createRef<HTMLDivElement>();

  public constructor(props: RouteComponentProps) {
    super(props);
    const user = AuthStore.getConnectedUser();

    if (!!user) {
      this.state = {
        user,
        isFeedbackModalOpened: false,
        isNotificationModalOpened: false,
        isNotificationListVisible: false,
        nbNotifications: undefined,
        image: '',
      };
    }
  }

  public componentDidMount() {
    this.isMount = true;
    this.setProfileAvatar();
    AuthStore.addListener(ActionTypes.PROFILE_UPDATE.toString(), this.setProfileAvatar);
    AuthStore.addUpdateProfileListener(this.updateProfile);
    NotificationActions.getNumberOfNotifications();
    NotificationStore.addNotificationsNumberChangeListener(this.setNumberOfNotifications);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentWillUnmount(): void {
    this.isMount = false;
    AuthStore.removeUpdateProfileListener(this.updateProfile);
    NotificationStore.removeNotificationsNumberChangeListener(this.setNumberOfNotifications);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  private setProfileAvatar = () => {
    if (this.isMount) {
      UserActions.getUserAvatar(
        this.state.user.id,
        image => this.setState({ image }),
      );
    }
  };

  private updateProfile = () => {
    if (this.isMount) {
      ProfileService.getProfilePic().then((blob: any) => {
        this.setState({ image: URL.createObjectURL(blob) });
      });
    }
  };

  private setNumberOfNotifications = () => {
    if (this.isMount) {
      this.setState({ nbNotifications: NotificationStore.getNumberOfNotifications() });
    }
  };

  private getNotifications = () => {
    if (NotificationStore.getNotifications() === undefined) {
      NotificationActions.getNotifications();
    }
  };

  private handleClickOutside = (event: any) => {
    if (this.notificationListRef && this.notificationListRef.current
      && this.notificationBellRef && this.notificationBellRef.current
      && !this.notificationListRef.current.contains(event.target)
    ) {
      if (this.notificationBellRef.current.contains(event.target)) {
        this.setNotificationListVisibility(!this.state.isNotificationListVisible);
      } else {
        if (!this.state.isNotificationModalOpened) {
          this.setNotificationListVisibility(false);
        }
      }
    }
  };

  private logout = async () => {
    await AuthService.logout();
    this.props.history.push('/');
  };

  private openFeedbackModal = () => {
    this.setState({ isFeedbackModalOpened: true });
  };

  private onCloseFeedbackModal = () => {
    this.setState({ isFeedbackModalOpened: false });
  };

  private setNotificationListVisibility = (isVisible: boolean) => {
    this.setState({ isNotificationListVisible: isVisible });
  };

  private setNotificationModalVisibility = (isVisible: boolean) => {
    this.setState({ isNotificationModalOpened: isVisible });
  };

  public render() {
    const dropdownTrigger = (
      <div id="profileContainerTopBar">
        <Img
          id="avatar"
          src={[this.state.image, defaultUserAvatar]}
          loader={<Loader active={true} size="large" />}
        />
        <div id="nameContainer">
          <h1>{this.state.user.firstName} {this.state.user.lastName}</h1>
          <h2>{this.state.user.isCoreTeam ? 'Core Team' : this.state.user.role.name}</h2>
        </div>
        <Icon name="angle down" />
      </div>
    );

    return (
      <div id="infoContainer" className="noselect-text">
        <div id="notification-bell" onClick={this.getNotifications} ref={this.notificationBellRef}>
          <Button
            id="notifButton"
            circular={true}
            icon="bell"
            disabled={true}
          />
          {this.state.nbNotifications && this.state.nbNotifications > 0
            && <div id="notifBadge">{this.state.nbNotifications}</div>}
        </div>

        <div id="notification-list-container" ref={this.notificationListRef}>
          {this.state.isNotificationListVisible &&
            <NotificationList
              isInTopBar={true}
              setNotificationListVisibility={this.setNotificationListVisibility}
              setNotificationModalVisibility={this.setNotificationModalVisibility}
            />
          }
        </div>

        <Dropdown trigger={dropdownTrigger} pointing="top right" icon={null}>
          <Dropdown.Menu>
            <FormattedMessage id="profile" defaultMessage="Profile">
              {msg => <Dropdown.Item
                id="profile"
                text={msg}
                icon="user"
                as={NavLink}
                to="/profile"
              />}
            </FormattedMessage>
            <FormattedMessage id="topBar.feedback" defaultMessage="Feedback">
              {msg => <Dropdown.Item
                id="feedback"
                text={msg}
                icon="comments"
                onClick={this.openFeedbackModal}
              />}
            </FormattedMessage>
            <FormattedMessage id="topBar.about" defaultMessage="About">
              {msg => <Dropdown.Item
                id="change_log"
                text={msg}
                icon="info"
                as={NavLink}
                to="/info"
              />}
            </FormattedMessage>
            <FormattedMessage id="menu.userGuide" defaultMessage="User Guide">
              {msg => <Dropdown.Item
                id="userGuide"
                text={msg}
                icon="file alternate"
                as={NavLink}
                to="/user-guide"
              />}
            </FormattedMessage>
            {this.state.user.isCoreTeam
              && <FormattedMessage id="topBar.admin" defaultMessage="Administration">
                {msg => <Dropdown.Item
                  id="administration"
                  text={msg}
                  icon="configure"
                  as={NavLink}
                  to="/admin"
                />}
              </FormattedMessage>
            }
            <FormattedMessage id="topBar.signout" defaultMessage="Sign Out">
              {msg => <Dropdown.Item
                id="sign_out"
                text={msg}
                icon="sign out"
                onClick={this.logout}
              />}
            </FormattedMessage>
          </Dropdown.Menu>
        </Dropdown>
        <div className="feedback-container">
          {this.state.isFeedbackModalOpened
            && <FeedbackModal open={this.state.isFeedbackModalOpened} onClose={this.onCloseFeedbackModal} />}
        </div>
      </div>
    );
  }
}

export default withRouter(InfoContainer);
