import './reportList.scss';

import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, Form, Icon, Segment } from 'semantic-ui-react';
import { RouteComponentProps, withRouter } from 'react-router';

import NotificationActions from 'src/actions/notification-actions';
import PerformancePlanActions from 'src/actions/performancePlan.action';
import Utils from 'src/utils/utils';
import ConfirmModal from '../../../confirmModal/confirmModal';
import { ReportingGetFormatted, ReportingPost } from '../../../../../../models/reporting';
import { ToastType } from 'src/components/common/toast/toast';
import { ReportingDate } from '../enum/reportingDate.enum';

interface IRouteProps {
  reportingId: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  ppId: number;
  reportings: ReportingGetFormatted[];
  setSelectedReportingId: (id: number) => void;
  onReportingCreated: () => void;
  empty: boolean;
  isReportingEditing: boolean;
  showReportingCancelModal: (reportingId: number) => void;
}

interface IStates {
  isEditingNewReport: boolean;
  report: { name: string, dueDate: Date };
  openConfirmModal: boolean;
  isLoading: boolean;
  isReportDateValid: boolean;
}

class ReportList extends React.Component<IProps, IStates> {

  private defaultReport = {
    name: '',
    dueDate: new Date(),
  };

  public constructor(props: IProps) {
    super(props);
    this.state = {
      isEditingNewReport: false,
      report: { ...this.defaultReport },
      openConfirmModal: false,
      isLoading: true,
      isReportDateValid: true,
    };
    moment.locale(Utils.getCurrentUserLanguageName());
  }

  public componentDidMount() {
    if (this.props.match.params.reportingId) {
      this.props.setSelectedReportingId(+this.props.match.params.reportingId);
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>, snapshot?: any): void {
    if (prevProps.match.params.reportingId !== this.props.match.params.reportingId) {
      this.onSelectReport(+this.props.match.params.reportingId);
    }
  }

  /**
   * Display selected report details
   */
  private onSelectReport = (reportId: number) => {
    if (this.props.isReportingEditing) {
      this.props.showReportingCancelModal(reportId);
    } else {
      this.props.setSelectedReportingId(reportId);
      this.props.history.replace(`/activities-board/performance-plan/${this.props.ppId}/reporting/${reportId}`);
    }
  };

  /**
   * Handle click on add new report validation
   */
  private handleAddReportButton() {
    if (this.state.report.name !== '' && this.state.report.dueDate) {
      this.setState({ openConfirmModal: true });
    } else {
      NotificationActions.toast(
        <FormattedMessage id="errorOccurred" defaultMessage="Error" />,
        <FormattedMessage id="nameOrDueDateInvalid" defaultMessage="Name or Due date isn't correct" />,
        ToastType.ERROR,
      );
    }
  }

  /**
   * Add new report
   */
  private addNewReport = () => {
    if (this.state.report.name !== '' && this.state.report.dueDate) {
      const momentDate = moment(this.state.report.dueDate, 'DD/MM/YYYY');
      const formattedDate = moment(momentDate).format('MM/DD/YYYY');
      this.state.report.dueDate = new Date(formattedDate);
      const body: ReportingPost =  Utils.parseDateStringToDate(this.state.report, ReportingDate.DUE_DATE);
      PerformancePlanActions.emitPerformancePlanNewReport(this.props.ppId, body)
        .then(() => {
          this.props.onReportingCreated();
          NotificationActions.toast(
            <FormattedMessage id="saved" defaultMessage="Saved!" />,
            <FormattedMessage id="reportSaved" defaultMessage="Your report has been successfully created" />,
            ToastType.SUCCESS,
          );
          this.setState({
            isEditingNewReport: false,
            openConfirmModal: false,
            report: { ...this.defaultReport },
          });
        });

    } else {
      this.setState({ openConfirmModal: false });
      NotificationActions.toast(
        <FormattedMessage id="errorOccurred" defaultMessage="Error" />,
        <FormattedMessage id="nameOrDueDateInvalid" defaultMessage="Name or Due date isn't correct" />,
        ToastType.ERROR,
      );
    }
  };

  private handleReportChange = (key: string, value: string | Date) => {
    const report = { ...this.state.report };
    report[key] = value;
    this.setState(prevState => ({
      report,
      isReportDateValid: key === ReportingDate.DUE_DATE ?
      Utils.isDateFormatLanguageValid(value) : prevState.isReportDateValid,
    }));
  };

  private cancelNewReport = () => {
    this.setState({ openConfirmModal: false, isEditingNewReport: false, report: { ...this.defaultReport } });
  };

  public render() {

    if (!this.props.reportings) {
      return Utils.loader();
    }

    const reportsList = this.props.reportings && this.props.reportings.length > 0 ?
      this.props.reportings.map((report: ReportingGetFormatted) => {
        return (
          <div
            id="list-element"
            key={`report_${report.id}`}
            onClick={() => this.onSelectReport(report.id)}
            className={report.id === +this.props.match.params.reportingId ? 'selected-element' : ''}
          >
            <div>
              <div id="name">
                {report.name}
              </div>
              <div id="date">
                {report.closingDate
                  ? <>
                    <FormattedMessage id="closingDate" defaultMessage="Closing date" />:&nbsp;
                      {Utils.displayDate(report.closingDate ? report.closingDate : report.dueDate)}
                  </>
                  : <span className={report.dueDate < new Date() ? 'due-date-passed' : ''}>
                    <FormattedMessage id="dueDate" defaultMessage="Due date" />:&nbsp;
                      {Utils.displayDate(report.dueDate)}
                  </span>
                }
              </div>
            </div>
            <Icon name="chevron right" />
          </div>
        );
      }) :
      (
        <div id="no-report-message">
          <FormattedMessage
            id="pp.emptyReport"
            defaultMessage="No report"
          />
        </div>

      );

    const newReport = this.state.isEditingNewReport ?
      (
        <div id="new-element">
          <div id="name-input">
            <FormattedMessage
              id="pp.reportName"
              defaultMessage="Report name"
            >
              {msg =>
                <Form.Input
                  id="reportName"
                  placeholder={msg}
                  maxLength={30}
                  value={this.state.report.name}
                  onChange={(e: any) => this.handleReportChange('name', e.target.value)}
                />
              }
            </FormattedMessage>
          </div>
          <div id="date-input">
            <FormattedMessage
              id="pp.reportDueDate"
              defaultMessage="Report due date"
            >
              {msg =>
                <DateInput
                  name={'reportDueDate'}
                  placeholder={msg}
                  popupPosition="right center"
                  startMode="month"
                  iconPosition="left"
                  dateFormat={Utils.getDateFormat()}
                  value={this.state.report.dueDate ? Utils.displayDate(this.state.report.dueDate) : ''}
                  onChange={(e: any, date: any) =>
                    this.handleReportChange(ReportingDate.DUE_DATE, date.value)}
                  error={!this.state.isReportDateValid}
                />
              }
            </FormattedMessage>
          </div>
        </div>
      ) : null;

    let newReportButton;

    if (this.state.isEditingNewReport) {
      newReportButton = (
        <div id="add-report-buttons">
          <Button
            basic={true}
            onClick={this.cancelNewReport}
          >
            <FormattedMessage
              id="cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            id="add-button"
            disabled={!this.state.isReportDateValid}
            onClick={() => this.handleAddReportButton()}
          >
            <FormattedMessage
              id="pp.addReport"
              defaultMessage="Add report"
            />
          </Button>
        </div>
      );
    } else {
      newReportButton = (
        <Button
          id="new-button"
          basic={true}
          onClick={() => this.setState({ isEditingNewReport: true })}
        >
          <Icon name="add circle" />
          <FormattedMessage
            id="pp.newReport"
            defaultMessage="New report"
          />
        </Button>
      );
    }

    const momentDate = moment(this.state.report.dueDate, 'DD/MM/YYYY');
    const formattedDate = moment(momentDate).format('MM/DD/YYYY');
    const date = new Date(formattedDate);
    const beforeDueDate = new Date(formattedDate);
    beforeDueDate.setDate(beforeDueDate.getDate() - 7);

    const confirmModal = this.state.openConfirmModal ?
      (
        <FormattedMessage
          id="pp.confirmAddReport"
          defaultMessage="You are creating a performance plan report due on {dueDate}.
This date will be sent as a due date for your BC leaders in iBoost.
A due date is also set for PI leaders, at 7 days in advance ({beforeDueDate}).

Do you confirm?"
          values={{
            dueDate: Utils.displayDate(date),
            beforeDueDate: Utils.displayDate(beforeDueDate),
          }}
        >
          {msg =>
            <ConfirmModal
              closeNoFunction={this.cancelNewReport}
              closeYesFunction={this.addNewReport}
              title={<FormattedMessage
                id="pp.confirmAddReportTitle"
                defaultMessage="Add Report: Confirm"
              />}
              message={msg.toString()}
            />
          }

        </FormattedMessage>
      )
      : null;

    return (
      <div id="report-list-container">
        {confirmModal}
        <Segment>
          <div id="title">
            <FormattedMessage
              id="pp.report"
              defaultMessage="Reports"
            />
          </div>
          <div id="list">
            {reportsList}
            {newReport}
          </div>
          <div id="button">
            {newReportButton}
          </div>
        </Segment>
      </div>
    );
  }

}

export default withRouter(ReportList);
