import * as React from 'react';
import ModeTypes from '../../../../../../../constants/modeTypes';
import { ActionEdit } from '../../../../../../../models/action';
import User from '../../../../../../../models/user';
import './actionTilePanelDisplay.scss';
import Utils from 'src/utils/utils';
import { ActionTextField } from '../../../../../../../constants/fields';
import CustomScrollBars from '../../../../../../common/customScrollBars/customScrollBars';
import { FormattedMessage } from 'react-intl';
import TypeActivity from '../../../../../../../constants/typeActivity';
import AttachmentsManagement from '../../../../attachmentsManagement/attachmentsManagement';
import FavouriteAndHideToggle from '../../../../../../common/activityElements/favouriteToggle/favouriteAndHideToggle';
import ActionEditionButtons from '../../../../../../common/activityElements/actionEditionButtons/actionEditionButtons';
import ActionStatus from '../../../../../../common/activityElements/actionStatus/actionStatus';
import ActivityTitle from '../../../../../../common/activityElements/activityTitle/activityTitle';
import ActionFields from '../../../../../../common/activityElements/actionFields/actionFields';
import ActionAssignation from '../../../../../../common/activityElements/actionAssignation/actionAssignation';
import ActionDates from '../../../../../../common/activityElements/actionDates/actionDates';
import { IAtFormValid } from '../../../../../../../constants/formValid';
import MilestonesManagement from 'src/components/activitiesBoard/containerActivities/linkedMilestone/milestonesManagement';
import Milestone from 'src/models/milestone';

interface IProps {
  mode: ModeTypes;
  action: ActionEdit;
  isFormValid: IAtFormValid;
  canEditPi: boolean;
  segmentEditionButtonsWidth: number;
  isSaveOrCloseDisabled: boolean;
  isEditorOnError: boolean[];
  contentPanelHeight: number;
  activityId: string;
  shouldResetAttachments: boolean;
  shouldResetMilestones: boolean;
  milestones: Milestone[] | null;

  setStatus(status: number, action: ActionEdit): void;
  handleChange(key: string, value: string | User[] | number | boolean): void;
  toggleIsFavourite(): void;
  toggleCloseAction(action: ActionEdit): void;
  changeMode(mode: ModeTypes): void;
  cancelAction(action: ActionEdit): void;
  updateAction(): Promise<void>;
  closeAction(): void;
  removeAction(atId: number | null): void;
  handleDateChange({ name, value }: any, event: any): void;
  handleErrorOnEditor(isOnError: boolean[], callback: Function): void;
  setAreAttachmentsValid(areAttachmentsValid: boolean): void;
  setAttachmentsResetDone(): void;
  setMilestonesResetDone(): void;
}

const paddingMarginHeight = 16;

const actionTilePanelDisplay = (props: IProps) => {
  return (
    <CustomScrollBars style={{ height: props.contentPanelHeight - paddingMarginHeight }}>
      <div className="action-header">
        <div className="header-right-sub-container">
          <div className="favourite-hide-container">
            <FavouriteAndHideToggle
              action={props.action}
              toggleIsFavourite={props.toggleIsFavourite}
              handleChange={props.handleChange}
              mode={props.mode}
              canEditPi={props.canEditPi}
            />
          </div>
          <ActionEditionButtons
            mode={props.mode}
            rights={props.action.rights}
            isClosed={props.action.isClosed}
            atId={props.action.id}
            isFormValid={props.isFormValid}
            segmentEditionButtonsWidth={props.segmentEditionButtonsWidth}
            isSaveOrCloseDisabled={props.isSaveOrCloseDisabled}
            toggleCloseAction={() => props.toggleCloseAction(props.action)}
            changeMode={props.changeMode}
            cancelAction={() => props.cancelAction(props.action)}
            updateAction={props.updateAction}
            removeAction={() => props.removeAction(props.action.id)}
            closeAction={props.closeAction}
          />
        </div>
        <div className={`header-container-panel ${Utils.isOnEditMode(props.mode) ? 'editing' : ''}`}>
          <div className="title-container">
            <span className={`title ${Utils.isOnEditMode(props.mode) ? 'editing' : ''}`}>
              <ActionStatus
                mode={props.mode}
                setStatus={props.setStatus}
                action={props.action}
                isHorizontal={true}
              />
              <ActivityTitle
                mode={props.mode}
                handleChange={props.handleChange}
                isFormValid={props.isFormValid}
                activity={props.action}
                type={TypeActivity.ACTION}
              />
              </span>
          </div>
        </div>
      </div>
      <div className="action-content">
        <ActionFields
          isEditorOnError={props.isEditorOnError}
          mode={props.mode}
          action={props.action}
          setIsEditorOnError={props.handleErrorOnEditor}
          handleChange={props.handleChange}
          onlySpecificField={ActionTextField.OBJECTIVES}
        />
        <div className="assignations">
          <div className="holders grey-title">
            <FormattedMessage id="action.holders" defaultMessage="Action holder(s)" />
          </div>
          <ActionAssignation
            mode={props.mode}
            actionId={props.action.id}
            assignedAccounts={props.action.assignedAccounts}
            isActionActive={true}
            handleChange={props.handleChange}
            withTitle={true}
          />
        </div>
        <div className="dates-area">
          <div id="dates" className="grey-title">
            <FormattedMessage id="action.dates" defaultMessage="Dates" />
          </div>
          <ActionDates
            mode={props.mode}
            action={props.action}
            areDatesValid={props.isFormValid}
            handleDateChange={props.handleDateChange}
            alternateDisplayOnEdit={Utils.isOnEditMode(props.mode)}
          />
        </div>
        <ActionFields
          isEditorOnError={props.isEditorOnError}
          mode={props.mode}
          action={props.action}
          setIsEditorOnError={props.handleErrorOnEditor}
          handleChange={props.handleChange}
          onlySpecificField={ActionTextField.PROGRESS}
        />
        <div className="content-container action-content-container attmil">
          <AttachmentsManagement
            mode={props.mode}
            canEdit={props.canEditPi}
            activityId={props.action.id && +props.action.id}
            activityType={TypeActivity.ACTION}
            setAreAttachmentsValid={props.setAreAttachmentsValid}
            shouldResetAttachments={props.shouldResetAttachments}
            setAttachmentsResetDone={props.setAttachmentsResetDone}
          />
          <MilestonesManagement
            mode={props.mode}
            canEdit={props.canEditPi}
            activityId={+props.activityId}
            actionId={props.action.id!!}
            activityType={TypeActivity.ACTION}
            milestones={props.milestones}
            shouldResetMilestones={props.shouldResetMilestones}
            setMilestonesResetDone={props.setMilestonesResetDone}
          />
        </div>
      </div>
    </CustomScrollBars>
  );
};
export default actionTilePanelDisplay;
