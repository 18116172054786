import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'semantic-ui-react';

import BusinessChallenge from '../../../../models/businessChallenge';
import PerformanceInitiative from '../../../../models/performanceInitiative';
import ActivitiesActions from '../../../../actions/activities.action';
import ModeTypes from '../../../../constants/modeTypes';
import Textarea from '../../../common/form/textarea/textarea';
import BusinessChallengeActions from '../../../../actions/businessChallenge.action';
import PerformanceInitiativeActions from '../../../../actions/performanceInitiative.action';
import NotificationActions from '../../../../actions/notification-actions';
import { ToastType } from '../../../common/toast/toast';
import Utils from '../../../../utils/utils';

interface IProps {
  data: BusinessChallenge | PerformanceInitiative;
  openValidationModal: (isOpen: boolean) => void;
  isCloseValidationModalOpen: boolean;
}

interface IStates {
  isButtonLoading: boolean;
  comment: string;
}

class ModalButton extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      isButtonLoading: false,
      comment: '',
    };
  }

  private handleDraftValidationModalClose = () => {
    this.props.openValidationModal(false);
    this.setState({ comment: '' });
  };

  public handleDraftValidationModalAgree = () => {
    let promise;
    let message;

    if (Utils.isActivityBc()) {
      const bcData = this.props.data as BusinessChallenge;
      promise = BusinessChallengeActions.emitToggleCloseBusinessChallenge(bcData, this.state.comment);
      message = bcData.isClosed
        ? <FormattedMessage id="close.successBcUnclose" defaultMessage="This BC has been successfully unclosed" />
        : <FormattedMessage id="close.successBcClose" defaultMessage="This BC has been successfully closed" />;
    } else {
      const piData = this.props.data as PerformanceInitiative;
      promise = PerformanceInitiativeActions.emitToggleClosePerformanceInitiative(piData, this.state.comment);
      message = piData.isClosed
        ? <FormattedMessage id="close.successPiUnclose" defaultMessage="This PI has been successfully unclosed" />
        : <FormattedMessage id="close.successPiClose" defaultMessage="This PI has been successfully closed" />;
    }

    this.setState(
      { isButtonLoading: true },
      () => {
        promise
          .then(() => {
            ActivitiesActions.emitReloadTitle();
            this.props.openValidationModal(false);
            NotificationActions.toast(
              <FormattedMessage id="success" defaultMessage="Success"/>,
              message,
              ToastType.SUCCESS,
            );
            ActivitiesActions.emitChangeMode(ModeTypes.MODE_VIEW);
          })
          .catch(() => {
            Utils.toastError();
            ActivitiesActions.emitChangeMode(ModeTypes.MODE_VIEW);
          });
      });
  }

  public onChangeComment = (e: any, data: any) => {
    this.setState({ comment: data.value });
  };

  public render() {
    return (
      <>
        {this.props.isCloseValidationModalOpen && !this.props.data.isClosed &&
          <div id="validation-publication">
            <Modal
              open={this.props.isCloseValidationModalOpen}
              closeOnEscape={false}
              closeOnDimmerClick={false}
            >
              <Modal.Header>
                <FormattedMessage id="close.title" defaultMessage="Activity closure" />
              </Modal.Header>
              <Modal.Content>
                <FormattedMessage
                  id="close.placeholder"
                  defaultMessage="You are closing an activity. You can add a comment to explain your decision."
                >
                  {msg =>
                    <Textarea
                      value={this.state.comment}
                      maxlength={500}
                      onChange={this.onChangeComment}
                      placeholder={msg.toString()}
                      fixedHeight={true}
                    />
                  }
                </FormattedMessage>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => this.handleDraftValidationModalClose()}>
                  <FormattedMessage id="cancel" defaultMessage="Cancel" />
                </Button>

                <Button color="green" onClick={() => this.handleDraftValidationModalAgree()}>
                  <FormattedMessage id="confirm" defaultMessage="confirm" />
                </Button>
              </Modal.Actions>
            </Modal>
          </div>
        }
      </>
    );
  }
}

export default ModalButton;
