export enum Status {
  OK,
  KO,
  NONE,
}

export enum ColorClass {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export enum Timing {
  LOW = 500,
  HIGH = 2000,
}
