import './actionTile.scss';

import * as React from 'react';
import { Segment } from 'semantic-ui-react';

import { ActionEdit } from 'src/models/action';
import Utils from 'src/utils/utils';
import { RouteComponentProps, withRouter } from 'react-router';
import LastUpdateHighlight
  from '../../../common/overviewPanel/panelContent/tabs/lastUpdatedInformations/lastUpdateHighlight';
import { SegmentType } from '../../../../../../models/segmentsMode';
import GuideStore from '../../../../../../stores/guide.store';
import { VIEWS } from '../../../../../../constants/guideConstants';
import GuideActions from '../../../../../../actions/guide.action';
import ModeTypes from '../../../../../../constants/modeTypes';
import SegmentsActions from '../../../../../../actions/segments.action';
import { MAX_DATE_LENGTH } from 'src/constants/date';
import User from 'src/models/user';
import { ActionDateField, CommonDateField, CommonField } from 'src/constants/fields';
import ActionTileListDisplay from './actionTileListDisplay/actionTileListDisplay';
import ActionTilePanelDisplay from './actionTilePanelDisplay/actionTilePanelDisplay';
import { IAtFormValid } from '../../../../../../constants/formValid';
import moment from 'moment';
import Milestone from 'src/models/milestone';
import NotificationUtils from 'src/utils/notification.utils';

interface IRouteProps {
  id: string;
}
interface IProps extends RouteComponentProps<IRouteProps> {
  action: ActionEdit;
  activeAction: number | null;
  canEditPi: boolean;
  isForPanelDisplay: boolean;
  contentPanelHeight?: number;
  saveAction(action: ActionEdit): void;
  updateAction(action: ActionEdit): void;
  cancelAction(action: ActionEdit): void;
  toggleCloseAction(action: ActionEdit): void;
  isCodeValid(atId: number | null, code: number): boolean;
  removeAction(atId: number | null): void;
  setActiveAction?(atId: number | null): void;
  toggleToEdit?(action: ActionEdit): void;
  milestones: Milestone[];
}

interface IStates {
  isStatusEditing: boolean;
  isFormValid: IAtFormValid;
  isEditorOnError: boolean[];
  isFormValidCheck: boolean;
  mode: ModeTypes;
  segmentEditionButtonsWidth: number;
  segmentButtonLoader: boolean;
  shouldResetAttachments: boolean;
  shouldResetMilestones: boolean;
}

class ActionTile extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);

    this.state = {
      isStatusEditing: false,
      isFormValid: {
        isStartingDateValid: true,
        isForecastDateValid: true,
        isCompletionDateValid: true,
        isCodeValid: this.props.action.code !== null,
        isNameValid: this.props.action.id !== null,
        isTargetDateValid: true,
        areAttachmentsValid: true,
      },
      isFormValidCheck: false,
      isEditorOnError: [false, false],
      mode: this.props.action.id === null ? ModeTypes.MODE_EDITION : ModeTypes.MODE_VIEW,
      segmentEditionButtonsWidth: 0,
      segmentButtonLoader: true,
      shouldResetAttachments: false,
      shouldResetMilestones: false,
    };
  }

  public componentDidMount() {
    this.calculateMilestoneTileEditButton();

    const location = this.props.history.location.pathname;
    const allLocations = location.split('/');
    const highlightActionId = +allLocations[allLocations.length - 1];
    if (!Number.isNaN(highlightActionId)) {
      this.props.setActiveAction && this.props.setActiveAction(highlightActionId);
      LastUpdateHighlight.highlightParam(highlightActionId);
    }

    this.setCurrentView();
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>, snapshot?: any) {
    const isFormValidCheck = this.state.isFormValidCheck;
    const action = this.props.action;
    const isFormValid = this.state.isFormValid;

    const actionNameCondition = action.id === null && action.name.length < 0 && isFormValid.isNameValid;
    const actionCodeCondition = action.id === null && action.code < 0 && isFormValid.isCodeValid
      && this.props.isCodeValid(this.props.action.id, this.props.action.code);

    if (actionNameCondition) {
      isFormValid.isNameValid = false;
    }

    if (actionCodeCondition) {
      isFormValid.isCodeValid = false;
    }

    if (!this.props.action.targetDate) {
      isFormValid.isTargetDateValid = false;
    }

    if (!this.props.action.startingDate) {
      isFormValid.isStartingDateValid = false;
    }

    if (!isFormValidCheck) {
      this.setState({ isFormValid, isFormValidCheck: true });
    }

    this.calculateMilestoneTileEditButton();
    this.setCurrentView();
  }

  private calculateMilestoneTileEditButton = () => {
    const editButtonElement = window.document.getElementsByClassName('cancel-save-container');
    if (editButtonElement.length > 0
      && this.state.segmentEditionButtonsWidth !== editButtonElement[0].clientWidth
      && this.state.segmentButtonLoader) {
      this.setState({
        segmentEditionButtonsWidth: editButtonElement[0].clientWidth,
        segmentButtonLoader: false,
      });
    }
  }

  private setCurrentView = () => {
    if (Utils.isOnEditMode(this.state.mode) && this.isActionActive()
      && GuideStore.shouldRunGuide(VIEWS.ACTION_LIST_VIEW_ON_EDIT)) {
      GuideActions.emitUpdateCurrentView(VIEWS.ACTION_LIST_VIEW_ON_EDIT);
    }
  }

  private isActionActive = () => {
    return this.props.activeAction === this.props.action.id;
  }

  public setStatus = (status: number, action: ActionEdit) => {
    action.status = status;
    this.props.saveAction(action);
  }

  private handleErrorOnEditor = (isOnError: boolean[], callback: Function) => {
    this.setState({ isEditorOnError: isOnError }, () => callback());
  };

  public handleChangeEvent = (key: string, value: any | User[] | number | boolean, event: any) => {
    const caret = event.target.selectionStart;
    const element = event.target;
    const isFormValid = { ...this.state.isFormValid };
    switch (key) {
      case CommonField.CODE:
        isFormValid.isCodeValid = this.props.isCodeValid(this.props.action.id, value as number);
        break;
      case CommonField.NAME:
        const name = value as string;
        isFormValid.isNameValid = (name.length > 0 && name.length <= 90);
        break;
    }

    const action = { ...this.props.action };
    action[key] = value;
    this.props.saveAction(action);
    this.setState({ isFormValid });
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
  };

  public handleChange = (key: string, value: any | User[] | number | boolean) => {

    const isFormValid = { ...this.state.isFormValid };
    switch (key) {
      case CommonField.CODE:
        isFormValid.isCodeValid = this.props.isCodeValid(this.props.action.id, value as number);
        break;
      case CommonField.NAME:
        const name = value as string;
        isFormValid.isNameValid = (name.length > 0 && name.length <= 90);
        break;
    }

    const action = { ...this.props.action };
    action[key] = value;
    this.props.saveAction(action);
    this.setState({ isFormValid });
  };

  public handleDateChange = ({ name, value }: any, event: any) => {
    if (value.length > MAX_DATE_LENGTH) {
      return;
    }

    const isFormValid = { ...this.state.isFormValid };
    let error : boolean = false;
    switch (name) {
      case CommonDateField.TARGET_DATE:
        isFormValid.isTargetDateValid = value !== '' && Utils.isDateFormatLanguageValid(value);
        break;
      case ActionDateField.STARTING_DATE:
        isFormValid.isStartingDateValid = value !== '' && Utils.isDateFormatLanguageValid(value);
        const momentStarting = moment(value, Utils.getDateFormat());
        let momentTarget = moment(this.props.action.targetDate, Utils.getDateFormat());
        // if the target Date was not changed it has another format in the database
        momentTarget = momentTarget.isValid() ? momentTarget : moment(this.props.action.targetDate, 'YYYY-MM-DD');
        isFormValid.isTargetDateValid = momentStarting.isBefore(momentTarget);
        error = !isFormValid.isTargetDateValid;
        if (this.props.action.completionDate) {
          let momentCompletion = moment(this.props.action.completionDate, Utils.getDateFormat());
          // if the completion Date was not changed it has another format in the database
          momentCompletion = momentCompletion.isValid() ? momentCompletion : moment(this.props.action.completionDate, 'YYYY-MM-DD');
          isFormValid.isCompletionDateValid = momentStarting.isBefore(momentCompletion);
          error = !isFormValid.isCompletionDateValid ? true : error;
        }
        if (this.props.action.forecastDate) {
          let momentForecast = moment(this.props.action.forecastDate, Utils.getDateFormat());
          // if the forecast Date was not changed it has another format in the database
          momentForecast = momentForecast.isValid() ? momentForecast : moment(this.props.action.forecastDate, 'YYYY-MM-DD');
          isFormValid.isForecastDateValid = momentStarting.isBefore(momentForecast);
          error = !isFormValid.isForecastDateValid ? true : error;
        }
        break;
      case ActionDateField.FORECAST_DATE:
        if (value !== '') {
          isFormValid.isForecastDateValid = Utils.isDateFormatLanguageValid(value);
        }
        break;
      case CommonDateField.COMPLETION_DATE:
        if (value !== '') {
          isFormValid.isCompletionDateValid = Utils.isDateFormatLanguageValid(value);
        }
        break;
    }
    if (error) {
      NotificationUtils.sendErrorDateToast();
    }

    this.props.action[name] = value !== '' ?  moment(value, Utils.getDateFormat()).toDate() : null;

    let caretStart;
    let caretEnd;
    if (event?.target) {
      event.persist();
      caretStart = event.target.selectionStart;
      caretEnd = event.target.selectionEnd;
    }

    this.props.saveAction(this.props.action);
    this.setState({ isFormValid }, () => {
      if (event.target) {
        // Prevent cursor to jump at the end
        setTimeout(() => {
          event.target.setSelectionRange(caretStart, caretEnd);
        });
      }
    });
  };

  private isSaveOrCloseDisabled = () => {
    return Object.values(this.state.isFormValid).some(el => !el) || this.state.isEditorOnError.some(el => el);
  };

  private toggleIsFavourite = () => {
    this.handleChange('isFavourite', !this.props.action.isFavourite);
  };

  private changeMode = (mode: ModeTypes) => {
    this.setState(prevState => ({
      mode: mode ?? prevState.mode,
    }));
  }

  private updateAction = (): Promise<void> => {
    this.props.updateAction(this.props.action);
    return Promise.resolve();
  }

  private closeAction = () => {
    const segmentType = `${SegmentType.AT_ACTION}-${this.props.action.id}`;
    this.changeMode(ModeTypes.MODE_VIEW);
    SegmentsActions.emitSegmentMode(segmentType, ModeTypes.MODE_VIEW);
    this.props.toggleCloseAction(this.props.action);
  }

  private setAreAttachmentsValid = (areAttachmentsValid: boolean) => {
    const isFormValid = { ...this.state.isFormValid };
    isFormValid.areAttachmentsValid = areAttachmentsValid;
    this.setState({ isFormValid });
  }

  private onActionCancel = (action: ActionEdit) => {
    this.setState({ shouldResetAttachments: true, shouldResetMilestones: true, isEditorOnError: [false, false] },
                  () => this.props.cancelAction(action),
    );
  }

  private setAttachmentsResetDone = () => {
    this.setState({ shouldResetAttachments: false });
  }

  private setMilestonesResetDone = () => {
    this.setState({ shouldResetMilestones: false });
  }

  public render() {
    const actionStyle = `
    ${this.props.action.isClosed ? 'action-closed' : ''}
    ${this.props.isForPanelDisplay ? 'tile-container-panel' : 'tile-container'}
    `;
    return (
      <Segment
        id={LastUpdateHighlight.getSegmentId(SegmentType.AT_ACTION, this.props.action.id)}
        className={actionStyle}
      >
        {!this.props.isForPanelDisplay ?
          <ActionTileListDisplay
            mode={this.state.mode}
            updateAction={this.updateAction}
            closeAction={this.closeAction}
            toggleIsFavourite={this.toggleIsFavourite}
            isSaveOrCloseDisabled={this.isSaveOrCloseDisabled()}
            isActionActive={this.isActionActive()}
            segmentEditionButtonsWidth={this.state.segmentEditionButtonsWidth}
            action={this.props.action}
            isFormValid={this.state.isFormValid}
            canEditPi={this.props.canEditPi}
            setStatus={this.setStatus}
            handleChange={this.handleChange}
            handleChangeEvent={this.handleChangeEvent}
            changeMode={this.changeMode}
            cancelAction={this.onActionCancel}
            toggleCloseAction={this.props.toggleCloseAction}
            toggleToEdit={this.props.toggleToEdit!!}
            removeAction={this.props.removeAction}
            setActiveAction={this.props.setActiveAction!!}
            activityId={this.props.match.params.id}
            handleErrorOnEditor={this.handleErrorOnEditor}
            isEditorOnError={this.state.isEditorOnError}
            handleDateChange={this.handleDateChange}
            setAreAttachmentsValid={this.setAreAttachmentsValid}
            shouldResetAttachments={this.state.shouldResetAttachments}
            shouldResetMilestones={this.state.shouldResetMilestones}
            setAttachmentsResetDone={this.setAttachmentsResetDone}
            setMilestonesResetDone={this.setMilestonesResetDone}
            milestones={this.props.milestones}

          />
          :
          <ActionTilePanelDisplay
            mode={this.state.mode}
            action={this.props.action}
            isFormValid={this.state.isFormValid}
            canEditPi={this.props.canEditPi}
            segmentEditionButtonsWidth={this.state.segmentEditionButtonsWidth}
            isSaveOrCloseDisabled={this.isSaveOrCloseDisabled()}
            isEditorOnError={this.state.isEditorOnError}
            setStatus={this.setStatus}
            handleChange={this.handleChange}
            toggleIsFavourite={this.toggleIsFavourite}
            toggleCloseAction={this.props.toggleCloseAction}
            changeMode={this.changeMode}
            cancelAction={this.onActionCancel}
            updateAction={this.updateAction}
            closeAction={this.closeAction}
            removeAction={this.props.removeAction}
            handleDateChange={this.handleDateChange}
            handleErrorOnEditor={this.handleErrorOnEditor}
            contentPanelHeight={this.props.contentPanelHeight!!}
            activityId={this.props.match.params.id}
            setAreAttachmentsValid={this.setAreAttachmentsValid}
            shouldResetAttachments={this.state.shouldResetAttachments}
            setAttachmentsResetDone={this.setAttachmentsResetDone}
            shouldResetMilestones={this.state.shouldResetMilestones}
            setMilestonesResetDone={this.setMilestonesResetDone}
            milestones={this.props.milestones}
          />
        }
      </Segment>
    );
  }
}

export default withRouter(ActionTile);
