import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import MailTeamModal from './mailTeamModal/mailTeamModal';
import PerformancePlan from '../../../../../../models/performancePlan';
import BusinessChallenge from '../../../../../../models/businessChallenge';
import PerformanceInitiative from '../../../../../../models/performanceInitiative';
import TypeActivity from '../../../../../../constants/typeActivity';
import Utils from '../../../../../../utils/utils';
import PerformancePlanAPI from '../../../../../../api/performancePlan.api';
import BusinessChallengeAPI from '../../../../../../api/businessChallenge.api';
import PerformanceInitiativeAPI from '../../../../../../api/performanceInitiative.api';

interface IProps {
  activityId: number;
  activityType: TypeActivity;
}

interface IStates {
  showMailModal: boolean;
  activityName: string;
  activity: PerformancePlan | BusinessChallenge | PerformanceInitiative | undefined;
  isLoading: boolean;
}

class MailTeam extends React.Component<IProps, IStates> {

  constructor(props) {
    super(props);
    this.state = {
      showMailModal: false,
      activityName: '',
      activity: undefined,
      isLoading: false,
    };
  }

  private onModalDisplay = () => {
    this.setState(
      { isLoading: true },
      () => {
        let request;
        switch (this.props.activityType) {
          case TypeActivity.PERFORMANCE_PLAN:
            request = PerformancePlanAPI.getPpForTeamEmail(this.props.activityId);
            break;
          case TypeActivity.BUSINESS_CHALLENGE:
            request = BusinessChallengeAPI.getBcForTeamEmail(this.props.activityId);
            break;
          case TypeActivity.PERFORMANCE_INITIATIVE:
            request = PerformanceInitiativeAPI.getPiForTeamEmail(this.props.activityId);
            break;
          default:
            break;
        }

        request
          .then((activity) => {
            this.setState({ activity, showMailModal: true, isLoading: false });
            this.generateActivityName();
          })
          .catch(() => this.setState({ isLoading: false }));
      },
    );
  }

  generateActivityName = () => {
    let activity;
    let activityName = '';
    switch (this.props.activityType) {
      case TypeActivity.PERFORMANCE_PLAN:
        activity = this.state.activity as PerformancePlan;
        activityName = Utils.formatPpNameWithYear(activity.name, activity.year);
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        activity = this.state.activity as BusinessChallenge;
        activityName = `${Utils.formatActivityName(activity.code, activity.name, this.props.activityType)} `;
        activityName += `(${Utils.formatPpNameWithYear(activity.performancePlan.name, activity.performancePlan.year)})`;
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        activity = this.state.activity as PerformanceInitiative;
        activityName = `${Utils.formatActivityName(activity.code, activity.name, this.props.activityType)} `;
        activityName += `(${Utils.formatPpNameWithYear(activity.businessChallenge.performancePlan.name, activity.businessChallenge.performancePlan.year)}`;
        activityName += ` ${Utils.formatActivityName(activity.businessChallenge.code, activity.businessChallenge.name, TypeActivity.BUSINESS_CHALLENGE)})`;
        break;
    }
    activityName = activityName.replace(new RegExp('&', 'g'), '%26');
    activityName = activityName.replace(new RegExp('#', 'g'), '%23');
    this.setState({ activityName });
  }

  render() {
    return (
      <>
        <Button basic={true} onClick={this.onModalDisplay} loading={this.state.isLoading}>
          <Icon name="mail" />
          <FormattedMessage id="mailTeam.contact" defaultMessage="Contact Team"/>
        </Button>
        {this.state.activity && this.state.showMailModal &&
          <MailTeamModal
            showModal={this.state.showMailModal}
            handleClosure={() => this.setState({ showMailModal: false })}
            activityName={this.state.activityName}
            activityId={this.state.activity.id}
            activityType={this.props.activityType}
            childrenToFilterOn={Utils.isActivityPp(this.props.activityType)
              ? (this.state.activity as PerformancePlan).businessChallenges : []}
          />
        }
      </>
    );
  }
}

export default MailTeam;
