import './linkedActivityRow.scss';

import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Divider, Label } from 'semantic-ui-react';

import { BcLinked } from '../../../../../models/businessChallenge';
import Utils from '../../../../../utils/utils';
import TypeActivity from '../../../../../constants/typeActivity';
import { PiLinked } from '../../../../../models/performanceInitiative';
import { PpLinked } from '../../../../../models/performancePlan';
import { PpTabs } from '../../contentPP/contentPP';
import { BcTabs } from '../../contentBC/contentBC';

interface IProps extends RouteComponentProps {
  activity: BcLinked;
  isLastActivity: boolean;
}

class LinkedActivityRow extends React.Component<IProps> {

  handleRedirection = (ppId: number, activityType: TypeActivity, bcId?: number) => {
    if (Utils.isActivityBc(activityType)) {
      this.props.history.push(`/activities-board/performance-plan/${ppId}/${PpTabs.COCKPIT}`);
    } else {
      this.props.history
        .push(`/activities-board/${ppId}/business-challenge/${bcId}/${BcTabs.COCKPIT}`);
    }
  };

  generatePpName = (pp: PpLinked, withSeparation = false) => {
    return (<span>{pp.name} <span className="pp-year">({pp.year})</span>{withSeparation ? ' : ' : ''}</span>);
  }

  generatePiRow = (pi: PiLinked) => {
    return (
      <div key={pi.id} className="pi-row">
        <div className="grey-title">
          {Utils.formatActivityCode(pi.code, TypeActivity.PERFORMANCE_INITIATIVE)}:
        </div>
        {pi.linkedBcs && pi.linkedBcs.map(bc =>
          <Label
            key={bc.id}
            className="ui label"
            onClick={() => this.handleRedirection(bc.linkedPps[0].id, TypeActivity.PERFORMANCE_INITIATIVE, bc.id)}
          >
            {this.generatePpName(bc.linkedPps[0], true)}
            {Utils.formatActivityName(bc.code, bc.name, TypeActivity.BUSINESS_CHALLENGE)}
          </Label>,
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="activity-row">
        <div className="bc-row">
          <div className="grey-title">
            {Utils.formatActivityCode(this.props.activity.code, TypeActivity.BUSINESS_CHALLENGE)}:
          </div>
          {this.props.activity.linkedPps && this.props.activity.linkedPps.map(pp =>
            <Label
              key={pp.id}
              className="ui label"
              onClick={() => this.handleRedirection(pp.id, TypeActivity.BUSINESS_CHALLENGE)}
            >
              {this.generatePpName(pp)}
            </Label>,
          )}
        </div>
        <div className="pis-area">
          {this.props.activity.pis && this.props.activity.pis.map(pi => this.generatePiRow(pi))}
        </div>
        {!this.props.isLastActivity && <Divider />}
      </div>

    );
  }
}
export default withRouter(LinkedActivityRow);
