import exportStore from '../stores/export.store';
import ExportActions from '../actions/export.action';

export default class ExportService {

  private static timerID: NodeJS.Timeout;
  private static readonly tick = 1000;

  public static async startPullingPdfProgression() {

    if (this.timerID) {
      this.stopPullingPdfProgression();
    }

    this.timerID = setInterval(
      () => {
        const pdfId = exportStore.getPdfId();
        if (exportStore.getIsPdfGenerating()) {
          ExportActions.emitPdfGenerationProgression(pdfId);
        } else {
          this.stopPullingPdfProgression();
        }
      },
      this.tick,
    );
  }

  private static async stopPullingPdfProgression() {
    clearInterval(this.timerID);
  }
}
