import History from 'src/models/history';

import HistoryAPI from '../api/history.api';
import ActionTypes from '../constants/actionTypes';
import Dispatcher from '../dispatcher';
import BusinessChallenge from '../models/businessChallenge';
import PerformanceInitiative from '../models/performanceInitiative';
import PerformancePlan from '../models/performancePlan';

export default class HistoryActions {

  /**
   * Get all histories of an activity from API and emit actions GET_HISTORY
   * @return Promise<any>
   */
  public static emitGetHistoryVersions(activityRoute: string, id: number): Promise<any> {
    return HistoryAPI.getHistories(activityRoute, id)
      .then((histories: History[]) => {
        Dispatcher.dispatch({
          histories,
          actionType: ActionTypes.GET_HISTORIES,
        });
      });
  }

  /**
   * Get a PP history from API and emit actions GET_ACTIVITY_HISTORY
   * @return Promise<any>
   */
  public static emitGetPPHistory(id: number, historyId: number): Promise<any> {
    return HistoryAPI.getPpHistoryById(id, historyId)
      .then((pp: PerformancePlan) => {
        Dispatcher.dispatch({
          pp,
          actionType: ActionTypes.GET_PP_HISTORY,
        });
      });
  }

  /**
   * Get a BC history from API and emit actions GET_ACTIVITY_HISTORY
   * @return Promise<any>
   */
  public static emitGetBCHistory(id: number, historyId: number): Promise<any> {
    return HistoryAPI.getBcHistoryById(id, historyId)
      .then((bc: BusinessChallenge) => {
        Dispatcher.dispatch({
          bc,
          actionType: ActionTypes.GET_BC_HISTORY,
        });
      });
  }

  /**
   * Get a PI history from API and emit actions GET_ACTIVITY_HISTORY
   * @return Promise<any>
   */
  public static emitGetPIHistory(id: number, historyId: number): Promise<any> {
    return HistoryAPI.getPiHistoryById(id, historyId)
      .then((pi: PerformanceInitiative) => {
        Dispatcher.dispatch({
          pi,
          actionType: ActionTypes.GET_PI_HISTORY,
        });
      });
  }
}
