import './ppYearsFilter.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import UserActions from 'src/actions/user.action';
import ActionTypes from 'src/constants/actionTypes';
import UserStore from 'src/stores/user.store';
import ActivitiesStore from 'src/stores/activities.store';
import PerformancePlan from 'src/models/performancePlan';

interface IProps {
  userId: number;
}

interface IStates {
  selectedYears: Record<string, boolean>;
  performancePlans: PerformancePlan[];
  numberPlansByYear: Record<string, number>;
  possibleYears: string[];
}

class PpYearsFilter extends React.Component<IProps, IStates> {

  private isMount = false;

  //region REACT LIFECYCLE METHODS
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedYears: {},
      performancePlans: ActivitiesStore.getActivities(),
      numberPlansByYear: {},
      possibleYears: [],
    };
  }

  public componentDidMount(): void {
    this.isMount = true;

    UserStore.addListener(ActionTypes.USER_SELECTED_YEARS_GET.toString(), this.getSelectedYears);
    ActivitiesStore.addListener(ActionTypes.ACTIVITIES_GET_MENU.toString(), this.getPerformancePlans);
    this.setState({ selectedYears: UserStore.getSelectedYears(), possibleYears: UserStore.getPossibleYears() });
    this.getNumberPlan();
  }

  public componentWillUnmount(): void {
    this.isMount = false;
    UserStore.removeListener(ActionTypes.USER_SELECTED_YEARS_GET.toString(), this.getSelectedYears);
    ActivitiesStore.removeListener(ActionTypes.ACTIVITIES_GET_MENU.toString(), this.getPerformancePlans);
  }
  //endregion

  private getSelectedYears() {
    if (this.isMount) {
      this.setState({ selectedYears: UserStore.getSelectedYears(), possibleYears: UserStore.getPossibleYears() });
    }
  }

  private getPerformancePlans() {
    if (this.isMount) {
      this.setState({ performancePlans: ActivitiesStore.getActivities() });
    }
  }

  private getNumberPlan() {
    const numberPlansByYear = this.state.numberPlansByYear;
    if (this.state.performancePlans) {
      for (const pp of this.state.performancePlans) {
        if (numberPlansByYear[pp.year]) {
          numberPlansByYear[pp.year] += 1;
        } else {
          numberPlansByYear[pp.year] = 1;
        }
      }
      this.setState({ numberPlansByYear });
    }
  }
  /**
   * Update the user's default PP
   * @param newPpId
   */
  private updateSelectedYearsData() {
    const years = Object.entries(this.state.selectedYears)
                      .filter(([_, value]) => value)
                      .map(([key, _]) => key)
                      .join('/');
    UserActions.emitChangeSelectedYears(this.props.userId, years, this.state.selectedYears);
  }

  private updateSelectedYears(year: string) {
    const selectedYears = this.state.selectedYears;
    selectedYears[year] = !selectedYears[year];
    this.setState({ selectedYears });
    this.updateSelectedYearsData();
  }

  public render() {
    return (
      <div id="selected-years-container">
        <h5><FormattedMessage id="selectedYears" defaultMessage="Performance Plans Years Filter"/></h5>
        {this.state.possibleYears.map(year => (
        <div key={year} className="checkbox-years">
          <input
            type="checkbox"
            className= "checkbox-year"
            value={year}
            checked={this.state.selectedYears[year] || false}
            onChange={() => this.updateSelectedYears(year)}
            disabled={this.state.numberPlansByYear[year] ? false : true}
          />
          <div id="label-container-checkbox">
            <label id="label-checkbox">{year}</label>
            <label>(You have {this.state.numberPlansByYear[year] ?
              this.state.numberPlansByYear[year] : 0} plans for this year)</label>
          </div>
        </div>
      ))}
      </div>
    );
  }
}

export default PpYearsFilter;
