import './organisation.scss';

import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Button, DropdownItemProps, Form, Label, Popup, Segment, Table } from 'semantic-ui-react';

import ActionTypes from 'src/constants/actionTypes';
import PerformancePlan from 'src/models/performancePlan';
import Utils from 'src/utils/utils';
import PerformancePlanStore from '../../../../stores/performancePlan.store';
import PerformancePlanActions from '../../../../actions/performancePlan.action';
import ExploreAPI from '../../../../api/explore.api';
import CustomScrollBars from '../../../common/customScrollBars/customScrollBars';
import TypeActivity from '../../../../constants/typeActivity';
import ActivitiesStore from '../../../../stores/activities.store';
import ActivitiesActions from '../../../../actions/activities.action';

interface IStates {
  selectedYear: any;
  availableYears: DropdownItemProps[] | undefined;
  data: Data | undefined;
  ppList: PerformancePlan[];
  ppTilePerCell: number;
  isLoading: boolean;

  selectedGbuIndex?: number;
  selectedBusinessLineIndex?: number;
  selectedSegmentIndex?: number;

  selectedGeographyIndex?: number;
  selectedCountryIndex?: number;
  selectedSubcountryIndex?: number;
}

enum SelectKeys {
  GBU = 'selectedGbuIndex',
  BUSINESS_LINE = 'selectedBusinessLineIndex',
  SEGMENT = 'selectedSegmentIndex',

  GEOGRAPHY = 'selectedGeographyIndex',
  COUNTRY = 'selectedCountryIndex',
  SUBCOUNTRY = 'selectedSubcountryIndex',
}

interface Indexes {
  gbuIndex?: number;
  blIndex?: number;
  segmentIndex?: number;

  geoIndex?: number;
  countryIndex?: number;
  subcountryIndex?: number;
}

interface IdName {
  id: number;
  name: string;
}

interface Gbu extends IdName {
  businessLines: BusinessLine[];
}

interface BusinessLine extends IdName {
  segments: Segment[];
}

interface Segment extends IdName {
}

interface Geography extends IdName {
  countries: Country[];
}

interface Country extends IdName {
  tag: string;
  subcountries: Subcountry[];
}

interface Subcountry extends IdName {
  tag: string;
}

interface Index {
  index: number;
}

interface RelationGbu extends Index {
  businessLine?: RelationBl;
}

interface RelationBl extends Index {
  segment?: RelationSg;
}

interface RelationSg extends Index {
}

interface RelationGeo extends Index {
  country?: RelationCo;
}

interface RelationCo extends Index {
  subcountry?: SubDTO;
}

interface SubDTO extends Index {
}

interface Relation {
  gbu: RelationGbu;
  geography: RelationGeo;
  pps: { id: number, name: string }[];
}

interface Data {
  gbus: Gbu[];
  geographies: Geography[];
  relations: Relation[];
}

interface Headers {
  first?: ColumnHeader;
  second: ColumnHeader[];
}

class ColumnHeader {
  gbuIndex?: number;
  blIndex?: number;
  segmentIndex?: number;

  gbu?: Gbu;
  businessLine?: BusinessLine;
  segment?: Segment;
}

class Organisation extends React.Component<RouteComponentProps & InjectedIntlProps, IStates> {

  private isMount = false;
  private timeout: NodeJS.Timeout;

  public constructor(props: RouteComponentProps & InjectedIntlProps) {
    super(props);
    this.state = {
      selectedYear: this.getCurrentYear(),
      availableYears: Organisation.formatYears(PerformancePlanStore.getYears()),
      data: undefined,
      ppList: [],
      ppTilePerCell: Organisation.getPpTilePerCell(),
      isLoading: true,
    };
  }

  public componentWillMount() {
    this.isMount = true;

    PerformancePlanStore.addListener(ActionTypes.PERFORMANCE_PLAN_YEARS_GET.toString(), this.getYears);

    if (this.state.availableYears === undefined) {
      PerformancePlanActions.emitGetPerformancePlanYears();
    }
  }

  public componentDidMount() {
    this.getData();

    if (ActivitiesStore.getActivities() === undefined) {
      ActivitiesActions.emitGetActivities();
    }

    window.addEventListener('resize', this.handleResize);
  }

  public componentWillUnmount() {
    this.isMount = false;
    PerformancePlanStore.removeListener(ActionTypes.PERFORMANCE_PLAN_YEARS_GET.toString(), this.getYears);

    window.removeEventListener('resize', this.handleResize);
  }

  private getYears = () => {
    if (this.isMount) {
      this.setState({ availableYears: Organisation.formatYears(PerformancePlanStore.getYears()) });
    }
  };

  private getData(year = this.getCurrentYear()) {
    ExploreAPI.getActivities(year)
      .then((data: Data) => {
        this.setState({ data, isLoading: false });
        this.toggleSelected(undefined, undefined, true);
      });
  }

  private static getPpTilePerCell(): number {
    const WIDTH = 1750;
    const ONE_COLUMN = 2;
    const TWO_COLUMNS = 5;

    return window.innerWidth > WIDTH ? TWO_COLUMNS : ONE_COLUMN;
  }

  private handleResize = () => {
    if (this.isMount) {
      // Wait 100ms between each resize, to prevent too many state modifications and re-renders
      clearTimeout(this.timeout);

      this.timeout = setTimeout(
        () => {
          this.setState({ ppTilePerCell: Organisation.getPpTilePerCell() });
        },
        100,
      );
    }
  };

  private static formatYears(years: string[] | undefined) {
    return !!years ? years.map(year => ({ key: year, text: year, value: year })) : undefined;
  }

  private getCurrentYear(): string {
    const currentDate = new Date();
    const octoberMonth = 9;
    let currentYear = currentDate.getFullYear();
    // it is necessary to check to see if there is plan in the next year
    if (currentDate.getMonth() >= octoberMonth && this.state &&
        this.state.availableYears && currentYear in this.state.availableYears) {
      currentYear = currentYear + 1;
    }
    return currentYear.toString();
  }

  private onYearChange(value: string) {
    this.setState(
      { selectedYear: value === 'All' ? '' : value, isLoading: true },
      () => {
        this.getData(value);
      },
    );
  }

  private onPpClick = (ppId: number) => {
    if (Utils.isExternalView(ppId, TypeActivity.PERFORMANCE_PLAN)) {
      this.props.history.push(`/pp-external/${ppId}`);
    } else {
      this.props.history.push(`/activities-board/performance-plan/${ppId}`);
    }
  };

  private getPpCardsByIndexes(indexes: Indexes, hasLimitedCardNumber = true) {
    const { gbuIndex, blIndex, segmentIndex, geoIndex, countryIndex, subcountryIndex } = indexes;

    let relations = this.state.data!.relations
      .filter(rel => rel.gbu.index === gbuIndex && rel.geography.index === geoIndex);

    if (this.state.selectedGeographyIndex === geoIndex && countryIndex === undefined) {
      relations = relations.filter(rel => rel.geography.country === undefined);
    }

    if (this.state.selectedCountryIndex !== undefined &&
      this.state.selectedCountryIndex === countryIndex && subcountryIndex === undefined) {
      relations = relations
        .filter(rel => rel.geography.country === undefined || rel.geography.country.subcountry === undefined);
    }

    if (blIndex !== undefined) {
      if (segmentIndex !== undefined) {
        relations = relations.filter(rel =>
                                       rel.gbu.businessLine && rel.gbu.businessLine.segment &&
                                       rel.gbu.businessLine.segment.index === segmentIndex);
      } else {
        relations = relations.filter(rel => rel.gbu.businessLine && rel.gbu.businessLine.index === blIndex);
      }
    }

    if (countryIndex !== undefined) {
      if (subcountryIndex !== undefined) {
        relations = relations.filter(rel =>
                                       rel.geography.country && rel.geography.country.subcountry &&
                                       rel.geography.country.subcountry.index === subcountryIndex);
      } else {
        relations = relations.filter(rel => rel.geography.country && rel.geography.country.index === countryIndex);
      }
    }

    const count = relations.reduce((sum, rel) => sum + rel.pps.length, 0);
    let nbPp = 0;

    const ppList: { id: number, name: string }[] = [];
    relations.forEach(rel => rel.pps.forEach(pp => ppList.push(pp)));

    const getPpCard = (pp: { id: number, name: string }) => (
      <Label key={`pp-card${pp.id}`} className="pp-card primary" onClick={() => this.onPpClick(pp.id)}>
        <div className="pp-name">
          {pp.name}
        </div>

        <div className="pp-card-tooltip">
          <span>{pp.name}</span>
          <div className="caret"/>
        </div>
      </Label>
    );

    return (
      <div className="pp-card-container">
        {ppList.map((pp, index) => {
          nbPp += 1;
          if (hasLimitedCardNumber && nbPp > this.state.ppTilePerCell && nbPp !== count) {
            return (
              <Popup
                key={`pp-card-popup-${index}`}
                content={(
                  <div className="pp-card-popup">
                    {ppList.splice(index).map(pp => getPpCard(pp))}
                  </div>
                )}
                trigger={
                  <Label className="pp-card">
                    {count - nbPp + 1} <FormattedMessage id="explore.organisation.more" defaultMessage="more"/>
                  </Label>
                }
                flowing={true}
                hoverable={true}
                size="tiny"
                position="bottom center"
              />
            );
          }

          return getPpCard(pp);
        })}
      </div>
    );
  }

  private getDefaultSelectedValues() {
    let defaultSelectedGeographyIndex: number | undefined = undefined;
    if (!!this.state.data) {
      const majorCountryIndex = this.state.data.geographies.findIndex(geo => geo.id === 2);
      if (majorCountryIndex !== -1) defaultSelectedGeographyIndex = majorCountryIndex;
    }

    return {
      selectedGbuIndex: undefined,
      selectedBusinessLineIndex: undefined,
      selectedSegmentIndex: undefined,
      selectedGeographyIndex: defaultSelectedGeographyIndex,
      selectedCountryIndex: undefined,
      selectedSubcountryIndex: undefined,
    };
  }

  private toggleSelected(key?: SelectKeys, value?: number, reset = false) {
    if (reset) {
      this.setState(this.getDefaultSelectedValues());
    }

    switch (key) {
      case SelectKeys.GBU:
        let selectedGbuIndex: number | undefined = value;
        if (this.state.selectedGbuIndex === value) selectedGbuIndex = undefined;

        this.setState({
          selectedGbuIndex,
          selectedBusinessLineIndex: undefined,
          selectedSegmentIndex: undefined,
        });
        break;

      case SelectKeys.BUSINESS_LINE:
        let selectedBusinessLineIndex: number | undefined = value;
        if (this.state.selectedBusinessLineIndex === value) selectedBusinessLineIndex = undefined;
        this.setState({
          selectedBusinessLineIndex,
          selectedSegmentIndex: undefined,
        });
        break;

      case SelectKeys.SEGMENT:
        this.setState({ selectedSegmentIndex: this.state.selectedSegmentIndex === value ? undefined : value });
        break;

      case SelectKeys.COUNTRY:
        let selectedCountryIndex: number | undefined = value;
        if (this.state.selectedCountryIndex === value) selectedCountryIndex = undefined;
        this.setState({
          selectedCountryIndex,
          selectedSubcountryIndex: undefined,
        });
        break;

      case SelectKeys.SUBCOUNTRY:
        this.setState({ selectedSubcountryIndex: this.state.selectedSubcountryIndex === value ? undefined : value });
        break;

      default:
        break;
    }
  }

  private hasValues(key: SelectKeys, indexes: Indexes, dgdiFirstRow = false): boolean {
    if (!this.state.data) return false;

    const { gbuIndex, blIndex, segmentIndex, geoIndex, countryIndex, subcountryIndex } = indexes;

    const getGbuCondition = (rel: Relation, condition: boolean) => this.state.selectedGbuIndex !== undefined
      ? condition && rel.gbu.index === this.state.selectedGbuIndex
      : condition;

    let hasValues = false;
    switch (key) {
      case SelectKeys.GBU:
        hasValues = this.state.data.relations.some(rel => rel.gbu.index === gbuIndex);
        break;

      case SelectKeys.BUSINESS_LINE:
        hasValues = this.state.data.relations
          .some(rel => rel.gbu.index === gbuIndex && rel.gbu.businessLine?.index === blIndex);
        break;

      case SelectKeys.SEGMENT:
        hasValues = this.state.data.relations
          .some(rel => rel.gbu.index === gbuIndex && rel.gbu.businessLine?.index === blIndex
            && rel.gbu.businessLine?.segment?.index === segmentIndex);
        break;

      case SelectKeys.GEOGRAPHY:
        let relations = [...this.state.data.relations];
        if (dgdiFirstRow) relations = relations.filter(rel => rel.gbu.index !== 0);
        hasValues = relations.some(rel => getGbuCondition(rel, rel.geography.index === geoIndex));
        break;

      case SelectKeys.COUNTRY:
        hasValues = this.state.data.relations.some(rel => getGbuCondition(
          rel, rel.geography.index === geoIndex && rel.geography.country?.index === countryIndex));
        break;

      case SelectKeys.SUBCOUNTRY:
        hasValues = this.state.data.relations.some(rel => getGbuCondition(
          rel, rel.geography.index === geoIndex && rel.geography.country?.index === countryIndex
          && rel.geography.country?.subcountry?.index === subcountryIndex));
        break;
    }

    return hasValues;
  }

  private setFirstLine(firstLine: ColumnHeader) {
    if (!firstLine.gbu || firstLine.gbuIndex === undefined) return;

    const colSpan = !!firstLine.businessLine
      ? firstLine.businessLine.segments.length
      : firstLine.gbu.businessLines.length;

    const isGbuParent = firstLine.gbu && firstLine.gbu.businessLines.length > 0;
    const isBlParent = firstLine.businessLine && firstLine.businessLine.segments.length > 0;

    return (
      <Table.Cell
        key={`gbu-${firstLine.gbuIndex}`}
        className="gbu"
        colSpan={colSpan}
      >
        <span
          className={isGbuParent ? 'parent' : ''}
          onClick={() => isGbuParent && this.toggleSelected(SelectKeys.GBU, firstLine.gbuIndex!)}
        >
          {firstLine.gbu.id !== 1 && firstLine.gbu.name}
        </span>
        {firstLine.businessLine &&
        <span className="sub-element">
            {' > '}
          <span
            className={isGbuParent ? 'parent' : ''}
            onClick={() => isBlParent && this.toggleSelected(SelectKeys.BUSINESS_LINE, firstLine.blIndex!)}
          >
            {firstLine.businessLine.name}
          </span>
          </span>
        }
      </Table.Cell>
    );
  }

  private setSecondLine(secondLines: ColumnHeader[], isFirstHeaderPresent: boolean) {
    return (
      <>
        {isFirstHeaderPresent && <Table.Cell/>}
        {secondLines.map((secondLine) => {
          let title = '';
          let isParent = false;
          let item: Gbu | BusinessLine | Segment | undefined;
          let index: number | undefined;
          let hasValues = false;
          let onClick;

          if (!!secondLine.gbu && secondLine.gbuIndex !== undefined) {
            title = 'gbu';
            isParent = secondLine.gbu!.businessLines.length > 0;
            item = secondLine.gbu;
            index = secondLine.gbuIndex;
            hasValues = this.hasValues(SelectKeys.GBU, { gbuIndex: secondLine.gbuIndex });
            onClick = () => hasValues && isParent && this.toggleSelected(SelectKeys.GBU, secondLine.gbuIndex!);

          } else if (!!secondLine.businessLine && secondLine.blIndex !== undefined) {
            title = 'business-line';
            isParent = secondLine.businessLine!.segments.length > 0;
            item = secondLine.businessLine;
            index = secondLine.blIndex;
            hasValues = this.hasValues(
              SelectKeys.BUSINESS_LINE,
              { gbuIndex: secondLine.gbuIndex, blIndex: secondLine.blIndex },
            );
            onClick = () => hasValues && isParent && this.toggleSelected(SelectKeys.BUSINESS_LINE, secondLine.blIndex!);

          } else if (!!secondLine.segment && secondLine.segmentIndex !== undefined) {
            title = 'segment';
            item = secondLine.segment;
            index = secondLine.segmentIndex;
            hasValues = this.hasValues(
              SelectKeys.SEGMENT,
              { gbuIndex: secondLine.gbuIndex, blIndex: secondLine.blIndex, segmentIndex: secondLine.segmentIndex },
            );
          }

          if (!!item && index !== undefined) {
            const key = `businessLine-${index}`;
            if (item.id === 1) return <Table.Cell key={key}/>;

            return (
              <Table.Cell
                key={key}
                className={`${title} ${Organisation.isOddClass(secondLine)} ${isParent && hasValues ? 'parent' : ''} ${hasValues ? '' : 'empty-col'}`}
                onClick={onClick}
              >
                {item.id !== 1 && item.name}
              </Table.Cell>
            );
          }

          return null;
        })}
      </>
    );
  }

  private getHeaders(): Headers {
    let headers: Headers = { second: [] };

    if (this.state.selectedGbuIndex === undefined) {
      headers.second = this.state.data!.gbus.map((gbu, gbuIndex) => ({ gbu, gbuIndex }));
    } else {
      const gbu = this.state.data!.gbus[this.state.selectedGbuIndex];

      if (this.state.selectedBusinessLineIndex === undefined) {
        const parentIndex = { gbuIndex: this.state.selectedGbuIndex };
        headers = {
          first: { ...parentIndex, gbu },
          second: gbu.businessLines.map((businessLine, blIndex) => ({ ...parentIndex, businessLine, blIndex })),
        };
      } else {
        const businessLine = gbu.businessLines[this.state.selectedBusinessLineIndex];

        if (this.state.selectedSegmentIndex === undefined) {
          const parentIndex = { gbuIndex: this.state.selectedGbuIndex, blIndex: this.state.selectedBusinessLineIndex };
          headers = {
            first: { ...parentIndex, gbu, businessLine },
            second: businessLine.segments.map((segment, segmentIndex) => ({ ...parentIndex, segment, segmentIndex })),
          };
        }
      }
    }

    return headers;
  }

  private renderColumns(headers: Headers) {
    const { selectedGeographyIndex, selectedGbuIndex } = this.getDefaultSelectedValues();

    const resetButtonCell = (
      <Table.Cell>
        <Button
          basic={true}
          size="tiny"
          disabled={this.state.selectedGeographyIndex === selectedGeographyIndex &&
          this.state.selectedGbuIndex === selectedGbuIndex}
          onClick={() => this.toggleSelected(undefined, undefined, true)}
        >
          <FormattedMessage id="explore.organisation.resetView" defaultMessage="Reset view"/>
        </Button>
      </Table.Cell>
    );

    return (
      <>
        {!!headers.first &&
        <Table.Row className="first-header">
          {resetButtonCell}
          {this.setFirstLine(headers.first)}
        </Table.Row>
        }

        <Table.Row className="second-header">
          {!headers.first && resetButtonCell}
          {!!headers.second && this.setSecondLine(headers.second, !!headers.first)}
        </Table.Row>
      </>
    );
  }

  private setGeographyRow(geography: Geography, indexes: Indexes) {
    const hasValues = this.hasValues(SelectKeys.GEOGRAPHY, indexes);

    if (geography.id === 1 && !hasValues) return null;

    const key = `geo-row-${geography.id}`;

    if (geography.name === 'DGDI') {
      const firstRowHasValues = this.hasValues(SelectKeys.GEOGRAPHY, indexes, true);
      const getSecondRowValues = () => (
        <Table.Cell className="dgdi-none-cell" colSpan={10}>
          {this.getPpCardsByIndexes({ ...indexes, gbuIndex: 0 }, false)}
        </Table.Cell>
      );

      return (
        <>
          <Table.Row
            className={`${geography.id === 1 ? 'none-row' : 'data-row'} ${hasValues ? '' : 'empty-row'}`}
            key={key}
          >
            <Table.Cell className="geography row-header" rowSpan={firstRowHasValues ? 2 : 1}>
              {geography.name}
            </Table.Cell>

            {firstRowHasValues || !hasValues
              ? this.setColumnCells(indexes, true)
              : this.state.selectedGbuIndex === undefined && getSecondRowValues()
            }
          </Table.Row>

          {firstRowHasValues && this.state.selectedGbuIndex === undefined &&
          <Table.Row
            className={`data-row ${hasValues ? '' : 'empty-row'}`}
            key={`${key}-second-row`}
          >
            {getSecondRowValues()}
          </Table.Row>
          }
        </>
      );
    }

    return (
      <Table.Row
        className={`${geography.id === 1 ? 'none-row' : 'data-row'} ${hasValues ? '' : 'empty-row'}`}
        key={key}
      >
        <Table.Cell className="geography row-header">
          {geography.id !== 1 && geography.name}
        </Table.Cell>

        {this.setColumnCells(indexes)}
      </Table.Row>
    );
  }

  private setCountryRow(country: Country, indexes: Indexes) {
    const hasValues = this.hasValues(SelectKeys.COUNTRY, indexes);
    return (
      <Table.Row className={`data-row ${hasValues ? '' : 'empty-row'}`}>
        <Table.Cell
          className={`country ${hasValues && country.subcountries.length > 0 && 'parent'} row-header`}
          onClick={() => hasValues && country.subcountries.length > 0 &&
            this.toggleSelected(SelectKeys.COUNTRY, indexes.countryIndex)}
        >
          {country.name}
        </Table.Cell>

        {this.setColumnCells(indexes)}
      </Table.Row>
    );
  }

  private setSubcountryRow(subcountry: Subcountry, indexes: Indexes) {
    const hasValues = this.hasValues(SelectKeys.SUBCOUNTRY, indexes);
    return (
      <Table.Row key={`subcountry-${indexes.subcountryIndex}`} className={`data-row ${hasValues ? '' : 'empty-row'}`}>
        <Table.Cell
          className="subcountry row-header"
          onClick={() => hasValues && this.toggleSelected(SelectKeys.SUBCOUNTRY, indexes.subcountryIndex)}
        >
          {subcountry.name}
        </Table.Cell>

        {this.setColumnCells(indexes)}
      </Table.Row>
    );
  }

  private setRows() {
    return this.state.data!.geographies.map((geography, geoIndex) => (
      <React.Fragment key={`geography-${geoIndex}`}>
        {this.setGeographyRow(geography, { geoIndex })}
        {this.state.selectedGeographyIndex === geoIndex &&
        this.state.data!.geographies[this.state.selectedGeographyIndex].countries
          .map((country, countryIndex) =>
            <React.Fragment key={`country-${countryIndex}`}>
            {this.setCountryRow(country, {
              geoIndex,
              countryIndex,
            })}
            {this.state.selectedCountryIndex === countryIndex &&
            this.state.data!
              .geographies[this.state.selectedGeographyIndex!]
              .countries[this.state.selectedCountryIndex]
              .subcountries.map((subcountry, subcountryIndex) =>
                                  this.setSubcountryRow(subcountry, { geoIndex, countryIndex, subcountryIndex }),
            )
            }
            </React.Fragment>,
        )
        }
      </React.Fragment>
    ));
  }

  private static isOddClass(indexes: Indexes) {
    const { gbuIndex, blIndex, segmentIndex } = indexes;
    let isOdd = false;
    if (gbuIndex !== undefined) {
      isOdd = gbuIndex % 2 === 1;

      if (blIndex !== undefined) {
        isOdd = blIndex % 2 === 1;

        if (segmentIndex !== undefined) {
          isOdd = blIndex % 2 === 1;
        }
      }
    }

    return isOdd ? 'odd' : '';
  }

  private setColumnCells(indexes: Indexes, isDgdiRow = false) {
    const items = this.getHeaders().second;
    const cells: JSX.Element[] = [];

    items.forEach((item, index) => {
      if (item.gbuIndex !== undefined) {
        let newIndexes = { ...indexes, gbuIndex: item.gbuIndex };
        if (item.blIndex !== undefined) {
          newIndexes = { ...newIndexes, blIndex: item.blIndex };
          if (item.segmentIndex !== undefined) {
            newIndexes = { ...newIndexes, segmentIndex: item.segmentIndex };
          }
        }

        cells.push(
          <React.Fragment key={`count-cell-${index}`}>
            <Table.Cell className={`pp-card-cell ${Organisation.isOddClass(item)}`}>
              {(!isDgdiRow || (isDgdiRow && item.gbuIndex)) ? this.getPpCardsByIndexes(newIndexes) : null}
            </Table.Cell>
          </React.Fragment>,
        );
      }
    });

    return cells;
  }

  public render() {
    if (this.state.availableYears === undefined || this.state.data === undefined) {
      return Utils.loader();
    }

    const all = this.props.intl.formatMessage({ id: 'all', defaultMessage: 'All' });

    const headers = this.getHeaders();
    const tableBody = (
      <Table.Body>
        {this.renderColumns(headers)}
        {this.setRows()}
      </Table.Body>
    );

    const scrollbarsClass = !!headers.first ? 'large' : 'small';

    return (
      <div id="container-activities-explore">
        <Segment>
          <div id="title">
            <FormattedMessage id="explore.activities.title" defaultMessage="Explore Ambition Boost!"/>
          </div>
          <div id="content">
            <div className="year-filter">
              <span id="year"><FormattedMessage id="year" defaultMessage="Year"/></span>
              <Form.Select
                placeholder={this.getCurrentYear()}
                defaultValue={this.getCurrentYear()}
                options={[
                  { key: 'yearNone', text: all, value: 'All' },
                  ...this.state.availableYears,
                ]}
                onChange={(e, { value }) => {
                  if (value) {
                    this.onYearChange(value as string);
                  }
                }}
              />
              <div>
                {this.state.isLoading && Utils.loader('loader', 'small')}
              </div>
            </div>
            <div id="table-container">
              <Table compact="very" className="header-container">
                {tableBody}
              </Table>

              <CustomScrollBars className={scrollbarsClass}>
                <Table compact="very" className="body-container">
                  {tableBody}
                </Table>
              </CustomScrollBars>
            </div>
          </div>
        </Segment>
      </div>
    );
  }
}

export default injectIntl(Organisation);
