import * as React from 'react';

interface IProps { }

interface IStates { }

class Keywords extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    return (
      <div id="items-manager">
        <span>Keywords</span>
      </div >
    );
  }
}

export default Keywords;
