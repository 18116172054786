import './mailTeamModal.scss';

import React, { Component } from 'react';
import { Button, Checkbox, Divider, Icon, Label, Modal, Popup } from 'semantic-ui-react';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';

import PerformancePlanAPI from '../../../../../../../api/performancePlan.api';
import User, { SendTeamMailAccountDTO } from '../../../../../../../models/user';
import CustomScrollBars from '../../../../../../common/customScrollBars/customScrollBars';
import Utils from '../../../../../../../utils/utils';
import TypeActivity, { AvailableRoles } from '../../../../../../../constants/typeActivity';
import BusinessChallengeAPI from '../../../../../../../api/businessChallenge.api';
import PerformanceInitiativeAPI from '../../../../../../../api/performanceInitiative.api';
import BusinessChallenge from '../../../../../../../models/businessChallenge';

interface IProps extends InjectedIntlProps {
  showModal: boolean;
  handleClosure: () => void;
  activityName: string;
  activityId: number;
  activityType: TypeActivity;
  childrenToFilterOn: BusinessChallenge[];
}

interface IStates {
  subject: String;
  selectableUsers: SendTeamMailAccountDTO[];
  selectedUsersMails: string[];
  optionalBcs: { key: number, text: string }[];
  optionalRoles: { key: AvailableRoles, text: string }[];
  selectedBcs: number[];
  selectedRoles: AvailableRoles[];
  isLoading: boolean;
  isRolesSelectOnErr: boolean;
}

class MailTeamModal extends Component<IProps, IStates> {

  constructor(props) {
    super(props);
    this.state = {
      subject: '',
      selectableUsers: [],
      selectedUsersMails: [],
      optionalBcs: [],
      optionalRoles: [],
      selectedBcs: [],
      selectedRoles: [],
      isLoading: true,
      isRolesSelectOnErr: false,
    };
  }

  componentDidMount() {
    this.generateData();
  }

  generateData = () => {
    const roles = this.generateRolesOptions();
    const selectedRoles = roles.map(ro => ro.key);
    const selectedBcs = this.props.childrenToFilterOn ? [...this.props.childrenToFilterOn.map(bc => bc.id)] : [];
    let modifications;
    modifications = {
      selectedRoles,
      optionalRoles: roles,
    };
    if (Utils.isActivityPp(this.props.activityType)) {
      modifications = {
        ...modifications,
        selectedBcs,
        optionalBcs: this.generateChildrenOptions(this.props.childrenToFilterOn),
      };
    }
    this.setState(modifications, () => this.generateMemberList(selectedRoles, selectedBcs));
  }

  generateMemberList = (defaultRoles?: AvailableRoles[], defaultBcs?: number[]) => {
    let promise;
    const roles = this.state.selectedRoles.length === 0 ? defaultRoles!! : this.state.selectedRoles;
    const bcs = this.state.selectedBcs.length === 0 ? defaultBcs!! : this.state.selectedBcs;
    switch (this.props.activityType) {
      case TypeActivity.PERFORMANCE_PLAN:
        promise = PerformancePlanAPI.retrieveTeamMembers(this.props.activityId, roles, bcs);
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        promise = BusinessChallengeAPI.retrieveTeamMembers(this.props.activityId, roles);
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        promise = PerformanceInitiativeAPI.retrieveTeamMembers(this.props.activityId, roles);
        break;
    }
    promise.then((users) => {
      this.setState({
        selectableUsers: users,
        selectedUsersMails: users.map(usr => usr.account.email),
        isLoading: false,
      });
    });
  }

  generateChildrenOptions = (bcs: BusinessChallenge[]) : { key: number, text: string }[]  => {
    return bcs.map(bc => ({
      key: bc.id,
      text: Utils.formatActivityName(bc.code, bc.name, TypeActivity.BUSINESS_CHALLENGE),
    }));
  };

  generateRolesOptions = () : { key: AvailableRoles, text: string }[] => {
    let options: { key: AvailableRoles, text: string }[] = [];
    if (Utils.isActivityPp(this.props.activityType)) {
      options = options.concat([
        {
          key: AvailableRoles.REFERENT,
          text: this.props.intl.formatMessage({ id: 'referent', defaultMessage: 'Referent' }),
        },
        {
          key: AvailableRoles.PP_INFORMED,
          text: this.props.intl.formatMessage({ id: 'ppShrWith', defaultMessage: 'PP Shared With' }),
        },
      ]);
    }
    if (Utils.isActivityPp(this.props.activityType) || Utils.isActivityBc(this.props.activityType)) {
      options = options.concat([
        {
          key: AvailableRoles.BC_LEADERS,
          text: this.props.intl.formatMessage({ id: 'bcLeaders', defaultMessage: 'BC Leaders' }),
        },
        { key: AvailableRoles.BC_INFORMED,
          text: this.props.intl.formatMessage({ id: 'bcShrWith', defaultMessage: 'BC Shared With' }),
        },
      ]);
    }
    options = options.concat([
      {
        key: AvailableRoles.PI_LEADERS,
        text: this.props.intl.formatMessage({ id: 'piLeaders', defaultMessage: 'PI Leaders' }),
      },
      {
        key: AvailableRoles.PI_INFORMED,
        text: this.props.intl.formatMessage({ id: 'piShrWith', defaultMessage: 'PI Shared With' }),
      },
      {
        key: AvailableRoles.AT_OWNERS,
        text: this.props.intl.formatMessage({ id: 'atOwners', defaultMessage: 'Action Owners' }),
      },
    ]);
    return options;
  };

  updateUsers = (userId: number) => {
    const filteredUsers = [...this.state.selectableUsers.filter(usr => usr.account.id !== userId)];
    this.setState({
      selectableUsers: filteredUsers,
      selectedUsersMails: filteredUsers.map(usr => usr.account.email),
    });
  }

  updateFilter = (id: number, shouldAdd: boolean, isRoles = false) => {
    let selectedOptions = isRoles ? [...this.state.selectedRoles] : [...this.state.selectedBcs];
    if (shouldAdd) {
      selectedOptions.push(id);
    } else {
      selectedOptions = selectedOptions.filter(key => key !== id);
    }
    if (isRoles) {
      let modifications;
      if (selectedOptions.length === 0) {
        modifications = {
          selectedRoles: [],
          isRolesSelectOnErr: true,
          selectableUsers: [],
          selectedUsersMails: [],
        };
      } else {
        modifications = {
          selectedRoles: selectedOptions,
          isRolesSelectOnErr: false,
        };
      }
      this.setState(modifications, () => {
        if (selectedOptions.length !== 0) {
          this.generateMemberList();
        }
      });
    } else {
      this.setState({ selectedBcs: selectedOptions }, () => this.generateMemberList());
    }
  }

  getUserList = (users: User[], areUsersListsNotEmpty: boolean, isForAssignedUsers = true) => {
    return users.length > 0 && (
      <div className={`member-list ${areUsersListsNotEmpty && 'quarter'}`}>
        {isForAssignedUsers
          ? <FormattedMessage id="mailModal.assignedUsers" defaultMessage="Assigned users" />
          : <FormattedMessage id="mailModal.sharedUsers" defaultMessage="Shared users" />
        }
        <br />
        <CustomScrollBars>
          {users.map(user =>
            <Label key={user.id} className="user-label">
              <span>{user.firstName} {user.lastName}</span>
              <Icon name="delete" onClick={() => this.updateUsers(user.id)}/>
            </Label>,
          )}
        </CustomScrollBars>
      </div>
    );
  }

  render() {
    const isActivityPp = Utils.isActivityPp(this.props.activityType);

    const assignedUsers: User[] = [];
    const informedUsers: User[] = [];

    this.state.selectableUsers.forEach((user) => {
      user.isAssigned
        ? assignedUsers.push(user.account)
        : informedUsers.push(user.account);
    });

    const areUsersListsNotEmpty = assignedUsers.length > 0 && informedUsers.length > 0;

    return (
      <Modal
        open={this.props.showModal}
        closeOnDimmerClick={false}
        closeOnEscape={false}
      >
        <Modal.Header>
          <FormattedMessage id="mailModal.title" defaultMessage="Team Members Selection" />
        </Modal.Header>
        <Modal.Content>
          <div id="mail-modal-content">
            <div id="filters-area">
              {isActivityPp &&
                <div className="bc-selection quarter">
                  <div className="title-element left-aligned">
                    <div className="grey-title">
                      <FormattedMessage id="mailModal.bcSelection" defaultMessage="Filter By BC" />
                    </div>
                  </div>
                  <CustomScrollBars>
                    {this.state.optionalBcs
                      .map(bc =>
                        <div key={bc.key} className="checkbox-filter">
                          <Checkbox
                            key={bc.key}
                            checked={this.state.selectedBcs.some(id => bc.key === id)}
                            onClick={(event, data) => this.updateFilter(bc.key, data.checked!!)}
                            className="coloring black"
                          />
                          <div className="title-element">
                            <div className="grey-title">{bc.text}</div>
                          </div>
                        </div>,
                      )}
                  </CustomScrollBars>
                </div>
              }
              {isActivityPp && <Divider className="section-divider" section={true} />}
              <div className={`role-selection ${isActivityPp && 'quarter'}`}>
                <div className="title-element left-aligned">
                  <div className="grey-title">
                    <FormattedMessage id="mailModal.roleSelection" defaultMessage="Filter By Role" />
                    {this.state.isRolesSelectOnErr &&
                    <Popup
                      size="tiny"
                      position="top center"
                      trigger={<Icon name="warning circle" id="role-mandatory" className="error" />}
                      content={
                        <div className="error">
                          <FormattedMessage
                            id="mailModal.roleMandatory"
                            defaultMessage="At least one role needs to be selected"
                          />
                        </div>
                      }
                    />
                    }
                  </div>
                </div>
                <CustomScrollBars>
                  {this.state.optionalRoles
                    .map(ro =>
                      <div key={ro.key} className="checkbox-filter">
                        <Checkbox
                          key={ro.key}
                          checked={this.state.selectedRoles.some(id => ro.key === id)}
                          onClick={(event, data) => this.updateFilter(ro.key, data.checked!!, true)}
                        />
                        <div className="title-element">
                          <div className="grey-title">{ro.text}</div>
                        </div>
                      </div>,
                    )}
                </CustomScrollBars>
              </div>
            </div>
            <div id="member-list-container">
              <div className="title-element">
                <div className="grey-title">
                  <FormattedMessage
                    id="mailModal.content"
                    defaultMessage="Please choose the team members you wish to send this email to :"
                  />
                </div>
              </div>
              {this.state.selectableUsers.length === 0
                ? this.state.isLoading
                  ? Utils.loader('loader-team')
                  : Utils.empty(<FormattedMessage id="mailModal.noMember" defaultMessage="No member found"/>,
                                {}, undefined, true)
                : <>
                  {this.getUserList(assignedUsers, areUsersListsNotEmpty)}
                  {areUsersListsNotEmpty && <Divider className="section-divider" section={true} />}
                  {this.getUserList(informedUsers, areUsersListsNotEmpty, false)}
                </>
              }
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.handleClosure}>
            <FormattedMessage id="cancel" defaultMessage="Cancel" />
          </Button>
          {this.state.selectedUsersMails.length === 0
            ? <Button color="blue" disabled={true}>
              <FormattedMessage id="mailModal.generateMail" defaultMessage="Generate Email" />
            </Button>
            : <a
              id="mail-to-button"
              href={`mailto:${this.state.selectedUsersMails.join(';')}?subject=${this.props.activityName}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="mailModal.generateMail" defaultMessage="Generate Email" />
            </a>
          }
        </Modal.Actions>

      </Modal>
    );
  }
}

export default injectIntl(MailTeamModal);
