import './community.scss';

import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { Dropdown, DropdownDivider, DropdownHeader, DropdownItem, Form, Segment, Loader } from 'semantic-ui-react';
import SearchApi, { IFilters } from 'src/api/search.api';

import User from 'src/models/user';
import CardPeople from './cardPeople';
import Utils from '../../../../utils/utils';
import DatavizAPI from 'src/api/dataviz.api';
import UserStore from '../../../../stores/user.store';
import Role from '../../../../models/role';
import UserActions from '../../../../actions/user.action';
import ActionTypes from '../../../../constants/actionTypes';
import CustomScrollBars from '../../../common/customScrollBars/customScrollBars';

interface IStates {
  items: any[];
  filters: IFilters;
  selectedFilters: {
    year: string | undefined,
    bcKeyword: number | undefined,
    bcFunction: number | undefined,
    piKeyword: number | undefined,
    gbu: number | undefined,
    country: number | undefined,
    geography: number | undefined,
    subCountries: number | undefined,
  };
  locationPlaceholder: string | undefined;
  roles: Role[];
  selectedRoles: number | undefined;
  cursor: number;
  hasMore: boolean;
  loading: boolean;
}

class Community extends React.Component<InjectedIntlProps, IStates> {
  private isMount = false;

  public constructor(props: InjectedIntlProps) {
    super(props);
    this.state = {
      items: [],
      filters: {
        years: [],
        bcKeywords: [],
        bcFunctions: [],
        piKeywords: [],
        gbus: [],
        geographies: [],
        countries: [],
        subCountries: [],
      },
      selectedFilters: {
        year: undefined,
        bcKeyword: undefined,
        bcFunction: undefined,
        piKeyword: undefined,
        gbu: undefined,
        country: undefined,
        geography: undefined,
        subCountries: undefined,
      },
      locationPlaceholder: undefined,
      roles: UserStore.getRoles(),
      selectedRoles: undefined,
      cursor: 0,
      hasMore: true,
      loading: false,
    };
  }

  public componentDidMount() {
    this.isMount = true;
    UserStore.addListener(ActionTypes.USERS_ROLES_GET.toString(), this.getRoles);
    UserActions.emitGetRoles();

    this.fetchFilters();
  }

  public componentWillUnmount() {
    this.isMount = false;
    UserStore.removeListener(ActionTypes.USERS_ROLES_GET.toString(), this.getRoles);
  }

  private translateRole = (role: Role): Role => {
    const referent = this.props.intl.formatMessage({
      id: 'referent',
      defaultMessage: 'Referent',
    });

    const basicUser = this.props.intl.formatMessage({
      id: 'basicUser',
      defaultMessage: 'Basic User',
    });

    const informed = this.props.intl.formatMessage({
      id: 'informed',
      defaultMessage: 'Informed',
    });

    switch (role.id) {
      case 1:
        role.name = referent;
        break;
      case 6:
        role.name = basicUser;
        break;
      case 7:
        role.name = informed;
        break;
      default:
        break;
    }

    return role;
  };

  private getRoles = () => {
    if (this.isMount) {
      const roles = UserStore.getRoles() ? UserStore.getRoles() : [];
      this.setState({
        roles: roles.map(role => this.translateRole(role)),
      });
    }
  };

  /**
   * Fetch filters from the api
   */
  private fetchFilters = () => {
    SearchApi.getFilters().then(filters => this.setState({ filters }));
  };

  /**
   * Set a filter
   * @param key
   * @param value
   * @param role
   */
  public setFilter(key: string, value: any, role: boolean = false) {
    if (role) {
      this.setState({
        items: [],
        cursor: 0,
        hasMore: true,
        selectedRoles: value,
      });
    } else {
      const selectedFilters = { ...this.state.selectedFilters };
      switch (key) {
        case 'geography':
          selectedFilters['country'] = undefined;
          selectedFilters['subCountries'] = undefined;
          selectedFilters[key] = value;
          break;
        case 'country':
          selectedFilters['geography'] = undefined;
          selectedFilters['subCountries'] = undefined;
          selectedFilters[key] = value;
          break;
        case 'subCountries':
          selectedFilters['country'] = undefined;
          selectedFilters['geography'] = undefined;
          selectedFilters[key] = value;
          break;
        case 'all':
          selectedFilters['country'] = undefined;
          selectedFilters['subCountries'] = undefined;
          selectedFilters['geography'] = undefined;
          break;
        default:
          selectedFilters[key] = value;
          break;
      }
      this.setState({
        selectedFilters,
        items: [],
        cursor: 0,
        hasMore: true,
      });
    }
  }

  private loadMore = async () => {

    if (this.state.loading) {
      return;
    }

    const limit = 25;

    this.setState({ loading: true });

    await DatavizAPI.getUsersWithFilters({
      limit,
      offset: limit * this.state.cursor,
      gbuId: this.state.selectedFilters.gbu,
      countryId: this.state.selectedFilters.country,
      geographyId: this.state.selectedFilters.geography,
      subcountryId: this.state.selectedFilters.subCountries,
      bcKeywordId: this.state.selectedFilters.bcKeyword,
      bcFunctionId: this.state.selectedFilters.bcFunction,
      piKeywordId: this.state.selectedFilters.piKeyword,
      year: this.state.selectedFilters.year ? this.state.selectedFilters.year : undefined,
      roleId: this.state.selectedRoles,
    }).then((users: User[]) => {
      const newItems: any = [];
      users.forEach((user: User) => {
        if (!this.state.items.some((item: any) => item.id === user.id)) {
          newItems.push({
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
            role: (user.role) ? user.role : { id: 6, name: 'Basic User' },
            gbu: (user.gbu) ? user.gbu.name : '',
            country: (user.country) ? user.country.name : '',
            mail: user.email,
            hasAvatar: user.hasAvatar,
          });
        }
      });
      return newItems;
    }).then((newItems: any[]) => {
      this.setState({
        hasMore: newItems.length !== 0,
        items: [...this.state.items, ...newItems],
        cursor: this.state.cursor + 1,
        loading: false,
      });
    });

  };

  private handleLocationItemClick = (itemType: number, item: any) => {
    switch (itemType) {
      case 2:
        this.setFilter('subCountries', item.value);
        break;
      case 1:
        this.setFilter('country', item.value);
        break;
      case 0:
      default:
        this.setFilter('geography', item.value);
        break;
    }
    this.setState({ locationPlaceholder: item.text });
  };

  private resetLocation = () => {
    this.setState({ locationPlaceholder: undefined });
    this.setFilter('all', undefined);
  };

  public render() {
    if (!this.state.roles) {
      return Utils.loader();
    }

    const geographies = Utils.sortWithNoneValue(this.state.filters.geographies)
      .map(item =>
        (
          <DropdownItem
            onClick={() => this.handleLocationItemClick(0, item)}
            id={item.key}
            value={item.value}
            key={`geography${item.value}`}
            selected={this.state.selectedFilters.geography === item.value}
          >
            {item.text}
          </DropdownItem>
        ));

    const countries = Utils.sortWithNoneValue(this.state.filters.countries)
      .map(item =>
        (
          <DropdownItem
            onClick={() => this.handleLocationItemClick(1, item)}
            id={item.key}
            value={item.value}
            key={`country${item.value}`}
            selected={this.state.selectedFilters.country === item.value}
          >
            {item.text}
          </DropdownItem>
        ));

    const subCountries = Utils.sortWithNoneValue(this.state.filters.subCountries)
      .map(item =>
        (
          <DropdownItem
            onClick={() => this.handleLocationItemClick(2, item)}
            id={item.key}
            value={item.value}
            key={`subcountry${item.value}`}
            selected={this.state.selectedFilters.subCountries === item.value}
          >
            {item.text}
          </DropdownItem>
        ));

    return (
      <div id="container-people-explorer">
        <Segment className={'segment-container'}>
          <div id={'title-people'}>
            <FormattedMessage
              id={'explore.people.title'}
              defaultMessage={'Ambition Boost Actors'}
            />
          </div>
          <div id={'desc-people'}>
            <FormattedMessage
              id={'explore.people.desc'}
              defaultMessage={'Find your coworkers or people you can work with!'}
            />
          </div>

          <Form.Group id={'filters'} widths={'equal'}>
            <FormattedMessage id={'all'} defaultMessage={'All'}>{select =>
              <div id={'dropdown-filter'}>
                <FormattedMessage id={'GBU'} defaultMessage={'GBU'}>{msg =>
                  <Form.Select
                    id={'gbu'}
                    fluid={true}
                    className={'select-filter'}
                    placeholder={select as string}
                    label={msg}
                    options={[
                      { key: 'gbuNone', text: select, value: undefined },
                      ...Utils.sortWithNoneValue(this.state.filters.gbus),
                    ]}
                    value={this.state.selectedFilters.gbu ? this.state.selectedFilters.gbu : undefined}
                    onChange={(e, { id, value }) => this.setFilter(id, value)}
                  />}
                </FormattedMessage>
                <div className={'dropdown-label-group'}>
                  <Form.Field>
                    <FormattedMessage id={'location'} defaultMessage={'Location'} />
                  </Form.Field>
                  <FormattedMessage id={'all'} defaultMessage={'All'}>{select =>
                    <Dropdown
                      id="location"
                      placeholder={!this.state.locationPlaceholder ? select as string : undefined}
                      text={this.state.locationPlaceholder ? this.state.locationPlaceholder : select as string}
                      scrolling={true}
                      className={'select-filter custom-dropdown'}
                    >
                      <Dropdown.Menu>
                        <DropdownItem key={'locationNone'} onClick={this.resetLocation}>
                          <FormattedMessage id={'all'} defaultMessage={'All'}>{msg => msg}</FormattedMessage>
                        </DropdownItem>
                        <DropdownHeader>
                          <FormattedMessage id={'geography'} defaultMessage={'GEOGRAPHY'} />
                        </DropdownHeader>
                        {geographies}
                        <DropdownDivider />
                        <DropdownHeader>
                          <FormattedMessage id={'countries'} defaultMessage={'COUNTRY'} />
                        </DropdownHeader>
                        {countries}
                        <DropdownDivider />
                        <DropdownHeader>
                          <FormattedMessage id={'subcountries'} defaultMessage={'SUBCOUNTRY'} />
                        </DropdownHeader>
                        {subCountries}
                      </Dropdown.Menu>
                    </Dropdown>}
                  </FormattedMessage>
                </div>
                <FormattedMessage id={'bcKeyword'} defaultMessage={'BC Keyword'}>{msg =>
                  <Form.Select
                    id={'bcKeyword'}
                    className={'select-filter'}
                    fluid={true}
                    placeholder={select as string}
                    label={msg}
                    options={[
                      { key: 'bcKeywordNone', text: select, value: undefined },
                      ...Utils.sortWithNoneValue(this.state.filters.bcKeywords),
                    ]}
                    value={this.state.selectedFilters.bcKeyword ? this.state.selectedFilters.bcKeyword : undefined}
                    onChange={(e, { id, value }) => this.setFilter(id, value)}
                  />}
                </FormattedMessage>
                <FormattedMessage id={'bcFunction'} defaultMessage={'Function'}>{msg =>
                  <Form.Select
                    id={'bcFunction'}
                    className={'select-filter'}
                    fluid={true}
                    placeholder={select as string}
                    label={msg}
                    options={[
                      { key: 'bcFunctionNone', text: select, value: undefined },
                      ...Utils.sortWithNoneValue(this.state.filters.bcFunctions),
                    ]}
                    value={this.state.selectedFilters.bcFunction ? this.state.selectedFilters.bcFunction : undefined}
                    onChange={(e, { id, value }) => this.setFilter(id, value)}
                  />}
                </FormattedMessage>
                <FormattedMessage id={'piKeyword'} defaultMessage={'PI Keyword'}>{msg =>
                  <Form.Select
                    id={'piKeyword'}
                    fluid={true}
                    className={'select-filter'}
                    placeholder={select as string}
                    label={msg}
                    options={[
                      { key: 'piKeywordNone', text: select, value: undefined },
                      ...Utils.sortWithNoneValue(this.state.filters.piKeywords),
                    ]}
                    value={this.state.selectedFilters.piKeyword ? this.state.selectedFilters.piKeyword : undefined}
                    onChange={(e, { id, value }) => this.setFilter(id, value)}
                  />}
                </FormattedMessage>
                <FormattedMessage id={'role'} defaultMessage={'Role'}>{msg =>
                  <Form.Select
                    id={'role'}
                    fluid={true}
                    className={'select-filter'}
                    placeholder={select as string}
                    label={msg}
                    options={[
                      { key: 'roleNone', text: select, value: undefined },
                      ...this.state.roles.map(role => ({ key: role.id, text: role.name, value: role.id })),
                    ]}
                    value={this.state.selectedRoles}
                    onChange={(e, { id, value }) => this.setFilter(id, value, true)}
                  />}
                </FormattedMessage>
                <FormattedMessage id={'year'} defaultMessage={'Year'}>{msg =>
                  <Form.Select
                    id={'year'}
                    fluid={true}
                    className={'select-filter'}
                    placeholder={select as string}
                    label={msg}
                    options={[
                      { key: 'yearNone', text: select, value: undefined },
                      ...this.state.filters.years.map(year => ({ key: year, text: year, value: year })),
                    ]}
                    value={this.state.selectedFilters.year ? this.state.selectedFilters.year : undefined}
                    onChange={(e, { id, value }) => this.setFilter(id, value)}
                  />}
                </FormattedMessage>
              </div>
            }</FormattedMessage>
          </Form.Group>

          <div id={'container-result'}>

            <CustomScrollBars>
              <InfiniteScroll
                pageStart={0}
                loadMore={this.loadMore}
                hasMore={this.state.hasMore}
                loader={<Loader id="loader" active={true} size={'large'} />}
                useWindow={false}
                threshold={440}
              >
                <div id={'result'}>
                  {this.state.items.length > 0
                    ? this.state.items.map(item =>
                      <CardPeople
                        key={`people${item.id}`}
                        id={item.id}
                        name={item.name}
                        role={this.translateRole(item.role)}
                        gbu={item.gbu}
                        country={item.country}
                        mail={item.mail}
                        hasAvatar={item.hasAvatar}
                      />,
                    )
                    : !this.state.hasMore && <FormattedMessage id="noPeopleFound" defaultMessage="No user found for this filter" />
                  }
                </div>
              </InfiniteScroll>
            </CustomScrollBars>
          </div>
        </Segment>
      </div>
    );
  }
}

export default injectIntl(Community);
