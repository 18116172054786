import * as React from 'react';

import Utils from '../../../../../utils/utils';
import Metric from '../../../../../models/metric';
import ModeTypes from '../../../../../constants/modeTypes';
import TypeActivity from '../../../../../constants/typeActivity';
import { ComparisonType } from '../../../../../constants/comparisonType';
import { FormattedMessage } from 'react-intl';
import { Button, Dropdown, Icon, Label, Modal, Popup } from 'semantic-ui-react';
import BusinessChallenge from 'src/models/businessChallenge';
import MetricSegment from './metricSegment/metricSegment';
import SegmentsStore from '../../../../../stores/segments.store';
import BusinessChallengeAPI from 'src/api/businessChallenge.api';
import PerformanceInitiativeAPI from 'src/api/performanceInitiative.api';
import NotificationActions from 'src/actions/notification-actions';
import { ToastType } from 'src/components/common/toast/toast';
import { ImportMetricsMilestonesModal, importType } from '../modal/importMetricsMilestonesModal';
import RightsStore from 'src/stores/rights.store';
import { RightsOnBC, RightsOnPI } from 'src/models/rights';
import ActionTypes from 'src/constants/actionTypes';
import LastUpdateHighlight from '../../common/overviewPanel/panelContent/tabs/lastUpdatedInformations/lastUpdateHighlight';
import { SegmentType } from 'src/models/segmentsMode';
import MetricsUtils from 'src/utils/metricsUtils';
import OrderActivitiesModal from '../../common/orderActivitiesModal/orderActivitiesModal';

// Waiting time in ms to scroll after a metric is created
const WAITING_SCROLL = 600;

interface IProps {
  mode: ModeTypes;
  metrics: Metric[];
  type: TypeActivity;
  forActivityCreation: boolean;
  selectedUrlMetric: string;
  data: BusinessChallenge | undefined;
  activityId: number;
  deleteMetric: (metricId: number) => void;
  updateMetrics: (metric: Metric) => void;
  reloadMetrics: (callback?: Function) => void;
  createMetric: () => void;
  deleteNewMetric: (index: number) => void;
  onDataChange(idAttribute: string, value: any, text?: any): void;
  updateSubFormValidation(isValid: boolean, subForm: string): void;
}

interface IStates {
  selectedMetricIndex: number;
  isChartModalOpened: boolean;
  isImageOpened: boolean;
  isDeleteModalOpen: boolean;
  selectedMetric: Metric | null;
  isImportMetricModalOpen: boolean;
  rightsOnBc: RightsOnBC;
  rightsOnPi: RightsOnPI;
  isOrderModalOpen: boolean;
}

export default class Metrics extends React.Component<IProps, IStates> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedMetricIndex: 0,
      isChartModalOpened: false,
      isImageOpened: false,
      isDeleteModalOpen: false,
      selectedMetric: null,
      isImportMetricModalOpen: false,
      rightsOnBc: RightsStore.getRightsOnBC(),
      rightsOnPi: RightsStore.getRightsOnPI(),
      isOrderModalOpen: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>, snapshot?: any) {
    if ((Utils.isOnEditMode(prevProps.mode) && Utils.isOnCancelMode(this.props.mode))
      || (prevProps.metrics !== this.props.metrics)) {
      if (this.props.selectedUrlMetric) {
        const index = this.props.metrics.findIndex(me => me.id === parseInt(this.props.selectedUrlMetric, 10));
        this.setChartData(index || 0);
      }
    }
  }

  componentDidMount() {
    RightsStore.addListener(ActionTypes.SET_BC_RIGHTS.toString(), this.setRightsOnBc);
    RightsStore.addListener(ActionTypes.SET_PI_RIGHTS.toString(), this.setRightsOnPI);
  }

  componentWillUnmount() {
    RightsStore.removeListener(ActionTypes.SET_BC_RIGHTS.toString(), this.setRightsOnBc);
    RightsStore.removeListener(ActionTypes.SET_PI_RIGHTS.toString(), this.setRightsOnPI);
  }

  private setRightsOnBc = () => {
    this.setState({
      rightsOnBc: RightsStore.getRightsOnBC(),
    });
  };

  private setRightsOnPI = () => {
    this.setState({
      rightsOnPi: RightsStore.getRightsOnPI(),
    });
  };

  private getCanEdit = () => {
    switch (this.props.type) {
      case TypeActivity.BUSINESS_CHALLENGE:
        return this.state.rightsOnBc.canEdit();
      case TypeActivity.PERFORMANCE_INITIATIVE:
      default:
        return this.state.rightsOnPi.canEdit();
    }
  };

  //region METRICS CHART MANAGEMENT
  /**
   * Set the selectedMetricIndex state
   * @param selectedMetricIndex: number (default: 0)
   */
  private setChartData(selectedMetricIndex = 0) {
    this.setState({ selectedMetricIndex });
  }

  //endregion.

  private setDeleteModal = (isOpen: boolean, metric?: Metric, index?: number) => {
    this.setState(prevState => ({
      isDeleteModalOpen: isOpen,
      selectedMetric: metric ?? prevState.selectedMetric,
      selectedMetricIndex: index ?? prevState.selectedMetricIndex,
    }));
  };

  private confirmDelete = async () => {
    if (!this.state.selectedMetric) {
      return;
    }
    const metricId = this.state.selectedMetric.id as number;
    try {
      switch (this.props.type) {
        case TypeActivity.BUSINESS_CHALLENGE:
          await BusinessChallengeAPI
            .deleteBusinessChallengeMetrics(this.props.activityId, [metricId]);
          break;
        case TypeActivity.PERFORMANCE_INITIATIVE:
          await PerformanceInitiativeAPI
            .deletePerformanceInitiativeMetrics(this.props.activityId, [metricId]);
          break;
      }
      this.setDeleteModal(false);
      this.props.deleteMetric(metricId);
      NotificationActions.toast(
        <FormattedMessage id="deleted" defaultMessage="Deleted!"/>,
        (
          <FormattedMessage
            id="metrics.toast.deletedSuccess"
            defaultMessage="The metric has been deleted successfully"
          />
        ),
        ToastType.SUCCESS,
      );
      this.clearSelectedMetric();
    } catch {
      Utils.toastError();
    }
  };

  private confirmUnlink = async () => {
    if (!this.state.selectedMetric) {
      return;
    }
    const metricId = this.state.selectedMetric.id as number;
    try {
      switch (this.props.type) {
        case TypeActivity.BUSINESS_CHALLENGE:
        default:
          await BusinessChallengeAPI
            .removeBusinessChallengeLinkMetrics(this.props.activityId, [metricId]);
          break;
      }
      this.setDeleteModal(false);
      this.props.deleteMetric(metricId);
      NotificationActions.toast(
        <FormattedMessage id="deleted" defaultMessage="Deleted!"/>,
        (
          <FormattedMessage
            id="metrics.toast.unlinkedSuccess"
            defaultMessage="The metric has been unlinked successfully"
          />
        ),
        ToastType.SUCCESS,
      );
      this.clearSelectedMetric();
    } catch {
      Utils.toastError();
    }
  };

  private cancelDelete = () => {
    SegmentsStore.emitSetDeletingSegmentsToEdition();
    this.setDeleteModal(false);
  };

  public static getComparisonStatusLabel(metric: Metric) {
    if (metric.comparisonType === ComparisonType.NONE) return;

    let comparisonLabelClass = '';
    let comparisonType;
    let comparisonInfo;
    let comparisonLetter;

    const lastSetCurrentValue = MetricsUtils.getLastSetCurrentValue(metric).current;

    const currentValue = lastSetCurrentValue !== null ? lastSetCurrentValue : metric!.mostRecentValues.current;
    if (metric!.mostRecentValues.target && currentValue) {
      const target = parseFloat(metric.mostRecentValues.target.toString());
      const current = parseFloat(currentValue.toString());

      if (metric.comparisonType === ComparisonType.EQUAL
        && metric!.comparisonTolerance !== undefined) {
        const lower = target - (target * metric!.comparisonTolerance / 100);
        const upper = target + (target * metric!.comparisonTolerance / 100);
        comparisonType = <FormattedMessage id="comparison.equal" defaultMessage="Equal"/>;
        comparisonLetter = <i className="icon">=</i>;
        comparisonLabelClass = 'equal ';
        if (current >= lower && current <= upper) {
          comparisonLabelClass += 'comparison-ok';
          comparisonInfo =
            <FormattedMessage id="comparison.inRange" defaultMessage="The value is in the target tolerance range"/>;
        } else {
          comparisonLabelClass += 'comparison-not-ok';
          comparisonInfo = (
            <FormattedMessage
              id="comparison.outsideRange"
              defaultMessage="The value is outside the target tolerance range"
            />
          );
        }
      } else if (metric.comparisonType === ComparisonType.UP) {
        comparisonType = <FormattedMessage id="comparison.up" defaultMessage="Increasing"/>;
        comparisonLetter = <Icon name="chevron up"/>;
        if (current >= target) {
          comparisonLabelClass = 'comparison-ok';
          comparisonInfo =
            <FormattedMessage id="comparison.aboveTarget" defaultMessage="The value is above the target"/>;
        } else {
          comparisonLabelClass = 'comparison-not-ok';
          comparisonInfo =
            <FormattedMessage id="comparison.underTarget" defaultMessage="The value is under the target"/>;
        }
      } else if (metric.comparisonType === ComparisonType.DOWN) {
        comparisonType = <FormattedMessage id="comparison.down" defaultMessage="Decreasing"/>;
        comparisonLetter = <Icon name="chevron down"/>;
        if (current <= target) {
          comparisonLabelClass = 'comparison-ok';
          comparisonInfo =
            <FormattedMessage id="comparison.underTarget" defaultMessage="The value is under the target"/>;
        } else {
          comparisonLabelClass = 'comparison-not-ok';
          comparisonInfo =
            <FormattedMessage id="comparison.aboveTarget" defaultMessage="The value is above the target"/>;
        }
      }
    }

    return (
      <Popup
        id="metric-comparison-popup"
        content={(
          <>
            <span className="uppercase">{comparisonType}</span> - {comparisonInfo}
          </>
        )}
        inverted={true}
        position="right center"
        size="small"
        trigger={(
          <Label className={`comparison-label ${comparisonLabelClass}`} circular={true}>
            <span className="comparison-letter">{comparisonLetter}</span>
          </Label>
        )}
      />
    );
  }

  clearSelectedMetric = () => {
    this.setState({ selectedMetric: null });
  }

  openImportModal = () => {
    this.setState({
      isImportMetricModalOpen: true,
    });
  };

  closeImportModal = () => {
    this.setState({
      isImportMetricModalOpen: false,
    });
  };

  private deleteNewMetric = () => {
    this.setDeleteModal(false);
    this.clearSelectedMetric();
    this.props.deleteNewMetric(this.state.selectedMetricIndex);
  };

  private hasNewMetric = () => {
    return this.props.metrics.some(me => me.isNew);
  };

  private scrollToMetric = (metricId: number) => {
    const METRICS_PER_LINE = 2;
    if (this.props.metrics.length > METRICS_PER_LINE) {
      setTimeout(() => {
        const id = `#${LastUpdateHighlight.getSegmentId(SegmentType.BC_METRICS, metricId)}`;
        document.querySelector(id)?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      },         WAITING_SCROLL);
    }
  };

  private renderDeleteModal = () => {
    return (
      <Modal
        open={this.state.isDeleteModalOpen}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={() => this.setDeleteModal(false)}
      >
        <Modal.Header>
          <FormattedMessage id="metrics.modal.title" defaultMessage="Delete the metric"/>
        </Modal.Header>
        <Modal.Content>
          <p>
            <FormattedMessage
              id="metrics.modal.body"
              defaultMessage="Are you sure you want to delete this metric?"
            />
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.cancelDelete}>
            <FormattedMessage
              id="cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            onClick={this.state.selectedMetric?.isNew ? this.deleteNewMetric :
              (this.state.selectedMetric?.isFromLinked ? this.confirmUnlink : this.confirmDelete)}
            positive={true}
          >
            <FormattedMessage id="delete" defaultMessage="Delete"/>
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  isOrderBtnDisabled = () => {
    return this.props.metrics.length <= 1 || this.state.isDeleteModalOpen || this.state.isImportMetricModalOpen
      || this.state.selectedMetric !== null;
  };

  generateHeaderBtn = (isOrderBtn = false) => {
    if (isOrderBtn) {
      return (
        <div className="header-button">
          <Button
            onClick={() => this.setState({ isOrderModalOpen: true })}
            disabled={this.isOrderBtnDisabled()}
          >
            <FormattedMessage
              id="orderMetrics"
              defaultMessage="Order metrics"
            />
          </Button>
        </div>
      );
    }
    return (
      <div className="header-button">
        <FormattedMessage
          id="createNewMetric"
          defaultMessage="Add a new metric"
        >
          {msg =>
            <Dropdown
              text={msg.toString()}
              icon="add circle"
              floating={true}
              labeled={true}
              disabled={this.hasNewMetric()}
              button={true}
              direction="left"
              className="icon"
              id="bc-button-group"
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={this.props.createMetric}
                >
                  <Icon name="add"/>
                  <FormattedMessage id="createNewMetric" defaultMessage="Add a new metric"/>
                </Dropdown.Item>
                {Utils.isActivityBc() && <Dropdown.Item
                  onClick={this.openImportModal}
                >
                  <Icon name="download"/>
                  <FormattedMessage id="importModal.metric.title" defaultMessage="Import a metric"/>
                </Dropdown.Item>}
              </Dropdown.Menu>
            </Dropdown>
          }
        </FormattedMessage>
      </div>
    );
  };

  onReorderMetrics = (callback: Function) => {
    this.props.reloadMetrics(callback);
  };

  onOrderActivitiesModalClose = () => {
    this.setState({ isOrderModalOpen: false });
  };

  onMetricUpdate = (metric: Metric) => {
    this.clearSelectedMetric();
    this.props.updateMetrics(metric);
  }

  render() {
    const MIN_LENGTH_FIT = 1;
    return (
      <div className="metrics-container">
        <div id="metrics-header-buttons">
          {this.getCanEdit() &&
            <>
            {this.generateHeaderBtn()}
            {this.generateHeaderBtn(true)}
            </>
          }
        </div>
        <ul id="metrics-list" className={`metrics-list ${this.props.metrics.length === MIN_LENGTH_FIT ? 'fit' : ''}`}>
          {this.props.metrics.map((metric, index) => {
            return (
              <li key={metric.id ?? index}>
                <MetricSegment
                  index={index}
                  data={this.props.data}
                  metric={metric}
                  typeActivity={this.props.type}
                  forActivityCreation={this.props.forActivityCreation}
                  setDeleteModal={this.setDeleteModal}
                  activityId={this.props.activityId}
                  onUpdate={this.onMetricUpdate}
                  reloadMetrics={this.props.reloadMetrics}
                  isNew={metric.isNew ?? false}
                  deleteNewMetric={this.props.deleteNewMetric}
                  canEdit={this.getCanEdit()}
                  scrollToMetric={this.scrollToMetric}
                  metricsCount={this.props.metrics.length}
                />
              </li>
            );
          })}
        </ul>
        {this.props.metrics.length <= 0 &&
          <div>{Utils.empty(<FormattedMessage id="noMetrics" defaultMessage="No metrics available"/>)}</div>}
        {this.renderDeleteModal()}
        {this.state.isImportMetricModalOpen && Utils.isActivityBc() &&
          <ImportMetricsMilestonesModal
            bcId={this.props.activityId}
            importType={importType.metric}
            closeModal={this.closeImportModal}
            reloadMetrics={this.props.reloadMetrics}
          />}
        <OrderActivitiesModal
          isOpen={this.state.isOrderModalOpen}
          activities={this.props.metrics}
          onReorder={this.onReorderMetrics}
          onClose={this.onOrderActivitiesModalClose}
          performancePlanId={this.props.data
            ? Utils.getPlanIdFromActivity(this.props.data, TypeActivity.BUSINESS_CHALLENGE) : null
          }
          parentType={Utils.isActivityBc() ? TypeActivity.BUSINESS_CHALLENGE : TypeActivity.PERFORMANCE_INITIATIVE}
          parentId={this.props.activityId}
        />
      </div>
    );
  }
}
