import './performanceMeasures.scss';

import { RouteComponentProps, withRouter } from 'react-router';
import React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import BusinessChallenge from '../../../../../models/businessChallenge';
import { Button } from 'semantic-ui-react';
import { ImportMetricsMilestonesModal, importType } from '../../metricsMilestones/modal/importMetricsMilestonesModal';
import TypeActivity from '../../../../../constants/typeActivity';
import MilestonesList from '../../common/milestonesList/milestonesList';
import MilestoneAction from '../../../../../actions/milestone.action';

interface IRouteProps {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  data: BusinessChallenge;
  canEditMil: boolean;
}

interface IStates {
  createMilestone: boolean;
  importMilestoneModalOpen: boolean;
}

class PerformanceMeasures extends React.Component<IProps & InjectedIntlProps, IStates> {

  public constructor(props: IProps & InjectedIntlProps) {
    super(props);

    this.state = {
      createMilestone: false,
      importMilestoneModalOpen: false,
    };
  }

  openImportModal = () => {
    this.setState({ importMilestoneModalOpen: true });
  }

  closeImportModal = () => {
    this.setState({ importMilestoneModalOpen: false });
  }

  private createNewMilestone = () => {
    this.setState({ createMilestone: true }, () => {
      MilestoneAction.emitCreateMilestone();
    });
  }

  public render() {
    return(
      <div id="bc-activity-tab">
        <div id="bc-activity-view">
          <div id="bc-activity-header">
            <div id="milestones-list-title">
              <FormattedMessage id="milestones" defaultMessage="Milestones" />
            </div>
            <div>
              <Button
                id="import-milestone-button"
                color="instagram"
                className="add-row-button"
                onClick={() => this.setState({ importMilestoneModalOpen: true })}
                disabled={!this.props.canEditMil}
              >
                <FormattedMessage id="importModal.milestone.title" defaultMessage="Import milestone" />
              </Button>
              <Button
                id="add-milestone-button"
                color="instagram"
                className="bc-milestone-creation-button"
                onClick={() => this.createNewMilestone()}
                disabled={this.state.createMilestone || !this.props.canEditMil}
              >
                <FormattedMessage id="newMilestone" defaultMessage="New Milestone" />
              </Button>
            </div>
          </div>
          <MilestonesList
            activityId={this.props.data.id}
            activityType={TypeActivity.BUSINESS_CHALLENGE}
            parentPpId={this.props.data.targetPpId}
            isCreate={this.state.createMilestone}
            canEditOrderMil={this.props.canEditMil}
            toggleCreationMode={() => this.setState({ createMilestone: !this.state.createMilestone })}
          />
          {this.state.importMilestoneModalOpen &&
          <ImportMetricsMilestonesModal
            importType={importType.milestone}
            closeModal={this.closeImportModal}
            bcId={this.props.data.id}
          />}
        </div>
      </div>
    );
  }
}

export default withRouter(injectIntl(PerformanceMeasures));
