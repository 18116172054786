import { Icon } from 'semantic-ui-react';
import React from 'react';
import { Months, Quarter, TimelineView } from '../../../../../../../../../constants/timeline';
import TimelineUtils from '../../../../../../../../../utils/timelineUtils';

interface IProps {
  selectedYear: number;
  selectedMonth: Months;
  selectedQuarter: Quarter;
  selectedView: TimelineView;
  setSelectedMonth: (month: Months) => void;
  setSelectedQuarter: (quarter: Quarter) => void;
  setMultiplePeriods: (period: Quarter | Months, year: number) => void;
  isVisible: boolean;
}

const periodSelector = (props: IProps) => {

  const periodTitle = TimelineUtils.isOnQuarterView(props.selectedView)
    ? TimelineUtils.getTitleOfQuarter(props.selectedQuarter)
    : TimelineUtils.getTitleOfMonth(props.selectedMonth);

  const updateSelectedPeriod = (getNextOne = true) => {
    let updatedMonth: Months | null = null;
    let updatedQuarter: Quarter | null = null;
    let updatedYear: number | null = null;
    switch (props.selectedView) {
      case TimelineView.QUARTER:
        if (getNextOne) {
          if (props.selectedQuarter === Quarter.Q4) {
            updatedQuarter = Quarter.Q1;
            updatedYear = props.selectedYear + 1;
          } else {
            updatedQuarter = props.selectedQuarter + 1;
          }
        } else {
          if (props.selectedQuarter === Quarter.Q1) {
            updatedQuarter = Quarter.Q4;
            updatedYear = props.selectedYear - 1;
          } else {
            updatedQuarter = props.selectedQuarter - 1;
          }
        }
        break;
      case TimelineView.MONTH:
        if (getNextOne) {
          if (props.selectedMonth === Months.DECEMBER) {
            updatedMonth = Months.JANUARY;
            updatedYear = props.selectedYear + 1;
          } else {
            updatedMonth = props.selectedMonth + 1;
          }
        } else {
          if (props.selectedMonth === Months.JANUARY) {
            updatedMonth = Months.DECEMBER;
            updatedYear = props.selectedYear - 1;
          } else {
            updatedMonth = props.selectedMonth - 1;
          }
        }
        break;
    }

    const updatedPeriod = updatedMonth !== null ? updatedMonth : updatedQuarter;
    if (updatedYear && updatedPeriod !== null) {
      props.setMultiplePeriods(updatedPeriod, updatedYear);
    } else  {
      updatedMonth !== null && props.setSelectedMonth(updatedMonth);
      updatedQuarter !== null && props.setSelectedQuarter(updatedQuarter);
    }
  };

  return (
    <div className="selector" key="periodSelector" style={{ visibility: props.isVisible ? 'visible' : 'hidden' }}>
      <div className="pointer">
        <Icon
          name="angle left"
          size="large"
          onClick={() => updateSelectedPeriod(false)}
        />
      </div>
      <div className="specific-selected">{periodTitle}</div>
      <div className="pointer">
        <Icon
          name="angle right"
          size="large"
          onClick={updateSelectedPeriod}
        />
      </div>
    </div>
  );
};
export default periodSelector;
