import './contentPI.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { Icon, Menu } from 'semantic-ui-react';

import ActivitiesActions from 'src/actions/activities.action';
import PerformanceInitiativeActions from 'src/actions/performanceInitiative.action';
import TitleTaskboardActions from 'src/actions/titleTaskboard.actions';
import ActionTypes from 'src/constants/actionTypes';
import ModeTypes from 'src/constants/modeTypes';
import TypeActivity from 'src/constants/typeActivity';
import PerformanceInitiative from 'src/models/performanceInitiative';
import ActivitiesStore from 'src/stores/activities.store';
import ErrorStore from 'src/stores/error.store';
import PerformanceInitiativeStore from 'src/stores/performanceInitiative.store';
import Utils from 'src/utils/utils';
import ConfirmModal from '../confirmModal/confirmModal';
import MetricsContainer from '../metricsMilestones/metricsContainer';
import TitleContainer from '../titleContainer/titleContainer';
import Cockpit from './cockpit/cockpit';
import MoreInfo from './moreInfo/moreInfo';
import DatesContainer from '../datesContainer/datesContainer';
import EditingDraftBanner from '../contentPP/editingDraftBanner/editingDraftBanner';
import PerformanceInitiativeErrors from 'src/models/pi-errors';
import ClosedBanner from '../closedBanner/closedBanner';
import OverviewPanel from '../common/overviewPanel/overviewPanel';
import { RightsOnPI } from 'src/models/rights';
import RightsStore from 'src/stores/rights.store';
import RightsActions from 'src/actions/rights.action';
import PerformanceInitiativeAPI from 'src/api/performanceInitiative.api';
import { guideWrapper } from '../../../guide/guideWrapper';
import PerformanceMeasures from './performanceMeasures/performanceMeasures';
import Milestone from '../../../../models/milestone';
import Action, { ActionEdit } from '../../../../models/action';
import ActionAPI from '../../../../api/action.api';

export enum PiTabs {
  COCKPIT = 'cockpit',
  METRICS_VIEW = 'metrics-view',
  ACTIVITY = 'activity',
  MORE_INFO = 'more-info',
}

interface IRouteProps {
  ppId: string;
  id: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  isOverviewPanelOpen: boolean;
  isOverviewPanelRelative: boolean;

  toggleOverviewPanelVisibility(isVisible?: boolean): void;
}

interface IStates {
  data: PerformanceInitiative;
  error?: Error;
  oldData: PerformanceInitiative;
  rightsOnPi: RightsOnPI | undefined;
  mode: ModeTypes;
  activeCode: number;
  isFormValid: boolean[];
  isLoading: boolean;
  isCockpitOnError: boolean;
  isMoreInfoOnError: boolean;
  errors: PerformanceInitiativeErrors;
  isOpenNewAtModal: boolean;
  contentHeight: number;
  overviewPanelActivity: Milestone | Action | undefined;
  overviewPanelActivityType: TypeActivity.MILESTONE | TypeActivity.ACTION | undefined;
  activityIdToScrollTo: number | undefined;
}

class ContentPI extends React.Component<IProps, IStates> {

  private isMount = false;

  //region REACT LIFECYCLE METHODS
  constructor(props: IProps) {
    super(props);
    this.state = {
      data: PerformanceInitiativeStore.getPerformanceInitiative(),
      error: ErrorStore.getError(),
      oldData: PerformanceInitiativeStore.getOldPerformanceInitiative(),
      rightsOnPi: undefined,
      mode: ActivitiesStore.getMode(),
      activeCode: 0,
      isFormValid: [true, true],
      isLoading: false,
      isCockpitOnError: false,
      isMoreInfoOnError: false,
      errors: PerformanceInitiativeStore.getErrors(),
      isOpenNewAtModal: false,
      contentHeight: 0,
      overviewPanelActivity: undefined,
      overviewPanelActivityType: undefined,
      activityIdToScrollTo: undefined,
    };
  }

  public componentWillMount() {
    this.isMount = true;

    PerformanceInitiativeStore.addListener(ActionTypes.PERFORMANCE_INITIATIVE_GET.toString(),
                                           this.getPerformanceInitiative);
    PerformanceInitiativeStore.addListener(ActionTypes.PERFORMANCE_INITIATIVE_CHANGE.toString(),
                                           this.getPerformanceInitiative);
    ActivitiesStore.addListener(ActionTypes.ACTIVITIES_GET_MODE.toString(), this.getMode);
    RightsStore.addListener(ActionTypes.SET_PI_RIGHTS.toString(), this.setRightsOnPi);
    ErrorStore.addListener(ActionTypes.ERROR_CHANGE.toString(), this.getError);
    ErrorStore.addListener(ActionTypes.ERROR_DELETED.toString(), this.getError);
    PerformanceInitiativeStore.addListener(ActionTypes.PERFORMANCE_INITIATIVE_ERRORS.toString(),
                                           this.setErrors);
    PerformanceInitiativeActions.emitGetPerformanceInitiativeById(+this.props.match.params.id,
                                                                  +this.props.match.params.ppId);
    ActivitiesActions.emitGetActivitySelect(TypeActivity.PERFORMANCE_INITIATIVE);
    PerformanceInitiativeStore.addListener(ActionTypes.SET_OPEN_NEW_AT_MODAL.toString(), this.setOpenNewAtModal);
    this.getPiRights();
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      PerformanceInitiativeActions.emitGetPerformanceInitiativeById(+nextProps.match.params.id,
                                                                    +nextProps.match.params.ppId);
      this.getPiRights(+nextProps.match.params.id);
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>, snapshot?: any) {
    if (prevProps.isOverviewPanelOpen && !this.props.isOverviewPanelOpen) {
      this.setState({ overviewPanelActivity: undefined, overviewPanelActivityType: undefined });
    }
  }

  public componentWillUnmount() {
    this.isMount = false;
    PerformanceInitiativeStore
      .removeListener(ActionTypes.PERFORMANCE_INITIATIVE_GET.toString(), this.getPerformanceInitiative);
    PerformanceInitiativeStore
      .removeListener(ActionTypes.BUSINESS_CHALLENGE_CHANGE.toString(), this.getPerformanceInitiative);
    ActivitiesStore.removeListener(ActionTypes.ACTIVITIES_GET_MODE.toString(), this.getMode);
    RightsStore.removeListener(ActionTypes.SET_PI_RIGHTS.toString(), this.setRightsOnPi);
    ErrorStore.removeListener(ActionTypes.ERROR_SET.toString(), this.getError);
    ErrorStore.removeListener(ActionTypes.ERROR_DELETED.toString(), this.getError);
    PerformanceInitiativeStore
      .removeListener(ActionTypes.PERFORMANCE_INITIATIVE_ERRORS.toString(), this.setErrors);
    PerformanceInitiativeStore
      .removeListener(ActionTypes.SET_OPEN_NEW_AT_MODAL.toString(), this.setOpenNewAtModal);
  }

  //endregion

  private getPiRights = async (piId?: number) => {
    const rights = await PerformanceInitiativeAPI
      .getPerformanceInitiativeRightsById(piId ?? +this.props.match.params.id);
    RightsActions.emitSetPiRights(rights);
  };

  //region LISTENER RELATED METHODS
  private setRightsOnPi = () => {
    this.setState({ rightsOnPi: RightsStore.getRightsOnPI() });
  };
  private getPerformanceInitiative = () => {
    if (this.isMount) {
      const data: PerformanceInitiative = PerformanceInitiativeStore.getPerformanceInitiative();
      const oldData: PerformanceInitiative = PerformanceInitiativeStore.getOldPerformanceInitiative();
      if (this.state.data !== undefined && this.state.data.id === data.id) {
        this.setState({ data, oldData });
      } else {
        this.setState({ data, oldData, activeCode: 0 });
      }
    }
  };

  private getError = () => {
    this.setState({ error: ErrorStore.getError() });
  };

  private setErrors = () => {
    if (this.isMount) {
      this.setState({ errors: PerformanceInitiativeStore.getErrors() });
    }
  };

  private triggerErrors = () => {
    if (Utils.isKeywordNone(this.state.data.keywords.id)) {
      return PerformanceInitiativeActions.emitError('keywords');
    }
    if (this.state.errors.keywords) {
      return PerformanceInitiativeActions.clearError('keywords');
    }
  };

  private getMode = () => {
    if (this.isMount) {
      this.setState({ mode: ActivitiesStore.getMode() });
    }
    if (Utils.isOnEditMode(ActivitiesStore.getMode())) {
      this.triggerErrors();
    }
  };
  //endregion

  //region CLASS METHODS
  private isFormValidated = (isValid: boolean, formName: string) => {
    const isFormValid = [...this.state.isFormValid];

    switch (formName) {
      case 'metricsMilestones':
        isFormValid[0] = isValid;
        break;
      case 'moreInfo':
        isFormValid[1] = isValid;
        break;
      default:
        break;
    }
    this.setState(
      { isFormValid },
      () => TitleTaskboardActions.setIsSaveDisabled(!this.state.isFormValid.every(el => el)),
    );
  };

  private navigateToTab = (tab: PiTabs) => {
    this.props.history.push(`${this.props.match.url}/${tab}`);
  };

  private setTabErrorStatus = (onError: boolean, tab: PiTabs) => {
    switch (tab) {
      case PiTabs.COCKPIT:
        this.setState({ isCockpitOnError: onError });
        break;
    }
  };

  private confirmDeletionCancel = (): void => {
    this.setState({ isLoading: true });
    ActivitiesActions.emitChangeMode(ModeTypes.MODE_VIEW);
    PerformanceInitiativeActions.emitRemovePerformanceInitiative(this.state.data.id)
      .then(() => {
        const bc = this.state.data.businessChallenge;
        this.props.history.push(`/activities-board/${bc.performancePlanId}/business-challenge/${bc.id}/cockpit`);
      });
  };

  private setOpenNewAtModal = () => {
    this.setState({ isOpenNewAtModal: PerformanceInitiativeStore.getIsOpenNewAtModal() });
  };

  private hasMoreInfoError = () => {
    return (this.state.errors.keywords || this.state.isMoreInfoOnError) && Utils.isOnEditMode(this.state.mode);
  };

  private noConfirmDeletionCancel = (): void => {
    ActivitiesActions.emitChangeMode(ModeTypes.MODE_VIEW);
  };
  //endregion

  private getCanEdit = () => {
    return this.state.rightsOnPi ? this.state.rightsOnPi.canEdit() : false;
  };

  public calculateContentSize = () => {
    const containerHeight = Utils.calculateContentSize();
    if (this.state.contentHeight !== containerHeight) {
      this.setState({ contentHeight: containerHeight });
    }
  };

  private setOverviewData = async (activityId: number | null, type: TypeActivity.MILESTONE | TypeActivity.ACTION,
                                   providedActivity?: ActionEdit | Milestone, preventVisibilityToggle = false) => {
    let activity;
    if (!this.state.overviewPanelActivity || (this.state.overviewPanelActivity
      && this.state.overviewPanelActivity.id !== activityId)) {

      if (activityId!!) {
        activity = Utils.isActivityAction(type) ? await ActionAPI.getActionById(activityId!)
          : await PerformanceInitiativeAPI.getPerformanceInitiativeMilestoneById(this.state.data.id, activityId!);

        if (activity!!) {
          this.setState({ overviewPanelActivity: activity, overviewPanelActivityType: type }, () => {
            if (!preventVisibilityToggle) this.props.toggleOverviewPanelVisibility(true);
          });
        }
      } else {
        this.setState({ overviewPanelActivity: providedActivity, overviewPanelActivityType: type }, () => {
          if (!preventVisibilityToggle) this.props.toggleOverviewPanelVisibility(true);
        });
      }

    } else if (this.state.overviewPanelActivity && this.state.overviewPanelActivity.id === activityId) {
      this.props.toggleOverviewPanelVisibility();
    }

  };

  private toggleOverviewPanelVisibility = (activityIdToScrollTo?: number) => {
    if (activityIdToScrollTo !== undefined) {
      this.setOverviewData(activityIdToScrollTo, this.state.overviewPanelActivityType!, undefined, true)
        .then(() => this.setState({ activityIdToScrollTo }));
    } else {
      this.props.toggleOverviewPanelVisibility();
    }
  };

  public render() {
    if (this.state.error !== undefined) {
      return Utils.empty(<FormattedMessage id="noPI" defaultMessage="No PI was found for this Business Challenge"/>);
    }

    if (!this.state.data || this.state.data.id !== +this.props.match.params.id || this.state.isLoading) {
      return Utils.loader();
    }
    const errIcon = <Icon className="menu-icon-error error" name="warning circle"/>;
    const confirmDeletionTitle = <FormattedMessage id="confirmDeletion" defaultMessage="Confirm deletion"/>;
    const confirmDeletionMessage = <FormattedMessage id="confirmDeletionQuestion" defaultMessage="Confirm deletion?"/>;

    return (
      <div id="pi-container">
        {this.state.data.isPpDraft &&
          <EditingDraftBanner
            activityType={TypeActivity.PERFORMANCE_INITIATIVE}
            triggerSizeCalculation={this.calculateContentSize}
          />}
        {this.state.data.isClosed &&
          <ClosedBanner
            activityType={TypeActivity.PERFORMANCE_INITIATIVE}
            message={this.state.data.closeComment}
            date={this.state.data.closeDate}
            triggerSizeCalculation={this.calculateContentSize}
          />
        }

        {(Utils.isOnDeleteMode(this.state.mode))
          && <ConfirmModal
            closeNoFunction={this.noConfirmDeletionCancel}
            closeYesFunction={this.confirmDeletionCancel}
            title={confirmDeletionTitle}
            message={confirmDeletionMessage}
          />}
        <TitleContainer
          type={TypeActivity.PERFORMANCE_INITIATIVE}
          triggerSizeCalculation={this.calculateContentSize}
          emitSave={() =>
            PerformanceInitiativeActions.emitSavePerformanceInitiative(this.state.data, this.state.oldData)}
        />

        <Menu id="main-tabs-menu" className={'tab-menu-container'} pointing={true} secondary={true}>
          <Menu.Item
            active={this.props.location.pathname.indexOf(PiTabs.COCKPIT) > -1}
            className={this.state.isCockpitOnError ? 'error' : ''}
            onClick={() => this.navigateToTab(PiTabs.COCKPIT)}
          >
            <FormattedMessage id="pi.cockpit" defaultMessage="Cockpit"/>
            {this.state.isCockpitOnError && errIcon}
          </Menu.Item>

          <Menu.Item
            active={this.props.location.pathname.indexOf(PiTabs.METRICS_VIEW) > -1}
            onClick={() => this.navigateToTab(PiTabs.METRICS_VIEW)}
          >
            <FormattedMessage id="metricView" defaultMessage="KPI view"/>
          </Menu.Item>

          <Menu.Item
            active={this.props.location.pathname.indexOf(PiTabs.ACTIVITY) > -1}
            onClick={() => this.navigateToTab(PiTabs.ACTIVITY)}
          >
            <FormattedMessage id="pi.activity" defaultMessage="Activity"/>
          </Menu.Item>

          <Menu.Item
            active={this.props.location.pathname.indexOf(PiTabs.MORE_INFO) > -1}
            className={this.hasMoreInfoError() ? 'error' : ''}
            onClick={() => this.navigateToTab(PiTabs.MORE_INFO)}
          >
            <FormattedMessage id="moreInformation" defaultMessage="More information"/>
          </Menu.Item>

          <DatesContainer
            dataActivity={this.state.data}
            isOverviewPanelOpen={this.props.isOverviewPanelOpen}
            togglePanelVisibility={this.props.toggleOverviewPanelVisibility}
          />
        </Menu>
        <div
          id="content-container"
          className={`content-container ${(this.state.data.isAwaitingValidation && this.state.rightsOnPi?.canPublish() ? 'has-publication' : '')}`}
          style={{ height: `${this.state.contentHeight}px` }}
        >
          <Switch>
            <Route
              path={`${this.props.match.path}/${PiTabs.COCKPIT}`}
              render={() => <Cockpit
                canEdit={this.getCanEdit()}
                setTabErrorStatus={this.setTabErrorStatus}
              />}
            />
            <Route
              path={`${this.props.match.path}/${PiTabs.METRICS_VIEW}/:metricId?`}
              render={() => <MetricsContainer
                type={TypeActivity.PERFORMANCE_INITIATIVE}
                onDataChange={PerformanceInitiativeActions.emitChangePerformanceInitiative}
                mode={this.state.mode}
                isFormValidated={this.isFormValidated}
                activityId={+this.props.match.params.id}
              />}
            />
            <Route
              path={`${this.props.match.path}/${PiTabs.ACTIVITY}`}
              render={() => <PerformanceMeasures
                data={this.state.data}
                mode={this.state.mode}
                rightsOnPi={this.state.rightsOnPi}
                toggleOverviewPanelVisibility={this.props.toggleOverviewPanelVisibility}
                setOverviewData={this.setOverviewData}
                activityIdToScrollTo={this.state.activityIdToScrollTo}
              />}
            />
            <Route
              path={`${this.props.match.path}/${PiTabs.MORE_INFO}`}
              render={() => <MoreInfo
                rightsOnPi={this.state.rightsOnPi}
                isLinkedPi={this.state.data.businessChallenge.performancePlan.id !== +this.props.match.params.ppId}
                mode={this.state.mode}
              />}
            />
            <Redirect to={`${this.props.match.path}/${PiTabs.COCKPIT}`}/>
          </Switch>
          <OverviewPanel
            isOverviewPanelOpen={this.props.isOverviewPanelOpen}
            activity={this.state.overviewPanelActivity || this.state.data}
            typeActivity={this.state.overviewPanelActivityType || TypeActivity.PERFORMANCE_INITIATIVE}
            togglePanelVisibility={this.toggleOverviewPanelVisibility}
            isRelativeView={this.props.isOverviewPanelRelative}
            containerHeight={this.state.contentHeight}
            canEditPi={this.state.rightsOnPi ? this.state.rightsOnPi.canEdit() : false}
          />
        </div>
      </div>
    );
  }
}

export default guideWrapper(withRouter(ContentPI));
