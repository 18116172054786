import BusinessChallengeAPI from '../api/businessChallenge.api';
import ExportAPI from '../api/export.api';
import PerformanceInitiativeAPI from '../api/performanceInitiative.api';
import PerformancePlanAPI from '../api/performancePlan.api';
import ActionTypes from '../constants/actionTypes';
import Dispatcher from '../dispatcher';
import exportStore from '../stores/export.store';
import Utils from '../utils/utils';
import ReportingAPI from '../api/reporting.api';
import { SortBy } from 'src/constants/interfaces/action.interface';
import { CustomOptions } from 'src/components/activitiesBoard/containerActivities/titleContainer/exportCenter/interfaces/ExportStepsOptions';
import ExportPptxAPI from 'src/api/exportPptx.api';

export enum ExportType {
  REPORTING,
  PP_FULL,
  PP_FULL_PPTX,
  PP_COCKPIT,
  BC_FULL,
  BC_COCKPIT,
  PI_COCKPIT,
  PI_FULL,
  PI_AT_LIST,
  BC_FULL_AT_LIST,
  PP_HISTORY,
  BC_HISTORY,
  PI_HISTORY,
  PP_FULL_AT_LIST,
  ACTION,
  CUSTOM,
}

export enum CsvExportType {
  AT_LIST,
  ACTIVITIES_LIST,
}

export default class ExportActions {

  /**
   * Post feedback from API and emit actions POST_FEEDBACK
   * @return void
   */
  public static emitExport(exportType: ExportType, activityId: number, selectedReporting?: number, onlyTop5?: boolean,
                           sortBy?: SortBy, historyId?: number, customOptions?: CustomOptions, isPublicView?: boolean) {
    let request;
    let pdfName = '';
    switch (exportType) {
      case ExportType.REPORTING:
        request = ReportingAPI.exportReporting(activityId, selectedReporting, onlyTop5);
        pdfName = 'export_PP_reporting';
        break;
      case ExportType.CUSTOM:
        request = PerformancePlanAPI.exportCustom(activityId, customOptions);
        pdfName = 'export_PP_custom';
        break;
      case ExportType.PP_FULL:
        request = PerformancePlanAPI.exportFull(activityId);
        pdfName = 'export_PP_all';
        break;
      case ExportType.PP_FULL_PPTX:
        pdfName = 'export_PP_all';
        this.dispatchCanExport(false);
        ExportPptxAPI.exportFullPp(activityId, customOptions!)
          .then((data) => {
            Utils.saveBase64AsPptx(data, pdfName);
            this.dispatchCanExport(true);
          })
          .catch((err) => {
            this.dispatchCanExport(true);
            console.log(err);
          });
        break;
      case ExportType.PP_COCKPIT:
        pdfName = 'export_PP';
        this.dispatchCanExport(false);
        ExportPptxAPI.exportCockpitPp(activityId)
          .then((data) => {
            Utils.saveBase64AsPptx(data, pdfName);
            this.dispatchCanExport(true);
          })
          .catch((err) => {
            this.dispatchCanExport(true);
            console.log(err);
          });
        break;
      case ExportType.PP_FULL_AT_LIST :
        request = PerformancePlanAPI.exportAllActions(activityId);
        pdfName = 'export_PP_ATS_List_all';
        break;
      case ExportType.PP_HISTORY:
        request = PerformancePlanAPI.exportHistory(activityId, historyId);
        pdfName = 'export_PP_HISTORY';
        break;
      case ExportType.BC_FULL:
        request = BusinessChallengeAPI.exportFull(activityId);
        pdfName = 'export_BC_all';
        break;
      case ExportType.BC_COCKPIT:
        pdfName = 'export_BC';
        this.dispatchCanExport(false);
        ExportPptxAPI.exportCockpitBc(activityId, customOptions!)
          .then((data) => {
            Utils.saveBase64AsPptx(data, pdfName);
            this.dispatchCanExport(true);
          })
          .catch((err) => {
            this.dispatchCanExport(true);
            console.log(err);
          });
        break;
      case ExportType.BC_HISTORY:
        request = BusinessChallengeAPI.exportHistory(activityId, historyId, isPublicView);
        pdfName = 'export_BC_HISTORY';
        break;
      case ExportType.PI_FULL:
        request = PerformanceInitiativeAPI.exportFull(activityId);
        pdfName = 'export_PI_all';
        break;
      case ExportType.PI_COCKPIT:
        pdfName = 'export_PI';
        this.dispatchCanExport(false);
        ExportPptxAPI.exportCockpitPi(activityId, customOptions!)
          .then((data) => {
            Utils.saveBase64AsPptx(data, pdfName);
            this.dispatchCanExport(true);
          })
          .catch((err) => {
            this.dispatchCanExport(true);
            console.log(err);
          });
        break;
      case ExportType.PI_HISTORY:
        request = PerformanceInitiativeAPI.exportHistory(activityId, historyId, isPublicView);
        pdfName = 'export_PI_HISTORY';
        break;
      case ExportType.PI_AT_LIST:
        request = PerformanceInitiativeAPI.exportActionsList(activityId, sortBy);
        pdfName = 'export_PI_ATS_List';
        break;
      case ExportType.BC_FULL_AT_LIST :
        request = BusinessChallengeAPI.exportAllActions(activityId);
        pdfName = 'export_BC_ATS_List_all';
        break;
    }

    if (request) {
      request
        .then((pdfIdWrapper: any) => {
          this.dispatchPdfId(pdfIdWrapper.pdfId, pdfName);
        })
        .catch(() => {
          Utils.toastError();
          this.dispatchCanExport();
        });
    }
  }

  private static dispatchCanExport(canExport = true) {
    Dispatcher.dispatch({
      canExport,
      actionType: ActionTypes.CAN_EXPORT,
    });
  }

  private static dispatchStopFetchingProgression() {
    Dispatcher.dispatch({
      actionType: ActionTypes.PDF_STOP_FETCHING_PROGRESSION,
    });
  }

  private static dispatchPdfId(pdfId: string, pdfName: string) {
    Dispatcher.dispatch({
      pdfId,
      pdfName,
      actionType: ActionTypes.PDF_ID_RECEIVED,
    });
  }

  public static async emitPdfGenerationProgression(pdfId: string) {
    try {
      const res = await ExportAPI.getPdfGenerationProgress(pdfId);
      this.dispatchPdfGenerationProgression(res.progression);
      if (res.isPdfAvailable) {
        this.dispatchStopFetchingProgression();
        const pdf = await ExportAPI.getGeneratedPdf(pdfId);
        // eslint-disable-next-line
        const linkSource = `data:application/pdf;base64,${pdf.replace(new RegExp('\"', 'g'), '')}`;
        const link = document.createElement('a');
        link.href = linkSource;
        link.download = `${exportStore.getPdfName()}_${Utils.displayDate(new Date(), false, '-')}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        this.dispatchCanExport();
      }
    } catch (err) {
      this.dispatchCanExport();
      Utils.toastError();
    }
  }

  private static dispatchPdfGenerationProgression(progression: number | null) {
    Dispatcher.dispatch({
      progression,
      actionType: ActionTypes.PDF_PROGRESSION_UPDATE,
    });
  }
}
