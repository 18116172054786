import './closedBanner.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Popup } from 'semantic-ui-react';

import TypeActivity from '../../../../constants/typeActivity';
import Utils from '../../../../utils/utils';

interface IProps {
  activityType: TypeActivity;
  message?: string;
  date: Date;
  triggerSizeCalculation(): void;
}

class ClosedBanner extends React.Component<IProps> {

  public constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.triggerSizeCalculation();
  }

  public render() {
    return (
      <div id="closed-edition-banner">
        <div id="closed-banner-msg">
          {Utils.isActivityBc(this.props.activityType)
            ? <FormattedMessage id="close.bc" defaultMessage="This Business Challenge has been closed" />
            : <FormattedMessage id="close.pi" defaultMessage="This Performance Initiative has been closed" />
          } <FormattedMessage id="on" defaultMessage="on" /> {Utils.displayFancyDate(this.props.date)}
        </div>
        {this.props.message &&
          <Popup
            position="bottom center"
            size="tiny"
            inverted={true}
            trigger={<Icon className="banner-icon" name="info circle" />}
            content={this.props.message}
          />
        }
      </div>
    );
  }
}

export default ClosedBanner;
