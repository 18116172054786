import BusinessChallenge from './businessChallenge';
import User from './user';

export default class BusinessChallengeReporting {

  public id: number;
  public bcSnapshot: BcSnapshot;
  public lastSnapshotUpdate: Date;
  public comment: string;
}

export class BusinessChallengeReportingPost {

  public id?: number;
  public businessChallenge?: BusinessChallenge;
  public comment: string;
  public status: number;
}

export class BusinessChallengeReportingPut {

  public id?: number;
  public comment: string | null;
  public status: number;

  constructor(id: number, comment: string | null, status: number) {
    this.id = id;
    if (comment) this.comment = comment;
    this.status = status;
  }
}

export class BcSnapshot {

  id: number;
  code: number;
  desc: string;
  name: string;
  top5: boolean;
  achievements: string;
  issuesAndRisks: string;
  nextSteps: string;
  decisionsToBeMade: string;
  pillar: number;
  assignedAccounts: User[];
  milestones: [];
  linkedMilestones: [];
  metrics: [];
  linkedMetrics: [];
  performanceInitiatives: [];
  status: number;
  isClosed: boolean;
}
