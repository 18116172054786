import { EventEmitter } from 'events';

import Action from 'src/actions/action';
import NavActions from 'src/constants/actionTypes';
import Dispatcher from 'src/dispatcher';
import Notification from 'src/models/notification';

export enum Events {
  NOTIFICATIONS_CHANGE = 'NOTIFICATIONS_CHANGE',
  NOTIFICATIONS_NUMBER_CHANGE = 'NOTIFICATIONS_NUMBER_CHANGE',
  TOAST_NOTIFICATION = 'TOAST_NOTIFICATION',
}

class NotificationStore extends EventEmitter {

  private notifications: Notification[];
  private notificationNumber: number;

  constructor() {
    super();
    Dispatcher.register(this.registerToActions.bind(this));
  }

  /**
   * Gets all connected user notifications
   */
  public getNotifications(): Notification[] {
    return this.notifications;
  }

  /**
   * Gets connected user number of notifications
   */
  public getNumberOfNotifications(): number {
    return this.notificationNumber;
  }

  public addNotificationsChangeListener(callback: () => void) {
    this.on(Events.NOTIFICATIONS_CHANGE, callback);
  }

  public removeNotificationsChangeListener(callback: () => void) {
    this.removeListener(Events.NOTIFICATIONS_CHANGE, callback);
  }

  public addNotificationsNumberChangeListener(callback: () => void) {
    this.on(Events.NOTIFICATIONS_NUMBER_CHANGE, callback);
  }

  public removeNotificationsNumberChangeListener(callback: () => void) {
    this.removeListener(Events.NOTIFICATIONS_NUMBER_CHANGE, callback);
  }

  private setNotifications(notifications: Notification[]) {
    this.notifications = notifications;
    this.emit(Events.NOTIFICATIONS_CHANGE);
  }

  private setNotificationsNumber(notificationNumber: number) {
    this.notificationNumber = notificationNumber;
    this.emit(Events.NOTIFICATIONS_NUMBER_CHANGE);
  }

  private registerToActions(action: Action) {
    switch (action.actionType) {
      case NavActions.TOAST_NOTIFICATION:
        this.emit(Events.TOAST_NOTIFICATION, action.title, action.message, action.type, action.timeout);
        break;
      case NavActions.NOTIFICATIONS_GET_NUMBER:
        this.setNotificationsNumber(action.notificationNumber);
        break;
      case NavActions.NOTIFICATIONS_GET:
        this.setNotifications(action.notifications);
        break;
    }
  }
}

export default new NotificationStore();
