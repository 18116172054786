import { Months, Quarter, TimelineView } from '../../../../../../../../constants/timeline';
import React from 'react';
import './timelineSelectors.scss';
import YearSelector from './yearSelector/yearSelector';
import PeriodSelector from './periodSelector/periodSelector';
import TimelineUtils from '../../../../../../../../utils/timelineUtils';

interface IProps {
  selectedYear: number;
  selectedMonth: Months;
  selectedQuarter: Quarter;
  selectedView: TimelineView;
  setSelectedYear: (year: number) => void;
  setSelectedMonth: (month: Months) => void;
  setSelectedQuarter: (quarter: Quarter) => void;
  setMultiplePeriods: (period: Quarter | Months, year: number) => void;
}

const timelineSelectors = (props: IProps) => {

  return (
    <>
      <YearSelector
        selectedYear={props.selectedYear}
        setSelectedYear={props.setSelectedYear}
      />
      <PeriodSelector
        selectedView={props.selectedView}
        selectedYear={props.selectedYear}
        selectedMonth={props.selectedMonth}
        selectedQuarter={props.selectedQuarter}
        setSelectedMonth={props.setSelectedMonth}
        setSelectedQuarter={props.setSelectedQuarter}
        setMultiplePeriods={props.setMultiplePeriods}
        isVisible={!TimelineUtils.isOnYearView(props.selectedView)}
      />
    </>
  );
};

export default timelineSelectors;
