import { Button, Dropdown, Icon } from 'semantic-ui-react';
import { Months, Quarter, TimelineView } from '../../constants/timeline';
import { FormattedMessage } from 'react-intl';
import TypeActivity from '../../constants/typeActivity';
import Timeline from './timeline/timeline';
import * as React from 'react';
import { FilterObject, SimpleFilter } from '../../constants/timeline.types';
import TimelineActions from '../../actions/timeline.action';
import TimelineUtils from '../../utils/timelineUtils';
import Utils from '../../utils/utils';

interface IStates {
  timeSelected: TimelineView;
  yearSelected: number;
  quarterSelected: Quarter;
  monthSelected: Months;
  filters: FilterObject[];
  filterSelected: FilterObject | SimpleFilter | undefined;
}

class TimelineContainer extends React.Component<{}, IStates> {

  //region REACT LIFECYCLE METHODS
  public constructor(props: {}) {
    super(props);
    const currentMonth = new Date().getMonth();
    this.state = {
      timeSelected: TimelineView.YEAR,
      yearSelected: (new Date()).getFullYear(),
      quarterSelected: this.getCurrentQuarter(currentMonth),
      monthSelected: currentMonth,
      filters: [],
      filterSelected: undefined,
    };
  }

  public componentDidMount(): void {
    TimelineActions.emitGetInformationTimeline(TimelineView.YEAR, (new Date()).getFullYear());
  }

  public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<IStates>, snapshot?: any) {
    if (prevState.yearSelected !== this.state.yearSelected) {
      TimelineActions.emitGetInformationTimeline(this.state.timeSelected, this.state.yearSelected);
    }
  }
  //endregion

  //region ACTIVITIES FILTERS METHODS
  public setFilters = (filters: FilterObject[]) => {
    this.setState({ filters });
  };

  private updateSelectedFilter = (filter: FilterObject | SimpleFilter | undefined) => {
    if (this.state.filterSelected !== filter) {
      this.setState({ filterSelected: filter });
    }
  };

  private getFilters = (): JSX.Element[] => {
    const DOM_ELEMENTS: JSX.Element[] = [(
            <FormattedMessage id="all" defaultMessage="All" key="all">
                {msg =>
                    <Dropdown.Item
                        toggle="true"
                        content={msg}
                        selected={this.state.filterSelected === undefined}
                        key="all"
                        active={this.state.filterSelected === undefined}
                        onClick={() => this.updateSelectedFilter(undefined)}
                    />
                }
            </FormattedMessage>
        )];

    this.state.filters.forEach((myElement: FilterObject, index: number) => {
      DOM_ELEMENTS.push(
                <Dropdown.Item
                    toggle="true"
                    text={`${myElement.name}`}
                    description={myElement.parentName && `${myElement.parentName}`}
                    selected={this.state.filterSelected === myElement}
                    key={`${myElement.name}${index}`}
                    active={this.state.filterSelected === myElement}
                    onClick={() => this.updateSelectedFilter(myElement)}
                />,
            );
    });
    const isFilterActions = this.state.filterSelected
            ? Utils.isActivityAt(this.state.filterSelected.type)
            : false;

    DOM_ELEMENTS.push(
            <Dropdown.Item
                toggle="true"
                content="Actions"
                selected={isFilterActions}
                key="actions"
                active={isFilterActions}
                onClick={() => this.updateSelectedFilter({ type: TypeActivity.ACTION })}
            />,
        );
    return DOM_ELEMENTS;
  };
  //endregion

  //region TIMELINE VIEW SELECTION METHODS
  private getCurrentQuarter = (currentMonth: number): Quarter => {
    const currentQuarter: number = Math.floor((currentMonth + 3) / 3);
    switch (currentQuarter) {
      case 1:
        return Quarter.Q1;
      case 2:
        return Quarter.Q2;
      case 3:
        return Quarter.Q3;
      case 4:
        return Quarter.Q4;
      default:
        return Quarter.Q1;
    }
  };

  private setTimeSelected = (timeSelected: TimelineView) => {
    this.setState({ timeSelected });
  };

  public setMonth = (idMonth: Months) => {
    if (this.state.monthSelected !== idMonth) {
      this.setState({
        monthSelected: idMonth,
        quarterSelected: this.getCurrentQuarter(idMonth),
      });
    }
  };

  private getSelector = (): JSX.Element[] => {
    const selector: JSX.Element[] = [(
            <div className="selector" key="yearSelector">
                <div className="pointer">
                    <Icon
                        name="angle left"
                        size="large"
                        onClick={() => this.setState({ yearSelected: this.state.yearSelected - 1 })}
                    />
                </div>
                <div id="yearSelected">{this.state.yearSelected}</div>
                <div className="pointer" onClick={() => this.setState({ yearSelected: this.state.yearSelected + 1 })}>
                    <Icon name="angle right" size="large" />
                </div>
            </div>
        )];

    switch (this.state.timeSelected) {
      case TimelineView.QUARTER:
        selector.push(
                    <div className="selector" key="quarterSelector">
                        <div className="pointer">
                            <Icon
                                name="angle left"
                                size="large"
                                onClick={() => this.previousQuarter()}
                            />
                        </div>
                        <div className="specific-selected">
                          {TimelineUtils.getTitleOfQuarter(this.state.quarterSelected)}
                        </div>
                        <div className="pointer">
                            <Icon
                                name="angle right"
                                size="large"
                                onClick={() => this.nextQuarter()}
                            />
                        </div>
                    </div>);
        break;

      case TimelineView.MONTH:
        selector.push(
                    <div className="selector" key="monthSelector">
                        <div className="pointer">
                            <Icon
                                name="angle left"
                                size="large"
                                onClick={() => this.previousMonth()}
                            />
                        </div>
                        <div className="specific-selected">
                          {TimelineUtils.getTitleOfMonth(this.state.monthSelected)}
                        </div>
                        <div className="pointer">
                            <Icon
                                name="angle right"
                                size="large"
                                onClick={() => this.nextMonth()}
                            />
                        </div>
                    </div>);
    }

    return selector;

  };

  private previousMonth = (): void => {
    if (this.state.monthSelected === Months.JANUARY) {
      this.setState({
        monthSelected: Months.DECEMBER,
        yearSelected: this.state.yearSelected - 1,
      });
      return;
    }
    this.setState({ monthSelected: this.state.monthSelected - 1 });
  };

  private nextMonth = (): void => {
    if (this.state.monthSelected === Months.DECEMBER) {
      this.setState({
        monthSelected: Months.JANUARY,
        yearSelected: this.state.yearSelected + 1,
      });
      return;
    }
    this.setState({ monthSelected: this.state.monthSelected + 1 });
  };

  private previousQuarter = (): void => {
    if (this.state.quarterSelected === Quarter.Q1) {
      this.setState({
        quarterSelected: Quarter.Q4,
        yearSelected: this.state.yearSelected - 1,
      });
      return;
    }
    this.setState({ quarterSelected: this.state.quarterSelected - 1 });
  };

  private nextQuarter = (): void => {
    if (this.state.quarterSelected === Quarter.Q4) {
      this.setState({
        quarterSelected: Quarter.Q1,
        yearSelected: this.state.yearSelected + 1,
      });
      return;
    }
    this.setState({ quarterSelected: this.state.quarterSelected + 1 });
  };
  //endregion

  render() {
    return (
            <>
                <div className="header">
                    <div id="title">TIMELINE</div>
                    <div id="actions">
                        <Button.Group basic={true} id="timeSelect">
                            <Button
                                className={(this.state.timeSelected === TimelineView.MONTH) ? 'selected' : 'unselected'}
                                onClick={() => this.setTimeSelected(TimelineView.MONTH)}
                            >
                                <FormattedMessage id="month" defaultMessage="Month"/>
                            </Button>
                            <Button
                                className={(this.state.timeSelected === TimelineView.QUARTER) ? 'selected' : 'unselected'}
                                onClick={() => this.setTimeSelected(TimelineView.QUARTER)}
                            >
                                <FormattedMessage id="3month" defaultMessage="3 Months"/>
                            </Button>
                            <Button
                                className={(this.state.timeSelected === TimelineView.YEAR) ? 'selected' : 'unselected'}
                                onClick={() => this.setTimeSelected(TimelineView.YEAR)}
                            >
                                <FormattedMessage id="year" defaultMessage="YEAR"/>
                            </Button>
                        </Button.Group>
                    </div>
                </div>
                <div className="content">
                    <div id="actions-timeline">
                        <div id="selector">
                            {this.getSelector()}
                        </div>
                        <div id="filter">
                            <FormattedMessage id="all" defaultMessage="All">
                                {msg =>
                                    <Dropdown
                                        id="filterButton"
                                        text={(this.state.filterSelected)
                                            ? (Utils.isActivityAt(this.state.filterSelected.type))
                                                ? 'Actions'
                                                : (this.state.filterSelected as FilterObject).name
                                            : (msg as string)}
                                        icon="filter"
                                        labeled={true}
                                        button={true}
                                        basic={true}
                                        className="icon"
                                        direction="left"
                                        fluid={true}
                                    >
                                        <Dropdown.Menu id="dropdown-filters">
                                            <Dropdown.Menu scrolling={true}>
                                                {this.getFilters()}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                            </FormattedMessage>
                        </div>
                    </div>
                    <Timeline
                        timeSelect={this.state.timeSelected}
                        year={this.state.yearSelected}
                        quarter={this.state.quarterSelected}
                        month={this.state.monthSelected}
                        setMonth={this.setMonth}
                        setFilters={this.setFilters}
                        filterSelected={this.state.filterSelected}
                    />
                </div>
            </>
    );
  }
}
export default TimelineContainer;
