import './linkLabel.scss';

import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Icon, Popup } from 'semantic-ui-react';

import Utils from '../../../utils/utils';
import TypeActivity from '../../../constants/typeActivity';
import { PpTabs } from '../../activitiesBoard/containerActivities/contentPP/contentPP';
import PerformancePlan from '../../../models/performancePlan';
import BusinessChallenge from '../../../models/businessChallenge';

export enum LinkLabelMode {
  LARGE = 'large',
  SMALL = 'small',
  ICON_ONLY = 'icon-only',
}

interface IProps extends RouteComponentProps {
  pp?: PerformancePlan;
  bc?: BusinessChallenge;
  mode?: LinkLabelMode;
  disableLink?: boolean;
}

class LinkLabel extends React.Component<IProps> {
  private onClick = () => {
    if (!this.props.disableLink && !!this.props.pp) {
      if (Utils.isExternalView(this.props.pp.id, TypeActivity.PERFORMANCE_PLAN)) {
        this.props.history.push(`/pp-external/${this.props.pp.id}`);
      } else {
        this.props.history.push(`/activities-board/performance-plan/${this.props.pp.id}/${PpTabs.MORE_INFO}`);
      }
    }
  };

  render() {
    const modeClass = this.props.mode ? this.props.mode : LinkLabelMode.LARGE;
    const linkClass = !this.props.disableLink ? 'link' : '';

    return (
      <Popup
        inverted={true}
        size="small"
        position={this.props.mode === LinkLabelMode.ICON_ONLY ? 'right center' : 'bottom center'}
        content={<FormattedMessage id="linkedTooltip" defaultMessage="This activity comes from an other Plan" />}
        trigger={(
          <div className={`link-label ${modeClass} ${linkClass}`} onClick={this.onClick}>
            <Icon name="linkify" />
            {!!this.props.pp &&
              <>
                {this.props.pp.name}
                {!!this.props.pp.year && <span className="label-year">&nbsp;({this.props.pp.year})</span>}
                {!!this.props.bc && <span className="bc-code">&nbsp;- BC{Utils.leadingZero(this.props.bc.code)}</span>}
              </>
            }
          </div>
        )}
      />
    );
  }
}

export default withRouter(LinkLabel);
