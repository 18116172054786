import './exportAllBcsPis.scss';

import React, { Component } from 'react';
import { Button, Checkbox, Divider, Icon, Modal, Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import ExportAPI from '../../../../../api/export.api';
import Utils from '../../../../../utils/utils';
import NotificationActions from '../../../../../actions/notification-actions';
import { ToastType } from '../../../../common/toast/toast';
import LoggingAPI from '../../../../../api/logging.api';
import { ExportChoices, ExportChoicesAttributes, ExportColumns } from '../../../../../constants/exportColumns';
import Scrollbars from 'react-custom-scrollbars';

interface IStates {
  exportXLSLoading: boolean;
  showModal: boolean;
  exportChoices: ExportChoices;
  isOnError: boolean;
}

class ExportAllBcsPisButton extends Component<{}, IStates> {

  public constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      exportXLSLoading: false,
      showModal: false,
      exportChoices: new ExportChoices(),
      isOnError: false,
    };
  }

  private exportSheet = () => {
    this.setState({ exportXLSLoading: true, showModal: false });
    ExportAPI.getAllBcsPisExportSheet(this.state.exportChoices).then((url: string) => {
      const link = document.createElement('a');
      link.href = url;
      link.download = `export_all_bcs_and_pis_${Utils.displayDate(new Date(), false, '-')}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      NotificationActions.toast(
        <FormattedMessage id="export" defaultMessage="Success" />,
        <FormattedMessage id="exportDone" defaultMessage="Sheet successfully exported"/>,
        ToastType.SUCCESS,
      );
      this.setState({ exportXLSLoading: false });
    })
      .catch(async (err) => {
        await LoggingAPI.sendErrorToLoggingService(err);
        Utils.toastError();
        this.setState({ exportXLSLoading: false });
      });
  }

  private updateExportChoices(column: ExportColumns) {
    const choices = { ...this.state.exportChoices };
    switch (column) {
      case ExportColumns.CODE:
        choices.code = !this.state.exportChoices.code;
        break;
      case ExportColumns.PLAN:
        choices.ppName = !this.state.exportChoices.ppName;
        break;
      case ExportColumns.YEAR:
        choices.ppYear = !this.state.exportChoices.ppYear;
        break;
      case ExportColumns.LEADERS:
        choices.leaders = !this.state.exportChoices.leaders;
        break;
      case ExportColumns.KEYWORDS:
        choices.keyword = !this.state.exportChoices.keyword;
        break;
      case ExportColumns.REFERENT:
        choices.ppReferent = !this.state.exportChoices.ppReferent;
        break;
      case ExportColumns.GBU:
        choices.gbu = !this.state.exportChoices.gbu;
        break;
      case ExportColumns.GEO:
        choices.geo = !this.state.exportChoices.geo;
        break;
      case ExportColumns.CO:
        choices.country = !this.state.exportChoices.country;
        break;
      case ExportColumns.SUB_CO:
        choices.subcountry = !this.state.exportChoices.subcountry;
        break;
      default:
        choices.name = !this.state.exportChoices.name;
        break;
    }
    this.setState({ exportChoices: choices, isOnError: !Object.values(choices).some(v => v) });
  }

  private generateCheckboxForColumn(column: ExportColumns, index: number) {
    let label: JSX.Element;
    let attributeName: string;
    switch (column) {
      case ExportColumns.CODE:
        label = <FormattedMessage id="exportChoices.code" defaultMessage="BC & PI codes" />;
        attributeName = ExportChoicesAttributes.CODE;
        break;
      case ExportColumns.PLAN:
        label = <FormattedMessage id="exportChoices.planName" defaultMessage="Plan Name" />;
        attributeName = ExportChoicesAttributes.PP_NAME;
        break;
      case ExportColumns.YEAR:
        label = <FormattedMessage id="exportChoices.planYear" defaultMessage="Plan Year" />;
        attributeName = ExportChoicesAttributes.PP_YEAR;
        break;
      case ExportColumns.KEYWORDS:
        label = <FormattedMessage id="exportChoices.keywords" defaultMessage="BC & PI Keywords" />;
        attributeName = ExportChoicesAttributes.KEYWORD;
        break;
      case ExportColumns.LEADERS:
        label = <FormattedMessage id="exportChoices.leaders" defaultMessage="BC & PI Leaders" />;
        attributeName = ExportChoicesAttributes.LEADERS;
        break;
      case ExportColumns.REFERENT:
        label = <FormattedMessage id="exportChoices.ppReferent" defaultMessage="PP Referent" />;
        attributeName = ExportChoicesAttributes.PP_REFERENT;
        break;
      case ExportColumns.CO:
        label = <FormattedMessage id="exportChoices.country" defaultMessage="Country" />;
        attributeName = ExportChoicesAttributes.COUNTRY;
        break;
      case ExportColumns.GEO:
        label = <FormattedMessage id="exportChoices.geo" defaultMessage="Geography" />;
        attributeName = ExportChoicesAttributes.GEO;
        break;
      case ExportColumns.SUB_CO:
        label = <FormattedMessage id="exportChoices.subCo" defaultMessage="SubCountry" />;
        attributeName = ExportChoicesAttributes.SUBCOUNTRY;
        break;
      case ExportColumns.GBU:
        label = <FormattedMessage id="exportChoices.gbu" defaultMessage="GBU" />;
        attributeName = ExportChoicesAttributes.GBU;
        break;
      default:
        label = <FormattedMessage id="exportChoices.name" defaultMessage="BC & PI names" />;
        attributeName = ExportChoicesAttributes.NAME;
        break;
    }
    return (
      <React.Fragment key={column}>
        {index !== 0 && <Divider />}
        <div className="choice-entry">
          <div className="grey-title">
            {label}
          </div>
          <Checkbox
            checked={this.state.exportChoices[attributeName]}
            onChange={() => this.updateExportChoices(column)}
          />
        </div>
      </React.Fragment>
    );
  }

  public render() {
    return (
      <>
        <Popup
          inverted={true}
          content={<FormattedMessage id="exportBcsPisAsSheet" defaultMessage="Export all BCs/PIs (XLS)" />}
          size="tiny"
          position="top center"
          trigger={
            <Button
              id="export-activities-btn"
              basic={true}
              onClick={() => this.setState({ showModal: true })}
              loading={this.state.exportXLSLoading}
            >
              <Icon name="download" />
            </Button>
          }
        />
        <Modal open={this.state.showModal} onClose={() => this.setState({ showModal: false })} className="user-list-modal">
          <Modal.Header>
            <>
              <FormattedMessage
                id="exportChoices.columnsSelection"
                defaultMessage="Columns selection for Export"
              />
              <span id="err-icon">
                {this.state.isOnError && Utils.getErrorPopup(
                  <FormattedMessage
                    id="exportChoices.oneColumnNeeded"
                    defaultMessage="At least one column needs to be checked"
                  />)
                }
              </span>
            </>
          </Modal.Header>
          <Modal.Content>
            <span className="grey-title">
              <FormattedMessage
                id="exportChoices.chooseColumns"
                defaultMessage="Please select which columns you wish to see in your export sheet :"
              />
            </span>
            <Scrollbars className="scroll-container">
              <div className="choices-container">
                {Object.values(ExportColumns).map((col, index) => this.generateCheckboxForColumn(col, index))}
              </div>
            </Scrollbars>
          </Modal.Content>
          <Modal.Actions>
            <Button id="cancel-modal-btn" onClick={() => this.setState({ showModal: false })}>
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <Button id="confirm-modal-btn" onClick={this.exportSheet} disabled={this.state.isOnError}>
              <FormattedMessage id="confirm" defaultMessage="Confirm" />
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}
export default ExportAllBcsPisButton;
