import './exportCenter.scss';

import * as React from 'react';
import Utils from 'src/utils/utils';
import { ExportType } from 'src/actions/export.action';
import { Button, Checkbox, Icon } from 'semantic-ui-react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import TypeActivity from 'src/constants/typeActivity';
import { ExportStepsOptions } from './interfaces/ExportStepsOptions';
import PerformancePlan from 'src/models/performancePlan';
import BusinessChallenge from 'src/models/businessChallenge';
import PerformanceInitiative from 'src/models/performanceInitiative';

interface IProps extends InjectedIntlProps{
  data: PerformancePlan | BusinessChallenge | PerformanceInitiative;
  exportStepsOptions: ExportStepsOptions;
  handleClick: (exportType: ExportType, activityId: number, selectedReporting?: number, onlyTop5?: boolean) => void;
  toggleActivity: (type: TypeActivity, id: number, isPresent: boolean, parentId?: number) => void;
  toggleActivities: (select: boolean) => void;
  performancePlan: PerformancePlan | undefined;
}

interface IStates {
  selectAllActivity: boolean;
}

class ExportThirdStep extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      selectAllActivity: true,
    };
  }

  public toggleAllSelection = () => {
    this.setState(
      prevState => (
        {
          selectAllActivity: !prevState.selectAllActivity,
        }
      ),
      () => {
        this.props.toggleActivities(this.state.selectAllActivity);
      });
  }

  private buildBcs = (pp: PerformancePlan) => {
    let bcs = pp.businessChallenges.map(bc => this.filterBc(bc, false));
    if (pp.linkedBcs) {
      bcs = bcs.concat(pp.linkedBcs.map(bc => this.filterBc(bc, true)));
    }
    return bcs;
  }

  private filterBc = (bc: BusinessChallenge, isLinked: boolean) => {
    return {
      isLinked,
      id: bc.id,
      name: bc.name,
      code: bc.code,
      selected: this.props.exportStepsOptions.bcs.includes(bc.id),
      performanceInitiatives: this.props.exportStepsOptions.includePis ? this.buildPis(bc) : [],
    };
  }

  private buildPis = (bc: BusinessChallenge) => {
    let pis = bc.performanceInitiatives.map(pi => this.filterPi(pi, false, bc.id));
    if (bc.linkedPis) {
      pis = pis.concat(bc.linkedPis.map(pi => this.filterPi(pi, true, bc.id)));
    }
    return pis;
  }

  private filterPi = (pi: PerformanceInitiative, isLinked: boolean, bcId: number) => {
    return {
      bcId,
      isLinked,
      id: pi.id,
      name: pi.name,
      code: pi.code,
      selected: this.props.exportStepsOptions.pis.includes(pi.id),
    };
  }

  public render() {
    if (!this.props.performancePlan) {
      return <div className="loader">{Utils.loader()}</div>;
    }
    switch (this.props.exportStepsOptions.selectedExportType) {
      case ExportType.REPORTING:
        const reporting = this.props.exportStepsOptions.selectedReporting;
        const reportings = [
          {
            key:'allBcs',
            title: <FormattedMessage id="allBcs" defaultMessage="All BCs" />,
            handleClick: () => this.props.handleClick(ExportType.REPORTING, this.props.data.id, reporting?.id),
          },
          {
            key:'onlyTop5',
            title: <FormattedMessage id="onlyTop5" defaultMessage="Only Top5 BCs" />,
            handleClick: () => this.props.handleClick(ExportType.REPORTING, this.props.data.id, reporting?.id, true),
          },
        ];
        return (
          <div className="options-container">
            {reporting && <p> {reporting.name}
              <span className="reporting-date"> - {Utils.displayDate(reporting.dueDate)}</span>
              </p>}
            {
              reportings.map((report) => {
                return (
                  <Button key={report.key} onClick={report.handleClick}>
                    {report.title}
                  </Button>
                );
              })
            }
          </div>
        );
      case ExportType.CUSTOM:
        const pp = this.props.performancePlan;
        let panels: any = [];
        if (pp && this.props.exportStepsOptions.includeBcs) {
          panels = this.buildBcs(pp);
        }
        return (
          <div className="activity-selection-container">
            <div className="filter">
              <Checkbox
                label={<label><FormattedMessage id="selectAll" defaultMessage="Select all" /></label>}
                onChange={this.toggleAllSelection}
                checked={this.state.selectAllActivity}
                toggle={true}
              />
            </div>
            {
              panels.map((bc) => {
                return (
                  <div className="activity-selection" key={bc.id}>
                  <div className="selection">
                    <Checkbox
                      id={`checkbox-${bc.id}`}
                      name={`checkbox-${bc.id}`}
                      checked={bc.selected}
                      onClick={() => this.props.toggleActivity(TypeActivity.BUSINESS_CHALLENGE, bc.id, bc.selected)}
                    />
                    <label className="bc-label" htmlFor={`checkbox-${bc.id}`}>
                      {bc.isLinked ? <Icon name="linkify" /> : ''}
                      {Utils.formatActivityCode(bc.code, TypeActivity.BUSINESS_CHALLENGE)} - {bc.name}
                    </label>
                  </div>
                  <ul>
                    {bc.performanceInitiatives.map((pi) => {
                      return (
                        <li key={pi.id}>
                          <Checkbox
                            id={`checkbox-${pi.id}`}
                            name={`checkbox-${pi.id}`}
                            checked={pi.selected}
                            onClick={() => this.props.toggleActivity(TypeActivity.PERFORMANCE_INITIATIVE,
                                                                     pi.id, pi.selected, pi.bcId)}
                          />
                          <label className="pi-label" htmlFor={`checkbox-${pi.id}`}>
                            {pi.isLinked ? <Icon name="linkify" /> : ''}
                            {Utils.formatActivityCode(pi.code, TypeActivity.PERFORMANCE_INITIATIVE)} - {pi.name}
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                );
              })
            }
          </div>
        );
    }
  }

}

export default injectIntl(ExportThirdStep);
