import { EventEmitter } from 'events';

import ActionTypes from 'src/constants/actionTypes';
import PerformanceInitiative from 'src/models/performanceInitiative';
import PerformanceInitiativeErrors, { defaultPerformanceInitiativeErrors } from 'src/models/pi-errors';
import Action from '../actions/action';
import Dispatcher from '../dispatcher';
import Keywords from '../models/keywords';
import Milestone from '../models/milestone';

class PerformanceInitiativeStore extends EventEmitter {

  private performanceInitiative: PerformanceInitiative;
  private oldPerformanceInitiative: PerformanceInitiative;
  private keywords: Keywords[];
  private errors: PerformanceInitiativeErrors = { ...defaultPerformanceInitiativeErrors };

  /* NEW STORE FOR  IBOOST V2 */
  private isOpenNewAtModal: boolean;

  constructor() {
    super();
    Dispatcher.register(this.registerToActions.bind(this));
  }

  /**
   * Get Performance Initiative
   * @return PerformanceInitiative
   */
  public getPerformanceInitiative(): PerformanceInitiative {
    return this.performanceInitiative;
  }

  /**
   * Get Performance Initiative
   * @return PerformanceInitiative
   */
  public getOldPerformanceInitiative(): PerformanceInitiative {
    return this.oldPerformanceInitiative;
  }

  /**
   * Invalidate Performance Initiative
   * @return void
   */
  public invalidatePerformanceInitiative(): void {
    delete this.performanceInitiative;
    delete this.oldPerformanceInitiative;
  }

   /**
   * Get Error
   * @return PerformanceInitiativeErrors
   */
  public getErrors(): PerformanceInitiativeErrors {
    return this.errors;
  }

  /**
 * Get Error
 * @return BusinessChallengeErrors[key]
 */
  public getError(key: string): PerformanceInitiativeErrors {
    return this.errors[key];
  }

    /**
   * Set Error BusinessChallengeErrors
   */
  public setError(error: string) {
    this.errors[error] = true;
    this.emit(ActionTypes.PERFORMANCE_INITIATIVE_ERRORS.toString());
  }

  /**
   * Clear Error BusinessChallengeErrors
   */
  public clearError(error: string) {
    this.errors[error] = false;
    this.emit(ActionTypes.PERFORMANCE_INITIATIVE_ERRORS.toString());
  }

  /**
   * Reset all Errors BusinessChallengeErrors
   */
  public resetError() {
    this.errors = { ...defaultPerformanceInitiativeErrors };
    this.emit(ActionTypes.PERFORMANCE_INITIATIVE_ERRORS.toString());
  }

  /**
   * Get Keywords
   * @return PiCategory[]
   */
  public getKeywords(): Keywords[] {
    return this.keywords;
  }

  /**
   * Set Keywords
   * @return PiCategory[]
   */
  public setKeywords(keywords: Keywords[]) {
    this.keywords = keywords;
    this.emit(ActionTypes.PERFORMANCE_INITIATIVE_KEYWORDS_GET.toString());
  }

  /**
   *  Set Performance Initiative
   * @param performanceInitiative: PerformanceInitiative
   * @return void
   */
  private setPerformanceInitiative(performanceInitiative: PerformanceInitiative): void {
    this.performanceInitiative = performanceInitiative;
    this.oldPerformanceInitiative = JSON.parse(JSON.stringify(performanceInitiative));
    this.emit(ActionTypes.PERFORMANCE_INITIATIVE_GET.toString());
  }

  private setMilestones(milestones: Milestone[]): void {
    this.performanceInitiative.milestones = milestones;
    this.oldPerformanceInitiative.milestones = milestones;
    this.emit(ActionTypes.REFRESH_MILESTONES.toString());
  }

  /**
   *  Delete Performance Initiative
   * @return void
   */
  private deletePerformanceInitiative(): void {
    this.emit(ActionTypes.PERFORMANCE_INITIATIVE_DELETED.toString());
  }

  private changePerformanceInitiative(idElement: string, value: any): void {
    const piTemp = { ...this.performanceInitiative };
    if (idElement === 'keywords') {
      piTemp.keywords.id = value;
    } else {
      piTemp[idElement] = value;
    }
    this.performanceInitiative = piTemp;
    this.emit(ActionTypes.PERFORMANCE_INITIATIVE_CHANGE.toString());
  }

  /**
 * Return boolean to know if new AT modal is open
 * @returns {boolean}
 */
  public getIsOpenNewAtModal(): boolean {
    return this.isOpenNewAtModal;
  }

  /**
   * Set open new at modal
   * @param {boolean} open
   * @returns {void}
   */
  private setIsOpenNewAtModal(open: boolean): void {
    this.isOpenNewAtModal = open;
    this.emit(ActionTypes.SET_OPEN_NEW_AT_MODAL.toString());
  }

  /**
   * Emit saved Performance Initiative
   * @return void
   */
  private savedPerformanceInitiative() {
    this.emit(ActionTypes.PERFORMANCE_INITIATIVE_SAVED.toString());
  }

  /**
   * Rollback changes Performance Initiative
   * @return void
   */
  private rollbackPerformanceInitiative() {
    this.performanceInitiative = JSON.parse(JSON.stringify(this.oldPerformanceInitiative));
    this.emit(ActionTypes.PERFORMANCE_INITIATIVE_CHANGE.toString());
  }

  /**
   * Performs a function according to an action
   * @param action :Action
   * @return void
   */
  private registerToActions(action: Action) {
    switch (action.actionType) {
      case ActionTypes.PERFORMANCE_INITIATIVE_GET:
        this.setPerformanceInitiative(action.performanceInitiative);
        break;
      case ActionTypes.PERFORMANCE_INITIATIVE_CHANGE:
        this.changePerformanceInitiative(action.idAttribute, action.value);
        break;
      case ActionTypes.PERFORMANCE_INITIATIVE_ROLLBACK:
        this.rollbackPerformanceInitiative();
        break;
      case ActionTypes.PERFORMANCE_INITIATIVE_DELETED:
        this.deletePerformanceInitiative();
        break;
      case ActionTypes.PERFORMANCE_INITIATIVE_SAVED:
        this.savedPerformanceInitiative();
        break;
      case ActionTypes.PERFORMANCE_INITIATIVE_KEYWORDS_GET:
        this.setKeywords(action.keywords);
        break;
      case ActionTypes.ACTIONS_FAVOURITE_STATUS_CHANGED:
        this.setMilestones(action.milestones);
        break;
      case ActionTypes.SET_OPEN_NEW_AT_MODAL:
        this.setIsOpenNewAtModal(action.isOpen);
        break;
    }
  }

}

export default new PerformanceInitiativeStore();
