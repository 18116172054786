import './timeline.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import ActionTypes from 'src/constants/actionTypes';
import TypeElementTimeline, { Months, Quarter, TimelineView } from 'src/constants/timeline';
import TypeActivity from 'src/constants/typeActivity';
import ZoneTimeline from 'src/models/zone';
import TimelineStore from 'src/stores/timeline.store';
import Utils from 'src/utils/utils';
import ElementTimeline from './elementTimeline/elementTimeline';
import ElementUpcoming from './elementUpcoming/elementUpcoming';
import {
  At,
  Bc,
  BcWithMilestones,
  FilterObject,
  Pi,
  PiWithMilestones,
  Pp,
  RepObjectOnTimeline,
  SimpleFilter,
  TimelineObject,
} from '../../../constants/timeline.types';
import CustomScrollBars from '../../common/customScrollBars/customScrollBars';
import TimelineUtils from '../../../utils/timelineUtils';

interface IProps {
  timeSelect: TimelineView;
  year: number;
  quarter: Quarter;
  month: Months;
  setMonth: (idMonth: Months) => void;
  setFilters: (filters: FilterObject[]) => void;
  filterSelected: FilterObject | SimpleFilter | undefined;
}

interface IStates {
  actions: At[];
  reporting: RepObjectOnTimeline[];
  bc: BcWithMilestones[];
  pi: PiWithMilestones[];
  pp: Pp[];
  loading: boolean;
}

class Timeline extends React.Component<IProps, IStates> {

  private zones: ZoneTimeline[] = [];
  private elementsTimeline: TimelineObject[] = [];
  private isFirstReporting: boolean = true;
  private isMount = false;

  //region REACT LIFECYCLE METHODS
  public constructor(props: IProps) {
    super(props);
    this.state = {
      actions: [],
      reporting: [],
      bc: [],
      pi: [],
      pp: [],
      loading: true,
    };
  }

  public componentDidMount() {
    this.isMount = true;
    TimelineStore.addListener(ActionTypes.TIMELINE_GET.toString(), this.getElements);
  }

  public componentWillUnmount() {
    this.isMount = false;
    TimelineStore.removeListener(ActionTypes.TIMELINE_GET.toString(), this.getElements);
  }
  //endregion

  //region LISTENER RELATED METHODS
  private getElements = () => {
    if (this.isMount) {
      this.setState({
        actions: TimelineStore.getActions(),
        bc: TimelineStore.getBc(),
        pi: TimelineStore.getPi(),
        reporting: TimelineStore.getReporting(),
        pp: TimelineStore.getPP(),
        loading: false,
      },            () => { this.initializeFilters(); });
    }
  };
  //endregion

  //region TIMELINE MAIN METHODS
  private initializeFilters = (): void => {

    const filters: FilterObject[] = [];

    this.state.pp && this.state.pp.forEach((myElement) => {
      filters.push(
        {
          type: TypeActivity.PERFORMANCE_PLAN,
          id: myElement.id,
          code: myElement.id,
          name: myElement.name,
          parentId: myElement.id,
        });
    });
    this.state.bc && this.state.bc.forEach((myElement) => {
      filters.push(
        {
          type: TypeActivity.BUSINESS_CHALLENGE,
          id: myElement.id,
          code: myElement.code,
          name: this.getFormattedActivityCode(myElement),
          parentName: myElement.performancePlan.name,
          parentId: myElement.performancePlan.id,
        });
    });
    this.state.pi && this.state.pi.forEach((myElement) => {
      filters.push(
        {
          type: TypeActivity.PERFORMANCE_INITIATIVE,
          id: myElement.id,
          code: myElement.code,
          name: this.getFormattedActivityCode(myElement),
          parentName: `${myElement.businessChallenge.performancePlan.name}
          - ${this.getFormattedActivityCode(myElement.businessChallenge)}`,
          parentId: myElement.businessChallenge.performancePlan.id,
        });
    });

    filters.sort((a, b) => (a.type > b.type) ? 1 : -1);
    filters.sort((a, b) => (a.type === b.type && a.code > b.code) ? 1 : -1);

    this.props.setFilters(filters);
  };

  private getTimelineViewForYear = () => {
    const nbDays = (Utils.isLeapYear(this.props.year)) ? 366 : 365;
    this.zones.push({ nbDays: 31, idMonth: Months.JANUARY, percentageSize: (31 / nbDays) * 100 });
    this.zones.push(
      { nbDays: (Utils.isLeapYear(this.props.year)) ? 29 : 28,
        idMonth: Months.FEBRUARY,
        percentageSize: ((Utils.isLeapYear(this.props.year)) ? 29 : 28 / nbDays) * 100,
      });
    this.zones.push({ nbDays: 31, idMonth: Months.MARCH, percentageSize: (31 / nbDays) * 100 });
    this.zones.push({ nbDays: 30, idMonth: Months.APRIL, percentageSize: (30 / nbDays) * 100 });
    this.zones.push({ nbDays: 31, idMonth: Months.MAY, percentageSize: (31 / nbDays) * 100 });
    this.zones.push({ nbDays: 30, idMonth: Months.JUNE, percentageSize: (30 / nbDays) * 100 });
    this.zones.push({ nbDays: 31, idMonth: Months.JULY, percentageSize: (31 / nbDays) * 100 });
    this.zones.push({ nbDays: 31, idMonth: Months.AUGUST, percentageSize: (31 / nbDays) * 100 });
    this.zones.push({ nbDays: 30, idMonth: Months.SEPTEMBER, percentageSize: (30 / nbDays) * 100 });
    this.zones.push({ nbDays: 31, idMonth: Months.OCTOBER, percentageSize: (31 / nbDays) * 100 });
    this.zones.push({ nbDays: 30, idMonth: Months.NOVEMBER, percentageSize: (30 / nbDays) * 100 });
    this.zones.push({ nbDays: 31, idMonth: Months.DECEMBER, percentageSize: (31 / nbDays) * 100 });
  };

  private getTimelineViewForQuarter = () => {
    let nbDays = 0;
    switch (this.props.quarter) {
      case Quarter.Q1:
        nbDays = (Utils.isLeapYear(this.props.year)) ? 31 + 29 + 31 : 31 + 28 + 31;
        this.zones.push({ nbDays: 31, idMonth: Months.JANUARY, percentageSize: (31 / nbDays) * 100 });
        this.zones.push(
          {
            nbDays: (Utils.isLeapYear(this.props.year))
                  ? 29 : 28, idMonth: Months.FEBRUARY, percentageSize: ((Utils.isLeapYear(this.props.year))
                  ? 29 : 28 / nbDays) * 100,
          });
        this.zones.push({ nbDays: 31, idMonth: Months.MARCH, percentageSize: (31 / nbDays) * 100 });
        break;
      case Quarter.Q2:
        nbDays = 30 + 31 + 30;
        this.zones.push({ nbDays: 30, idMonth: Months.APRIL, percentageSize: (30 / nbDays) * 100 });
        this.zones.push({ nbDays: 31, idMonth: Months.MAY, percentageSize: (31 / nbDays) * 100 });
        this.zones.push({ nbDays: 30, idMonth: Months.JUNE, percentageSize: (30 / nbDays) * 100 });
        break;
      case Quarter.Q3:
        nbDays = 31 + 31 + 30;
        this.zones.push({ nbDays: 31, idMonth: Months.JULY, percentageSize: (31 / nbDays) * 100 });
        this.zones.push({ nbDays: 31, idMonth: Months.AUGUST, percentageSize: (31 / nbDays) * 100 });
        this.zones.push({ nbDays: 30, idMonth: Months.SEPTEMBER, percentageSize: (30 / nbDays) * 100 });
        break;
      case Quarter.Q4:
        nbDays = 31 + 30 + 31;
        this.zones.push({ nbDays: 31, idMonth: Months.OCTOBER, percentageSize: (31 / nbDays) * 100 });
        this.zones.push({ nbDays: 30, idMonth: Months.NOVEMBER, percentageSize: (30 / nbDays) * 100 });
        this.zones.push({ nbDays: 31, idMonth: Months.DECEMBER, percentageSize: (31 / nbDays) * 100 });
        break;
    }
  };

  private getTimelineViewForMonth = () => {
    let nbDays = 0;
    if (this.props.month >= 0 && this.props.month < 12) {
      switch (this.props.month) {
        case Months.FEBRUARY:
          nbDays = ((Utils.isLeapYear(this.props.year)) ? 29 : 28);
          break;
        case Months.JANUARY:
        case Months.MARCH:
        case Months.MAY:
        case Months.JULY:
        case Months.AUGUST:
        case Months.OCTOBER:
        case Months.DECEMBER:
          nbDays = 31;
          break;
        default:
          nbDays = 30;
          break;
      }
      for (let i = 0; i < nbDays; i = i + 1) {
        this.zones.push({
          nbDays: 1,
          idMonth: this.props.month,
          percentageSize: 1 / nbDays * 100,
          jour: i + 1,
        });
      }
    }
  };

  private initializeTimelineView = (): void => {

    this.zones = [];
    this.elementsTimeline = [];
    this.isFirstReporting = true;

    switch (this.props.timeSelect) {
      case TimelineView.YEAR:
        this.getTimelineViewForYear();
        break;
      case TimelineView.QUARTER:
        this.getTimelineViewForQuarter();
        break;
      case TimelineView.MONTH:
        this.getTimelineViewForMonth();
        break;
    }
  };

  private displayTodayIcon = (myZone: ZoneTimeline, elementsOnTimeline: JSX.Element[]) => {
    const dateToday = new Date();
    if (dateToday.getMonth() === myZone.idMonth && dateToday.getFullYear() === this.props.year) {
      if (myZone.jour === undefined || myZone.jour === dateToday.getDate()) {
        const todayStyle = {
          left: `${((myZone.jour === undefined) ? (new Date()).getDate() / myZone.nbDays : 0.5) * 100}%`,
        };
        elementsOnTimeline.push(<div key="today" className="today" style={todayStyle} />);
      }
    }
  };

  private addAllElementsOnTimeline = (myZone: ZoneTimeline, elementsOnTimeline: JSX.Element[]) => {

    // Add Reporting Objects For PP & BC
    this.addReportingObjects(elementsOnTimeline, myZone);
    // Add Reporting Objects For PI
    this.addReportingObjects(elementsOnTimeline, myZone, TypeElementTimeline.REPORTING_BEFORE);

    this.state.bc.forEach((bc) => {
      this.addMilestones(bc, elementsOnTimeline, myZone);
    });
    this.state.pi.forEach((pi) => {
      this.addMilestones(pi, elementsOnTimeline, myZone);
    });

    this.addActions(elementsOnTimeline, myZone);
  };

  private getZones = (): JSX.Element[] => {

    this.initializeTimelineView();

    const zonesOnTimeline: JSX.Element[] = [];

    this.zones.forEach((myZone: ZoneTimeline, index: number) => {

      const elementsOnTimeline: JSX.Element[] = [];

      this.displayTodayIcon(myZone, elementsOnTimeline);

      // add elements on timeline according to filters
      if (!this.props.filterSelected) {
        this.addAllElementsOnTimeline(myZone, elementsOnTimeline);
      } else {
        if (Utils.isActivityBc(this.props.filterSelected.type)
            || Utils.isActivityPp(this.props.filterSelected.type)) {
          this.addReportingObjects(elementsOnTimeline, myZone, undefined,
                                   (this.props.filterSelected as FilterObject).parentId);
        }
        switch (this.props.filterSelected.type) {
          case TypeActivity.ACTION:
            this.addActions(elementsOnTimeline, myZone);
            break;
          case TypeActivity.PERFORMANCE_INITIATIVE:
            this.addReportingObjects(elementsOnTimeline, myZone, TypeElementTimeline.REPORTING_BEFORE,
                                     (this.props.filterSelected as FilterObject).parentId);
            const currentPi = this.state.pi.filter((pi) => {
              return pi.id === (this.props.filterSelected as FilterObject).id
                  && pi.businessChallenge.performancePlan.id === (this.props.filterSelected as FilterObject).parentId;
            });
            this.addMilestones(currentPi[0], elementsOnTimeline, myZone);
            break;
          case TypeActivity.BUSINESS_CHALLENGE: {
            const currentBc = this.state.bc.filter((bc) => {
              return bc.id === (this.props.filterSelected as FilterObject).id
                  && bc.performancePlan.id === (this.props.filterSelected as FilterObject).parentId;
            });
            this.addMilestones(currentBc[0], elementsOnTimeline, myZone);
            break;
          }
        }
      }

      const isMonday: boolean = (myZone.jour !== undefined
          && (new Date(this.props.year, this.props.month, myZone.jour)).getDay() === 1);

      zonesOnTimeline.push(
          <div
              key={`${myZone.idMonth}_${index}_ZONE_`}
              className={`${myZone.nbDays === 1 ? 'zone-no-hover' : 'zone'} ${(isMonday) ? 'monday' : 'notMonday'}`}
              style={{ maxWidth: `${myZone.percentageSize}%` }}
              onClick={() => {
                myZone.nbDays !== 1 && this.props.setMonth(myZone.idMonth);
              }}
          >
            <div key={`${myZone.idMonth}_${index}_ZONE_CHILD`}>
              {(myZone.jour !== undefined) ? myZone.jour : TimelineUtils.getTitleOfMonth(myZone.idMonth)}
            </div>
            {elementsOnTimeline}
          </ div>);
    });

    return zonesOnTimeline;
  };

  private getLegend = (): JSX.Element[] => {
    const elements: JSX.Element[] = [];

    if (this.state.reporting.length !== 0) {
      elements.push(
      <span key="reportingLegend">
        <div id="reportingsBadge" className="badge"/>
        <FormattedMessage id="timeline.legend.rep" defaultMessage="Reportings"/>
      </span>);
    }
    if (this.state.bc.length !== 0 || this.state.pi.length !== 0) {
      elements.push(
      <span key="milestoneLegend">
        <div id="milestonesBadge" className="badge" />
        <FormattedMessage id="timeline.legend.milestones" defaultMessage="Milestones"/>
      </span>);
    }
    if (this.state.actions.length !== 0) {
      elements.push(
      <span key="actionLegend">
        <div id="actionsBadge" className="badge" />
        <FormattedMessage id="timeline.legend.actions" defaultMessage="Actions"/>
      </span>);
    }

    return elements;
  };
  //endregion

  //region ADD ELEMENTS ON TIMELINE METHODS
  private isActivityOfTypeBC = (activity: BcWithMilestones | PiWithMilestones | Bc | Pi) => {
    return activity.hasOwnProperty('performancePlan');
  }
  private getFormattedActivityCode = (activity: BcWithMilestones | PiWithMilestones | Bc | Pi) => {
    const isActivityOfTypeBC = this.isActivityOfTypeBC(activity);
    const prefixStr = isActivityOfTypeBC ? 'BC' : 'PI';
    return `${prefixStr}${Utils.leadingZero(activity.code)}`;
  }

  private addMilestones = (activity: BcWithMilestones | PiWithMilestones,
                           target: JSX.Element[], myZone: ZoneTimeline): void => {
    const isActivityOfTypeBC = this.isActivityOfTypeBC(activity);
    const keyStr = isActivityOfTypeBC ? 'bcMilestone' : 'piMilestone';

    const bcMilestoneActivity = activity as BcWithMilestones;
    const piMilestoneActivity = activity as PiWithMilestones;

    activity.milestones.forEach((milestone, index: number) => {
      const dateIni = new Date(milestone.targetDate);
      const milestoneTimelineObj: TimelineObject = {
        elementType: TypeElementTimeline.MILESTONE,
        name: '',
        desc: milestone.name,
        date: dateIni,
        activityType: isActivityOfTypeBC ? TypeActivity.BUSINESS_CHALLENGE : TypeActivity.PERFORMANCE_INITIATIVE,
        activityId: activity.id,
        ppId: isActivityOfTypeBC
            ? bcMilestoneActivity.performancePlan.id
            : piMilestoneActivity.businessChallenge.performancePlan.id,
      };

      if (isActivityOfTypeBC) {
        milestoneTimelineObj.name = `${bcMilestoneActivity.performancePlan.name}
        ${this.getFormattedActivityCode(activity)}`;
      } else {
        milestoneTimelineObj.name = `${piMilestoneActivity.businessChallenge.performancePlan.name}
        ${this.getFormattedActivityCode(piMilestoneActivity.businessChallenge)}
        ${this.getFormattedActivityCode(activity)}`;
      }

      if (!this.elementsTimeline.find((myObj) => {
        return myObj.elementType === milestoneTimelineObj.elementType &&
            myObj.name === milestoneTimelineObj.name &&
            myObj.desc === milestoneTimelineObj.desc && myObj.date.getTime() === milestoneTimelineObj.date.getTime();
      })) {
        this.elementsTimeline.push(milestoneTimelineObj);
      }

      if (dateIni.getMonth() === myZone.idMonth && dateIni.getFullYear() === this.props.year
        && !bcMilestoneActivity.isClosed && !piMilestoneActivity.isClosed) {
        if (myZone.jour === undefined || myZone.jour === dateIni.getDate()) {
          target.push(
              <ElementTimeline
                  key={`${milestone.id}${keyStr}${index}`}
                  date={dateIni}
                  typeElement={TypeElementTimeline.MILESTONE}
                  title={`${milestoneTimelineObj.name}: ${milestone.name}`}
                  percentage={((myZone.jour === undefined) ? dateIni.getDate() / myZone.nbDays : 0.5) * 100}
              />,
          );
        }
      }
    });
  };

  private addReportingObjects = (elementsOnTimeline: JSX.Element[], myZone: ZoneTimeline,
                                 elementType = TypeElementTimeline.REPORTING, specificPpId?: number) => {
    let reports: RepObjectOnTimeline[];
    if (specificPpId) {
      reports = this.state.reporting.filter((repObj) => {
        return repObj.performancePlanId === specificPpId && repObj.type === elementType;
      });
    } else {
      reports = this.state.reporting.filter((repObj) => {
        return repObj.type === elementType;
      });
    }

    reports.forEach((report, index: number) => {
      const dateIni = new Date(report.dueDate);
      let keyStr = 'reporting';
      if (elementType === TypeElementTimeline.REPORTING_BEFORE) {
        dateIni.setDate(dateIni.getDate() - 7);
        keyStr = 'beforeReporting';
      }
      const eTimeline: TimelineObject = {
        elementType: report.type,
        name: report.name,
        desc: 'Reporting',
        date: dateIni,
        ppId: report.performancePlanId,
        activityId: report.activityId,
        activityType: report.activityType,
      };
      if (!this.elementsTimeline.find((timelineObj) => {
        return timelineObj.elementType === eTimeline.elementType &&
            timelineObj.desc === eTimeline.desc &&
            timelineObj.name === eTimeline.name && timelineObj.date.getTime() === eTimeline.date.getTime();
      })) {
        this.elementsTimeline.push(eTimeline);
      }
      if (dateIni.getMonth() === myZone.idMonth && dateIni.getFullYear() === this.props.year) {
        if (myZone.jour === undefined || myZone.jour === dateIni.getDate()) {
          const showInfo = elementType === TypeElementTimeline.REPORTING
              ? this.isFirstReporting && dateIni.getTime() >= (new Date().getTime())
              : undefined;
          elementsOnTimeline.push(
              <ElementTimeline
                  key={`${report.id}${keyStr}${index}`}
                  date={dateIni}
                  showInfo={showInfo}
                  typeElement={elementType}
                  title={report.name}
                  percentage={((myZone.jour === undefined) ? dateIni.getDate() / myZone.nbDays : 0.5) * 100}
              />,
          );
          if (elementType === TypeElementTimeline.REPORTING &&
              this.isFirstReporting && dateIni.getTime() >= (new Date().getTime())) {
            this.isFirstReporting = false;
          }
        }
      }
    });
  }

  private addActions = (target: JSX.Element[], myZone: ZoneTimeline): void => {
    this.state.actions.forEach((ac) => {
      const dateIni = new Date(ac.targetDate);
      const actionTitle = `${ac.performanceInitiative.businessChallenge.performancePlan.name}
        ${this.getFormattedActivityCode(ac.performanceInitiative.businessChallenge)}
        ${this.getFormattedActivityCode(ac.performanceInitiative)}
         A${Utils.leadingZero(ac.code)}`;
      const eTimeline: TimelineObject = {
        elementType: TypeElementTimeline.ACTION,
        name: actionTitle,
        desc: ac.name,
        date: dateIni,
        ppId: ac.performanceInitiative.businessChallenge.performancePlan.id,
        activityId: ac.performanceInitiative.id,
        activityType: TypeActivity.PERFORMANCE_INITIATIVE,
      };
      if (!this.elementsTimeline.find((myObj) => {
        return myObj.elementType === eTimeline.elementType &&
            myObj.desc === eTimeline.desc &&
            myObj.name === eTimeline.name && myObj.date.getTime() === eTimeline.date.getTime();
      })) {
        this.elementsTimeline.push(eTimeline);
      }
      if (dateIni.getMonth() === myZone.idMonth && dateIni.getFullYear() === this.props.year) {
        if (myZone.jour === undefined || myZone.jour === dateIni.getDate()) {
          target.push(
              <ElementTimeline
                  key={`${ac.id}action${ac.code}`}
                  date={dateIni}
                  typeElement={TypeElementTimeline.ACTION}
                  title={`${actionTitle}: ${ac.name}`}
                  percentage={((myZone.jour === undefined) ? dateIni.getDate() / myZone.nbDays : 0.5) * 100}
              />,
          );
        }
      }
    });
  };
  //endregion

  //region TIMELINE FOOTER METHODS
  private readonly sortTimeline = (obj1: TimelineObject, obj2: TimelineObject) =>
    (obj1.date.getTime() > obj2.date.getTime())
      ? 1
      : (obj1.date.getTime() < obj2.date.getTime())
        ? -1
        : 0;

  private getElementsUpcoming = (): JSX.Element[] => {
    const elements: JSX.Element[] = [];
    const sortArrayTimeline = [...this.elementsTimeline];

    sortArrayTimeline.sort(this.sortTimeline);
    sortArrayTimeline.forEach((eTimeline) => {
      if ((new Date(eTimeline.date)).getTime() >= (new Date()).getTime()) {
        elements.push(<ElementUpcoming key={`element-upcoming-${Math.random()}`} element={eTimeline} />);
      }
    });

    return elements;
  };

  private getElementsUpcomingSelected = (): JSX.Element[] => {
    const elements: JSX.Element[] = [];
    const sortArrayTimeline = [...this.elementsTimeline];

    sortArrayTimeline.sort(this.sortTimeline);
    sortArrayTimeline.forEach((eTimeline) => {
      const dateElement = new Date(eTimeline.date);
      if (dateElement.getMonth() === this.props.month && dateElement.getFullYear() === this.props.year) {
        elements.push(<ElementUpcoming key={`element-upcoming-${Math.random()}`} element={eTimeline} />);
      }
    });

    return elements;
  };

  private getTitleUpcomingSelected = (): JSX.Element => {
    return TimelineUtils.getTitleOfMonth(this.props.month);
  };
  //endregion

  public render() {
    if (this.state.loading) {
      return (
          <div>
            <div id="timeline-component"/>
            <div id="upcoming">
              <div id="all">
                {Utils.loader()}
              </div>
            </div>
          </div>
      );
    }
    return (
      <div>
        <div id="timeline-component">
          <div id="line-timeline">
            {this.getZones()}
          </div>
          <div id="legend">
            <div>
              {this.getLegend()}
            </div>
          </div>
        </div>
        <div id="upcoming">
          <div id="all">
            <div className="title">
              <FormattedMessage id="upcoming" defaultMessage="Upcoming" /> :
            </div>
            <div className="scrollable-container content-upcoming">
              <CustomScrollBars>
                {this.getElementsUpcoming()}
              </CustomScrollBars>
            </div>
          </div>
          <div id="detail">
            <div className="title">
              {this.getTitleUpcomingSelected()} {this.props.year} :
            </div>
            <div className="scrollable-container content-upcoming">
              <CustomScrollBars>
                {this.getElementsUpcomingSelected()}
              </CustomScrollBars>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Timeline;
