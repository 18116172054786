import React from 'react';

import Action, { ActionEdit, ActionPost } from '../../../../../../../../models/action';
import TypeActivity from '../../../../../../../../constants/typeActivity';
import Utils from '../../../../../../../../utils/utils';
import Milestone from '../../../../../../../../models/milestone';
import ActionTile from '../../../../../contentPI/performanceMeasures/actionTile/actionTile';
import ActionStore from '../../../../../../../../stores/action.store';
import ActionTypes from '../../../../../../../../constants/actionTypes';
import ActionActions from '../../../../../../../../actions/actions.action';
import NotificationActions from '../../../../../../../../actions/notification-actions';
import { FormattedMessage } from 'react-intl';
import { ToastType } from '../../../../../../../common/toast/toast';
import ActionUtils from '../../../../../../../../utils/action.utils';
import PerformanceInitiativeActions from '../../../../../../../../actions/performanceInitiative.action';
import NotificationUtils from '../../../../../../../../utils/notification.utils';
import MilestoneTile from '../../../../milestoneTile/milestoneTile';
import { CommonDateField } from '../../../../../../../../constants/fields';
import PerformanceInitiativeAPI from '../../../../../../../../api/performanceInitiative.api';
import MilestoneStore from '../../../../../../../../stores/milestone.store';
import MilestoneAction from '../../../../../../../../actions/milestone.action';

interface IProps {
  activity: Action | Milestone;
  activityType: TypeActivity;
  canEditPi: boolean;
  contentPanelHeight: number;
  piId: number;

  resetPanelActivity(activityIdToScrollTo?: number): void;
}

interface IStates {
  isCodeValid: boolean;
  actions: ActionEdit[];
  milestones: Milestone[];
  editedActivity: ActionEdit | Milestone | null;
  isLoading: boolean;
}

class ActionMilestone extends React.Component<IProps, IStates> {

  private isMount = false;

  constructor(props) {
    super(props);
    this.state = {
      isCodeValid: true,
      actions: [],
      milestones: [],
      editedActivity: null,
      isLoading: false,
    };
  }

  public componentDidMount() {
    this.isMount = true;
    ActionStore.addListener(ActionTypes.ACTION_SAVED.toString(), this.getActions);
    ActionStore.addListener(ActionTypes.ACTION_DELETED.toString(), this.getActions);
    MilestoneStore.addListener(ActionTypes.GET_MILESTONES.toString(), this.getMilestones);
    if (this.props.activityType === TypeActivity.ACTION) {
      this.getActions(true);
    }
    this.getMilestones(true);
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>, snapshot?: any) {
    if (prevProps.activity.id === null && this.props.activity.id !== null) {
      this.setState({ isLoading: false });
    }
  }

  public componentWillUnmount() {
    this.isMount = false;
    ActionStore.removeListener(ActionTypes.ACTION_SAVED.toString(), this.getActions);
    ActionStore.removeListener(ActionTypes.ACTION_DELETED.toString(), this.getActions);
    MilestoneStore.removeListener(ActionTypes.GET_MILESTONES.toString(), this.getMilestones);
  }

  //region ACTIONS METHODS
  private getActions = (preventParentRefresh = false) => {
    if (this.isMount) {
      const actions = ActionStore.getActions() as ActionEdit[];
      actions.forEach((at: ActionEdit) => {
        at.isStatusEditing = false;
      });
      this.setState({ actions }, () => {
        if (!preventParentRefresh) {
          setTimeout(() => {
            PerformanceInitiativeActions.emitChangePerformanceInitiative('actions', this.state.actions);
          },         0);
        }
      });
    }
  };

  public isCodeValid = (atId: number | null, code: number): boolean => {
    return ActionUtils.isCodeValid(this.state.actions, atId, code);
  };

  private updateAction = (data: ActionEdit) => {
    const action = ActionUtils.parseActionDate(data);

    // save in creation
    if (action.id === null) {
      const newAction = { ...data as ActionPost };
      ActionActions.createAction(this.props.piId, newAction).then((at) => {
        this.props.resetPanelActivity(at.id);
        ActionActions.getActions(this.props.piId);
        ActionActions.onActionSaved();
        NotificationUtils.sendActivityCreatedToast(TypeActivity.ACTION);
        const actions = [...this.state.actions];
        actions.push(at as ActionEdit);
        actions.sort((a, b) => a.code - b.code);
        this.setState({ actions, editedActivity: null, isLoading: true });
      });
    } else {
      // save in edition
      ActionActions.editAction(
        this.state.actions,
        action,
        this.props.piId,
        this.state.actions.find(ac => ac.id === action.id),
      )
        .then(async () => {
          NotificationUtils.sendActivityUpdatedToast(TypeActivity.ACTION);
          const actions = [...this.state.actions];
          const acIndex = actions.findIndex(at => at.id === action.id);
          if (acIndex > -1) {
            actions[acIndex] = action;
            this.setState({ actions });
          }
          ActionActions.getActions(this.props.piId);
          ActionActions.onActionSaved();
        })
        .catch((err) => {
          if (err === 'duplicated codes') NotificationUtils.sendErrorDuplicateCodesToast(TypeActivity.ACTION);
          else Utils.toastError();
        });
    }
  };

  private toggleCloseAction = (action: ActionEdit) => {
    const actionToToggle = { ...action };
    actionToToggle.isClosed = !actionToToggle.isClosed;
    this.updateAction(actionToToggle);
    this.saveActivityInState(actionToToggle);
  };

  private removeAction = (atId: number) => {
    const code = this.props.activity.code;
    ActionActions.removeAction(atId)
      .then(() => {
        ActionActions.getActions(this.props.piId);
        this.props.resetPanelActivity();
      })
      .finally(() => {
        NotificationActions.toast(
          <FormattedMessage id="actionDeleted" defaultMessage="Deleted!"/>,
          (
            <FormattedMessage
              id="actionDeleted"
              defaultMessage="The action {actionCode} was successfully deleted"
              values={{
                actionCode: Utils.formatActivityCode(code, TypeActivity.ACTION),
              }}
            />
          ),
          ToastType.SUCCESS,
        );
      });
  };
  //endregion

  //region COMMON METHODS
  private saveActivityInState = (activity: ActionEdit | Milestone) => {
    this.setState({ editedActivity: activity });
  };

  private cancelActivity = () => {
    if (!this.props.activity.id) {
      this.props.resetPanelActivity();
    } else {
      this.setState({ editedActivity: null });
    }
  };
  //endregion

  //region MILESTONES METHODS
  private getMilestones = (preventParentRefresh = false) => {
    if (this.isMount) {
      const milestones = MilestoneStore.getMilestones() as Milestone[];
      this.setState({ milestones }, () => {
        if (!preventParentRefresh) {
          setTimeout(() => {
            PerformanceInitiativeActions.emitChangePerformanceInitiative('milestones', this.state.milestones);
          },         0);
        }
      });
    }
  };

  private updateMilestone = async (data: Milestone) => {
    this.setState({ isLoading: true }, () => {
      let milestone = data;
      if (milestone.targetDate) {
        milestone = Utils.parseDateStringToDate(milestone, CommonDateField.TARGET_DATE);
      }
      if (milestone.completionDate) {
        milestone = Utils.parseDateStringToDate(milestone, CommonDateField.COMPLETION_DATE);
      }

      if (milestone.id === null) {
        const allMilestones =  this.state.milestones && this.state.milestones.length > 0
          ? [...this.state.milestones] : [];
        milestone.code = Utils.findActivityNextCode(allMilestones, false);

        PerformanceInitiativeAPI.createMilestones(this.props.piId, [milestone])
          .then((result) => {
            if (result && result.milestoneId) {
              this.props.resetPanelActivity(result.milestoneId);
              milestone.id = result.milestoneId;
              const newMilestoneArray = [...allMilestones, milestone].sort((a, b) => a.code - b.code);
              this.setState({ milestones: newMilestoneArray, editedActivity: null }, () => {
                NotificationUtils.sendActivityCreatedToast(TypeActivity.MILESTONE);
                MilestoneAction.setMilestones(this.state.milestones);
              });
            } else {
              Utils.toastError();
              this.setState({ isLoading: false });
            }
          }).catch(() => Utils.toastError());
      } else {
        PerformanceInitiativeAPI.putPerformanceInitiativeMilestones(this.props.piId, [milestone])
          .then(() => {
            NotificationUtils.sendActivityUpdatedToast(TypeActivity.MILESTONE);
            const milestones = [...this.state.milestones];
            const miIndex = milestones.findIndex(mi => mi.id === milestone.id);
            if (miIndex > -1) {
              milestones[miIndex] = milestone;
              this.setState({ milestones, isLoading: false });
            }
            MilestoneAction.setMilestones(milestones);
          })
          .catch(() => {
            Utils.toastError();
            this.setState({ isLoading: false });
          });
      }
    });
  }

  private removeMilestone = (milestone: Milestone) => {
    const code = this.props.activity.code;
    PerformanceInitiativeAPI.deleteMilestones(this.props.piId, [this.props.activity.id!])
      .then(() => {
        MilestoneAction.getPiMilestones(this.props.piId);
        this.props.resetPanelActivity();
      })
      .finally(() => {
        NotificationUtils.sendActivityDeletedToast(TypeActivity.MILESTONE, code);
      });
  };

  // this function is caleld when an actin completion date impacting a milestone is changed
  public saveDateUpdate = (data:Milestone) => {
    let milestone = data;
    if (milestone.targetDate) {
      milestone = Utils.parseDateStringToDate(milestone, CommonDateField.TARGET_DATE);
    }
    if (milestone.completionDate) {
      milestone = Utils.parseDateStringToDate(milestone, CommonDateField.COMPLETION_DATE);
    }
    let request;
    request = PerformanceInitiativeAPI.putPerformanceInitiativeMilestones(this.props.piId, [milestone]);
    request.then(() => {
      MilestoneAction.setMilestones(this.state.milestones);
    });
  }

  //endregion

  render() {
    if (Utils.isActivityAction(this.props.activityType)) {
      let action : Action = this.props.activity as Action;
      if (this.state.editedActivity !== null && this.state.editedActivity!.id === this.props.activity.id) {
        action = this.state.editedActivity as Action;
      }
      return (
        <div>
          {this.state.isLoading && Utils.loader()}
          <ActionTile
            action={action as ActionEdit}
            activeAction={0}
            canEditPi={this.props.canEditPi}
            isForPanelDisplay={true}
            saveAction={this.saveActivityInState}
            updateAction={this.updateAction}
            cancelAction={this.cancelActivity}
            toggleCloseAction={this.toggleCloseAction}
            isCodeValid={this.isCodeValid}
            removeAction={this.removeAction}
            contentPanelHeight={this.props.contentPanelHeight}
            milestones={this.state.milestones}
          />
        </div>
      );
    }
    let milestone : Milestone = this.props.activity as Milestone;
    if (this.state.editedActivity !== null && this.state.editedActivity!.id === this.props.activity.id) {
      milestone = this.state.editedActivity as Milestone;
    }
    return (
      <div>
        {this.state.isLoading && Utils.loader()}
        <MilestoneTile
          milestone={milestone}
          isAlternateDisplayForPanel={true}
          activityType={TypeActivity.PERFORMANCE_INITIATIVE}
          saveMilestonesInState={this.saveActivityInState}
          onEdit={this.updateMilestone}
          onCancel={this.cancelActivity}
          onDelete={this.removeMilestone}
          activityId={this.props.activity.id!}
          saveDateUpdate={this.saveDateUpdate}
        />
      </div>
    );
  }
}

export default ActionMilestone;
