export enum VIEWS {
  COCKPIT_VIEW_ON_EDIT = 'cockpitOnEdit',
  ACTION_LIST_VIEW_ON_EDIT = 'actionListOnEdit',
}

// POSSIBLE STATUS VALUE FOR A GUIDE
export enum GUIDE_STATUS {
  PENDING = 'pending',
  READY = 'ready',
  INITIATED = 'initiated',
  RUNNING = 'running',
  SKIPPED = 'skipped',
  FINISHED = 'finished',
}

// TYPE OF LOCK FOR ADVANCED GUIDE
export enum GUIDE_LOCK_TYPE {
  // Default lock (no lock)
  NONE = 0,

  // Soft lock: Hide 'next' button so user has to do an action to automatically go to next step.
  // The action HAS to be a click on the target, WITHOUT any condition.
  // Moreover, the next step need to be immediately accessible.
  SOFT = 1,

  // Hard lock: Hide 'next' button so user has to do an action to automatically go to next step,
  // where he can't go back to previous. The action can be anything.
  HARD = 2,
}

export interface IGuideType {
  id: GuideIds; // id of the guide
  fileName: string; // name of the file containing the guide's steps
}

export enum PLACEMENT_TYPE {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}

export interface IGuideStep {
  target: string; // the id of the DOM element to target (#id)
  // the content of the dialog, it is not necessary when hide is at TRUE
  content?: string | JSX.Element | { id: string, defaultMessage: string };
  title?: string | { id: string, defaultMessage: string }; // the title of the dialog
  allowClick?: boolean;
  lock?: GUIDE_LOCK_TYPE; // the type of lock
  placement?: PLACEMENT_TYPE; // the placement of the dialog
  listContent?: boolean; // is the content containing a list (<ul>/<ol>)
  strictSpotlight?: boolean;
  hide?:boolean;
  steps?: JSX.Element[];
}

// GUIDES LIST
export enum GuideIds {
  NONE = '',
  MENTION_ACTIVITIES = 'mention-activities',
}

export const GUIDES: IGuideType[] = [
  {
    id: GuideIds.MENTION_ACTIVITIES,
    fileName: 'mention-activities',
  },
];
