import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import NotificationActions from '../../actions/notification-actions';
import { ToastType } from '../../components/common/toast/toast';
import { CommonErrors } from './common.error';

export enum BcErrors {
  BC_NOT_FOUND = 'Business Challenge not found',
  ALL_PIS_NEED_TO_BE_PUBLISHED = 'All PIs need to be published first',
  BC_ALREADY_AWAITING_FOR_VALIDATION = 'This Business Challenge is already awaiting for validation',
  BC_PUBLICATION_REQUEST_NOTIFICATION = 'Could not set BC publish request to done when rejecting',
  BC_PUBLICATION_REJECTION = 'An error occurred during BC publication rejection',
  BC_PUBLICATION_REJECTION_NOTIFICATION = 'An error occurred on BC publication rejection notification',
  BC_NOT_FOUND_PUBLICATION = 'Could not find Business Challenge to reject publication',
  BC_NEVER_BEEN_PUBLISHED = 'Business Challenge has never been published',
  BC_WRONG_HISTORY = 'This history does not belong to this Business Challenge',

  BC_CANNOT_LINK_TO_SAME_PP = 'Could not link a Business Challenge to a Plan it is already linked to',
  BC_CANNOT_LINK_TO_OWN_PP = 'Could not link a Business Challenge to its original Plan',
  BC_CANNOT_LINK_WHEN_DRAFT = 'Could not link a Business Challenge when its Plan is a draft',
  BC_CANNOT_LINK_TO_DRAFT = 'Could not link a Business Challenge to a draft Plan',
  BC_CANNOT_LINK_WITH_PIS_ALREADY_LINKED = 'Could not link a Business Challenge because one of its PIs is already linked into this Plan',
  BC_ALREADY_WAITING_LINK_VALIDATION = 'This Business Challenge link has already been requested',
  BC_CANNOT_LINK_DIFFERENT_YEAR = 'Could not link a Business Challenge when its Plan\'s year is different from the target Plan\'s year',

  BC_INVALID_TEXTAREA = 'Fields should respect chars/lines limit',
  PUBLICATION_CONDITIONS_NOT_MET = 'There is no need to publish, because it was already published today and has not been edited since',
}

export default class BusinessChallengeError {

  public static toastBcError(error: string) {
    let message: JSX.Element;
    let toastType = ToastType.ERROR;
    let title = <FormattedMessage id="errorOccurred" defaultMessage="Error"/>;

    switch (error) {
      case BcErrors.ALL_PIS_NEED_TO_BE_PUBLISHED:
        message = <FormattedMessage id="allPisNeedToBePublished" defaultMessage="All PIs need to be published first" />;
        break;
      case BcErrors.BC_ALREADY_AWAITING_FOR_VALIDATION:
        message = (
          <FormattedMessage
            id="bcAlreadyAwaitingForValidation"
            defaultMessage="This Business Challenge is already awaiting for validation"
          />
        );
        break;
      case BcErrors.BC_PUBLICATION_REQUEST_NOTIFICATION:
        message = (
          <FormattedMessage
            id="bcPublicationRequestNotification"
            defaultMessage="Could not set BC publish request to done when rejecting"
          />
        );
        break;
      case BcErrors.BC_PUBLICATION_REJECTION:
        message = (
          <FormattedMessage
            id="bcPublicationRejection"
            defaultMessage="An error occurred during BC publication rejection"
          />
        );
        break;
      case BcErrors.BC_PUBLICATION_REJECTION_NOTIFICATION:
        message = (
          <FormattedMessage
              id="bcPublicationRejectionNotification"
              defaultMessage="An error occurred on BC publication rejection notification"
          />
        );
        break;
      case BcErrors.BC_NOT_FOUND_PUBLICATION:
        message = (
          <FormattedMessage
            id="bcNotFound"
            defaultMessage="Could not find Business Challenge to reject publication"
          />
        );
        break;
      case BcErrors.BC_CANNOT_LINK_TO_SAME_PP:
        message = (
          <FormattedMessage
            id="link.bcLinkOnSamePlanError"
            defaultMessage="Could not link a Business Challenge to a Plan it is already linked to"
          />
        );
        break;
      case BcErrors.BC_CANNOT_LINK_TO_OWN_PP:
        message = (
          <FormattedMessage
            id="link.bcOriginalPlanError"
            defaultMessage="Could not link a Business Challenge to its original Plan"
          />
        );
        break;
      case BcErrors.BC_CANNOT_LINK_WHEN_DRAFT:
        message = (
          <FormattedMessage
            id="link.bcInDraftPlanError"
            defaultMessage="Could not link a Business Challenge when its Plan is a draft"
          />
        );
        break;
      case BcErrors.BC_CANNOT_LINK_TO_DRAFT:
        message = (
          <FormattedMessage
            id="link.bcToDraftPlanError"
            defaultMessage="Could not link a Business Challenge to a draft Plan"
          />
        );
        break;
      case BcErrors.BC_CANNOT_LINK_WITH_PIS_ALREADY_LINKED:
        message = (
          <FormattedMessage
            id="link.bcWithPisAlreadyLinked"
            defaultMessage="Could not link a Business Challenge because one of its PIs is already linked into this Plan"
          />
        );
        break;
      case BcErrors.BC_ALREADY_WAITING_LINK_VALIDATION:
        message = (
          <FormattedMessage
            id="link.bcAlreadyWaitingValidation"
            defaultMessage="This Business Challenge link has already been requested"
          />
        );
        break;
      case BcErrors.BC_CANNOT_LINK_DIFFERENT_YEAR:
        message = (
            <FormattedMessage
              id="link.bcDifferentPerformancePlanYear"
              defaultMessage="Could not link a Business Challenge when its Plan's year is different from the target Plan's year"
            />
          );
        break;
      case CommonErrors.PUBLICATION_CONDITIONS_NOT_MET:
        title = <FormattedMessage id="informationPublicationPlanError" defaultMessage="Info"/>;
        message = (
          <FormattedMessage
            id="notPublishedMessage"
            defaultMessage="The activity could not be published because it was already published today and has not been modified since (neither it nor the activities it contains)"
          />
        );
        toastType = ToastType.INFO;
        break;
      default:
        message = (
          <FormattedMessage
            id="errorOccurredMessage"
            defaultMessage="An error occurred, please try again later"
          />
        );
        break;
    }

    NotificationActions.toast(title, message, toastType);
  }
}
