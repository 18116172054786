import Keywords from './keywords';
import Metric from './metric';
import Milestone from './milestone';
import PerformanceInitiative, { PerformanceInitiativesCockpitFields, PiLinked } from './performanceInitiative';
import PerformancePlan, { PpLinked } from './performancePlan';
import Reporting from './reporting';
import Attachment from './attachment';
import User from './user';
import { RightsOnActivityDTO } from './rights';
import { KeyAction } from './action';

export default class BusinessChallenge {

  public id: number;
  public name: string;
  public objectives: string;
  public code: number;
  public status: number;
  public keywordsId: number;
  public keywords: Keywords;
  public functionsId: number;
  public functions: Keywords;
  public dueDate: Date;
  public metrics: Metric[];
  public attachments: Attachment[];
  public milestones: Milestone[];
  public performancePlan: PerformancePlan;
  public performancePlanId: number;
  public assignedAccounts: User[];
  public deputies: User[];
  public informedAccounts: User[];
  public reportings: Reporting[];
  public isBudgetHidden: boolean;
  public top5: boolean;
  public pillar: number;
  public achievements: string;
  public issuesAndRisks: string;
  public nextSteps: string;
  public budget: string;
  public decisionsToBeMade: string;
  public performanceInitiatives: PerformanceInitiative[];
  public linkedPis?: PerformanceInitiative[];
  public publishedDate: Date;
  public lastUpdate: Date;
  public lastModifier: User;
  public lastModifierId: number;
  public isPpDraft: boolean;
  public isAssignee: boolean;
  public isLinked: boolean;
  public targetPpId: number;
  public isPending?: boolean;
  public hasActions: boolean;
  public linkedBcLinkMetrics: Metric[];
  public isClosed: boolean;
  public closeComment: string;
  public closeDate: Date;
  public keyActions: KeyAction[];

  constructor(bc: BusinessChallengeGet) {
    this.id = bc.id;
    this.name = bc.name;
    this.objectives = bc.objectives;
    this.code = bc.code;
    this.status = bc.status;
    this.keywordsId = bc.keywordsId;
    this.keywords = bc.keywords;
    this.functionsId = bc.functionsId;
    this.functions = bc.functions;
    this.dueDate = bc.dueDate;
    this.metrics = bc.metrics;
    this.attachments = bc.attachments;
    this.milestones = bc.milestones;
    this.performancePlan = bc.performancePlan;
    this.performancePlanId = bc.performancePlanId;
    this.assignedAccounts = bc.assignedAccounts;
    this.deputies = bc.deputies;
    this.informedAccounts = bc.informedAccounts;
    this.reportings = bc.reportings;
    this.isBudgetHidden = bc.isBudgetHidden;
    this.top5 = bc.top5;
    this.pillar = bc.pillar;
    this.achievements = bc.achievements;
    this.issuesAndRisks = bc.issuesAndRisks;
    this.nextSteps = bc.nextSteps;
    this.budget = bc.budget;
    this.decisionsToBeMade = bc.decisionsToBeMade;
    this.performanceInitiatives = bc.performanceInitiatives;
    this.linkedPis = bc.linkedPis;
    this.publishedDate = bc.publishedDate;
    this.lastUpdate = bc.lastUpdate;
    this.lastModifier = bc.lastModifier;
    this.lastModifierId = bc.lastModifierId;
    this.isPpDraft = bc.isPpDraft;
    this.isLinked = bc.isLinked;
    this.targetPpId = bc.targetPpId;
    this.isPending = bc.isPending;
    this.hasActions = bc.hasActions;
    this.linkedBcLinkMetrics = bc.linkedBcLinkMetrics;
    this.isClosed = bc.isClosed;
    this.closeComment = bc.closeComment;
    this.closeDate = bc.closeDate;
    this.keyActions = bc.keyActions;
  }
}

export class BusinessChallengeGet {

  public id: number;
  public name: string;
  public objectives: string;
  public code: number;
  public status: number;
  public keywordsId: number;
  public keywords: Keywords;
  public functionsId: number;
  public functions: Keywords;
  public dueDate: Date;
  public metrics: Metric[];
  public attachments: Attachment[];
  public milestones: Milestone[];
  public performancePlan: PerformancePlan;
  public performancePlanId: number;
  public assignedAccounts: User[];
  public deputies: User[];
  public informedAccounts: User[];
  public isAwaitingValidation: boolean;
  public reportings: Reporting[];
  public isBudgetHidden: boolean;
  public top5: boolean;
  public pillar: number;
  public achievements: string;
  public issuesAndRisks: string;
  public nextSteps: string;
  public budget: string;
  public decisionsToBeMade: string;
  public performanceInitiatives: PerformanceInitiative[];
  public linkedPis?: PerformanceInitiative[];
  public isLinked: boolean;
  public publishedDate: Date;
  public lastUpdate: Date;
  public lastModifier: User;
  public lastModifierId: number;
  public rightsOnBC: RightsOnActivityDTO;
  public isPpDraft: boolean;
  public targetPpId: number;
  public isPending: boolean;
  public hasActions: boolean;
  public linkedBcLinkMetrics: Metric[];
  public isClosed: boolean;
  public closeComment: string;
  public closeDate: Date;
  public keyActions: KeyAction[];
}

export class BusinessChallengeDetails {
  public id: number;
  public name: string;
  public objectives: string;
  public successFactorsRisks: string;
  public code: number;
  public status: number;
  public performanceInitiatives: PerformanceInitiative[];
  public linkedPis?: PerformanceInitiative[];
  public isClosed: boolean;
  public budget: string;
  public isBudgetHidden: boolean;
}

export class BusinessChallengePost {

  public name: string;
  public objectives: string;
  public code: number;
  public status?: number;
  public dueDate?: Date;
  public metrics: Metric[];
  public attachments: Attachment[];
  public milestones: Milestone[];
  public performancePlanId?: number;
  public assignedAccounts?: User[];
  public deputies?: User[];
  public informedAccounts?: User[];
  public reportings?: Reporting[];
  public isBudgetHidden?: boolean;
  public top5: boolean;
  public keywordsId?: number;
  public functionsId?: number;
  public pillar?: number;
  public achievements?: string;
  public issuesAndRisks?: string;
  public nextSteps?: string;
  public budget: string;
  public decisionsToBeMade?: string;
  public performanceInitiatives?: PerformanceInitiative[];
}

export class BusinessChallengePut {

  public name?: string;
  public objectives?: string;
  public code?: number;
  public status?: number;
  public metrics?: any;
  public attachments: Attachment[];
  public milestones?: any;
  public performancePlanId?: number;
  public assignedAccounts?: User[];
  public deputies?: User[];
  public informedAccounts?: User[];
  public isBudgetHidden?: boolean;
  public top5?: boolean;
  public keywordsId?: number;
  public functionsId?: number;
  public pillar?: number;
  public achievements?: string;
  public issuesAndRisks?: string;
  public nextSteps?: string;
  public budget?: string;
  public decisionsToBeMade?: string;
  public performanceInitiatives?: PerformanceInitiative[];
  public isLinked?: boolean;
  public targetPpId?: number;
}

export class BcLinked {
  public id: number;
  public code: number;
  public name: string;
  public linkedPps?: PpLinked[];
  public pis?: PiLinked[];
}

export class BcLinkedWithSource {
  public id: number;
  public code: number;
  public name: string;
  public linkedPps: PpLinked[];
}

export class BusinessChallengePisFields {
  public id: number;
  public performanceInitiatives: PerformanceInitiativesCockpitFields[];
  public linkedPis: PerformanceInitiativesCockpitFields[];
}
