import 'react-datepicker/dist/react-datepicker.css';
import './newVersionModal.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'semantic-ui-react';
import Utils from '../../utils/utils';
import UserAPI from 'src/api/user.api';
import ReactMarkdown from 'react-markdown';

interface IProps {
  userId: number;
  onClose: () => void;
  open: boolean;
}

interface IStates {
  contentVersion: string;
}

class NewVersionModal extends React.Component<IProps, IStates> {

  public componentDidMount(): void {
    const content = require('./VERSION_CONTENT.md');

    fetch(content).then((res) => {
      return res.text();
    }).then((text) => {
      this.setState({ contentVersion: text });
    });
  }

  /**
   * Action to stop the modal from appearing at the next connexion
   */
  private stopVersionModal = () => {
    UserAPI.stopVersionModal(this.props.userId)
      .then(() => {
        this.props.onClose();
      })
      .catch(() => {
        Utils.toastError();
      });
  };

  public render() {
    return (
      <Modal
        id="new-version-modal-container"
        open={this.props.open}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={this.props.onClose}
      >
        <Modal.Content id="modal-content">
          <Modal.Header>
            <h1>
              <FormattedMessage
                id="new-version.title"
                defaultMessage="New release, new updates !"
              />
            </h1>
          </Modal.Header>
          <div id="details-container">
            {this.state && this.state.contentVersion
              ? <ReactMarkdown source={this.state.contentVersion} />
              : Utils.loader()
            }
          </div>
        </Modal.Content>
        <Modal.Actions id="actions">
          <span>
          <Button
              onClick={this.props.onClose}
            >
              <FormattedMessage
                id="continue-modal"
                defaultMessage="I want to read it next time"
              />
            </Button>
            <Button
              onClick={this.stopVersionModal}
              primary={true}
            >
              <FormattedMessage
                id="stop-modal"
                defaultMessage="Don't show again"
              />
            </Button>
          </span>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default NewVersionModal;
