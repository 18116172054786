import HttpClient from './utils/httpClient';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './index.css';

(async () => {

  if (process.env.NODE_ENV !== 'development') {
    try {
      await HttpClient.post('version', { version: process.env.REACT_APP_VERSION });
    } catch {
    }
  }

  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById('root') as HTMLElement,
  );
})();
