import './elementTimeline.scss';

import * as React from 'react';
import TypeElementTimeline from 'src/constants/timeline';
import Utils from 'src/utils/utils';

interface IProps {
  date: Date;
  typeElement: TypeElementTimeline;
  title: string;
  percentage: number;
  showInfo?: boolean;
}

interface IStates {
  showDetail: boolean;
  backgroundClass: string;
  colorClass: string;
}

class ElementTimeline extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      showDetail: false,
      backgroundClass: 'reportingBg',
      colorClass: 'reportingColor',
    };
  }
  public componentDidMount() {
    this.setColorClass();
  }

  private setColorClass = (): void => {
    switch (this.props.typeElement) {
      case TypeElementTimeline.REPORTING:
        this.setState({ backgroundClass: 'reportingBg', colorClass: 'reportingColor' });
        break;
      case TypeElementTimeline.REPORTING_BEFORE:
        this.setState({ backgroundClass: 'reportingBeforeBg', colorClass: 'reportingBeforeColor' });
        break;
      case TypeElementTimeline.MILESTONE:
        this.setState({ backgroundClass: 'milestoneBg', colorClass: 'milestoneColor' });
        break;
      case TypeElementTimeline.ACTION:
        this.setState({ backgroundClass: 'actionBg', colorClass: 'actionColor' });
        break;
      default:
        this.setState({ backgroundClass: 'reportingBg', colorClass: 'reportingColor' });
        break;
    }
  }

  public render() {

    let dottedStyle = {};

    switch (this.props.typeElement) {

      case TypeElementTimeline.MILESTONE:
        dottedStyle = { borderLeft: '2px dotted #118AB2' };
        break;
      case TypeElementTimeline.ACTION:
        dottedStyle = { borderLeft: '2px dotted #F54770' };
        break;
      default:
      case TypeElementTimeline.REPORTING:
        dottedStyle = { borderLeft: '2px dotted #06D6A0' };
        break;
    }

    return (
      <div
        id={'element'}
        className={(
          this.props.showInfo ||
          this.state.showDetail) ? (this.props.typeElement === TypeElementTimeline.REPORTING) ?
            'openToolTipReporting reporting' : 'openToolTip notReporting' : 'closeToolTip notReporting'}
        style={{ left: `${this.props.percentage}%` }}
      >
        <div id={'timeline-element'}>
          <div
            id={'info-element'}
            style={dottedStyle}
            className={
              this.props.showInfo ||
                this.state.showDetail ? 'opacity-on' : 'opacity-off'}
          >
            <div id={'title'} className={this.state.colorClass}>{this.props.title}</div>
            <div id={'date'}>
              {`${Utils.displayMonthDayDate(this.props.date)}`}
            </div>
            <div
              className={`${
                (this.props.typeElement === TypeElementTimeline.REPORTING) ? 'lienReporting' : 'lien'
                }`}
            />
          </div>
          <div />
          <div
            id={'base'}
            className={this.state.backgroundClass}
            onMouseEnter={() => { this.setState({ showDetail: true }); }}
            onMouseLeave={() => { this.setState({ showDetail: false }); }}
          />
        </div>
      </div>
    );
  }
}

export default ElementTimeline;
