import './toast.scss';

import * as React from 'react';
import { Icon } from 'semantic-ui-react';

import NotificationStore, { Events } from 'src/stores/notification.store';
import config from 'src/config';

export enum ToastType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  UNDEFINED = '',
}

interface IStates {
  showToast: boolean;
  title: string;
  message: string;
  type: ToastType;
  timeout: number;
}

export default class Toast extends React.Component<{}, IStates> {

  private isMount = false;

  private readonly DEFAULT_STATE = {
    showToast: false,
    title: '',
    message: '',
    type: ToastType.UNDEFINED,
    timeout: config['toast']['timeout'],
  };

  constructor(props: {}) {
    super(props);
    this.state = this.DEFAULT_STATE;
  }

  public componentWillMount() {
    this.isMount = true;
    NotificationStore.addListener(Events.TOAST_NOTIFICATION.toString(), this.showToast);
  }

  public componentWillUnmount(): void {
    this.isMount = false;
    NotificationStore.removeListener(Events.TOAST_NOTIFICATION.toString(), this.showToast);
  }

  private showToast = (title: string, message: string, type: ToastType, timeout?: number) => {
    if (this.isMount) {
      const timeout2 = !!timeout ? timeout : this.state.timeout;
      this.setState(
        { title, message, type, showToast: true, timeout: timeout2 },
        () => {
          setTimeout(
            () => {
              this.setState(this.DEFAULT_STATE);
            },
            timeout2,
          );
        },
      );
    }
  };

  public render() {
    if (!this.state.showToast) {
      return null;
    }

    switch (this.state.type) {
      case ToastType.SUCCESS:
        return (
          <div
            className="toast__container"
            style={{ animation: `fadein 0.5s, fadeout 0.5s ${this.state.timeout / 1000 - 0.5}s` }}
          >
            <div className="toast__cell">
              <div className="toast toast--green">
                <Icon className="toast__icon" circular={true} inverted={true} name="check" color="green" />
                <div className="toast__content">
                  <p className="toast__type">{this.state.title}</p>
                  <p className="toast__message">{this.state.message}</p>
                </div>
                <div className="toast__close" />
              </div>
              <div
                className="toast__progress_bar"
                style={{ animation: `fill ${this.state.timeout / 1000}s linear 1` }}
              />
            </div>
          </div>
        );

      case ToastType.ERROR:
        return (
          <div
            className="toast__container"
            style={{ animation: `fadein 0.5s, fadeout 0.5s ${this.state.timeout / 1000 - 0.5}s` }}
          >
            <div className="toast__cell">
              <div className="toast toast--red">
                <Icon className="toast__icon" circular={true} inverted={true} name="close" color="red" />
                <div className="toast__content">
                  <p className="toast__type">{this.state.title}</p>
                  <p className="toast__message">{this.state.message}</p>
                </div>
                <div className="toast__close" />
              </div>
              <div
                className="toast__progress_bar"
                style={{ animation: `fill ${this.state.timeout / 1000}s linear 1` }}
              />
            </div>
          </div>
        );

      case ToastType.INFO:
      case ToastType.WARNING:
        return (
          <div className="toast__container">
            <div className="toast__cell">
              <div className="toast toast--yellow">
                <Icon className="toast__icon" circular={true} inverted={true} name="info" color="blue" />
                <div className="toast__content">
                  <p className="toast__type">{this.state.title}</p>
                  <p className="toast__message">{this.state.message}</p>
                </div>
                <div className="toast__close" />
              </div>
              {ToastType.WARNING
                && <div
                  className="toast__progress_bar"
                  style={{ animation: `fill ${this.state.timeout / 1000}s linear 1` }}
                />
              }
            </div>
          </div>
        );
      default:
        return null;
    }
  }
}
