import './containerAdministration.scss';

import * as React from 'react';
import FeedbackActions from 'src/actions/feedback.action';
import Dashboard from 'src/components/administrationBoard/containerAdministration/dashboard/dashboard';
import Feedbacks from 'src/components/administrationBoard/containerAdministration/feedbacks/feedbacks';
import Gbu from 'src/components/administrationBoard/containerAdministration/itemsManager/gbu';
import Geographies from 'src/components/administrationBoard/containerAdministration/itemsManager/geographies';
import Users from 'src/components/administrationBoard/containerAdministration/users/users';
import ActionTypes from 'src/constants/actionTypes';
import AdministrationTab from 'src/constants/administrationTab';
import FeedbackKeysType from 'src/constants/feedbackKeys';
import AdministrationStore from 'src/stores/administration.store';

import Keywords from './itemsManager/keywords';
import LoadTestsResults from './loadTestsResults/loadTestsResults';

interface IProps { }

interface IStates {
  selectedTab: AdministrationTab;
}

class ContainerAdministration extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);

    this.state = {
      selectedTab: AdministrationStore.getTab(),
    };
  }

  public componentWillMount() {
    AdministrationStore.addListener(ActionTypes.ADMINISTRATION_TAB_GET.toString(), () => {
      this.setState({
        selectedTab: AdministrationStore.getTab(),
      });
    });
  }

  public componentWillUnmount() {
    AdministrationStore.removeListener(ActionTypes.ADMINISTRATION_TAB_GET.toString(), () => {
      this.setState({
        selectedTab: AdministrationStore.getTab(),
      });
    });
  }

  public render() {
    let content : null | JSX.Element = null;

    switch (this.state.selectedTab) {
      case AdministrationTab.DASHBOARD:
        content = <Dashboard />;
        break;
      case AdministrationTab.GBU:
        content = <Gbu />;
        break;
      case AdministrationTab.GEOGRAPHIES:
        content = <Geographies />;
        break;
      case AdministrationTab.KEYWORDS:
        content = <Keywords />;
        break;
      case AdministrationTab.USERS:
        content = <Users />;
        break;
      case AdministrationTab.FEEDBACK_BUGS:
        FeedbackActions.emitGetFeedbacks(FeedbackKeysType.FB_TYPE_OPTIONS_BUG);
        FeedbackActions.emitGetFeedbacksCounts();
        content = <Feedbacks type={FeedbackKeysType.FB_TYPE_OPTIONS_BUG} />;
        break;
      case AdministrationTab.FEEDBACK_SUGGESTIONS:
        FeedbackActions.emitGetFeedbacks(FeedbackKeysType.FB_TYPE_OPTIONS_SUGGESTIONS);
        FeedbackActions.emitGetFeedbacksCounts();
        content = <Feedbacks type={FeedbackKeysType.FB_TYPE_OPTIONS_SUGGESTIONS} />;
        break;
      case AdministrationTab.FEEDBACK_OTHER:
        FeedbackActions.emitGetFeedbacks(FeedbackKeysType.FB_TYPE_OPTIONS_OTHER);
        FeedbackActions.emitGetFeedbacksCounts();
        content = <Feedbacks type={FeedbackKeysType.FB_TYPE_OPTIONS_OTHER} />;
        break;
      case AdministrationTab.FEEDBACK_ARCHIVES:
        FeedbackActions.emitGetFeedbacks(-1, true);
        FeedbackActions.emitGetFeedbacksCounts();
        content = <Feedbacks type={FeedbackKeysType.FB_TYPE_OPTIONS_ARCHIVED} />;
        break;
      case AdministrationTab.LOAD_TESTS:
        content = <LoadTestsResults />;
        break;
    }
    return (
      <div id="container-administration">
        {content}
      </div >
    );
  }
}

export default ContainerAdministration;
