import {
  AtTimelineElement,
  KeyPoints,
  TimelineElement,
} from '../../../../../../../../../constants/piTimeline';
import { Months, Quarter, TimelineView } from '../../../../../../../../../constants/timeline';
import TimelineUtils from '../../../../../../../../../utils/timelineUtils';
import Utils from '../../../../../../../../../utils/utils';
import { Table } from 'semantic-ui-react';
import * as React from 'react';
import { StatusType } from '../../../../../../../../../constants/statusEnums';
import moment from 'moment';
import ContentCell from './contentCell/contentCell';

interface IProps {
  timelineElement: TimelineElement;
  year: number;
  quarter: Quarter;
  selectedView: TimelineView;
  month: Months;
  isAt: boolean;
}

const contentRowElements = (props: IProps) => {

  const nbMonthsInYear = 12;
  const nbMonthsInQuarter = 3;

  const getInCurrentPeriod = (keyPoints: KeyPoints, currentMonth: number, currMonthOrDay: number)
    : [boolean, boolean, boolean] => {

    let startingDateIsCurrentPeriod = keyPoints.isStartingSet() && keyPoints.startingDate!.month === currentMonth
    && keyPoints.startingDate!.year === props.year;
    let targetDateIsCurrentPeriod = keyPoints.isTargetSet() && keyPoints.targetDate!.month === currentMonth
      && keyPoints.targetDate!.year === props.year;
    let forecastDateIsCurrentPeriod = keyPoints.isForecastSet() && keyPoints.forecastDate!.month === currentMonth
      && keyPoints.forecastDate!.year === props.year;

    if (TimelineUtils.isOnMonthView(props.selectedView)) {
      startingDateIsCurrentPeriod = startingDateIsCurrentPeriod && keyPoints.startingDate!.day === currMonthOrDay;
      targetDateIsCurrentPeriod = targetDateIsCurrentPeriod && keyPoints.targetDate!.day === currMonthOrDay;
      forecastDateIsCurrentPeriod = forecastDateIsCurrentPeriod && keyPoints.forecastDate!.day === currMonthOrDay;
    }

    return [startingDateIsCurrentPeriod, targetDateIsCurrentPeriod, forecastDateIsCurrentPeriod];
  };

  /**
   * Generate the display of a timeline line according to the provided dates (on year & quarter view)
   * @param {number} currMonthOrDay
   * @param {KeyPoints} keyPoints
   * @param {StatusType} atStatus
   * @return {JSX.Element}
   */
  const generateTimelineLineDisplay = (currMonthOrDay: number, keyPoints: KeyPoints, atStatus?: StatusType) => {
    const currentMonth = TimelineUtils.isOnMonthView(props.selectedView) ? props.month : currMonthOrDay;
    const currDate = TimelineUtils.isOnMonthView(props.selectedView)
      ? `${props.year}-${props.month + 1}-${currMonthOrDay}`
      : `${props.year}-${currentMonth + 1}-01`;
    const currentPeriodIsInBetween = keyPoints.isStartingSet() && keyPoints.isTargetSet()
      && moment(currDate).isBetween(keyPoints.startingDate, keyPoints.targetDate);

    // We care only when forecast is after target
    let currentPeriodIsInBetweenForecast : boolean = false;

    if (keyPoints.isForecastSet() && keyPoints.isTargetSet()) {
      currentPeriodIsInBetweenForecast = moment(currDate).isBetween(keyPoints.targetDate, keyPoints.forecastDate);
    }

    const [startingDateIsCurrentPeriod, targetDateIsCurrentPeriod, forecastDateIsCurrentPeriod]
      = getInCurrentPeriod(keyPoints, currentMonth, currMonthOrDay);

    const isForecastBeforeTarget = moment(keyPoints.forecastDate).isBefore(moment(keyPoints.targetDate));

    const displayTarget = targetDateIsCurrentPeriod && atStatus !== undefined;
    const displayStartingTarget = startingDateIsCurrentPeriod && targetDateIsCurrentPeriod;

    return (
      <ContentCell
        key={Utils.generateRandomKey()}
        selectedView={props.selectedView}
        year={props.year}
        quarter={props.quarter}
        month={props.month}
        keyPoints={keyPoints}
        monthOrDay={currMonthOrDay}
        actionStatus={startingDateIsCurrentPeriod || displayTarget || currentPeriodIsInBetween ? atStatus : undefined}
        isStartingDate={startingDateIsCurrentPeriod}
        day={startingDateIsCurrentPeriod ? keyPoints.startingDate!.day : displayTarget
          ? keyPoints.targetDate!.day : undefined}
        secondDay={displayStartingTarget ? keyPoints.targetDate!.day : undefined}
        isLastDay={displayStartingTarget || displayTarget}
        elementId={props.timelineElement.id}
        forecastDay={forecastDateIsCurrentPeriod ? keyPoints.forecastDate!.day : undefined}
        isForecastBetween={currentPeriodIsInBetweenForecast}
        isForecastBeforeTarget={isForecastBeforeTarget}
        isAt={props.isAt}
      />
    );
  };

  /**
   * Generates empty cells for a row
   * @return {JSX.Element[]}
   */
  const generateEmptyCells = () => {
    const elements: JSX.Element[] = [];
    let nbCells = 1;
    switch (props.selectedView) {
      case TimelineView.YEAR:
        nbCells = nbMonthsInYear;
        break;
      case TimelineView.QUARTER:
        nbCells = nbMonthsInQuarter;
        break;
      case TimelineView.MONTH:
        nbCells = TimelineUtils.getNbDaysForMonth(props.month, props.year);
        break;
      default:
    }
    for (let i = 0; i < nbCells; i += 1) {
      elements.push(<Table.Cell key={Utils.generateRandomKey()} />);
    }
    return elements;
  };

  /**
   * Generates the row display according to the chosen view (day, month, year)
   * @return {JSX.Element[]}
   */
  const generateRowDisplayFromView = () => {
    let nbColumns = 0;
    let startMonth = 0;
    let indexOverride = 0;
    let altIndex = 0;
    let useAlternateIndex = false;
    const elements: JSX.Element[] = generateEmptyCells();
    const newKeyPoints = new KeyPoints(props.timelineElement);

    switch (props.selectedView) {
      case TimelineView.YEAR:
        nbColumns = nbMonthsInYear;
        break;
      case TimelineView.QUARTER:
        useAlternateIndex = true;
        switch (props.quarter) {
          case Quarter.Q1:
            startMonth = 0;
            nbColumns = 2;
            break;
          case Quarter.Q2:
            startMonth = 3;
            nbColumns = 5;
            break;
          case Quarter.Q3:
            startMonth = 6;
            nbColumns = 8;
            break;
          case Quarter.Q4:
            startMonth = 9;
            nbColumns = 11;
            break;
        }
        break;
      case TimelineView.MONTH:
        indexOverride = 1;
        nbColumns = TimelineUtils.getNbDaysForMonth(props.month, props.year);
        break;
      default:
        break;
    }

    const condition = (i: number) => useAlternateIndex ? i <= nbColumns : i < nbColumns;

    if (Utils.isActivityAt(props.timelineElement.type)) {
      const action = props.timelineElement as AtTimelineElement;
      for (let i = startMonth; condition(i); i += 1) {
        elements[useAlternateIndex ? altIndex : i] = generateTimelineLineDisplay(i + indexOverride, newKeyPoints,
                                                                                 action.status);
        altIndex += 1;
      }
    } else {
      for (let i = startMonth; condition(i); i += 1) {
        elements[useAlternateIndex ? altIndex : i] = generateTimelineLineDisplay(i + indexOverride, newKeyPoints);
        altIndex += 1;
      }
    }
    return elements;
  };

  return <>{generateRowDisplayFromView()}</>;

};
export default contentRowElements;
