/* Copyright (C) Thales Services, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Thales Services, 2020
 */
import User from 'src/models/user';
import PerformancePlan from './performancePlan';
import BusinessChallenge from './businessChallenge';
import PerformanceInitiative from './performanceInitiative';
import Action from './action';

export enum NotificationType {
  PP_EDIT = 0,
  BC_EDIT = 1,
  PI_EDIT = 2,
  BC_PUBLISH_REQUEST = 3,
  PI_PUBLISH_REQUEST = 4,
  BC_PUBLISH_GRANTED = 5,
  PI_PUBLISH_GRANTED = 6,
  BC_PUBLISH_REJECTED = 7,
  PI_PUBLISH_REJECTED = 8,
  FEEDBACK_STATUS_UPDATED = 9,
  ELEMENT_ADMIN_UPDATE = 10,
  REPORTING_BC_COMMENT = 11,
  BC_ASSIGNMENT = 12,
  PI_ASSIGNMENT = 13,
  ACTION_ASSIGNMENT = 14,
  BC_PUBLISH_FORCED = 15,
  PI_PUBLISH_FORCED = 16,
  PP_ASSIGNMENT = 17,
  PP_DRAFT_REMINDER = 18,
  BC_LINK = 19,
  BC_LINK_REJECTED = 20,
  BC_LINK_GRANTED = 21,
  PI_LINK = 22,
  PI_LINK_REJECTED = 23,
  PI_LINK_GRANTED = 24,
  PI_CLOSURE = 25,
  BC_CLOSURE = 26,
  AT_STATUS_CHANGED = 27,
}

export default class Notification {
  public id: number;
  public type: NotificationType;
  public sender: User;
  public isSeen: boolean;
  public performancePlan?: PerformancePlan;
  public businessChallenge?: BusinessChallenge;
  public performanceInitiative?: PerformanceInitiative;
  public action?: Action;
  public comment?: string;
  public feedbackStatus?: number;
  public createdAt: Date;
  public isActivityClosed: boolean;
}
