import './tileBC.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Segment } from 'semantic-ui-react';
import BusinessChallengeReporting from 'src/models/businessChallengeReporting';
import Utils from 'src/utils/utils';

import ReportModal from '../modal/reportModal';
import { RouteComponentProps, withRouter } from 'react-router';
import Metric from '../../../../../../models/metric';
import BusinessChallengeAPI from '../../../../../../api/businessChallenge.api';

interface IRouteProps {
  id: string;
}
interface IProps extends RouteComponentProps<IRouteProps> {
  bcReporting: BusinessChallengeReporting;
  reportingId: number;
  isReportingClosed: boolean;
  onBcReportingUpdate: (bcReporting: BusinessChallengeReporting) => void;
}

interface IStates {
  openModal: boolean;
  metrics : Metric[];
}

class TileBC extends React.Component<IProps & RouteComponentProps, IStates> {

  public constructor(props: IProps & RouteComponentProps) {
    super(props);
    this.state = {
      openModal: false,
      metrics: [],
    };
  }

  /**
   * Open BC report details modal
   */
  private handleOpenReportModal = () => {
    this.getMetrics(this.props.bcReporting.bcSnapshot.id).then((metric) => {

      this.setState({ openModal: true, metrics: [...metric] });
    });
  };

  /**
   * Close feedback modal
   */
  private onCloseReportModal = () => {
    this.setState({ openModal: false });
  };

  private getMetrics = async (idBC: number) => {
    const bcMetrics = await BusinessChallengeAPI.getBusinessChallengeMetrics(idBC);
    return this.mergeLinkedMetrics(bcMetrics);
  }

  //region LISTENER RELATED METHODS
  public mergeLinkedMetrics = (data) => {
    const metrics: Metric[] = data.metrics;
    if (data.linkedBcLinkMetrics) {
      for (const metric of data.linkedBcLinkMetrics) {
        if (!data.metrics.find(m => m.id === metric.id)) {
          metrics.push({ ...metric, isFromLinked: true });
        }
      }
    }
    return metrics;
  }

  private navigateToBc = () => {
    const bcId = this.props.bcReporting.bcSnapshot.id;
    const ppId = +this.props.match.params.id;
    this.props.history.push(`/activities-board/${ppId}/business-challenge/${bcId}/cockpit`);
  };

  public render() {
    return (
      <div id="tileBC-container">
        <Segment>
          <div className="bc-container">
            <div className="bc-title-container">
              {Utils.setBadge(this.props.bcReporting.bcSnapshot.status, this.props.bcReporting.bcSnapshot.isClosed)}
              <span className="bc-title" onClick={this.navigateToBc}>
                BC{Utils.leadingZero(this.props.bcReporting.bcSnapshot.code)} - {this.props.bcReporting.bcSnapshot.name}
              </span>
            </div>
            <div className="bc-tags-container">
              {Utils.isTop5(this.props.bcReporting.bcSnapshot)}
              {Utils.getPillar(this.props.bcReporting.bcSnapshot.pillar)}
            </div>
          </div>
          <div className="bc-info-container">
            <div className="name-user-container">
              {Utils.getUsersAvatars(this.props.bcReporting.bcSnapshot)}
            </div>
            <span className="date">
              <FormattedMessage id="lastUpdate" defaultMessage="Last update">
                {msg => `${msg}: ${this.props.bcReporting.lastSnapshotUpdate
                  ? Utils.displayDate(this.props.bcReporting.lastSnapshotUpdate)
                  : '-'}`}
              </FormattedMessage>
            </span>
          </div>
          <div id="comment">
            <FormattedMessage id="pp.comment" defaultMessage="Referent's comment on the period:" />
            <div id="text">
              {this.props.bcReporting && this.props.bcReporting.comment
                ? <pre>{this.props.bcReporting.comment}</pre>
                : <FormattedMessage id="pp.noReferentComment" defaultMessage="No comment yet" />
              }
            </div>
          </div>
          <div id="button">
            <Button
              basic={true}
              onClick={() => this.handleOpenReportModal()}
            >
              <FormattedMessage id="pp.openReport" defaultMessage="Open report" />
            </Button>
          </div>
        </Segment>
        {this.state.openModal
          && <ReportModal
            open={this.state.openModal}
            onClose={this.onCloseReportModal}
            initialBcReporting={this.props.bcReporting}
            reportingId={this.props.reportingId}
            isReportingClosed={this.props.isReportingClosed}
            onBcReportingUpdate={this.props.onBcReportingUpdate}
            metrics={this.state.metrics}
          />
        }
      </div>
    );
  }
}

export default withRouter(TileBC);
