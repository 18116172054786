import { SegmentType } from '../../../../../../../../models/segmentsMode';

export default class LastUpdateHighlight {
  private static readonly HIGHLIGHT_TIMEOUT = 2000;

  private static getElement(updatedType: SegmentType, targetId?: number | null): Element | null {
    let selector = `#${updatedType}-segment`;
    // If it's action or metrics, add id to id selector
    if ((updatedType === SegmentType.AT_ACTION
      || updatedType === SegmentType.BC_METRICS
      || updatedType === SegmentType.PI_METRICS
      || updatedType === SegmentType.BC_MILESTONES) && !!targetId) {
      selector = `#${updatedType}-${targetId}-segment`;
    }
    return document.querySelector(selector);
  }

  public static highlightArea = (updatedType: SegmentType, targetId?: number | null): void => {
    const element = LastUpdateHighlight.getElement(updatedType, targetId);
    if (element) {
      element.classList.add('highlight');
    }
  }

  public static unHighlightArea = (updatedType: SegmentType, targetId?: number | null): void => {
    const element = LastUpdateHighlight.getElement(updatedType, targetId);
    if (element && element.classList.contains('highlight')) {
      element.classList.remove('highlight');
    }
  }

  public static highlightParam(targetId?: number | null) {
    const updatedType = new URLSearchParams(window.location.search).get('highlight') || null;
    if (updatedType) {
      this.highlightArea(SegmentType[updatedType], targetId);
      setTimeout(
        () => {
          this.unHighlightArea(SegmentType[updatedType], targetId);
          window.history.replaceState({}, '', (window.location.href.toString() as string).split('?')[0]);
        },
        this.HIGHLIGHT_TIMEOUT,
      );
    }
  }

  public static getSegmentId(segmentType: SegmentType, targetId?: number | null) {
    if (!!targetId) return `${segmentType}-${targetId}-segment`;
    return `${segmentType}-segment`;
  }
}
