import TimelineApi from 'src/api/timeline.api';
import { TimelineView } from 'src/constants/timeline';

import Constants from '../constants/actionTypes';
import Dispatcher from '../dispatcher';

export default class TimelineActions {

  /**
   * Get information for timeline
   */
  public static emitGetInformationTimeline(type: TimelineView, year: number): Promise<void> {
    return TimelineApi.getInformationsTimeline(type, year).then((informationTimeline: any[]) => {
      Dispatcher.dispatch({
        informationTimeline,
        actionType: Constants.TIMELINE_GET,
      });
    });
  }

}
