import * as React from 'react';
import { Divider } from 'semantic-ui-react';

import { PerformancePlanPut } from 'src/models/performancePlan';
import PpGbuGeoEdition, { IBusinessLocationsValues, IRenderBusinessLocations } from '../../common/form/ppGbuGeo/ppGbuGeo';

interface IProps {
  pp: PerformancePlanPut;
  onUpdate(geoGbuValues: IBusinessLocationsValues): void;
  setNavigationDisabled(isNavigationDisabled: boolean): void;
}

class PpCreationStep1 extends React.Component<IProps> {

  public constructor(props: IProps) {
    super(props);
  }

  public componentDidMount(): void {
    this.props.setNavigationDisabled(false);
  }

  public renderGeoGbu(elements: IRenderBusinessLocations): JSX.Element {
    return (
      <div>
        <div className="row">
          {elements.gbu}
          {elements.businessLine}
          {elements.segment}
        </div>
        <Divider />
        <div className="row">
          {elements.geography}
          {elements.country}
          {elements.subcountry}
        </div>
      </div>
    );
  }

  public render(): React.ReactNode {
    const selectedValues: IBusinessLocationsValues = {
      gbuId: this.props.pp.gbuId,
      businessLineId: this.props.pp.businessLineId,
      segmentId: this.props.pp.segmentId,
      geographyId: this.props.pp.geographyId,
      countryId: this.props.pp.countryId,
      subcountryId: this.props.pp.subcountryId,
    };

    return (
      <div id="pp-creation-step1">
        <PpGbuGeoEdition
          selectedValues={selectedValues}
          onChange={this.props.onUpdate}
          renderGeoGbu={this.renderGeoGbu}
        />
      </div>
    );
  }
}

export default PpCreationStep1;
