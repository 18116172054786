import './assignedList.scss';

import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Notification from 'src/models/notification';
import Action from 'src/models/action';
import PerformanceInitiative from 'src/models/performanceInitiative';
import BusinessChallenge from 'src/models/businessChallenge';
import PerformancePlan from 'src/models/performancePlan';
import { FormattedMessage } from 'react-intl';

interface IRouteProps {
  id: string;
}

interface IAssignationList {
  id: number;
  email: string;
  isConflict: boolean;
  ownedPPs: PerformancePlan[];
  assignedPPs: PerformancePlan[];
  assignedBCs: BusinessChallenge[];
  assignedPIs: PerformanceInitiative[];
  assignedATs: Action[] | any;
  notifications: Notification[];
  secondaryEmails: string[];
}

enum IRoles {
  PP = 1,
  BC = 2,
  PI = 3,
  AT = 4,
}

enum ActionMapping {
  OWNER = 'performance-plan',
  PP = 'performance-plan',
  BC = 'business-challenge',
  PI = 'performance-initiative',
  AT = 'performance-initiative',
}

interface IProps extends RouteComponentProps<IRouteProps>{
  deletionCallbackData: IAssignationList;
  role: number;
}

class AssignedList extends React.Component<IProps> {

  public constructor(props: IProps) {
    super(props);
  }

  public formatAssignationList(assignationList : IAssignationList, role: IRoles) {
    const assingsRet : any = [];
    const roles = ['Referent'];
    const actionMapping = [
      ActionMapping.OWNER,
    ];
    if (role === IRoles.PP) {
      assingsRet[0] = assignationList.ownedPPs;
    }
    return {
      roles,
      actionMapping,
      assignations : assingsRet,
    };
  }

  public render() {
    const generatedAssignList = this.formatAssignationList(this.props.deletionCallbackData, this.props.role);
    const node : any = [];
    const as =  generatedAssignList.assignations;
    for (let i = 0; i < as.length ; i = i + 1) {
      if (as[i].length > 0) {
        node.push(
          <p key={i} className="assignationTitle">
            <FormattedMessage
              defaultMessage="Referent"
              id="referent"
            />:
          </p>,
        );
        for (let j = 0; j < as[i].length; j = j + 1) {
          node.push((
                    <p
                      key={`${i}0${j}`}
                      className="assignedItem"
                      onClick={() =>
                        this.props.history.push(
                          `/activities-board/performance-plan/${as[i][j].id}/cockpit`,
                        )}
                    >
                      {as[i][j].name} ({as[i][j].year})
                    </p>
                    ),
          );
        }
      }
    }
    return <div className="assignedList"> {node} </div>;
  }
}

export default withRouter(AssignedList);
