import Notification from 'src/models/notification';

import HttpClient from '../utils/httpClient';

export default class NotificationApi {

  /**
   * GET : /api/notifications
   * Get the notifications for the logged in user
   * @returns Promise<Notification>
   */
  public static getNotifications(): Promise<Notification[]> {
    return HttpClient.get<Notification[]>('notifications');
  }

  /**
   * GET : /api/notifications/amount
   * Get the notifications for the logged in user
   * @returns Promise<Notification>
   */
  public static getNotificationsNumber(): Promise<number> {
    return HttpClient.get<number>('notifications/count');
  }

  /**
   * PUT : /api/notifications/mark-as-reas
   * Mark the array of notification as read
   * @returns Promise<Notification>
   */
  public static putMarkNotificationAsRead(ids: number[]): Promise<void> {
    return HttpClient.put<void>('notifications/mark-as-read', ids);
  }
}
