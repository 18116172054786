import Constants from '../constants/actionTypes';
import Dispatcher from '../dispatcher';

export default class TitleTaskboardActions {

  public static setIsSaveDisabled(isSaveDisabled: boolean) {
    Dispatcher.dispatch({
      isSaveDisabled,
      actionType: Constants.SAVE_DISABLED,
    });
  }
}
