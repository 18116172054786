import dev from './dev.json';
import develop from './develop.json';
import prod from './prod.json';
import preprod from './preprod.json';

const config = process.env.NODE_ENV === 'development'
  ? dev
  : window.location.hostname.indexOf('iboost-dev') === -1
    ? window.location.hostname.indexOf('iboost') === -1
      ? preprod
      : prod
    : develop;

export default config;
