import 'moment/locale/fr';

import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Image, Label, Loader, Popup, SemanticSIZES } from 'semantic-ui-react';
import parse from 'html-react-parser';
import UserAvatar from 'src/components/common/userAvatar/userAvatar';
import TypeActivity from 'src/constants/typeActivity';
import BusinessChallenge from 'src/models/businessChallenge';
import ActivitiesStore from 'src/stores/activities.store';
import AuthStore from 'src/stores/auth.store';
import UserStore from 'src/stores/user.store';
import NotificationActions from 'src/actions/notification-actions';
import comp_logo from 'src/images/comp.png';
import negative_comp_logo from 'src/images/comp_negatif.png';
import growth_logo from 'src/images/growth.png';
import negative_growth_logo from 'src/images/growth_negatif.png';
import people_logo from 'src/images/people.png';
import negative_people_logo from 'src/images/people_negatif.png';
import User from 'src/models/user';
import Attachment from 'src/models/attachment';
import AuthAPI from 'src/api/auth.api';
import { BcSnapshot } from 'src/models/businessChallengeReporting';
import { Pillars } from 'src/constants/pillarEnums';
import { ToastType } from 'src/components/common/toast/toast';
import PerformanceInitiative from '../models/performanceInitiative';
import { FilterType } from 'src/components/common/form/filter/filter';
import { StatusType } from 'src/constants/statusEnums';
import Action from '../models/action';
import Languages from '../constants/languages';
import ModeTypes from '../constants/modeTypes';
import { FieldType } from '../components/activitiesBoard/containerActivities/metricsMilestones/metricsContainer';
import { ErrType } from '../constants/errors/typeError';
import Colors from './colors';
import { PpTabs } from 'src/components/activitiesBoard/containerActivities/contentPP/contentPP';
import { BcTabs } from 'src/components/activitiesBoard/containerActivities/contentBC/contentBC';
import { PiTabs } from 'src/components/activitiesBoard/containerActivities/contentPI/contentPI';
import PerformancePlan from 'src/models/performancePlan';
import LinkLabel, { LinkLabelMode } from 'src/components/common/linkLabel/linkLabel';
import { SegmentType } from '../models/segmentsMode';
import { RESOLUTION_TYPE } from '../constants/display';
import { MAX_DATE_LENGTH } from 'src/constants/date';
import { FIELD_IDS, FIELD_POSITION } from '../constants/fields';
import { AtTimelineElement } from '../constants/piTimeline';
import Milestone from '../models/milestone';
import ToggleHideElement from '../components/activitiesBoard/containerActivities/toggleHideElement/toggleHideElement';
import {
  ActionType,
} from '../components/activitiesBoard/containerActivities/common/segmentEditButtons/segmentEditButtons';
import History from 'src/models/history';

const DATE_FORMAT_FR = 'DD/MM/YYYY';
const DATE_FORMAT_EN = 'MM/DD/YYYY';

interface DataActivity {
  type: number;
  id: number;
  pp: number;
  pi: number;
}

export default class Utils {
  private isAuthToastDisplayed = false;

  /**
   * Regroup object in a collection by specified property
   * @param collection: collection of object
   * @param property: property of object
   */
  public static groupBy(collection: any, property: string) {
    const grouped = collection.reduce(
      (rv : any, x: any)  => {
        (rv[x[property]] = rv[x[property]] || []).push(x);
        return rv;
      },
      {},
    );
    return Object.keys(grouped).map((key) => {
      return [key, grouped[key]];
    });
  }

  public static isLeapYear(year: number): boolean {
    return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
  }

  /**
   * Add a zero before numbers that are inferior to 10
   * @param n: number
   */
  public static leadingZero(n: number): string {
    return (n < 10) ? (String(`0${n}`)) : String(n);
  }

  /**
   * Format the activity code according to its type
   * @param code
   * @param typeActivity
   * @param miCode
   */
  public static formatActivityCode(code: number, typeActivity: TypeActivity, miCode?: number): string {
    let prefix = '';
    switch (typeActivity) {
      case TypeActivity.BUSINESS_CHALLENGE:
        prefix = 'BC';
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        prefix = 'PI';
        break;
      case TypeActivity.ACTION:
        prefix = 'A';
        break;
      case TypeActivity.MILESTONE:
        if (AuthStore.getConnectedUser().language === Languages.FR) {
          prefix = 'J';
        } else {
          prefix = 'M';
        }
    }
    if (miCode && typeActivity === TypeActivity.ACTION) {
      const atCode = (code < 10) ? `0${code}` : code;
      const parentCode = (code < 10) ? `0${miCode}` : miCode;
      return `${prefix}${parentCode}.${atCode}`;
    }
    return (code < 10) ? `${prefix}0${code}` : `${prefix}${code}`;
  }

  /**
   * Format the activity name and code according to its type
   * @param {number} code
   * @param {string} name
   * @param {TypeActivity} typeActivity
   * @param {number} [miCode]
   * @param {boolean} useUnderscore
   * @param {boolean} replaceBlanks
   * @return {string}
   */
  public static formatActivityName(code: number, name: string, typeActivity: TypeActivity, miCode?: number,
                                   useUnderscore = false, replaceBlanks = false) : string {
    const formattedName = replaceBlanks ? name.replace(/\s/g, '_') : name;
    return `${this.formatActivityCode(code, typeActivity, miCode)} ${useUnderscore ? '_' : '-'} ${formattedName}`;
  }

   /**
   * Format the activity name and code OR LinkedLabel and name according to its type
   * @param {number} code
   * @param {string} name
   * @param {TypeActivity} typeActivity
   * @param {boolean} isLinked
   * @param {PerformancePlan} pp
   * @return {JSX.Element}
   */
  public static formatLinkedActivityName(code: number, name: string, typeActivity: TypeActivity, isLinked: boolean,
                                         pp: PerformancePlan) : JSX.Element {
    return (
      <>
        {isLinked
              ? <LinkLabel pp={pp} mode={LinkLabelMode.SMALL} disableLink={true} />
              : <span>{Utils.formatActivityCode(code, typeActivity)} -&nbsp;</span>}
          <span>{name}</span>
      </>
    );
  }

  /**
   * Format the name and year of a performance plan to a string
   * @param {string} name
   * @param {string} year
   * @return {string}
   */
  public static formatPpNameWithYear(name: string, year: string) : string {
    return `${name} (${year})`;
  }

  /**
   * Make the first letter of all the words in a string uppercase
   * @param str: string
   */
  public static capitalizeWords(str: string) {
    if (str === null) {
      return str;
    }
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  /**
   * Set a loading element, with the possibility of adding a CSS class
   * @param additionalClass: string
   * @param size: SemanticSIZES (default "large")
   */
  public static loader(additionalClass?: string, size: SemanticSIZES = 'large'): JSX.Element {
    return <Loader active={true} size={size} className={additionalClass} />;
  }

  /**
   * Set a empty element, with type of content initially desired
   * @param text: JSX.Element
   * @param style: Object
   * @param icon: JSX.Element (default: <Icon className={'empty'} name={'unlink'} />)
   * @param smallVersion: boolean (default: false)
   */
  public static empty(text: JSX.Element, style = {}, icon = <Icon className={'empty'} name={'unlink'} />,
                      smallVersion = false): JSX.Element {
    return (
      <span key={0} className={`empty-element-container ${smallVersion && 'sm'}`}>
      {icon}
        <h4 style={{ opacity: 0.6, ...style }}>{text}</h4></span>
    );
  }

  /**
   * Display the round colored badge for status
   * @param status: number
   * @param closed
   */
  public static setBadge(status: number, closed?: boolean): JSX.Element {
    if (!status || closed) {
      return (<span className="badge grey" />);
    }

    switch (status) {
      case 1:
        return (<span className="badge red" />);
      case 2:
        return (<span className="badge orange" />);
      case 3:
        return (<span className="badge green" />);
      default:
        return (<span className="badge grey" />);
    }
  }

  /**
   * Return the filterType based on status.
   * @param statusType: StatusType
   */
  public static mapStatusToFilterType(statusType: StatusType): FilterType {
    switch (statusType) {
      case StatusType.AT_RISK:
        return FilterType.AT_RISK;
      case StatusType.CONFIDENT:
        return FilterType.CONFIDENT;
      case StatusType.CRITICAL:
        return FilterType.CRITICAL;
    }
  }

  /**
   * Display the status' name depending on its id
   * @param status: number
   * @param closed
   */
  public static getStatusName(status: number, closed?: boolean): JSX.Element {
    if (closed) {
      return <FormattedMessage id="status.closed" defaultMessage="Closed" />;
    }
    switch (status) {
      case 1:
        return <FormattedMessage id="critical" defaultMessage="Critical" />;
      case 2:
        return <FormattedMessage id="atRisk" defaultMessage="At Risk" />;
      case 3:
        return <FormattedMessage id="confident" defaultMessage="Confident" />;
      default:
        return <FormattedMessage id="noStatus" defaultMessage="No status" />;
    }
  }

  /**
   * Get colour status
   * @param {number} status the status ID
   * @param closed
   * @return {string} the color status
   */
  public static getColorStatus(status: number, closed?: boolean): string {
    if (closed) {
      return Colors.grey;
    }
    switch (status) {
      case 1:
        return Colors.critical;
      case 2:
        return Colors.atRisk;
      case 3:
        return Colors.confident;
      default:
        return Colors.grey;
    }
  }

  /**
   * Get the color depending of the badge
   * @param {StatusType} status
   * @param {boolean} emptyDefault: return empty string as default behavior
   */
  public static getColorFromStatus(status?: StatusType, emptyDefault = false) {
    switch (status) {
      case StatusType.CRITICAL:
        return 'red';
      case StatusType.AT_RISK:
        return 'orange';
      case StatusType.CONFIDENT:
        return 'green';
      default:
        return emptyDefault ? '' : 'grey';
    }
  }

  /**
   * Returns a Top 5 div if the bc is top5, or nothing if not
   * @param bc: BusinessChallenge
   * @returns JSX.Element | null
   */
  public static isTop5(bc: BcSnapshot | BusinessChallenge): JSX.Element | null {
    if (bc.top5) {
      return <div className="top5">Top 5</div>;
    }
    return null;
  }

  /**
   * Returns the correct image, depending of the BC's pillar
   * @param pillar: number
   * @param short: boolean (default: false)
   * @returns JSX.Element | null
   */
  public static getPillarName(pillar: Pillars, short = false): string {
    switch (pillar) {
      case Pillars.GROWTH:
        return short ? 'growth' : 'Growth';
      case Pillars.COMPETITIVENESS:
        return short ? 'comp' : 'Competitiveness';
      case Pillars.PEOPLE:
        return short ? 'people' : 'People';
    }
  }

  /**
   * Returns the correct image, depending of the BC's pillar
   * @param pillar: number
   * @returns JSX.Element | null
   */
  public static getPillar(pillar: Pillars): JSX.Element | null {
    if (pillar === Pillars.GROWTH) {
      return <Image className="pillar" centered={true} src={growth_logo} />;
    }

    if (pillar === Pillars.COMPETITIVENESS) {
      return <Image className="pillar" centered={true} src={comp_logo} />;
    }

    return <Image className="pillar" centered={true} src={people_logo} />;
  }

  /**
   * Returns the correct image, depending of the BC's pillar
   * @param pillar: number
   * @returns JSX.Element | null
   */
  public static getNegativePillar(pillar: Pillars): JSX.Element | null {
    if (pillar === Pillars.GROWTH) {
      return <Image className="pillar" centered={true} src={negative_growth_logo} />;
    }

    if (pillar === Pillars.COMPETITIVENESS) {
      return <Image className="pillar" centered={true} src={negative_comp_logo} />;
    }

    return <Image className="pillar" centered={true} src={negative_people_logo} />;
  }

  /**
   * Check if the user has the correct edit rights for the data
   */
  public static updateCurrentUser() {
    AuthAPI.postCheck()
      .then((json: any) => {
        AuthStore.setConnectedUser(json.data.user);
        AuthStore.updateProfile();
      })
      .catch((err) => {
        if (err.reqStatus === 401 || err.reqStatus === 404 || err.reqStatus === 500) {
          NotificationActions.toast(
            <FormattedMessage id="error" defaultMessage="Error!" />,
            <FormattedMessage id="errorOccurredMessage" defaultMessage="An error occurred, please try again later" />,
            ToastType.ERROR,
          );
        }
      });
  }

  /**
   * @description Get the LastInformationsType and transform to corresponding translation
   * @param type LastInformationsType
   * @returns FormattedMessage
   */
  public static displayUpdatedInformationsType(type: SegmentType): JSX.Element {
    if (SegmentType[type] === SegmentType.PP_TEAM || SegmentType[type] === SegmentType.BC_TEAM
      || SegmentType[type] === SegmentType.PI_TEAM) {
      return <FormattedMessage id="team" defaultMessage="Team" />;
    }

    if (SegmentType[type] === SegmentType.BC_MILESTONES
      || SegmentType[type] === SegmentType.PI_MILESTONES
      || SegmentType[type] === SegmentType.BC_MILESTONES_DELETION
      || SegmentType[type] === SegmentType.PI_MILESTONES_DELETION) {
      return <FormattedMessage id="milestones" defaultMessage="Milestones" />;
    }

    if (SegmentType[type] === SegmentType.PI_ATTACHMENTS || SegmentType[type] === SegmentType.BC_ATTACHMENTS) {
      return <FormattedMessage id="attachments" defaultMessage="Attachments" />;
    }

    if (SegmentType[type] === SegmentType.AT_ACTION_DELETION) {
      return <FormattedMessage id="actions" defaultMessage="Actions" />;
    }

    if (SegmentType[type] === SegmentType.PI_METRICS
      || SegmentType[type] === SegmentType.PI_METRICS_DELETION
      || SegmentType[type] === SegmentType.BC_METRICS
      || SegmentType[type] === SegmentType.BC_METRICS_DELETION) {
      return <FormattedMessage id="metrics" defaultMessage="Metrics" />;
    }

    return <FormattedMessage id={SegmentType[type]} defaultMessage={type} />;
  }

  /**
   * Display date with correct format
   * @param date: Date
   * @param withHours: boolean (default: false)
   * @param dateSeparator: string (default: '/')
   */
  public static displayDate(date: Date, withHours = false, dateSeparator = '/') {
    if (date.toString().length <= MAX_DATE_LENGTH) {
      return date;
    }
    let dateFormat = `DD${dateSeparator}MM${dateSeparator}YYYY`;
    let hourFormat = withHours ? ' - HH:mm' : '';

    // If user's browser localization is US & user's language is not French
    if (this.getUserNavigatorAndLanguage() === Languages.EN) {
      dateFormat = `MM${dateSeparator}DD${dateSeparator}YYYY`;
      hourFormat = withHours ? ' - hh:mm a' : '';
    }

    return moment(date).format(`${dateFormat}${hourFormat}`);
  }

  public static getUserNavigatorAndLanguage() {
    return navigator.language.toUpperCase() === 'EN-US'
    && AuthStore.getConnectedUser().language === Languages.EN ? Languages.EN : Languages.FR;
  }

  /**
   * Display hours with correct format
   * @param date: Date
   */
  public static displayHours(date: Date) {
    let hourFormat = 'HH:mm';

    // If user's browser localization is US & user's language is not French
    if (this.getUserNavigatorAndLanguage() === Languages.EN) {
      hourFormat = 'hh:mm a';
    }

    return moment(date).format(hourFormat);
  }

  /**
   * Display date month & day with correct format
   * @param date: Date
   * @param dateSeparator: string (default: '/')
   */
  public static displayMonthDayDate(date: Date, dateSeparator = '/') {
    let dateFormat = `DD${dateSeparator}MM`;

    if (this.getUserNavigatorAndLanguage() === Languages.EN) {
      dateFormat = `MM${dateSeparator}DD`;
    }
    return moment(date).format(`${dateFormat}`);
  }

  /**
   * Display user names
   * @param user: User
   */
  public static displayUser(user: User) {
    return `${user.firstName} ${user.lastName}`;
  }

  /**
   * Display date with fancy format
   * @param date: Date
   * @param concat: boolean (DD/MM/YYYY)
   */
  public static displayFancyDate(date: Date, concat?: boolean) {
    const isUserLanguageEn = AuthStore.getConnectedUser().language === Languages.EN;

    // Format 'LL' => 'September 4, 1986', so only the user's language matters
    let format = 'LL';
    let locale = isUserLanguageEn ? 'en' : 'fr';

    if (!!concat && concat) {
      // Format 'L' => '09/04/1986', so the user's language AND the navigator's language matter
      format = 'L';
      locale = (navigator.language.toUpperCase() === 'EN-US' && isUserLanguageEn) ? 'en' : 'fr';
    }

    return moment(date).locale(locale).format(format);
  }

  /**
   * Method to determinate which user(s) from array1 is not in the array2
   * @param {User[]} array1: first array of users
   * @param {User[]} array2: second array of users
   * @return {any} array which contains the user(s) of array1 who are not in array2
   */
  public static differenceUsers(array1: User[], array2: User[]) {
    if (array1.length > 0) {
      return array1.filter((obj1: User) => {
        return !array2.some((obj2: User) => {
          return obj1.id === obj2.id;
        });
      });
    }
    return [];
  }

  /**
   * User information displayed as name & avatar
   * @param {any} element
   * @return {JSX.Element[]}
   */
  public static getUsersAvatars(element: any): JSX.Element[] {
    return this.getUsersAvatarsFromList(element.assignedAccounts);
  }

  /**
   * User information displayed as name & avatar
   * @param {User[]} list
   * @return {JSX.Element[]}
   */
  public static getUsersAvatarsFromList(list: User[]): JSX.Element[] {
    return list.map((account: User) => <UserAvatar account={account} key={account.id} />);
  }

  /**
   * Sort an array depending on the key values
   * @param {any} array array to sort
   * @param {string} key value used to sort the array
   * @param {boolean?} reverse the sorting if true
   * @return {any} sorted array by code value
   */
  public static sortArrayByKey(array: any, key: string, reverse?: boolean): any {
    if (!array) {
      return;
    }
    if (!!reverse && reverse) {
      return array.sort((a: any, b: any) => {
        if (a[key] > b[key]) {
          return -1;
        }
        if (a[key] < b[key]) {
          return 1;
        }
        return 0;
      });
    }
    return array.sort((a: any, b: any) => {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });
  }

  /**
   * Sort alphabetically the initial array except for the first value ('None')
   * @param array
   * @return { key: number, text: string, value: number }[] sorted array formatted for Dropdown lists
   */
  public static sortWithNoneValue(array: any[]): { key: number, text: string, value: number }[] {
    const formattedArray = array.map(el => ({ key: el.id, text: el.name, value: el.id }));
    const noneValue = formattedArray.find(el => el.value === 1);

    if (noneValue) {
      formattedArray.splice(formattedArray.indexOf(noneValue), 1);
      return [...Utils.sortArrayByKey(formattedArray, 'text')];
    }

    return Utils.sortArrayByKey(formattedArray, 'text');
  }

  /**
   * Compare old and new arrays and returns what is to add, to keep, and to remove
   * @param {any} oldObjects previous array (before modification)
   * @param {any} newObjects new array (after modification)
   * @return {create: array, edit: array, remove: array} objected containing created, edited and removed objects
   */
  public static filterChanges(oldObjects: any[], newObjects: any[]): { create: any[], edit: any[], remove: any[] } {
    const create: any[] = [];
    const edit: any[] = [];
    const remove: any[] = [];
    if (oldObjects) {
      oldObjects.forEach((oldObject: any) => {
        if (!newObjects.find(obj => obj.id === oldObject.id)) {
          remove.push(oldObject);
        }
      });
    }
    if (newObjects) {
      newObjects.forEach((newObject: any) => {
        if (!newObject.id) {
          create.push(newObject);
        } else {
          const oldObject = oldObjects.find(obj => newObject.id === obj.id);
          if (!oldObject) {
            create.push(newObject);
          } else {
            let isSame = true;
            Object.keys(newObject).forEach((key: any) => {
              if (!(key in oldObject) || JSON.stringify(newObject[key]) !== JSON.stringify(oldObject[key])) {
                isSame = false;
              }
            });
            if (!isSame) {
              edit.push(newObject);
            }
          }
        }
      });
    }
    return { create, edit, remove };
  }

  /**
   * Toasts a generic error
   */
  public static toastError() {
    NotificationActions.toast(
      <FormattedMessage id="errorOccurred" defaultMessage="Error" />,
      <FormattedMessage id="errorOccurredMessage" defaultMessage="An error occurred, please try again later" />,
      ToastType.ERROR,
    );
  }

  /**
   * Toasts an authentication error
   */
  public toastAuthError() {
    if (!this.isAuthToastDisplayed) {
      this.isAuthToastDisplayed = true;

      const timeout = 5000;
      NotificationActions.toast(
        <FormattedMessage id="sessionError" defaultMessage="Session Error" />,
        (
          <FormattedMessage
            id="sessionExpired"
            defaultMessage="Your session has expired, please sign out and try to sign in again"
          />
        ),
        ToastType.ERROR,
        timeout,
      );

      setTimeout(
        () => {
          this.isAuthToastDisplayed = false;
        },
        timeout,
      );
    }
  }

  /**
   * Check which view to display
   */
  public static isExternalView(elementId: number, typeActivity: TypeActivity): boolean {
    let external = true;

    switch (typeActivity) {
      case TypeActivity.PERFORMANCE_PLAN:
        if (ActivitiesStore.getActivities() && ActivitiesStore.getActivities().find(pp => pp.id === elementId)) {
          external = false;
        }
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        ActivitiesStore.getActivities().forEach((pp) => {
          if (pp.businessChallenges.find(bc => bc.id === elementId)) {
            external = false;
          }
        });
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        ActivitiesStore.getActivities().forEach((pp) => {
          pp.businessChallenges.forEach((bc) => {
            if (bc.performanceInitiatives.find(pi => pi.id === elementId)) {
              external = false;
            }
          });
        });
        break;
    }
    return external;
  }

  public static hexToRGB(hex: string, alpha: number) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r},${g},${b},${alpha})`;
    }
    return `rgba(${r},${g},${b})`;
  }

  /**
   * Test if the year is valid
   * @param year : string
   */
  public static testYear(year: string): boolean {
    return new RegExp('^\\d{4}$').test(year);
  }

  /**
   * Test if attachments are valid
   * @param attachments : attachments
   */
  public static testAttachments(attachments: Attachment[]): boolean {
    const MIN_URL_LENGTH = 3;
    const MAX_URL_LENGTH = 512;
    return attachments.map(att =>
      !!att.url && att.url.length > 0
      && !!att.title && att.title.length > 0
      && att.url.length >= MIN_URL_LENGTH
      && att.url.length <= MAX_URL_LENGTH)
      .every(el => el);
  }

  /**
   * Generate random key for items on loops
   */
  public static generateRandomKey(): string {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  public static getTypeName = (typeActivity: TypeActivity, getRouteName?: boolean, initials = false): string => {
    switch (typeActivity) {
      case TypeActivity.PERFORMANCE_PLAN:
        return !!getRouteName && getRouteName ? 'performance-plans' : initials ? 'PP' : 'Performance Plan';
      case TypeActivity.BUSINESS_CHALLENGE:
        return !!getRouteName && getRouteName ? 'business-challenges' : 'BC';
      case TypeActivity.PERFORMANCE_INITIATIVE:
        return !!getRouteName && getRouteName ? 'performance-initiatives' : 'PI';
      default:
        return '';
    }
  };

  public static getCurrentUserLanguageName(): 'EN' | 'FR' {
    return UserStore.getLanguage() === Languages.EN ? 'EN' : 'FR';
  }

  public static isCodeOnError = (activities: any[], activityCode: string | number) => {
    return (!!activities.find((activity: PerformanceInitiative | BusinessChallenge) => {
      let code;
      if (typeof activityCode === 'string') {
        code = parseInt(activityCode, 10);
      } else {
        code = activityCode;
      }
      return activity.code === code;
    }));
  };

  public static getFormattedTextFromHTMLString = (htmlText: string | undefined)
    : JSX.Element | string | JSX.Element[]  => {
    return htmlText ? parse(htmlText) : '';
  };

  /**
   * @description
   * @param {string} htmlText
   * @param {React.Component} parent
   */
  public static replaceActivityMentionToJsx = (htmlText: string, parent) : JSX.Element | string | JSX.Element[]  => {
    return htmlText
      ? parse(htmlText, {
        replace(domNode) {
          if (domNode.attribs && domNode.attribs.class === 'mention-activity') {
            if (domNode.children && domNode.children.length > 0) {
              return React.createElement(
                'a', {
                  className: 'mention-activity',
                  onClick: (event) => {
                    event.preventDefault();
                    let url;
                    if (domNode.attribs) {
                      const activity: DataActivity = {
                        id: parseInt(domNode.attribs['data-activity'], 10),
                        type: parseInt(domNode.attribs['data-type'], 10),
                        pp: parseInt(domNode.attribs['data-pp'], 10),
                        pi: parseInt(domNode.attribs['data-pi'], 10),
                      };
                      const activityId = activity.id;
                      // Create the url with the data-activity
                      switch (activity.type) {
                        case TypeActivity.PERFORMANCE_PLAN:
                          if (Utils.isExternalView(activityId, TypeActivity.PERFORMANCE_PLAN)) {
                            url = `/pp-external/${activityId}`;
                          } else {
                            url = `/activities-board/performance-plan/${activityId}/${PpTabs.COCKPIT}`;
                          }
                          break;
                        case TypeActivity.BUSINESS_CHALLENGE:
                          if (Utils.isExternalView(activityId, TypeActivity.BUSINESS_CHALLENGE)) {
                            url = `/bc-external/${activityId}`;
                          } else {
                            url = `/activities-board/${activity.pp}/business-challenge/${activityId}/${BcTabs.COCKPIT}`;
                          }
                          break;
                        case TypeActivity.PERFORMANCE_INITIATIVE:
                          if (Utils.isExternalView(activityId, TypeActivity.PERFORMANCE_INITIATIVE)) {
                            url = `/pi-external/${activityId}`;
                          } else {
                            url = `/activities-board/${activity.pp}/performance-initiative/${activityId}/${PiTabs.COCKPIT}`;
                          }
                          break;
                        case TypeActivity.ACTION:
                          if (Utils.isExternalView(activity.pi, TypeActivity.PERFORMANCE_INITIATIVE)) {
                            url = `/pi-external/${activity.pi}`;
                          } else {
                            url = `/activities-board/${activity.pp}/performance-initiative/${activity.pi}/${PiTabs.ACTIVITY}?ACTION=${activityId}`;
                          }
                          break;
                      }
                      parent.props.history.push(url);
                    }
                    return;
                  },
                  href: domNode.attribs['href'],
                },
                `${domNode.children[0].data}`);
            }
          }
        },
      })
      : '';
  };

  public static getAssigneeList(activity: PerformanceInitiative | Action) {
    const userList: string[] = [];
    let additionalUsers = 0;

    if (activity.assignedAccounts) {
      for (let i = 0; i < activity.assignedAccounts.length; i += 1) {
        if (i < 3) {
          userList.push(`${activity.assignedAccounts[i].firstName} ${activity.assignedAccounts[i].lastName}`);
        }
      }

      additionalUsers = activity.assignedAccounts.length - 3;
    }

    return (
      <span className="assignees">
      {userList.map(el => el).join(', ')}
        {additionalUsers > 0 &&
        <span className="additional-users">
          &nbsp;+ {additionalUsers}&nbsp;
          {additionalUsers === 1
            ? <FormattedMessage id="moreUser" defaultMessage="more user" />
            : <FormattedMessage id="moreUsers" defaultMessage="more users" />
          }
        </span>
        }
    </span>
    );
  }

  /**
   * Returns false if the value is undefined or empty
   * @param value: number|string|null|undefined
   * @param isMandatory: boolean
   * @param fieldType: FieldType
   * @returns boolean
   */
  static isFieldOnError(
    value: number | string | null | undefined | Date,
    isMandatory: boolean,
    fieldType?: FieldType,
  ): boolean {
    if (isMandatory && (value === null || value === undefined || value === '')) {
      return true;
    }

    switch (fieldType) {
      case FieldType.STRING:
        return value ? (value as string).length > 255 : false;
      case FieldType.NUMBER:
        return value ? isNaN(value as number) : false;
      default:
        return value === null || value === undefined || value === '';
    }
  }

  static showValueIfExists = (value: number | string | null | undefined, withHyphen = true) => {
    return value ? value : withHyphen ? '-' : '';
  };

  static showDateIfExists = (date: Date | null | undefined, withHyphen = true) => {
    return date ? Utils.displayDate(date) : withHyphen ? '-' : '';
  };

  static isValueNullOrEmpty = (value: string | number | Date | null) => {
    return value === '' || value === null || value === undefined;
  };

  //region INTERFACE MODE
  public static isOnEditMode(mode: ModeTypes) : boolean {
    return mode === ModeTypes.MODE_EDITION;
  }

  public static isOnViewMode(mode: ModeTypes) : boolean {
    return mode === ModeTypes.MODE_VIEW;
  }

  public static isOnCancelMode(mode: ModeTypes) : boolean {
    return mode === ModeTypes.MODE_CANCEL;
  }

  public static isOnDeleteMode(mode: ModeTypes) : boolean {
    return mode === ModeTypes.MODE_DELETE;
  }

  public static isOnViewOrDeletionMode(mode: ModeTypes) : boolean {
    return mode === ModeTypes.MODE_VIEW || mode === ModeTypes.MODE_DELETE;
  }

  public static isOnEditOrCancelMode(mode: ModeTypes) : boolean {
    return mode === ModeTypes.MODE_CANCEL || mode === ModeTypes.MODE_EDITION;
  }
  //endregion

  //region ACTIVITY TYPE
  public static isCurrentActivityTypeBC() : boolean {
    return Utils.isActivityBc();
  }

  public static isActivityBc(type = ActivitiesStore.getTypeActivity()) : boolean {
    return type === TypeActivity.BUSINESS_CHALLENGE;
  }

  public static isActivityPi(type = ActivitiesStore.getTypeActivity()) : boolean {
    return type === TypeActivity.PERFORMANCE_INITIATIVE;
  }

  public static isActivityPp(type = ActivitiesStore.getTypeActivity()) : boolean {
    return type === TypeActivity.PERFORMANCE_PLAN;
  }

  public static isBoundToParent(publication: History, type = ActivitiesStore.getTypeActivity()) : boolean {
    return (publication?.isBoundToBcHistory && Utils.isActivityPi(type)) ||
    (publication?.isBoundToPpHistory && Utils.isActivityBc(type));
  }

  public static parentType(type = ActivitiesStore.getTypeActivity()) : TypeActivity {
    switch (type) {
      case TypeActivity.BUSINESS_CHALLENGE:
        return TypeActivity.PERFORMANCE_PLAN;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        return TypeActivity.BUSINESS_CHALLENGE;
      default:
        return TypeActivity.PERFORMANCE_PLAN;
    }
  }

  public static isActivityAt(type = ActivitiesStore.getTypeActivity()) : boolean {
    return type === TypeActivity.ACTION;
  }

  /**
   * Get activity by route pattern
   * @param {string} route
   * @returns {TypeActivity}
   */
  public static getActivityByRoute(route: string): TypeActivity {
    const bcRegex = new RegExp('/activities-board/[0-9]*/business-challenge/[0-9]*');
    const piRegex = new RegExp('/activities-board/[0-9]*/performance-initiative/[0-9]*');
    if (bcRegex.test(route)) {
      return TypeActivity.BUSINESS_CHALLENGE;
    }
    if (piRegex.test(route)) {
      return TypeActivity.PERFORMANCE_INITIATIVE;
    }
    return TypeActivity.PERFORMANCE_PLAN;
  }
  //endregion

  //region KEYWORD
  public static isKeywordNone(keywordId: number) : boolean {
    return keywordId === 1;
  }
  //endregion

  public static getErrorPopup(msg: string | JSX.Element, iconStyle?: string) {
    return (
      <Popup
        inverted={true}
        size="tiny"
        content={msg}
        trigger={<Icon className={iconStyle ? `${iconStyle} error` : 'error'} name="warning circle" />}
      />
    );
  }

  public static getErrorMessageWithPopup(errType?: ErrType) {
    switch (errType) {
      case ErrType.ME_NAME:
        return Utils.getErrorPopup(
          <FormattedMessage
            id="someMetricsHaveNoName"
            defaultMessage="Some metrics have no name or a name with a length that exceeds 255 characters"
          />,
        );
      case ErrType.ME_COMPARISON_TOLERANCE:
        return Utils.getErrorPopup(
          <FormattedMessage
            id="comparison.invalidToleranceRange"
            defaultMessage="Some metrics have invalid tolerance range"
          />,
        );
      case ErrType.MEV_DATE:
        return Utils.getErrorPopup(
          <FormattedMessage id="someMetricsHaveInvalidDate" defaultMessage="Some metrics have invalid date values" />,
        );
      case ErrType.MEV_CURRENT:
        return Utils.getErrorPopup(
          <FormattedMessage
            id="someMetricHaveInvalidCurrent"
            defaultMessage="Some metrics have invalid current values"
          />,
        );
      case ErrType.MEV_TARGET:
        return Utils.getErrorPopup(
          <FormattedMessage id="someMetricHaveInvalidTarget" defaultMessage="Some metrics have invalid target values"/>,
        );

      case ErrType.MI_NAME:
        return Utils.getErrorPopup(
          <FormattedMessage
            id="someMilestonesHaveNoName"
            defaultMessage="Some milestones have no name or a name with a length that exceeds 255 characters"
          />,
        );
      case ErrType.MI_TARGET_DATE:
        return Utils.getErrorPopup(
          <FormattedMessage
            id="someMilestonesHaveInvalidDate"
            defaultMessage="Some milestones have invalid target date values"
          />,
        );
      case ErrType.TEXT_FIELDS:
        return Utils.getErrorPopup(
          <FormattedMessage
            id="textarea.error.messageToLong"
            defaultMessage="Text fields should respect characters/lines limit"
          />,
          'menu-icon-error',
        );
      default:
        return Utils.getErrorPopup(
          <FormattedMessage
            id="fields.error"
            defaultMessage="Some fields are not valid"
          />,
          'menu-icon-error',
        );
    }
  }

  public static updateFieldsOnError = (idElement: FIELD_IDS, isFieldOnError: boolean, errorsList: boolean[]) => {
    const isOnError = [...errorsList];
    switch (idElement) {
      case FIELD_IDS.ACHIEVEMENTS:
        isOnError[FIELD_POSITION.ACHIEVEMENTS] = isFieldOnError;
        break;
      case FIELD_IDS.ISSUES_AND_RISKS:
        isOnError[FIELD_POSITION.ISSUES_AND_RISKS] = isFieldOnError;
        break;
      case FIELD_IDS.DECISIONS:
        isOnError[FIELD_POSITION.DECISIONS] = isFieldOnError;
        break;
      case FIELD_IDS.NEXT_STEPS:
        isOnError[FIELD_POSITION.NEXT_STEPS] = isFieldOnError;
        break;
    }
    return isOnError;
  }

  /**
   * Setup the information tooltip for BC/PI keywords selection, used for BC/PI creation/edition
   * @param subject: string
   * @param isForCreation: boolean (default: false)
   * @returns JSX.Element
   */
  public static getKeywordTooltip(subject: string, isForCreation = true): JSX.Element {
    const icon = isForCreation
      ? <Icon name="info circle" />
      : <Icon className="error" name="exclamation circle" />;

    return (
      <Popup
        size="small"
        position="right center"
        hoverable={true}
        content={
          <>
            <FormattedMessage
              id="keywordsMandatoryError"
              defaultMessage="Keyword is mandatory, and can be modified later. If you don't find a correct keyword, you can propose a new one to the "
            />
            <a href={`mailto:ambitionboost@thalesgroup.com?subject=${subject}`}>
              <FormattedMessage id="supportTeam" defaultMessage="support team" />
            </a>
          </>
        }
        trigger={icon}
      />
    );
  }

  public static showDateOrNone = (date: Date | undefined | null) => {
    return date ? Utils.displayDate(date) : <FormattedMessage id="none" defaultMessage="None"/>;
  }

  /**
   * Get the PP id from an activity
   * @param {BusinessChallenge | PerformanceInitiative} activity
   * @param {TypeActivity} type
   * @return {number}
   */
  public static getPlanIdFromActivity(activity: BusinessChallenge | PerformanceInitiative, type: TypeActivity) {
    let bc: BusinessChallenge | null;
    if (this.isActivityBc(type)) {
      bc = (activity as BusinessChallenge);
    } else {
      bc = (activity as PerformanceInitiative).businessChallenge;
    }
    return bc ? (bc.performancePlan ? bc.performancePlan.id : bc.performancePlanId) : 0;
  }

  public static getResolution = (): RESOLUTION_TYPE => {
    const page = document.getElementsByTagName('html');
    if (page && page[0]) {
      switch (page[0].clientWidth) {
        case 1280:
          return RESOLUTION_TYPE.MOBILITY;
        default:
          return RESOLUTION_TYPE.FHD;
      }
    }
    return RESOLUTION_TYPE.FHD;
  }

  public static isOnMobility = (): boolean => {
    return Utils.getResolution() === RESOLUTION_TYPE.MOBILITY;
  }

  public static getDateFormat = () => {
    return Utils.getUserNavigatorAndLanguage() === Languages.EN ? DATE_FORMAT_EN : DATE_FORMAT_FR;
  }

  public static isDateFormatLanguageValid = (value): boolean => {
    if (!value) {
      return false;
    }
    const date = value?.toString().length <= MAX_DATE_LENGTH ? value : Utils.displayDate(value);
    return moment(date, Utils.getDateFormat(), true).isValid();
  }

  public static parseDateStringToDate = (data, key: string) => {
    if (!Utils.isDateFormatLanguageValid(data[key])) {
      return null;
    }
    return {
      ...data,
      [key]: moment(data[key]).toDate(),
    };
  }

  public static getHeightToSubtract = (includeTopBar = false) => {
    const draftBanner =  window.document.getElementById('draft-edition-banner');
    const dueDateBanner =  window.document.getElementById('due-date-banner');
    const toolbar =  window.document.getElementById('taskBoard-toolbar');
    const toolBarHeight = toolbar ? toolbar.offsetHeight : 0;
    const tabsMenu =  window.document.getElementById('main-tabs-menu');
    const tabsMenuHeight = tabsMenu ? tabsMenu.offsetHeight : 0;
    const closedBanner = window.document.getElementById('closed-edition-banner');
    const topBar = window.document.getElementById('topBar');
    const topBarHeight = topBar && includeTopBar ? topBar!.offsetHeight : 0;
    let totalHeight = toolBarHeight + tabsMenuHeight + topBarHeight;
    if (draftBanner && dueDateBanner) {
      return draftBanner.offsetHeight + dueDateBanner.offsetHeight + totalHeight;
    }  if (closedBanner && dueDateBanner) {
      return closedBanner.offsetHeight + dueDateBanner.offsetHeight + totalHeight;
    }
    totalHeight += dueDateBanner
      ? dueDateBanner!.offsetHeight
      : draftBanner ? draftBanner!.offsetHeight : closedBanner ? closedBanner!.offsetHeight : 0;
    return totalHeight;
  }

  public static calculateContentSize = () => {
    const height = Utils.getHeightToSubtract();
    return document.getElementById('container-activities')!.offsetHeight - height;
  }

  public static areDatesSame = (date1: Date, date2: Date, ignoreHours = true) => {
    const dateOne = ignoreHours ? date1.setHours(0, 0, 0, 0) : date1.getTime();
    const dateTwo = ignoreHours ? date2.setHours(0, 0, 0, 0) : date2.getTime();
    return dateOne === dateTwo;
  }

  public static getEditingFieldSectionLabel = (segmentTitle: SegmentType | string) => {
    if (segmentTitle.includes(SegmentType.BC_PUBLICATIONS) || segmentTitle.includes(SegmentType.PI_PUBLICATIONS)
      || segmentTitle.includes(SegmentType.PP_PUBLICATIONS)) {
      return <FormattedMessage id="overviewPanel.publication" defaultMessage="Publication" />;
    }

    if (segmentTitle.includes(SegmentType.PP_TEAM) || segmentTitle.includes(SegmentType.BC_TEAM)
      || segmentTitle.includes(SegmentType.PI_TEAM)) {
      return <FormattedMessage id="team" defaultMessage="Team" />;
    }

    if (segmentTitle.includes(SegmentType.BC_MILESTONES) || segmentTitle.includes(SegmentType.PI_MILESTONES)) {
      return <FormattedMessage id="milestones" defaultMessage="Milestones" />;
    }

    if (segmentTitle.includes(SegmentType.PI_ATTACHMENTS) || segmentTitle.includes(SegmentType.BC_ATTACHMENTS)) {
      return <FormattedMessage id="attachments" defaultMessage="Attachments" />;
    }

    if (segmentTitle.includes(SegmentType.AT_ACTION)) {
      return <FormattedMessage id="actions" defaultMessage="Actions" />;
    }

    if (segmentTitle.includes(SegmentType.PI_METRICS) || segmentTitle.includes(SegmentType.BC_METRICS)) {
      return <FormattedMessage id="metrics" defaultMessage="Metrics" />;
    }

    if (segmentTitle.includes(SegmentType.BC_DETAILS) || segmentTitle.includes(SegmentType.PI_DETAILS)) {
      return <FormattedMessage id="overviewPanel.details" defaultMessage="Details" />;
    }

    if (segmentTitle.includes(SegmentType.BC_ABOUT) || segmentTitle.includes(SegmentType.PI_ABOUT)
      || segmentTitle.includes(SegmentType.PP_ABOUT)) {
      return <FormattedMessage id="topBar.about" defaultMessage="About" />;
    }

    if (segmentTitle.includes(SegmentType.BC_MAIN_ACHIEVEMENTS)
      || segmentTitle.includes(SegmentType.BC_DECISIONS_TO_BE_MADE)
      || segmentTitle.includes(SegmentType.BC_MAIN_ISSUES)
      || segmentTitle.includes(SegmentType.BC_NEXT_STEPS)
      || segmentTitle.includes(SegmentType.PI_MAIN_ACHIEVEMENTS)
      || segmentTitle.includes(SegmentType.PI_DECISIONS_TO_BE_MADE)
      || segmentTitle.includes(SegmentType.PI_MAIN_ISSUES)
      || segmentTitle.includes(SegmentType.PI_NEXT_STEPS)
    ) {
      return <FormattedMessage id="topBar.cockpit" defaultMessage="Cockpit" />;
    }
  };

  public static isActionTypeEdit(actionType: ActionType): boolean {
    return actionType === ActionType.EDIT;
  }

  public static getFavouriteIcon = (at: Action | AtTimelineElement) => {
    return at.isFavourite ? <div><Icon name="star" className="favourite-icon"/></div> : null;
  }

  public static getClosedTag = (at: Action | AtTimelineElement) => {
    return at.isClosed ? <Label><FormattedMessage id="actionClosed" defaultMessage="CLOSED"/></Label> : null;
  }

  public static getHiddenIcon = (mi: Milestone) => {
    return mi.isHidden ? <ToggleHideElement isElementHidden={mi.isHidden} viewOnly={true} /> : null;
  }

  public static isActivityActionOrMilestone = (activityType?: TypeActivity) => {
    return activityType && (activityType === TypeActivity.ACTION || activityType === TypeActivity.MILESTONE);
  }

  public static isActivityAction = (activityType?: TypeActivity) => {
    return activityType && activityType === TypeActivity.ACTION;
  }

  public static isActivityMilestone = (activityType?: TypeActivity) => {
    return activityType && activityType === TypeActivity.MILESTONE;
  }

  public static getMoreUsersLabel = (nbOfUsers: number, maxVisible: number) => {
    return (
      <Label>
        + {nbOfUsers - maxVisible} &nbsp;
        {
          nbOfUsers - maxVisible === 1
            ? <FormattedMessage id="moreUser" defaultMessage="more user"/>
            : <FormattedMessage id="moreUsers" defaultMessage="more users"/>
        }
      </Label>
    );
  }

  /**
   * Get field title display with (or without) the error icon if the field is on error or not
   * @param {JSX.Element} title
   * @param {boolean} isFieldOnErr
   * @param {boolean} isGenericErr
   */
  public static getTitleWithErrIcon = (title: JSX.Element, isFieldOnErr: boolean, isGenericErr = false) => {
    const errIcon = isFieldOnErr && Utils.getErrorMessageWithPopup(isGenericErr ? undefined : ErrType.TEXT_FIELDS);
    return (
      <div className={`title-message ${ isFieldOnErr ? 'error' : ''}`}>
        {title}
        {errIcon}
      </div>
    );
  }

  /**
   * Find the next available code for an activity
   * @param {Milestone[]} activities
   * @param {boolean} shouldRemoveFirstElement
   * @return {number}
   */
  public static findActivityNextCode = (activities: Milestone[], shouldRemoveFirstElement = true) : number => {
    const allActivities = [...activities];
    if (shouldRemoveFirstElement) allActivities.shift();

    let newCode = allActivities.map(act => act.code).findIndex((code: number, index) => code !== index + 1) + 1;
    if (newCode === 0) {
      newCode = allActivities.length + 1;
    }
    return newCode;
  }

  public static saveBase64AsPptx = (pptx: string, filename : string) => {
    const linkSource = `data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,${pptx.replace(new RegExp('"', 'g'), '')}`;
    const link = document.createElement('a');
    link.href = linkSource;
    link.download = `${filename}_${Utils.displayDate(new Date(), false, '-')}.pptx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
