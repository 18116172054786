import * as React from 'react';
import { Route } from 'react-router';
import Frame from 'src/components/frame/frame';
import Checkpoint from 'src/components/checkpoint/checkpoint';
import authStore from 'src/stores/auth.store';
import UserGuide from '../userGuide/userGuide';

class PrivateRoute extends React.Component<any, any> {

  constructor(props: any) {
    super(props);

    this.state = {
      isLoggedIn: false,
    };
  }

  componentDidMount() {
    authStore.addLoginListener(() => {
      this.setState({ isLoggedIn: true });
    });
    authStore.addLogoutListener(() => {
      this.setState({ isLoggedIn: false });
    });
  }

  componentWillUnmount() {
    authStore.removeLoginListener(() => {
    });
    authStore.removeLogoutListener(() => {
    });
  }

  login = () => {
    this.setState({ isLoggedIn: true });
  };

  public render() {
    const isLoggedIn = this.state.isLoggedIn;

    const { ...rest } = this.props;

    return (
      <div>
        {isLoggedIn
          ? (
            <Route
              {...rest}
              render={() => <Frame />}
            />
          )
          : this.props.location.pathname === '/user-guide'
            ? <UserGuide isVisitor={true} />
            : <Checkpoint login={this.login} />
        }
      </div>
    );
  }
}

export default PrivateRoute;
