import * as React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import './toggleHideElement.scss';

interface IProps {
  isElementHidden: boolean;
  viewOnly?: boolean;
  isDisabled?: boolean;

  updateElementState?(): void;
}

class ToggleHideElement extends React.Component<IProps & InjectedIntlProps> {

  public render() {
    const elementHiddenMsg = this.props.intl.formatMessage(
      {
        id: 'elementHidden',
        defaultMessage: 'Element is hidden in external view',
      });

    const elementVisibleMsg = this.props.intl.formatMessage(
      {
        id: 'elementVisible',
        defaultMessage: 'Element is visible in external view',
      });

    const message = this.props.isElementHidden ? elementHiddenMsg : elementVisibleMsg;

    if (this.props.viewOnly) {
      return(
        <div className={`icon-container ${this.props.isDisabled ? 'forbidden-cursor' : ''}`}>
          <Icon
            name={this.props.isElementHidden ? 'lock' : 'lock open'}
            active={this.props.isElementHidden.toString()}
            disabled={this.props.isDisabled}
          />
        </div>
      );
    }
    return (
      <Popup
        inverted={true}
        content={message}
        size="tiny"
        trigger={

          <Button
            icon={true}
            className="lock-btn"
            onClick={this.props.updateElementState}
            disabled={this.props.isDisabled}
          >
            <Icon
              name={this.props.isElementHidden ? 'lock' : 'lock open'}
              className="icon-container-toggle"
              active={this.props.isElementHidden.toString()}
            />
          </Button>
        }
      />
    );
  }
}

export default injectIntl(ToggleHideElement);
