import './community.scss';

import * as React from 'react';
import Img from 'react-image';
import { Loader, Segment } from 'semantic-ui-react';
import UserActions from '../../../../actions/user.action';
import defaultUserAvatar from '../../../../images/default-user.png';
import Role from '../../../../models/role';

interface IProps {
  id: number;
  name: string;
  role: Role;
  gbu: string;
  country: string;
  mail: string;
  hasAvatar: boolean;
}

interface IStates {
  image: string;
}

class CardPeople extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = { image: '' };
  }

  public componentDidMount() {
    if (!!this.props.id && this.props.hasAvatar) {
      UserActions.getUserAvatar(
        this.props.id,
        image => this.setState({ image }),
      );
    }
  }

  public render() {
    return (
      <div id={this.props.id.toString()} className="card-people">
        <Segment className={'segment-card-people'}>
          <div id={'content'}>
            <Img
              className="avatar"
              src={[this.state.image, defaultUserAvatar]}
              loader={<Loader active={true} size="large" />}
            />
            <div id={'info'}>
              <div id={'name'}>
                {this.props.name}
              </div>
              <div id={'role'}>
                {this.props.role.name}
              </div>
              <div id={'info-people'}>
                GBU: {this.props.gbu} / Country: {this.props.country}
              </div>
              <div id={'mail'}>
                {this.props.mail}
              </div>
            </div>
          </div>
        </Segment>
      </div>
    );
  }
}

export default CardPeople;
