import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Menu, Popup, Responsive, Divider } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import Utils from 'src/utils/utils';
import BusinessChallenge from 'src/models/businessChallenge';
import PerformanceInitiative from 'src/models/performanceInitiative';
import PerformancePlanAPI from 'src/api/performancePlan.api';
import PerformancePlanStore from 'src/stores/performancePlan.store';
import ActionTypes from 'src/constants/actionTypes';

interface IRouteProps {
  id: string;
  ppId?: string;
}
interface IProps extends RouteComponentProps<IRouteProps> {
  isOverviewPanelOpen: boolean;
  dataActivity?: BusinessChallenge | PerformanceInitiative;
  togglePanelVisibility(): void;
  ppPublishedDate?: Date;
}

interface IState {
  lastUpdate: Date | undefined;
  publishedDate: Date | undefined;
}

class DatesContainer extends React.Component<IProps & RouteComponentProps, IState> {
  private isMount = false;

  public constructor(props: IProps & RouteComponentProps) {
    super(props);
    this.state = {
      lastUpdate: undefined,
      publishedDate: undefined,
    };
  }

  componentDidMount() {
    this.isMount = true;
    PerformancePlanStore.addListener(ActionTypes.ACTIVITY_PUBLISHED.toString(), this.getPpDates);
    PerformancePlanStore.addListener(ActionTypes.MOST_RECENT_PP_HISTORY_DELETED.toString(), this.getPpDates);
    if (!this.props.dataActivity) {
      this.getPpDates();
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.ppPublishedDate !== this.props.ppPublishedDate) {
      this.setState({
        publishedDate: this.props.ppPublishedDate,
      });
    }
  }

  componentWillUnmount() {
    this.isMount = false;
    PerformancePlanStore.removeListener(ActionTypes.ACTIVITY_PUBLISHED.toString(), this.getPpDates);
  }

  private getPpDates = async () => {
    if (this.isMount) {
      const ppId = this.props.match.params.ppId ? this.props.match.params.ppId : this.props.match.params.id;
      const pp = await PerformancePlanAPI.getPpDates(+ppId);
      this.setState({
        publishedDate: pp.publishedDate,
        lastUpdate: pp.lastUpdate,
      });
    }
  }

  private seeOverviewPanel = (): JSX.Element => {
    return (
      <>
        <Menu.Item
          className={`overview see-overview ${this.props.isOverviewPanelOpen ? 'selected' : ''}`}
          onClick={() => this.props.togglePanelVisibility()}
        >
          {this.props.isOverviewPanelOpen ? <FormattedMessage id="hideOverview" defaultMessage="Hide overview" /> :
          <FormattedMessage id="showOverview" defaultMessage="Show overview" />}
        </Menu.Item>
      </>
    );
  };

  public render() {
    let lastPublication: JSX.Element | null = null;
    let lastUpdate: JSX.Element | null = null;
    let dueDate: JSX.Element | null = null;
    let compactDates: JSX.Element | null = null;
    let responsiveWidth = 1620;

    // For BC And PI
    if (this.props.dataActivity !== undefined) {
      const data = this.props.dataActivity;
      if (data.publishedDate) {
        lastPublication = (
          <Menu.Item className={'last-date'}>
            <FormattedMessage id="publishedAt" defaultMessage="Last publication" />
            {`: ${Utils.displayDate(data.publishedDate)}`}
          </Menu.Item>
        );
      }

      if (data.dueDate) {
        dueDate = (
          <Menu.Item className={'last-date bold'}>
            <FormattedMessage id="dueDate" defaultMessage="Due Date" />:&nbsp;
            {Utils.displayDate(data.dueDate)}
          </Menu.Item>
        );
      }

      lastUpdate = this.seeOverviewPanel();

      compactDates = (
        <>
          {(data.publishedDate || data.dueDate) &&
            <FormattedMessage id="seeDatesDetails" defaultMessage="Dates details">
              {msg => (
                <Popup
                  content={
                    <>
                      <br/>
                      {data.publishedDate
                      && <>
                        <FormattedMessage id="publishedAt" defaultMessage="Last publication"/>:&nbsp;
                        {Utils.displayDate(data.publishedDate)} <br/>
                      </>
                      }

                      {(data.publishedDate && data.dueDate) && <Divider/>}

                      {data.dueDate
                      && <>
                        <FormattedMessage id="dueDate" defaultMessage="Due Date"/>:&nbsp;
                        {Utils.displayDate(data.dueDate)}
                        <br/>
                      </>
                      }
                    </>
                  }
                  key={'last-date'}
                  header={msg}
                  trigger={
                    <Menu.Item className={'last-date'}>
                      <FormattedMessage id="seeDatesDetails" defaultMessage="Dates details"/>(...)
                    </Menu.Item>}
                />)
              }
            </FormattedMessage>
          }
          {this.seeOverviewPanel()}
        </>
      );
    }

    if (!this.props.dataActivity) {
      const data = { ...this.state };
      if (data.publishedDate) {
        lastPublication = (
          <Menu.Item className={'last-date'}>
            <FormattedMessage id="publishedAt" defaultMessage="Last publication" />:&nbsp;
            {Utils.displayDate(data.publishedDate)}
          </Menu.Item>
        );
      }

      lastUpdate = this.seeOverviewPanel();

      compactDates = (
        <FormattedMessage id="seeDatesDetails" defaultMessage="Dates details">
          {msg => (
            <Popup
              content={
                <>
                  {data.publishedDate
                    && <>
                      <br />
                      <FormattedMessage id="publishedAt" defaultMessage="Last publication" />:&nbsp;
                      {Utils.displayDate(data.publishedDate)}
                      <br />
                    </>
                  }

                  {(data.publishedDate && data.lastUpdate) && <Divider />}

                  {data.lastUpdate
                    && <>
                      <FormattedMessage id="updatedAt" defaultMessage="Last Update" />:&nbsp;
                      {Utils.displayDate(data.lastUpdate)}
                    </>
                  }
                </>
              }
              key={'last-date'}
              header={msg}
              trigger={
                <Menu.Item className={'last-date'}>
                  <FormattedMessage id="updatedAt" defaultMessage="Last Update" />:&nbsp;
                {Utils.displayDate(data.lastUpdate as Date)}&nbsp;(...)
              </Menu.Item>}
            />
          )}
        </FormattedMessage>
      );

      responsiveWidth = 1110;
    }

    return (
      <>
        <Responsive
          as={Menu}
          className={'tab-menu-container right'}
          pointing={true}
          secondary={true}
          minWidth={responsiveWidth}
        >
          {lastPublication}
          {dueDate}
          {lastUpdate}
        </Responsive>
        <Responsive
          as={Menu}
          className={'tab-menu-container right'}
          pointing={true}
          secondary={true}
          maxWidth={responsiveWidth - 1}
        >
          {compactDates}
        </Responsive>
      </>
    );
  }
}

export default withRouter(DatesContainer);
