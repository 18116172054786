import './userGuide.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import UserGuideApi from '../../api/userGuide.api';
import Utils from '../../utils/utils';
import { UserGuideError } from '../../constants/errors/user-guide.error';

interface IProps {
  isVisitor: boolean;
}

interface IStates {
  pdfDocument: string | null;
  isPdfLoading: boolean;
}

class UserGuide extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      pdfDocument: null,
      isPdfLoading: true,
    };
  }

  public componentDidMount() {
    UserGuideApi.getUserGuide()
      .then((pdfDocument) => {
        if (pdfDocument) {
          this.setState({ pdfDocument, isPdfLoading: false });
        }
      })
      .catch((err) => {
        if (!err.error || err.error !== UserGuideError.FILE_NOT_FOUND) {
          Utils.toastError();
        }
        this.setState({ isPdfLoading: false });
      });
  }

  public render() {
    if (this.state.isPdfLoading) {
      return Utils.loader();
    }

    return (
      <>
        <div id="user-guide-container" className={this.props.isVisitor ? 'visitor' : ''}>
          {
            this.state.pdfDocument !== null
              ? <iframe
                className="pdfFrame"
                title="pdfViewer"
                src={`${this.state.pdfDocument}#toolbar=1`}
                width="100%"
                height="100%"
              >
                <div className="no-guide">
                  <FormattedMessage
                    id="userGuide.pdfNotSupported"
                    defaultMessage="This browser does not support PDF"
                  />
                </div>
              </iframe>
              : <div className="no-guide">
                <FormattedMessage
                  id="userGuide.noPdfDocument"
                  defaultMessage="No User Guide to display"
                />
              </div>
          }
        </div>
      </>
    );
  }
}

export default UserGuide;
