import 'react-datepicker/dist/react-datepicker.css';

import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import UserSelection from '../../common/form/userSelection/userSelection';
import User from '../../../models/user';
import { PerformanceIndicatorPost } from '../../../models/performanceInitiative';

interface IProps {
  pi: PerformanceIndicatorPost;
  onUpdate: (key: string, value: any) => void;

  setNavigationDisabled(isNavigationDisabled: boolean): void;
}

interface IStates {
  assignedUsers: User[];
}

class PiCreationStep3 extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    this.state = { assignedUsers: [] };
  }

  /**
   * Update the users in the parent component
   * @param users : User[]
   */
  private onSelectedUsersChange = (users: User[]) => {
    this.props.onUpdate('assignedAccounts', users);
  };

  public render(): React.ReactNode {
    return (
      <Form>
        <Form.Group widths="equal">
          <Form.Field id="mainAchivements">
            <FormattedMessage
              id="piCreation.assignUsers"
              defaultMessage="Assign PI leader(s)"
            >
              {msg => (
                <div>
                  <h3>{msg}</h3>
                  <UserSelection
                    defaultValue={this.props.pi.assignedAccounts ? this.props.pi.assignedAccounts : []}
                    onChange={this.onSelectedUsersChange}
                  />
                </div>
              )}
            </FormattedMessage>
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

export default PiCreationStep3;
