import React from 'react';
import './timelineHeader.scss';
import TimelineSelectors from './timelineSelectors/timelineSelectors';
import TimelineViewButtons from './timelineViewButtons/timelineViewButtons';
import { Months, Quarter, TimelineView } from '../../../../../../../constants/timeline';

interface IProps {
  selectedView: TimelineView;
  selectedYear: number;
  selectedMonth: Months;
  selectedQuarter: Quarter;
  setTimelineView: (view: TimelineView) => void;
  setSelectedYear: (year: number) => void;
  setSelectedMonth: (month: Months) => void;
  setSelectedQuarter: (quarter: Quarter) => void;
  setMultiplePeriods: (period: Quarter | Months, year: number) => void;
}

const timelineHeader = (props: IProps) => {
  return (
    <div className="header">
      <TimelineSelectors
        selectedView={props.selectedView}
        selectedYear={props.selectedYear}
        selectedMonth={props.selectedMonth}
        selectedQuarter={props.selectedQuarter}
        setSelectedYear={props.setSelectedYear}
        setSelectedMonth={props.setSelectedMonth}
        setSelectedQuarter={props.setSelectedQuarter}
        setMultiplePeriods={props.setMultiplePeriods}
      />
      <TimelineViewButtons
        selectedView={props.selectedView}
        setTimelineView={props.setTimelineView}
      />
    </div>
  );
};

export default timelineHeader;
