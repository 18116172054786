import * as React from 'react';

interface IProps { }

interface IStates { }

class Geographies extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    return (
      <div id="items-manager">
        <span>Geographies</span>
      </div >
    );
  }
}

export default Geographies;
