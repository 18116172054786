import * as React from 'react';
import { Button, Icon, Modal, Input } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import TypeActivity from 'src/constants/typeActivity';
import BusinessChallenge from 'src/models/businessChallenge';
import PerformancePlanActions from 'src/actions/performancePlan.action';
import NotificationActions from 'src/actions/notification-actions';
import Utils from 'src/utils/utils';
import ActivitiesStore from 'src/stores/activities.store';
import BusinessChallengeActions from 'src/actions/businessChallenge.action';
import PerformanceInitiativeActions from 'src/actions/performanceInitiative.action';
import PerformancePlan from 'src/models/performancePlan';
import PerformanceInitiative from 'src/models/performanceInitiative';
import ModeTypes from 'src/constants/modeTypes';
import ActionTypes from 'src/constants/actionTypes';
import BusinessChallengeError from 'src/constants/errors/business-challenge.error';
import { ToastType } from 'src/components/common/toast/toast';
import PerformancePlanError from '../../../../../constants/errors/performance-plan.error';
import PerformanceInitiativeError from 'src/constants/errors/performance-initiative.error';

interface IProps {
  data: PerformancePlan | BusinessChallenge | PerformanceInitiative;
  mode: ModeTypes;
}

interface IStates {
  isPublishModalOpen: boolean;
  type: TypeActivity;
  name: string;
  isLoading: boolean;
}
const nameMaxLength = 60;

class PublicationButton extends React.Component<IProps, IStates> {

  private isMount = false;

  public constructor(props: IProps) {
    super(props);
    this.state = {
      isPublishModalOpen: false,
      type: ActivitiesStore.getTypeActivity(),
      name: '',
      isLoading: false,
    };
  }

  public componentWillMount() {
    this.isMount = true;
    ActivitiesStore.addListener(ActionTypes.ACTIVITY_SELECTED.toString(), this.setType);
  }

  public componentWillUnmount() {
    this.isMount = false;
    ActivitiesStore.removeListener(ActionTypes.ACTIVITY_SELECTED.toString(), this.setType);
  }

  private setType = () => {
    if (this.isMount) {
      this.setState({ type: ActivitiesStore.getTypeActivity() });
    }
  };

  private handleModalClose = () => {
    this.setState({ isPublishModalOpen: false, name: '' });
  };

  private handlePublish = async () => {
    this.setState({ isLoading: true }, async () => {
      let message;
      try {
        switch (ActivitiesStore.getTypeActivity()) {
          case TypeActivity.PERFORMANCE_PLAN:
            await this.publishPp();
            message = <FormattedMessage id="publishedPpMessage" defaultMessage="The Performance Plan is published!" />;
            break;
          case TypeActivity.BUSINESS_CHALLENGE:
            await this.publishBc();
            message = (
              <FormattedMessage id="publishedBcMessage" defaultMessage="The Business Challenge is published!" />
            );
            break;
          case TypeActivity.PERFORMANCE_INITIATIVE:
            await this.publishPi();
            message = (
              <FormattedMessage
                id="publishedPiMessage"
                defaultMessage="The Performance Initiative is published!"
              />
            );
            break;
        }
        NotificationActions.toast(
          <FormattedMessage id="published" defaultMessage="Published!" />,
          message,
          ToastType.SUCCESS,
        );
        this.handleModalClose();
      } catch (error) {
        this.handlePublishErrors(error);
      }
      this.setState({ isLoading: false });
    });
  };

  private handlePublishErrors = (error) => {
    switch (ActivitiesStore.getTypeActivity()) {
      case TypeActivity.PERFORMANCE_PLAN:
        PerformancePlanError.toastPpError(error.error);
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        BusinessChallengeError.toastBcError(error.error);
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        PerformanceInitiativeError.toastPiError(error.error);
        break;
    }
  }

  private publishPp = () => {
    return PerformancePlanActions.emitPublish(this.props.data.id, this.state.name);
  }

  private publishBc = () => {
    return BusinessChallengeActions.emitGrantPublication(this.props.data.id, this.state.name);
  }

  private publishPi = () => {
    return PerformanceInitiativeActions.emitGrantPublication(this.props.data.id, this.state.name);
  }

  public openPublishModal = () => {
    this.setState({ isPublishModalOpen: true });
  };

  private getPublishButton(): JSX.Element {
    return (
      <Button
        key="publish-button"
        basic={true}
        onClick={this.openPublishModal}
      >
        <Icon name="upload" />
        <FormattedMessage id="publish" defaultMessage="Publish"/>&nbsp;
        {Utils.getTypeName(this.state.type, undefined, true)}
      </Button>
    );
  }

  private onNameChange = (name: string) => {
    this.setState({ name });
  }

  public render() {
    let title;
    let body;
    const addPublicationName = (
      <>
      <FormattedMessage
        id="publishModal.askPublicationName"
        defaultMessage="You can add a name to the publication if you wish to: "
      />
      <FormattedMessage id="publication.name" defaultMessage="Publication name">
        {msg =>
          <Input
            size="small"
            placeholder={msg}
            onChange={(e, { value }) => this.onNameChange(value)}
            maxLength={nameMaxLength}
          />
        }
      </FormattedMessage>
    </>
    );
    const checkRefMessageBcPi = (
      <FormattedMessage
        id="publishModal.bc.pi.body"
        defaultMessage="Have you checked the data sensitivity of all items before publishing? If you are not sure about the data sensitivity, please check Ref.: 87201725-GOV-GRP-EN-005 in Chorus"
      />
    );
    switch (ActivitiesStore.getTypeActivity()) {
      case TypeActivity.PERFORMANCE_PLAN:
        title = <FormattedMessage id="publishModal.pp.title" defaultMessage="Publish the Performance Plan" />;
        body = (
          <>
          <div>
            <FormattedMessage
              id="publishModal.pp.mainDesc"
              defaultMessage="Are you sure you want to publish this Performance Plan?"
            />
          <br />
          <FormattedMessage
            id="publishModal.pp.secondDesc"
            defaultMessage="Doing so will automatically publish the related BC and PI."
          />
          </div>
          {addPublicationName}
        </>
        );
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        title = <FormattedMessage id="publishModal.bc.title" defaultMessage="Publish the Business Challenge" />;
        body = (
          <>
          <div>
            {checkRefMessageBcPi}
          </div>
          {addPublicationName}
          </>
        );
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        title = <FormattedMessage id="publishModal.pi.title" defaultMessage="Publish the Performance Initiative" />;
        body = (
          <>
          <div>
            {checkRefMessageBcPi}
          </div>
          {addPublicationName}
          </>
        );
        break;
    }

    return (
      <>
        {this.getPublishButton()}
        {this.state.isPublishModalOpen &&
          <Modal
            open={this.state.isPublishModalOpen}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            onClose={this.handleModalClose}
          >
            <Modal.Header>
              {title}
            </Modal.Header>
            <Modal.Content>
                {body}
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.handleModalClose}>
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                onClick={this.handlePublish}
                positive={true}
                loading={this.state.isLoading}
                labelPosition="right"
                icon="checkmark"
                content={<FormattedMessage id="publishModal.pp.publish" defaultMessage="Publish" />}
              />
            </Modal.Actions>
          </Modal>
        }
      </>
    );
  }
}

export default PublicationButton;
