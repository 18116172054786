/* Copyright (C) Thales Services, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Thales Services, 2020
 */

export default class Attachment {

  public id: number;
  public title: string;
  public url: string;
}
