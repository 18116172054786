import './info.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import UserStore from 'src/stores/user.store';
import Utils from '../../utils/utils';

interface IProps extends RouteComponentProps {
}

interface IStates {
  changelogContent: string | undefined;
}

class Info extends React.Component<IProps, IStates> {

  public constructor(props: any) {
    super(props);
    this.state = {
      changelogContent: undefined,
    };
  }

  public componentDidMount(): void {
    let content;
    if (UserStore.getLanguage() === 1) {
      content = require('../../CHANGELOG_EN.md');
    } else {
      content = require('../../CHANGELOG_FR.md');
    }

    fetch(content).then((res) => {
      return res.text();
    }).then((text) => {
      this.setState({ changelogContent: text });
    });
  }

  public render() {
    return (
      <div id="info-main-container">
        <div id="info-container">
          <div id="changelog">
            <h1>
              <FormattedMessage
                id="changelog"
                defaultMessage="CHANGELOG"
              />
            </h1>
          </div>
          <div id="details-container">
            {this.state.changelogContent
              ? <ReactMarkdown source={this.state.changelogContent} />
              : Utils.loader()
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Info);
