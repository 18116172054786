import './publicationHistory.scss';

import * as React from 'react';
import Utils from 'src/utils/utils';
import History, { OrderedHistories } from 'src/models/history';
import HistoryAPI from 'src/api/history.api';
import TypeActivity from 'src/constants/typeActivity';
import PerformanceInitiative from 'src/models/performanceInitiative';
import BusinessChallenge from 'src/models/businessChallenge';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import CustomScrollBars from 'src/components/common/customScrollBars/customScrollBars';
import { Accordion, Icon } from 'semantic-ui-react';
import PublicationRow from './publicationRow/publicationRow';
import { HistoryBaseUrl, RoutesBaseUrl } from 'src/constants/routes.enum';
import ActivitiesStore from 'src/stores/activities.store';
import { RightsOnBC, RightsOnPI, RightsOnPP } from 'src/models/rights';
import RightsStore from 'src/stores/rights.store';
import ActionTypes from 'src/constants/actionTypes';

interface IRouteProps {
  id: string;
  ppId: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  typeActivity: TypeActivity | undefined;
  activity: PerformanceInitiative | BusinessChallenge | undefined;
  contentPanelHeight: number;
}

interface IState {
  orderedHistories: OrderedHistories[];
  route: string;
  activityId: number;
  activeIndex: number[];
  loadingData: boolean;
  rightsOnPp: RightsOnPP | undefined;
  rightsOnBc: RightsOnBC | undefined;
  rightsOnPi: RightsOnPI | undefined;
}

class PublicationHistory extends React.Component<IProps & InjectedIntlProps, IState> {

  constructor(props: IProps & InjectedIntlProps) {
    super(props);

    this.state = {
      orderedHistories: [],
      route: '',
      activityId: 0,
      activeIndex: [],
      loadingData: false,
      rightsOnPp: RightsStore.getRightsOnPP(),
      rightsOnBc: RightsStore.getRightsOnBC(),
      rightsOnPi: RightsStore.getRightsOnPI(),
    };
  }

  componentDidMount() {
    this.buildActivityRoutes();
    ActivitiesStore.addListener(ActionTypes.ACTIVITY_PUBLISHED.toString(), this.buildActivityRoutes);
    RightsStore.addListener(ActionTypes.SET_PP_RIGHTS.toString(), this.setRightsOnPp);
    RightsStore.addListener(ActionTypes.SET_BC_RIGHTS.toString(), this.setRightsOnBc);
    RightsStore.addListener(ActionTypes.SET_PI_RIGHTS.toString(), this.setRightsOnPI);
  }

  componentWillUnmount() {
    ActivitiesStore.removeListener(ActionTypes.ACTIVITY_PUBLISHED.toString(), this.buildActivityRoutes);
    RightsStore.removeListener(ActionTypes.SET_PP_RIGHTS.toString(), this.setRightsOnPp);
    RightsStore.removeListener(ActionTypes.SET_BC_RIGHTS.toString(), this.setRightsOnBc);
    RightsStore.removeListener(ActionTypes.SET_PI_RIGHTS.toString(), this.setRightsOnPI);
  }

  componentDidUpdate(prevProps: Readonly<IProps & ReactIntl.InjectedIntlProps>,
                     prevState: Readonly<IState>, snapshot?: any) {
    if (prevProps.activity !== this.props.activity) {
      this.buildActivityRoutes();
    }
  }

  private setRightsOnPp = () => {
    this.setState({
      rightsOnPp: RightsStore.getRightsOnPP(),
    });
  }

  private setRightsOnBc = () => {
    this.setState({
      rightsOnBc: RightsStore.getRightsOnBC(),
    });
  }

  private setRightsOnPI = () => {
    this.setState({
      rightsOnPi: RightsStore.getRightsOnPI(),
    });
  }

  private buildActivityRoutes = () => {
    let route;
    const activityId  = +this.props.match.params.id;
    switch (this.props.typeActivity) {
      case TypeActivity.PERFORMANCE_PLAN:
        route = RoutesBaseUrl.PP;
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        route = RoutesBaseUrl.BC;
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        route = RoutesBaseUrl.PI;
        break;
      default:
        break;
    }
    this.setState(
      {
        route,
        activityId : this.props.activity ? this.props.activity.id : activityId,
        loadingData: true,
      },
      () => {
        this.getPublicationsHistory();
      });
  }

  private getPublicationsHistory = async () => {
    try {
      const histories = await HistoryAPI.getHistories(this.state.route, this.state.activityId);
      this.formatHistories(histories);
    } catch (error) {
      Utils.toastError();
    }
  }

  private formatHistories = (histories: History[]) => {
    const betterHistories = histories.map((el) => {
      return { ...el, year: new Date(el.publishedDate).getFullYear() };
    });
    const GROUP_BY = 'year';
    const orderedHistories = Utils.groupBy(betterHistories, GROUP_BY);
    orderedHistories.reverse();
    this.setState({
      orderedHistories,
      loadingData: false,
      // Open all accordion
      activeIndex: orderedHistories.reduce(
        (acc, history, index) => {
          acc.push(index);
          return acc;
        },
        []),
    });
  }

  /**
   * Get an edited history row to update the global orderedHistories
   * @param {History} history
   */
  private editedHistory = (history: History) => {
    const newOrderedHistories = [...this.state.orderedHistories];
    for (const year of newOrderedHistories) {
      for (const row of year[1]) {
        if (row.id === history.id) {
          row.name = history.name;
        }
      }
    }
    this.setState({
      orderedHistories: newOrderedHistories,
    });
  }

  /**
   * Get a archived history row to remove it from the global orderedHistories
   * @param {History} history
   */
  private archiveHistory = (history: History) => {
    const newOrderedHistories = [...this.state.orderedHistories];
    for (const year of newOrderedHistories) {
      const index = year[1].findIndex(hist => hist.id === history.id);
      if (index > -1) {
        year[1].splice(index, 1);
      }
      if (year[1].length === 0) {
        const yearIndex = newOrderedHistories.findIndex(oHistory => oHistory[0] === year[0]);
        newOrderedHistories.splice(yearIndex, 1);
      }
    }
    this.setState({
      orderedHistories: newOrderedHistories,
    });
  }

  private isSameActivityRoute = () => {
    return (this.props.activity?.id.toString() === this.props.match.params.id)
    || (!this.props.activity && Utils.isActivityPp(this.props.typeActivity));
  }

  private handleHistoryNavigation = (el: History) => {
    let route;
    switch (this.props.typeActivity) {
      case TypeActivity.PERFORMANCE_PLAN:
        route = HistoryBaseUrl.PP;
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        route = HistoryBaseUrl.BC;
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        route = HistoryBaseUrl.PI;
        break;
    }
    this.props.history.push(`/${route}/${this.props.activity?.id ?? +this.props.match.params.id}/${el.id}`);
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    this.setState(prevState => ({
      activeIndex: !prevState.activeIndex.includes(index) ?
        [...prevState.activeIndex, index] : prevState.activeIndex.filter(idx => idx !== index),
    }));
  }

  public render() {
    return (
    <div id="publication" style={{ height: `${this.props.contentPanelHeight}px` }}>
      <CustomScrollBars>
        {this.state.loadingData && Utils.loader()}
        {this.state.orderedHistories.length === 0 && !this.state.loadingData &&
        <div className="no-publication"><FormattedMessage id="noPublication" defaultMessage="No publication" /></div>}
        {this.state.orderedHistories.length > 0 && this.state.orderedHistories.map((historyGroup, index) => {
          return (
            <Accordion key={`history-list${historyGroup[0]}`}>
              <Accordion.Title
                active={this.state.activeIndex.includes(index)}
                index={index}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                {historyGroup[0]}
              </Accordion.Title>
              <Accordion.Content active={this.state.activeIndex.includes(index)}>
                <ul>
                  {historyGroup[1].map((history: History) => {
                    return (
                      <li className="parent-segment-selector" key={`history-list${history.id}`}>
                        <PublicationRow
                          publication={history}
                          handleHistoryNavigation={this.handleHistoryNavigation}
                          typeActivity={this.props.typeActivity}
                          activityId={this.state.activityId}
                          isSameActivityRoute={this.isSameActivityRoute}
                          editedHistory={this.editedHistory}
                          archiveHistory={this.archiveHistory}
                          rightsOnPp={this.state.rightsOnPp}
                          rightsOnBc={this.state.rightsOnBc}
                          rightsOnPi={this.state.rightsOnPi}
                        />
                      </li>);
                  })}
                </ul>
              </Accordion.Content>
            </Accordion>
          );
        })}
      </CustomScrollBars>
    </div>);
  }
}

export default withRouter(injectIntl(PublicationHistory));
