import Utils from '../../../../utils/utils';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import SegmentEditButtons
  from '../../../activitiesBoard/containerActivities/common/segmentEditButtons/segmentEditButtons';
import * as React from 'react';
import ModeTypes from '../../../../constants/modeTypes';
import { RightsOnAT } from '../../../../models/rights';
import { SegmentType } from '../../../../models/segmentsMode';
import { IAtFormValid } from '../../../../constants/formValid';

interface IProps {
  mode: ModeTypes;
  rights: RightsOnAT;
  isClosed: boolean;
  atId: number | null;
  isFormValid: IAtFormValid;
  segmentEditionButtonsWidth: number;
  isSaveOrCloseDisabled: boolean;

  toggleCloseAction(): void;
  changeMode(mode: ModeTypes): void;
  cancelAction(): void;
  toggleToEdit?(): void;
  updateAction(): Promise<void>;
  removeAction(): void;
  closeAction(): void;
}

class ActionEditionButtons extends React.Component<IProps> {
  render() {
    const segmentType = `${SegmentType.AT_ACTION}-${this.props.atId}`;
    const isSaveDisabled = !this.props.isFormValid.isNameValid || !this.props.isFormValid.isCodeValid
      || !this.props.isFormValid.isTargetDateValid || this.props.isSaveOrCloseDisabled;
    if (Utils.isOnViewMode(this.props.mode)) {
      return (
        <div className="view-container">
          {this.props.rights.canEdit()
            ? this.props.isClosed
              ? <Popup
                inverted={true}
                content={<FormattedMessage id="at.uncloseTooltip" defaultMessage="Click to unclose action"/>}
                size="tiny"
                position="left center"
                trigger={
                  <div className="icon-container" onClick={this.props.toggleCloseAction}>
                    <Icon className="icon unclose-icon" name="redo alternate"/>
                  </div>}
              />
              : <SegmentEditButtons
                mode={this.props.mode}
                segmentType={segmentType}
                changeMode={this.props.changeMode}
                onCancel={this.props.cancelAction}
                onSave={() => Promise.resolve()}
                onEdit={this.props.toggleToEdit}
              />
            : null
          }
        </div>
      );
    }
    return (
      <div className="edit-buttons-container">
        <>
          <SegmentEditButtons
            mode={this.props.mode}
            segmentType={segmentType}
            changeMode={this.props.changeMode}
            onCancel={this.props.cancelAction}
            onSave={this.props.updateAction}
            deletable={this.props.atId !== null}
            onDelete={this.props.removeAction}
            isSaveDisabled={isSaveDisabled}
          />
          {this.props.atId !== null &&
            <FormattedMessage id="at.closeTooltip" defaultMessage="Doing so will make the action uneditable">
              {msg =>
                <Popup
                  inverted={true}
                  content={msg}
                  size="tiny"
                  position="top right"
                  trigger={
                    <Button
                      className="close-action icon-container"
                      onClick={this.props.closeAction}
                      disabled={this.props.isSaveOrCloseDisabled}
                      color="instagram"
                      style={{ marginRight: `${this.props.segmentEditionButtonsWidth}px` }}
                    >
                      <FormattedMessage id="at.closeAction" defaultMessage="Close Action"/>
                    </Button>
                  }
                />
              }
            </FormattedMessage>
          }
        </>
      </div>
    );
  }
}

export default ActionEditionButtons;
