import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import NotificationActions from '../../actions/notification-actions';
import { ToastType } from '../../components/common/toast/toast';
import { CommonErrors } from './common.error';

export enum PpErrors {
  PP_NOT_FOUND = 'Performance Plan not found',
  PP_NEVER_BEEN_PUBLISHED = 'Performance Plan has never been published',
  PP_WRONG_HISTORY = 'This history does not belong to this Performance Plan',
  PP_IS_DRAFT = 'Performance Plan is a draft',
  PP_WITH_SAME_NAME_YEAR_ALREADY_EXISTS = 'A Performance Plan with the same name/year combination already exists',
  PUBLICATION_CONDITIONS_NOT_MET = 'There is no need to publish, because it was already published today and has not been edited since',
}

class PerformancePlanError {

  public static toastPpError(error: string) {
    let message: JSX.Element;
    let toastType = ToastType.ERROR;
    let title = <FormattedMessage id="errorOccurred" defaultMessage="Error"/>;

    switch (error) {
      case PpErrors.PP_IS_DRAFT:
        message = (
          <FormattedMessage
            id="ppPublicationMustNotBeDraft"
            defaultMessage="A Performance Plan cannot be published if it is a draft"
          />
        );
        break;
      case CommonErrors.PUBLICATION_CONDITIONS_NOT_MET:
        title = <FormattedMessage id="informationPublicationPlanError" defaultMessage="Info"/>;
        message = (
          <FormattedMessage
            id="notPublishedMessage"
            defaultMessage="The activity could not be published because it was already published today and has not been modified since (neither it nor the activities it contains)"
          />
        );
        toastType = ToastType.INFO;
        break;
      default:
        message = (
          <FormattedMessage
            id="errorOccurredMessage"
            defaultMessage="An error occurred, please try again later"
          />
        );
        break;
    }

    NotificationActions.toast(title, message, toastType);
  }
}

export default PerformancePlanError;
