import React from 'react';
import Utils from '../../../../utils/utils';
import { StatusType } from '../../../../constants/statusEnums';
import ModeTypes from '../../../../constants/modeTypes';
import { ActionEdit } from '../../../../models/action';
import './actionStatus.scss';

interface IProps {
  action: ActionEdit;
  mode: ModeTypes;
  isHorizontal: boolean;
  setStatus(status: StatusType, action: ActionEdit);
}

interface IStates {
  isStatusEditing: boolean;
}

class ActionStatus extends React.Component<IProps, IStates> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      isStatusEditing: false,
    };
  }

  componentDidUpdate(prevProps:Readonly<IProps>, prevState:Readonly<IStates>, snapshot?:any) {
    if (Utils.isOnEditOrCancelMode(prevProps.mode) && this.state.isStatusEditing
      && Utils.isOnViewMode(this.props.mode)) {
      this.setState({ isStatusEditing: false });
    }
  }

  /**
   * Opens the status dropdown at the specified index
   */
  public toggleStatusDropdown = () => {
    if (Utils.isOnEditMode(this.props.mode)) {
      this.setState(state => ({ isStatusEditing: !state.isStatusEditing }));
    }
  };

  public onStatusClick = (status: StatusType) => {
    this.props.setStatus(status, this.props.action);
  }

  render() {
    const statusColor = Utils.getColorFromStatus(this.props.action.status);
    return (
      <div className="status-container">
      <div
        className={
          `status
                ${Utils.isOnEditMode(this.props.mode) ? 'editing' : null}
                ${this.props.isHorizontal ? 'horizontal' : null}
                ${this.state.isStatusEditing ? 'open' : null}
                ${this.props.action.isClosed ? 'closed' : statusColor}`}
        onClick={() => this.toggleStatusDropdown()}
      >
        {this.state.isStatusEditing
          && <div className="status-dropdown">
            {statusColor !== 'red' &&
              <div className="status red" onClick={() => this.onStatusClick(StatusType.CRITICAL)}/>}
            {statusColor !== 'orange' &&
              <div className="status orange" onClick={() => this.onStatusClick(StatusType.AT_RISK)}/>}
            {statusColor !== 'green' &&
              <div className="status green" onClick={() => this.onStatusClick(StatusType.CONFIDENT)}/>}
          </div>
        }
      </div>
    </div>
    );
  }

}

export default ActionStatus;
