import './changePassword.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import ReactSVG from 'react-svg';
import { Button, Form, Header, Icon, Input, Message } from 'semantic-ui-react';

import logo from '../../images/logo.svg';
import User from '../../models/user';
import AuthService from '../../services/auth.service';
import AuthStore from '../../stores/auth.store';

interface IState {
  password: string;
  passwordConfirm: string;
  errorMessage: JSX.Element | undefined;
}

class ChangePassword extends React.Component<RouteComponentProps, IState | any> {

  constructor(props: any) {
    super(props);
    this.state = {
      password: '',
      passwordConfirm: '',
      errorMessage: undefined,
    };
  }

  public handleInputChange = (e: any, { name, value }: any) => this.setState({ [name]: value });

  public login = () => {
    AuthService.changePassword(this.state.password)
      .then((user: User | any) => {
        AuthStore.setConnectedUser(user);
        this.props.history.push('/activities-board/taskboard');
      })
      .catch(() => {
        this.setState({
          errorMessage:
            (
              <Message
                visible={true}
                error={true}
                header="Session is invalid"
                content="Your session is invalid. Please try to reconnect in another tab."
              />
            ),
        });
      });
  };

  public render() {
    const isPasswordMatching = !(this.state.password !== '' &&
      this.state.passwordConfirm !== '' &&
      this.state.password !== this.state.passwordConfirm);
    const isPasswordValid = this.state.password === '' ||
      new RegExp('^(?=.*[\\d])' +
        '(?=.*[A-Z])' +
        '(?=.*[a-z])' +
        '(?=.*[ !"#$%&\'()*+,\\-./:;<=>?@[\\\\\\]^_`{|}~])[\\w !"#$%&\'()*+,\\-./:;<=>?@[\\\\\\]^_`{|}~]{8,20}$')
        .test(this.state.password);
    const canSubmit = this.state.password !== '' &&
      this.state.passwordConfirm !== '' && isPasswordMatching && isPasswordValid;

    return (
      <div id="login">
        <div id="container-login">
          <div id="container-login-form">
            <Header as="h2" icon={true} textAlign="center" id="login-title">
              <ReactSVG
                id="logoAB-login"
                src={logo}
              />
              <Header.Content>
                <h2><FormattedMessage
                  id="changePassword.message"
                  defaultMessage="Please change your password"
                /></h2>
              </Header.Content>
            </Header>
            <Form>
              <Form.Field id="login-password">
                <div>
                  <label><FormattedMessage
                    id="changePassword.password"
                    defaultMessage="Password"
                  /></label>
                  <Input
                    name="password"
                    type="password"
                    iconPosition="left"
                    placeholder="Password"
                    autoFocus={true}
                    value={this.state.password}
                    onChange={this.handleInputChange}
                  >
                    <Icon name="lock" />
                    <input />
                  </Input>
                </div>
              </Form.Field>

              <Form.Field id="login-password">
                <div>
                  <label><FormattedMessage
                    id="changePassword.passwordConfirm"
                    defaultMessage="Confirm your password"
                  /></label>
                  <Input
                    name="passwordConfirm"
                    type="password"
                    iconPosition="left"
                    placeholder="Password"
                    value={this.state.passwordConfirm}
                    onChange={this.handleInputChange}
                  >
                    <Icon name="lock" />
                    <input />
                  </Input>
                </div>
              </Form.Field>
              {!isPasswordMatching ?
                <Message
                  visible={true}
                  error={true}
                  header="Passwords don't match"
                  content="Please make sure both passwords are the same."
                /> : null
              }
              {!isPasswordValid ?
                <Message
                  visible={true}
                  error={true}
                  header="Password is invalid"
                  content="Your password must be between 8 and 20 characters long, have a lowercase, an uppercase,
                  a number and a special character."
                /> : null
              }
              {this.state.errorMessage}
              <div id="container-button-login">
                <FormattedMessage
                  id="changePassword.submit"
                  defaultMessage="Submit"
                >
                  {msg => <Button
                    id="login-button"
                    color="blue"
                    content={msg}
                    icon="check"
                    labelPosition="right"
                    onClick={this.login}
                    disabled={!canSubmit}
                  />
                  }
                </FormattedMessage>
              </div>
            </Form>

          </div>
          <div id="copyright">
            <p>THALES © <br /> Made with ♥ at Vélizy.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ChangePassword);
