import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Input, Popup, Segment } from 'semantic-ui-react';
import ModeTypes from 'src/constants/modeTypes';
import PerformancePlan from 'src/models/performancePlan';
import TitleTaskboardActions from '../../../../../../actions/titleTaskboard.actions';
import Utils from '../../../../../../utils/utils';
import PpGbuGeoEdition, {
  BusinessLocationsAttr,
  BusinessLocationsKeys,
  IBusinessLocationsValues,
  IRenderBusinessLocations,
} from '../../../../../common/form/ppGbuGeo/ppGbuGeo';
import IPpBusinessLocations from '../../../../../../models/performancePlanBusinessLocations';
import LinkedActivities from '../../../linkedActivities/linkedActivities';
import PerformancePlanAPI from 'src/api/performancePlan.api';
import SegmentEditButtons from '../../../common/segmentEditButtons/segmentEditButtons';
import NotificationActions from 'src/actions/notification-actions';
import { ToastType } from 'src/components/common/toast/toast';
import LastUpdateHighlight from '../../../common/overviewPanel/panelContent/tabs/lastUpdatedInformations/lastUpdateHighlight';
import { SegmentType } from 'src/models/segmentsMode';
import ActivitiesActions from 'src/actions/activities.action';

export interface IPpFormValid {
  isYearValid: boolean;
  areAttachmentsValid: boolean;
}

interface IRouteProps {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  canEdit: boolean;
}

interface IStates {
  data: PerformancePlan | undefined;
  oldData: PerformancePlan | undefined;
  geoGbuOptions: IPpBusinessLocations | undefined;
  isFormValid: IPpFormValid;
  mode: ModeTypes;
}

class About extends React.Component<IProps, IStates> {
  private isMount = false;

  constructor(props: IProps) {
    super(props);
    this.state = {
      data: undefined,
      oldData: undefined,
      geoGbuOptions: undefined,
      isFormValid: { isYearValid: true, areAttachmentsValid: true },
      mode: ModeTypes.MODE_VIEW,
    };
  }

  //region REACT LIFE-CYCLE
  public componentDidMount() {
    this.isMount = true;
    LastUpdateHighlight.highlightParam();
    this.getAboutData();
    this.getEntitiesLocations();
  }

  public componentWillUnmount() {
    this.isMount = false;
  }

  //endregion

  private getAboutData = async () => {
    if (!this.isMount) {
      return;
    }
    try {
      const pp = await PerformancePlanAPI.getPerformancePlanAbout(+this.props.match.params.id);
      this.setState({
        data: pp,
        oldData: pp,
      });
    } catch (error) {
      Utils.toastError();
    }
  }

  private getEntitiesLocations = async () => {
    if (!this.isMount) {
      return;
    }
    try {
      const entitiesLocations = await PerformancePlanAPI.getPpEntitiesLocations();
      this.setState({
        geoGbuOptions: entitiesLocations,
      });
    } catch (error) {
      Utils.toastError();
    }
  }

  //region EDITION HANDLERS
  /**
   * handle the year changes
   * @param year : string
   */
  private handleChangeYear = (year: string) => {
    const isFormValid = { ...this.state.isFormValid };
    isFormValid.isYearValid = Utils.testYear(year);
    this.setState(prevState => ({
      isFormValid,
      data: {
        ...prevState.data as PerformancePlan,
        year,
      },
    }));
    TitleTaskboardActions.setIsSaveDisabled(Object.values(isFormValid).some(el => !el));
  };

  private onGbuGeoChange = (values: IBusinessLocationsValues) => {
    Object.entries(values).forEach((keyValue) => {
      let attribute;
      switch (keyValue[0] as BusinessLocationsAttr) {
        case BusinessLocationsAttr.GBU:
          attribute = 'gbu';
          break;
        case BusinessLocationsAttr.BUSINESS_LINE:
          attribute = 'businessLine';
          break;
        case BusinessLocationsAttr.SEGMENT:
          attribute = 'segment';
          break;
        case BusinessLocationsAttr.GEOGRAPHY:
          attribute = 'geography';
          break;
        case BusinessLocationsAttr.COUNTRY:
          attribute = 'country';
          break;
        case BusinessLocationsAttr.SUBCOUNTRY:
          attribute = 'subcountry';
          break;
      }
      if (this.state.data && this.state.data[attribute].id !== keyValue[1]) {
        this.setState(prevState => ({
          data: {
            ...prevState.data as PerformancePlan,
            [attribute]: {
              id: keyValue[1],
            },
          },
        }));
      }
    });
  };
  //endregion

  //region RENDER SUB-METHODS
  private findBusinessLocationsNameById = (key: string, id: number): string | JSX.Element => {
    if (id === 1) return <FormattedMessage id="noValue" defaultMessage="None" />;

    if (!!this.state.geoGbuOptions) {
      const geoGbuList = this.state.geoGbuOptions[key];
      if (!!geoGbuList) {
        const index = geoGbuList.findIndex(el => el.id === id);
        if (index !== -1) return geoGbuList[index].name;
      }
    }

    return '';
  };

  private renderGeoGbuViewElement = (key: BusinessLocationsKeys): JSX.Element => {
    if (!this.state.data) {
      return <></>;
    }
    let title: JSX.Element;
    let id: number;
    switch (key) {
      case BusinessLocationsKeys.GBU:
        title = <FormattedMessage id="pp.info.gbu" defaultMessage="GBU" />;
        id = this.state.data.gbu.id;
        break;
      case BusinessLocationsKeys.BUSINESS_LINE:
        title = <FormattedMessage id="pp.info.bl" defaultMessage="Business Line" />;
        id = this.state.data.businessLine.id;
        break;
      case BusinessLocationsKeys.SEGMENT:
        title = <FormattedMessage id="pp.info.segment" defaultMessage="Segment" />;
        id = this.state.data.segment.id;
        break;
      case BusinessLocationsKeys.GEOGRAPHY:
        title = <FormattedMessage id="pp.info.geo" defaultMessage="Geography" />;
        id = this.state.data.geography.id;
        break;
      case BusinessLocationsKeys.COUNTRY:
        title = <FormattedMessage id="country" defaultMessage="Country" />;
        id = this.state.data.country.id;
        break;
      case BusinessLocationsKeys.SUBCOUNTRY:
        title = <FormattedMessage id="pp.info.subCountry" defaultMessage="Subcountry" />;
        id = this.state.data.subcountry.id;
        break;
    }

    return (
      <div className="info-element">
        <div className="grey-title">
          {title}
        </div>
        {this.findBusinessLocationsNameById(key, id)}
      </div>
    );
  };

  public renderGeoGbuEdition(elements: IRenderBusinessLocations): JSX.Element {
    return (
      <>
        <div className="column">
          {elements.gbu}
          {elements.businessLine}
          {elements.segment}
        </div>
        <div className="column">
          {elements.geography}
          {elements.country}
          {elements.subcountry}
        </div>
      </>
    );
  }
  //endregion

  private edit = () => {
    this.setState({
      mode: ModeTypes.MODE_EDITION,
    });
  }

  private save = async () => {
    if (!this.state.data) {
      return;
    }
    const selectedValues: IBusinessLocationsValues = {
      gbuId: this.state.data.gbu.id,
      businessLineId: this.state.data.businessLine.id,
      segmentId: this.state.data.segment.id,
      geographyId: this.state.data.geography.id,
      countryId: this.state.data.country.id,
      subcountryId: this.state.data.subcountry.id,
      year: this.state.data.year,
    };
    try {
      await PerformancePlanAPI.putPerformancePlanAbout(+this.props.match.params.id, selectedValues);
      this.successSavedToast();
      const editedData = { ...this.state.data };
      this.setState({
        mode: ModeTypes.MODE_VIEW,
        oldData: editedData,
      });
      ActivitiesActions.emitUpdatedLastUpdate();
    } catch (error) {
      Utils.toastError();
    }
  }

  private successSavedToast = () => {
    const message = (
      <FormattedMessage
        id="successPISave"
        defaultMessage="The Performance Initiative has been successfully saved"
      />
    );
    NotificationActions.toast(
      <FormattedMessage id="saved" defaultMessage="Saved!" />,
      message,
      ToastType.SUCCESS,
    );
  }

  private cancel = () => {
    const oldData = { ...this.state.oldData } as PerformancePlan;
    this.setState({ data: oldData });
  }

  private changeMode = (mode: ModeTypes) => {
    this.setState({ mode });
  }

  public render() {
    if (!this.state.data) {
      return Utils.loader();
    }

    const selectedValues: IBusinessLocationsValues = {
      gbuId: this.state.data.gbu.id,
      businessLineId: this.state.data.businessLine.id,
      segmentId: this.state.data.segment.id,
      geographyId: this.state.data.geography.id,
      countryId: this.state.data.country.id,
      subcountryId: this.state.data.subcountry.id,
    };

    return (
      <>
        {this.state.data && <Segment id={LastUpdateHighlight.getSegmentId(SegmentType.PP_ABOUT)}>
        {this.props.canEdit && <SegmentEditButtons
          segmentType={SegmentType.PP_ABOUT}
          mode={this.state.mode}
          onEdit={this.edit}
          onSave={this.save}
          onCancel={this.cancel}
          changeMode={this.changeMode}
        />}
        <div className="title-element">
          <FormattedMessage id="aboutPP" defaultMessage="About the Performance Plan" />
        </div>
        <div className="content-element">
          {Utils.isOnViewOrDeletionMode(this.state.mode)
            ? <>
              <div className="column">
                {this.renderGeoGbuViewElement(BusinessLocationsKeys.GBU)}
                {this.renderGeoGbuViewElement(BusinessLocationsKeys.BUSINESS_LINE)}
                {this.renderGeoGbuViewElement(BusinessLocationsKeys.SEGMENT)}
              </div>

              <div className="column">
                {this.renderGeoGbuViewElement(BusinessLocationsKeys.GEOGRAPHY)}
                {this.renderGeoGbuViewElement(BusinessLocationsKeys.COUNTRY)}
                {this.renderGeoGbuViewElement(BusinessLocationsKeys.SUBCOUNTRY)}
              </div>
            </>
            : <PpGbuGeoEdition
              selectedValues={selectedValues}
              onChange={this.onGbuGeoChange}
              renderGeoGbu={this.renderGeoGbuEdition}
            />
          }
        </div>

        <div className="content-element year-content-element">
          <div className="info-element">
            <div className="grey-title">
              <FormattedMessage id="year" defaultMessage="Year" />
            </div>
            {Utils.isOnViewOrDeletionMode(this.state.mode)
              ? this.state.data.year
              : <div>
                <FormattedMessage id="pp.creation.notAYear" defaultMessage="Please enter a valid year">
                  {msg =>
                    <Popup
                      inverted={true}
                      content={msg}
                      open={!this.state.isFormValid.isYearValid}
                      size="tiny"
                      position="right center"
                      trigger={
                        <Input
                          pattern="[0-9]*"
                          onChange={(event: any, { value }) => event.target.validity.valid
                            ? this.handleChangeYear(value)
                            : null}
                          value={this.state.data?.year}
                          error={!this.state.isFormValid.isYearValid}
                        />
                      }
                    />
                  }
                </FormattedMessage>
              </div>
            }
          </div>
        </div>
        <LinkedActivities ppId={this.state.data.id} />
      </Segment>}
      </>
    );
  }
}

export default withRouter(About);
