import './activityLink.scss';

import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import Utils from '../../utils/utils';
import CustomScrollBars from '../common/customScrollBars/customScrollBars';
import BusinessChallenge from '../../models/businessChallenge';

interface IProps {
  businessChallenges: BusinessChallenge[] | undefined;
  selectedBc: number;
  selectBc(selectedPp: number): void;
}

export default class ActivityBcSelection extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    if (this.props.businessChallenges === undefined) {
      return Utils.loader();
    }

    if (this.props.businessChallenges.length === 0) {
      return Utils.empty(<FormattedMessage id="noBC" defaultMessage="No BC was found for this Performance Plan" />);
    }

    return (
      <div id="activity-link-step4" className="step-container">
        <CustomScrollBars>
            {this.props.businessChallenges.map(bc =>
              <div key={`bc${bc.id}`} className="tile-container">
                <div
                  className={`tile ${this.props.selectedBc === bc.id ? 'selected' : ''}`}
                  onClick={() => this.props.selectBc(bc.id)}
                >
                  {this.props.selectedBc === bc.id && <Icon name="check" />} {Utils.leadingZero(bc.code)} - {bc.name}
                </div>
              </div>,
            )}
        </CustomScrollBars>
      </div>
    );
  }
}
