import HttpClient from '../utils/httpClient';
import { LoadTestScenario } from '../models/loadTestsResults';
import moment from 'moment';

export default class LoadTestResultsAPI {

  /**
   * GET : /api/admin/load-tests/stats
   * Get the last load tests results
   * @returns Promise<LoadTestScenario[]>
   */
  public static getLoadTestsResults(date: Date): Promise<LoadTestScenario[]> {
    return HttpClient.get<LoadTestScenario[]>(`admin/load-tests/stats${HttpClient.formatUrlQueryParams({ date: moment(date).format('YYYY-MM-DD') })}`);
  }

  /**
   * GET : /api/admin/load-tests/list
   * Get the dates of available load tests
   * @returns Promise<Date[]>
   */
  public static getLoadTestsDates(): Promise<Date[]> {
    return HttpClient.get<Date[]>('admin/load-tests/list');
  }
}
