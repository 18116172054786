import { TimelineView } from '../../../../../../../../../../../constants/timeline';
import * as React from 'react';
import { ShapedIcon } from '../../../../../../../../../../../constants/piTimeline';
import './iconsDisplay.scss';
import IconDisplay from './iconDisplay/iconDisplay';
import Utils from '../../../../../../../../../../../utils/utils';

interface IProps {
  selectedView: TimelineView;
  icons: ShapedIcon[];
  monthId: number;
  year: number;
  elementId: number;
  isAction: boolean;
}

const iconsDisplay = (props: IProps) => {
  /**
   * Generates the display of an icon according to its type
   * @return {JSX.Element}
   * @param icon
   */
  const displayIcon = (icon: ShapedIcon) => {
    return (
     <IconDisplay
       key={Utils.generateRandomKey()}
       selectedView={props.selectedView}
       icon={icon}
       monthId={props.monthId}
       year={props.year}
       elementId={props.elementId}
       isAction={props.isAction}
     />
    );
  };

  /**
   * Orders icons and prepares them for display
   * @return {JSX.Element}
   */
  const displayMultipleIconsOnSameCell = () => {
    if (props.selectedView === TimelineView.MONTH) {
      return (
        <React.Fragment key={Utils.generateRandomKey()}>
          {props.icons.map(icon => displayIcon(icon))}
        </React.Fragment>
      );
    }
    const orderedIcons = props.icons.sort((a, b) => a.day - b.day);
    return (
      <React.Fragment key={Utils.generateRandomKey()}>
        {orderedIcons.map(icon => displayIcon(icon))}
      </React.Fragment>
    );
  };

  return displayMultipleIconsOnSameCell();
};
export default iconsDisplay;
