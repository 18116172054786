import './metricRow.scss';

import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Button, Icon, Input, Popup, Segment, Table } from 'semantic-ui-react';

import ModeTypes from '../../../../../../../constants/modeTypes';
import ToggleHideElement from '../../../../toggleHideElement/toggleHideElement';
import Utils from '../../../../../../../utils/utils';
import MetricValuesModal from './metricValues/metricValuesModal';
import Metric, { MetricValueGet, MostRecentMetricValueDto } from '../../../../../../../models/metric';
import MetricValueEdit from './metricValues/metricValueEdit';
import TypeActivity, { MetricMilestone } from '../../../../../../../constants/typeActivity';
import BusinessChallengeStore from '../../../../../../../stores/businessChallenge.store';
import ActionTypes from '../../../../../../../constants/actionTypes';
import PerformanceInitiativeStore from '../../../../../../../stores/performanceInitiative.store';
import { ComparisonType } from '../../../../../../../constants/comparisonType';
import Metrics from '../../metrics';
import ImportedLabel from '../../../shared/importedLabel';
import { FieldType, FieldValues } from '../../../metricsContainer';
import MetricsUtils from 'src/utils/metricsUtils';

interface IProps extends InjectedIntlProps {
  metric: Metric;
  index: number;
  mode: ModeTypes;
  selectedMetricIndex: number;
  forActivityCreation: boolean;
  type: TypeActivity;
  handleRowClick(index: number): void;
  handleChangeMetric(event, fieldValues: FieldValues): void;
  removeMetric(index: number): void;
  updateMetricValue(metricValue: MetricValueGet, mIndex: number | null, mvIndex: number | null, callback?: () => void);
  removeMetricValue(mvIndex: number, mIndex: number);
}

interface IStates {
  showPreviousValuesModal: boolean;
  metricValueIndex: number | null;
  currentMetricValueIndex: number;
  showComparisonPopup: boolean;
}

class MetricRow extends React.Component<IProps, IStates> {

  private isMount = false;
  private comparisonPopupRef = React.createRef<HTMLDivElement>();

  private comparisonOptions = [
    {
      text: <FormattedMessage id="comparison.noComparison" defaultMessage="No comparison" />,
      value: ComparisonType.NONE,
    },
    {
      text: <FormattedMessage id="comparison.up" defaultMessage="Increasing" />,
      value: ComparisonType.UP,
    },
    {
      text: <FormattedMessage id="comparison.down" defaultMessage="Decreasing" />,
      value: ComparisonType.DOWN,
    },
    {
      text: <FormattedMessage id="comparison.equal" defaultMessage="Equal" />,
      value: ComparisonType.EQUAL,
    },
  ];

  //region REACT LIFECYCLE METHODS
  constructor(props) {
    super(props);
    this.state = {
      showPreviousValuesModal: false,
      metricValueIndex: null,
      currentMetricValueIndex: -1,
      showComparisonPopup: false,
    };
  }

  componentDidMount() {
    this.isMount = true;
    switch (this.props.type) {
      case TypeActivity.BUSINESS_CHALLENGE:
        BusinessChallengeStore
          .addListener(ActionTypes.BUSINESS_CHALLENGE_SAVED.toString(), this.resetIndexes);
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        PerformanceInitiativeStore
          .addListener(ActionTypes.PERFORMANCE_INITIATIVE_SAVED.toString(), this.resetIndexes);
        break;
    }

    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    this.isMount = false;
    switch (this.props.type) {
      case TypeActivity.BUSINESS_CHALLENGE:
        BusinessChallengeStore
          .removeListener(ActionTypes.BUSINESS_CHALLENGE_SAVED.toString(), this.resetIndexes);
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        PerformanceInitiativeStore
          .removeListener(ActionTypes.PERFORMANCE_INITIATIVE_SAVED.toString(), this.resetIndexes);
        break;
    }

    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  //endregion

  //region LISTENER RELATED METHODS
  resetIndexes = () => {
    if (this.isMount) {
      this.setState({
        currentMetricValueIndex: -1,
        metricValueIndex: null,
      });
    }
  };
  //endregion

  private handleClickOutside = (event: any) => {
    if (this.comparisonPopupRef
      && this.comparisonPopupRef.current
      && !this.comparisonPopupRef.current.contains(event.target)
    ) {
      this.setState({ showComparisonPopup: false });
    }
  };

  private toggleShowPreviousValuesModal = () => {
    this.setState(state => ({ showPreviousValuesModal: !state.showPreviousValuesModal }));
  };

  private isMetricSelected = (): boolean => {
    return this.props.selectedMetricIndex === this.props.index;
  }

  public areMostRecentValuesNull = (values: MostRecentMetricValueDto): boolean => {
    return values.current === null && values.target === null && values.updateDate === null;
  }

  public updateMetricValue = (updatedMv: MetricValueGet, mIndex: number, mvIndex: number, newMv = false) => {
    updatedMv.metricId = this.props.metric.id;
    if (newMv) {
      this.props.updateMetricValue(updatedMv, mIndex, mvIndex, this.setNewlyCreatedMetricValueIndex);
    } else {
      this.props.updateMetricValue(updatedMv, mIndex, mvIndex);
    }
  }

  public setNewlyCreatedMetricValueIndex = () => {
    this.setState((state, props) => ({
      metricValueIndex: props.metric.metricValues.length - 1,
      currentMetricValueIndex: props.metric.metricValues.length - 1,
    }));
  }

  private handleChange = (event: any) => {
    if (event.target.validity.valid) {
      this.props.handleChangeMetric(
        this.props.index,
        {
          name: 'comparisonTolerance',
          value: event.target.value === '' ? undefined : parseFloat(event.target.value),
        },
      );
    } else if (event.target.value === '') {
      // Input type="number" gives an incorrect value as empty string, but keeps displaying this value
      // Workaround to force input's displayed value to only number OR empty value
      const input = document.getElementById('comparisonTolerance') as HTMLInputElement;
      if (!!input) {
        if (this.props.metric.comparisonTolerance) {
          input.value = this.props.metric.comparisonTolerance.toString().replace(/\D/g, '');
        } else {
          input.value = '';
        }
      }

      this.props.handleChangeMetric(
        this.props.index,
        {
          name: 'comparisonTolerance',
          value: event.target.value === '' ? undefined : parseFloat(event.target.value),
        },
      );
    }
  };

  public render() {
    const bc = BusinessChallengeStore.getBusinessChallenge();
    const mostRecentMetricValueWithCurrent: MostRecentMetricValueDto = MetricsUtils
    .getLastSetCurrentValue(this.props.metric);
    const piCode = `PI${this.props.metric.performanceInitiative
    && Utils.leadingZero(this.props.metric.performanceInitiative.code)}`;

    if (Utils.isOnViewOrDeletionMode(this.props.mode)) {
      return (
        <Table.Row
          key={this.props.index}
          className={`metric-row${this.isMetricSelected() ? ' active' : ''}`}
          onClick={() => this.props.handleRowClick(this.props.index)}
        >
          <Table.Cell>
            <div className="metric-row-name">
              {this.props.metric.name} {this.props.metric.unit && `(${this.props.metric.unit})`}
              {this.props.metric.isFromLinked &&
              <ImportedLabel
                ppId={bc.performancePlanId}
                piId={this.props.metric.performanceInitiative?.id}
                metricMilestone={this.props.metric}
                type={MetricMilestone.METRIC}
              />}
            </div>
          </Table.Cell>
          <Table.Cell width={3} className="bold-item">
            {Utils.showValueIfExists(mostRecentMetricValueWithCurrent.current)}
          </Table.Cell>
          <Table.Cell width={3} className="bold-item">
            {Utils.showValueIfExists(mostRecentMetricValueWithCurrent.target)}
          </Table.Cell>
          <Table.Cell width={2}>
            {Metrics.getComparisonStatusLabel(this.props.metric)}
            <ToggleHideElement isElementHidden={this.props.metric.isHidden} viewOnly={true} />
          </Table.Cell>
        </Table.Row>
      );
    }

    const isMetricNameInvalid = Utils.isFieldOnError(this.props.metric.name, true, FieldType.STRING);
    const isUnitInvalid = Utils.isFieldOnError(this.props.metric.unit, false, FieldType.STRING);

    const row = (
      <Table.Row className={this.props.metric.isFromLinked ? 'disabled-row' : ''} key={this.props.index}>
        <Table.Cell width={6}>
          <Input
            name="name"
            className="title"
            disabled={this.props.metric.isFromLinked}
            value={(this.props.metric.name) ? this.props.metric.name : ''}
            onChange={(event, { name, value }) =>
              this.props.handleChangeMetric(this.props.index, { name, value })}
            error={isMetricNameInvalid}
          />
        </Table.Cell>
        <MetricValueEdit
          metricIndex={this.props.index}
          metricValueIndex={this.state.metricValueIndex}
          mostRecentTarget={mostRecentMetricValueWithCurrent.target}
          updateMetricValue={this.updateMetricValue}
          metricIsEmpty={this.props.metric.metricValues.length === 0}
          metricValue={this.state.currentMetricValueIndex !== -1
            ? this.props.metric.metricValues[this.state.currentMetricValueIndex] : undefined}
          isImported={this.props.metric.isFromLinked}
        />
        <Table.Cell className="bold-item" width={1}>
          <Input
            name="unit"
            disabled={this.props.metric.isFromLinked}
            value={Utils.showValueIfExists(this.props.metric.unit, false)}
            onChange={(event, { name, value }) =>
              this.props.handleChangeMetric(this.props.index, { name, value })}
            error={isUnitInvalid}
          />
        </Table.Cell>
        <Table.Cell className="bold-item comparison-container" width={1}>
          <Button
            className={`comparison-button ${this.props.metric.comparisonTolerance === undefined ? 'error' : ''}`}
            onClick={() => this.setState({ showComparisonPopup: true })}
            icon="chart line"
            disabled={this.props.metric.isFromLinked}
          />

          {this.state.showComparisonPopup &&
            <div className="popup-container" ref={this.comparisonPopupRef}>
              <Segment>
                {this.comparisonOptions.map(option => (
                  <div
                    key={`comparisonRadio-${option.value}`}
                    className="comparison-radio"
                    onClick={() => this.props.handleChangeMetric(
                      this.props.index,
                      { name: 'comparisonType', value: option.value },
                    )}
                  >
                    <input type="radio" checked={this.props.metric.comparisonType === option.value} />
                    {option.text}
                  </div>
                ))}

                  <div className="input-container">
                  <span className={this.props.metric.comparisonType !== ComparisonType.EQUAL ? 'disabled' : ''}>
                    <FormattedMessage id="comparison.tolerance" defaultMessage="Tolerance" />:
                  </span>
                      <Input
                          id="comparisonTolerance"
                          type="number"
                          pattern="[0-9]*"
                          min="0"
                          max="100"
                          disabled={this.props.metric.comparisonType !== ComparisonType.EQUAL}
                          value={this.props.metric.comparisonTolerance === undefined
                            ? ''
                            : this.props.metric.comparisonTolerance
                          }
                          onInput={this.handleChange}
                          error={this.props.metric.comparisonTolerance === undefined}
                      />
                      <span>%</span>
                  </div>
              </Segment>
          </div>
          }
        </Table.Cell>
        <Table.Cell className="previous-values-container" width={1}>
          {!this.props.forActivityCreation
          && <FormattedMessage id="metrics.previousValues" defaultMessage="Previous Values">
            {msg =>
              <Popup
                inverted={true}
                size="tiny"
                position="left center"
                trigger={(
                  <Button
                    className="previous-values-button"
                    primary={true}
                    onClick={this.toggleShowPreviousValuesModal}
                  >
                    <Icon name="history" />
                  </Button>
                )}
                content={msg}
              />
            }
          </FormattedMessage>
          }
        </Table.Cell>
        <Table.Cell className="actions-container" width={3}>
          <div className="action-icons">
            {!this.props.metric.isFromLinked && <ToggleHideElement
                isElementHidden={this.props.metric.isHidden}
                updateElementState={() => this.props.handleChangeMetric(
                  this.props.index, { name: 'isHidden', value: !this.props.metric.isHidden })}
            />}
            <Icon name="trash" onClick={() => this.props.removeMetric(this.props.index)} />
          </div>
        </Table.Cell>

        {this.state.showPreviousValuesModal &&
          <MetricValuesModal
            mode={this.props.mode}
            showModal={this.state.showPreviousValuesModal}
            metric={this.props.metric}
            index={this.props.index}
            metricValueIndex={this.state.metricValueIndex}
            currentMetricValueIndex={this.state.currentMetricValueIndex}
            toggleShowModal={this.toggleShowPreviousValuesModal}
            updateMetricValue={this.updateMetricValue}
            removeMetricValue={this.props.removeMetricValue}
          />
        }
      </Table.Row>
    );

    return (
      <>
        {this.props.metric.isFromLinked
          ? <Popup
            inverted={true}
            content={(
              <>
                <FormattedMessage
                  id="imported.edit.notAllowed"
                  defaultMessage="You can only edit at original PI level"
                />
                &nbsp;
                <b className="original-pi">{piCode}</b>
              </>
            )}
            size="tiny"
            position="left center"
            trigger={row}
          />
          : row
        }
        {!this.props.forActivityCreation &&
          <Table.Row disabled={true} className="previous-values-row">
            <Table.Cell colSpan={6}>
              <span className="previous-value">
                <span className="title">
                  <FormattedMessage id="metrics.previousUpdateDate" defaultMessage="Date of previous values" />:
                </span>
                {Utils.showDateIfExists(mostRecentMetricValueWithCurrent.updateDate)}
              </span>
                <span className="previous-value">
                <span className="title">
                  <FormattedMessage id="metrics.previousValue" defaultMessage="Previous value" />:&nbsp;
                </span>
                  {Utils.showValueIfExists(mostRecentMetricValueWithCurrent.current, true)}
                </span>
            </Table.Cell>
        </Table.Row>
        }
      </>
    );
  }
}

export default injectIntl(MetricRow);
