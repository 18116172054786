import './checkpoint.scss';

import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { RouteComponentProps, withRouter } from 'react-router';
import AuthService from 'src/services/auth.service';

class LoginError extends React.Component<RouteComponentProps> {

  public constructor(props: RouteComponentProps) {
    super(props);
  }

  private handleRedirectClick = async () => {
    await AuthService.logout();
    this.props.history.push('/');
    window.location.reload();
  };

  public render() {
    return (
      <>
        <Button
          color="red"
          content="Retry Logging In"
          icon="left arrow"
          labelPosition="left"
          onClick={this.handleRedirectClick}
        />
      </>
    );
  }
}

export default withRouter(LoginError);
