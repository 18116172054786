import Metric, { MetricValueGet } from 'src/models/metric';
import Attachment from 'src/models/attachment';
import PerformanceInitiative, {
  PerformanceIndicatorPost,
  PerformanceIndicatorPut,
  PerformanceInitiativeDetails,
  PerformanceInitiativeGet,
} from 'src/models/performanceInitiative';
import HttpClient from 'src/utils/httpClient';

import Keywords from '../models/keywords';
import Milestone from '../models/milestone';
import { ImportPiData } from 'src/models/importActivities';
import { TopicsParams } from '../components/explore/containerExplore/topics/topics';
import ILinkRequestRes from '../constants/activityLink';
import { SortBy } from 'src/constants/interfaces/action.interface';
import { AssignedUser, SendTeamMailAccountDTO, UsersAssignation } from '../models/user';
import { AvailableRoles } from '../constants/typeActivity';
import { MemberOptions } from '../constants/member';
import Action from 'src/models/action';
import { RightsOnActivityDTO } from 'src/models/rights';
import { BcPiFieldsDTO } from '../models/bcPiFields';
import { BcPiFields } from '../constants/bcPiFields';
import { ActionOrderBody, CodeOrderBody } from '../constants/order';
import { ArchiveHistoryResult } from '../constants/interfaces/common.interface';

export default class PerformanceInitiativeAPI {

  /**
   * ROUTE : /api/performance-initiatives/by-keyword
   * Get a PI by keywords filtered by user rights
   * @param bcKeywordId: number
   * @param piKeywordId: number
   * @param params: TopicsParams
   * @returns Promise<BusinessChallenge>
   */
  public static getPerformanceInitiativesByKeyword(bcKeywordId: number, piKeywordId: number, params: TopicsParams)
    : Promise<PerformanceInitiative[]> {
    return HttpClient.get<PerformanceInitiative[]>(
      `performance-initiatives/by-keyword/${bcKeywordId}/${piKeywordId}${HttpClient.formatUrlQueryParams(params)}`,
    );
  }

  /**
   * GET /api/performance-initiatives/{piId}
   * Get performance initiative by Id
   * @param piId: number
   * @param targetPpId: number
   * @returns Promise<PerformanceInitiative>
   */
  public static async getPerformanceInitiativeById(piId: number, targetPpId?: number): Promise<PerformanceInitiative> {
    const params = targetPpId ? { targetPpId } : {};
    return new PerformanceInitiative(await HttpClient
      .get<PerformanceInitiativeGet>(`performance-initiatives/${piId}${HttpClient.formatUrlQueryParams(params)}`));
  }

  /**
   * GET /api/performance-initiatives/{piId}/details
   * Get performance initiative details by Id
   * @param {number} piId
   * @returns {Promise<PerformanceInitiativeDetails>}
   */
  public static async getPerformanceInitiativeDetailsById(piId: number): Promise<PerformanceInitiativeDetails> {
    return HttpClient.get<PerformanceInitiativeDetails>(`performance-initiatives/${piId}/details`);
  }

  /**
   * PUT /api/performance-initiatives/{piId}/details
   * Put performance initiative details
   * @param {number} piId
   * @param {PerformanceInitiativeDetails} pi
   * @returns {Promise<PerformanceInitiativeDetails>}
   */
  public static async putPerformanceInitiativeDetails(piId: number, pi: PerformanceInitiativeDetails)
    : Promise<PerformanceInitiativeDetails> {
    return HttpClient.put<PerformanceInitiativeDetails>(`performance-initiatives/${piId}/details`, pi);
  }

  /**
   * GET /api/performance-initiatives/{piId}/rights
   * Get performance initiative rights
   * @param {number} piId
   * @returns {Promise<RightsOnActivityDTO>}
   */
  public static async getPerformanceInitiativeRightsById(piId: number): Promise<RightsOnActivityDTO> {
    return await HttpClient.get<RightsOnActivityDTO>(`performance-initiatives/${piId}/rights`);
  }

  /**
   * GET : /api/performance-initiatives/{piId}/field/{fieldType}
   * Get PI textarea field
   * @param {number} piId
   * @param {FieldType} fieldType
   * @returns {Promise<BcPiFieldsDTO>}
   */
  public static async getPiField(piId: number, fieldType: BcPiFields): Promise<BcPiFieldsDTO> {
    return HttpClient.get<BcPiFieldsDTO>(`performance-initiatives/${piId}/field/${fieldType}`);
  }

  /**
   * PUT : /api/performance-initiatives/{piId}/field/{fieldType}
   * Edit PI textarea field
   * @param {number} piId
   * @param {FieldType} fieldType
   * @param {string} value
   * @returns {Promise<void>}
   */
  public static async editPiField(piId: number, fieldType: BcPiFields, value: string): Promise<void> {
    return HttpClient.put<void>(`performance-initiatives/${piId}/field/${fieldType}`, { value });
  }

  /**
   * GET /api/performance-initiatives/{piId}/global
   * Get global performance initiative by Id
   * @param {number} piId
   * @param {number} targetPpId
   * @returns {Promise<PerformanceInitiative>}
   */
  public static async getPerformanceInitiativeGlobalById(piId: number, targetPpId?: number)
    : Promise<PerformanceInitiative> {
    const params = targetPpId ? { targetPpId } : {};
    const url = `performance-initiatives/${piId}/global${HttpClient.formatUrlQueryParams(params)}`;
    return new PerformanceInitiative(await HttpClient.get<PerformanceInitiativeGet>(url));
  }

  /**
   * Get all Pi actions for mentions
   * @param {number} piId
   * @returns {Action[]} actions
   */
  public static async getPerformanceInitiativeActionsMentions(piId: number): Promise<Action[]> {
    return HttpClient.get<Action[]>(`performance-initiatives/link-at-list/${piId}`);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/actions/order
   * Edit BC actions orders
   * @param {number} piId
   * @param {ActionOrderBody[]} actionsOrdered
   * @returns {void}
   */
  public static putPerformanceInitiativeAtsOrder(piId: number, actionsOrdered: ActionOrderBody[]): Promise<void> {
    return HttpClient.put<void>(`performance-initiatives/${piId}/actions/order`, actionsOrdered);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/assignations
   * Get performance-initiatives' assignations
   * @param {number} piId
   * @returns {Promise<PerformanceInitiative>}
   */
  public static getPerformanceInitiativeAssignations(piId: number): Promise<PerformanceInitiative> {
    return HttpClient.get<PerformanceInitiative>(`performance-initiatives/${piId}/assignations`);
  }

  /**
   * ROUTE : performance-initiatives/${piId}/publish
   * @param {number} piId
   * @param {string} name
   * @returns Promise<void>
   */
  public static postPublishPerformanceInitiativeById(piId: number, name: string)
    : Promise<void> {
    return HttpClient.post<void>(`performance-initiatives/${piId}/publish`, { name });
  }

  /**
   * ROUTE : /api/business-challenges/${bcId}/performance-initiatives
   * Create a Performance Indicator
   * @param bcId : number
   * @param pi : PerformanceIndicatorPost
   * @returns Promise<PerformanceInitiative>
   */
  public static postPerformanceInitiative(bcId: number, pi: PerformanceIndicatorPost): Promise<PerformanceInitiative> {
    return HttpClient.post<PerformanceInitiative>(`business-challenges/${bcId}/performance-initiatives`, pi);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}
   * Delete performance initiative by Id
   * @param piId : number
   * @returns Promise<PerformanceInitiative>
   */
  public static deletePerformanceInitiativeById(piId: number): Promise<PerformanceInitiative> {
    return HttpClient.deleteWithId<PerformanceInitiative>(`performance-initiatives/${piId}`);
  }

  /**
   * PUT /api/performance-initiatives/{piId}
   * Edit a performance initiative by id
   * @param {number} piId
   * @param {PerformanceInitiative} pi
   * @returns {Promise<PerformanceInitiative>}
   */
  public static putPerformanceInitiative(piId: number, pi: PerformanceIndicatorPut): Promise<PerformanceInitiative> {
    return HttpClient.put<PerformanceInitiative>(`performance-initiatives/${piId}`, pi);
  }

  //region METRIC VALUES
  /**
   * ROUTE : /api/performance-initiatives/metrics/{metricId}/image
   * Get PI metric image
   * @param {number} metricId
   * @returns {Promise<Blob>}
   */
  public static getPerformanceInitiativeMetricImage(metricId: number): Promise<Blob> {
    return HttpClient.getImage(`performance-initiatives/metrics/${metricId}/image`);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/metrics/{metricId}/image
   * @param {number} piId
   * @param {number} metricId
   * @param {FormData} formData
   * @returns {Promise<void>}
   */
  public static setPerformanceInitiativeMetricImage(piId: number, metricId: number, formData: FormData) {
    return HttpClient.setImage(`performance-initiatives/${piId}/metrics/${metricId}/image`, formData);
  }

  /**
* ROUTE : /api/performance-initiatives/{piId}/metrics/{metricId}/image
* Delete PI metric image
* @param {number} piId
* @param {number} metricId
* @returns {Promise<void>}
*/
  public static deletePerformanceInitiativeMetricImage(piId: number, metricId: number): Promise<void> {
    return HttpClient.delete<void>(`performance-initiatives/${piId}/metrics/${metricId}/image`, {});
  }

  /**
  * ROUTE : /api/performance-initiatives/{piId}/metrics
  * Get PI metrics
  * @param piId : number
  * @returns Promise<{metrics: Metric[]}>
  */
  public static getPerformanceInitiativeMetrics(piId: number)
    : Promise<{ metrics: Metric[] }> {
    return HttpClient.get<{ metrics: Metric[] }>(`performance-initiatives/${piId}/metrics`);
  }

  /**
* ROUTE : /api/performance-initiatives/{bcId}/metrics/order
* Edit PI metrics orders
* @param {number} piId
* @param {CodeOrderBody[]} metricsOrdered
* @returns {void}
*/
  public static putPerformanceInitiativeMetricsOrder(piId: number, metricsOrdered: CodeOrderBody[]): Promise<void> {
    return HttpClient.put<void>(`performance-initiatives/${piId}/metrics/order`, metricsOrdered);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/metric-values
   * Create one or multiple new metric values
   * @param piId : number
   * @param metricValues : MetricValueGet[]
   * @param isCreate
   * @returns Promise<MetricValueGet[]>
   */
  public static postPerformanceInitiativeMetricValues(piId: number, metricValues: MetricValueGet[])
    : Promise<MetricValueGet[]> {
    return HttpClient.post<MetricValueGet[]>(`performance-initiatives/${piId}/metric-values`,  metricValues);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/metrics/edit-metric-values
   * Edit one or multiple metric values
   * @param piId : number
   * @param metricValues: MetricValues[]
   * @returns Promise<metricValues[]>
   */
  public static putPerformanceInitiativeMetricValues(piId: number, metricValues: MetricValueGet[])
    : Promise<MetricValueGet[]> {
    return HttpClient.put<MetricValueGet[]>(`performance-initiatives/${piId}/metrics/metric-values`, metricValues);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/metric-values
   * Delete one or multiple metric values
   * @param piId : number
   * @param metricValuesIds: number[]
   * @returns Promise<MetricValueGet[]>
   */
  public static deletePerformanceInitiativeMetricValues(piId: number, metricValuesIds: number[])
    : Promise<MetricValueGet[]> {
    return HttpClient.delete<MetricValueGet[]>(`performance-initiatives/${piId}/metric-values`, metricValuesIds);
  }

  //endregion

  /**
   * ROUTE : /api/performance-initiatives/{piId}/metrics
   * Create a new metric
   * @param piId : number
   * @param metrics : Metric[]
   * @returns Promise<Metric[]>
   */
  public static postPerformanceInitiativeMetrics(piId: number, metrics: Metric[]): Promise<Metric[]> {
    return HttpClient.post<Metric[]>(`performance-initiatives/${piId}/metrics`, metrics);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/metrics
   * Delete a metric
   * @param piId : number
   * @param metrics : number[]
   * @returns Promise<Metric[]>
   */
  public static deletePerformanceInitiativeMetrics(piId: number, metrics: number[]): Promise<Metric[]> {
    return HttpClient.delete<Metric[]>(`performance-initiatives/${piId}/metrics`, metrics);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/metrics
   * Delete a metric
   * @param piId : number
   * @param ids
   * @returns Promise<Metric[]>
   */
  public static deletePerformanceInitiativeAttachments(piId: number, ids: number[]): Promise<Attachment[]> {
    return HttpClient.delete<Attachment[]>(`performance-initiatives/${piId}/attachments`, ids);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/metrics/edit-metrics
   * Edit the metrics in existing pi
   * @param piId : number
   * @param metrics : Metric[]
   * @returns Promise<Metric[]>
   */
  public static putPerformanceInitiativeMetrics(
    piId: number, metrics: Metric[],
  ): Promise<Metric[]> {
    return HttpClient.put<Metric[]>(`performance-initiatives/${piId}/metrics/edit-metrics`, metrics);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/attachments/{metricId}
   * Edit one or multiple attachments in existing pi
   * @param piId : number
   * @param attachments
   * @returns Promise<Attachment[]>
   */
  public static putPerformanceInitiativeAttachments(
    piId: number, attachments: Attachment[],
  ): Promise<Attachment[]> {
    return HttpClient.put<Attachment[]>(`performance-initiatives/${piId}/attachments/`, attachments);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/attachments/{metricId}
   * Edit one or multiple attachments in existing pi
   * @param piId : number
   * @param attachments
   * @returns Promise<Metric[]>
   */
  public static postPerformanceInitiativeAttachments(
    piId: number, attachments: Attachment[],
  ): Promise<Attachment[]> {
    const attachmentsWithoutTempId = attachments.map((attachment) => {
      const attachmentWithoutTempId = new Attachment();
      attachmentWithoutTempId.title = attachment.title;
      attachmentWithoutTempId.url = attachment.url;
      return attachmentWithoutTempId;
    });
    return HttpClient.post<Attachment[]>(`performance-initiatives/${piId}/attachments/`, attachmentsWithoutTempId);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/milestones
   * Get milestones from PI
   * @param piId : number
   * @param forPi
   * @returns Promise<{milestones: Milestone[]}>
   */
  public static getPerformanceInitiativeMilestones(piId: number, forPi = false): Promise<Milestone[]> {
    return HttpClient.get<Milestone[]>(`performance-initiatives/${piId}/milestones?forPi=${forPi}`);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/milestone/{miId}
   * Get milestones from PI
   * @param piId : number
   * @param miId
   * @returns Promise<{milestones: Milestone[]}>
   */
  public static getPerformanceInitiativeMilestoneById(piId: number, miId: number): Promise<Milestone> {
    return HttpClient.get<Milestone>(`performance-initiatives/${piId}/milestones/${miId}`);
  }

  /**
   * ROUTE : /api/business-challenges/{bcId}/milestones/order
   * Edit BC milestones orders
   * @param {number} piId
   * @param {CodeOrderBody[]} milestonesOrdered
   * @returns {void}
   */
  public static putPerformanceInitiativeMilsOrder(piId: number, milestonesOrdered: CodeOrderBody[]): Promise<void> {
    return HttpClient.put<void>(`performance-initiatives/${piId}/milestones/order`, milestonesOrdered);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/milestones
   * Create a new milestone
   * @param piId : number
   * @param milestones : Milestone[]
   * @returns Promise<Milestone[]>
   */
  public static createMilestones(piId: number, milestones: Milestone[]): Promise<{ milestoneId: number }> {
    return HttpClient.post<{ milestoneId: number }>(`performance-initiatives/${piId}/milestones`, milestones);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/milestones
   * Delete a Milestone
   * @param piId : number
   * @param milestones : number[]
   * @returns Promise<Milestone[]>
   */
  public static deleteMilestones(piId: number, milestones: number[]): Promise<Milestone[]> {
    return HttpClient.delete<Milestone[]>(`performance-initiatives/${piId}/milestones`, milestones);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/milestones/edit-milestones
   * Edit the milestones in existing pi
   * @param piId : number
   * @param milestones : Milestone[]
   * @returns Promise<Milestone[]>
   */
  public static putPerformanceInitiativeMilestones(piId: number, milestones: Milestone[])
    : Promise<Milestone[]> {
    return HttpClient.put<Milestone[]>(
      `performance-initiatives/${piId}/milestones/edit-milestones`,
      milestones,
    );
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/assign
   * Assign users to PI
   * @param piId : number
   * @param users : UsersAssignation
   * @returns Promise<void>
   */
  public static postPerformanceInitiativeAssignees(piId: number, users: UsersAssignation): Promise<AssignedUser[]> {
    return HttpClient.post<AssignedUser[]>(`performance-initiatives/${piId}/assign`, users);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/unassign
   * Unassign users from PI
   * @param piId : number
   * @param users : UsersAssignation
   * @returns Promise<void>
   */
  public static deletePerformanceInitiativeAssignees(piId: number, users: UsersAssignation): Promise<void> {
    return HttpClient.delete<void>(`performance-initiatives/${piId}/unassign`, users);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/team-email
   * Get PI for the team email modale
   * @param {number} piId
   * @returns {Promise<PerformanceInitiative>}
   */
  public static getPiForTeamEmail(piId: number): Promise<PerformanceInitiative> {
    return HttpClient.get<PerformanceInitiative>(`performance-initiatives/${piId}/team-email`);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/import
   * Import a whole Performance Initiative
   * @param data : ImportPiData
   * @returns Promise<void>
   */
  public static importPi(data: ImportPiData): Promise<void> {
    return HttpClient.post<void>(`performance-initiatives/${data.piId}/import`, data);
  }

  /**
   * ROUTE : /api/performance-initiatives/keywords
   * Get all performance initiative keywords
   * @returns Promise<PiCategory[]>
   */
  public static getKeywords(): Promise<Keywords[]> {
    return HttpClient.get<Keywords[]>('performance-initiatives/keywords');
  }

  /**
   * ROUTE : /api/export/{piId}/full-pi
   * Export the full performance Initiative
   * @param piId : number
   * @returns Promise<void>
   */
  public static exportFull(piId: number): Promise<string> {
    return HttpClient.get<string>(`export/${piId}/full-pi`);
  }

  /**
   * ROUTE : /api/export/{piId}/cockpit-pi
   * Export the performance Initiative Cockpit
   * @param piId : number
   * @returns Promise<void>
   */
  public static exportCockpit(piId: number): Promise<string> {
    return HttpClient.get<string>(`export/${piId}/cockpit-pi`);
  }

  /**
   * ROUTE : /api/export/{piId}/history-pi
   * Export the performance Initiative History
   * @param {number} piId
   * @param {number | undefined} historyId
   * @param {boolean} isPublicView (optional)
   * @returns {Promise<string>}
   */
  public static exportHistory(piId: number, historyId: number | undefined, isPublicView?: boolean): Promise<string> {
    return HttpClient.get<string>(`export/${piId}/history-pi${HttpClient.formatUrlQueryParams({
      historyId,
      isPublicView,
    })}`);
  }

  /**
   * ROUTE : /api/performance-initiatives/{ppId}/history/{historyId}
   * Edit a PI history
   * @param {number} piId
   * @param {number} historyId
   * @param {string} name
   * @returns {Promise<History>}
   */
  public static editHistoryName(piId: number, historyId: number, name: string): Promise<History> {
    return HttpClient.put<History>(`performance-initiatives/${piId}/history/${historyId}`, { name });
  }

  /**
   * ROUTE : /api/performance-initiatives/{ppId}/history/{historyId}/archive
   * Archive a PI history
   * @param {number} piId
   * @param {number} historyId
   * @returns {Promise<ArchiveHistoryResult>}
   */
  public static archiveHistory(piId: number, historyId: number): Promise<ArchiveHistoryResult> {
    return HttpClient.post<ArchiveHistoryResult>(`performance-initiatives/${piId}/history/${historyId}/archive`, {});
  }

  public static exportActionsList(piId: number, sortBy?: SortBy): Promise<string> {
    return HttpClient.get<string>(`export/${piId}/actions-list${HttpClient.formatUrlQueryParams({
      sortBy: sortBy?.value,
      orderBy: sortBy?.order,
    })}`);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/link-to-bc/{bcId}
   * Link a PI to a BC
   * @param piId: number
   * @param bcId: number
   * @returns Promise<void>
   */
  public static linkPiToBc(piId: number, bcId: number): Promise<void> {
    return HttpClient.patch<void>(`performance-initiatives/${piId}/link-to-bc/${bcId}`);
  }

  /**
   * ROUTE : /api/performance-initiatives/{bcId}/link-to-pp/{ppId}/reject
   * reject Link of a PI to a BC
   * @param piId: number
   * @param bcId: number
   * @returns Promise<void>
   */
  public static rejectLinkPiToBc(piId: number, bcId: number): Promise<void> {
    return HttpClient.patch<void>(`performance-initiatives/${piId}/link-to-bc/${bcId}/reject`);
  }

  /**
   * ROUTE : /api/performance-initiatives/{bcId}/request-link
   * Send a performance Initiative notification to link
   * @param piId
   * @param bcId : number
   * @returns Promise<BusinessChallenge>
   */

  public static requestLinkPiToBc(piId: number, bcId: number): Promise<ILinkRequestRes> {
    return HttpClient.post<ILinkRequestRes>(`performance-initiatives/${piId}/request-link/${bcId}`, {});
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/unlink-from-pp/{ppId}
   * Unlink a PI from a PP
   * @param piId: number
   * @param ppId: number
   * @returns Promise<void>
   */
  public static unlinkPiFromPp(piId: number, ppId: number): Promise<void> {
    return HttpClient.deleteWithId<void>(`performance-initiatives/${piId}/unlink-from-pp/${ppId}`);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/attachments
   * Get PI attachments
   * @param {number} piId
   * @returns {Promise<Attachment[]>}
   */
  public static getPerformanceInitiativeAttachments(piId: number): Promise<Attachment[]> {
    return HttpClient.get<Attachment[]>(`performance-initiatives/${piId}/attachments`);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/about
   * Get PI about information
   * @param {number} piId
   * @returns {Promise<PerformanceInitiative>}
   */
  public static getPerformanceInitiativeAbout(piId: number): Promise<PerformanceInitiative> {
    return HttpClient.get<PerformanceInitiative>(`performance-initiatives/${piId}/about`);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/about
   * Put PI about information
   * @param {number} piId
   * @param {PerformanceInitiative} piAbout
   * @returns {Promise<void>}
   */
  public static putPerformanceInitiativeAbout(piId: number, piAbout: PerformanceInitiative): Promise<void> {
    return HttpClient.put<void>(`performance-initiatives/${piId}/about`, piAbout);
  }

  /**
   * ROUTE: /api/performance-initiatives/{piId}/team-members
   * @param {number} piId
   * @param {AvailableRoles[]} roles
   * @return {Promise<SendTeamMailAccountDTO[]>}
   */
  public static retrieveTeamMembers(piId: number, roles: AvailableRoles[]): Promise<SendTeamMailAccountDTO[]> {
    const options = new MemberOptions(roles);
    return HttpClient.get<SendTeamMailAccountDTO[]>(`performance-initiatives/${piId}/team-members${HttpClient.formatUrlQueryParams(options)}`);
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/toggle-close
   * Toggle close PI
   * @param {number} piId
   * @param {string} comment (optional)
   * @returns Promise<PerformanceInitiative>
   */
  public static toggleClosePi(piId: number, comment?: string): Promise<PerformanceInitiative> {
    return HttpClient.patch<PerformanceInitiative>(`performance-initiatives/${piId}/toggle-close`, { comment });
  }

  /**
   * ROUTE : /api/performance-initiatives/{piId}/next-at-code
   * Retrieve the next action code available
   * @param {number} piId
   * @returns Promise<number>
   */
  public static getNextAvailableActionCode(piId: number): Promise<{ code: number }> {
    return HttpClient.get<{ code: number }>(`performance-initiatives/${piId}/next-at-code`);
  }
}
