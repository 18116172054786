import './ckEditorGenerator.scss';

import React, { Component } from 'react';
import CKEditor from 'ckeditor4-react';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, Popup } from 'semantic-ui-react';

import PerformancePlan from 'src/models/performancePlan';
import BusinessChallenge from 'src/models/businessChallenge';
import PerformanceInitiative from 'src/models/performanceInitiative';
import TypeActivity from 'src/constants/typeActivity';
import { PpTabs } from './contentPP/contentPP';
import { BcTabs } from './contentBC/contentBC';
import { PiTabs } from './contentPI/contentPI';
import MentionActivityTextModal from './mentionActivityTextModal';
import { BcPiFields } from '../../../constants/bcPiFields';
import GuideStore from '../../../stores/guide.store';
import ActionTypes from '../../../constants/actionTypes';
import GuideActions from '../../../actions/guide.action';
import { GuideIds } from '../../../constants/guideConstants';

declare var CKEDITOR;

const CKEDITOR_LIB = require('ckeditor4-react');

interface IProps {
  data: any;
  onChangeAction: Function;
  onErrorAction: Function;
  idElement: string;
  smallVersion: boolean;
  onDisplayPiData?: (type: BcPiFields) => void;
  toggleButton?: boolean;
}

interface IState {
  nbChars: number;
  nbLines: number;
  isMentionActivityModalOpen: boolean;
}

const MAX_CHAR_LIMIT = 1000;
const MAX_CHAR_LIMIT_SMALL = 750;
const MAX_LINES_LIMIT = 20;
const BACKSPACE_KEYCODE = 8;

class EditorGenerator extends Component<IProps, IState> {

  private isMount: boolean = false;

  public static defaultProps = {
    smallVersion: false,
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      nbChars: 0,
      nbLines: 0,
      isMentionActivityModalOpen: false,
    };
  }

  componentDidMount() {
    this.isMount = true;
    this.updateCounters();
    setTimeout(
      () => {
        let keyPressed = 0;
        if (!CKEDITOR) {
          return;
        }
        try {
          CKEDITOR.instances[this.props.idElement].on('key', (evt) => {
            keyPressed = evt.data.keyCode;
          });
          CKEDITOR.instances[this.props.idElement].on('change', (evt) => {
            this.onEditorChange(evt, keyPressed);
          });
        } catch (e) {
          console.error(e);
        }
      },
      700);
    GuideStore.addListener(ActionTypes.GUIDE_STATUS_UPDATED.toString(), this.setClose);
  }

  componentWillUnmount() {
    this.isMount = false;
    GuideStore.removeListener(ActionTypes.GUIDE_STATUS_UPDATED.toString(), this.setClose);
  }

  private trimHTML = (text: any) : string => {
    // tslint:disable-next-line:max-line-length
    return text ? text.replace(/(<br([^>]+)>)/ig, '\n').replace(/(<([^>]+)>)/ig, '')
    .replace(/(&([^;]+);)/ig, ' ') : '';
  }

  private updateCounters = (newData?: any, callback?: Function) => {
    const onlyCharsData = this.trimHTML(newData ? newData : this.props.data);
    this.setState(
      {
        nbChars: onlyCharsData.length,
        nbLines: onlyCharsData.length === 0 ? 0 : onlyCharsData.split(/\r\n|\r|\n/).length,
      },
      () => !!callback && callback(),
    );
  };

  private updateData = (id: string, data: any) => {
    const charLimit = this.props.idElement === 'budget' ? MAX_CHAR_LIMIT_SMALL : MAX_CHAR_LIMIT;
    const isOnErr = this.state.nbChars > charLimit || this.state.nbLines > MAX_LINES_LIMIT;
    this.props.onErrorAction(id, isOnErr, this.props.onChangeAction.bind(this, id, data));
  }

  private onEditorChange = (evt: any, keyPressed: number) => {
    // On backspace press, delete the complete activity mention
    if (keyPressed === BACKSPACE_KEYCODE) {
      const selection = evt.editor.getSelection();
      const range = selection.getRanges()[0];
      const position = range.startOffset;
      const element = selection.getStartElement();
      // Check if It's an activity mention, position needs to be higher than 0
      // otherwise it will remove the following text
      if (element.$.classList.contains('mention-activity') && position > 0) {
        selection.selectElement(element);
      }
    }
    const dataTimeout = setTimeout(() => this.updateTextareaData(evt));
    setTimeout(
      () => {
        if (dataTimeout) {
          clearTimeout(dataTimeout);
        }
        this.updateTextareaData(evt);
      },
      100);
  };

  private updateTextareaData = (evt) => {
    this.updateCounters(evt.editor.getData(), this.updateData.bind(this, this.props.idElement, evt.editor.getData()));
  }

  /**
   * Overrides the editor's writer config
   * @param writer: any
   */
  private configWriter = (writer: any) => {
    const rules = {
      breakBeforeOpen: true,
      breakAfterOpen: false,
      breakBeforeClose: false,
      breakAfterClose: false,
    };
    const listRules = {
      breakBeforeOpen: false,
      breakAfterOpen: false,
      breakBeforeClose: false,
      breakAfterClose: false,
    };
    writer.setRules('p', rules);
    writer.setRules('ol', listRules);
    writer.setRules('ul', listRules);
    writer.setRules('li', rules);
    writer.setRules('br', {
      breakBeforeOpen: false,
      breakAfterOpen: false,
      breakBeforeClose: false,
      breakAfterClose: false,
    });
  };

  private setOpen = () => {
    this.setState({ isMentionActivityModalOpen: true });
  }

  private setClose = () => {
    if (this.isMount) {
      this.setState({ isMentionActivityModalOpen: false });
    }
  }

  private insertMention = (selectedElement: PerformancePlan | BusinessChallenge | PerformanceInitiative,
                           type: TypeActivity, selectedPp?: number, selectedPi?: number) => {
    const id = selectedElement.id;
    let url = window.location.origin;

    switch (type) {
      case TypeActivity.PERFORMANCE_PLAN:
        url += `/activities-board/performance-plan/${id}/${PpTabs.COCKPIT}`;
        break;
      case TypeActivity.BUSINESS_CHALLENGE:
        url += `/activities-board/${selectedPp}/business-challenge/${id}/${BcTabs.COCKPIT}`;
        break;
      case TypeActivity.PERFORMANCE_INITIATIVE:
        url += `/activities-board/${selectedPp}/performance-initiative/${id}/${PiTabs.COCKPIT}`;
        break;
      case TypeActivity.ACTION:
        url += `/activities-board/${selectedPp}/performance-initiative/${selectedPi}/${PiTabs.ACTIVITY}/${id}`;
        break;
    }

    // Set default url and set data in data-activity
    const element = CKEDITOR.dom.element
      .createFromHtml(`<a href="${url}" class="mention-activity" data-activity="${id}" data-type="${type}" data-pp="${selectedPp}" data-pi="${selectedPi}">@${selectedElement.name}</a>`);
    if (CKEDITOR.instances[this.props.idElement]) {
      const editor = CKEDITOR.instances[this.props.idElement];
      editor.insertElement(element);
      this.updateCounters(editor.getData(), this.updateData.bind(this, this.props.idElement, editor.getData()));
    }
    this.setClose();
  }

  private setDisplayPiButtonText() {
    switch (this.props.idElement) {
      case BcPiFields.MAIN_ACHIEVEMENTS:
        return <FormattedMessage id="showPisAchievements" defaultMessage="Show PIs' Main Achievements"/>;
      case BcPiFields.DECISIONS_TO_BE_MADE:
        return <FormattedMessage id="showPisDecisions" defaultMessage="Show PIs' Decisions to be made"/>;
      case BcPiFields.ISSUES_AND_RISKS:
        return <FormattedMessage id="showPisIssues" defaultMessage="Show PIs' Main Issues & Risks"/>;
      case BcPiFields.NEXT_STEPS:
        return <FormattedMessage id="showPisNextSteps" defaultMessage="Show PIs' Next Steps"/>;
    }
  }

  private handleClick = (buttonType: BcPiFields) => {
    if (this.props.onDisplayPiData) {
      this.props.onDisplayPiData(buttonType);
    }
  }

  private generatePiDisplayButton() {
    if (window.location.href.includes('cockpit') && window.location.href.includes('business-challenge')) {
      return (
        <>
          <Popup
            inverted={true}
            content={this.setDisplayPiButtonText()}
            size="tiny"
            position="top center"
            trigger={<Button
              active={this.props.toggleButton}
              className="btn-pi"
              onClick={() => this.handleClick(this.props.idElement as BcPiFields)}
            >
              PI
            </Button>}
          />
          <div className="vertical-divider" />
        </>
      );
    }
    return (<></>);
  }

  private onGuideClick = () => {
    GuideActions.emitRunGuide(GuideIds.MENTION_ACTIVITIES);
  }

  render() {
    const config = {
      toolbar: [['Bold', 'Italic', 'Underline'], ['NumberedList', 'BulletedList'], ['Link', 'Unlink']],
      fillEmptyBlocks: false,
      resize_enabled: false,
      bodyClass: 'cke_editable_body',
      contentsCss: `${window.location.origin}/cke.css`,
      height: this.props.smallVersion
        ? 100
        : window.innerHeight < 700
          ? 190
          : 220,
      allowedContent: {
        $1: {
          elements: CKEDITOR_LIB.dtd,
          attributes: true,
          styles: true,
          classes: true,
        },
      },
      disallowedContent: '*{font*}; *{color*}',
    };

    const charLimit = this.props.idElement === 'budget' ? MAX_CHAR_LIMIT_SMALL : MAX_CHAR_LIMIT;

    return (
      <div className="editor-container">
        <CKEditor
            data={this.props.data}
            type="classic"
            config={config}
            name={this.props.idElement}
            onInstanceReady={(evt: any) => this.configWriter(evt.editor.dataProcessor.writer)}
        />

        <label className="editor-counters">
          <span className={this.state.nbLines > MAX_LINES_LIMIT ? 'on-error' : ''}>
            {this.state.nbLines}/{MAX_LINES_LIMIT}&nbsp;
            <FormattedMessage id="ckEditor.lines" defaultMessage="lines" />
          </span>
          &nbsp;-&nbsp;
          <span className={this.state.nbChars > charLimit ? 'on-error' : ''}>
            {this.state.nbChars}/{charLimit}&nbsp;
            <FormattedMessage id="ckEditor.chars" defaultMessage="chars" />
          </span>
        </label>

        <>
          {this.props.toggleButton !== undefined && this.generatePiDisplayButton()}
          <Popup
            inverted={true}
            content={
              <span className="mention-activity-tooltip">
                <FormattedMessage id="mentionActivity" defaultMessage="Mention an activity"/>
                <Icon className="guide-button" name="question circle" onClick={this.onGuideClick}/>
              </span>
            }
            size="tiny"
            position="top center"
            hoverable={true}
            trigger={<Button
              id="mention-activity-btn"
              className="btn-mention-activity"
              icon="linkify"
              onClick={this.setOpen}
            />}
          />
          {this.state.isMentionActivityModalOpen
            && <MentionActivityTextModal closeModal={this.setClose} submit={this.insertMention}/>}
        </>
      </div>
    );
  }
}

export default EditorGenerator;
