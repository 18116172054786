import React, { Component } from 'react';
import './piTimelineContainer.scss';
import { Segment } from 'semantic-ui-react';
import TimelineContent from './timelineContent/timelineContent';
import Action from '../../../../../../models/action';
import Milestone from '../../../../../../models/milestone';
import { Months, Quarter, TimelineView } from '../../../../../../constants/timeline';
import TimelineLegend from './timelineLegend/timelineLegend';
import TimelineHeader from './timelineHeader/timelineHeader';
import TimelineUtils from '../../../../../../utils/timelineUtils';
import { AtTimelineElement, MiTimelineElement, TimelineElement } from '../../../../../../constants/piTimeline';
import TypeActivity from '../../../../../../constants/typeActivity';

interface IProps {
  actions: Action[];
  milestones: Milestone[];
  activityIdToScrollTo?: number;
  setOverviewData(activityId: number, type: TypeActivity.MILESTONE | TypeActivity.ACTION): void;
}
interface IStates {
  selectedView: TimelineView;
  selectedYear: number;
  selectedQuarter: Quarter;
  selectedMonth: Months;
}

class PiTimelineContainer extends Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
    const currentMonth = new Date().getMonth();
    this.state = {
      selectedView: TimelineView.YEAR,
      selectedYear: (new Date()).getFullYear(),
      selectedQuarter: TimelineUtils.getCurrentQuarter(currentMonth),
      selectedMonth: currentMonth,
    };
  }

  //region VIEW & SELECTORS METHODS
  private setTimelineView = (view: TimelineView) => {
    this.setState({ selectedView: view });
  };

  private setSelectedYear = (year: number) => {
    this.setState({ selectedYear: year });
  };

  private setSelectedMonth = (month: Months) => {
    this.setState({
      selectedMonth: month,
      selectedQuarter: TimelineUtils.getCurrentQuarter(month),
    });
  };

  private setSelectedQuarter = (quarter: Quarter) => {
    this.setState({
      selectedQuarter: quarter,
      selectedMonth: TimelineUtils.getFirstMonthOfQuarter(quarter),
    });
  };

  private setMultiplePeriods = (period: Months | Quarter, year: number) => {
    switch (this.state.selectedView) {
      case TimelineView.QUARTER:
        this.setState({
          selectedYear: year,
          selectedQuarter: period as Quarter,
        });
        break;
      case TimelineView.MONTH:
        this.setState({
          selectedYear: year,
          selectedMonth: period as Months,
        });
        break;
    }
  }
  //endregion

  private formatElements = (actions: Action[], milestones: Milestone[]) => {
    const elements : TimelineElement[] = [];
    let usedActionsIds: number[] = [];
    for (const at of actions) {
      if (!usedActionsIds.includes(at.id)) {
        elements.push(new AtTimelineElement(at));
      }
    }
    for (const mi of milestones) {
      const associatedActions = actions.filter(at => at.piMilestoneId === mi.id);
      usedActionsIds = [...usedActionsIds, ...associatedActions.map(at => at.id)];
      elements.push(new MiTimelineElement(mi, associatedActions));
    }
    return elements;
  }

  render() {
    return (
      <Segment id="pi-timeline">
        <TimelineHeader
          selectedView={this.state.selectedView}
          selectedYear={this.state.selectedYear}
          selectedMonth={this.state.selectedMonth}
          selectedQuarter={this.state.selectedQuarter}
          setTimelineView={this.setTimelineView}
          setSelectedYear={this.setSelectedYear}
          setSelectedMonth={this.setSelectedMonth}
          setSelectedQuarter={this.setSelectedQuarter}
          setMultiplePeriods={this.setMultiplePeriods}
        />
        <div className="content">
          <TimelineContent
            selectedView={this.state.selectedView}
            year={this.state.selectedYear}
            quarter={this.state.selectedQuarter}
            month={this.state.selectedMonth}
            timelineElements={this.formatElements(this.props.actions, this.props.milestones)}
            setOverviewData={this.props.setOverviewData}
            activityIdToScrollTo={this.props.activityIdToScrollTo}
          />
          <TimelineLegend />
        </div>
      </Segment>
    );
  }
}

export default PiTimelineContainer;
