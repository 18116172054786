import { Table } from 'semantic-ui-react';
import { TimelineZone } from '../../../../../../../../constants/piTimeline';
import React, { Component } from 'react';
import TimelineUtils from '../../../../../../../../utils/timelineUtils';
import { Months, Quarter, TimelineView } from '../../../../../../../../constants/timeline';
import HeaderZone from './headerZone/headerZone';
import Utils from '../../../../../../../../utils/utils';

interface IProps {
  year: number;
  quarter: Quarter;
  selectedView: TimelineView;
  month: Months;
  isHidden: boolean;
}

export default class TimelineContentHeader extends Component<IProps> {

  private zones: TimelineZone[] = [];

  //region ZONES METHODS
  private getTimelineViewForYear = () => {
    const nbDays = TimelineUtils.getNbDaysInYear(this.props.year);
    this.zones.push(new TimelineZone(Months.JANUARY, this.props.year, nbDays));
    this.zones.push(new TimelineZone(Months.FEBRUARY, this.props.year, nbDays));
    this.zones.push(new TimelineZone(Months.MARCH, this.props.year, nbDays));
    this.zones.push(new TimelineZone(Months.APRIL, this.props.year, nbDays));
    this.zones.push(new TimelineZone(Months.MAY, this.props.year, nbDays));
    this.zones.push(new TimelineZone(Months.JUNE, this.props.year, nbDays));
    this.zones.push(new TimelineZone(Months.JULY, this.props.year, nbDays));
    this.zones.push(new TimelineZone(Months.AUGUST, this.props.year, nbDays));
    this.zones.push(new TimelineZone(Months.SEPTEMBER, this.props.year, nbDays));
    this.zones.push(new TimelineZone(Months.OCTOBER, this.props.year, nbDays));
    this.zones.push(new TimelineZone(Months.NOVEMBER, this.props.year, nbDays));
    this.zones.push(new TimelineZone(Months.DECEMBER, this.props.year, nbDays));
  };

  private getTimelineViewForQuarter = () => {
    const nbDays = TimelineUtils.getNbDaysForQuarter(this.props.quarter, this.props.year);
    switch (this.props.quarter) {
      case Quarter.Q1:
        this.zones.push(new TimelineZone(Months.JANUARY, this.props.year, nbDays));
        this.zones.push(new TimelineZone(Months.FEBRUARY, this.props.year, nbDays));
        this.zones.push(new TimelineZone(Months.MARCH, this.props.year, nbDays));
        break;
      case Quarter.Q2:
        this.zones.push(new TimelineZone(Months.APRIL, this.props.year, nbDays));
        this.zones.push(new TimelineZone(Months.MAY, this.props.year, nbDays));
        this.zones.push(new TimelineZone(Months.JUNE, this.props.year, nbDays));
        break;
      case Quarter.Q3:
        this.zones.push(new TimelineZone(Months.JULY, this.props.year, nbDays));
        this.zones.push(new TimelineZone(Months.AUGUST, this.props.year, nbDays));
        this.zones.push(new TimelineZone(Months.SEPTEMBER, this.props.year, nbDays));
        break;
      case Quarter.Q4:
        this.zones.push(new TimelineZone(Months.OCTOBER, this.props.year, nbDays));
        this.zones.push(new TimelineZone(Months.NOVEMBER, this.props.year, nbDays));
        this.zones.push(new TimelineZone(Months.DECEMBER, this.props.year, nbDays));
        break;
    }
  };

  private getTimelineViewForMonth = () => {
    const nbDays = TimelineUtils.getNbDaysForMonth(this.props.month, this.props.year);
    for (let i = 0; i < nbDays; i = i + 1) {
      this.zones.push(new TimelineZone(this.props.month, this.props.year, nbDays, i, i + 1));
    }
  };

  private initializeTimelineView = (): void => {
    this.zones = [];
    switch (this.props.selectedView) {
      case TimelineView.YEAR:
        this.getTimelineViewForYear();
        break;
      case TimelineView.QUARTER:
        this.getTimelineViewForQuarter();
        break;
      case TimelineView.MONTH:
        this.getTimelineViewForMonth();
        break;
    }
  };

  private getZones = (): JSX.Element[] => {

    this.initializeTimelineView();

    const zonesOnTimeline: JSX.Element[] = [];

    this.zones.forEach((zone: TimelineZone) => {
      zonesOnTimeline.push(
        <HeaderZone
          key={Utils.generateRandomKey()}
          zone={zone}
          selectedView={this.props.selectedView}
          year={this.props.year}
          quarter={this.props.quarter}
          month={this.props.month}
        />,
      );
    });
    return zonesOnTimeline;
  };
  //endregion

  render() {
    return (
      <Table.Header style={{ visibility: this.props.isHidden ? 'collapse' : 'visible' }}>
        <Table.Row>
          <Table.HeaderCell key="empty-zone" />
          {this.getZones()}
        </Table.Row>
      </Table.Header>
    );
  }

}
