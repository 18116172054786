import './customDropdown.scss';

import React from 'react';
import { Icon } from 'semantic-ui-react';
import { MenuItems } from '../titleContainer';
import Utils from '../../../../../utils/utils';

interface IProps {
  menuItems: MenuItems[];
  display: boolean;
  isSubMenuOnRight?: boolean;
  exportDropdown?: boolean;
}

interface IStates {
  activeItem: number;
}

class CustomDropdown extends React.Component<IProps, IStates> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      activeItem: -1,
    };
  }

  public render() {
    let ulClass = this.props.exportDropdown ? 'more-actions-menu export-action-menu' : 'more-actions-menu';

    if (!this.props.display) ulClass += ' hidden';
    if (this.props.menuItems.length > 25) ulClass += ' scrolling';
    if (!!this.props.isSubMenuOnRight) ulClass += ' right';

    let subMenuNumber = 0;
    this.props.menuItems.forEach((item) => {
      subMenuNumber = !!item.subMenu ? subMenuNumber + 1 : subMenuNumber;
    });

    return (
      <ul className={ulClass}>
        {this.props.menuItems.map((item, index) => {
          if (!!item.subMenu) {
            const isItemActive = item.active === undefined || item.active;
            return (
              <li
                key={`key${item.title}${index}`}
                className={`sub-menu ${isItemActive ? '' : 'disabled'}`}
                onMouseEnter={() => isItemActive && this.setState({ activeItem: index })}
                onMouseLeave={() => isItemActive && this.setState({ activeItem: -1 })}
              >
                <Icon name="caret left" />
                <span id="dropdown-label">{item.title}</span>
                {isItemActive
                  ? <>
                    <CustomDropdown
                      key={`CustomDropdown-${item.title}-sub-menu`}
                      menuItems={item.subMenu}
                      display={this.state.activeItem === index}
                      isSubMenuOnRight={this.props.isSubMenuOnRight}
                    />
                  </>
                  : Utils.loader('spinner', 'mini')
                }
              </li>
            );
          }

          return (
            <li
              key={`key${item.title}${index}`}
              onClick={item.handleClick}
            >
              <span className={`${subMenuNumber === 0 ? 'dropdown-label-with-submenu' : 'dropdown-label-without-submenu'}`}>{item.title}</span>
            </li>
          );
        })}
      </ul>
    );
  }
}

export default CustomDropdown;
