import './menuActivities.scss';

import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Icon, Popup } from 'semantic-ui-react';

import ActionTypes from 'src/constants/actionTypes';
import { MenuTypeSelected } from 'src/constants/typeActivity';
import PerformanceInitiative from 'src/models/performanceInitiative';
import ActivitiesStore from 'src/stores/activities.store';
import Utils from 'src/utils/utils';
import LinkLabel, { LinkLabelMode } from '../../common/linkLabel/linkLabel';
import { PiTabs } from '../containerActivities/contentPI/contentPI';
import { FormattedMessage } from 'react-intl';

interface IRouteProps {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  data: PerformanceInitiative;
  ppId: number;
}

interface IStates {
  isSelected: boolean;
  idNextPi: number;
  menuSelected: MenuTypeSelected;
}

class MenuPi extends React.Component<IProps, IStates> {
  private isMount: boolean = false;

  constructor(props: IProps) {
    super(props);
    this.state = {
      isSelected: ActivitiesStore.getSelectedActivityId() === this.props.data.id
        && Utils.isActivityPi(),
      idNextPi: -1,
      menuSelected: MenuTypeSelected.MENU_ACTIVITY,
    };
  }

  public componentWillMount() {
    this.isMount = true;
    ActivitiesStore.addListener(ActionTypes.ACTIVITY_SELECTED.toString(), this.getIdActivitySelected);
    ActivitiesStore.addListener(ActionTypes.MENU_TYPE_SELECTED.toString(), this.getMenuSelected);
  }

  public componentWillUnmount() {
    this.isMount = false;
    ActivitiesStore.removeListener(ActionTypes.ACTIVITY_SELECTED.toString(), this.getIdActivitySelected);
    ActivitiesStore.removeListener(ActionTypes.MENU_TYPE_SELECTED.toString(), this.getMenuSelected);
  }

  private getMenuSelected = () => {
    if (this.isMount) {
      this.setState({ menuSelected: ActivitiesStore.getMenuTypeSelected() });
    }
  };

  private getIdActivitySelected = () => {
    if (this.isMount) {
      this.setState({
        isSelected: ActivitiesStore.getSelectedActivityId() === this.props.data.id
          && Utils.isActivityPi(),
      });
    }
  };

  private onClick = () => {
    this.props.history
      .push(`/activities-board/${this.props.ppId}/performance-initiative/${this.props.data.id}/${PiTabs.COCKPIT}`);
  };

  public render() {
    let className = 'activities-menu-item menu-pi-render';
    if (this.props.location.pathname.indexOf(`performance-initiative/${this.props.data.id}/`) > -1) {
      className = `${className} selectedMenu`;
    } else {
      className = `${className} unselectedMenu`;
    }

    if (this.props.data.isClosed) className = `${className} closed`;

    return (
      <div key={`pi${this.props.data.id}`}>
        <div className={className} onClick={this.onClick}>
          {Utils.setBadge(this.props.data.status, this.props.data.isClosed)}
          <div className="menu-title-pi">
            {this.props.data.isLinked
              ? <LinkLabel mode={LinkLabelMode.ICON_ONLY} />
              : <span>PI{Utils.leadingZero(this.props.data.code)}</span>}
          </div>
          <div className="menu-name-pi">
            {this.props.data.name}
          </div>
            {this.props.data.isAssignee &&
            <FormattedMessage id="menu.piAssigned" defaultMessage="Assigned on the PI">
            {msg =>
              <Popup
                inverted={true}
                content={msg}
                size="tiny"
                trigger={
                  <div className="icon-div">
                  <Icon name="id badge" />
                </div>
                }
              />
            }
          </FormattedMessage>
            }
            {!this.props.data.isAssignee && this.props.data.isActionAssignee &&
            <FormattedMessage id="menu.actionAssigned" defaultMessage="Assigned on an Action">
            {msg =>
              <Popup
                inverted={true}
                content={msg}
                size="tiny"
                trigger={
                  <div className="icon-div">
                  <Icon name="id badge outline" />
                </div>
                }
              />
            }
          </FormattedMessage>
            }
        </div>
      </div>
    );
  }
}

export default withRouter(MenuPi);
