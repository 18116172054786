import React from 'react';
import Img from 'react-image';
import { FormattedHTMLMessage } from 'react-intl';
import { Button, Icon, Modal } from 'semantic-ui-react';

interface Props {
  image: string | undefined;
  disabled: boolean | undefined;
  setImageToUpload: (image: File) => void;
  setDeletedImage: () => void;
}

interface State {
  isUploadImageModalOpen: boolean;
  newImage: File | null;
  newImagePreview: string;
}

class MetricImageUpload extends React.Component<Props, State> {

  private readonly inputOpenFileRef: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isUploadImageModalOpen: false,
      newImage: null,
      newImagePreview: '',
    };
    this.inputOpenFileRef = React.createRef();
  }

  private setOpen = (isOpen: boolean) => {
    this.setState({
      isUploadImageModalOpen: isOpen,
    });
  }

  public uploadFile = () => {
    this.inputOpenFileRef.current.click();
  };

  public handleChange(e: any) {
    this.setState({
      newImage: e.target.files[0],
      newImagePreview: URL.createObjectURL(e.target.files[0]),
    });
  }

  private onCloseModal = () => {
    if (this.state.newImage) {
      this.props.setImageToUpload(this.state.newImage);
    }
    this.setOpen(false);
  }

  private resetImage = () => {
    if (this.props.image) {
      this.props.setDeletedImage();
    }
    this.setState({
      newImage: null,
      newImagePreview: '',
    });
  }

  private renderModal = () => {
    const imageSrc = this.state.newImagePreview !== '' ? this.state.newImagePreview : this.props.image;
    return (
      <Modal
        id="upload-image-modal"
        onClose={this.onCloseModal}
        onOpen={() => this.setOpen(true)}
        open={this.state.isUploadImageModalOpen}
      >
      <Modal.Header>
        <FormattedHTMLMessage id="metrics.uploadPicture.modal.title" defaultMessage="Upload a metric picture" />
      </Modal.Header>
      <Modal.Content image={true}>
        <Modal.Description>
        <input
          accept=".gif, .jpg, .tiff, .jpeg, .png"
          id="file-uploader"
          type="file"
          ref={this.inputOpenFileRef}
          onChange={e => this.handleChange(e)}
        />
        <>
          <label className="upload-image-label" htmlFor="file" onClick={this.uploadFile}>
            {imageSrc && <Img className="metrics-image" draggable="false" src={imageSrc} />}
            <Button className="upload-input">
              {!this.props.image && this.state.newImagePreview === '' &&
                <p><FormattedHTMLMessage id="metrics.uploadPicture.modal.uploadInput" defaultMessage="Click here to upload a picture" /></p>
              }
            </Button>
          </label>
          {(this.props.image || this.state.newImagePreview !== '') &&
          <Button basic={true} className="btn-delete" color="red" circular={true} onClick={this.resetImage}>
            <Icon name="trash" />
          </Button>}
        </>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary={true}
          onClick={this.onCloseModal}
        >
          <FormattedHTMLMessage id="submit" defaultMessage="Submit" />
          <Icon name="checkmark" />
        </Button>
      </Modal.Actions>
      </Modal>
    );
  }

  render() {
    return (
      <>
        <Button
          disabled={this.props.disabled}
          onClick={() => this.setOpen(true)}
        >
          <Icon name="picture" />
          <FormattedHTMLMessage id="metrics.uploadPicture" defaultMessage="Upload a picture" />
          {(this.props.image || this.state.newImagePreview) && <span className="picture-badge">1</span>}
        </Button>
        {this.renderModal()}
      </>
    );
  }
}

export default MetricImageUpload;
