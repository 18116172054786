import './menuAdministration.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactSVG from 'react-svg';
import AdministrationActions from 'src/actions/administration.action';
import FeedbackActions from 'src/actions/feedback.action';
import ActionTypes from 'src/constants/actionTypes';
import AdministrationTab from 'src/constants/administrationTab';
import comment from 'src/images/comment.svg';
import home from 'src/images/home.svg';
import users from 'src/images/users.svg';
import FeedbackStore from 'src/stores/feedback.store';
import UserGuide from './userGuide/userGuide';
import { Icon } from 'semantic-ui-react';

interface IProps {
}

interface IStates {
  activeItem: AdministrationTab;
  feedbackBugsCount: number;
  feedbackIdeasCount: number;
  feedbackOtherCount: number;
  feedbackArchivesCount: number;
  isUserGuideModalOpened: boolean;
}

class MenuAdministration extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);

    this.state = {
      activeItem: AdministrationTab.DASHBOARD,
      feedbackBugsCount: FeedbackStore.getFeedbacksBugsCount(),
      feedbackIdeasCount: FeedbackStore.getFeedbacksSuggestionsCount(),
      feedbackOtherCount: FeedbackStore.getFeedbacksOthersCount(),
      feedbackArchivesCount: FeedbackStore.getFeedbacksArchivedCount(),
      isUserGuideModalOpened: false,
    };

    FeedbackActions.emitGetFeedbacksCounts();
    AdministrationActions.emitTabSelect(AdministrationTab.DASHBOARD);
  }

  public componentWillMount() {
    FeedbackStore.addListener(ActionTypes.GET_FEEDBACKS_COUNTS.toString(), () => {
      this.countFeedback();
    });
  }

  public componentWillUnmount() {
    FeedbackStore.removeListener(ActionTypes.GET_FEEDBACKS_COUNTS.toString(), () => {
      this.countFeedback();
    });
  }

  /**
   * Set active menu item and change container content
   */
  private setActiveItem = (activeItem: AdministrationTab) => {
    this.setState({ activeItem });
    AdministrationActions.emitTabSelect(activeItem);
  };

  /**
   * Counts the feedbacks message depending on their type (Bug / Suggestions / Other)
   */
  private countFeedback() {
    this.setState({
      feedbackBugsCount: FeedbackStore.getFeedbacksBugsCount(),
      feedbackIdeasCount: FeedbackStore.getFeedbacksSuggestionsCount(),
      feedbackOtherCount: FeedbackStore.getFeedbacksOthersCount(),
      feedbackArchivesCount: FeedbackStore.getFeedbacksArchivedCount(),
    });
  }

  public render() {
    return (
      <div id="menu-administration">
        <div id="menu-title">
          <FormattedMessage
            id="menu.administrationTitle"
            defaultMessage="iBOOST ADMIN"
          />
        </div>

        <div
          className={`menu-button ${this.state.activeItem === AdministrationTab.DASHBOARD ? 'active' : null}`}
          onClick={() => this.setActiveItem(AdministrationTab.DASHBOARD)}
        >
          <ReactSVG
            className="svg-icon"
            src={home}
          />
          <FormattedMessage
            id="menu.dashboard"
            defaultMessage="Dashboard"
          />
        </div>

        <div
          className={`menu-button ${this.state.activeItem === AdministrationTab.USERS ? 'active' : null}`}
          onClick={() => this.setActiveItem(AdministrationTab.USERS)}
        >
          <ReactSVG
            className="svg-icon"
            src={users}
          />
          <FormattedMessage
            id="menu.users"
            defaultMessage="Users"
          />
        </div>

        <div
          className={`menu-button ${
            this.state.activeItem >= AdministrationTab.FEEDBACK_BUGS
            && this.state.activeItem <= AdministrationTab.FEEDBACK_ARCHIVES
              ? 'active'
              : null
          }`}
          onClick={() => this.setActiveItem(AdministrationTab.FEEDBACK_BUGS)}
        >
          <ReactSVG
            className="svg-icon"
            src={comment}
          />
          <FormattedMessage
            id="menu.feedbacks"
            defaultMessage="Feedbacks"
          />
        </div>

        <div className="submenu">
          <div
            className={`submenu-button ${this.state.activeItem === AdministrationTab.FEEDBACK_BUGS ? 'active' : null}`}
            onClick={() => this.setActiveItem(AdministrationTab.FEEDBACK_BUGS)}
          >
            <FormattedMessage
              id="menu.feedbacks.bugs"
              defaultMessage="Bugs"
            >
              {msg => `${msg} (${this.state.feedbackBugsCount})`}
            </FormattedMessage>
          </div>

          <div
            className={`submenu-button ${this.state.activeItem === AdministrationTab.FEEDBACK_SUGGESTIONS ?
              'active' : null}`}
            onClick={() => this.setActiveItem(AdministrationTab.FEEDBACK_SUGGESTIONS)}
          >
            <FormattedMessage
              id="menu.feedbacks.suggestions"
              defaultMessage="Suggestions"
            >
              {msg => `${msg} (${this.state.feedbackIdeasCount})`}
            </FormattedMessage>
          </div>

          <div
            className={`submenu-button ${this.state.activeItem === AdministrationTab.FEEDBACK_OTHER ? 'active' : null}`}
            onClick={() => this.setActiveItem(AdministrationTab.FEEDBACK_OTHER)}
          >
            <FormattedMessage
              id="menu.feedbacks.otherComments"
              defaultMessage="Other Comments"
            >
              {msg => `${msg} (${this.state.feedbackOtherCount})`}
            </FormattedMessage>
          </div>

          <div
            className={`submenu-button ${
              this.state.activeItem === AdministrationTab.FEEDBACK_ARCHIVES
                ? 'active'
                : null
            }`}
            onClick={() => this.setActiveItem(AdministrationTab.FEEDBACK_ARCHIVES)}
          >
            <FormattedMessage
              id="menu.feedbacks.archives"
              defaultMessage="Archives"
            >
              {msg => `${msg} (${this.state.feedbackArchivesCount})`}
            </FormattedMessage>
          </div>
        </div>

        <div
          className={`menu-button ${this.state.activeItem === AdministrationTab.USER_GUIDE ? 'active' : null}`}
          onClick={() => this.setState({ isUserGuideModalOpened: true })}
        >
          <Icon className="user-guide-icon" name="file alternate"/>
          <FormattedMessage
            id="menu.userGuide"
            defaultMessage="User Guide"
          />
        </div>

        <div
          className={`menu-button ${this.state.activeItem === AdministrationTab.LOAD_TESTS ? 'active' : null}`}
          onClick={() => this.setActiveItem(AdministrationTab.LOAD_TESTS)}
        >
          <Icon className="user-guide-icon" name="server"/>
          <FormattedMessage id="loadTestsResults" defaultMessage="Load tests results" />
        </div>

        {this.state.isUserGuideModalOpened
          && <UserGuide
            onModalClose={() => this.setState({ isUserGuideModalOpened: false })}
          />
        }
      </div>
    );
  }
}

export default MenuAdministration;
