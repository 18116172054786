import './activityLink.scss';

import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import PerformancePlan from '../../models/performancePlan';
import Utils from '../../utils/utils';
import CustomScrollBars from '../common/customScrollBars/customScrollBars';

interface IProps {
  performancePlans: PerformancePlan[] | undefined;
  selectedPp: number;
  selectPp(selectedPp: number): void;
}

export default class ActivityPpSelection extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    if (this.props.performancePlans === undefined) {
      return Utils.loader();
    }

    if (this.props.performancePlans.length === 0) {
      return Utils.empty(<FormattedMessage id="noPP" defaultMessage="No Performance Plan was found" />);
    }

    return (
      <div id="activity-link-step3" className="step-container">
        <CustomScrollBars className={this.props.performancePlans.length === 0 ? 'large' : ''}>
          {this.props.performancePlans.map(pp =>
            <div
              key={`pp${pp.id}`}
              className="tile-container"
              onClick={() => this.props.selectPp(pp.id)}
            >
              <div className={`tile pp-tile ${this.props.selectedPp === pp.id ? 'selected' : ''}`}>
                {this.props.selectedPp === pp.id && <Icon name="check" />}&nbsp;
                {pp.name}&nbsp;
                <span className="year">{pp.year}</span>
              </div>
            </div>,
          )}
        </CustomScrollBars>
      </div>
    );
  }
}
