import { EventEmitter } from 'events';

import Action from '../actions/action';
import ActionTypes from '../constants/actionTypes';
import Dispatcher from '../dispatcher';
import TypeActivity from '../constants/typeActivity';
import User from '../models/user';

export interface SelectedActivity {
  name: string;
  type: TypeActivity;
  id: number;
  assignedAccounts: User[];
  objectives: string;
  publishedDate: Date;
}

class TreeViewStore extends EventEmitter {
  private selectedActivity: SelectedActivity;

  constructor() {
    super();
    Dispatcher.register(this.registerToActions.bind(this));
  }

  /**
   * Get selectedActivity
   */
  public getSelectedActivity(): SelectedActivity {
    return this.selectedActivity;
  }

  /**
   * Set selectedActivity
   */
  public setSelectedActivity(selectedActivity: SelectedActivity): void {
    this.selectedActivity = selectedActivity;
    this.emit(ActionTypes.GET_SELECTED_ACTIVITY_TREEVIEW.toString());
  }

  /**
   * Performs a function according to an action
   * @param action : Action
   * @return void
   */
  private registerToActions(action: Action) {
    switch (action.actionType) {
      case ActionTypes.GET_SELECTED_ACTIVITY_TREEVIEW:
        this.setSelectedActivity(action.selectedActivity);
        break;
    }
  }
}

export default new TreeViewStore();
