import './reportModalContent.scss';

import React, { createRef } from 'react';
import { FormattedMessage } from 'react-intl';
import PerformanceInitiative from 'src/models/performanceInitiative';
import Utils from 'src/utils/utils';

interface IProps {
  tabId: number;
  tab: string;
  bcComment: string | JSX.Element;
  pi: PerformanceInitiative[];
}

interface IStates {
  tabCommentBC: string;
  tabTitle: string;
}

class ReportModalContent extends React.Component<IProps, IStates> {
  public scrollbarRef =  createRef<HTMLDivElement>();

  public constructor(props: IProps) {
    super(props);
    this.state = {
      tabCommentBC: '',
      tabTitle: '',
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>, snapshot?: any): void {
    if ((prevProps.tabId !== this.props.tabId) && this.scrollbarRef.current) {
      this.scrollbarRef.current.scrollIntoView(true);
    }
  }

  private getPIComments(pi: PerformanceInitiative) : string {
    let tabCommentsPI = '';
    switch (this.props.tabId) {
      case 1:
        tabCommentsPI = pi.achievements;
        break;
      case 2:
        tabCommentsPI = pi.issuesAndRisks;
        break;
      case 3:
        tabCommentsPI = pi.decisionsToBeMade;
        break;
      case 4:
        tabCommentsPI = pi.nextSteps;
        break;
      default:
        break;
    }
    return tabCommentsPI;
  }

  public render() {

    const leftColumnContent: JSX.Element[] = [];
    const rightColumnContent: JSX.Element[] = [];

    if (this.props.pi && this.props.pi.length > 0 && this.props.tabId !== 0) {
      const performanceInitiatives = Utils.sortArrayByKey(this.props.pi, 'code');
      performanceInitiatives.map((pi: PerformanceInitiative, index: number) => {
        const piElement = (
            <div id="pi" key={pi.id}>
              <div id="pi-title">
                {Utils.setBadge(pi.status, pi.isClosed)}
                {`   PI${Utils.leadingZero(pi.code)}`} {this.props.tab}:
              </div>
              <div id="pi-content">
                {Utils.getFormattedTextFromHTMLString(this.getPIComments(pi))}
              </div>
            </div>
          );

        if (index % 2 === 0) {
          leftColumnContent.push(piElement);
        } else {
          rightColumnContent.push(piElement);
        }
        return null;
      });
    }
    return (
      <div id="reporting-modal-container" ref={this.scrollbarRef}>
        {this.props.tabId !== 0
          && <div id="bc-comment-title">
            <FormattedMessage
              id="bc.comment"
              defaultMessage="BC Leader's comment on "
            >
              {msg => `${msg} ${this.props.tab}:`}
            </FormattedMessage>
          </div>
        }
        <div id="bc-comment">
          {typeof this.props.bcComment === 'string'
            ? Utils.getFormattedTextFromHTMLString(this.props.bcComment)
            : this.props.bcComment
          }
        </div>
        <div id="pi-list">
          <div id="left-column" className="column">
            {leftColumnContent}
          </div>
          <div className="column">
            {rightColumnContent}
          </div>
        </div>
      </div>
    );
  }
}

export default ReportModalContent;
