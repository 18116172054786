import './administrationBoard.scss';

import * as React from 'react';
import MenuAdministration from 'src/components/administrationBoard/menuAdministration/menuAdministration';
import ContainerAdministration
  from 'src/components/administrationBoard/containerAdministration/containerAdministration';

interface IProps { }

interface IStates { }

class AdministrationBoard extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    return (
      <div id="administration-container">
        <MenuAdministration />
        <ContainerAdministration />
      </div >
    );
  }
}

export default AdministrationBoard;
