export enum AssignationType {
  PP_DEPUTY,
  PP_INFORMED,
  BC_LEADER,
  BC_DEPUTY,
  BC_INFORMED,
  PI_LEADER,
  PI_DEPUTY,
  PI_INFORMED,
  AT_OWNER,
}
