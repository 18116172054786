import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Button, Icon } from 'semantic-ui-react';
import Xlsx from 'xlsx';

import Utils from 'src/utils/utils';
import PerformancePlan from 'src/models/performancePlan';
import BusinessChallenge from 'src/models/businessChallenge';
import PerformanceInitiative from 'src/models/performanceInitiative';
import ExportAPI from 'src/api/export.api';
import NotificationActions from 'src/actions/notification-actions';
import { ToastType } from 'src/components/common/toast/toast';
import ModeTypes from 'src/constants/modeTypes';
import TypeActivity from 'src/constants/typeActivity';
import { ExportSheetErrors } from '../../../../constants/errors/export.error';

interface IProps {
  data: BusinessChallenge | PerformanceInitiative | PerformancePlan;
  mode: ModeTypes;
  type: TypeActivity;
}

interface IState {
  exportLoading: boolean;
}

class ExportSheetButton extends React.Component<IProps & RouteComponentProps, IState> {

  public constructor(props: IProps & RouteComponentProps) {
    super(props);
    this.state = {
      exportLoading: false,
    };
  }

  /**
   * Export team list as SHEET
  */
  private exportSheet = () => {
    this.setState({ exportLoading: true });
    const wb = ExportAPI.getCsvExport(this.props.data.id, this.props.type);
    wb.then((workbook) => {
      let activity;
      let code = '';
      switch (this.props.type) {
        case TypeActivity.PERFORMANCE_PLAN:
          activity = 'PP';
          break;
        case TypeActivity.BUSINESS_CHALLENGE:
          activity = 'BC';
          code = Utils.leadingZero((this.props.data as BusinessChallenge).code);
          break;
        case TypeActivity.PERFORMANCE_INITIATIVE:
          activity = 'PI';
          code = Utils.leadingZero((this.props.data as PerformanceInitiative).code);
          break;
      }

      const fileName = `export_${activity}${code}_members_${Utils.displayDate(new Date(), false, '-')}.xlsx`;

      Xlsx.writeFile(workbook, fileName);

      NotificationActions.toast(
        <FormattedMessage id="export" defaultMessage="Success" />,
        <FormattedMessage id="exportDone" defaultMessage="Sheet successfully exported"/>,
        ToastType.SUCCESS,
      );
    })
    .catch((err) => {
      if (err.error === ExportSheetErrors.NO_ASSIGNATIONS) {
        NotificationActions.toast(
          <FormattedMessage id="exportUnable" defaultMessage="Export to Sheet unavailable" />,
          <FormattedMessage id="exportNoAssignation" defaultMessage="You can't export this activity because there is no assignation on it."/>,
          ToastType.WARNING,
        );
      } else {
        Utils.toastError();
      }
    });
    this.setState({ exportLoading: false });
  }

  public render() {
    return (
      <Button
        id="exportButton"
        basic={true}
        onClick={this.exportSheet}
        loading={this.state.exportLoading}
        disabled={this.props.mode === ModeTypes.MODE_EDITION}
      >
        <Icon name="download" />
        <FormattedMessage id="exportSheet" defaultMessage="Export (SHEET)" />
      </Button>
    );
  }
}

export default withRouter(ExportSheetButton);
