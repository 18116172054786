import React from 'react';
import Utils from '../../../../utils/utils';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import ToggleHideElement from '../../../activitiesBoard/containerActivities/toggleHideElement/toggleHideElement';
import { ActionEdit } from '../../../../models/action';
import ModeTypes from '../../../../constants/modeTypes';
import { MeMiAtCommonField } from '../../../../constants/fields';
import User from '../../../../models/user';

interface IProps {
  action: ActionEdit;
  mode: ModeTypes;
  canEditPi: boolean;
  toggleIsFavourite(): void;
  handleChange(key: string, value: string | User[] | number | boolean): void;

}

class FavouriteAndHideToggle extends React.Component<IProps, any> {

  private getPopUpContent = () => {
    return (
      this.props.canEditPi ? (
        <div>
          <FormattedMessage
            id="setActionFavourite"
            defaultMessage="Marking the action as a favourite brings it up in the Metrics View tab and at Exports' level"
          />
        </div>
      ) : (
        <div>
          <FormattedMessage
            id="unauthorized"
            defaultMessage="You are not authorized to perform this action"
          />
        </div>
      )
    );
  }

  private getFavouriteIconDisplay = () => {
    const favouriteIconStyle = this.props.action.isFavourite ? 'star' : 'star outline';
    const favouriteIcon = <Icon name={favouriteIconStyle} className="favourite-icon"/>;

    const editFavouriteBtn = (
      <Button
        icon={true}
        className="favourite-btn"
        onClick={this.props.toggleIsFavourite}
        disabled={!this.props.canEditPi}
      >
        {favouriteIcon}
      </Button>
    );

    return Utils.isOnViewMode(this.props.mode)
      ? <span id="fav-icon">{favouriteIcon}</span>
      : (
          <Popup
            id="favourite-popup"
            size="tiny"
            inverted={true}
            content={this.getPopUpContent()}
            trigger={
              this.props.canEditPi
                ? editFavouriteBtn
                : <span className="favourite-btn-span">{editFavouriteBtn}</span>
            }
          />
      );
  }

  render() {
    return (
        <>
          {this.getFavouriteIconDisplay()}
          <ToggleHideElement
            isElementHidden={this.props.action.isHidden}
            updateElementState={() =>
              this.props.handleChange(MeMiAtCommonField.IS_HIDDEN, !this.props.action.isHidden)
            }
            viewOnly={Utils.isOnViewMode(this.props.mode)}
          />
        </>
    );
  }
}

export default FavouriteAndHideToggle;
