import { EventEmitter } from 'events';
import AdministrationTab from 'src/constants/administrationTab';
import Dispatcher from '../dispatcher';
import Action from '../actions/action';
import ActionTypes from 'src/constants/actionTypes';

class AdministrationStore extends EventEmitter {

  private administrationTab: AdministrationTab = AdministrationTab.DASHBOARD;

  constructor() {
    super();
    Dispatcher.register(this.registerToActions.bind(this));
  }

  /**
   * Get selected Administration Tab
   * @return AdministrationTab
   */
  public getTab(): AdministrationTab {
    return this.administrationTab;
  }

  /**
   * Set Administration Tab
   * @param administrationTab AdministrationTab
   */
  public setTab(administrationTab: AdministrationTab) {
    this.administrationTab = administrationTab;
    this.emit(ActionTypes.ADMINISTRATION_TAB_GET.toString());
  }

  /**
   * Performs a function according to an action
   * @param action : Action
   * @return void
   */
  private registerToActions(action: Action) {
    switch (action.actionType) {
      case ActionTypes.ADMINISTRATION_TAB_GET:
        this.setTab(action.administrationTab);
        break;
    }
  }

}

export default new AdministrationStore();
