import './chartModal.scss';

import * as React from 'react';
import { Button, Icon, Modal, Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { exportComponentAsJPEG } from 'react-component-export-image';
import ReactDOM from 'react-dom';
import html2canvas from 'html2canvas';
import { isChrome, isEdge } from 'react-device-detect';

import Metric from '../../../../../../../models/metric';
import Chart from './chart';
import Utils from '../../../../../../../utils/utils';
import Metrics from '../../metrics';
import Img from 'react-image';

interface IProps {
  metric: Metric;
  onModalClose(): void;
  metricsCount: number;
  image: string;
}

interface IStates {
  isChartCopying: boolean;
  chartCopied: boolean;
  imageUploaded: string;
}

class ChartModal extends React.Component<IProps, IStates> {
  private chartRef = React.createRef<HTMLDivElement>();

  public constructor(props: IProps) {
    super(props);

    this.state = {
      isChartCopying: false,
      chartCopied: false,
      imageUploaded: '',
    };
  }

  private close = () => {
    this.props.onModalClose();
  };

  private saveChart = () => {
    exportComponentAsJPEG(this.chartRef, `${this.props.metric.name}.jpg`);
  };

  private copyToClipboard = () => {
    this.setState(
      { isChartCopying: true },
      async () => {
        // @ts-ignore
        const result = await navigator.permissions.query({ name: 'clipboard-write' });
        if (result.state === 'granted' || result.state === 'prompt') {
          const element = ReactDOM.findDOMNode(this.chartRef.current);
          if (!!element && element instanceof HTMLElement) {
            try {
              // Convert chart to canvas
              const canvas = await html2canvas(element, {
                backgroundColor: null,
                scrollY: -window.scrollY,
                useCORS: true,
              });

              canvas.toBlob((blob) => {
                if (blob) {
                  (navigator.clipboard as any).write([new ClipboardItem({ 'image/png': blob })]);

                  this.setState(
                    { isChartCopying: false, chartCopied: true },
                    () => {
                      setTimeout(
                        () => this.setState({ chartCopied: false }),
                        2000,
                      );
                    },
                  );
                }
              });
            } catch {
              Utils.toastError();
            }
          }
        }
      },
    );
  }

  public render() {
    const saveToClipboardButton = (
      <Button
        id="save-chart"
        primary={true}
        loading={this.state.isChartCopying}
        disabled={this.state.isChartCopying}
        onClick={this.copyToClipboard}
      >
        <FormattedMessage id="copyToClipboard" defaultMessage="Copy the chart to clipboard" />
        <Icon name="copy" />
      </Button>
    );

    const canCopyToClipboard = isEdge || isChrome;
    const copyToClipboardButton = this.state.chartCopied
      ? (
        <Popup
          inverted={true}
          position="top center"
          trigger={saveToClipboardButton}
          content={<FormattedMessage id="chartCopied" defaultMessage="Chart copied!" />}
        />
      )
      : saveToClipboardButton;

    return (
      <Modal
        id="screenshot-modal"
        open={true}
        onClose={this.close}
      >
        <Modal.Content>
          <div id="chart-container" ref={this.chartRef}>
            <div className="metric-info">
              <span className="metric-name">
                {this.props.metric.name}
                {this.props.metric.unit && this.props.metric.unit !== '' && ` (${this.props.metric.unit})`}
              </span>
              {Metrics.getComparisonStatusLabel(this.props.metric)}
            </div>
            {this.props.metric.hasImage
            ?
            <Img
              className="metrics-image"
              draggable="false"
              src={[this.props.image]}
            />
            :
            <Chart
              metricsCount={this.props.metricsCount}
              metric={this.props.metric}
              isCalledFromModal={true}
            />
            }
          </div>
        </Modal.Content>
        <Modal.Actions className="actions">
          <Button color="grey" onClick={this.close}>
            <FormattedMessage id="close" defaultMessage="Close" />
          </Button>

          <div className="save-buttons">
            {canCopyToClipboard && copyToClipboardButton}
            <Button id="save-chart" primary={true} onClick={this.saveChart}>
              <FormattedMessage id="saveImage" defaultMessage="Save the image" />
              <Icon name="camera" />
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ChartModal;
