import './userInfos.scss';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Input, Label } from 'semantic-ui-react';

import Utils from 'src/utils/utils';
import User from 'src/models/user';
import UserActions from 'src/actions/user.action';
import NotificationActions from 'src/actions/notification-actions';
import { ToastType } from 'src/components/common/toast/toast';

interface IProps {
  user: User;
}

interface IStates {
  phoneDisplay: boolean;
  phone: string;
  phoneLabelClass: string;
  phoneLabelMessage: string | JSX.Element;
}

class UserInfos extends React.Component<IProps, IStates> {

  public constructor(props: IProps) {
    super(props);

    this.state = {
      phoneDisplay: true,
      phone: this.props.user.phone,
      phoneLabelClass: '',
      phoneLabelMessage: '',
    };
  }

  /**
   * Validate the new phone number
   * @param {any} event
   */
  public handleChangePhone = (event: any) => {
    this.setState({
      phoneLabelClass: '',
    });

    if (new RegExp('^.{10,20}$').test(event.target.value)) {
      this.setState({
        phone: event.target.value,
      });
    } else {
      if (event.target.value.length === 0) {
        this.setState({ phoneLabelClass: '' });
      } else if (event.target.value.length < 10) {
        this.setPhoneLabel('tooShort');
      } else if (event.target.value.length > 20) {
        this.setPhoneLabel('tooLong');
      }
    }
  };

  /**
   * Format the phone (adding spaces to make it human readable)
   * @param {string} str the phone number to format
   * @return {string} the phone number formatted
   */
  public phoneWithSpaces(str: string) {
    if (str === null) {
      return str;
    }
    return str.replace(/\B(?=(\d{2})+(?!\d))/g, ' ').replace(/\)/, ') ');
  }

  /**
   * Set the formatted error message depending on the message code
   * @param {string} msg message code
   */
  public setPhoneLabel(msg: string) {
    let jsxMessage: JSX.Element;

    switch (msg) {
      case 'tooShort':
        jsxMessage = (
          <FormattedMessage
            id="profile.tooShort"
            defaultMessage="Too short"
          >
            {msg => (this.setLabelText(msg))}
          </FormattedMessage>);
        break;

      case 'tooLong':
        jsxMessage = (
          <FormattedMessage
            id="profile.tooLong"
            defaultMessage="Too long"
          >
            {msg => (this.setLabelText(msg))}
          </FormattedMessage>
        );
        break;

      default:
        jsxMessage = <div />;
        break;
    }

    this.setState({
      phoneLabelClass: 'incorrect',
      phoneLabelMessage: jsxMessage,
    });
  }

  /**
   * Set the error message label
   * @param {string} msg error message
   * @return {JSX.Element} the label
   */
  public setLabelText(msg: string | JSX.Element): JSX.Element {
    return <Label id="phone-label" pointing={true}>{msg}</Label>;
  }

  /**
   * Allow the user to press the 'enter' key to confirm his input
   * @param {any} target
   */
  public handleKeyPress(target: any) {
    if (target.charCode === 13) {
      this.togglePhoneVisibility('display');
    }
  }

  /**
   * Toggle the phone display/edition mode
   * @param {string} mode either 'display' or 'edition'
   */
  public togglePhoneVisibility(mode: string) {
    this.setState({ phoneDisplay: !this.state.phoneDisplay });

    if (mode === 'display') {
      const userCopy = JSON.parse(JSON.stringify(this.props.user));
      userCopy.phone = this.state.phone;

      UserActions.emitSaveUserProfile(userCopy, this.props.user)
        .then(() => {
          NotificationActions.toast(
            <FormattedMessage id="success" defaultMessage="Success" />,
            <FormattedMessage id="successProfileSave" defaultMessage="Your profile has been successfully saved" />,
            ToastType.SUCCESS,
          );
        })
        .catch(() => {
          Utils.toastError();
        });
    }
  }

  /**
   * Set formatted message when the phone input is empty
   * @return {JSX.Element} the formatted message
   */
  public phoneEmpty(): JSX.Element {
    return (
      <FormattedMessage
        id="profile.phoneEmpty"
        defaultMessage="No phone provided"
      />
    );
  }

  public render() {
    return (
      <div id="user-infos-subcontainer">
        <div id="left-container">
          <div className="username user-infos">
            {this.props.user.firstName} {this.props.user.lastName.toUpperCase()}
          </div>
          <div className="user-infos">{Utils.capitalizeWords(this.props.user.role.name)}</div>
          <div className="user-infos last">{this.props.user.entity}</div>
        </div>

        <div id="right-container">
          <div id="location" className="user-infos">
            {this.props.user.location}
          </div>
          <div id="phone" className="user-infos">
            <div className={this.state.phoneDisplay ? '' : 'hidden-AB'}>
              {this.props.user.phone && this.props.user.phone !== ''
                ? this.phoneWithSpaces(this.props.user.phone)
                : this.phoneEmpty()}
              <a href="#/" onClick={() => this.togglePhoneVisibility('edit')}>
                <Icon name="edit" className="edit-button" />
              </a>
            </div>

            <div className={this.state.phoneDisplay ? 'hidden-AB' : this.state.phoneLabelClass}>
              <Input
                defaultValue={this.props.user.phone}
                onChange={this.handleChangePhone}
                onKeyPress={(e: any) => this.handleKeyPress(e)}
              />
              {this.state.phoneLabelMessage}

              <a href="#/" onClick={() => this.togglePhoneVisibility('display')}>
                <Icon name="check" className="edit-button" />
              </a>
            </div>
          </div>
          <div className="user-infos last">{this.props.user.email}</div>
        </div>
      </div>
    );
  }
}

export default UserInfos;
